import React from 'react';
import { useTheme } from 'styled-components';
import { Icon } from 'rio-ui-components';
import { getAntivirusIconProps } from './antivirusStatusProps';

export function AntivirusStatusCell(props) {
  const { data: { antivirusStatus } = {} } = props;
  const theme = useTheme();

  return <Icon name={`document-row__antivirus`} title={antivirusStatus} size="lg" {...getAntivirusIconProps(antivirusStatus, theme)} />;
}
