import { GridApi, IRowNode } from 'ag-grid-community';

export function handleSelection(gridApi: GridApi | undefined, pageSize: number, shouldDeselectAll?: boolean) {
  if (gridApi) {
    const currentPage = gridApi.paginationGetCurrentPage();
    const startRow = currentPage * pageSize;
    const endRow = startRow + pageSize - 1;

    const nodesOnCurrentPage: IRowNode[] = [];
    for (let i = startRow; i <= endRow; i++) {
      const node = gridApi.getDisplayedRowAtIndex(i);
      if (node) {
        nodesOnCurrentPage.push(node);
      }
    }

    nodesOnCurrentPage.forEach((rowNode) => {
      if (shouldDeselectAll) {
        rowNode.setSelected(false);
        return;
      }

      if (!rowNode.isSelected()) {
        rowNode.setSelected(true);
      }
    });
  }
}
