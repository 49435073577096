import { TextField } from '@rio/ui-components';

interface SearchHeaderProps {
  search: string;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}
export const SearchHeader = (props: SearchHeaderProps) => {
  const { search, onSearchValueChange, placeholder = 'Search Documents...' } = props;

  return (
    <TextField
      type="search"
      name="DocumentFiles__Search"
      placeholder={placeholder}
      value={search}
      onChange={onSearchValueChange}
    />
  );
};
