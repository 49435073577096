import { useCallback, useMemo, useState } from 'react';
import { Survey, SurveyContributor, SurveySubmission, User } from '@rio/rio-types';
import _ from 'lodash';
import { styled, AgGrid, Icons, Text } from '@rio/ui-components';
import { ColDef, GetRowIdParams, GridOptions } from 'ag-grid-community';
import { alphabetiseStrings } from '~/utils';
import { StatusSpan } from '~/components/Surveys/v2';
import { formatSubmissionStatus } from '~/components/Surveys';
import { getPerformanceStatus } from './chartConfigs/calculateScores';
import { ProgressBarChart } from './ProgressBarChart';
import { useNavigate } from 'react-router-dom';
import { RowClickedEvent } from 'ag-grid-community';
import { SurveyLockWarningModal } from '../../ReceivedSurveysContainer/SurveyLockWarningModalV2';
import { useNotification } from '../../../../hooks';

type ContributorsSubmissionInformation = {
  contributor: SurveyContributor;
  submission?: SurveySubmission;
  reviewable: boolean;
  performance?: number;
};

interface ContributorsGridProps {
  submissionDetails: ContributorsSubmissionInformation[];
  showPerformance: boolean;
  survey: Survey;
}

/**
 * @see https://ag-grid.com/react-data-grid/global-style-customisation-variables/#reference-variables---ag-data-color
 */
const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: 480px;

  & .ag-paging-panel {
    justify-content: flex-start;
  }

  && .ag-row:hover {
    cursor: pointer;
  }

  &&& .ag-row .ag-cell:first-child {
    padding-left: 12px;
  }
`;
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Column = styled('div')`
  display: flex;
  flex-direction: column;
`;
const ActionCellWrapper = styled('div')`
  padding: 8px 4px;
`;
const ActionText = styled(Text)`
  text-decoration: underline;
`;

const defaultColDef: ColDef = {
  autoHeight: true,
  filter: false,
  sortable: false,
  suppressFiltersToolPanel: true,
  suppressMenu: true,
};

const components = {
  status: ({ data }) => (
    <StatusSpan typescale="body" size="medium" status={data.submission?.status}>
      {formatSubmissionStatus(data.submission?.status)}
    </StatusSpan>
  ),
  performance: ({ data }) => {
    return (
      <>
        {data.performance && (
          <Column>
            <ProgressBarChart
              text={_.capitalize(`${getPerformanceStatus(data.performance)} overall performance`)}
              totals={[data.performance, 100]}
            />
          </Column>
        )}
        {!data.performance && '-'}
      </>
    );
  },
  chevron: ({ data }) => {
    return (
      <>
        {data.reviewable && (
          <IconContainer>
            <Icons.ChevronRight color="secondary" />
          </IconContainer>
        )}
      </>
    );
  },
  action: ({ data }) => {
    return (
      <ActionCellWrapper>
        <ActionText typescale="body" size="medium">
          {data.reviewable ? 'Review' : 'Complete survey'}
        </ActionText>
      </ActionCellWrapper>
    );
  },
};

const getRowId = (params: GetRowIdParams) => params.data.contributor.account.id;

const getContributorName = (contributor: SurveyContributor) =>
  contributor.user
    ? `${contributor.user.first_name} ${contributor.user.last_name} (${contributor.user.email})`
    : contributor.account.name;

export const SurveyContributorsResponseGrid = ({
  submissionDetails,
  showPerformance,
  survey,
}: ContributorsGridProps) => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [lockWarningUser, setLockWarningUser] = useState<User | null>(null);

  const isSurveyPerAccountType = useMemo(() => survey.contributors.every((c) => !Boolean(c.user)), [survey]);

  const handleRowClickPerAccountType = useCallback(
    (cell: RowClickedEvent) => {
      const surveyLockedBy = cell.data.contributor?.surveys?.find((s) => s.id === survey.id)?.lockedBy;

      const navigateToSurvey = () => {
        if (cell.data.reviewable && cell.data.submission.id) {
          return navigate(
            `../sent/contributors/${cell.data.contributor.account.id}/submission/${cell.data.submission.id}`
          );
        }

        return navigate(`../received/${survey.id}?contributorId=${cell.data.contributor.account.id}`);
      };

      return surveyLockedBy ? setLockWarningUser(surveyLockedBy) : navigateToSurvey();
    },
    [navigate, survey.id]
  );

  const handleRowClickIndividualType = useCallback(
    (e: RowClickedEvent) => {
      if (e.data.reviewable) {
        navigate(`../sent/contributors/${e.data.contributor.account.id}/submission/${e.data.submission.id}`);
      } else {
        showNotification(
          `The response of ${getContributorName(e.data.contributor)} is not currently in a reviewable state.`,
          'warning'
        );
      }
    },
    [navigate, showNotification]
  );

  const gridOptions: GridOptions = useMemo(
    () => ({
      defaultColDef,
      getRowId,
      components,
      rowData: submissionDetails,
      onRowClicked: isSurveyPerAccountType ? handleRowClickPerAccountType : handleRowClickIndividualType,
      pagination: false,
      columnDefs: [
        {
          headerName: 'Name',
          valueGetter: ({ data: { contributor } }) => getContributorName(contributor),
          sort: 'asc' as const,
          comparator: alphabetiseStrings,
          flex: 100,
        },
        {
          headerName: 'Contributor type',
          valueGetter: ({ data: { contributor } }) => (contributor.user ? 'Contributor' : 'Child account'),
          flex: 100,
        },
        {
          headerName: 'Response status',
          cellRenderer: 'status',
          flex: 100,
        },
        {
          headerName: 'Performance',
          cellRenderer: 'performance',
          flex: 100,
          hide: !showPerformance,
        },
        isSurveyPerAccountType
          ? {
              headerName: 'Action',
              flex: 100,
              cellRenderer: 'action',
            }
          : {
              headerName: 'Chevron',
              cellRenderer: 'chevron',
              flex: 1,
            },
      ],
    }),
    [
      submissionDetails,
      isSurveyPerAccountType,
      handleRowClickPerAccountType,
      handleRowClickIndividualType,
      showPerformance,
    ]
  );

  return (
    <>
      <AgGridStyled gridKey="surveyContributorsResponseGridV2" gridOptions={gridOptions} />
      <SurveyLockWarningModal
        open={Boolean(lockWarningUser)}
        onClose={() => setLockWarningUser(null)}
        lockedBy={lockWarningUser}
      />
    </>
  );
};
