import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { TransactionType } from '@rio/rio-types';
import * as _ from 'lodash';
import { Row, Button } from 'rio-ui-components';

import PageHeader from '../../../components/PageHeader';
import withPermissions from '../../../hocs/withPermissions';
import { useCurrentAccount } from '~/hooks';
import { GET_OCR_DATA } from '../OcrReviewContainer/index.queries';
import { ClearButton } from '../../../components/ClearButton';
import { DromoUploaderButton } from '../DataOverview/DromoUploader';
import NotificationBadge from './NotificationBadge';
import { useLocation } from 'react-router-dom';

const UploadButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const NotfifcationButtonContainer = styled.div`
  position: relative;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ControlsStyled = styled(Row)`
  flex: 0 1 auto;
`;

function getUploadsTitle(transactionType: TransactionType) {
  switch (transactionType) {
    case TransactionType.Hotelstays:
      return 'Hotel Stays';
    case TransactionType.Heatsteam:
      return 'Heat and Steam';
    default:
      return _.capitalize(transactionType);
  }
}

const UploadsPageHeader = (props) => {
  const location = useLocation();
  const isV2 = useMemo(() => location.pathname.startsWith('/v2'), [location]);
  const {
    dataSection,
    onFileUploadClick = () => {},
    onDirectUploadClick = () => {},
    permissions,
    onOcrClick,
    allowOcr,
    gridApi,
    isDirectActivityValue,
  } = props;

  const title = getUploadsTitle(dataSection);
  const currentAccount = useCurrentAccount();
  const accountId = currentAccount?.id;
  const { data } = useQuery(GET_OCR_DATA, {
    variables: { accountId, utility: title.toUpperCase() },
    pollInterval: 3000,
    skip: !allowOcr,
  });
  const toReviewCount = data?.getOcrDataForAccount.length;

  const allUploadsAvailable = [
    TransactionType.Waste,
    TransactionType.Electricity,
    TransactionType.Gas,
    TransactionType.Transport,
    TransactionType.Water,
    TransactionType.Heatsteam,
    TransactionType.Hotelstays,
  ];
  const massUploadsAvailable = [TransactionType.Fuel, TransactionType.Materials];
  const directUploadsAvailable = [];
  const dromoUploadsAvailable = [
    TransactionType.Refrigerant,
    TransactionType.Hotelstays,
    TransactionType.Electricity,
    TransactionType.Gas,
    TransactionType.Heatsteam,
    TransactionType.Materials,
    TransactionType.Water,
    TransactionType.Transport,
    TransactionType.Fuel,
    TransactionType.Waste,
    TransactionType.Investment,
  ];

  const uploadAllowedForRole = permissions.data.find((a) => a.startsWith('createImportBatch'));

  const enableDromoUpload = currentAccount?.accessControls?.dromoUpload || false;
  const allowUploadDromo = dromoUploadsAvailable.includes(dataSection) && enableDromoUpload && uploadAllowedForRole;
  const isDirectUploadsAvailable = [...allUploadsAvailable, ...directUploadsAvailable].includes(dataSection);
  const isMassUploadsAvailable =
    [...allUploadsAvailable, ...massUploadsAvailable].includes(dataSection) &&
    !allowUploadDromo &&
    uploadAllowedForRole;

  const formattedTitle = `${title} Data Uploads`;

  const breadcrumbs = useMemo(() => [{ title: 'Data', to: '..' }, { title: formattedTitle }], [formattedTitle]);

  const getLinkSlug = (transactionType: TransactionType): string => {
    switch (transactionType) {
      case TransactionType.Refrigerant:
        return 'refrigerants';
      case TransactionType.Heatsteam:
        return 'heatSteam';
      case TransactionType.Hotelstays:
        return 'hotelStays';
      default:
        return transactionType.toLowerCase();
    }
  };

  return (
    <PageHeader
      name="Uploads"
      title={formattedTitle}
      breadcrumbs={breadcrumbs}
      icon="chart-line"
      iconColor="quaternary"
    >
      <ControlsStyled container distribution="center" vdistribution="center" itemAlign="center">
        <ClearButton inline gridApi={gridApi?.api} columnApi={gridApi?.columnApi} />
        <UploadButtonStyled inline component="routerLink" color="info" to={`../${getLinkSlug(dataSection)}/view`}>
          View {title} Transactions
        </UploadButtonStyled>
        {allowOcr && !!permissions.ocr.find((action) => action.startsWith('getData')) && (
          <NotfifcationButtonContainer>
            <Button inline component="routerLink" color="info" to="ocr">
              Review OCR
            </Button>
            {!!toReviewCount && toReviewCount > 0 && <NotificationBadge count={toReviewCount} />}
          </NotfifcationButtonContainer>
        )}
        {!isV2 &&
          !!permissions.data.find((action) => action.startsWith('createImportBatch')) &&
          isDirectUploadsAvailable && (
            <UploadButtonStyled
              inline
              name="DataUploadContainer__directDataUploadButton"
              component="button"
              onClick={onDirectUploadClick}
            >
              Direct Data Upload
            </UploadButtonStyled>
          )}
        {allowOcr && !!permissions.ocr.find((action) => action.startsWith('uploadDocument')) && (
          <UploadButtonStyled
            inline
            name="DataUploadContainer__uploadInvoiceButton"
            component="button"
            onClick={onOcrClick}
          >
            Upload Invoice
          </UploadButtonStyled>
        )}
        {allowUploadDromo && (
          <DromoUploaderButton
            transactionType={dataSection}
            isDirectActivity={isDirectActivityValue}
            showDromoButton={allowUploadDromo}
          />
        )}
        {isMassUploadsAvailable && (
          <UploadButtonStyled
            inline
            name="DataUploadContainer__uploadButton"
            component="button"
            onClick={onFileUploadClick}
          >
            Upload Data
          </UploadButtonStyled>
        )}
      </ControlsStyled>
    </PageHeader>
  );
};

export default withPermissions(UploadsPageHeader);
