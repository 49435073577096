import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Query } from '@rio/rio-types';
import { GET_RECEIVED_SURVEY_BY_ID } from './queries';
import { useCurrentAccountId, usePageSuspendingQuery } from '../../../hooks';
import { Async } from '../../../components/Async';
import { NotFound } from '../../../components/Errors';
import { ReceivedSurvey } from './ReceivedSurvey';

type Response = Pick<Query, 'getReceivedSurveyById'>;

export const ReceivedSurveyContainer = memo(function ReceivedSurveyContainer() {
  const { surveyId } = useParams();
  const accountId = useCurrentAccountId();
  const queryResult = usePageSuspendingQuery(GET_RECEIVED_SURVEY_BY_ID, {
    variables: {
      id: surveyId,
      accountId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const renderData = useCallback(
    ({ getReceivedSurveyById }: Response) =>
      getReceivedSurveyById ? <ReceivedSurvey survey={getReceivedSurveyById} /> : <NotFound />,
    []
  );

  return <Async<Response> result={queryResult} data={renderData} />;
});
