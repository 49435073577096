import { Query, QueryGetSurveySubmissionByIdArgs as Variables } from '@rio/rio-types';
import { useQuery } from '@apollo/client';
import { GET_MY_CONTRIBUTOR_SUBMISSION } from './queries';

export type GetContributorSubmissionResponse = Pick<Query, 'getSurveySubmissionById'>;

export function useGetMyContributorSubmission(id: string) {
  return useQuery<GetContributorSubmissionResponse, Variables>(GET_MY_CONTRIBUTOR_SUBMISSION, {
    variables: {
      id,
    },
  });
}
