import { useQuery } from '@apollo/client';
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_CURRENCY_TYPES } from './index.queries';
import { toSelectOptions, namedEntityToSelectOption } from '../../utils';
import { SelectEvent, Nullable, Option, MultiSelectEvent } from '../../types';

interface CurrencySingleSelectProps {
  isMulti?: false;
  onChange: (e: SelectEvent) => void;
  name?: string;
  value?: Nullable<string | Option>;
  currencyId?: string;
  isDisabled?: boolean;
}
interface CurrencyMultiSelectProp {
  isMulti: true;
  onChange: (e: MultiSelectEvent) => void;
  name?: string;
  value?: Nullable<string[] | Option[]>;
  currencyId?: string;
  isDisabled?: boolean;
}
type SelectProps = CurrencySingleSelectProps | CurrencyMultiSelectProp;

export function CurrencySelect({ name, value, onChange, isMulti, currencyId, isDisabled }: SelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllCurrencyTypes'>>(GET_ALL_CURRENCY_TYPES);

  const allCurrencies = data?.getAllCurrencyTypes || [];
  const currency = allCurrencies.find((currency) => currency.id === currencyId);
  const currencyValue = currency && namedEntityToSelectOption(currency);

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && data && (
        <Select
          name={name}
          placeholder="Please select currency"
          isMulti={isMulti}
          value={value || currencyValue}
          options={toSelectOptions(data?.getAllCurrencyTypes)}
          error={error}
          isLoading={loading}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}
