import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Tour } from 'rio-ui-components';

// eslint-disable-next-line
export function withTour(ComponentToWrap, tourSection, options = {}, steps, userName = '') {
  class HOC extends Component {
    state = {
      runTour: true
    };

    updateTourStatus = (visited) => {
      const tourStatus = this.getTourStatus();
      tourStatus[tourSection] = visited;
      localStorage.setItem('TOUR_STATUS', JSON.stringify(tourStatus));
    };

    getTourStatus = () => {
      let tourStatusLocalStorage = localStorage.getItem('TOUR_STATUS');
      if (!tourStatusLocalStorage) {
        localStorage.setItem('TOUR_STATUS', `{"${tourSection}": false}`);
        tourStatusLocalStorage = localStorage.getItem('TOUR_STATUS');
      }
      return JSON.parse(tourStatusLocalStorage);
    };

    shouldDisplayTour = () => {
      return this.state.runTour && !this.getTourStatus()[tourSection];
    };

    joyrideHandleClose = (data) => {
      if (data.action === 'close') options.onFinish && options.onFinish(this);
      if (data.status === 'finished' || data.action === 'skip') this.updateTourStatus(true);
    };

    render() {
      const { intl } = this.props;
      const { joyrideOptions } = options;

      if (joyrideOptions) joyrideOptions.scrollToFirstStep = this.props.type === 'Explore';

      const joyrideProps = {
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        continuous: true,
        callback: this.joyrideHandleClose,
        locale: {
          close: intl.formatMessage({ id: options.closeLabel ? options.closeLabel : 'learnTour.letsGo' }),
          skip: intl.formatMessage({ id: 'learnTour.skip' }),
          next: intl.formatMessage({ id: 'learnTour.next' }),
          back: intl.formatMessage({ id: 'learnTour.back' })
        },
        ...joyrideOptions
      };
      const translatedSteps = (this.props.steps ? this.props.steps : steps).map((item) => ({
        ...item,
        title: intl.formatMessage({ id: item.title }, { name: userName }),
        content: intl.formatMessage({ id: item.content })
      }));

      return (
        <>
          <ComponentToWrap {...this.props} />
          <Tour
            id="TourGuide"
            name="TourGuide"
            run={this.shouldDisplayTour()}
            steps={translatedSteps}
            disableBeacon
            joyride={joyrideProps}
          />
        </>
      );
    }
  }

  return injectIntl(HOC);
}
