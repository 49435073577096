import { useQuery } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import { Query } from '@rio/rio-types';
import { GET_LOCATIONS_BY_ACCOUNT } from '../index.queries';
import { OptionType, MultiSelect } from '@rio/ui-components';

interface LocationsMultiSelectProps {
  accountId: string;
  value: OptionType[] | string[];
  onChange: (selectedLocations: OptionType[]) => void;
  error?: string;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
}

const LocationsMultiSelect: FC<LocationsMultiSelectProps> = ({
  accountId,
  value,
  onChange,
  error,
  name = 'locationIds',
  loading,
  label = 'Locations',
  disabled,
}) => {
  const {
    data,
    loading: loadingLocations,
    error: locationsLoadingError,
  } = useQuery<{ getLocationsPage: Query['getLocationsPage'] }>(GET_LOCATIONS_BY_ACCOUNT, {
    variables: {
      accountId,
      sort: [{ field: 'name', order: 'asc' }],
    },
  });

  const options = useMemo(
    () => data?.getLocationsPage?.rows.map((loc) => ({ value: loc.id, label: loc.name })) || [],
    [data]
  );
  const errorMessage = locationsLoadingError?.message || error || '';

  return (
    <MultiSelect
      name={name}
      label={label}
      isLoading={loadingLocations || loading}
      disabled={loading || disabled}
      onChange={onChange}
      value={value}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      options={options}
    />
  );
};

export { LocationsMultiSelect };
