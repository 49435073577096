import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { NamedEntity, Query, Scheme } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_SCHEMES_BY_ACCOUNT_ID } from '../../graphql/queries/scheme/GetSchemesByAccountId.query';
import { namedEntityToSelectOption } from '../../utils';
import { SelectEvent, Nullable } from '../../types';
import { SchemeOption } from '../../containers/ProjectContainer/ProjectView/types';

interface SchemeSelectProps {
  accountId: string;
  name?: string;
  value: Nullable<string | SchemeOption>;
  projectCategory?: Nullable<NamedEntity>;
  readOnly?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (e: SelectEvent) => void;
  className?: string;
  error?: string;
}

export function SchemeSelect({
  accountId,
  name,
  value,
  projectCategory,
  readOnly,
  isClearable,
  isDisabled,
  onChange,
  className,
  error
}: SchemeSelectProps) {
  const {
    data,
    loading,
    error: loadingError
  } = useQuery<Pick<Query, 'getProjectSchemes'>>(GET_SCHEMES_BY_ACCOUNT_ID, {
    variables: {
      accountId
    }
  });

  const schemeOptions = useMemo(() => {
    const schemes =
      (projectCategory
        ? data?.getProjectSchemes?.filter((scheme: Scheme) => scheme.category.id === projectCategory.id)
        : data?.getProjectSchemes) || [];
    return schemes.map(namedEntityToSelectOption);
  }, [projectCategory, loading]);

  return (
    <Select
      size="md"
      name={name || 'scheme'}
      placeholder="Please select scheme"
      classPrefix="scheme-select"
      value={value}
      error={error || loadingError}
      isLoading={loading}
      onChange={onChange}
      defaultOptions
      readOnly={readOnly || false}
      options={schemeOptions}
      isDisabled={isDisabled || loading}
      isClearable={isClearable || false}
      className={className}
    />
  );
}
