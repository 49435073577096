import React from 'react';
import { LearnContentType } from '@rio/rio-types';
import styled from 'styled-components';

import CourseRow from './CourseRow';
import { LinkRef } from '../../../../components/CustomRouteLink';

const ResultsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const CoursesList = ({ items, learnAccess, bundleId }) => {
  const isStatic = ['explore', 'continue', 'completed', 'all'].includes(bundleId);

  return (
    <ResultsContainer>
      {items.map((item) => {
        const id = isStatic ? item?.bundleIdList[0] : bundleId;
        const url = item.restricted
          ? '#'
          : `/engage/${id ? id + '/' : ''}course${item.type === LearnContentType.Scorm ? '/SCORM' : ''}/${item.id}`;

        if (learnAccess) {
          return (
            <LinkRef key={item.id} restricted={item.restricted} name={`CoursesList__Link__${item.id}`} to={url}>
              <CourseRow
                learnAccess={learnAccess}
                courseId={item.id}
                title={item.name}
                viewed={item.metaData.viewed}
                type={item.type}
                image={item.metaData.thumbnail}
                key={item.id}
                restricted={item.restricted}
              />
            </LinkRef>
          );
        } else {
          return (
            <CourseRow
              learnAccess={learnAccess}
              courseId={item.id}
              title={item.name}
              viewed={item.metaData.viewed}
              type={item.type}
              image={item.metaData.thumbnail}
              key={item.id}
              restricted={item.restricted}
            />
          );
        }
      })}
    </ResultsContainer>
  );
};

export default CoursesList;
