import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetById, GetSurveyResponse } from '../queries';
import { SurveyOverviewPage } from './SurveyOverviewPage';
import { Async } from '../../../components/Async';
import { useRoutes } from '~/hooks';

interface SurveyByIdRetrieverProps {
  surveyId: string;
}

export function SurveyByIdRetriever({ surveyId }: SurveyByIdRetrieverProps) {
  const navigate = useNavigate();
  const routes = useRoutes();

  const handleDelete = useCallback(() => {
    navigate(`/${routes.surveys.root}/${routes.surveys.sent.surveys}`);
  }, [navigate, routes]);

  const result = useGetById(surveyId);

  const renderData = useCallback(
    ({ getSurveyById }) => <SurveyOverviewPage survey={getSurveyById} onDelete={handleDelete} latest={false} />,
    [handleDelete]
  );

  return <Async<GetSurveyResponse> result={result} data={renderData} />;
}
