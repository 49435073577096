import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_TREATMENT_PROCESSES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface TreatmentProcessSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: string;
}
export function TreatmentProcessSelect({ value, onChange, error }: TreatmentProcessSelectProps) {
  const {
    data,
    loading,
    error: queryError
  } = useQuery<Pick<Query, 'getAllWasteTreatmentProcesses'>>(GET_TREATMENT_PROCESSES);
  return (
    <Select
      placeholder="Please select treatment process"
      value={value}
      options={toSelectOptions(data?.getAllWasteTreatmentProcesses)}
      error={error || queryError}
      isLoading={loading}
      onChange={onChange}
    />
  );
}
