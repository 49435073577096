import { gql } from '@apollo/client';

const CONTRIBUTOR_SUBMISSION_FRAGMENT = gql`
  fragment ContributorSubmissionFields on SurveySubmission {
    id
    status
    submittedDate
    owner {
      id
      name
    }
    survey {
      id
      name
      feedbackEnabled
      template {
        id
        formContent
        questions {
          id
        }
      }
    }
    version
    answers {
      id
      answer
      question {
        id
        name
        type
      }
    }
  }
`;

export const GET_MY_CONTRIBUTOR_SUBMISSION = gql`
  query getMyContributorsSubmission($id: ID!) {
    getSurveySubmissionById(id: $id) {
      ...ContributorSubmissionFields
    }
  }
  ${CONTRIBUTOR_SUBMISSION_FRAGMENT}
`;

export const SEND_FEEDBACK_ON_SURVEY_SUBMISSION = gql`
  mutation SendFeedbackOnSurveySubmission(
    $submissionId: ID!
    $status: SurveySubmissionFeedbackStatus!
    $feedback: String
  ) {
    sendFeedbackOnSurveySubmission(submissionId: $submissionId, status: $status, feedback: $feedback) {
      ...ContributorSubmissionFields
    }
  }
  ${CONTRIBUTOR_SUBMISSION_FRAGMENT}
`;
