import { Header, styled } from '@rio/ui-components';
import { useHeader } from './useHeader';

const FixedHeader = styled(Header)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  padding-left: 32px;
  padding-right: 132px; // width of side nav + normal padding
`;

export const HeaderContainer = () => {
  const { onProfileClick, onResetToursClick, onLogoutClick, accounts, onAccountChanged, currentAccountId, user } =
    useHeader();
  return (
    <FixedHeader
      onProfileClick={onProfileClick}
      onResetToursClick={onResetToursClick}
      onLogoutClick={onLogoutClick}
      user={user}
      accounts={accounts}
      onAccountChanged={onAccountChanged}
      selectedAccount={currentAccountId}
    />
  );
};
