import { Query } from '@rio/rio-types';
import { useQuery } from '@apollo/client';
import { useCurrentAccountId, usePageSuspendingQuery } from '~/hooks';
import GET_COURSE_PREVIEW from '../../../../graphql/queries/engage/GetCoursePreviewPage.query.graphql';
import GET_AVAILABLE_COURSES from '../../../../graphql/queries/engage/GetAvailableCourses.query.graphql';

export type Response = Pick<Query, 'getUserCourse'>;

type Variables = {
  accountId: string;
  courseId: string;
  userId: string;
  bundleId?: string;
};

type UseCoursePreviewPageArgs = {
  courseId: string;
  bundleId?: string;
  userId: string;
};

export function useCoursePreviewPage({ courseId, userId }: UseCoursePreviewPageArgs) {
  const accountId = useCurrentAccountId();

  const coursePreviewResult = usePageSuspendingQuery<Response, Variables>(GET_COURSE_PREVIEW, {
    variables: {
      accountId,
      courseId,
      userId,
    },
  });
  const getAvailableCoursesResult = useQuery(GET_AVAILABLE_COURSES, {
    variables: {
      accountId,
      pageNumber: 1,
      ownedCourses: true,
    },
  });
  return [coursePreviewResult, getAvailableCoursesResult];
}
