import { useObjectFilters } from '../../components/FiltersAndSorts/useFilters';

export default function useCategoriesFilters(params) {
  return useObjectFilters({
    activeFilter: null,
    previousFilter: null,
    filters: {
      categories: {
        name: 'Categories',
        options: params.options || [],
        applied: params.applied || [],
        values: params.values,
        addNullOption: true,
        showFilter: true
      }
    },
    nameFilter: 'categories'
  });
}
