/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery } from '@apollo/client';
import { QueryGetAuditByEntityIdArgs as Variables, Query } from '@rio/rio-types';
import { GET_AUDIT_BY_ENTITY_ID } from '../../graphql/queries/audit';

export type GetAuditByEntityIdResponse = Pick<Query, 'getAuditByEntityId'>;

export function useGetAuditByEntityId(entityId?: string) {
  return useQuery<GetAuditByEntityIdResponse, Variables>(GET_AUDIT_BY_ENTITY_ID, {
    fetchPolicy: 'network-only',
    variables: { entityId: entityId! },
    skip: !entityId,
  });
}
