/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { Col, Row, Button, Heading, Label, TextInput, Select, Modal, ScrollContainer } from 'rio-ui-components';
import { NamedEntity } from '@rio/rio-types';
import AccountSelector from '../../../components/AccountSelector';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { MetersMultiSelect } from '../../../components/MetersMultiSelect';
import { SelectSupplierEvent, SupplierSelector } from '../../../components/SuppliersSelector';
import { useNotification } from '../../../hooks';
import { SelectEvent, InputEvent, MultiSelectEvent } from '../../../types';
import { Option } from '../../../types';
import { GET_LOCATIONS_BY_ACCOUNT } from '../../../components/LocationsMultiSelect/index.queries';
import { toSelectOptions } from '../../../utils';
import { GET_SUPPLIER } from '../../../queries/suppliers';
import { CREATE_DATA_TRACKER_CONFIGURATION, UPDATE_DATA_TRACKER_CONFIGURATION } from './index.queries';
import { DataTrackerFormattedDetails } from './types';
import { prepareDataTrackerConfigInput, isTextEvent } from './utils';
import { ValidateDataTrackerConfig, ValidateErrors } from './Validate';
import { frequencyOptions } from './constants';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ModalContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const ScrollableContainerStyled = styled(ScrollContainer)`
  ${({ theme }) => `
    overflow: auto;
    padding: ${theme.geometry.xs.spacing};
  `}
`;

const queryVariables = {
  offset: 0,
  limit: 10,
  filters: {},
  sort: [{ field: 'name', order: 'asc' }],
};

interface DataTrackerConfigPopupProps {
  isSysAdmin: boolean;
  currentAccount: NamedEntity;
  dataTrackerConfigToUpdate?: DataTrackerFormattedDetails;
  refresh: () => void;
  dismiss: () => void;
}

const energyAndWaterUtilities = ['WATER', 'ELECTRICITY', 'GAS', 'HEATSTEAM'];

const DataTrackerConfigPopup = ({
  isSysAdmin,
  currentAccount,
  dataTrackerConfigToUpdate,
  refresh,
  dismiss,
}: DataTrackerConfigPopupProps) => {
  const client = useApolloClient();
  const initialAccount = isSysAdmin ? { id: null, name: null } : currentAccount;
  const [selectedAccount, setSelectedAccount] = useState({
    name: dataTrackerConfigToUpdate?.account?.label || initialAccount.name,
    id: dataTrackerConfigToUpdate?.account?.value || initialAccount.id,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidateErrors>({});
  const [selectedLocations, setSelectedLocations] = useState(dataTrackerConfigToUpdate?.locations || []);
  const [selectedMeters, setSelectedMeters] = useState(dataTrackerConfigToUpdate?.meters || []);
  const [dataTrackerConfig, setDataTrackerConfig] = useState(
    dataTrackerConfigToUpdate || {
      id: uuid(),
      account: isSysAdmin ? null : { value: currentAccount.id, label: currentAccount.name },
      supplier: null,
      frequency: null,
      recurrence: 0,
      locations: [],
      meters: [],
      supplierUtility: '',
    }
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await client.query({
        query: GET_SUPPLIER,
        variables: {
          id: dataTrackerConfigToUpdate?.supplier?.value,
        },
      });
      const { utility } = response.data?.getSupplier;
      setDataTrackerConfig({
        ...dataTrackerConfig,
        supplierUtility: utility,
      });
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTrackerConfigToUpdate]);

  useEffect(() => {
    const hasErrors = Object.values(validationErrors).some(Boolean);
    const hasInputs = Object.values(dataTrackerConfig).every(Boolean);
    setIsFormValid(!hasErrors && hasInputs);
  }, [validationErrors, dataTrackerConfig]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { showNotification } = useNotification();

  const { data } = useQuery(GET_LOCATIONS_BY_ACCOUNT, {
    variables: { ...queryVariables, accountId: selectedAccount.id },
  });
  const defaultLocations = toSelectOptions(data?.getLocationsPage?.rows);

  const [createOrUpdateDataTrackerConfig, { loading }] = useMutation(
    dataTrackerConfigToUpdate ? UPDATE_DATA_TRACKER_CONFIGURATION : CREATE_DATA_TRACKER_CONFIGURATION
  );

  const handleChange = useCallback(
    (event: SelectEvent | MultiSelectEvent) => {
      if (event.target.name === 'locationsSelect') {
        setSelectedLocations(event.target.select as Option[]);
        setSelectedMeters([]);
        setDataTrackerConfig({
          ...dataTrackerConfig,
          locations: event.target.select as Option[],
          meters: [],
        });
      } else if (event.target.name === 'meters') {
        setSelectedMeters(event.target.select as Option[]);
        setDataTrackerConfig({
          ...dataTrackerConfig,
          meters: event.target.select as Option[],
        });
      } else if (event.target.name === 'supplier') {
        setDataTrackerConfig({
          ...dataTrackerConfig,
          supplier: event.target.select as Option,
          // eslint-disable-next-line @typescript-eslint/dot-notation
          supplierUtility: (event as SelectSupplierEvent).entity.utility,
        });
        if (!energyAndWaterUtilities.includes((event as SelectSupplierEvent).entity.utility)) {
          setDataTrackerConfig({
            ...dataTrackerConfig,
            supplier: event.target.select as Option,
            // eslint-disable-next-line @typescript-eslint/dot-notation
            supplierUtility: (event as SelectSupplierEvent).entity.utility,
            meters: [],
          });
          setSelectedMeters([]);
        }
      } else {
        setDataTrackerConfig({
          ...dataTrackerConfig,
          [event.target.name]: !isTextEvent(event) ? event.target.select : event.target.value,
        });
      }
    },
    [dataTrackerConfig]
  );

  const handleInputChange = useCallback(
    (event: InputEvent) => {
      const newState = {
        ...dataTrackerConfig,
        [event.target.name]: event.target.value,
      };
      setDataTrackerConfig(newState);
      setValidationErrors(ValidateDataTrackerConfig(newState));
    },
    [dataTrackerConfig]
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    const variables = {
      ...prepareDataTrackerConfigInput(dataTrackerConfig),
      accountId: dataTrackerConfig.account?.value || currentAccount.id,
    };

    try {
      await createOrUpdateDataTrackerConfig({
        variables: dataTrackerConfigToUpdate ? { ...variables, id: dataTrackerConfigToUpdate?.id } : variables,
      });
      showNotification(
        `Data Tracker configuration has been successfully ${dataTrackerConfigToUpdate ? 'updated' : 'created'}`,
        'success'
      );
      refresh();
    } catch (err) {
      showNotification('Something went wrong. If the problem persists, contact support', 'danger');
      console.error(err);
    } finally {
      dismiss();
    }
    setIsSubmitted(false);
  };

  const selectedAccountId = isSysAdmin ? selectedAccount.id : currentAccount.id;

  return (
    <Modal
      size="md"
      show
      loading={loading}
      dismissable
      name="ConfigurationSchemesContainer__Modal--update"
      onDismiss={dismiss}
    >
      <ModalContainerStyled>
        <HeaderStyled size="lg">
          {dataTrackerConfigToUpdate ? (
            <FormattedMessage id="pages.configuration.dataTracker.modal.updateHeader" />
          ) : (
            <FormattedMessage id="pages.configuration.dataTracker.modal.createHeader" />
          )}
        </HeaderStyled>
        <ScrollableContainerStyled>
          <form name="create-or-update-dataTrackerConfig__form">
            {isSysAdmin && (
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>
                      <FormattedMessage id="pages.configuration.dataTracker.modal.account" />
                    </LabelStyled>
                  </LabelContainerStyled>
                  <AccountSelector
                    data-cy="DataTrackerConfig__Account-name"
                    onChange={({ name, id }: { name: string; id: string }) => {
                      setSelectedAccount({ name, id });
                      setSelectedLocations([]);
                      setSelectedMeters([]);
                      setDataTrackerConfig({
                        ...dataTrackerConfig,
                        account: {
                          value: id,
                          label: name,
                        },
                        supplier: null,
                        locations: [],
                        meters: [],
                      });
                    }}
                    name="account"
                    value={selectedAccount.id}
                    error={validationErrors.account}
                  />
                </ColStyled>
              </Row>
            )}
            {selectedAccountId && (
              <>
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.supplier" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <SupplierSelector
                      data-cy="DataTrackerConfig__Supplier"
                      accountId={dataTrackerConfig?.account?.value || selectedAccountId!}
                      onChange={handleChange}
                      name="supplier"
                      value={dataTrackerConfig.supplier}
                      error={validationErrors.supplier}
                    />
                  </ColStyled>
                </Row>
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.frequency" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <Select
                      name="frequency"
                      classPrefix="frequency-select"
                      isDisabled={isSubmitted}
                      onChange={handleChange}
                      value={dataTrackerConfig.frequency}
                      options={frequencyOptions}
                      error={validationErrors.frequency}
                    />
                  </ColStyled>
                </Row>
                <Row container align="between" data-cy="ProjectDetails__Input-priority">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.recurrence" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <TextInput
                      data-cy="DataTrackerConfigDetails__Input-recurrence"
                      disabled={isSubmitted}
                      onChange={handleInputChange}
                      name="recurrence"
                      value={dataTrackerConfig.recurrence}
                      error={validationErrors.recurrence}
                      box
                    />
                  </ColStyled>
                </Row>
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.locations" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <LocationsMultiSelect
                      name="locationsSelect"
                      accountId={dataTrackerConfig?.account?.value || selectedAccountId!}
                      value={selectedLocations}
                      onChange={handleChange}
                      isSubmitting={isSubmitted}
                      disabled={isSubmitted}
                      defaultOptions={defaultLocations}
                      error={undefined}
                    />
                  </ColStyled>
                </Row>
                {energyAndWaterUtilities.includes(dataTrackerConfig.supplierUtility) && (
                  <Row container align="between">
                    <ColStyled item>
                      <LabelContainerStyled>
                        <LabelStyled>
                          <FormattedMessage id="pages.configuration.dataTracker.modal.meters" />
                        </LabelStyled>
                      </LabelContainerStyled>
                      <MetersMultiSelect
                        name="meters"
                        accountId={dataTrackerConfig?.account?.value || selectedAccountId!}
                        locationsToFilter={dataTrackerConfig.locations}
                        value={selectedMeters}
                        onChange={handleChange}
                        isSubmitting={isSubmitted}
                        disabled={isSubmitted}
                        defaultOptions={false}
                        isGenerationMeters={false}
                        error={undefined}
                      />
                    </ColStyled>
                  </Row>
                )}
              </>
            )}
          </form>
        </ScrollableContainerStyled>
        <Row container>
          <ColStyled item container>
            <Button
              name="create-or-update-dataTrackerConfig__button"
              color="success"
              onClick={(e: React.FormEvent) => {
                handleSubmit(e);
              }}
              disabled={loading || !isFormValid}
            >
              {dataTrackerConfigToUpdate ? (
                <FormattedMessage id="pages.configuration.dataTracker.modal.update" />
              ) : (
                <FormattedMessage id="pages.configuration.dataTracker.modal.create" />
              )}
            </Button>
          </ColStyled>
        </Row>
      </ModalContainerStyled>
    </Modal>
  );
};

export default DataTrackerConfigPopup;
