import { v4 as uuid } from 'uuid';
import get from 'lodash/get';
import {
  Aspect,
  Scope,
  Location,
  Tag,
  SetFilter,
  Filters,
  SortCommand,
  AspectInput,
  UserPermissions,
  Legislation,
  SearchFilter,
  AspectOptionType,
} from '@rio/rio-types';
import { ColumnState, SortModelItem } from 'ag-grid-community';
import { Nullable, Option, NamedEntity, SelectEventUnion, Theme } from '../../../types';
import { mapFilterModel, mapSortModel } from '../../../utils';
import { AspectFormState, AspectEvidenceNotes } from './types';

export const getScopeLevel = (scope: Scope) => {
  switch (scope) {
    case Scope.Location:
      return 'Location';
    case Scope.Tag:
      return 'Tag';
    case Scope.Account:
    default:
      return 'Organisation';
  }
};

export const getScopeLocations = (aspect: Aspect): string => {
  if (aspect.scope === Scope.Account) {
    return getScopeLevel(aspect.scope);
  }
  if (aspect.scope === Scope.Location) {
    const locationNames = aspect.locations?.map((location: Nullable<Location>) => location?.name) || [];
    return locationNames.filter(Boolean).join(', ');
  }
  return '';
};

export const getScopeTags = (aspect: Aspect): string => {
  if (aspect.scope === Scope.Account) {
    return getScopeLevel(aspect.scope);
  }
  if (aspect.scope === Scope.Tag) {
    const tagNames = aspect.tags?.map((tag: Nullable<Tag>) => tag?.tagName) || [];
    return tagNames.filter(Boolean).join(', ');
  }
  return '';
};

export const formatScope = (aspect: Nullable<Aspect>): Nullable<string> => {
  if (!aspect) {
    return null;
  }
  const scope = getScopeLevel(aspect.scope);

  if (aspect.scope === Scope.Location) {
    return `${scope}: ${getScopeLocations(aspect)}`;
  }
  if (aspect.scope === Scope.Tag) {
    return `${scope}: ${getScopeTags(aspect)}`;
  }
  return scope;
};

export const joinList = (list: Nullable<NamedEntity[]>) => {
  return list?.map((e: NamedEntity) => e.name).join(', ');
};

export const formatList = (list: Nullable<NamedEntity[]>) => {
  return list?.map((e: NamedEntity) => e.name).join(', ') || '-';
};

const relations = [
  'tags',
  'locations',
  'departments',
  'activity',
  'aspects',
  'impacts',
  'legislations',
  'emergencyConditions',
  'controlMeasures',
  'status',
];

export const getSearchFilter = (value: string): SearchFilter => {
  return {
    value,
    field: [
      'tags.name',
      'locations.name',
      'departments.name',
      'legislations.name',
      'activity.name',
      'aspects.name',
      'impacts.name',
      'emergencyConditions.name',
      'controlMeasures.name',
      'likelihoodNoControl',
      'severityNoControl',
      'likelihoodControl',
      'severityControl',
      'status.name',
    ],
  };
};

export const mapAspectFilterModel = (filterModel: object, searchValue: Nullable<string>): Filters => {
  const filters: Filters = mapFilterModel(filterModel);
  if (filters.set) {
    filters.set = filters.set.map((filter: SetFilter) =>
      relations.includes(filter.field) ? { ...filter, field: `${filter.field}.id` } : filter
    );
  }
  if (searchValue) {
    filters.search = getSearchFilter(searchValue);
  }
  return filters;
};

export const prepareMultiselectOptions = (options: NamedEntity[] = []) =>
  options ? options.map((option) => ({ label: option.name, value: option.id })) : [];

export const checkPermissions = (permissions: UserPermissions, action: string, isOwnResource?: boolean) =>
  !!permissions.governance?.find((a) => a?.startsWith(action) && !a.includes('Option') && !a.endsWith('Own')) ||
  (permissions.governance?.includes(`${action}Own`) && isOwnResource);

export const prepareOptions = <T>(options: T[], labelKey = 'name', valueKey = 'id'): (T & Option)[] =>
  options
    ?.filter(Boolean)
    .map((option) => ({ ...option, label: get(option, labelKey), value: get(option, valueKey) })) || [];

export const getAspectOptionsWithNew =
  (canCreate: boolean) =>
  (data: NamedEntity[] = []): Option[] => {
    const options: Option[] = data
      .map((aspectOption) => ({
        ...aspectOption,
        value: aspectOption.id,
        label: aspectOption.name,
      }))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    if (canCreate) {
      options.unshift({ value: 'NEW', label: 'Create New ...' });
    }
    return options;
  };

export const onSelectChange = (
  event: SelectEventUnion,
  onCreate: (e: SelectEventUnion) => void,
  onChange: (e: SelectEventUnion) => void
) => {
  const createNew = Array.isArray(event.target.select)
    ? event.target.select.find(({ value }) => value === 'NEW')
    : event.target.select?.value === 'NEW';

  if (createNew) {
    onCreate(event);
  } else {
    onChange(event);
  }
};

export const formatAspect = (values: Partial<Aspect>, accountId: string, token: { sub: string }): AspectFormState => ({
  id: values.id || uuid(),
  accountId,
  scope: values.scope,
  locationIds: prepareOptions<Location>(values.locations as Location[]),
  tagIds: prepareOptions<Tag>(values.tags as Tag[], 'tagName'),
  activity: values.activity?.id || null,
  departments: prepareMultiselectOptions(values.departments),
  aspects: prepareMultiselectOptions(values.aspects),
  impacts: prepareMultiselectOptions(values.impacts),
  legislationIds: prepareOptions<Legislation>(values.legislations as Legislation[], 'title'),
  likelihoodNoControl: String(values.likelihoodNoControl),
  severityNoControl: String(values.severityNoControl),
  emergencyConditions: prepareMultiselectOptions(values.emergencyConditions),
  controlMeasures: prepareMultiselectOptions(values.controlMeasures),
  likelihoodControl: String(values.likelihoodControl),
  severityControl: String(values.severityControl),
  reviewDate: values.reviewDate,
  status: values.status?.id,
  taskId: values.task?.id,
  authorId: values.author?.id || token.sub,
});

export const prepareAspectInput = (
  form: AspectFormState,
  evidenceNotes: AspectEvidenceNotes,
  isUpdate: boolean,
  isCopy: boolean,
  token: Nullable<{ sub: string }>
): AspectInput => {
  let createdDate;
  if (!isUpdate) createdDate = new Date().toISOString();
  return {
    ...form,
    authorId: (isCopy ? token?.sub : form.authorId) || '',
    scope: form.scope!,
    likelihoodNoControl: Number(form.likelihoodNoControl),
    likelihoodControl: Number(form.likelihoodControl),
    severityNoControl: Number(form.severityNoControl),
    severityControl: Number(form.severityControl),
    activity: form.activity || '',
    locationIds: form.locationIds?.map(({ value }) => value),
    tagIds: form.tagIds?.map(({ value }) => value),
    legislationIds: form.legislationIds?.map(({ value }) => value),
    impacts: form.impacts?.map(({ value }) => value) || [],
    aspects: form.aspects?.map(({ value }) => value) || [],
    emergencyConditions: form.emergencyConditions?.map(({ value }) => value) || [],
    controlMeasures: form.controlMeasures?.map(({ value }) => value) || [],
    departments: form.departments?.map(({ value }) => value),
    createdDate,
    editedDate: isUpdate ? new Date().toISOString() : null,
    editorId: isUpdate ? token?.sub : null,
    evidenceNotes,
  };
};

export function mapAspectSortModel(sortModel: Array<SortModelItem | ColumnState> = []) {
  return mapSortModel(sortModel).map((sort: SortCommand) => {
    if (relations.includes(sort.field)) {
      return { ...sort, field: `${sort.field}.name` };
    }
    return sort;
  });
}

export const getFilterFieldByOptionType = (optionType: AspectOptionType) => {
  const map = {
    [AspectOptionType.Activity]: 'activity.id',
    [AspectOptionType.Aspect]: 'aspects.id',
    [AspectOptionType.ControlMeasure]: 'controlMeasures.id',
    [AspectOptionType.EmergencyCondition]: 'emergencyConditions.id',
    [AspectOptionType.Impact]: 'impacts.id',
    [AspectOptionType.Status]: 'status.id',
  };
  return get(map, optionType);
};

export const determineStyles = (value: string, theme: Theme) => {
  const defaultStyles = { alignText: 'center' };
  if (value === 'Control measure(s) in place')
    return { ...defaultStyles, backgroundColor: theme.colors.success.dark.background };
  if (value === 'Opportunity for improvement')
    return { ...defaultStyles, backgroundColor: theme.colors.warning.dark.background };
  if (value === 'Risk identified') return { ...defaultStyles, backgroundColor: theme.colors.danger.dark.background };
  return defaultStyles;
};
