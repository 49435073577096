import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../../../../graphql/fragments/LocationFields.fragment';

export const GET_REFRIGERANT_TRANSACTIONS = gql`
  query GetRefrigerantTransactionsPage(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getRefrigerantTransactionsPage(
      accountId: $accountId
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        locationName
        location {
          ...LocationFields
        }
        quantityUnit {
          id
          name
        }
        locationCode
        locationEndDate
        gasType
        gasGroup
        date
        quantity
        accuracy
        carbonScope1
        tags
        dataProvider
        comment
        evidenceReference
        otherReference
        currency
        totalCost
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_REFRIGERANT_TRANSACTIONS_FILTER = gql`
  query GetRefrigerantTransactionsPageFilter($accountId: ID!, $field: String!) {
    getRefrigerantTransactionsPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
