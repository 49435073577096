import { gql } from '@apollo/client';

export const ANOMALY_THRESHOLD_FRAGMENT = gql`
  fragment AnomalyThresholdFields on AnomalyThreshold {
    id
    account {
      id
      name
    }
    thresholdPercentage
    utilityType {
      transactionType
      isDefault
    }
  }
`;

export const GET_ANOMALY_THRESHOLDS = gql`
  query GetAnomalyThresholds(
    $accountId: ID
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getAnomalyThresholds(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...AnomalyThresholdFields
      }
    }
  }
  ${ANOMALY_THRESHOLD_FRAGMENT}
`;
