import { useEffect, useState } from 'react';
import { Tabs, TabPanel } from 'rio-ui-components';
import { FormattedMessage } from 'react-intl';

import { InfoIcon } from '~/components/SvgIcons/Info';
import { useCurrentAccount } from '~/hooks';
import {
  MainContainer,
  HeaderContainer,
  TopInfoContainer,
  TabsContainer,
  TabsButtonContainer,
  TabsStyled,
} from './Elements';
import { UploadDataItems } from './UploadDataItems';
import { getUploadCurrentTabs, setUploadCurrentTabs, uploadTabs } from './utils/uploadTabs';

const UploadContainer = ({ activeAccessControls }: { activeAccessControls: string[] }) => {
  const tab = getUploadCurrentTabs();
  const account = useCurrentAccount();
  const isInvest = !account.accessControls.data.investment;
  const currentTab = !isInvest ? tab : uploadTabs.organization.value;
  const [tabIndex, setTabIndex] = useState(currentTab);

  const filteredTabs = isInvest ? [uploadTabs.organization] : Object.values(uploadTabs);

  const handlerChangeTab = (val: string) => {
    setTabIndex(val);
    return setUploadCurrentTabs(val);
  };

  useEffect(() => {
    if (isInvest) {
      setUploadCurrentTabs(uploadTabs.organization.value);
    }
  }, [isInvest]);

  return (
    <MainContainer>
      <TopInfoContainer>
        <HeaderContainer>
          <FormattedMessage id="pages.data.overview.uploadHeader" />
        </HeaderContainer>
      </TopInfoContainer>
      <TabsContainer>
        <TabsButtonContainer style={{ width: `${isInvest ? '112px' : '224px'}` }}>
          <Tabs variant="standard" value={tabIndex}>
            {filteredTabs.map(({ label, value }) => (
              <TabsStyled key={label} label={label} onClick={() => handlerChangeTab(value)} value={value} />
            ))}
          </Tabs>
        </TabsButtonContainer>
        <InfoIcon />
      </TabsContainer>

      <TabPanel value={tabIndex} index={uploadTabs.organization.value}>
        <UploadDataItems activeAccessControls={activeAccessControls} />
      </TabPanel>
      {!isInvest && (
        <TabPanel value={tabIndex} index={uploadTabs.portfolio.value}>
          <UploadDataItems isPortfolio />
        </TabPanel>
      )}
    </MainContainer>
  );
};

export default UploadContainer;
