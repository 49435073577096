import { useState, useCallback, useMemo } from 'react';
import { WasteStream } from '@rio/rio-types';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation, ApolloError, QueryOptions } from '@apollo/client';
import { styled, Checkbox, Grid, TextField, Select, OptionsProps, Button } from '@rio/ui-components';
import { useNotification } from '~/hooks';
import { wasteStreamToSelectOptions } from '~/components/WasteStreamSelect/WasteStreamSelectV2';
import { GET_ALL_EWCS, CREATE_WASTE_STREAM } from '../index.queries';

const GridStyled = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

type CreateWasteStreamProps = {
  accountId: string;
  onSuccess: (message: string, color: string, id: string, wasteStream: OptionsProps) => void;
  onError?: (text: string, color: string) => void;
  onUpdate?: (cache: string, data: WasteStream) => void;
  refetchQueries?: QueryOptions[];
};

export const CreateWasteStream = ({
  onSuccess,
  onError,
  onUpdate,
  refetchQueries,
  accountId,
}: CreateWasteStreamProps) => {
  const { showNotification } = useNotification();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectValue, setSelectValue] = useState<OptionsProps | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_ALL_EWCS);

  const [createWasteStream] = useMutation(CREATE_WASTE_STREAM, {
    onCompleted: (data) => handleCreateComplete(data),
    onError: () => handleCreateError(),
    update: (cache, data) => handleCreateUpdate(cache, data),
    refetchQueries,
  });

  const handleCreateComplete = (data) => {
    if (onSuccess) {
      onSuccess(
        'Waste Stream created successfully',
        'success',
        data.createWasteStream.id,
        wasteStreamToSelectOptions(data.createWasteStream)
      );
    }
  };

  const handleCreateError = () => {
    showNotification('There was an error creating the waste stream');
    setIsSubmitted(false);
    if (onError) {
      onError('Something went wrong. If the problem continues please contact support.', 'danger');
    }
  };

  const handleCreateUpdate = useCallback(
    (cache, data) => {
      if (onUpdate) {
        onUpdate(cache, data);
      }
    },
    [onUpdate]
  );

  const ewcs = useMemo(
    () =>
      data?.getAllEWCs?.map((v) => ({
        label: `${v.code} - ${v.description}`,
        value: v.id,
        hazardous: v.hazardous,
      })) || [],
    [data?.getAllEWCs]
  );

  const handlerChange = useCallback(() => {
    createWasteStream({
      variables: {
        id: uuidv4(),
        name: inputValue,
        ewcId: selectValue?.value,
        accId: accountId,
        hazardous: isChecked,
      },
    });
    setIsSubmitted(true);
  }, [accountId, createWasteStream, inputValue, isChecked, selectValue?.value]);

  const changeSelect = useCallback(
    (e) => {
      const ewc = ewcs.find((v) => v.value === e.value);
      setIsChecked(ewc?.hazardous || false);
      setSelectValue(e);
    },
    [ewcs]
  );

  return (
    <GridStyled container>
      <TextField
        label="Name"
        name="Waste_Stream_Name"
        disabled={isSubmitted}
        placeholder="Name"
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue ?? ''}
      />

      <Select
        label="EWC Code"
        name="Waste_Stream_EWC_Code"
        disabled={isSubmitted}
        options={ewcs}
        value={selectValue?.value || ''}
        error={!!error}
        helperText={(error as ApolloError)?.message || ''}
        onChange={changeSelect}
        isLoading={loading}
        placeholder={loading ? 'Loading' : 'Select EWC Code'}
      />

      <Checkbox label="Hazardous" name="Waste_Stream_IsHazardous" checked={isChecked} onChange={() => {}} />

      <Button
        name="Waste_Stream_Create"
        variant="filled"
        disabled={!data || !data.getAllEWCs || isSubmitted}
        onClick={handlerChange}
      >
        Create
      </Button>
    </GridStyled>
  );
};
