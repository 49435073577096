import jwt_decode from 'jwt-decode';

export const getUsersCurrentAccountId = () => {
  const selectedAccount = getCurrentAccountFromStorage();
  if (selectedAccount) {
    return selectedAccount;
  }
  const token = localStorage.getItem('ID_TOKEN');
  const decodedToken = token ? jwt_decode(token) : null;
  return decodedToken?.name || null;
};

export const getCurrentAccountFromStorage = () => {
  return localStorage.getItem('CURRENT_ACCOUNT') || null;
};

export const storeCurrentAccount = (account) => localStorage.setItem('CURRENT_ACCOUNT', account);
