import { gql } from '@apollo/client';

export const GET_ACCOUNT_INFO_FRAGMENT = gql`
  fragment getAccountInfoFragment on Account {
    id
    name
    type
    logoPath
    showChatBot
    accessControls {
      learn
      intelligence
      tasks
      projects
      surveys
      flows
      dataTracker
      dromoUpload
      v2Design
      comply {
        legislation
        tagLevelLegislation
        policies
        systems
        targets
        frameworks
        agreements
      }
      data {
        waste
        electricity
        gas
        water
        materials
        fuel
        transport
        refrigerants
        heatSteam
        buildingEnergyRatings
        hotelStays
        investment
      }
    }
    departments {
      id
      name
    }
    children {
      id
      name
      logoPath
    }
  }
`;

export const GET_ACCOUNT_INFO = gql`
  query GetAccountInfo($id: ID!) {
    getAccountInfo(id: $id) {
      ...getAccountInfoFragment
    }
  }
  ${GET_ACCOUNT_INFO_FRAGMENT}
`;
