import React from 'react';
import { LinkStyled } from './elements';

interface TariffReferenceProps {
  value: string;
  data: {
    tariffId: string;
  };
}

export const TariffReference = ({ value, data }: TariffReferenceProps) => {
  return value && data.tariffId ? <LinkStyled to={`/configuration/carbonconversionfactors/market?selectedRows=${data.tariffId}`}>{value}</LinkStyled> : '';
};
