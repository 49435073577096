import { Page } from './styles';
import { SurveysPlaceholder } from '../../../components/SurveysPlaceholder';
import { SurveyOverviewContainerHeader } from './SurveyOverviewContainerHeader';

export function SurveyOverviewContainerPlaceholder() {
  return (
    <Page>
      <SurveyOverviewContainerHeader />
      <SurveysPlaceholder
        title="You don't have any surveys yet"
        description="Surveys are a quick way to collect data and insights"
        icon={['rio', 'clipboard-check-custom']}
      />
    </Page>
  );
}
