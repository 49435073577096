import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Project } from '@rio/rio-types';
import { useNavigate } from 'react-router-dom';
import { styled, AgGrid } from '@rio/ui-components';
import { RowClickedEvent, GridReadyEvent } from 'ag-grid-community';
import { toSentenceCase } from '~/utils';
import { PriorityCell, DescriptionCell, OwnersCell, CategoryCell } from '../../../TasksContainer/GridCell/v2';
import { StartDateCell, CostCell } from '../../GridCell/v2';
import { defaultConfig, options } from './options';

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 268px); // header + page title height

  .ag-sort-indicator-icon {
    display: flex;
    align-items: center;
  }
`;

interface GridComponentProps {
  projects: Project[];
  showGoTo: boolean;
  onGridReady: (e: GridReadyEvent) => void;
}

export const GridComponent = (props: GridComponentProps) => {
  const navigate = useNavigate();
  const { projects, onGridReady } = props;
  const { formatMessage } = useIntl();
  const { columnDefs, ...agGridConfig } = options(formatMessage);

  const gridOptions = useMemo(() => {
    const components = {
      category: CategoryCell,
      costCell: CostCell,
      description: DescriptionCell,
      owners: OwnersCell,
      priority: PriorityCell,
      startDate: StartDateCell,
    };

    const onRowClicked = (e: RowClickedEvent) => {
      navigate(`./${e.data.id}`);
    };

    return {
      ...defaultConfig,
      ...agGridConfig,
      columnDefs: columnDefs?.map((d) => ({
        ...d,
        headerName: d.headerName ? toSentenceCase(d.headerName) : d.headerName,
      })),
      components,
      onGridReady,
      onRowClicked,
      rowData: projects,
    };
  }, [agGridConfig, columnDefs, navigate, onGridReady, projects]);

  return <AgGridStyled gridKey="projectsGridV2" gridOptions={gridOptions} />;
};
