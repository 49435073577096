import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled, { useTheme } from 'styled-components';
import { PropertySheet, Label, Icon, Col } from 'rio-ui-components';
import { RETURN_KEY } from '../../constants/keyCodes';

const LabelStyled = styled(Label)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const IconStyled = styled(Icon)`
  cursor: pointer;
`;
export function List({ label, items, onChange, error, placeholder }) {
  const theme = useTheme();
  const [newItem, setNewItem] = useState('');
  const [newItemError, setNewItemError] = useState(null);
  const submitNewItem = () => {
    if (!newItem) {
      return setNewItemError(`Please fill in the field`);
    }
    onChange(
      items.concat([
        {
          id: uuid(),
          text: newItem
        }
      ])
    );
    setNewItem('');
  };
  return (
    <>
      <LabelStyled>{label}</LabelStyled>
      <PropertySheet.Row>
        <PropertySheet.TextInputColumn
          value={newItem}
          error={newItemError || error}
          placeholder={placeholder || 'Item text'}
          onKeyPress={(e) => {
            if (e.key === RETURN_KEY) {
              submitNewItem();
            }
          }}
          onChange={(e) => {
            setNewItem(e.target.value);
          }}
        />
        <Col span={1} vdistribution="center" itemAlign="center" item container>
          <IconStyled
            icon="save"
            title="Add item"
            color={theme.colors.success.normal.background}
            onClick={() => {
              submitNewItem();
            }}
          />
        </Col>
      </PropertySheet.Row>
      {items.map((item) => (
        <PropertySheet.Row key={item.id}>
          <PropertySheet.TextInputColumn
            value={item.text}
            onChange={(e) => {
              onChange(
                items.map((i) =>
                  i.id === item.id
                    ? {
                        ...i,
                        text: e.target.value
                      }
                    : i
                )
              );
            }}
          />
          <Col span={1} vdistribution="center" itemAlign="center" item container>
            <IconStyled
              icon="trash-alt"
              title="Delete item"
              color={theme.colors.danger.normal.background}
              onClick={() => {
                onChange(items.filter((i) => i.id !== item.id));
              }}
            />
          </Col>
        </PropertySheet.Row>
      ))}
    </>
  );
}
