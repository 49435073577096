import styled from 'styled-components';
import { Col, Row, Label, TextLink } from 'rio-ui-components';
import { Nullable } from '../../types';

const Container = styled.div`
  height: 20px;
  width: 80%;
  background-color: #e0e0de;
  border-radius: 50px;
  margin: 10px;
`;

interface ProgressChartProps {
  completed: number;
}

interface ProgressChartRowProps {
  completed: number;
  name: string;
  link?: Nullable<string>;
}

export const ProgressBar = styled.div<{ completed: number }>`
  position: relative;
  height: 100%;
  width: ${(props) => props.completed || 0}%;
  background-color: ${({ theme }) => theme.colors.success.normal.background};
  border-radius: inherit;
  text-align: right;
  > label {
    margin-left: 10px;
  }
`;

export const ProgressChart = ({ completed = 0 }: ProgressChartProps) => {
  return (
    <Container>
      <ProgressBar completed={completed}>
        <Label color="dark">{`${completed || 0}%`}</Label>
      </ProgressBar>
    </Container>
  );
};

export const ProgressChartRow = ({ name, completed, link }: ProgressChartRowProps) => (
  <Row container item>
    <Col container item span={6} vdistribution="center" distribution="start">
      {link ? <TextLink component="routerLink" to={link}>{name}</TextLink> : <span>{name}</span>}
    </Col>
    <Col item span={6} vdistribution="center">
      <ProgressChart completed={completed} />
    </Col>
  </Row>
);
