import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';
import { TransactionType } from '@rio/rio-types';
import { GridOptions, SetFilterValuesFuncParams } from 'ag-grid-community';
import { formatDate, getAgRowId, gridFilterKeyCreator, gridFilterValueFormatter } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import GET_ELECTRICITY_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/electricity/GetElectricityTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';
import { UploadReferenceCell, FactorUsedCell, DocumentReference, TariffReference } from '../../CellRenderers';

export function useElectricityGridConfig(): GridOptions {
  const [query] = useLazyQuery(GET_ELECTRICITY_TRANSACTIONS_PAGE_FILTER);
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = useMemo(
    () => async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getEnergyTransactionsPageFilter: values },
        } = await query({
          variables: {
            accountId,
            field: params.colDef.colId || params.colDef.field,
            energyType: TransactionType.Electricity,
          },
        });

        return params.success(values);
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [showNotification, accountId, query]
  );
  const config: GridOptions = useMemo(
    () => ({
      rowSelection: 'multiple',
      getRowId: getAgRowId('id'),
      components: {
        uploadReference: UploadReferenceCell,
        documentReference: DocumentReference,
        tariffReference: TariffReference,
        factorReference: FactorUsedCell,
      },
      columnDefs: [
        {
          headerName: 'Meter Name',
          field: 'meterName',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Location',
          field: 'locationName',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,

            refreshValuesOnOpen: false,
          },
        },
        {
          headerName: 'Location Code',
          field: 'energySource.locationPoint.location.code',
          colId: 'locationCode',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        OccupationEndDateGridColumn,
        {
          headerName: 'Tags',
          field: 'tags',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Departments',
          field: 'departments',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Supplier',
          field: 'supplier',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Tariff',
          field: 'tariff',
          cellRenderer: 'tariffReference',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Start Date',
          field: 'startDate',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }) => formatDate(value),
          cellStyle: { textAlign: 'center' },
          initialSort: 'desc',
        },
        {
          headerName: 'Start Time',
          field: 'startTime',
          cellStyle: { textAlign: 'center' },
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }) => formatDate(value),
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'End Time',
          field: 'endTime',
          cellStyle: { textAlign: 'center' },
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Quantity (kWh)',
          field: 'quantity',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Act/Est',
          field: 'accuracy',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Charge/kWh',
          field: 'chargePerKilowatt',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Total Cost',
          field: 'totalCost',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Total Cost (VAT)',
          field: 'totalCostVat',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Currency Code',
          field: 'currency',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Scope 2 kgCO2e (Location Based)',
          field: 'carbonScope2',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 3 kgCO2e (Location Based)',
          field: 'carbonScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 2 kgCO2 (Location Based)',
          field: 'kgCO2Scope2',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 3 kgCO2 (Location Based)',
          field: 'kgCO2Scope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 2 kgCH4 (Location Based)',
          field: 'kgCH4Scope2',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 3 kgCH4 (Location Based)',
          field: 'kgCH4Scope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 2 kgN2O (Location Based)',
          field: 'kgN2OScope2',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 3 kgN2O (Location Based)',
          field: 'kgN2OScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
          initialHide: true,
        },
        {
          headerName: 'Scope 2 kgCO2e (Market Based)',
          field: 'marketBasedCarbonScope2',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 3 kgCO2e (Market Based)',
          field: 'marketBasedCarbonScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 3 (T&D) kgCO2e',
          field: 'tdScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 3 (WTT - generation) kgCO2e',
          field: 'wttGenScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Scope 3 (WTT - T&D)',
          field: 'wttTdScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Activity',
          headerTooltip:
            'This shows if the activity falls under your organisation’s direct (company owned emission sources) or indirect (non company owned, employee, contractor or third party emission sources) activities',
          field: 'activity',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Comment',
          field: 'comment',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Invoice Number',
          field: 'invoiceNumber',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
          cellRenderer: 'documentReference',
        },
        {
          headerName: 'Country',
          field: 'energySource.locationPoint.location.address.country.name',
          colId: 'country',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Region',
          field: 'energySource.locationPoint.location.region.name',
          colId: 'region',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Evidence Reference',
          field: 'evidenceReference',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
          cellRenderer: 'documentReference',
        },
        {
          headerName: 'Other Reference',
          field: 'otherReference',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
          cellRenderer: 'documentReference',
        },
        {
          headerName: 'Upload Reference',
          field: 'importBatchFileName',
          cellRenderer: 'uploadReference',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridFilterValueFormatter,
            keyCreator: gridFilterKeyCreator,
          },
        },
        {
          headerName: 'Factors used',
          headerTooltip:
            'The factor set applied to this transaction, click the link to see the factors for this year in more detail',
          field: 'reportingYearOfFactors',
          cellRenderer: 'factorReference',
          cellRendererParams: {
            to: '/configuration/carbonconversionfactors/location/electricity',
          },
          filter: false,
        },
      ],
    }),
    [getFilterValues]
  );
  return config;
}
