import { UserRole, MysqlUser } from '@rio/rio-types';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';
import { usePermissions, useUserToken } from '../../../hooks';
import checkPermissions from '../../../utils/checkUserPermissions';

const ButtonStyled = styled.button`
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: ${(props) => `0 ${props.theme.geometry.sm.spacing}`};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

export interface UserActionsProps {
  user: MysqlUser;
  onEdit: (user: MysqlUser) => void;
  onPasswordReset: (user: MysqlUser) => void;
}

export const UserActions = ({ user, onEdit, onPasswordReset }: UserActionsProps) => {
  const { token } = useUserToken();
  const permissions = usePermissions();
  const isSysAdmin = user.role === UserRole.Sysadmin;
  const isBusinessAdmin = user.role === UserRole.Businessadmin;
  const isOwn = user.id === token.sub;
  const canResetPassword =
    !user.isExternalUser &&
    checkPermissions(permissions.user, 'resetPassword', isOwn) &&
    ((!isSysAdmin && !isBusinessAdmin) || permissions.user.includes('resetPasswordAll') || isOwn);
  const canEditUser =
    checkPermissions(permissions.user, 'edit', isOwn) && (!isSysAdmin || permissions.user.includes('editAll'));

  return (
    <>
      {canEditUser && (
        <ButtonStyled aria-label="edit" onClick={() => onEdit(user)}>
          <Icon name={`user-row__edit--${user.id}`} icon="pencil-alt" />
        </ButtonStyled>
      )}
      {canResetPassword && (
        <ButtonStyled aria-label="reset password" onClick={() => onPasswordReset(user)}>
          <Icon name={`user-row__passwordReset--${user.id}`} icon="key" />
        </ButtonStyled>
      )}
    </>
  );
};
