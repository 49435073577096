import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Text, Heading } from 'rio-ui-components';
import { SET_PASSWORD, GET_LOGIN_ERROR_MESSAGE } from '../../queries/auth';
import { AuthPage, LoginError, Button, TextInput, Form, TermsAndConditionsCheckbox } from '../../components/AuthPage';
import { isInvalidPassword, getFromQueryString } from '../ResetPasswordContainer/utils';
import { SetPasswordIssue } from './SetPasswordIssue';

const SetPasswordContainer = (props) => {
  const location = useLocation();
  const email = getFromQueryString('email', location.search);
  const [state, setState] = useState({
    email: decodeURIComponent(email),
    oldPassword: { value: '', error: null, success: null },
    password1: { value: '', error: null, success: null },
    password2: { value: '', error: null, success: null },
    terms: { use: false, service: false },
  });

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: { ...prevState[event.target.name], value: event.target.value },
    }));
  };

  const handleSetPasswordButtonClick = (event, callback) => {
    event.preventDefault();

    if (!state.oldPassword.value) {
      return setState((prevState) => ({
        ...prevState,
        oldPassword: { ...prevState.oldPassword, error: 'Please enter temporary password (Check email).' },
      }));
    }

    const password1 = state.password1.value;
    const password2 = state.password2.value;

    if (password1 !== password2)
      return setState((prevState) => ({
        ...prevState,
        password2: { ...prevState.password2, error: 'Passwords do not match' },
      }));

    const invalidPassword = isInvalidPassword(password1);
    if (invalidPassword)
      return setState((prevState) => ({
        ...prevState,
        password1: { ...prevState.password1, error: invalidPassword },
        password2: { ...prevState.password2, error: null },
      }));

    callback({
      variables: {
        email: state.email,
        oldPassword: state.oldPassword.value,
        password: state.password1.value,
        user: state.user,
      },
    });
  };

  const handleUseCheckboxClick = () =>
    setState((prevState) => ({
      ...prevState,
      terms: { use: !prevState.terms.use, service: prevState.terms.service },
    }));

  const handleServiceCheckboxClick = () =>
    setState((prevState) => ({
      ...prevState,
      terms: { use: prevState.terms.use, service: !prevState.terms.service },
    }));

  const getSetPasswordErrorMessageId = (loginError) => {
    return {
      UserNotFoundException: 'login.setPassword.userNotFound',
      NotAuthorizedException: 'login.setPassword.incorrectPassword',
      UserDisabledException: 'login.main.userDisabled',
      GeneralError: 'login.setPassword.generalError',
    }[loginError];
  };

  const [setPassword] = useMutation(SET_PASSWORD);

  const {
    data: { loginError },
  } = useQuery(GET_LOGIN_ERROR_MESSAGE);

  return (
    <AuthPage>
      {loginError === 'NotAuthorizedException' ? (
        <SetPasswordIssue email={state.email} />
      ) : (
        <>
          <Heading size="lg">
            <FormattedMessage id="login.setPassword.title" />
          </Heading>
          <Text align="center">
            <FormattedMessage id="login.setPassword.subheading" />
          </Text>
          <Form name="set-password__form" onSubmit={(event) => handleSetPasswordButtonClick(event, setPassword)}>
            <TextInput box type="text" value={state.email} disabled />
            <TextInput
              name="oldPassword"
              error={state.oldPassword.error}
              box
              type="password"
              placeholder={props.intl.formatMessage({ id: 'login.setPassword.oldPassword' })}
              value={state.oldPassword.value}
              onChange={handleChange}
            />
            <TextInput
              name="password1"
              error={state.password1.error}
              box
              type="password"
              placeholder={props.intl.formatMessage({ id: 'login.setPassword.passwordPlaceholder' })}
              value={state.password1.value}
              onChange={handleChange}
            />
            <TextInput
              name="password2"
              error={state.password2.error}
              box
              type="password"
              placeholder={props.intl.formatMessage({ id: 'login.setPassword.confirmPasswordPlaceholder' })}
              value={state.password2.value}
              onChange={handleChange}
            />
            <TermsAndConditionsCheckbox
              terms="service"
              termsAgreement={state.terms.service}
              onChange={() => handleServiceCheckboxClick(state)}
            />
            <TermsAndConditionsCheckbox
              terms="use"
              termsAgreement={state.terms.use}
              onChange={() => handleUseCheckboxClick(state)}
            />
            <Button name="set-password-container__button" submit disabled={!(state.terms.service && state.terms.use)}>
              <FormattedMessage id="login.setPassword.submitButton" />
            </Button>
            {loginError && (
              <LoginError name="set-password-container__form-error" color="danger" size="sm" weight="bold">
                <FormattedMessage id={getSetPasswordErrorMessageId(loginError)} />
              </LoginError>
            )}
          </Form>
        </>
      )}
    </AuthPage>
  );
};

export default injectIntl(SetPasswordContainer);
