import groupBy from 'lodash/groupBy';

export function groupFactorsByType(factors) {
  const {
    GAS: gas = [],
    ELECTRICITY: electricity = [],
    WASTE: waste = [],
    WATER: water = [],
    TRANSPORT: transport = [],
    FUEL: fuel = []
  } = groupBy(factors, 'type');
  return {
    gas,
    electricity,
    waste,
    water,
    transport,
    fuel
  };
}
