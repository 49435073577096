import React from 'react';
import { Row, Col, Heading } from 'rio-ui-components';
import styled from 'styled-components';

const RowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing} ${props.theme.geometry.sm.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${(props) => `${props.theme.colors.danger.light.background}`};
`;

interface NoResultsProps {
  id?: string;
  name?: string;
  title: string;
}
const NoResults = ({ id, title }: NoResultsProps) => {
  return (
    <RowStyled name={`noresults-row--${id}`} container itemAlign="center">
      <Col container item>
        <Heading name={`noresults-row__title--${id}`} size="md">
          {title}
        </Heading>
      </Col>
    </RowStyled>
  );
};

export default NoResults;
