import { ScoreChart } from '../../../../../components/ScoreChart';
import { disclosureScores } from '../constants';

interface DisclosureScoreChartProps {
  currentScore?: {
    name: string;
    progress: number;
  };
}

export function CarbonDisclosureScoreChart({ currentScore }: DisclosureScoreChartProps) {
  return (
    <ScoreChart
      currentScore={currentScore}
      scores={disclosureScores.map((score) => ({
        name: score.name,
        description: score.level,
        color: score.color
      }))}
    />
  );
}
