import { gql } from '@apollo/client';

export const CARBON_CONVERSION_FACTOR = gql`
  fragment CarbonConversionFactorFields on CarbonConversionFactor {
    id
    startDate
    endDate
    reportingYear
    fromUnit
    toUnit
    value
    scope
    type
    reference
    note
  }
`;

export const GET_CARBON_CONVERSION_FACTORS_BY_ACCOUNT_ID = gql`
  query GetCarbonConversionFactorsByAccountId($accountId: ID!) {
    getCarbonConversionFactorsByAccountId(accountId: $accountId) {
      ...CarbonConversionFactorFields
      ... on WasteCarbonConversionFactor {
        ewcId
        defraEquivalent
      }
    }
    getGasConversionFactors {
      id
      reportingYear
      reference
      consumptionKgCo2e
      wttKgCo2e
      startDate
      endDate
    }
    getWaterConversionFactors {
      id
      reportingYear
      reference
      supplyKgCo2e
      treatmentKgCo2e
      startDate
      endDate
    }
    getElectricityConversionFactors {
      id
      reportingYear
      reference
      # CO2e
      genKgCo2e
      tdKgCo2e
      wttGenKgCo2e
      wttTdKgCo2e
      wttTradeKgCo2e
      # CO2
      genKgCo2
      tdKgCo2
      wttGenKgCo2
      wttTdKgCo2
      wttTradeKgCo2
      # CH4
      genKgCh4
      tdKgCh4
      wttGenKgCh4
      wttTdKgCh4
      wttTradeKgCh4
      # N2O
      genKgN2o
      tdKgN2o
      wttGenKgN2o
      wttTdKgN2o
      wttTradeKgN2o
      startDate
      endDate
    }
    getHeatConversionFactors {
      id
      reportingYear
      reference

      # CO2e
      genKgCo2e
      tdKgCo2e
      wttGenKgCo2e
      wttTdKgCo2e
      # CO2
      genKgCo2
      tdKgCo2
      # CH4
      genKgCh4
      tdKgCh4
      # N2O
      genKgN2o
      tdKgN2o

      startDate
      endDate
    }
    getRefrigerantConversionFactors {
      id

      kgCO2e

      reportingYear
      startDate
      endDate
      reference

      gasName
      gasGroupName
    }

    getMaterialConversionFactors {
      id
      material
      origin
      activity
      kgCO2e
      reportingYear
      reference
      startDate
      endDate
    }
  }
  ${CARBON_CONVERSION_FACTOR}
`;
