import { usePageSuspendingQuery } from '~/hooks';
import { QueryGetMySurveyContributorByIdArgs as Variables, Query } from '@rio/rio-types';
import { GET_MY_CONTRIBUTORS_OVERVIEW } from './queries';

type GetMyContributorOverviewResponse = Pick<Query, 'getMySurveyContributorById'>;

export const useGetMyContributor = (accountId: string, contributorAccountId: string, contributorUserId?: string) => {
  return usePageSuspendingQuery<GetMyContributorOverviewResponse, Variables>(GET_MY_CONTRIBUTORS_OVERVIEW, {
    variables: {
      accountId,
      contributorAccountId,
      contributorUserId,
    },
    fetchPolicy: 'cache-first',
    keepErrorAfterUnmount: true,
    skip: !contributorAccountId,
  });
};
