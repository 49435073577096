import { useState, useMemo, useCallback } from 'react';
import * as _ from 'lodash';
import { UserPermissions } from '@rio/rio-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Grid, Page, Text, Button } from '@rio/ui-components';
import { CLEAN } from '~/constants/antivirusStatuses';
import { ButtonGridColumn } from '~/components/TransactionGrid/style';
import { MAX_ITEMS_IN_MEMORY_LIMIT } from '~/constants';
import { useUserToken, usePermissions, useCurrentAccountId, useNotification, useSuppliers } from '~/hooks';
import { toDateTime } from '../../utils';
import { EditDocumentModal } from '../../DocumentModal/v2';
import { GET_DOCUMENT, DELETE_DOCUMENT_RECORD } from '../../index.queries';
import { fileListData } from './fileListData';
import { DocumentDeleteModal } from './DocumentDeleteModal';
import {
  TextTitle,
  GridStyled,
  GridWrapper,
  TextWrapper,
  FileIconStyle,
  SubTextWrapper,
  ButtonGridStyled,
} from './style';

const MAX_STRING_LENGTH = 50;

const checkPermissions = (permissions: UserPermissions, action: string, isOwnResource: boolean) =>
  !!permissions.document?.find((a) => a?.startsWith(action) && !a.endsWith('Own')) ||
  (permissions.document?.includes(`${action}Own`) && isOwnResource);

export const DocumentFile = () => {
  const { state: routeState } = useLocation();
  const { taskId } = routeState ? (routeState as Record<string, unknown>) : { taskId: null };
  const { projectId } = routeState ? (routeState as Record<string, unknown>) : { projectId: null };
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { id, library, type } = useParams();
  const { showNotification } = useNotification();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();

  const createBreadcrumbs = useCallback(() => {
    const crumbs = [{ label: 'Documents', to: '/documents' }];

    if (library) {
      crumbs.push({ label: `${_.capitalize(library)}`, to: `/documents/${library}` });
      crumbs.push({ label: `${_.startCase(type)}`, to: `/documents/${library}/${type}` });
    } else {
      crumbs.push({ label: 'All', to: `/documents/all` });
    }
    return crumbs;
  }, [library, type]);

  const [deleteDocumentRecord] = useMutation(DELETE_DOCUMENT_RECORD);

  const variables = useMemo(
    () => ({
      id,
      accountId,
    }),
    [accountId, id]
  );

  const { data, error, loading, refetch } = useQuery(GET_DOCUMENT, {
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const document = useMemo(() => (data && data.getDocumentRecordById ? data.getDocumentRecordById : {}), [data]);
  const isClean = document.antivirusStatus === CLEAN;
  const isReadOnly = !permissions.document.find((action: string) => action.startsWith('create'));
  const canDelete = checkPermissions(permissions, 'delete', document?.createdBy?.id === token.sub);

  const apolloClient = useApolloClient();
  const suppliers = useSuppliers({
    pageSize: MAX_ITEMS_IN_MEMORY_LIMIT,
  });
  const suppliersIndex = _.keyBy(suppliers, 'id');
  const supplier = suppliersIndex[document.supplierId];

  const onDelete = useCallback(async () => {
    try {
      await deleteDocumentRecord({ variables: { id } });
      navigate(-1);
      showNotification('Document has been successfully deleted', 'success');
    } catch (err) {
      showNotification('An Error has occurred', 'danger');
    } finally {
      setShowDeleteModal(false);
    }
  }, [deleteDocumentRecord, id, navigate, showNotification]);

  const goBack = useCallback(() => {
    const documentPath = library && type ? `/documents/${library}/${type}` : library ? `/documents/${library}` : '';
    const taskPath = taskId ? `/tasks/${taskId}` : '';
    const projectPath = projectId ? `/projects/${projectId}` : '';
    const fallbackPath = '/documents/all';

    const path = documentPath || taskPath || projectPath || fallbackPath;
    navigate(path);
  }, [library, type, taskId, projectId, navigate]);

  const pageTitle = useMemo(
    () => ({
      crumbs: createBreadcrumbs(),
      content: `${
        document.category || (routeState ? _.startCase((routeState as any).documentType) : _.startCase(type))
      } Document`,
      actionButton: (
        <ButtonGridStyled wrap="wrap" container xs={12} md={6}>
          <ButtonGridColumn md={2.5} xs={5.5}>
            <Button variant="filled" onClick={goBack}>
              Back
            </Button>
          </ButtonGridColumn>
          <ButtonGridColumn md={2.5} xs={5.5}>
            <Button
              variant="filled"
              disabled={!isClean}
              onClick={() => {
                if (isClean) {
                  window.open(document.link);
                }
              }}
            >
              Open
            </Button>
          </ButtonGridColumn>
          {!isReadOnly && (
            <ButtonGridColumn md={2.5} xs={5.5}>
              <Button variant="filled" onClick={() => setShowEditModal(true)}>
                Edit
              </Button>
            </ButtonGridColumn>
          )}
          {canDelete && (
            <ButtonGridColumn md={2.5} xs={5.5}>
              <Button variant="filled" onClick={() => setShowDeleteModal(true)}>
                Delete
              </Button>
            </ButtonGridColumn>
          )}
        </ButtonGridStyled>
      ),
    }),
    [createBreadcrumbs, document.category, document.link, routeState, type, goBack, isClean, isReadOnly, canDelete]
  );

  const renderFileDataList = useMemo(
    () =>
      fileListData(document, supplier).map((item) => {
        if (
          (!(document.type && Array.isArray(document.type) && !!document.type.length) && item.title === 'Type:') ||
          (!document.managementSystemCode && item.title === 'Management System Reference:')
        ) {
          return null;
        } else {
          return (
            <GridWrapper key={item.title} container>
              <TextTitle typescale="title" size="medium">
                {item.title}
              </TextTitle>
              <Text typescale="body" size="large">
                {item.value}
              </Text>
            </GridWrapper>
          );
        }
      }),
    [document, supplier]
  );

  return (
    <>
      <Page loading={loading} error={error} title={pageTitle}>
        <Grid container>
          <FileIconStyle />
          <TextWrapper>
            <Text typescale="headline" size="small">
              {_.truncate(document.fileName, {
                length: MAX_STRING_LENGTH,
              })}
            </Text>
            <SubTextWrapper>
              <Text typescale="title" size="medium">
                {document.category} |
              </Text>
              <Text typescale="body" size="large">
                Uploaded on {toDateTime(document.createdAt)}
              </Text>
            </SubTextWrapper>
          </TextWrapper>
        </Grid>

        <GridStyled container>{renderFileDataList}</GridStyled>
      </Page>

      <DocumentDeleteModal
        onDelete={onDelete}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />

      {showEditModal && (
        <EditDocumentModal
          document={document}
          onComplete={(updatedDocument) => {
            apolloClient.writeQuery({
              query: GET_DOCUMENT,
              variables,
              data: {
                getDocumentRecordById: {
                  ...updatedDocument,
                  updatedAt: new Date().toISOString(),
                  updatedBy: {
                    first_name: token.given_name,
                    last_name: token.family_name,
                    __typename: 'User',
                  },
                },
              },
            });
            setTimeout(() => refetch(variables), 500);
            setShowEditModal(false);
            showNotification('Document has been edited!', 'success');
          }}
          onError={(err) => {
            showNotification(err.message, 'danger');
          }}
          onDismiss={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};
