import React, { Component } from 'react';
import { Row, Col, Heading, ProfilePicture, Checkbox } from 'rio-ui-components';
import styled from 'styled-components';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

class SubscriptionRow extends Component {
  render() {
    const { managementSystemName, id, onCheckboxClick, checked } = this.props;
    return (
      <div key={id}>
        <SubscriptionRowStyled name={`mss-row--${id}`} container itemAlign="center">
          <SubscriptionRowPictureStyled name={`mss-row__profile-picture--${id}`} src={''} size="xs" accountName={managementSystemName} />

          <Col container item>
            <Heading inline name={`mss-row__name--${id}`} size="md">
              {managementSystemName}
            </Heading>
          </Col>

          <Checkbox id={`update-subscription-${id}`} name={id} onChange={onCheckboxClick} checked={checked} />
        </SubscriptionRowStyled>
      </div>
    );
  }
}

export default SubscriptionRow;
