import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Mutation } from '@apollo/client/react/components';
import { Button, Text, Heading, TextInput } from 'rio-ui-components';

import { FORGOT_PASSWORD } from '../../../queries/auth';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
`;
const TextInputStyled = styled(TextInput)`
  width: 100%;
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;
const ButtonStyled = styled(Button)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: { value: props.username || '', error: null },
      success: false
    };
  }

  handleChange = (event) => {
    this.setState({ username: { value: event.target.value } });
  };

  handleSendPasswordResetCode = (event, callback) => {
    if (this.state.username.value) {
      callback({ variables: { username: this.state.username.value } });
    }
  };

  render() {
    return (
      <Container name="forgot-password-container">
        {this.state.success && (
          <div>
            <HeaderStyled size="lg">
              <FormattedMessage id="login.forgotPassword.success.title" />
            </HeaderStyled>
            <Text align="center">
              <FormattedMessage id="login.forgotPassword.success.subheading" />
            </Text>
            <ButtonStyled
              name="forgot-password-container__go-to-resetbutton"
              component="routerLink"
              to={`/reset-password?username=${this.state.username.value}`}
            >
              <FormattedMessage id="login.forgotPassword.goToResetButton" />
            </ButtonStyled>
          </div>
        )}

        {!this.state.success && (
          <div>
            <HeaderStyled size="lg">
              <FormattedMessage id="login.forgotPassword.title" />
            </HeaderStyled>
            <Text align="center">
              <FormattedMessage id="login.forgotPassword.subheading" />
            </Text>
            <TextInputStyled
              name="username"
              error={this.state.username.error}
              box
              placeholder={this.props.intl.formatMessage({ id: 'login.forgotPassword.usernamePlaceholder' })}
              value={this.state.username.value}
              onChange={this.handleChange}
            />

            <Mutation
              mutation={FORGOT_PASSWORD}
              onCompleted={() => {
                this.setState({ success: true });
                console.log('==== COMPLETE ====');
              }}
            >
              {(forgotPassword) => (
                <ButtonStyled
                  name="forgot-password-container__button"
                  onClick={(event) => this.handleSendPasswordResetCode(event, forgotPassword)}
                >
                  <FormattedMessage id="login.forgotPassword.submitButton" />
                </ButtonStyled>
              )}
            </Mutation>
          </div>
        )}
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  username: PropTypes.string,
  errors: PropTypes.object
};

export default injectIntl(ForgotPassword);
