import { useApolloClient } from '@apollo/client';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import GET_WASTE_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/waste/GetWasteTransactionsPageFilter.query.graphql';
// import GET_WASTE_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/waste/GetWasteTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useWasteGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getWasteTransactionsPageFilter: values },
      } = await client.query({
        query: GET_WASTE_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };
  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Individual Collection Date',
        field: 'individualCollectionDate',
        valueFormatter: ({ value }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        headerTooltip: 'Individual Collection Date',
      },
      {
        headerName: 'Waste-Out Location',
        field: 'wasteOut',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location Code',
        field: 'locationCode',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Waste-In Location',
        field: 'wasteIn',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Waste Stream',
        field: 'wasteStream',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'EWC',
        field: 'ewc',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Waste Contractor',
        field: 'wasteContractor',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Waste Carrier',
        field: 'wasteCarrier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Treatment Process',
        field: 'treatmentProcess',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Number Of Collections',
        field: 'numberOfCollections',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Number Of Containers',
        field: 'numberOfContainers',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Container Type Size',
        field: 'containerTypeSize',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Unit Of Weight',
        field: 'quantityUnitName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Actual/Estimated',
        field: 'accuracy',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }) => (value === 'ACT' ? 'Actual' : 'Estimated'),
      },
      {
        headerName: 'Gate Fee',
        field: 'gateFee',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Management Fee',
        field: 'managementFee',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Bin Hire',
        field: 'binHire',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Bin Exchange Fee',
        field: 'binExchangeFee',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Transport Charge',
        field: 'transportCharge',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Lift Charge',
        field: 'liftCharge',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Equipment Hire',
        field: 'equipmentHire',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Labour Cost',
        field: 'labourCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Misc Cost',
        field: 'miscCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Misc Cost Rate',
        field: 'miscCostRate',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Rebate',
        field: 'rebate',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Penalty',
        field: 'penalty',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Cost (VAT)',
        field: 'totalCostVat',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Notes',
        field: 'notes',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Reference',
        field: 'reference',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Waste Transfer Note ID',
        field: 'wasteTransferNote',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Consignment Note (SEPA) ID',
        field: 'consignmentNoteId',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Consignment Note (EA) ID',
        field: 'consignmentNoteEA',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Sales Order ID',
        field: 'salesOrder',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Invoice Number',
        field: 'invoiceNumber',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Country',
        field: 'location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Carbon Emission Scope 3 (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmission',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
