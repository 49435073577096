import React from 'react';
import styled from 'styled-components';

import { CircleIcon, Heading, Text, Row, Col } from 'rio-ui-components';
import shineCss from '../../utils/shineCss';

const ContainerStyled = styled(Col)`
  opacity: ${(props) => (props.comingSoon ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const PlaceHolderContainerStyled = styled(Col)`
  position: relative;
  opacity: ${(props) => (props.comingSoon ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${(props) => props.theme.colors.neutral.normal.background};
  height: 200px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  ${shineCss}
`;

const HeadContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: 0;
  flex: 0 0 auto;
`;

const CircleIconStyled = styled(CircleIcon)`
  z-index: 100;
  background: #fff;
  display: inline-flex;
`;

const TextContainer = styled(Row)`
  z-index: 90;
  padding: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.xl.spacing};
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  margin-top: -${(props) => props.theme.geometry.lg.spacing};
`;

const ComingSoonStyled = styled.div`
  flex: 0 0 auto;
  opacity: 0.7;
  display: inline-block;
  background: ${(props) => props.theme.colors.overlay.dark.background};
  padding: ${(props) => props.theme.geometry.xs.spacing} ${(props) => props.theme.geometry.sm.spacing};
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;
const TitleCol = styled(Col)`
  flex-grow: 9;
`;
const StatusCol = styled(Col)`
  align-items: center;
  flex-grow: 3;
`;

export const Tile = ({ title, category, icon, iconColor, comingSoon, placeholder, status }) => {
  return (
    <>
      {placeholder ? (
        <PlaceHolderContainerStyled container item />
      ) : (
        <ContainerStyled container item comingSoon={comingSoon}>
          <HeadContainer container item distribution="between">
            <CircleIconStyled height="70px" size="lg" icon={icon} iconColor={iconColor} circleColor={iconColor} />
            <Heading inline size="md" color="normal" weight="light">
              {category}
            </Heading>
          </HeadContainer>

          <TextContainer container item vdistribution="between">
            <TitleCol span={status ? 9 : 12}>
              <Heading size="lg" color="dark" weight="light">
                {title}
              </Heading>
            </TitleCol>
            {status && (
              <StatusCol container span={3}>
                {status}
              </StatusCol>
            )}

            {comingSoon && (
              <ComingSoonStyled>
                <Text align="center" size="xs" color="white">
                  Coming soon
                </Text>
              </ComingSoonStyled>
            )}
          </TextContainer>
        </ContainerStyled>
      )}
    </>
  );
};
