import { get } from 'lodash';
import { DromoRecord, DromoRow, InfoMessage } from '../types';

// used to mark all the records as corrupted, to prevent user from finishing the import
export function corruptRecords(records: DromoRecord[]) {
  return records.map((record) => {
    const newRecord = { ...record };

    newRecord.row[Object.keys(newRecord.row)[0]].info = [
      {
        message: `There been an error while processing this row. Please contact support team via support@rio.ai to resolve this issue.`,
        level: 'error',
      },
    ];

    return newRecord;
  });
}

export function extractRecords(record: object, fields: string[]) {
  return fields.map((key: string) => {
    const field: DromoRow = get(record, key);
    return field.resultValue;
  });
}

export const setError = (error: InfoMessage, record: DromoRecord, fields: string[]): DromoRecord => {
  fields.forEach((field) => {
    record.row[field].info.push(error);
  });
  return record;
};

export const resetErrors = (record: DromoRecord, fields: string[]): DromoRecord => {
  fields.forEach((field) => {
    record.row[field].info = [];
  });
  return record;
};

export const fieldIsPresent = (fieldValue: any): boolean => {
  if (typeof fieldValue === 'string') {
    return !!(fieldValue as string).trim();
  }

  return !!fieldValue;
};
