import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Search, LoadingIndicator } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import SubscriptionRow from './SubscriptionRow';
import { useParams } from 'react-router-dom';
import {
  GET_ALL_MANAGEMENT_SYSTEMS,
  GET_MANAGEMENT_SYSTEM_SUBSCRIPTION,
  SET_MANAGEMENT_SYSTEM_SUBSCRIPTION
} from './index.queries';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const ManagementSystemSubscriptionSelector = () => {
  const [searchValue, setSearchValue] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [systems, setSystems] = useState([]);
  const { accountId } = useParams();

  const { data: allSystems, loading: systemsLoading } = useQuery(GET_ALL_MANAGEMENT_SYSTEMS);
  const { data: subData, loading: subsLoading } = useQuery(GET_MANAGEMENT_SYSTEM_SUBSCRIPTION, {
    variables: { accountId },
    fetchPolicy: 'network-only'
  });
  const [mutateSubs] = useMutation(SET_MANAGEMENT_SYSTEM_SUBSCRIPTION);

  useEffect(() => {
    if (subData) setSubscriptions(subData.getManagementSystemSubscription);
    if (allSystems) setSystems(allSystems.getAllManagementSystems);
  }, [allSystems, subData]);

  const onCheckboxClick = (id) => {
    let newSubs = [];
    if (subscriptions.includes(id)) {
      newSubs = subscriptions.filter((item) => item !== id);
    } else {
      newSubs = [...subscriptions, id];
    }
    setSubscriptions(newSubs);
    mutateSubs({ variables: { accountId, subscriptions: newSubs } });
  };

  return (
    <ContainerStyled>
      <Col name="ConfigurationMssContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationMssSubscriptionsContainer__Controls"
          icon="briefcase"
          iconColor="primary"
          title="Management Systems Subscriptions"
        >
          <Col container item>
            <Search
              name="ConfigurationMssSubscriptionsContainer__Controls__Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              hideButton
            />
          </Col>
        </ContainerHeader>
      </Col>
      {(systemsLoading || subsLoading) && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {!(systemsLoading || subsLoading) &&
        systems &&
        systems.map((system) => {
          return (
            <SubscriptionRow
              key={system.id}
              id={system.id}
              managementSystemName={system.title}
              onCheckboxClick={() => onCheckboxClick(system.id)}
              checked={subscriptions.includes(system.id)}
            />
          );
        })}
    </ContainerStyled>
  );
};

export default ManagementSystemSubscriptionSelector;
