import round from 'lodash/round';

export const gridConfig = {
  name: {
    columnName: 'Name',
    width: 4
  },
  volume: {
    columnName: 'Volume',
    width: 4,
    format(volume) {
      return volume === null ? '-' : round(volume, 4);
    }
  },
  unit: {
    columnName: 'Unit',
    width: 4
  }
};
