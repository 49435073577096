import { ApolloError } from "@apollo/client";

export function formatError(error: Error | ApolloError) {
  const graphQLError: ApolloError = error as ApolloError;

  if (graphQLError.graphQLErrors) {
    return `GraphQL error: ${error}
All errors: ${JSON.stringify(graphQLError.graphQLErrors)}
Network error: ${graphQLError.networkError}
Extra info: ${graphQLError.extraInfo || ''}
Stack: ${graphQLError.stack}`;
  }
  return `${error.message}
Stack: ${error.stack}`;
}
