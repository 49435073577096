import { useQuery } from '@apollo/client';
import { GET_DATA_IMPORT_BATCH_BY_ID } from '../AliasContainer/index.queries';
import { ValidationErrors } from './ValidationErrors';
import { LoadFailed, Text, styled, LinearProgress } from '@rio/ui-components';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;

const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
`;

const LinearProgressStyled = styled(LinearProgress)`
  width: 100%;
`;

type Props = {
  batchId: string;
  withTitle?: boolean;
};

export function BatchErrors({ batchId, withTitle = true }: Props) {
  const { data, error, loading, refetch } = useQuery(GET_DATA_IMPORT_BATCH_BY_ID, {
    variables: {
      id: batchId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { getDataImportBatchById: batch } = data || {};

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <LinearProgressStyled variant="indeterminate" />
        </LoadingContainer>
      )}
      {error && <LoadFailed title="Something went wrong" error={error} retry={refetch} />}
      {!loading && !error && batch && (
        <>
          {withTitle && (
            <Title typescale="title" size="medium">
              Batch Errors
            </Title>
          )}
          {batch.failedReason ? (
            <Text typescale="body" size="medium">
              {batch.failedReason}
            </Text>
          ) : (
            <ValidationErrors errors={batch.errors} errorsCount={batch.errorsCount} />
          )}
        </>
      )}
    </Container>
  );
}
