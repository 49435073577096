import { Button } from 'rio-ui-components';
import { GridApi, ColumnApi, Column } from 'ag-grid-community';
import { Nullable } from '../../types';

export interface ClearButtonProps {
  gridApi: Nullable<GridApi>;
  columnApi: Nullable<ColumnApi>;
  onClick?: Function;
  inline?: boolean;
  title?: string;
}

export const ClearButton = ({ gridApi, columnApi, onClick, inline, title }: ClearButtonProps) => (
  <Button
    name="button-clear-filters"
    color="info"
    onClick={() => {
      if (gridApi && columnApi) {
        gridApi.setFilterModel(null);
        columnApi.getColumns()?.forEach((column: Column) => columnApi?.setColumnVisible(column.getColId(), true));
      }
      if (onClick) {
        onClick();
      }
    }}
    disabled={!gridApi}
    inline={inline}
  >
    {title || 'Clear Filters'}
  </Button>
);
