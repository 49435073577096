import React, { createContext, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Col } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import { useCurrentAccountId, useRouteMatch, useRoutes } from '../../../hooks';
import { TabContent } from '../../../components/TabContent';
import { TabContentProps } from '../../../components/TabContent/TabContent';
import { groupFactorsByType } from './groupFactorsByType';
import { WasteCarbonConversionFactorsGrid } from './WasteCarbonConversionFactorsGrid';
import { TransportCarbonConversionFactorsGrid } from './transport';
import { FuelCarbonConversionFactorsGrid } from './fuel';
import { CommonFactorsDescription } from './FactorsDescriptions';
import { ElectricityConversionFactorGrid } from './ElectricityConversionFactorGrid';
import { WaterConversionFactorsGrid } from './WaterConversionFactorsGrid';
import { GasConversionFactorGrid } from './GasConversionFactorGrid';
import { HeatConversionFactorsGrid } from './HeatConversionFactorsGrid';
import { RefrigerantConversionFactorsGrid } from './RefrigerantConversionFactorsGrid';
import { useGetCarbonConversionFactors } from './useGetCarbonConversionFactors';
import { HotelStaysCarbonConversionFactorsGrid } from './hotel-stays';
import { MaterialConversionFactorsGrid } from './MaterialConversionFactorsGrid';

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

export const TabContentContext = createContext<Omit<TabContentProps, 'index'>>({
  error: undefined,
  loading: undefined,
  currentTab: undefined,
  retry: () => undefined,
});

export function LocationBasedFactors() {
  const accountId = useCurrentAccountId();
  const { data, loading, error, refetch } = useGetCarbonConversionFactors();
  const routes = useRoutes();
  const factors = groupFactorsByType(data ? data.getCarbonConversionFactorsByAccountId : []);
  const retry = useCallback(() => refetch({ accountId }), [accountId, refetch]);
  const routeMatch = useRouteMatch(Object.values(routes.configuration.factors));
  const currentTab = routeMatch || routes.configuration.factors.gas;
  const tabContentProps = {
    error,
    loading,
    currentTab,
    retry,
  };

  return (
    <Page>
      <ContainerHeader
        name="ConfigurationContractorsContainer__Controls"
        icon="recycle"
        iconColor="primary"
        title="Location Based Carbon Conversion Factors"
      />
      <Tabs variant="scrollable" scrollButtons="auto" value={currentTab}>
        <Tab
          label="Gas"
          value={routes.configuration.factors.gas}
          to={routes.configuration.factors.gas}
          component={Link}
        />
        <Tab
          label="Electricity"
          value={routes.configuration.factors.electricity}
          to={routes.configuration.factors.electricity}
          component={Link}
        />
        <Tab
          label="Water"
          value={routes.configuration.factors.water}
          to={routes.configuration.factors.water}
          component={Link}
        />
        <Tab
          label="Waste"
          value={routes.configuration.factors.waste}
          to={routes.configuration.factors.waste}
          component={Link}
        />
        <Tab
          label="Transport"
          value={routes.configuration.factors.transport}
          to={routes.configuration.factors.transport}
          component={Link}
        />
        <Tab
          label="Fuel"
          value={routes.configuration.factors.fuel}
          to={routes.configuration.factors.fuel}
          component={Link}
        />
        <Tab
          label="Heat & Steam"
          value={routes.configuration.factors.heat}
          to={routes.configuration.factors.heat}
          component={Link}
        />
        <Tab
          label="Refrigerants & Other gasses"
          value={routes.configuration.factors.refrigerant}
          to={routes.configuration.factors.refrigerant}
          component={Link}
        />
        <Tab
          label="Hotel stays & Home Working"
          value={routes.configuration.factors.hotelStays}
          to={routes.configuration.factors.hotelStays}
          component={Link}
        />
        <Tab
          label="Material"
          value={routes.configuration.factors.material}
          to={routes.configuration.factors.material}
          component={Link}
        />
      </Tabs>
      <TabContentContext.Provider value={tabContentProps}>
        <GasConversionFactorGrid factors={data?.getGasConversionFactors || []} />
        <ElectricityConversionFactorGrid factors={data?.getElectricityConversionFactors || []} />
        <WaterConversionFactorsGrid factors={data?.getWaterConversionFactors || []} />
        <TabContent {...tabContentProps} index={routes.configuration.factors.waste}>
          <WasteCarbonConversionFactorsGrid factors={factors.waste} />
          <CommonFactorsDescription />
        </TabContent>
        <TransportCarbonConversionFactorsGrid />
        <FuelCarbonConversionFactorsGrid />
        <HeatConversionFactorsGrid factors={data?.getHeatConversionFactors || []} />
        <RefrigerantConversionFactorsGrid factors={data?.getRefrigerantConversionFactors || []} />
        <HotelStaysCarbonConversionFactorsGrid />
        <MaterialConversionFactorsGrid factors={data?.getMaterialConversionFactors || []} />
      </TabContentContext.Provider>
    </Page>
  );
}
