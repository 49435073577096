import { Scope } from '@rio/rio-types';
import { AspectFormState, AspectFormErrors } from './types';

export function validate(aspect: AspectFormState): AspectFormErrors {
  const errors: AspectFormErrors = {};

  if (!aspect.scope) {
    errors.scope = 'Scope is required';
  }

  if (aspect.scope === Scope.Location && (!aspect.locationIds || !aspect.locationIds.length)) {
    errors.locationIds = 'Location is required';
  }

  if (aspect.scope === Scope.Tag && (!aspect.tagIds || !aspect.tagIds.length)) {
    errors.tagIds = 'Tag is required';
  }

  if (!aspect.activity) {
    errors.activity = 'Activity / Process is required';
  }

  if (!aspect.aspects || !aspect.aspects.length) {
    errors.aspects = 'Environmental Aspect is required';
  }

  if (!aspect.impacts || !aspect.impacts.length) {
    errors.impacts = 'Potential Environmental Impact is required';
  }

  if (!aspect.controlMeasures || !aspect.controlMeasures.length) {
    errors.controlMeasures = 'Relevant Conrol Measure is required';
  }

  return errors;
}
