import { ReceivedSurvey, SurveySubmissionStatus } from '@rio/rio-types';
import { styled } from '@rio/ui-components';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { formatSubmissionStatus } from '../../Surveys';
import { StatusSpan } from './StatusSpan';
import { SendFeedbackButtons } from './SendFeedbackButtons';

type SendFeedbackFunction = (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;

type SurveyInfoProps = {
  survey: ReceivedSurvey;
  readonly?: boolean;
  sendFeedback?: SendFeedbackFunction;
};

const Section = styled('section')<{ name: string }>`
  display: flex;
  flex-flow: column nowrap;
  padding: 8px 0;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.sys.color.onSecondary};
`;

const SectionRow = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const Completion = styled('span')<{ status?: SurveySubmissionStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SurveyInfo = (props: SurveyInfoProps) => {
  const { survey, readonly, sendFeedback } = props;

  return (
    <Section name="SubmissionInfo__Section">
      <SectionRow>
        <Completion status={survey.submission?.status}>
          <StatusSpan typescale="body" size="medium" status={survey.submission?.status}>
            {formatSubmissionStatus(survey.submission?.status)}
          </StatusSpan>
        </Completion>
      </SectionRow>
      {readonly && sendFeedback && survey.feedbackEnabled && survey.submission && (
        <SendFeedbackButtons submission={survey.submission} sendFeedback={sendFeedback} />
      )}
    </Section>
  );
};
