import { FC } from 'react';
import { TSlideProps } from './type';
import { Slide } from './styles';

const SliderItem: FC<TSlideProps> = ({ item, handlerClick }) => {
  const { accountId, thumbnail, url, type } = item;

  return (
    <Slide onClick={() => handlerClick(url, type)}>
      <img src={thumbnail} alt={`Banner ${accountId}`} />
    </Slide>
  );
};

export default SliderItem;
