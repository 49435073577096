import { styled } from '@rio/ui-components';
import { VirtuosoGrid } from 'react-virtuoso';

export const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled('div')`
  padding: 8px 0 16px;
  width: 100%;
`;

export const ListSurvey = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, minmax(10px, 1fr));
  gap: 20px;
  font-family: 'Plus Jakarta Sans';

  @media (min-width: 1367px) and (max-width: 1600px) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }

  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
`;

export const EllipsisMenu = styled('div')`
  position: absolute;
  top: 0;
  left: 16px;
  right: 4px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  font-family: ${({ theme }) => theme.sys.typescale.body.medium.family};
  font-size: 12px !important;
  line-height: 16px;

  span {
    top: 10px;
  }
`;

export const ListItemWrapper = styled('div')`
  width: 100%;
`;

export const Virtuoso = styled(VirtuosoGrid)`
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  height: 100%;

  & > div:first-child {
    position: static !important;
  }

  &::-webkit-scrollbar {
    display: none;
  },
`;
