import { gql } from '@apollo/client';

export const CREATE_ENERGY_SOURCES = gql`
  mutation CreateEnergySources($energySources: [EnergySourceInput!]!) {
    createEnergySources(energySources: $energySources) {
      id
      name
    }
  }
`;

export const UPDATE_ENERGY_SOURCE = gql`
  mutation UpdateEnergySource(
    $id: ID!
    $name: String!
    $meterId: String!
    $locationId: ID!
    $typeId: ID!
    $accountId: ID!
    $inOut: InOut
    $isGeneration: Boolean
    $meterCode: String
    $hardwareProviderName: String
    $operatorProviderName: String
    $startDate: String
    $endDate: String
  ) {
    updateEnergySource(
      input: {
        id: $id
        name: $name
        locationId: $locationId
        meterId: $meterId
        typeId: $typeId
        accountId: $accountId
        inOut: $inOut
        isGeneration: $isGeneration
        meterCode: $meterCode
        hardwareProviderName: $hardwareProviderName
        operatorProviderName: $operatorProviderName
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      id
      name
    }
  }
`;

export const GET_ENERGY_SOURCE_BY_ACC_ID = gql`
  query getEnergySourceByAccountIdAndType(
    $accountId: ID!
    $type: TransactionType
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getEnergySourceByAccountIdAndType(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        name
        id
        type {
          name
          id
        }
        locationPoint {
          name
          location {
            id
            name
          }
        }
        meterCode
        inOut
        isGeneration
        hardwareProviderName
        operatorProviderName
        startDate
        endDate
      }
    }
  }
`;

export const GET_ENERGY_SOURCE_BY_ID = gql`
  query GetEnergySourceById($id: ID!) {
    getEnergySourceById(id: $id) {
      name
      id
      inOut
      isGeneration
      type {
        id
        name
      }
      locationPoint {
        name
        location {
          id
          name
        }
      }
      meterCode
      hardwareProviderName
      operatorProviderName
      startDate
      endDate
    }
  }
`;

export const GET_LOCATIONS_BY_ACCOUNT = gql`
  query GetLocationsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getLocationsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ENERGY_TYPES = gql`
  query GetAllEnergyTypes {
    getAllEnergyType {
      id
      name
    }
  }
`;
