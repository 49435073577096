import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Row } from 'rio-ui-components';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { formatReviewDate } from '../../DocumentContainer/utils';
import { Grid as AgGrid } from '../../../components/UI';
import { calculateSignificanceControl, calculateSignificanceNoControl } from './utils';

const RowStyled = styled(Row)`
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const LegislationLink = styled(Link)`
  text-decoration: underline;
  margin-right: 10px;
  &:hover {
    text-decoration: none;
  }
`;

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const LegislationReference = (params) => {
  return params.data.legislations.length
    ? params.data.legislations.map((legislation) => (
        <LegislationLink key={legislation.id} to={`/governance/legislation/${legislation.id}`}>
          {legislation.title}
        </LegislationLink>
      ))
    : 'Not exist';
};

const ActionCell = ({ data: { id }, onEdit, onCopy }) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(id)}>
      <Icon name={`account-row__edit--${id}`} title="Edit" icon="pencil-alt" />
    </EditLinkStyled>
    <EditLinkStyled onClick={() => onCopy(id)}>
      <Icon name={`account-row__copy--${id}`} title="Duplicate" icon="copy" />
    </EditLinkStyled>
  </GridCellContainer>
);

export function Grid({ risks, showEditModal, showCopyModal, canEdit, currentAccount, agGrid }) {
  const navigate = useNavigate();
  const canEditRef = useRef();
  const currentAccountRef = useRef();
  currentAccountRef.current = currentAccount;
  canEditRef.current = canEdit;

  const columnDefs = [
    {
      headerName: 'Scope',
      field: 'scopeLevel',
      resizable: true,
    },
    {
      headerName: 'Scope (Tags)',
      field: 'scopeTags',
      resizable: true,
    },
    {
      headerName: 'Scope (Locations)',
      field: 'scopeLocations',
      resizable: true,
    },
    {
      headerName: 'Departments',
      field: 'departments',
      valueGetter: (params) => {
        const value = params.data.departments;

        if (!value) return null;
        return value.join(', ');
      },
      resizable: true,
      filterParams: {
        valueFormatter: (params) => {
          if (!params.value) return params.value;
          const department = find(currentAccountRef.current.departments, { id: params.value });
          return department ? department.name : null;
        },
      },
    },
    { headerName: 'Activity / Process', field: 'activity' },
    { headerName: 'Identified Hazards', field: 'hazzards' },
    { headerName: 'Who may be effected', field: 'whoIsEffected' },
    {
      headerName: 'Legislation Reference(s)',
      field: 'legislations',
      valueGetter: (params) =>
        params.data.legislations
          .filter(Boolean)
          .map(({ title }) => title)
          .join(',') || 'Not exist',
      cellRenderer: 'legislationReference',
    },
    {
      headerName: 'Likelihood (no control)',
      field: 'likelihoodNoControl',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Severity (no control)',
      field: 'severityNoControl',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Significance (no control)',
      valueGetter: (params) => calculateSignificanceNoControl(params.data),
      cellStyle: { textAlign: 'center' },
    },
    { headerName: 'Potential Emergency Condition(s)', field: 'emergencyConditions' },
    { headerName: 'Relevant Control Measure(s)', field: 'controlMeasures' },
    {
      headerName: 'Likelihood (with control)',
      field: 'likelihoodControl',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Severity (with control)',
      field: 'severityControl',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Significance (with control)',
      valueGetter: (params) => calculateSignificanceControl(params.data),
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Review Date',
      valueGetter: (params) => (params.data.reviewDate && formatReviewDate(params.data.reviewDate)) || '',
      cellStyle: { textAlign: 'center' },
      width: 140,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'notEqual',
          'inRange',
          {
            displayKey: 'emptyValue',
            displayName: 'Empty',
            filter: function (filterValue, cellValue) {
              if (cellValue === '') return true;
            },
          },
        ],
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ['reset', 'apply'],
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: '',
      cellRenderer: 'action',
      cellStyle: { textAlign: 'center' },
      width: 75,
      sortable: false,
      filter: false,
    },
  ];
  const location = useLocation();
  const hashLink = location.hash?.slice?.(1);

  const components = {
    legislationReference: LegislationReference,
    action: (props) => <ActionCell {...props} onEdit={showEditModal} onCopy={showCopyModal} />,
  };

  const linkedCells = ['action'];
  const onCellClicked = (e) => {
    if (!linkedCells.includes(e.colDef.cellRenderer)) {
      navigate(`/governance/health-and-safety-risk-register/${e.data.id}`);
    }
  };

  return (
    <RowStyled container item>
      <AgGrid
        defaultColDef={{ sortable: true, resizable: true, filter: true, wrapText: true, autoHeight: true }}
        columnDefs={columnDefs}
        rowData={risks}
        components={components}
        onFirstDataRendered={() => {
          if (hashLink) {
            agGrid.api.forEachNodeAfterFilter((node) => {
              if (node.data.id === hashLink) {
                node.setSelected(true);
              }
            });
          }
          agGrid.onFirstDataRendered();
        }}
        onCellClicked={onCellClicked}
        onGridReady={agGrid.onGridReady}
        onFilterChanged={agGrid.onFilterChanged}
        onSortChanged={agGrid.onSortChanged}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        rowSelection="single"
      />
    </RowStyled>
  );
}
