import styled from 'styled-components';

const Frame = styled.iframe`
  width: 100%;
  height: calc(100% - 5px);
`;

const ConfigurationFlowsContainer = () => {
  return <Frame id="flowsFrame" src={'/rio-flows/configuration/'}></Frame>;
};

export default ConfigurationFlowsContainer;
