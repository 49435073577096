import { useQuery, QueryHookOptions } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_REPORT_PROGRESS_GROUP_BREAKDOWN } from './index.queries';
import { useCurrentAccountId, useNotification } from '../../hooks';
import { Nullable } from '../../types';

export type QueryResponse = Pick<Query, 'getReportProgressGroupBreakdown'>;

export interface QueryVariables {
  accountId: string;
  frameworkId: string;
  groupId: Nullable<string>;
}

export function useGetReportProgressGroupBreakdown(
  frameworkId: string,
  groupId: Nullable<string>,
  queryOptions?: QueryHookOptions<QueryResponse, QueryVariables>
) {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  return useQuery<QueryResponse, QueryVariables>(GET_REPORT_PROGRESS_GROUP_BREAKDOWN, {
    ...queryOptions,
    variables: {
      accountId,
      frameworkId,
      groupId,
    },
    fetchPolicy: 'network-only',
    onError: (err: Error) => {
      showNotification(`Couldn't load report progress: ${err}`, 'danger');
    },
  });
}
