import Highcharts from 'highcharts';
import { baseChart } from './baseChart';

export const baseColumnChart: Highcharts.Options = {
  ...baseChart,
  chart: {
    ...baseChart.chart,
    type: 'column',
  },
  plotOptions: {
    column: {
      groupPadding: 0, // distribute columns evenly
      maxPointWidth: 70, // width of columns
    },
  },
};
