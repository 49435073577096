import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getEnvVar } from '../../../env';

const ChatBot = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ChatFrame = ({ accountId, kbId, accessToken, landbotId, fullName }) => {
  useEffect(() => {
    const chatBotId = kbId ? 'H-1220830-V6QWNXTLPPYJS8O3' : 'H-752857-L6DAXUHW9TJ2J7Q1';
    document.getElementById(
      'myLandbot'
    ).src = `https://chats.landbot.io/v3/${chatBotId}/index.html?accountid=${accountId}&jwt=${accessToken}&kbtoken=${kbId}&apistage=${getEnvVar(
      'REACT_APP_INTELLIGENCE_API_STAGE'
    )}&fullname=${fullName}`;
  }, [accountId, kbId, accessToken, landbotId, fullName]);

  return <ChatBot id="myLandbot"></ChatBot>;
};

export default ChatFrame;
