import { Select } from 'rio-ui-components';
import { SelectEvent } from '../../types';

const sizes = [10, 25, 50, 100, 200];

const options = sizes.map((s) => ({
  label: `${s} rows`,
  value: s.toString()
}));

interface PageSizeSelectProps {
  value: number;
  onChange: (size: number) => void;
}

export function PageSizeSelect({ value, onChange }: PageSizeSelectProps) {
  return (
    <Select
      placeholder="Show"
      value={value.toString()}
      options={options}
      onChange={(e: SelectEvent) => {
        const { value } = e.target.select;
        const number = Number(value);
        onChange(number);
      }}
    />
  );
}
