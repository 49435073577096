import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

export interface AutoSaveProps {
  newSubscriptionUpdate?: boolean;
}

const Container = styled.span`
  margin-right: 20px;
  color: ${(props) => props.theme.colors.text.light.text};
`;

export const AutoSave = () => (
  <Container>
    <Icon icon="check" size="md" />
    <span>Auto-save is on</span>
  </Container>
);
