import React from 'react';
import { Row, Col, Heading, ProfilePicture, Checkbox } from 'rio-ui-components';
import styled from 'styled-components';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const SubscriptionRow = ({ name, id, onCheckboxClick, checked }) => {
  return (
    <div key={id}>
      <SubscriptionRowStyled name={`kb-row--${id}`} container itemAlign="center">
        <SubscriptionRowPictureStyled name={`kb-row__profile-picture--${id}`} src={''} size="xs" accountName={name} />

        <Col container item>
          <Heading inline name={`account-row__name--${id}`} size="md">
            {name}
          </Heading>
        </Col>

        <Checkbox id={`update-subscription-${id}`} name={id} onChange={onCheckboxClick} checked={checked} />
      </SubscriptionRowStyled>
    </div>
  );
};

export default SubscriptionRow;
