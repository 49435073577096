/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { SurveyAuditLogGrid } from './SurveyAuditLogGrid';
import ContainerHeader from '../../../components/ContainerHeader';
import { useCurrentAccountId, useGetAuditByEntityId, GetAuditByEntityIdResponse } from '../../../hooks';
import { PageFailed } from '../../../components/Errors';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { Async } from '../../../components/Async';
import { SurveysPlaceholder } from '../../../components/SurveysPlaceholder';
import { useGetById as useGetSurveyById } from '../queries';
import { Page } from './styles';

const defaultBreadcrumbs = [
  { to: '../sent/overview', title: 'Surveys' },
  { to: '../sent/surveys', title: 'Sent surveys' },
];

const Placeholder = () => (
  <SurveysPlaceholder
    title="Contributors haven't interacted with this survey yet"
    description="Their interactions will be logged soon after they occur"
    icon={['rio', 'clipboard-check-custom']}
  />
);

export function SurveyAuditLogContainer() {
  const accountId = useCurrentAccountId();
  const { surveyId } = useParams();

  const { data: surveyData } = useGetSurveyById(surveyId!);
  const survey = surveyData?.getSurveyById;

  const result = useGetAuditByEntityId(surveyId!);

  const breadcrumbs = useMemo(() => {
    if (survey) {
      return [
        ...defaultBreadcrumbs,
        { to: `../sent/surveys/${survey.id}`, title: survey?.name },
        { title: 'Survey log' },
      ];
    }
    return defaultBreadcrumbs;
  }, [survey]);

  const renderData = useCallback(
    ({ getAuditByEntityId }) =>
      getAuditByEntityId.length ? <SurveyAuditLogGrid rows={getAuditByEntityId} /> : <Placeholder />,
    []
  );

  const renderError = useCallback(
    (error, { refetch }) => <PageFailed error={error} retry={() => refetch({ accountId })} />,
    [accountId]
  );

  const renderLoading = useCallback(() => <ContainerLoadingIndicator name="SurveyAuditLogContainer__Loading" />, []);

  return (
    <Page>
      <ContainerHeader
        name="SurveyAuditLog"
        icon="clipboard-check"
        iconColor="quaternary"
        title="Survey log"
        breadcrumb={breadcrumbs}
      />
      <Async<GetAuditByEntityIdResponse>
        result={result}
        data={renderData}
        error={renderError}
        loading={renderLoading}
      />
    </Page>
  );
}
