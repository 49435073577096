import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import _ from 'lodash';
import { DataType } from '@rio/rio-types';
import { MassUploadModal } from '../../../components/MassUploadModal';
import { GET_LOCATIONS_BY_ACCOUNT, GET_ALL_ENERGY_TYPES, CREATE_ENERGY_SOURCES } from './index.queries';
import { validateMeters, templateColumns } from './metersParser';
import { useSuppliers } from '../../../hooks';
import { MAX_ITEMS_IN_MEMORY_LIMIT } from '../../../constants';
import { tryParseSpreadsheet } from '../../../utils/excelUtils';
import { TemplateType } from '../../../types';

export function UploadMetersModal({ onDismiss, accountId, onComplete }) {
  const [errors, setErrors] = useState([]);
  const [createEnerySources] = useMutation(CREATE_ENERGY_SOURCES);
  const suppliers = useSuppliers({ accountId, pageSize: MAX_ITEMS_IN_MEMORY_LIMIT });
  const client = useApolloClient();
  const typesResponse = useQuery(GET_ALL_ENERGY_TYPES);
  const types = typesResponse.data && typesResponse.data.getAllEnergyType ? typesResponse.data.getAllEnergyType : [];
  const typesByName = _(types).keyBy('name').value();
  const suppliersByName = _(suppliers).keyBy('name').value();

  const startUploading = async ([file]) => {
    const [err, rows] = await tryParseSpreadsheet(file);
    if (err && err.length) {
      return setErrors(err);
    }

    if (!rows.length) {
      setErrors(['Your spreadsheet is empty, please add some rows']);
    }

    const uniqParsedLocations = _(rows).map('Meter Location').uniq().value();
    const locationsResponse = await client.query({
      query: GET_LOCATIONS_BY_ACCOUNT,
      variables: {
        accountId,
        limit: MAX_ITEMS_IN_MEMORY_LIMIT,
        filters: {
          set: [
            {
              field: 'name',
              filterType: DataType.Set,
              values: uniqParsedLocations,
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    });

    const locations = locationsResponse?.data?.getLocationsPage?.rows;

    if (!locations?.length) {
      return setErrors(['Locations from spreadsheet are not found in the current account']);
    }
    const locationsByName = _(locations).keyBy('name').value();

    const [parseErrors, energySources] = await validateMeters(
      rows,
      typesByName,
      locationsByName,
      suppliersByName,
      accountId
    );
    if (parseErrors && parseErrors.length) {
      return setErrors(parseErrors);
    }
    try {
      await createEnerySources({
        variables: {
          energySources,
        },
      });
      onComplete('Meters have been uploaded', 'success');
    } catch (__) {
      onComplete('Something went wrong', 'danger');
    }
  };
  return (
    <MassUploadModal
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      template={{
        type: TemplateType.Dynamic,
        columns: templateColumns,
        fileName: 'meters-upload',
      }}
      errors={errors}
      onFileInput={startUploading}
      onDismiss={onDismiss}
    />
  );
}
