import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { SurveyQuestion, SurveyQuestionInput } from '@rio/rio-types';
import { QuestionDetails } from './QuestionDetails';
import { useNotification } from '../../../hooks/useNotification';
import { useUpdateQuestion } from './queries';
import { Errors } from '../../../types';
import { validate } from './validate';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

interface UpdateQuestionProps {
  question: SurveyQuestion;
  onUpdated: () => void;
}

export function UpdateQuestion({ onUpdated, question }: UpdateQuestionProps) {
  const [questionInput, setQuestionInput] = useState<Partial<SurveyQuestionInput>>({
    category: question.category?.id,
    scores:
      question.options?.map(({ id, score }) => ({
        id,
        score
      })) || []
  });
  const [errors, setErrors] = useState<Errors<SurveyQuestionInput>>({});

  const [updateQuestion, { loading }] = useUpdateQuestion();
  const { showNotification } = useNotification();

  const handleChange = useCallback((payload: Partial<SurveyQuestionInput>) => {
    setQuestionInput((t) => ({
      ...t,
      ...payload
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors] = validate(questionInput);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }

      await updateQuestion({
        variables: {
          question: {
            id: question.id,
            category: questionInput.category,
            scores: questionInput.scores || []
          }
        },
        onCompleted: onUpdated,
        onError: (e) => showNotification(e.message, 'danger')
      });
    },
    [question, updateQuestion, onUpdated, showNotification, questionInput]
  );

  return (
    <Container>
      <PropertySheet container onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow name="update-question__heading">Update Survey Question</PropertySheet.HeaderRow>
        <QuestionDetails onChange={handleChange} questionInput={questionInput} errors={errors} question={question} />
        <PropertySheet.Row container>
          <PropertySheet.ButtonColumn
            disabled={loading}
            onClick={handleSubmit}
            name="UpdateQuestion__UpdateQuestionButton"
          >
            Update Question
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
