import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Col, Search, LoadingIndicator, Button, Notification } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_ALL_LEGISLATION } from './index.queries';
import LegislationRow from './LegislationRow';
import styled from 'styled-components';
import * as JsSearch from 'js-search';
import { useLocation } from 'react-router-dom';
import alphabetiseStrings from '../../../utils/alphabetise';
import CreateLegislationModal from './CreateLegislationModal';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const search = (data, term) => {
  if (!term) return data;
  const search = new JsSearch.Search('id');
  search.addIndex('title');
  search.addDocuments(data);
  return search.search(term);
};

const LegislationContainer = ({ accountId }) => {
  const [searchValue, setSearchValue] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { pathname } = useLocation();
  const [notification, setNotification] = useState(null);
  const { data, loading } = useQuery(GET_ALL_LEGISLATION, { variables: { accountId } });
  const searchedData = data ? search(data.getAllLegislation, searchValue) : [];
  return (
    <ContainerStyled>
      <Col name="ConfigurationLegislationContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationLegislationContainer__Controls"
          icon="pencil-alt"
          iconColor="tertiary"
          title="Legislation"
        >
          <HeaderContent>
            <Search
              name="ConfigurationLegislationContainer__Controls__Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              hideButton
            />
            <Button
              name="ConfigurationLegislationContainer__Controls__Button--add"
              color="primary"
              inline
              component="button"
              onClick={() => setShowCreateModal(true)}
            >
              + Add Legislation
            </Button>
          </HeaderContent>
        </ContainerHeader>
      </Col>
      {notification && (
        <Notification name="Task__Notification" show color={notification.colour} onClose={() => setNotification(null)}>
          {notification.message}
        </Notification>
      )}
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {!loading && showCreateModal && (
        <CreateLegislationModal onDismiss={() => setShowCreateModal(false)} onComplete={setNotification} />
      )}
      {!!searchedData.length &&
        searchedData
          .slice()
          .sort((legA, legB) => alphabetiseStrings(legA.title, legB.title))
          .map((legislation) => {
            return (
              <LegislationRow key={legislation.id} id={legislation.id} title={legislation.title} path={pathname} />
            );
          })}
    </ContainerStyled>
  );
};

export default LegislationContainer;
