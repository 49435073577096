import React from 'react';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

export interface EditCellProps {
  data: {
    id: string;
  };
  onEdit: Function;
}

export const EditCell = ({ data, onEdit }: EditCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(data.id, data)}>
      <Icon name={`edit--${data.id}`} icon="pencil-alt" />
    </EditLinkStyled>
  </GridCellContainer>
);
