import React from 'react';
import styled from 'styled-components';
import { Row, Col, Heading, Button, Modal } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(p) => p.theme.geometry.lg.spacing};
`;
export const StyledRow = styled(Row)`
  padding-top: ${(p) => p.theme.geometry.md.spacing};
`;
export const StyledCol = styled(Col)`
  padding-right: ${(p) => p.theme.geometry.md.spacing};
`;
const HeadingStyled = styled(Heading)`
  margin-bottom: ${(p) => p.theme.geometry.md.spacing};
`;
interface DocumentModalProps {
  onDismiss: () => void;
  heading: string;
  children: React.ReactNode;
  onSubmit: () => void;
  submitButtonTitle: string;
  isSubmitting?: boolean;
  submitDisabled?: boolean;
  showButtons?: boolean;
}
export function DocumentModal({ onDismiss, heading, children, onSubmit, submitButtonTitle, isSubmitting, submitDisabled, showButtons = true }: DocumentModalProps) {
  return (
    <Modal size="lg" onDismiss={onDismiss} show dismissable>
      <Container>
        <HeadingStyled align="center">{heading}</HeadingStyled>
        {children}
        {showButtons && (
          <StyledRow container>
            <StyledCol item>
              <Button color="info" disabled={isSubmitting} onClick={onDismiss}>
                Cancel
              </Button>
            </StyledCol>
            <Col item>
              <Button color="success" disabled={isSubmitting || submitDisabled} onClick={onSubmit}>
                {submitButtonTitle}
              </Button>
            </Col>
          </StyledRow>
        )}
      </Container>
    </Modal>
  );
}
