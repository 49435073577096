import React from 'react';
import { CountPicker } from '../UI'

export function CounterPickerRender(props) {
  const { value, onChange, name, ...others } = props;

  return (
    <CountPicker
      {...others}
      name={name}
      onIncrement={onChange}
      onDecrement={onChange}
      value={value}
    />
  );
}
