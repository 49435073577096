import { MutationCreateSurveyTemplateSubscriptionArgs as Variables } from '@rio/rio-types';
import { Errors } from '../../../types';

type Invalid = [Errors<Variables>, null];

type Valid = [null, Variables];

export function validate(variables: Partial<Variables>): Valid | Invalid {
  const errors: Errors<Variables> = {};

  if (!variables.accountId) {
    errors.accountId = 'Account is required';
  }
  if (!variables.templateId) {
    errors.templateId = 'Template is required';
  }
  if (Object.keys(errors).length) {
    return [errors, null];
  }
  return [null, variables as Variables];
}
