import React, { useCallback, useMemo, useState } from 'react';
import { AutocompleteField as Field } from 'rio-ui-components';
import { OpenEndedQuestion } from '@rio/rio-types';
import { debounce } from 'lodash';
import { QuestionText } from './Elements';

interface OpenEndedQuestionFieldProps {
  question: OpenEndedQuestion;
  onAnswer: (question: OpenEndedQuestion) => void;
}

export function OpenEndedQuestionField({ question, onAnswer }: OpenEndedQuestionFieldProps) {
  const [reportedText, setReportedText] = useState(question.reportedText || '');
  const saveAnswer = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onAnswer({
        ...question,
        reportedText: event.target.value || null
      }),
    [question, onAnswer]
  );

  const handleChange = useMemo(() => debounce(saveAnswer, 3000), [saveAnswer]);

  return (
    <>
      {question?.displaySettings?.showFormulation && <QuestionText source={question.formulation} />}
      <Field
        fullWidth
        name={question.id}
        id={question.id}
        value={reportedText || ''}
        placeholder={question.shortFormulation || question.formulation || 'Enter your value'}
        label={question.shortFormulation || question.formulation}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setReportedText(event.target.value);
          handleChange(event);
        }}
      />
    </>
  );
}
