import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { TransactionType } from '@rio/rio-types';
import styled from 'styled-components';
import { Notification, PropertySheet, Row, Col, Label, TextArea, MultiSelect } from 'rio-ui-components';
import { v4 as uuid } from 'uuid';
import AddressPropertySheet from '../../../components/AddressPropertySheet';
import AccountSelector from '../../../components/AccountSelector';
import { useCountries } from '../../../hooks/useCountries';
import { SupplierTypeCheckboxes } from './SupplierTypeCheckboxes';
import { CREATE_SUPPLIER } from './index.queries';
import { validateForm } from './validateForm';
import { usePermissions } from '../../../hooks/usePermissions';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const CreateSupplier = ({
  onSuccess,
  accountId: userAccountId,
  refetchQuery,
  utility,
  heading,
  defaultTypes = [],
  forType: prefilledType,
  materialTypesOptions,
}) => {
  const permissions = usePermissions();
  const { countryList, defaultCountry, allCountries } = useCountries();
  const [state, setState] = useState({
    hasError: false,
    isSuccess: false,
    notification: { show: false, message: '', color: 'success' },
    supplierName: { text: '', error: '' },
    address1: { text: '', error: '' },
    address2: { text: '', error: '' },
    address3: { text: '', error: '' },
    city: { text: '', error: '' },
    postcode: { text: '', error: '' },
    phone: { text: '', error: '' },
    selectCountry: null,
    materials: null,
    notes: null,
    type: { value: prefilledType ? [prefilledType] : defaultTypes, error: '' },
    accountId: userAccountId,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createSupplier] = useMutation(CREATE_SUPPLIER, {
    refetchQueries: refetchQuery,
    onError: (error) => {
      setIsSubmitting(false);
      if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].errorType === 400) {
        setState({
          ...state,
          notification: {
            show: true,
            message: 'Supplier name must be unique.',
            color: 'danger',
          },
          hasError: true,
          isSuccess: false,
        });
        return;
      }

      setState({
        ...state,
        notification: {
          show: true,
          message: 'Something went wrong. If the problem continues please contact support.',
          color: 'danger',
        },
        hasError: true,
        isSuccess: false,
      });
    },
    onCompleted: (data) => {
      setIsSubmitting(true);
      setState({
        ...state,
        hasError: false,
        isSuccess: true,
      });
      onSuccess('Supplier has been created!', 'success', data.createSupplier.id, data.createSupplier);
    },
  });

  const handleSaveSupplier = () => {
    const { supplierName, address1, address2, address3, city, postcode, phone, selectCountry, type, materials, notes } =
      state;
    const countryId = selectCountry?.value || defaultCountry.value;
    const input = {
      utility,
      countryId,
      id: uuid(),
      name: supplierName.text,
      addressId: uuid(),
      add1: address1.text || '',
      add2: address2.text || '',
      add3: address3.text || '',
      city: city.text,
      post: postcode.text,
      phone: phone.text ? phone.text.trim() : '',
      type: type.value,
      accountId: type.value.includes('Contractor') ? null : state.accountId,
      materialIds: materials?.map(({ value }) => value),
      notes: notes?.text,
    };
    const [newState, valid] = validateForm(
      state,
      allCountries.find((c) => c.id === countryId)
    );
    if (!valid) {
      setState({ ...newState });
    } else {
      createSupplier({
        variables: input,
      });
    }
  };

  const handleChange = (e) => setState({ ...state, [e.target.name]: { text: e.target.value, error: '' } });

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.select.value, label: e.target.select.label },
    }));
  };

  const handleSupplierNameChange = (event) => {
    const text = event.target.value;
    const error = text ? '' : state.supplierName.error;
    setState({
      ...state,
      supplierName: { ...state.supplierName, text, error },
    });
  };

  const hideNotification = () => {
    setState({
      ...state,
      notification: { ...state.notification, show: false },
    });
  };

  const { notification, supplierName, address1, address2, address3, city, postcode, phone, selectCountry } = state;

  return (
    <Container name="create-supplier">
      {state.notification.show && (
        <Notification name="create-supplier__notification" show color={notification.color} onClose={hideNotification}>
          {notification.message}
        </Notification>
      )}

      <PropertySheet id={'createSupplier--propertySheet'}>
        <PropertySheet.HeaderRow name="createSupplier--header">{heading}</PropertySheet.HeaderRow>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            name="supplier--name"
            onChange={handleSupplierNameChange}
            label="Supplier Name"
            value={supplierName.text}
            error={supplierName.error}
          />
        </PropertySheet.Row>
        <AddressPropertySheet
          id={'SupplierAddressPropertySheet'}
          name={'SupplierAddressPropertySheet'}
          address1={address1}
          address2={address2}
          address3={address3}
          city={city}
          postcode={postcode}
          phone={phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          disabled={isSubmitting}
          countryList={countryList || []}
          selectCountry={selectCountry || defaultCountry}
        />
        {permissions.account.includes('getAll') && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Account</LabelStyled>
              </LabelContainerStyled>
              <AccountSelector
                value={state.accountId}
                onChange={({ id }) => {
                  setState((prevState) => ({
                    ...prevState,
                    accountId: id,
                  }));
                }}
              />
            </ColStyled>
          </Row>
        )}
        {!prefilledType && (
          <SupplierTypeCheckboxes
            utility={utility}
            error={state.type.error}
            defaultSelected={defaultTypes}
            onChange={(value) =>
              setState({
                ...state,
                type: {
                  value,
                  error: '',
                },
              })
            }
          />
        )}
        {utility === TransactionType.Materials && (
          <>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Material Supplied</LabelStyled>
                </LabelContainerStyled>
                <MultiSelect
                  value={state.materials}
                  options={materialTypesOptions}
                  isDisabled={isSubmitting}
                  onChange={(e) => setState((prev) => ({ ...prev, materials: e.target.select }))}
                  multi
                />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Notes</LabelStyled>
                </LabelContainerStyled>
                <TextArea
                  size="lg"
                  disabled={isSubmitting}
                  onChange={(e) => handleChange(e)}
                  name="notes"
                  value={state.notes?.text}
                  box
                />
              </ColStyled>
            </Row>
          </>
        )}
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn
            name="createSupplier"
            onClick={() => handleSaveSupplier()}
            disabled={isSubmitting}
          >
            Save
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
};

export default CreateSupplier;
