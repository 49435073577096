import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { EditCell } from '../../../components/CellRenderers';
import { Grid as AgGrid } from '~/components/UI';

import { useAgGrid } from '../../../hooks/useAgGrid';
import { GET_ENERGY_SOURCE_FILTER } from '../../../graphql/queries/energySource/getEnergySourceByAccountIdAndType.queries';
import {
  mapFilterModel,
  mapSortModel,
  gridValueFormatter,
  formatBooleanValue,
  formatBooleanFilterValue,
} from '../../../utils';
import { useNotification } from '../../../hooks';
import { LoadFailed } from '../../../components/Errors';

const PAGE_SIZE = 25;

export function Grid({ onEdit, fetchRows, accountId, setGridApi }) {
  const client = useApolloClient();
  const agGrid = useAgGrid({ autoFit: true });
  const [error, setError] = useState(null);

  const { showNotification } = useNotification();

  const locationFilterValues = (values) => {
    return values.reduce((acc, currentItem) => {
      const existingItemIndex = acc.findIndex((location) => location.label === currentItem.label);
      if (existingItemIndex !== -1) {
        acc[existingItemIndex] = {
          ...acc[existingItemIndex],
          value: Array.isArray(acc[existingItemIndex].value)
            ? acc[existingItemIndex].value.concat([currentItem.value])
            : [acc[existingItemIndex].value].concat([currentItem.value]),
        };
      } else {
        acc.push(currentItem);
      }

      return acc;
    }, []);
  };

  const generationFilterValues = (values) =>
    values.map((item) => {
      return { ...item, value: item.value === 'true' ? '1' : item.value === 'false' ? '0' : null };
    });

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getEnergySourcePageFilter: values },
      } = await client.query({
        query: GET_ENERGY_SOURCE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });

      const filters =
        params.colDef.colId === 'locationPointId'
          ? locationFilterValues(values)
          : params.colDef.colId === 'isGeneration'
          ? generationFilterValues(values)
          : values;
      params.success(filters.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      Sentry.captureException(err);
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  const datasource = {
    async getRows(params) {
      try {
        const { startRow, sortModel, filterModel } = params.request;
        const variables = {
          accountId,
          offset: startRow,
          limit: PAGE_SIZE,
          sort: mapSortModel(sortModel),
          filters: mapFilterModel(filterModel),
        };

        const { rows, totalRows } = await fetchRows(variables);
        params.success({
          rowData: rows,
          rowCount: totalRows,
        });
      } catch (err) {
        params.fail();
        setError(err);
        Sentry.captureException(err);
      }
    },
  };

  const defaultConfig = {
    pagination: true,
    paginationPageSize: 25,
    paginationPageSizeSelector: false,
    rowModelType: 'serverSide',
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      autoHeight: true,
      filterParams: {
        showTooltip: true,
      },
    },
  };

  const options = {
    ...defaultConfig,
    columnDefs: [
      {
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'name',
      },
      {
        colId: 'typeId',
        headerName: 'Type',
        field: 'type.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        colId: 'locationPointId',
        headerName: 'Location',
        field: 'locationPoint.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        colId: 'meterCode',
        headerName: 'Meter Code',
        field: 'meterCode',
        filter: 'agTextColumnFilter',
      },
      {
        colId: 'inOut',
        headerName: 'Water Direction',
        field: 'inOut',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        colId: 'isGeneration',
        headerName: 'Renewable energy',
        field: 'isGeneration',
        valueFormatter: (value) => formatBooleanValue(value),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: formatBooleanFilterValue,
        },
      },
      {
        colId: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        cellRenderer: 'date',
      },
      {
        colId: 'endDate',
        headerName: 'End Date',
        field: 'endDate',
        cellRenderer: 'date',
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  const components = {
    action: (props) => <EditCell {...props} onEdit={onEdit} />,
    date: (props) => {
      if (props.value) {
        return Intl.DateTimeFormat().format(new Date(props.value));
      }
      return null;
    },
  };

  return error ? (
    <LoadFailed
      error={error}
      name={error.message}
      retry={async () => {
        await fetchRows({
          accountId,
          offset: 0,
          limit: PAGE_SIZE,
        });
        setError(null);
      }}
    />
  ) : (
    <AgGrid
      {...options}
      paginationPageSize={PAGE_SIZE}
      cacheBlockSize={PAGE_SIZE}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, columnApi: params.columnApi, datasource: datasource });
        params.api.setServerSideDatasource(datasource);
      }}
    />
  );
}
