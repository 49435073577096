import { gql } from '@apollo/client';
import { ASPECT_FIELDS } from '../../fragments/AspectFields.fragment.js';

export const GET_ASPECT_BY_ID_AND_ACCOUNT_ID = gql`
  query GetAspectByIdQuery($id: ID!, $accountId: ID!) {
    getAspectById(id: $id, accountId: $accountId) {
      ...AspectFields
      evidenceNotes {
        id
        notes
        documents {
          id
          fileName
        }
      }
    }
  }
  ${ASPECT_FIELDS}
`;
