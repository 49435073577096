import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Basket from './Basket';
import { Col, IconTile, Row } from 'rio-ui-components';

const MainContainer = styled.div`
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
  position: relative;
`;

const ContainerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  border-radius: 2px;
`;

const HeaderContainer = styled(Row)`
  background: white;
  flex: 0 1 auto;
  border-radius: 2px;
  min-height: 119px;
  justify-content: space-between;
`;

const HeaderLeftCol = styled(Col)`
  flex: 1;
  align-items: baseline;
  justify-content: center;
  display: flex;
  padding-left: 30px;
`;

const HeaderRightCol = styled(Col)`
  display: flex;
  flex-direction: row;
  padding-right: 30px;
`;

const GreetingText = styled.h1`
  text-align: center;
  font-size: 28px;
  color: ${(props) => props.theme.colors.text.light.text};
`;

const IconTab = styled(IconTile)`
  text-align: center;
  font-size: 10px;
  min-width: 165px;
  margin: 20px 0px 20px 20px;
  display: inline-block;
  border-radius: 12px;
  padding: 13px 16px 5px 16px;
  p {
    font-size: 16px;
  }
`;

export function MarketBody(props) {
  const { cart = {}, children = '', route = 'account' } = props;

  const {
    state: { isCartEmpty }
  } = cart;

  const theme = useTheme();
  const navigate = useNavigate();

  const forwardToCourses = () => navigate('/upgrade/courses');

  const isCoursesActive = route === 'courses';
  const isCartActive = route === 'cart';
  const isCheckoutActive = route === 'checkout';
  const isCartVisible = !isCartEmpty && !isCartActive && !isCheckoutActive;

  return (
    <MainContainer>
      {isCartVisible ? <Basket cart={cart} /> : null}

      <ContainerWrapper name="Market-Body__Container">
        <HeaderContainer container item>
          <HeaderLeftCol>
            <GreetingText>How would you like to upgrade?</GreetingText>
          </HeaderLeftCol>
          <HeaderRightCol>
            <IconTab
              title="Courses"
              name="Market-Body__Courses-Tab"
              icon="graduation-cap"
              iconColor={theme.colors.text.normal.background}
              active={isCoursesActive}
              onClick={forwardToCourses}
            />
          </HeaderRightCol>
        </HeaderContainer>
        {React.cloneElement(children, { isCartVisible })}
      </ContainerWrapper>
    </MainContainer>
  );
}
