import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { DocumentLibrary } from '@rio/rio-types';
import { Col } from 'rio-ui-components';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS_AND_DOCUMENTS } from '../../GovernanceContainer/ManagementSystems/Overview/index.queries';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useGetDocuments } from '../useGetDocuments';
import { DocumentExplorer } from '../DocumentExplorer';
import PageHeader from '../../../components/PageHeader';
import documentTypes from '../documentTypes';
import { createBaseBreadcrumbs, mapManagementSystemsToFolders, LIBRARY, CATEGORY } from './utils';
import { useSearch } from '../../../hooks/useSearch';
import { getAllFilters } from '../utils';

const siblingFolders = documentTypes.filter((folder) => folder.libraries.includes(DocumentLibrary.Governance));

export const ManagementSystems = injectIntl(function ManagementSystems({ intl }) {
  const accountId = useCurrentAccountId();
  const variables = {
    accountId,
  };
  const { data, loading } = useQuery(GET_AVAILABLE_MANAGEMENT_SYSTEMS_AND_DOCUMENTS, {
    variables,
  });
  const breadcrumbs = createBaseBreadcrumbs(intl);
  const { pathname } = useLocation();
  const managementSystems = data?.getAvailableManagementSystems || [];
  const folders = mapManagementSystemsToFolders(managementSystems, pathname);
  const [searchValue, setSearchValue, searchedFolders] = useSearch(folders, ['name']);
  const { documents } = useGetDocuments({
    accountId,
    filters: getAllFilters(searchValue, LIBRARY, CATEGORY),
  });

  return (
    <Col container fullHeight>
      <PageHeader
        title="Management Systems"
        icon="file-alt"
        iconColor="quaternary"
        breadcrumbs={breadcrumbs}
        dropdownItems={siblingFolders}
        dropdownDefault="Management Systems"
        isDropdown
      />
      <DocumentExplorer
        files={documents}
        folders={searchedFolders}
        searchValue={searchValue}
        library={LIBRARY}
        category={CATEGORY}
        onSearch={setSearchValue}
        searchPlaceholder="Search management systems"
        loading={loading}
      />
    </Col>
  );
});
