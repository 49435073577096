import React from 'react';
import styled from 'styled-components';

import { Video } from 'rio-ui-components';
import { GET_ID_TOKEN } from '../../queries/auth';
import { UPDATE_USER_COURSE_PROGRESS } from './index.queries';
import { withApollo } from '@apollo/client/react/hoc';
import { COMPLETE, INPROGRESS } from '../../constants/courseProgress';

const VideoContainer = styled.div`
  padding: ${(p) => p.theme.geometry.xl.spacing};
  position: relative;
  max-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoStyled = styled(Video)`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
`;

const onVideoStart = async (course, client, courseRefetch) => {
  const idToken = await client.query({ query: GET_ID_TOKEN });
  const userId = idToken.data.idToken.sub;
  await client.mutate({
    mutation: UPDATE_USER_COURSE_PROGRESS,
    variables: { userId, courseId: course.id, courseProgress: INPROGRESS }
  });
  courseRefetch();
};

const onVideoEnd = async (course, client, courseRefetch, onCompleted) => {
  const idToken = await client.query({ query: GET_ID_TOKEN });
  const userId = idToken.data.idToken.sub;
  await client.mutate({
    mutation: UPDATE_USER_COURSE_PROGRESS,
    variables: { userId, courseId: course.id, courseProgress: COMPLETE }
  });
  courseRefetch();
  onCompleted();
};

const LearnEmbeddedVideoPlayer = ({ course, client, courseRefetch, onCompleted }) => {
  return (
    <VideoContainer>
      <VideoStyled
        autoPlay
        sources={[{ link: course.metaData.highQualityLink || course.metaData.lowQualityLink || course.metaData.link }]}
        onPlay={() => onVideoStart(course, client, courseRefetch)}
        onEnded={() => onVideoEnd(course, client, courseRefetch, onCompleted)}
      />
    </VideoContainer>
  );
};

export default withApollo(LearnEmbeddedVideoPlayer);
