import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';
import { ManagementSystemPage } from '../../../../../types';

interface CarbonDisclosureBusinessStrategyProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureBusinessStrategy({ page, framework }: CarbonDisclosureBusinessStrategyProps) {
  const buttons = useMemo(
    () => [
      {
        title: 'View guidance',
        color: 'primary',
        onClick: () => {
          window.open('https://www.cdp.net/en/guidance', 'noopener', 'noreferrer');
        }
      }
    ],
    []
  );

  return <ReportingFrameworkLayout framework={framework} page={page} buttons={buttons} />;
}
