import styled from 'styled-components';
import { Heading, LoadingIndicator, Button } from 'rio-ui-components';

export const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-top: ${(props) => props.theme.geometry.md.spacing};
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

export const TableContainer = styled.div`
  width: 100%;
  padding-bottom: 15px;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.geometry.md.spacing};
`;

export const Loader = styled(LoadingIndicator)`
  margin: 0 auto;
`;
export const ButtonStyled = styled(Button)`
  width: 240px;
`;
