import { useCurrentAccountId } from './useCurrentAccountId';
import { QueryHookOptions, useQuery } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import { GET_QUICKSIGHT_DASHBOARD } from '~/containers/ReportingContainer/QuicksightReport/index.queries';

export const useDashboard = (dashboardId: string, options: QueryHookOptions = {}) => {
  const accountId = useCurrentAccountId();
  const idToken = localStorage.getItem('ID_TOKEN');
  let variables;
  if (idToken) {
    const { sub } = jwt_decode<{ sub: string }>(idToken);
    variables = {
      username: sub,
      dashboardId,
      accountId,
    };
  }
  const result = useQuery(GET_QUICKSIGHT_DASHBOARD, {
    variables,
    fetchPolicy: 'network-only',
    ...options,
  });

  return result;
};
