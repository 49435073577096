/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Task, User, TaskInput, TaskCategory, TaskSubject, TaskPriority, Scope } from '@rio/rio-types';
import { TaskDetails } from './types';
import { Option } from '../../../types';
import { mapSelectedOptionsToInputValues } from '../../../utils';

const mapOwnersToValues = (owners: User[]) => {
  return owners?.map((owner) => ({ label: owner.first_name + ' ' + owner.last_name, value: owner.id }));
};

const mapAuthorToLabel = (author: User): string => {
  return `${author.first_name} ${author.last_name}`;
};

const mapMaybeEntityToSelectOptions = (list: Task['locations'] | Task['tags']) => {
  return list?.map((item) => {
    return (
      item && {
        label: 'tagName' in item ? item.tagName : item.name,
        value: item.id,
      }
    );
  }) as Option[];
};

export const formatTaskData = (task: Task): TaskDetails => {
  return {
    id: task.id,
    accountId: task.accountId,
    author: mapAuthorToLabel(task.author),
    owners: mapOwnersToValues(task.owners),
    locations: mapMaybeEntityToSelectOptions(task?.locations),
    name: task.name,
    description: task.description,
    priority: task.priority,
    category: task.category,
    scope: task.scope,
    subject: task.subject,
    item: task.item,
    itemId: task.item?.id,
    tags: mapMaybeEntityToSelectOptions(task?.tags),
    createdAt: task.createdAt,
    completedAt: task.completedAt,
    isCompleted: task.isCompleted,
    dueDate: task.dueDate,
    evidence: task.evidence,
    project: task?.projectId,
    projectId: null,
  };
};

export const prepareTaskInput = (taskData: TaskDetails): TaskInput => {
  return {
    id: taskData.id,
    accountId: taskData.accountId,
    name: taskData.name,
    description: taskData.description,
    category: taskData.category as TaskCategory,
    subject: taskData.subject as TaskSubject,
    itemId: taskData.itemId,
    owners: mapSelectedOptionsToInputValues(taskData.owners) as string[],
    priority: taskData.priority as TaskPriority,
    dueDate: taskData.dueDate!,
    createdAt: taskData.createdAt!,
    completedAt:
      taskData.isCompleted && !!taskData.completedAt
        ? taskData.completedAt
        : taskData.isCompleted
        ? new Date().toISOString()
        : null,
    isCompleted: taskData.isCompleted,
    scope: taskData.scope as Scope,
    locationIds: mapSelectedOptionsToInputValues(taskData.locations!) as string[],
    tagIds: mapSelectedOptionsToInputValues(taskData.tags!) as string[],
    projectId: taskData.projectId,
  };
};
