import moment from 'moment';
import { dromoDateFormat as dateFormat } from '~/constants';
import { DromoRecord, InfoMessage } from '../types';
import { extractRecords, resetErrors, setError } from './utils';

const endDateError = (): InfoMessage => ({
  message: 'End date should be equal to or greater than start date.',
  level: 'error',
});

// Validate end date is not before start date fields in records
export const validateDates = (fields: string[], records: DromoRecord[]) => {
  if (fields.length !== 1 && fields.length !== 2) {
    throw new Error('validateDates expects 2 date fields');
  }

  return records.map((record) => {
    const [startDateValue, endDateValue] = extractRecords(record.row, fields);

    const startDate = moment(startDateValue as string, dateFormat);
    const endDate = moment(endDateValue as string, dateFormat);

    if (endDate.isSameOrAfter(startDate)) {
      return resetErrors(record, fields);
    }

    return setError(endDateError(), record, fields);
  });
};
