import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from 'rio-ui-components';
import { SET_USER_DISABLED } from './index.queries';

export function DisableUserButton({ userId, disabled, showNotification }) {
  const [isPending, setIsPending] = useState(false);
  const [setUserDisable] = useMutation(SET_USER_DISABLED);

  return (
    <Button
      color={disabled ? 'secondary' : 'danger'}
      disabled={isPending}
      onClick={async () => {
        setIsPending(true);
        try {
          await setUserDisable({
            variables: {
              id: userId,
              disabled: !disabled
            }
          });
          showNotification(`The user has been successfully ${disabled ? 'enabled' : 'disabled'}!`, 'success');
        } catch (err) {
          showNotification('Something went wrong, if the problem persists please contact support', 'danger');
        } finally {
          setIsPending(false);
        }
      }}
    >
      {disabled ? 'Enable user' : 'Disable user'}
    </Button>
  );
}
