import { Filters } from '@rio/rio-types';
import { RowModelType } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { FetchRowsFunction, GridApiType } from './types';
import { mapSortModel, mapFilterModel } from '../../../utils';
import { ActionCell, ActionCellProps, ActionFunction } from './GridCell/ActionCell';
import { RelatedProjectsCell, RelatedProjectsCellProps } from './GridCell/RelatedProjectsCell';

interface GridProps {
  onEdit: ActionFunction;
  onDelete: ActionFunction;
  fetchRows: FetchRowsFunction;
  setGridApi: React.Dispatch<React.SetStateAction<GridApiType>>;
}

export function Grid({ setGridApi, fetchRows, onEdit, onDelete }: GridProps) {
  const agGrid = useAgGrid({ autoFit: true });

  const options = {
    pagination: true,
    paginationPageSize: 25,
    rowModelType: 'serverSide' as RowModelType,
    defaultColDef: {
      resizable: false,
      sortable: true,
      filter: true,
      cellStyle: {
        padding: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
      autoHeight: true,
    },
    columnDefs: [
      {
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        field: 'name',
      },
      {
        headerName: 'Description',
        filter: 'agTextColumnFilter',
        field: 'description',
      },
      {
        headerName: 'Category',
        filter: false,
        sortable: false,
        field: 'category.name',
      },
      {
        headerName: 'Related Projects',
        filter: false,
        sortable: false,
        field: 'relatedProjects',
        cellRenderer: 'relatedProjects',
      },
      {
        headerName: 'Projects Related',
        filter: false,
        sortable: false,
        hide: true,
        field: 'projectsRelated',
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  const components = {
    action: (props: ActionCellProps) => <ActionCell {...props} onEdit={onEdit} onDelete={onDelete} />,
    relatedProjects: (props: RelatedProjectsCellProps) => <RelatedProjectsCell {...props} />,
  };

  return (
    <AgGrid
      {...options}
      cacheBlockSize={options.paginationPageSize}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, columnApi: params.columnApi });
        params.api.setServerSideDatasource({
          async getRows({ request, success, fail }) {
            try {
              const filters: Filters = mapFilterModel(request.filterModel);

              const { rows, totalRows } = await fetchRows({
                offset: request.startRow || 0,
                limit: options.paginationPageSize,
                sort: mapSortModel(request.sortModel),
                filters,
              });
              success({
                rowData: rows,
                rowCount: totalRows,
              });

              return { rows, totalRows };
            } catch (err) {
              fail();
              throw err;
            }
          },
        });
      }}
    />
  );
}
