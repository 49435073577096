export const constructEnergyData = (data, fileName) => {
  const rows = [];
  rows.push([data.meterName, data.startDate, data.endDate, data.quantity, 'act', data.totalCost, 'GBP', '', data.invoiceNumber, '', fileName.split('.')[0]]);
  return rows;
};

export const constructWaterData = (data, fileName) => {
  const rows = [];
  rows.push([data.meterName, data.startDate, data.endDate, data.reading, data.quantity, 'act', data.tariff, 'GBP', '', data.invoiceNumber.toString(), '', fileName.split('.')[0]]);
  return rows;
};
