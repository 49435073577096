"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.list = exports.dateTime = exports.date = exports.time = void 0;
const utils_1 = require("../../utils");
const clean = (f) => (p) => p.value === null || p.value === undefined ? '' : f(p);
exports.time = clean(({ value }) => {
    return utils_1.formatters.time.format(new Date(value));
});
exports.date = clean(({ value }) => {
    return utils_1.formatters.date.format(new Date(value));
});
exports.dateTime = clean(({ value }) => {
    return utils_1.formatters.dateTime.format(new Date(value));
});
const list = ({ value }) => utils_1.formatters.list.format(value);
exports.list = list;
