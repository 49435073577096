import { useApolloClient } from '@apollo/client';
import { Icon } from 'rio-ui-components';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { Grid as AgGrid } from '../../../components/UI';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { useNotification, usePermissions } from '../../../hooks';
import { formatDate } from '../../../utils';
import { mapFilterModel, mapSortModel, gridValueFormatter } from '../../../utils';
import { GET_LOCATIONS_FILTER } from './index.queries';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const ActionCell = ({ data: { id }, onEdit }) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(id)}>
      <Icon name={`location-row__edit--${id}`} icon="pencil-alt" />
    </EditLinkStyled>
  </GridCellContainer>
);

const CheckCell = ({ data, field }) => {
  return <input type="checkbox" checked={!!data[field]} />;
};

export function Grid({ onEdit, fetchRows, accountId, setGridApi }) {
  const client = useApolloClient();
  const agGrid = useAgGrid({ autoFit: true });
  const permissions = usePermissions();
  const canEditLocations = !!permissions.data.find((action) => action.startsWith('editLocation'));
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getLocationsPageFilter: values },
      } = await client.query({
        query: GET_LOCATIONS_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      Sentry.captureException(err);
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      params.success([]);
    }
  };

  const datasource = {
    async getRows(params) {
      try {
        const { startRow, sortModel, filterModel } = params.request;
        const variables = {
          accountId,
          offset: startRow,
          limit: 25,
          sort: mapSortModel(sortModel),
          filters: mapFilterModel(filterModel),
        };

        const { rows, totalRows } = await fetchRows(variables);
        params.success({
          rowData: rows,
          rowCount: totalRows,
        });
      } catch (err) {
        Sentry.captureException(err);
        params.fail();
      }
    },
  };

  const options = {
    pagination: true,
    paginationPageSize: 25,
    paginationPageSizeSelector: false,
    rowModelType: 'serverSide',
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        height: 'auto',
      },
    },
    columnDefs: [
      {
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'name',
        resizable: true,
        sortable: true,
      },
      {
        colId: 'address1',
        headerName: 'Address 1',
        field: 'address.address1',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
      },
      {
        colId: 'address2',
        headerName: 'Address 2',
        field: 'address.address2',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'address3',
        headerName: 'Address 3',
        field: 'address.address3',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'city',
        headerName: 'City',
        field: 'address.city',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
      },
      {
        colId: 'postcode',
        headerName: 'Postcode',
        field: 'address.postcode',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
      },
      {
        colId: 'country',
        headerName: 'Country',
        field: 'address.country.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
      },
      {
        colId: 'wasteIn',
        field: 'wasteIn',
        headerName: 'Waste In',
        valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
        cellRenderer: 'checkWasteIn',
        filter: false,
        sortable: false,
        resizable: true,
        hide: true,
      },
      {
        colId: 'wasteOut',
        headerName: 'Waste Out',
        field: 'wasteOut',
        valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
        cellRenderer: 'checkWasteOut',
        filter: false,
        sortable: false,
        resizable: true,
        hide: true,
      },
      {
        colId: 'tags',
        headerName: 'Tags',
        field: 'tags',
        autoHeight: true,
        valueFormatter: ({ value = [] }) => value.map((tag) => tag.tagName).join(', '),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        sortable: false,
        resizable: true,
        hide: true,
      },
      {
        colId: 'departments',
        headerName: 'Departments',
        field: 'departments',
        autoHeight: true,
        valueFormatter: ({ value = [] }) => value.map((dep) => dep.name).join(', '),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        sortable: false,
        resizable: true,
        hide: true,
      },
      {
        colId: 'region',
        headerName: 'Region',
        field: 'region.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'tenure',
        headerName: 'Tenure',
        field: 'tenure.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'buildingType',
        headerName: 'Building Type',
        field: 'buildingType.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'code',
        headerName: 'Location Code',
        field: 'code',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'phone1',
        headerName: 'Phone',
        field: 'address.phone1',
        filter: false,
        resizable: true,
        sortable: false,
        hide: true,
      },
      {
        colId: 'floorSpace',
        headerName: 'Floor Space',
        field: 'floorSpace',
        filter: 'agNumberColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'startDate',
        headerName: 'Occupation start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        sortable: true,
        resizable: true,
        hide: true,
      },
      {
        colId: 'endDate',
        headerName: 'Occupation end Date',
        field: 'endDate',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'leaseRenewalDate',
        headerName: 'Lease Renewal Date',
        field: 'leaseRenewalDate',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'notes',
        headerName: 'Notes',
        field: 'notes',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        hide: true,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  if (canEditLocations) {
    options.columnDefs.push({
      headerName: 'Actions',
      cellRenderer: 'action',
      filter: false,
      sortable: false,
      resizable: true,
    });
  }

  const components = {
    action: (props) => <ActionCell {...props} onEdit={onEdit} />,
    checkWasteIn: (props) => <CheckCell {...props} field={'wasteIn'} />,
    checkWasteOut: (props) => <CheckCell {...props} field={'wasteOut'} />,
  };

  return (
    <AgGrid
      gridOptions={options}
      paginationPageSize={25}
      cacheBlockSize={25}
      serverSideDatasource={datasource}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, datasource, columnApi: params.columnApi });
      }}
    />
  );
}
