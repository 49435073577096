import { Col } from 'rio-ui-components';
import { useCallback, useRef } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import ContainerHeader from '~/components/ContainerHeader';
import { useCurrentAccountId } from '~/hooks';
import { FundsGrid } from './ConfigurationFundsGrid';
import { ConfigurationFundsUploadButton } from './ConfigurationFundsUploadButton';

export function ConfigurationFunds() {
  const accountId = useCurrentAccountId();

  const gridApi = useRef<GridApi>();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
  }, []);

  const handleUploadCompleted = useCallback(() => {
    if (gridApi.current) {
      gridApi.current.refreshServerSide({
        purge: true,
      });
    }
  }, []);

  return (
    <Col container fullHeight>
      <ContainerHeader icon="business-time" iconColor="primary" title="Fund management">
        <ConfigurationFundsUploadButton onCompleted={handleUploadCompleted} />
      </ContainerHeader>
      <FundsGrid accountId={accountId} onGridReady={handleGridReady} />
    </Col>
  );
}
