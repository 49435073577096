import { Button, Text, TextField, styled } from '@rio/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForgotPassword } from './useForgotPassword';

const Container = styled('div')`
  display: block;
  text-align: center;
  padding: 0.3rem;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 1.5rem;
`;

const SuccessContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type V2ForgotPasswordProps = { username: string } & React.ComponentProps<'div'>;

export const V2ForgotPassword = ({ username: initialUsername, ...rest }: V2ForgotPasswordProps) => {
  const { username, changeUsername, handleForgotPassword, success, error, loading } =
    useForgotPassword(initialUsername);
  const intl = useIntl();

  return (
    <Container {...rest}>
      {success && (
        <SuccessContainer>
          <Text size="large" typescale="title" sx={{ marginBottom: '1rem' }}>
            <FormattedMessage id="login.forgotPassword.success.title" />
          </Text>
          <Text size="large" typescale="label" sx={{ marginBottom: '1rem' }}>
            <FormattedMessage id="login.forgotPassword.success.subheading" />
          </Text>
          <Button
            name="forgot-password-container__go-to-resetbutton"
            to={`/v2/reset-password?username=${username}`}
            sx={{ width: 'fit-content' }}
          >
            <FormattedMessage id="login.forgotPassword.goToResetButton" />
          </Button>
        </SuccessContainer>
      )}
      {!success && (
        <>
          <Text size="large" typescale="title" sx={{ marginBottom: '0.3rem' }}>
            <FormattedMessage id="login.forgotPassword.title" />
          </Text>
          <Text sx={{ textAlign: 'center', marginBottom: '1rem' }} size="large" typescale="label">
            <FormattedMessage id="login.forgotPassword.subheading" />
          </Text>
          <Form onSubmit={handleForgotPassword}>
            <TextField
              name="username"
              error={error}
              placeholder={intl.formatMessage({ id: 'login.forgotPassword.usernamePlaceholder' })}
              value={username}
              onChange={changeUsername}
              sx={{ marginBottom: '1.5rem' }}
            />

            <Button
              type="submit"
              variant="filled"
              name="forgot-password-container__button"
              loading={loading}
              sx={{ alignSelf: 'center' }}
            >
              {loading && <FormattedMessage id="login.forgotPassword.buttonSubmitting" />}
              {!loading && <FormattedMessage id="login.forgotPassword.submitButton" />}
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};
