import { FC } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderItem from './SliderItem';
import { TCarouselProps } from './type';
import { CarouselContainer } from './styles';

const CarouselBanners: FC<TCarouselProps> = ({ banners, handlerClick }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {banners.map((item) => (
          <SliderItem key={item.accountId} handlerClick={handlerClick} item={item} />
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default CarouselBanners;
