import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../components/PageHeader';
import styled from 'styled-components';
import { Row, Col, ProfilePicture, LoadingIndicator, Text, Icon, Modal, Notification } from 'rio-ui-components';
import { useGetCurrentUserQuery } from '../../hooks';
import { UserDetails } from './UserDetails';
import { ProfilePictureUpload } from './ProfilePictureUpload';
import { UPLOAD_PROFILE_PICTURE } from './index.queries';
import { getEnvVar } from '../../env';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled(Row)`
  background-color: ${(props) => props.theme.colors.basic.white};
  width: 100%;
  display: flex;
`;

const PictureContainer = styled(Col)`
  display: flex;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
  align-items: center;
`;

const DetailsContainer = styled(Col)`
  display: flex;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
  align-items: center;
`;

const TextStyled = styled(Text)`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;

const IconStyled = styled(Icon)`
  margin-right: 10px;
`;

const ProfileContainer = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [notification, setNotification] = useState(null);
  const [uploadProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE, {
    onCompleted: () => {
      setNotification({ message: 'Profile picture has been updated', colour: 'success' });
    },
    onError: () => {
      setNotification({ message: 'Something went wrong. Please try again later.', colour: 'danger' });
    },
  });
  const { data, loading } = useGetCurrentUserQuery();

  const handleUpload = (image) => {
    uploadProfilePicture({
      variables: {
        ...data,
        account_id: data.account.id,
        previousEmail: data.email,
        imageData: image,
        contentType: 'image/png',
        fileName: `${data.id}.png`,
        profile: `${getEnvVar('REACT_APP_PROFILE_BUCKET')}/${data.id}.png?time=${new Date().toISOString()}`,
      },
    });

    setShowUploadModal(false);
  };

  return (
    <ContainerStyled>
      <PageHeader
        name="ProfilePage"
        title={'My Profile'}
        breadcrumbs={[{ title: 'My Profile' }]}
        icon="user"
        iconColor="quaternary"
      />
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {showUploadModal && (
        <Modal size="md" show onDismiss={() => setShowUploadModal(false)}>
          <ProfilePictureUpload onSubmit={handleUpload} imageUrl={data.profilePicture} />
        </Modal>
      )}
      {notification && (
        <Notification
          name="Profile__Notification"
          show
          color={notification.colour}
          onClose={() => setNotification(null)}
        >
          {notification.message}
        </Notification>
      )}
      {!loading && data && (
        <ContentContainer item container>
          <PictureContainer span="3">
            <ProfilePicture
              border
              name={`profile-picture`}
              src={data.profilePicture}
              size="xl"
              accountName={data.first_name}
            />
            <TextStyled pointer onClick={() => setShowUploadModal(true)}>
              <IconStyled icon="pencil-alt" />
              <FormattedMessage id="profile.editProfilePicture" />
            </TextStyled>
            <TextStyled>
              <IconStyled icon="id-card" />
              {`${data.first_name} ${data.last_name}`}
            </TextStyled>
            <TextStyled>
              <IconStyled icon="user-tag" />
              {data.role.name}
            </TextStyled>
            <TextStyled>
              <IconStyled icon="building" />
              {data.account.name}
            </TextStyled>
          </PictureContainer>
          <DetailsContainer span="9">
            <UserDetails user={data} setNotification={setNotification} />
          </DetailsContainer>
        </ContentContainer>
      )}
    </ContainerStyled>
  );
};

export default ProfileContainer;
