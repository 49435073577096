import { gql } from '@apollo/client';

export const GET_KBS_FOR_ACCOUNT = gql`
  query GetKnowledgeBasesForAccount($accountId: ID!) {
    getKnowledgeBasesForAccount(accountId: $accountId) {
      id
      externalID
      title
      synopsis
      demoMode
    }
  }
`;
