import DromoUploader, { IDeveloperField, IDeveloperSettings } from 'dromo-uploader-react';
import React, { useMemo, useCallback } from 'react';
import { useCurrency, useCurrentAccount, useCurrentUser, useNotification } from '~/hooks';
import { getFullName } from '~/utils';
import { useImportFunds } from './hooks';
import dromoSchema from './dromo-schema.json';
import { baseDromoConfiguration } from '../DromoConfiguration';
import { getEnvVar } from '../../../env';

interface ConfigurationFundsUploadButtonProps {
  onCompleted: () => void;
}
export function ConfigurationFundsUploadButton({ onCompleted }: ConfigurationFundsUploadButtonProps) {
  const currentAccount = useCurrentAccount();

  const currentUser = useCurrentUser();

  const { data: currencies } = useCurrency();

  const { showNotification } = useNotification();

  const [importFunds] = useImportFunds();

  const handleResults = useCallback(
    (results) => {
      importFunds({
        variables: {
          funds: results,
          accountId: currentAccount.id,
        },
        onCompleted: ({ importFunds: { created, updated, deleted } }) => {
          showNotification(`${created} created, ${updated} updated, ${deleted} deleted`);
          onCompleted();
        },
      });
    },
    [importFunds, currentAccount, onCompleted, showNotification]
  );

  if (!currentUser) {
    throw new Error('Configuration is not ready');
  }

  const user = {
    id: currentUser?.id || '',
    name: getFullName(currentUser),
    email: currentUser?.email,
    companyId: currentAccount.id,
    companyName: currentAccount.name,
  };

  const fields = useMemo(
    () =>
      dromoSchema.fields.map((field) =>
        field.key === 'currencyId'
          ? { ...field, selectOptions: currencies.map((currency) => ({ label: currency.name, value: currency.id })) }
          : field
      ),
    [currencies]
  );

  return (
    <DromoUploader
      licenseKey={getEnvVar('REACT_APP_DROMO_LICENSE_KEY')!}
      user={user}
      style={{
        ...baseDromoConfiguration.style,
        ...(dromoSchema.style as React.CSSProperties),
      }}
      settings={{
        ...baseDromoConfiguration.settings,
        ...(dromoSchema.settings as IDeveloperSettings),
      }}
      fields={fields as IDeveloperField[]}
      onResults={handleResults}
    >
      + Upload funds
    </DromoUploader>
  );
}
