export function makeUrl(string = '', params = {}) {
  let out = string;

  // /some/url/:param1/and/:param2 -> /some/url/LOL/and/MAN
  for (const k in params) {
    out = out.replace(`:${k}`, params[k]);
  }

  return out;
}
