import { Select } from 'rio-ui-components';

export function SelectRender(props) {
  const {
    value,
    onChange,
    name,
    error,
    trigger = () => {},
    onCreateNew = () => {},
    mapper = () => {},
    options = [],
    ...others
  } = props;

  return (
    <Select
      {...others}
      name={name}
      error={error}
      onChange={({ target: { value } } = {}) => {
        const { id } = value;

        if (id === 'createNew') {
          return onCreateNew(name);
        }

        onChange(value);
        trigger();
      }}
      options={options}
      value={value && mapper(value)}
    />
  );
}
