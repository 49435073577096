import React, { useState, useCallback } from 'react';
import { Modal, Grid, Button, TextField, styled } from '@rio/ui-components';
import { RETURN_KEY } from '../../constants/keyCodes';

interface NameYourFileModalProps {
  show: boolean;
  onDismiss: () => void;
  onSubmit: (fileName: string) => void;
  defaultFileName: string;
}

const ButtonStyled = styled(Button)`
  width: 50%;
`;

const ButtonContainerStyled = styled('div')`
  width: 100%;
  padding-top: 24px;
  display: flex;
  gap: 24px;
`;

export const NameYourFileModalV2 = ({ show, onDismiss, onSubmit, defaultFileName }: NameYourFileModalProps) => {
  const [fileName, setFileName] = useState('');

  const handleSubmit = useCallback(
    (name: string) => {
      onSubmit(name);
      setFileName('');
    },
    [onSubmit]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === RETURN_KEY) {
        handleSubmit(fileName || defaultFileName);
      }
    },
    [defaultFileName, fileName, handleSubmit]
  );

  return (
    <Modal title="Please enter file name" open={show} onClose={onDismiss}>
      <Grid container>
        <Grid xs={12}>
          <TextField
            name="fileName"
            onChange={(e) => setFileName(e.target.value)}
            label="File name"
            error={false}
            value={fileName}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <ButtonContainerStyled>
          <ButtonStyled
            variant="outlined"
            color="primary"
            onClick={() => {
              handleSubmit(defaultFileName);
            }}
          >
            Use default
          </ButtonStyled>
          <ButtonStyled
            variant="filled"
            disabled={!fileName}
            onClick={() => {
              handleSubmit(fileName);
            }}
          >
            Confirm
          </ButtonStyled>
        </ButtonContainerStyled>
      </Grid>
    </Modal>
  );
};
