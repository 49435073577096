import { useCompleteCourse } from './useCompleteCourse';
import { downloadFileFromUrl } from '../../utils';

export function useDownloadPdf(course, downloadLink, refetch) {
  const completeCourse = useCompleteCourse(course, refetch);
  return async () => {
    await completeCourse();
    downloadFileFromUrl(downloadLink);
  };
}
