"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuProps = exports.options = void 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
exports.options = [
    { value: '1', label: 'Oliver Hansen' },
    { value: '2', label: 'Van Henry' },
    { value: '3', label: 'April Tucker' },
    { value: '4', label: 'Ralph Hubbard' },
    { value: '5', label: 'Omar Alexander' },
    { value: '6', label: 'Carlos Abbott' },
    { value: '7', label: 'Miriam Wagner' },
    { value: '8', label: 'Bradley Wilkerson' },
    { value: '9', label: 'Virginia Andrews' },
    { value: '10', label: 'Kelly Snyder' },
];
exports.MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
