import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { GridReadyEvent, ColumnState, Column, GridApi } from 'ag-grid-community';
import { styled, Button, Page, Grid, SegmentedButton, Icons, CustomIcons } from '@rio/ui-components';
import {
  useUserToken,
  usePermissions,
  useNotification,
  useFiltersApplied,
  useCurrentAccountId,
  usePageSuspendingQuery,
} from '~/hooks';
import GET_PROJECTS from '../../../../graphql/queries/projects/GetProjects.query.graphql';
import { CreateProjectModal } from '../CreateProjectModal';
import { useInitialFilterModel } from '../useInitialFilterModel';
import { GridComponent } from './Grid';
import { defaultSortModel, segmentedButtonValues } from './options';

const GridStyled = styled(Grid)`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 24px;
  justify-content: flex-end;
`;

const SegmentedButtonWrapper = styled(Grid)`
  flex: 1;
  display: flex;
  align-items: flex-start;
`;

const Wrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

enum ModalType {
  Create,
}

const ButtonWrapperStyled = styled(Grid)`
  min-width: 165px;
`;

export const ProjectsGridContainer = () => {
  const agGridApi = useRef<GridApi>();
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const canCreateProjects: boolean = Boolean(permissions.project.find((action: string) => action.startsWith('create')));
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);
  const { showMine, setShowMine } = useInitialFilterModel();
  const canAccessAccountProjects: boolean = Boolean(permissions.project.find((action: string) => action === 'getAll'));
  const { hasFiltersApplied, updateClearFilter } = useFiltersApplied(agGridApi);

  const { data, loading, refetch, error } = usePageSuspendingQuery(GET_PROJECTS, {
    variables: { accountId, userId: token.sub },
  });

  const projects = data?.getProjects || [];

  const showProjectCreatedNotification = useCallback(
    ({ message, colour }) => {
      showNotification(message, colour);
    },
    [showNotification]
  );

  useEffect(() => {
    refetch({ accountId, userId: token.sub });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = useCallback(() => {
    if (!agGridApi.current) {
      return;
    }
    const api: GridApi = agGridApi.current!;
    api.setFilterModel(null);
    api.getColumns()?.forEach((column: Column) => {
      api.setColumnsVisible([column?.getColId()], true);
    });
    setShowMine(false);
  }, [setShowMine]);

  const filterMine = useCallback(() => {
    if (!agGridApi.current) {
      return false;
    }
    const api: GridApi = agGridApi.current!;
    const filterModel = api?.getFilterModel() || {};

    if (!showMine) {
      filterModel.owners = {
        type: 'set',
        values: [`${token.given_name} ${token.family_name}`],
      };
    } else {
      delete filterModel.owners;
    }

    api?.setFilterModel(filterModel);
    setShowMine(!showMine);
  }, [setShowMine, showMine, token.family_name, token.given_name]);

  const onGridReadyHandler = useCallback(
    (e: GridReadyEvent) => {
      agGridApi.current = e.api;
      agGridApi.current.applyColumnState({
        state: defaultSortModel as ColumnState[],
      });
      updateClearFilter();
    },
    [updateClearFilter]
  );

  const pageTitle = useMemo(
    () => ({
      content: '',
      actionButton: (
        <GridStyled container>
          {canAccessAccountProjects && (
            <SegmentedButtonWrapper container>
              <ButtonWrapperStyled lg={3.5} md={6}>
                <SegmentedButton values={segmentedButtonValues} onClick={filterMine} />
              </ButtonWrapperStyled>
            </SegmentedButtonWrapper>
          )}

          <Wrapper wrap="wrap" container xs={6}>
            <ButtonWrapperStyled lg={3.5} md={5.8}>
              <ButtonStyled
                startIcon={<CustomIcons.Clear width="18px" height="18px" color="onSecondary" />}
                variant="filled"
                color="secondary"
                onClick={resetFilters}
                disabled={!hasFiltersApplied}
              >
                Clear Filters
              </ButtonStyled>
            </ButtonWrapperStyled>

            {canCreateProjects && (
              <ButtonWrapperStyled lg={3.5} md={5.8}>
                <ButtonStyled startIcon={<Icons.Add />} variant="filled" onClick={() => setModalType(ModalType.Create)}>
                  Create Project
                </ButtonStyled>
              </ButtonWrapperStyled>
            )}
          </Wrapper>
        </GridStyled>
      ),
    }),
    [canAccessAccountProjects, filterMine, resetFilters, hasFiltersApplied, canCreateProjects]
  );

  return (
    <Page loading={loading} error={error} title={pageTitle}>
      <GridComponent onGridReady={onGridReadyHandler} projects={projects} showGoTo={false} />

      {modalType === ModalType.Create && (
        <CreateProjectModal
          accountId={accountId}
          onDismiss={() => setModalType(undefined)}
          onComplete={(n) => showProjectCreatedNotification({ message: n.message, colour: n.color })}
        />
      )}
    </Page>
  );
};
