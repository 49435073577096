import React, { useState, useEffect, useCallback } from 'react';
import { orderBy, startCase } from 'lodash';
import ProductCard from '../../../components/ProductCard';
import ProductModal from '../../../components/ProductModal';
import { TextInputWithMultiDropdown } from 'rio-ui-components';
import { marketProductType } from '../../../constants';
import { CATEGORIES } from '../../ConfigurationContainer/ConfigurationLearnUploadContainer/constants';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { COURSE_BUNDLE } from '../../../constants/marketProductType';
import {
  ColSearch,
  ContactUs,
  ProductContainer,
  ProductWrapper,
  RowSearch,
  SelectAllTopics,
  SelectNewest,
  timeOptions,
} from './SharedStyle';

interface CourseMetaData {
  categories: string[];
}

interface Course {
  metaData: CourseMetaData;
}

interface CourseBundle {
  courses: Course[];
}

interface Content {
  courseBundle: CourseBundle[];
}

interface Props {
  cart: any;
  content: Content;
  isCartVisible: boolean;
}

interface BundleInfo {
  id: string;
  thumbnail: string;
  title: string;
  description: string;
  price: number;
  product: unknown;
  hideBuyButton: boolean;
}

const Courses: React.FC<Props> = ({ cart, content, isCartVisible }) => {
  const [query, setQuery] = useQueryParams({
    courseId: withDefault(StringParam, null),
  });
  const [state, setState] = useState({ search: '', sortingByDate: 'desc', sortingByCategory: 'allCategories' });
  const [modal, setModal] = useState<{ show: boolean; bundleInfo: BundleInfo | null }>({
    show: false,
    bundleInfo: null,
  });

  const { addProduct, hasProduct, removeLine, findLine } = cart;

  // categories fetched from courses
  const categories = content.courseBundle.reduce((acc, cb) => {
    cb.courses.forEach((c) => {
      c.metaData?.categories?.forEach((cat) => {
        acc.add(cat);
      });
    });

    return acc;
  }, new Set());
  const categoryMap = new Map(
    [...categories].flatMap((item) => {
      const category = CATEGORIES.find((cat) => cat.value === item);
      if (category) {
        const { value, label } = category;
        return [[value, label]];
      }
      return [];
    })
  );

  const presentCategory: Set<string> = new Set();

  const sorting = (item) => {
    const checkText =
      item.title.toLocaleLowerCase().includes(state.search) ||
      item.description.toLocaleLowerCase().includes(state.search);
    const checkCategory =
      state.sortingByCategory === 'allCategories' ? true : item.categories.has(state.sortingByCategory.toUpperCase());
    return checkText && checkCategory;
  };

  const onDismiss = () => setModal((prevState) => ({ ...prevState, show: false }));

  const handleAddProduct = (product) => addProduct({ ...product }, 1, null, false);

  const findCourseBundle = useCallback(
    (id) => content[marketProductType.COURSE_BUNDLE].find((item) => item.id === id),
    [content]
  );

  const showModal = useCallback(
    (item) => {
      setModal({
        show: true,
        bundleInfo: {
          id: item.id,
          thumbnail: item.image,
          title: item.title,
          description: item.description,
          price: item.price,
          product: item,
          hideBuyButton: cart.hasProduct(item.id),
        },
      });
    },
    [cart]
  );

  const clickByPreview = useCallback(
    (item) => {
      setQuery({ courseId: item.id });

      const foundItem = findCourseBundle(item.id);
      if (foundItem) {
        showModal(foundItem);
      }
    },
    [setQuery, findCourseBundle, showModal]
  );

  useEffect(() => {
    if (query.courseId) {
      const courseItem = findCourseBundle(query.courseId);
      if (courseItem && !cart.hasProduct(courseItem.id)) {
        showModal(courseItem);
      }
    }
  }, [query.courseId, findCourseBundle, showModal, cart]);

  const productList = orderBy(
    content[marketProductType.COURSE_BUNDLE]
      .map((item) => {
        const { courses = [] } = item;
        const newCategories: Set<string> = new Set();

        courses.forEach((courseItem) => {
          const courseCategories = courseItem?.metaData.categories;
          if (courseCategories) {
            courseCategories.forEach((courseCategoryItem: string) => {
              newCategories.add(courseCategoryItem);
              presentCategory.add(courseCategoryItem);
            });
          }
        });

        return {
          ...item,
          categories: newCategories,
          firstCategory: newCategories.size && startCase([...newCategories][0].toLocaleLowerCase()),
        };
      })
      .filter(sorting),
    'createdAt',
    state.sortingByDate === 'desc' ? 'desc' : 'asc'
  );

  const findCurrentQuantity = (id) => {
    const { quantity } = findLine(id) || { quantity: 0 };

    return quantity;
  };

  const categoryOptions = [
    { value: 'allCategories', label: 'All categories' },
    ...[...presentCategory].map((item) => ({ value: item, label: categoryMap.get(item) })),
  ];

  return (
    <>
      {modal.show ? (
        <ProductModal bundleInfo={modal.bundleInfo} addProduct={handleAddProduct} onDismiss={onDismiss} />
      ) : null}
      <RowSearch>
        <ColSearch>
          <TextInputWithMultiDropdown
            id={'TextInputWithMultiDropdown'}
            name={'TextInputWithMultiDropdown'}
            inline={false}
            disabled={false}
            pill={true}
            box={true}
            size={'md'}
            iconType={'search'}
            success={''}
            error={''}
            textPlaceHolder={'Search for Title, Subject, Content'}
            onTextChange={(e) => {
              e.persist();
              setState((prevState) => ({ ...prevState, search: e.target?.value.toLocaleLowerCase() }));
            }}
            multiSelectList={[
              {
                transferredSelect: SelectNewest,
                selectValue: 'Select Value',
                selectPlaceholder: 'Newest',
                options: timeOptions,
                onSelectChange: (e) => {
                  setState((prevState) => ({ ...prevState, sortingByDate: e.target?.value }));
                },
              },
              {
                transferredSelect: SelectAllTopics,
                selectValue: '',
                selectPlaceholder: 'All Categories',
                options: categoryOptions,
                onSelectChange: (e) => {
                  setState((prevState) => ({ ...prevState, sortingByCategory: e.target?.value }));
                },
              },
            ]}
          />
        </ColSearch>
      </RowSearch>

      <ProductWrapper className={isCartVisible ? 'isCartVisible' : ''}>
        <ProductContainer>
          {productList.map(({ id, ...others }) => (
            <ProductCard
              {...{ id, ...others, quantity: findCurrentQuantity(id) }}
              key={id}
              isInCart={hasProduct(id)}
              addProduct={handleAddProduct}
              removeLine={removeLine}
              link={others.productType === COURSE_BUNDLE}
              handlePreview={clickByPreview}
            />
          ))}
        </ProductContainer>
        <ContactUs />
      </ProductWrapper>
    </>
  );
};

export default Courses;
