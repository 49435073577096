import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading, Text, TextInput, Row, Col, Button } from 'rio-ui-components';
import { validate } from './validate';

const Container = styled(Col)`
  width: 100%;
  height: 100%;
  padding: ${(p) => p.theme.geometry.md.spacing};
`;
const HeadingStyled = styled(Heading)`
  flex: 0;
  margin-bottom: ${(p) => p.theme.geometry.md.spacing};
`;
const DescriptionStyled = styled(Text)`
  flex: 0;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const RowStyled = styled(Row)`
  flex: 0 1 75px;
  padding: ${(p) => p.theme.geometry.xs.spacing} 0;
`;
const QuestionsRow = styled(Row)`
  flex: 1;
  overflow: auto;
`;
const QuestionsColumn = styled(Col)`
  flex: 1;
`;
const QuestionNumber = styled(Text)`
  margin-right: ${(p) => p.theme.geometry.xs.spacing};
`;
const AnswerCol = styled(Col)`
  padding-right: ${(p) => p.theme.geometry.sm.spacing};
`;
const ButtonsRow = styled(Row)`
  flex: 0;
  width: 100%;
  align-self: flex-end;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.geometry.sm.spacing};
`;
const ButtonStyled = styled(Button)`
  flex-basis: 48%;
  padding-right: ${(p) => p.theme.geometry.xs.spacing};
`;

function getAnswersHashMap(questions, index) {
  return questions.reduce(
    (map, q) => ({
      ...map,
      [q.id]: q.answers?.[index]?.value,
    }),
    {}
  );
}
export function Evaluation({ evaluation, onSubmit, onGoNext, goNextButtonTitle, reevaluation, type, isSubmitting }) {
  const [answers, setAnswers] = useState(getAnswersHashMap(evaluation.questions, 0));
  const [newAnswers, setNewAnswers] = useState(getAnswersHashMap(evaluation.questions, 1));
  const [errors, setErrors] = useState({});
  const preAssessmentCompleted = evaluation.questions.every((q) => q?.answers?.length >= 1);
  const postAssessmentCompleted = evaluation.questions.every((q) => q?.answers?.length >= 2);
  return (
    <Container container>
      <HeadingStyled align="center">{evaluation.name}</HeadingStyled>
      <DescriptionStyled>{evaluation.description}</DescriptionStyled>
      <QuestionsRow container item>
        <QuestionsColumn container item>
          {evaluation.questions.map((question, index) => (
            <RowStyled key={question.id} item container>
              <Col span={reevaluation ? 8 : 10} vdistribution="center" container item>
                <Text>
                  <QuestionNumber weight="bold" inline>
                    Q{index + 1}:
                  </QuestionNumber>
                  {question.text}
                </Text>
              </Col>
              <AnswerCol span={2} item>
                <TextInput
                  type="number"
                  min={evaluation.scale.min}
                  max={evaluation.scale.max}
                  value={answers[question.id]}
                  error={reevaluation ? null : errors[question.id]}
                  placeholder={`${evaluation.scale.min}-${evaluation.scale.max}`}
                  disabled={preAssessmentCompleted}
                  onChange={(e) => {
                    setAnswers({
                      ...answers,
                      [question.id]: e.target.value,
                    });
                  }}
                />
              </AnswerCol>
              {reevaluation && (
                <AnswerCol span={2} item>
                  <TextInput
                    type="number"
                    min={evaluation.scale.min}
                    max={evaluation.scale.max}
                    value={newAnswers[question.id]}
                    error={errors[question.id]}
                    placeholder={`${answers[question.id] || evaluation.scale.min}-${evaluation.scale.max}`}
                    disabled={postAssessmentCompleted}
                    onChange={(e) => {
                      setNewAnswers({
                        ...newAnswers,
                        [question.id]: e.target.value,
                      });
                    }}
                  />
                </AnswerCol>
              )}
            </RowStyled>
          ))}
        </QuestionsColumn>
      </QuestionsRow>
      <ButtonsRow container item>
        <ButtonStyled color="secondary" onClick={onGoNext}>
          {goNextButtonTitle}
        </ButtonStyled>
        <ButtonStyled
          color="success"
          disabled={isSubmitting || (reevaluation ? postAssessmentCompleted : preAssessmentCompleted)}
          onClick={() => {
            const answersToSubmit = reevaluation ? newAnswers : answers;
            const previousAnswers = reevaluation ? answers : null;
            const newErrors = validate(evaluation, answersToSubmit, previousAnswers);
            if (Object.keys(newErrors).length) {
              return setErrors(newErrors);
            }
            if (Object.keys(errors).length) {
              setErrors({});
            }
            onSubmit(
              Object.keys(answersToSubmit).map((questionId) => ({
                questionId,
                value: answersToSubmit[questionId],
              }))
            );
          }}
        >
          Submit {type}
        </ButtonStyled>
      </ButtonsRow>
    </Container>
  );
}
