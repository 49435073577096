import { useQuery } from '@apollo/client';
import React from 'react';
import { Query, MarketCarbonConversionFactor } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_TARIFFS_BY_ACCOUNT_ID } from './index.queries';
import { SelectEvent, Nullable, Option } from '../../types';

interface TariffSelectProps {
  value: Nullable<string | Option>;
  valueAsLabel?: boolean;
  onChange: (e: SelectEvent) => void;
  accountId: string;
}
export function TariffSelect({ value, onChange, accountId, valueAsLabel }: TariffSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getMarketCarbonConversionFactors'>>(
    GET_TARIFFS_BY_ACCOUNT_ID,
    {
      variables: {
        accountId
      }
    }
  );
  return (
    <Select
      placeholder="Please select a tariff"
      value={value}
      options={data?.getMarketCarbonConversionFactors?.map((f: MarketCarbonConversionFactor) => ({
        label: f.tariff,
        value: valueAsLabel ? f.tariff : f.id
      }))}
      error={error}
      isLoading={loading}
      onChange={onChange}
      isClearable
    />
  );
}
