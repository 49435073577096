import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row, CircleIcon, Heading, Text, TextLink, Select } from 'rio-ui-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { SelectEvent, Nullable, Breadcrumb } from '../../types';
import { LeftArrow } from '../NavigationButtons';

const HeadContainer = styled(Row)`
  background: white;
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
`;
const HeadIcon = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;
const HeadTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;
const Seperator = styled(Text)`
  padding: 0 ${(props) => props.theme.geometry.xs.spacing};
`;
const SelectContainer = styled.div<{ characterLength: number }>`
  display: flex;
  flex: 0 0 auto;
  width: ${(props) => props.characterLength * 16 + 100}px;
  .rs__value-container {
    padding-left: 0 !important;
  }

  .rs__single-value {
    font-size: 32px;
    line-height: 1.4em;
    color: #4d505b;
  }
`;
const SelectStyled = styled(Select)`
  background-color: transparent;
  border-radius: 0;
  border: none;
`;
const LeftArrowStyled = styled(LeftArrow)`
  margin-right: 10px;
`;

export interface DropdownItem {
  title: string;
  link: string;
}
interface PageHeaderProps {
  title: string;
  breadcrumbs: Breadcrumb[];
  icon: string;
  iconColor: string;
  name: string;
  children?: React.ReactNode;
  isDropdown?: boolean;
  dropdownItems?: DropdownItem[];
  dropdownDefault?: Nullable<string>;
  leftButtonClick?: () => any;
}

const PageHeader = ({
  name,
  title,
  breadcrumbs,
  icon,
  iconColor,
  isDropdown,
  dropdownItems,
  dropdownDefault,
  children,
  leftButtonClick,
  ...rest
}: PageHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HeadContainer name={`${name}__PageHeader`} container distribution="between" itemAlign="center" {...rest}>
      {leftButtonClick ? <LeftArrowStyled onClick={leftButtonClick} /> : ''}
      <HeadTitleContainer>
        <HeadIcon
          name={`${name}__PageHeader__Icon`}
          inline
          height="50px"
          size="lg"
          circleColor={iconColor}
          iconColor={iconColor}
          icon={icon}
        />
        <Col container item vdistribution="center" span={12}>
          {!!breadcrumbs && (
            <div>
              {breadcrumbs.map((breadcrumb, index) => {
                return (
                  <Fragment key={index}>
                    {breadcrumb.to ? (
                      <TextLink
                        name={`${name}__PageHeader__Breadcrumb__Link`}
                        component="routerLink"
                        inline
                        underline={false}
                        color="dark"
                        size="sm"
                        to={breadcrumb.to}
                      >
                        {breadcrumb.title}
                      </TextLink>
                    ) : (
                      <Text name={`${name}__PageHeader__Breadcrumb__Text`} inline color="dark" size="sm">
                        {breadcrumb.title}
                      </Text>
                    )}
                    {index < breadcrumbs.length - 1 && (
                      <Seperator inline color="dark" size="sm">
                        /
                      </Seperator>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          {!!title && !isDropdown && (
            <Heading name={`${name}__PageHeader__Title`} size="xxl" weight="light" align="left">
              {title}
            </Heading>
          )}
          {isDropdown && dropdownItems && dropdownDefault && (
            <SelectContainer characterLength={dropdownDefault.length}>
              <SelectStyled
                onChange={(event: SelectEvent) => {
                  navigate(location.pathname.replace(/[^/]*$/, event.target.select.link!));
                }}
                value={dropdownDefault}
                options={dropdownItems.map((item) => ({
                  label: item.title,
                  value: item.title,
                  link: item.link,
                }))}
                isSearchable={false}
              />
            </SelectContainer>
          )}
        </Col>
      </HeadTitleContainer>
      {children ? children : null}
    </HeadContainer>
  );
};

PageHeader.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  isDropdown: PropTypes.bool,
  dropdownItems: PropTypes.array,
  dropdownDefault: PropTypes.string,
  breadcrumbs: PropTypes.array,
  icon: PropTypes.string,
  iconColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'info',
    'success',
    'warning',
    'danger',
    'quaternary',
  ]),
};

export default PageHeader;
