import { useMemo } from 'react';
import { Survey } from '@rio/rio-types';
import { FlexContainer, ProgressBarChart } from './ProgressBarChart';
import { calculateScores } from './calculateScores';
import { colors } from './colors';

interface CategoryPerformanceChartProps {
  survey: Survey;
}

// alternate colors to avoid showing same color twice in a row or column
const progressBarColors = [colors.pink, colors.green, colors.yellow, colors.yellow, colors.pink];

export function CategoryPerformanceChart({ survey }: CategoryPerformanceChartProps) {
  const { categoryNames, totalCategoryScores, categoryScores } = useMemo(() => calculateScores(survey), [survey]);

  let colorIndex = 0;
  const colors = progressBarColors;

  return (
    <FlexContainer>
      {categoryNames.map((categoryName, index) => {
        const score = categoryScores[categoryName] || 0;
        const total = totalCategoryScores[categoryName];

        if (colorIndex > colors.length - 1) {
          colorIndex = 0;
        }
        const color = colors[colorIndex];
        colorIndex++;

        return (
          <ProgressBarChart
            key={`catergory__progress-bar-${index}`}
            text={categoryName}
            totals={[score, total]}
            color={color}
          />
        );
      })}
    </FlexContainer>
  );
}
