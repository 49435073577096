import { useQuery } from '@apollo/client';
import { Query, QueryGetReceivedSurveysPageFilterArgs as Variables } from '@rio/rio-types';
import { GET_RECEIVED_SURVEYS_PAGE_FILTER } from './queries';

export type GetReceivedSurveysPageFilterResponse = Pick<Query, 'getReceivedSurveysPageFilter'>;

export function useGetReceivedSurveysPageFilter({ accountId, field }: Variables) {
  return useQuery<GetReceivedSurveysPageFilterResponse, Variables>(GET_RECEIVED_SURVEYS_PAGE_FILTER, {
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      field,
    },
  });
}
