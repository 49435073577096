import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'rio-ui-components';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
  max-width: 150px;
`;

const TextStyled = styled(Text)`
  margin: ${(props) => props.theme.geometry.sm.spacing};
`;

type UpgradeBannerProps = {
  featureName: string;
  link: string;
};

const UpgradeBanner = ({ featureName, link }: UpgradeBannerProps) => {
  const navigate = useNavigate();

  return (
    <>
      <TextStyled>
        <Text>{`Your account does not currently subscribed to ${featureName} functionality.`}</Text>
        <Text>{`If you would like to use ${featureName} to aggregate your tasks and track performance of sustainability initiatives against cost and carbon please contact Rio.`}</Text>
      </TextStyled>
      <ButtonStyled onClick={() => navigate(link)}>Upgrade now</ButtonStyled>
    </>
  );
};

export default UpgradeBanner;
