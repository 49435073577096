import { dromoConfiguration, DromoConfiguration } from './base';
import { validateDependency } from './hooks';
import template from '../MaterialsContainer/UploadContainer/materials-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Materials';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getMaterialsConfiguration(data: any): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
    bulkRowHooks: [(records) => validateDependency(data?.materialMaterialOrigin, ['material', 'origin'], records)],
  };
}
