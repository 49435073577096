export const COURSE_CATEGORY = {
  WASTE: 'WASTE',
  WATER: 'WATER',
  ENERGY: 'ENERGY',
  TRANSPORT: 'TRANSPORT',
  ENVIRONMENTAL_SOCIAL_GOVERNANCE: 'ENVIRONMENTAL_SOCIAL_GOVERNANCE',
  CIRCULAR_ECONOMY: 'CIRCULAR_ECONOMY',
  HEALTH_AND_SAFETY: 'HEALTH_AND_SAFETY',
  HEALTH_AND_WELLBEING: 'HEALTH_AND_WELLBEING'
};

export const CATEGORIES = [
  {
    label: 'Waste',
    value: COURSE_CATEGORY.WASTE
  },
  {
    label: 'Water',
    value: COURSE_CATEGORY.WATER
  },
  {
    label: 'Energy',
    value: COURSE_CATEGORY.ENERGY
  },
  {
    label: 'Transport',
    value: COURSE_CATEGORY.TRANSPORT
  },
  {
    label: 'Environmental Social Governance (ESG)',
    value: COURSE_CATEGORY.ENVIRONMENTAL_SOCIAL_GOVERNANCE
  },
  {
    label: 'Circular Economy',
    value: COURSE_CATEGORY.CIRCULAR_ECONOMY
  },
  {
    label: 'Health & Safety',
    value: COURSE_CATEGORY.HEALTH_AND_SAFETY
  },
  {
    label: 'Health & Wellbeing',
    value: COURSE_CATEGORY.HEALTH_AND_WELLBEING
  }
];
