import { HeatConversionFactor } from '@rio/rio-types';
import { useCallback, useContext } from 'react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { formatDate, gridNumberFormatter } from '../../../utils';
import { HeatFactorsDescription } from './FactorsDescriptions';
import { FactorsGrid } from './FactorsGrid';
import { TabContentContext } from './LocationBasedFactors';
import { TabContent } from '../../../components/TabContent';
import { useRoutes } from '~/hooks';

interface GridProps {
  factors: HeatConversionFactor[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Reporting year',
    field: 'reportingYear',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Generation (kgCO2e)',
    field: 'genKgCo2e',
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Transmission & distribution (kgCO2e)',
    field: 'tdKgCo2e',
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'WTT generation (kgCO2e)',
    field: 'wttGenKgCo2e',
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'WTT transmission and distribution (kgCO2e)',
    field: 'wttTdKgCo2e',
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Start date',
    field: 'startDate',
  },
  {
    headerName: 'End date',
    field: 'endDate',
  },
  {
    headerName: 'Generation (kgCO2)',
    field: 'genKgCo2',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Generation (kgCH4)',
    field: 'genKgCh4',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Generation (kgN2O)',
    field: 'genKgN2o',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Transmission and distribution (kgCO2)',
    field: 'tdKgCo2',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Transmission and distribution (kgCH4)',
    field: 'tdKgCh4',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Transmission and distribution (kgN2O)',
    field: 'tdKgN2o',
    valueFormatter: gridNumberFormatter,
    initialHide: true,
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'reference',
    cellRendererParams: { icon: 'external-link-alt' },
  },
];
export function HeatConversionFactorsGrid({ factors }: GridProps) {
  const tabContentData = useContext(TabContentContext);
  const routes = useRoutes();

  const handleGridReady = useCallback((e: GridReadyEvent) => e.api.sizeColumnsToFit(), []);

  const rowData = factors.map((factor) => ({
    ...factor,
    startDate: formatDate(factor.startDate),
    endDate: formatDate(factor.endDate),
  }));

  return (
    <TabContent {...tabContentData} index={routes.configuration.factors.heat}>
      <div className="ag-theme-alpine">
        <FactorsGrid domLayout="autoHeight" rowData={rowData} onGridReady={handleGridReady} columnDefs={columnDefs} />
      </div>
      <HeatFactorsDescription />
    </TabContent>
  );
}
