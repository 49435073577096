import { useQuery, ApolloError } from '@apollo/client';
import { Select, OptionsProps } from '@rio/ui-components';
import { toSelectOptions } from '~/utils';
import { GET_TREATMENT_PROCESSES } from './index.queries';

interface TreatmentProcessSelectProps {
  value?: string;
  onChange: (e: OptionsProps) => void;
  error?: string;
  label?: string;
}

export const TreatmentProcessSelectV2 = ({ value, onChange, label, error }: TreatmentProcessSelectProps) => {
  const { data, loading, error: queryError } = useQuery(GET_TREATMENT_PROCESSES);
  const currentError = error || queryError;
  const helperText = currentError instanceof ApolloError ? currentError.message : currentError;
  const options = toSelectOptions(data?.getAllWasteTreatmentProcesses);

  return (
    <Select
      placeholder="Please select treatment process"
      value={value}
      label={label}
      isLoading={loading}
      options={options}
      error={!!currentError}
      helperText={helperText || ''}
      onChange={(e) => onChange(e)}
    />
  );
};
