import { gql } from '@apollo/client';
import CHAPTER_FIELDS from '../../graphql/fragments/ChapterFields.fragment';
import CHAPTER_FIELDS_NESTING from '../../graphql/fragments/ChapterFieldsNesting.fragment';
import DOCUMENT_FIELDS from '../../graphql/fragments/DocumentFields.fragment';

export const CREATE_DOCUMENT_RECORD = gql`
  mutation CreateDocumentRecord(
    $id: ID!
    $accountId: ID!
    $key: String
    $userId: ID!
    $category: String!
    $type: [String!]
    $supplierId: String
    $fileName: String!
    $library: DocumentLibrary!
    $referenceId: String
    $reviewDate: String
    $managementSystemCode: String
    $managementSystemId: ID
    $managementSystemChapterId: ID
    $source: Source!
    $link: String
    $tags: [String!]
    $notes: String
  ) {
    createDocumentRecord(
      input: {
        id: $id
        userId: $userId
        accountId: $accountId
        key: $key
        category: $category
        type: $type
        supplierId: $supplierId
        fileName: $fileName
        library: $library
        referenceId: $referenceId
        reviewDate: $reviewDate
        managementSystemCode: $managementSystemCode
        managementSystemId: $managementSystemId
        managementSystemChapterId: $managementSystemChapterId
        link: $link
        source: $source
        tags: $tags
        notes: $notes
      }
    ) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FIELDS}
`;

export const GET_DOCUMENT = gql`
  query GetDocumentRecordById($id: ID!, $accountId: String) {
    getDocumentRecordById(id: $id, accountId: $accountId) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FIELDS}
`;

export const DELETE_DOCUMENT_RECORD = gql`
  mutation DeleteDocumentRecord($id: ID!) {
    deleteDocumentRecord(id: $id)
  }
`;

export const GET_MANAGEMENT_SYSTEM_BY_ID_WITH_SIBLINGS = gql`
  query GetManagementSystemById($id: ID!, $accountId: ID!) {
    getManagementSystemById(id: $id, accountId: $accountId) {
      id
      title
      synopsis
      shortCode
      documents {
        ...DocumentFields
      }
      chapters {
        ...ChapterFields
        ... on ManagementSystemChapter {
          chapters {
            ...ChapterFields
            ...ChapterFieldsNesting
          }
        }
      }
    }
    getAvailableManagementSystems(accountId: $accountId) {
      id
      title
    }
  }
  ${DOCUMENT_FIELDS}
  ${CHAPTER_FIELDS}
  ${CHAPTER_FIELDS_NESTING}
`;

export const ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM = gql`
  mutation AttachDocumentToManagementSystem($systemId: ID!, $documentId: ID!, $accountId: ID!, $linkId: ID!) {
    attachDocumentToManagementSystem(
      input: { id: $systemId, documentId: $documentId, accountId: $accountId, linkId: $linkId }
    )
  }
`;

export const GET_DOCUMENTS_COUNT = gql`
  query GetDocumentsCount(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 1
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getDocumentsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
    }
  }
`;
