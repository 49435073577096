import { useEffect } from 'react';

export function useBeforeUnload(condition: boolean) {
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (condition) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [condition]);
}
