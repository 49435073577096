import { gql } from '@apollo/client';

export const GET_LOCATIONS_BY_ACCOUNT = gql`
  query GetLocationsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getLocationsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;
