import { toNumeric } from '../toNumeric';
import { isNumeric } from '../isNumeric';
import { forIn } from 'lodash';

export const calculateWasteTotalCost = (data) => {
  // could be in a string, firstly convert to numbers
  forIn(data, (value, key) => (data[key] = isNumeric(value) ? toNumeric(value) : 0));

  const { gateFee = 0, quantity = 0, managementFee = 0, binHire = 0, binExchangeFee = 0, numberOfCollections = 0, transportCharge = 0, liftCharge = 0, equipmentHire = 0, miscCost = 0, miscCostRate = 0, rebate = 0 } = data;

  return gateFee * quantity + managementFee + binHire + binExchangeFee * numberOfCollections + transportCharge + liftCharge * numberOfCollections + equipmentHire + miscCost + miscCostRate * quantity + rebate * quantity;
};
