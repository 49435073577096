import React from 'react';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { Modal, Button, Heading } from 'rio-ui-components';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 20px;
`;

const ButtonStyled = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const HeadingStyled = styled(Heading)`
  padding: 30px 30px 0px 30px;
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.colors.neutral.dark.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 30px;
`;

interface DeleteModalProps {
  deleteObjectName?: string;
  onDismissClick: () => void;
  onConfirmClick: () => void;
}

export const DeleteModal = ({ onDismissClick, onConfirmClick, deleteObjectName }: DeleteModalProps) => {
  return (
    <Modal height="auto" maxHeight="80vh" show onDismiss={onDismissClick}>
      <HeadingStyled>Delete {capitalize(deleteObjectName)}</HeadingStyled>
      <TextContainer>
        This action will permanently delete this {deleteObjectName}. Do you want to proceed?
      </TextContainer>
      <ButtonRow>
        <ButtonStyled onClick={onConfirmClick} color={'danger'} data-testid="Confirm_Deletion_Button">
          Delete
        </ButtonStyled>
        <ButtonStyled onClick={onDismissClick} data-testid="Abort_Deletion_Button">
          Cancel
        </ButtonStyled>
      </ButtonRow>
    </Modal>
  );
};
