import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/engage/GetCourseById.query.graphql';
import { useCurrentAccountId } from '../useCurrentAccountId';

export function useGetAccountCourseById(courseId, options = {}) {
  const accountId = useCurrentAccountId();

  const { data: { getCourseById } = {}, ...others } = useQuery(QUERY, {
    variables: {
      accountId,
      id: courseId
    },
    ...options
  });

  return { data: getCourseById, ...others };
}
