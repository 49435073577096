import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_LOCATION_SELECT_OPTIONS } from './index.queries';

type Response = Pick<
  Query,
  'getAccountInfo' | 'getTagsByAccountId' | 'getAllCountries' | 'getLocationMetadataOptionsForAccount'
>;

export function useLocationSelectOptions(accountId: string) {
  return useQuery<Response>(GET_LOCATION_SELECT_OPTIONS, {
    variables: {
      accountId,
      accountIdAsString: accountId, // Workaround to a mismatch between ID! and String! types
    },
  });
}
