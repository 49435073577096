import { useCallback, useEffect, useState, useMemo } from 'react';
import { ReceivedSurvey, User } from '@rio/rio-types';
import { Search } from 'rio-ui-components';
import { useNavigate } from 'react-router-dom';
import { TextField, styled } from '@rio/ui-components';
import { DataType, Maybe, SetFilter } from '@rio/rio-types';
import { useCurrentAccountId, useCurrentUserId } from '../../../hooks';
import { EmptySurveys } from '../SurveyOverviewContainer/SurveyOverviewV2';
import { useGetReceivedSurveysPage } from './queries/useGetReceivedSurveysPage';
import { StatusFilterV2 as StatusFilter } from './StatusFilterV2';
import { useDebounce } from 'use-debounce';
import { throttle } from 'lodash';
import { ReceivedSurveysVirtualList } from './ReceivedSurveysVirtualListV2';
import { SurveyLockWarningModal } from './SurveyLockWarningModalV2';

export const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SearchContainer = styled(Search)`
  width: 100%;
  font-family: ${({ theme }) => theme.sys.typescale.label.large.family};
`;

function getSetFilters(status) {
  const filters: Maybe<Array<SetFilter>> = [];
  const activeStatus = status === 'not_started' ? null : status;
  if (status) {
    filters.push({
      field: 'submissionStatus',
      filterType: DataType.Text,
      values: [activeStatus],
    });
  }

  return filters;
}

const PageContainer = styled('div')`
  padding: 8px 0 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 24px;
`;

const StatusFilterContainer = styled('div')`
  width: 300px;
`;

export const ReceivedSurveysContainerV2 = () => {
  const pageSize = 20;
  const accountId = useCurrentAccountId();
  const userId = useCurrentUserId();
  const [surveys, setSurveys] = useState<ReceivedSurvey[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 600);
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState<string>('');

  const variables = useMemo(
    () => ({
      accountId,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      filters: {
        search: {
          field: ['name'],
          value: debouncedSearchValue,
        },
        set: getSetFilters(submissionStatus),
      },
    }),
    [accountId, pageSize, currentPage, debouncedSearchValue, submissionStatus]
  );

  const result = useGetReceivedSurveysPage(variables);

  const navigate = useNavigate();
  const handleSurveyClick = useCallback((id: string) => navigate(`./${id}`), [navigate]);

  useEffect(() => {
    if (result?.data?.getReceivedSurveysPage?.totalRows && !result.loading) {
      setTotalPages(Math.ceil(result.data.getReceivedSurveysPage.totalRows / pageSize));
      setSurveys((prevSurveys) => [...prevSurveys, ...(result?.data?.getReceivedSurveysPage?.rows || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.loading]);

  useEffect(() => {
    if (result?.data?.getReceivedSurveysPage && !result.loading && (debouncedSearchValue || submissionStatus)) {
      setTotalPages(Math.ceil(result.data.getReceivedSurveysPage.totalRows / pageSize));
      setSurveys(result?.data?.getReceivedSurveysPage?.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.loading, debouncedSearchValue, submissionStatus]);

  useEffect(() => {
    setSurveys([]);
    setCurrentPage(1);
  }, [submissionStatus, debouncedSearchValue]);

  const incrementCurrentPageThrottled = useMemo(
    () =>
      throttle(
        () => {
          if (!result.loading) {
            setCurrentPage((state) => state + 1);
          }
        },
        400,
        { trailing: false }
      ),
    [result.loading]
  );

  const handleScrollEnd = useMemo(
    () =>
      throttle(
        () => {
          const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

          if (!result.loading && totalPages > currentPage && isAtBottom) {
            incrementCurrentPageThrottled();
          }
        },
        400,
        { leading: false }
      ),
    [totalPages, currentPage, result.loading, incrementCurrentPageThrottled]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEnd);
    return () => {
      handleScrollEnd.cancel();
      window.removeEventListener('scroll', handleScrollEnd);
    };
  }, [handleScrollEnd]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const [lockedModalShown, setLockedModalShown] = useState(false);
  const [lock, setLock] = useState<User | null>(null);
  const toggleLockedModal = useCallback(() => setLockedModalShown((shown) => !shown), []);

  const emptySurveys = result.loading ? null : <EmptySurveys />;

  return (
    <WrapperStyled>
      <PageContainer>
        <TextField
          name="ConfigurationAccountsContainer__Controls__Search"
          type="search"
          value={searchValue}
          onChange={handleSearchChange}
          error={false}
          placeholder="Search"
          style={{ width: '300px' }}
        />
        <StatusFilterContainer>
          <StatusFilter accountId={accountId} status={submissionStatus} setStatus={setSubmissionStatus} />
        </StatusFilterContainer>
        <SurveyLockWarningModal open={lockedModalShown} onClose={toggleLockedModal} lockedBy={lock} />
      </PageContainer>
      {surveys.length ? (
        <ReceivedSurveysVirtualList
          surveys={surveys}
          toggleLockedModal={toggleLockedModal}
          userId={userId}
          setLock={setLock}
          handleSurveyClick={handleSurveyClick}
        />
      ) : (
        emptySurveys
      )}
    </WrapperStyled>
  );
};
