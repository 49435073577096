import styled from 'styled-components';
import { Heading, Text, PreContainer } from 'rio-ui-components';
import _ from 'lodash';
import { DataImportError } from '@rio/rio-types';

const PreContainerStyled = styled(PreContainer)`
  height: auto;
`;
const Subtitle = styled(Heading)`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const RowOfErrors = styled.div`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const Ul = styled.ul`
  list-style-type: disc;
  margin-bottom: ${(p) => p.theme.geometry.xs.spacing};
`;
const Li = styled.li`
  margin-left: ${(p) => p.theme.geometry.md.spacing};
`;

interface ValidationErrorsProps {
  errors: DataImportError[];
  errorsCount: number;
}
type SplitError = DataImportError & {
  row?: string;
};

export function ValidationErrors({ errors, errorsCount }: ValidationErrorsProps) {
  // split errors by single row
  const splitErrors: SplitError[] = errors.filter(Boolean).reduce((acc: SplitError[], error) => {
    const rows = error.rows?.split(',');
    if (rows?.length) {
      return acc.concat(
        rows.map((row) => ({
          ...error,
          row,
        }))
      );
    }
    return acc.concat([error]);
  }, []);

  const rowsWithErrorsNumber = Object.keys(_(splitErrors).groupBy('row').value()).length;

  return (
    <PreContainerStyled size="md" wrap>
      <Subtitle size="lg">
        Showing {errorsCount} errors in {rowsWithErrorsNumber} rows:
      </Subtitle>
      {_(splitErrors)
        .groupBy('row')
        .map((importErrors: DataImportError[], row) => (
          <RowOfErrors key={row}>
            <Heading size="md">Row {row}:</Heading>
            {_(importErrors)
              .filter(Boolean)
              .groupBy((e) => _.capitalize(e.fieldName!) || 'Other errors')
              .map((err, field) => (
                <Ul key={field}>
                  <Heading size="sm">{field}:</Heading>
                  {err.map((e) => (
                    <Li key={JSON.stringify(e)}>
                      {e.message?.includes('Something unexpected happened') || e.message?.includes('0 rows') ? (
                        <Text weight={!e.actionRequired ? 'bold' : 'normal'}>{e.message}</Text>
                      ) : (
                        <Text
                          weight={!e.actionRequired ? 'bold' : 'normal'}
                        >{`You've provided: "${e.value}". ${e.message}`}</Text>
                      )}
                      {e.actionRequired && <Text>Field can be aliased.</Text>}
                    </Li>
                  ))}
                </Ul>
              ))
              .value()}
          </RowOfErrors>
        ))
        .value()}
    </PreContainerStyled>
  );
}
