import { useQuery } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import { DataType, FilterStrategy, JoinOperator, Query, User, UserRole } from '@rio/rio-types';
import { OptionType, MultiSelect } from '@rio/ui-components';
import { GET_USERS } from '../../../containers/ConfigurationContainer/ConfigurationUsersContainer/index.queries';

interface UsersMultiSelectProps {
  accountId: string;
  value: OptionType[] | User[] | string[];
  onChange: (selectedUsers: OptionType[]) => void;
  error?: string;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
}

const UsersMultiSelect: FC<UsersMultiSelectProps> = ({
  accountId,
  value,
  onChange,
  error,
  name = 'userIds',
  loading,
  label = 'Users',
  disabled,
}) => {
  const finalValue = useMemo(
    () =>
      value.map((val) => {
        if (typeof value === 'string') {
          return val;
        }

        return {
          value: val.value || val.id,
          label: val.label || `${val.firstName || val.first_name} ${val.lastName || val.last_name}`,
        };
      }),
    [value]
  );

  const {
    data,
    loading: loadingUsers,
    error: usersLoadingError,
  } = useQuery<{ getUsers: Query['getUsers'] }>(GET_USERS, {
    variables: {
      accountId,
      filters: {
        text: [
          {
            filterType: DataType.Text,
            field: 'fullName',
            type: FilterStrategy.Contains,
            filter: '',
          },
          {
            filterType: DataType.Text,
            operator: JoinOperator.Or,
            condition1: {
              filterType: DataType.Text,
              field: 'accountId',
              type: FilterStrategy.Equals,
              filter: accountId,
            },
            condition2: {
              filterType: DataType.Text,
              field: 'role',
              type: FilterStrategy.Equals,
              filter: UserRole.Consultant,
            },
          },
        ],
      },
      sort: [{ field: 'firstName', order: 'asc' }],
    },
  });

  const options = useMemo(
    () =>
      data?.getUsers?.rows.map((user) => ({
        value: user.id.toLowerCase(),
        label: `${user.firstName} ${user.lastName}`,
      })) || [],
    [data]
  );
  const errorMessage = usersLoadingError?.message || error || '';

  return (
    <MultiSelect
      name={name}
      label={label}
      isLoading={loadingUsers || loading}
      disabled={loading || disabled}
      onChange={onChange}
      value={finalValue}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      options={options}
    />
  );
};

export { UsersMultiSelect };
