import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import * as V1 from '../containers/AppContainer/v1';
import * as V2 from '../containers/AppContainer/v2';
import LoginContainer from '../containers/LoginContainer';
import SSOLoginContainer from '../containers/LoginContainer/SSO';
import { V2LoginContainer } from '../containers/LoginContainer/v2/V2LoginContainer';
import { RegistrationContainer as RegistrationContainerV2 } from '../containers/RegistrationContainer/v2/RegistrationContainer';
import RegistrationContainer, { VerifyEmail } from '../containers/RegistrationContainer';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';
import V2ResetPassword from '../containers/ResetPasswordContainer/V2ResetPassword';
import SetPasswordContainer from '../containers/SetPasswordContainer';
import { staticRoutes } from '../hooks';
import { AllProviders } from './AllProviders';
import { PublicRoute, ProtectedRoute } from './Utils';
import { ErrorElement, ErrorBoundary } from '../components';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AllProviders />}>
      <Route
        path={staticRoutes.registration.verify}
        element={<PublicRoute component={VerifyEmail} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={`${staticRoutes.registration.verify}/:code`}
        element={<PublicRoute component={VerifyEmail} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={staticRoutes.registration.setPassword}
        element={<PublicRoute component={SetPasswordContainer} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={staticRoutes.registration.resetPassword}
        element={<PublicRoute component={ResetPasswordContainer} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={`${staticRoutes.registration.register}/*`}
        element={<PublicRoute component={RegistrationContainer} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={`${staticRoutes.registration.login}`}
        element={<PublicRoute component={LoginContainer} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={`${staticRoutes.registration.login}/:providerId`}
        element={<PublicRoute component={SSOLoginContainer} />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={`v2/${staticRoutes.registration.login}`}
        element={<PublicRoute component={V2LoginContainer} />}
        errorElement={<ErrorElement />}
      />
      <Route
        path={`v2/${staticRoutes.registration.register}/*`}
        element={<PublicRoute component={RegistrationContainerV2} />}
        errorElement={<ErrorElement />}
      />
      <Route
        path={`v2/${staticRoutes.registration.resetPassword}`}
        element={<PublicRoute component={V2ResetPassword} />}
        errorElement={<ErrorElement />}
      />
      <Route path={`v2/*`} element={<V2.AppContainer />} loader={V2.loader} errorElement={<ErrorElement />}>
        <Route path="*" element={<V2.AppRoutes />} errorElement={<ErrorElement />} />
      </Route>
      <Route
        path={`*`}
        element={<ProtectedRoute component={V1.AppContainer} redirectUrl={staticRoutes.registration.login} />}
        loader={V1.loader}
        errorElement={<ErrorBoundary />}
      >
        <Route path="*" element={<V1.AppRoutes />} errorElement={<ErrorBoundary />} />
      </Route>
    </Route>
  )
);

export { router };
