"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_material_1 = require("@mui/icons-material");
const styles_1 = require("@mui/material-next/styles");
const Grid_1 = require("../Grid");
const Text_1 = require("../Text");
const Button_1 = require("../Button");
const Modal_1 = require("../Modal");
const react_1 = require("react");
const ButtonsContainer = (0, styles_1.styled)(Grid_1.Grid) `
  margin-top: 12px;
`;
const IconContainer = (0, styles_1.styled)(icons_material_1.ReportProblemOutlined) `
  fill: ${({ theme }) => theme.sys.color.error};
`;
const Stack = (0, styles_1.styled)('div') `
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
`;
function ErrorLayout({ title, error, retry }) {
    const [detailsOpen, setDetailsOpen] = (0, react_1.useState)(false);
    const toggleDetailsOpen = (0, react_1.useCallback)(() => {
        setDetailsOpen((v) => !v);
    }, [setDetailsOpen]);
    const onRetryClick = (0, react_1.useCallback)(() => {
        if (retry) {
            retry();
        }
        else {
            window.location.reload();
        }
    }, [retry]);
    return ((0, jsx_runtime_1.jsxs)(Grid_1.Grid, { container: true, xs: 12, spacing: 1, direction: "column", alignItems: "center", justifyContent: "center", children: [(0, jsx_runtime_1.jsx)(Grid_1.Grid, { children: (0, jsx_runtime_1.jsx)(IconContainer, {}) }), (0, jsx_runtime_1.jsx)(Grid_1.Grid, { children: (0, jsx_runtime_1.jsx)(Text_1.Text, { typescale: "title", size: "medium", children: title }) }), error && ((0, jsx_runtime_1.jsxs)(ButtonsContainer, { container: true, direction: "row", spacing: 3, children: [(0, jsx_runtime_1.jsx)(Grid_1.Grid, { item: true, children: (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: "outlined", color: "secondary", onClick: onRetryClick, children: "Retry" }) }), (0, jsx_runtime_1.jsx)(Grid_1.Grid, { item: true, children: (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: "outlined", color: "secondary", onClick: toggleDetailsOpen, children: "Details" }) })] })), (0, jsx_runtime_1.jsx)(Modal_1.Modal, { title: error?.message, open: detailsOpen, onClose: toggleDetailsOpen, children: error?.stack && (0, jsx_runtime_1.jsx)(Stack, { children: error?.stack }) })] }));
}
exports.ErrorLayout = ErrorLayout;
