import React from 'react';
import styled from 'styled-components';
import { MODALS, Nullable } from '../../../../types';
import { usePermissions } from '../../../../hooks';
import { Icon } from 'rio-ui-components';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

export type ActionFunction = (type?: Nullable<string>, schemeId?: Nullable<string>) => void;

export interface ActionCellProps {
  data: {
    id: string;
  };
  onEdit: ActionFunction;
  onDelete: ActionFunction;
}

export const ActionCell = ({ data: { id }, onEdit, onDelete }: ActionCellProps) => {
  const permissions = usePermissions();
  const canEditSchemes = permissions.scheme.find((action: string) => action.startsWith('edit'));
  const canDeleteSchemes = permissions.scheme.find((action: string) => action.startsWith('delete'));

  return (
    <GridCellContainer>
      {canEditSchemes && (
        <LinkStyled onClick={() => onEdit(MODALS.UPDATE, id)}>
          <Icon name={`scheme-row__edit--${id}`} icon="pencil-alt" />
        </LinkStyled>
      )}
      {canDeleteSchemes && (
        <LinkStyled onClick={() => onDelete(MODALS.DELETE, id)}>
          <Icon name={`scheme-row__delete--${id}`} icon="trash-alt" />
        </LinkStyled>
      )}
    </GridCellContainer>
  );
};
