import { getPathFromType } from '../../../../utils/managementSystemUtils';

const createLink = (type, managementSystemId, chapterId = '') => {
  return `${getPathFromType(type)}/${managementSystemId}/${chapterId}`;
};

export const constructNavigationMenu = (managementSystem, managementSystemTree) => {
  if (!managementSystem) {
    return [];
  }
  const chapters = managementSystem.chapters.map((chapter) => ({
    heading: managementSystemTree[chapter.id].tocTitle,
    link: createLink(managementSystem.type, managementSystem.id, chapter.id),
    id: chapter.id,
    children: chapter.chapters?.map((clause) => ({
      heading: managementSystemTree[clause.id].tocTitle,
      link: createLink(managementSystem.type, managementSystem.id, clause.id),
      id: clause.id,
      children: clause.chapters?.map((subClause) => ({
        heading: managementSystemTree[subClause.id].tocTitle,
        link: createLink(managementSystem.type, managementSystem.id, subClause.id),
        id: subClause.id,
        children: []
      }))
    }))
  }));
  return [
    {
      heading: managementSystem.title,
      link: createLink(managementSystem.type, managementSystem.id),
      id: managementSystem.id,
      children: chapters,
      expanded: true
    }
  ];
};
