import { gql } from '@apollo/client';

export const GET_TRANSPORT_CONVERSION_FACTORS = gql`
  query getTransportConversionFactors($filters: Filters, $limit: Int = 100, $offset: Int, $sort: [SortCommand!]) {
    getTransportConversionFactors(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      rows {
        id
        startDate
        endDate
        reportingYear
        fromUnit
        toUnit
        value
        scope
        type
        reference
        note
        transportType
        transportSubtype
        fuelType
      }
      totalRows
    }
  }
`;

export const GET_TRANSPORT_CONVERSION_FACTORS_FILTER = gql`
  query getTransportConversionFactorsFilter($field: String!) {
    getTransportConversionFactorsFilter(field: $field) {
      label
      value
    }
  }
`;
