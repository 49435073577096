import React, { useCallback, useState } from 'react';
import jwtDecode from 'jwt-decode';
import strip from 'strip';
import isNumeric from 'validator/es/lib/isNumeric';
import SEND_CONTACT_EMAIL from '../../../graphql/mutations/contact/sendEmail.mutation.graphql';
import { useNotification } from '~/hooks';
import { Button, Text, TextField, Select, styled, TextArea } from '@rio/ui-components';
import { useMutation } from '@apollo/client';

const MainContainerStyled = styled('div')`
  width: 100%;
  max-width: 800px;
`;

const TitleStyled = styled(Text)`
  margin-bottom: 32px;
`;

const FormStyled = styled('form')`
  width: 100%;
  display: flex;
  gap: 36px;
`;

const TextFieldsContainerStyled = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 36px;
`;

const initialInputState = {
  fullName: {
    value: '',
    error: '',
  },
  companyName: {
    value: '',
    error: '',
  },
  phoneNumber: {
    value: '',
    error: '',
  },
  subject: {
    value: '',
    error: '',
  },
  enquiry: {
    value: '',
    error: '',
  },
};

export const EmailForm = () => {
  const { showNotification } = useNotification();

  const [inputValues, setInputValues] = useState(initialInputState);

  const [sendEmail, { loading: isSendingEmail }] = useMutation(SEND_CONTACT_EMAIL, {
    onCompleted: () => {
      showNotification('Your enquiry has been sent!', 'success');
      setInputValues(initialInputState);
    },
    onError: () => {
      showNotification('Something went wrong. If the problem persists, contact support.', 'danger');
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const newFullNameState = { ...inputValues.fullName };
      const newCompanyNameState = { ...inputValues.companyName };
      const newPhoneNumberState = { ...inputValues.phoneNumber };
      const newSubjectState = { ...inputValues.subject };
      const newEnquiryState = { ...inputValues.enquiry };

      newFullNameState.error = !inputValues.fullName.value ? 'Please enter your full name.' : '';
      newCompanyNameState.error = !inputValues.companyName.value ? 'Please enter a valid company name.' : '';
      newPhoneNumberState.error = !inputValues.phoneNumber.value
        ? 'Please enter a valid phone number.'
        : !isNumeric(inputValues.phoneNumber.value)
        ? 'Only numbers are valid in this field.'
        : '';
      newEnquiryState.error = !inputValues.enquiry.value
        ? 'Please enter an enquiry.'
        : inputValues.enquiry.value.length > 1000
        ? 'You have exceeded the character limit.'
        : '';
      newSubjectState.error = !inputValues.subject.value ? 'Please choose an option.' : '';

      setInputValues({
        fullName: newFullNameState,
        companyName: newCompanyNameState,
        phoneNumber: newPhoneNumberState,
        subject: newSubjectState,
        enquiry: newEnquiryState,
      });

      if (
        !newFullNameState.error &&
        !newCompanyNameState.error &&
        !newPhoneNumberState.error &&
        !newEnquiryState.error &&
        !newSubjectState.error
      ) {
        const replace = {
          name: strip(inputValues.fullName.value),
          company: strip(inputValues.companyName.value),
          email: jwtDecode<{ email: string }>(localStorage.getItem('ID_TOKEN') as string).email,
          phone: strip(inputValues.phoneNumber.value),
          enquiry: strip(inputValues.enquiry.value),
          topic: strip(inputValues.subject.value),
        };

        sendEmail({
          variables: {
            replacements: JSON.stringify(replace),
          },
        });
      }
    },
    [inputValues, sendEmail]
  );

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setInputValues({
        ...inputValues,
        [name]: { value: value },
      });
    },
    [inputValues]
  );

  const handleSelectChange = useCallback(
    (option, name) => {
      const event = {
        target: {
          name,
          value: option.value,
        },
      };

      handleInputChange(event);
    },
    [handleInputChange]
  );

  return (
    <MainContainerStyled>
      <TitleStyled typescale="title" size="large">
        Send us a message
      </TitleStyled>
      <FormStyled name="email-form" onSubmit={handleSubmit}>
        <TextFieldsContainerStyled>
          <TextField
            name="fullName"
            onChange={handleInputChange}
            value={inputValues.fullName.value}
            helperText={inputValues.fullName.error}
            error={Boolean(inputValues.fullName.error)}
            placeholder="John Doe"
            label="Full name"
          />
          <TextField
            name="companyName"
            onChange={handleInputChange}
            value={inputValues.companyName.value}
            helperText={inputValues.companyName.error}
            error={Boolean(inputValues.companyName.error)}
            placeholder="Rio ESG LTD"
            label="Company name"
          />
          <TextField
            name="phoneNumber"
            onChange={handleInputChange}
            value={inputValues.phoneNumber.value}
            helperText={inputValues.phoneNumber.error}
            error={Boolean(inputValues.phoneNumber.error)}
            placeholder="07000000000"
            label="Phone number"
          />
          <Select
            name="subject"
            options={[
              { value: 'comply', label: 'Comply' },
              { value: 'data', label: 'Data' },
              { value: 'learn', label: 'Engage' },
              { value: 'other', label: 'Other' },
              { value: 'reporting', label: 'Reporting' },
              { value: 'upgrades', label: 'Upgrades' },
            ]}
            onChange={(event) => handleSelectChange(event, 'subject')}
            value={inputValues.subject.value}
            helperText={inputValues.subject.error}
            error={Boolean(inputValues.subject.error)}
            placeholder="Please choose"
            label="What is your enquiry about?"
          />
        </TextFieldsContainerStyled>
        <TextFieldsContainerStyled>
          <TextArea
            name="enquiry"
            onChange={handleInputChange}
            value={inputValues.enquiry.value}
            helperText={inputValues.enquiry.error}
            error={Boolean(inputValues.enquiry.error)}
            placeholder="Please type your message here"
            label="Enquiry"
            rows={9}
          />
          <Button
            type="submit"
            variant="filled"
            color="primary"
            sx={{ width: '100%' }}
            loading={isSendingEmail}
            disabled={isSendingEmail}
          >
            Send enquiry
          </Button>
        </TextFieldsContainerStyled>
      </FormStyled>
    </MainContainerStyled>
  );
};
