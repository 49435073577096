import { TransactionType } from '@rio/rio-types';
import { useState } from 'react';
import { useCurrentAccountId, useUploadFileMutation, useBeforeUnload } from '../../hooks';
import { MassUploadModal } from '../MassUploadModal';
import { getHeaders } from '../../utils/excelUtils';
import TemplateScreen from './TemplateScreen';
import { DEFAULT_TRANSPOSITION_ID } from './TemplateScreen/constants';
import IsDirectActivityModal from './IsDirectActivityModal';

const { Electricity, Gas, Transport, Fuel } = TransactionType;

type VariablesType = {
  transactionType: string;
  fileName?: string | null;
  isDirectActivity?: boolean;
  transpositionId?: string;
};

type StateType = {
  transpositionHeaders: null | ReturnType<typeof getHeaders>;
  error: null | string;
  file: null | File;
  transpositionId: null | string;
  showIsDirectActivityModal: boolean;
  isDirectActivity: null | boolean;
};

const UploadProcess = ({ template, transactionType, onComplete, onDismiss }) => {
  const accountId = useCurrentAccountId();
  const [state, setState] = useState<StateType>({
    transpositionHeaders: null,
    error: null,
    file: null,
    transpositionId: null,
    showIsDirectActivityModal: false,
    isDirectActivity: null,
  });
  const updateState = (newState) => setState((prevState) => ({ ...prevState, ...newState }));

  const { file, transpositionId, showIsDirectActivityModal, transpositionHeaders } = state || {};

  const [uploadFile, { loading: isFileUploading }] = useUploadFileMutation();

  const isTransactionDirect = [Electricity, Gas, Transport, Fuel].includes(transactionType);

  const shouldShowTranspositions = (selectedFile, selectedTranspositionId) =>
    selectedFile && selectedTranspositionId === null;

  const isFileReadyToUpload = (
    selectedFile: unknown,
    selectedShowIsDirectActivityModal: boolean,
    isDirectActivity: boolean,
    selectedTranspositionId: string | null
  ) => {
    const isDirectActivityDefined = !selectedShowIsDirectActivityModal || isDirectActivity !== null;
    const notShowingTranspositions = !shouldShowTranspositions(selectedFile, selectedTranspositionId);
    return selectedFile && isDirectActivityDefined && notShowingTranspositions;
  };

  useBeforeUnload(isFileUploading);

  const handleUploadClick = async (uploadData) => {
    // Determine whether the modal for confirming direct/indirect transactions should be shown,
    // and if so, update the state to display the modal.
    const { isDirectActivity, transpositionId: selectedTranspositionId = transpositionId } = uploadData || {};

    if (selectedTranspositionId) {
      updateState({ transpositionId: selectedTranspositionId });
    }

    const shouldShowDirectActivityModal =
      isTransactionDirect && file && selectedTranspositionId && !showIsDirectActivityModal;
    if (shouldShowDirectActivityModal) {
      updateState({ showIsDirectActivityModal: true });
      return;
    }

    // If the file is ready to upload, construct the necessary variables and initiate the upload.
    if (isFileReadyToUpload(file, showIsDirectActivityModal, isDirectActivity, selectedTranspositionId)) {
      const variables: VariablesType = {
        transactionType,
        fileName: file?.name,
      };

      if (isDirectActivity !== null) {
        variables.isDirectActivity = isDirectActivity;
      }

      if (selectedTranspositionId && selectedTranspositionId !== DEFAULT_TRANSPOSITION_ID) {
        variables.transpositionId = selectedTranspositionId;
      }

      try {
        updateState({ transpositionId: selectedTranspositionId, isDirectActivity });
        await uploadFile({ ...variables, file });
        onComplete();
      } catch (error) {
        updateState({ error });
      } finally {
        updateState({ file: null, showIsDirectActivityModal: false });
      }
    }
  };

  const AnyTemplateScreen: any = TemplateScreen;

  return (
    <MassUploadModal
      isUploading={isFileUploading}
      errors={null}
      size={'lg'}
      accept=".xlsx, .xls, .csv"
      template={template}
      onFileInput={async ([selectedFile]) => {
        const selectedTranspositionheaders = await getHeaders(selectedFile);
        updateState({
          file: selectedFile,
          transpositionHeaders: selectedTranspositionheaders,
        });
      }}
      onDismiss={onDismiss}
    >
      {showIsDirectActivityModal ? (
        <IsDirectActivityModal
          onConfirm={(isDirectActivity) => {
            handleUploadClick({ isDirectActivity });
          }}
          type={transactionType}
        />
      ) : (
        shouldShowTranspositions(file, transpositionId) && (
          <AnyTemplateScreen
            fileName={file?.name}
            headers={transpositionHeaders}
            template={template}
            onTemplateSelect={(transposition) => {
              handleUploadClick({ transpositionId: transposition.id });
            }}
            accountId={accountId}
            type={transactionType}
          />
        )
      )}
    </MassUploadModal>
  );
};

export default UploadProcess;
