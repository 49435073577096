import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_PROJECT_CATEGORIES } from '../../graphql/queries/projectCategory/GetProjectCategories.query';
import { namedEntityToSelectOption } from '../../utils';
import { Nullable, Option } from '../../types';

interface ProjectCategorySelectProps {
  name?: string;
  value: Nullable<string | Option>;
  onChange: (e: React.ChangeEvent<{ value: string; label: string }>) => void;
  readOnly?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  className?: string;
}

export const ProjectCategorySelect = ({
  name,
  value,
  onChange,
  readOnly,
  isClearable,
  isDisabled,
  className
}: ProjectCategorySelectProps) => {
  const { data, loading, error } = useQuery<Pick<Query, 'getProjectCategories'>>(GET_PROJECT_CATEGORIES);

  const categoryOptions = useMemo(() => {
    return data?.getProjectCategories?.map(namedEntityToSelectOption) || [];
  }, [loading]);

  return (
    <Select
      defaultOptions
      size="md"
      name={name || 'category'}
      value={value}
      onChange={onChange}
      placeholder="Please select scheme category"
      options={categoryOptions}
      error={error}
      isLoading={loading}
      readOnly={readOnly || false}
      isDisabled={isDisabled || loading}
      isClearable={isClearable || false}
      className={className}
    />
  );
};
