export const COURSE = 'course';
export const COURSE_BUNDLE = 'courseBundle';
export const SUBSCRIPTION = 'subscription';
export const MANAGEMENT_SYSTEM = 'managementSystem';
export const ACCOUNT_CAPACITY = 'accountCapacity';

export const marketProductType = {
  COURSE,
  COURSE_BUNDLE,
  SUBSCRIPTION,
  MANAGEMENT_SYSTEM,
  ACCOUNT_CAPACITY
};