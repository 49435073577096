import { useQuery } from '@apollo/client';
import { AccountType } from '@rio/rio-types';
import { useCurrentUserId } from './useCurrentUserId';
import { GET_USER_BY_USERNAME } from '../queries/users';
import { cloneDeep } from 'lodash';

export function useGetCurrentUserQuery() {
  const userId = useCurrentUserId();
  const { data: { getUserInfoById } = {}, ...other } = useQuery(GET_USER_BY_USERNAME, { variables: { name: userId } });

  let data;

  if (getUserInfoById) {
    // apollo supplies immutable objects while InMemoryCache used, deepclone needed to "un-freeze" nested objects
    data = cloneDeep(getUserInfoById);

    // inject custom props
    data.account = {
      ...data.account,
      isEngage: data?.account?.type === AccountType.Engage,
      isTeam: data?.account?.type === AccountType.Team,
      isEnterprise: data?.account?.type === AccountType.Enterprise
    };
  }

  return {
    data,
    ...other
  };
}
