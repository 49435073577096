import React from 'react';

import { Modal } from 'rio-ui-components';

import CreateEnergyMeter from './CreateEnergyMeterModal';
import UpdateEnergyMeter from './UpdateEnergyMeterModal';

const RenderInner = ({ modalState, accountId, onSuccess }) => {
  if (modalState.createModal) return <CreateEnergyMeter accountId={accountId} onSuccess={onSuccess} />;
  if (modalState.updateModal) return <UpdateEnergyMeter accountId={accountId} onSuccess={onSuccess} sourceId={modalState.updateModal} />;
  return null;
};

const EnergyMeterModal = (props) => {
  const { onDismiss, modalState, accountId, onComplete } = props;
  if (!modalState.createModal && !modalState.updateModal) return null;

  return (
    <Modal show dismissable name="ConfigurationEnergyMeter__Modal" onDismiss={onDismiss} span={6} height="auto" maxHeight="90vh">
      <RenderInner modalState={modalState} accountId={accountId} onSuccess={onComplete} />
    </Modal>
  );
};

export default EnergyMeterModal;
