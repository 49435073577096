import React from 'react';
import styled from 'styled-components';
import { CircleIcon, Heading, Row, Col } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const PreviewImage = styled(Row)`
  padding: 0 0 75% 0;
  display: block;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.overlay.light.background} url(${({ src }) => src}) center center no-repeat;
  background-size: contain;
`;

const CircleIconStyled = styled(CircleIcon)`
  background: #fff;
  display: inline-flex;
  flex: 0 0 auto;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

const TextContainer = styled(Col)`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${({ theme }) => theme.colors.basic.white};
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: ${(p) => p.theme.colors[p.color].normal.background};
`;

const OverlayText = styled.div`
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  color: white;
  font-size: 20px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  display: none;
`;

const ImageContainer = styled.div`
  position: relative;

  &:hover .overlay {
    opacity: 1;
    div {
      display: block;
    }
  }
`;

const ReportTile = ({ title, subject, reportId, description, thumbnail }) => {
  let icon = 'chart-line';
  let iconColor = 'quaternary';

  if (subject === 'LEARN') {
    icon = 'graduation-cap';
    iconColor = 'secondary';
  } else if (subject === 'COMPLY') {
    icon = 'pencil-alt';
    iconColor = 'tertiary';
  }

  return (
    <ContainerStyled name={`ReportTile ReportTile--${reportId}`} container item>
      <ImageContainer>
        <PreviewImage name="ReportTile__DashboardThumbnail" container item distribution="between" src={thumbnail} />
        <OverlayContainer className="overlay" color={iconColor}>
          <OverlayText>{description}</OverlayText>
        </OverlayContainer>
      </ImageContainer>
      <TextContainer container item vdistribution="between">
        <Row container item itemAlign="center">
          <CircleIconStyled
            name="ReportTile__Icon"
            height="50px"
            size="lg"
            icon={icon}
            iconColor={iconColor}
            circleColor={iconColor}
          />
          <Heading name="ReportTile__Title" size="lg" color="dark" weight="light">
            {title}
          </Heading>
        </Row>
      </TextContainer>
    </ContainerStyled>
  );
};

export default ReportTile;
