import React from 'react';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

const DescriptionIconContainer = styled.div`
  color: ${(props) => props.theme.colors.text.normal.background};
`;

export function DescriptionCell(props) {
  const {
    data: { description }
  } = props;

  return (
    description && (
      <DescriptionIconContainer>
        <Icon name={`task-row__info`} icon="info-circle" title={description} />
      </DescriptionIconContainer>
    )
  );
}
