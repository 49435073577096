import React, { RefObject } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { TYPES_LABELS } from '../../../constants/aspects';
import { ConfirmActionModal } from '../../../components/ConfirmAction';
import { useNotification } from '../../../hooks';
import { useOptionToDeleteFromQueryParam } from './useOptionToDeleteFromQueryParam';
import { useDeleteAspectOption } from './useDeleteAspectOption';

interface ConfirmOptionDeletionModalProps {
  accountId: string;
  portalRef: RefObject<HTMLDivElement>;
  onReviewAspects: () => void;
  onError: (error: Error) => void;
  onCompleted: () => void;
  onDismiss: () => void;
}

export function ConfirmOptionDeletionModal({
  accountId,
  portalRef,
  onError,
  onCompleted,
  onDismiss,
  onReviewAspects
}: ConfirmOptionDeletionModalProps) {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [deleteAspectOption] = useDeleteAspectOption({
    onError: (err) => {
      onError(err);
      showNotification(err.message, 'danger');
    },
    onCompleted: () => {
      onCompleted();
    }
  });
  const [optionToDelete, setOptionToDelete] = useOptionToDeleteFromQueryParam();

  return (
    <>
      {portalRef.current &&
        optionToDelete &&
        createPortal(
          <ConfirmActionModal
            show={!!optionToDelete}
            headingText={`Are you sure you want to delete this ${get(TYPES_LABELS, optionToDelete.option.type)}?`}
            bodyText={`This might affect ${optionToDelete.relatedAspectsCount} related aspects`}
            onConfirmAction={async () => {
              await deleteAspectOption({
                variables: {
                  accountId,
                  id: optionToDelete.option.id,
                  type: optionToDelete.option.type
                }
              });
              showNotification(`${optionToDelete.option.name} has been deleted!`);
              setOptionToDelete(null);
            }}
            confirmButtonTitle="Yes, delete"
            cancelButtonTitle="Review aspects"
            onCancelAction={() => {
              onDismiss();
              onReviewAspects();
              navigate('/governance/environmental-aspects');
              setOptionToDelete({
                ...optionToDelete,
                isReviewingAspects: true
              });
            }}
            onDismiss={() => {
              onDismiss();
              setOptionToDelete(null);
            }}
          />,
          portalRef.current
        )}
    </>
  );
}
