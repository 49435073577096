import { Icon } from 'rio-ui-components';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams, ColDef, RowNode } from 'ag-grid-community';
import styled from 'styled-components';
import { SurveyTemplateSubscription } from '@rio/rio-types';
import { useCallback } from 'react';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface DeleteCellProps {
  data: RowNode;
  onDelete: (row: RowNode) => void;
}

const GridContainer = styled.div`
  height: 100%;
`;
const ActionCell = ({ data, onDelete }: ICellRendererParams & DeleteCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onDelete(data)}>
      <Icon icon="trash" color="#ff909a" />
    </EditLinkStyled>
  </GridCellContainer>
);

interface GridOfSubscriptionsProps {
  rows: SurveyTemplateSubscription[];
  onDelete: (subscription: SurveyTemplateSubscription) => void;
}
const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  cellStyle: {
    height: 'auto',
  },
};

const columnDefs: ColDef[] = [
  {
    headerName: 'Account',
    field: 'account.name',
  },
  {
    headerName: 'Template',
    field: 'template.name',
  },
  {
    headerName: 'Actions',
    cellRenderer: 'actions',
  },
];

export function GridOfSubscriptions({ rows, onDelete }: GridOfSubscriptionsProps) {
  const components = {
    actions: (props: ICellRendererParams) => (
      <ActionCell {...props} onDelete={(row) => onDelete(row as unknown as SurveyTemplateSubscription)} />
    ),
  };
  const handleGridReady = useCallback((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  }, []);

  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        rowSelection="single"
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        components={components}
        rowData={rows}
        onGridReady={handleGridReady}
        paginationPageSize={25}
        pagination
      />
    </GridContainer>
  );
}
