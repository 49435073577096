import { useApolloClient } from '@apollo/client';
import { SetFilterValuesFuncParams, ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { FilterData, Legislation } from '@rio/rio-types';
import * as Sentry from '@sentry/react';
import { useTheme } from 'styled-components';
import { gridValueFormatter } from '../../../utils';
import { useCurrentAccountId, useNotification } from '../../../hooks';
import { formatReviewDate } from '../../DocumentContainer/utils';
import { GET_ASPECT_FILTER } from './index.queries';
import { PAGE_SIZE } from './constants';
import { getScopeLevel, getScopeTags, getScopeLocations, joinList, determineStyles } from './utils';

export function useAspectsGridConfig(): GridOptions {
  const client = useApolloClient();
  const theme = useTheme();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getAspectFilter: values },
      } = await client.query({
        query: GET_ASPECT_FILTER,
        variables: {
          accountId,
          field: params.colDef.field,
        },
      });
      params.success(values.map((item: FilterData) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      Sentry.captureException(err);
      params.success([]);
    }
  };
  const filterComparator = (a: string, b: string) => JSON.parse(a)?.label - JSON.parse(b)?.label;
  const setFilterParams = {
    filter: 'agSetColumnFilter',
    values: getFilterValues,
    valueFormatter: gridValueFormatter,
    comparator: filterComparator,
  };
  const entityFormatter = ({ value }: ValueFormatterParams) => (Array.isArray(value) ? joinList(value) : value?.name);

  const columnDefs: ColDef[] = [
    {
      headerName: 'Aspect Id',
      field: 'id',
      cellRenderer: 'searchable',
      filterParams: setFilterParams,
      hide: true,
    },
    {
      headerName: 'Scope',
      field: 'scope',
      resizable: true,
      filterParams: {
        ...setFilterParams,
        valueFormatter: (params: ValueFormatterParams) => getScopeLevel(gridValueFormatter(params)),
      },
      valueFormatter: ({ data }: ValueFormatterParams) => getScopeLevel(data.scope),
    },
    {
      headerName: 'Scope (Tags)',
      cellRenderer: 'searchable',
      field: 'tags',
      resizable: true,
      valueFormatter: ({ data }: ValueFormatterParams) => getScopeTags(data),
      filterParams: setFilterParams,
    },
    {
      headerName: 'Scope (Locations)',
      cellRenderer: 'searchable',
      field: 'locations',
      resizable: true,
      valueFormatter: ({ data }: ValueFormatterParams) => getScopeLocations(data),
      filterParams: setFilterParams,
    },
    {
      headerName: 'Departments',
      cellRenderer: 'searchable',
      field: 'departments',
      resizable: true,
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Activity / Process',
      cellRenderer: 'searchable',
      field: 'activity',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Environmental Aspect(s)',
      cellRenderer: 'searchable',
      field: 'aspects',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Potential Environmental Impact(s)',
      cellRenderer: 'searchable',
      field: 'impacts',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Legislation Reference(s)',
      field: 'legislations',
      cellRenderer: 'legislationReference',
      filterParams: setFilterParams,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.map((e: Legislation) => e.title).join(', '),
    },
    {
      headerName: 'Likelihood (no control)',
      cellRenderer: 'searchable',
      field: 'likelihoodNoControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Severity (no control)',
      cellRenderer: 'searchable',
      field: 'severityNoControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Significance (no control)',
      field: 'significanceNoControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Potential Emergency Condition(s)',
      cellRenderer: 'searchable',
      field: 'emergencyConditions',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Relevant Control Measure(s)',
      cellRenderer: 'searchable',
      field: 'controlMeasures',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
    },
    {
      headerName: 'Likelihood (with control)',
      cellRenderer: 'searchable',
      field: 'likelihoodControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Severity (with control)',
      cellRenderer: 'searchable',
      field: 'severityControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Significance (with control)',
      field: 'significanceControl',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Review Date',
      field: 'reviewDate',
      valueGetter: (params) => (params.data.reviewDate && formatReviewDate(params.data.reviewDate)) || '',
      cellStyle: { textAlign: 'center' },
      width: 140,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'notEqual',
          'inRange',
          {
            displayKey: 'emptyValue',
            displayName: 'Empty',
            filter: (_: string, cellValue: string) => !cellValue,
          },
        ],
      },
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      valueGetter: (params) => (params.data.createdDate && formatReviewDate(params.data.createdDate)) || '',
      cellStyle: { textAlign: 'center' },
      width: 140,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange'],
      },
    },
    {
      headerName: 'Author',
      cellRenderer: 'searchable',
      field: 'author.name',
      cellStyle: { textAlign: 'center' },
      filterParams: setFilterParams,
    },
    {
      headerName: 'Status',
      cellRenderer: 'searchable',
      field: 'status',
      filterParams: setFilterParams,
      valueFormatter: entityFormatter,
      cellStyle: (params) => {
        const name = params?.value?.name;
        return determineStyles(name, theme);
      },
    },
    {
      headerName: 'Active Tasks',
      cellRenderer: 'searchable',
      field: 'activeTasks',
      cellStyle: { textAlign: 'center' },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: '',
      cellRenderer: 'action',
      cellStyle: {
        textAlign: 'center',
      },
      width: 75,
      sortable: false,
      filter: false,
    },
  ];
  return {
    columnDefs,
    pagination: true,
    paginationPageSizeSelector: false,
    cacheBlockSize: PAGE_SIZE,
    paginationPageSize: PAGE_SIZE,
    rowModelType: 'serverSide',
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      filterParams: {
        showTooltip: true,
      },
    },
  };
}
