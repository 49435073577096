import { FormattedMessage } from 'react-intl';
import { styled, Text } from '@rio/ui-components';
import { Link } from 'react-router-dom';
import { CoursesSection } from '../CoursesSection';
import { Course } from '@rio/rio-types';

const RowStyled = styled('div')`
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.sys.color.errorContainer};
`;
const TextStyle = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`.withComponent('h3');

interface RelatedCourseGridProps {
  relatedCourses: Course[];
}
const RelatedCourseGrid = ({ relatedCourses }: RelatedCourseGridProps) => {
  return relatedCourses.length ? (
    <CoursesSection
      title={<FormattedMessage id="pages.coursesPreview.relatedCourses" defaultMessage="Related courses" />}
      courses={relatedCourses}
    />
  ) : (
    <Link to="/v2/engage">
      <RowStyled>
        <TextStyle typescale="title" size="medium">
          <FormattedMessage id="pages.coursesPreview.noResults" />
        </TextStyle>
      </RowStyled>
    </Link>
  );
};

export { RelatedCourseGrid };
