import { useApolloClient } from '@apollo/client';
import { formatDate, gridNumberFormatter, gridValueFormatter } from '../../../../utils';
import { useNotification } from '../../../../hooks';
import { GET_TRANSPORT_CONVERSION_FACTORS_FILTER } from './index.queries';
import { GridOptions, SetFilterValuesFuncParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { FilterData } from '@rio/rio-types';

export function useTransportConversionFactorsGridConfig(): GridOptions {
  const client = useApolloClient();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getTransportConversionFactorsFilter: values },
        } = await client.query<{ getTransportConversionFactorsFilter: FilterData[] }>({
          query: GET_TRANSPORT_CONVERSION_FACTORS_FILTER,
          variables: {
            field: params.colDef.colId || params.colDef.field,
          },
        });
        params.success(values.map((item) => (item.value ? JSON.stringify(item) : (item.value as string))));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [client]
  );

  const config = useMemo(() => ({
    columnDefs: [
      // date
      {
        headerName: 'Start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        filter: 'agDateColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
      },

      // set
      {
        headerName: 'Reporting Year',
        field: 'reportingYear',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Scope',
        field: 'scope',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'From Unit',
        field: 'fromUnit',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'To Unit',
        field: 'toUnit',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Transport Type',
        field: 'transportType',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Transport Subtype',
        field: 'transportSubtype',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Fuel Type',
        field: 'fuelType',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },


      // number
      {
        headerName: 'kgCO2e',
        field: 'value',
        minWidth: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: gridNumberFormatter,
      },
      
      // string
      {
        headerName: 'Reference',
        field: 'note',
        cellRenderer: 'reference',
        cellRendererParams: {
          icon: 'external-link-alt',
        },
        minWidth: 100,
      },
      {
        headerName: 'Note',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'note',
        resizable: true,
      },
    ],
  }), [getFilterValues]);

  return config;
}
