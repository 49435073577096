import ContainerHeader from '../ContainerHeader';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useGetAccountCourseById } from '../../hooks/engage';
import {
  StyledLoadingIndicator,
  FormHolder,
  CreateButton,
  ButtonHolder,
  AddAllButton,
  FirstLatterUpper,
  CounterHolder,
  ManageLicencesButton,
} from './Styles';
import {
  useGetUsersInfoByAccountIdPagedQuery,
  useGetAccountInfoByIdQuery,
  useCreateBatchLicencesMutation,
  usePermissions,
  useNotification,
  useRoutes,
} from '../../hooks';
import { useForm, Controller } from 'react-hook-form';
import { CounterPickerRender } from './CounterPickerRender';
import { useParams, useNavigate } from 'react-router-dom';
import { makeUrl } from '../../utils';
import { Row, Col } from 'rio-ui-components';
import { useGetBundleByIdQuery } from '../../hooks/engage/useGetBundleByIdQuery';

export function CreateAccountProductLicence() {
  const navigate = useNavigate();
  const { productId, accountId, productType } = useParams();
  const routes = useRoutes();
  const isBundle = productType === 'bundle';

  const { control, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      accountId,
      productId,
    },
  });

  useEffect(() => {
    register('accountId');
    register('productId');
    // eslint-disable-next-line
  }, []);

  const {
    licenceService: { assignLicence },
  } = usePermissions();
  const [createLicences, { loading: isLicencesCreating }] = useCreateBatchLicencesMutation();

  const { data: course, loading: isCourseFetching } = useGetAccountCourseById(productId, { skip: isBundle });
  const { data: bundle, loading: isBundleFetching } = useGetBundleByIdQuery(productId, { skip: !isBundle });
  const { data: accountInfo, loading: isAccountFetching } = useGetAccountInfoByIdQuery(accountId);
  const {
    data: { users },
    loading: isUserFetching,
  } = useGetUsersInfoByAccountIdPagedQuery(accountId);
  const { showNotification } = useNotification();

  const isLoading = isCourseFetching || isBundleFetching || isAccountFetching || isUserFetching || isLicencesCreating;
  const product = isBundle ? bundle : course;
  const isProductFetching = isBundleFetching || isCourseFetching;
  const productName = isBundle ? product?.title : product?.name;
  const usersCount = users?.length;

  useEffect(() => {
    if (!isProductFetching && !product) {
      showNotification(`The ${productType} has not been found`, 'danger');
    }
    // eslint-disable-next-line
  }, [product, isProductFetching]);

  const submitForm = async ({ licenceCount = 0, accountId: accId, productId: prodId }) => {
    try {
      const list = Array.from({ length: licenceCount }, () => ({ accountId: accId, productId: prodId }));

      if (!list.length) {
        return;
      }

      await createLicences(list);

      showNotification('Licences have been created');
    } catch (e) {
      showNotification('Licences have not been created', 'danger');
      Sentry.captureException(e);
    }
  };

  const addForAllUsers = (e) => {
    e.preventDefault();

    setValue('licenceCount', usersCount);
  };
  const forwardToManageLicence = () =>
    navigate(
      makeUrl(`/${routes.configuration.root}/${routes.configuration.menu.learn.assignLicense}`, {
        accountId,
        productId,
        productType,
      })
    );

  return (
    <section>
      <ContainerHeader
        name="ConfigurationLearnSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title="Licence creation"
      />

      {isLoading && <StyledLoadingIndicator />}

      {!isLoading && product && (
        <FormHolder>
          {assignLicence && (
            <Row container itemAlign="center">
              <Col span={12}>
                <ManageLicencesButton size="sm" onClick={forwardToManageLicence}>
                  Manage licences
                </ManageLicencesButton>
              </Col>
            </Row>
          )}

          <form onSubmit={handleSubmit(submitForm)}>
            <h1>Licences for:</h1>
            <p>
              <FirstLatterUpper>{productType}</FirstLatterUpper> {productName}
            </p>

            <h1>Account: </h1>
            <p>{accountInfo.name}</p>

            <ButtonHolder>
              <AddAllButton size="sm" onClick={addForAllUsers}>
                Add for all ({usersCount}) users
              </AddAllButton>

              <CounterHolder>
                <Controller
                  name="licenceCount"
                  control={control}
                  render={({ field }) => <CounterPickerRender {...field} />}
                />
              </CounterHolder>
            </ButtonHolder>

            <Row container itemAlign="center" align="around">
              <Col span={12}>
                <CreateButton>Create</CreateButton>
              </Col>
            </Row>
          </form>
        </FormHolder>
      )}
    </section>
  );
}
