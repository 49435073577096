import { DocumentLibrary } from '@rio/rio-types';
import { DocumentTypeFolder } from './types';

const documentTypes: DocumentTypeFolder[] = [
  {
    id: '2403396f-fbc5-4a83-8a93-b0faeb517014',
    title: 'All Documents',
    filterValue: '',
    link: 'all',
    icon: 'folder',
    libraries: [
      DocumentLibrary.Waste,
      DocumentLibrary.Water,
      DocumentLibrary.Electricity,
      DocumentLibrary.Gas,
      DocumentLibrary.Governance,
      DocumentLibrary.Transport,
    ],
  },
  {
    id: '0d8c4d5d-958b-4231-98d3-28465b93d918',
    title: 'Consignment Notes (EA)',
    filterValue: 'Consignment Note (EA)',
    link: 'ConsignmentNoteEA',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: 'cd03cc92-9651-4509-82a5-7758ddcc6814',
    title: 'Consignment Notes (SEPA)',
    filterValue: 'Consignment Note (SEPA)',
    link: 'ConsignmentNoteSEPA',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: '24991346-1812-412e-a0b3-7cbb5ff2add7',
    title: 'Evidence',
    filterValue: 'Evidence Reference',
    link: 'Evidence',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: '59e823b4-9261-40e7-b702-5f59846fda9b',
    title: 'Invoices',
    filterValue: 'Invoice Number',
    link: 'Invoices',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste, DocumentLibrary.Transport],
  },
  {
    id: 'd37ab75f-3c10-4159-b8e2-458628ffb26a',
    title: 'Sales Orders',
    filterValue: 'Sales Order',
    link: 'SalesOrders',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: 'b9dfbafd-afea-4eee-9a87-487820252692',
    title: 'Waste Carrier Licences',
    filterValue: 'Waste Carrier Licence',
    link: 'Licences',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: '59d1a3b6-d258-455a-b066-4d1bd093ff8c',
    title: 'Waste Permits',
    filterValue: 'Waste Permit',
    link: 'Permits',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: '1832c095-6713-4d62-a966-f237400bdf5c',
    title: 'Waste Transfer Notes',
    filterValue: 'Waste Transfer Note',
    link: 'WasteTransferNotes',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: 'e8ad418f-9b3a-4763-95d7-6c9e90215b13',
    title: 'Waste Contractor Reports',
    filterValue: 'Waste Contractor Report',
    link: 'WasteContractorReports',
    icon: 'folder',
    libraries: [DocumentLibrary.Waste],
  },
  {
    id: '05c8791c-5b2a-4475-b6f8-dd642e62615d',
    title: 'Travel Receipts',
    filterValue: 'Travel Receipt',
    link: 'TravelReceipts',
    icon: 'folder',
    libraries: [DocumentLibrary.Transport],
  },
  {
    id: '9b8084ee-b631-4be6-8d3c-859ebebe970d',
    title: 'Audit Reports',
    filterValue: 'Audit Report',
    link: 'AuditReports',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: 'f3d665c3-e149-4c07-8834-aa67666a347b',
    title: 'Aspects and Impacts',
    filterValue: 'Aspects and Impacts',
    link: 'AspectsAndImpacts',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '7c786c9c-bb7b-45c5-90d6-01b44c8f8c18',
    title: 'Certificates',
    filterValue: 'Certificate',
    link: 'Certificates',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: 'ed23e08a-4202-4100-a6bc-b3e7566d3bae',
    title: 'Communications',
    filterValue: 'Communications',
    link: 'Communications',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '73c2c1f3-8bee-4af6-844c-a220ce211d83',
    title: 'Legislation',
    filterValue: 'Legislation',
    link: 'Legislation',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '8a49a5f3-2e9f-4763-93b2-5d9e9ef5daa6',
    title: 'Management Systems',
    filterValue: 'Management Systems',
    link: 'ManagementSystems',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '24d74b26-1706-448f-9de7-618a38cc164c',
    title: 'Policies',
    filterValue: 'Policies',
    link: 'Policies',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: 'a308e909-204b-4699-bfbe-307b26826c75',
    title: 'Reporting Frameworks',
    filterValue: 'Reporting Frameworks',
    link: 'ReportingFrameworks',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '561f13f4-b6f8-448c-baeb-59db3c0de822',
    title: 'Standards',
    filterValue: 'Standards',
    link: 'Standards',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: '33aa1592-e850-49ce-88eb-f633d1d7f5b4',
    title: 'Training',
    filterValue: 'Training',
    link: 'Training',
    icon: 'folder',
    libraries: [DocumentLibrary.Governance],
  },
  {
    id: 'd592e948-7460-44ab-acdc-6d91d1a6769a',
    title: 'Bills',
    filterValue: 'Bill',
    link: 'Bills',
    icon: 'folder',
    libraries: [DocumentLibrary.Electricity, DocumentLibrary.Gas, DocumentLibrary.Water],
  },
  {
    id: 'ee557b4b-35ee-4544-85a5-e1cfea25360c',
    title: 'Licences and Permits',
    filterValue: 'Licences and Permits',
    link: 'LicencesAndPermits',
    icon: 'folder',
    libraries: [DocumentLibrary.Water, DocumentLibrary.Transport, DocumentLibrary.Governance],
  },
  {
    id: '292a3f37-c772-4dce-a457-11def3bcb5eb',
    title: 'Meter Readings',
    filterValue: 'Meter Readings',
    link: 'MeterReadings',
    icon: 'folder',
    libraries: [DocumentLibrary.Electricity, DocumentLibrary.Gas, DocumentLibrary.Water],
  },
  {
    id: '74edb971-9146-44ae-b308-e4bb8b600524',
    title: 'Supplier Agreements',
    filterValue: 'Supplier Agreement',
    link: 'SupplierAgreements',
    icon: 'folder',
    libraries: [DocumentLibrary.Electricity, DocumentLibrary.Gas, DocumentLibrary.Water],
  },
  {
    id: '3b8bee92-ed8c-4ae4-9cbf-819be4649ff9',
    title: 'Others',
    filterValue: 'Other Reference',
    link: 'Others',
    icon: 'folder',
    libraries: [
      DocumentLibrary.Waste,
      DocumentLibrary.Electricity,
      DocumentLibrary.Gas,
      DocumentLibrary.Water,
      DocumentLibrary.Transport,
      DocumentLibrary.Governance,
    ],
  },
];

export default documentTypes;
