import { useCallback } from 'react';
import { TransactionGrid, FetchRowsParameters } from '../../../components/TransactionGrid';
import { useBuildingEnergyRatingsGridConfig } from './useGridConfig';
import { useGetBuildingEnergyRatingsByAccountId } from './useGetBuildingEnergyRatingsByAccountIdQuery';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Building Energy Ratings' }];

export const BuildingEnergyRatingsGrid = () => {
  const config = useBuildingEnergyRatingsGridConfig();
  const [getBuildingEnergyRatingsByAccountId] = useGetBuildingEnergyRatingsByAccountId();
  const fetchRows = useCallback(
    async (variables: FetchRowsParameters) => {
      const { data } = await getBuildingEnergyRatingsByAccountId({
        variables,
      });
      if (data) {
        const {
          getBuildingEnergyRatingsByAccountId: { totalRows, rows },
        } = data;
        return {
          rows,
          totalRows,
        };
      }
      throw new Error("Couldn't fetch building energy ratings");
    },
    [getBuildingEnergyRatingsByAccountId]
  );
  return (
    <TransactionGrid
      title="Building Energy Ratings"
      defaultExportFileName="building-energy-ratings"
      breadcrumbs={breadcrumbs}
      config={config}
      fetchRows={fetchRows}
    />
  );
};
