import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { BannerType, LearnContentType } from '@rio/rio-types';
import { Modal } from 'rio-ui-components';
import { CarouselBanners, LearnEmbeddedViewer } from '~/components';
import { useCurrentAccountId, useCurrentUserId } from '~/hooks';
import BannerImage from '~/assets/img/learn/banner-2x.jpg';
import { GET_BANNER, GET_COURSE_BY_ID } from './index.queries';
import { isLocalhost } from '../../../utils/isLocalhost';
import { getEnvVar } from '../../../env';

const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
const StyledDiv = styled.div`
  cursor: pointer;
  min-width: 844px;
  min-height: 102px;
`;
const ModalStyled = styled(Modal)`
  div {
    overflow: hidden;
  }
`;

function getCourseIdFromUrl(url) {
  if (!url) {
    return null;
  }

  const match = url?.match(/\/([0-9a-fA-F-]+)$/);
  return match && match[1];
}

function toAbsoluteUrl(path) {
  const origin = isLocalhost ? getEnvVar('REACT_APP_BASE_URL') : window.location.origin;
  return origin + path;
}

function courseWithAbsoluteUrl(course) {
  return {
    ...course,
    type: course?.type,
    metaData: {
      ...course?.metaData,
      link: toAbsoluteUrl(course?.metaData?.link),
    },
  };
}

const defaultCourse = {
  type: LearnContentType.Video,
  metaData: {
    link: toAbsoluteUrl(getEnvVar('REACT_APP_BANNER_CONTENT')),
  },
};

const CourseBanner = (props) => {
  const [showModal, setShowModal] = useState(false);
  const dismissModal = () => setShowModal(false);
  const [bannerCourseId, setBannerCourseId] = useState('');

  const accountId = useCurrentAccountId();
  const userId = useCurrentUserId();
  const { data: { getBanners: banners } = {}, loading } = useQuery(GET_BANNER, { variables: { accountId } });
  const noBannerConfigured = banners === null;

  const { data } = useQuery(GET_COURSE_BY_ID, {
    variables: { id: bannerCourseId, accountId, userId, userIdNullable: userId },
    skip: !bannerCourseId,
  });

  const handlerClick = (url, type) => {
    if (type === BannerType.Hyperlink && url) {
      return window.open(url, '_blank');
    }
    setShowModal(true);
    setBannerCourseId(getCourseIdFromUrl(url));
  };

  if (loading) {
    return null;
  }
  return (
    <>
      <ModalStyled span={8} height="80%" show={showModal} onDismiss={dismissModal}>
        {data?.getCourseById || noBannerConfigured ? (
          <LearnEmbeddedViewer
            course={noBannerConfigured ? defaultCourse : courseWithAbsoluteUrl(data?.getCourseById)}
            link={noBannerConfigured ? null : data?.getCourseById?.metaData?.link}
          />
        ) : null}
      </ModalStyled>
      {banners?.length ? (
        <CarouselBanners banners={banners} handlerClick={handlerClick} />
      ) : (
        <StyledDiv name="BannerDiv">
          <ImageStyled {...props} src={BannerImage} />
        </StyledDiv>
      )}
    </>
  );
};

export default CourseBanner;
