import { dromoConfiguration, DromoConfiguration } from './base';
import template from '../WasteContainer/UploadContainer/waste-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Waste';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getWasteConfiguration(): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
  };
}
