import { TAG, LOCATION } from '../../../constants/scopes';

export function validate(risk) {
  const errors = {};

  if (!risk.scope) {
    errors.scope = 'Scope is required';
  }

  if (risk.scope === LOCATION && (!risk.locationIds || !risk.locationIds.length)) {
    errors.locationIds = 'Location is required';
  }

  if (risk.scope === TAG && (!risk.tagIds || !risk.tagIds.length)) {
    errors.tagIds = 'Tag is required';
  }

  if (!risk.activity) {
    errors.activity = 'Activity / Process is required';
  }

  if (!risk.risks || !risk.risks.length) {
    errors.risks = 'Environmental Risk is required';
  }

  if (!risk.hazzards) {
    errors.activity = 'Identified Hazzards is required';
  }

  if (!risk.whoIsEffected) {
    errors.risks = 'Who may be effected is required';
  }

  if (!risk.impacts || !risk.impacts.length) {
    errors.impacts = 'Potential Environmental Impact is required';
  }

  if (!risk.emergencyConditions || !risk.emergencyConditions.length) {
    errors.emergencyConditions = 'Potential Emergency Conditions is required';
  }

  if (!risk.controlMeasures || !risk.controlMeasures.length) {
    errors.controlMeasures = 'Relevant Conrol Measure is required';
  }

  return errors;
}
