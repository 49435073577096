import { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Theme, styled, useTheme } from '@rio/ui-components';
import { Survey } from '@rio/rio-types';
import { ProgressBarChart } from './ProgressBarChart';
import { baseColumnChart } from './chartConfigs/baseColumnChart';
import { countSubmittedSubmissions, sumPerformanceScore } from './chartConfigs';
import { Tick } from '~/components/SvgIcons';

interface ContributorPerformanceChartProps {
  children: string | JSX.Element | JSX.Element[];
  survey: Survey;
}
interface PercentagesOfContributorsChartProps {
  survey: Survey;
  percentPerformance: { positive: number; neutral: number; negative: number };
}

const ProgressBarChartFlex = styled(ProgressBarChart)`
  flex: 0 0 50%;
  margin: 10px 0;
  text-transform: capitalize;
`;
const FlexContainerStretch = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const Banner = styled('div')`
  margin-bottom: 12px;
  padding: 12px;
  background: #00c8b511;
  color: #45b7ad;
  border-radius: 12px;
`;
const TickStyled = styled(Tick)`
  margin-right: 12px;
  vertical-align: middle;
`;

const chart = (
  percentNotSubmitted: number,
  percentPerformance: { positive: number; neutral: number; negative: number },
  theme: Theme
): Highcharts.Options => ({
  ...baseColumnChart,
  legend: {
    itemStyle: {
      fontFamily: theme.sys.typescale.body.medium.family,
      fontSize: `${theme.sys.typescale.body.medium.size}px`,
      fontWeight: theme.sys.typescale.body.medium.weight,
      height: theme.sys.typescale.body.medium.lineHeight,
      color: theme.sys.color.onSurface,
    },
  },
  xAxis: {
    categories: ['Not submitted', 'Negative', 'Neutral', 'Positive'],
    labels: { enabled: false },
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: '% of contributors',
      x: -10,
      style: { fontWeight: 'bold' },
    },
  },
  series: [
    {
      name: 'Not submitted',
      data: [percentNotSubmitted],
      color: theme.sys.color.onSurfaceVariant,
      type: 'column',
    },
    {
      name: 'Negative',
      data: [percentPerformance.negative],
      color: theme.sys.color.error,
      type: 'column',
    },
    {
      name: 'Neutral',
      data: [percentPerformance.neutral],
      color: theme.customColors.warning.color,
      type: 'column',
    },
    {
      name: 'Positive',
      data: [percentPerformance.positive],
      color: theme.extendedColors.environmental.color,
      type: 'column',
    },
  ],
});

function PercentOfContributorsChart({ survey, percentPerformance }: PercentagesOfContributorsChartProps) {
  const totalApprovedSubmissions = countSubmittedSubmissions(survey.submissions);
  const theme = useTheme();
  const totalContributors = survey.contributors.length;
  const percentNotSubmitted = ((totalContributors - totalApprovedSubmissions) / totalContributors) * 100;
  const lowResponses = percentNotSubmitted > 0 && percentNotSubmitted < 50;

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const chartCached = useMemo(
    () => chart(percentNotSubmitted, percentPerformance, theme),
    [percentNotSubmitted, percentPerformance, theme]
  );

  return (
    <>
      {lowResponses && (
        <Banner>
          <TickStyled />
          Encourage more responses to try and improve your impact
        </Banner>
      )}
      <HighchartsReact highcharts={Highcharts} options={chartCached} ref={chartRef} />
    </>
  );
}

export function ContributorPerformanceChart({ children, survey }: ContributorPerformanceChartProps) {
  const performance = useMemo(() => sumPerformanceScore(survey), [survey]);
  const performanceText = performance.text.toLowerCase();
  const percent = (performance[performanceText as keyof typeof performance] || 0) as number;

  return (
    <>
      {children}
      <ProgressBarChartFlex text={`${performance.text} Overall performance`} totals={[percent, 100]} />
      <FlexContainerStretch>
        <PercentOfContributorsChart survey={survey} percentPerformance={performance} />
      </FlexContainerStretch>
    </>
  );
}
