import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../../../../graphql/fragments/LocationFields.fragment';

export const GET_FUEL_TRANSACTIONS_PAGE = gql`
  query GetFuelTransactionsPage($accountId: ID!, $offset: Int, $limit: Int, $sort: [SortCommand!], $filters: Filters) {
    getFuelTransactionPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        locationName
        locationCode
        locationEndDate
        tags
        date
        fuelActivityName
        fuelType
        quantity
        unit
        accuracy
        totalCost
        currency
        carbonScope1
        carbonScope3
        carbonEmissionScope3WTT
        kgCO2Scope1
        kgCO2Scope3
        kgCH4Scope1
        kgCH4Scope3
        kgN2OScope1
        kgN2OScope3
        energyConsumption
        comment
        invoiceNumber
        evidenceReference
        otherReference
        importBatchId
        importBatchFileName
        departments
        supplier
        location {
          ...LocationFields
        }
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_REFRIGERANT_TRANSACTIONS_FILTER = gql`
  query GetRefrigerantTransactionsPageFilter($accountId: ID!, $field: String!) {
    getRefrigerantTransactionsPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
