import { gql } from '@apollo/client';

export const GET_ALL_TEMPLATES = gql`
  query GetAllTemplates {
    getAllTemplates {
      id
      name
      parserId
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($id: ID!, $name: String!, $parserId: String!) {
    createTemplate(input: { id: $id, name: $name, parserId: $parserId }) {
      id
      name
      parserId
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($id: ID!, $name: String!, $parserId: String!) {
    updateTemplate(input: { id: $id, name: $name, parserId: $parserId }) {
      id
      name
      parserId
    }
  }
`;
