import { useQuery } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import { Tag, Query } from '@rio/rio-types';
import { GET_TAGS_BY_ACCOUNT_ID } from '../index.queries';
import { sortBy } from 'lodash';
import { OptionType, MultiSelect } from '@rio/ui-components';

function tagToSelectOptions(tag: Tag) {
  return {
    value: tag.id,
    label: tag.tagName,
  };
}

interface TagsMultiSelectProps {
  accountId: string;
  value: OptionType[] | string[];
  onChange: (selectedTags: OptionType[]) => void;
  error?: string;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
}

const TagsMultiSelect: FC<TagsMultiSelectProps> = ({
  accountId,
  value,
  onChange,
  error,
  name = 'tagIds',
  loading,
  label = 'Tags',
  disabled,
}) => {
  const {
    data,
    loading: loadingTags,
    error: tagsLoadingError,
  } = useQuery<Query>(GET_TAGS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
    fetchPolicy: 'network-only',
  });

  const tags: Tag[] = useMemo(() => (data?.getTagsByAccountId as Tag[]) || [], [data?.getTagsByAccountId]);
  const options = useMemo(
    () => sortBy(tags.map(tagToSelectOptions), (item) => item?.label.trim().toLowerCase()),
    [tags]
  );

  const errorMessage = tagsLoadingError?.message || error || '';

  return (
    <MultiSelect
      name={name}
      label={label}
      isLoading={loadingTags || loading}
      disabled={loading || disabled}
      onChange={onChange}
      value={value}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      options={options}
    />
  );
};

export { TagsMultiSelect };
