import React, { useState, useCallback } from 'react';
import { LoadingIndicator } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useFlowSubscriptions } from '../../../../hooks';
import alphabetiseStrings from '../../../../utils/alphabetise';
import SubscriptionSelector from './SubscriptionSelector';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ConfigurationFlowsSubscriptionsSelectionContainer = () => {
  const { accountId } = useParams();
  const [search, setSearch] = useState('');
  const { data, loading, error } = useFlowSubscriptions();

  const onSearchChange = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  const flowSubscriptions = data?.getFlowSubscriptions || [];

  const filteredFlows = flowSubscriptions
    .filter((flow) => flow.account.name?.includes(search) && flow.account.id === accountId)
    .sort((flowA, flowB) => alphabetiseStrings(flowA.flow.title, flowB.flow.title));

  return (
    <>
      {(loading || error) && (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      )}
      {!loading && !error && (
        <SubscriptionSelector
          filteredFlows={filteredFlows}
          onSearchChange={onSearchChange}
          title={`Subscriptions`}
          loading={loading}
          search={search}
        />
      )}
    </>
  );
};

export default ConfigurationFlowsSubscriptionsSelectionContainer;
