import { useCallback, useState } from 'react';
import { Col, Button, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { SurveyTemplate } from '@rio/rio-types';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContainerHeader from '../../../components/ContainerHeader';
import { CreateTemplate } from './CreateTemplate';
import { UpdateTemplate } from './UpdateTemplate';
import { useGet } from './queries';
import { LoadFailed } from '../../../components/Errors';
import { GridOfTemplates } from './GridOfTemplates';
import { TemplateDetails } from './TemplateDetails';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ContainerHeaderStyled = styled(ContainerHeader)`
  justify-content: space-between;
`;

const ConfigurationSurveyTemplatesContainer = () => {
  const { data, loading, error, refetch, networkStatus } = useGet();
  const [createModalShown, setCreateModalShown] = useState(false);
  const [templateEditing, setTemplateEditing] = useState<SurveyTemplate | null>(null);
  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const handleEditButtonClick = useCallback((template) => setTemplateEditing(template), []);
  const closeEditModal = useCallback(() => setTemplateEditing(null), []);
  return (
    <Col container fullHeight>
      <ContainerHeaderStyled
        icon="briefcase"
        iconColor="primary"
        name="ConfigurationSurveysContainer"
        title="Survey Templates"
      >
        <Col span={4} container item>
          <ButtonStyled size="md" color="primary" onClick={toggleCreateModal} name="AddSurveyTemplateButton" inline>
            + Add Survey Template
          </ButtonStyled>
        </Col>
      </ContainerHeaderStyled>
      {((loading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />
      )}
      {error && <LoadFailed error={error} retry={refetch} />}
      {!error && !loading && data?.getSurveyTemplates && (
        <GridOfTemplates rows={data?.getSurveyTemplates} onEdit={handleEditButtonClick} />
      )}
      {templateEditing && (
        <Modal size="md" onDismiss={closeEditModal} dismissable show>
          <UpdateTemplate existingTemplate={templateEditing} onDismiss={closeEditModal} />
        </Modal>
      )}
      {createModalShown && (
        <Modal size="md" onDismiss={toggleCreateModal} dismissable show>
          <CreateTemplate
            onDismiss={toggleCreateModal}
            TemplateDetails={TemplateDetails}
            title="Create Survey Template"
            submitButtonText="Create template"
          />
        </Modal>
      )}
    </Col>
  );
};

export default ConfigurationSurveyTemplatesContainer;
