export const uploadTabs = {
  organization: { label: 'Organisation', value: '/organisation' },
  portfolio: { label: 'Portfolio', value: '/portfolio' },
};

export const tabsItems = ['Organisation', 'Portfolio'];

export const setUploadCurrentTabs = (value: string) => localStorage.setItem('UPLOAD_CURRENT_TAB', value);

export const getUploadCurrentTabs = () => {
  return localStorage.getItem('UPLOAD_CURRENT_TAB') || null;
};

export const setUploadCurrentTabsV2 = (value: string) => localStorage.setItem('UPLOAD_CURRENT_TAB_V2', value);

export const getUploadCurrentTabsV2 = () => {
  return localStorage.getItem('UPLOAD_CURRENT_TAB_V2') || null;
};
