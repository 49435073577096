import { ErrorMessage, ContainerError } from 'rio-ui-components';
import { ApolloError } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useRoutes } from '~/hooks';

interface ErrorMessageProps {
  error: Error | ApolloError | string;
  title?: string;
  body?: string;
  name?: string;
}

interface ErrorMessageRenderProps {
  icon: string;
}

const ContainerErrorStyled = styled(ContainerError)`
  height: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const ErrorLayout = ({ error, title, body, name }: ErrorMessageProps) => (
  <ErrorMessage error={error}>
    {({ icon }: ErrorMessageRenderProps) => <ContainerErrorStyled name={name} icon={icon} title={title} body={body} />}
  </ErrorMessage>
);

export const Forbidden = (props: ErrorMessageProps) => (
  <ErrorLayout title="Forbidden" body="Access denied" {...props} />
);

export const NotFound = (props: Partial<ErrorMessageProps>) => (
  <ErrorLayout title="Not found" body="Requested resource was not found" error={new Error('Not found')} {...props} />
);

export const PageNotFound = (props: Partial<ErrorMessageProps>) => (
  <ErrorLayout
    error={new Error('Not found')}
    title="Page not found"
    body="The page you are looking for does not exist"
    {...props}
  />
);

export const NotAvailable = (props: ErrorMessageProps) => (
  <ErrorLayout title="Not available" body="This page is not available for this account" {...props} />
);

export const NotLicence = ({ name, error }: ErrorMessageProps) => {
  const routes = useRoutes();
  return (
    <ErrorMessage error={error}>
      {({ icon }: ErrorMessageRenderProps) => (
        <ContainerErrorStyled name={name} icon={icon} title="You do not have access to this learning content.">
          Please contact your Rio Administrator to gain access to this Premium content, or purchase it{' '}
          <StyledLink to={routes.market.engage}>here</StyledLink>
        </ContainerErrorStyled>
      )}
    </ErrorMessage>
  );
};
