import { useQuery } from '@apollo/client';
import { GET_USER_BY_USERNAME } from '../queries/users';
import { useUserToken } from './useUserToken';
import { useUserUpdated } from './useUserUpdated';

export const getConfigurationRoute = (permissions) => {
  if (!!permissions?.account.find((action) => action.startsWith('create'))) {
    return 'accountconfig/accounts';
  }

  if (!!permissions?.learn.find((action) => action.startsWith('createCourse'))) {
    return 'learnconfig/learncontent';
  }

  if (permissions?.dashboard.includes('createAll')) {
    return 'dashboardconfig/dashboards';
  }

  if (permissions?.governance.includes('createLegislationAll')) {
    return 'governanceconfig/managementsubscriptions';
  }

  if (permissions?.ocr.includes('createTemplateAll')) {
    return 'ocr';
  }

  if (!!permissions?.data.find((action) => action.startsWith('getLocation'))) {
    return 'accountconfig/locations';
  }

  if (!!permissions?.data.find((action) => action.startsWith('createSupplier'))) {
    return 'suppliers';
  }

  if (!!permissions?.data.find((action) => action.startsWith('createWasteStream'))) {
    return 'wasteconfig/wastestream';
  }
};

export function usePermissions() {
  const { token } = useUserToken();
  const userUpdatedData = useUserUpdated();
  const { sub: userId } = token || {};

  const { data, loading } = useQuery(GET_USER_BY_USERNAME, {
    variables: { name: userId },
    skip: !!userUpdatedData || !userId,
  });

  const userInfo = userUpdatedData || data?.getUserInfoById;
  const permissions = userInfo?.role?.permissions;
  const role = userInfo?.role?.role;
  const hasAction = (actionName, list = []) => list.find((action) => action.startsWith(actionName));

  return {
    loading,
    /**
     * do not use this role anymore, it is a wrong way, we need to check by permissions
     * @deprecated
     */
    isSystemAdmin: hasAction('createSubscription', permissions?.learn || []),

    allowedRioModules: permissions?.allowedRioModules || [],
    currentUser: userInfo,
    role: role || null,
    user: permissions?.user || [],
    account: permissions?.account || [],
    data: permissions?.data || [],
    governance: permissions?.governance || [],
    document: permissions?.document || [],
    tag: permissions?.tag || [],
    transposition: permissions?.transposition || [],
    email: permissions?.email || [],
    notification: permissions?.notification || [],
    learn: permissions?.learn || [],
    dashboard: permissions?.dashboard || [],
    task: permissions?.task || [],
    ocr: permissions?.ocr || [],
    intelligence: permissions?.intelligence || [],
    scheme: permissions?.scheme || [],
    project: permissions?.project || [],
    survey: permissions?.survey || [],
    flows: permissions?.flows || [],
    invest: permissions?.invest || [],
    configurationRoute: getConfigurationRoute(permissions),

    learnService: {
      createCourse: hasAction('createCourse', permissions?.learn),
    },

    licenceService: {
      createLicence: hasAction('create', permissions?.licence || []),
      assignLicence: hasAction('assign', permissions?.licence || []),
    },
    dataTracker: permissions?.dataTracker || [],
    dataTrackerAnomalies: permissions?.dataTrackerAnomalies || [],
  };
}
