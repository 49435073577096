import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { MutationCreateSurveyArgs, SurveyInput } from '@rio/rio-types';
import { SurveyForm } from './SurveyForm';
import { useCreate } from '../queries';
import { validate } from './validate';
import { Errors } from '../../../types';
import { useCurrentAccountId } from '../../../hooks';
import { useSurveyForm } from './hooks/useSurveyForm';
import { FormEvent } from 'react';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const StyledTextColumn = styled(PropertySheet.TextColumn)`
  align-self: center;
`;

interface CreateSurveyProps {
  onDismiss: () => void;
  buttonDescription?: string;
  title: string;
  submitButtonText: string;
}

export function CreateSurvey({ onDismiss, buttonDescription, title, submitButtonText }: CreateSurveyProps) {
  const accountId = useCurrentAccountId();
  const { state: survey } = useSurveyForm();

  const [formErrors, setErrors] = useState<Errors<SurveyInput>>({});

  const [createSurvey, { loading }] = useCreate();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      setErrors({});

      const [errors, validSurvey] = validate(survey);
      if (errors) {
        return setErrors((err) => ({
          ...err,
          ...errors,
        }));
      }

      const variables: MutationCreateSurveyArgs = {
        survey: { ...validSurvey, ownerAccountId: accountId },
      };

      await createSurvey({
        variables,
        onCompleted: () => onDismiss(),
      });
    },
    [survey, createSurvey, setErrors, onDismiss, accountId]
  );

  return (
    <Container>
      <PropertySheet onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>{title}</PropertySheet.HeaderRow>
        <SurveyForm errors={formErrors} disabled={loading} accountId={accountId} />
        <PropertySheet.ButtonColumn name="AddSurvey__Submit" disabled={loading} onClick={handleSubmit}>
          {submitButtonText}
        </PropertySheet.ButtonColumn>
        <PropertySheet.Row>
          <StyledTextColumn span={12} size="sm" color={'normal'}>
            {buttonDescription}
          </StyledTextColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
