import styled from 'styled-components';
import { TextInput, Button, Text, Checkbox } from 'rio-ui-components';

const TextInputStyled = styled(TextInput)`
  width: 100%;
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonStyled = styled(Button)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const LoginErrorStyled = styled(Text)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  min-height: 19px;
  text-align: center;
`;

const FormStyled = styled.form`
  width: 100%;
`;

const CheckboxStyled = styled(Checkbox)`
  div {
    width: 15px;
    height: 15px;
  }
`;

const CheckboxLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.sm.size};
  padding-left: ${(props) => props.theme.geometry.xs.spacing};
`;

export { TextInputStyled as TextInput, ButtonStyled as Button, LoginErrorStyled as LoginError, FormStyled as Form, CheckboxStyled as Checkbox, CheckboxLabel };
