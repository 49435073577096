import { useMutation } from "@apollo/client";
import { useNotification } from '../../../hooks';
import { APPROVE_UPLOAD } from '../index.queries';

export function useApproveUpload() {
  const { showNotification } = useNotification();
  return useMutation(APPROVE_UPLOAD, {
    onCompleted: () => {
      showNotification('Approved!');
    },
    onError: () => {
      showNotification(`Something went wrong. The data wasn't approved.`, 'danger');
    }
  });
}
