import React from 'react';
import { styled, Button, Modal } from '@rio/ui-components';

const ButtonsContainer = styled('div')`
  margin-top: 32px;
  display: flex;
  gap: 16px;
`;

const ButtonStyled = styled(Button)`
  flex: 1;
`;

interface DocumentModalProps {
  onDismiss: () => void;
  heading: string;
  children: React.ReactNode;
  onSubmit: () => void;
  submitButtonTitle: string;
  isSubmitting?: boolean;
  submitDisabled?: boolean;
  showButtons?: boolean;
}

export function DocumentModal({
  onDismiss,
  heading,
  children,
  onSubmit,
  submitButtonTitle,
  isSubmitting,
  submitDisabled,
  showButtons = true,
}: DocumentModalProps) {
  return (
    <Modal open onClose={onDismiss} title={heading} grid={{ xs: 9, md: 6, lg: 6 }}>
      {children}
      {showButtons && (
        <ButtonsContainer>
          <ButtonStyled variant="outlined" disabled={isSubmitting} onClick={onDismiss}>
            Cancel
          </ButtonStyled>
          <ButtonStyled variant="filled" disabled={isSubmitting || submitDisabled} onClick={onSubmit}>
            {submitButtonTitle}
          </ButtonStyled>
        </ButtonsContainer>
      )}
    </Modal>
  );
}
