import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Text, ErrorMessage, ContainerError, Col } from 'rio-ui-components';
import { GET_WASTE_STREAMS_BY_ACCOUNT_ID } from './index.queries';
import WasteStreamRow from './WasteStreamRow';
import NoResults from '../../../components/NoResults';
import { Query } from '@apollo/client/react/components';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const ColStyled = styled(Col)`
  p {
    padding: ${(p) => p.theme.geometry.md.profile.padding};
  }
`;

const RowsContainer = styled.div`
  flex: 1 1 auto;
`;
const refetchEvent = (refetch, onRefetch) => {
  refetch();
  onRefetch();
};

const WasteStreamGrid = (props) => {
  const { queryVariables, shouldRefetch, onRefetch, showModal } = props;
  return (
    <Fragment>
      <RowStyled container align="between">
        <ColStyled item span={2}>
          <Text weight="bold">Name</Text>
        </ColStyled>
        <ColStyled item span={2}>
          <Text weight="bold">EWC Code</Text>
        </ColStyled>
        <ColStyled item span={4}>
          <Text weight="bold">Description</Text>
        </ColStyled>
        <ColStyled item span={2}>
          <Text weight="bold">Hazardous</Text>
        </ColStyled>
        <ColStyled item span={2} />
      </RowStyled>
      <Query query={GET_WASTE_STREAMS_BY_ACCOUNT_ID} fetchPolicy="network-only" variables={queryVariables}>
        {({ data, error, loading, refetch }) => {
          if (shouldRefetch) refetchEvent(refetch, onRefetch);
          if (loading) return <ContainerLoadingIndicator name="ConfigurationWasteStreamsContainer__Loading" />;
          if (error)
            return (
              <ErrorMessage error={error}>
                {({ title, body, icon }) => (
                  <ContainerError
                    name="ConfigurationWasteStreamsContainer__ContainerError"
                    icon={icon}
                    title={title}
                    body={body}
                    retry={() => refetch()}
                  />
                )}
              </ErrorMessage>
            );

          if (data) {
            const wasteStreams = data.getWasteStreamsByAccountId.wasteStreams;
            return (
              <Fragment>
                {!wasteStreams.length && (
                  <NoResults
                    name="ConfigurationWasteStreamsContainer__NoResults"
                    title="There are no results for this search."
                  />
                )}
                {!!wasteStreams.length && (
                  <RowsContainer>
                    {wasteStreams.map((v) => (
                      <WasteStreamRow
                        key={v.id}
                        name={v.name}
                        id={v.id}
                        streamId={v.id}
                        ewc={v.ewc.code}
                        description={v.ewc.description}
                        isHazardous={v.isHazardous ? 'True' : 'False'}
                        onEdit={() => showModal('update', v.id, v.name, v.ewc)}
                      />
                    ))}
                  </RowsContainer>
                )}
              </Fragment>
            );
          }
        }}
      </Query>
    </Fragment>
  );
};

WasteStreamGrid.propTypes = {
  queryVariables: PropTypes.shape({
    accId: PropTypes.string,
    Page: PropTypes.number,
    PageSize: PropTypes.number,
    filterValue: PropTypes.string
  }),
  shouldRefetch: PropTypes.bool,
  onRefetch: PropTypes.func,
  onGetWasteStreamsCompleted: PropTypes.func
};

export default WasteStreamGrid;
