import { isNil } from 'lodash';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, DatePicker, TimePicker, Text, Button, Select } from '@rio/ui-components';
import { DataFormRowsFillingStrategy, DateFrequency, DataFormInputStrategy } from '~/constants';
import { combineDateAndTime } from './util';
import { CheckboxWrapper, ErrorText, GridStyled } from './style';
import moment from 'moment';

const getErrorMessage = (errors) => {
  if (errors.uploadReference?.type === 'maxLength') {
    return 'Upload reference max length is 80 characters';
  }

  if (errors.data) {
    return 'Please fill all mandatory fields';
  }
};

export const DataForm = (props) => {
  const { form, onSubmit = () => {}, children, showEndDate = true, showSubmitButton = true, showTime } = props;

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    control,
    trigger: triggerValidation,
    formState: { isValid, errors },
  } = form || {};

  const errMsg = getErrorMessage(errors);

  // TODO: remove or refactor when will be implemented proper ref forwarding in rio-ui-components
  useEffect(() => {
    register('dataFormInputStrategy', { defaultValue: DataFormInputStrategy.Single });
    register('dataFormRowsFillingStrategy', { defaultValue: DataFormRowsFillingStrategy.Distributed });
    register('financial.currency', { defaultValue: 'USD' });
    register('startDate', {
      defaultValue: null,
      required: true,
    });
    register('endDate', {
      defaultValue: null,
      validate: (v) => {
        if (!v || !getValues('startDate')) {
          return true;
        }

        const combinedStartDate = combineDateAndTime(getValues('startDate'), getValues('startTime') || '12:00');
        const combinedEndDate = combineDateAndTime(v, getValues('endTime') || '12:00');

        if (moment(combinedEndDate).isBefore(moment(combinedStartDate))) {
          return `End date ${showTime ? '(and time) ' : ''}must be before start date`;
        }

        return true;
      },
    });
    register('startTime', { defaultValue: null });
    register('endTime', { defaultValue: null });
  }, [register, getValues, showTime]);

  const [
    endTime,
    endDate,
    startTime,
    startDate,
    dateFrequencyValue,
    dataFormInputStrategyValue,
    dataFormRowsFillingStrategyValue,
  ] = watch([
    'endTime',
    'endDate',
    'startTime',
    'startDate',
    'dateFrequency',
    'dataFormInputStrategy',
    'dataFormRowsFillingStrategy',
  ]);

  const markAsSingle = () => setValue('dataFormInputStrategy', DataFormInputStrategy.Single);
  const markAsAggregated = () => setValue('dataFormInputStrategy', DataFormInputStrategy.Aggregated);
  const markFillingStrategyAsDistributed = () =>
    setValue('dataFormRowsFillingStrategy', DataFormRowsFillingStrategy.Distributed);
  const markFillingStrategyAsReplicated = () =>
    setValue('dataFormRowsFillingStrategy', DataFormRowsFillingStrategy.Replicated);
  const selectStartDate = (value) => {
    const combinedDate = combineDateAndTime(value, startTime || '12:00');
    setValue('startDate', combinedDate);
    triggerValidation('endDate');
  };
  const selectEndDate = (value) => {
    const combinedDate = combineDateAndTime(value, endTime || '12:00');
    setValue('endDate', combinedDate);
    triggerValidation('endDate');
  };
  const selectedStartTime = (value) => {
    if (startDate) {
      const combinedStartDate = combineDateAndTime(startDate, value || '12:00');
      setValue('startDate', combinedStartDate);
      triggerValidation('endDate');
    }
    setValue('startTime', value);
  };
  const selectedEndTime = (value) => {
    if (getValues(endDate)) {
      const combinedEndDate = combineDateAndTime(endDate, value || '12:00');
      setValue('endDate', combinedEndDate);
      triggerValidation('endDate');
    }
    setValue('endTime', value);
  };

  const isSingle = dataFormInputStrategyValue === DataFormInputStrategy.Single;
  const isAggregated = dataFormInputStrategyValue === DataFormInputStrategy.Aggregated;
  const isRowsFillingStrategyDistributed = dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed;
  const isDefault = !isSingle && !isAggregated;
  const isRowsFillingStrategyReplicated = dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Replicated;
  const isRowsFillingStrategyVisible = !isNil(dataFormInputStrategyValue) || isDefault;
  const isDateFrequencyVisible = isRowsFillingStrategyDistributed;
  const isDateRangeVisible = isRowsFillingStrategyReplicated || !isNil(dateFrequencyValue);
  const isStartDateNotEmpty = !isNil(startDate) && startDate !== '';
  const isDataFormVisible =
    ((isRowsFillingStrategyDistributed && !isNil(dateFrequencyValue) && isStartDateNotEmpty) ||
      (isRowsFillingStrategyReplicated && startDate)) &&
    (!showEndDate || endDate);
  const isSecondDatePickerVisible =
    dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed || showEndDate;
  const isErrorVisible = !isValid && isDataFormVisible;
  const frequencyDropdownOptions = Object.entries(DateFrequency).map(([, value]) => ({ label: value, value }));

  if (isDefault) setValue('dataFormInputStrategy', DataFormInputStrategy.Single);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridStyled container>
        <Text typescale="title" size="medium">
          Is this data entry for a single transaction or for aggregated set of data?
        </Text>
        <CheckboxWrapper container>
          <Grid xs={5.5}>
            <Checkbox
              name="Single_Container"
              checked={isSingle || isDefault}
              value={DataFormInputStrategy.Single}
              onChange={markAsSingle}
              label="Single (i.e. single commuter journey, regular waste collection or meter reading)"
            />
          </Grid>
          <Grid xs={5.5}>
            <Checkbox
              name="Aggregated_Container"
              checked={isAggregated}
              value={DataFormInputStrategy.Aggregated}
              onChange={markAsAggregated}
              label="Aggregated data (i.e annual waste tonnage, quarterly energy bill)"
            />
          </Grid>
        </CheckboxWrapper>

        {isRowsFillingStrategyVisible && (
          <>
            <Text typescale="title" size="medium">
              Do you want this data entry to be {isSingle || isDefault ? 'replicated' : 'distributed'} in your database
              and reports?
            </Text>
            <Grid container>
              <Grid xs={6}>
                <Checkbox
                  name="Replicated"
                  checked={isRowsFillingStrategyDistributed}
                  onChange={markFillingStrategyAsDistributed}
                  label="Yes"
                />
              </Grid>
              <Grid xs={6}>
                <Checkbox
                  name="NotReplicated"
                  checked={isRowsFillingStrategyReplicated}
                  onChange={markFillingStrategyAsReplicated}
                  label="No"
                />
              </Grid>
            </Grid>
          </>
        )}

        {isDateFrequencyVisible && (
          <Controller
            name="dateFrequency"
            render={({ field }) => (
              <Select
                {...field}
                label={`Frequency of data to be ${isSingle ? 'replicated' : 'distributed'}:`}
                onChange={(e) => field.onChange(e.value)}
                options={frequencyDropdownOptions}
              />
            )}
            control={control}
          />
        )}

        {isDateRangeVisible && (
          <>
            <DatePicker
              onChange={(val) => selectStartDate(val)}
              name="startDate"
              label="Start date"
              value={startDate || null}
            />

            {showTime && (
              <TimePicker
                label="Start time (optional)"
                name="startTime"
                onChange={(val) => selectedStartTime(val)}
                value={startTime}
              />
            )}

            {isSecondDatePickerVisible && (
              <>
                <DatePicker
                  name="endDate"
                  label="End date"
                  disabled={!startDate}
                  value={endDate}
                  onChange={(val) => selectEndDate(val)}
                  error={errors?.endDate?.message}
                />

                {showTime && (
                  <TimePicker
                    name="endTime"
                    label="End time (optional)"
                    value={endTime}
                    onChange={(val) => selectedEndTime(val)}
                  />
                )}
              </>
            )}
          </>
        )}

        {isDataFormVisible && children}

        {isErrorVisible && (
          <ErrorText typescale="body" size="medium">
            {errMsg}
          </ErrorText>
        )}

        {isDataFormVisible && showSubmitButton && (
          <Button type="submit" variant="filled">
            Confirm data entry
          </Button>
        )}
      </GridStyled>
    </form>
  );
};
