import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { isEmail } from 'validator';
import { Heading, Notification } from 'rio-ui-components';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthPage, Form, TextInput, Button } from '../../components/AuthPage';
import { ResendEmailButton } from './ResendEmailButton';

const TextInputStyled = styled(TextInput)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

export function VerifyEmail() {
  const { code: codeParam } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem('MEMOIZED_EMAIL'));
  const [code, setCode] = useState(codeParam || '');
  const [checking, setChecking] = useState(false);
  const [verified, setVerified] = useState(false);
  const [notification, setNotification] = useState(null);
  const checkCode = async () => {
    if (isEmail(email) && code.length) {
      try {
        setChecking(true);
        await Auth.confirmSignUp(email.trim().toLowerCase(), code);
        setVerified(true);
        setNotification({
          color: 'success',
          message: `Confirmed! Please go to Login.`,
        });
      } catch (err) {
        setNotification({
          color: 'danger',
          message: err.message,
        });
      } finally {
        setChecking(false);
      }
    }
  };
  useEffect(() => {
    checkCode();
    // eslint-disable-next-line
  }, []);
  return (
    <AuthPage>
      <Heading size="lg">Please verify your email</Heading>
      <Form>
        <TextInputStyled
          required
          type="email"
          value={email}
          placeholder="Your email"
          autoComplete="email"
          name="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextInputStyled
          required
          type="text"
          value={code}
          placeholder="Verification code"
          autoComplete={false}
          name="code"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <ResendEmailButton email={email} />
        <Button
          type="button"
          color={verified ? 'tertiary' : 'primary'}
          disabled={!code || !email}
          loading={checking}
          onClick={async () => {
            if (verified) {
              navigate(`/upgrade/courses`);
            } else {
              await checkCode();
            }
          }}
          submit
        >
          {verified ? 'Go to Login' : 'Verify'}
        </Button>
      </Form>
      {notification && (
        <Notification name={notification.message} color={notification.color} onClose={() => setNotification(null)} show>
          {notification.message}
        </Notification>
      )}
    </AuthPage>
  );
}
