import { AccordionDropdown, AccordionMenu, AccordionDropdownItem, Col, Row } from 'rio-ui-components';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { hasAccess } from '../../utils/routes';
import CustomRouteLink from '../../components/CustomRouteLink';
import { PageNotFound } from '../../components/Errors';
import NotificationBanner from '../../components/NotificationBanner';
import { countIncompleteSurveys } from '~/components/SideBar/countIncompleteSurveys';
import { usePermissions, useCurrentAccount, useCurrentAccountId, useUserToken, useRoutes } from '../../hooks';
import { SurveysContainer } from './SurveysContainer';
import { SurveyAuditLogContainer } from './SurveyAuditLogContainer';
import { ReceivedSurveyContainer } from './ReceivedSurveyContainer';
import { SurveyOverviewContainer } from './SurveyOverviewContainer';
import { MyContributorsContainer } from './MyContributorsContainer';
import { ReceivedSurveysContainer } from './ReceivedSurveysContainer';
import { useGetReceivedSurveysPage } from './ReceivedSurveysContainer/queries';
import { ReceivedSurveyAuditLogContainer } from './ReceivedSurveyAuditLogContainer';
import { MyContributorSubmissionContainer } from './MyContributorSubmissionContainer';
import { MyContributorAuditLogContainer, MyContributorContainer } from './MyContributorContainer';

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;
const AccordionMenuStyled = styled(AccordionMenu)`
  overflow: auto;
`;

const RowStyled = styled(Row)`
  height: 100%;
`;

const ContentContainerStyled = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ColStyled = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  z-index: 3;
  position: relative;
`;

const SurveysReceivedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const NotificationBadge = styled.div`
  background-color: #eb3223;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  font-size: 11px;
`;

interface Props {
  intl: { formatMessage: (props: { id: string }) => string };
}

const FormsContainer = ({ intl }: Props) => {
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const { token: idToken } = useUserToken();
  const routes = useRoutes();
  const hasFormManagerAccess = permissions.survey.some((action: string) => action.startsWith('getSurveys'));
  const hasContributorAccess = permissions.survey.some((action: string) => action.startsWith('getReceivedSurveys'));
  const account = useCurrentAccount();
  const hasSubscription = account ? account.accessControls.surveys : false;

  const variables = {
    accountId,
    userId: idToken.sub,
  };
  const { data: notificationSurveysData } = useGetReceivedSurveysPage(variables);
  const notificationCount = countIncompleteSurveys(notificationSurveysData?.getReceivedSurveysPage?.rows);

  return (
    <Container>
      {!hasSubscription && (
        <>
          <Overlay />
          <NotificationBannerStyled
            name="FormsContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title="Surveys"
            body="Currently Surveys are not included in your subscription, if you’d like access to Surveys please contact Rio ESG LTD or get in touch with your Business Admin."
          />
        </>
      )}
      <RowStyled container item>
        <ColStyled container item span={3}>
          <AccordionMenuStyled
            id="pic"
            name="forms-menu"
            inline
            size="md"
            text={<FormattedMessage id="pages.surveys.nav.heading" />}
            icon={['rio', 'clipboard-check-custom']}
          >
            {hasFormManagerAccess && (
              <CustomRouteLink to={routes.surveys.sent.overview} base="sent">
                <AccordionDropdown text="Surveys sent" dropdown>
                  <CustomRouteLink name="forms-menu__item--overview" to={routes.surveys.sent.overview}>
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.surveys.subjects.overview' })} />
                  </CustomRouteLink>
                  <CustomRouteLink name="forms-menu__item--contributors" to={routes.surveys.sent.contributors}>
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.surveys.subjects.contributors' })} />
                  </CustomRouteLink>
                  <CustomRouteLink name="forms-menu__item--surveys" to={routes.surveys.sent.surveys}>
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.surveys.subjects.surveys' })} />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}
            {hasContributorAccess && (
              <CustomRouteLink name="forms-menu__item--surveys" to={routes.surveys.received.surveys}>
                <AccordionDropdown
                  text={
                    <SurveysReceivedWrapper>
                      {!!notificationCount && <NotificationBadge>{notificationCount}</NotificationBadge>}
                      {intl.formatMessage({ id: 'pages.surveys.subjects.surveysReceived' })}
                    </SurveysReceivedWrapper>
                  }
                />
              </CustomRouteLink>
            )}
          </AccordionMenuStyled>
        </ColStyled>
        <Col container inline span={10}>
          <ContentContainerStyled>
            <Routes>
              <Route
                path={routes.surveys.sent.overview}
                element={hasAccess(SurveyOverviewContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.sent.contributors}
                element={hasAccess(MyContributorsContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.sent.contributor}
                element={hasAccess(MyContributorContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.sent.contributorAuditLog}
                element={hasAccess(MyContributorAuditLogContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.sent.submission}
                element={hasAccess(MyContributorSubmissionContainer, hasFormManagerAccess)}
              />
              <Route path={routes.surveys.sent.surveys} element={hasAccess(SurveysContainer, hasFormManagerAccess)} />
              <Route
                path={routes.surveys.sent.survey}
                element={hasAccess(SurveyOverviewContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.received.surveys}
                element={hasAccess(ReceivedSurveysContainer, hasContributorAccess)}
              />
              <Route
                path={routes.surveys.received.survey}
                element={hasAccess(ReceivedSurveyContainer, hasContributorAccess)}
              />
              <Route
                path={routes.surveys.sent.auditLog}
                element={hasAccess(SurveyAuditLogContainer, hasFormManagerAccess)}
              />
              <Route
                path={routes.surveys.received.auditLog}
                element={hasAccess(ReceivedSurveyAuditLogContainer, hasContributorAccess)}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ContentContainerStyled>
        </Col>
      </RowStyled>
    </Container>
  );
};

export default injectIntl(FormsContainer);
