import { useCallback, useMemo, useRef, useState } from 'react';
import { useCurrentAccountId, usePermissions } from '~/hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { IServerSideGetRowsParams, GridReadyEvent, GridApi, IRowNode } from 'ag-grid-community';
import { getAgRowId, mapFilterModel, mapSortModel } from '~/utils';
import { useGetDataAnomalies, useIgnoreDataAnomalies } from '../../Anomalies/queries';
import { useColumnDefs } from './useColumnDefs';
import { DataAnomaliesPlaceholder } from './DataAnomaliesPlaceholder';
import { LoadFailed, styled, Button, AgGrid } from '@rio/ui-components';
import { RowModelType } from 'ag-grid-community';
import { IgnoreDataAnomaliesModal } from './IgnoreDataAnomaliesModal';
import { DataAnomaly, VarianceReason } from '@rio/rio-types';

const AgGridContainerStyled = styled('div')`
  min-height: 480px;
`;

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: 480px;

  .ag-tooltip-custom {
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: 700px;
    height: 432px;
  }
`;

const ButtonsContainerStyled = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
  gap: 16px;
`;

const ButtonStyled = styled(Button)`
  width: 130px;
`;

const PAGINATION_PAGE_SIZE = 20;

export function DataAnomaliesGrid() {
  const accountId = useCurrentAccountId();
  const intl = useIntl();

  const [getDataAnomalies] = useGetDataAnomalies();

  const columnDefs = useColumnDefs();
  const gridApiRef = useRef<GridApi>();

  const [placeholderShown, setPlaceholderShown] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [toBeIgnoredDataAnomalies, setToBeIgnoredDataAnomalies] = useState<DataAnomaly[]>([]);

  const [isSelected, setIsSelected] = useState(false);
  const permissions = usePermissions();

  const [ignoreDataAnomalies] = useIgnoreDataAnomalies();

  const openConfirmationModal = useCallback(() => {
    if (!gridApiRef.current) {
      return;
    }
    const rows = gridApiRef.current.getSelectedRows();

    setIsConfirmationModalOpen(true);
    setToBeIgnoredDataAnomalies(rows);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
    setToBeIgnoredDataAnomalies([]);
  }, []);

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApiRef.current = event.api;
    event.api.setFilterModel({
      ignored: {
        values: ['0'],
        filterType: 'set',
      },
    });
  }, []);

  const dataSource = useMemo(
    () => ({
      getRows: async ({ request, success, fail }: IServerSideGetRowsParams) => {
        const sort = mapSortModel(request.sortModel);
        const filters = mapFilterModel(request.filterModel);
        try {
          const { data, error: getDataAnomaliesError } = await getDataAnomalies({
            variables: {
              accountId,
              sort,
              filters,
              limit: PAGINATION_PAGE_SIZE,
              offset: request.startRow || 0,
            },
          });
          if (getDataAnomaliesError) {
            throw getDataAnomaliesError;
          }
          if (!data) {
            throw new Error('Error at getDataAnomalies');
          }
          if (!data.getDataAnomaliesByAccountId.rows.length && !Object.keys(request.filterModel || {}).length) {
            return setPlaceholderShown(true);
          }
          success({
            rowData: data.getDataAnomaliesByAccountId.rows,
            rowCount: data.getDataAnomaliesByAccountId.totalRows,
          });
        } catch (err) {
          setError(err as Error);
          fail();
        }
      },
    }),
    [getDataAnomalies, accountId]
  );

  const handleAccept = useCallback(
    (varianceReason?: VarianceReason | null, notes?: string | null) => {
      if (!gridApiRef.current) {
        return;
      }
      const rows = gridApiRef.current.getSelectedRows();

      ignoreDataAnomalies({
        variables: {
          anomalyIds: rows.map((row) => row.id),
          varianceReason,
          notes,
        },
        onCompleted: () => {
          gridApiRef.current?.refreshServerSide();
          gridApiRef.current?.deselectAll();
          setIsSelected(false);
        },
      });
    },
    [ignoreDataAnomalies]
  );

  const handleSelectionChanged = useCallback(() => {
    if (!gridApiRef.current) {
      return;
    }

    const selectedRowsList = gridApiRef.current.getSelectedRows();
    setIsSelected(!!selectedRowsList?.length);
  }, []);

  const handleSelection = useCallback((shouldDeselectAll?: boolean) => {
    if (gridApiRef.current) {
      const currentPage = gridApiRef.current.paginationGetCurrentPage();
      const startRow = currentPage * PAGINATION_PAGE_SIZE;
      const endRow = startRow + PAGINATION_PAGE_SIZE - 1;

      const nodesOnCurrentPage: IRowNode[] = [];
      for (let i = startRow; i <= endRow; i++) {
        const node = gridApiRef.current.getDisplayedRowAtIndex(i);
        if (node) {
          nodesOnCurrentPage.push(node);
        }
      }

      if (shouldDeselectAll) {
        gridApiRef.current.deselectAll();
        setIsSelected(false);
        return;
      }

      nodesOnCurrentPage.forEach((rowNode) => {
        if (!rowNode.isSelected()) {
          rowNode.setSelected(true);
        }
      });
    }
  }, []);

  const hasPermissionToManage = useMemo(
    () => permissions.dataTrackerAnomalies.some((p) => p.startsWith('ignoreDataAnomalies')),
    [permissions]
  );

  const gridOptions = useMemo(
    () => ({
      tooltipInteraction: true,
      tooltipShowDelay: 700,
      suppressRowClickSelection: true,
      pagination: true,
      paginationPageSize: PAGINATION_PAGE_SIZE,
      cacheBlockSize: PAGINATION_PAGE_SIZE,
      paginationPageSizeSelector: false,
      rowModelType: 'serverSide' as RowModelType,
      serverSideDatasource: dataSource,
      rowSelection: 'multiple' as const,
      onGridReady: handleGridReady,
      getRowId: getAgRowId('id'),
      noRowsOverlayComponent: DataAnomaliesPlaceholder,
      columnDefs: columnDefs,
      onSelectionChanged: handleSelectionChanged,
    }),
    [dataSource, columnDefs, handleSelectionChanged, handleGridReady]
  );

  if (error) {
    return <LoadFailed title="Something went wrong" error={error} />;
  }

  if (placeholderShown) {
    return <DataAnomaliesPlaceholder />;
  }

  return (
    <div>
      {hasPermissionToManage && (
        <ButtonsContainerStyled>
          <ButtonStyled
            color="primary"
            variant="outlined"
            onClick={() => handleSelection()}
            disabled={!gridApiRef.current}
            title={intl.formatMessage({ id: 'pages.data.overview.selectAll' })}
          >
            <FormattedMessage id="pages.data.overview.selectAllButtonText" />
          </ButtonStyled>
          {isSelected && (
            <ButtonStyled
              color="primary"
              variant="outlined"
              onClick={() => handleSelection(true)}
              disabled={!gridApiRef.current}
              title={intl.formatMessage({ id: 'pages.data.overview.deselectAll' })}
            >
              <FormattedMessage id="pages.data.overview.deselectAllButtonText" />
            </ButtonStyled>
          )}
          <ButtonStyled
            color="primary"
            variant="filled"
            onClick={openConfirmationModal}
            title={intl.formatMessage({ id: 'pages.data.overview.acceptInfo' })}
          >
            <FormattedMessage id="pages.data.overview.accept" />
          </ButtonStyled>
        </ButtonsContainerStyled>
      )}

      {isConfirmationModalOpen && (
        <IgnoreDataAnomaliesModal
          onClose={closeConfirmationModal}
          onConfirm={handleAccept}
          toBeIgnoredDataAnomalies={toBeIgnoredDataAnomalies}
        />
      )}

      <AgGridContainerStyled>
        <AgGridStyled gridKey="dataAnomaliesGridV2" gridOptions={gridOptions} />
      </AgGridContainerStyled>
    </div>
  );
}
