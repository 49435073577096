import { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import { SurveyModel } from 'survey-core';
import { useNavigate } from 'react-router-dom';
import { SurveySubmissionStatusInput, SurveySubmissionStatus } from '@rio/rio-types';
import { useCurrentAccountId, useNotification, useCurrentUser } from '~/hooks';
import { useSurveyContext } from '~/components/SurveyProvider';
import { transformSubmissionToAnswers } from '../utils';
import {
  useSubmitData,
  useUploadedDocuments,
  useCreateSurveyAuditEvent,
  useSetSurveySubmissionUpdated,
} from '../hooks';

export const useSurveyReceived = (survey) => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const { expanded, setExpanded } = useSurveyContext();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [newSubscriptionUpdate, setNewSubscriptionUpdate] = useState(false);

  const accountId = useCurrentAccountId();
  const submission = survey.submission;
  const { uploadedDocuments, uploadedDocumentsIds } = useUploadedDocuments(survey.submission);
  const user = useCurrentUser();
  const { createSurveyAuditEvent } = useCreateSurveyAuditEvent();
  const timeoutId = useRef<number | null>(null);

  useSetSurveySubmissionUpdated(survey.id, accountId);

  const { id, first_name: firstName, last_name: lastName } = survey?.lockedBy || {};
  const isLocked = user?.id !== id && !!id;
  const editingUser = firstName + ' ' + lastName;

  const prevSurveyModelRef = useRef<null | SurveyModel>(null);

  const surveyModel = useMemo(() => {
    const model = new SurveyModel(survey.template.formContent);
    model.showCompletedPage = false;
    model.showNavigationButtons = false;
    model.showPreviewBeforeComplete = 'showAnsweredQuestions';
    if (survey.submission) {
      model.data = transformSubmissionToAnswers(survey.submission.answers, uploadedDocuments);
      if (
        submission &&
        (submission.status === SurveySubmissionStatus.Approved ||
          submission.status === SurveySubmissionStatus.ReadyForReview)
      ) {
        model.mode = 'display';
      }
    }

    const prevSurveyModel = prevSurveyModelRef.current;

    if (prevSurveyModel) {
      prevSurveyModelRef.current = model;
    }

    model.currentPageNo = prevSurveyModel?.currentPageNo || 0;
    prevSurveyModelRef.current = model;

    return model;
  }, [survey.template.formContent, survey.submission, uploadedDocuments, submission]);

  const { submitData, loading } = useSubmitData({
    surveyId: survey.id,
    accountId,
    model: surveyModel,
    uploadedDocumentsIds,
    version: submission?.version || 1,
  });

  const surveyComplete = useCallback(
    async () =>
      submitData(
        SurveySubmissionStatusInput.ReadyForReview,
        () => {
          showNotification('Survey submitted successfully', 'success');
          navigate('../received');
        },
        (error) => {
          showNotification(`Error submitting survey: ${error.message}`, 'danger');
        }
      ),
    [showNotification, navigate, submitData]
  );

  const saveSurvey = useCallback(() => {
    submitData(
      SurveySubmissionStatusInput.InProgress,
      () => {
        setNewSubscriptionUpdate(true);
        timeoutId.current = setTimeout(() => {
          setNewSubscriptionUpdate(false);
        }, 3000) as unknown as number;
      },
      (error) => {
        showNotification(`Error saving survey: ${error.message}`, 'danger');
      }
    );
  }, [showNotification, submitData]);

  const createAuditEvent = useCallback(
    async (auditType, receivedSurvey, userId) => createSurveyAuditEvent(auditType, receivedSurvey, accountId, userId),
    [createSurveyAuditEvent, accountId]
  );

  useEffect(() => {
    surveyModel.onComplete.clear();
    surveyModel.onComplete.add(surveyComplete);

    createAuditEvent('opened', survey, user?.id);

    return () => {
      createAuditEvent('closed', survey, user?.id);
    };
  }, [surveyModel, surveyComplete, survey, user, createAuditEvent]);

  const isMultipageSurvey = surveyModel.pageCount > 1;

  const handleSubmit = useCallback(async () => {
    setSubmitButtonDisabled(true);

    try {
      if (isMultipageSurvey) {
        if (surveyModel.validate()) {
          await surveyModel.completeLastPage();
        } else {
          showNotification('Please check fields validation on all pages', 'danger');
          return;
        }
      } else {
        await surveyModel.completeLastPage();
      }

      showNotification('Survey submitted successfully', 'success');
      navigate('../received');
    } catch (error) {
      if (error instanceof Error) {
        showNotification(`Error submitting survey: ${error.message}`, 'danger');
      }
    } finally {
      setSubmitButtonDisabled(false);
    }
  }, [surveyModel, showNotification, isMultipageSurvey, navigate]);

  return {
    loading,
    surveyModel,
    isLocked,
    editingUser,
    saveSurvey,
    newSubscriptionUpdate,
    handleSubmit,
    expanded,
    submitButtonDisabled,
    expand: useCallback(() => setExpanded(true), []),
    collapse: useCallback(() => setExpanded(false), []),
  };
};
