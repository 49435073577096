import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ReportingFrameworkLayout, ReportProgressByGroup } from '../../../../../components/ReportingFramework';
import { ManagementSystemPage } from '../../../../../types';
import { MainProgressPages } from '../../constants';

interface CarbonDisclosureProgressProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function EcbProgress({ page, framework }: CarbonDisclosureProgressProps) {
  const content = useMemo(
    () => ({
      header: 'Progress:',
      render: () => (
        <ReportProgressByGroup managementSystem={framework} page={page} progressMainPage={MainProgressPages.ECB} />
      )
    }),
    [framework, page]
  );

  return <ReportingFrameworkLayout title="Completion" page={page} framework={framework} content={content} />;
}
