import { useState } from 'react';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Grid, Select, Button, styled, TextField } from '@rio/ui-components';
import { useNotification } from '~/hooks/useNotification';
import { useCurrentAccountId } from '~/hooks/useCurrentAccountId';
import { TENURE, BUILDINGTYPE, REGION } from '~/constants/locationMetaOptionTypes';
import { GET_LOCATION_META_OPTIONS, CREATE_LOCATION_META_OPTION } from './index.queries';

const FormStyled = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  width: calc(50% - 12px);
`;

const GridWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const getOptionAccesser = (type) => {
  switch (type) {
    case REGION:
      return 'regions';
    case BUILDINGTYPE:
      return 'buildingTypes';
    case TENURE:
      return 'tenures';
    default:
      return '';
  }
};

export const LocationMetaOptionSelect = ({
  optionType = TENURE,
  accountId,
  selectedOption,
  label,
  setSelectedOption,
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [optionName, setOptionName] = useState('');
  const { showNotification } = useNotification();
  const currentAccountId = useCurrentAccountId();

  const { data } = useQuery(GET_LOCATION_META_OPTIONS, { variables: { accountId: accountId || currentAccountId } });

  const [createOption] = useMutation(CREATE_LOCATION_META_OPTION, {
    onCompleted: () => {
      setShowCreate(false);
      setOptionName('');
      showNotification('Option has been created.');
    },
    onError: () => {
      showNotification('Something went wrong please try again later.', 'danger');
    },
  });

  const fetchedOptions = [
    { name: 'Create New...', id: 'NEW' },
    { name: 'Not Specified', id: null },
    ...get(data, `getLocationMetadataOptionsForAccount.${getOptionAccesser(optionType)}`, []),
  ];

  const handleChange = (e) => {
    if (e.value === 'NEW') {
      setShowCreate(true);
    } else {
      setSelectedOption(e);
    }
  };

  const handleCreate = () => {
    if (!!optionName) {
      const newId = uuid();
      createOption({
        variables: {
          id: newId,
          accountId: accountId || currentAccountId,
          name: optionName,
          type: optionType,
        },
        refetchQueries: ['GetLocationMetaOptions'],
      });
      setSelectedOption({ value: newId, label: optionName });
    } else {
      showNotification('Name is a required field.', 'danger');
    }
  };

  const mappedOptions = fetchedOptions.map((op) => {
    return { label: op.name, value: op.id };
  });

  return (
    <>
      <Select label={label} options={mappedOptions} value={selectedOption} onChange={(e) => handleChange(e)} />

      {showCreate && (
        <Modal title="Create Option" open onClose={() => setShowCreate(false)} grid={{ xs: 8, md: 6 }}>
          <FormStyled>
            <TextField
              onChange={(e) => setOptionName(e.target.value)}
              label="Name (Required)"
              name="name"
              value={optionName}
            />
            <GridWrapper container>
              <ButtonStyled variant="outlined" onClick={() => setShowCreate(false)}>
                Cancel
              </ButtonStyled>
              <ButtonStyled variant="filled" onClick={() => handleCreate()}>
                Create
              </ButtonStyled>
            </GridWrapper>
          </FormStyled>
        </Modal>
      )}
    </>
  );
};
