import { GridOptions } from 'ag-grid-community';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'rio-ui-components';
import { useAgGrid, useCurrentAccountId, useAccessControls } from '../../../hooks';
import { Grid } from '../../../components/UI';
import PageHeader from '../../../components/PageHeader';
import { PageSizeSelect } from '../../../components/TransactionGrid/PageSizeSelect';
import { ClearButton } from '../../../components/ClearButton';
import areas from '../areas';

const ColStyled = styled(Col)`
  flex: 1;
`;

const white = css`
  color: white;
`;

const GridStyled = styled(Col)`
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};

  .background-blue {
    background-color: #6a99d0;
    ${white}
  }

  .background-green {
    background-color: #b1cf95;
    ${white}
  }

  .background-orange {
    background-color: #e9b38b;
    ${white}
  }
`;
const ControlPanel = styled(Row)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  *:not(:last-child) {
    margin-right: ${(props) => props.theme.geometry.xs.spacing};
  }
`;

const Description = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

interface IMateriality {
  pillar: string;
  sustainabilityArea: string;
  subject: string;
  metricDescription: string;
  sfdr: string;
  euTaxonomy: string;
  gri: string;
  capitalism: string;
  sasb: string;
  cdp: string;
  ipieca: string;
  rio: string;
  report: string;
  funds: string;
}

const gridOptions: GridOptions = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: false,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
  rowSelection: 'multiple',
  columnDefs: [
    {
      headerName: 'Pillar',
      field: 'pillar',
      headerClass: 'background-blue',
    },
    {
      headerName: 'Sustainability Area',
      field: 'sustainabilityArea',
      headerClass: 'background-blue',
    },
    {
      headerName: 'Subject',
      field: 'subject',
      filter: 'agTextColumnFilter',
      minWidth: 300,
      editable: true,
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      headerClass: 'background-blue',
    },
    {
      headerName: 'Metric Description',
      field: 'metricDescription',
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      minWidth: 300,
      headerClass: 'background-blue',
    },
    {
      headerName: 'Reported Metric',
      field: 'reportedMetric',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-blue',
    },
    {
      headerName: 'SFDR',
      field: 'sfdr',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-green',
    },
    {
      headerName: 'EU Taxonomy',
      field: 'euTaxonomy',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-green',
    },
    {
      headerName: 'TCFD',
      field: 'tcfd',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'GRESB',
      field: 'gresb',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'WEF Measuring Stakeholder capitalism',
      field: 'capitalism',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'SASB',
      field: 'sasb',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'CDP',
      field: 'cdp',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'EPIECA',
      field: 'epieca',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'Rio',
      field: 'rio',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-orange',
    },
    {
      headerName: 'Report',
      field: 'report',
      filter: 'agSetColumnFilter',
      cellEditor: 'agSelectCellEditor',
      editable: true,
      cellEditorParams: {
        values: ['', 'Yes', 'No'],
      },
      headerClass: 'background-blue',
    },
    {
      headerName: 'Funds/Channels reporting',
      field: 'funds',
      filter: 'agTextColumnFilter',
      editable: true,
      headerClass: 'background-blue',
    },
  ],
};

export const MaterialityComponent = ({ materialities }: { materialities: IMateriality[] }) => {
  const accountId = useCurrentAccountId();
  const [pageSize, setPageSize] = useState(gridOptions.paginationPageSize || 25);
  const agGrid = useAgGrid();
  const { data: accessControls } = useAccessControls();
  const availableGovernancePages = areas.filter((area) => !area.comingSoon && accessControls.comply[area.accessIndex]);
  return (
    <ColStyled container item>
      <PageHeader
        name="materiality"
        title="Materiality"
        breadcrumbs={[{ title: 'Governance', to: '/governance' }, { title: 'Materiality' }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown
        dropdownItems={availableGovernancePages}
        dropdownDefault="Materiality"
      >
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <Col span={4}>
            <PageSizeSelect
              value={pageSize}
              onChange={(size: number) => {
                setPageSize(size);
                if (agGrid.api) {
                  agGrid.api.paginationSetPageSize(size);
                }
              }}
            />
          </Col>
          <Col span={4}>
            <ClearButton gridApi={agGrid.api} columnApi={agGrid.columnApi} />
          </Col>
        </ControlPanel>
      </PageHeader>
      <Description>
        Conduct your materiality analysis here to identify what Sustainability/ESG topics are most important to your
        organisation.
      </Description>
      <GridStyled container item>
        <Grid
          {...gridOptions}
          key={accountId} // This will re-mount the grid and refetch the data when account changes
          paginationPageSize={pageSize}
          cacheBlockSize={pageSize}
          onColumnVisible={agGrid.onSaveGridColumnState}
          onColumnPinned={agGrid.onSaveGridColumnState}
          onColumnResized={agGrid.onSaveGridColumnState}
          onColumnMoved={agGrid.onSaveGridColumnState}
          onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
          onColumnValueChanged={agGrid.onSaveGridColumnState}
          onColumnPivotChanged={agGrid.onSaveGridColumnState}
          onDestroy={agGrid.onDestroy}
          rowData={materialities}
          onGridReady={(params) => {
            agGrid.api?.resetRowHeights();
            agGrid.onGridReady(params);
          }}
        />
      </GridStyled>
    </ColStyled>
  );
};

export const Materiality = React.memo(MaterialityComponent);
