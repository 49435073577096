import { Text, styled } from '@rio/ui-components';

const ProfileContainer = styled('div')`
  background-color: ${({ theme }) => theme.sys.color.surface};
  border: 2px solid ${({ theme }) => theme.sys.color.outlineVariant};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
`;

const ProfilePicture = styled('img')`
  border: 2px solid ${({ theme }) => theme.sys.color.outlineVariant};
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
`;

export const CreatorCell = (props) => {
  const {
    data: { author },
  } = props;

  return author.profilePicture ? (
    <ProfilePicture key={author.profilePicture + author.first_name} src={author.profilePicture} />
  ) : (
    <ProfileContainer>
      <Text typescale="headline" size="small">
        {author.first_name.charAt(0).toUpperCase()}
      </Text>
    </ProfileContainer>
  );
};
