import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { NamedEntity, Project, User } from '@rio/rio-types';
import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { GridOptions, ValueGetterParams, GridReadyEvent, ColumnState } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import capitalize from 'lodash/capitalize';
import { CategoryCell, CostCell, DescriptionCell, OwnersCell, PriorityCell, StartDateCell } from '../GridCell/index';
import { compareDate, defaultFilterParams, formatDateRange, getAgRowId } from '../../../utils';
import { AgGrid as AgGridInterface } from '../../../hooks/useAgGrid';

const RowStyled = styled(Row)`
  flex: 1;

  .ag-header-cell-resize {
    z-index: 0;
  }
`;

interface GridComponentProps {
  projects: Project[];
  showGoTo: boolean;
  agGrid: AgGridInterface;
}

const defaultSortModel = [
  { colId: 'startDate', sort: 'asc', sortIndex: 0 },
  { colId: 'name', sort: 'asc', sortIndex: 1 },
];

function GridComponent(props: GridComponentProps) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { projects } = props;

  const components = {
    category: CategoryCell,
    costCell: CostCell,
    description: DescriptionCell,
    owners: OwnersCell,
    priority: PriorityCell,
    startDate: StartDateCell,
  };

  const textCellStyle = {
    lineHeight: '21px',
    wordBreak: 'inherit',
    display: 'flex',
    alignItems: 'center',
  };
  const options: GridOptions = {
    onRowClicked: (e) => {
      navigate(`./${e.data.id}`);
    },
    rowHeight: 120,
    columnDefs: [
      {
        headerName: formatMessage({ id: 'pages.project.grid.header.name' }),
        field: 'name',
        filterParams: {
          ...defaultFilterParams(),
        },
        width: 160,
        minWidth: 160,
        cellStyle: textCellStyle,
      },

      {
        headerName: 'Priority',
        field: 'priority',
        sortable: false,
        filter: true,
        hide: true,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.impact' }),
        field: 'impact',
        sortable: false,
        filter: false,
        width: 200,
        minWidth: 160,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.description' }),
        cellRenderer: 'description',
        sortable: false,
        filter: false,
        minWidth: 50,
        width: 110,
        cellStyle: { textAlign: 'center' },
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.locations' }),
        valueGetter: ({ data: { locations } }) => locations?.map((item: NamedEntity) => item?.name),
        filterParams: {
          ...defaultFilterParams,
        },
        minWidth: 130,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.meters' }),
        valueGetter: ({ data: { meters } }) => meters?.map((item: NamedEntity) => item?.name),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 110,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.category' }),
        cellRenderer: 'category',
        filterParams: {
          ...defaultFilterParams(),
        },
        width: 130,
        minWidth: 130,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.scheme' }),
        valueGetter: ({ data: { scheme } }) => (scheme ? scheme.name : ''),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 110,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.owners' }),
        field: 'owners',
        cellRenderer: 'owners',
        valueGetter: ({ data: { owners = [] } }) =>
          owners.map(({ first_name, last_name }: User) => `${first_name} ${last_name}`),
        filterParams: {
          ...defaultFilterParams(),
        },
        width: 160,
        minWidth: 90,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.cost' }),
        field: 'cost',
        cellRenderer: 'costCell',
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.implementationDate' }),
        field: 'startDate',
        cellRenderer: 'startDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          ...defaultFilterParams(),
          comparator: compareDate,
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.baselineDate' }),
        cellStyle: textCellStyle,
        valueGetter: ({ data: { baselineStartDate } }: ValueGetterParams) =>
          formatDateRange([
            baselineStartDate,
            new Date(baselineStartDate).setFullYear(new Date(baselineStartDate).getFullYear() + 1),
          ]),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.benefitsDate' }),
        cellStyle: textCellStyle,
        valueGetter: ({ data: { startDate, endDate } }: ValueGetterParams) => formatDateRange([startDate, endDate]),
        filterParams: {
          ...defaultFilterParams(),
          comparator: compareDate,
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.plannedBenefit' }),
        field: 'plannedBenefit',
        cellStyle: textCellStyle,
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.plannedCostChange' }),
        field: 'plannedCostChange',
        cellStyle: textCellStyle,
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.plannedCO2eChange' }),
        field: 'plannedCO2eChange',
        cellStyle: textCellStyle,
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.project.grid.header.frequency' }),
        valueGetter: ({ data: { frequency } }) => capitalize(frequency),
        cellStyle: textCellStyle,
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },
    ],

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        height: 'auto',
      },
    },

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <RowStyled container>
      <AgGrid
        rowData={projects}
        components={components}
        getRowId={getAgRowId('id')}
        onGridReady={(params: GridReadyEvent) => {
          params.columnApi.applyColumnState({
            state: defaultSortModel as ColumnState[],
          });
          props.agGrid.onGridReady(params);
        }}
        onFilterChanged={props.agGrid.onFilterChanged}
        onFirstDataRendered={props.agGrid.onFirstDataRendered}
        {...options}
      />
    </RowStyled>
  );
}

export const Grid = GridComponent;
