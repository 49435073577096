import React from 'react';
import { Icons, styled, FileUploadButton } from '@rio/ui-components';

const SelectFilesContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 16px;
  min-height: 250px;
`;

interface SelectFilesProps {
  onFiles: (files: File[]) => void;
}

export function SelectFiles({ onFiles }: SelectFilesProps) {
  return (
    <SelectFilesContainer>
      <Icons.CloudUpload fontSize="large" />
      <FileUploadButton multiple onFileUpload={onFiles}>
        Select files
      </FileUploadButton>
    </SelectFilesContainer>
  );
}
