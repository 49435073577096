import React, { useState } from 'react';
import styled from 'styled-components';
import { MarketCarbonConversionFactor, MarketCarbonConversionFactorInput } from '@rio/rio-types';
import { Modal, Button, Row, Col } from 'rio-ui-components';
import { MarketCarbonConversionFactorDetails } from './MarketCarbonConversionFactorDetails';
import { useUpdateMarketCarbonConversionFactor } from './useUpdateMarketCarbonConversionFactors';
import { useDeleteMarketCarbonConversionFactor } from './useDeleteMarketCarbonConversionFactor';
import { useNotification } from '../../../hooks';
import { validateInput } from './utils';

interface EditMarketCarbonConversionFactorModalProps {
  factor: MarketCarbonConversionFactorInput;
  onComplete: (factors: MarketCarbonConversionFactor[]) => void;
  onDismiss: () => void;
}
const RowStyled = styled(Row)`
  justify-content: space-between;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ColStyled = styled(Col)`
  max-width: 48%;
  flex-grow: 0;
`;

export function UpdateMarketCarbonConversionFactorModal({
  factor,
  onDismiss,
  onComplete
}: EditMarketCarbonConversionFactorModalProps) {
  const [editedFactor, setEditedFactor] = useState(factor);
  const [errors, setErrors] = useState({});
  const [updateMarketCarbonConversionFactor] = useUpdateMarketCarbonConversionFactor({
    onComplete
  });
  const [deleteMarketCarbonConversionFactor] = useDeleteMarketCarbonConversionFactor({
    onComplete
  });
  const { showNotification } = useNotification();
  return (
    <Modal size="md" onDismiss={onDismiss} show>
      <Row container>
        <MarketCarbonConversionFactorDetails
          heading="Edit Market Based Carbon Conversion Factor"
          isSubmitting={false}
          onChange={(newFactor) =>
            setEditedFactor((oldFactor) => ({
              ...oldFactor,
              ...newFactor
            }))
          }
          values={editedFactor}
          errors={errors}
          setErrors={setErrors}
        />
      </Row>
      <RowStyled container>
        <ColStyled item span={6}>
          <Button
            color="danger"
            onClick={async () => {
              const agreed = window.confirm('Are you sure you want to delete this Tariff?');
              if (!agreed) {
                return;
              }
              try {
                await deleteMarketCarbonConversionFactor({
                  variables: {
                    id: editedFactor.id
                  }
                });
                showNotification('Tariff has been deleted', 'success');
              } catch (err) {
                showNotification((err as Error).message, 'danger');
              }
            }}>
            Delete
          </Button>
        </ColStyled>
        <ColStyled item span={6}>
          <Button
            color="success"
            onClick={async () => {
              try {
                const errors = validateInput(editedFactor);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }
                await updateMarketCarbonConversionFactor({
                  variables: {
                    factor: editedFactor
                  }
                });
                showNotification('Tariff has been edited', 'success');
              } catch (err) {
                showNotification((err as Error).message, 'danger');
              }
            }}>
            Edit
          </Button>
        </ColStyled>
      </RowStyled>
    </Modal>
  );
}
