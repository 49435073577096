import React from 'react';
import { LearnContentType } from '@rio/rio-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Row, Heading, MarkdownRenderer } from 'rio-ui-components';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { LinkRef } from '../../../../components/CustomRouteLink';
import { NOTSTARTED, INPROGRESS, COMPLETE } from '../../../../constants/courseProgress';
import { validate } from 'uuid';

import BannerTile from './BannerTile';

const ScrollableBanner = styled.div`
  padding: ${({ column }) => (column ? '0' : '0 10px 0 18px')};
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: start;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(${({ column }) => (column ? '1' : '10')}, minmax(180px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
  margin: 0;
`;

const BannerHeader = styled.h1`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.geometry.xxs.spacing};
  font-size: ${(props) => props.theme.fonts.xxl.size};
`;

const BannerContainer = styled.div`
  margin-top: ${({ theme, noBackground }) => (noBackground ? '0' : theme.geometry.md.spacing)};
  background: ${({ noBackground }) => (noBackground ? 'none' : 'white')};
  color: ${(props) => props.theme.colors.text.light.text};
  min-height: ${({ noBackground }) => (noBackground ? '0' : '232px')};
`;

const CourseTileStyled = styled(BannerTile)`
  border: none;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: ${(props) => props.theme.geometry.xs.spacing};
  align-items: center;
`;

const LinkStyled = styled(Link)`
  text-decoration: underline;
  opacity: 0.7;
  font-size: ${({ theme }) => theme.fonts.md.size};
`;

const CompletedIconStyled = styled(Icon)`
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
  color: ${({ theme }) => theme.colors.success.normal.background};
`;

const InProgressIconStyled = styled(Icon)`
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
  color: ${({ theme }) => theme.colors.primary.normal.background};
`;

const BundleDescription = styled.div`
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
`;

const MarkdownRendererStyled = styled(MarkdownRenderer)`
  > p {
    font-family: ${(props) => props.theme.fonts.family};
    color: ${(props) => props.theme.colors.text.light.text};
    font-weight: ${(props) => props.theme.fonts.weight.default};
  }
`;

const CourseTilePlaceholder = ({ column }) => {
  return (
    <Link name={`CoursesBanner__Link__Placeholder`} to={``} key={1}>
      <CourseTileStyled
        learnAccess={true}
        courseId={0}
        title="Placeholder"
        viewed={false}
        type={null}
        image={''}
        key={1}
        placeholder={true}
        column={column}
      />
    </Link>
  );
};

const shuffleIfExplore = (items, type) => {
  if (type === 'Explore') return _.shuffle(items);
  return items;
};

const CoursesBanner = ({
  name,
  items,
  sectionTitle,
  highlightColor,
  learnAccess,
  loading,
  column,
  noBackground,
  noHeader,
  type,
  restricted,
  restrictedCourseList,
  bundleDescription,
}) => {
  return (
    <BannerContainer name={name} highlightColor={highlightColor} noBackground={noBackground}>
      {!noHeader && (
        <TitleRow>
          <BannerHeader>{sectionTitle}</BannerHeader>
          <LinkStyled name={`${type}-ViewAll`} to={items.length > 0 ? `/engage/${type.toLowerCase()}` : 'contact'}>
            <FormattedMessage id="pages.learn.viewLink" values={{ amount: items.length }} />
          </LinkStyled>
          <BundleDescription>
            {
              <MarkdownRendererStyled
                name="Bundle__MarkDown--Description"
                source={_.truncate(bundleDescription, {
                  length: 100,
                  separator: ' ',
                  omission: ' ...',
                })}
              />
            }
          </BundleDescription>
        </TitleRow>
      )}
      <ScrollableBanner column={column}>
        {loading &&
          ' '
            .repeat(10)
            .split('')
            .map((x, i) => (
              <CourseTilePlaceholder key={`Course__Loading__key__${i}`} column={column}>
                Loading
              </CourseTilePlaceholder>
            ))}
        {!loading &&
          shuffleIfExplore(items, type)
            .slice(0, 10)
            .map((item, index) => {
              const courseProgress =
                item.userCourseMetaData && item.userCourseMetaData.courseProgress
                  ? item.userCourseMetaData.courseProgress
                  : NOTSTARTED;
              const bundleId = validate(type) ? type : item?.bundleIdList[0];

              const title = (
                <Row container item itemAlign="center">
                  <Heading name="CourseTile__Title" size="md" color="dark" weight="light">
                    {item.name}
                  </Heading>
                  {courseProgress === COMPLETE && <CompletedIconStyled icon="check" size="1x" />}
                  {courseProgress === INPROGRESS && <InProgressIconStyled icon="clock" size="1x" />}
                </Row>
              );
              let restrictedFlag = index === 0 ? false : restricted;
              restrictedFlag = type === 'Explore' && restrictedCourseList.includes(item.id) ? true : restrictedFlag;
              const url = restrictedFlag
                ? '#'
                : `./${bundleId ? bundleId + '/' : ''}course${item.type === LearnContentType.Scorm ? '/SCORM' : ''}/${
                    item.id
                  }`;
              return (
                <LinkRef
                  name={`CoursesBanner__Link__${item.id}`}
                  to={url}
                  key={`link${index}-${item.id}`}
                  restricted={restrictedFlag}
                  title={restrictedFlag ? 'Not yet available - part of restricted content order.' : ''}
                >
                  <CourseTileStyled
                    restricted={restrictedFlag}
                    learnAccess={learnAccess}
                    courseId={item.id}
                    title={title}
                    viewed={item.metaData.viewed}
                    type={item.type}
                    image={item.metaData.thumbnail}
                    key={item.id}
                    column={column}
                  />
                </LinkRef>
              );
            })}
      </ScrollableBanner>
    </BannerContainer>
  );
};

export default CoursesBanner;
