export const checkValues = (value, name) => {
  if (value < 0) {
    return `${name} should be positive.`;
  }
  if (!value) {
    return `${name} cannot be empty.`;
  }
  return null;
};

export const checkValuesSumsEqual100 = (values) => {
  const result = values.reduce((acc, val) => {
    acc += +val;
    return acc;
  }, 0);
  return result === 100 ? null : `Percentages must add up to 100, Total is currently at ${result}`;
};
