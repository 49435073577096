import React, { useState } from 'react';
import { TextInput, TermsAndConditionsCheckbox, Button } from '../../components/AuthPage';
import { Form } from './Form';
import { validateCredentialsForm } from './validators';

export function CredentialsForm({ form, onChange, onSubmit, intl }) {
  const [errors, setErrors] = useState({});
  const formInvalid = !!Object.keys(errors).length;

  return (
    <Form
      onChange={onChange}
      onSubmit={onSubmit}
      form={form}
      validate={validateCredentialsForm}
      errors={errors}
      setErrors={setErrors}
      name="credentials"
    >
      <TextInput
        type="email"
        value={form.email}
        error={errors.email && intl.formatMessage({ id: `login.errors.validation.${errors.email}` })}
        placeholder={intl.formatMessage({ id: 'login.registration.mainPage.emailPlaceholder' })}
        autoComplete="email"
        name="email"
        required
      />
      <TextInput
        type="password"
        value={form.password}
        error={errors.password && intl.formatMessage({ id: `login.errors.validation.${errors.password}` })}
        placeholder={intl.formatMessage({ id: 'login.registration.mainPage.passwordPlaceholder' })}
        autoComplete="new-password"
        name="password"
        required
      />
      <TextInput
        type="password"
        value={form.passwordConfirmed}
        error={
          errors.passwordConfirmed && intl.formatMessage({ id: `login.errors.validation.${errors.passwordConfirmed}` })
        }
        placeholder={intl.formatMessage({ id: 'login.registration.mainPage.confirmPasswordPlaceholder' })}
        autoComplete="new-password"
        name="passwordConfirmed"
        required
      />
      <TermsAndConditionsCheckbox
        terms="service"
        termsAgreement={form.agreedWithTermsOfService}
        required
        onChange={() => {
          const newForm = {
            ...form,
            agreedWithTermsOfService: !form.agreedWithTermsOfService
          };
          if (formInvalid) {
            setErrors(validateCredentialsForm(newForm));
          }
          onChange(newForm);
        }}
      />
      <TermsAndConditionsCheckbox
        terms="use"
        termsAgreement={form.agreedWithTermsOfUse}
        required
        onChange={() => {
          const newForm = {
            ...form,
            agreedWithTermsOfUse: !form.agreedWithTermsOfUse
          };
          if (formInvalid) {
            setErrors(validateCredentialsForm(newForm));
          }
          onChange(newForm);
        }}
      />
      <Button
        type="submit"
        disabled={!!Object.keys(errors).length || !form.agreedWithTermsOfUse || !form.agreedWithTermsOfService}
      >
        {intl.formatMessage({ id: 'login.registration.mainPage.nextButtonText' })}
      </Button>
    </Form>
  );
}
