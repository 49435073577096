import { useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { CommonFactorsDescription } from '../FactorsDescriptions';
import { mapFilterModel, mapSortModel } from '../../../../utils';
import { PaginatedFactorsGrid } from '../PaginatedFactorsGrid';
import { TabContent } from '../../../../components/TabContent';
import { TabContentContext } from '../LocationBasedFactors';
import { GET_HOTEL_STAYS_CONVERSION_FACTORS } from './index.queries';
import { useHotelStaysConversionFactorsGridConfig } from './useGridConfig';
import { useRoutes } from '~/hooks';

const pageSize = 25;

export function HotelStaysCarbonConversionFactorsGrid() {
  const { currentTab } = useContext(TabContentContext);
  const routes = useRoutes();
  const config = useHotelStaysConversionFactorsGridConfig();
  const [fetchRows, { error, refetch }] = useLazyQuery(GET_HOTEL_STAYS_CONVERSION_FACTORS);

  const getRows = useCallback(
    async (params: IServerSideGetRowsParams) => {
      try {
        const { startRow, sortModel, filterModel } = params.request;
        const {
          data: {
            getHotelStaysConversionFactors: { rows, totalRows },
          },
        } = await fetchRows({
          variables: {
            offset: startRow,
            limit: pageSize,
            sort: mapSortModel(sortModel),
            filters: mapFilterModel(filterModel),
          },
        });

        params.success({
          rowData: rows,
          rowCount: totalRows,
        });
      } catch (err) {
        params.fail();
        throw err;
      }
    },
    [fetchRows]
  );

  const serverSideDatasource = useMemo(() => ({
    getRows,
  }), [getRows]);

  return (
    <TabContent
      currentTab={currentTab}
      index={routes.configuration.factors.hotelStays}
      error={error}
      // ag-grid responsible for showing loader
      loading={false}
      retry={refetch}
    >
      <div className="ag-theme-alpine">
        <PaginatedFactorsGrid config={config} serverSideDatasource={serverSideDatasource} />
        <CommonFactorsDescription />
      </div>
    </TabContent>
  );
}
