import { Icon, Text } from 'rio-ui-components';
import styled from 'styled-components';

interface SurveysPlaceholderProps {
  title: string;
  description: string;
  icon: string | [string, string];
}
const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: auto;
`;
const IconStyled = styled(Icon)`
  color: ${(props) => props.theme.colors.neutral.light.text};
  height: 50px;
  width: 50px;
  margin-bottom: 40px;
`;
const TextStyled = styled(Text)`
  width: 500px;
  text-align: center;
  color: #4d505b;
`;
const Title = styled(TextStyled)`
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 23px;
`;
const Description = styled(TextStyled)`
  font-size: 16px;
  line-height: 19.49px;
  letter-spacing: -0.005em;
`;

export function SurveysPlaceholder({ title, description, icon }: SurveysPlaceholderProps) {
  return (
    <PlaceholderContainer>
      <IconStyled color="#83838e" icon={icon} />
      <Title>{title}</Title>
      <Description size="sm">{description}</Description>
    </PlaceholderContainer>
  );
}
