const checkRestrictions = (restrictedAreas, dataMap) => {
  const allowedAreas = [];
  restrictedAreas.forEach((area) => {
    if (dataMap[area.accessIndex].find((system) => system.id === area.restrictionId)) {
      allowedAreas.push(area);
    }
  });
  return allowedAreas;
};

export { checkRestrictions };
