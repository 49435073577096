import { Survey, SurveyQuestionType, SurveySubmission, SurveySubmissionStatus } from '@rio/rio-types';
import _ from 'lodash';
import { Dictionary } from '~/types';

export const calculateScores = (survey: Survey) => {
  const categoryNames: Set<string> = new Set();
  const totalCategoryScores: Dictionary<number> = {};
  const questionCategories: Dictionary<string> = {};
  const questionScores: Map<string, Dictionary<number> | number> = new Map();
  const categoryScores: Dictionary<number> = {};
  const submissionScores: Dictionary<Dictionary<number>> = {};

  // build category scores
  for (const question of survey?.template?.questions || []) {
    const categoryName = question.category?.name;

    if (categoryName !== undefined) {
      categoryNames.add(categoryName);
      questionCategories[question.name] = categoryName;

      // build question scores
      // for max category score and scoring submission answers

      let increment;
      if (question.type === SurveyQuestionType.Boolean) {
        questionScores.set(question.name, 1);
        increment = survey.contributors.length;
      } else {
        const options: Dictionary<number> = {};
        for (const option of question.options) {
          options[option.name] = option.score || 0;
        }
        questionScores.set(question.name, options);
        // max score for each category
        const maxScore = Math.max(...Object.values(options));
        increment = (maxScore > 0 ? maxScore : 0) * survey.contributors.length; // Replace -Infinity with 0
      }
      if (categoryName in totalCategoryScores) {
        totalCategoryScores[categoryName] = totalCategoryScores[categoryName] + increment;
      } else {
        totalCategoryScores[categoryName] = increment;
      }
    }
  }

  // build submission scores
  for (const submission of survey.submissions) {
    for (const submissionAnswer of submission.answers) {
      const { answer, question } = submissionAnswer;
      const answerClean = answer.replace(/"/g, ''); // Strip double quotes
      const questionName = question.name;
      const categoryName = questionCategories[questionName];
      const options = questionScores.get(questionName) || {};

      if (categoryName === undefined) {
        continue;
      }
      const increment =
        question.type === SurveyQuestionType.Boolean && answerClean === 'true'
          ? 1
          : (options as Dictionary<number>)[answerClean] || 0;

      if (categoryName in categoryScores) {
        categoryScores[categoryName] = categoryScores[categoryName] + increment;
      } else {
        categoryScores[categoryName] = increment;
      }
    }

    submissionScores[submission.id] = categoryScores;
  }

  return { categoryNames: Array.from(categoryNames), categoryScores, submissionScores, totalCategoryScores };
};

export const countSubmittedSubmissions = (submissions: SurveySubmission[]) =>
  submissions.filter(
    (s) => s.status === SurveySubmissionStatus.Approved || s.status === SurveySubmissionStatus.ReadyForReview
  ).length;

export const sumPerformanceScore = (survey: Survey) => {
  const { categoryScores, totalCategoryScores } = calculateScores(survey);
  const percentages: number[] = [];

  for (const category in categoryScores) {
    const score = categoryScores[category];
    const totalScore = totalCategoryScores[category];
    const percentage = (score / totalScore) * 100;

    if (Number.isNaN(percentage)) {
      continue;
    }
    percentages.push(percentage);
  }

  // Total submitted score / Maximum category score = Total score per contributor
  const percentagesAverage = _.round(_.mean(percentages));

  const positive = (percentagesAverage > 66.7 && percentagesAverage) || 0;
  const neutral = (percentagesAverage > 33.3 && percentagesAverage < 66.7 && percentagesAverage) || 0;
  const negative = (percentagesAverage < 33.3 && percentagesAverage) || 0;

  let text: string = '';
  if (positive > neutral && positive > negative) {
    text = 'Positive';
  } else if (neutral > positive && neutral > negative) {
    text = 'Neutral';
  } else if (negative > positive && negative > neutral) {
    text = 'Negative';
  }

  return {
    text,
    positive,
    neutral,
    negative,
  };
};

export const sumPerformancePercent = (
  scores: Dictionary<Dictionary<number>>,
  totalScores: Dictionary<number>,
  submissionId: string
): number | undefined => {
  const submission = scores[submissionId];
  const percentages: number[] = [];

  if (submission === undefined) {
    return;
  }

  for (const category in submission) {
    const score = submission[category];
    const totalScore = totalScores[category];
    const percentage = (score / totalScore) * 100;

    if (Number.isNaN(percentage)) {
      continue;
    }
    percentages.push(percentage);
  }

  // Total submitted score / Maximum category score = Total score per contributor
  return _.round(_.mean(percentages), 0);
};

export const getPerformanceStatus = (percent: number): string => {
  if (percent > 33.3 && percent < 66.7) {
    return 'Neutral';
  } else if (percent > 66.7) {
    return 'Positive';
  } else if (percent < 33.3) {
    return 'Negative';
  }
  return 'Neutral';
};
