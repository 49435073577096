import moment from 'moment';

export const compareDate = (left, right) => {
  const leftDate = moment(moment(left).format('YYYY-MM-DD'));
  const rightDate = moment(moment(right).format('YYYY-MM-DD'));

  if (!leftDate.isValid() || !rightDate.isValid()) {
    throw new Error('Date is invalid');
  }

  if (leftDate.isBefore(rightDate)) {
    return 1;
  } else if (leftDate.isAfter(rightDate)) {
    return -1;
  }

  return 0;
};
