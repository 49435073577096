import { useSaveEvidence } from '../../components/EvidenceNotes';

export function useSaveEvidenceNotes() {
  const saveEvidence = useSaveEvidence();
  let newDocumentIds: any[];

  return async (evidenceDocuments: any[], notes: string) => {
    if (!evidenceDocuments.length && !notes) return [];
    if (!!evidenceDocuments.length) {
      newDocumentIds = await saveEvidence(evidenceDocuments, [], 'Other Reference');
    }
    return [
      {
        notes,
        documents: newDocumentIds || []
      }
    ];
  };
}
