import { useQuery } from '@apollo/client';
import { Query, AliasType, TransactionType, DataImportError, DataImportBatch } from '@rio/rio-types';
import _ from 'lodash';
import { GET_ALIAS_BY_VALUE, GET_DATA_IMPORT_BATCH_BY_ID } from './index.queries';
import { getAliasTypeByFieldName } from './config';
import { Nullable } from '../../../types';

export interface ValueToAlias {
  aliasType: AliasType;
  error: DataImportError;
}

type UseValuesToAliasTuple = [
  {
    batch: Nullable<DataImportBatch>;
    valuesToAlias: ValueToAlias[];
  },
  {
    loading: boolean;
    error: Nullable<Error>;
    refetch: () => void;
  }
];

export function useValuesToAlias(accountId: string, batchId: string, subject: TransactionType): UseValuesToAliasTuple {
  const getBatchVariables = {
    id: batchId,
  };
  const {
    data: batchData,
    error: batchError,
    loading: batchLoading,
    refetch: refetchBatch,
  } = useQuery<Query>(GET_DATA_IMPORT_BATCH_BY_ID, {
    variables: getBatchVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const batch = batchData?.getDataImportBatchById || null;
  const uniqErrors = _(batch?.errors)
    .filter((e) => !!e.actionRequired)
    .uniqBy((e) => [e.fieldName, e.value].join())
    .value();

  const getAliasesVariables = {
    GetAliasBatchInput: {
      accountId,
      aliasValues: uniqErrors.map((e) => ({
        name: e.value,
        aliasType: getAliasTypeByFieldName(e.fieldName, subject),
        energyType: subject,
      })),
    },
  };

  const {
    data: aliasesData,
    error: aliasesError,
    loading: aliasesLoading,
    refetch: refetchAliases,
  } = useQuery<Query>(GET_ALIAS_BY_VALUE, {
    variables: getAliasesVariables,
    skip: batchLoading || !!batchError,
    notifyOnNetworkStatusChange: true,
  });
  const existingAliases = aliasesData?.getAliasByValueBatch?.getParentResponses || [];
  const valuesToAlias: ValueToAlias[] =
    _(uniqErrors)
      .map((error) => {
        const aliasType = getAliasTypeByFieldName(error.fieldName, subject);
        return {
          error,
          aliasType: aliasType!,
        };
      })
      .filter(
        ({ error, aliasType }) => !existingAliases?.find((a) => a.aliasType === aliasType && error.value === a.name)
      )
      .value() || [];

  return [
    {
      batch,
      valuesToAlias,
    },
    {
      loading: batchLoading || aliasesLoading,
      error: batchError || aliasesError,
      refetch: async () => {
        await refetchBatch(getBatchVariables);
        await refetchAliases(getAliasesVariables);
      },
    },
  ];
}
