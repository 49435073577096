/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverflowMenu } from 'rio-ui-components';
import { ReceivedSurvey, Survey } from '@rio/rio-types';
import { generateSpreadsheet } from '../../utils';
import { useLazyGetMyContributors } from '../../containers/SurveysContainer/MyContributorsContainer/queries';
import { useCurrentAccountId, useRoutes } from '../../hooks';
import { isSurvey, parseSurveyAnswer } from './utils';
import styled from 'styled-components';

const StyledOverflowMenu = styled(OverflowMenu)`
  background-color: red;
  div ul {
    z-index: 100;
  }
`;

export const isOverflowMenu = (e: React.MouseEvent) => {
  const node = (e.target as HTMLElement).firstChild as HTMLSpanElement;
  const isSpan = node?.tagName?.toLowerCase() === 'span';
  const isEmpty = !node?.textContent;
  return isSpan && isEmpty;
};

export interface SurveyOverflowMenuProps {
  survey: ReceivedSurvey | Survey;
}

export const SurveyOverflowMenu = ({ survey }: SurveyOverflowMenuProps) => {
  const accountId = useCurrentAccountId();
  const [getContributors] = useLazyGetMyContributors(accountId);
  const navigate = useNavigate();
  const routes = useRoutes();
  const type = useMemo(() => (survey.__typename === 'Survey' ? 'survey' : 'response'), [survey]);

  const auditLogLink = useMemo(() => {
    const { pathname } = window.location;

    const contributorPage = pathname.includes(routes.surveys.sent.contributor.replace('/:contributorId', ''));

    const sentSurveysPage = pathname.includes(routes.surveys.sent.surveys);

    const overviewSurveyPage = pathname.includes(routes.surveys.sent.overview);

    const surveyPage = pathname.includes(routes.surveys.sent.survey.replace(':surveyId', survey.id));

    const receivedSurveysPage = pathname.includes(routes.surveys.received.surveys);

    const receivedSurveyPage = pathname.includes(routes.surveys.received.survey.replace(':surveyId', survey.id));

    const reviewSubmissionPage = pathname.includes('submission');

    if (overviewSurveyPage || surveyPage) {
      return `../sent/surveys/${survey.id}/survey-log`;
    } else if ((contributorPage || sentSurveysPage) && type === 'survey') {
      return `./${survey.id}/survey-log`;
    } else if (type === 'survey') {
      return `./survey-log`;
    } else if (reviewSubmissionPage) {
      const receivedSurvey = survey as ReceivedSurvey;
      return `../sent/contributors/${receivedSurvey?.submission?.owner?.id}/response-log/${survey.id}`;
    } else if (receivedSurveyPage) {
      return `./response-log`;
    } else if (receivedSurveysPage) {
      return `./${survey.id}/response-log`;
    } else {
      return `./response-log/${survey.id}`;
    }
  }, [type, survey]);

  const overflowMenuLinks = useMemo(() => {
    const menuItems = [
      {
        text: `View ${type} log`,
        onClick: (e: React.MouseEvent) => {
          e.stopPropagation();
          navigate(auditLogLink);
        },
      },
    ];

    if (isSurvey(survey)) {
      menuItems.push({
        text: `Export to XLSX`,
        onClick: async (e: React.MouseEvent) => {
          e.stopPropagation();
          const { data } = await getContributors();
          const contributors = data?.getMySurveyContributors || [];
          const headers = [
            'contributor',
            ...(survey?.template?.questions?.map((q) => `${q.title} - (${q.name})`) || []),
          ];

          const results = survey.submissions.map((s) => {
            return s.answers.reduce(
              (acc, cur) => {
                return { ...acc, [`${cur.question.title} - (${cur.question.name})`]: JSON.parse(cur.answer) };
              },
              { contributor: contributors.find((c) => c.account.id === s.owner.id)?.account.name }
            );
          });

          generateSpreadsheet(headers, `${survey.name} Results`, results, parseSurveyAnswer);
        },
      });
    }

    return menuItems;
  }, [type, navigate, auditLogLink, survey, getContributors]);

  return <StyledOverflowMenu items={overflowMenuLinks} />;
};
