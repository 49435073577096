import React from 'react';
import styled from 'styled-components';
import { Col, CircleIcon, Heading } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.geometry.xs.spacing} 0;
`;

const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.basic.white};
`;

const ManagementSystemTile = ({ title }) => {
  return (
    <ContainerStyled name={`SubjectTile SubjectTile--${title}`} container item>
      <CenteredContainer>
        <CircleIconStyled name="SubjectTile__Icon" height="70px" size="lg" icon={'pencil-alt'} iconColor={'tertiary'} circleColor={'tertiary'} />
      </CenteredContainer>
      <CenteredContainer>
        <Heading align="center" size="lg">
          {title}
        </Heading>
      </CenteredContainer>
    </ContainerStyled>
  );
};

export default ManagementSystemTile;
