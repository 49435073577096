import { useCallback } from 'react';
import styled from 'styled-components';
import { SurveyTemplateInput, User } from '@rio/rio-types';
import { PropertySheet, DatePicker, TextArea } from 'rio-ui-components';
import { Errors, InputEvent, SelectEvent } from '../../../types';
import { getFullName, today } from '../../../utils';
import UserSelect from '../../../components/UserSelect';
import { useCurrentAccountId } from '../../../hooks';

interface FormTemplateDetailsProps {
  onChange: (payload: Partial<SurveyTemplateInput>) => void;
  template: Partial<SurveyTemplateInput>;
  owner?: User;
  errors: Errors<SurveyTemplateInput>;
  disabled?: boolean;
}
const TextAreaStyled = styled(TextArea)`
  height: 150px;
`;

export function TemplateDetails({ template, errors, onChange, owner, disabled }: FormTemplateDetailsProps) {
  const accountId = useCurrentAccountId();
  const handleInputChange = useCallback((e: InputEvent) => onChange({ [e.target.name]: e.target.value }), [onChange]);
  return (
    <>
      <PropertySheet.Row container>
        <PropertySheet.TextInputColumn
          label="Title"
          name="name"
          onChange={handleInputChange}
          value={template.name}
          error={errors.name}
          disabled={disabled}
        />
      </PropertySheet.Row>
      <PropertySheet.Row container>
        <PropertySheet.TextInputColumn
          label="Description"
          name="description"
          onChange={handleInputChange}
          value={template.description}
          error={errors.description}
          disabled={disabled}
        />
      </PropertySheet.Row>
      <PropertySheet.Row container>
        <PropertySheet.Column label="Form content" name="formContent">
          <TextAreaStyled
            name="formContent"
            onChange={handleInputChange}
            value={template.formContent}
            error={errors.formContent}
            disabled={disabled}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>
      <PropertySheet.Row>
        <PropertySheet.Column label="Owner" span={12}>
          <UserSelect
            isMulti={false}
            accountId={accountId}
            value={owner ? { value: owner.id, label: getFullName(owner) } : template.ownerId}
            error={errors.ownerId || undefined}
            onChange={useCallback((e: SelectEvent) => onChange({ ownerId: e.target.select.value }), [onChange])}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>
      <PropertySheet.Row>
        <PropertySheet.Column label="Expiry date" span={12}>
          <DatePicker
            onDayClick={useCallback((date: Date) => onChange({ expiryDate: date }), [onChange])}
            name="expiryDate"
            selectedDate={template.expiryDate}
            disabledDatesBefore={today().toDate()}
            box
          />
        </PropertySheet.Column>
      </PropertySheet.Row>
      <PropertySheet.Row container>
        <PropertySheet.TextInputColumn
          label="Version"
          name="version"
          onChange={handleInputChange}
          value={template.version}
          error={errors.version}
          disabled={disabled}
        />
      </PropertySheet.Row>
    </>
  );
}
