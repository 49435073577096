import styled from 'styled-components';

export const QuantityInput = styled.div`
  width: 90px;
  height: 40px;
  border: 1px solid #43425d;
  border-radius: 5px;
  background-color: #fff;
  font-size: 22px;
  display: flex;
  float: right;
  
  .cart-line-quantity {
    flex-grow: 1;
    text-align: center;
    align-self: center;
    
    div {
      margin: 0 auto;
      padding: 0;
      font-size: 22px;
      width: 50px;
      height: 30px;
      border: none;
      border-radius: 0;
      background-color: transparent;
      outline-color: transparent;
      outline-offset: 0;
      color: #000;
    }
    
    input {
      padding: 0;
      font-size: 22px;
      width: 50px;
      height: 30px;
      text-align: center;
      border: none;
      border-radius: 0;
      background-color: #fff;
      outline-color: #fff;
      outline-offset: 0;
      color: #000;
    }
  }
  
  .cart-line-arrow-holder {
    display: flex;
    flex-direction: column;
    width: 30px;
    color: #fff;
    text-align: center;
    user-select: none;
    cursor: pointer;
    
    span {
      background-color: #43425d;
      height: 19px;
      font-size: 16px;
    }
  }
`
