import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Waste', to: '/data/waste/view' }, { label: 'Uploads' }];

export const WasteUploadsGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <>
      <UploadGridV2
        gridKey="wasteUploadsGridV2"
        title="Here's your upload history"
        breadcrumbs={breadcrumbs}
        uploadLink="../electricity/uploads"
        config={config}
        dataType={TransactionType.Waste}
      />
    </>
  );
};
