import React from 'react';
import { Form as FormElement } from '../../components/AuthPage';

export function Form({ form, validate, onChange, onSubmit, children, errors, setErrors, name }) {
  const formInvalid = !!Object.keys(errors).length;
  return (
    <FormElement
      name={name}
      onChange={(e) => {
        const newForm = { ...form, [e.target.name]: e.target.value };
        if (formInvalid) {
          setErrors(validate(newForm));
        }
        onChange(newForm);
      }}
      onSubmit={(e) => {
        e.preventDefault();
        const validationErrors = validate(form);
        if (!!Object.keys(validationErrors).length) {
          return setErrors(validationErrors);
        }
        onSubmit(form);
      }}>
      {children}
    </FormElement>
  );
}
