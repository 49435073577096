import { Text, styled, DrawerLink } from '@rio/ui-components';
import { DrawerText } from '~/containers/DataContainer/DataOverview/v2/AnomaliesDrawer';
import { ContainerStyled } from './style';

const YourDataContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextStyled = styled(Text)`
  color: ${(p) => p.theme.sys.color.onSurface};
`;

const Container = styled(ContainerStyled)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px;
`;

const DrawerTextStyled = styled(DrawerText)`
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export function YourData() {
  return (
    <YourDataContainer>
      <TextStyled typescale="title" size="medium">
        Your data
      </TextStyled>
      <Container>
        <DrawerLink to="/v2/data">
          <DrawerTextStyled color="sys.color.error">Missing data and empty transactions</DrawerTextStyled>
        </DrawerLink>
        <DrawerLink to="/v2/data">
          <DrawerTextStyled color="customColors.warning.color">Anomalies found in your data</DrawerTextStyled>
        </DrawerLink>
      </Container>
    </YourDataContainer>
  );
}
