import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TransactionType } from '@rio/rio-types';
import { Modal, Row, TextInput } from 'rio-ui-components';
import { DataFormPreview } from '../DataFormPreview';
import { DataFormXlsUploader } from '../DataFormXlsUploader';
import { WasteDataForm } from '../WasteDataForm';
import { EnergyDataForm } from '../EnergyDataForm';
import { TransportDataForm } from '../TransportDataForm';
import { WaterDataForm } from '../WaterDataForm';
import { HotelStaysDataForm } from '../HotelStaysDataForm';
import { ColStyled, LabelContainerStyled, LabelStyled, SubHeader } from '../DataForm/DataFormStyles';
import { Container } from './DataFormModalStyles';

const getFormBySection = (section) => {
  switch (section) {
    case TransactionType.Waste:
      return WasteDataForm;
    case TransactionType.Electricity:
    case TransactionType.Gas:
    case TransactionType.Heatsteam:
      return EnergyDataForm;
    case TransactionType.Water:
      return WaterDataForm;
    case TransactionType.Transport:
      return TransportDataForm;
    case TransactionType.Hotelstays:
      return HotelStaysDataForm;
    default:
      throw new Error(`Undefined section: ${section}`);
  }
};

export function DataFormModal(props) {
  const { onDismiss = () => {}, onComplete = () => {}, onError = () => {}, dataSection } = props;

  const [isFormVisible, setFormVisible] = useState(true);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isUploaderVisible, setUploaderVisible] = useState(false);
  const [formData, setFormData] = useState();

  // TODO: need to move into forData, for now it need for a reset
  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',

    defaultValues: {
      data: {
        currency: 'GBP',
      },
    },
  });

  const { reset, control } = form;

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  const showForm = () => {
    setFormVisible(true);
    setPreviewVisible(false);
    setUploaderVisible(false);
  };

  const closeForm = () => onDismiss();

  const showPreview = () => {
    setFormVisible(false);
    setPreviewVisible(true);
    setUploaderVisible(false);
  };

  const showUploader = () => {
    setFormVisible(false);
    setPreviewVisible(false);
    setUploaderVisible(true);
  };

  const submitForm = (data) => {
    setFormData(data);
    showPreview();
  };

  const completeUpload = () => {
    setFormVisible(false);
    setPreviewVisible(false);
    setUploaderVisible(false);
    onComplete();
    onDismiss();
  };

  const handleOnError = (e) => {
    showForm();
    onError(e);
  };

  const DataForm = getFormBySection(dataSection);

  return (
    <>
      <Modal show={isFormVisible} size="md" onDismiss={closeForm}>
        <Container>
          <DataForm form={form} onSubmit={submitForm} dataSection={dataSection}>
            <Row>
              <SubHeader>Upload Reference</SubHeader>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled labelFor="uploadReference">
                    Please input a description to this upload which will be used to identify it in the Upload log. This
                    could be your organisation name, relevant tag, department, region, or location.
                  </LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="uploadReference"
                  render={({ field, fieldState }) => (
                    <TextInput id="uploadReference" {...field} error={fieldState.error?.message} />
                  )}
                  defaultValue=""
                  control={control}
                  rules={{ required: 'Upload Reference is required', maxLength: 80 }}
                />
              </ColStyled>
            </Row>
          </DataForm>
        </Container>
      </Modal>

      <Modal show={isPreviewVisible} onDismiss={showForm} size="xl">
        <Container>
          <DataFormPreview formData={formData} onBack={showForm} onConfirm={showUploader} dataSection={dataSection} />
        </Container>
      </Modal>

      <Modal show={isUploaderVisible} onDismiss={closeForm} size="lg">
        <Container>
          <DataFormXlsUploader
            formData={formData}
            onUploadCompleted={completeUpload}
            onError={handleOnError}
            dataSection={dataSection}
          />
        </Container>
      </Modal>
    </>
  );
}
