import { ReceivedSurvey } from '@rio/rio-types';
import { styled } from '@rio/ui-components';
import { useSurveyReceived } from '~/containers/SurveysContainer/ReceivedSurveyContainer/hooks/useSurveyReceived';
import { SurveyInfoStatus } from '../../Surveys/v2';
import { AutoSave } from './AutoSave';
import { ExpandButton } from './ExpandButton';

export interface SurveyWorkSheetHeaderProps {
  survey: ReceivedSurvey;
}

const WrapperStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StatusWrapper = styled('div')`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background: ${({ theme }) => theme.sys.color.onSecondary};
  box-shadow: ${({ theme }) => theme.sys.elevation[0]};

  &:hover {
    box-shadow: ${(p) => p.theme.sys.elevation[1]};
  }
`;

export const SurveyWorkSheetHeader = ({ survey }: SurveyWorkSheetHeaderProps) => {
  const { expand } = useSurveyReceived(survey);

  return (
    <WrapperStyled>
      <AutoSave />
      <ExpandButton expand={expand} />
      <StatusWrapper>
        <SurveyInfoStatus survey={survey} />
      </StatusWrapper>
    </WrapperStyled>
  );
};
