import { useQuery } from '@apollo/client';
import GET_TARGETS_BY_ACCOUNT_ID from '../../graphql/queries/targets/GetTargetsByAccountId.query.graphql';
import { useCurrentAccountId } from '../useCurrentAccountId';
import { Query } from '@rio/rio-types';

export function useGetTargetsByAccountId() {
  const accountId = useCurrentAccountId();
  const {
    data: { getTargetsByAccountId = [] } = {},
    loading,
    error,
  } = useQuery<Pick<Query, 'getTargetsByAccountId'>>(GET_TARGETS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
    skip: !accountId,
  });

  return { targets: getTargetsByAccountId, loading, error };
}
