import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SurveyQuestion, SurveyQuestionInput, Query } from '@rio/rio-types';
import { PropertySheet } from 'rio-ui-components';
import { keyBy } from 'lodash';
import { InputEvent, SelectEvent, Errors } from '../../../types';
import { GET_QUESTION_CATEGORIES } from './queries/queries';

interface QuestionDetailsProps {
  onChange: (payload: Partial<SurveyQuestionInput>) => void;
  questionInput: Partial<SurveyQuestionInput>;
  errors: Errors<SurveyQuestionInput>;
  question: SurveyQuestion;
  disabled?: boolean;
}

export function QuestionDetails({ questionInput, question, onChange, disabled, errors }: QuestionDetailsProps) {
  const handleInputChange = (optionId: string) => (e: InputEvent) => {
    const scores = questionInput.scores?.map((s) => {
      if (s.id === optionId) {
        return { ...s, score: parseInt(e.target.value) };
      }
      return s;
    });
    onChange({ scores });
  };
  const { data } = useQuery<Pick<Query, 'getSurveyQuestionCategories'>>(GET_QUESTION_CATEGORIES);
  const keyedOptions = useMemo(() => keyBy(question.options, 'id'), [question.options]);

  return (
    <>
      <PropertySheet.Column container>
        <PropertySheet.SelectColumn
          name="category"
          label="Category"
          disabled={disabled}
          value={questionInput.category}
          options={data?.getSurveyQuestionCategories?.map((c) => ({ value: c.id, label: c.name }))}
          error={errors.category}
          onChange={useCallback((e: SelectEvent) => onChange({ category: e.target.value }), [onChange])}
        />
        {questionInput.scores && questionInput.scores.length > 0 && (
          <PropertySheet.Row>
            <PropertySheet.Column label="Question options scores">
              <PropertySheet.Row>
                <PropertySheet.Column label="Option" />
                <PropertySheet.Column label="Score" />
              </PropertySheet.Row>
              {questionInput.scores.map((score, idx) => {
                const value = questionInput.scores![idx].score ?? '';
                return (
                  <PropertySheet.Row key={score.id} className="score-row">
                    <PropertySheet.TextColumn>{keyedOptions[score.id]?.name}</PropertySheet.TextColumn>
                    <PropertySheet.TextInputColumn
                      type="number"
                      min="0"
                      name={`score-${score.id}`}
                      onChange={handleInputChange(score.id)}
                      value={value}
                      disabled={disabled}
                    />
                  </PropertySheet.Row>
                );
              })}
            </PropertySheet.Column>
          </PropertySheet.Row>
        )}
      </PropertySheet.Column>
    </>
  );
}
