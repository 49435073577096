import React from 'react';
import styled from 'styled-components';
import { CircleIcon, Col, Row } from 'rio-ui-components';

const iconStyles = {
  circleIcon: {
    height: '32px',
    size: 'md'
  },
  Icon: {
    size: '24px'
  }
};

const StyledCircleIconCol = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  cursor: pointer;
`;

const StyledContainer = styled(Row)``;

const ReportSubjectFilter = ({ showComply, showData, showLearn, onClick }) => {
  return (
    <StyledContainer container>
      <StyledCircleIconCol onClick={() => onClick('Learn')}>
        <CircleIcon icon="graduation-cap" height={iconStyles.circleIcon.height} iconColor={showLearn ? 'secondary' : 'normal'} size={iconStyles.circleIcon.size} circleColor={showLearn ? 'secondary' : 'normal'} name={`Reports-ShowLearn-${showLearn}`} />
      </StyledCircleIconCol>

      <StyledCircleIconCol onClick={() => onClick('Comply')}>
        <CircleIcon icon="pencil-alt" height={iconStyles.circleIcon.height} iconColor={showComply ? 'tertiary' : 'normal'} size={iconStyles.circleIcon.size} circleColor={showComply ? 'tertiary' : 'normal'} name={`Reports-ShowComply-${showComply}`} />
      </StyledCircleIconCol>

      <StyledCircleIconCol onClick={() => onClick('Data')}>
        <CircleIcon icon="chart-line" height={iconStyles.circleIcon.height} iconColor={showData ? 'quaternary' : 'normal'} size={iconStyles.circleIcon.size} circleColor={showData ? 'quaternary' : 'normal'} name={`Reports-ShowData-${showData}`} />
      </StyledCircleIconCol>
    </StyledContainer>
  );
};

export default ReportSubjectFilter;
