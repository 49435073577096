import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Legislation } from '@rio/rio-types';
import { ICellRendererParams } from 'ag-grid-community';
import { MatchMark } from '../../../../components/MatchMark';
import { Nullable } from '../../../../types';

const LegislationLink = styled(Link)`
  text-decoration: underline;
  margin-right: 10px;
  &:hover {
    text-decoration: none;
  }
`;

interface LegislationReferenceCellProps extends ICellRendererParams {
  searchValue: Nullable<string>;
}

export const LegislationReferenceCell = (props: LegislationReferenceCellProps) => {
  return props.data.legislations.length
    ? props.data.legislations.map((legislation: Legislation) => (
        <LegislationLink key={legislation.id} to={`/governance/legislation/${legislation.id}`}>
          <MatchMark text={legislation.title} searchValue={props.searchValue} />
        </LegislationLink>
      ))
    : 'Not exist';
};
