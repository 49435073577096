import { gql } from '@apollo/client';

export const EVALUATION_FRAGMENT = gql`
  fragment EvaluationFields on Evaluation {
    id
    name
    description
    type
    accountId
    scale {
      min
      max
    }
    questions {
      id
      text
    }
  }
`;
export const GET_ALL_EVALUATIONS = gql`
  query getAllEvaluations($accountId: ID) {
    getAllEvaluations(accountId: $accountId) {
      ...EvaluationFields
    }
  }
  ${EVALUATION_FRAGMENT}
`;

export const CREATE_EVALUATION = gql`
  mutation createEvaluation($input: CreateEvaluationInput!) {
    createEvaluation(input: $input) {
      ...EvaluationFields
    }
  }
  ${EVALUATION_FRAGMENT}
`;
