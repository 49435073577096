import styled from 'styled-components';
import { CircleFragment } from './Fragments';
import { SvgIconProps } from './types';

export const SvgStyled = styled.svg`
  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const Svg = ({ circle, children, ...rest }: SvgIconProps) => (
  <svg {...rest}>
    {children}
    {circle && <CircleFragment fill={circle} />}
  </svg>
);
