import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Col, Search, Button, Modal, Notification } from 'rio-ui-components';
import styled from 'styled-components';

import { GET_NORMALISERS_PAGE } from './index.queries';

import ContainerHeader from '../../../components/ContainerHeader';
import { NormalisersGrid } from './NormalisersGrid';
import CreateNormaliser from './CreateNormaliser';
import UpdateNormaliser from './UpdateNormaliser';
import { UploadNormalisersModal } from './UploadNormalisersModal';
import AccountSelector from '../../../components/AccountSelector';
import { usePermissions } from '../../../hooks/usePermissions';
import { FetchRowsParameters } from './types';
import { Nullable } from '~/types';
import { SortCommand } from '@rio/rio-types';

const ContainerHeaderStyled = styled(ContainerHeader)`
  & > div {
    width: auto;
`;
const ButtonContainerStyled = styled(Col)`
  padding-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ButtonStyled = styled(Button)`
  width: 100%;
`;
const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

type ComponentState = {
  accountId: string;
  search: { value: string };
  createModal: { show: boolean };
  updateModal: { id?: string; show: boolean };
  uploadModal: { id?: string; show: boolean };
  notification: { message?: Nullable<string>; color?: Nullable<string> };
};
enum ModalTypes {
  'create' = 'create',
  'update' = 'update',
  'upload' = 'upload',
}

function addDefaultSort(params: { sort?: SortCommand[] }) {
  const sortByType = { field: 'type', order: 'asc' };
  const sortByName = { field: 'name', order: 'asc' };
  if (Array.isArray(params.sort)) {
    if (!params.sort.find((s) => s.field === 'name')) {
      params.sort = [sortByName, ...params.sort];
    }
    if (!params.sort.find((s) => s.field === 'type')) {
      params.sort = [sortByType, ...params.sort];
    }
  } else {
    params.sort = [sortByType, sortByName];
  }
}

function ConfigurationNormalizersContainer({ accountId }) {
  const [state, setState] = useState<ComponentState>({
    accountId,
    search: { value: '' },
    createModal: { show: false },
    updateModal: { id: '', show: false },
    uploadModal: { id: '', show: false },
    notification: { message: null, color: null },
  });
  const [gridApi, setGridApi] = useState<{ api?: any; datasource?: any }>({});

  const permissions = usePermissions();
  const client = useApolloClient();

  const refreshGrid = useCallback(() => gridApi?.api?.refreshServerSide({ purge: true }), [gridApi]);

  useEffect(() => {
    if (gridApi?.api?.getFilterInstance) {
      const instance = gridApi.api.getFilterInstance('name');
      instance.setModel({
        filterType: 'text',
        type: 'contains',
        filter: state.search.value,
      });
      gridApi.api.onFilterChanged();
    }
  }, [gridApi?.api, state.search.value]);

  const fetchRows = async (params: FetchRowsParameters<{ accountId: string }>) => {
    addDefaultSort(params);

    const {
      data: {
        getNormalisersPage: { rows, totalRows },
      },
    } = await client.query({
      query: GET_NORMALISERS_PAGE,
      variables: {
        ...params,
        offset: params.offset || 0,
        limit: params.limit || 25,
      },
      fetchPolicy: 'network-only',
    });

    return { rows, totalRows };
  };

  const handleSearchChange = ({ target: { value } }) => {
    setState((prevState) => ({ ...prevState, search: { ...prevState.search, value } }));
  };

  const showModal = (type: ModalTypes, id?: string) => {
    if (type === ModalTypes.create) {
      setState((prevState) => ({ ...prevState, createModal: { show: true } }));
    }
    if (type === ModalTypes.update) {
      setState((prevState) => ({ ...prevState, updateModal: { id, show: true } }));
    }
    if (type === ModalTypes.upload) {
      setState((prevState) => ({ ...prevState, uploadModal: { id: '', show: true } }));
    }
  };
  const dismissModals = (notification: { message?: string; color?: string } = {}, refetch?: () => void, show = '') => {
    setState((prevState) => {
      if (typeof refetch === 'function') {
        refetch();
      }
      return {
        ...prevState,
        createModal: { show: show === 'create' },
        updateModal: { id: '', show: show === 'update' },
        uploadModal: { id: '', show: false },
        notification: { message: notification.message || null, color: notification.color || null },
      };
    });
  };
  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: '', color: null },
    }));
  };

  return (
    <Col name="ConfigurationNormaliserContainer" container fullHeight>
      <ContainerHeaderStyled
        name="ConfigurationNormaliserContainer__Controls"
        icon="subscript"
        iconColor="primary"
        title="Normalisers"
      >
        <Col container item>
          <Search
            name="ConfigurationNormaliserContainer__Controls__Search"
            value={state.search.value}
            onChange={handleSearchChange}
            onSearch={handleSearchChange}
            hideButton
          />
        </Col>
        <ButtonContainerStyled item={1} container>
          <ButtonStyled
            name="ConfigurationNormaliserContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => showModal(ModalTypes.create)}
          >
            + Add Normaliser
          </ButtonStyled>
        </ButtonContainerStyled>
        <ButtonContainerStyled item={2} container>
          <ButtonStyled
            name="ConfigurationNormaliserContainer__Controls__Button--upload"
            size="md"
            color="secondary"
            inline
            onClick={() => showModal(ModalTypes.upload)}
          >
            Upload Normalisers
          </ButtonStyled>
        </ButtonContainerStyled>
      </ContainerHeaderStyled>
      {permissions.account.includes('getAll') && (
        <AccountSelectorContainer>
          <AccountSelector
            value={state.accountId}
            onChange={({ id }) =>
              setState((prevState) => ({
                ...prevState,
                accountId: id,
              }))
            }
          />
        </AccountSelectorContainer>
      )}
      {state.createModal.show && (
        <Modal
          size="md"
          show
          name="ConfigurationNormaliserContainer__Modal--create"
          dismissable
          onDismiss={dismissModals}
        >
          <CreateNormaliser
            showNotification={(message, color, show) => dismissModals({ message, color }, refreshGrid, show)}
            accountId={state.accountId}
          />
        </Modal>
      )}
      {state.updateModal.show && (
        <Modal
          size="md"
          show
          name="ConfigurationNormaliserContainer__Modal--create"
          dismissable
          onDismiss={dismissModals}
        >
          <UpdateNormaliser
            id={state.updateModal.id}
            showNotification={(message, color, show) => dismissModals({ message, color }, refreshGrid, show)}
            accountId={state.accountId}
          />
        </Modal>
      )}
      {state.uploadModal.show && (
        <UploadNormalisersModal
          showNotification={(message, color) => dismissModals({ message, color }, refreshGrid)}
          accountId={state.accountId}
          onDismiss={dismissModals}
        />
      )}
      {state.notification.message && (
        <Notification
          name="ConfigurationNormaliserContainer__Notification"
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}
      <NormalisersGrid
        key={state.accountId}
        accountId={state.accountId}
        fetchRows={fetchRows}
        showModal={showModal}
        setGridApi={setGridApi}
      />
    </Col>
  );
}

export default ConfigurationNormalizersContainer;
