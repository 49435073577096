import { useApolloClient } from '@apollo/client';
import { TransactionType } from '@rio/rio-types';
import { ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import GET_GAS_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/gas/GetGasTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useGasGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getEnergyTransactionsPageFilter: values },
      } = await client.query({
        query: GET_GAS_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
          energyType: TransactionType.Gas,
        },
      });
      params.success(values.map((item: any) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Meter Name',
        field: 'meterName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location',
        field: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        field: 'energySource.locationPoint.location.code',
        headerName: 'Location Code',
        colId: 'locationCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Supplier',
        field: 'supplier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Quantity (kWh)',
        field: 'quantity',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Act/Est',
        field: 'accuracy',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Cost (VAT)',
        field: 'totalCostVat',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Carbon Emission Scope 1 (kgCO2e)',
        field: 'carbonScope1',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Carbon Emission Scope 3 (kgCO2e)',
        field: 'carbonScope3',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'kg CO2e of CO2',
        headerTooltip:
          'This shows how much CO2 was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionCO2',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'kg CO2e of N2O',
        headerTooltip:
          'This shows how much N2O was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionN2O',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'kg CO2e of CH4',
        headerTooltip:
          'This shows how much CH4 was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionCH4',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Activity',
        headerTooltip:
          'This shows if the activity falls under your organisation’s direct (company owned emission sources) or indirect (non company owned, employee, contractor or third party emission sources) activities',
        field: 'activity',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Comment',
        field: 'comment',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Invoice Number',
        field: 'invoiceNumber',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Country',
        field: 'energySource.locationPoint.location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'energySource.locationPoint.location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Factors used',
        headerTooltip:
          'The factor set applied to this transaction, click the link to see the factors for this year in more detail',
        field: 'reportingYearOfFactors',
        cellRenderer: 'link',
        cellRendererParams: {
          to: '/configuration/carbonconversionfactors/location/gas',
        },
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
