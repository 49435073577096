import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserInfoById(id: $id) {
      id
      email
      username
      roles
      first_name
      last_name
      jobTitle
      department
      mobile
      phone
      profilePicture
      disabled
      last_active_at
      total_time_spent_ms
      account {
        id
        name
      }
      role {
        role
        name
        restrictedAccess
      }
      accessLevel
      allowedLocations {
        id
        name
        tags {
          id
          tagName
        }
      }
      allowedTags {
        id
        tagName
        locations {
          id
        }
      }
    }
  }
`;
