import React, { Component } from 'react';
import { Col, Search, Button, Modal, Notification, ErrorMessage, ContainerError } from 'rio-ui-components';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import * as JsSearch from 'js-search';

import { GET_ALL_DASHBOARDS } from './index.queries';

import ContainerHeader from '../../../components/ContainerHeader';
import NoResults from '../../../components/NoResults';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import DashboardRow from './DashboardRow';
import CreateDashboard from './CreateDashboard';
import UpdateDashboard from './UpdateDashboard';
import alphabetise from '../../../utils/alphabetise';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

class ConfigurationDashboardsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: { value: '', error: '' },
      createModal: { show: false },
      updateModal: { id: '' },
      notification: { message: null, color: null }
    };
  }

  handleSearchChange = (event) => this.setState({ search: { ...this.state.search, value: event.target.value } });

  search = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('id');
    search.addIndex('name');
    search.addIndex('provider');
    search.addIndex('area');
    search.addDocuments(data);
    return search.search(term);
  };

  showModal = (type, id) => {
    if (type === 'create') return this.setState({ createModal: { show: true } });
    if (type === 'update') return this.setState({ updateModal: { id } });
  };

  dismissModals = (notification = {}, refetch = () => {}) => {
    this.setState(
      {
        createModal: { show: false },
        updateModal: { id: '' },
        notification: { message: notification.message, color: notification.color }
      },
      refetch
    );
  };

  render() {
    return (
      <Col name="ConfigurationAccountsContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationAccountsContainer__Controls"
          icon="tachometer-alt"
          iconColor="primary"
          title="Dashboards"
        >
          <Col container item>
            <Search
              name="ConfigurationAccountsContainer__Controls__Search"
              value={this.state.search.value}
              onChange={this.handleSearchChange}
              hideButton
            />
          </Col>
          <ButtonStyled
            name="ConfigurationAccountsContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => this.showModal('create')}
          >
            + Add Dashboard
          </ButtonStyled>
        </ContainerHeader>

        <Query query={GET_ALL_DASHBOARDS}>
          {({ loading, error, data, refetch, networkStatus }) => {
            if (this.state.shouldRefetch) this.setState({ shouldRefetch: false }, refetch);
            if ((loading && networkStatus !== 3) || networkStatus === 4)
              return <ContainerLoadingIndicator name="ConfigurationDashboardsContainer__Loading" />;
            if (error)
              return (
                <ErrorMessage error={error}>
                  {({ title, body, icon }) => (
                    <ContainerError
                      name="ConfigurationDashboardsContainer__Error"
                      icon={icon}
                      title={title}
                      body={body}
                      retry={refetch}
                    />
                  )}
                </ErrorMessage>
              );

            const searched = this.search(data.getAllDashboards, this.state.search.value);

            return (
              <>
                <>
                  {this.state.createModal.show && (
                    <Modal
                      size="sm"
                      show
                      name="ConfigurationDashboardsContainer__Modal--create"
                      dismissable
                      onDismiss={this.dismissModals}
                      span={4}
                    >
                      <CreateDashboard
                        onSuccess={(message, color) => this.dismissModals({ message, color }, refetch)}
                      />
                    </Modal>
                  )}
                  {this.state.updateModal.id && (
                    <Modal
                      size="sm"
                      show
                      name="ConfigurationDashboardsContainer__Modal--create"
                      dismissable
                      onDismiss={this.dismissModals}
                      span={4}
                    >
                      <UpdateDashboard
                        id={this.state.updateModal.id}
                        onSuccess={(message, color) => this.dismissModals({ message, color }, refetch)}
                      />
                    </Modal>
                  )}
                  {this.state.notification.message && (
                    <Notification
                      name="ConfigurationDashboardsContainer__Notification"
                      show
                      color="success"
                      onClose={this.hideNotification}
                    >
                      {this.state.notification.message}
                    </Notification>
                  )}
                </>
                <>
                  {!searched.length && (
                    <NoResults
                      name="ConfigurationDashboardsContainer__NoResults"
                      title="There are no results for this search."
                    />
                  )}
                  {!!searched.length &&
                    searched
                      .slice()
                      .sort((dashboardA, dashboardB) => alphabetise(dashboardA.name, dashboardB.name))
                      .map((dash) => (
                        <DashboardRow
                          key={dash.id}
                          dashboardId={dash.id}
                          dashboardName={dash.name}
                          onEdit={() => this.showModal('update', dash.id)}
                        />
                      ))}
                </>
              </>
            );
          }}
        </Query>
      </Col>
    );
  }
}

export default ConfigurationDashboardsContainer;
