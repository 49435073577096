import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { SurveyQuestionCategory, SurveyQuestionCategoryInput } from '@rio/rio-types';
import { CategoryDetails } from './CategoryDetails';
import { validate } from './validate';
import { useNotification } from '../../../hooks/useNotification';
import { Errors } from '../../../types';
import { useUpdateSurveyQuestionCategory, useDeleteSurveyQuestionCategory } from './queries';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

interface UpdateCategoryProps {
  existingCategory: SurveyQuestionCategory;
  onUpdated: () => void;
  onDeleted: () => void;
}

export function UpdateCategory({ onUpdated, onDeleted, existingCategory }: UpdateCategoryProps) {
  const [category, setCategory] = useState<Partial<SurveyQuestionCategoryInput>>({
    name: existingCategory.name
  });
  const [formErrors, setErrors] = useState<Errors<SurveyQuestionCategoryInput>>({});
  const [updateCategory, { loading: updating }] = useUpdateSurveyQuestionCategory();
  const [deleteCategory, { loading: deleting }] = useDeleteSurveyQuestionCategory();
  const { showNotification } = useNotification();

  const handleChange = useCallback((payload: Partial<SurveyQuestionCategoryInput>) => {
    setCategory((t) => ({
      ...t,
      ...payload
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors, validCategory] = validate(category);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }

      await updateCategory({
        variables: {
          id: existingCategory.id,
          category: {
            name: validCategory.name
          }
        },
        onCompleted: onUpdated,
        onError: (e) => {
          showNotification(e.message, 'danger');
        }
      });
    },
    [category, updateCategory, onUpdated, existingCategory.id, showNotification]
  );

  const handleDelete = useCallback(() => {
    deleteCategory({
      variables: { id: existingCategory.id },
      onCompleted: onDeleted
    });
  }, [existingCategory.id, deleteCategory, onDeleted]);

  const disabled = updating || deleting;

  return (
    <Container>
      <PropertySheet container onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>Update Survey Category</PropertySheet.HeaderRow>
        <CategoryDetails onChange={handleChange} category={category} errors={formErrors} disabled={disabled} />
        <PropertySheet.Row container>
          <PropertySheet.ButtonColumn
            span={6}
            disabled={disabled}
            onClick={handleSubmit}
            name="UpdateCategory__UpdateCategoryButton"
          >
            Update category
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            span={6}
            disabled={disabled}
            onClick={handleDelete}
            color="danger"
            name="UpdateCategory__DeleteCategoryButton"
          >
            Delete category
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
