import { useSubscription } from '@apollo/client';
import ON_ACCOUNT_UPDATED from '../graphql/subscriptions/account/OnAccountUpdated.subscription.graphql';
import { useCurrentAccountId } from './useCurrentAccountId';
import { useNotification } from './useNotification';

export function useAccountUpdated() {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const { data } = useSubscription(ON_ACCOUNT_UPDATED, {
    variables: { id: accountId },
    skip: !accountId,
    onSubscriptionData: () => {
      showNotification('Your account has been updated');
    }
  });

  return data?.accountUpdated;
}
