import { useCallback } from 'react';
import { GridOptions } from 'ag-grid-community';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useFuelGridConfig } from '../FuelContainer/FuelTransactions/useFuelGridConfig';
import { GET_FUEL_TRANSACTIONS_PAGE } from '../FuelContainer/FuelTransactions/index.queries';
import { DocumentReferenceV2 as DocumentReference, UploadReferenceCell } from '../CellRenderers';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: '..' }, { label: 'Fuel' }];

export const FuelTransactionsGrid = () => {
  const config = useFuelGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_FUEL_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getFuelTransactionPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <>
      <TransactionGrid
        title="Your fuel data"
        defaultExportFileName="fuel-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../fuel/uploads"
        config={
          {
            ...config,
            components: {
              uploadReference: UploadReferenceCell,
              documentReference: DocumentReference,
            },
          } as GridOptions
        }
        fetchRows={fetchRows}
      />
    </>
  );
};
