import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../../../graphql/fragments/LocationFields.fragment.js';

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $locId: ID!
    $locName: String!
    $accId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $wasteIn: ID
    $wasteOut: ID
    $tags: [ID!]
    $countryId: ID!
    $code: String
    $buildingType: ID
    $departments: [ID!]
    $tenure: ID
    $region: ID
    $floorSpace: Float
    $startDate: String
    $endDate: String
    $leaseRenewalDate: String
    $notes: String
  ) {
    createLocationWithLocationPoints(
      input: {
        id: $locId
        name: $locName
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
        accountId: $accId
        wasteInId: $wasteIn
        wasteOutId: $wasteOut
        tags: $tags
        code: $code
        buildingType: $buildingType
        departments: $departments
        tenure: $tenure
        region: $region
        floorSpace: $floorSpace
        startDate: $startDate
        endDate: $endDate
        leaseRenewalDate: $leaseRenewalDate
        notes: $notes
      }
    ) {
      ...LocationFields
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $locId: ID!
    $locName: String!
    $accId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $wasteIn: UpdateLocationLocationPointInput
    $wasteOut: UpdateLocationLocationPointInput
    $tags: [ID!]
    $countryId: ID!
    $code: String
    $buildingType: ID
    $departments: [ID!]
    $tenure: ID
    $region: ID
    $floorSpace: Float
    $startDate: String
    $endDate: String
    $leaseRenewalDate: String
    $notes: String
  ) {
    updateLocationWithLocationPoints(
      input: {
        id: $locId
        name: $locName
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
        accountId: $accId
        wasteIn: $wasteIn
        wasteOut: $wasteOut
        tags: $tags
        code: $code
        buildingType: $buildingType
        departments: $departments
        tenure: $tenure
        region: $region
        floorSpace: $floorSpace
        startDate: $startDate
        endDate: $endDate
        leaseRenewalDate: $leaseRenewalDate
        notes: $notes
      }
    ) {
      ...LocationFields
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const EXPORT_LOCATIONS = gql`
  mutation ExportLocations($accountId: ID!) {
    getLocationExport(accountId: $accountId)
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: ID!) {
    getLocationById(id: $id) {
      ...LocationFields
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_LOCATIONS_BY_ACCOUNT = gql`
  query GetLocationsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getLocationsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...LocationFields
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_TAGS_BY_ACCOUNT_ID = gql`
  query getTagsByAccountId($accountId: String!) {
    getTagsByAccountId(input: { accountId: $accountId, applicationId: "RIO" }) {
      id
      tagName
      isSystemLevelTag
    }
  }
`;

export const GET_LOCATIONS_FILTER = gql`
  query GetLocationsPageFilter($accountId: ID!, $field: String!) {
    getLocationsPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;

export const UPSERT_LOCATIONS = gql`
  mutation UpsertLocations($locations: [UpsertLocationsInput!]!, $accountId: ID!) {
    upsertLocations(accountId: $accountId, locations: $locations)
  }
`;

export const GET_LOCATION_SELECT_OPTIONS = gql`
  query GetLocationSelectOptions($accountId: ID!, $accountIdAsString: String!) {
    getTagsByAccountId(input: { accountId: $accountIdAsString, applicationId: "RIO" }) {
      value: id
      label: tagName
    }
    getAccountInfo(id: $accountId) {
      departments {
        value: id
        label: name
      }
    }
    getAllCountries {
      value: id
      label: name
    }
    getLocationMetadataOptionsForAccount(accountId: $accountId) {
      tenures {
        value: id
        label: name
      }
      buildingTypes {
        value: id
        label: name
      }
      regions {
        value: id
        label: name
      }
    }
  }
`;

export const GET_ALL_LOCATION_IDS = gql`
  query GetAllLocationIds($accountId: ID!) {
    getLocationsPage(accountId: $accountId, limit: 100000) {
      rows {
        id
      }
    }
  }
`;
