import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import GET_ALL_CURRENCY_TYPES from '../graphql/queries/GetAllCurrencyTypes.graphql';

export function useCurrency() {
  const { data: { getAllCurrencyTypes = [] } = {}, ...others } = useQuery(GET_ALL_CURRENCY_TYPES);

  return {
    data: orderBy(getAllCurrencyTypes, ['name'], ['asc']),
    ...others,
  };
}
