import React from 'react';
import styled from 'styled-components';
import { Text, Col, Row, Icon } from 'rio-ui-components';
import { LoadFailed } from '../../../components/Errors';
import { labels } from '../../../constants/evaluationTypes';

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
`;
const Headers = styled(RowStyled)`
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;
const ColStyled = styled(Col)`
  flex: ${(props) => props.span};
  padding: ${(p) => p.theme.geometry.md.profile.padding};
`;
const IconStyled = styled(Icon)`
  cursor: pointer;
`;

const gridConfig = [
  {
    field: 'type',
    name: 'Type',
    width: 5.5,
    format: (val) => labels[val]
  },
  {
    field: 'name',
    name: 'Name',
    width: 5.5
  },
  {
    field: 'edit',
    name: null,
    width: 1,
    format: () => <IconStyled icon="pencil-alt" />
  }
];

export function EvaluationsGrid({ evaluations, error, onEdit, retry }) {
  return (
    <Col>
      <Headers container>
        {gridConfig.map((column) => (
          <ColStyled key={column.field} container item span={column.width}>
            <Text weight="bold">{column.name}</Text>
          </ColStyled>
        ))}
      </Headers>
      {error ? (
        <LoadFailed name={error.message} error={error} retry={retry} />
      ) : (
        evaluations.map((evaluation) => (
          <RowStyled key={evaluation.id} container item>
            {gridConfig.map((column) => (
              <ColStyled key={evaluation.id + column.field} container item inline span={column.width}>
                {column.field === 'edit' ? (
                  <IconStyled
                    onClick={() => {
                      onEdit(evaluation);
                    }}
                    icon="pencil-alt"
                  />
                ) : (
                  <Text size="md">{column.format ? column.format(evaluation[column.field]) : evaluation[column.field]}</Text>
                )}
              </ColStyled>
            ))}
          </RowStyled>
        ))
      )}
    </Col>
  );
}
