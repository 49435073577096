import gql from 'graphql-tag';

const SINGLE_QUESTION_FIELDS = gql`
  fragment SingleQuestionFields on ReportingQuestion {
    id
    formulation
    shortFormulation
    reportingFrameworkId
    reportingFrameworkChapterId
    materialityMetricId
    parentId
    displaySettings {
      colSpan
      showFormulation
    }
    order
    tableOfContentsNumber
    ... on ChoiceQuestion {
      variants
      canSpecifyOwnChoice
      reportedChoice
    }
    ... on MultipleChoiceQuestion {
      variants
      canSpecifyOwnChoice
      reportedChoices
    }
    ... on YesNoQuestion {
      reportedAnswer
    }
    ... on OpenEndedQuestion {
      reportedText
    }
    ... on OpenEndedRichQuestion {
      reportedText
    }
    ... on IntegerQuestion {
      reportedInt
    }
    ... on FloatQuestion {
      reportedFloat
    }
    ... on DateQuestion {
      reportedDate
    }
  }
`;

export const REPORTING_QUESTION_FIELDS = gql`
  fragment ReportingQuestionFields on ReportingQuestion {
    ...SingleQuestionFields
    ... on QuestionSequence {
      questions {
        ...SingleQuestionFields
        ... on QuestionSequence {
          questions {
            ...SingleQuestionFields
          }
        }
      }
    }
    ... on QuestionSequenceRepeatable {
      questions {
        ...SingleQuestionFields
        ... on QuestionSequence {
          questions {
            ...SingleQuestionFields
          }
        }
      }
      reportedRows {
        id
        answers {
          questionId
          answer
        }
      }
    }
    ... on BreakdownQuestion {
      questions {
        ...SingleQuestionFields
      }
      reportedBreakdown {
        id
        figures
        partition
      }
    }
  }
  ${SINGLE_QUESTION_FIELDS}
`;

export const GET_REPORTING_QUESTIONS = gql`
  query GetReportingFrameworkQuestions($accountId: ID!, $frameworkId: ID!, $chapterId: ID) {
    getReportingQuestions(accountId: $accountId, frameworkId: $frameworkId, chapterId: $chapterId) {
      ...ReportingQuestionFields
    }
  }
  ${REPORTING_QUESTION_FIELDS}
`;

export const ANSWER_REPORTING_QUESTIONS = gql`
  mutation AnswerReportingQuestions($accountId: ID!, $input: AnswerReportingQuestionInput!) {
    answerReportingQuestions(accountId: $accountId, input: $input) {
      ...ReportingQuestionFields
    }
  }
  ${REPORTING_QUESTION_FIELDS}
`;

export const GET_REPORT_PROGRESS_CHAPTER_BREAKDOWN = gql`
  query GetReportProgressChapterBreakdown($accountId: ID!, $frameworkId: ID!) {
    getReportProgressChapterBreakdown(accountId: $accountId, frameworkId: $frameworkId) {
      totalProgress
      subProgressBreakdown {
        id
        name
        progress
      }
    }
  }
`;

export const GET_REPORT_PROGRESS_GROUP_BREAKDOWN = gql`
  query GetReportProgressGroupBreakdown($accountId: ID!, $frameworkId: ID!, $groupId: ID) {
    getReportProgressGroupBreakdown(accountId: $accountId, frameworkId: $frameworkId, groupId: $groupId ) {
      totalProgress
      subProgressBreakdown {
        id
        name
        progress
      }
    }
  }
`;

export const ATTACH_DOCUMENT = gql`
  mutation attachDocument($id: ID!, $accountId: ID!, $systemId: ID!, $linkId: ID!) {
    attachDocumentToManagementSystem(input: { id: $systemId, accountId: $accountId, linkId: $linkId, documentId: $id })
  }
`;