import { DisclosureScore } from './types';

export const disclosureScores: DisclosureScore[] = [
  {
    level: 'Leadership',
    name: 'A',
    climateChangeMinPercentage: 80,
    climateChangeMaxPercentage: 100,
    color: '#C1D7EC'
  },
  {
    level: '',
    name: 'A-',
    climateChangeMinPercentage: 1,
    climateChangeMaxPercentage: 44,
    color: '#C1D7EC'
  },
  {
    level: 'Management',
    name: 'B',
    climateChangeMinPercentage: 45,
    climateChangeMaxPercentage: 79,
    color: '#DBDBDB'
  },
  {
    level: '',
    name: 'B-',
    climateChangeMinPercentage: 1,
    climateChangeMaxPercentage: 44,
    color: '#DBDBDB'
  },
  {
    level: 'Awareness',
    name: 'C',
    climateChangeMinPercentage: 45,
    climateChangeMaxPercentage: 79,
    color: '#CBE0B8'
  },
  {
    level: '',
    name: 'C-',
    climateChangeMinPercentage: 1,
    climateChangeMaxPercentage: 44,
    color: '#CBE0B8'
  },
  {
    level: 'Disclosure',
    name: 'D',
    climateChangeMinPercentage: 45,
    climateChangeMaxPercentage: 79,
    color: '#F5D7B7'
  },
  {
    level: '',
    name: 'D-',
    climateChangeMinPercentage: 1,
    climateChangeMaxPercentage: 44,
    color: '#F5D7B7'
  }
];
