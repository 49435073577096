import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { MultiSelect } from 'rio-ui-components';
import { sortBy } from 'lodash';
import {
  GET_METERS_BY_ACCOUNT_ID,
  GET_ENERGY_SOURCE_FILTER
} from '../../graphql/queries/energySource/getEnergySourceByAccountIdAndType.queries';
import { Nullable, MultiSelectEvent, Option } from '../../types';
import { namedEntityToSelectOption } from '../../utils';
import { getSetFilterModel } from './getSetFilterModel';

interface MetersMultiSelectProps {
  accountId: string;
  value: Nullable<string | string[] | Option[]>;
  onChange: (e: MultiSelectEvent) => void;
  error?: Nullable<Error | string>;
  isSubmitting?: boolean;
  locationsToFilter?: Option[];
  name: string;
  defaultOptions?: Array<string> | boolean;
  disabled?: boolean;
  isGenerationMeters?: boolean;
}

function MetersMultiSelect({
  accountId,
  value,
  onChange,
  error,
  isSubmitting,
  name = 'metersIds',
  locationsToFilter,
  defaultOptions,
  disabled,
  isGenerationMeters
}: MetersMultiSelectProps) {
  const locationsNamesToFilter = locationsToFilter?.map((location) => location.label);
  const { data, loading } = useQuery(GET_ENERGY_SOURCE_FILTER, {
    variables: {
      accountId,
      field: 'locationPointId'
    },
    fetchPolicy: 'network-only'
  });

  const locationsPointItems = data?.getEnergySourcePageFilter?.filter((location: Option) => {
    return locationsNamesToFilter?.includes(location.label);
  });
  const locationsPointIds = locationsPointItems?.map((location: Option) => location.value) || [];
  const filters = getSetFilterModel(locationsPointIds, isGenerationMeters);

  const { data: metersData, loading: metersLoading } = useQuery(GET_METERS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
      filters: filters,
      sort: [{ field: 'name', order: 'asc' }]
    },

    skip: loading,
    fetchPolicy: 'network-only'
  });

  let options = useMemo(() => {
    const meters = metersData?.getEnergySourceByAccountIdAndType?.rows;

    return sortBy(meters?.map(namedEntityToSelectOption), (item) => item?.label.trim().toLowerCase());
  }, [metersData?.getEnergySourceByAccountIdAndType?.rows]);

  if (locationsToFilter && locationsToFilter.length > 0 && !locationsPointIds.length) {
    options = [
      {
        label: 'There is no meters for selected locations',
        value: null,
        isDisabled: true
      }
    ];
  }

  return (
    <MultiSelect
      name={name}
      onChange={onChange}
      isDisabled={isSubmitting || disabled}
      isLoading={loading || metersLoading}
      error={error}
      options={options}
      multiValueColor="tertiary"
      defaultOptions={Array.isArray(defaultOptions) ? defaultOptions : true}
      value={value}
      placeholder={'Start typing to search for a meter'}
    />
  );
}

export { MetersMultiSelect };
