import React, { Component } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Mutation } from '@apollo/client/react/components';
import { Text, LoadingIndicator } from 'rio-ui-components';

import PickTemplate from './PickTemplate';
import TranspositionSection from './TranspositionSection';
import ViewTranspositionSection from './ViewTranspositionSection';

import { CREATE_TRANSPOSITION } from '../index.queries';
import { withTranspositionsGotByAccountId } from '../UploadProcessQueryUtils';

const NEW_TEMPLATE_VAL = 'New template name...';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  height: 100%;
`;

const ContainerStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

class TemplateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: null,
      createTemplate: false,
      transposition: null,
      type: props.type
    };
  }

  onTemplateChange = (e) => {
    if (e.target.value === NEW_TEMPLATE_VAL) {
      return this.setState({ template: null, createTemplate: true });
    }
    return this.setState({ template: e.target.value, createTemplate: false });
  };

  onTranspositionSave = (transposition, callback) => {
    const { accountId } = this.props;
    this.setState({ transposition: transposition.transposition });

    const id = uuidv4();
    const name = transposition.transpositionName || id;
    const mappings = transposition.transposition.reduce((collector, col) => {
      col.dittoColumn && collector.push({ columnName: col.userColumn.trim(), transposition: col.dittoColumn.trim() });
      return collector;
    }, []);
    const ignored = transposition.transposition.reduce((collector, col) => {
      col.ignored && collector.push(col.userColumn);
      return collector;
    }, []);

    callback({ variables: { id, name, mappings, ignored, accountId, type: this.state.type } });
  };

  onTranspositionConfirm = (transposition) => {
    this.props.onTemplateSelect(transposition);
  };

  render() {
    const { transpositions, headers, fileName, accountId, loading, error, refetch } = this.props;
    const { template, createTemplate } = this.state;
    return (
      <ContainerStyled>
        <PickTemplate
          fileName={fileName}
          name="pickTemplate"
          defaultValue={NEW_TEMPLATE_VAL}
          value={template}
          onTemplateChange={this.onTemplateChange}
          accountId={accountId}
          transpositions={transpositions}
          error={error}
          loading={loading}
          refetch={refetch}
        />
        {template && !createTemplate && (
          <ViewTranspositionSection
            name="viewTranspositionSection"
            onConfirm={this.onTranspositionConfirm}
            headers={headers}
            template={template}
            type={this.state.type}
          />
        )}
        {!template && createTemplate && (
          <Mutation
            mutation={CREATE_TRANSPOSITION}
            onCompleted={(data) => {
              this.onTranspositionConfirm(!!data && data.createTransposition);
            }}
          >
            {(createTransposition, { error, loading }) => {
              if (loading)
                return (
                  <CenterContainer>
                    <LoadingIndicator name="createTemplate-Loading" size="lg" />
                  </CenterContainer>
                );
              if (error)
                return (
                  <CenterContainer>
                    <Text name="createTemplate-error">
                      Template name already exists. Please try again with a different name.
                    </Text>
                  </CenterContainer>
                );
              return (
                <TranspositionSection
                  name="TranspositionSection"
                  defaultTemplateValue={NEW_TEMPLATE_VAL}
                  headers={headers}
                  onSave={(transposition) => {
                    this.onTranspositionSave(transposition, createTransposition);
                  }}
                  transpositionNames={transpositions.map((t) => t.transpositionName)}
                  type={this.state.type}
                />
              );
            }}
          </Mutation>
        )}
      </ContainerStyled>
    );
  }
}

export default withTranspositionsGotByAccountId(TemplateScreen);
