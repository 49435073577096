import React from 'react';
import { capitalize } from 'lodash';
import { Modal, Button, Text, styled } from '@rio/ui-components';

const ButtonRow = styled('div')`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
`;

interface DeleteModalProps {
  open: boolean;
  deleteObjectName?: string;
  onDismissClick: () => void;
  onConfirmClick: () => void;
}

export const DeleteModal = ({ onDismissClick, onConfirmClick, deleteObjectName, open }: DeleteModalProps) => {
  return (
    <Modal open={open} onClose={onDismissClick} title={`Delete ${capitalize(deleteObjectName)}`}>
      <Text typescale="body" size="medium">
        This action will permanently delete this {deleteObjectName}. Do you want to proceed?
      </Text>
      <ButtonRow>
        <Button onClick={onDismissClick} variant="outlined" data-testid="Abort_Deletion_Button">
          Cancel
        </Button>
        <Button onClick={onConfirmClick} variant="filled" color="tertiary" data-testid="Confirm_Deletion_Button">
          Delete
        </Button>
      </ButtonRow>
    </Modal>
  );
};
