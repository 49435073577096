import React from 'react';
import { TransactionType } from '@rio/rio-types';
import UploadContainer from '../../UploadContainer';
import columns from './columns.json';
import template from './materials-template.xlsx';
import { TemplateType } from '../../../../types';

function MaterialsUploadContainer(props) {
  return (
    <UploadContainer
      {...props}
      dataSection={TransactionType.Materials}
      template={[
        {
          columns,
          type: TemplateType.Dynamic,
          name: 'Simple',
          fileName: 'simple-materials-template',
        },
        {
          type: TemplateType.Static,
          name: 'Advanced',
          fileName: 'advanced-materials-template',
          url: template,
        },
      ]}
    />
  );
}

export default MaterialsUploadContainer;
