import { useMemo } from 'react';
import { IDeveloperField } from 'dromo-uploader-react';
import get from 'lodash/get';
import { useLocationSelectOptions } from './useLocationSelectOptions';

const keyToPathMapping = {
  tagIds: 'getTagsByAccountId',
  departmentIds: 'getAccountInfo.departments',
  countryId: 'getAllCountries',
  tenureId: 'getLocationMetadataOptionsForAccount.tenures',
  buildingTypeId: 'getLocationMetadataOptionsForAccount.buildingTypes',
  regionId: 'getLocationMetadataOptionsForAccount.regions',
};

const dromoFields: IDeveloperField[] = [
  {
    key: 'id',
    type: 'string',
    label: 'ID',
    description:
      'Rio ID. Enter it if you want to update existing location. Example value: 5a72c22b-6c56-4eb1-802c-b26d91e7953b',
  },
  {
    key: 'name',
    type: 'string',
    label: 'Name',
    description: 'Name of the location',
    validators: [
      {
        validate: 'unique_case_insensitive',
      },
      {
        validate: 'required',
      },
    ],
  },
  {
    key: 'address1',
    type: 'string',
    label: 'Address 1',
    validators: [
      {
        validate: 'required',
      },
    ],
    description: 'First line of the address',
  },
  {
    key: 'address2',
    type: 'string',
    label: 'Address 2',
    validators: [],
    description: 'Address 2 (optional)',
  },
  {
    key: 'address3',
    type: 'string',
    label: 'Address 3',
    validators: [],
    description: 'Address 3 (optional)',
  },
  {
    key: 'city',
    type: 'string',
    label: 'City',
    description: 'City',
    validators: [
      {
        validate: 'required',
      },
    ],
  },
  {
    key: 'postCode',
    type: 'string',
    label: 'Postcode',
    description: 'Postcode',
    validators: [
      {
        validate: 'required',
      },
    ],
  },
  {
    key: 'countryId',
    type: 'select',
    label: 'Country',
    selectOptions: [],
    description: 'Country',
    validators: [
      {
        errorMessage: 'Please ensure you have selected a country for your locations',
        validate: 'required',
      },
    ],
  },
  {
    key: 'wasteIn',
    type: 'checkbox',
    label: 'Waste In',
    description: 'Check if it is a Waste In location',
  },
  {
    key: 'wasteOut',
    type: 'checkbox',
    label: 'Waste Out',
    description: 'Check if it is a Waste Out location',
  },
  {
    key: 'tagIds',
    type: 'select',
    label: 'Tags',
    description: 'Selects tags (optional)',
    manyToOne: true,
  },
  {
    key: 'departmentIds',
    type: 'select',
    label: 'Departments',
    description: 'Selects departments (optional)',
    manyToOne: true,
  },
  {
    key: 'regionId',
    type: 'select',
    label: 'Region',
    selectOptions: [],
    description: 'Select region (optional)',
  },
  {
    key: 'tenureId',
    type: 'select',
    label: 'Tenure',
    selectOptions: [],
    description: 'Select tenure (optional)',
  },
  {
    key: 'buildingTypeId',
    type: 'select',
    label: 'Building Type',
    selectOptions: [],
    description: 'Select building type (optional)',
  },
  {
    key: 'locationCode',
    type: 'string',
    label: 'Location code',
    description: 'Location code (Location ID / ePIM) (optional)',
  },
  {
    key: 'phone1',
    type: 'string',
    label: 'Phone',
    description: 'Phone number',
  },
  {
    key: 'floorSpace',
    type: ['number', 'default'],
    label: 'Floor space',
    description: 'Enter floor space (square meters) (optional)',
    invalidValueMessage: 'Please ensure floor space is entered as a number using the m2 unit',
  },
  {
    key: 'startDate',
    type: 'date',
    label: 'Occupation start date',
    description: 'Enter occupation start date (optional)',
  },
  {
    key: 'endDate',
    type: 'date',
    label: 'Occupation end date',
    description: 'Enter occupation end date (optional)',
  },
  {
    key: 'leaseRenewalDate',
    type: 'date',
    label: 'Lease renewal date',
    description: 'Enter lease renewal date (optional)',
  },
  {
    key: 'notes',
    type: 'string',
    label: 'Notes',
    description: 'Enter notes (optional)',
  },
] as IDeveloperField[];

export function useDromoFields(accountId: string): IDeveloperField[] {
  const { data } = useLocationSelectOptions(accountId);

  const fields: IDeveloperField[] = useMemo(
    () =>
      dromoFields.map((field) =>
        field.type === 'select'
          ? {
              ...field,
              type: 'select',
              selectOptions: get(data, keyToPathMapping[field.key], []),
            }
          : field
      ),
    [data]
  );

  return fields;
}
