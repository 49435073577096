import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccountInfo($id: ID!) {
    getAccountInfo(id: $id) {
      id
      name
      accessControls {
        learn
        intelligence
        v2Design
        comply {
          legislation
          tagLevelLegislation
          policies
          systems
          targets
          frameworks
          agreements
        }
        data {
          waste
          electricity
          gas
          water
          materials
          fuel
          transport
          refrigerants
          heatSteam
        }
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTIONS = gql`
  query GetAllLearnSubscriptions($accountId: String!) {
    getAllLearnSubscriptions(accountId: $accountId) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }
  }
`;

export const SAVE_SUBSCRIPTION = gql`
  mutation SaveLearnSubscriptions(
    $accountId: String!
    $subscriptions: [String!]!
    $bundleShow: [String!]!
    $bundleHidden: [String!]!
    $mandatoryBundleId: String
  ) {
    saveLearnSubscriptions(
      input: {
        accountId: $accountId
        subscriptions: $subscriptions
        bundleShow: $bundleShow
        bundleHidden: $bundleHidden
        mandatoryBundleId: $mandatoryBundleId
      }
    ) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }
  }
`;

export const COURSES_FRAGMENT = gql`
  fragment PaginatedCoursesFields on PaginatedCourses {
    isLastPage
    totalItemCount
    data {
      id
      name
      type
      metaData {
        viewed
        link
        thumbnail
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTION_INFO = gql`
  query GetAllSubscriptionInfo($accountId: String!) {
    getAllLearnBundles {
      id
      title
      description
      restricted
      startDate
      endDate
      courses
    }

    getAllLearnSubscriptions(accountId: $accountId) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }

    courses: getAllCourses {
      ...PaginatedCoursesFields
    }
  }
  ${COURSES_FRAGMENT}
`;

export const GET_AVAILABLE_SUBSCRIPTION_INFO = gql`
  query GetAvailableSubscriptionInfo($accountId: String!, $userId: ID!) {
    getAllLearnBundles {
      id
      title
      description
      restricted
      startDate
      endDate
      courses
    }

    getAllLearnSubscriptions(accountId: $accountId) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }

    courses: getAvailableCourses(accountId: $accountId, userId: $userId, ownedCourses: true, pageNumber: 1) {
      ...PaginatedCoursesFields
    }
  }
  ${COURSES_FRAGMENT}
`;

export const GET_USERS_BY_ACCOUNT_ID = gql`
  query GetUserInfoByAccountId($accountId: String!, $limit: Int!, $paginationToken: String) {
    getUserInfoByAccountId(accountId: $accountId, limit: $limit, paginationToken: $paginationToken) {
      paginationToken
      users {
        id
        email
        first_name
        last_name
        username
        last_active_at
        total_time_spent_ms
        roles
        role {
          role
          name
        }
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query GetCourseById($id: ID!, $accountId: ID!) {
    getCourseById(id: $id, accountId: $accountId) {
      name
      id
    }
  }
`;

export const GET_EXCLUSIONS_FOR_ACCOUNT = gql`
  query GetExclusionsForAccount($id: ID!) {
    getExclusionsByAccountId(id: $id) {
      userId
      exclusions
    }
  }
`;

export const SAVE_USER_EXCLUSION = gql`
  mutation SaveUserExclusion($userId: ID!, $accountId: ID!, $exclusions: [String!]!) {
    saveUserExclusion(input: { userId: $userId, accountId: $accountId, exclusions: $exclusions }) {
      userId
      accountId
      exclusions
    }
  }
`;

export const BULK_UPDATE_EXCLUSIONS = gql`
  mutation BulkUpdateExclusions(
    $courseId: ID!
    $accountId: ID!
    $userIds: [String!]!
    $operation: BulkUpdateExclusionOperation!
  ) {
    bulkUpdateExclusions(
      input: { userIds: $userIds, courseId: $courseId, accountId: $accountId, operation: $operation }
    )
  }
`;
