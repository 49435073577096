import { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, LoadingIndicator } from 'rio-ui-components';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import UploadBar from '../../../components/UploadBar';
import {
  usePermissions,
  useAccessControls,
  useLicencesPaidSubscription,
  useRedirectToDesignVersion,
} from '../../../hooks';

import { OverlayProgressBars } from '../../../components/OverlayProgressBars';

const ContainerStyled = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 1200px;
`;

const MainColStyled = styled(Col)`
  background-color: ${(props) => props.theme.colors.neutral.normal.background};
  overflow: hidden;

  & > .Header {
    padding-left: 20px;
    padding-right: 40px;
  }
`;

const PageContainer = styled(Col)`
  height: 100%;
  overflow: hidden;
  flex: 1 1 auto;
`;

const Loader = styled(LoadingIndicator)`
  margin: auto;
  position: relative;
  top: 35%;
`;

export const AppContainer = () => {
  const [items, addItems] = useState([]);
  const [createMutation, setCreateMutation] = useState();
  const [onComplete, setOnComplete] = useState();
  const permissions = usePermissions();
  useLicencesPaidSubscription();
  const { loading: isAccessControlsLoading } = useAccessControls();

  useRedirectToDesignVersion();

  return (
    <ContainerStyled id="app-container" className="v1">
      <Row container item>
        <SideBar />
        <MainColStyled container item name="main">
          <Header />
          <PageContainer>
            {isAccessControlsLoading || permissions.loading ? (
              <Loader />
            ) : (
              <Outlet context={[addItems, setCreateMutation, setOnComplete]} />
            )}
          </PageContainer>
        </MainColStyled>
        {items.length > 0 && createMutation && (
          <UploadBar
            items={items}
            dismiss={() => addItems([])}
            createMutation={createMutation}
            onComplete={onComplete}
          />
        )}
      </Row>
      <OverlayProgressBars />
    </ContainerStyled>
  );
};
