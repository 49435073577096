import { ReactNode } from 'react';
import { Course } from '@rio/rio-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Text, styled } from '@rio/ui-components';
import { MarkdownRenderer } from 'rio-ui-components'; // TODO Move to @rio/ui-components
import { CourseCards } from './CourseCards';

interface CoursesSectionProps {
  title: string | ReactNode;
  description?: string;
  courses: Course[];
  bundleId?: string;
}

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  a {
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

const Section = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
`;

const SectionTitle = styled(Text)`
  margin-right: 8px;
`.withComponent('h2');

const MarkdownRendererStyled = styled(Text)`
  margin-top: -2px;
  margin-bottom: 22px;
`.withComponent(MarkdownRenderer);

export function CoursesSection({ title, description, courses, bundleId }: CoursesSectionProps) {
  const engageId = bundleId || (typeof title === 'string' ? title.toLowerCase() : '');

  return (
    <Section>
      <TitleWrapper>
        <SectionTitle typescale="title" size="medium">
          {title}
        </SectionTitle>
        {!!courses.length && engageId && (
          <Link to={`/v2/engage/${engageId}`}>
            <FormattedMessage id="pages.learn.viewLink" values={{ amount: courses.length }} />
          </Link>
        )}
      </TitleWrapper>
      {description && (
        <div title={description}>
          <MarkdownRendererStyled
            typescale="body"
            size="medium"
            source={_.truncate(description, {
              length: 100,
              separator: ' ',
              omission: ' ...',
            })}
          >
            {description}
          </MarkdownRendererStyled>
        </div>
      )}
      <CourseCards courses={courses} />
    </Section>
  );
}
