import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getDashboardLink } from '../../utils';
import ReportRow from './ReportRow';

const ResultsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const ReportingReportsList = ({ items }) => {
  return (
    <ResultsContainer>
      {items.map((item) => (
        <Link
          key={item.id}
          name={`ReportingReportsList__Link ReportingReportsList__Link--${item.id}`}
          to={getDashboardLink(item)}
        >
          <ReportRow
            reportId={item.providerDashboardId}
            title={item.name}
            description={item.description}
            type={item.area}
            key={item.id}
            thumbnail={item.thumbnailUrl}
          />
        </Link>
      ))}
    </ResultsContainer>
  );
};

export default ReportingReportsList;
