import React from 'react';
import { Heading, Text, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const RowStyled = styled.div`
  display: flex;
  align-items: top;
  padding: ${(props) => props.theme.geometry.md.spacing} 0 ${(props) => props.theme.geometry.xs.spacing} 0;
`;

const IconStyled = styled(Icon)`
  margin: 0 ${(props) => props.theme.geometry.sm.spacing} 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const HeaderStyled = styled(Heading)`
  padding: 0 0 ${(props) => props.theme.geometry.xs.spacing} 0;
`;

const ContainerStyled = styled.form`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ContactDetails = ({ intl }) => {
  return (
    <ContainerStyled>
      <HeaderStyled size="lg" align="left">
        {intl.formatMessage({ id: 'pages.support.contactInformation' })}
      </HeaderStyled>
      <RowStyled>
        <IconStyled inline size="lg" icon="home" color="#ccc" />
        <Text inline>
          Rio ESG LTD <br />
          1 Mark Square <br />
          London <br />
          EC2A 4EG <br />
          UK
        </Text>
      </RowStyled>
      <RowStyled>
        <IconStyled inline size="lg" icon="phone" color="#ccc" />
        <Text inline>020 3371 7630</Text>
      </RowStyled>
      <RowStyled>
        <IconStyled inline size="lg" icon="envelope" color="#ccc" />
        <Text inline>support@rio.ai</Text>
      </RowStyled>
    </ContainerStyled>
  );
};

export default injectIntl(ContactDetails);
