import { useQuery } from '@apollo/client';
import { sortBy, find } from 'lodash';
import { GET_All_COUNTRIES } from '../queries/countries';

export function useCountries() {
  const { data } = useQuery(GET_All_COUNTRIES);

  let countryList = [],
    defaultCountry = null;
  const allCountries = data?.getAllCountries;
  if (allCountries) {
    const getAllCountries = sortBy(allCountries, 'name');
    const greatBritain = find(getAllCountries, (item) => item.name.includes('United Kingdom'));

    countryList = getAllCountries.map((item) => ({ value: item.id, label: item.name }));
    defaultCountry = { value: greatBritain.id, label: greatBritain.name };
  }

  return { countryList, defaultCountry, allCountries };
}
