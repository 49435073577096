import { gql } from "@apollo/client";

export const GET_WASTE_STREAMS_BY_ACCOUNT_ID = gql`
  query getWasteStreamsByAccountId($accountId: ID!, $filterValue: String, $pageSize: Int = 200) {
    getWasteStreamsByAccountId(accountId: $accountId, page: 1, pageSize: $pageSize, filterValue: $filterValue) {
      pageCount
      wasteStreams {
        id
        name
        ewc {
          code
          id
        }
      }
    }
  }
`;
