import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/account/GetAccountInfoById.query.graphql';

export function useGetAccountInfoByIdQuery(id) {
  const { data: { getAccountInfo = {} } = {}, ...others } = useQuery(QUERY, {
    variables: { id },
    skip: !id
  });

  return { data: getAccountInfo, ...others };
}
