import { gql } from '@apollo/client';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $id: ID!
    $account_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $previousEmail: String
    $jobTitle: String
    $department: String
    $phone: String
    $mobile: String
    $profile: String
  ) {
    updateUserProfile(
      input: {
        id: $id
        account_id: $account_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        previousEmail: $previousEmail
        jobTitle: $jobTitle
        department: $department
        phone: $phone
        mobile: $mobile
        profilePicture: $profile
      }
    ) {
      id
      email
      first_name
      last_name
      jobTitle
      department
      phone
      mobile
    }
  }
`;

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation UploadProfilePicture(
    $fileName: String!
    $imageData: String!
    $contentType: String!
    $id: ID!
    $account_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $previousEmail: String
    $jobTitle: String
    $department: String
    $phone: String
    $mobile: String
    $profile: String
  ) {
    uploadProfilePicture(
      input: {
        fileName: $fileName
        contentType: $contentType
        imageData: $imageData
        accountId: $account_id
        userId: $id
      }
    )

    updateUserProfile(
      input: {
        id: $id
        account_id: $account_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        previousEmail: $previousEmail
        jobTitle: $jobTitle
        department: $department
        phone: $phone
        mobile: $mobile
        profilePicture: $profile
      }
    ) {
      id
      email
      first_name
      last_name
      jobTitle
      department
      phone
      mobile
      profilePicture
    }
  }
`;
