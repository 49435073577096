import React from 'react';
import { useTheme } from 'styled-components';
import { getPriorityIconProps } from '../priorityIconProps';
import { Icon } from 'rio-ui-components';

export function StatusCell(props) {
  const { data: { isCompleted, dueDate } = {} } = props;

  const taskPriority = isCompleted ? 'COMPLETED' : new Date(dueDate) > new Date() ? 'INCOMPLETED' : 'OVERDUE';

  const theme = useTheme();

  return <Icon name={`task-row__status`} size="lg" {...getPriorityIconProps(taskPriority, theme)} />;
}
