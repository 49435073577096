"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageErrorContext = exports.ErrorContextProvider = exports.ErrorContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const defaultValue = {
    error: null,
    setError: (_) => { },
};
exports.ErrorContext = (0, react_1.createContext)(defaultValue);
const ErrorContextProvider = ({ children }) => {
    const [error, setError] = (0, react_1.useState)(null);
    const value = (0, react_1.useMemo)(() => ({
        error,
        setError,
    }), [error, setError]);
    return (0, jsx_runtime_1.jsx)(exports.ErrorContext.Provider, { value: value, children: children });
};
exports.ErrorContextProvider = ErrorContextProvider;
const usePageErrorContext = () => (0, react_1.useContext)(exports.ErrorContext);
exports.usePageErrorContext = usePageErrorContext;
