import React, { useCallback } from 'react';
import { Scope, Task, TaskCategory, TaskPriority, TaskSubject } from '@rio/rio-types';
import {
  Grid,
  TextField,
  DatePicker,
  Checkbox,
  Select,
  Text,
  styled,
  TextArea,
  OptionsProps,
} from '@rio/ui-components';
import { TagsMultiSelect } from '~/components/TagsMultiSelect/v2';
import { LocationsMultiSelect } from '~/components/LocationsMultiSelect/v2';
import { LOCATION, TAG } from '~/constants/scopes';
import { useScopeOptions } from '~/hooks';
import { UsersMultiSelect } from '~/components/UserSelect/v2';
import categoryOptions from '../taskCategories.json';
import subjectOptions from '../taskSubjects.json';
import priorityOptions from '../taskPriorities.json';
import { LegislationSelect } from '~/components/LegislationSelect/v2';
import { AspectsSelect } from '~/components/AspectsSelect/v2';
import { TargetsSelect } from '~/components/TargetsSelect/v2';
import { ProjectSelect } from '~/components/ProjectSelect/v2';

const LabelContainerStyled = styled('div')`
  margin-bottom: 8px;
`;

export interface FullTask
  extends Omit<Task, 'id' | 'author' | 'category' | 'priority' | 'projectId' | 'scope' | 'subject'> {
  category: TaskCategory | null;
  priority: TaskPriority | null;
  scope: Scope | null;
  subject: TaskSubject | null;
  locationIds: string[];
  tagIds: string[];
  itemId: string | null;
  projectId: string | null;
}

type Props = {
  isSubmitting?: boolean;
  task: FullTask;
  setTask: React.Dispatch<React.SetStateAction<FullTask>>;
  accountId: string;
  isEditing?: boolean;
  projectId?: string;
  hasProjectsAccess?: boolean;
  sourcePage?: string;
  disabled: Record<string, boolean>;
  errors: Record<string, string>;
};

const TaskDetails = ({
  isSubmitting,
  task,
  setTask,
  accountId,
  isEditing,
  sourcePage,
  disabled = {},
  projectId = '',
  hasProjectsAccess = false,
  errors,
}: Props) => {
  const scopeOptions = useScopeOptions();

  const handleChange = useCallback(
    (event) => {
      if (event.target.name === 'projectId') {
        event.target.select = event.target.value;
      }
      setTask((state) => ({
        ...state,
        [event.target.name]: event.target.value,
      }));
      if (event.target.name === 'subject' && (task.item?.id || task?.itemId)) {
        setTask((state) => ({
          ...state,
          itemId: null,
          item: null,
        }));
      }
    },
    [setTask, task]
  );

  const handleSelectChange = useCallback(
    (field: string, value: string | OptionsProps[] | string[], overrides?: Record<string, unknown>) => {
      setTask((state) => ({
        ...state,
        ...(overrides || {}),
        [field]: value,
      }));
    },
    [setTask]
  );

  const taskItemId = task.itemId || task.item?.id;
  return (
    <Grid container columns={12} rowSpacing={3}>
      <Grid item xs={12}>
        <TextField
          label="Task Name"
          data-cy="TaskDetails__Input--name"
          disabled={isSubmitting}
          onChange={(e) => handleChange(e)}
          name="name"
          value={task.name}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          label="Description"
          rows={4}
          data-cy="TaskDetails__Input--description"
          disabled={isSubmitting}
          onChange={(e) => handleChange(e)}
          name="description"
          value={task.description}
          error={Boolean(errors.description)}
          helperText={errors.description}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Scope"
          name="scope"
          options={scopeOptions}
          value={task.scope}
          onChange={({ value }) => handleSelectChange('scope', value)}
          disabled={disabled.scope || isSubmitting}
        />
      </Grid>
      {task.scope === LOCATION && (
        <Grid item xs={12}>
          <LocationsMultiSelect
            label="Location(s)"
            name="locationIds"
            accountId={accountId}
            value={task.locationIds || []}
            onChange={(selectedLocations) =>
              handleSelectChange('locationIds', selectedLocations?.map((st) => st.value) || [])
            }
            disabled={disabled.locations || isSubmitting}
          />
        </Grid>
      )}
      {task.scope === TAG && (
        <Grid item xs={12}>
          <TagsMultiSelect
            label="Tag(s)"
            name="tagIds"
            accountId={accountId}
            value={task.tagIds || []}
            onChange={(selectedTags) => handleSelectChange('tagIds', selectedTags?.map((st) => st.value) || [])}
            disabled={disabled.tags || isSubmitting}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          label="Priority"
          name="priority"
          disabled={isSubmitting}
          onChange={({ value }) => handleSelectChange('priority', value)}
          value={task.priority}
          options={priorityOptions}
          error={Boolean(errors.priority)}
          helperText={errors.priority}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Category"
          name="category"
          disabled={disabled.category || isSubmitting}
          onChange={({ value }) =>
            handleSelectChange('category', value, {
              subject: null,
              itemId: null,
              item: null,
            })
          }
          value={task.category}
          options={categoryOptions}
          error={Boolean(errors.category)}
          helperText={errors.category}
        />
      </Grid>
      {task.category && subjectOptions[task.category] && (
        <Grid item xs={12}>
          <Select
            label="Subject"
            name="subject"
            onChange={({ value }) => handleSelectChange('subject', value)}
            value={task.subject?.toUpperCase()}
            options={subjectOptions[task.category]}
            disabled={disabled.subject || isSubmitting}
            error={Boolean(errors.subject)}
            helperText={errors.subject}
          />
        </Grid>
      )}
      {task.subject && [TaskSubject.Legislation, TaskSubject.Aspects, TaskSubject.Targets].includes(task.subject) && (
        <Grid item xs={12}>
          <LabelContainerStyled>
            <Text typescale="body" size="medium">
              Related item{' '}
              {disabled[task.subject] &&
                task.subject === TaskSubject.Aspects &&
                sourcePage === 'TASKS' &&
                '(Please go to your Aspect and Impact Register to create a task relating to a single aspect or impact.)'}
            </Text>
          </LabelContainerStyled>
          {task.subject === TaskSubject.Legislation && (
            <LegislationSelect
              name="itemId"
              onChange={({ value }) => handleSelectChange('itemId', value)}
              value={taskItemId || ''}
              disabled={disabled[TaskSubject.Legislation] || isSubmitting}
            />
          )}
          {task.subject === TaskSubject.Aspects && (
            <AspectsSelect
              name="itemId"
              onChange={({ value }) => handleSelectChange('itemId', value)}
              value={taskItemId || ''}
              disabled={disabled[TaskSubject.Aspects] || isSubmitting}
            />
          )}
          {task.subject === TaskSubject.Targets && (
            <TargetsSelect
              name="itemId"
              onChange={({ value }) => handleSelectChange('itemId', value)}
              value={taskItemId || ''}
              disabled={isSubmitting}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <UsersMultiSelect
          label="Owners"
          onChange={(value) => {
            handleChange({
              target: {
                value,
                name: 'owners',
              },
            });
          }}
          value={task.owners || []}
          accountId={accountId}
          error={errors.owner}
        />
      </Grid>

      <Grid item xs={12}>
        <DatePicker
          label="Due Date"
          disabled={isSubmitting}
          onChange={(date) => {
            handleChange({
              target: {
                value: date ? new Date(date).toISOString() : undefined,
                name: 'dueDate',
              },
            });
          }}
          name="dueDate"
          value={task.dueDate ? new Date(task.dueDate) : undefined}
          error={errors.dueDate}
        />
      </Grid>

      {hasProjectsAccess ? (
        <Grid item xs={12}>
          <ProjectSelect
            name="projectId"
            accountId={accountId}
            onChange={({ value }) => handleSelectChange('projectId', value)}
            value={task.projectId || projectId || ''}
            disabled={isSubmitting}
          />
        </Grid>
      ) : null}
      {isEditing && (
        <Grid item xs={12}>
          <Checkbox
            label="Completed"
            checked={task.isCompleted}
            value={task.isCompleted}
            onChange={() =>
              setTask({
                ...task,
                isCompleted: !task.isCompleted,
              })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TaskDetails;
