import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Search, LoadingIndicator, Button, Notification } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_CONTAINER_TYPES } from './index.queries';
import { ContainerTypesGrid } from './ContainerTypesGrid';
import { getContainerTypeVolumes } from './getContainerTypeVolumes';
import { useSearch } from '../../../hooks/useSearch';
import { LoadFailed } from '../../../components/Errors';
import { CreateContainerTypeModal } from './CreateContainerTypeModal';
import { usePermissions } from '../../../hooks/usePermissions';

const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

const SearchStyled = styled(Search)`
  flex: 1;
`;

const RowStyled = styled(Row)`
  flex: 1;
  min-width: 400px;
`;

const ButtonCol = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const LoadingContainer = styled(Col)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export default function ContainerTypes() {
  const { data, loading, error, refetch } = useQuery(GET_CONTAINER_TYPES);
  const containerTypes = data && data.getContainerTypes ? data.getContainerTypes : [];
  const [notification, setNotification] = useState(null);
  const typeSizes = getContainerTypeVolumes(containerTypes);
  const [searchValue, setSearchValue, searchResult] = useSearch(typeSizes, ['name', 'unit', 'volume']);
  const [createModalShown, setCreateModalShown] = useState(false);
  const permissions = usePermissions();
  const canCreate = permissions.data.includes('createContainerTypeAll');
  return (
    <Page>
      {createModalShown && (
        <CreateContainerTypeModal
          onDismiss={() => setCreateModalShown(false)}
          onError={(err) =>
            setNotification({
              message: err.message,
              color: 'danger',
            })
          }
          onCompleted={() => {
            setCreateModalShown(false);
            setNotification({
              message: 'Container has been created!',
              color: 'success',
            });
            refetch();
          }}
        />
      )}
      {notification && (
        <Notification name={notification.message} color={notification.color} show>
          {notification.message}
        </Notification>
      )}
      <ContainerHeader icon="dumpster" iconColor="primary" title="Container Types">
        <RowStyled container>
          <Col span={canCreate ? 6 : 12} container item>
            <SearchStyled
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={(e) => setSearchValue(e.target.value)}
              hideButton
            />
          </Col>
          {canCreate && (
            <ButtonCol container item span={6}>
              <Button onClick={() => setCreateModalShown(true)}>+ Add Container Type</Button>
            </ButtonCol>
          )}
        </RowStyled>
      </ContainerHeader>
      {error ? (
        <LoadFailed error={error} retry={refetch} />
      ) : loading ? (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ) : (
        <ContainerTypesGrid typeSizes={searchResult} />
      )}
    </Page>
  );
}
