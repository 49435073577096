import { useMemo, useCallback } from 'react';
import { ColDef, SetFilterValuesFuncParams } from 'ag-grid-community';
import { FilterData } from '@rio/rio-types';
import _ from 'lodash';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { dateTimeValueFormatter, booleanValueFormatter, gridValueFormatter } from '~/utils';
import { useGetFundsFilter } from './useGetFundsFilter';

export const useColumnDefs = (): ColDef[] => {
  const accountId = useCurrentAccountId();

  const { showNotification } = useNotification();

  const [getFundsFilter] = useGetFundsFilter();

  const handleGetFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const field = params.colDef.field || params.colDef.colId;

        if (!field) {
          throw new Error(`Unfilterable column`);
        }

        const response = await getFundsFilter({
          variables: {
            accountId,
            field,
          },
        });

        const data: FilterData[] = response.data?.getFundsFilter || [];

        const values = data.map((v) => (v.value ? JSON.stringify(_.omit(v, '__typename')) : v.value!));

        params.success(values);
      } catch (err) {
        showNotification(`Couldn't load filter values for column ${params.colDef.field}: ${err}`, 'danger');
      }
    },
    [getFundsFilter, accountId, showNotification]
  );

  const filterParams = useMemo(
    () => ({
      values: handleGetFilterValues,
      valueFormatter: gridValueFormatter,
      cache: false,
      refreshValuesOnOpen: true,
    }),
    [handleGetFilterValues]
  );

  const deletedColumnFilterParams = useMemo(
    () => ({
      ...filterParams,
      valueFormatter: gridValueFormatter,
    }),
    [filterParams]
  );

  return useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'External ID',
        field: 'externalId',
        filter: 'agTextColumnFilter',
      },
      {
        filterParams,
        headerName: 'Currency',
        field: 'currency.code',
        colId: 'currency.id',
      },
      {
        headerName: 'Created At',
        field: 'createdAt',
        valueFormatter: dateTimeValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Last Updated At',
        field: 'lastUpdatedAt',
        valueFormatter: dateTimeValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        filterParams: deletedColumnFilterParams,
        headerName: 'Deleted',
        field: 'deleted',
        valueFormatter: booleanValueFormatter,
        filter: 'agSetColumnFilter',
      },
    ],
    [filterParams, deletedColumnFilterParams]
  );
};
