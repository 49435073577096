import { useMemo, useEffect, memo, useCallback, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Col, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import {
  SurveySubmissionStatus,
  SurveySubmissionStatusInput,
  ReceivedSurvey as ReceivedSurveyInterface,
  SurveySubmission,
  Maybe,
} from '@rio/rio-types';
import { SurveyModel } from 'survey-core';
import { SurveyWorksheet } from '../../../components/Surveys';
import { useCurrentAccountId, useNotification, useUnlockContributor } from '../../../hooks';
import { ExpandButton } from '../../../components/Surveys';
import ContainerHeader from '../../../components/ContainerHeader';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { transformSubmissionToAnswers } from './utils';
import { AuditType, useCreateSurveyAuditEvent, useUploadedDocuments, useSubmitData } from './hooks';
import { Editing } from './Editing';
import { useSetSurveySubmissionUpdated } from './hooks/useSetSurveySubmissionUpated';

const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 20px;
`;

const SubmitButton = styled(Button)`
  margin-left: 20px;
  width: 200px;
`;

const isDisabled = (submission?: Maybe<SurveySubmission>) =>
  submission &&
  (submission.status === SurveySubmissionStatus.Approved ||
    submission.status === SurveySubmissionStatus.ReadyForReview);

interface ReceivedSurveyProps {
  survey: ReceivedSurveyInterface;
}

export const ReceivedSurvey = memo(function ReceivedSurvey({ survey }: ReceivedSurveyProps) {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [newSubscriptionUpdate, setNewSubscriptionUpdate] = useState(false);

  const accountId = useCurrentAccountId();
  const submission = survey.submission;
  const { uploadedDocuments, uploadedDocumentsIds } = useUploadedDocuments(survey.submission);
  const user = useCurrentUser();
  const { createSurveyAuditEvent } = useCreateSurveyAuditEvent();
  const timeoutId = useRef<number | null>(null);

  useSetSurveySubmissionUpdated(survey.id, accountId);

  const { id, first_name: firstName, last_name: lastName } = survey?.lockedBy || {};
  const isLocked = user?.id !== id && !!id;
  const editingUser = firstName + ' ' + lastName;

  const [editingModalShown, setEditingModalShown] = useState(isLocked);
  const toggleEditingModal = useCallback(() => setEditingModalShown((shown) => !shown), []);

  const prevSurveyModelRef = useRef<null | SurveyModel>(null);

  const surveyModel = useMemo(() => {
    const model = new SurveyModel(survey.template.formContent);
    model.showCompletedPage = false;
    model.showNavigationButtons = false;
    model.showPreviewBeforeComplete = 'showAnsweredQuestions';
    if (survey.submission) {
      model.data = transformSubmissionToAnswers(survey.submission.answers, uploadedDocuments);
      if (isDisabled(survey.submission)) {
        model.mode = 'display';
      }
    }

    const prevSurveyModel = prevSurveyModelRef.current;

    if (prevSurveyModel) {
      prevSurveyModelRef.current = model;
    }

    model.currentPageNo = prevSurveyModel?.currentPageNo || 0;
    prevSurveyModelRef.current = model;

    return model;
  }, [survey.template.formContent, survey.submission, uploadedDocuments]);

  const { submitData, loading } = useSubmitData({
    surveyId: survey.id,
    accountId,
    model: surveyModel,
    uploadedDocumentsIds,
    version: submission?.version || 1,
  });
  const [expanded, setExpanded] = useState(false);
  const expand = useCallback(() => setExpanded(true), []);
  const collapse = useCallback(() => setExpanded(false), []);

  const surveyComplete = useCallback(
    async () =>
      submitData(
        SurveySubmissionStatusInput.ReadyForReview,
        () => {
          showNotification('Survey submitted successfully', 'success');
          navigate('../received');
        },
        (error) => {
          showNotification(`Error submitting survey: ${error.message}`, 'danger');
        }
      ),
    [showNotification, navigate, submitData]
  );

  const saveSurvey = useCallback(() => {
    submitData(
      SurveySubmissionStatusInput.InProgress,
      () => {
        setNewSubscriptionUpdate(true);
        timeoutId.current = setTimeout(() => {
          setNewSubscriptionUpdate(false);
        }, 3000) as unknown as number;
      },
      (error) => {
        showNotification(`Error saving survey: ${error.message}`, 'danger');
      }
    );
  }, [showNotification, submitData]);

  const createAuditEvent = useCallback(
    async (auditType: AuditType, receivedSurvey: ReceivedSurveyInterface, userId?: string) =>
      createSurveyAuditEvent(auditType, receivedSurvey, accountId, userId),
    [createSurveyAuditEvent, accountId]
  );

  useEffect(() => {
    surveyModel.onComplete.clear();
    surveyModel.onComplete.add(surveyComplete);

    createAuditEvent('opened', survey, user?.id);

    return () => {
      createAuditEvent('closed', survey, user?.id);
    };
  }, [surveyModel, surveyComplete, survey, user, createAuditEvent]);

  const isMultipageSurvey = surveyModel.pageCount > 1;

  const handleSubmit = useCallback(() => {
    if (isMultipageSurvey) {
      if (surveyModel.validate()) {
        surveyModel.completeLastPage();
      } else {
        showNotification('Please check fields validation on all pages', 'danger');
      }
    } else {
      surveyModel.completeLastPage();
    }
  }, [surveyModel, showNotification, isMultipageSurvey]);

  const breadcrumbs = useMemo(
    () => [{ to: '../received', title: 'Surveys received' }, { title: survey.name }],
    [survey]
  );

  const { unlockContributor } = useUnlockContributor(accountId, toggleEditingModal);

  return (
    <Page>
      {editingModalShown && (
        <Modal
          span={5}
          size="xs"
          height="auto"
          maxHeight="60vh"
          onDismiss={toggleEditingModal}
          dismissable
          enableEscapeKey
          show
        >
          <Editing
            title={`Sorry! It seems that ${editingUser} is currently making edits to this survey.`}
            description={`You won't be able to make changes until ${editingUser} has completed their edits and exited the survey.`}
            onDismiss={toggleEditingModal}
            onSecondaryButtonClick={() => unlockContributor(survey.id)}
            secondaryButtonText="Unlock"
            submitButtonText="OK"
            buttonDescription="This will send your survey to your recipients"
          />
        </Modal>
      )}
      <ContainerHeader
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title={survey.name}
        breadcrumb={breadcrumbs}
      >
        <ExpandButton onClick={expand} />
      </ContainerHeader>
      <SurveyWorksheet
        model={surveyModel}
        onDismiss={collapse}
        expanded={expanded}
        survey={survey}
        isLocked={isLocked}
        editingUser={editingUser}
        saveSurvey={saveSurvey}
        newSubscriptionUpdate={newSubscriptionUpdate}
      >
        <ButtonRow>
          <SubmitButton
            onClick={handleSubmit}
            loading={loading}
            color="success"
            disabled={isDisabled(survey.submission) || isLocked}
          >
            Submit for review
          </SubmitButton>
        </ButtonRow>
      </SurveyWorksheet>
    </Page>
  );
});
