import styled from 'styled-components';
import { PreContainer } from 'rio-ui-components';

const PreContainerStyled = styled(PreContainer)`
  height: auto;
  overflow: auto;
`;

export function ServerError({ error }) {
  return <PreContainerStyled>{error}</PreContainerStyled>;
}
