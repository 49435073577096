import React, { useState } from 'react';
import styled from 'styled-components';
import { Account, AccessControls, Maybe } from '@rio/rio-types';
import { Col, Row, Button } from 'rio-ui-components';
import setImmutable from 'lodash/fp/set';
import { escape } from '../../../utils/escape';
import { SelectEvent, CheckboxEvent, Option } from '../../../types';
import { AccountDetails } from './AccountDetails';
import { useUpdateAccount } from './useUpdateAccount';
import { validateField } from './utils';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

interface UpdateAccountProps {
  account: Account;
  accessControls: AccessControls;
  businessName: string;
  reportingYearId: string;
  notes: string;
  showChatBot: boolean;
  dismiss: () => void;
  allAccounts: Account[];
}

function getChildAccountOptions(account: Account): Option[] {
  return account.children.filter(Boolean).map((child: Maybe<Account>) => ({
    label: child?.name,
    value: child?.id,
  })) as Option[];
}

export function UpdateAccount({
  account,
  allAccounts,
  accessControls,
  businessName,
  notes,
  showChatBot,
  reportingYearId,
  dismiss,
}: UpdateAccountProps) {
  const [updateAccount, { loading }] = useUpdateAccount(dismiss);
  const childAccounts = getChildAccountOptions(account);
  const [state, setState] = useState({
    values: {
      showChatBot,
      reportingYearId,
      childAccounts,
      businessName: { text: businessName, error: '' },
      notes: notes || '',
      sector: {
        id: account?.sector?.id || '',
      },
    },
    accessControls: {
      learn: accessControls.learn,
      intelligence: accessControls.intelligence,
      comply: accessControls.comply,
      data: accessControls.data,
      tasks: accessControls.tasks,
      projects: accessControls.projects,
      surveys: accessControls.surveys,
      flows: accessControls.flows,
      dataTracker: accessControls.dataTracker,
      dromoUpload: accessControls.dromoUpload,
      v2Design: accessControls.v2Design,
    },
  });
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { values, accessControls } = state;
    const error = validateField(values.businessName.text);
    if (error) {
      return setState((prevState) => ({
        ...prevState,
        values: {
          ...values,
          businessName: {
            ...values.businessName,
            error,
          },
        },
      }));
    }
    await updateAccount({
      variables: {
        accessControls,
        id: account.id,
        name: values.businessName.text,
        type: account.type,
        notes: escape(values.notes),
        showChatBot: values.showChatBot,
        children: values.childAccounts.map(({ value }: Option) => value),
        reportingYearId: values.reportingYearId,
        sectorId: values.sector.id,
      },
    });
    setState((prevState) => ({
      ...prevState,
      isSubmitted: true,
    }));
  };
  const handleChange = (e: SelectEvent) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [e.target.name]: {
          text: e.target.value,
          error: null,
        },
      },
    }));
  };
  const onCheckboxClick = (event: CheckboxEvent) => {
    setState((prevState) => ({
      ...prevState,
      accessControls: setImmutable(event.target.name, event.target.checked, prevState.accessControls),
    }));
  };
  return (
    <Container>
      <form
        name="update-account__form"
        onSubmit={(e: React.FormEvent) => {
          handleSubmit(e);
        }}
      >
        <AccountDetails
          heading="Update Account"
          handleChange={handleChange}
          businessName={state.values.businessName}
          isSubmitted={loading}
          onCheckboxClick={onCheckboxClick}
          accessControls={state.accessControls}
          allAccounts={allAccounts}
          accountId={account.id}
          selectedChildAccounts={state.values.childAccounts}
          selectedSector={state.values.sector.id}
          notes={state.values.notes}
          showChatBot={state.values.showChatBot}
          reportingYearId={state.values.reportingYearId}
          onShowChatBotChange={() => {
            setState((prevState) => ({
              ...prevState,
              values: {
                ...state.values,
                showChatBot: !state.values.showChatBot,
              },
            }));
          }}
          onNotesChange={(note: string) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                notes: note,
              },
            }))
          }
          onSectorChange={(sector: { value: string; label: string }) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                sector: {
                  id: sector.value,
                },
              },
            }))
          }
          onChildAccountsChange={(accounts: Option[]) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                childAccounts: accounts,
              },
            }))
          }
          onReportingYearChange={(yearId: string) => {
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                reportingYearId: yearId,
              },
            }));
          }}
        />
        <Row container align="between">
          <ColStyled item container>
            <Button disabled={loading} name="update-account__button--cancel" color="info" onClick={dismiss}>
              Cancel
            </Button>
          </ColStyled>
          <ColStyled item container>
            <Button
              name="update-account__button--save"
              color="success"
              submit
              disabled={loading || !state.values.businessName.text}
            >
              Save
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
}
