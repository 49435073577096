import { ExportProcess, Export } from './types';

export const updateProcess = (id: string, iteratee: object) => (exports: ExportProcess[]) =>
  exports.map((e) => (e.id === id ? { ...e, ...iteratee } : e));

export const mapProcess = (process: ExportProcess): Export => {
  const processObject = {
    ...process,
    percents: 0,
  };

  if (process?.offset && process?.totalRows && process.offset !== 0) {
    const percent = Math.ceil((process?.offset / process?.totalRows) * 100);
    processObject.percents = percent;
  }

  return processObject;
};
