import React from 'react';
import { Pagination } from 'rio-ui-components';

const PaginationContainer = ({
  id,
  name,
  inline = true,
  totalPagesCount,
  onChange,
  currentPage,
  itemsCountPerPage = 5,
  size = 'md'
}) => {
  return (
    <Pagination
      id={id}
      name={name}
      inline={inline}
      activePage={currentPage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalPagesCount}
      onChange={onChange}
      size={size}
    />
  );
};

export default PaginationContainer;
