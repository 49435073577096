import moment from 'moment';
import { useMemo } from 'react';
import { ReportProgress, ReportSubProgressBreakdownItem, ManagementSystem } from '@rio/rio-types';
import styled from 'styled-components';
import { Col, Row, Heading } from 'rio-ui-components';
import { ProgressChartRow } from '../ProgressChart';
import { useGetReportProgressChapterBreakdown } from './useGetReportProgressChapterBreakdown';
import { useGetReportProgressGroupBreakdown } from './useGetReportProgressGroupBreakdown';
import { formatChaptersProgressBreakdown, formatParentChapterProgressBreakdown } from './utils';
import { ManagementSystemPage } from '../../types';
import { getPathFromType } from '../../utils/managementSystemUtils';

interface CarbonDisclosureProgressContentProps {
  reportProgress: ReportProgress;
  managementSystem: ManagementSystem;
  deadline?: string | Date;
  withHyperlinks?: boolean;
}

const Note = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const StyledHeading = styled(Heading)`
  margin-top: 20px;
`;

function ReportProgressChart({
  reportProgress,
  deadline,
  withHyperlinks,
  managementSystem
}: CarbonDisclosureProgressContentProps) {
  const todayMoment = moment().startOf('day');
  const deadlineMoment = deadline ? moment(deadline) : null;
  const daysDiff = deadlineMoment ? moment.duration(deadlineMoment.diff(todayMoment)).asDays() : null;
  return (
    <Col container>
      {reportProgress.subProgressBreakdown.map((item: ReportSubProgressBreakdownItem) => (
        <ProgressChartRow
          key={`ProgressChartRow__${item.id}`}
          completed={item.progress}
          name={item.name}
          link={withHyperlinks ? `${getPathFromType(managementSystem.type)}/${managementSystem.id}/${item.id}` : '/'}
        />
      ))}
      {deadlineMoment && (
        <Note>
          Deadline for completion: {deadlineMoment.format('LL')} (
          {deadlineMoment.isBefore(todayMoment) ? 'Days until deadline' : 'Days since overdue'} - {daysDiff})
        </Note>
      )}
    </Col>
  );
}

interface ReportProgressByChaptersProps {
  managementSystem: ManagementSystem;
}

export function ReportProgressByChapters({ managementSystem }: ReportProgressByChaptersProps) {
  const { data, loading } = useGetReportProgressChapterBreakdown(managementSystem.id);
  const reportProgress = useMemo(
    () => formatChaptersProgressBreakdown(data?.getReportProgressChapterBreakdown, managementSystem),
    [managementSystem, data?.getReportProgressChapterBreakdown]
  );
  if (loading || !reportProgress) {
    return <>Loading...</>;
  }
  return <ReportProgressChart reportProgress={reportProgress} managementSystem={managementSystem} withHyperlinks />;
}

interface ReportProgressByGroupProps {
  managementSystem: ManagementSystem;
  page: ManagementSystemPage;
  progressMainPage: string;
}

export function ReportProgressByGroup({ managementSystem, page, progressMainPage }: ReportProgressByGroupProps) {
  const { data, loading } = useGetReportProgressGroupBreakdown(
    managementSystem.id,
    page?.id === progressMainPage ? null : page?.id
  );

  const parentChapterBreakdown = useMemo(() => {
    const pcb = formatParentChapterProgressBreakdown(data?.getReportProgressGroupBreakdown, managementSystem);

    return (
      <>
        {pcb?.map((reportProgressBreakdown, key) => (
          <div key={key}>
            {reportProgressBreakdown.title && <StyledHeading size="lg">{reportProgressBreakdown.title}</StyledHeading>}
            <ReportProgressChart
              reportProgress={reportProgressBreakdown.progressBreakdown}
              managementSystem={managementSystem}
              withHyperlinks
            />
          </div>
        ))}
      </>
    );
  }, [managementSystem, data?.getReportProgressGroupBreakdown]);
  if (loading || !parentChapterBreakdown) {
    return <>Loading...</>;
  }

  return parentChapterBreakdown;
}
