import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { NavigationDrawer } from '@rio/ui-components';
import { styled } from '@mui/material-next/styles';

const Container = styled('div')`
  width: 100%;
  max-width: 260px;
  height: max-content;
  position: sticky;
  top: 0;
`;

type NavigationSidbarProps = {
  activeAccessControls: string[];
  inactiveAccessControls: string[];
};

export const NavigationSidbar = ({ activeAccessControls, inactiveAccessControls }: NavigationSidbarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const generateLinkForItem = (item: string) => {
    const path = item === 'overview' ? './' : item === 'fundsAssets' ? `./investment/view` : `./${item}/view`;

    return {
      to: path,
      item: <FormattedMessage id={`pages.data.subjects.${item}`} />,
      isActive: pathname.includes(path) || item === 'overview',
    };
  };

  const activeAccessControlsItems = activeAccessControls.map((el) => generateLinkForItem(el));
  const inactiveAccessControlsItems = inactiveAccessControls.map((el) => generateLinkForItem(el));
  const { formatMessage } = useIntl();

  return (
    <Container>
      <NavigationDrawer items={[generateLinkForItem('overview')]} />
      <NavigationDrawer title={formatMessage({ id: 'Organisation' })} items={activeAccessControlsItems} />

      {!!inactiveAccessControls.length && (
        <NavigationDrawer title={formatMessage({ id: 'Portfolio' })} items={inactiveAccessControlsItems} />
      )}
    </Container>
  );
};
