import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetCurrentUserQuery, useNotification } from '~/hooks';
import { UPLOAD_PROFILE_PICTURE } from '../index.queries';
import { Page, Text, styled, Modal, Icons, ImageEditor } from '@rio/ui-components';
import { ProfilePicture } from 'rio-ui-components';
import { EditUserDetails } from './EditUserDetails';
import { getEnvVar } from '../../../env';

const PageContentContainer = styled('div')`
  display: flex;
  gap: 72px;
`;

const UserDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 300px;
`;

const UserDetailItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserDetailText = styled(Text)`
  text-align: center;
`;

const EditUserDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 600px;
`;

export const ProfileContainer = () => {
  const { showNotification } = useNotification();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE, {
    onCompleted: () => {
      showNotification('Profile picture has been updated', 'success');
      setShowUploadModal(false);
    },
    onError: (e) => {
      showNotification(`Something went wrong (${e.message}). Please try again later.`, 'danger');
      setShowUploadModal(false);
    },
  });
  const { data, loading } = useGetCurrentUserQuery();

  const handleUpload = async (image: string) => {
    await uploadProfilePicture({
      variables: {
        ...data,
        account_id: data.account.id,
        previousEmail: data.email,
        imageData: image,
        contentType: 'image/png',
        fileName: `${data.id}.png`,
        profile: `${getEnvVar('REACT_APP_PROFILE_BUCKET')}/${data.id}.png?time=${new Date().toISOString()}`,
      },
    });
  };

  return (
    <Page
      loading={loading}
      title={{
        content: 'My profile',
        crumbs: [{ label: 'My profile' }],
      }}
    >
      {showUploadModal && (
        <Modal open onClose={() => setShowUploadModal(false)} title="Edit Profile Picture">
          <ImageEditor defaultImageSrc={data.profilePicture} onSave={handleUpload} shouldResetAfterSuccess />
        </Modal>
      )}
      <PageContentContainer>
        <UserDetailsContainer>
          <ProfilePicture
            border
            name={`profile-picture`}
            src={data.profilePicture}
            size="xl"
            accountName={data.first_name}
          />
          <UserDetailItem as="button" sx={{ cursor: 'pointer' }} onClick={() => setShowUploadModal(true)}>
            <Icons.EditRounded />
            <Text typescale="title" size="medium">
              <FormattedMessage id="profile.editProfilePicture" />
            </Text>
          </UserDetailItem>
          <UserDetailItem>
            <Icons.AssignmentIndRounded />
            <UserDetailText typescale="title" size="medium">
              {`${data.first_name} ${data.last_name}`}
            </UserDetailText>
          </UserDetailItem>
          <UserDetailItem>
            <Icons.ManageAccountsRounded />
            <UserDetailText typescale="title" size="medium">
              {data.role.name}
            </UserDetailText>
          </UserDetailItem>
          <UserDetailItem>
            <Icons.ApartmentRounded />
            <UserDetailText typescale="title" size="medium">
              {data.account.name}
            </UserDetailText>
          </UserDetailItem>
        </UserDetailsContainer>
        {data && (
          <EditUserDetailsContainer>
            <EditUserDetails user={data} />
          </EditUserDetailsContainer>
        )}
      </PageContentContainer>
    </Page>
  );
};
