import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Search, Row } from 'rio-ui-components';

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const SearchBar = ({ searchValue, onSearch, intl }) => {
  return (
    <SearchHeader container item itemAlign="center">
      <SearchStyled 
        name="Courses__Search" 
        value={searchValue} 
        onChange={onSearch} 
        hideButton 
        placeholder={intl.formatMessage({ id: 'home.search' })} 
      />
    </SearchHeader>
  );
};

export default injectIntl(SearchBar);
