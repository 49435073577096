import { DromoRecord } from '../types';
import { corruptRecords } from './utils';

type ValidatorFN = (records: DromoRecord[]) => void;

export const bulkRowHooksBuilder = (...validators: ValidatorFN[]) => {
  return validators.map((validator) => {
    return (records) => {
      try {
        return validator(records);
      } catch (err) {
        return corruptRecords(records);
      }
    };
  });
};
