import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Col, Row, Button, Notification } from 'rio-ui-components';
import { Mutation } from '@apollo/client/react/components';

import { CREATE_DASHBOARD } from './index.queries';
import { DashboardDetails } from './DashboardDetails';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

class CreateDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isSuccess: false,
      values: {
        dashboardName: { text: '', error: '' },
        description: { text: '', error: '' },
        area: { text: '', error: '' },
        thumbnail: { text: '', error: '' },
        providerId: { text: '', error: '' }
      },
      notification: { message: '', color: 'success', id: null },
      isSubmitted: false
    };
  }

  validateField = (value) => (value === '' ? 'Field cannot be empty' : null);
  hideNotification = () => this.setState({ notification: { message: null, color: null } });
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'select' ? target.select.value : target.value;
    this.setState({ values: { ...this.state.values, [e.target.name]: { text: value, error: null } } });
  };

  handleSubmit = (e, callback) => {
    e.preventDefault();

    const v = this.state.values;
    v.dashboardName.error = this.validateField(v.dashboardName.text);
    v.description.error = this.validateField(v.description.text);
    v.area.error = this.validateField(v.area.text);
    v.thumbnail.error = this.validateField(v.thumbnail.text);
    v.providerId.error = this.validateField(v.providerId.text);

    if (v.dashboardName.error || v.description.error || v.area.error || v.providerId.error || v.thumbnail.error) {
      this.setState({ values: v });
      return;
    }

    callback({
      variables: {
        id: uuid(),
        name: this.state.values.dashboardName.text,
        description: this.state.values.description.text,
        area: this.state.values.area.text,
        thumbnail: this.state.values.thumbnail.text,
        provider: 'QUICKSIGHT',
        providerId: this.state.values.providerId.text
      }
    });

    this.setState({
      isSubmitted: true
    });
  };

  onCreateComplete = () =>
    this.props.onSuccess(`The dashboard ${this.state.values.dashboardName.text}, has been created!`, 'success');

  onCreateError = () => {
    this.setState({
      notification: { message: `Something went wrong. If the problem persists, contact support.`, color: 'danger' },
      hasError: true,
      isSuccess: false,
      isSubmitted: false
    });
  };

  onCheckboxClick = (data) => {
    this.setState({
      accessControls: {
        ...this.state.accessControls,
        [data.target.name]: data.target.checked
      }
    });
  };

  render() {
    return (
      <Container name="create-dashboard">
        {this.state.notification.message && (
          <Notification
            name="create-dashboard__notification"
            show
            color={this.state.notification.color}
            onClose={this.hideNotification}
          >
            {this.state.notification.message}
            {this.state.notification.id && (
              <p name="create-dashboard__id" style={{ display: 'none' }}>
                {this.state.notification.id}
              </p>
            )}
          </Notification>
        )}

        <Mutation mutation={CREATE_DASHBOARD} onCompleted={this.onCreateComplete} onError={this.onCreateError}>
          {(createDashboard) => (
            <form
              name="create-dashboard__form"
              onSubmit={(e) => {
                this.handleSubmit(e, createDashboard);
              }}
            >
              <DashboardDetails
                handleChange={this.handleChange}
                values={this.state.values}
                isSubmitted={this.state.isSubmitted}
                heading={'Create Dashboard'}
              />
              <Row container align="between">
                <ColStyled item>
                  <Button
                    name="create-dashboard__button--create"
                    color="success"
                    submit
                    disabled={!this.state.values.dashboardName.text || this.state.isSubmitted}
                  >
                    Create Dashboard
                  </Button>
                </ColStyled>
              </Row>
            </form>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default CreateDashboard;
