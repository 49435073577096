import styled from 'styled-components';
import { Col } from 'rio-ui-components';

type ChartsProps = {
  v2?: boolean;
};

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;
export const Heading2 = styled.h2`
  margin: 10px 0;
  font-size: 2em;
  color: #333;
`;
export const Heading3 = styled.h3`
  margin: 20px 0;
  font-size: 1.2em;
  color: #555;
`;
export const Heading3Column = styled(Heading3)`
  flex: 0 0 50%;
`;
export const Heading4 = styled.h4`
  font-size: 0.93em;
  color: #666;
`;
export const Charts = styled('div')<ChartsProps>`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  font-family: ${(props) => props.v2 && 'Plus Jakarta Sans !important'};
`;
export const TopCharts = styled(Charts)`
  display: flex;
  flex-flow: nowrap;
`;
export const Chart = styled.div`
  ${({ theme }) => `
    padding: ${theme.geometry.xs.spacing} ${theme.geometry.md.spacing};
    flex: 1;
    border: 1px solid #E8E9EC;
    overflow-x: hidden;
  `};
`;
export const LeftChart = styled(Chart)`
  flex: 0 1 50%;
`;
export const RightChart = styled(Chart)`
  display: flex;
  flex: 0 1 50%;
  border-left: 0;
`;
export const BottomChart = styled(Chart)`
  display: flex;
  padding: 32px 20px 20px;
  border-top: 0;
`;
export const BottomChartHeading = styled(Heading3)`
  margin: 0 auto;
  min-width: 210px;
`;
export const Row = styled.div`
  width: 100%;
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #c7c5d0;
  background: #fff;
  height: calc(100% - 70px);
`;

export const Image = styled('img')`
  height: 152px;
  width: 152px;
`;
