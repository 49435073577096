import { useCallback, useEffect, useState, useMemo } from 'react';
import { Survey } from '@rio/rio-types';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@rio/ui-components';
import { useCurrentAccountId } from '~/hooks';
import { useGet } from '../queries';
import { EmptySurveys } from '../SurveyOverviewContainer/SurveyOverviewV2';
import { PageContainer, WrapperStyled } from './styles';
import { useDebounce } from 'use-debounce';
import { throttle } from 'lodash';
import { SurveysVirtualList } from './SurveysVirtualListV2';

export function SurveysContainer() {
  const pageSize = 20;
  const accountId = useCurrentAccountId();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 600);

  const variables = useMemo(
    () => ({
      accountId,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      filters: {
        search: {
          field: ['name'],
          value: debouncedSearchValue,
        },
      },
    }),
    [accountId, currentPage, debouncedSearchValue]
  );

  const result = useGet(variables);
  const navigate = useNavigate();
  const handleSurveyClick = useCallback((id: string) => navigate(`./${id}`), [navigate]);

  // checks and changes total pages each time query succeeds
  useEffect(() => {
    if (result?.data?.getSurveysPage?.totalRows && !result.loading) {
      setTotalPages(Math.ceil(result.data.getSurveysPage.totalRows / pageSize));
      setSurveys((prevSurveys) => [...prevSurveys, ...(result?.data?.getSurveysPage?.rows || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.loading]);

  useEffect(() => {
    if (result?.data?.getSurveysPage && !result.loading && debouncedSearchValue) {
      setTotalPages(Math.ceil(result.data.getSurveysPage.totalRows / pageSize));
      setSurveys(result?.data?.getSurveysPage?.rows || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.loading, debouncedSearchValue]);

  useEffect(() => {
    setSurveys([]);
    setCurrentPage(1);
  }, [debouncedSearchValue]);

  const incrementCurrentPageThrottled = useMemo(
    () =>
      throttle(
        () => {
          if (!result.loading) {
            setCurrentPage((state) => state + 1);
          }
        },
        400,
        { trailing: false }
      ),
    [result.loading]
  );

  const handleScrollEnd = useMemo(
    () =>
      throttle(
        () => {
          const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

          if (!result.loading && totalPages > currentPage && isAtBottom) {
            incrementCurrentPageThrottled();
          }
        },
        400,
        { leading: false }
      ),
    [totalPages, currentPage, result.loading, incrementCurrentPageThrottled]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEnd);
    return () => {
      handleScrollEnd.cancel();
      window.removeEventListener('scroll', handleScrollEnd);
    };
  }, [handleScrollEnd]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const emptySurveys = result.loading ? null : <EmptySurveys />;

  return (
    <WrapperStyled>
      <PageContainer>
        <TextField
          name="ConfigurationAccountsContainer__Controls__Search"
          type="search"
          value={searchValue}
          onChange={handleSearchChange}
          error={false}
          placeholder="Search"
        />
      </PageContainer>
      {surveys.length ? <SurveysVirtualList surveys={surveys} handleSurveyClick={handleSurveyClick} /> : emptySurveys}
    </WrapperStyled>
  );
}
