import { useCallback, useState } from 'react';
import { Grid, Button, styled } from '@rio/ui-components';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { SurveySubmission, SurveySubmissionStatus } from '@rio/rio-types';
import { SendFeedbackModal } from './SendFeedbackModal';

type SendFeedbackFunction = (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;

interface SendFeedbackPanelProps {
  submission: SurveySubmission;
  sendFeedback: SendFeedbackFunction;
}

const SectionButtons = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 24px;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

export function SendFeedbackButtons({ submission, sendFeedback }: SendFeedbackPanelProps) {
  const [approveModalShown, setApproveModalShown] = useState(false);
  const [requestChangesModalShown, setRequestChangesModalShown] = useState(false);

  const toggleRequestChangesModal = useCallback(
    () => setRequestChangesModalShown((shown) => !shown),
    [setRequestChangesModalShown]
  );

  const toggleApproveModal = useCallback(() => setApproveModalShown((shown) => !shown), []);

  return (
    <>
      <SectionButtons container>
        {submission.status !== SurveySubmissionStatus.RequiresChanges && (
          <Grid xs={12} md={4} lg={3}>
            <ButtonStyled variant="filled" onClick={toggleRequestChangesModal}>
              Request changes
            </ButtonStyled>
          </Grid>
        )}
        {submission.status !== SurveySubmissionStatus.Approved && (
          <Grid xs={12} md={4} lg={3}>
            <ButtonStyled variant="filled" onClick={toggleApproveModal}>
              Approve
            </ButtonStyled>
          </Grid>
        )}
      </SectionButtons>

      {requestChangesModalShown && (
        <SendFeedbackModal
          intention={SurveySubmissionFeedbackStatus.RequiresChanges}
          onDismiss={toggleRequestChangesModal}
          onFeedback={sendFeedback}
        />
      )}
      {approveModalShown && (
        <SendFeedbackModal
          intention={SurveySubmissionFeedbackStatus.Approved}
          onDismiss={toggleApproveModal}
          onFeedback={sendFeedback}
        />
      )}
    </>
  );
}
