export const NEW = 'NEW';
export const ACTIVATED = 'ACTIVATED';
export const EXPIRED = 'EXPIRED';
export const BLOCKED = 'BLOCKED';

export const licenceStatus = {
  NEW,
  ACTIVATED,
  EXPIRED,
  BLOCKED
};
