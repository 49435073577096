import { frequencyOptions, priorityOptions } from '../constants';
import moment from 'moment';
import { ProjectInput } from '@rio/rio-types';

enum NumberFields {
  cost = 'cost',
  plannedBenefit = 'plannedBenefit',
  plannedCO2eChange = 'plannedCO2eChange',
  plannedCostChange = 'plannedCostChange',
}

enum RequiredFields {
  name = 'name',
  priority = 'priority',
  baselineStartDate = 'baselineStartDate',
  startDate = 'startDate',
  endDate = 'endDate',
  plannedBenefit = 'plannedBenefit',
  frequency = 'frequency',
  schemeId = 'schemeId',
}

type NumberFieldsValidateErrors = {
  [name in NumberFields]?: string;
};

type RequiredFieldsValidateErrors = {
  [name in RequiredFields]?: string;
};

export type ValidateErrors = {
  owners?: string;
} & RequiredFieldsValidateErrors &
  NumberFieldsValidateErrors;

export const ValidateProject = (project: ProjectInput): ValidateErrors => {
  const errors: ValidateErrors = {};

  for (const key of Object.values(NumberFields)) {
    if (project[key] && !isFinite(Number(project[key]))) {
      errors[key] = 'Value have to be a number';
    }
  }

  for (const key of Object.values(RequiredFields)) {
    !project[key] && (errors[key] = `Value required`);
  }

  if (!errors.priority && !priorityOptions.find((priority) => priority.id === project.priority)) {
    const knownValues = priorityOptions.map(({ value }) => value).join(', ');
    errors.priority = `Should be one of known properties: ${knownValues}`;
  }
  if (!errors.frequency && !frequencyOptions.find((freq: any) => freq.value === project.frequency)) {
    const knownValues = frequencyOptions.map(({ value }) => value).join(', ');
    errors.frequency = `Should be one of known properties: ${knownValues}`;
  }
  // project start date have to be after
  // baseline year end date (baseline start date + 1 year)
  const isStartDateValid =
    moment(project.startDate).isValid() &&
    moment(project.baselineStartDate).isValid() &&
    moment(project.startDate).isAfter(moment(project.baselineStartDate).add(1, 'year'));
  if (!errors.startDate && !errors.baselineStartDate && !isStartDateValid) {
    errors.startDate = 'Have to be after baseline year end date (baseline start date + 1 year)';
  }
  const isEndDateValid =
    moment(project.startDate).isValid() &&
    moment(project.endDate).isValid() &&
    moment(project.startDate).isBefore(moment(project.endDate));
  if (!errors.startDate && !errors.endDate && !isEndDateValid) {
    errors.endDate = 'Have to be after start date';
  }

  return errors;
};
