import { useCallback } from 'react';
import { TextLink } from 'rio-ui-components';
import { useMutation } from '@apollo/client';
import { useCurrentAccountId, useNotification } from '../../hooks';
import styled from 'styled-components';
import {
  AttachmentQuestion as IAttachmentQuestion,
  DocumentRecord,
  DocumentLibrary,
  ManagementSystem,
  QuestionUnion
} from '@rio/rio-types';
import { FileUpload } from '../FileUpload';
import { ATTACH_DOCUMENT } from './index.queries';
import { formatManagementSystemCode } from './utils';

const Link = styled(TextLink)`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface AttachmentQuestionProps {
  question: IAttachmentQuestion;
  parent?: QuestionUnion;
  onAnswer: (updatedQuestion: IAttachmentQuestion) => void;
  framework: ManagementSystem;
}

export function AttachmentQuestion({ question, framework, parent }: AttachmentQuestionProps) {
  const { showNotification } = useNotification();
  const [attachDocument] = useMutation(ATTACH_DOCUMENT, {
    onCompleted: () => {
      showNotification('Document has been uploaded!');
    }
  });
  const accountId = useCurrentAccountId();
  const handleUpload = useCallback(
    (document: DocumentRecord) => {
      attachDocument({
        variables: {
          accountId,
          id: document.id,
          systemId: question.reportingFrameworkId,
          linkId: question.reportingFrameworkChapterId
        }
      });
    },
    [attachDocument, accountId, question]
  );
  return (
    <FileUpload
      onUpload={handleUpload}
      predefinedValues={{
        category: 'Reporting Frameworks',
        library: DocumentLibrary.Governance,
        managementSystemChapterId: question.reportingFrameworkChapterId!,
        managementSystemCode: formatManagementSystemCode(framework, question, parent),
        managementSystemId: framework.id
      }}
    >
      {(selectFiles: () => void) => <Link onClick={selectFiles}>{question.formulation}</Link>}
    </FileUpload>
  );
}
