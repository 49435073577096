import { useQuery } from '@apollo/client';
import { Select, OptionsProps } from '@rio/ui-components';
import { MarketCarbonConversionFactor } from '@rio/rio-types';
import { GET_TARIFFS_BY_ACCOUNT_ID } from './index.queries';

interface TariffSelectProps {
  value?: string;
  accountId: string;
  onChange: (e: OptionsProps) => void;
  label?: string;
  valueAsLabel?: boolean;
}
export const TariffSelectV2 = ({ value, onChange, label, accountId, valueAsLabel }: TariffSelectProps) => {
  const { data, loading, error } = useQuery(GET_TARIFFS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
  });

  const options =
    data?.getMarketCarbonConversionFactors?.map((f: MarketCarbonConversionFactor) => ({
      label: f.tariff,
      value: valueAsLabel ? f.tariff : f.id,
    })) || [];

  return (
    <Select
      placeholder="Please select a tariff"
      value={value}
      label={label}
      options={options}
      error={!!error}
      isLoading={loading}
      helperText={error?.message ?? ''}
      onChange={(e) => onChange(e)}
    />
  );
};
