import React from 'react';
import { useTheme } from 'styled-components';
import { Icon } from 'rio-ui-components';
import { ProjectPriority } from '@rio/rio-types';
import { Theme } from '../../../types';

type IconOptionItem = {
  icon: string;
  color: string;
};

type IconOptions = {
  [name in ProjectPriority]: IconOptionItem;
};

const getPriorityIconProps = (
  priority: ProjectPriority | undefined,
  theme: Theme
): IconOptionItem | Record<string, unknown> => {
  const options: IconOptions = {
    HIGH: {
      icon: 'exclamation-triangle',
      color: theme.colors.danger.normal.background
    },
    MEDIUM: {
      icon: 'exclamation-circle',
      color: theme.colors.warning.normal.background
    },
    LOW: {
      icon: 'exclamation-circle',
      color: theme.colors.text.normal.background
    }
  };

  return (priority && options[priority]) || {};
};

type PriorityCellProps = {
  data: {
    priority: ProjectPriority;
  };
};

export function PriorityCell(props: PriorityCellProps) {
  const { data: { priority } = {} } = props;

  const theme = useTheme();

  return <Icon name={`project-row__priority`} size="lg" {...getPriorityIconProps(priority, theme)} />;
}
