import { gql } from '@apollo/client';

export const GET_ALL_LEGISLATION = gql`
  query GetAllLegislation($accountId: ID!, $tagId: ID) {
    getAllLegislation(accountId: $accountId, tagId: $tagId) {
      id
      title
      synopsis
      isActive
      countries
      type
      status
      reviewDate
      lastFlaggedForReview
      lastClearedReviewFlag
      applicability
      tags {
        id
        tagName
      }
      locations {
        id
        name
      }
      regions {
        id
        name
      }
      departmentIds
    }
  }
`;
