import React from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Notification } from 'rio-ui-components';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';

import { UPDATE_DASHBOARD, GET_DASHBOARD } from './index.queries';
import { DashboardDetails } from './DashboardDetails';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

class UpdateDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isSuccess: false,
      loading: true,
      values: {
        dashboardName: { text: '', error: '' },
        description: { text: '', error: '' },
        provider: { text: '', error: '' },
        area: { text: '', error: '' },
        thumbnail: { text: '', error: '' },
        providerId: { text: '', error: '' }
      },
      notification: { message: '', color: 'success', id: null },
      isSubmitted: false
    };
  }

  async componentDidMount() {
    const result = await this.props.client.query({
      query: GET_DASHBOARD,
      variables: {
        id: this.props.id
      }
    });

    this.setState({
      loading: false,
      values: {
        dashboardName: { text: result.data.getDashboard.name, error: '' },
        description: { text: result.data.getDashboard.description, error: '' },
        provider: { text: result.data.getDashboard.provider, error: '' },
        area: { text: result.data.getDashboard.area, error: '' },
        thumbnail: { text: result.data.getDashboard.thumbnailType, error: '' },
        providerId: { text: result.data.getDashboard.providerDashboardId, error: '' }
      }
    });
  }

  validateField = (value) => (value === '' ? 'Field cannot be empty' : null);
  hideNotification = () => this.setState({ notification: { message: null, color: null } });
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'select' ? target.select.value : target.value;
    this.setState({ values: { ...this.state.values, [e.target.name]: { text: value, error: null } } });
  };

  handleSubmit = (e, callback) => {
    e.preventDefault();

    const v = this.state.values;
    v.dashboardName.error = this.validateField(v.dashboardName.text);
    v.description.error = this.validateField(v.description.text);
    v.provider.error = this.validateField(v.provider.text);
    v.area.error = this.validateField(v.area.text);
    v.thumbnail.error = this.validateField(v.thumbnail.text);
    v.providerId.error = this.validateField(v.providerId.text);

    if (
      v.dashboardName.error ||
      v.description.error ||
      v.provider.error ||
      v.area.error ||
      v.providerId.error ||
      v.thumbnail.error
    ) {
      this.setState({ values: v });
      return;
    }

    callback({
      variables: {
        id: this.props.id,
        name: this.state.values.dashboardName.text,
        description: this.state.values.description.text,
        area: this.state.values.area.text,
        thumbnail: this.state.values.thumbnail.text,
        provider: this.state.values.provider.text,
        providerId: this.state.values.providerId.text
      }
    });

    this.setState({
      isSubmitted: true
    });
  };

  onUpdateComplete = () =>
    this.props.onSuccess(`The dashboard ${this.state.values.dashboardName.text}, has been updated!`, 'success');

  onUpdateError = () => {
    this.setState({
      notification: { message: `Something went wrong. If the problem persists, contact support.`, color: 'danger' },
      hasError: true,
      isSuccess: false,
      isSubmitted: false
    });
  };

  onCheckboxClick = (data) => {
    this.setState({
      accessControls: {
        ...this.state.accessControls,
        [data.target.name]: data.target.checked
      }
    });
  };

  render() {
    return (
      <>
        {this.state.loading && <ContainerLoadingIndicator name="ConfigurationDashboardsContainer__Loading" />}
        {!this.state.loading && (
          <Container name="update-dashboard">
            {this.state.notification.message && (
              <Notification
                name="update-dashboard__notification"
                show
                color={this.state.notification.color}
                onClose={this.hideNotification}
              >
                {this.state.notification.message}
                {this.state.notification.id && (
                  <p name="update-dashboard__id" style={{ display: 'none' }}>
                    {this.state.notification.id}
                  </p>
                )}
              </Notification>
            )}

            <Mutation mutation={UPDATE_DASHBOARD} onCompleted={this.onUpdateComplete} onError={this.onUpdateError}>
              {(updateDashboard) => (
                <form
                  name="update-dashboard__form"
                  onSubmit={(e) => {
                    this.handleSubmit(e, updateDashboard);
                  }}
                >
                  <DashboardDetails
                    handleChange={this.handleChange}
                    values={this.state.values}
                    isSubmitted={this.state.isSubmitted}
                    heading={'Update Dashboard'}
                  />
                  <Row container align="between">
                    <ColStyled item>
                      <Button
                        name="update-dashboard__button--update"
                        color="success"
                        submit
                        disabled={!this.state.values.dashboardName.text || this.state.isSubmitted}
                      >
                        Update Dashboard
                      </Button>
                    </ColStyled>
                  </Row>
                </form>
              )}
            </Mutation>
          </Container>
        )}
      </>
    );
  }
}

export default withApollo(UpdateDashboard);
