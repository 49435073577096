import styled from 'styled-components';
import {
  LoadingIndicator,
  Row,
  Col,
  DatePicker as DatePickerComponent,
  TimePicker as TimePickerComponent,
  Heading,
  Label,
  Select
} from 'rio-ui-components';

export const Container = styled.div`
  min-height: 550px;
`;

export const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

export const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

export const CenteredCol = styled(Col)`
  margin: 8px 0;
  padding: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  & small {
    font-size: 14px;
  }
`;

export const LabelStyled = styled(Label)`
  padding-left: 20px;
`;

export const DescriptionStyled = styled(LabelStyled)`
  font-size: 12px;
`;

export const LabelContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelDescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.geometry.md.spacing};
`;

export const SelectStyled = styled(Select)`
  text-transform: capitalize;
`;

export const DatePicker = styled(DatePickerComponent)`
  .DayPickerInput-Overlay {
    top: -150px;
    left: 50%;
  }
`;

export const TimePicker = styled(TimePickerComponent)`
  .DayPickerInput-Overlay {
    top: -150px;
    left: 50%;
  }
`;

export const SubHeader = styled.div`
  text-align: left;
  margin: 18px 0;
`;

export const CenteredErrorText = styled.div`
  text-align: center;
  color: #ff909a;
`;

export const Loader = styled(LoadingIndicator)`
  margin: 0 auto;
`;

export const SectionStyled = styled.div`
  display: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 'block' : 'none')};
`;

export const RowWithPadding = styled(Row)`
  padding: 18px 0;
`;
