import { useState, useCallback, useMemo } from 'react';
import { find } from 'lodash';
import { useMutation } from '@apollo/client';
import { Select, MultiSelect } from '@rio/ui-components';
import { useNotification } from '~/hooks';
import DELETE_DEPARTMENT from '../graphql/mutations/account/DeleteDepartment.mutation.graphql';
import { useUserToken } from './useUserToken';
import { usePermissions } from './usePermissions';
import { useCurrentAccountId } from './useCurrentAccountId';
import { useUpdateCacheAccount } from './useUpdateCacheAccount';

type SelectOption = {
  value: string;
  label: string;
};

type DepartmentOption = {
  id?: string;
  value: string;
  label: string;
};

export const getDepartments = (departments: SelectOption[], departmentIds: string[]): SelectOption[] | null => {
  if (!departments || !departmentIds) return null;

  const listDepartments: SelectOption[] = [];
  departments.forEach((item) => {
    if (departmentIds.includes(item.value)) {
      listDepartments.push(item);
    }
  });
  return listDepartments;
};

export const useCreateDepartment = (
  departments: SelectOption[],
  currentDepartment?: SelectOption[],
  passedAccountId?: string
) => {
  const [showCreateDepartment, setShowCreateDepartment] = useState(false);
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const { token } = useUserToken();
  const updateCacheAccount = useUpdateCacheAccount();
  const currentAccountId = useCurrentAccountId();
  const accountId = passedAccountId || currentAccountId;
  const userId = token ? token.sub : null;
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT);

  const canCreateDepartment = permissions.account.some((action) => action.startsWith('createDepartment'));
  const canDeleteDepartment = permissions.account.some((action) => action.startsWith('deleteDepartment'));

  const listDepartments: DepartmentOption[] = useMemo(() => {
    const processedDepartments = [...departments];

    if (currentDepartment && !Array.isArray(currentDepartment) && find(departments, { id: currentDepartment })) {
      processedDepartments.unshift({ value: 'notSelected', label: 'Not selected' });
    }
    if (canCreateDepartment) {
      processedDepartments.unshift({ value: 'NEW', label: 'Create New ...' });
    }

    return processedDepartments;
  }, [departments, currentDepartment, canCreateDepartment]);

  const currentDepartmentValue = useMemo(
    () => (Array.isArray(currentDepartment) ? currentDepartment || [] : currentDepartment),
    [currentDepartment]
  );

  const onDelete = useCallback(
    (departmentId: string) => {
      deleteDepartment({
        variables: { accountId, departmentId },
        update(cache, { data: { deleteDepartment } }) {
          updateCacheAccount(cache, deleteDepartment, accountId, userId);
        },
      });
    },
    [accountId, deleteDepartment, updateCacheAccount, userId]
  );

  const handleClearOption = useCallback(
    (value) => {
      if (!canDeleteDepartment && value !== 'notSelected') {
        return showNotification(
          'You are not permitted to delete this option, please contact your business admin.',
          'warning'
        );
      }
      onDelete(value);
    },
    [canDeleteDepartment, onDelete, showNotification]
  );

  const onSelectChange = useCallback((event, onCreate, onChange) => {
    const createNew = event?.value === 'NEW';

    if (createNew) {
      onCreate();
    } else {
      onChange(event);
    }
  }, []);

  const handleSelectChange = useCallback(
    (event, handleChange) => {
      onSelectChange(
        event,
        () => setShowCreateDepartment(true),
        (event) =>
          handleChange({
            target: {
              name: 'department',
              value: event.target.value,
            },
          })
      );
    },
    [onSelectChange]
  );

  const departmentSelect = useCallback(
    (handleChange) => (
      <Select
        options={listDepartments}
        value={currentDepartmentValue ? find(listDepartments, { value: currentDepartmentValue }) : null}
        onChange={(e) => handleSelectChange(e, handleChange)}
        placeholder="Select..."
      />
    ),
    [handleSelectChange, listDepartments, currentDepartmentValue]
  );

  const departmentMultipleSelect = useCallback(
    (handleChange) => (
      <MultiSelect
        options={listDepartments}
        value={currentDepartmentValue}
        label="Departments (Optional)"
        placeholder="Select..."
        newValue="NEW"
        onCreate={() => setShowCreateDepartment(true)}
        handleClearOption={handleClearOption}
        onChange={(e) => handleChange(e)}
      />
    ),
    [handleClearOption, listDepartments, currentDepartmentValue]
  );

  return { departmentSelect, departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment };
};
