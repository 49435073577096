import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { useGetDataAnomaliesFilter } from '../../Anomalies/queries';
import {
  mapFilterModel,
  dateTimeValueFormatter,
  booleanValueFormatter,
  bitFormatter,
  dateLocalValueFormatter,
} from '~/utils';
import { ColDef, ValueGetterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import { varianceReasonDictionary } from './IgnoreDataAnomaliesModal';
import { VarianceTimeseriesTooltip } from './VarianceTimeseriesTooltip';

const getStartDate = ({ data }: ValueGetterParams) =>
  data.transaction?.startDate || data.transaction?.individualCollectionDate || data.transaction?.date || data.startDate;

const getEndDate = ({ data }: ValueGetterParams) =>
  data.transaction?.endDate || data.transaction?.individualCollectionDate || data.transaction?.date || data.endDate;

const getUser = ({ data }: ValueGetterParams) => `${data.user.first_name} ${data.user.last_name}`;

const getLocationName = ({ data }: ValueGetterParams) => `${data.locationName ? data.locationName : ''}`;

const getMeterName = ({ data }: ValueGetterParams) => `${data.meterName ? data.meterName : ''}`;

const getFileName = ({ data }: ValueGetterParams) => data.uploadedIn.fileName;

const getVarianceReason = ({ data }: ValueGetterParams) => varianceReasonDictionary[data.varianceReason];

const varianceReasonFilterParams = {
  values: [null, ...Object.keys(varianceReasonDictionary)],
  valueFormatter: (params) => varianceReasonDictionary[params.value] || params.value,
};

export const useColumnDefs = (): ColDef[] => {
  const accountId = useCurrentAccountId();

  const { showNotification } = useNotification();

  const intl = useIntl();

  const [getDataAnomaliesFilter] = useGetDataAnomaliesFilter();

  const translateTransactionType = useCallback(
    (transactionType: string) => intl.formatMessage({ id: `pages.dataAnomalies.transactionTypes.${transactionType}` }),
    [intl]
  );

  const formatUtility = useCallback(
    ({ data }: ValueGetterParams) => translateTransactionType(data.__typename),
    [translateTransactionType]
  );

  const handleGetFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const { field } = params.colDef;

        if (!field) {
          throw new Error('Unfilterable column');
        }

        const filterModel = params.api.getFilterModel();

        const response = await getDataAnomaliesFilter({
          variables: {
            accountId,
            field,
            filters: mapFilterModel(filterModel),
          },
        });

        const values: string[] = response.data?.getDataAnomaliesFilter || [];

        params.success(values.map((v) => (field === 'transactionType' ? translateTransactionType(v) : v)));
      } catch (err) {
        if (err instanceof Error && err.message === 'Virtual list has not been created.') {
          return;
        }
        showNotification(`Couldn't load filter values for column ${params.colDef.field}: ${err}`, 'danger');
      }
    },
    [getDataAnomaliesFilter, accountId, showNotification, translateTransactionType]
  );

  const filterParams = useMemo(
    () => ({
      values: handleGetFilterValues,
      cache: false,
    }),
    [handleGetFilterValues]
  );

  const acceptedColumnFilterParams = useMemo(
    () => ({
      ...filterParams,
      valueFormatter: bitFormatter,
    }),
    [filterParams]
  );

  return useMemo(
    () => [
      {
        headerName: 'Utility',
        valueGetter: formatUtility,
        field: 'transactionType',
        filterParams: filterParams,
        filter: 'agSetColumnFilter',
        checkboxSelection: true,
        sortable: true,
      },
      {
        headerName: 'Start date',
        valueGetter: getStartDate,
        valueFormatter: dateLocalValueFormatter,
        filter: false,
        sortable: false,
      },
      {
        headerName: 'End date',
        valueGetter: getEndDate,
        valueFormatter: dateLocalValueFormatter,
        filter: false,
        sortable: false,
      },
      {
        headerName: 'Detected time',
        field: 'detectedAt',
        valueFormatter: dateTimeValueFormatter,
        initialSort: 'desc',
        filter: 'agDateColumnFilter',
        sortable: true,
      },
      {
        headerName: 'Location',
        field: 'locationName',
        valueGetter: getLocationName,
        filter: 'agSetColumnFilter',
        filterParams: filterParams,
        sortable: true,
      },
      {
        headerName: 'Meter',
        field: 'meterName',
        valueGetter: getMeterName,
        filter: 'agSetColumnFilter',
        filterParams: filterParams,
        sortable: true,
      },
      {
        headerName: 'Reason',
        field: 'reason',
        minWidth: 400,
        filter: 'agTextColumnFilter',
        sortable: true,
        tooltipField: 'reason',
        tooltipComponent: VarianceTimeseriesTooltip,
      },
      {
        headerName: 'Variance Reason',
        field: 'varianceReason',
        valueGetter: getVarianceReason,
        minWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: varianceReasonFilterParams,
        sortable: true,
      },
      {
        headerName: 'Notes',
        field: 'notes',
        minWidth: 400,
        filter: 'agTextColumnFilter',
        cellEditor: 'agTextCellEditor',
        sortable: true,
        editable: true,
      },
      {
        headerName: 'Uploader',
        field: 'user',
        valueGetter: getUser,
        filterParams: filterParams,
        filter: 'agSetColumnFilter',
        sortable: true,
      },
      {
        headerName: 'Filename',
        field: 'uploadedIn',
        valueGetter: getFileName,
        filterParams: filterParams,
        filter: 'agSetColumnFilter',
        sortable: true,
      },
      {
        headerName: 'Accepted',
        field: 'ignored',
        valueFormatter: booleanValueFormatter,
        filterParams: acceptedColumnFilterParams,
        filter: 'agSetColumnFilter',
        sortable: true,
        hide: true,
      },
      {
        headerName: '% Variance',
        field: 'percentDiff',
        filterParams: filterParams,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
    ],
    [filterParams, formatUtility, acceptedColumnFilterParams]
  );
};
