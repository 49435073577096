import React from 'react';
import { Row, Col, Heading, Text, ProfilePicture, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import { usePermissions } from '../../../hooks/usePermissions';

const ContractorRowStyled = styled(Row)`
  flex: 0 0 auto;
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  padding-right: ${(props) => `${props.theme.geometry.xs.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  opacity: 0.7;
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 1;
  }

  &:hover .slide-in-animation {
    transform: translateX(25px);
  }
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: ${(props) => `${props.theme.geometry.md.spacing} ${props.theme.geometry.sm.spacing}`};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

const AccountProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const PhoneLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: ${(props) => `${props.theme.geometry.md.spacing} ${props.theme.geometry.sm.spacing}`};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;
const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
`;
const ColFlexEnd = styled(ColStyled)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ContractorRow = ({ contractorId, name, address, phoneNumber, onEdit }) => {
  const permissions = usePermissions();
  return (
    <ContractorRowStyled container name={`contractor-row--${contractorId}`} itemAlign="center">
      <AccountProfilePictureStyled name={`contractor-row__profile-picture--${contractorId}`} size="xs" accountName={name} />
      <ColStyled container item contractorId={contractorId} span={3}>
        <Heading inline name={`contractor-row__name--${contractorId}`} size="md">
          {name}
        </Heading>
      </ColStyled>

      <ColStyled container item contractorId={contractorId} span={3}>
        {phoneNumber && (
          <ColStyled contractorId={contractorId}>
            <PhoneLinkStyled name={`contractor-row__phoneNumber--${contractorId}`} size="md" href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </PhoneLinkStyled>
          </ColStyled>
        )}
      </ColStyled>

      <ColStyled container item inline contractorId={contractorId} span={3}>
        {address && (
          <ColStyled contractorId={contractorId} inline>
            <Text name={`contractorRow__addressLine1--${contractorId}`} size="md">
              {address.address1}
            </Text>
            <Text name={`contractorRow__addressLine2--${contractorId}`} size="md">
              {address.address2}
            </Text>
            <Text name={`contractorRow__addressLine3--${contractorId}`} size="md">
              {address.address3}
            </Text>
            <Text name={`contractorRow__addressCity--${contractorId}`} size="md">
              {address.city}
            </Text>
            <Text name={`contractorRow__addressPostcode--${contractorId}`} size="md">
              {address.postcode}
            </Text>
            <Text name={`contractorRow__addressPostcode--${contractorId}`} size="md">
              {address.country.name}
            </Text>
          </ColStyled>
        )}
      </ColStyled>
      {permissions.data.includes('editWasteContractorAll') && (
        <ColFlexEnd span={2}>
          <LinkStyled onClick={onEdit}>
            <Icon name={`contractorRow__edit--${contractorId}`} icon="pencil-alt" />
          </LinkStyled>
        </ColFlexEnd>
      )}
    </ContractorRowStyled>
  );
};

export default ContractorRow;
