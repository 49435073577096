import { useMemo } from 'react';
import { styled, Button } from '@rio/ui-components';
import {
  Maybe,
  SurveySubmission,
  SurveySubmissionStatus,
  ReceivedSurvey as ReceivedSurveyInterface,
} from '@rio/rio-types';
import { SurveyWorksheet } from '~/components/Surveys/v2';
import { useSurveyReceived } from '../hooks/useSurveyReceived';

const ContainerWrapper = styled('div')`
  display: flex;
  height: 100%;
`;

const ButtonRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 20px;
`;

const SubmitButton = styled(Button)`
  margin-left: 20px;
  width: 200px;
`;

const isSubmission = (submission?: Maybe<SurveySubmission>) =>
  submission &&
  (submission.status === SurveySubmissionStatus.Approved ||
    submission.status === SurveySubmissionStatus.ReadyForReview);

interface ReceivedSurveyProps {
  survey: ReceivedSurveyInterface;
}

export const ReceivedSurvey = ({ survey }: ReceivedSurveyProps) => {
  const {
    loading,
    isLocked,
    expanded,
    collapse,
    saveSurvey,
    editingUser,
    surveyModel,
    handleSubmit,
    submitButtonDisabled,
  } = useSurveyReceived(survey);

  const isDisabled = useMemo(
    () => submitButtonDisabled || isSubmission(survey.submission) || isLocked || loading,
    [isLocked, loading, submitButtonDisabled, survey.submission]
  );

  return (
    <ContainerWrapper>
      <SurveyWorksheet
        model={surveyModel}
        onDismiss={collapse}
        expanded={expanded}
        survey={survey}
        isLocked={isLocked}
        editingUser={editingUser}
        saveSurvey={saveSurvey}
      >
        <ButtonRow>
          <SubmitButton onClick={handleSubmit} variant="filled" disabled={isDisabled}>
            Submit for review
          </SubmitButton>
        </ButtonRow>
      </SurveyWorksheet>
    </ContainerWrapper>
  );
};
