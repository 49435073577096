"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DurationProgress = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material-next/styles");
const LinearProgress_1 = require("../LinearProgress/LinearProgress");
const utils_1 = require("../../utils");
const StyledProgress = (0, styles_1.styled)(LinearProgress_1.LinearProgress) `
  &.MuiLinearProgress-root {
    width: 100%;
    height: 8px;
    border-radius: 16px;
    width: 100%;
    background-color: ${(props) => {
    switch (props.color) {
        case 'white': {
            return (0, utils_1.opacifyHex)(props.theme.palette.common.white, 0.5);
        }
        case 'black': {
            return (0, utils_1.opacifyHex)(props.theme.ref.palette.neutral[10], 0.5);
        }
        case 'primary':
        default: {
            return (0, utils_1.opacifyHex)(props.theme.sys.color.primary, 0.5);
        }
    }
}};
  }

  .MuiLinearProgress-bar1 {
    width: auto;
    border-radius: 16px;
    animation: progress ${(props) => (props.duration ? props.duration / 1000 : 5)}s linear forwards;
    background-color: ${(props) => {
    switch (props.color) {
        case 'white': {
            return props.theme.palette.common.white;
        }
        case 'black': {
            return props.theme.ref.palette.neutral[10];
        }
        case 'primary':
        default: {
            return props.theme.sys.color.primary;
        }
    }
}};
  }

  .MuiLinearProgress-bar2 {
    display: none;
  }

  @keyframes progress {
    from {
      left: -35%;
      right: 100%;
    }

    to {
      left: 0%;
      right: 0%;
    }
  }
`;
const DurationProgress = ({ duration, color, ...rest }) => {
    return ((0, jsx_runtime_1.jsx)(StyledProgress, { duration: duration, color: color, ...rest, ref: rest.ref, variant: "indeterminate" }));
};
exports.DurationProgress = DurationProgress;
