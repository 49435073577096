import {
  addTableOfContents,
  addSiblings,
  getAllParents,
  createManagementSystemTree,
  createBreadcrumbs,
  getLinkForChapter
} from '../../../utils/managementSystemUtils';
import { BASE_PATH } from './constants';

export { addTableOfContents, addSiblings, getAllParents, createManagementSystemTree };

export function mapChapterToFolder(chapter, pathname, itemId) {
  return {
    id: chapter.id,
    name: chapter.tocTitle,
    url: getLinkForChapter(itemId, chapter.id, pathname),
    icon: {
      name: 'folder',
      color: 'quaternary'
    },
    libraries: ['GOVERNANCE']
  };
}

const emptyPage = {
  folders: [],
  files: []
};

export function getFoldersAndFilesFromManagementSystem(managementSystem, items, itemId, pathname) {
  if (!managementSystem) {
    return emptyPage;
  }
  if (itemId) {
    const item = items[itemId];
    if (item) {
      const subItems = item.chapters || [];
      return {
        folders: subItems.map((subItem) => mapChapterToFolder(items[subItem.id], pathname, itemId)),
        files: item.documents || []
      };
    }
    return emptyPage;
  }
  return {
    folders: managementSystem.chapters.map((chapter) => mapChapterToFolder(items[chapter.id], pathname, itemId)),
    files: managementSystem.documents
  };
}

export function createBaseBreadcrumbs(intl) {
  const { formatMessage } = intl;
  return [
    {
      title: formatMessage({ id: 'pages.documents.breadcrumbs.one' }),
      to: '/documents'
    },
    {
      title: formatMessage({ id: 'pages.governance.breadcrumbs.one' }),
      to: '/documents/governance'
    }
  ];
}

export function createBreadcrumbsForManagementSystem(intl, managementSystem, managementSystemTree, chapter) {
  const tail = createBreadcrumbs(managementSystem, managementSystemTree, chapter, BASE_PATH);
  return createBaseBreadcrumbs(intl).concat(tail);
}

export function mapManagementSystemsToFolders(managementSystems, pathname) {
  return (
    managementSystems.map((managementSystem) => ({
      id: managementSystem.id,
      name: managementSystem.title,
      url: `${pathname}/${managementSystem.id}`,
      icon: {
        name: 'folder',
        color: 'quaternary'
      },
      libraries: ['GOVERNANCE']
    })) || []
  );
}

export const LIBRARY = 'governance';
export const CATEGORY = 'Management Systems';
