import { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import { Survey } from '@rio/rio-types';
import { FlexContainer, ProgressBarChart } from './ProgressBarChart';
import { baseColumnChart } from './baseColumnChart';
import { countSubmittedSubmissions, sumPerformanceScore } from './calculateScores';
import { colors, colorsScore } from './colors';
import { Row } from '../styles';
import { Tick } from '../../../../components/SvgIcons';

interface ContributorPerformanceChartProps {
  children: string | JSX.Element | JSX.Element[];
  survey: Survey;
}
interface PercentagesOfContributorsChartProps {
  survey: Survey;
  percentPerformance: { positive: number; neutral: number; negative: number };
}

const ProgressBarChartFlex = styled(ProgressBarChart)`
  flex: 0 0 calc(50% - 5px);
  margin: 10px 0;
`;
export const FlexContainerStretch = styled(FlexContainer)`
  flex-direction: column;
  align-items: stretch;
`;
const Banner = styled.div`
  margin-bottom: 12px;
  padding: 12px;
  background: #00c8b511;
  color: #45b7ad;
  border-radius: 12px;
`;
const TickStyled = styled(Tick)`
  margin-right: 12px;
  vertical-align: middle;
`;

const chart = (
  percentNotSubmitted: number,
  percentPerformance: { positive: number; neutral: number; negative: number }
): Highcharts.Options => ({
  ...baseColumnChart,
  xAxis: {
    categories: ['Not submitted', 'Negative', 'Neutral', 'Positive'],
    labels: { enabled: false },
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: '% of contributors',
      x: -10,
      style: { fontWeight: 'bold' },
    },
  },
  series: [
    {
      name: 'Not submitted',
      data: [percentNotSubmitted],
      color: colors.grey,
      type: 'column',
    },
    {
      name: 'Negative',
      data: [percentPerformance.negative],
      color: colors.pink,
      type: 'column',
    },
    {
      name: 'Neutral',
      data: [percentPerformance.neutral],
      color: colors.yellow,
      type: 'column',
    },
    {
      name: 'Positive',
      data: [percentPerformance.positive],
      color: colors.green,
      type: 'column',
    },
  ],
});

function PercentOfContributorsChart({ survey, percentPerformance }: PercentagesOfContributorsChartProps) {
  const totalApprovedSubmissions = countSubmittedSubmissions(survey.submissions);
  const totalContributors = survey.contributors.length;
  const percentNotSubmitted = ((totalContributors - totalApprovedSubmissions) / totalContributors) * 100;
  const lowResponses = percentNotSubmitted > 0 && percentNotSubmitted < 50;

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const chartCached = useMemo(
    () => chart(percentNotSubmitted, percentPerformance),
    [percentNotSubmitted, percentPerformance]
  );

  return (
    <>
      {lowResponses && (
        <Banner>
          <TickStyled />
          Encourage more responses to try and improve your impact
        </Banner>
      )}
      <HighchartsReact highcharts={Highcharts} options={chartCached} ref={chartRef} />
    </>
  );
}

export function ContributorPerformanceChart({ children, survey }: ContributorPerformanceChartProps) {
  const performance = useMemo(() => sumPerformanceScore(survey), [survey]);
  const performanceText = performance.text.toLowerCase();
  const percent = (performance[performanceText as keyof typeof performance] || 0) as number;
  const color = colorsScore[performanceText as keyof typeof colorsScore];

  return (
    <FlexContainer>
      {children}
      <ProgressBarChartFlex text={`${performance.text} Overall Performance`} totals={[percent, 100]} color={color} />
      <Row />
      <FlexContainerStretch>
        <PercentOfContributorsChart survey={survey} percentPerformance={performance} />
      </FlexContainerStretch>
    </FlexContainer>
  );
}
