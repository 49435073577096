import { useCallback } from 'react';
import { useGetByLatest, GetSurveyLatestResponse } from '../queries';
import { SurveyOverviewPage } from './SurveyOverviewPage';
import { Async } from '../../../components/Async';
import { useCurrentAccountId } from '../../../hooks';

export function SurveyLatestRetriever() {
  const currentAccountId = useCurrentAccountId();

  const result = useGetByLatest(currentAccountId);

  const renderData = useCallback(
    ({ getSurveyLatest }: GetSurveyLatestResponse) => <SurveyOverviewPage survey={getSurveyLatest} latest={true} />,
    []
  );

  return <Async<GetSurveyLatestResponse> result={result} data={renderData} />;
}
