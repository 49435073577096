import { Query } from '@rio/rio-types';
import { useQuery } from '@apollo/client';
import { useCurrentAccountId } from '../../../hooks';
import { GET_CARBON_CONVERSION_FACTORS_BY_ACCOUNT_ID } from './index.queries';

type Queries =
  | 'getCarbonConversionFactorsByAccountId'
  | 'getElectricityConversionFactors'
  | 'getGasConversionFactors'
  | 'getHeatConversionFactors'
  | 'getRefrigerantConversionFactors'
  | 'getWaterConversionFactors'
  | 'getMaterialConversionFactors';
type Response = Pick<Query, Queries>;

type Variables = {
  accountId: string;
};

export function useGetCarbonConversionFactors() {
  const accountId = useCurrentAccountId();
  return useQuery<Response, Variables>(GET_CARBON_CONVERSION_FACTORS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
}
