import { dromoConfiguration, DromoConfiguration } from './base';
import { validateDates } from './hooks';
import templateGas from '../GasContainer/UploadContainer/gas-template.xlsx';
import templateElectricity from '../ElectricityContainer/UploadContainer/electricity-template.xlsx';
import templateHeatAndSteam from '../HeatSteamContainer/UploadContainer/heatsteam-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const templateGasUrl = [getEnvVar('REACT_APP_BASE_URL'), templateGas].join('');
const templateElectricityUrl = [getEnvVar('REACT_APP_BASE_URL'), templateElectricity].join('');
const templateHeatAndSteamUrl = [getEnvVar('REACT_APP_BASE_URL'), templateHeatAndSteam].join('');
const baseEnergyConfiguration: DromoConfiguration = {
  ...dromoConfiguration,
  bulkRowHooks: [(records) => validateDates(['start date (dd/mm/yyyy)', 'end date (dd/mm/yyyy)'], records)],
};

export function getElectricityConfiguration(): DromoConfiguration {
  return {
    ...baseEnergyConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: 'Electricity',
      title: `Upload Electricity`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateElectricityUrl),
      },
    },
  };
}
export function getGasConfiguration(): DromoConfiguration {
  return {
    ...baseEnergyConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: 'Gas',
      title: `Upload Gas`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateGasUrl),
      },
    },
  };
}
export function getHeatAndSteamConfiguration(): DromoConfiguration {
  return {
    ...baseEnergyConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: 'Heat & Steam',
      title: `Upload Heat & Steam`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateHeatAndSteamUrl),
      },
    },
  };
}
