import React from 'react';
import styled from 'styled-components';
import { Col, MarkdownRenderer, Row, Heading, CircleIcon, Button } from 'rio-ui-components';
import { Modal } from 'rio-ui-components';
import { formatPrice } from '../ProductCard';

const CourseContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.lg.spacing};
  background: ${(props) => props.theme.colors.basic.white};
`;

const CourseImage = styled.div`
  position: relative;
  padding: 0 0 75% 0;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.secondary.dark.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-flex;
  flex: 0 0 auto;
`;

const CourseTextContainer = styled(Col)`
  padding-left: ${(props) => props.theme.geometry.md.spacing};
`;

const CourseHeading = styled(Heading)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonStyled = styled(Button)`
  margin-top: ${(props) => props.theme.geometry.md.spacing};
  border-radius: 0;
`;

const ProductModal = (props) => {
  const { bundleInfo, onDismiss, addProduct } = props;

  const handleClickByAdd = () => {
    addProduct({
      id: bundleInfo.product.id,
      title: bundleInfo.product.title,
      price: bundleInfo.product.price,
      productType: bundleInfo.product.productType
    });
    onDismiss();
  };

  return (
    <Modal show={true} onDismiss={onDismiss} size="lg">
      <CourseContainer container item>
        <Col item span={5}>
          <CourseImage name="CoursePreview__Thumbnail" src={bundleInfo.thumbnail}>
            {!bundleInfo.thumbnail && (
              <CircleIconStyled
                name="CoursePreview__Icon"
                height="100px"
                size="lg"
                icon="graduation-cap"
                iconColor="white"
                circleColor="white"
              />
            )}
          </CourseImage>
        </Col>
        <CourseTextContainer item span={7}>
          <CourseHeading size="xxl" weight="light" color="dark">
            {bundleInfo.title}
          </CourseHeading>

          {!!bundleInfo.description && (
            <MarkdownRenderer name="CoursePreview__MarkDown--Description" source={bundleInfo.description} />
          )}
          {!bundleInfo.hideBuyButton ? (
            <ButtonStyled color="success" inline href={null} onClick={handleClickByAdd}>
              £{formatPrice(bundleInfo.price)} Add to basket
            </ButtonStyled>
          ) : null}
        </CourseTextContainer>
      </CourseContainer>
    </Modal>
  );
};
export default ProductModal;
