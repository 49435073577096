import { gql } from '@apollo/client';

export const AUDIT_EVENT_FRAGMENT = gql`
  fragment AuditEventFields on AuditEvent {
    id
    service
    entityId
    entity
    user {
      ... on User {
        id
        first_name
        last_name
      }
    }
    account {
      ... on Account {
        id
        name
      }
    }
    type
    notes
    payload
    createdDate
  }
`;

export const GET_AUDIT_BY_ENTITY_ID = gql`
  query GetAuditByEntityId($entityId: ID!) {
    getAuditByEntityId(entityId: $entityId) {
      ...AuditEventFields
    }
  }
  ${AUDIT_EVENT_FRAGMENT}
`;
