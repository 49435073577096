import { Text, styled } from '@rio/ui-components';
import { SurveysPlaceholder } from '~/components/SurveysPlaceholder';
import { useCurrentAccountId } from '~/hooks';
import { ContributorsGrid } from './ContributorsGrid';
import { useGetMyContributors } from './queries';

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StatusContainer = styled('div')`
  display: flex;
  padding: 10px;
  align-items: center;
`;

export const MyContributorsContainerV2 = () => {
  const accountId = useCurrentAccountId();
  const { data } = useGetMyContributors(accountId);
  const numberOfUsersNotRegistered = 0;
  const numberOfUsersRegistered = data?.getMySurveyContributors.length;

  return (
    <WrapperStyled>
      {numberOfUsersNotRegistered > 0 && (
        <StatusContainer>
          <Text
            typescale="body"
            size="medium"
          >{`${numberOfUsersNotRegistered} not registered for RIO out of ${numberOfUsersRegistered} invited.`}</Text>
        </StatusContainer>
      )}
      {data?.getMySurveyContributors.length === 0 ? (
        <SurveysPlaceholder
          title="You don’t have any contributors yet"
          description="Contributors are teams that can receive surveys. Contributors will appear once you assign a survey to teams in Surveys > Overview."
          icon={['rio', 'clipboard-check-custom']}
        />
      ) : (
        <ContributorsGrid contributors={data?.getMySurveyContributors || []} />
      )}
    </WrapperStyled>
  );
};
