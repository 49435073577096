import React, { useCallback, useMemo, useState } from 'react';
import { styled, Button, Modal, Select, TextArea, Grid } from '@rio/ui-components';
import { useIntl } from 'react-intl';
import { DataAnomaly, VarianceReason } from '@rio/rio-types';

const ButtonRow = styled('div')`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
`;

type Props = {
  toBeIgnoredDataAnomalies: DataAnomaly[];
  onConfirm: (varianceReason?: VarianceReason | null, notes?: string | null) => void;
  onClose: () => void;
};

export const varianceReasonDictionary = {
  [VarianceReason.OnsiteWork]: 'Onsite project work',
  [VarianceReason.Headcount]: 'Changes in occupancy rates (headcount)',
  [VarianceReason.Floorspace]: 'Changes in floorspace',
  [VarianceReason.WaterLeak]: 'Water leak',
  [VarianceReason.OtherOperational]: 'Other operational root cause',
  [VarianceReason.BelowThreshold]: 'Below threshold, not investigated',
  [VarianceReason.SupplierDataIssue]: 'Supplier data issue',
  [VarianceReason.NoActionRequired]: 'Within range of expected seasonality, no action required',
  [VarianceReason.PendingSupplierResponse]: 'Pending supplier response',
  [VarianceReason.Other]: 'Other',
};
export const varianceReasonOptions = Object.entries(varianceReasonDictionary).map(([enumValue, label]) => ({
  value: enumValue,
  label,
}));

export const IgnoreDataAnomaliesModal = ({ toBeIgnoredDataAnomalies, onClose, onConfirm }: Props) => {
  const hasAtLeastOneVarianceType = useMemo(
    () => toBeIgnoredDataAnomalies.some((dataAnomaly) => Boolean(dataAnomaly.percentDiff)),
    [toBeIgnoredDataAnomalies]
  );

  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState({ varianceReason: '' });

  const [varianceReason, setVarianceReason] = useState('');
  const [notes, setNotes] = useState('');

  const handleConfirmClick = useCallback(() => {
    if (hasAtLeastOneVarianceType) {
      if (!varianceReason) {
        setValidationErrors({ varianceReason: 'Required' });
        return;
      }

      onConfirm((varianceReason as VarianceReason) || null, notes || null);
      onClose();
      return;
    }

    if (!hasAtLeastOneVarianceType) {
      onConfirm();
      onClose();
      return;
    }
  }, [hasAtLeastOneVarianceType, varianceReason, notes, onClose, onConfirm]);

  const title = useMemo(() => {
    if (hasAtLeastOneVarianceType) {
      return "You're about to accept and clear variances and anomalies";
    }

    if (!hasAtLeastOneVarianceType) {
      return intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.title' });
    }
  }, [hasAtLeastOneVarianceType, intl]);

  return (
    <Modal open onClose={onClose} title={title} titleMaxWidth={500}>
      {hasAtLeastOneVarianceType && (
        <Grid container columns={12} rowGap={4}>
          <Grid item xs={12}>
            <Select
              label="Variance reason"
              value={varianceReason}
              error={Boolean(validationErrors.varianceReason)}
              helperText={validationErrors.varianceReason}
              options={varianceReasonOptions}
              onChange={({ value }) => {
                setVarianceReason(value);
                setValidationErrors({ varianceReason: '' });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea label="Notes (optional)" value={notes} onChange={(e) => setNotes(e.target.value)} />
          </Grid>
        </Grid>
      )}
      <ButtonRow>
        <Button onClick={onClose} variant="outlined" color="primary">
          {intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.disagree' })}
        </Button>
        <Button onClick={handleConfirmClick} variant="filled" color="primary">
          {intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.agree' })}
        </Button>
      </ButtonRow>
    </Modal>
  );
};
