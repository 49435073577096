"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartCard = exports.chartCardClassNames = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material-next/styles");
const react_1 = require("react");
const Text_1 = require("../Text");
const ChartCardContainer = (0, styles_1.styled)('div') `
  height: 100%;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background: ${({ theme }) => theme.sys.color.surface};
`;
const TextStyled = (0, styles_1.styled)(Text_1.Text) `
  margin: 0;
  margin-bottom: 8px;
`;
exports.chartCardClassNames = {
    title: 'title',
};
exports.ChartCard = (0, react_1.forwardRef)(({ title, children }, ref) => {
    return ((0, jsx_runtime_1.jsxs)(ChartCardContainer, { ref: ref, children: [(0, jsx_runtime_1.jsx)(TextStyled, { typescale: "label", size: "small", className: exports.chartCardClassNames.title, children: title }), children] }));
});
