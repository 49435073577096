import { gql } from "@apollo/client";

export const MARKET_CARBON_CONVERSION_FACTOR = gql`
  fragment MarketCarbonConversionFactorFields on MarketCarbonConversionFactor {
    id
    supplierId
    supplierName
    tariff
    renewablePercentage
    isGlobal
    accountIds
    generationFactor
    wellToTankForGenerationFactor
    wellToTankForTransmissionAndDistributionFactor
    transmissionAndDistributionFactor
    startDate
    endDate
    reference
    notes
  }
`;
export const GET_MARKET_CARBON_CONVERSION_FACTORS = gql`
  query getMarketCarbonConversionFactors($accountId: ID) {
    getMarketCarbonConversionFactors(accountId: $accountId) {
      ...MarketCarbonConversionFactorFields
    }
  }
  ${MARKET_CARBON_CONVERSION_FACTOR}
`;
export const CREATE_MARKET_CARBON_CONVERSION_FACTOR = gql`
  mutation createMarketCarbonConversionFactor($factor: MarketCarbonConversionFactorInput!) {
    createMarketCarbonConversionFactor(factor: $factor) {
      ...MarketCarbonConversionFactorFields
    }
  }
  ${MARKET_CARBON_CONVERSION_FACTOR}
`;
export const EDIT_MARKET_CARBON_CONVERSION_FACTOR = gql`
  mutation editMarketCarbonConversionFactor($factor: MarketCarbonConversionFactorInput!) {
    updateMarketCarbonConversionFactor(factor: $factor) {
      ...MarketCarbonConversionFactorFields
    }
  }
  ${MARKET_CARBON_CONVERSION_FACTOR}
`;
export const DELETE_MARKET_CARBON_CONVERSION_FACTOR = gql`
  mutation deleteMarketCarbonConversionFactor($id: ID!) {
    deleteMarketCarbonConversionFactor(id: $id) {
      ...MarketCarbonConversionFactorFields
    }
  }
  ${MARKET_CARBON_CONVERSION_FACTOR}
`;
