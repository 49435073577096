import _ from 'lodash';
import { useMemo } from 'react';
import { Options } from 'highcharts';
import { PieChartData } from '@rio/rio-types';
import { useMediaQuery } from '@mui/material';
import { useTheme, SimplePieDonutChart, ChartDataItem, styled } from '@rio/ui-components';

const ChartStyled = styled(SimplePieDonutChart)`
  & {
    width: 100%;
    height: 100%;

    rect {
      fill: none;
    }

    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  & .highcharts-data-labels {
    path {
      stroke-dasharray: 2;
    }
  }
  & > div:first-child {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: visible !important;

    & > div:first-child {
      display: flex;
      justify-content: center;
      justify-content: center;
      height: 100% !important;
      overflow: visible !important;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    & {
      position: static;
      flex-direction: column;
      gap: 24px;
    }
  }
`;

const scopeColors = {
  'Scope 1': 'extendedColors.environmental.color',
  'Scope 2': 'extendedColors.economic.color',
  'Scope 3': 'extendedColors.governance.color',
};

interface ScopeBreakdownPieChartProps {
  scopeBreakdown: PieChartData;
}

export function ScopeBreakdownPieChart({ scopeBreakdown }: ScopeBreakdownPieChartProps) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const customOptions: Options = useMemo(() => {
    const chartWidth = isMdScreen ? 320 : isLgScreen ? 480 : 600;
    const chartHeight = isMdScreen ? 140 : isLgScreen ? 160 : 212;

    return {
      chart: {
        width: chartWidth,
        height: chartHeight,
      },
    };
  }, [isMdScreen, isLgScreen]);

  const data: ChartDataItem[] = useMemo(
    () =>
      _(scopeBreakdown.series)
        .map((s) => ({
          y: s.absoluteValue,
          name: s.id,
          color: _.get(theme, scopeColors[s.id]),
        }))
        .orderBy(['name'], ['asc'])
        .value(),
    [scopeBreakdown, theme]
  );

  return <ChartStyled data={data} unit="tCO2e" precision={0} customOptions={customOptions} />;
}
