import { gql } from '@apollo/client';

export const SCHEME_FRAGMENT = gql`
  fragment SchemeFields on Scheme {
    id
    name
    accountId
    category {
      id
      name
    }
    description
  }
`;

export const CREATE_SCHEME = gql`
  mutation CreateProjectScheme($name: String!, $accountId: ID!, $categoryId: ID!, $description: String) {
    createProjectScheme(
      input: { name: $name, accountId: $accountId, categoryId: $categoryId, description: $description }
    ) {
      ...SchemeFields
    }
  }
  ${SCHEME_FRAGMENT}
`;

export const UPDATE_SCHEME = gql`
  mutation UpdateProjectScheme($id: ID!, $name: String!, $accountId: ID!, $categoryId: ID!, $description: String) {
    editProjectScheme(
      input: { id: $id, name: $name, accountId: $accountId, categoryId: $categoryId, description: $description }
    ) {
      ...SchemeFields
    }
  }
  ${SCHEME_FRAGMENT}
`;

export const DELETE_SCHEME = gql`
  mutation DeleteProjectScheme($id: ID!, $accountId: ID!) {
    deleteProjectScheme(id: $id, accountId: $accountId)
  }
`;
