import React, { useEffect } from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { Select, Heading } from 'rio-ui-components';

const RightAlignedHeader = styled(Heading)`
  text-align: right;
`;

function LegislationTagSelect({ tags, value, onChange, error, additionalOptions = [] }) {
  const filteredTags = tags?.filter((tag) => tag.legislationTag) || [];

  const orderedTags = orderBy(filteredTags, [(tag) => tag.tagName.toLowerCase()], ['asc']);
  const mappedTags = orderedTags.map((tag) => ({
    value: tag.id,
    label: tag.tagName
  }));

  const singleTag = mappedTags.length === 1;

  useEffect(() => {
    if (!!mappedTags.length && !value) {
      onChange({ target: mappedTags[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedTags]);

  return (
    <>
      {singleTag && <RightAlignedHeader>{mappedTags[0].label}</RightAlignedHeader>}
      {!singleTag && <Select name="tagId" placeholder={tags ? 'No Available Tags' : 'Loading...'} value={value} options={[...additionalOptions, ...mappedTags]} error={error} onChange={onChange} />}
    </>
  );
}

export { LegislationTagSelect };
