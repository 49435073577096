import React, { FC, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import SubscriptionRow from './SubscriptionRow';
import { DELETE_FLOW_SUBSCRIPTION, GET_ALL_FLOW_SUBSCRIPTIONS } from './index.queries';

interface Flow {
  flow: {
    id: string;
    title: string;
  };
  account: {
    id: string;
  };
}

interface SubscriptionRowsProps {
  flows: Flow[];
  onComplete: (message: string, type: 'success' | 'danger') => void;
}

const SubscriptionRows: FC<SubscriptionRowsProps> = ({ flows, onComplete }) => {
  const [deleteFlowSubscriptionMutation] = useMutation(DELETE_FLOW_SUBSCRIPTION, {
    onCompleted: () => {
      onComplete('Flow subscription has been deleted.', 'success');
    },
    onError: () => {
      onComplete('Something went wrong! Please try again later.', 'danger');
    },
    update: (cache, { data: { deleteFlowSubscription } }) => {
      const existingFlowSubscriptions: { getFlowSubscriptions: Flow[] } | null = cache.readQuery({
        query: GET_ALL_FLOW_SUBSCRIPTIONS,
      });
      if (existingFlowSubscriptions && deleteFlowSubscription) {
        const newFlowSubscriptions = existingFlowSubscriptions.getFlowSubscriptions.filter(
          (flowSubscription) => flowSubscription.flow.id !== deleteFlowSubscription.flow.id
        );
        cache.writeQuery({
          query: GET_ALL_FLOW_SUBSCRIPTIONS,
          data: { getFlowSubscriptions: newFlowSubscriptions },
        });
      }
    },
  });

  const onDelete = useCallback(
    (flowId: string, accountId: string) => deleteFlowSubscriptionMutation({ variables: { accountId, flowId } }),
    [deleteFlowSubscriptionMutation]
  );

  return (
    <>
      {flows.map((flow) => {
        return (
          <SubscriptionRow
            key={flow.flow.id}
            id={flow.flow.id}
            accountId={flow.account.id}
            name={flow.flow.title}
            onDelete={onDelete}
          />
        );
      })}
    </>
  );
};

export default SubscriptionRows;
