import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UploadFiles, InputFile, Tabs, Tab, TabPanel, Col, Row, Select, TextInput, Button } from 'rio-ui-components';
import { GET_SUBSCRIBED_DASHBOARDS } from '../../ReportingContainer/ReportingReports/index.queries';
import { getDashboardPath } from '../../ReportingContainer/utils';
import validator from 'validator';
import { RETURN_KEY } from '../../../constants/keyCodes';
import { DocumentLink } from '../types';
import { Dashboard } from '@rio/rio-types';

const SelectFilesContainer = styled(Col)`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`;
const TabContent = styled(Col)`
  min-height: 60vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.inputs.normal.background};
`;
const UploadFilesStyled = styled(UploadFiles)`
  min-height: 60vh;
  display: flex;
  flex: 1;
  & > div {
    width: 100%;
  }
`;
const TabStyled = styled(Tab)`
  & .MuiTab-wrapper {
    text-transform: none;
  }
`;
const FieldContainer = styled(Col)`
  align-items: flex-start;
  width: 50%;
  height: 150px;
  margin: auto;
`;
const FieldRow = styled(Row)`
  width: 100%;
  height: auto;
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonStyled = styled(Button)``;

function makeLink(link: string) {
  return link.match(/^http/) ? link : `https://${link}`;
}

interface SelectFilesProps {
  onFiles: (files: File[]) => void;
  onLink: (links: DocumentLink) => void;
  accountId: string;
}

interface LinkState {
  url: string;
  name: string;
  error: string | null;
}

export function SelectFiles({ onFiles, onLink, accountId }: SelectFilesProps) {
  const [tab, setTab] = useState(0);
  const [dashboard, setDashboard] = useState({
    id: null,
    name: '',
    error: null
  });
  const [link, setLink] = useState<LinkState>({
    url: '',
    name: '',
    error: null
  });
  const { data } = useQuery(GET_SUBSCRIBED_DASHBOARDS, {
    variables: {
      accountId
    }
  });
  const dashboards = data?.getSubscribedDashboards || [];
  const validateUrl = (url: string) => {
    // @ts-ignore
    if (url && !validator.isURL(url)) {
      setLink({
        url,
        name: link.name,
        error: `"${url}" is not a valid URL`
      });
      return false;
    }
    return true;
  };
  const getFinalLink = (name: string, link: string): DocumentLink => {
    const url = makeLink(link);
    return {
      name: name || link,
      link: url
    };
  };
  return (
    <SelectFilesContainer>
      <Tabs variant="fullWidth" value={tab} onChange={(_: unknown, tab: number) => setTab(tab)}>
        <TabStyled label="Select files" />
        <TabStyled label="Provide link" />
        <TabStyled label="Select dashboard" />
      </Tabs>
      <TabContent container>
        <TabPanel value={tab} index={0}>
          <InputFile
            onChange={(files: File[]) => {
              onFiles([...files]);
            }}
            multiple
          >
            {(selectFiles: File[]) => (
              <UploadFilesStyled
                buttonText="Select files"
                onClick={selectFiles}
                onDrop={(files: File[]) => {
                  onFiles([...files]);
                }}
                background
              />
            )}
          </InputFile>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <FieldContainer container item>
            <FieldRow item>
              <TextInput
                value={link.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLink({
                    name: e.target.value,
                    url: link.url,
                    error: link.error
                  });
                }}
                placeholder="Document name"
              />
            </FieldRow>
            <FieldRow item>
              <TextInput
                value={link.url}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLink({
                    name: link.name,
                    url: e.target.value,
                    error: null
                  });
                }}
                placeholder="Documents link"
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const value: string = (e.target as any).value;
                  const valid = validateUrl(value);
                  if (valid && e.key === RETURN_KEY) {
                    const url = makeLink(value);
                    onLink(getFinalLink(link.name, url));
                  }
                }}
                error={link.error}
                onBlur={(e: React.MouseEvent<HTMLInputElement>) => {
                  validateUrl((e.target as any).value);
                }}
              />
            </FieldRow>
            <FieldRow item>
              <ButtonStyled
                disabled={!link.url || !!link.error}
                onClick={() => {
                  onLink(getFinalLink(link.name, link.url));
                }}
              >
                Submit
              </ButtonStyled>
            </FieldRow>
          </FieldContainer>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <FieldContainer container item>
            <FieldRow item>
              <TextInput
                value={dashboard.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDashboard({
                    ...dashboard,
                    name: e.target.value,
                    error: null
                  });
                }}
                placeholder="Document name"
              />
            </FieldRow>
            <FieldRow>
              <Select
                placeholder="Select a dashboard from the list..."
                onChange={(e: any) => {
                  setDashboard({
                    ...dashboard,
                    id: e.target.select.value
                  });
                }}
                value={dashboard.id}
                options={dashboards.map((dashboard: Dashboard) => ({
                  value: dashboard.id,
                  label: dashboard.name
                }))}
              />
            </FieldRow>
            <FieldRow>
              <ButtonStyled
                disabled={!dashboard.id}
                onClick={() => {
                  const selectedDashboard = dashboards.find((d: Dashboard) => d.id === dashboard.id);
                  const path = getDashboardPath(selectedDashboard);
                  const url = window.location.origin + path;
                  onLink(getFinalLink(dashboard.name || selectedDashboard.name, url));
                }}
              >
                Submit
              </ButtonStyled>
            </FieldRow>
          </FieldContainer>
        </TabPanel>
      </TabContent>
    </SelectFilesContainer>
  );
}
