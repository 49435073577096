import { useCallback } from 'react';
import { Select, OptionsProps } from '@rio/ui-components';

interface SelectRenderProps {
  value?: string;
  onChange: (e: OptionsProps) => void;
  error?: string;
  label?: string;
  name?: string;
  options?: OptionsProps[];
  onCreateNew?: (name: string) => void;
}

export const SelectRenderV2 = (props: SelectRenderProps) => {
  const { value, onChange, error, label, options = [], onCreateNew = () => {}, ...others } = props;

  const handlerChange = useCallback(
    (e: OptionsProps) => {
      if (e.value === 'createNew' && onCreateNew) {
        return onCreateNew(e.label);
      }

      onChange(e);
    },
    [onChange, onCreateNew]
  );

  return (
    <Select
      {...others}
      error={!!error}
      label={label}
      helperText={error || ''}
      onChange={handlerChange}
      options={options}
      value={value || ''}
    />
  );
};
