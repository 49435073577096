import Highcharts from 'highcharts';

export const baseChart: Highcharts.Options = {
  title: { text: '' },
  chart: {
    height: 350,
    backgroundColor: 'transparent',
    style: { fontFamily: "'Proxima Nova', Helvetica, Arial, sans-serif;" },
    reflow: true,
  },
  legend: {
    align: 'center',
    layout: 'horizontal',
    verticalAlign: 'bottom',
    margin: 30,
    itemStyle: {
      fontSize: '1.27em',
      fontWeight: 'normal',
    },
  },
  tooltip: { enabled: false },
  credits: { enabled: false },
};
