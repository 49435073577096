import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Dictionary, cloneDeep } from 'lodash';
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import { GET_MANAGEMENT_SYSTEM_BY_ID } from './index.queries';
import { LoadingIndicator, Heading, CircleIcon, Col, Row } from 'rio-ui-components';
import PageHeader from '../../../../components/PageHeader';
import { NotAvailable, LoadFailed } from '../../../../components/Errors';
import { useCurrentAccountId } from '../../../../hooks/useCurrentAccountId';
import { constructNavigationMenu } from './constructNavigationMenu';
import { usePermissions } from '../../../../hooks/usePermissions';
import { AccordionMenu } from '../../../../components/AccordionMenu';
import { createBreadcrumbs, createManagementSystemTree } from '../../../../utils/managementSystemUtils';
import { ContentOverview } from './ContentOverview';
import { DocumentRecord } from '@rio/rio-types';
import { GetManagementSystemByIdResponse, ManagementSystemPage } from '../../../../types';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ChildHeader = styled.div`
  background-color: ${(props) => props.theme.colors.basic.white};
  width: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} ${(props) => props.theme.geometry.sm.spacing} 0
    ${(props) => props.theme.geometry.sm.spacing};
`;

const ContentContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const MenuContainer = styled(Col)`
  max-height: 100vh;
  overflow: auto;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ManagementSystemOverviewContainer = () => {
  const { id } = useParams() as { id: string };
  const { pathname } = useLocation();
  const chapterId = pathname.split('/').pop() as string;
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const isReadOnly = !permissions.document.find((action: any) => action.startsWith('create'));
  const { loading, data, error, refetch } = useQuery<GetManagementSystemByIdResponse>(GET_MANAGEMENT_SYSTEM_BY_ID, {
    variables: {
      id,
      accountId,
    },
    notifyOnNetworkStatusChange: false,
  });
  const retry = useCallback(() => refetch({ id, accountId }), [id, accountId, refetch]);
  const managementSystem = cloneDeep(data?.getManagementSystemById);

  if (!managementSystem) return null;
  const { tree } = createManagementSystemTree(managementSystem) as {
    tree: Dictionary<ManagementSystemPage>;
    documents: DocumentRecord[];
  };

  const currentPage = tree[chapterId] || managementSystem;
  const canEditContent = permissions.governance.find((action: any) =>
    action.startsWith('attachContentToManagementSystem')
  );

  const breadcrumbs = [{ title: 'Governance', to: '..' }, ...createBreadcrumbs(managementSystem, tree, currentPage)];
  const page = tree[chapterId] || managementSystem;

  const managementSystemElement = (
    <ContentOverview
      managementSystem={managementSystem}
      page={page}
      canEditContent={canEditContent}
      isReadOnly={isReadOnly}
      refetch={() => {
        refetch({ id, accountId });
      }}
    />
  );

  return (
    <ContainerStyled>
      <PageHeader
        name="ManagementSystemOverviewHeader"
        title={managementSystem?.title || ''}
        breadcrumbs={breadcrumbs}
        icon="pencil-alt"
        iconColor="tertiary"
      />
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {error?.graphQLErrors?.[0]?.message === 'Forbidden' && (
        <NotAvailable name={'Forbidden_Management_System'} error={error} />
      )}
      {error && error?.graphQLErrors?.[0]?.message !== 'Forbidden' && (
        <LoadFailed
          name={'LoadFailed_Management_System'}
          error={error}
          retry={() => {
            return retry;
          }}
        />
      )}
      {!loading && managementSystem && (
        <ContentContainer container>
          <MenuContainer span={4} item>
            <ChildHeader>
              <FlexRow>
                <TitleContainerStyled>
                  <CircleIconStyled
                    inline
                    height="50px"
                    size="xl"
                    circleColor="tertiary"
                    iconColor="tertiary"
                    icon="link"
                  />
                  <Heading name="LegislationItem_Docs__Title" inline>
                    {managementSystem.type === 'REPORTING_FRAMEWORK'
                      ? 'Reporting Framework'
                      : 'Management System Breakdown'}
                  </Heading>
                </TitleContainerStyled>
              </FlexRow>
            </ChildHeader>
            <AccordionMenu
              active={chapterId || managementSystem?.id}
              menu={constructNavigationMenu(managementSystem, tree)}
            />
          </MenuContainer>
          <Routes>
            <Route path="" element={managementSystemElement} />
            <Route path=":itemId" element={managementSystemElement} />
          </Routes>
        </ContentContainer>
      )}
    </ContainerStyled>
  );
};

export default ManagementSystemOverviewContainer;
