import { useQuery } from "@apollo/client";
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_MATERIALS_BY_ACCOUNT_ID } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface MaterialSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  accountId: string;
}
export function MaterialSelect({ value, onChange, accountId }: MaterialSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getMaterialsByAccountID'>>(GET_MATERIALS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    }
  });
  return <Select placeholder="Please select material" value={value} options={toSelectOptions(data?.getMaterialsByAccountID)} error={error} isLoading={loading} onChange={onChange} />;
}
