import { useApolloClient } from '@apollo/client';
import { DataType, FilterStrategy, JoinOperator, UserRole } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_USERS } from '../../containers/ConfigurationContainer/ConfigurationUsersContainer/index.queries';

const userToSelectOptions = (user) => {
  return {
    value: user.id.toLowerCase(),
    label: `${user.firstName} ${user.lastName}`,
  };
};

const UserSelect = ({
  accountId,
  value,
  onChange,
  isDisabled = false,
  selectedOption = [],
  error = '',
  isMulti = true,
}) => {
  const apolloClient = useApolloClient();

  return (
    <Select
      isMulti={isMulti}
      name="owner"
      placeholder="Begin typing to find users..."
      multiValueColor="tertiary"
      value={value}
      defaultOptions
      onChange={onChange}
      selectedOption={selectedOption}
      classPrefix="user-select"
      isDisabled={isDisabled}
      error={error}
      loadOptions={async (filterValue) => {
        const {
          data: { getUsers },
        } = await apolloClient.query({
          query: GET_USERS,
          variables: {
            accountId,
            filters: {
              text: [
                {
                  filterType: DataType.Text,
                  field: 'fullName',
                  type: FilterStrategy.Contains,
                  filter: filterValue,
                },
                {
                  filterType: DataType.Text,
                  operator: JoinOperator.Or,
                  condition1: {
                    filterType: DataType.Text,
                    field: 'accountId',
                    type: FilterStrategy.Equals,
                    filter: accountId,
                  },
                  condition2: {
                    filterType: DataType.Text,
                    field: 'role',
                    type: FilterStrategy.Equals,
                    filter: UserRole.Consultant,
                  },
                },
              ],
            },
            sort: [{ field: 'firstName', order: 'asc' }],
          },
        });
        const { totalRows } = getUsers;
        const users = getUsers.rows?.map(userToSelectOptions) || [];

        return { options: users, total: totalRows };
      }}
    />
  );
};

export default UserSelect;
