import React from 'react';
import { Row, Text, CircleIcon } from 'rio-ui-components';
import styled from 'styled-components';

const ManagementSystemRowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${(props) => (props.styleSelected ? props.theme.colors.overlay.light.background : 'none')};
`;

const AreaDetails = styled(Row)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

const ManagementSystemRow = ({ title }) => {
  return (
    <ManagementSystemRowStyled name={`BotRow--${title}`} container itemAlign="center">
      <AreaDetails container item itemAlign="center" distribution="between">
        <Row container item itemAlign="center">
          <CircleIconStyled height="30px" size="md" icon={'pencil-alt'} iconColor={'tertiary'} circleColor={'tertiary'} />
          <Text weight="bold" color="dark" inline name={`BotRow__Title--${title}`} size="md">
            {title}
          </Text>
        </Row>
      </AreaDetails>
    </ManagementSystemRowStyled>
  );
};

export default ManagementSystemRow;
