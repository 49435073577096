import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Label, TextInput } from 'rio-ui-components';
import { Button, Checkbox, CheckboxLabel } from '../../components/AuthPage';
import { Form } from './Form';
import { validateProfileForm } from './validators';

const LabelContainer = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  align-items: center;
`;
const RowStyled = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.xs.spacing};
`;

export function ProfileForm({ onSubmit, form, onChange, intl }) {
  const [errors, setErrors] = useState({});
  return (
    <Form
      onChange={onChange}
      onSubmit={onSubmit}
      form={form}
      validate={validateProfileForm}
      errors={errors}
      setErrors={setErrors}
      name="profile"
    >
      <Row container>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.detailsPage.firstName' })}</Label>
          </LabelContainer>
          <TextInput
            type="text"
            value={form.firstName}
            error={errors.firstName && intl.formatMessage({ id: `login.errors.validation.${errors.firstName}` })}
            autoComplete="given-name"
            name="firstName"
            required
          />
        </ColStyled>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.detailsPage.lastName' })}</Label>
          </LabelContainer>
          <TextInput
            type="text"
            value={form.lastName}
            error={errors.lastName && intl.formatMessage({ id: `login.errors.validation.${errors.lastName}` })}
            autoComplete="family-name"
            name="lastName"
            required
          />
        </ColStyled>
      </Row>
      <Row container>
        <ColStyled item span={12}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.detailsPage.companyName' })}</Label>
          </LabelContainer>
          <TextInput
            type="text"
            value={form.companyName}
            error={errors.companyName && intl.formatMessage({ id: `login.errors.validation.${errors.companyName}` })}
            autoComplete="organization"
            name="companyName"
            required={!form.preferPersonalUse}
          />
        </ColStyled>
      </Row>
      <RowStyled container>
        <Checkbox
          name="preferPersonalUse"
          checked={form.preferPersonalUse}
          onChange={() => {
            onChange({
              ...form,
              preferPersonalUse: !form.preferPersonalUse
            });
          }}
        />
        <CheckboxLabel for="preferPersonalUse">
          {intl.formatMessage({ id: 'login.registration.detailsPage.personalUse' })}
        </CheckboxLabel>
      </RowStyled>
      <Row container>
        <ColStyled item span={6}>
          <Link to="/register">
            <Button type="button" color="info">
              {intl.formatMessage({ id: 'login.registration.detailsPage.backButtonText' })}
            </Button>
          </Link>
        </ColStyled>
        <ColStyled item span={6}>
          <Button type="submit">{intl.formatMessage({ id: 'login.registration.detailsPage.nextButtonText' })}</Button>
        </ColStyled>
      </Row>
    </Form>
  );
}
