import { useEffect } from 'react';
import { useNotification } from '../useNotification';

interface UsePersistToStorageMethods {
  clearStorageItem: () => void;
}

// Should be used to persist value from/to local storage on page mount/unmount accordingly
export const usePersistToStorage = (
  key: string,
  value: any,
  setValue: (val: any) => void
): UsePersistToStorageMethods => {
  const { showNotification } = useNotification();

  // set aliases FROM local storage
  useEffect(() => {
    try {
      const item = localStorage.getItem(key);
      const storageAliases = item ? JSON.parse(item || '') : [];
      if (storageAliases.length) {
        setValue(storageAliases);
      }
    } catch (err) {
      showNotification('Failed to load aliases from storage');
    }
  }, [key, showNotification, setValue]);
  // set aliases TO local storage on unmount
  useEffect(() => {
    return () => {
      localStorage.setItem(key, JSON.stringify(value));
    };
  }, [key, value]);

  return {
    clearStorageItem: () => {
      localStorage.removeItem(key);
    }
  };
};
