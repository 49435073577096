import { useCallback, useState } from 'react';
import { TransactionType, SupplierType } from '@rio/rio-types';
import { Controller, Control } from 'react-hook-form';
import { Checkbox, Text, TextField, Grid, OptionsProps } from '@rio/ui-components';
import { useCurrentAccountId } from '~/hooks';
import { DataForm } from '../DataFormModal/v2';
import { NumberInput } from '../UI/V2';
import { LocationSelect } from '../LocationSelect/v2';
import { SupplierSelector } from '../SuppliersSelector/v2';
import { GridStyled, SectionStyled } from '../WasteDataForm/style';
import { TariffSelectV2 as TariffSelect } from '../TariffSelect';
import { CountrySelectV2 as CountrySelect } from '../CountrySelect';
import { CurrencySelectV2 as CurrencySelect } from '../CurrencySelect';

type Reference = Partial<{
  invoiceNumber: string | number;
  evidenceReference: string | number;
  otherReference: string | number;
}>;

type Data = {
  location: OptionsProps;
  country: OptionsProps;
  dataProvider: OptionsProps;
  electricityTariff: OptionsProps;
  currency: string;
  totalCost: string;
  hotelNights: string;
  homeWorkingDays: string;
  notes: string;
  reference: string;
  invoiceNumber: string;
  evidenceReference: string;
  otherReference: string;
};

type FormData = Partial<{
  data: Data;
  reference: Reference;
}>;

interface FormProps {
  setValue: (field: string, value: OptionsProps | string) => void;
  getValues: (name?: string) => FormData;
  control: Control;
  trigger: () => void;
  register: (field: string) => void;
  watch: (fields: string[]) => boolean[];
}

interface HotelStaysDataFormProps {
  form: FormProps;
  onSubmit: (data: object) => void;
  dataSection: TransactionType;
  children: React.ReactNode;
}

type SubmitProps = {
  data: object;
  reference: object;
};

const quantityRequiredMessage = 'Either Hotel nights either Home working days are required';

export const HotelStaysDataFormV2 = ({ form, onSubmit, children, dataSection }: HotelStaysDataFormProps) => {
  const accountId = useCurrentAccountId();

  const { control, setValue } = form;
  const values = form.getValues();

  const [isReferenceDataRequired, setIsReferenceDataRequired] = useState(false);
  const toggleIsReferenceDataRequired = () => setIsReferenceDataRequired((v) => !v);

  const handleOnSubmit = useCallback(
    ({ data, ...others }: SubmitProps) => {
      const formData = {
        ...others,
        data,
      };
      onSubmit(formData);
    },
    [onSubmit]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form}>
      <Text typescale="title" size="small">
        Please enter the following transactional hotel stays data:
      </Text>

      <Controller
        name="data.location"
        render={({ field, fieldState }) => (
          <LocationSelect
            value={field?.value?.value}
            label="Location"
            onChange={(e) => setValue('data.location', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Location is required' }}
        control={control}
      />

      <Controller
        name="data.country"
        render={({ field, fieldState }) => (
          <CountrySelect
            value={field?.value?.value}
            label="Hotel stay / Home working country"
            onChange={(e) => setValue('data.country', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Country is required' }}
        control={control}
      />

      <Controller
        name="data.hotelNights"
        render={({ field, fieldState }) => (
          <NumberInput label="Hotel nights" {...field} error={fieldState.error?.message} />
        )}
        rules={{ required: !values.data?.homeWorkingDays ? quantityRequiredMessage : false }}
        control={control}
      />

      <Controller
        name="data.homeWorkingDays"
        render={({ field, fieldState }) => (
          <NumberInput {...field} label="Home working days" error={fieldState.error?.message} />
        )}
        rules={{ required: !values.data?.hotelNights ? quantityRequiredMessage : false }}
        control={control}
      />

      <Controller
        name="data.dataProvider"
        render={({ field, fieldState }) => (
          <SupplierSelector
            accountId={accountId}
            value={field?.value?.value}
            label="Data provider"
            onChange={(e) => setValue('data.dataProvider', e)}
            error={fieldState.error?.message}
            utility={dataSection}
            supplierType={SupplierType.Supplier}
          />
        )}
        rules={{ required: 'Data provider is required' }}
        control={control}
      />

      <Controller
        name="data.electricityTariff"
        render={({ field }) => (
          <TariffSelect
            accountId={accountId}
            value={field?.value?.value}
            label="Electricity tariff (optional)"
            onChange={(e) => setValue('data.electricityTariff', e)}
          />
        )}
        control={control}
      />

      <Controller
        name="data.totalCost"
        render={({ field, fieldState }) => (
          <NumberInput {...field} label="Total cost (optional)" error={fieldState.error?.message} />
        )}
        control={control}
      />

      <Controller
        name="data.currency"
        render={({ field }) => (
          <CurrencySelect
            value={field?.value?.value}
            label="Currency (optional)"
            onChange={(e: OptionsProps) => setValue('data.currency', e)}
          />
        )}
        control={control}
      />

      <Controller
        name="data.notes"
        render={({ field }) => <TextField {...field} label="Notes (optional)" />}
        control={control}
      />

      <GridStyled container>
        <Text typescale="title" size="medium">
          Do you want to add any references?
        </Text>
        <Grid container>
          <Checkbox
            name="Reference"
            label="Yes"
            checked={!!isReferenceDataRequired}
            onChange={toggleIsReferenceDataRequired}
          />
          <Checkbox
            name="NoReference"
            label="No"
            checked={!isReferenceDataRequired}
            onChange={toggleIsReferenceDataRequired}
          />
        </Grid>
      </GridStyled>

      <SectionStyled isVisible={isReferenceDataRequired}>
        <Controller
          name="data.reference"
          render={({ field }) => <TextField label="Reference (optional)" {...field} />}
          control={control}
        />

        <Controller
          name="data.invoiceNumber"
          render={({ field }) => <TextField label="Invoice number (optional)" {...field} />}
          control={control}
        />

        <Controller
          name="data.evidenceReference"
          render={({ field }) => <TextField {...field} label="Evidence reference (optional)" />}
          control={control}
        />

        <Controller
          name="data.otherReference"
          render={({ field }) => <TextField {...field} label="Other reference (optional)" />}
          control={control}
        />
      </SectionStyled>

      {children}
    </DataForm>
  );
};
