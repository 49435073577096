import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Heading, Text } from 'rio-ui-components';

const BannerContainer = styled.div`
  ${({ theme, color = 'primary' }) => `
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${theme.colors[color].normal.background};
    padding: ${theme.geometry.sm.spacing};
  `};
`;

const IconStyled = styled(Icon)`
  ${({ theme }) => `
    flex: 0 0 auto;
    margin-right: ${theme.geometry.sm.spacing};
    margin-left: ${theme.geometry.xs.spacing};
    color: ${theme.colors.basic.white};
  `};
`;

const TextContainer = styled.div`
  ${({ theme }) => `
    flex: 1 1 auto;
    margin-right: ${theme.geometry.sm.spacing};
  `};
`;

const DismissStyled = styled(Icon)`
  ${({ theme }) => `
    flex: 0 0 auto;
    margin-right: ${theme.geometry.sm.spacing};
    color: ${theme.colors.basic.white};
    background:transparent;
    &:hover {
      cursor:pointer;
    }
  `};
`;

const NotificationBanner = ({ name, dismissable, onDismiss, title, body, icon, ...rest }) => {
  return (
    <BannerContainer {...rest}>
      {icon && <IconStyled icon={icon} />}
      <TextContainer>
        {title && (
          <Heading size="md" color="white">
            {title}
          </Heading>
        )}
        {body && (
          <Text size="md" color="white">
            {body}
          </Text>
        )}
      </TextContainer>
      {dismissable && <DismissStyled onClick={onDismiss} icon="times" name={`${name}__dismiss-cross`} />}
    </BannerContainer>
  );
};

NotificationBanner.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.string
};

export default NotificationBanner;
