import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { ProjectInput } from '@rio/rio-types';
import { Button, Col, Heading, Modal, Row, ScrollContainer } from 'rio-ui-components';
import styled from 'styled-components';
import ProjectDetails from './ProjectDetails';
import CREATE_PROJECT from '../../../graphql/mutations/projects/CreateProject.mutation.graphql';
import { v4 as uuid } from 'uuid';
import { ValidateProject } from '../Validate/index';
import { Dictionary, NotificationProps } from '../../../types';
import { useSaveEvidenceNotes } from '../useSaveEvidenceNotes';

interface ProjectModalProps {
  accountId: string;
  onComplete: (data: NotificationProps) => void;
  onDismiss: () => void;
  defaultValues?: ProjectInput | Dictionary<any>;
}

const ModalContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
`;

const ColStyled = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.sm.spacing};
`;

const ScrollableContainerStyled = styled(ScrollContainer)`
  ${({ theme }) => `
    overflow: auto;
    padding: ${theme.geometry.xs.spacing};
  `}
`;

const CreateProjectModalComponent = ({ accountId, onDismiss, onComplete, defaultValues }: ProjectModalProps) => {
  const { formatMessage } = useIntl();
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');
  const [createProject] = useMutation(CREATE_PROJECT, {
    onCompleted: () => {
      onComplete({
        message: formatMessage({ id: 'pages.project.messages.created' }),
        color: 'success',
      });
      onDismiss();
    },
    onError: () => {
      onComplete({
        message: formatMessage({ id: 'pages.project.messages.error' }),
        color: 'danger',
      });
    },
  });

  const saveEvidenceNotes = useSaveEvidenceNotes();

  const [project, setProject] = useState<ProjectInput>({
    id: defaultValues?.id || uuid(),
    accountId,
    name: defaultValues?.name || '',
    description: defaultValues?.description || '',
    priority: defaultValues?.priority || null,
    schemeId: defaultValues?.schemeId || null,
    impact: defaultValues?.impact || '',
    cost: defaultValues?.cost || 0,
    currencyId: defaultValues?.currencyId || null,
    baselineStartDate: defaultValues?.baselineStartDate || null,
    startDate: defaultValues?.startDate || null,
    endDate: defaultValues?.endDate || null,
    frequency: defaultValues?.frequency || null,
    plannedBenefit: defaultValues?.plannedBenefit || null,
    plannedCostChange: defaultValues?.plannedCostChange || null,
    plannedCO2eChange: defaultValues?.plannedCO2eChange || null,
    locations: defaultValues?.locations || [],
    owners: defaultValues?.owners || [],
    evidenceNotes: defaultValues?.evidenceNotes || [],
    meters: defaultValues?.meters || [],
  });

  const handleSubmit = async () => {
    const projectEvidenceNotes = await saveEvidenceNotes(evidenceDocuments, evidenceNotes);
    createProject({
      variables: {
        ...project,
        plannedBenefit: +project.plannedBenefit ? +project.plannedBenefit : project.plannedBenefit,
        plannedCO2eChange:
          project.plannedCO2eChange && +project.plannedCO2eChange
            ? Math.abs(+project.plannedCO2eChange)
            : project.plannedCO2eChange,
        plannedCostChange:
          project.plannedCostChange && +project.plannedCostChange
            ? Math.abs(+project.plannedCostChange)
            : project.plannedCostChange,
        createdAt: new Date().toISOString(),
        evidenceNotes: projectEvidenceNotes,
      },
      refetchQueries: ['GetProjects'],
    });
  };

  const validationErrors = ValidateProject(project);
  const isFormValid = !Object.values(validationErrors).some(Boolean);

  return (
    <Modal size="md" show onDismiss={() => onDismiss()}>
      <ModalContainerStyled>
        <HeaderStyled>{formatMessage({ id: 'pages.project.create.header' })}</HeaderStyled>
        <ScrollableContainerStyled>
          <ProjectDetails
            project={project}
            setProject={setProject}
            accountId={accountId}
            isSubmitting={false}
            errors={validationErrors}
            evidenceNotes={evidenceNotes}
            evidenceDocuments={evidenceDocuments}
            setEvidenceDocuments={setEvidenceDocuments}
            setEvidenceNotes={setEvidenceNotes}
          />
        </ScrollableContainerStyled>
        <Row container align="between">
          <ColStyled item>
            <Button data-cy="CreateProjectModal__submit" disabled={!isFormValid} onClick={() => handleSubmit()}>
              <FormattedMessage id="pages.project.create.submit" />
            </Button>
          </ColStyled>
        </Row>
      </ModalContainerStyled>
    </Modal>
  );
};

export const CreateProjectModal = CreateProjectModalComponent;
