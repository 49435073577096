import { ColumnApi, GridApi, Column, ValueFormatterParams, SortModelItem, ColumnState } from 'ag-grid-community';
import { useCallback, useRef, useMemo, useState } from 'react';
import { Button } from 'rio-ui-components';
import { Dictionary, get, map } from 'lodash';
import { Filters, SortCommand } from '@rio/rio-types';
import { useExportApi, ExportPageFn } from '../../hooks';
import { NameYourFileModal } from '../NameYourFileModal';
import { mapFilterModel, mapSortModel } from '../../utils';
import { Nullable } from '../../types';

interface ExportButtonProps {
  label: string;
  fetchRows: ExportPageFn;
  columnApi: ColumnApi | undefined;
  gridApi: GridApi | undefined;
  defaultExportFileName: string;
  context?: Dictionary<unknown>;
  columnsToSkip?: string[];
  step?: number;
  customSortModelMapper?: (columnState: Array<SortModelItem | ColumnState>) => SortCommand[];
  customFilterModelMapper?: (filterModel: Dictionary<unknown>) => Filters;
}

interface FormatterConfig {
  headerName: string;
  valueFormatter: (value: unknown, data: object) => unknown;
}

export function ExportButton({
  label,
  fetchRows,
  columnApi,
  gridApi,
  defaultExportFileName,
  context = {},
  columnsToSkip = [],
  step,
  customFilterModelMapper,
  customSortModelMapper,
}: ExportButtonProps) {
  const [fileNameModalShown, setFileNameModalShown] = useState(false);
  const exportApi = useExportApi();
  const exportId = useRef<Nullable<string>>();

  // useMemo and useCallback below prevent memory leak - please do changes with caution
  const formatters = useMemo(() => {
    const columns = columnApi?.getColumns() || [];
    const valueFormatters: Dictionary<FormatterConfig> = {};

    columns.forEach((column: Column) => {
      const colDef = column.getColDef();

      // skip column on match
      if (columnsToSkip.includes(colDef.headerName!)) {
        return;
      }

      valueFormatters[colDef.field!] = {
        headerName: colDef.headerName!,
        valueFormatter: (value: unknown, data: object) =>
          typeof colDef.valueFormatter === 'function'
            ? colDef.valueFormatter({ value, context, data } as ValueFormatterParams)
            : value,
      };
    });
    return valueFormatters;
  }, [columnApi, context, columnsToSkip]);

  const formatRow = useCallback(
    (row) => {
      const formattedRow: Dictionary<unknown> = {};
      map(formatters, (formatter, key) => {
        const { headerName, valueFormatter } = formatter;
        // used to access nested object property via stringified path
        const value = get(row, key);

        // puts empty data in cell to preserve column order
        formattedRow[headerName!] = valueFormatter(value || '', row);
      });
      return formattedRow;
    },
    [formatters]
  );

  return (
    <>
      <Button
        color="tertiary"
        onClick={() => {
          setFileNameModalShown(true);
        }}
        title="You are about to export your data. Please note that large datasets may take some time to export."
      >
        {label}
      </Button>
      <NameYourFileModal
        show={fileNameModalShown}
        onDismiss={() => {
          setFileNameModalShown(false);
        }}
        defaultFileName={defaultExportFileName}
        onSubmit={(exportFileName) => {
          if (!columnApi || !gridApi) {
            throw new Error('Data is not yet ready for export');
          }
          const columnState = columnApi.getColumnState();

          const filterModel = gridApi.getFilterModel();

          setFileNameModalShown(false);

          const sort = customSortModelMapper ? customSortModelMapper(columnState) : mapSortModel(columnState);

          const filters = customFilterModelMapper ? customFilterModelMapper(filterModel) : mapFilterModel(filterModel);

          exportId.current = exportApi?.start({
            fetchRows,
            exportFileName,
            formatRow,
            step,
            sort,
            filters,
          });
        }}
      />
    </>
  );
}
