import { useCallback, useMemo, useRef } from 'react';
import { DataType } from '@rio/rio-types';
import { Page, Button, CustomIcons, setAgGridLicenseKey } from '@rio/ui-components';
import * as Sentry from '@sentry/react';
import { GridOptions, RowModelType, Column, GridApi, GridReadyEvent } from 'ag-grid-community';
import { mapSortModel, mapFilterModel, toSentenceCase } from '~/utils';
import { usePermissions, useCurrentAccountId, useFiltersApplied } from '~/hooks';
import { ExportButtonV2 } from '../ExportButton';
import { FetchRowsFunction } from './types';
import { ButtonGrid, ButtonGridColumn, AgGridStyled } from './style';
import { getEnvVar } from '../../env';

setAgGridLicenseKey(getEnvVar('REACT_APP_AG_GRID_LICENSE_KEY'));

const defaultConfig: GridOptions = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: [10, 25, 50, 100],
  cacheBlockSize: 25,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

type CrumbDefinition = {
  label: string;
  to?: string;
};

interface TransactionGridProps {
  breadcrumbs: CrumbDefinition[];
  title: string;
  config: GridOptions;
  uploadLink?: string;
  fetchRows: FetchRowsFunction;
  defaultExportFileName?: string;
}

export const TransactionGridV2 = ({
  title,
  breadcrumbs,
  config: { columnDefs, ...agGridConfig },
  uploadLink,
  fetchRows,
  defaultExportFileName,
}: TransactionGridProps) => {
  const agGridApi = useRef<GridApi>();
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { hasFiltersApplied, updateClearFilter } = useFiltersApplied(agGridApi);

  const resetFilters = useCallback(() => {
    if (!agGridApi.current) {
      return;
    }
    const api: GridApi = agGridApi.current!;

    api.setFilterModel(null);
    api.getColumns()?.forEach((column: Column) => {
      api.setColumnsVisible([column?.getColId()], true);
    });
  }, [agGridApi]);

  const allowedToExport = useMemo(
    () => !!permissions.data.find((action: string) => action.startsWith('exportTransactions')),
    [permissions]
  );

  const pageTitle = useMemo(
    () => ({
      crumbs: breadcrumbs,
      content: title,
      actionButton: (
        <ButtonGrid wrap="wrap" container xs={6}>
          <ButtonGridColumn lg={3.5} md={6}>
            <Button
              startIcon={<CustomIcons.Clear width="18px" height="18px" color="onSecondary" />}
              variant="filled"
              color="secondary"
              onClick={resetFilters}
              disabled={!hasFiltersApplied}
            >
              Clear filters
            </Button>
          </ButtonGridColumn>
          {allowedToExport && !!defaultExportFileName && (
            <ButtonGridColumn lg={3.5} md={6}>
              <ExportButtonV2
                fetchRows={fetchRows}
                gridApi={agGridApi.current}
                defaultExportFileName={defaultExportFileName}
              />
            </ButtonGridColumn>
          )}
          {!!uploadLink && (
            <ButtonGridColumn lg={3.5} md={6}>
              <Button
                variant="filled"
                to={uploadLink}
                startIcon={<CustomIcons.Upload width="18px" height="18px" color="onPrimary" />}
              >
                Upload
              </Button>
            </ButtonGridColumn>
          )}
        </ButtonGrid>
      ),
    }),
    [
      breadcrumbs,
      title,
      resetFilters,
      hasFiltersApplied,
      allowedToExport,
      defaultExportFileName,
      fetchRows,
      uploadLink,
      agGridApi.current,
    ]
  );

  const gridOptions = useMemo(
    () => ({
      ...defaultConfig,
      ...agGridConfig,
      columnDefs: columnDefs?.map((d) => ({
        ...d,
        headerName: d.headerName ? toSentenceCase(d.headerName) : d.headerName,
      })),
      onGridReady: (e: GridReadyEvent) => {
        agGridApi.current = e.api;
        updateClearFilter();
      },
      serverSideDatasource: {
        async getRows(params) {
          try {
            const { startRow = 0, endRow = 25, sortModel, filterModel } = params.request;
            for (const columnName in filterModel) {
              if (filterModel[columnName].filterType === DataType.Set) {
                const filterValues = filterModel[columnName].values;
                if (filterValues.includes(null)) {
                  filterValues.push(' ');
                }
              }
            }
            const { rows, totalRows } = await fetchRows({
              accountId,
              offset: startRow,
              limit: endRow - startRow,
              sort: mapSortModel(sortModel),
              filters: mapFilterModel(filterModel),
            });
            params.success({
              rowData: rows,
              rowCount: totalRows,
            });
          } catch (err) {
            params.fail();
            Sentry.captureException(err);
          }
        },
      },
    }),
    // eslint-disable-next-line
    [updateClearFilter]
  );

  return (
    <Page title={pageTitle}>
      <AgGridStyled gridKey={`${defaultExportFileName}GridV2`} gridOptions={gridOptions} />
    </Page>
  );
};
