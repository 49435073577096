import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Col, ErrorMessage, ContainerError, Search } from 'rio-ui-components';
import { useLocation } from 'react-router-dom';
import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_ACCOUNTS } from '../../../components/AccountsMultiSelect/index.queries';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import AccountRow from './AccountRow';
import alphabetiseStrings from '../../../utils/alphabetise';

const ConfigurationIntelligenceSubscriptionsContainer = () => {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { loading, error, data, refetch, networkStatus } = useQuery(GET_ACCOUNTS, {
    variables: {
      limit: 100,
      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: search,
            field: 'name'
          }
        ]
      }
    }
  });

  const searched = data?.getAccounts.rows || [];

  return (
    <>
      <Col name="ConfigurationAccountsContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationIntelligenceSubscriptionsContainer__Controls"
          icon="briefcase"
          iconColor="primary"
          title="Intelligence Subscriptions"
        >
          <Col container item>
            <Search
              name="ConfigurationIntelligenceSubscriptionsContainer__Controls__Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              hideButton
            />
          </Col>
        </ContainerHeader>

        {((loading && networkStatus !== 3) || networkStatus === 4) && (
          <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />
        )}
        {error && (
          <ErrorMessage error={error}>
            {({ title, body, icon }) => (
              <ContainerError
                name="ConfigurationAccountsContainer__Error"
                icon={icon}
                title={title}
                body={body}
                retry={refetch}
              />
            )}
          </ErrorMessage>
        )}

        {!loading && !searched.length && (
          <NoResults
            name="ConfigurationIntelligenceSubscriptionsContainer__NoResults"
            title="There are no results for this search."
          />
        )}

        {searched
          .slice()
          .sort((accountA, accountB) => alphabetiseStrings(accountA.name, accountB.name))
          .map((account) => {
            return (
              <AccountRow
                key={account.id}
                accountId={account.id}
                businessName={account.name}
                profileImage={account.profile}
                path={location.pathname}
              />
            );
          })}
      </Col>
    </>
  );
};

export default ConfigurationIntelligenceSubscriptionsContainer;
