import { useSubscription } from '@apollo/client';
import { useCurrentAccountId } from '../useCurrentAccountId';
import { useNotification } from '../useNotification';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../usePermissions';
import { useRoutes } from '../useRoutes';
import SUBSCRIPTION from '../../graphql/subscriptions/licence/LicencesPaidSubscription.subscription.graphql';

export function useLicencesPaidSubscription() {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const routes = useRoutes();
  const { licenceService, isSystemAdmin } = usePermissions();

  const { data: { licencesPaid } = {}, ...others } = useSubscription(SUBSCRIPTION, {
    variables: { id: accountId },
    skip: !accountId,
    onSubscriptionData: () => {
      if (isSystemAdmin) {
        return;
      }

      if (licenceService.assignLicence) {
        showNotification('You have new licences', undefined, {
          callback: () => {
            navigate(routes.configuration.learn.subscriptions);
          },
          text: 'Configure',
          autoClose: false,
        });
      }
    },
  });

  return {
    data: licencesPaid,
    ...others,
  };
}
