import { Query, Mutation, Maybe } from '@rio/rio-types';
import { ApolloCache } from '@apollo/client';
import GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID from '../../../../graphql/queries/surveys/GetSurveyTemplatesByAccountId.query.graphql';

type SubscribeResult = Pick<Mutation, 'createSurveyTemplateSubscription'>;

type UnsubscribeResult = Pick<Mutation, 'deleteSurveyTemplateSubscription'>;

type AccountQueryResult = Pick<Query, 'getSurveyTemplatesByAccountId'>;

export const addSurveyTemplateToAccountQuery = (cache: ApolloCache<unknown>, result?: Maybe<SubscribeResult>) => {
  if (!result?.createSurveyTemplateSubscription) {
    return;
  }
  const newSubscription = result.createSurveyTemplateSubscription;

  const accountQuery = {
    query: GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID,
    variables: {
      accountId: newSubscription.account.id,
    },
  };
  const accountQueryResult = cache.readQuery<AccountQueryResult>(accountQuery);

  if (accountQueryResult) {
    const currentTemplates = accountQueryResult.getSurveyTemplatesByAccountId;

    const data = {
      getSurveyTemplatesByAccountId: Array.isArray(currentTemplates)
        ? currentTemplates.concat(newSubscription.template)
        : [newSubscription.template],
    };

    cache.writeQuery({
      ...accountQuery,
      data,
    });
  }
};

export const deleteSurveyTemplateFromAccountQuery = (
  cache: ApolloCache<unknown>,
  result?: Maybe<UnsubscribeResult>
) => {
  if (!result?.deleteSurveyTemplateSubscription) {
    return;
  }
  const deletedSubscription = result.deleteSurveyTemplateSubscription;

  const accountQuery = {
    query: GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID,
    variables: {
      accountId: deletedSubscription.account.id,
    },
  };
  const accountQueryResult = cache.readQuery<AccountQueryResult>(accountQuery);

  if (accountQueryResult) {
    const currentTemplates = accountQueryResult.getSurveyTemplatesByAccountId;

    const data = {
      getSurveyTemplatesByAccountId: Array.isArray(currentTemplates)
        ? currentTemplates.filter((t) => t.id !== deletedSubscription.template.id)
        : [],
    };

    cache.writeQuery({
      ...accountQuery,
      data,
    });
  }
};
