import { Option } from '../../types';

type PriorityOption = {
  id: string;
  name: string;
  icon: string;
  colour: string;
} & Option;

export const categoryColor: Record<string, string> = {
  'Waste performance improvement category (t)': 'quaternary',
  'Energy generation category (kwh)': 'secondary',
  'Electricity reduction category (kwh)': 'secondary',
  'Transport emissions reduction category (km)': 'tertiary',
  'Gas reduction category (kwh)': 'primary',
  'Water efficiency category (m3)': 'secondary',
  default: 'primary'
};

export const unitsByCategory: Record<string, string> = {
  'Waste performance improvement category (t)': 'tonnes',
  'Energy generation category (kwh)': 'kwh',
  'Electricity reduction category (kwh)': 'kwh',
  'Transport emissions reduction category (km)': 'km',
  'Gas reduction category (kwh)': 'kwh',
  'Water efficiency category (m3)': 'm3',
  default: ''
};

export const frequencyOptions: Option[] = [
  { label: 'QUARTER-ANNUAL', value: 'QUARTER-ANNUAL' },
  { label: 'SEMI-ANNUAL', value: 'SEMI-ANNUAL' },
  { label: 'ANNUAL', value: 'ANNUAL' }
];

export const priorityOptions: PriorityOption[] = [
  {
    id: 'HIGH',
    name: 'High',
    icon: 'exclamation-triangle',
    colour: 'danger',
    label: 'High',
    value: 'HIGH'
  },
  {
    id: 'MEDIUM',
    name: 'Medium',
    icon: 'exclamation-circle',
    colour: 'warning',
    label: 'Medium',
    value: 'MEDIUM'
  },
  {
    id: 'LOW',
    name: 'Low',
    icon: 'exclamation-circle',
    colour: 'text',
    label: 'Low',
    value: 'LOW'
  }
];
