"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const notistack_1 = require("notistack");
const Toast_1 = require("./Toast");
const ToastProvider = ({ children }) => {
    return ((0, jsx_runtime_1.jsx)(notistack_1.SnackbarProvider, { Components: { rioToastV2: Toast_1.Toast }, autoHideDuration: null, preventDuplicate: true, anchorOrigin: { vertical: 'top', horizontal: 'right' }, maxSnack: 5, children: children }));
};
exports.ToastProvider = ToastProvider;
