import { Row, Button } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import AccountDropdown from './AccountDropdown';
import RioLogo from '../../assets/img/RioLogo2021.png';

const ContainerStyled = styled(Row)`
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.colors.basic.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  position: relative;
`;

const LogoContainerLink = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoContainer = styled.div`
  position: relative;
  background: no-repeat center center / contain url(${RioLogo});
  height: 44px;
  width: 88px;
  background-size: 88px 44px;
`;
const UpdateAccountLink = styled(NavLink)``;

const UpdateAccount = styled(Button)`
  display: inline-block;
  max-width: 160px;
  font-size: 14px;
  width: auto;
  padding: 12px 10px;
  margin-right: ${({ theme }) => theme.geometry.md.spacing};
  position: relative;
`;

const Header = () => {
  return (
    <ContainerStyled container distribution="center" itemAlign="center">
      <LogoContainerLink to="/home">
        <LogoContainer />
      </LogoContainerLink>
      <UpdateAccountLink to="/upgrade/courses">
        <UpdateAccount name="AccountDropdown__Upgrade">Buy Courses</UpdateAccount>
      </UpdateAccountLink>
      <AccountDropdown />
    </ContainerStyled>
  );
};

export default Header;
