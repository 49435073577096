import React from 'react';
import styled from 'styled-components';
import { Heading, Row, Col, CircleIcon, Icon } from 'rio-ui-components';
import { COMPLETE, INPROGRESS } from '../../constants/courseProgress';

const ContainerStyled = styled(Col)`
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CourseImage = styled.div`
  position: relative;
  padding: 0 0 75% 0;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.secondary.dark.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
`;

const TextContainer = styled(Col)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${({ theme }) => theme.colors.basic.white};
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-flex;
  flex: 0 0 auto;
`;

const CompletedIconStyled = styled(Icon)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.success.normal.background};
`;

const InProgressIconStyled = styled(Icon)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.primary.normal.background};
`;

export const FadedCourse = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
`;

export const LockIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  display: inline-flex;
  flex: 0 0 auto;
  font-size: 23px;
`;

const CourseTile = ({ learnAccess, courseId, title, image, courseProgress, restricted }) => {
  return (
    <ContainerStyled name={`CourseTile CourseTile--${courseId}`} container item disabled={!learnAccess}>
      <CourseImage name="CourseTile__Thumbnail" src={image}>
        {!image && (
          <CircleIconStyled
            name="CourseTile__Icon"
            height="100px"
            size="lg"
            icon="graduation-cap"
            iconColor="white"
            circleColor="white"
          />
        )}
        {restricted ? (
          <FadedCourse>
            <LockIcon icon="lock" color="#ababab" />
          </FadedCourse>
        ) : null}
      </CourseImage>
      <TextContainer container item vdistribution="between">
        <Row container item itemAlign="center">
          <Heading name="CourseTile__Title" size="lg" color="dark" weight="light">
            {title}
          </Heading>
          {courseProgress === COMPLETE && <CompletedIconStyled icon="check" size="lg" />}
          {courseProgress === INPROGRESS && <InProgressIconStyled icon="clock" size="lg" />}
        </Row>
      </TextContainer>
    </ContainerStyled>
  );
};

export default CourseTile;
