import React from 'react';
import styled from 'styled-components';
import { Row, Heading, Text, Icon } from 'rio-ui-components';

const ContainerStyled = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  overflow: hidden;
`;

const TextContainer = styled.div`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const TextStyled = styled(Text)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const ChevronStyled = styled(Text)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const Preview = styled.div`
  width: 192px;
  height: 142px;
  display: block;
  background: ${({ theme }) => theme.colors.overlay.light.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
  flex: 0 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
`;

const ReportRow = ({ reportId, title, type, description, thumbnail }) => {
  return (
    <ContainerStyled name={`ReportRow ReportRow--${reportId}`} container itemAlign="center">
      <Preview name="ReportRow__Thumbnail" src={thumbnail} />
      <Row container item distribution="between" itemAlign="center">
        <TextContainer>
          <HeadingStyled size="lg" weight="normal" color="dark" name="ReportRow__Title">
            {title}
          </HeadingStyled>
          <TextStyled name="ReportRow__Category" size="md" color="normal">
            Category: {type}
          </TextStyled>
          <Text name="ReportRow__LastModified" size="md" color="normal">
            Description: {description}
          </Text>
        </TextContainer>
        <ChevronStyled size="lg" color="normal">
          <Icon icon="chevron-right" />
        </ChevronStyled>
      </Row>
    </ContainerStyled>
  );
};

export default ReportRow;
