import { useQuery } from '@apollo/client';
import React from 'react';
import { Select } from 'rio-ui-components';
import { GET_TAGS_BY_ACCOUNT_ID } from './index.queries';

function TagSelect({ accountId, value, onChange, error, additionalOptions = [] }) {
  const { data } = useQuery(GET_TAGS_BY_ACCOUNT_ID, {
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });
  const tags = data?.getTagsByAccountId || [];
  const mappedTags = tags.map((tag) => ({
    value: tag.id,
    label: tag.tagName
  }));

  return (
    <Select
      name="tagId"
      placeholder="Begin typing to find tags"
      value={value}
      options={[...additionalOptions, ...mappedTags]}
      error={error}
      onChange={onChange}
    />
  );
}

export { TagSelect };
