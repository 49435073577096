import { gql } from '@apollo/client';

export const ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM = gql`
  mutation attachDocument($input: AttachDocumentInput!) {
    attachDocumentToManagementSystem(input: $input)
  }
`;

export const ATTACH_CONTENT_TO_MANAGEMENT_SYSTEM = gql`
  mutation attachContent($input: AttachContentInput!) {
    attachContentToManagementSystem(input: $input)
  }
`;
