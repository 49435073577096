import React from 'react';
import { ConfigurationAliases } from './index';
import { AliasType } from '@rio/rio-types';

const AliasTypes = [
  { aliasType: AliasType.Wastestream, label: 'Waste Stream' },
  { aliasType: AliasType.Wastein, label: 'Waste In Location' },
  { aliasType: AliasType.Wasteout, label: 'Waste Out Location' },
  { aliasType: AliasType.Containertypesize, label: 'Container Type' },
  { aliasType: AliasType.Wastetreatmentprocess, label: 'Treatment Process' },
  { aliasType: AliasType.Wastecontractor, label: 'Waste Contractor' },
  { aliasType: AliasType.Wastecarrier, label: 'Waste Carrier' }
];

function ConfigurationAliasesWaste({ accountId }) {
  return <ConfigurationAliases accountId={accountId} aliasTypes={AliasTypes} />;
}
export default ConfigurationAliasesWaste;
