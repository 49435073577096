import React from 'react';
import { Text } from 'rio-ui-components';
import { formatDate } from '../../../utils';

export function CompletedDateCell(props) {
  const { data: { completedAt } = {} } = props;

  const date = formatDate(completedAt);

  return completedAt && <Text align="left">{date}</Text>;
}
