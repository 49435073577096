import gql from 'graphql-tag';
import CHAPTER_FIELDS from './ChapterFields.fragment';

export default gql`
  fragment ChapterFieldsNesting on Chapter {
    ... on ManagementSystemChapter {
        chapters {
            ...ChapterFields
            ... on ManagementSystemChapter {
                chapters {
                    ...ChapterFields
                }
            }
        }
    }
   
  }
  ${CHAPTER_FIELDS}
`;
