import { useQuery } from '@apollo/client';
import { OptionsProps } from '@rio/ui-components';
import { Nullable, Option } from '~/types';
import { useCurrentAccountId } from '~/hooks';
import { mapLocationToOption } from '../utils';
import { GET_LOCATIONS_BY_ACCOUNT } from '../index.queries';
import { BaseLocationSelect, order } from './BaseLocationSelect';

interface LocationSelectProps {
  value?: OptionsProps;
  onChange: (e: OptionsProps) => void;
  error?: Nullable<Error | string>;
  createNew?: boolean;
  name?: string;
  createNewModalSize?: number;
  label?: string;
}

export const LocationSelect = ({ value, name, onChange, error, label, createNew }: LocationSelectProps) => {
  const accountId = useCurrentAccountId();

  const { data, refetch } = useQuery(GET_LOCATIONS_BY_ACCOUNT, {
    variables: {
      accountId,
      sort: order,
      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: '',
            field: 'name',
          },
        ],
      },
      limit: 2500,
    },
  });

  return (
    <BaseLocationSelect
      value={value}
      name={name}
      onChange={onChange}
      label={label}
      accountId={accountId}
      error={error}
      refetch={refetch}
      createNew={createNew}
      isLocate
      loadOptions={async () => {
        const options = data?.getLocationsPage?.rows
          ? (data.getLocationsPage.rows.map(mapLocationToOption) as Option[])
          : [];
        return { options, total: data.getLocationsPage.totalRows };
      }}
    />
  );
};
