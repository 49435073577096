import { gql } from "@apollo/client";

export const GET_MATERIALS_BY_ACCOUNT_ID = gql`
  query getMaterialsByAccountID($accountId: ID!) {
    getMaterialsByAccountID(accountId: $accountId) {
      id
      name
    }
  }
`;
