import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { useCurrentAccount } from '../../hooks';
import { PageNotFound } from '../../components/Errors';
import NotificationBanner from '../../components/NotificationBanner';
import FlowsHome from './FlowsHome';

const ContainerStyled = styled.div`
  height: 100%;
  overflow: visible;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  z-index: 3;
  position: relative;
`;

const FlowsContainer = () => {
  const account = useCurrentAccount();
  const hasSubscription = account ? account.accessControls.flows : false;

  return (
    <>
      {!hasSubscription && (
        <>
          <Overlay />
          <NotificationBannerStyled
            name="FormsContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title="Flows"
            body="Currently Flows are not included in your subscription, if you’d like access to Flows please contact Rio ESG LTD or get in touch with your Business Admin."
          />
        </>
      )}
      {hasSubscription && (
        <ContainerStyled>
          <Routes>
            <Route path="/" element={<FlowsHome />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ContainerStyled>
      )}
    </>
  );
};

export default FlowsContainer;
