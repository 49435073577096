import { gql } from '@apollo/client';

export const GET_ACCOUNT_SECTORS = gql`
  query GetAccountSectors {
    getAccountSectors {
      value: id
      label: name
    }
  }
`;
