import React, { useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Row, Col, ErrorMessage, ContainerError, Button, Notification } from 'rio-ui-components';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import withPermissions from '../../../hocs/withPermissions';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import WasteHierarchyContainer from './WasteHierarchyContainer';
import { Modals } from './Modals';
import { WasteInSelect } from './WasteInSelect';
import { GET_WASTE_HIERARCHIES_DATA } from './index.queries';
import {
  getWasteHierarchiesFromData,
  getWasteInLocationsFromData,
  filterWasteHierarchiesByWasteIn,
  sortHiearachiesInView
} from './dataAccessors';

const RowStyled = styled(Row)`
  margin-left: auto;
`;

const StyledCol = styled(Col)`
  margin-left: auto;
`;

const SelectCol = styled(Col)`
  padding-right: 32px;
`;

const isInitialLoad = (networkStatus) => {
  return networkStatus < NetworkStatus.refetch;
};

function ConfigurationWasteHierarchiesContainer() {
  const [state, setState] = useState({
    showCreateModal: false,
    viewHierarchyModal: {
      show: false,
      hierarchy: {}
    },
    notification: {
      message: null,
      color: null,
      id: null
    }
  });
  const [currentWasteIn, setCurrentWasteIn] = useState('Default');
  const onCreateClick = () => {
    setState((prevState) => ({
      ...prevState,
      showCreateModal: true
    }));
  };
  const onDismiss = () => {
    setState((prevState) => ({
      ...prevState,
      showCreateModal: false,
      viewHierarchyModal: { show: false, hierarchy: null }
    }));
  };
  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: {
        message: null,
        color: null,
        id: null
      }
    }));
  };
  const onNotification = (message, color, id) => {
    setState((prevState) => ({
      ...prevState,
      notification: {
        message,
        color,
        id
      }
    }));
  };
  const onShowWasteHierarchyModal = (hierarchy) => {
    setState((prevState) => ({
      ...prevState,
      viewHierarchyModal: {
        show: true,
        hierarchy
      }
    }));
  };
  const accountId = useCurrentAccountId();
  const variables = {
    accountId
  };
  const { data, error, refetch, networkStatus } = useQuery(GET_WASTE_HIERARCHIES_DATA, {
    variables
  });
  const isLoading = isInitialLoad(networkStatus);
  const wasteHierarchies = getWasteHierarchiesFromData(data);
  const wasteInLocations = getWasteInLocationsFromData(data);
  const hierarchiesOfThisWasteInLocation = filterWasteHierarchiesByWasteIn(wasteHierarchies, currentWasteIn)
    .slice()
    .sort(sortHiearachiesInView);
  return (
    <Col container fullHeight>
      {state.notification.message && (
        <Notification
          color={state.notification.color}
          onClose={hideNotification}
          name={state.notification.id}
          show
          preventDuplicate
        >
          {state.notification.message}
        </Notification>
      )}
      <Modals
        showCreateModal={state.showCreateModal}
        onDismiss={onDismiss}
        onNotification={onNotification}
        accountId={accountId}
        viewHierarchyModal={state.viewHierarchyModal}
        currentWasteIn={currentWasteIn === 'Default' ? null : wasteInLocations.find((w) => w.id === currentWasteIn)}
      />
      <ContainerHeader icon="trash-alt" iconColor="primary" title="Waste Hierarchies">
        <RowStyled span={9} item container>
          <SelectCol item span={6}>
            <WasteInSelect wasteInLocations={wasteInLocations} onChange={setCurrentWasteIn} value={currentWasteIn} />
          </SelectCol>
          <StyledCol item span={6}>
            <Button onClick={onCreateClick}>+ Set Custom %s</Button>
          </StyledCol>
        </RowStyled>
      </ContainerHeader>
      {isLoading && <ContainerLoadingIndicator />}
      {!isLoading && error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError icon={icon} title={title} body={body} retry={() => refetch(variables)} />
          )}
        </ErrorMessage>
      )}
      {!isLoading && wasteHierarchies.length && (
        <WasteHierarchyContainer
          wasteTreatmentProcessHierarchies={hierarchiesOfThisWasteInLocation}
          showViewModal={onShowWasteHierarchyModal}
        />
      )}
    </Col>
  );
}

export default withPermissions(ConfigurationWasteHierarchiesContainer);
