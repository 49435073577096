import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import TasksGridContainer from './TasksGrid';
import TasksViewContainer from './TaskView';

const ContainerStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TasksRoutes = ({ hasProjectsAccess }: { hasProjectsAccess: boolean }) => {
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<TasksGridContainer hasProjectsAccess={hasProjectsAccess} />} />
        <Route path="/:id" element={<TasksViewContainer />} />
      </Routes>
    </ContainerStyled>
  );
};

export default TasksRoutes;
