import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading, TextArea, Select, DatePicker } from 'rio-ui-components';
import { GET_NORMALISER_TYPES, GET_LOCATION_BY_ID } from './index.queries';
import { LocationSelect } from '../../../components/LocationSelect';
import { TagSelect } from '../../../components/TagSelect';
import { scopeOptions, ACCOUNT, LOCATION, TAG } from '../../../constants/scopes';
import { alphabetiseByField } from '../../../utils/alphabetise';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const StartDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -354px;
    left: 50%;
  }
`;

const EndDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -354px;
    left: 50%;
  }
`;

function NormaliserDetails({ heading, accountId, isSubmitting, setValues, values, errors }) {
  const { data: normaliserTypesData } = useQuery(GET_NORMALISER_TYPES);
  const normaliserTypes =
    normaliserTypesData?.getNormaliserTypes
      ?.map(({ id, name, scopes }) => ({ label: name, value: id, scopes }))
      .sort(alphabetiseByField('label')) || [];

  const selectedType = normaliserTypes.find(({ value }) => value === values.type);

  const scopes = scopeOptions.filter(({ value }) => selectedType?.scopes.includes(value)) || [];

  const { data: locationData, loading: loadingSelectedOption } = useQuery(GET_LOCATION_BY_ID, {
    variables: { id: values.locationId },
    skip: !values.locationId
  });

  const handleChange = (e) => {
    if (isSubmitting) {
      return;
    }
    const target = e.target;
    const value = target.type === 'select' ? target.select.value : target.value;

    setValues({ [e.target.name]: value });
  };

  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Normaliser Name</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            disabled={isSubmitting}
            onChange={handleChange}
            name="name"
            value={values.name}
            error={errors.name}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Normaliser Type</LabelStyled>
          </LabelContainerStyled>
          <Select
            name="type"
            disabled={isSubmitting}
            onChange={(e) => {
              handleChange(e);

              const selectedType = normaliserTypes.find(({ value }) => value === e.target.select.value);
              if (!selectedType.scopes.includes(values.scope)) {
                setValues({ scope: null });
              }
            }}
            value={values.type}
            error={errors.type}
            options={normaliserTypes}
          />
        </ColStyled>
      </Row>
      {!!values.type && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Normaliser Scope</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="scope"
              disabled={isSubmitting}
              onChange={(e) => {
                handleChange(e);

                if (e.target.select.value === ACCOUNT) {
                  setValues({ locationId: null, tagId: null });
                }

                if (e.target.select.value === TAG) {
                  setValues({ locationId: null });
                }

                if (e.target.select.value === LOCATION) {
                  setValues({ tagId: null });
                }
              }}
              value={values.scope}
              error={errors.scope}
              options={scopes}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Description</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            disabled={isSubmitting}
            onChange={handleChange}
            name="description"
            value={values.description}
            error={errors.description}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Quantity</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            disabled={isSubmitting}
            onChange={handleChange}
            name="quantity"
            value={values.quantity}
            error={errors.quantity}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Start Date</LabelStyled>
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date) => {
              if (date) {
                handleChange({
                  target: {
                    value: date,
                    name: 'startDate'
                  }
                });
              }
            }}
            name="startDate"
            selectedDate={values.startDate}
            disabledDatesAfter={values.endDate}
            error={errors.startDate}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>End Date</LabelStyled>
          </LabelContainerStyled>
          <EndDatePicker
            disabled={isSubmitting}
            onDayClick={(date) => {
              if (date) {
                handleChange({
                  target: {
                    value: date,
                    name: 'endDate'
                  }
                });
              }
            }}
            name="endDate"
            selectedDate={values.endDate}
            disabledDatesBefore={values.startDate}
            error={errors.endDate}
            box
          />
        </ColStyled>
      </Row>
      {values.scope === LOCATION && !loadingSelectedOption && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Location</LabelStyled>
            </LabelContainerStyled>
            <LocationSelect
              accountId={accountId}
              value={values.locationId}
              onChange={handleChange}
              error={errors.locationId}
              disabled={isSubmitting}
              selectedOption={locationData?.getLocationById}
            />
          </ColStyled>
        </Row>
      )}
      {values.scope === TAG && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Tag</LabelStyled>
            </LabelContainerStyled>
            <TagSelect
              accountId={accountId}
              value={values.tagId}
              onChange={handleChange}
              error={errors.tagId}
              disabled={isSubmitting}
            />
          </ColStyled>
        </Row>
      )}
    </>
  );
}

export default NormaliserDetails;
