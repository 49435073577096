import { useState, useCallback, useMemo } from 'react';
import { Col, Button, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { Survey } from '@rio/rio-types';
import ContainerHeader from '../../../components/ContainerHeader';
import { CreateSurvey } from '../SurveysContainer/CreateSurvey';
import { UpdateSurvey } from '../SurveysContainer/UpdateSurvey';
import { SurveyFormProvider } from '../SurveysContainer/provider/SurveyFormProvider';

const ActionsColumn = styled(Col)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
`;
const CreateSurveyButton = styled(Button)`
  align-self: flex-end;
  width: 200px;
`;
const EditSurveyButton = styled(Button)`
  width: 150px;
  background-color: ${(props) => props.theme.colors.basic.white};
  color: ${(props) => props.theme.colors.primary.light.text};
  border-color: ${(props) => props.theme.colors.primary.light.text};
  border-width: 1px;
`;

const defaultBreadcrumbs = [
  {
    to: '../sent/overview',
    title: 'Surveys sent',
  },
];

interface SurveyOverviewContainerHeaderProps {
  survey?: Survey;
  onDelete?: () => void;
}

export function SurveyOverviewContainerHeader({ survey, onDelete }: SurveyOverviewContainerHeaderProps) {
  const [createModalShown, setCreateModalShown] = useState(false);
  const [editModalShown, setEditModalShown] = useState(false);
  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const toggleEditModal = useCallback(() => setEditModalShown((shown) => !shown), []);

  const breadcrumbs = useMemo(() => {
    if (survey) {
      return [
        ...defaultBreadcrumbs,
        {
          to: '../sent/surveys',
          title: 'All surveys',
        },
        {
          title: survey.name,
        },
      ];
    }
    return defaultBreadcrumbs;
  }, [survey]);

  return (
    <>
      <ContainerHeader
        name="SurveysOverview"
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title={survey ? survey.name : 'Overview'}
        breadcrumb={breadcrumbs}
      >
        <ActionsColumn span={9} container>
          {survey && (
            <EditSurveyButton id="EditSurveyButton" onClick={toggleEditModal}>
              Edit survey
            </EditSurveyButton>
          )}
          <CreateSurveyButton id="CreateSurveyButton" onClick={toggleCreateModal}>
            + Create a survey
          </CreateSurveyButton>
        </ActionsColumn>
      </ContainerHeader>
      {editModalShown && survey && (
        <Modal size="xl" onDismiss={toggleEditModal} dismissable enableEscapeKey show>
          <SurveyFormProvider survey={survey}>
            <UpdateSurvey existingSurvey={survey} onDismiss={toggleEditModal} onDelete={onDelete} />
          </SurveyFormProvider>
        </Modal>
      )}
      {createModalShown && (
        <Modal size="xl" onDismiss={toggleCreateModal} dismissable enableEscapeKey show>
          <SurveyFormProvider>
            <CreateSurvey
              onDismiss={toggleCreateModal}
              title="Create a survey"
              submitButtonText="Create Survey"
              buttonDescription="This will send your surveys to your recipients"
            />
          </SurveyFormProvider>
        </Modal>
      )}
    </>
  );
}
