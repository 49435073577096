import merge from 'lodash/merge';
import { resolvers as authResolvers, defaults as authDefaults } from './auth';
import { resolvers as uploadResolvers } from './upload';

import { wasteContractorsGridResolvers, wasteContractorsGridDefaults } from './WasteContractorsGrid/';
import { suppliersGridResolvers, suppliersGridDefaults } from './SuppliersGrid/';
import { wasteStreamGridResolvers, wasteStreamGridDefaults } from './WasteStreamGrid';
import { resolvers as documentUploadResolvers } from './DocumentUpload/';
import { resolvers as learnContentUploadResolvers } from './LearnContentUpload';

import { resolvers as currentAccountResolvers, defaults as currentAccountDefaults } from './currentAccount';

export const resolvers = merge(
  authResolvers,
  uploadResolvers,
  wasteContractorsGridResolvers,
  suppliersGridResolvers,
  documentUploadResolvers,
  wasteStreamGridResolvers,
  learnContentUploadResolvers,
  currentAccountResolvers
);

export const defaults = [
  currentAccountDefaults,
  ...authDefaults,
  ...wasteContractorsGridDefaults,
  ...suppliersGridDefaults,
  ...wasteStreamGridDefaults,
];
