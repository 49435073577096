import { Course, CourseProgress, LearnBundleEngage } from '@rio/rio-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useCurrentAccount } from '~/hooks';

export const isExplore = (c) =>
  !c.userCourseMetaData ||
  (c.userCourseMetaData &&
    ![CourseProgress.Inprogress, CourseProgress.Complete].includes(c.userCourseMetaData.courseProgress));

export const isContinue = (c) =>
  c.userCourseMetaData && c.userCourseMetaData.courseProgress === CourseProgress.Inprogress;

export const isComplete = (c) =>
  c.userCourseMetaData && c.userCourseMetaData.courseProgress === CourseProgress.Complete;

export const sortByDateDesc = (a, b) =>
  a.userCourseMetaData?.lastUpdatedAt > b.userCourseMetaData?.lastUpdatedAt
    ? -1
    : a.userCourseMetaData?.lastUpdatedAt < b.userCourseMetaData?.lastUpdatedAt
    ? 1
    : 0;

interface CoursesSection {
  title: string;
  bundleId?: string;
  description?: string;
  courses: Course[];
}
export function useCoursesSections(courses: Course[] = [], bundles: LearnBundleEngage[] = []): CoursesSection[] {
  const intl = useIntl();
  const account = useCurrentAccount();

  return useMemo(() => {
    const listBundles = { mandatoryList: [], showList: [], restrictedCourseList: [] };
    bundles.forEach((bundle) => {
      if (bundle.isbBundleShow) {
        const fullCourses = courses.filter((item) => bundle.courses.includes(item.id));
        const keyName = bundle.isMandatoryBundle ? 'mandatoryList' : 'showList';
        const coursesList = _.orderBy(fullCourses, (item) => bundle.courses.indexOf(item.id), 'asc');
        _.remove(
          coursesList,
          (item) => item.userCourseMetaData && item.userCourseMetaData.courseProgress === CourseProgress.Complete
        );
        (listBundles[keyName] as any).push({ ...bundle, coursesList: coursesList });
        if (bundle.restricted && bundle.courses.length) {
          (listBundles.restrictedCourseList as any) = [
            ...listBundles.restrictedCourseList,
            ...coursesList.slice(1, coursesList.length).map((item) => item.id),
          ];
        }
      }
    });

    const accountLevelCourses = courses.filter((course) => course.accountId === account.id);

    listBundles.restrictedCourseList = _.uniq(listBundles.restrictedCourseList);
    listBundles.showList = _.orderBy(listBundles.showList, (item: any) => item.title.toLowerCase(), 'asc') as any;

    const filterBundleList = (b) => b.coursesList?.length > 0;

    return [
      // Mandatory bundles first
      ...listBundles.mandatoryList.filter(filterBundleList).map((bundle: any) => ({
        bundleId: bundle.id,
        title: bundle.title,
        description: bundle.description,
        courses: bundle.coursesList,
      })),
      // "Continue" section
      {
        title: intl.formatMessage({ id: 'pages.learn.banners.continue' }),
        courses: courses.filter(isContinue).sort(sortByDateDesc),
      },
      {
        // "Explore" section
        title: intl.formatMessage({ id: 'pages.learn.banners.explore' }),
        courses: courses.filter(isExplore).sort(sortByDateDesc),
      },
      // "Your account - Uploaded Courses" section
      {
        title: `${account?.name} - Uploaded Courses`,
        courses: accountLevelCourses.sort(sortByDateDesc),
      },
      // All bundles that are not mandatory
      ...listBundles.showList.filter(filterBundleList).map((bundle: any) => ({
        bundleId: bundle.id,
        title: bundle.title,
        description: bundle.description,
        courses: bundle.coursesList,
      })),
      // "Completed" section
      {
        title: intl.formatMessage({ id: 'pages.learn.banners.completed' }),
        courses: courses.filter(isComplete).sort(sortByDateDesc),
      },
    ].filter((s) => s.courses.length);
  }, [intl, courses, bundles, account]);
}
