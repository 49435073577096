import React from 'react';
import styled from 'styled-components';
import { Col, Row, Heading, ImageEditor } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const UploadColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ProfilePictureUpload = ({ imageUrl, onSubmit }) => {
  return (
    <Container>
      <HeaderStyled name="upload-account__heading" size="lg">
        Add/Edit Profile Picture
      </HeaderStyled>
      <Row container align="between">
        <UploadColStyled item container>
          <ImageEditor width={200} height={200} border={20} defaultImage={imageUrl ? imageUrl : ''} onSave={(data) => onSubmit(data)} acceptedFormats={['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']} outputFormat={{ format: 'image/png', width: 200, height: 200 }} />
        </UploadColStyled>
      </Row>
    </Container>
  );
};

export { ProfilePictureUpload };
