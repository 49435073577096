import { useApolloClient } from '@apollo/client';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';
import { GET_REFRIGERANT_TRANSACTIONS_FILTER } from './index.queries';

export function useRefrigerantGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getRefrigerantTransactionsPageFilter: values },
      } = await client.query({
        query: GET_REFRIGERANT_TRANSACTIONS_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Location',
        field: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        colId: 'locationCode',
        headerName: 'Location Code',
        field: 'locationCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Date',
        field: 'date',
        valueFormatter: ({ value }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        initialSort: 'desc' as const,
      },
      {
        headerName: 'Gas Type',
        field: 'gasType',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Gas Group',
        field: 'gasGroup',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        filter: 'agNumberColumnFilter',
      },
      {
        colId: 'unitName',
        headerName: 'Quantity Unit',
        field: 'quantityUnit.name',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Act/Est',
        field: 'accuracy',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Data Provider',
        field: 'dataProvider',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Carbon Emission Scope 1 (kgCO2e)',
        field: 'carbonScope1',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Comment',
        field: 'comment',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Country',
        field: 'location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
