import { useState, useCallback } from 'react';
import { CircularProgress, LinearProgress, LoadFailed, styled, Icons, Text } from '@rio/ui-components';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import externalStyled from 'styled-components';
import { DataCompletenessGrid } from './DataCompletenessGrid';
import { useGetDataQualityPercentage } from './useGetDataQualityPercentage';
import { useCurrentAccountId } from '~/hooks';

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background: ${({ theme }) => theme.sys.color.surface};
  gap: 24px;
  min-height: 557px;
`;

const HorizontalContainer = styled('div')`
  display: flex;
  margin-bottom: auto;
`;

const ChartContainer = styled('div')`
  margin-top: 8px;
  min-width: 190px;
  margin-right: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const InfoText = styled(Text)`
  color: ${(props) => props.theme.sys.color.onSurfaceVariant};
`;

const LoadingContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 32px 32px 32px;
  width: 100%;
`;

const LinearProgressStyled = styled(LinearProgress)`
  width: 100%;
  height: 3px;
`;

const HeaderContainer = styled('p')`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.sys.color.onBackground};
`;

const AboutIconContainer = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${(p) => p.theme.palette.grey[600]};
    }
  }
`;

const BoldText = externalStyled.span`
  font-weight: bold;
`;

const ListItemWrapper = externalStyled.ol`
  padding: 20px 0 0;
`;

const ListItem = externalStyled.li`
  margin-bottom: 10px;
`;

const Wrapper = externalStyled.div`
  padding: 20px;
  max-width: 800px;
`;

export const DataQualityAndCompleteness = () => {
  const accountId = useCurrentAccountId();
  const { loading, error, data, refetch } = useGetDataQualityPercentage(accountId);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <MainContainer>
      <HeaderContainer>
        <Text typescale="title" size="medium">
          Data quality & completeness
        </Text>
        <AboutIconContainer aria-describedby={id} onClick={handleClick}>
          <Icons.InfoOutlined />
        </AboutIconContainer>
      </HeaderContainer>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Wrapper>
          <Typography variant="body1">
            Gain a high-level view of your data: <BoldText>Data Quality</BoldText> and{' '}
            <BoldText>Data Completeness</BoldText> per utility are calculated for the entire period in which your
            account has data. For example, if your first data point in Rio for electricity is 01/01/2019, Rio will
            analyse the period from 01/01/2019 to present day to calculate the percentages seen below.
          </Typography>
          <ListItemWrapper>
            <ListItem>
              <Typography variant="body2">
                <BoldText>1. Data Quality</BoldText> is calculated based on the percentage of <em>actual</em> vs{' '}
                <em>estimated</em> activity data. For instance, if your data quality is 75%, then 75% of all data
                received is from <em>actual</em> transactions, while 25% is from <em>estimated</em> transactions.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                <BoldText>2. Data Completeness</BoldText> analyses how many days have data vs the number of days missing
                data. For example, if your data completeness is 30%, then out of the 100 days Rio has been collecting
                data, uploaded transactions cover only 30 days, while 70 days lack data.
              </Typography>
            </ListItem>
          </ListItemWrapper>
        </Wrapper>
      </Popover>
      <HorizontalContainer>
        <ChartContainer>
          <Text typescale="label" size="small">
            Organisation data quality
          </Text>
          {!loading && !error && data && (
            <>
              <CircularProgress mode="chart" value={data?.getDataQualityPercentage.overallDataQuality} />
              <CircularProgress
                mainContainerProps={{ sx: { minWidth: '190px' } }}
                mode="simple-right"
                additionalText="data completeness"
                value={data?.getDataQualityPercentage.overallDataCompleteness}
                color="secondary"
              />
            </>
          )}
        </ChartContainer>
        {loading && (
          <LoadingContainer>
            <LinearProgressStyled />
          </LoadingContainer>
        )}
        {!loading && !error && data && (
          <DataCompletenessGrid data={data?.getDataQualityPercentage.dataQualityBreakdown || []} />
        )}
        {!loading && error && <LoadFailed title="Error at loading the data" error={error} retry={refetch} />}
      </HorizontalContainer>
      <InfoText>Upload actual sustainability data for better Data Quality scores</InfoText>
    </MainContainer>
  );
};
