import { useState } from 'react';
import * as JsSearch from 'js-search';
import { useQueryParam, StringParam } from 'use-query-params';

import { useIntl } from 'react-intl';
import { Button, Col } from 'rio-ui-components';
import { DocumentLibrary } from '@rio/rio-types';
import { GridApi, ColumnApi } from 'ag-grid-community';
import PageHeader from '../../../components/PageHeader';
import { ControlPanel } from '../../../components/ControlPanel';
import { ClearButton } from '../../../components/ClearButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import headerLibraries, { DocumentLibraries } from '../documentLibraries';
import { UploadDocumentModal } from '../DocumentModal';
import { DocumentExplorer } from '../DocumentExplorer';
import { getLibraryColour, getAllFilters } from '../utils';
import { useGetDocuments } from '../useGetDocuments';
import { useNotification, usePermissions, useAccessControls, useCurrentAccountId } from '../../../hooks';
import { DocumentLibraryFolder, DocumentTypeFolder } from '../types';
import ExportButtons from '../ExportButtons';

type Folders = DocumentLibraries | DocumentTypeFolder[];

interface DocumentLandingPageProps {
  libraries: DocumentLibraries;
  types: DocumentTypeFolder[];
}

// TODO: refactor
// Possible merge with DocumentFiles component
// or move export/select logic to DocumentExplorer
const DocumentLandingPage = ({ libraries, types }: DocumentLandingPageProps) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useQueryParam('search', StringParam);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [gridApi, setGridApi] = useState<{ api: GridApi; columnApi: ColumnApi }>();
  const { showNotification } = useNotification();
  const accountId = useCurrentAccountId();
  const { documentsExist, error, loading, refetch } = useGetDocuments(
    {
      accountId,
      filters: getAllFilters(searchValue || ''),
    },
    !searchValue
  );
  const { data: accessControls } = useAccessControls();
  const permissions = usePermissions();
  const { library } = useParams();
  const filteredFolders = library
    ? types.filter((folder) => folder.libraries.includes(library.toUpperCase() as DocumentLibrary))
    : libraries;

  const search = (data: Folders, term: string): Folders => {
    if (!term) {
      return data;
    }
    const searcher = new JsSearch.Search('id');
    searcher.addIndex('title');
    searcher.addDocuments(data);
    return searcher.search(term) as DocumentLibraryFolder[];
  };

  const createBreadcrumbs = () => {
    const crumbs = [{ title: 'Documents', to: '../documents' }];

    if (library) {
      crumbs.push({ title: _.capitalize(library), to: `../../documents/${library}` });
    }
    return crumbs;
  };

  const userCanUpload = !!permissions.document.find((action: string) => action.startsWith('create'));
  const searchedFolders = search(filteredFolders, searchValue!);

  return (
    <Col container fullHeight>
      <PageHeader
        name="DocumentLandingPageHeader"
        title={intl.formatMessage({ id: 'pages.documents.heading' })}
        breadcrumbs={createBreadcrumbs()}
        icon="file-alt"
        iconColor="quaternary"
        dropdownDefault={library ? `${_.capitalize(library)} Documents` : 'Documents'}
        isDropdown={!!library}
        dropdownItems={headerLibraries.filter((item) =>
          item.accessControlIndexer ? _.get(accessControls, item.accessControlIndexer) : true
        )}
      >
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          {userCanUpload && documentsExist && (
            <Col span={3}>
              <ClearButton gridApi={gridApi?.api} columnApi={gridApi?.columnApi} />
            </Col>
          )}
          {userCanUpload && documentsExist && (
            <Col span={6}>
              <ExportButtons gridApi={gridApi} accountId={accountId} />
            </Col>
          )}
          {userCanUpload && (
            <Col span={3}>
              <Button name="DocumentLanding__Button--upload" color="primary" onClick={() => setShowCreateModal(true)}>
                + Upload Documents
              </Button>
            </Col>
          )}
        </ControlPanel>
      </PageHeader>
      <DocumentExplorer
        searchValue={searchValue!}
        library={library}
        onSearch={setSearchValue}
        folders={(searchedFolders as DocumentLibraryFolder[]).map(
          (folder: DocumentLibraryFolder | DocumentTypeFolder) => ({
            name: folder.title,
            url: `./${folder.link}`,
            icon: {
              color: getLibraryColour((folder as DocumentLibraryFolder).library || library),
              name: 'folder',
            },
            disabled: !('accessControlIndexer' in folder ? _.get(accessControls, folder.accessControlIndexer!) : true),
          })
        )}
        documentsExist={documentsExist}
        setGridApi={setGridApi}
        error={error}
        retry={refetch}
        loading={loading && !documentsExist}
      />
      {showCreateModal && (
        <UploadDocumentModal
          onDismiss={() => setShowCreateModal(false)}
          onComplete={(message: string) => {
            showNotification(message, 'success');
          }}
          onError={(err: Error) => {
            showNotification(err.message, 'danger');
          }}
          predefinedValues={{
            library: (library?.toUpperCase() || null) as DocumentLibrary,
          }}
        />
      )}
    </Col>
  );
};

export default DocumentLandingPage;
