"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const styles_1 = require("@mui/material-next/styles");
const Text_1 = require("../Text");
const Icons_1 = require("../Icons");
const DrawerLinkContainer = (0, styles_1.styled)(react_router_dom_1.Link) `
  display: flex;
  width: 100%;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  outline: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background: ${({ theme }) => theme.sys.color.surface};
  box-shadow: ${({ theme }) => theme.sys.elevation[1]};
  text-decoration: none;
  ${({ theme }) => (0, Text_1.textCss)(theme, { typescale: 'body', size: 'medium' })}
  color: ${({ theme }) => theme.sys.color.onSurface};

  &:hover {
    cursor: pointer;
  }

  & * {
    ${({ theme }) => (0, Text_1.textCss)(theme, { typescale: 'body', size: 'medium' })}
  }

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;
const IconContainer = (0, styles_1.styled)('div') `
  width: 24px;
  height: 24px;
`;
const KeyboardArrowRightStyled = (0, styles_1.styled)(Icons_1.Icons.KeyboardArrowRight) `
  width: 100%;
  height: 100%;
  fill: ${(props) => props.theme.sys.color.primary};
`;
exports.DrawerLink = (0, react_1.forwardRef)(function DrawerLink({ children, to, ...rest }, ref) {
    return ((0, jsx_runtime_1.jsxs)(DrawerLinkContainer, { to: to, ref: ref, ...rest, children: [children, (0, jsx_runtime_1.jsx)(IconContainer, { children: (0, jsx_runtime_1.jsx)(KeyboardArrowRightStyled, {}) })] }));
});
