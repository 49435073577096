import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../../queries/auth';
import { useCallback, useState } from 'react';

export function useForgotPassword(initialUsername: string) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [username, setUsername] = useState(initialUsername);
  const [error, setError] = useState(false);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  return {
    handleForgotPassword: useCallback(
      (e: React.FormEvent) => {
        e.preventDefault();
        if (username.length === 0) {
          return setError(true);
        }
        setLoading(true);
        forgotPassword({
          variables: { username: username },
          onCompleted() {
            setSuccess(true);
          },
          onError() {
            setLoading(false);
          },
        });
      },
      [setSuccess, forgotPassword, setLoading, username, setError]
    ),
    changeUsername: useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setError(false);
        setUsername(e.target.value);
      },
      [setUsername]
    ),
    username,
    success,
    error,
    loading,
  };
}
