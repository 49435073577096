import { Select } from 'rio-ui-components';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { Query, Aspect } from '@rio/rio-types';
import { GET_ASPECTS_BY_ACCOUNT_ID } from '../../graphql/queries/governance/GetAspectsByAccountId.query.js';
import { Nullable, SelectEvent } from '../../types';
import { useCurrentAccountId } from '../../hooks';

type ResponseType = Pick<Query, 'getAspectsByAccountId'>;

interface AspectsSelectProps {
  value?: string;
  error: Nullable<Error | string>;
  name?: string;
  onChange: (event: SelectEvent) => void;
  isDisabled?: boolean;
}
const getAspectsFromResponse = (res: ApolloQueryResult<ResponseType>) => res?.data?.getAspectsByAccountId?.rows || [];

const order = [
  {
    order: 'asc',
    field: 'activity.name'
  }
];

export function AspectsSelect({ value, error, name, onChange, isDisabled }: AspectsSelectProps) {
  const apolloClient = useApolloClient();
  const accountId = useCurrentAccountId();
  const loadOptions = async (filterValue: string) => {
    const response = await apolloClient.query<ResponseType>({
      query: GET_ASPECTS_BY_ACCOUNT_ID,
      variables: {
        accountId,
        sort: order,
        filters: {
          text: [
            {
              filterType: 'text',
              type: 'contains',
              filter: filterValue,
              field: 'activity.name'
            }
          ]
        }
      }
    });
    return getAspectsFromResponse(response).map((aspect: Aspect) => ({
      value: aspect.id,
      label: aspect.activity?.name
    }));
  };
  return (
    <Select
      defaultOptions
      name={name}
      placeholder="Please select the Activity/Process"
      value={value}
      error={error}
      onChange={onChange}
      classPrefix="aspects-select"
      isDisabled={isDisabled}
      loadOption={loadOptions}
    />
  );
}
