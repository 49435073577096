import React from 'react';
import styled from 'styled-components';
import { Row, Heading, Text, Icon, CircleIcon } from 'rio-ui-components';
import { FadedCourse, LockIcon } from '../../CourseTile';

const ContainerStyled = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  overflow: hidden;
`;

const TextContainer = styled.div`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const ChevronStyled = styled(Text)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const CourseImage = styled.div`
  position: relative;
  width: 192px;
  height: 130px;
  display: block;
  background: ${({ theme }) => theme.colors.secondary.dark.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
  flex: 0 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  display: inline-flex;
  flex: 0 0 auto;
`;

const CourseRow = ({ learnAccess, courseId, title, image, restricted }) => {
  return (
    <ContainerStyled name={`CourseRow CourseRow--${courseId}`} container itemAlign="center" disabled={!learnAccess}>
      <CourseImage name="CourseRow__Thumbnail" src={image}>
        {!image && (
          <CircleIconStyled
            name="CourseRow__Icon"
            height="50px"
            size="lg"
            icon="graduation-cap"
            iconColor="white"
            circleColor="white"
          />
        )}
        {restricted ? (
          <FadedCourse>
            <LockIcon icon="lock" color="#ababab" />
          </FadedCourse>
        ) : null}
      </CourseImage>
      <Row container item distribution="between" itemAlign="center">
        <TextContainer>
          <HeadingStyled size="lg" weight="normal" color="dark" name="CourseRow__Title">
            {title}
          </HeadingStyled>
        </TextContainer>
        <ChevronStyled size="lg" color="normal">
          <Icon icon="chevron-right" />
        </ChevronStyled>
      </Row>
    </ContainerStyled>
  );
};

export default CourseRow;
