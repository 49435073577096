import React, { useState } from 'react';
import styled from 'styled-components';
import { ProgressBar, Row, Col, Text, Icon, Modal, Heading, Draggable } from 'rio-ui-components';
import { useExportApi, Export } from '../../hooks';
import { ConfirmActionModal } from '../ConfirmAction';
import { Nullable } from '../../types';

const Overlay = styled.div`
  position: fixed;
  left: 25%;
  bottom: 16px;
  width: 50%;
  height: auto;
  background: white;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
`;
const IconStyled = styled(Icon)`
  cursor: pointer;
`;
const ProgressBarRow = styled(Row)`
  position: relative;
  align-items: center;
  height: 48px;
`;
const ProgressBarStyled = styled(ProgressBar)`
  & {
    position: absolute;
    height: 36px;
  }
`;
const ErrorDetails = styled(Text)`
  min-height: 300px;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const TextLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;
const FileName = styled(Text)`
  width: 100%;
`;
const getProgressBarColor = (e: Export) => {
  if (e.error) {
    return 'danger';
  }
  if (e.percents === 100) {
    return 'green';
  }
  return 'blue';
};

export function OverlayProgressBars() {
  const exportApi = useExportApi();
  const exports = exportApi?.getExports();
  const [error, setError] = useState<Nullable<Error>>(null);
  const [stopExportModal, setStopExportModal] = useState<Nullable<string>>(null);
  return !!exports?.length ? (
    <>
      <Draggable>
        <Overlay>
          {exports.map((e) => (
            <ProgressBarRow key={e.id} container>
              <Col container span={9}>
                <Row item container>
                  <FileName align="center">{e.options.exportFileName}</FileName>
                </Row>
                <ProgressBarStyled completed={e.percents} color={getProgressBarColor(e)} valueBuffer={e.step} />
              </Col>
              <Col span={2}>
                {e.error ? (
                  <TextLink align="center" onClick={() => setError(e.error)}>
                    Show details
                  </TextLink>
                ) : (
                  <Text align="center">
                    {e.offset} / {e.totalRows || '?'}
                  </Text>
                )}
              </Col>
              <Col span={1}>
                {e.completed ? (
                  <IconStyled
                    onClick={() => {
                      exportApi?.stop(e.id);
                    }}
                    icon="check"
                    color="green"
                  />
                ) : (
                  <IconStyled
                    onClick={() => {
                      if (e.error) {
                        exportApi?.stop(e.id);
                      } else {
                        setStopExportModal(e.id);
                      }
                    }}
                    icon="times-circle"
                    color="red"
                  />
                )}
              </Col>
            </ProgressBarRow>
          ))}
        </Overlay>
      </Draggable>
      <Modal show={!!error} size="sm" onDismiss={() => setError(null)}>
        <Heading align="center" size="md" color="dark">
          Error details:
        </Heading>
        <ErrorDetails align="center" size="md" color="dark">
          {error?.toString()}
        </ErrorDetails>
      </Modal>
      <ConfirmActionModal
        show={!!stopExportModal}
        onDismiss={() => setStopExportModal(null)}
        headingText="Do you want to abort the export?"
        bodyText="All progress will be lost."
        onConfirmAction={() => {
          exportApi?.stop(stopExportModal!);
          setStopExportModal(null);
        }}
        onCancelAction={() => {
          setStopExportModal(null);
        }}
      />
    </>
  ) : null;
}
