import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_ASPECTS_BY_ACCOUNT_ID } from '../../../graphql/queries/governance/GetAspectsByAccountId.query.js';
import { useCurrentAccountId } from '~/hooks';
import { OptionsProps, Select } from '@rio/ui-components';

interface AspectsSelectProps {
  value?: string;
  name?: string;
  onChange: (selectedOption: { value: string; label: string }) => void;
  disabled?: boolean;
}

const loadingOption = { label: 'Loading...', value: '', disabled: true };
const noResultsFoundOption = { label: 'No targets found', value: '', disabled: true };

export function AspectsSelect({ value, name, onChange, disabled }: AspectsSelectProps) {
  const accountId = useCurrentAccountId();
  const { data, error, loading } = useQuery<Pick<Query, 'getAspectsByAccountId'>>(GET_ASPECTS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
      sort: {
        order: 'asc',
        field: 'activity.name',
      },
    },
  });

  const options = (data?.getAspectsByAccountId?.rows || [])
    .map((a) => ({
      value: a.id,
      label: a.activity?.name,
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 1) as OptionsProps[];

  if (!loading && !error && options.length === 0) {
    options.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label="Aspects"
      placeholder="Please select the Activity/Process"
      value={value}
      error={Boolean(error)}
      helperText={error?.message}
      onChange={onChange}
      disabled={disabled}
      options={loading ? [loadingOption] : options}
    />
  );
}
