import { styled } from '@rio/ui-components';
import { Routes, Route } from 'react-router-dom';
import ProjectViewContainer from '../../ProjectContainer/ProjectView';
import { ProjectsGridContainer } from '../../ProjectContainer/ProjectsGrid/v2';

const ContainerStyled = styled('div')`
  width: 100%;
  height: 100%;
`;

export const ProjectRoutes = () => {
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<ProjectsGridContainer />} />
        <Route path="/:id" element={<ProjectViewContainer />} />
      </Routes>
    </ContainerStyled>
  );
};
