import { useQuery } from '@apollo/client';
import React, { useState, RefObject, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import flatten from 'lodash/flatten';
import { Scope, Aspect, AspectOption, DataType } from '@rio/rio-types';
import get from 'lodash/get';
import { Label, Col, Row, Modal, Heading, DatePicker, Button, Select, MultiSelect, Text } from 'rio-ui-components';
import Option from '../../../components/Option';
import { useCreateDepartment } from '../../../hooks/useCreateDepartment';
import { useCurrentAccount } from '../../../hooks/useCurrentAccount';
import { validate } from './validate';
import { usePermissions, useNotification, useUserToken } from '../../../hooks';
import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { scopeOptions } from '../../../constants/scopes';
import { TYPES, TYPES_LABELS, LIKELIHOOD_OPTIONS, SEVERITY_OPTIONS } from '../../../constants/aspects';
import { GET_ALL_LEGISLATION } from '../Legislation/LegislationLibrary/index.queries';
import { alphabetiseByField } from '../../../utils/alphabetise';
import { CreateOptionModal } from '../../../components/CreateOptionModal';
import { CreateDepartmentModal } from '../../../components/CreateDepartmentModal';
import { Option as OptionType, SelectEvent, MultiSelectEvent } from '../../../types';
import { useDeleteAspect } from './useDeleteAspect';
import { useGetAllAspectOptions } from './useGetAllAspectOptions';
import {
  formatAspect,
  onSelectChange,
  checkPermissions,
  prepareOptions,
  getAspectOptionsWithNew,
  getFilterFieldByOptionType
} from './utils';
import { useIsDuplicate } from './useIsDuplicate';
import { AspectFormState, AspectFormErrors, CreateAspectOptionResponse } from './types';
import { ConfirmOptionDeletionModal } from './ConfirmOptionDeletionModal';
import { useOptionToDeleteFromQueryParam } from './useOptionToDeleteFromQueryParam';
import { useGetAspectsCountByAccountId } from './useGetAspectsByAccountId';
import { useDeleteAspectOption } from './useDeleteAspectOption';
import { EvidencePicker } from '../../../components/EvidenceNotes';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const Form = styled.form`
  padding: 32px;
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonColStyled = styled(ColStyled)`
  flex-basis: 50%;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;
const ExpiryDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -340px;
    left: 50%;
  }
`;
const StyledModalContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButtonRow = styled(Row)`
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

interface AspectModalProps {
  accountId: string;
  title: string;
  submitTitle: string;
  onDismiss: () => void;
  onSubmit: (aspectFormState: AspectFormState, evidenceNotes: string, evidenceDocuments: any[]) => void;
  modalPortalRef: RefObject<HTMLDivElement>;
  values?: Partial<Aspect>;
  isCopy?: boolean;
}

const aspectKeyByType = {
  [TYPES.ACTIVITY]: 'activity',
  [TYPES.ASPECT]: 'aspects',
  [TYPES.IMPACT]: 'impacts',
  [TYPES.EMERGENCY_CONDITION]: 'emergencyConditions',
  [TYPES.CONTROL_MEASURE]: 'controlMeasures',
  [TYPES.STATUS]: 'status',
  [TYPES.DEPARTMENT]: 'departments'
};

export const AspectModal = ({
  accountId,
  title,
  submitTitle,
  onDismiss,
  onSubmit,
  values = {},
  modalPortalRef,
  isCopy
}: AspectModalProps) => {
  const { token } = useUserToken();
  const currentAccount = useCurrentAccount() || {};
  const defaultAspect = formatAspect(values, accountId, token);
  const [errors, setErrors] = useState<AspectFormErrors>({});
  const [aspect, setAspect] = useState(defaultAspect);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createOptionType, setShowCreateModal] = useState<string | boolean>(false);
  const [, setOptionToDelete] = useOptionToDeleteFromQueryParam();
  const { showNotification } = useNotification();
  const getAspectsCountByAccountId = useGetAspectsCountByAccountId();
  const isDuplicate = useIsDuplicate();
  const { data: legislationData } = useQuery(GET_ALL_LEGISLATION, { variables: { accountId } });
  const legislationOptions = prepareOptions(legislationData?.getAllLegislation, 'title').sort(
    alphabetiseByField('label')
  );

  const permissions = usePermissions();
  const canCreateOptions = permissions.governance.find((action: string) => action.startsWith('createAspectOption'));
  const canDeleteOptions = permissions.governance.find((action: string) => action.startsWith('deleteAspectOption'));

  const isUpdate = !!values.id;
  const showDeleteButton = isUpdate && checkPermissions(permissions, 'deleteAspect', values.author?.id === token.sub);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const getAspectOptions = getAspectOptionsWithNew(canCreateOptions);
  const getAspectOptionsNoCreate = getAspectOptionsWithNew(false);

  const { data: options, refetch: refetchOptions } = useGetAllAspectOptions();

  const { departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    currentAccount.departments,
    aspect.departments
  );

  const [deleteAspect] = useDeleteAspect({
    onError: () => {
      setIsSubmitting(false);
    },
    onCompleted: () => {
      onDismiss();
    }
  });
  const [deleteAspectOption] = useDeleteAspectOption();

  const onDelete = useCallback(
    async (id: string) => {
      const optionsArrays: AspectOption[][] = Object.values(options!);
      const allOptions = flatten(optionsArrays);
      const option = allOptions.find((option: AspectOption) => option.id === id);
      if (!option) {
        return;
      }

      const { totalRows } = await getAspectsCountByAccountId({
        accountId,
        filters: {
          set: [
            {
              filterType: DataType.Set,
              field: getFilterFieldByOptionType(option.type)!,
              values: [option.id]
            }
          ]
        }
      });
      if (totalRows >= 1) {
        return setOptionToDelete({
          option,
          isReviewingAspects: false,
          relatedAspectsCount: totalRows
        });
      }
      await deleteAspectOption({
        variables: {
          accountId,
          id: option.id,
          type: option.type
        }
      });
    },
    [options, accountId, setOptionToDelete, getAspectsCountByAccountId, deleteAspectOption]
  );

  const optionsComponents = useMemo(
    () => ({
      Option: ({ ...props }) => <Option {...props} onDelete={onDelete} canDelete={canDeleteOptions} />
    }),
    [canDeleteOptions, onDelete]
  );

  const optionsComponentsNoDelete = useMemo(
    () => ({
      Option: ({ ...props }) => <Option {...props} onDelete={onDelete} canDelete={false} />
    }),
    [onDelete]
  );

  const setDepartment = async (data: object[], newDepartment: { name: string; id: string }) => {
    const aspectDepartments = aspect['departments'] || [];
    setAspect({
      ...aspect,
      departments: [...aspectDepartments, { label: newDepartment.name, value: newDepartment.id }]
    });
  };

  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');

  return (
    <Modal size="md" onDismiss={onDismiss} dismissable show>
      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        onComplete={setDepartment}
        passedAccountId={accountId}
      />
      {createOptionType &&
        modalPortalRef.current &&
        createPortal(
          <CreateOptionModal
            accountId={accountId}
            type={createOptionType}
            onDismiss={() => setShowCreateModal(false)}
            onError={(err: Error) => showNotification(err.message, 'danger')}
            onCompleted={(data: CreateAspectOptionResponse) => {
              const { type, name, id } = data.createAspectOption;
              refetchOptions();
              setShowCreateModal(false);
              const key: keyof AspectFormState = aspectKeyByType[type] as keyof AspectFormState;
              if (Array.isArray(aspect[key])) {
                const values = aspect[key] as OptionType[];
                setAspect({ ...aspect, [key]: [...values, { label: name, value: id }] });
              } else {
                setAspect({ ...aspect, [key]: id });
              }
              setErrors({ ...errors, [key]: null });
              showNotification(`${get(TYPES_LABELS, type)} has been created!`);
            }}
          />,
          modalPortalRef.current
        )}
      <ConfirmOptionDeletionModal
        accountId={accountId}
        portalRef={modalPortalRef}
        onReviewAspects={() => {
          onDismiss();
        }}
        onError={() => {
          setIsSubmitting(false);
        }}
        onCompleted={() => {
          refetchOptions({
            accountId
          });
        }}
        onDismiss={() => {
          setOptionToDelete(null);
        }}
      />
      <Form>
        <HeaderStyled>{title}</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Scope</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={scopeOptions}
              value={aspect.scope}
              onChange={(e: SelectEvent) => {
                setAspect({ ...aspect, scope: e.target.value as Scope, tagIds: null, locationIds: null });
                setErrors({ ...errors, scope: null, tagIds: null, locationIds: null });
              }}
              error={errors.scope}
            />
          </ColStyled>
        </Row>
        {aspect.scope === Scope.Location && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Location(s)</LabelStyled>
              </LabelContainerStyled>
              <LocationsMultiSelect
                defaultOptions
                disabled={false}
                accountId={accountId}
                value={aspect.locationIds}
                onChange={(e: MultiSelectEvent) => {
                  setAspect({ ...aspect, locationIds: e.target.select });
                  setErrors({ ...errors, locationIds: null });
                }}
                error={errors.locationIds}
                isSubmitting={isSubmitting}
              />
            </ColStyled>
          </Row>
        )}
        {aspect.scope === Scope.Tag && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Tag(s)</LabelStyled>
              </LabelContainerStyled>
              <TagsMultiSelect
                accountId={accountId}
                value={aspect.tagIds}
                onChange={(e: MultiSelectEvent) => {
                  setAspect({ ...aspect, tagIds: e.target.select });
                  setErrors({ ...errors, tagIds: null });
                }}
                error={errors.tagIds}
                isSubmitting={isSubmitting}
              />
            </ColStyled>
          </Row>
        )}

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Departments</LabelStyled>
            </LabelContainerStyled>
            {departmentMultipleSelect((e: MultiSelectEvent) => {
              setAspect({ ...aspect, departments: e.target.select });
              setErrors({ ...errors, departments: null });
            })}
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Activity / Process</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={getAspectOptions(options?.activities)}
              value={aspect.activity}
              onChange={(e: SelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.ACTIVITY),
                  () => {
                    setAspect({ ...aspect, activity: e.target.value });
                    setErrors({ ...errors, activity: null });
                  }
                );
              }}
              components={optionsComponents}
              error={errors.activity}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Environmental Aspect(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={getAspectOptions(options?.aspects)}
              value={aspect.aspects}
              onChange={(e: MultiSelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.ASPECT),
                  () => {
                    setAspect({ ...aspect, aspects: e.target.select });
                    setErrors({ ...errors, aspects: null });
                  }
                );
              }}
              error={errors.aspects}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Potential Environmental Impact(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={getAspectOptions(options?.impacts)}
              value={aspect.impacts}
              onChange={(e: MultiSelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.IMPACT),
                  () => {
                    setAspect({ ...aspect, impacts: e.target.select });
                    setErrors({ ...errors, impacts: null });
                  }
                );
              }}
              error={errors.impacts}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Legislation Reference(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={legislationOptions}
              value={aspect.legislationIds}
              onChange={(e: MultiSelectEvent) => {
                setAspect({ ...aspect, legislationIds: e.target.select });
                setErrors({ ...errors, legislationIds: null });
              }}
              error={errors.legislationIds}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Likelihood (no control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={LIKELIHOOD_OPTIONS}
              value={String(aspect.likelihoodNoControl)}
              onChange={(e: SelectEvent) => {
                setAspect({ ...aspect, likelihoodNoControl: e.target.value });
                setErrors({ ...errors, likelihoodNoControl: null });
              }}
              error={errors.likelihoodNoControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Severity (no control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={SEVERITY_OPTIONS}
              value={String(aspect.severityNoControl)}
              onChange={(e: SelectEvent) => {
                setAspect({ ...aspect, severityNoControl: e.target.value });
                setErrors({ ...errors, severityNoControl: null });
              }}
              error={errors.severityNoControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Potential Emergency Condition(s) (optional)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={getAspectOptions(options?.emergencyConditions)}
              value={aspect.emergencyConditions}
              onChange={(e: MultiSelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.EMERGENCY_CONDITION),
                  () => {
                    setAspect({ ...aspect, emergencyConditions: e.target.select });
                    setErrors({ ...errors, emergencyConditions: null });
                  }
                );
              }}
              error={errors.emergencyConditions}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Relevant Control Measure(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={getAspectOptions(options?.controlMeasures)}
              value={aspect.controlMeasures}
              onChange={(e: MultiSelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.CONTROL_MEASURE),
                  () => {
                    setAspect({ ...aspect, controlMeasures: e.target.select });
                    setErrors({ ...errors, controlMeasures: null });
                  }
                );
              }}
              error={errors.controlMeasures}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Likelihood (with control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={LIKELIHOOD_OPTIONS}
              value={String(aspect.likelihoodControl)}
              onChange={(e: SelectEvent) => {
                setAspect({ ...aspect, likelihoodControl: e.target.value });
                setErrors({ ...errors, likelihoodControl: null });
              }}
              error={errors.likelihoodControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Severity (with control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={SEVERITY_OPTIONS}
              value={String(aspect.severityControl)}
              onChange={(e: SelectEvent) => {
                setAspect({ ...aspect, severityControl: e.target.value });
                setErrors({ ...errors, severityControl: null });
              }}
              error={errors.severityControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Review date (optional)</LabelStyled>
            </LabelContainerStyled>
            <ExpiryDatePicker
              placeholder="Select review date"
              disabled={isSubmitting}
              onDayClick={(reviewDate: Date) => {
                setAspect({ ...aspect, reviewDate: reviewDate?.toISOString() });
                setErrors({ ...errors, reviewDate: null });
              }}
              selectedDate={aspect.reviewDate ? new Date(aspect.reviewDate) : null}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Status (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={getAspectOptionsNoCreate(options?.statuses)}
              value={aspect.status}
              onChange={(e: SelectEvent) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.STATUS),
                  () => {
                    setAspect({ ...aspect, status: e.target.value });
                    setErrors({ ...errors, status: null });
                  }
                );
              }}
              components={optionsComponentsNoDelete}
              error={errors.status}
            />
          </ColStyled>
        </Row>
        <EvidencePicker
          evidenceDocuments={evidenceDocuments}
          setEvidenceDocuments={setEvidenceDocuments}
          evidenceNotes={evidenceNotes}
          setEvidenceNotes={setEvidenceNotes}
        />
        {errors?.general && (
          <Row container>
            <Text color="danger" name="errorMessage">
              {errors.general}
            </Text>
          </Row>
        )}
        <Row container>
          {showDeleteButton && (
            <ButtonColStyled item grow="1">
              <ButtonStyled
                color="danger"
                disabled={isSubmitting}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setShowDeleteConfirmationModal(true);
                }}
              >
                Delete
              </ButtonStyled>
            </ButtonColStyled>
          )}
          <ButtonColStyled item grow="1">
            <ButtonStyled
              disabled={isSubmitting}
              onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();

                const errors = validate(aspect);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }

                if (isCopy && (await isDuplicate(aspect))) {
                  return setErrors({
                    general:
                      'It seems you are attempting to create an aspect which already exists. Please create a unique aspect.'
                  });
                }

                setIsSubmitting(true);
                await onSubmit(aspect, evidenceNotes, evidenceDocuments);
                setIsSubmitting(false);
              }}
            >
              {submitTitle}
            </ButtonStyled>
          </ButtonColStyled>
        </Row>
      </Form>
      {showDeleteConfirmationModal && (
        <Modal onDismiss={() => setShowDeleteConfirmationModal(false)} show name="confirmationModal">
          <StyledModalContainer>
            <StyledButtonRow item>
              <Heading>Delete Aspect</Heading>
            </StyledButtonRow>
            <StyledButtonRow item>
              <Text>This action will permanently delete this aspect. Do you want to proceed?</Text>
            </StyledButtonRow>
            <StyledButtonRow container distribution="around">
              <StyledButton
                color="danger"
                disabled={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  deleteAspect({ variables: { id: aspect.id, accountId } });
                }}
              >
                Delete
              </StyledButton>
              <StyledButton disabled={isSubmitting} onClick={() => setShowDeleteConfirmationModal(false)}>
                Cancel
              </StyledButton>
            </StyledButtonRow>
          </StyledModalContainer>
        </Modal>
      )}
    </Modal>
  );
};
