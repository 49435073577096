export type Area = {
  id: string;
  link: string;
  title: string;
  description: string;
  icon: string;
  iconColor: string;
  accessIndex: string;
  basePermission: string;
  permissionSection: string;
  restricted?: boolean;
  restrictionId?: string;
  comingSoon?: boolean;
};

const legislationArea: Area = {
  id: '573e0c6e-8a60-431e-bb9d-dd0f2849dba4',
  link: 'legislation',
  title: 'Legislation',
  description: 'A legal library of environmental legislation complete with synopsis and URL to gov.uk ',
  icon: 'balance-scale',
  iconColor: 'tertiary',
  accessIndex: 'legislation',
  basePermission: 'getLegislation',
  permissionSection: 'governance',
  comingSoon: false,
};

const managementSystemsAndStandardsArea: Area = {
  id: '841f7f86-9497-474d-9fd6-b543aac42bba',
  link: 'systems',
  title: 'Management Systems and Standards',
  description:
    'Document your progress towards a number of different management systems such as ISO 14001 and ISO 50001',
  icon: 'university',
  iconColor: 'tertiary',
  accessIndex: 'systems',
  basePermission: 'getManagementSystem',
  permissionSection: 'governance',
  comingSoon: false,
};

const environmentalManagementSystemsArea: Area = {
  id: '59d00cd4-2758-4e04-83fe-e7945ac2a35b',
  link: 'environmental-aspects',
  title: 'Environmental Management System',
  description: 'Aspects & Impacts Register',
  icon: 'clipboard-list',
  iconColor: 'tertiary',
  accessIndex: 'systems',
  basePermission: 'getManagementSystem',
  permissionSection: 'governance',
  restricted: true,
  restrictionId: 'cc0838fd-fcf3-4211-ab1e-506d31348216',
  comingSoon: false,
};

const areas: Area[] = [
  legislationArea,
  {
    id: '184ea723-60d3-459a-bcd3-6f4e8d4b1d0e',
    link: 'policies',
    title: 'Policies',
    description: 'Upload your organisations policy documents',
    icon: 'scroll',
    iconColor: 'tertiary',
    accessIndex: 'policies',
    basePermission: 'getPolicy',
    permissionSection: 'governance',
    comingSoon: false,
  },
  managementSystemsAndStandardsArea,
  {
    id: '8d28b74b-cc59-400f-98e3-2824c5f50ae3',
    link: 'targets',
    title: 'Targets',
    description: 'Set and manage your targets to track your performance',
    icon: 'subscript',
    iconColor: 'tertiary',
    accessIndex: 'targets',
    basePermission: 'getTarget',
    permissionSection: 'data',
    comingSoon: false,
  },
  {
    id: 'e531d074-7210-47c1-b9ba-8a2edc8b5475',
    link: 'frameworks',
    title: 'Reporting Frameworks',
    description: 'Build your submissions for reporting frameworks such as TCFD, CDP, ESOS, SECR',
    icon: 'drafting-compass',
    iconColor: 'tertiary',
    accessIndex: 'frameworks',
    basePermission: 'getManagementSystem',
    permissionSection: 'governance',
    comingSoon: false,
  },
  {
    id: '9e9a35ec-43d8-49f7-b83e-44603aa18af9',
    link: 'agreements',
    title: 'Voluntary Agreements',
    description: 'Build your company strategy around voluntary agreements or frameworks such as the UN SDGs, WEF, SASB',
    icon: 'thumbs-up',
    iconColor: 'tertiary',
    accessIndex: 'agreements',
    basePermission: 'getManagementSystem',
    permissionSection: 'governance',
    comingSoon: false,
  },
  environmentalManagementSystemsArea,
  {
    id: 'a0ab613d-e506-47da-8113-5a0ddc75fab8',
    link: 'health-and-safety-risk-register',
    title: 'Health & Safety Risk Register',
    description: '',
    icon: 'notes-medical',
    iconColor: 'tertiary',
    accessIndex: 'systems',
    basePermission: 'getManagementSystem',
    permissionSection: 'governance',
    restricted: true,
    restrictionId: '92eec0c0-18d4-497e-a8ed-5517056eec93',
    comingSoon: false,
  },
  {
    id: '9e9a35ec-43d8-49f7-b83e-44603aa18af1',
    link: 'materiality',
    title: 'Materiality',
    description:
      'Conduct your materiality analysis here to identify what Sustainability/ESG topics are most important to your organisation.',
    icon: 'file-contract',
    iconColor: 'tertiary',
    accessIndex: 'systems',
    basePermission: 'getManagementSystem',
    permissionSection: 'governance',
    comingSoon: false,
    restricted: true,
    restrictionId: '421f50fa-aeab-4f6a-952a-dcd535f3cc3d',
  },
];

export const areasV2 = [legislationArea, managementSystemsAndStandardsArea, environmentalManagementSystemsArea];

export default areas;
