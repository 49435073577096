import { AssetInput } from '@rio/rio-types';
import DromoUploader, { IDeveloperField, IDeveloperSettings } from 'dromo-uploader-react';
import React, { useMemo, useCallback } from 'react';
import { useCurrency, useCurrentAccount, useCurrentUser, useNotification } from '~/hooks';
import { getFullName } from '~/utils';
import { useImportAssets } from './hooks';
import dromoSchema from './dromo-schema.json';
import { useGicsCodes } from '~/hooks/useGicsCodes';
import { chunk } from 'lodash';
import { baseDromoConfiguration } from '../DromoConfiguration';
import { getEnvVar } from '../../../env';

interface ConfigurationAssetsUploadButtonProps {
  onCompleted: () => void;
}

export function ConfigurationAssetsUploadButton({ onCompleted }: ConfigurationAssetsUploadButtonProps) {
  const currentAccount = useCurrentAccount();

  const currentUser = useCurrentUser();

  const { data: currencies } = useCurrency();
  const { data: gicsCodes } = useGicsCodes();

  const { showNotification } = useNotification();

  const [importAssets] = useImportAssets();

  const handleResults = useCallback(
    async (results: AssetInput[]) => {
      const chunks: AssetInput[][] = chunk(results, 500);

      const responseResults = {
        created: 0,
        updated: 0,
        deleted: 0,
        failed: 0,
      };
      for (const assets of chunks) {
        const importAssetsResponse = await importAssets({
          variables: {
            assets,
            accountId: currentAccount.id,
          },
        });
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500);
        });
        if (importAssetsResponse.errors) {
          responseResults.failed += assets.length;
        } else if (importAssetsResponse.data) {
          responseResults.created += importAssetsResponse.data.importAssets.created;
          responseResults.updated += importAssetsResponse.data.importAssets.updated;
          responseResults.deleted += importAssetsResponse.data.importAssets.deleted;
        }
      }

      const { created, updated, deleted, failed } = responseResults;
      let message = `${created} created, ${updated} updated, ${deleted} deleted`;
      if (failed > 0) {
        message += `, ${failed} failed`;
      }
      showNotification(message);
      onCompleted();
    },
    [importAssets, currentAccount, onCompleted, showNotification]
  );

  if (!currentUser) {
    throw new Error('Configuration is not ready');
  }

  const user = {
    id: currentUser?.id || '',
    name: getFullName(currentUser),
    email: currentUser?.email,
    companyId: currentAccount.id,
    companyName: currentAccount.name,
  };

  const fields = useMemo(
    () =>
      dromoSchema.fields.map((field) => {
        switch (field.key) {
          case 'currencyId':
            return {
              ...field,
              selectOptions: currencies.map((currency) => ({ label: currency.name, value: currency.id })),
            };
          case 'gicsCodeId':
            return {
              ...field,
              selectOptions: gicsCodes.map((gicsCode) => ({
                label: `${gicsCode.code} - ${gicsCode.name}`,
                value: gicsCode.id,
              })),
            };
          default:
            return field;
        }
      }),
    [currencies, gicsCodes]
  );

  return (
    <DromoUploader
      licenseKey={getEnvVar('REACT_APP_DROMO_LICENSE_KEY')!}
      user={user}
      style={{
        ...baseDromoConfiguration.style,
        ...(dromoSchema.style as React.CSSProperties),
      }}
      settings={{
        ...baseDromoConfiguration.settings,
        ...(dromoSchema.settings as IDeveloperSettings),
      }}
      fields={fields as IDeveloperField[]}
      onResults={handleResults}
    >
      + Upload assets
    </DromoUploader>
  );
}
