import { AntivirusStatus } from '@rio/rio-types';
import { Icons, useTheme } from '@rio/ui-components';

type Props = {
  data: { antivirusStatus: string };
};

export function AntivirusStatusCell(props: Props) {
  const { data: { antivirusStatus } = {} } = props;
  const theme = useTheme();

  switch (antivirusStatus) {
    case AntivirusStatus.Infected: {
      return <Icons.Warning sx={{ color: theme.sys.color.error }} />;
    }
    case AntivirusStatus.Error: {
      return <Icons.Error sx={{ color: theme.customColors.warning.color }} />;
    }
    case AntivirusStatus.Pending: {
      return <Icons.Error sx={{ color: theme.sys.color.onSurface }} />;
    }
    case AntivirusStatus.Clean: {
      return <Icons.Check sx={{ color: theme.extendedColors.environmental.color }} />;
    }
    default:
      return null;
  }
}
