import styled from 'styled-components';
import { Fullscreen } from '../SvgIcons';

const ExpandButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: #83838e;
  &:hover {
    cursor: pointer;
  }
`;
const SpanStyled = styled.span`
  margin-left: 6px;
`;

interface ExpandButtonProps {
  onClick: () => void;
}

export const ExpandButton = ({ onClick }: ExpandButtonProps) => (
  <ExpandButtonContainer onClick={onClick}>
    <Fullscreen /> <SpanStyled>Expand</SpanStyled>
  </ExpandButtonContainer>
);
