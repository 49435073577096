import React from 'react';
import styled from 'styled-components';
import { TransactionType, MarketCarbonConversionFactorInput, Supplier, SupplierType } from '@rio/rio-types';
import { Col, Row, Label, TextInput, DatePicker, Heading, Checkbox } from 'rio-ui-components';
import { Errors } from '../../../types';
import { SupplierSelector } from '../../../components/SuppliersSelector';
import { useCurrentAccountId } from '../../../hooks';
import AccountSelector from '../../../components/AccountSelector';
import { validateInput } from './utils';

const DetailsContainer = styled(Col)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
export interface MarketCarbonConversionFactorDetailsProps {
  heading: string;
  isSubmitting: boolean;
  onChange: (factor: MarketCarbonConversionFactorInput) => void;
  setErrors: (errors: Errors<MarketCarbonConversionFactorInput>) => void;
  values: MarketCarbonConversionFactorInput;
  errors: Errors<MarketCarbonConversionFactorInput>;
}

export function MarketCarbonConversionFactorDetails({
  heading,
  isSubmitting,
  onChange,
  values,
  errors,
  setErrors,
}: MarketCarbonConversionFactorDetailsProps) {
  const accountId = useCurrentAccountId();
  const handleChange = ({ target }: React.ChangeEvent<any>) => {
    const name = target.name;
    const value =
      target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseFloat(target.value) : target.value;
    const factor = {
      ...values,
      [name]: value,
    };
    if (Object.keys(errors).length) {
      const newErrors = validateInput(factor);
      setErrors(newErrors);
    }
    onChange(factor);
  };
  return (
    <DetailsContainer span={12} container>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Tariff</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            disabled={isSubmitting}
            onChange={handleChange}
            name="tariff"
            value={values.tariff}
            error={errors.tariff}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Supplier</LabelStyled>
          </LabelContainerStyled>
          <SupplierSelector
            value={values.supplierId}
            name="supplierId"
            accountId={accountId}
            utility={TransactionType.Electricity}
            onChange={handleChange}
            error={errors.supplierId}
            filter={(supplier: Supplier) => {
              return supplier.type.includes(SupplierType.Contractor);
            }}
            includeEmpty
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Renewable (%)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            min={0}
            max={100}
            name="renewablePercentage"
            value={values.renewablePercentage}
            onChange={handleChange}
            error={errors.renewablePercentage}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Generation (kgCO2e)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            value={values.generationFactor}
            name="generationFactor"
            onChange={handleChange}
            error={errors.generationFactor}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Well-to-Tank (Generation) (kgCO2e)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            value={values.wellToTankForGenerationFactor}
            name="wellToTankForGenerationFactor"
            onChange={handleChange}
            error={errors.wellToTankForGenerationFactor}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Well-to-Tank (T&D) (kgCO2e)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            value={values.wellToTankForTransmissionAndDistributionFactor}
            name="wellToTankForTransmissionAndDistributionFactor"
            onChange={handleChange}
            error={errors.wellToTankForTransmissionAndDistributionFactor}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Transmission & Distribution (kgCO2e)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            value={values.transmissionAndDistributionFactor}
            name="transmissionAndDistributionFactor"
            onChange={handleChange}
            error={errors.transmissionAndDistributionFactor}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Start Date</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            placeholder="Select start date"
            name="startDate"
            disabled={isSubmitting}
            onDayClick={(startDate: Date) => {
              onChange({
                ...values,
                startDate: startDate.toISOString(),
              });
            }}
            selectedDate={values.startDate ? new Date(values.startDate) : null}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>End Date</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            placeholder="Select end date"
            name="endDate"
            disabled={isSubmitting}
            onDayClick={(endDate: Date) => {
              onChange({
                ...values,
                endDate: endDate.toISOString(),
              });
            }}
            selectedDate={values.endDate ? new Date(values.endDate) : null}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Reference</LabelStyled>
          </LabelContainerStyled>
          <TextInput value={values.reference} name="reference" onChange={handleChange} />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Notes</LabelStyled>
          </LabelContainerStyled>
          <TextInput value={values.notes} name="notes" onChange={handleChange} />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Visible to All Accounts</LabelStyled>
          </LabelContainerStyled>
          <Checkbox name="isGlobal" checked={values.isGlobal} onChange={handleChange} />
        </ColStyled>
      </Row>
      {!values.isGlobal && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Select Accounts</LabelStyled>
            </LabelContainerStyled>
            <AccountSelector
              name="accountIds"
              value={values.accountIds}
              onChange={(accounts: { id: string; name: string }[]) => {
                onChange({
                  ...values,
                  accountIds: accounts.map(({ id }) => id),
                });
              }}
              isMulti
            />
          </ColStyled>
        </Row>
      )}
    </DetailsContainer>
  );
}
