import React from 'react';
import { Text, Icon } from 'rio-ui-components';
import { TransactionType } from '@rio/rio-types';
import styled from 'styled-components';
import { getGridConfig, ColStyled, RowStyled } from './gridConfig';

const SupplierRowStyled = styled(RowStyled)`
  flex: 0 0 auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  opacity: 0.7;
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 1;
  }

  &:hover .slide-in-animation {
    transform: translateX(25px);
  }
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: ${(props) => `${props.theme.geometry.md.spacing} ${props.theme.geometry.sm.spacing}`};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

const PhoneLinkStyled = styled.a`
  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

const SupplierRow = ({
  supplierId,
  utility,
  name,
  type,
  address,
  phoneNumber,
  onEdit,
  materials,
  accountId,
  permissions
}) => {
  const gridConfig = getGridConfig(utility);
  const canEdit = type.includes('Contractor') ? permissions.data.includes('createSupplierAll') : true;

  return (
    <SupplierRowStyled container name={`supplier-row--${supplierId}`}>
      <ColStyled container vdistribution="center" item span={gridConfig[0].width}>
        <Text size="md">{name}</Text>
      </ColStyled>

      <ColStyled container item inline span={gridConfig[1].width}>
        {address && (
          <ColStyled supplierId={supplierId} inline>
            <Text name={`supplierRow__addressLine1--${supplierId}`} size="md">
              {address.address1}
            </Text>
            <Text name={`supplierRow__addressLine2--${supplierId}`} size="md">
              {address.address2}
            </Text>
            <Text name={`supplierRow__addressLine3--${supplierId}`} size="md">
              {address.address3}
            </Text>
            <Text name={`supplierRow__addressCity--${supplierId}`} size="md">
              {address.city}
            </Text>
            <Text name={`supplierRow__addressPostcode--${supplierId}`} size="md">
              {address.postcode}
            </Text>
            <Text name={`supplierRow__addressPostcode--${supplierId}`} size="md">
              {address.country.name}
            </Text>
            {phoneNumber && (
              <Text>
                <PhoneLinkStyled
                  name={`supplier-row__phoneNumber--${supplierId}`}
                  size="md"
                  href={`tel:${phoneNumber}`}
                >
                  {phoneNumber}
                </PhoneLinkStyled>
              </Text>
            )}
          </ColStyled>
        )}
      </ColStyled>

      <ColStyled vdistribution="center" span={gridConfig[2].width} container item>
        {utility === TransactionType.Materials ? materials?.map(({ name }) => name).join(', ') : type.join(', ')}
      </ColStyled>

      <ColStyled vdistribution="center" span={gridConfig[3].width} container item>
        <Text name={`supplierRow__supplierScope--${supplierId}`} size="md">
          {Boolean(accountId) ? 'Account' : 'Global'}
        </Text>
      </ColStyled>

      <ColStyled vdistribution="center" span={gridConfig[4].width} container item>
        {canEdit && (
          <LinkStyled onClick={onEdit}>
            <Icon name={`supplierRow__edit--${supplierId}`} icon="pencil-alt" />
          </LinkStyled>
        )}
      </ColStyled>
    </SupplierRowStyled>
  );
};

export default SupplierRow;
