import { gql } from '@apollo/client';
import { FLOW_DETAILS } from '../../containers/ConfigurationContainer/ConfigurationFlowsSubscriptionsContainer/SubscriptionsSelectionContainer/index.queries';

export const GET_FLOWS = gql`
  query GetFlows {
    getFlows {
      ...FlowDetails
    }
  }
  ${FLOW_DETAILS},
`;
