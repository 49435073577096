import { useQuery, ApolloError } from '@apollo/client';
import { Select, OptionsProps } from '@rio/ui-components';
import { Nullable } from '~/types';
import { toSelectOptions } from '~/utils';
import { GET_ALL_UNIT_TYPES } from './index.queries';

interface MeasurementUnitSelectProps {
  value?: string;
  onChange: (e: OptionsProps) => void;
  label?: string;
  error?: Nullable<string>;
  possibleOptions?: Nullable<string[]>;
  placeholder?: Nullable<string>;
}
export const MeasurementUnitSelectV2 = ({
  value,
  label,
  onChange,
  placeholder,
  possibleOptions,
  error: validationError,
}: MeasurementUnitSelectProps) => {
  const { data, loading, error } = useQuery(GET_ALL_UNIT_TYPES);
  const allOptions = toSelectOptions(data?.getAllUnits);
  const validOptions = allOptions.filter((option) => possibleOptions?.includes(option.label));
  const currentError = error || validationError;
  const helperText = currentError instanceof ApolloError ? currentError.message : currentError;

  return (
    <Select
      placeholder={placeholder || 'Please select unit of measurement'}
      value={value}
      label={label}
      isLoading={loading}
      options={validOptions}
      error={!!currentError}
      helperText={helperText || ''}
      onChange={(e) => onChange(e)}
    />
  );
};
