import { useQuery } from '@apollo/client';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS } from '../queries/managementSystems';
import { GovernanceItemType } from '@rio/rio-types';
import { useCurrentAccountId } from './useCurrentAccountId';

export function useManagementSystems() {
  const accountId = useCurrentAccountId();

  const { data } = useQuery(GET_AVAILABLE_MANAGEMENT_SYSTEMS, {
    variables: { accountId, type: GovernanceItemType.ManagementSystem }
  });

  return data?.getAvailableManagementSystems || [];
}
