import { useQuery } from '@apollo/client';
import { Query, TimeGranularity } from '@rio/rio-types';
import { KpiChartCard, ColumnChart, StackedChart, styled } from '@rio/ui-components';
import { oneYearBefore, today } from '~/utils';
import { useCurrentAccountId } from '~/hooks';
import { GET_REPORTING_OVERVIEW } from './index.queries';
import { ScopeBreakdownPieChart } from '~/containers/HomeContainer/v2/Reporting/ScopeBreakdownPieChart';
import { EmissionsByScopeSource } from './EmissionsByScopeSource';

type Queries =
  | 'getTotalEmissions'
  | 'getEmissionsPercentageBreakdownByScope'
  | 'getEmissionsBySource'
  | 'getEmissionsByScopeAndSource'
  | 'getEmissionsNetAndGross';

type Response = Pick<Query, Queries>;

type Variables = {
  accountId: string;
  timeGranularity: TimeGranularity;
  from: string;
  to: string;
};

const WrapperStyled = styled('div')`
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 100%;
`;

const RowStyled = styled('div')`
  display: flex;
  gap: 32px;
  width: 100%;

  & > div:first-child {
    max-width: 257px;
  }
`;

const StackedChartWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 32px 18px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

const PieChartWrapperStyled = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 16px;
  padding: 32px 18px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

const ColumnChartWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 24px 18px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

const from = oneYearBefore().format('YYYY-MM-DD');
const to = today().format('YYYY-MM-DD');

export function ReportingOverview() {
  const accountId = useCurrentAccountId();
  const { data, loading } = useQuery<Response, Variables>(GET_REPORTING_OVERVIEW, {
    variables: {
      accountId,
      timeGranularity: TimeGranularity.Month,
      to,
      from,
    },
  });
  if (loading || !data) {
    return null;
  }

  return (
    <WrapperStyled>
      <RowStyled>
        <KpiChartCard title="Total Carbon (tCO2e)" value={data.getTotalEmissions || 0} decimalPlaces={2} />
        <PieChartWrapperStyled>
          <ScopeBreakdownPieChart scopeBreakdown={data.getEmissionsPercentageBreakdownByScope} />
        </PieChartWrapperStyled>
      </RowStyled>
      {data?.getEmissionsBySource && (
        <StackedChartWrapperStyled>
          <StackedChart emissionsData={data.getEmissionsBySource} />
        </StackedChartWrapperStyled>
      )}
      <EmissionsByScopeSource emissions={data?.getEmissionsByScopeAndSource?.emissions || []} />
      <ColumnChartWrapperStyled>
        <ColumnChart title="Gross and net carbon emissions (tCO2e)" data={data?.getEmissionsNetAndGross || []} />
      </ColumnChartWrapperStyled>
    </WrapperStyled>
  );
}
