import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoutes } from '~/hooks';

import { Survey } from '@rio/rio-types';
import { Modal } from '@rio/ui-components';
import { SurveyFormProvider } from '../../SurveysContainer/provider/SurveyFormProvider';
import { CreateSurvey } from './CreateSurvey';
import { UpdateSurvey } from './UpdateSurvey';

export type ActionTypes = {
  createModalShown: boolean;
  editModalShown: boolean;
  toggleCreateModal: () => void;
  toggleEditModal: () => void;
};

interface SurveyOverviewHeaderProps {
  survey: Survey | null;
  action: ActionTypes;
}

export const SurveyOverviewHeader = ({ survey, action }: SurveyOverviewHeaderProps) => {
  const { createModalShown, toggleCreateModal, editModalShown, toggleEditModal } = action;

  const routes = useRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const isAllSurveysTab = pathname.includes(routes.surveys.sent.surveys);

  const onDelete = useCallback(() => {
    if (isAllSurveysTab) {
      navigate(routes.surveys.sent.surveys);
    }
  }, [navigate, routes.surveys.sent.surveys, isAllSurveysTab]);

  return (
    <>
      {createModalShown && (
        <SurveyFormProvider>
          <Modal
            title="Create a survey"
            viewportHeight={80}
            onClose={toggleCreateModal}
            open={createModalShown}
            grid={{ md: 12, lg: 'auto' }}
          >
            <CreateSurvey
              onDismiss={toggleCreateModal}
              submitButtonText="Create Survey"
              buttonDescription="This will send your survey to your recipients"
            />
          </Modal>
        </SurveyFormProvider>
      )}

      {editModalShown && survey && (
        <Modal
          viewportHeight={80}
          title="Edit a survey"
          onClose={toggleEditModal}
          open={editModalShown}
          grid={{ md: 12, lg: 'auto' }}
        >
          <SurveyFormProvider survey={survey}>
            <UpdateSurvey existingSurvey={survey} onDismiss={toggleEditModal} onDelete={onDelete} />
          </SurveyFormProvider>
        </Modal>
      )}
    </>
  );
};
