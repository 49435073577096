import { useMutation } from '@apollo/client';
import UPLOAD_DATA_MUTATION from '../graphql/mutations/UploadData.mutation.graphql';
import INITIATE_DATA_UPLOAD_MUTATION from '../graphql/mutations/InitialDataUpload.mutation.graphql';
import { UploadFileHook as UploadFileByAccountIdMutationHook } from './useUploadFileMutation';

export function useUploadFileByAccountIdMutation(): UploadFileByAccountIdMutationHook {
  const [initiateDataUploading, { loading: isInitialDataLoading }] = useMutation(INITIATE_DATA_UPLOAD_MUTATION);
  const [uploadFile, { loading: isUploadFileLoading, ...others }] = useMutation(UPLOAD_DATA_MUTATION);

  return [
    async ({ file, fileName, accountId, ...rest } = {}) => {
      const tron = await initiateDataUploading({
        variables: {
          fileName,
          accountId,
          ...rest,
        },
      });
      const { data: { initiateDataUpload: signedUrl } = {} } = tron;

      return uploadFile({
        variables: {
          file,
          fileName,
          accId: accountId,
          url: signedUrl,
        },
      });
    },

    {
      ...others,
      loading: isInitialDataLoading || isUploadFileLoading,
    },
  ];
}
