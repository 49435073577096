import React from 'react';
import styled from 'styled-components';
import truncate from 'lodash/truncate';
import { Col, Row, MarkdownRenderer } from 'rio-ui-components';
import { COURSE_BUNDLE } from '../../constants/marketProductType';

const Product = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
`;

const BasketCol = styled(Col)`
  ${({ isInCart }) => `
    flex-basis: content;
    color: ${isInCart ? 'unset' : '#50b9f9'};
    font-weight: ${isInCart ? 'unset' : 'bold'};
    text-decoration: ${isInCart ? 'unset' : 'underline'};
    cursor: ${isInCart ? 'unset' : 'pointer'};;
  `}
`;

const ProductHeader = styled.div`
  background: #50b9f9;
  height: 60px;
  align-items: center;
  display: flex;
  padding: 0 15px 0 15px;
  flex-direction: row;
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`;

const ProductLeft = styled.div`
  flex: 1 1 100%;
  display: flex;
`;

const CategoryCol = styled.div`
  border-radius: 12px;
  padding: 6px 10px 6px 10px;
  border: 1px solid;
  font-size: 12px;
  margin-left: 10px;
`;

const ProductContent = styled.div`
  min-height: 150px;
  padding: 20px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const ProductDescription = styled.div`
  flex: 1 1 100%;
  display: flex;
  margin: 10px 0 5px 0;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 20px 0 0;
  float: left;
`;

const ProductFooter = styled.div`
  > div {
    padding: 0 20px 20px;
    align-items: center;
  }
`;

const CounterWrapper = styled(Row)`
  align-items: center;
  text-align: right;
`;

const TextLeft = styled.span`
  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`;

const TextRight = styled.div`
  text-align: right;
  color: ${(props) => props.theme.colors.senary.light.text};

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`;

const ColLeft = styled(Col)`
  text-align: left;
`;

export const formatPrice = (value) => {
  const price = value ? parseFloat(value) : 0;
  return price.toFixed(2);
};

const ProductCard = (props) => {
  const {
    id,
    title,
    productType,
    description,
    synopsis,
    price,
    addProduct = () => {},
    handlePreview = () => {},
    link,
    firstCategory,
    image,
    isInCart = false
  } = props;

  const product = {
    id,
    title,
    price,
    productType,
    previewProductInfo: props?.courses ? props.courses[0] : null
  };

  const handleAddProduct = (e) => {
    e.preventDefault();

    addProduct(product);
  };

  return (
    <Product name="Courses-Tab__Product-Card">
      <ProductHeader>
        <ProductLeft name="Product-Card__Product-Left--Title">
          <h4>{title}</h4>
        </ProductLeft>
        {firstCategory ? <CategoryCol>{firstCategory}</CategoryCol> : null}
      </ProductHeader>

      <ProductContent>
        <ProductDescription>
          {image ? <PreviewImage src={image} /> : null}
          {productType === COURSE_BUNDLE ? (
            <MarkdownRenderer
              name="Bundle__MarkDown--Description"
              source={truncate(description, {
                length: 100,
                separator: ' ',
                omission: ' ...'
              })}
            />
          ) : (
            synopsis
          )}
        </ProductDescription>
      </ProductContent>

      <ProductFooter>
        <CounterWrapper container>
          <Col span="12">
            <TextRight>£{formatPrice(price)}</TextRight>
            ex. VAT
          </Col>
        </CounterWrapper>
        <CounterWrapper container>
          <ColLeft span="6">
            <TextLeft>
              {link ? (
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePreview(props);
                  }}
                >
                  Course Preview
                </a>
              ) : null}
            </TextLeft>
          </ColLeft>
          <Col span="6">
            {isInCart ? (
              <BasketCol isInCart={isInCart} container>
                In Basket
              </BasketCol>
            ) : (
              <BasketCol name="Product-Card__Add-To-Basket--Button" container onClick={handleAddProduct}>
                Add To Basket
              </BasketCol>
            )}
          </Col>
        </CounterWrapper>
      </ProductFooter>
    </Product>
  );
};
export default ProductCard;
