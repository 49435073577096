import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DocumentRecord, CreateDocumentRecordInput, ManagementSystem, DocumentLibrary } from '@rio/rio-types';
import { Heading, Col, MarkdownRenderer, Button, Carousel, EvidenceUploadItem } from 'rio-ui-components';
import {
  CircleIconStyled,
  TitleContainerStyled,
  DocumentAndChildContainer,
  DocumentContainer,
  FlexRow,
  PaddedContainer
} from '../../components/StyledElements';
import MarkdownViewEditor from '../MarkdownViewEditor';
import { UploadDocumentModal } from '../../containers/DocumentContainer/DocumentModal/UploadDocumentModal';
import { NullableObject, Nullable, ManagementSystemPage } from '../../types';
import { AddDocumentTile } from '../AddDocumentTile';
import { calculateEvidenceTileNumber } from '../../utils/managementSystemUtils';
import { useAttachDocumentToManagementSystem } from './useAttachDocumentToManagementSystem';
import { useAttachContentToManagementSystem } from './useAttachContentToManagementSystem';
import { useCurrentAccountId } from '../../hooks';
import { escape } from '../../utils/escape';

const ManagementSystemContainer = styled(Col)`
  flex: 1;
  padding-left: ${(props) => props.theme.geometry.md.spacing};
`;

const HeaderContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const RowStyled = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

const HeadingStyled = styled(Heading)`
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const Section = styled(Col)`
  flex-grow: ${(props) => props.flexGrow || 0};
  background-color: ${(props) => props.theme.colors.basic.white};
  padding: ${(props) => props.theme.geometry.sm.spacing};
  margin-bottom: ${(props) => ('marginBottom' in props ? `${props.marginBottom}px` : props.theme.geometry.md.spacing)};
`;

const ButtonCol = styled(Col)`
  flex-grow: 0;
  flex-basis: 220px;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

export interface MarkdownSynopsis {
  content: Nullable<string>;
  mode: 'markdown';
  editable: boolean;
}

export interface JsxSynopsis {
  content: React.ReactNode;
  mode: 'jsx';
}

export interface DocumentInfo {
  records: DocumentRecord[];
  readonly: boolean;
  predefinedValues?: NullableObject<CreateDocumentRecordInput>;
}

export interface ButtonInfo {
  title: string;
  color: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface HeaderInfo {
  title: string;
  buttons?: Nullable<ButtonInfo[]>;
}

export interface ContentInfo {
  header?: Nullable<string>;
  loading?: boolean;
  render: () => React.ReactNode;
}

interface ManagementSystemChapterLayoutProps {
  header: HeaderInfo;
  synopsis: MarkdownSynopsis | JsxSynopsis;
  content?: ContentInfo;
  documents: false | DocumentInfo;
  page: ManagementSystemPage;
  managementSystem: ManagementSystem;
}

export function ManagementSystemChapterLayout({
  header,
  synopsis,
  content,
  documents,
  managementSystem,
  page
}: ManagementSystemChapterLayoutProps) {
  const [uploadModalShown, setUploadModalShown] = useState(false);
  const [attachDocumentToManagementSystem] = useAttachDocumentToManagementSystem();
  const [attachContentToManagementSystem] = useAttachContentToManagementSystem();
  const accountId = useCurrentAccountId();
  const documentPredefinedValues = useMemo(() => {
    if (documents) {
      return {
        ...documents.predefinedValues,
        library: DocumentLibrary.Governance,
        category: managementSystem.type === 'MANAGEMENT_SYSTEM' ? 'Management Systems' : 'Reporting Frameworks',
        managementSystemId: managementSystem.id,
        managementSystemChapterId: page.id,
        managementSystemCode: managementSystem.shortCode
      };
    }
  }, [documents, managementSystem, page]);

  const handleDocumentUpload = useCallback(
    (_: string, document: DocumentRecord) => {
      attachDocumentToManagementSystem({
        variables: {
          input: {
            accountId,
            id: managementSystem.id,
            linkId: page.id,
            documentId: document.id
          }
        }
      });
    },
    [attachDocumentToManagementSystem, managementSystem, page, accountId]
  );

  const handleAttachContent = useCallback(
    (content: string) => {
      attachContentToManagementSystem({
        variables: {
          input: {
            accountId,
            id: managementSystem.id,
            linkId: page.id,
            synopsis: escape(content)
          }
        }
      });
    },
    [attachContentToManagementSystem, accountId, managementSystem, page]
  );

  const handleDismiss = useCallback(() => {
    setUploadModalShown(false);
  }, [setUploadModalShown]);

  return (
    <ManagementSystemContainer container item>
      <Section container item>
        <TitleContainerStyled distribution="between" item container>
          <HeaderContainer span={8} item>
            <CircleIconStyled
              inline
              height="50px"
              size="xl"
              circleColor="tertiary"
              iconColor="tertiary"
              icon="pencil-alt"
            />
            <Heading inline>{header.title}</Heading>
          </HeaderContainer>
          {header.buttons?.map((button: ButtonInfo, i) => (
            <ButtonCol key={`ManagementSystemChapterLayout__ButtonCol__${i}`} grow={0} item>
              <ButtonStyled
                size="md"
                color={button.color}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  button.onClick(e);
                }}
                inline
              >
                {button.title}
              </ButtonStyled>
            </ButtonCol>
          ))}
        </TitleContainerStyled>
        <RowStyled>
          <HeadingStyled size="md">Overview:</HeadingStyled>
          {synopsis.mode === 'markdown' ? (
            <>
              {synopsis.editable ? (
                <MarkdownViewEditor
                  onSave={handleAttachContent}
                  source={synopsis.content || 'Add your own content here.'}
                />
              ) : (
                <MarkdownRenderer source={synopsis.content || 'Add your own content here.'} />
              )}
            </>
          ) : (
            synopsis.content
          )}
        </RowStyled>
      </Section>

      {content && (
        <Section flexGrow={1} marginBottom={documents ? 24 : 0}>
          {content.header && (
            <FlexRow>
              <TitleContainerStyled container item>
                <HeaderContainer span={12} item>
                  <Heading inline>{content.header}</Heading>
                </HeaderContainer>
              </TitleContainerStyled>
            </FlexRow>
          )}
          {content.loading ? 'Loading...' : content.render()}
        </Section>
      )}

      {documents && (
        <DocumentAndChildContainer container item>
          <DocumentContainer>
            <FlexRow>
              <TitleContainerStyled distribution="start" span={12}>
                <CircleIconStyled
                  inline
                  height="50px"
                  size="xl"
                  circleColor="tertiary"
                  iconColor="tertiary"
                  icon="file-alt"
                />
                <Heading inline>Evidence Library</Heading>
              </TitleContainerStyled>
            </FlexRow>
            <FlexRow>
              <HeadingStyled size="md">
                Upload documents to support this section of the management system.
              </HeadingStyled>
            </FlexRow>
            <PaddedContainer>
              <Carousel show={3} scroll={1} width="675px" infinite={false}>
                {new Array(calculateEvidenceTileNumber(documents.records)).fill(0).map((d, i) => (
                  <AddDocumentTile
                    id={d.id}
                    key={`upload-evidence${i}`}
                    disabled={documents.readonly}
                    onClick={() => setUploadModalShown(true)}
                  >
                    <EvidenceUploadItem title="Upload Evidence" />
                  </AddDocumentTile>
                ))}
                {documents?.records?.map((doc: DocumentRecord) => (
                  <Link key={doc.id} target="_blank" to={`/documents/all/${doc.id}`}>
                    <EvidenceUploadItem
                      title={doc.managementSystemCode ? `${doc.managementSystemCode.toUpperCase()}` : `Evidence`}
                      desc={doc.fileName}
                      uploaded={true}
                    />
                  </Link>
                ))}
              </Carousel>
            </PaddedContainer>
          </DocumentContainer>
        </DocumentAndChildContainer>
      )}
      {documents && uploadModalShown && (
        <UploadDocumentModal
          predefinedValues={documentPredefinedValues}
          onDismiss={handleDismiss}
          onUpload={handleDocumentUpload}
        />
      )}
    </ManagementSystemContainer>
  );
}
