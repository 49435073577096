import { gql } from '@apollo/client';

export const ON_USER_UPDATED_SUBSCRIPTION = gql`
  subscription OnUserUpdated($id: ID!) {
    userUpdated(id: $id) {
      id
      email
      first_name
      last_name
      jobTitle
      department
      phone
      mobile
      profilePicture
      roles
      account {
        id
        name
        type
        logoPath
        children {
          id
          name
          logoPath
        }
      }
      role {
        role
        name
        permissions {
          user
          account
          data
          governance
          document
          tag
          transposition
          email
          notification
          learn
          dashboard
          task
          ocr
          intelligence
        }
      }
    }
  }
`;
