import React, { useState } from 'react';
import { Modal, PropertySheet } from 'rio-ui-components';
import { RETURN_KEY } from '../../constants/keyCodes';

interface NameYourFileModalProps {
  show: boolean;
  onDismiss: () => void;
  onSubmit: (fileName: string) => void;
  defaultFileName: string;
}

export function NameYourFileModal({ show, onDismiss, onSubmit, defaultFileName }: NameYourFileModalProps) {
  const [fileName, setFileName] = useState('');
  const handleSubmit = (fileName: string) => {
    onSubmit(fileName);
    setFileName('');
  };
  return (
    <Modal size="md" show={show} onDismiss={onDismiss} height="auto" maxHeight="80vh">
      <PropertySheet>
        <PropertySheet.Row container>
          <PropertySheet.TextInputColumn
            textInputRef={(input: HTMLInputElement) => {
              input?.focus();
            }}
            name="fileName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value)}
            label="File name"
            value={fileName}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === RETURN_KEY) {
                handleSubmit(fileName || defaultFileName);
              }
            }}
          />
        </PropertySheet.Row>
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn
            span={6}
            color="primary"
            onClick={() => {
              handleSubmit(defaultFileName);
            }}
          >
            Use default
          </PropertySheet.ButtonColumn>

          <PropertySheet.ButtonColumn
            span={6}
            disabled={!fileName}
            color="tertiary"
            onClick={() => {
              handleSubmit(fileName);
            }}
          >
            Confirm
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Modal>
  );
}
