import { UserPermissions } from '@rio/rio-types';
import { Text } from '@rio/ui-components';
import { usePermissions, useUserToken } from '~/hooks';
import { DateLike } from '~/types';

const checkPermissions = (permissions: UserPermissions, action: string, isOwnResource: boolean) =>
  !!permissions.data?.find((a) => a?.startsWith?.(action) && !a?.endsWith?.('Own')) ||
  (permissions.data?.includes(`${action}Own`) && isOwnResource);

const RollBackStatusLink = ({ id, onClick }: { id: string; onClick: (id: string) => void }) => {
  return (
    <Text
      size="medium"
      typescale="body"
      onClick={() => onClick(id)}
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
    >
      Roll Back
    </Text>
  );
};

export const RollbackCell = ({
  data,
  onClick,
}: {
  data: { lastUpdated: DateLike; id: string; status: string; userId: string };
  onClick: (id: string) => void;
}) => {
  const { token } = useUserToken();

  const permissions = usePermissions();

  const isOwnResource = data.userId === token.sub;
  const isCompleted = data.status === 'Completed';

  const showRollbackButton = isCompleted && checkPermissions(permissions, 'rollbackImportBatch', isOwnResource);
  if (showRollbackButton) {
    return <RollBackStatusLink id={data.id} onClick={onClick} />;
  }
  return null;
};
