import { PieChartData } from '@rio/rio-types';
import { NavigationDrawer, Tabs, RadialChart, Icons } from '@rio/ui-components';
import { ScopeBreakdownPieChart } from './ScopeBreakdownPieChart';
import { TitleStyles, BlockStyled, RadialChartWrapper, ButtonStyled, WrapperStyled, ChartPieWrapper } from './style';

interface ReportingProps {
  scopeBreakdown: PieChartData | undefined;
}

export const Reporting = ({ scopeBreakdown }: ReportingProps) => {
  const items = [
    {
      to: '',
      item: 'Carbon emissions by scope',
      isActive: true,
    },
  ];

  const tabs = [
    {
      label: 'Environmental',
      children: (
        <BlockStyled>
          <NavigationDrawer items={items} />
          <ChartPieWrapper>
            {scopeBreakdown && <ScopeBreakdownPieChart scopeBreakdown={scopeBreakdown} />}
          </ChartPieWrapper>
        </BlockStyled>
      ),
    },
    {
      label: 'ESG',
      children: (
        <RadialChartWrapper>
          <RadialChart />
        </RadialChartWrapper>
      ),
    },
  ];

  return (
    <WrapperStyled>
      <ButtonStyled variant="text" to="/v2/reporting">
        <Icons.ArrowForward />
        View all reports
      </ButtonStyled>
      <TitleStyles typescale="title" size="medium">
        Reporting
      </TitleStyles>
      <Tabs tabs={tabs} />
    </WrapperStyled>
  );
};
