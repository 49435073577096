import { useApolloClient } from '@apollo/client';
import { DataImportBatch, Query, QueryGetDataImportBatchImportFileUrlArgs } from '@rio/rio-types';
import { Text } from '@rio/ui-components';
import { useCallback } from 'react';
import { GET_DATA_BATCH_IMPORT_FILE_URL } from '~/containers/DataContainer/index.queries';
import { downloadFileFromUrl } from '~/utils';

type GetDataImportBatchImportFileUrl = Pick<Query, 'getDataImportBatchImportFileUrl'>;

export type ImportFileCellProps = {
  data: DataImportBatch;
};

const ImportFileCell = ({ data }: ImportFileCellProps) => {
  const client = useApolloClient();
  const onExportClick = useCallback(
    async (id: string) => {
      const {
        data: { getDataImportBatchImportFileUrl: url },
      } = await client.query<GetDataImportBatchImportFileUrl, QueryGetDataImportBatchImportFileUrlArgs>({
        query: GET_DATA_BATCH_IMPORT_FILE_URL,
        variables: { id },
      });

      if (url) {
        downloadFileFromUrl(url);
      }
    },
    [client]
  );

  return data.fileName ? (
    <Text size="medium" typescale="body" onClick={() => onExportClick(data.id)}>
      {data.fileName}
    </Text>
  ) : null;
};

export { ImportFileCell };
export default ImportFileCell;
