import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Search, Button, Notification } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import { useSearch } from '../../../hooks/useSearch';
import { GET_ALL_EVALUATIONS } from './index.queries';
import { EvaluationsGrid } from './EvaluationsGrid';
import { EvaluationModal } from './EvaluationModal';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { usePermissions } from '../../../hooks/usePermissions';

const CreateButton = styled(Button)`
  width: 250px;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

export function ConfigurationLearnEvaluations() {
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { data, error, refetch } = useQuery(GET_ALL_EVALUATIONS, {
    variables: { accountId: permissions.learn.includes('createEvaluationAll') ? null : accountId }
  });
  const [searchValue, setSearchValue, searchResults] = useSearch(data?.getAllEvaluations || [], ['id', 'name', 'type']);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editedEvaluation, setEditedEvaluation] = useState(null);
  const [notification, setNotification] = useState(null);
  const onError = (e) => {
    setNotification({
      message: e.message,
      color: 'danger'
    });
  };
  return (
    <Col>
      <ContainerHeader icon="briefcase" iconColor="primary" title="Engage Evaluations">
        <Col container item>
          <Search
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            hideButton
          />
        </Col>
        <CreateButton
          onClick={() => {
            setShowCreateModal(true);
          }}
        >
          + Add Evaluation
        </CreateButton>
      </ContainerHeader>
      <EvaluationsGrid evaluations={searchResults} error={error} retry={refetch} onEdit={setEditedEvaluation} />
      {showCreateModal && (
        <EvaluationModal
          heading="Create Evaluation"
          onDismiss={() => {
            setShowCreateModal(false);
          }}
          onCompleted={() => {
            setShowCreateModal(false);
            setNotification({
              message: 'Evaluation has been successfully created!',
              color: 'success'
            });
          }}
          onError={onError}
        />
      )}
      {editedEvaluation && (
        <EvaluationModal
          heading="Edit Evaluation"
          onDismiss={() => {
            setEditedEvaluation(null);
          }}
          onCompleted={() => {
            setEditedEvaluation(null);
            setNotification({
              message: 'Evaluation has been successfully edited!',
              color: 'success'
            });
          }}
          onError={onError}
          defaultEvaluation={editedEvaluation}
        />
      )}
      {notification && (
        <Notification name={notification.message} show color={notification.color} onClose={() => setNotification(null)}>
          {notification.message}
        </Notification>
      )}
    </Col>
  );
}
