import { ICellRendererParams, RowModelType } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { UserGridProps } from '../../containers/ConfigurationContainer/ConfigurationUsersContainer/types';
import { UserActions } from '../../containers/ConfigurationContainer/ConfigurationUsersContainer/UserActions';
import { useUserGridConfig } from '../../containers/ConfigurationContainer/ConfigurationUsersContainer/useUserGridConfig';
import { useAgGrid } from '../../hooks';
import { mapSortModel, mapFilterModel } from '../../utils';
import { AgGridReact } from 'ag-grid-react';

const defaultConfig = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: false,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

const UserGrid = ({ accountId, setGridApi, fetchRows, showModal, roles }: UserGridProps) => {
  const config = useUserGridConfig(roles);

  const gridConfig = useMemo(
    () => ({
      ...defaultConfig,
      ...config,
    }),
    [config]
  );
  const agGrid = useAgGrid();

  const onEdit = useCallback(
    (user) => {
      showModal('update', user.id.toLowerCase());
    },
    [showModal]
  );

  const onPasswordReset = useCallback(
    (user) => {
      showModal('resetPassword', user.id.toLowerCase());
    },
    [showModal]
  );

  const components = useMemo(
    () => ({
      actions: (props: ICellRendererParams) => {
        // column value - readonly field
        return !props.value ? (
          <UserActions user={props.data} onEdit={onEdit} onPasswordReset={onPasswordReset} />
        ) : null;
      },
    }),
    [onEdit, onPasswordReset]
  );

  const onGridReady = useCallback(
    (gridReadyParams) => {
      setGridApi({ api: gridReadyParams.api, columnApi: gridReadyParams.columnApi });
    },
    [setGridApi]
  );

  const serverSideDatasource = useMemo(
    () => ({
      async getRows(params) {
        try {
          const { startRow, sortModel, filterModel } = params.request;

          const { rows, totalRows } = await fetchRows({
            accountId,
            offset: startRow || 0,
            limit: gridConfig.paginationPageSize,
            sort: mapSortModel(sortModel),
            filters: mapFilterModel(filterModel),
          });
          params.success({
            rowData: rows,
            rowCount: totalRows,
          });
        } catch (err) {
          params.fail();
        }
      },
    }),
    [accountId, fetchRows, gridConfig.paginationPageSize]
  );

  return (
    <div className="ag-theme-alpine" style={{ height: '100vh' }}>
      <AgGridReact
        {...gridConfig}
        paginationPageSize={gridConfig.paginationPageSize}
        cacheBlockSize={gridConfig.paginationPageSize}
        key={accountId} // This will re-mount the grid and refetch the data when account changes
        components={components}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        serverSideDatasource={serverSideDatasource}
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default UserGrid;
