import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Tag, CreateDocumentRecordInput } from '@rio/rio-types';
import { CREATE_DOCUMENT_RECORD } from '../index.queries';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { DocumentDetails } from './DocumentDetails';
import { DocumentModal } from './DocumentModal';
import { NullableObject } from '../../../types';

interface EditDocumentModalProps {
  onComplete: (document: NullableObject<CreateDocumentRecordInput>) => void;
  onError: (error: Error) => void;
  onDismiss: () => void;
  document: CreateDocumentRecordInput & {
    tags: (Tag & { value: string })[];
  };
}

export function EditDocumentModal({ onComplete, onError, onDismiss, document }: EditDocumentModalProps) {
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const [editedDocument, setEditedDocument] = useState<NullableObject<CreateDocumentRecordInput>>(document);
  const [createDocumentRecord, { loading: isSubmitting }] = useMutation(CREATE_DOCUMENT_RECORD);
  const newTags = editedDocument?.tags?.map((tag: any) => tag.value || tag.id);
  return (
    <DocumentModal
      heading="Edit Document"
      onDismiss={onDismiss}
      submitButtonTitle={isSubmitting ? 'Updating...' : 'Update'}
      onSubmit={async () => {
        try {
          const variables = {
            ...editedDocument,
            notes: editedDocument.notes?.trim(),
            tags: newTags,
            accountId,
            userId: token.sub,
            fileName: document.fileName
          };
          await createDocumentRecord({
            variables
          });
          onComplete(variables);
        } catch (err) {
          onError(new Error(`Document ${document.fileName} failed to update`));
        }
      }}
    >
      <DocumentDetails document={editedDocument} onChange={setEditedDocument} isSubmitting={isSubmitting} />
    </DocumentModal>
  );
}
