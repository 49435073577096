import { useQuery } from '@apollo/client';
import React from 'react';
import { LoadingIndicator } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SubscriptionSelector from './SubscriptionSelector';
import UserSubscriptionSelector from './UserSubscriptionSelector';
import { GET_ACCOUNT_BY_ID, GET_COURSE_BY_ID } from './index.queries';
import { usePermissions, useCurrentAccountId } from '../../../hooks';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ConfigurationSubscriptionSelectionContainer = () => {
  const accountId = useCurrentAccountId();
  const params = useParams();
  const { isSystemAdmin } = usePermissions();

  const { data, loading } = useQuery(isSystemAdmin ? GET_ACCOUNT_BY_ID : GET_COURSE_BY_ID, {
    variables: { id: params.accountId, accountId },
  });

  return (
    <>
      {loading && (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      )}
      {!loading && data && isSystemAdmin && (
        <SubscriptionSelector
          accountId={params.accountId}
          title={`Learn Subscriptions - ${data.getAccountInfo.name}`}
        />
      )}
      {!loading && data && !isSystemAdmin && (
        <UserSubscriptionSelector
          courseId={data.getCourseById.id}
          accountId={accountId}
          title={`Learn Subscriptions - ${data.getCourseById.name}`}
        />
      )}
    </>
  );
};

export default ConfigurationSubscriptionSelectionContainer;
