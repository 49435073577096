import React from 'react';
import styled from 'styled-components';
import { Row, Col, TextLink } from 'rio-ui-components';
import { useDeleteAspectOption } from './useDeleteAspectOption';
import { OptionToDelete } from './useOptionToDeleteFromQueryParam';

const ReviewAspectsRowContainer = styled(Row)`
  padding-left: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.md.spacing};
`;
const ReviewAspectsRowCol = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;
const LinkButton = styled(TextLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const DangerLinkButton = styled(LinkButton)`
  color: red;
  &:hover {
    color: red;
  }
`;
interface ReviewAspectsRowProps {
  accountId: string;
  optionToDelete: OptionToDelete;
  onCancelReview: () => void;
}

export function ReviewAspectsRow({ accountId, optionToDelete, onCancelReview }: ReviewAspectsRowProps) {
  const [deleteAspectOption] = useDeleteAspectOption({
    onCompleted: () => {
      onCancelReview();
    }
  });
  return (
    <ReviewAspectsRowContainer alignItems="center" container>
      <ReviewAspectsRowCol item span={6}>
        <DangerLinkButton
          color="danger"
          onClick={() => {
            deleteAspectOption({
              variables: {
                accountId,
                type: optionToDelete.option.type,
                id: optionToDelete.option.id
              }
            });
          }}>
          Yes, delete {optionToDelete.option.name}
        </DangerLinkButton>
      </ReviewAspectsRowCol>
      <ReviewAspectsRowCol item span={6}>
        <LinkButton
          to="#"
          onClick={() => {
            onCancelReview();
          }}>
          Cancel
        </LinkButton>
      </ReviewAspectsRowCol>
    </ReviewAspectsRowContainer>
  );
}
