import { gql } from '@apollo/client';

export const GET_CURRENT_ACCOUNT_ID = gql`
  query GetCurrentAccountId {
    currentAccountId @client
  }
`;

export const SET_CURRENT_ACCOUNT_ID = gql`
  mutation SetCurrentAccountId($currentAccountId: String!) {
    setCurrentAccountId(currentAccountId: $currentAccountId) @client
  }
`;
