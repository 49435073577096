import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal, Col, TextLink, Heading, Text, Button, CircleIcon } from 'rio-ui-components';
import { ResendEmailButton } from './ResendEmailButton';

const ColStyled = styled(Col)`
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.geometry.xl.spacing};
`;
const Icon = styled(CircleIcon)`
  border-width: 4px;
  margin-bottom: ${(props) => props.theme.geometry.xl.spacing};
`;
const HeadingStyled = styled(Heading)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;
const TextStyled = styled(Text)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;
const ButtonStyled = styled(Button)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;
const LinkStyled = styled(TextLink)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  text-align: center;
`;
export function VerifyEmailPrompt({ email, show, onDismiss, queryParams }) {
  return (
    <Modal size="md" onDismiss={onDismiss} show={show}>
      <ColStyled align="center" container>
        <Icon icon="envelope" size="md" />
        <HeadingStyled size="lg">Verify your email address</HeadingStyled>
        <TextStyled>In order to start using your Rio account, you need to confirm your email address.</TextStyled>
        <Link to={`/verify-email${queryParams}`}>
          <ButtonStyled>Verify Email Address</ButtonStyled>
        </Link>
        <ResendEmailButton email={email} />
        <LinkStyled component="routerLink" to="/register" onClick={onDismiss}>
          Change Email Address
        </LinkStyled>
      </ColStyled>
    </Modal>
  );
}
