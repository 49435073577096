import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, TextField, Checkbox } from '@rio/ui-components';
import { Form } from './Form';
import { AuthPage } from './AuthPage';
import { ButtonStyled } from './style';

export const ProfileForm = ({ form, errors, setErrors, handleChange, handleProfileChange, handleProfileSubmit }) => {
  const intl = useIntl();

  return (
    <AuthPage title="login.registration.detailsPage.title">
      <Form onSubmit={handleProfileSubmit} name="profile" form={form} setErrors={setErrors}>
        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <TextField
              type="text"
              value={form.firstName}
              error={!!errors.firstName}
              label={intl.formatMessage({ id: 'login.registration.detailsPage.firstName' })}
              helperText={errors.firstName && intl.formatMessage({ id: `login.errors.validation.${errors.firstName}` })}
              autoComplete="given-name"
              name="firstName"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              type="text"
              value={form.lastName}
              error={!!errors.lastName}
              label={intl.formatMessage({ id: 'login.registration.detailsPage.lastName' })}
              helperText={errors.lastName && intl.formatMessage({ id: `login.errors.validation.${errors.lastName}` })}
              autoComplete="family-name"
              name="lastName"
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12}>
            <TextField
              type="text"
              value={form.companyName}
              error={!!errors.companyName}
              label={intl.formatMessage({ id: 'login.registration.detailsPage.companyName' })}
              helperText={
                errors.companyName && intl.formatMessage({ id: `login.errors.validation.${errors.companyName}` })
              }
              autoComplete="organization"
              name="companyName"
              onChange={handleChange}
              required={!form.preferPersonalUse}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Checkbox
            name="preferPersonalUse"
            checked={form.preferPersonalUse}
            label={intl.formatMessage({ id: 'login.registration.detailsPage.personalUse' })}
            onChange={() => {
              handleProfileChange({
                ...form,
                preferPersonalUse: !form.preferPersonalUse,
              });
            }}
          />
        </Grid>
        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <Link to="/v2/register">
              <ButtonStyled variant="outlined" type="button" color="primary">
                <FormattedMessage id="login.registration.detailsPage.backButtonText" />
              </ButtonStyled>
            </Link>
          </Grid>
          <Grid xs={6}>
            <ButtonStyled variant="filled" type="submit" color="primary">
              <FormattedMessage id="login.registration.detailsPage.nextButtonText" />
            </ButtonStyled>
          </Grid>
        </Grid>
      </Form>
    </AuthPage>
  );
};
