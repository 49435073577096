import { Col } from 'rio-ui-components';
import { useCallback, useRef } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import ContainerHeader from '~/components/ContainerHeader';
import { AssetsGrid } from './Grid';
import { ConfigurationAssetsUploadButton } from './UploadButton';

export function ConfigurationAssetManagementContainer({ accountId }: { accountId: string }) {
  const gridApi = useRef<GridApi>();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
  }, []);

  const handleUploadCompleted = useCallback(() => {
    if (gridApi.current) {
      gridApi.current.refreshServerSide({
        purge: true,
      });
    }
  }, []);

  return (
    <Col container fullHeight>
      <ContainerHeader icon="briefcase" iconColor="primary" title="Asset management">
        <ConfigurationAssetsUploadButton onCompleted={handleUploadCompleted} />
      </ContainerHeader>
      <AssetsGrid accountId={accountId} onGridReady={handleGridReady} />
    </Col>
  );
}
