import { escapeRegExp } from 'lodash';
export { EcbDisclosure } from './EcbDisclosure';
export { EcbProgress } from './EcbProgress';

type Chapter = {
  name: string;
  chapters?: Chapter[];
};

const chapters: Chapter[] = [
  {
    name: 'Business Models and Strategy (Chapter 4)',
    chapters: [{ name: 'Business Environment (Expectation 4.1)' }, { name: 'Business Strategy (Expectation 4.2)' }]
  },
  {
    name: 'Governance and Risk Appetite (Chapter 5)',
    chapters: [
      { name: 'Management Body (Expectation 5.1)' },
      { name: 'Risk Appetite (Expectation 5.2)' },
      { name: 'Organisation Structure (Expectation 5.3)' },
      { name: 'Reporting (Expectation 5.4)' }
    ]
  },
  {
    name: 'Risk Management (Chapter 6)',
    chapters: [
      { name: 'Risk Management Framework (Expectation 6.1)' },
      { name: 'Credit Risk Management (Expectation 6.2)' },
      { name: 'Operational Risk Management (Expectation 6.3)' },
      { name: 'Market Risk Management (Expectation 6.4)' },
      { name: 'Scenario Analysis and Stress Testing (Expectation 6.5)' },
      { name: 'Liquidity Risk Management (Expectation 6.6)' }
    ]
  },
  {
    name: 'Disclosures (Chapter 7)',
    chapters: [
      { name: 'Disclosure Policies and Procedures (Expectation 7.1)' },
      { name: 'Content of Climate-Related and Environmental Risk Disclosures (Expectation 7.2)' }
    ]
  }
];

const buildChapterRegex = (chapters: Chapter[]): string => {
  const regexParts: Array<string> = [];

  chapters.forEach((chapter: Chapter) => {
    chapter.chapters?.forEach((subChapter) => {
      const suffix = '(\\..+)?';
      regexParts.push('(^' + escapeRegExp(`ECB.${chapter.name}.${subChapter.name}`) + suffix + '$)');
    });
  });

  return regexParts.join('|');
};

export const MATCH_DISCLOSURE = buildChapterRegex(chapters);
export const MATCH_PROGRESS = /^ECB\.Progress(.+$|$)/;
