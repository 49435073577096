import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getPathFromType } from '../../../../../utils/managementSystemUtils';

import ManagementSystemTile from '../ManagementSystemTile';

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const ManagementSystemGrid = ({ items }) => {
  return (
    <TileContainer>
      {items.map((item) => {
        return (
          <Link
            key={item.id}
            name={`ManagementSystemGrid__Link__${item.title}`}
            to={{ pathname: `${getPathFromType(item.type)}/${item.id}`, state: { title: item.title } }}
          >
            <ManagementSystemTile key={item.id} title={item.title} />
          </Link>
        );
      })}
    </TileContainer>
  );
};

export default ManagementSystemGrid;
