import { gql } from '@apollo/client';

export const GET_FUNDS_BY_ACCOUNT_ID = gql`
  query GetFundsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getFundsByAccountId(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
        externalId
        createdAt
        lastUpdatedAt
        deleted
        currency {
          id
          name
          code
        }
      }
    }
  }
`;

export const IMPORT_FUNDS = gql`
  mutation ImportFunds($funds: [FundInput!]!, $accountId: ID!) {
    importFunds(funds: $funds, accountId: $accountId) {
      deleted
      created
      updated
    }
  }
`;

export const GET_FUNDS_FILTER = gql`
  query GetFundsFilter($field: String!, $accountId: ID!) {
    getFundsFilter(field: $field, accountId: $accountId) {
      label
      value
    }
  }
`;
