import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { GridOptions } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { ActionCell, ActionCellProps, ActionFunction } from './GridCell/ActionCell';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { mapFilterModel, mapSortModel } from '../../../utils';
import { Filters } from '@rio/rio-types';

const RowStyled = styled(Row)`
  flex: 1;
  display: flex;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

interface GridComponentProps {
  setGridApi: Function;
  fetchRows: Function;
  showModal: ActionFunction;
}

function GridComponent({ fetchRows, setGridApi, showModal }: GridComponentProps) {
  const agGrid = useAgGrid({ autoFit: true });
  const components = {
    action: (props: ActionCellProps) => <ActionCell {...props} onEdit={showModal} />,
  };

  const gridOptions: GridOptions = {
    rowHeight: 50,
    pagination: true,
    paginationPageSize: 25,
    rowModelType: 'serverSide',
    defaultColDef: {
      sortable: true,
      resizable: false,
      filter: true,
      cellStyle: {
        padding: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
    },
    columnDefs: [
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        minWidth: 300,
      },
      {
        headerName: 'Aliased Name',
        field: 'parentItem.name',
        filter: 'agTextColumnFilter',
        minWidth: 300,
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
        minWidth: 80,
      },
    ],

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <RowStyled>
      <AgGrid
        {...gridOptions}
        cacheBlockSize={gridOptions.paginationPageSize}
        onFirstDataRendered={agGrid.onFirstDataRendered}
        onFilterChanged={agGrid.onFilterChanged}
        onSortChanged={agGrid.onSortChanged}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        components={components}
        onGridReady={(params) => {
          agGrid.onGridReady(params);
          setGridApi({ api: params.api, columnApi: params.columnApi });
          params.api.setServerSideDatasource({
            async getRows({ request, fail, success }) {
              try {
                const { startRow, sortModel, filterModel } = request;
                const filters: Filters = mapFilterModel(filterModel);

                const { rows, totalRows } = await fetchRows({
                  offset: startRow || 0,
                  limit: gridOptions.paginationPageSize,
                  sort: mapSortModel(sortModel),
                  filters,
                });
                success({
                  rowData: rows,
                  rowCount: totalRows,
                });

                return { rows, totalRows };
              } catch (err) {
                fail();
                throw err;
              }
            },
          });
        }}
      />
    </RowStyled>
  );
}

export const AliasesContentGrid = GridComponent;
