import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import DromoUploader, { IResultMetadata } from 'dromo-uploader-react';
import { Modal, LoadingIndicator } from 'rio-ui-components';
import { UploadButtonStyled, CenterContainer } from './styles';
import { TransactionType } from '@rio/rio-types';

import { useCurrentUser, useCurrentAccount, useNotification } from '~/hooks';
import IsDirectActivityModal, { isDirectActivityQuestionnaire } from '~/components/UploadProcess/IsDirectActivityModal';
import { GET_DROMO_SCHEMA, INITIATE_DROMO_UPLOAD } from './initiateDromoUploadMutation';
import { parseData } from './parse-data';
import { useGetDromoConfiguration } from '../../DromoConfiguration';
import { Dictionary } from '~/types';
import { getEnvVar } from '../../../../env';

interface DromoUploaderButtonProps {
  transactionType: TransactionType;
  isDirectActivity: boolean;
  showDromoButton: boolean;
  children?: ReactNode;
}

export const DromoUploaderButton = ({ transactionType, showDromoButton }: DromoUploaderButtonProps) => {
  const { showNotification } = useNotification();
  const currentAccount = useCurrentAccount();
  const currentUser = useCurrentUser();
  const [initiateDromoUpload] = useMutation(INITIATE_DROMO_UPLOAD);
  const uploaderRef = useRef<DromoUploader | null>(null);

  const [showDirectActivityModal, setShowDirectActivityModal] = useState(false);
  const [isDirectActivity, setIsDirectActivity] = useState(false);
  const [isDirectActivityResponded, setIsDirectActivityResponded] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const isResponseRequired = useMemo(
    () => isDirectActivityQuestionnaire[transactionType] && !isDirectActivityResponded,
    [isDirectActivityResponded, transactionType]
  );

  const { data, loading, error, refetch } = useQuery(GET_DROMO_SCHEMA, {
    variables: { transactionType, accountId: currentAccount.id },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      showNotification(`Could not retrieve Dromo Schema for ${transactionType}`, 'danger');
    },
  });

  const { fields, data: schemaData } = parseData(data?.getDromoSchema);
  const dromoConfiguration = useGetDromoConfiguration(transactionType, schemaData);

  const onResults = useCallback(
    (_data: Dictionary<unknown>, metadata: IResultMetadata) => {
      const defaultFileName = `${transactionType}_${new Date().toISOString()}`;

      initiateDromoUpload({
        variables: {
          transactionType,
          accountId: currentAccount.id,
          fileName: metadata.filename || defaultFileName,
          dromoId: metadata.id,
          isDirectActivity,
        },
      });

      setIsDirectActivityResponded(false);
    },
    [currentAccount.id, initiateDromoUpload, isDirectActivity, transactionType]
  );

  useEffect(() => {
    if (!isResponseRequired && isButtonClicked) {
      uploaderRef.current?.open();
    }
  }, [isButtonClicked, isDirectActivityResponded, isResponseRequired, uploaderRef, data]);

  const licenseKey = getEnvVar('REACT_APP_DROMO_LICENSE_KEY');

  if (!licenseKey) {
    showNotification('Dromo license key not found', 'danger');
    return null;
  }

  if (!dromoConfiguration) {
    return null;
  }

  dromoConfiguration.fields = fields;

  // See docs: https://developer.dromo.io/users/
  const user = {
    id: currentUser?.id || '',
    name: `${currentUser?.first_name} ${currentUser?.last_name}`,
    email: currentUser?.email,
    companyId: currentAccount.id,
    companyName: currentAccount.name,
  };

  const UploadButton = () => (
    <UploadButtonStyled
      inline
      name="DromoUploaderButton__uploadDataButton"
      component="button"
      onClick={() => {
        if (error) {
          refetch();
        } else if (!loading) {
          setShowDirectActivityModal(true);
          setIsButtonClicked(true);
        }
      }}
    >
      Upload Data
    </UploadButtonStyled>
  );

  if (isResponseRequired || loading) {
    return (
      <>
        <UploadButton />
        {isResponseRequired && (
          <Modal
            show={showDirectActivityModal}
            size="lg"
            height="fit-content"
            onDismiss={() => setShowDirectActivityModal(false)}
          >
            <IsDirectActivityModal
              onConfirm={(value) => {
                setIsDirectActivity(value);
                setShowDirectActivityModal(false);
                setIsDirectActivityResponded(true);
              }}
              type={transactionType}
            />
          </Modal>
        )}
        {loading && (
          <CenterContainer>
            <LoadingIndicator />
          </CenterContainer>
        )}
      </>
    );
  }

  return (
    <DromoUploader
      licenseKey={licenseKey}
      settings={dromoConfiguration.settings}
      fields={dromoConfiguration.fields}
      user={user}
      style={showDromoButton ? dromoConfiguration.style : { display: 'none' }}
      onResults={onResults}
      onCancel={() => setIsDirectActivityResponded(false)}
      ref={uploaderRef}
      bulkRowHooks={dromoConfiguration.bulkRowHooks}
    >
      Upload Data
    </DromoUploader>
  );
};
