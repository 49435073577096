"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_material_1 = require("@mui/icons-material");
const styles_1 = require("@mui/material-next/styles");
const IconButton_1 = require("../IconButton");
const Image = (0, styles_1.styled)('img') `
  width: 36px;
  border-radius: 50%;
`;
function ProfileButton({ profilePicture, ...rest }) {
    const Content = (0, react_1.useMemo)(() => {
        if (profilePicture) {
            return (0, jsx_runtime_1.jsx)(Image, { src: profilePicture });
        }
        return (0, jsx_runtime_1.jsx)(icons_material_1.PermIdentityOutlined, {});
    }, [profilePicture]);
    return (0, jsx_runtime_1.jsx)(IconButton_1.IconButton, { ...rest, children: Content });
}
exports.ProfileButton = ProfileButton;
