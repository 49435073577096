import styled from 'styled-components';
import { Row, Col, CircleIcon, Button, TextArea, Label, Select, Icon, TextInput, DatePicker } from 'rio-ui-components';

export const DocumentAndChildContainer = styled(Row)`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.basic.white};
  padding: ${(props) => props.theme.geometry.sm.spacing};
  width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

export const PaddedCol = styled(Col)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

export const TitleContainerStyled = styled(Row)`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

export const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

export const PaddedContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

export const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.basic.white};
`;

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.basic.white};
  padding: 20px 30px;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 15px;
  margin-top: 20px;
  font-weight: bolder;
  font-size: ${(props) => props.theme.fonts.sm.size};
  color: ${(props) => props.theme.colors.text.light.text};
`;

export const StyledNameInput = styled(TextInput)`
  & > div {
    border-color: ${(props) => props.theme.colors.text.normal.background};
  }
`;

export const StyledRow = styled(Row)`
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  & > div {
    border-radius: 5px;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
`;

export const StyledTextInput = styled(TextInput)`
  & > div {
    height: 63px;
    border-radius: 5px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  & > div > div {
    height: 65px;
    border-radius: 5px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

export const MultiSelectContainer = styled.div`
  & > div > div {
    border-radius: 5px;
  }
`;
export const StyledCurrencySelect = styled.div`
  & > div > div {
    border-radius: 5px;
  }
`;

export const StyledButton = styled(Button)`
  width: 150px;
  border-radius: 10px;
  align-self: center;
`;

export const SaveButton = styled(StyledButton)`
  margin-right: 20px;
  border-radius: ${(props) => props.theme.geometry.md.radius};
`;

export const CancelButton = styled(StyledButton)`
  margin-right: 20px;
  background-color: #cccccc;
  border-color: #cccccc;
  border-radius: ${(props) => props.theme.geometry.md.radius};
  &:hover,
  &:focus {
    background-color: #c1bebe;
    border-color: #cccccc;
  }
`;

export const DeleteButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.danger.normal.background};
  border-color: ${(props) => props.theme.colors.danger.normal.background};
  border-radius: ${(props) => props.theme.geometry.md.radius};
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.danger.dark.background};
    border-color: ${(props) => props.theme.colors.danger.dark.background};
  }
`;

export const ControlButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
`;

export const StyledLabelWithRequiredBlock = styled(StyledLabel)`
  & {
    position: relative;
    font-weight: bolder;
    font-size: ${(props) => props.theme.fonts.sm.size};
  }
  &:after {
    content: '*';
    position: absolute;
    top: 0;
    color: red;
  }
`;

export const StyledIcon = styled(Icon)`
  color: black;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const InfoLabel = styled.div`
  > svg {
    cursor: pointer;
    margin: 5px 0 0 10px;
  }
  display: flex;
  align-items: center;
`;

export const Tooltip = styled.div`
  background-color: #ffe5b4;
  border-radius: 6px;
  color: #83838e;
  cursor: default;
  font-size: 14px;
  height: 65px;
  margin-left: 10px;
  text-align: left;
  padding: 5px 10px;
  width: 51%;
  word-break: break-word;
`;

export const SectionWithTooltip = styled.div`
  display: flex;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
`;

export const PageHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.basic.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

export const StyledSelectWrapper = styled.div`
  & > div > div {
    border-radius: 5px;
  }
`;

export const StyledStatusContainer = styled.div`
  margin-right: 10px;
`;
