import { Text } from '@rio/ui-components';
import capitalize from 'lodash/capitalize';

export const CategoryCell = (props) => {
  const { data: task } = props;

  return (
    <Text typescale="body" size="medium">
      {capitalize(task.category)}
    </Text>
  );
};
