import { useEffect, useState } from 'react';
import { TransactionType } from '@rio/rio-types';
import { useForm, Controller } from 'react-hook-form';
import { Modal, styled, Text } from '@rio/ui-components';
import { WasteDataFormV2 as WasteDataForm } from '../../WasteDataForm';
import { WaterDataForm } from '../../WaterDataForm/v2';
import { EnergyDataFormV2 as EnergyDataForm } from '../../EnergyDataForm';
import { TextFieldStyled } from '../../WasteDataForm/style';
import { DataFormPreview } from '../../DataFormPreview/v2';
import { TransportDataForm } from '../../TransportDataForm/v2';
import { HotelStaysDataFormV2 as HotelStaysDataForm } from '../../HotelStaysDataForm';
import { DataFormXlsUploader } from '../../DataFormXlsUploader/v2';

const UploadReferenceContainer = styled('div')`
  width: 100%;
`;

const getFormBySection = (section) => {
  switch (section) {
    case TransactionType.Waste:
      return WasteDataForm;
    case TransactionType.Electricity:
    case TransactionType.Gas:
    case TransactionType.Heatsteam:
      return EnergyDataForm;
    case TransactionType.Water:
      return WaterDataForm;
    case TransactionType.Transport:
      return TransportDataForm;
    case TransactionType.Hotelstays:
      return HotelStaysDataForm;
    default:
      throw new Error(`Undefined section: ${section}`);
  }
};

export const DataFormModal = (props) => {
  const { onDismiss = () => {}, onComplete = () => {}, onError = () => {}, dataSection } = props;

  const [formData, setFormData] = useState();
  const [isFormVisible, setFormVisible] = useState(true);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isUploaderVisible, setUploaderVisible] = useState(false);

  // TODO: need to move into forData, for now it need for a reset
  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',

    defaultValues: {
      uploadReference: '',
    },
  });

  const { reset, control } = form;

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  const showForm = () => {
    setFormVisible(true);
    setPreviewVisible(false);
    setUploaderVisible(false);
  };

  const closeForm = () => onDismiss();

  const showPreview = () => {
    setFormVisible(false);
    setPreviewVisible(true);
    setUploaderVisible(false);
  };

  const showUploader = () => {
    setFormVisible(false);
    setPreviewVisible(false);
    setUploaderVisible(true);
  };

  const submitForm = (data) => {
    setFormData(data);
    showPreview();
  };

  const completeUpload = () => {
    setFormVisible(false);
    setPreviewVisible(false);
    setUploaderVisible(false);
    onComplete();
    onDismiss();
  };

  const handleOnError = (e) => {
    showForm();
    onError(e);
  };

  const DataForm = getFormBySection(dataSection);

  return (
    <>
      <Modal
        title="Direct Data Upload"
        open={isFormVisible}
        viewportHeight={90}
        grid={{ xs: 8, md: 6 }}
        onClose={closeForm}
      >
        <DataForm form={form as any} onSubmit={submitForm} dataSection={dataSection}>
          <UploadReferenceContainer>
            <Text typescale="title" size="medium">
              Upload reference
            </Text>
            <Controller
              name="uploadReference"
              render={({ field, fieldState }) => (
                <TextFieldStyled
                  {...field}
                  error={!!fieldState.error}
                  helperText={!!fieldState.error && fieldState.error?.message}
                  label="Enter a brief description for this upload, such as your organization name, relevant tag, department, region, or location."
                />
              )}
              defaultValue=""
              control={control}
              rules={{ required: 'Upload reference is required', maxLength: 80 }}
            />
          </UploadReferenceContainer>
        </DataForm>
      </Modal>

      <Modal open={isPreviewVisible} onClose={showForm} grid={{ xs: 8, md: 6 }} title="Data preview">
        <DataFormPreview formData={formData} onBack={showForm} onConfirm={showUploader} dataSection={dataSection} />
      </Modal>

      <Modal open={isUploaderVisible} onClose={closeForm} grid={{ xs: 8, md: 6 }} title="Data uploading">
        <DataFormXlsUploader
          formData={formData}
          onUploadCompleted={completeUpload}
          onError={handleOnError}
          dataSection={dataSection}
        />
      </Modal>
    </>
  );
};
