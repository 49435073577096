import { redirect } from 'react-router-dom';
import apolloClient from '../../../apollo-client';
import { GET_ACCOUNT_INFO } from '../../../queries/accounts';
import { GET_CURRENT_ACCOUNT_ID } from '../../../queries/currentAccount';

// checks if the user is logged in and redirects to v2 if necessary
export const loader = async ({ request }) => {
  try {
    const {
      data: { currentAccountId },
    } = await apolloClient.query({ query: GET_CURRENT_ACCOUNT_ID });

    if (!currentAccountId) {
      return {};
    }

    const { data } = await apolloClient.query({
      query: GET_ACCOUNT_INFO,
      variables: { id: currentAccountId },
    });

    const req = new URL(request.url);

    if (data.getAccountInfo.accessControls.v2Design) {
      return redirect(req.pathname.replace('/', '/v2/'));
    }

    return {};
  } catch (error) {
    return {};
  }
};
