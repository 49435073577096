import { useMemo } from 'react';
import { Text } from '@rio/ui-components';
import { useQuery } from '@apollo/client';
import GET_ALL_CURRENCY_TYPES from '../../../../graphql/queries/GetAllCurrencyTypes.graphql';

const CurrencySymbols: Record<string, string> = {
  BGN: 'лв',
  CZK: 'Kč',
  DKK: 'kr',
  EUR: '€',
  GBP: '£',
  HRK: 'kn',
  GEL: '₾',
  HUF: 'ft',
  NOK: 'kr',
  PLN: 'zł',
  RUB: '₽',
  RON: 'lei',
  SEK: 'kr',
  TRY: '₺',
  UAH: '₴',
  BRL: 'R$',
  CAD: '$',
  CLP: '$',
  COP: '$',
  MXN: '$',
  PEN: 'S/.',
  USD: '$',
  AUD: '$',
  BDT: '৳',
  CNY: '¥',
  HKD: 'HK$',
  IDR: 'Rp',
  INR: '₹',
  JPY: '¥',
  MYR: 'RM',
  NZD: '$',
  PHP: '₱',
  PKR: 'Rs',
  SGD: '$',
  KRW: '₩',
  LKR: 'Rs',
  THB: '฿',
  VND: '₫',
};

type CostCellProps = {
  data: {
    cost: number;
    currencyId: string;
  };
};

export const CostCell = (props: CostCellProps) => {
  const {
    data: { cost, currencyId },
  } = props;

  const { data } = useQuery(GET_ALL_CURRENCY_TYPES);
  const allCurrencies = data?.getAllCurrencyTypes || [];

  const currencySymbol = useMemo(() => {
    const currency = allCurrencies.find((el) => el.id === currencyId);
    return CurrencySymbols[currency?.name || ''] ?? currency?.name;
  }, [allCurrencies, currencyId]);

  return (
    <Text typescale="body" size="medium">
      {cost} {currencySymbol}
    </Text>
  );
};
