import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/engage/GetUserCourse.query.graphql';

export function useGetUserCourseQuery(variables) {
  const { data: { getUserCourse } = {}, ...others } = useQuery(QUERY, {
    variables: { ...variables }
  });

  return {
    data: getUserCourse,
    ...others
  };
}
