import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RioModule } from '@rio/rio-types';

import { Row, Col, Text, Icon } from 'rio-ui-components';

import { useGetReceivedSurveysPage } from '~/containers/SurveysContainer/ReceivedSurveysContainer/queries';
import GET_TASKS_BY_USER from '../../graphql/queries/tasks/GetTasksByUserId.query.graphql';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { useUserToken } from '../../hooks/useUserToken';
import { usePermissions } from '../../hooks/usePermissions';
import { useAccessControls } from '../../hooks/useAccessControls';
import { accountsWithHiddenContentParts } from '../../constants/accounts';
import { AccountName } from './AccountName';
import RioLogoRings from './rio-logo-rings.svg';
import RioLogo from './RIO_Logo_White.png';
import { SideBarItem } from './SideBarItems';
import { countIncompleteSurveys } from './countIncompleteSurveys';

const ContainerStyled = styled(Col)`
  position: relative;
  background-color: ${(props) => props.theme.colors.sidebar.normal.background};
  width: ${({ collapsed }) => (collapsed ? '70px' : '260px')};
  flex: 0 0 auto;
`;

const ToggleStyled = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  right: 0;
  top: 78px; // height of header component + xs spacing
  bottom: ${(props) => props.theme.geometry.xs.spacing};
  width: ${(props) => props.theme.geometry.sm.spacing};
  border-right: 2px solid ${(props) => props.theme.colors.sidebar.normal.background};
  color: ${(props) => props.theme.colors.sidebar.normal.background};
  margin-right: -${(props) => props.theme.geometry.sm.spacing};
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const AccountSection = styled(Row)`
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  border-right: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const RingRioLogo = styled.div`
  flex: 0 0 auto;
  width: 70px;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: url(${RioLogoRings}) center center no-repeat;
  background-size: 35px 35px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.overlay.light.background};
  }
`;

const LinkSection = styled(Col)`
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const CopyrightSection = styled(Col)`
  flex: 0 0 auto;
  opacity: 0.3;
  padding: ${(props) => props.theme.geometry.sm.spacing};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
`;

const CompanyLogo = styled.div`
  flex: 0 0 auto;
  width: 92px;
  height: 44px;
  background: url(${RioLogo}) center center no-repeat;
  background-size: 92px 44px;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const CopyrightText = styled(Text)`
  flex: 0 0 auto;
`;

const Divider = styled.div`
  height: 1px;
  opacity: 0.1;
  margin: ${(props) => props.theme.geometry.xs.spacing} 0;
  background-color: #fff;
`;

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('SIDEBAR_COLLAPSED') === 'collapsed');
  const accountId = useCurrentAccountId();
  const { data: accessControls } = useAccessControls();
  const { token: idToken } = useUserToken();
  const permissions = usePermissions();

  const hasFormManagerAccess = permissions.survey.some((action) => action.startsWith('getSurveys'));
  const variables = {
    accountId,
    userId: idToken.sub,
  };
  const { data: notificationData } = useQuery(GET_TASKS_BY_USER, {
    variables,
    skip: !idToken.sub || !accountId,
  });
  const { data: notificationSurveysData } = useGetReceivedSurveysPage(variables);

  const incompleteNotification = notificationData?.getTasksByUserId?.filter(
    (item) => !item.isCompleted && !!item.owners.filter(({ id }) => id.toLowerCase() === idToken.sub)
  );

  const incompleteSurveysCount = countIncompleteSurveys(notificationSurveysData?.getReceivedSurveysPage?.rows);

  const toggleCollapse = () => {
    localStorage.setItem('SIDEBAR_COLLAPSED', !collapsed === true ? 'collapsed' : 'open');
    setCollapsed(!collapsed);
  };

  const showMenuItems = accountsWithHiddenContentParts.includes(accountId);

  return (
    <ContainerStyled container name="Sidebar" vdistribution="between" collapsed={collapsed}>
      <ToggleStyled id="Sidebar__toggle-collapse" onClick={toggleCollapse}>
        <Icon icon={collapsed ? 'angle-right' : 'angle-left'} />
      </ToggleStyled>
      <AccountSection container>
        <Link to="">
          <RingRioLogo />
        </Link>
        <AccountName />
      </AccountSection>

      <LinkSection item>
        <SideBarItem
          id="Sidebar__NavLink--Homepage"
          exact
          route=""
          messageId="sidebar.homepage"
          icon="home"
          circleColor="white"
          name="Sidebar__NavLink--Homepage"
          collapsed={collapsed}
        />
        {permissions.allowedRioModules.includes(RioModule.Engage) && (
          <SideBarItem
            id="Sidebar__NavLink--Learn"
            route="engage"
            messageId="sidebar.learn"
            icon="graduation-cap"
            circleColor="secondary"
            name="Sidebar__NavLink--Learn"
            collapsed={collapsed}
          />
        )}
        {permissions.allowedRioModules.includes(RioModule.Governance) && !showMenuItems && (
          <SideBarItem
            id="Sidebar__NavLink--Comply"
            route="governance"
            messageId="sidebar.governance"
            icon="pencil-alt"
            circleColor="tertiary"
            name="Sidebar__NavLink--Comply"
            collapsed={collapsed}
          />
        )}
        {accessControls.surveys && !showMenuItems && (
          <SideBarItem
            route={hasFormManagerAccess ? 'surveys/sent/overview' : 'surveys/received'}
            messageId="sidebar.surveys"
            icon={['rio', 'clipboard-check-custom']}
            notificationCount={incompleteSurveysCount}
            circleColor="quaternary"
            name="Sidebar__NavLink--Surveys"
            id="Sidebar__NavLink--Surveys"
            collapsed={collapsed}
          />
        )}
        {permissions.allowedRioModules.includes(RioModule.Data) && !showMenuItems && (
          <SideBarItem
            id="Sidebar__NavLink--Data"
            route="data"
            messageId="sidebar.data"
            icon="chart-line"
            circleColor="quaternary"
            name="Sidebar__NavLink--Data"
            collapsed={collapsed}
          />
        )}
        {permissions.allowedRioModules.includes(RioModule.Reporting) && !showMenuItems && (
          <SideBarItem
            id="Sidebar__NavLink--Reporting"
            route="reporting"
            messageId="sidebar.reporting"
            icon="chart-area"
            circleColor="quaternary"
            name="Sidebar__NavLink--Reporting"
            collapsed={collapsed}
          />
        )}
        {!!accessControls.intelligence &&
          permissions.allowedRioModules.includes(RioModule.Intelligence) &&
          !showMenuItems && (
            <SideBarItem
              id="Sidebar__NavLink--Intelligence"
              route="intelligence"
              messageId="sidebar.intelligence"
              icon="brain"
              circleColor="primary"
              name="Sidebar__NavLink--Intelligence"
              collapsed={collapsed}
            />
          )}
        {accessControls.flows && !showMenuItems && (
          <SideBarItem
            route="flows"
            messageId="sidebar.flows"
            icon={['rio', 'flows']}
            circleColor="quaternary"
            name="Sidebar__NavLink--Flows"
            id="Sidebar__NavLink--Flows"
            collapsed={collapsed}
          />
        )}
        <Divider />
        {permissions.allowedRioModules.includes(RioModule.Configuration) && (
          <SideBarItem
            id="Sidebar__NavLink--Configuration"
            route={`configuration/${permissions.configurationRoute}`}
            messageId="sidebar.configuration"
            icon="cog"
            circleColor="white"
            name="Sidebar__NavLink--Configuration"
            collapsed={collapsed}
          />
        )}
        {permissions.allowedRioModules.includes(RioModule.Documents) && !showMenuItems && (
          <SideBarItem
            id="Sidebar__NavLink--Documents"
            route="documents"
            messageId="sidebar.documents"
            icon="file-alt"
            circleColor="quaternary"
            name="Sidebar__NavLink--Documents"
            collapsed={collapsed}
          />
        )}
        {permissions.allowedRioModules.includes(RioModule.Tasks) && !showMenuItems && (
          <SideBarItem
            id="Sidebar__NavLink--Tasks"
            route="tasks"
            notificationCount={incompleteNotification?.length}
            messageId="sidebar.tasks"
            icon="tasks"
            circleColor="tertiary"
            name="Sidebar__NavLink--Tasks"
            collapsed={collapsed}
          />
        )}
        <Divider />
        {permissions.allowedRioModules.includes(RioModule.Support) && (
          <SideBarItem
            id="Sidebar__NavLink--Support"
            externalLink
            route="https://support.rio.ai/knowledge"
            messageId="sidebar.support"
            icon="question"
            circleColor="info"
            name="Sidebar__NavLink--Support"
            data-cy="Sidebar__NavLink--Support"
            collapsed={collapsed}
          />
        )}
      </LinkSection>

      <CopyrightSection container itemAlign="center" collapsed={collapsed}>
        <CompanyLogo />
        <CopyrightText size="xs" color="white" align="center">
          © {new Date().getFullYear()} Rio ESG LTD
        </CopyrightText>
      </CopyrightSection>
    </ContainerStyled>
  );
};

export default SideBar;
