import { useCallback } from 'react';
import { GridOptions } from 'ag-grid-community';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useMaterialsGridConfig } from '../MaterialsContainer/TransactionsContainer/useMaterialsGridConfig';
import { GET_MATERIALS_TRANSACTIONS_PAGE } from '../MaterialsContainer/TransactionsContainer/index.queries';
import { DocumentReferenceV2 as DocumentReference, UploadReferenceCell } from '../CellRenderers';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Materials' }];

export const MaterialsTransactionsGrid = () => {
  const config = useMaterialsGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_MATERIALS_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getMaterialsTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <>
      <TransactionGrid
        title="Your materials data"
        defaultExportFileName="materials-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../materials/uploads"
        config={
          {
            ...config,
            components: {
              documentReference: DocumentReference,
              uploadReference: UploadReferenceCell,
            },
          } as GridOptions
        }
        fetchRows={fetchRows}
      />
    </>
  );
};
