import { useMemo } from 'react';
import { LinearProgress, styled, AgGrid } from '@rio/ui-components';

import { useCurrentAccountId } from '~/hooks';
import { alphabetiseByField } from '~/utils';

import { useGetRecipients } from './hooks/useGetRecipients';
import { RecipientSurveyType, useSurveyForm } from './hooks/useSurveyForm';

export const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: 400px;

  .ag-header {
    display: none;
  }

  .ag-sticky-top {
    top: 0 !important;
  }
`;

enum RowSection {
  single = 'single',
  multiple = 'multiple',
}

export type RecipientsGridProps = {
  surveyType: RecipientSurveyType;
};

const columnDefs = [];

export function RecipientsGrid() {
  const accountId = useCurrentAccountId();
  const { rows, loading } = useGetRecipients();
  const { surveyType, selectContributors, selectIndividualContributors, selectedIds } = useSurveyForm();

  const isPerUser = surveyType === RecipientSurveyType.user;

  const sortedRows = useMemo(
    () => rows.slice().sort(alphabetiseByField(isPerUser ? 'firstName' : 'name')),
    [isPerUser, rows]
  );

  const gridOptions = useMemo(
    () => ({
      key: surveyType,
      onGridReady: (e) => {
        e.api.forEachNode((node) => {
          if (node.id && selectedIds.has(node.id)) {
            node.setSelected(true);
          }
        });
      },
      onSelectionChanged: (e) => {
        const selectedRows = e.api.getSelectedRows();
        if (surveyType === RecipientSurveyType.contributor) {
          const contributorIds = selectedRows
            .filter((row) => row.__typename === 'Account' && row.id !== accountId)
            .map((row) => row.id);
          return selectContributors(contributorIds);
        }
        const individualContributorIds = selectedRows
          .filter((row) => row.__typename === 'MysqlUser')
          .map((row) => row.id);
        return selectIndividualContributors(individualContributorIds);
      },
      treeData: true,
      rowSelection: RowSection.multiple as RowSection,
      columnDefs: columnDefs,
      rowData: sortedRows,
      getDataPath: (data) => {
        return data.orgHierarchy;
      },
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      groupDefaultExpanded: 1,
      loadingOverlayComponent: LinearProgress,
      getRowId: (params) => params.data.id,
      autoGroupColumnDef: {
        headerName: 'Name',
        flex: 1,
        valueGetter: (params) => {
          if (!params.data) {
            return undefined;
          }
          switch (params.data.__typename) {
            case 'Account':
              return params.data.name;
            case 'MysqlUser':
              return `${params.data.firstName} ${params.data.lastName} <${params.data.email}>`;
          }
        },
        cellRendererParams: {
          checkbox: true,
          suppressCount: true,
        },
      },
    }),
    [accountId, sortedRows, selectContributors, selectIndividualContributors, selectedIds, surveyType]
  );

  if (loading) {
    return <LinearProgress />;
  }

  return <AgGridStyled gridKey="recipientsGrid" gridOptions={gridOptions} />;
}
