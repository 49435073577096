import { useState } from 'react';
import { LIST_OR_GRID, LIST, GRID } from '../constants/preferences';

export function useViewPreference() {
  const [view, setView] = useState(localStorage.getItem(LIST_OR_GRID) || GRID);
  const storeAndSet = (view) => {
    localStorage.setItem(LIST_OR_GRID, view);
    setView(view);
  };
  return {
    view,
    showGrid: () => storeAndSet(GRID),
    showList: () => storeAndSet(LIST),
    isGrid: view === GRID,
    isList: view === LIST
  };
}
