import { DataType, AspectInput, Filters, FilterStrategy } from '@rio/rio-types';
import { AspectFormState } from './types';
import { prepareAspectInput } from './utils';
import { useGetAspectsByAccountId } from './useGetAspectsByAccountId';

function getQueryFilters(aspectInput: AspectInput): Filters {
  const filters: Filters = {
    text: [
      {
        field: 'scope',
        filterType: DataType.Text,
        type: FilterStrategy.Equals,
        filter: aspectInput.scope,
      },
      {
        field: 'activity.id',
        filterType: DataType.Text,
        type: FilterStrategy.Equals,
        filter: aspectInput.activity,
      },
    ],
    set: [],
  };
  if (aspectInput.locationIds?.length) {
    filters?.set?.push({
      field: 'locations.id',
      filterType: DataType.Set,
      values: aspectInput.locationIds,
    });
  }
  if (aspectInput.tagIds?.length) {
    filters?.set?.push({
      field: 'tags.id',
      filterType: DataType.Set,
      values: aspectInput.tagIds,
    });
  }
  if (aspectInput.departments?.length) {
    filters?.set?.push({
      field: 'departments.id',
      filterType: DataType.Set,
      values: aspectInput.departments,
    });
  }
  if (aspectInput.aspects?.length) {
    filters?.set?.push({
      field: 'aspects.id',
      filterType: DataType.Set,
      values: aspectInput.aspects,
    });
  }
  if (aspectInput.impacts?.length) {
    filters?.set?.push({
      field: 'impacts.id',
      filterType: DataType.Set,
      values: aspectInput.impacts,
    });
  }
  if (aspectInput.legislationIds?.length) {
    filters?.set?.push({
      field: 'legislations.id',
      filterType: DataType.Set,
      values: aspectInput.legislationIds,
    });
  }
  return filters;
}
export function useIsDuplicate() {
  const getAspectsByAccountId = useGetAspectsByAccountId();
  return async (aspectFormState: AspectFormState) => {
    const aspectInput = prepareAspectInput(aspectFormState, [], false, false, null);
    const { totalRows } = await getAspectsByAccountId({
      accountId: aspectInput.accountId,
      filters: getQueryFilters(aspectInput),
    });
    if (totalRows >= 1) {
      return true;
    }
    return false;
  };
}
