import { TextField, Select, OptionsProps } from '@rio/ui-components';
import { GridWrapper } from './styles';
import { AddressPropertySheetProps } from './type';

export const AddressPropertySheet = (props: AddressPropertySheetProps) => {
  const {
    id,
    address1,
    address2,
    address3,
    city,
    postcode,
    phone,
    disabled,
    handleChange,
    countryList,
    selectCountry,
    handleSelectChange,
  } = props;

  return (
    <GridWrapper container>
      <TextField
        id={`${id}-address1`}
        disabled={disabled}
        name="address1"
        onChange={handleChange}
        label="Address 1"
        value={address1.text}
        error={!!address1.error}
        helperText={address1.error}
      />
      <TextField
        id={`${id}-address2`}
        disabled={disabled}
        name="address2"
        onChange={handleChange}
        label=" Address 2 (Optional)"
        value={address2.text}
        error={!!address2.error}
        helperText={address2.error}
      />
      <TextField
        id={`${id}-address3`}
        disabled={disabled}
        name="address3"
        onChange={handleChange}
        label="Address 3 (Optional)"
        value={address3.text}
        error={!!address3.error}
        helperText={address3.error}
      />
      <TextField
        id={`${id}-city`}
        disabled={disabled}
        name="city"
        onChange={handleChange}
        label="City"
        value={city.text}
        error={!!city.error}
        helperText={city.error}
      />
      <TextField
        id={`${id}-postcode`}
        disabled={disabled}
        name="postcode"
        onChange={handleChange}
        label="Postcode"
        value={postcode.text}
        error={!!postcode.error}
        helperText={postcode.error}
      />
      <Select
        id={`${id}-country`}
        disabled={disabled}
        name="selectCountry"
        onChange={(e: OptionsProps) => handleSelectChange(e)}
        value={selectCountry?.value || ''}
        options={countryList}
        label="Country"
      />
      <TextField
        id={`${id}-phone`}
        disabled={disabled}
        name="phone"
        onChange={handleChange}
        label="Phone (Optional)"
        value={phone.text}
        error={!!phone.error}
        helperText={phone.error}
      />
    </GridWrapper>
  );
};
