/**
 * Converts a base64 file string to a File object
 * @param fileString
 * @param fileName
 * @param mimeType
 * @returns
 */
export const convertBase64ToFile = (fileString: string, fileName: string, mimeType: string): Promise<File> => {
  return fetch(fileString)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], fileName, { type: mimeType }));
};

/**
 * Converts a file object to a base64 string
 * @param file
 * @returns
 */
export const convertFileToBase64String = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result!.toString());
    reader.onerror = (error) => reject(error);
  });

/**
 * Downloads remote file and returns File object
 * @param url
 * @param name
 * @returns
 */
export const downloadFile = async (url: string, name: string): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type,
  });
};
