import { RefrigerantConversionFactor } from '@rio/rio-types';
import { useCallback, useContext, useMemo } from 'react';
import { GridReadyEvent, ColDef } from 'ag-grid-community';
import { formatDate, getGridDateComparator, gridNumberFormatter } from '../../../utils';
import { FactorsGrid } from './FactorsGrid';
import { RefrigerantFactorsDescription } from './FactorsDescriptions';
import { TabContentContext } from './LocationBasedFactors';
import { TabContent } from '../../../components/TabContent';
import { useRoutes } from '~/hooks';

interface GridProps {
  factors: RefrigerantConversionFactor[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Reporting Year',
    field: 'reportingYear',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Start date',
    field: 'startDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: getGridDateComparator(),
    },
  },
  {
    headerName: 'End date',
    field: 'endDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: getGridDateComparator(),
    },
  },
  {
    headerName: 'Gas Type',
    field: 'gasName',
    filter: 'agSetColumnFilter',
  },

  {
    headerName: 'Gas Category',
    field: 'gasGroupName',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'kgCO2e',
    field: 'kgCO2e',
    filter: 'agNumberColumnFilter',
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Unit',
    filter: 'agNumberColumnFilter',
    valueGetter: () => 'kg',
    initialWidth: 80,
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'reference',
    cellRendererParams: { icon: 'external-link-alt' },
  },
];

export function RefrigerantConversionFactorsGrid({ factors }: GridProps) {
  const tabContentData = useContext(TabContentContext);
  const routes = useRoutes();
  const handleGridReady = useCallback((e: GridReadyEvent) => e.api.sizeColumnsToFit(), []);

  const rowData = useMemo(
    () =>
      factors.map((factor) => ({
        ...factor,
        startDate: formatDate(factor.startDate),
        endDate: formatDate(factor.endDate),
      })),
    [factors]
  );

  return (
    <TabContent {...tabContentData} index={routes.configuration.factors.refrigerant}>
      <div className="ag-theme-alpine">
        <FactorsGrid domLayout="autoHeight" rowData={rowData} onGridReady={handleGridReady} columnDefs={columnDefs} />
      </div>
      <RefrigerantFactorsDescription />
    </TabContent>
  );
}
