import { Page, styled } from '@rio/ui-components';
import { ApplicationData } from '~/types';
import { Tasks } from './Tasks';
import { ReportingSection } from './Reporting';
import { ContinueLearning } from './ContinueLearning';
import { useGetHomePageEmissionData } from './useGetHomePageEmissionData';
import { useGetHomePageLearningProgressData } from './useGetHomePageLearningProgressData';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const title = {
  content: 'Sustainability is for everyone',
};
interface HomeContainerProps {
  applicationData: ApplicationData;
}
export function HomeContainer({
  applicationData: { currentAccountId, getTasksByUserId, idToken },
}: HomeContainerProps) {
  const { data: emissionData } = useGetHomePageEmissionData({
    accountId: currentAccountId,
  });

  const { data: learningProgressData } = useGetHomePageLearningProgressData({
    userId: idToken.sub,
    continueLearningLimit: 3,
  });

  const coursesToContinue = learningProgressData?.getLearnProgressLogByUserId || [];

  return (
    <Page title={title}>
      <Container>
        {!!coursesToContinue.length && (
          <ContinueLearning userFirstName={idToken.given_name} courses={coursesToContinue} />
        )}
        <ReportingSection data={emissionData} />
        <Tasks tasks={getTasksByUserId} />
      </Container>
    </Page>
  );
}
