import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { GET_MANAGEMENT_SYSTEM_BY_ID, ATTACH_CONTENT, ATTACH_DOCUMENT } from '../Overview/index.queries';
import {
  Heading,
  Col,
  Row,
  MarkdownRenderer,
  Carousel,
  EvidenceUploadItem,
  Button,
  CircleIcon,
  Notification,
  Text,
  TextLink
} from 'rio-ui-components';
import MarkdownViewEditor from '../../../../components/MarkdownViewEditor';
import { AccordionItem } from '../../../../components/AccordionItem';
import AddDocumentTile from '../../../../components/AddDocumentTile/AddDocumentTile';
import { UploadDocumentModal } from '../../../DocumentContainer/DocumentModal/UploadDocumentModal';
import { makeManagementSystemCode, calculateEvidenceTileNumber } from '../../../../utils/managementSystemUtils';
import { useCurrentAccountId } from '../../../../hooks/useCurrentAccountId';
import { escape } from '../../../../utils/escape';
import InvestmentsGrid from './InvestmentsGrid';
import CharacteristicsModal from './CharacteristicsModal';
import { ProgressChartRow } from '../../../../components/ProgressChart';

const ManagementSystemContainer = styled(Col)`
  flex: 1;
  padding-left: ${(props) => props.theme.geometry.md.spacing};
`;

const HeaderContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RowStyled = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

const TitleContainerStyled = styled(Row)`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const HeadingStyled = styled(Heading)`
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const DescriptionContainer = styled(Col)`
  flex-grow: 0;
  background-color: ${(props) => props.theme.colors.basic.white};
  margin-bottom: 0 ${(props) => props.theme.geometry.md.spacing};
  padding: ${(props) => props.theme.geometry.sm.spacing};
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const DocumentAndChildContainer = styled(Row)`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.basic.white};
  padding: ${(props) => props.theme.geometry.sm.spacing};
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const PaddedContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

export function ManagementSystemOverview({
  managementSystem,
  managementSystemTree,
  canEditContent,
  isReadOnly,
  refetch
}) {
  const { itemId } = useParams();
  const currentPage = managementSystemTree[itemId] || managementSystem;
  const [uploadModalShown, setUploadModalShown] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [attachDocument, { error: mutationError }] = useMutation(ATTACH_DOCUMENT);
  const [attachContent] = useMutation(ATTACH_CONTENT);
  const accountId = useCurrentAccountId();
  const onAddDocumentComplete = (error) => {
    error
      ? setNotification({ message: 'Something went wrong. Please try again later. ', color: 'danger' })
      : setNotification({ message: 'Document successfully added. Now scanning for viruses.', color: 'success' });
    refetch();
  };
  const onSaveContent = async (content, id, linkId) => {
    attachContent({
      variables: {
        accountId,
        id,
        linkId,
        synopsis: escape(content)
      },
      update: (cache) => {
        const data = cache.readQuery({ query: GET_MANAGEMENT_SYSTEM_BY_ID, variables: { id, accountId } });
        if (data.getManagementSystemById.id === linkId) {
          data.getManagementSystemById.synopsis = escape(content);
        } else {
          data.getManagementSystemById.chapters.forEach((chapter) => {
            if (chapter.id === linkId) chapter.synopsis = escape(content);
            chapter.chapters.forEach((clause) => {
              if (clause.id === linkId) clause.synopsis = escape(content);
            });
          });
        }
        cache.writeQuery({
          data,
          query: GET_MANAGEMENT_SYSTEM_BY_ID
        });
      }
    }).catch(() => {
      setNotification({
        message: 'Something went wrong when saving your content. Please try again later.',
        color: 'danger'
      });
    });
  };
  return (
    <ManagementSystemContainer container item>
      <DescriptionContainer container item>
        <TitleContainerStyled item container>
          <HeaderContainer span={12} item>
            <Col span={8} item>
              <CircleIconStyled
                inline
                height="50px"
                size="xl"
                circleColor="tertiary"
                iconColor="tertiary"
                icon="pencil-alt"
              />
              <Heading inline>{currentPage.tocTitle || currentPage.title}</Heading>
            </Col>
            {currentPage.content?.headerButton && (
              <Col span={4} item>
                {currentPage.content?.headerButton.link && (
                  <TextLink target="__blank" href={currentPage.content?.headerButton.link}>
                    <Button>{currentPage.content?.headerButton.text}</Button>
                  </TextLink>
                )}
                {currentPage.content?.headerButton.modal && (
                  <Button onClick={() => setShowModal(currentPage.content?.headerButton.modal)}>
                    {currentPage.content?.headerButton.text}
                  </Button>
                )}
              </Col>
            )}
          </HeaderContainer>
        </TitleContainerStyled>
        <RowStyled container item>
          <HeadingStyled size="md">Overview:</HeadingStyled>
          {canEditContent ? (
            <MarkdownViewEditor
              onSave={(content) => onSaveContent(content, managementSystem.id, currentPage.id)}
              source={currentPage.synopsis || 'Add your own content here.'}
            />
          ) : (
            <MarkdownRenderer source={currentPage.synopsis || 'Add your own content here.'} />
          )}
        </RowStyled>
      </DescriptionContainer>

      {!!showModal && <CharacteristicsModal onDismiss={() => setShowModal(null)} />}

      {currentPage.content?.showInvestments && (
        <DescriptionContainer container item>
          <TitleContainerStyled item container>
            <HeaderContainer span={12} item>
              <Col span={8} item>
                <CircleIconStyled
                  inline
                  height="50px"
                  size="xl"
                  circleColor="tertiary"
                  iconColor="tertiary"
                  icon="pencil-alt"
                />
                <Heading inline>Standing Investments Portfolio</Heading>
              </Col>
            </HeaderContainer>
          </TitleContainerStyled>
          <RowStyled container item>
            <InvestmentsGrid />
          </RowStyled>
        </DescriptionContainer>
      )}

      {currentPage.content?.showAssets && (
        <DescriptionContainer container item>
          <RowStyled container item>
            {['A', 'B', 'C', 'D', 'E'].map((char) => {
              return (
                <AccordionItem key={char} heading={`Asset ${char}`} hasChildren>
                  <Text>Asset Details...</Text>
                </AccordionItem>
              );
            })}
          </RowStyled>
        </DescriptionContainer>
      )}

      {currentPage.content?.showCompletion && (
        <DescriptionContainer container item>
          <TitleContainerStyled item container>
            <HeaderContainer span={12} item>
              <Col span={8} item>
                <CircleIconStyled
                  inline
                  height="50px"
                  size="xl"
                  circleColor="tertiary"
                  iconColor="tertiary"
                  icon="pencil-alt"
                />
                <Heading inline>Completion</Heading>
              </Col>
            </HeaderContainer>
          </TitleContainerStyled>
          <RowStyled container item>
            {currentPage.chapters?.map((clause) => (
              <ProgressChartRow key={clause.id} completed={clause.completion} name={clause.title} />
            ))}
          </RowStyled>
        </DescriptionContainer>
      )}

      <DocumentAndChildContainer container item>
        <DocumentContainer>
          <FlexRow>
            <TitleContainerStyled span={12}>
              <CircleIconStyled
                inline
                height="50px"
                size="xl"
                circleColor="tertiary"
                iconColor="tertiary"
                icon="file-alt"
              />
              <Heading inline>Evidence Library</Heading>
            </TitleContainerStyled>
          </FlexRow>
          <FlexRow>
            <HeadingStyled size="md">Upload documents to support this section of the management system.</HeadingStyled>
          </FlexRow>
          <PaddedContainer>
            <Carousel show={3} scroll={1} width={'675px'} infinite={false}>
              {new Array(calculateEvidenceTileNumber(currentPage.documents)).fill(0).map((_, i) => {
                return (
                  <AddDocumentTile
                    key={`upload-evidence${i}`}
                    disabled={isReadOnly}
                    onClick={() => setUploadModalShown(true)}
                  >
                    <EvidenceUploadItem title="Upload Evidence" />
                  </AddDocumentTile>
                );
              })}
              {currentPage.documents.map((doc) => {
                return (
                  <Link key={doc.id} target="_blank" to={`/documents/all/${doc.id}`}>
                    <EvidenceUploadItem
                      title={
                        doc.managementSystemCode
                          ? doc.managementSystemCode.toUpperCase()
                          : `${managementSystem.shortCode.toUpperCase()} Evidence`
                      }
                      desc={doc.fileName}
                      uploaded={true}
                    />
                  </Link>
                );
              })}
            </Carousel>
          </PaddedContainer>
        </DocumentContainer>
      </DocumentAndChildContainer>
      {uploadModalShown && (
        <UploadDocumentModal
          predefinedValues={{
            category: 'Management Systems',
            library: 'GOVERNANCE',
            managementSystemCode: makeManagementSystemCode(managementSystem, currentPage)
          }}
          onDismiss={() => {
            setUploadModalShown(false);
          }}
          onUpload={async (_, document) => {
            await attachDocument({
              variables: {
                id: document.id,
                accountId,
                systemId: managementSystem.id,
                linkId: currentPage.id
              }
            });
            onAddDocumentComplete(mutationError);
          }}
        />
      )}
      {notification && (
        <Notification name={notification.message} show color={notification.color} onClose={() => setNotification(null)}>
          {notification.message}
        </Notification>
      )}
    </ManagementSystemContainer>
  );
}
