import { NEW } from '../../constants';
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  display: inline-block;
  margin-left: ${(props) => props.theme.geometry.xxs.spacing};
`;

export function LicencesCount(props) {
  const { id, licencesCountMap = new Map() } = props;

  const licencesCount = {
    total: 0,
    assigned: 0
  };

  licencesCountMap.has(id) &&
    licencesCountMap.get(id).forEach(({ count, status }) => {
      if (status !== NEW) {
        licencesCount.assigned += count;
      }

      licencesCount.total += count;
    });
  return (
    <StyledSpan>
      Licences {licencesCount.assigned}/{licencesCount.total}
    </StyledSpan>
  );
}
