import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Col, LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Heading, TextInput, Button, Checkbox, Notification, Text } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import MarkdownViewEditor from '../../../components/MarkdownViewEditor';
import LegislationCountrySelect from '../../../components/LegislationCountrySelect';
import legCountries from '../../../components/LegislationCountrySelect/countries.json';
import { GET_LEGISLATION_BY_ID, UPDATE_LEGISLATION } from './index.queries';
import { useCurrentUserId } from '../../../hooks';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const HeadingStyled = styled(Heading)`
  cursor: pointer;
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const LegislationEditContainer = ({ accountId }) => {
  const userId = useCurrentUserId();
  const { legislationId } = useParams();
  const { data, loading } = useQuery(GET_LEGISLATION_BY_ID, { variables: { id: legislationId, accountId } });

  const [title, setTitle] = useState('');
  const [notification, setNotification] = useState(null);
  const [description, setDescription] = useState('');
  const [countries, setCountries] = useState([]);
  const [links, setLinks] = useState('');
  const [apiData, setApiData] = useState('');
  const [lastFlaggedForReview, setLastFlaggedForReview] = useState('');
  const [reviewFlag, setReviewFlag] = useState(false);
  const [reviewFlagCleared, setReviewFlagCleared] = useState(false);

  const [updateLegislation] = useMutation(UPDATE_LEGISLATION, {
    onCompleted: () => {
      setReviewFlagCleared(false);
      setNotification({ colour: 'success', message: 'Legislation has been updated!' });
    },
    onError: () => {
      setReviewFlagCleared(false);
      setNotification({ colour: 'danger', message: 'Something went wrong! Please try again later.' });
    },
  });

  useEffect(() => {
    setTitle(data ? data.getLegislationById.title : '');
    setDescription(data ? data.getLegislationById.synopsis : '');
    setLastFlaggedForReview(data ? data.getLegislationById.lastFlaggedForReview : '');
    setLinks(data && Array.isArray(data.getLegislationById.links) ? data.getLegislationById.links.join(',') : '');
    setApiData(data && data.getLegislationById.apiData ? data.getLegislationById.apiData.path : '');
    setCountries(
      data && Array.isArray(data.getLegislationById.countries)
        ? legCountries.filter((country) => data.getLegislationById.countries.includes(country.value))
        : ''
    );
  }, [data]);

  const onUpdateLegislation = () => {
    updateLegislation({
      variables: {
        legislation: {
          ...data.getLegislationById,
          title,
          apiData: {
            path: apiData,
          },
          synopsis: description,
          lastFlaggedForReview: reviewFlagCleared
            ? null
            : reviewFlag
            ? new Date().toISOString()
            : data.getLegislationById.lastFlaggedForReview,
          countries: countries.map((country) => country.value),
          links: links.split(','),
        },
        userId,
        accountId,
      },
    });
  };

  return (
    <ContainerStyled>
      <Col name="ConfigurationLegislationContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationLegislationContainer__Controls"
          icon="pencil-alt"
          iconColor="tertiary"
          title="Edit Legislation"
        ></ContainerHeader>
      </Col>
      {notification && (
        <Notification
          name="ConfigurationLegislationContainer__Notification"
          show
          color={notification.colour}
          onClose={() => setNotification(null)}
        >
          {notification.message}
        </Notification>
      )}
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {!loading && data && (
        <>
          <ContentContainer>
            <Heading>Title</Heading>
            <TextInput onChange={(e) => setTitle(e.target.value)} value={title} box />
          </ContentContainer>
          <ContentContainer>
            <Heading>Synopsis</Heading>
            <MarkdownViewEditor source={description} onSave={(content) => setDescription(content)} />
          </ContentContainer>
          <ContentContainer>
            <Heading>Links</Heading>
            <TextInput onChange={(e) => setLinks(e.target.value)} value={links} box />
          </ContentContainer>
          <ContentContainer>
            <Heading>Countries</Heading>
            <LegislationCountrySelect
              value={countries}
              onChange={(e) => {
                setCountries(e.target.select);
              }}
            />
          </ContentContainer>
          <ContentContainer>
            <Heading>Api Link</Heading>
            <TextInput onChange={(e) => setApiData(e.target.value)} value={apiData} box />
          </ContentContainer>
          <RowContainer>
            <RowContainer onClick={() => setReviewFlag(!reviewFlag)}>
              <HeadingStyled>Flag For Review</HeadingStyled>
              <Checkbox checked={reviewFlag} />
            </RowContainer>
            <ContentContainer>
              {!reviewFlagCleared && (
                <Button
                  onClick={() => {
                    setReviewFlag(false);
                    setReviewFlagCleared(true);
                  }}
                >
                  Clear Review Flag
                </Button>
              )}
              {reviewFlagCleared && <HeadingStyled onClick={() => setReviewFlagCleared(false)}>Undo</HeadingStyled>}
            </ContentContainer>
          </RowContainer>
          {lastFlaggedForReview && (
            <ContentContainer>
              <Text>{`This legislation was last flagged for review on ${moment(lastFlaggedForReview).format(
                'DD/MM/YYYY'
              )}`}</Text>
            </ContentContainer>
          )}
          <ContentContainer>
            <Button name="save" color="success" onClick={() => onUpdateLegislation()}>
              Save
            </Button>
          </ContentContainer>
        </>
      )}
    </ContainerStyled>
  );
};

export default LegislationEditContainer;
