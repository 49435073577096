import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as JsSearch from 'js-search';

import { injectIntl } from 'react-intl';
import { Row, Search, Icon, MarkdownRenderer, CircleIcon, Heading, LoadingIndicator, Text } from 'rio-ui-components';
import { GET_KBS_FOR_ACCOUNT } from './index.queries';
import { GET_LANDBOT_ID } from './../../../queries/landbot';
import PageHeader from '../../../components/PageHeader';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { AccordionItem } from '../../../components/AccordionItem';
import ChatFrame from './chatFrame';
import { useParams, Link } from 'react-router-dom';
import { useIsDemoAccount } from '../../../hooks/useIsDemoAccount';
import { usePermissions } from '../../../hooks/usePermissions';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 110%;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0 ${(props) => props.theme.geometry.md.spacing} ${(props) => props.theme.geometry.md.spacing}
    ${(props) => props.theme.geometry.md.spacing};
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const KnowledgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.geometry.sm.spacing};
  width: 40%;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.geometry.sm.spacing};
  width: 60%;
  height: 90vh;
`;

const ChildContentContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  max-height: 20vh;
  overflow-y: scroll;
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ChildHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.basic.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  width: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

const KbListHeader = styled.div`
  background-color: ${(props) => props.theme.colors.basic.white};
  width: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing};
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const EmbeddedChatBot = () => {
  const [searchValue, setSearchValue] = useState('');
  const params = useParams();
  const accountId = useCurrentAccountId();
  const { currentUser } = usePermissions();
  const accessToken = localStorage.getItem('ACCESS_TOKEN');

  const { data, loading } = useQuery(GET_KBS_FOR_ACCOUNT, {
    variables: {
      accountId
    }
  });
  const { data: landbotData } = useQuery(GET_LANDBOT_ID);

  const applySearch = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('id');
    search.addIndex('title');
    search.addDocuments(data);
    return search.search(term);
  };

  const showDemoBots = useIsDemoAccount();

  const botColours = ['primary', 'secondary', 'tertiary', 'quaternary'];

  const filterForRio = (data) => {
    return showDemoBots ? data : data.filter((bot) => !bot.demoMode);
  };

  const availableBots = data?.getKnowledgeBasesForAccount || [];

  const selectedBot = availableBots.find((item) => item.externalID === params.id);
  const filteredBots = filterForRio(availableBots);

  const coloredBots = filteredBots.map((bot, index) => ({ ...bot, colour: botColours[index % botColours.length] }));

  const searchedBots = applySearch(coloredBots, searchValue);

  return (
    <ContainerStyled name="Intelligence">
      <PageHeader
        name="Intelligence_Header"
        title={'Intelligence'}
        breadcrumbs={[{ title: 'Intelligence' }]}
        icon="brain"
        iconColor="primary"
      />

      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}

      {!loading && data && (
        <ContentContainer>
          <KnowledgeContainer>
            <KbListHeader>
              <FlexRow>
                <TitleContainerStyled>
                  <CircleIconStyled
                    inline
                    height="50px"
                    size="xl"
                    circleColor="primary"
                    iconColor="primary"
                    icon="crosshairs"
                  />
                  <Heading name="KB__Title" inline>
                    Knowledge Bases
                  </Heading>
                </TitleContainerStyled>
              </FlexRow>
              <FlexRow>
                <SearchHeader container item itemAlign="center">
                  <SearchStyled
                    name="IntelligenceBots__Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    hideButton
                  />
                </SearchHeader>
              </FlexRow>
            </KbListHeader>
            {searchedBots.map((bot) => {
              const isActive = bot.externalID === selectedBot?.externalID;
              return (
                <AccordionItem
                  key={bot.externalID}
                  defaultExpanded={false}
                  active={isActive}
                  heading={
                    <>
                      <CircleIconStyled
                        inline
                        height="50px"
                        size="lg"
                        icon="robot"
                        circleColor={isActive ? 'white' : bot.colour}
                        iconColor={isActive ? 'white' : bot.colour}
                      />{' '}
                      {bot.title}
                    </>
                  }
                  summary={bot.synopsis}
                >
                  <Column>
                    <ChildContentContainer>
                      <MarkdownRenderer name="LegislationItem__Synopsis" source={bot.synopsis} />
                    </ChildContentContainer>
                    <Link to={`/intelligence/${bot.externalID}`}>
                      <Icon icon="external-link-alt" /> Start Consultation
                    </Link>
                  </Column>
                </AccordionItem>
              );
            })}
          </KnowledgeContainer>
          <ChatContainer>
            <ChildHeader>
              <CircleIconStyled
                inline
                height="50px"
                size="lg"
                icon="robot"
                circleColor={selectedBot?.colour || 'primary'}
                iconColor={selectedBot?.colour || 'primary'}
              />
              <div>
                <Heading>{selectedBot?.title || 'Choose a Knowledge Base to get started...'}</Heading>
                {selectedBot && <Text>{`Rio's guide to ${selectedBot.title}`}</Text>}
              </div>
            </ChildHeader>
            <ChatFrame
              kbId={selectedBot?.externalID}
              accountId={accountId}
              accessToken={accessToken}
              landbotId={landbotData?.getLandbotId}
              fullName={`${currentUser?.first_name} ${currentUser?.last_name}`}
            />
          </ChatContainer>
        </ContentContainer>
      )}
    </ContainerStyled>
  );
};

export default injectIntl(EmbeddedChatBot);
