import { dromoConfiguration, DromoConfiguration } from './base';
import { validateDates, validateEitherFieldsIncluded } from './hooks';
import template from '../HotelStaysContainer/UploadContainer/advanced-hotel-stays-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Hotel Stays & Home Working';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getHotelstaysConfiguration(): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
    bulkRowHooks: [
      (records) => validateEitherFieldsIncluded(['hotel nights', 'home working days'], records),
      (records) => validateDates(['start date (dd/mm/yyyy)', 'end date (dd/mm/yyyy)'], records),
    ],
  };
}
