import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Heading, Label, TextInput, Select } from 'rio-ui-components';
import { useMutation, useQuery } from '@apollo/client';
import { useNotification } from '../../../hooks';
import { SelectEvent } from '../../../types';
import { namedEntityToSelectOption } from '../../../utils';
import { Query, Scheme } from '@rio/rio-types';
import { GET_PROJECT_CATEGORIES } from '../../../graphql/queries/projectCategory/GetProjectCategories.query';
import { CREATE_SCHEME, UPDATE_SCHEME } from './index.queries';
import { Modal } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

interface SchemePopupProps {
  accountId: string;
  schemeToUpdate?: Scheme;
  refresh: () => void;
  dismiss: () => void;
}

const SchemePopup = ({ accountId, schemeToUpdate, refresh, dismiss }: SchemePopupProps) => {
  const [name, setName] = useState(schemeToUpdate?.name || '');
  const [description, setDescription] = useState(schemeToUpdate?.description || '');
  const [category, setCategory] = useState(schemeToUpdate?.category || null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { showNotification } = useNotification();

  const [createOrUpdateScheme, { loading }] = useMutation(schemeToUpdate ? UPDATE_SCHEME : CREATE_SCHEME);
  const { data } = useQuery<Pick<Query, 'getProjectCategories'>>(GET_PROJECT_CATEGORIES);
  const categoryOptions = useMemo(() => {
    return data?.getProjectCategories?.map(namedEntityToSelectOption) || [];
  }, [data?.getProjectCategories]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    const variables = {
      accountId: accountId,
      name: name,
      description: description,
      categoryId: category?.id
    };

    try {
      await createOrUpdateScheme({
        variables: schemeToUpdate ? { ...variables, id: schemeToUpdate?.id } : variables
      });
      showNotification(`Scheme ${name} has been successfully ${schemeToUpdate ? 'updated' : 'created'}`, 'success');
      refresh();
    } catch (err) {
      showNotification('Something went wrong. If the problem persists, contact support', 'danger');
      console.error(err);
    } finally {
      dismiss();
    }
    setIsSubmitted(false);
  };

  return (
    <Modal
      size="md"
      height="auto"
      maxHeight="90vh"
      show
      loading={loading}
      dismissable
      name="ConfigurationSchemesContainer__Modal--update"
      onDismiss={dismiss}
    >
      <Container>
        <form
          name="create-or-update-scheme__form"
          onSubmit={(e: React.FormEvent) => {
            handleSubmit(e);
          }}
        >
          <>
            <HeaderStyled size="lg">{schemeToUpdate ? 'Update Scheme' : 'Create Scheme'}</HeaderStyled>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Scheme Name</LabelStyled>
                </LabelContainerStyled>
                <TextInput
                  disabled={isSubmitted}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e?.target.value)}
                  name="schemeName"
                  value={name}
                  box
                />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Description</LabelStyled>
                </LabelContainerStyled>
                <TextInput
                  disabled={isSubmitted}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e?.target.value)}
                  name="schemeDescription"
                  value={description}
                  box
                />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Category</LabelStyled>
                </LabelContainerStyled>
                <Select
                  id="Categories__Select"
                  onChange={(e: SelectEvent) => setCategory({ id: e.target.value, name: e.target.name })}
                  isDisabled={isSubmitted}
                  error={''}
                  value={category?.id || ''}
                  placeholder={'Start typing to search for a category'}
                  options={categoryOptions}
                  isClearable={true}
                />
              </ColStyled>
            </Row>
          </>
          <Row container>
            <ColStyled item container>
              <Button
                name="create-or-update-scheme__button"
                color="success"
                submit
                disabled={loading || !name || !category?.id}
              >
                {schemeToUpdate ? 'Update' : 'Create'}
              </Button>
            </ColStyled>
          </Row>
        </form>
      </Container>
    </Modal>
  );
};

export default SchemePopup;
