import { useLazyQuery, QueryHookOptions } from '@apollo/client';
import { QueryGetMySurveyContributorsArgs as Variables, Query } from '@rio/rio-types';
import { GET_MY_CONTRIBUTORS } from './queries';
import { usePageSuspendingQuery } from '~/hooks';

export type GetMyContributorsResponse = Pick<Query, 'getMySurveyContributors'>;

export function useGetMyContributors(accountId: string) {
  return usePageSuspendingQuery<GetMyContributorsResponse, Variables>(GET_MY_CONTRIBUTORS, {
    variables: {
      accountId,
    },
  });
}

export function useLazyGetMyContributors(
  accountId: string,
  options: QueryHookOptions<GetMyContributorsResponse, Variables> = {}
) {
  return useLazyQuery<GetMyContributorsResponse, Variables>(GET_MY_CONTRIBUTORS, {
    variables: {
      accountId,
    },
    ...options,
  });
}
