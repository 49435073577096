import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  height: 80%;
  padding: 0 32px;
  @media (max-width: 1920px) {
    transform: scale(0.9);
  }
`;

const WidgetColumn = styled.div`
  width: calc(100% / 3);
  height: 100%;
  display: flex;
  flex: 1 0 calc(100% / 3);
  max-width: 520px;
  margin: 32px;
`;

const ContentColumns = ({ children }) => {
  return children.length > 1 ? (
    <ContentContainer>
      {children
        .map((child, idx) => [idx, child])
        .map((child) => {
          return <WidgetColumn key={child[0]}>{child[1]}</WidgetColumn>;
        })}
    </ContentContainer>
  ) : (
    <ContentContainer>
      <WidgetColumn>{children}</WidgetColumn>
    </ContentContainer>
  );
};

export default ContentColumns;
