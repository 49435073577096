import { dromoConfiguration, DromoConfiguration } from './base';
import template from '../RefrigerantsContainer/UploadContainer/refrigerants-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Refrigerants & Other Gases';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getRefrigerantConfiguration(): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
  };
}
