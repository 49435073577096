import Highcharts from 'highcharts';
import { baseChart } from './baseChart';

export const baseProgressBarChart: Highcharts.Options = {
  ...baseChart,
  chart: {
    ...baseChart.chart,
    type: 'bar',
    height: 14,
    spacing: [0, 0, 0, 0]
  },
  plotOptions: {
    bar: { showInLegend: false }
  },
  xAxis: { visible: false },
  yAxis: { visible: false, max: 100 },
  series: [
    {
      data: [100] as Highcharts.XrangePointOptionsObject[],
      color: '#E8E9EC', // grey background
      type: 'bar',
      grouping: false,
      borderRadius: 6
    }
  ]
};
