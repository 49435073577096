import { useLocation, matchPath } from 'react-router-dom';

export function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  const isV2 = pathname.includes('v2');

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = isV2 ? `/v2${patterns[i]}` : patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return patterns[i];
    }
  }

  return null;
}
