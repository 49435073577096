import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { GET_WASTE_TRANSACTIONS_PAGE } from './index.queries';
import { useWasteGridConfig } from './useWasteGridConfig';
import { UploadReferenceCell, DocumentReference } from '../../CellRenderers/index';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Waste Transactions' }];

export function WasteTransactionsGrid() {
  const client = useApolloClient();
  const config = useWasteGridConfig();
  return (
    <>
      <TransactionGrid
        title="Waste Transactions"
        defaultExportFileName="waste-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../waste/uploads"
        config={{
          ...config,
          components: {
            uploadReference: UploadReferenceCell,
            documentReference: DocumentReference,
          },
        }}
        fetchRows={async (variables) => {
          const {
            data: {
              getWasteTransactionsPage: { totalRows, rows },
            },
          } = await client.query({
            query: GET_WASTE_TRANSACTIONS_PAGE,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows,
            totalRows,
          };
        }}
      />
    </>
  );
}
