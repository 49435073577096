import { gql } from '@apollo/client';

export const GET_SURVEY_QUESTION_CATEGORIES = gql`
  query {
    getSurveyQuestionCategories {
      id
      name
    }
  }
`;

export const CREATE_SURVEY_QUESTION_CATEGORY = gql`
  mutation CreateSurveyQuestionCategory($category: SurveyQuestionCategoryInput!) {
    createSurveyQuestionCategory(category: $category) {
      id
      name
    }
  }
`;

export const UPDATE_SURVEY_QUESTION_CATEGORY = gql`
  mutation UpdateSurveyQuestionCategory($id: ID!, $category: SurveyQuestionCategoryInput!) {
    updateSurveyQuestionCategory(id: $id, category: $category) {
      id
      name
    }
  }
`;

export const DELETE_SURVEY_QUESTION_CATEGORY = gql`
  mutation DeleteSurveyQuestionCategory($id: ID!) {
    deleteSurveyQuestionCategory(id: $id) {
      id
      name
    }
  }
`;
