import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../../../../graphql/fragments/LocationFields.fragment';

export const GET_TRANSPORT_TRANSACTION_PAGE = gql`
  query GetTransportTransactionPage(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getTransportTransactionPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        date
        location {
          ...LocationFields
        }
        locationCode
        type
        subtype
        fuelType
        purpose
        reference
        origin
        destination
        volume
        volumeUnit
        distance
        numberOfJourneys
        journeyDistance
        distanceUnit
        freight
        carrier
        dataProvider
        activity
        cost
        costVat
        currency
        documentReference
        carbonEmissionsScope1
        carbonEmissionsScope2
        carbonEmissionsScope3Indirect
        carbonEmissionsScope3WTT
        carbonEmissionsScope3
        carbonEmissionCO2
        carbonEmissionN2O
        carbonEmissionCH4
        carbonEmissionsScope3TD
        notes
        importBatchId
        importBatchFileName
        tags
        departments
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

// LABEL SELECTOR
export const GET_COLUMNS = gql`
  {
    transportTransactionGridColumns @client {
      id
      displayName
      display
      width
      dataType
      field
      filterable
    }
  }
`;

export const SET_COLUMNS = gql`
  mutation SwapTransportTransactionColumnDisplay($id: String!) {
    swapTransportTransactionColumnDisplay(id: $id) @client
  }
`;

// GRID CONTAINER
export const SET_TOTAL_PAGES = gql`
  mutation SetWaterTransactionGridTotalPages($totalPages: Int!) {
    setTransportTransactionGridTotalPages(totalPages: $totalPages) @client
  }
`;
