import { GridApi, ColumnApi } from 'ag-grid-community';
import { Filters, SortCommand, DataTrackerConfigsPage } from '@rio/rio-types';

export type FetchRowsParameters = {
  offset?: number;
  limit?: number;
  sort?: SortCommand[];
  filters?: Filters;
};

export type FetchRowsFunction = (params: FetchRowsParameters) => Promise<DataTrackerConfigsPage>;

export type GridApiType =
  | {
      api: GridApi;
      columnApi: ColumnApi;
    }
  | undefined;

export const accessControls = [
  'waste',
  'electricity',
  'gas',
  'water',
  'transport',
  'fuel',
  'buildingEnergyRatings',
  'refrigerants',
  'heatSteam',
  'materials',
  'hotelStays',
  'safety',
  'employees',
  'community',
  'business',
  'biodiversity',
] as const;

// eslint-disable-next-line prettier/prettier
export type Icons = (typeof accessControls)[number] | 'overview';

export type DataItemProps = {
  kind: Icons;
  to: string;
};
export type UploadDataItemsProps = {
  activeAccessControls?: string[];
  isPortfolio?: boolean;
};
