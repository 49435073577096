import { gql } from '@apollo/client';

export const GET_HOTEL_STAYS_CONVERSION_FACTORS = gql`
  query getHotelStaysConversionFactors($filters: Filters, $limit: Int = 100, $offset: Int, $sort: [SortCommand!]) {
    getHotelStaysConversionFactors(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      rows {
        id
        reportingYear
        type
        unit
        reference
        startDate
        endDate
        country
        kgCO2e
      }
      totalRows
    }
  }
`;

export const GET_HOTEL_STAYS_CONVERSION_FACTORS_FILTER = gql`
  query getHotelStaysConversionFactorsFilter($field: String!) {
    getHotelStaysConversionFactorsFilter(field: $field) {
      label
      value
    }
  }
`;
