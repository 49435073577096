import { gql } from "@apollo/client";

export const GET_ALL_MATERIAL_ORIGIN_TYPES = gql`
  query GetAllMaterialOriginTypes {
    getAllMaterialOriginTypes {
      id
      name
    }
  }
`;
