import { gql } from '@apollo/client';
import DOCUMENT_FIELDS from '../../graphql/fragments/DocumentFields.fragment';

export const CREATE_DOCUMENT_RECORD = gql`
  mutation CreateDocumentRecord($input: CreateDocumentRecordInput!) {
    createDocumentRecord(input: $input) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FIELDS}
`;
