import { gql } from '@apollo/client';

export const WASTE_HIERARCHY_FRAGMENT = gql`
  fragment WasteHierarchyFields on WasteHierarchy {
    id
    accountId
    prevention
    preparingForReuse
    recycling
    otherRecovery
    disposal
    unknown
    reference
    isGlobal
    treatmentProcess {
      name
      id
    }
    user {
      id
      first_name
      last_name
    }
    wasteIn {
      id
      name
    }
  }
`;

export const GET_WASTE_HIERARCHIES_DATA = gql`
  query GetWasteHierarchiesData($accountId: ID!) {
    getWasteHierarchiesByAccountId(accountId: $accountId) {
      ...WasteHierarchyFields
    }
    getWasteInsByAccountId(id: $accountId) {
      id
      name
    }
  }
  ${WASTE_HIERARCHY_FRAGMENT}
`;

export const GET_TREATMENT_PROCESSES = gql`
  query getTreatmentProcesses {
    getAllWasteTreatmentProcesses {
      id
      name
    }
  }
`;

export const CREATE_CUSTOM_WASTE_HIERARCHY = gql`
  mutation CreateHierarchy($input: CreateCustomWasteHierarchyInput!) {
    createCustomWasteHierarchy(input: $input) {
      ...WasteHierarchyFields
    }
  }
  ${WASTE_HIERARCHY_FRAGMENT}
`;

export const GET_DEFAULT_WASTE_HIERARCHY = gql`
  query GetDefaultWasteHierarchyByWasteTreatmentId($treatmentProcessId: ID!) {
    getDefaultWasteHierarchyByWasteTreatmentId(treatmentProcessId: $treatmentProcessId) {
      ...WasteHierarchyFields
    }
  }
  ${WASTE_HIERARCHY_FRAGMENT}
`;
