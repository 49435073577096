import styled from 'styled-components';
import { Survey as SurveyInterface, Maybe } from '@rio/rio-types';

import { Page } from './styles';
import { Survey } from './Survey';
import { SurveyContributors } from './SurveyContributors';
import { SurveyOverviewChart } from './SurveyOverviewChart';
import { SurveyOverviewContainerHeader } from './SurveyOverviewContainerHeader';
import { SurveyOverviewContainerPlaceholder } from './SurveyOverviewContainerPlaceholder';

interface SurveyOverviewContainerProps {
  onDelete?: () => void;
  survey: Maybe<SurveyInterface> | undefined;
  latest?: boolean;
}

export const SurveyOverviewSection = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

export function SurveyOverviewPage({ survey, onDelete, latest = true }: SurveyOverviewContainerProps) {
  if (!survey) {
    return <SurveyOverviewContainerPlaceholder />;
  }

  return (
    <Page>
      <SurveyOverviewContainerHeader survey={survey} onDelete={onDelete} />
      <SurveyOverviewSection>
        <Survey survey={survey} latest={latest} />
        <SurveyOverviewChart survey={survey} />
        <SurveyContributors survey={survey} />
      </SurveyOverviewSection>
    </Page>
  );
}
