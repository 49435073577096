import { ConfigurationAliases } from './index';
import { AliasType } from '@rio/rio-types';

const AliasTypes = [
  { aliasType: AliasType.Tariff, label: 'Tariffs' },
  { aliasType: AliasType.Fueltype, label: 'Fuel Type' }
];

function ConfigurationAliasesOther({ accountId }) {
  return <ConfigurationAliases accountId={accountId} aliasTypes={AliasTypes} />;
}
export default ConfigurationAliasesOther;
