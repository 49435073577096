import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import chunk from 'lodash/chunk';
import round from 'lodash/round';
import { DocumentRecord, CreateDocumentRecordInput } from '@rio/rio-types';
import { Row, ProgressBar } from 'rio-ui-components';
import { DocumentDetails, ColStyled, LabelContainerStyled, LabelStyled } from './DocumentDetails';
import { DocumentModal } from './DocumentModal';
import { CREATE_DOCUMENT_RECORD } from '../index.queries';
import UPLOAD_DOCUMENT from '../../../graphql/mutations/UploadDocument.graphql';
import { createS3Key, getReferenceIdFromFileName } from '../utils';
import { useUserToken } from '../../../hooks/useUserToken';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { FileCard } from './FileCard';
import { SelectFiles } from './SelectFiles';
import { S3, URL } from '../../../constants/documentSources';
import { DocumentLink } from '../types';
import { NullableObject } from '../../../types';

function createDefaultDocument(
  predefinedValues: NullableObject<CreateDocumentRecordInput> | any
): NullableObject<CreateDocumentRecordInput> {
  return {
    documentArea: null,
    category: null,
    type: null,
    supplierId: null,
    reviewDate: null,
    library: null,
    referenceId: null,
    metadata: null,
    ...predefinedValues
  };
}

type DocumentLike = File | DocumentLink;

interface UploadDocumentModalProps {
  onDismiss: () => void;
  onError?: (err: Error) => void;
  onUpload?: (message: string, document: DocumentRecord) => void;
  onComplete?: (message: string, uploadedDocuments: DocumentRecord[], errors: Error[]) => void;
  predefinedValues?: NullableObject<CreateDocumentRecordInput>;
}

export function UploadDocumentModal({
  onDismiss,
  onError,
  onUpload,
  onComplete,
  predefinedValues
}: UploadDocumentModalProps) {
  const [files, setFiles] = useState<DocumentLike[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [document, setDocument] = useState<NullableObject<CreateDocumentRecordInput>>(
    createDefaultDocument(predefinedValues)
  );
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord] = useMutation(CREATE_DOCUMENT_RECORD);
  const { token } = useUserToken();
  const accountId = useCurrentAccountId();
  return (
    <DocumentModal
      onDismiss={onDismiss}
      heading="Documents Upload"
      submitButtonTitle="Upload"
      showButtons={!!files.length}
      submitDisabled={!document.library || !document.category}
      isSubmitting={isSubmitting}
      onSubmit={async () => {
        setIsSubmitting(true);
        const newTotalRequestNumber = files.length * 2;
        const requestPercentage = 100 / newTotalRequestNumber;
        const fileBatches = chunk(files, 20);
        let newUploadProgress = uploadProgress;
        const uploadedDocuments: DocumentRecord[] = [];
        const errors: Error[] = [];
        for (const [index, batch] of fileBatches.entries()) {
          await Promise.all(
            // eslint-disable-next-line no-loop-func
            batch.map(async (file: File | DocumentLink) => {
              const id = uuid();
              const isNativeFile = file instanceof File;
              const fileName = file.name;
              try {
                if (isNativeFile) {
                  const uploadResponse = await uploadDocument({
                    variables: {
                      accountId,
                      id,
                      file,
                      fileName,
                      userId: token.sub
                    }
                  });
                  setUploadProgress((newUploadProgress += requestPercentage));
                  if (!uploadResponse?.data?.uploadDocument?.fileUploaded) {
                    errors.push(
                      new Error(
                        `Unfortunately the file ${fileName} is too large, please upload a file smaller than 10Mb`
                      )
                    );
                  }
                }
                const variables = {
                  ...document,
                  id,
                  accountId,
                  fileName,
                  userId: token.sub,
                  key: isNativeFile ? createS3Key(accountId, id) : null,
                  link: isNativeFile ? null : (file as DocumentLink).link,
                  source: isNativeFile ? S3 : URL,
                  referenceId: document.referenceId || getReferenceIdFromFileName(fileName),
                  tags: (document.tags as any)?.map((tag: any) => {
                    return tag.value;
                  }),
                  notes: document.notes?.trim()
                };
                if (onError && errors.length) {
                  errors.forEach((error) => onError(error));
                  return;
                }
                const response = await createDocumentRecord({
                  variables
                });
                const newDocument = response?.data?.createDocumentRecord;
                if (onUpload && newDocument) {
                  uploadedDocuments.push(newDocument);
                  onUpload(`${fileName} has been uploaded`, newDocument);
                }
                setUploadProgress((newUploadProgress += requestPercentage));
              } catch (err) {
                const error = new Error(`Creation of ${fileName} failed.`);
                errors.push(error);
                if (onError) {
                  onError(error);
                }
              }
            })
          );

          if (index !== fileBatches.length - 1) {
            await new Promise((resolve) => setTimeout(resolve, 15000));
          }
        }
        onDismiss();
        if (uploadedDocuments.length === files.length && onComplete && onError) {
          if (errors.length) {
            const tooLargeEntities = errors.filter((error) => error.message.includes('too large'));
            if (tooLargeEntities.length) {
              onError(
                new Error(
                  'Unfortunately one or more of your uploads are too large, please upload files smaller than 10Mb'
                )
              );
            }
          } else {
            onComplete('All files have been successfully uploaded.', uploadedDocuments, errors);
          }
        }
      }}
    >
      {!files.length ? (
        <SelectFiles accountId={accountId} onLink={(link: DocumentLink) => setFiles([link])} onFiles={setFiles} />
      ) : (
        files.map((file) => <FileCard key={file.name} fileName={(file as File).name} />)
      )}
      {!!files.length && (
        <DocumentDetails document={document} predefinedValues={predefinedValues} onChange={setDocument} />
      )}
      {isSubmitting && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Loading... {round(uploadProgress, 2)}%</LabelStyled>
            </LabelContainerStyled>
            <ProgressBar completed={round(uploadProgress, 2)} />
          </ColStyled>
        </Row>
      )}
    </DocumentModal>
  );
}
