import { useMutation } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material';
import { OptionsProps } from '@rio/ui-components';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentAccountId, useCurrentUser, useUserAccounts, useUserToken } from '~/hooks';
import { SET_CURRENT_ACCOUNT_ID } from '../../../queries/currentAccount';
import { LOG_OUT } from '../../../queries/auth';
import { alphabetiseByName } from '~/utils/alphabetise';

export function useHeader() {
  const user = useCurrentUser();
  const { token: idToken } = useUserToken();
  const { accounts: accountsRecord } = useUserAccounts(idToken.sub);
  const currentAccountId = useCurrentAccountId();
  const [setCurrentAccountId] = useMutation(SET_CURRENT_ACCOUNT_ID);
  const [onLogoutClick] = useMutation(LOG_OUT);
  const accounts = useMemo(() => {
    return Object.keys(accountsRecord)
      .map((accountId) => ({
        id: accountId,
        logo: accountsRecord[accountId].logoPath,
        name: accountsRecord[accountId].name,
      }))
      .sort(alphabetiseByName);
  }, [accountsRecord]);

  const onAccountChanged = useCallback(
    (option: OptionsProps | SelectChangeEvent<unknown>) => {
      if ('value' in option) {
        setCurrentAccountId({
          variables: {
            currentAccountId: option.value,
          },
        });
      }
    },
    [setCurrentAccountId]
  );
  const navigate = useNavigate();

  const onProfileClick = useCallback(() => {
    navigate('/v2/profile');
  }, [navigate]);

  const onResetToursClick = useCallback(() => {
    localStorage.removeItem('TOUR_STATUS');
  }, []);

  return {
    onProfileClick,
    onResetToursClick,
    onLogoutClick,
    onAccountChanged,
    accounts,
    currentAccountId,
    user: {
      fullname: user ? `${user.first_name ?? ''} ${user.last_name}` : '',
      profilePicture: user ? user.profilePicture ?? null : null,
    },
  };
}
