import { useApolloClient } from '@apollo/client';
import { Grid as AgGrid } from '../../../components/UI';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

import { getNormaliserScope } from './normalizerScopes';
import { labels } from '../../../constants/scopes';
import { formatDate, mapFilterModel, mapSortModel, gridValueFormatter } from '../../../utils/index';
import { useNotification, usePermissions, useAgGrid } from '../../../hooks';
import { FetchRowsFunction } from './types';
import { GET_NORMALISERS_PAGE_FILTER } from './index.queries';
import { RowModelType } from 'ag-grid-community';

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;
const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionCell = ({ data: { id }, onEdit, canEdit }) => (
  <GridCellContainer>
    {canEdit && (
      <EditLinkStyled onClick={() => onEdit(id)}>
        <Icon name={`location-row__edit--${id}`} icon="pencil-alt" />
      </EditLinkStyled>
    )}
  </GridCellContainer>
);

type AdditionalParams = {
  accountId: string;
};
type NormalisersGridParams = {
  accountId: string;
  fetchRows: FetchRowsFunction<AdditionalParams>;
  showModal: (...params: any[]) => void;
  setGridApi: (...params: any[]) => void;
};

export function NormalisersGrid({ accountId, fetchRows, showModal, setGridApi }: NormalisersGridParams) {
  const client = useApolloClient();
  const agGrid = useAgGrid({ autoFit: true });
  const permissions = usePermissions();
  const { showNotification } = useNotification();

  const canEdit = Boolean(permissions.data.find((action) => action.startsWith('editNormaliser')));

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getNormalisersPageFilter: values },
      } = await client.query({
        query: GET_NORMALISERS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      params.success([]);
    }
  };

  const options = {
    pagination: true,
    paginationPageSize: 25,
    rowModelType: 'serverSide' as RowModelType,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        height: 'auto',
      },
    },
    columnDefs: [
      {
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'name',
        resizable: true,
        sortable: true,
      },
      {
        colId: 'type',
        headerName: 'Type',
        field: 'type.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
      },
      {
        colId: 'quantity',
        headerName: 'Quantity',
        field: 'quantity',
        filter: 'agNumberColumnFilter',
        width: 90,
        resizable: true,
        sortable: true,
      },
      {
        colId: 'scope',
        headerName: 'Applied to',
        field: 'scope',
        valueFormatter: ({ data }) => labels[getNormaliserScope(data)],
        filter: false,
        width: 100,
        resizable: true,
        sortable: true,
      },
      {
        colId: 'locationName',
        headerName: 'Location',
        field: 'location.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        resizable: true,
        sortable: true,
      },
      {
        colId: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        width: 110,
        resizable: true,
        sortable: true,
      },
      {
        colId: 'endDate',
        headerName: 'End Date',
        field: 'endDate',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        width: 110,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        width: 80,
        resizable: true,
        sortable: false,
        hidden: !canEdit,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  const components = {
    action: (props) => <ActionCell {...props} onEdit={(id) => showModal('update', id)} canEdit={canEdit} />,
  };

  const datasource = {
    async getRows(params) {
      try {
        const { startRow, sortModel, filterModel } = params.request;

        const { rows, totalRows } = await fetchRows({
          accountId,
          offset: startRow || 0,
          limit: options.paginationPageSize,
          sort: mapSortModel(sortModel),
          filters: mapFilterModel(filterModel),
        });

        params.success({ rowData: rows, rowCount: totalRows });
        return { rows, totalRows };
      } catch (err) {
        params.fail();
        throw err;
      }
    },
  };

  return (
    <AgGrid
      {...options}
      paginationPageSize={25}
      cacheBlockSize={25}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, datasource: datasource });
        params.api.setServerSideDatasource(datasource);
      }}
    />
  );
}
