import { Text, styled } from '@rio/ui-components';
import feedback from '../../../../assets/img/icons/feedback.svg';
import { Content, Image, Page } from '../styles';

const PageWrapper = styled(Page)`
  display: contents;
`;

export const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

export const EmptySurveys = () => {
  return (
    <PageWrapper>
      <Content>
        <Image src={feedback} alt="Feedback" />
        <Text typescale="headline" size="small">
          You don't have any surveys yet
        </Text>
        <SubTitle typescale="body" size="large">
          Surveys are a quick way to collect data and insights from your contributors
        </SubTitle>
      </Content>
    </PageWrapper>
  );
};
