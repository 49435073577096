import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LearnContentType } from '@rio/rio-types';
import { injectIntl } from 'react-intl';

import CourseTile from '../CourseTile';
import { getRelatedCourses } from './utils/utils';
import NoResults from '../../../components/NoResults';
import { NOTSTARTED } from '../../../constants/courseProgress';

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
`;

const CoursesGrid = ({ learnAccess, courseId, items, intl }) => {
  const relatedCourses = getRelatedCourses(items, courseId);
  return relatedCourses.length > 0 ? (
    <TileContainer>
      {relatedCourses.map((item) => (
        <Link
          name={`CoursesGrid__Link__${item.id}`}
          to={`/engage/course/${item.type === LearnContentType.Scorm ? 'SCORM/' : ''}${item.id}`}
          key={item.id}
        >
          <CourseTile
            learnAccess={learnAccess}
            courseId={item.id}
            title={item.name}
            viewed={item.metaData.viewed}
            type={item.type}
            image={item.metaData.thumbnail}
            key={item.id}
            courseProgress={item.userCourseMetaData ? item.userCourseMetaData.courseProgress : NOTSTARTED}
          />
        </Link>
      ))}
    </TileContainer>
  ) : (
    <Link name={`LearnHome__Link__${courseId}`} to="engage">
      <NoResults
        name="RelatedCoursesGrid__NoResults"
        title={intl.formatMessage({ id: 'pages.coursesPreview.noResults' })}
      />
    </Link>
  );
};

export default injectIntl(CoursesGrid);
