import { isPostalCode, isPostalCodeLocales } from 'validator';
import { isPhoneNumber } from '../../../utils/validation';
import cloneDeep from 'lodash/cloneDeep';

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  return value.trim() === '';
};

export const validateForm = (form, selectedCountry) => {
  let valid = true;
  const { supplierName, address1, city, postcode, phone, type } = cloneDeep(form);
  const postcodeLocale = selectedCountry?.alpha2Code.toUpperCase();
  const shouldCheckPostcode = isPostalCodeLocales.includes(postcodeLocale);
  if (isEmpty(supplierName.text)) {
    valid = false;
    form.supplierName.error = 'Supplier Name is required';
  }
  if (isEmpty(address1.text)) {
    valid = false;
    form.address1.error = 'Address 1 is required';
  }
  if (isEmpty(city.text)) {
    valid = false;
    form.city.error = 'City is required';
  }
  if (isEmpty(postcode.text)) {
    valid = false;
    form.postcode.error = `Postcode is required`;
  } else if (shouldCheckPostcode && !isPostalCode(postcode.text, postcodeLocale)) {
    valid = false;
    form.postcode.error = `Invalid postcode`;
  }
  if (!isEmpty(phone.text) && !isPhoneNumber(phone.text)) {
    valid = false;
    form.phone.error = `Invalid phone number`;
  }
  if (!type.value.length) {
    valid = false;
    form.type.error = 'At least one supplier type should be selected';
  }
  return [form, valid];
};
