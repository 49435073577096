import { useMutation } from "@apollo/client";
import { ROLLBACK_BATCH } from '../index.queries';
import { useNotification } from '../../../hooks';

export function useRollbackBatch() {
  const { showNotification } = useNotification();
  return useMutation(ROLLBACK_BATCH, {
    onCompleted: () => {
      showNotification('The data has been successfully rolled back!');
    },
    onError: () => {
      showNotification(`Something went wrong. The data wasn't rolled back.`, 'danger');
    }
  });
}
