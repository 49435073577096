export const getAntivirusIconProps = (priority, theme) => {
  const options = {
    INFECTED: {
      icon: 'exclamation-triangle',
      color: theme.colors.danger.normal.background
    },
    ERROR: {
      icon: 'exclamation-circle',
      color: theme.colors.warning.normal.background
    },
    PENDING: {
      icon: 'exclamation-circle',
      color: theme.colors.text.normal.background
    },
    CLEAN: {
      icon: 'check',
      color: theme.colors.success.normal.background
    }
  };

  return options[priority] || {};
};
