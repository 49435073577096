import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import DromoUploader, { IResultMetadata } from 'dromo-uploader-react';
import { TransactionType } from '@rio/rio-types';

import { useCurrentUser, useNotification, useCurrentAccountId, useCurrentAccount } from '~/hooks';
import { isDirectActivityQuestionnaire } from '~/components/UploadProcess/IsDirectActivityModal';
import { GET_DROMO_SCHEMA, INITIATE_DROMO_UPLOAD } from './initiateDromoUploadMutation';
import { parseData } from './parse-data';
import { useGetDromoConfiguration } from '../../DromoConfiguration';
import { Dictionary } from '~/types';
import { Button, CustomIcons, styled, IconButton } from '@rio/ui-components';
import { getEnvVar } from '../../../../env';
import { ConfirmActionModal } from '../../../../components/ConfirmAction/v2';

interface DromoUploaderButtonProps {
  transactionType: TransactionType;
  iconOnly?: boolean;
  onCompleted?: () => void | undefined;
  children?: ReactNode;
}

export const CenterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 4px;
  z-index: 1000;
`;

const StyledButton = styled(Button)`
  height: 40px;
`;

export const V2DromoUploaderButton = ({
  transactionType,
  iconOnly,
  onCompleted = () => {},
}: DromoUploaderButtonProps) => {
  const { showNotification } = useNotification();
  const currentAccountId = useCurrentAccountId();
  const currentAccount = useCurrentAccount();
  const currentUser = useCurrentUser();
  const [initiateDromoUpload] = useMutation(INITIATE_DROMO_UPLOAD, { onCompleted });
  const uploaderRef = useRef<DromoUploader | null>(null);

  const [showDirectActivityModal, setShowDirectActivityModal] = useState(false);
  const [isDirectActivity, setIsDirectActivity] = useState(false);
  const [isDirectActivityResponded, setIsDirectActivityResponded] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const isResponseRequired = useMemo(
    () => isDirectActivityQuestionnaire[transactionType] && !isDirectActivityResponded,
    [isDirectActivityResponded, transactionType]
  );

  const { data, loading, error, refetch } = useQuery(GET_DROMO_SCHEMA, {
    variables: { transactionType, accountId: currentAccountId },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      showNotification(`Could not retrieve Dromo Schema for ${transactionType}`, 'danger');
    },
  });

  const { fields, data: schemaData } = parseData(data?.getDromoSchema);
  const dromoConfiguration = useGetDromoConfiguration(transactionType, schemaData);

  const onResults = useCallback(
    (_data: Dictionary<unknown>, metadata: IResultMetadata) => {
      const defaultFileName = `${transactionType}_${new Date().toISOString()}`;

      initiateDromoUpload({
        variables: {
          transactionType,
          accountId: currentAccountId,
          fileName: metadata.filename || defaultFileName,
          dromoId: metadata.id,
          isDirectActivity,
        },
      });

      setIsDirectActivityResponded(false);
    },
    [currentAccountId, initiateDromoUpload, isDirectActivity, transactionType]
  );

  useEffect(() => {
    if (!isResponseRequired && isButtonClicked) {
      uploaderRef.current?.open();
    }
  }, [isButtonClicked, isDirectActivityResponded, isResponseRequired, uploaderRef, data]);

  const licenseKey = getEnvVar('REACT_APP_DROMO_LICENSE_KEY');

  const UploadButton = useMemo(() => {
    const onClick = () => {
      if (error) {
        refetch();
      } else if (!loading) {
        setShowDirectActivityModal(true);
        setIsButtonClicked(true);
      }
    };

    return iconOnly ? (
      <IconButton variant="filled" name="DromoUploaderButton__uploadDataButton" disabled={loading} onClick={onClick}>
        <CustomIcons.Upload color="onPrimary" />
      </IconButton>
    ) : (
      <StyledButton
        variant="filled"
        name="DromoUploaderButton__uploadDataButton"
        startIcon={<CustomIcons.Upload color="onPrimary" />}
        onClick={onClick}
        disabled={loading}
        loading={loading}
      >
        Upload
      </StyledButton>
    );
  }, [loading, setIsButtonClicked, setShowDirectActivityModal, refetch, error, iconOnly]);

  const DromoUploadButton = useMemo(() => {
    const onClick = () => {
      uploaderRef.current?.open();
    };

    return iconOnly ? (
      <IconButton variant="filled" name="DromoUploaderButton__uploadDataButton" onClick={onClick}>
        <CustomIcons.Upload color="onPrimary" />
      </IconButton>
    ) : (
      <StyledButton
        variant="filled"
        name="DromoUploaderButton__uploadDataButton"
        startIcon={<CustomIcons.Upload color="onPrimary" />}
        onClick={onClick}
      >
        Upload
      </StyledButton>
    );
  }, [uploaderRef, iconOnly]);

  if (!licenseKey) {
    showNotification('Dromo license key not found', 'danger');
    return null;
  }

  if (!dromoConfiguration) {
    return null;
  }

  dromoConfiguration.fields = fields;

  // See docs: https://developer.dromo.io/users/
  const user = {
    id: currentUser?.id || '',
    name: `${currentUser?.first_name} ${currentUser?.last_name}`,
    email: currentUser?.email,
    companyId: currentAccountId,
    companyName: currentAccount?.name,
  };

  if (isResponseRequired || loading) {
    return (
      <>
        {UploadButton}
        {isResponseRequired && showDirectActivityModal && (
          <ConfirmActionModal
            open
            title={isDirectActivityQuestionnaire[transactionType]?.header}
            body={isDirectActivityQuestionnaire[transactionType]?.text}
            onClose={() => {
              setShowDirectActivityModal(false);
            }}
            onCloseButtonClick={() => {
              setIsDirectActivity(false);
              setShowDirectActivityModal(false);
              setIsDirectActivityResponded(true);
            }}
            onConfirm={() => {
              setIsDirectActivity(true);
              setShowDirectActivityModal(false);
              setIsDirectActivityResponded(true);
            }}
            confirmButtonText="Yes"
            closeButtonText="No"
          />
        )}
      </>
    );
  }

  return (
    <>
      {DromoUploadButton}
      <DromoUploader
        licenseKey={licenseKey}
        settings={dromoConfiguration.settings}
        fields={dromoConfiguration.fields}
        user={user}
        style={{ display: 'none' }}
        onResults={onResults}
        onCancel={() => setIsDirectActivityResponded(false)}
        ref={uploaderRef}
        bulkRowHooks={dromoConfiguration.bulkRowHooks}
      >
        Upload Data
      </DromoUploader>
    </>
  );
};
