import { useContext } from 'react';
import { ColDef } from 'ag-grid-community';
import { ElectricityConversionFactor } from '@rio/rio-types';
import { ElectricityFactorsDescription } from './FactorsDescriptions';
import { FactorsGrid } from './FactorsGrid';
import { CommonFactorsDescription } from './FactorsDescriptions';
import { toDate } from '../../DocumentContainer/utils';
import { TabContent } from '../../../components/TabContent';
import { TabContentContext } from './LocationBasedFactors';
import { gridNumberFormatter } from '~/utils';
import { useRoutes } from '~/hooks';

interface ElectricityConversionFactorGridProps {
  factors: ElectricityConversionFactor[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Reporting year',
    field: 'reportingYear',
    filter: 'agSetColumnFilter',
    minWidth: 100,
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    filter: 'agSetColumnFilter',
    valueFormatter: (data: { value: string }) => toDate(data.value),
    minWidth: 100,
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    filter: 'agSetColumnFilter',
    valueFormatter: (data: { value: string }) => toDate(data.value),
    minWidth: 100,
  },
  {
    headerName: 'Scope 2 Generation (kgCO2e)',
    field: 'genKgCo2e',
    minWidth: 320,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 Transmission and Distribution (kgCO2e)',
    field: 'tdKgCo2e',
    minWidth: 320,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT Generation (kgCO2e)',
    field: 'wttGenKgCo2e',
    minWidth: 320,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT T&D',
    field: 'wttTdKgCo2e',
    minWidth: 320,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'reference',
    cellRendererParams: {
      icon: 'external-link-alt',
    },
    minWidth: 100,
  },
  {
    headerName: 'Scope 2 Generation (kgCO2)',
    field: 'genKgCo2',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 T&D (kgCO2)',
    field: 'tdKgCo2',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT generation (kgCO2)',
    field: 'wttGenKgCo2',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3  WTT T&D (kgCO2)',
    field: 'wttTdKgCo2',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 2 Generation (kgCH4)',
    field: 'genKgCh4',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 T&D (kgCH4)',
    field: 'tdKgCh4',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT generation (kgCH4)',
    field: 'wttGenKgCh4',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT T&D (kgCH4)',
    field: 'wttTdKgCh4',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 2 Generation (kgN2O)',
    field: 'genKgN2o',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 T&D (kgN2O)',
    field: 'tdKgN2o',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT generation (kgN2O)',
    field: 'wttGenKgN2o',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT T&D (kgN2O)',
    field: 'wttTdKgN2o',
    initialHide: true,
    valueFormatter: gridNumberFormatter,
  },
];
export function ElectricityConversionFactorGrid({ factors }: ElectricityConversionFactorGridProps) {
  const tabContentData = useContext(TabContentContext);
  const routes = useRoutes();

  return (
    <TabContent {...tabContentData} index={routes.configuration.factors.electricity}>
      <div className="ag-theme-alpine">
        <FactorsGrid rowData={factors} columnDefs={columnDefs} />
      </div>
      <ElectricityFactorsDescription />
      <CommonFactorsDescription />
    </TabContent>
  );
}
