import { useMutation } from '@apollo/client';
import MUTATION from '../../graphql/mutations/licence/AssignAccountLicenceMutation.mutation.graphql';

export function useAssignBatchAccountLicencesMutation() {
  const [execute, data] = useMutation(MUTATION);

  const map = ({ data: { assignBatchAccountLicence = [] } = {}, ...others } = {}) => ({
    data: assignBatchAccountLicence,
    ...others
  });

  return [async (input) => map(await execute({ variables: { input } })), map(data)];
}
