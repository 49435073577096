export function validate(evaluation, answers, previousAnswers) {
  const errors = {};
  evaluation.questions.forEach((q) => {
    const answer = answers[q.id];
    if (!answer) {
      errors[q.id] = 'Please answer the questions';
    }
    if (answer < evaluation.scale.min) {
      errors[q.id] = `Can't be less than ${evaluation.scale.min}`;
    }
    if (answer > evaluation.scale.max) {
      errors[q.id] = `Can't be greater than ${evaluation.scale.max}`;
    }
    if (previousAnswers) {
      const previousAnswer = previousAnswers[q.id];
      if (answer < previousAnswer) {
        errors[q.id] = `Can't be less than previous answer - ${previousAnswer}`;
      }
    }
  });
  return errors;
}
