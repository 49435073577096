import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { SurveyTemplateInput } from '@rio/rio-types';

import { useCreate } from './queries/useCreate';
import { validate } from './validate';
import { Errors } from '../../../types';

const Container = styled.div<{ name: string }>`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const StyledTextColumn = styled(PropertySheet.TextColumn)`
  align-self: center;
`;

interface CreateFormTemplateProps {
  onDismiss: () => void;
  TemplateDetails: any;
  buttonDescription?: string;
  title: string;
  submitButtonText: string;
}

export function CreateTemplate({
  onDismiss,
  buttonDescription,
  TemplateDetails,
  title,
  submitButtonText = 'Create Template'
}: CreateFormTemplateProps) {
  const [template, setTemplate] = useState<Partial<SurveyTemplateInput>>({
    name: '',
    description: '',
    expiryDate: null
  });
  const [errors, setErrors] = useState<Errors<SurveyTemplateInput>>({});

  const [createFormTemplate, { loading }] = useCreate();

  const handleChange = useCallback((payload: Partial<SurveyTemplateInput>) => {
    setTemplate((t) => ({
      ...t,
      ...payload
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors, validTemplate] = validate(template);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }
      await createFormTemplate({
        variables: { template: validTemplate },
        onCompleted: () => onDismiss()
      });
    },
    [template, createFormTemplate, onDismiss]
  );

  return (
    <Container name="CreateTemplate">
      <PropertySheet onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>{title}</PropertySheet.HeaderRow>
        <TemplateDetails onChange={handleChange} template={template} errors={errors} disabled={loading} />
        <PropertySheet.ButtonColumn disabled={loading} onClick={handleSubmit} name="CreateTemplate__SubmitButton">
          {submitButtonText}
        </PropertySheet.ButtonColumn>
        <PropertySheet.Row>
          <StyledTextColumn span={12} size="xs" color={'normal'}>
            {buttonDescription}
          </StyledTextColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
