import { Text, ProfilePicture, ProfilePictureCluster } from 'rio-ui-components';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { NamedEntity, User } from '@rio/rio-types';
import { categoryColor } from '../constants';

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type OwnersCellProps = {
  data: {
    category?: NamedEntity;
    owners: User[];
  };
};

function OwnersCellComponent(props: OwnersCellProps) {
  const { formatMessage } = useIntl();
  const { data: { owners = [] } = {} } = props;

  const ownerNames = owners.length
    ? owners
        .slice()
        .reverse()
        .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
        .join(', ')
    : [];

  const pictures = owners
    .slice()
    .reverse()
    .map((owner) => {
      return (
        <ProfilePicture
          key={owner.profilePicture + owner.first_name}
          src={owner.profilePicture}
          accountName={owner.first_name}
        />
      );
    });

  return (
    <>
      {!!owners.length && (
        <ProfileContainer>
          <ProfilePictureCluster
            title={ownerNames}
            outline
            size="xxs"
            color={categoryColor[props.data.category?.name || 'default']}
          >
            {pictures}
          </ProfilePictureCluster>
        </ProfileContainer>
      )}
      {!owners.length && <Text align="left">{formatMessage({ id: 'pages.project.grid.value.emptyOwners' })}</Text>}
    </>
  );
}

export const OwnersCell = OwnersCellComponent;
