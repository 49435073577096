import { useCallback, useState } from 'react';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isPostalCode } from 'validator';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { TextField, Button, Checkbox, MultiSelect, OptionsProps } from '@rio/ui-components';
import { useCountries } from '~/hooks/useCountries';
import { isPhoneNumber } from '~/utils/validation';
import { validateField } from '../utils';
import { useNotification } from '~/hooks';
import { CREATE_LOCATION, GET_TAGS_BY_ACCOUNT_ID } from '../index.queries';
import { defaultState } from './defaultState';
import { LocationMetadata } from './LocationMetadata';
import { AddressPropertySheet } from './AddressPropertySheet';
import { LocationState, SelectedOptions } from './type';
import { CheckboxWrapper, ErrorText, FormStyled, GridStyled } from './styles';
import { toIsoIfDate } from '~/utils';

export const CreateLocation = (props) => {
  const {
    accountId,
    onSuccess,
    refreshGrid,
    onUpdate,
    showModal,
    locationPoints: prefilledLocationPoints = {
      wasteOut: false,
      wasteIn: false,
    },
  } = props;
  const { showNotification } = useNotification();
  const { countryList, defaultCountry } = useCountries();
  const [state, setState] = useState<LocationState>(defaultState(prefilledLocationPoints));
  const [errorMessage, setErrorMessage] = useState('');

  const [metadataOptions, setMetadataOptions] = useState<SelectedOptions>({
    code: null,
    tenure: null,
    region: null,
    buildingType: null,
    floorSpace: null,
    startDate: null,
    endDate: null,
    leaseRenewalDate: null,
    notes: null,
    departments: [],
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [e.target.name]: { ...prevState.values[e.target.name as keyof typeof prevState.values], text: e.target.value },
      },
    }));
  }, []);

  const handleSelectChange = useCallback((e: OptionsProps) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        selectCountry: e,
      },
    }));
  }, []);

  const onTagChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        tags: event,
      },
    }));
  };

  const onCreateComplete = (data) => {
    setState((prevState) => ({
      ...prevState,
      createdAccountId: data.createLocationWithLocationPoints.id,
    }));
    onSuccess(
      `The location ${data.createLocationWithLocationPoints.name} has been created!`,
      'success',
      showModal
        ? data.createLocationWithLocationPoints.id
        : [data.createLocationWithLocationPoints.id, data.createLocationWithLocationPoints.address.id],
      data.createLocationWithLocationPoints
    );

    refreshGrid();
  };

  const onCreateError = useCallback(
    (error) => {
      setState((prevState) => ({
        ...prevState,
        hasError: true,
        isSuccess: false,
        isSubmitted: false,
      }));

      if (error instanceof ApolloError) {
        showNotification('Something went wrong! Please try again later.', 'danger');
      }
    },
    [showNotification]
  );

  const onCheckboxClick = useCallback((data) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        locationPoints: {
          ...prevState.values.locationPoints,
          [data.target.name]: { checked: data.target.checked },
        },
      },
    }));
  }, []);

  const update = useCallback(
    (cache, data) => {
      if (onUpdate) {
        onUpdate(cache, data);
      }
    },
    [onUpdate]
  );

  const { loading, error, data } = useQuery(GET_TAGS_BY_ACCOUNT_ID, { variables: { accountId: accountId } });
  const options =
    !loading && !error ? data.getTagsByAccountId.map((tag) => ({ label: tag.tagName, value: tag.id })) : [];

  const isCheckboxVisible = !(
    get(prefilledLocationPoints, 'wasteIn', false) || get(prefilledLocationPoints, 'wasteOut', false)
  );

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onError: onCreateError,
    onCompleted: onCreateComplete,
    update,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const {
        values: { locationName, address1, city, postcode, phone },
      } = state;

      const locationNameError = validateField(locationName.text, 'Location Name') || '';
      const address1Error = validateField(address1.text, 'Address 1') || '';
      const cityError = validateField(city.text, 'City') || '';
      const postcodeError =
        validateField(postcode.text, 'Postcode') ||
        (isPostalCode(postcode.text, 'any') ? '' : 'Please enter a valid postcode.');
      const phoneError = !phone.text
        ? ''
        : isPhoneNumber(phone.text)
        ? ''
        : 'Please enter a valid phone number using only characters 0 - 9';

      if (locationNameError || address1Error || cityError || postcodeError || phoneError) {
        setState((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            locationName: {
              ...prevState.values.locationName,
              error: locationNameError,
            },
            address1: {
              ...prevState.values.address1,
              error: address1Error,
            },
            city: {
              ...prevState.values.city,
              error: cityError,
            },
            postcode: {
              ...prevState.values.postcode,
              error: postcodeError,
            },
            phone: {
              ...prevState.values.phone,
              error: phoneError,
            },
          },
        }));
        setErrorMessage('Please correct the errors in the form.');
        return false;
      }
      const variables = {
        locId: uuid(),
        locName: state.values.locationName.text,
        accId: accountId,
        add1: state.values.address1.text || null,
        add2: state.values.address2.text || null,
        add3: state.values.address3.text || null,
        city: state.values.city.text,
        post: state.values.postcode.text,
        phone: state.values.phone.text ? state.values.phone.text.trim() : null,
        wasteOut: state.values.locationPoints.wasteOut.checked ? uuid() : null,
        wasteIn: state.values.locationPoints.wasteIn.checked ? uuid() : null,
        tags: state.values.tags.map((el) => el.value),
        aliases: state.values.tags.map((el) => el.value),
        countryId: state.values.selectCountry?.value || defaultCountry?.value,
        /* Metadata fields */
        ...metadataOptions,
        tenure: metadataOptions?.tenure?.value,
        region: metadataOptions?.region?.value,
        buildingType: metadataOptions?.buildingType?.value,
        departments: metadataOptions?.departments?.map((dep) => dep?.id) || [],
        startDate: toIsoIfDate(metadataOptions?.startDate),
        endDate: toIsoIfDate(metadataOptions?.endDate),
        leaseRenewalDate: toIsoIfDate(metadataOptions?.leaseRenewalDate),
      };

      createLocation({
        variables,
      });
      setState((prevState) => ({
        ...prevState,
        isSubmitted: true,
      }));
    },
    [accountId, defaultCountry?.value, metadataOptions, state, createLocation]
  );

  const helperText = error instanceof ApolloError ? error?.message : error;

  return (
    <GridStyled container>
      <FormStyled name="create-location__form">
        <TextField
          name="locationName"
          disabled={state.isSubmitted}
          label="Location Name"
          onChange={handleChange}
          value={state.values.locationName.text}
          error={!!state.values.locationName.error}
          helperText={state.values.locationName.error}
        />

        <AddressPropertySheet
          id={'LocationAddressPropertySheet'}
          name={'LocationAddressPropertySheet'}
          address1={state.values.address1}
          address2={state.values.address2}
          address3={state.values.address3}
          city={state.values.city}
          postcode={state.values.postcode}
          phone={state.values.phone}
          handleChange={handleChange}
          handleSelectChange={(e: OptionsProps) => handleSelectChange(e)}
          disabled={state.isSubmitted}
          countryList={countryList || []}
          selectCountry={state.values.selectCountry || defaultCountry}
        />

        {isCheckboxVisible && (
          <CheckboxWrapper container>
            <Checkbox
              label="Waste Out"
              disabled={state.isSubmitted}
              name="wasteOut"
              onChange={onCheckboxClick}
              checked={state.values.locationPoints.wasteOut.checked}
            />

            <Checkbox
              disabled={state.isSubmitted}
              label=" Waste In"
              name="wasteIn"
              onChange={onCheckboxClick}
              checked={state.values.locationPoints.wasteIn.checked}
            />
          </CheckboxWrapper>
        )}

        <MultiSelect
          name="create-location-tag-multiselection"
          isLoading={loading}
          value={state?.values?.tags}
          label="Tags (Optional)"
          onChange={(e) => onTagChange(e)}
          error={!!error}
          helperText={helperText || ''}
          disabled={!!(loading || error)}
          options={options}
        />

        <LocationMetadata
          accountId={accountId}
          selectedOptions={metadataOptions}
          setSelectedOptions={setMetadataOptions}
        />

        {errorMessage && (
          <ErrorText typescale="body" size="medium">
            {errorMessage}
          </ErrorText>
        )}

        <Button variant="filled" onClick={handleSubmit} disabled={!state.values.locationName.text || state.isSubmitted}>
          Create Location
        </Button>
      </FormStyled>
    </GridStyled>
  );
};
