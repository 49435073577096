import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
  query GetAccounts(
    $limit: Int = 25
    $offset: Int = 0
    $sort: [SortCommand!] = [{ field: "name", order: "asc" }]
    $filters: Filters = {}
  ) {
    getAccounts(offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;
