import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import GET_GAS_TRANSACTIONS_PAGE from '../../../../graphql/queries/gas/GetGasTransactionsPage.query.graphql';
import { useGasGridConfig } from './useGasGridConfig';
import { UploadReferenceCell, DocumentReference, FactorUsedCell } from '../../CellRenderers';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Gas Transactions' }];

export function GasTransactionsGrid() {
  const client = useApolloClient();
  const config = useGasGridConfig();
  return (
    <>
      <TransactionGrid
        title="Gas Transactions"
        defaultExportFileName="gas-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../gas/uploads"
        config={{
          ...config,
          components: {
            uploadReference: UploadReferenceCell,
            documentReference: DocumentReference,
            link: FactorUsedCell,
          },
        }}
        fetchRows={async (variables) => {
          const {
            data: {
              getGasTransactionsPage: { totalRows, rows },
            },
          } = await client.query({
            query: GET_GAS_TRANSACTIONS_PAGE,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows,
            totalRows,
          };
        }}
      />
    </>
  );
}
