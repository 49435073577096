import { LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export interface ContainerLoadingIndicatorProps {
  name?: string;
}

const ContainerLoadingIndicator = ({ name, ...rest }: ContainerLoadingIndicatorProps) => (
  <LoadingContainer {...rest} role="progressbar">
    <LoadingIndicator name={name} size="md" />
  </LoadingContainer>
);

export default ContainerLoadingIndicator;
