import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SubjectTile from './SubjectTile';
import { injectIntl } from 'react-intl';

import ElectricityImage from '../../../../assets/img/data/Electricity.jpg';
import FuelImage from '../../../../assets/img/data/Fuel.jpg';
import GasImage from '../../../../assets/img/data/Gas.jpg';
import MaterialsImage from '../../../../assets/img/data/Materials.jpg';
import WasteImage from '../../../../assets/img/data/Waste.jpg';
import WaterImage from '../../../../assets/img/data/Water.jpg';
import TransportImage from '../../../../assets/img/data/Transport.jpg';
import SafetyImage from '../../../../assets/img/data/Safety.jpg';
import EmployeesImage from '../../../../assets/img/data/Employees.jpg';
import EngagementImage from '../../../../assets/img/data/Engagement.jpg';
import EthicsImage from '../../../../assets/img/data/Ethics.jpg';
import RefrigerantsImage from '../../../../assets/img/data/Refrigerants.jpg';
import HeatSteamImage from '../../../../assets/img/data/HeatSteam.jpg';
import BiodiversityImage from '../../../../assets/img/data/Biodiversity.jpg';
import BuildingEnergyRatingsImage from '../../../../assets/img/data/BuildingEnergyRatings.jpg';
import HotelStaysImage from '../../../../assets/img/data/HotelStays.jpg';

const images = {
  electricity: ElectricityImage,
  fuel: FuelImage,
  gas: GasImage,
  materials: MaterialsImage,
  waste: WasteImage,
  water: WaterImage,
  transport: TransportImage,
  refrigerants: RefrigerantsImage,
  heatSteam: HeatSteamImage,
  safety: SafetyImage,
  employees: EmployeesImage,
  engagement: EngagementImage,
  ethics: EthicsImage,
  biodiversity: BiodiversityImage,
  buildingEnergyRatings: BuildingEnergyRatingsImage,
  hotelStays: HotelStaysImage
};

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};

  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const DataSubjectsGrid = ({ items, fullAccess, intl }) => {
  return (
    <TileContainer>
      {items.map((item) => {
        const hasAccessToSubject = fullAccess[item.title];
        const link = item.comingSoon || !hasAccessToSubject ? '/contact' : `/data/${item.title}`;
        return (
          <Link name={`DataSubjectsGrid__Link__${item.id}`} to={link} key={item.id}>
            <SubjectTile
              fullAccess={hasAccessToSubject}
              key={item.id}
              title={intl.formatMessage({ id: `pages.data.subjects.${item.title}` })}
              image={images[item.title]}
              comingSoon={item.comingSoon || !hasAccessToSubject}
              icon={item.icon}
              iconColor={item.iconColor}
              description={item.description}
            />
          </Link>
        );
      })}
    </TileContainer>
  );
};

export default injectIntl(DataSubjectsGrid);
