import React from 'react';
import styled from 'styled-components';
import rioLogo from '../../assets/img/RioLogo2021.png';

const Logo = styled.img`
  height: 70px;
  object-fit: contain;
`;

export function AccountLogo({ account, onClick, ...rest }) {
  return <Logo alt={rioLogo} src={account.logoPath || rioLogo} onClick={onClick} {...rest} />;
}
