import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import { DiscountCodeField, VerifyButton } from './CartStyles';
import { Icon, Row } from 'rio-ui-components';
import { useNotification } from '../../hooks/useNotification';
import GET_PROMOTION_CODE from '../../graphql/queries/market/GetPromotionCode.query.graphql';

export function DiscountCode({ defaultValue, applyDiscount }) {
  const [discountCode, setDiscountCode] = useState(defaultValue);
  const { showNotification } = useNotification();
  const client = useApolloClient();
  return (
    <Row item container name="Discount-Code-Container">
      <DiscountCodeField
        name="Discount-Code-Container__Input"
        placeholder="Discount code (optional)"
        value={discountCode}
        onChange={(e) => {
          const { value } = e.target;
          if (!value) {
            applyDiscount(null);
          }
          setDiscountCode(value);
        }}
        box
      />
      <VerifyButton
        disabled={!discountCode}
        name="Discount-Code-Container__Verify-Button"
        onClick={async () => {
          try {
            const {
              data: { getPromotionCode }
            } = await client.query({
              query: GET_PROMOTION_CODE,
              variables: {
                code: discountCode
              }
            });
            if (getPromotionCode) {
              applyDiscount(getPromotionCode);
              showNotification('Discount has been applied', 'success');
            } else {
              showNotification('Invalid discount code', 'danger');
            }
          } catch (err) {
            showNotification(err, 'danger');
          }
        }}
        title="Verify discount code"
        inline
      >
        <Icon icon="angle-right" />
      </VerifyButton>
    </Row>
  );
}
