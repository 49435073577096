import { useState } from 'react';
import { Heading, Text, Button, Row, Col } from 'rio-ui-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import NotificationBanner from '../../../components/NotificationBanner';
import RioLogo from '../../../assets/img/RioLogo2021.png';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Dictionary } from 'lodash';
import { useMutation } from '@apollo/client';
import { LOG_IN_SSO } from '../../../queries/auth';
import { getEnvVar } from '../../../env';

const ContainerStyled = styled(Col)`
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ColStyled = styled(Col)`
  text-align: center;
`;

const LogoStyled = styled.div`
  height: 70px;
  width: 125px;
  background: no-repeat top center / contain url(${RioLogo});
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  align-self: center;
  flex: 0 0 auto;
`;

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  width: 140px;
`;

const LoginErrorStyled = styled(Text)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  text-align: center;
`;

enum ProviderType {
  OKTA = 'Okta',
  AZURE = 'Azure',
}

let identities: Dictionary<ProviderType> = {
  'rio-azure-ad': ProviderType.AZURE,
  okta: ProviderType.OKTA,
};

if (getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'Live') {
  identities = {
    luminor: ProviderType.AZURE,
    kier: ProviderType.AZURE,
    'british-council': ProviderType.AZURE,
  };
}

export default function SSOLoginContainer() {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [login] = useMutation(LOG_IN_SSO);
  const signIn = useCallback(() => {
    setIsLoading(true);
    login({
      variables: {
        provider: params.providerId,
      },
    });
  }, [login, params?.providerId]);

  const error = !params.providerId || !(params.providerId in identities);

  return (
    <ContainerStyled container item distribution="center" name="login-container">
      {['Develop', 'QA', 'UAT'].includes(process?.env?.REACT_APP_ENVIRONMENT_NAME || 'Develop') && (
        <NotificationBanner
          id="banner"
          dismissable={false}
          onDismiss={() => null}
          name="LoginContainer__NotificationBanner"
          color="primary"
          icon="exclamation-triangle"
          title={`This is the ${getEnvVar('REACT_APP_ENVIRONMENT_NAME')} site`}
          body="Please note that we regularly reset the data and anything you enter may be overwritten. We recommend that personal details of real individuals are not entered"
        />
      )}
      <Row container item distribution="center">
        <ColStyled container vdistribution="center" item itemAlign="center" span={3}>
          <a target="__blank" href={getEnvVar('REACT_APP_DS_URL')}>
            <LogoStyled />
          </a>
          <Heading size="lg">
            <FormattedMessage id="login.main.title" />
          </Heading>
          <Text align="center">
            <FormattedMessage id="login.main.subheading" />
          </Text>
          {error ? (
            <LoginErrorStyled name="formError" color="danger" size="sm" weight="bold">
              <FormattedMessage id="login.main.providerNotFound" />
            </LoginErrorStyled>
          ) : (
            <StyledButton name="loginbutton" onClick={signIn} size="sm" loading={isLoading}>
              <FormattedMessage
                id="login.main.loginButtonSSO"
                values={{
                  provider: params?.providerId ? identities[params.providerId] : 'your IdP',
                }}
              />
            </StyledButton>
          )}
        </ColStyled>
      </Row>
    </ContainerStyled>
  );
}
