import styled from 'styled-components';
import { SurveyInput } from '@rio/rio-types';
import { PropertySheet, DatePicker, TextArea, Checkbox, Text } from 'rio-ui-components';
import { Errors } from '../../../types';
import { today } from '../../../utils';
import { SurveyTemplateSelect } from '../../../components/Surveys';
import { Recipients } from './Recipients';
import { useSurveyForm } from './hooks/useSurveyForm';

export type SurveyFormProps = {
  errors: Errors<SurveyInput>;
  disabled?: boolean;
  accountId: string;
  isUpdatingSurvey?: boolean;
};

const TextAreaStyled = styled(TextArea)`
  height: 150px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionSubText = styled(Text)`
  padding: 0;
  padding-left: 30px;
`;

const TextStyledCount = styled.div`
  position: absolute;
  bottom: 25px;
  right: 30px;
  color: ${(props) => props.theme.colors.text.normal.background};
`;

const RelativeRow = styled(PropertySheet.Row)`
  position: relative;
`;

export function SurveyForm({ errors, accountId, disabled, isUpdatingSurvey }: SurveyFormProps) {
  const {
    state: survey,
    changeName,
    toggleAutomatedScoring,
    toggleFeedbackEnabled,
    changeNote,
    changeStartDate,
    changeDeadlineDate,
    changeSurveyTemplateId,
  } = useSurveyForm();

  return (
    <>
      <PropertySheet.Row container>
        <PropertySheet.TextInputColumn
          id="AddSurveyForm__Input_Name"
          label="Survey name"
          name="name"
          onChange={(e) => changeName(e.target.value)}
          value={survey.name}
          error={errors.name}
          disabled={disabled}
        />
      </PropertySheet.Row>
      <PropertySheet.Row>
        <PropertySheet.TextColumn size="sm" color={'normal'}>
          This will be presented to recipients
        </PropertySheet.TextColumn>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Survey" span={12}>
          <SurveyTemplateSelect
            value={survey.surveyTemplateId}
            accountId={accountId}
            error={errors.surveyTemplateId || undefined}
            onChange={(e) => changeSurveyTemplateId(e.target.select.value)}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <Recipients hideNotifyByEmail={isUpdatingSurvey} />

      <PropertySheet.Row>
        <PropertySheet.Column label="Feedback">
          <Container>
            <Checkbox
              id="AddSurveyForm_Checkbox_Feedback"
              inline
              onChange={() => toggleFeedbackEnabled()}
              checked={survey.feedbackEnabled}
            />
            <PropertySheet.TextColumn size="md" color="normal">
              Approve responses
            </PropertySheet.TextColumn>
          </Container>
          <Container>
            <OptionSubText size="sm" color="normal">
              If selected, you will have to approve or request changes for each response in Rio
            </OptionSubText>
          </Container>
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Automated Scoring">
          <Container>
            <Checkbox
              id="AddSurveyForm_Checkbox_AutomatedScoring"
              inline
              onChange={() => toggleAutomatedScoring()}
              checked={survey.automatedScoring}
            />
            <PropertySheet.TextColumn size="md" color="normal">
              Score contributions
            </PropertySheet.TextColumn>
          </Container>
          <Container>
            <OptionSubText size="sm" color="normal">
              If selected, you and your recipients will automatically receive performance feedback against Rio
              sustainability metrics
            </OptionSubText>
          </Container>
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Start date" span={12}>
          <DatePicker
            onDayClick={changeStartDate}
            name="startDate"
            selectedDate={survey?.startDate}
            disabledDatesBefore={today().toDate()}
            box
            error={errors.startDate}
            placeholder={'DD/MM/YYYY'}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Deadline for responses" span={12}>
          <DatePicker
            onDayClick={changeDeadlineDate}
            name="deadlineDate"
            selectedDate={survey?.deadlineDate}
            disabledDatesBefore={today().toDate()}
            box
            error={errors.deadlineDate}
            placeholder={'DD/MM/YYYY'}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <RelativeRow container>
        <PropertySheet.Column label="Notes for recipients (optional)" name="notes">
          <TextAreaStyled
            id="AddSurveyForm__Input_Notes"
            name="notes"
            onChange={(e) => changeNote(e.target.value)}
            value={survey?.notes}
            error={errors.notes}
            disabled={disabled}
          />
        </PropertySheet.Column>
        <TextStyledCount color={'normal'}>{survey.notes?.length ?? 0}/1000</TextStyledCount>
      </RelativeRow>
      <PropertySheet.Row>
        <PropertySheet.TextColumn size="sm" color={'normal'}>
          These notes will appear in the email sent to your recipients
        </PropertySheet.TextColumn>
      </PropertySheet.Row>
    </>
  );
}
