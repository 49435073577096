import { useMutation } from '@apollo/client';
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { LOG_IN } from '../../queries/auth';
import { isInvalidPassword } from './utils';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';

export type ResetPasswordFormState = {
  password1: { value: string; error: string | null; success: boolean | null };
  password2: { value: string; error: string | null; success: boolean | null };
  username: { value: string; error: string | null; success: boolean | null };
  confirmationCode: { value: string; error: string | null; success: boolean | null };
};

const getResetPasswordErrorMessageId = ({ name }: Error) => {
  return {
    UserNotFoundException: 'login.resetPassword.userNotFound',
    NotAuthorizedException: 'login.resetPassword.incorrectPassword',
    UserDisabledException: 'login.main.userDisabled',
    ConfirmationCodeEmptyException: 'login.resetPassword.confirmationCodeRequired',
    InvalidCodeException: 'login.resetPassword.invalidCode',
    GeneralError: 'login.resetPassword.generalError',
    LimitExceededException: 'login.resetPassword.limitExceeded',
    ExpiredCodeException: 'login.resetPassword.expiredCode',
  }[name];
};

export function useResetPassword(initialUsername: string, code: string) {
  const [state, setState] = useState<ResetPasswordFormState>({
    password1: { value: '', error: null, success: null },
    password2: { value: '', error: null, success: null },
    username: { value: initialUsername, error: null, success: null },
    confirmationCode: { value: code, error: null, success: null },
  });
  const [login, loginInfo] = useMutation(LOG_IN);
  const [loginError, setLoginError] = useState<ReturnType<typeof getResetPasswordErrorMessageId> | null>(null);
  const [resetLoading, setResetLoading] = useState(false);
  const intl = useIntl();
  const validateFormValues = useCallback(
    (username: string, confirmationCode: string, password1: string, password2: string): boolean => {
      if (username.length === 0) {
        setState((s) => ({
          ...s,
          username: {
            ...s.username,
            error: intl.formatMessage({ id: 'login.resetPassword.noUsernameEntered' }),
          },
        }));
        return false;
      }

      if (confirmationCode.length === 0) {
        setState((s) => ({
          ...s,
          confirmationCode: {
            ...s.confirmationCode,
            error: intl.formatMessage({ id: 'login.resetPassword.noCodeEntered' }),
          },
        }));
        return false;
      }

      if (password1 !== password2) {
        setState((s) => ({
          ...s,
          password2: { ...s.password2, error: intl.formatMessage({ id: 'login.resetPassword.passwordsDontMatch' }) },
        }));
        return false;
      }
      const invalidPassword = isInvalidPassword(password1);
      if (invalidPassword) {
        setState((s) => ({
          ...s,
          password1: { ...s.password1, error: invalidPassword },
          password2: { ...s.password2, error: null },
        }));
        return false;
      }
      return true;
    },
    [setState, intl]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState((s) => ({
        ...s,
        [event.target.name]: { ...s[event.target.name], value: event.target.value, error: false },
      }));
    },
    [setState]
  );

  const handleResetPassword = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const {
        username: { value: username },
        password1: { value: password1 },
        password2: { value: password2 },
        confirmationCode: { value: confirmationCode },
      } = state;
      setResetLoading(true);
      try {
        if (!validateFormValues(username, confirmationCode, password1, password2)) {
          return;
        }
        await Auth.forgotPasswordSubmit(username.toLocaleLowerCase(), confirmationCode, password1);
        await login({
          variables: { email: state.username.value.toLocaleLowerCase(), password: state.password1.value },
        });
      } catch (error) {
        if (error instanceof Error) {
          const messageId = getResetPasswordErrorMessageId(error);
          setLoginError(messageId);
        }
        Sentry.captureException(error);
      } finally {
        setResetLoading(false);
      }
    },
    [state, login, setLoginError, setResetLoading, validateFormValues]
  );

  return useMemo(
    () => ({
      state,
      loading: resetLoading || loginInfo.loading,
      loginError,
      handleChange,
      handleResetPassword,
    }),
    [state, resetLoading, loginInfo, loginError, handleChange, handleResetPassword]
  );
}
