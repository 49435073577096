import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { Col, Button } from 'rio-ui-components';
import { GET_BANNERS_FOR_ACCOUNT } from './index.queries';
import { usePermissions } from '../../../hooks/usePermissions';
import { BannersGrid } from './BannersGrid';
import { AccountSelector } from '~/components';
import PageHeader from '~/components/PageHeader';
import { ControlPanel } from '~/components/ControlPanel';
import { BannersInteractionsProvider, useBannersGrid } from './hooks/useBannersGrid';

const CreateButton = styled(Button)`
  width: 250px;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

export const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

function ConfigurationLearnBanners() {
  const { currentAccountId, onCreate, onAccountSelectorChange } = useBannersGrid();

  const permissions = usePermissions();
  const client = useApolloClient();

  const breadcrumbs = [
    { title: 'Configuration', to: '/configuration' },
    { title: 'Engage', to: '/configuration/learnconfig/learncontent' },
    { title: 'Banners' },
  ];

  return (
    <Col container fullHeight>
      <PageHeader
        name={'Engage Banners'}
        title={'Engage Banners'}
        breadcrumbs={breadcrumbs}
        icon="chart-line"
        iconColor="quaternary"
      >
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <CreateButton
            name="ConfigurationEnergyMetersContainer__Controls__Button--add"
            color="primary"
            inline={true}
            onClick={() => onCreate()}
          >
            + Create Banner
          </CreateButton>
        </ControlPanel>
      </PageHeader>
      {permissions.account.includes('getAll') && (
        <AccountSelectorContainer>
          <AccountSelector value={currentAccountId} onChange={({ id }) => onAccountSelectorChange(id)} />
        </AccountSelectorContainer>
      )}

      <BannersGrid
        fetchRows={async (variables) => {
          const {
            data: { getBanners },
          } = await client.query({
            query: GET_BANNERS_FOR_ACCOUNT,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows: getBanners,
            totalRows: getBanners.length,
          };
        }}
      />
    </Col>
  );
}

export default () => {
  return (
    <BannersInteractionsProvider>
      <ConfigurationLearnBanners />
    </BannersInteractionsProvider>
  );
};
