import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { GET_GICS_CODES } from '../graphql/queries/invest/getGicsCodes.query';

export function useGicsCodes() {
  const { data: { getGicsCodes = [] } = {}, ...others } = useQuery(GET_GICS_CODES);

  return {
    data: orderBy(getGicsCodes, ['name'], ['asc']),
    ...others,
  };
}
