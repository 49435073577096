import { v4 as uuid } from 'uuid';

export const validateField = (value, name) => (value.trim() === '' ? `${name} cannot be empty.` : null);

export const getTagsToBeSaved = (tags) => tags.map((tag) => tag.value);

export const updateLocationPoint = (checked, id) => {
  if (checked && !id) {
    return {
      id: uuid(),
      operation: 'CREATE',
    };
  }

  if (!checked && id) {
    return {
      id: id,
      operation: 'DELETE',
    };
  }

  return null;
};
