import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import chunk from 'lodash/chunk';
import round from 'lodash/round';
import { DocumentRecord, CreateDocumentRecordInput } from '@rio/rio-types';
import { DocumentDetails } from './DocumentDetails';
import { CREATE_DOCUMENT_RECORD } from '../../index.queries';
import UPLOAD_DOCUMENT from '../../../../graphql/mutations/UploadDocument.graphql';
import { createS3Key, getReferenceIdFromFileName } from '../../utils';
import { useUserToken, useCurrentAccountId, useNotification } from '~/hooks';
import { FileCard } from './FileCard';
import { SelectFiles } from './SelectFiles';
import { S3, URL } from '~/constants/documentSources';
import { DocumentLink } from '../../types';
import { NullableObject } from '~/types';
import { styled, LinearProgress, Text, Grid } from '@rio/ui-components';
import { DocumentModal } from './DocumentModal';

const ProgressContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const getUploadFinishedMessage = (allFilesWereUploadedSuccessfully: boolean, uploadedDocumentsLength: number) =>
  `${allFilesWereUploadedSuccessfully ? 'All files ' : ''}(${uploadedDocumentsLength}) ${
    !allFilesWereUploadedSuccessfully ? `file${uploadedDocumentsLength !== 0 && 's'} ` : ''
  }have been successfully uploaded.`;

function createDefaultDocument(
  predefinedValues?: NullableObject<CreateDocumentRecordInput>
): NullableObject<CreateDocumentRecordInput> {
  return {
    type: null,
    supplierId: null,
    reviewDate: null,
    library: null,
    referenceId: null,
    ...(predefinedValues || {}),
  };
}

interface UploadDocumentModalProps {
  onDismiss: () => void;
  onError?: (err: Error) => void;
  onUpload?: (message: string, document: DocumentRecord) => void;
  onComplete?: (message: string, uploadedDocuments: DocumentRecord[], errors: Error[]) => void;
  predefinedValues?: NullableObject<CreateDocumentRecordInput>;
}

export function UploadDocumentModal({
  onDismiss,
  onError,
  onUpload,
  onComplete,
  predefinedValues,
}: UploadDocumentModalProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [document, setDocument] = useState<NullableObject<CreateDocumentRecordInput>>(
    createDefaultDocument(predefinedValues)
  );
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord] = useMutation(CREATE_DOCUMENT_RECORD);
  const { token } = useUserToken();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const newTotalRequestNumber = files.length * 2;
    const requestPercentage = 100 / newTotalRequestNumber;
    const fileBatches = chunk(files, 20);
    let newUploadProgress = uploadProgress;
    const uploadedDocuments: DocumentRecord[] = [];
    const errors: Error[] = [];
    for (const [index, batch] of fileBatches.entries()) {
      await Promise.all(
        // eslint-disable-next-line no-loop-func
        batch.map(async (file: File | DocumentLink) => {
          const id = uuid();
          const isNativeFile = file instanceof File;
          const fileName = file.name;
          try {
            if (isNativeFile) {
              await uploadDocument({
                variables: {
                  accountId,
                  id,
                  file,
                  fileName,
                  userId: token.sub,
                  shouldThrowIfFailed: true,
                },
              });
              setUploadProgress((newUploadProgress += requestPercentage));
            }
            const variables = {
              ...document,
              id,
              accountId,
              fileName,
              userId: token.sub,
              key: isNativeFile ? createS3Key(accountId, id) : null,
              link: isNativeFile ? null : (file as DocumentLink).link,
              source: isNativeFile ? S3 : URL,
              referenceId: document.referenceId || getReferenceIdFromFileName(fileName),
              tags: document.tags,
              notes: document.notes?.trim(),
            };
            if (onError && errors.length) {
              errors.forEach((error) => onError(error));
              return;
            }
            const response = await createDocumentRecord({
              variables,
            });
            const newDocument = response?.data?.createDocumentRecord;
            if (newDocument) {
              uploadedDocuments.push(newDocument);
              if (onUpload) {
                onUpload(`${fileName} has been uploaded`, newDocument);
              }
            }
            setUploadProgress((newUploadProgress += requestPercentage));
          } catch (err) {
            const errorMessage = typeof err === 'string' ? err : (err as Error).message;
            const error = new Error(`Creation of ${fileName} failed (${errorMessage})`);
            errors.push(error);
            if (onError) {
              onError(error);
            }
          }
        })
      );

      if (index !== fileBatches.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, 15000));
      }
    }
    onDismiss();

    if (errors.length && onError) {
      onError(new Error(`Unfortunately ${errors.length} of your uploads did not go through`));
    }

    if (uploadedDocuments.length > 0) {
      const allFilesWereUploadedSuccessfully = uploadedDocuments.length === files.length;

      const completedMessage = getUploadFinishedMessage(allFilesWereUploadedSuccessfully, uploadedDocuments.length);

      if (onComplete) {
        onComplete(completedMessage, uploadedDocuments, errors);
      }

      showNotification(completedMessage, 'success');
    }
  }, [
    files,
    uploadProgress,
    onDismiss,
    onError,
    document,
    accountId,
    token.sub,
    createDocumentRecord,
    uploadDocument,
    onUpload,
    onComplete,
    showNotification,
  ]);

  return (
    <DocumentModal
      onDismiss={onDismiss}
      heading="Documents Upload"
      submitButtonTitle="Upload"
      showButtons={!!files.length}
      submitDisabled={!document.library || !document.category}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <Grid container columns={12} rowGap={3}>
        {!files.length ? (
          <Grid item xs={12}>
            <SelectFiles onFiles={setFiles} />
          </Grid>
        ) : (
          files.map((file) => (
            <Grid item key={file.name} xs={12}>
              <FileCard fileName={(file as File).name} />
            </Grid>
          ))
        )}
        {!!files.length && (
          <Grid item xs={12}>
            <DocumentDetails document={document} predefinedValues={predefinedValues} onChange={setDocument} />
          </Grid>
        )}
        {isSubmitting && (
          <Grid item xs={12}>
            <ProgressContainerStyled>
              <Text typescale="body" size="medium">
                Loading... {round(uploadProgress, 2)}%
              </Text>
              <LinearProgress variant="determinate" value={round(uploadProgress, 2)} />
            </ProgressContainerStyled>
          </Grid>
        )}
      </Grid>
    </DocumentModal>
  );
}
