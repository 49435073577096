import React from 'react';
import { Row, Col, Heading, ProfilePicture, TextInput, Checkbox } from 'rio-ui-components';
import styled from 'styled-components';

const LearnContentRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.sm.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const AccountProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  align-items: center;
`;

const OrderInput = styled(TextInput)`
  width: 90px;
  & input {
    text-align: center;
    flex: auto;
  }
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

const LearnBundleRow = ({ checkedElements, courseId, courseTitle, courseType, onChecked, changeTyping, changeOrder }) => {
  const order = checkedElements?.[courseId] ? checkedElements[courseId].order : '';
  const typingOrder = checkedElements?.[courseId] ? checkedElements[courseId].typingOrder : null;
  return (
    <LearnContentRowStyled name={`account-row--${courseId}`} container itemAlign="center">
      <AccountProfilePictureStyled name={`account-row__profile-picture--${courseId}`} src={null} size="xs" accountName={`${courseType}-${courseTitle}`} />

      <Col container item span={18}>
        <Heading inline name={`account-row__name--${courseId}`} size="md">
          {courseTitle}
        </Heading>
        <Heading inline name={`account-row__type--${courseId}`} size="md">
          {courseType}
        </Heading>
      </Col>

      <Col container>
        <OrderInput box onChange={changeTyping} onBlur={changeOrder} value={typingOrder ? typingOrder : order} />
      </Col>

      <Col container>
        <Checkbox size="md" onChange={onChecked} checked={checkedElements?.[courseId] && checkedElements[courseId].checked} />
      </Col>
    </LearnContentRowStyled>
  );
};
export default LearnBundleRow;
