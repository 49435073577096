import { AliasType, TransactionType } from '@rio/rio-types';
import { Nullable } from '../../../types';

const config = [
  [AliasType.Wastestream, 'Waste Stream', TransactionType.Waste],
  [AliasType.Wastetreatmentprocess, 'Treatment Process', TransactionType.Waste],
  [AliasType.Containertypesize, 'Container Type', TransactionType.Waste],
  [AliasType.Wastecontractor, 'Waste Contractor', TransactionType.Waste],
  [AliasType.Wastecarrier, 'Waste Carrier', TransactionType.Waste],
  [AliasType.Wastein, 'Waste In Location', TransactionType.Waste],
  [AliasType.Wasteout, 'Waste Out Location', TransactionType.Waste],
  [AliasType.Meter, 'Meter Name', TransactionType.Electricity],
  [AliasType.Meter, 'Meter Name', TransactionType.Gas],
  [AliasType.Meter, 'Meter Name', TransactionType.Water],
  [AliasType.Meter, 'Meter Name', TransactionType.Heatsteam],
  [AliasType.Locationpoint, 'Location', TransactionType.Transport],
  [AliasType.Locationpoint, 'Location', TransactionType.Fuel],
  [AliasType.Locationpoint, 'Location', TransactionType.Materials],
  [AliasType.Locationpoint, 'Location', TransactionType.Hotelstays],
  [AliasType.Transport, 'Type', TransactionType.Transport],
  [AliasType.Fueltype, 'Fuel Type', TransactionType.Fuel],
  [AliasType.Transportfueltype, 'Fuel Type', TransactionType.Transport],
  [AliasType.Transportsubtype, 'Subtype', TransactionType.Transport],
  [AliasType.Journeytarget, 'Purpose Of Journey', TransactionType.Transport],
  [AliasType.Unit, 'Quantity units', TransactionType.Fuel],
  [AliasType.Unit, 'Quantity Weight Units', TransactionType.Materials],
  [AliasType.Accuracy, 'Act/Est', TransactionType.Fuel],
  [AliasType.Currency, 'Currency', TransactionType.Waste],
  [AliasType.Currency, 'Currency', TransactionType.Electricity],
  [AliasType.Currency, 'Currency', TransactionType.Heatsteam],
  [AliasType.Currency, 'Currency', TransactionType.Gas],
  [AliasType.Currency, 'Currency', TransactionType.Water],
  [AliasType.Currency, 'Currency', TransactionType.Transport],
  [AliasType.Currency, 'Currency', TransactionType.Fuel],
  [AliasType.Currency, 'Currency', TransactionType.Materials],
  [AliasType.Currency, 'Currency', TransactionType.Hotelstays],
  [AliasType.Tariff, 'Tariff', TransactionType.Electricity],
  [AliasType.Tariff, 'Tariff', TransactionType.Heatsteam],
  [AliasType.Tariff, 'Tariff', TransactionType.Hotelstays],
  [AliasType.Material, 'Material', TransactionType.Materials],
  [AliasType.Materialorigin, 'Origin', TransactionType.Materials],
  [AliasType.Counterparty, 'Supplier', TransactionType.Materials],
  [AliasType.Counterparty, 'Data Provider', TransactionType.Hotelstays],
  [AliasType.Counterparty, 'Supplier', TransactionType.Electricity],
  [AliasType.Counterparty, 'Supplier', TransactionType.Gas],
  [AliasType.Counterparty, 'Supplier', TransactionType.Heatsteam],
  [AliasType.Counterparty, 'Supplier', TransactionType.Water],
  [AliasType.Counterparty, 'Supplier', TransactionType.Fuel],
  [AliasType.Counterparty, 'Data Provider', TransactionType.Transport],
];

export const getAliasTypeByFieldName = (fieldName: Nullable<string>, subject: TransactionType): Nullable<AliasType> => {
  const fieldConfig = config.find(([, field, transactionType]) => field === fieldName && subject === transactionType);
  if (fieldConfig) {
    const [aliasType] = fieldConfig;
    return aliasType as AliasType;
  }
  return null;
};

export const getFieldsToAliasBySubject = (subject: TransactionType) => {
  return config.filter(([transactionType]) => subject && transactionType);
};
