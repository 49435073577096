import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading, Select, Modal, Button } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const OwnershipOptions = [
  { value: '1', label: 'Government' },
  { value: '2', label: 'Public (listed) – Property company' },
  { value: '3', label: 'Public (listed) – Real Estate Investment Trust (REIT)' },
  { value: '4', label: 'Private (non-listed)' }
];

const InvestmentOptions = [
  { value: '1', label: 'Core' },
  { value: '2', label: 'Value Added' },
  { value: '3', label: 'Opportunistic' },
  { value: '4', label: 'Debt' }
];

const EndOptions = [
  { value: '1', label: 'Open End' },
  { value: '2', label: 'Closed End' }
];

const VehicleOptions = [
  { value: '1', label: 'Club Deal' },
  { value: '2', label: 'Direct Investment' },
  { value: '3', label: 'Fund' },
  { value: '4', label: 'Joint Venture' },
  { value: '5', label: 'Separate Account' },
  { value: '6', label: 'Special Purpose Vehicle' }
];

const CommencementOptions = [
  { value: '1', label: '2020' },
  { value: '2', label: '2021' }
];

const ReportingOptions = [
  { value: '1', label: 'Calenadar Year' },
  { value: '2', label: 'Fiscal Year' }
];

const StartingOptions = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const CurrencyOptions = [
  { value: '1', label: 'GBP' },
  { value: '2', label: 'USD' },
  { value: '3', label: 'AED' },
  { value: '4', label: 'EUR' }
];

const AreaOptions = [
  { value: '1', label: 'Square Feet' },
  { value: '2', label: 'Square Meters' }
];

const LocationOptions = [
  { value: '1', label: 'United Kingdom' },
  { value: '2', label: 'United States' },
  { value: '3', label: 'Germany' },
  { value: '4', label: 'France' },
  { value: '5', label: 'China' }
];

const PropertyOptions = [
  { value: '1', label: 'Retail' },
  { value: '2', label: 'Office' },
  { value: '3', label: 'Industrial' },
  { value: '4', label: 'Residential' },
  { value: '5', label: 'Hotel' },
  { value: '6', label: 'Lodging' },
  { value: '7', label: 'Education' },
  { value: '8', label: 'Technology/Science' },
  { value: '9', label: 'Healthcare' },
  { value: '10', label: 'Mixed Use' },
  { value: '11', label: 'Diversified' },
  { value: '12', label: 'Other' }
];

const BusinessOptions = [
  { value: '1', label: 'Management of standing investments only' },
  {
    value: '2',
    label: 'Management of standing investments and development of new construction and major renovation projects'
  },
  { value: '3', label: 'Development of new construction and major renovation projects' }
];

const CharacteristicsModal = (props) => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    const value = e.target.value || e.target.select;
    setState({
      ...state,
      [e.target.name]: value
    });
  };

  return (
    <Modal size="md" show dismissable name="GRESBContainer__Modal" onDismiss={props.onDismiss}>
      <Container name="Characteristics-modal">
        <HeaderStyled name="heading" size="lg">
          Characteristics
        </HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Entity Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput onChange={handleChange} value={state.entityName || ''} name="entityName" box />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Nature of Ownership</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="ownership"
              onChange={handleChange}
              value={state.ownership || null}
              options={OwnershipOptions}
            />
          </ColStyled>
        </Row>
        {state.ownership === '4' && (
          <>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Investment Style</LabelStyled>
                </LabelContainerStyled>
                <Select
                  name="investmentStyle"
                  onChange={handleChange}
                  value={state.investmentStyle || null}
                  options={InvestmentOptions}
                />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Open / Closed End</LabelStyled>
                </LabelContainerStyled>
                <Select name="end" onChange={handleChange} value={state.end || null} options={EndOptions} />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Investment Vehicle</LabelStyled>
                </LabelContainerStyled>
                <Select name="vehicle" onChange={handleChange} value={state.vehicle || null} options={VehicleOptions} />
              </ColStyled>
            </Row>
          </>
        )}
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Entity Commencement Date</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="commencement"
              onChange={handleChange}
              value={state.commencement || null}
              options={CommencementOptions}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Reporting Period</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="reporting"
              onChange={handleChange}
              value={state.reporting || null}
              options={ReportingOptions}
            />
          </ColStyled>
        </Row>
        {state.reporting === '2' && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Starting Month</LabelStyled>
              </LabelContainerStyled>
              <Select
                name="starting"
                onChange={handleChange}
                value={state.starting || null}
                options={StartingOptions}
              />
            </ColStyled>
          </Row>
        )}
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Reporting Currency</LabelStyled>
            </LabelContainerStyled>
            <Select name="currency" onChange={handleChange} value={state.currency || null} options={CurrencyOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Gross Asset Value in millions (At end of period)</LabelStyled>
            </LabelContainerStyled>
            <TextInput onChange={handleChange} value={state.value || ''} name="value" box />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Floor Area Unit</LabelStyled>
            </LabelContainerStyled>
            <Select name="area" onChange={handleChange} value={state.area || null} options={AreaOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Portfolio Location</LabelStyled>
            </LabelContainerStyled>
            <Select name="location" onChange={handleChange} value={state.location || null} options={LocationOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Predominemnt Proprty Type</LabelStyled>
            </LabelContainerStyled>
            <Select name="property" onChange={handleChange} value={state.property || null} options={PropertyOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Entity&apos;s Core Business</LabelStyled>
            </LabelContainerStyled>
            <Select name="business" onChange={handleChange} value={state.business || null} options={BusinessOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item container>
            <Button color="success">Save</Button>
          </ColStyled>
        </Row>
      </Container>
    </Modal>
  );
};

export default CharacteristicsModal;
