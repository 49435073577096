import styled from 'styled-components';
import { Button, Icon, TextInput, Col } from 'rio-ui-components';

export const CardHolder = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: visible;
  background-color: #f8f8f8;

  > div {
    margin: 0 auto;
    max-width: 1200px;
    background-color: #f8f8f8;
    padding: 40px 0 60px;
  }

  h2 {
    font-size: 26px;
    margin-bottom: 40px;

    svg {
      margin-left: 25px;
    }
  }

  h4 {
    font-size: 20px;
  }

  .cart-line {
    border-bottom: 2px solid #ccc;
    background-color: rgba(204, 204, 204, 0.2);
    height: 60px;
    padding-left: 25px;

    > div {
      height: 100%;
    }
  }

  .cart-lines h4 {
    margin-bottom: 10px;
  }

  .cart-lines.cart-lines-learn {
    .cart-lines-title {
      color: #00baff;
    }

    .cart-line {
      border-bottom: 2px solid #00baff;
      background-color: #dff2f9;
    }

    .cart-line:first-child {
      border-top: 2px solid #00baff;
    }
  }

  .cart-lines.cart-lines-comply {
    .cart-lines-title {
      color: #00c8b5;
    }

    .cart-line {
      border-bottom: 2px solid #00c8b5;
      background-color: #f0f7f6;
    }

    .cart-line:first-child {
      border-top: 2px solid #00c8b5;
    }
  }
`;

export const BaseLine = styled.div`
  border-bottom: 2px solid #ccc;
  background-color: rgba(204, 204, 204, 0.2);
  height: 60px;
  padding-left: 25px;

  > div {
    height: 100%;
  }
`;

export const LineHolder = styled.div`
  margin-bottom: 50px;

  > div:first-child {
    border-top: 2px solid #ccc;
  }
`;

export const Title = styled.span`
  font-size: 18px;
`;
export const Price = styled.span`
  font-size: 18px;
`;
export const DeleteIcon = styled(Icon)`
  color: #ff909a;
  font-size: 20px;
  cursor: pointer;
`;

export const CartFooter = styled.div`
  margin-top: 50px;
`;

export const SubTotal = styled.span`
  font-size: 24px;
`;
export const Total = styled.span`
  font-size: 13px;
`;

export const PayButton = styled(Button)`
  margin: 0 auto;
  background-color: #00c8b5;
  border-color: #00c8b5;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  text-transform: capitalize;

  &:hover {
    background-color: #00c8b5;
    border-color: #00c8b5;
    opacity: 0.8;
  }
`;

export const CartIcon = styled(Icon)`
  font-size: 18px;
`;

export const PreviewText = styled.span`
  padding-left: 20px;
  font-size: 13px;
  color: #000;
  text-decoration: underline;
`;

export const EmptyCartMessage = styled.div`
  margin: 50px 0 20px;
  text-align: center;

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 18px;
  }

  a {
    color: rgba(59, 134, 255, 1);
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CounterPickerWrapper = styled.div`
  margin-right: 20px;
`;

export const AssignText = styled.div`
  margin-top: -25px;
  text-align: center;
  font-size: 20px;
`;

export const CenteredDiv = styled.div`
  margin: 0 auto;
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DiscountCodeColumn = styled(Col)`
  padding-right: 16px;
`;
export const DiscountCodeField = styled(TextInput)`
  border: 2px solid #00baff;
  border-radius: 5px 0 0 5px;
  & > div {
    border-radius: 5px;
    outline: none;
    border: none;
  }
  & input:focus,
  & input:active {
    background-color: #dff2f9;
  }
`;
export const VerifyButton = styled(Button)`
  background-color: #00baff;
  border-radius: 0 5px 5px 0;
  border: none;
  cursor: pointer;
`;
