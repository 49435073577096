import { Select } from 'rio-ui-components';
import { TransactionType } from '@rio/rio-types';
import { Nullable, SelectEvent, InputEvent, Option } from '../../types';
import { useMemo } from 'react';

export type SelectUtilityEvent = {
  entity: TransactionType;
} & SelectEvent;

export interface UtilityTypeSelectorProps {
  value: Nullable<string> | Nullable<Option>;
  error?: Nullable<string>;
  name?: string;
  onChange: (event: InputEvent, alternativeEvent: InputEvent) => void;
}

const utilityTypeToSelectOption = (): { value: string; label: string }[] => {
  return Object.entries(TransactionType).map(([key, value]) => ({
    value: value,
    label: key,
  }));
};

export function UtilityTypeSelector({ value, error, name, onChange }: UtilityTypeSelectorProps) {
  const defaultOptions = useMemo(() => {
    return utilityTypeToSelectOption();
  }, []);

  return (
    <Select
      name={name}
      placeholder="Select a utility type..."
      value={value}
      options={defaultOptions}
      error={error}
      onChange={onChange}
      classPrefix="utility-select"
    />
  );
}
