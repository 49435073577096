import { Scope } from '@rio/rio-types';
import { scopeOptions } from '../../../constants/scopes';

export const isRestrictedAccess = (accessLevel) => accessLevel === Scope.Tag || accessLevel === Scope.Location;

export const getRestrictedScopeOptions = (accessLevel) => {
  if (accessLevel === Scope.ACCOUNT) {
    return scopeOptions;
  }
  if (isRestrictedAccess(accessLevel)) {
    return scopeOptions.filter((option) => option.value !== Scope.Account);
  }
  return scopeOptions;
};

export const getRestrictedTagOptions = (currentUser) => {
  return currentUser.allowedTags.map(({ id, tagName }) => ({
    value: id,
    label: tagName,
    isFixed: true
  }));
};

export const getRestrictedLocationsOptions = (currentUser) => {
  return currentUser.allowedLocations.map(({ id, name }) => ({
    value: id,
    label: name,
    isFixed: true
  }));
};
