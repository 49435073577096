import React from 'react';
import { ExistingSurvey, useSurveyFormReducer } from '../reducer/useSurveyRecipientsReducer';
import { SurveyFormStateContext } from '../context/SurveyFormStateContext';
import { SurveyFormDispatchContext } from '../context/SurveyFormDispatchContext';

export type SurveyProviderProps = {
  survey?: ExistingSurvey;
  children: React.ReactNode;
};

export const SurveyFormProvider = ({ children, survey }: SurveyProviderProps) => {
  const [state, dispatch] = useSurveyFormReducer(survey);
  return (
    <SurveyFormStateContext.Provider value={state}>
      <SurveyFormDispatchContext.Provider value={dispatch}>{children}</SurveyFormDispatchContext.Provider>
    </SurveyFormStateContext.Provider>
  );
};
