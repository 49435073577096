import { GridReadyEvent, RowModelType } from 'ag-grid-community';
import styled from 'styled-components';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useCallback } from 'react';
import { mapFilterModel, mapSortModel } from '~/utils';
import { useGetAssetsPageByAccountId, useColumnDefs } from './hooks';

const ConfigurationAssetsGridContainer = styled.div`
  height: 100%;
`;

const config: AgGridReactProps = {
  pagination: true,
  paginationPageSize: 25,
  cacheBlockSize: 25,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

interface AssetsGridProp {
  accountId: string;
  onGridReady: (event: GridReadyEvent) => void;
}

export function AssetsGrid({ accountId, onGridReady }: AssetsGridProp) {
  const [getAssetsPageByAccountId] = useGetAssetsPageByAccountId();

  const columnDefs = useColumnDefs();

  const handleGridReady = useCallback(
    (gridReadyParams: GridReadyEvent) => {
      onGridReady(gridReadyParams);

      gridReadyParams.api.setFilterModel({
        deleted: {
          field: 'deleted',
          values: [JSON.stringify({ label: 'No', value: '0' })],
          filterType: 'set',
        },
      });

      gridReadyParams.api.setServerSideDatasource({
        async getRows(params) {
          try {
            const { startRow, sortModel, filterModel } = params.request;

            const response = await getAssetsPageByAccountId({
              variables: {
                accountId,
                offset: startRow || 0,
                limit: config.paginationPageSize,
                sort: mapSortModel(sortModel),
                filters: mapFilterModel(filterModel),
              },
            });
            if (!response.data?.getAssetsPageByAccountId) {
              throw new Error('Failed to fetch assets page');
            }
            const { rows, totalRows } = response.data?.getAssetsPageByAccountId;

            params.success({
              rowData: rows,
              rowCount: totalRows || 0,
            });
          } catch (err) {
            params.fail();
          }
        },
      });
    },
    [getAssetsPageByAccountId, accountId, onGridReady]
  );

  return (
    <ConfigurationAssetsGridContainer className="ag-theme-alpine">
      <AgGridReact {...config} columnDefs={columnDefs} onGridReady={handleGridReady} />
    </ConfigurationAssetsGridContainer>
  );
}
