import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

const columnDefs = [
  {
    field: 'country',
    headerName: 'Country',
  },
  {
    field: 'type',
    headerName: 'Property Type',
  },
  {
    field: 'assets',
    headerName: 'Assets',
  },
  {
    field: 'area',
    headerName: 'Floor Area',
  },
  {
    field: 'gav',
    headerName: '% GAV',
  },
];
const InvestmentsGrid = () => {
  const [rowData] = useState([
    { country: 'UK', type: 'Office', assets: 3, area: '120,000', gav: 30 },
    { country: 'Italy', type: 'Retail', assets: 6, area: '400,000', gav: 40 },
    { country: 'USA', type: 'Office', assets: 2, area: '50,000', gav: 25 },
    { country: 'France', type: 'Hotel', assets: 1, area: '100,000', gav: 70 },
    { country: 'China', type: 'Education', assets: 1, area: '75,000', gav: 30 },
    { country: 'Australia', type: 'Healthcare', assets: 1, area: '12,000', gav: 5 },
  ]);

  function onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 700 }}>
      <AgGridReact onGridReady={onGridReady} rowData={rowData} columnDefs={columnDefs} />
    </div>
  );
};

export default InvestmentsGrid;
