import { SurveyInput } from '@rio/rio-types';

import { Errors } from '../../../types';

type Invalid = [Errors<Omit<SurveyInput, 'ownerAccountId'>>, null];

type Valid = [null, Omit<SurveyInput, 'ownerAccountId'>];

export function validate(survey: Omit<SurveyInput, 'ownerAccountId'>): Valid | Invalid {
  const errors: Errors<SurveyInput> = {};

  if (!survey.name) {
    errors.name = 'Please provide a name';
  }

  if (!survey.surveyTemplateId) {
    errors.surveyTemplateId = 'Please select a survey';
  }

  if (!survey.startDate) {
    errors.startDate = 'Please select a start date';
  }

  const contributorList = survey.contributors || survey.individualContributors || [];

  if (contributorList.length === 0) {
    errors.contributors = 'Please select at least one contributor';
  }

  if ((survey?.notes?.length || 0) > 1000) {
    errors.notes = 'Notes can only be 1000 characters long';
  }

  if (Object.keys(errors).length) {
    return [errors, null];
  }

  return [null, survey];
}
