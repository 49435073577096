import { TransactionType } from '@rio/rio-types';
import UploadContainer from '../../UploadContainer';
import columns from './columns';
import { TemplateType } from '../../../../types';
import { dromoConfiguration } from '../../DromoConfiguration';

export function UploadFuelTransactions({ ...props }) {
  return (
    <UploadContainer
      {...props}
      dataSection={TransactionType.Fuel}
      dromoConfiguration={dromoConfiguration.fuel}
      template={{
        columns,
        fileName: 'fuel-template',
        type: TemplateType.Dynamic,
      }}
    />
  );
}
