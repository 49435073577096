import { gql } from '@apollo/client';

export default gql`
  fragment DocumentFields on DocumentRecord {
    id
    link
    source
    key
    fileName
    documentArea
    category
    type
    supplierId
    supplierName
    reviewDate
    reviewDateStatus
    library
    referenceId
    managementSystemCode
    managementSystemId
    managementSystemChapterId
    createdAt
    createdBy {
      id
      first_name
      last_name
    }
    createdByName
    updatedAt
    updatedBy {
      first_name
      last_name
    }
    updatedByName
    antivirusStatus
    tags {
      id
      tagName
    }
    notes
  }
`;
