import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Col, Search, Button, Notification, LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';

import { GET_ALL_TEMPLATES } from './index.queries';

import ContainerHeader from '../../../components/ContainerHeader';
import { LoadFailed } from '../../../components/Errors';
import { useSearch } from '../../../hooks/useSearch';
import NoResults from '../../../components/NoResults';
import TemplateRow from './TemplateRow';
import CreateTemplateModal from './CreateTemplateModal';
import UpdateTemplateModal from './UpdateTemplateModal';
import alphabetise from '../../../utils/alphabetise';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const ConfigurationOcrContainer = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [notification, setNotification] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_ALL_TEMPLATES);

  const [search, setSearch, searched] = useSearch(data?.getAllTemplates || [], ['name', 'parserId']);

  return (
    <Col name="ConfigurationOcrContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationOcrContainer__Controls"
        icon="tachometer-alt"
        iconColor="primary"
        title="OCR Templates"
      >
        <Col container item>
          <Search
            name="ConfigurationOcrContainer__Controls__Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            hideButton
          />
        </Col>
        <ButtonStyled
          name="ConfigurationOcrContainer__Controls__Button--create"
          size="md"
          color="primary"
          inline
          onClick={() => setShowCreateModal(true)}
        >
          + Add Template
        </ButtonStyled>
      </ContainerHeader>

      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}

      {error && <LoadFailed error={error} retry={refetch} />}

      {showCreateModal && (
        <CreateTemplateModal onComplete={setNotification} onDismiss={() => setShowCreateModal(false)} />
      )}
      {showUpdateModal && (
        <UpdateTemplateModal
          onComplete={setNotification}
          onDismiss={() => setShowUpdateModal(false)}
          selectedTemplate={selectedTemplate}
        />
      )}
      {notification && (
        <Notification
          name="Template__Notification"
          show
          color={notification.colour}
          onClose={() => setNotification(null)}
        >
          {notification.message}
        </Notification>
      )}
      {!loading && !error && !searched.length && (
        <NoResults name="ConfigurationDashboardsContainer__NoResults" title="There are no results for this search." />
      )}
      {!loading &&
        !!searched.length &&
        searched
          .slice()
          .sort((templateA, templateB) => alphabetise(templateA.name, templateB.name))
          .map((template) => (
            <TemplateRow
              key={template.id}
              template={template}
              onEdit={() => {
                setSelectedTemplate(template);
                setShowUpdateModal(true);
              }}
            />
          ))}
    </Col>
  );
};

export default ConfigurationOcrContainer;
