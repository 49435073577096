import { FormattedMessage, useIntl } from 'react-intl';
import { useResetPassword } from './useResetPassword';
import { Button, Logo, Text, TextField, styled } from '@rio/ui-components';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useTheme } from '@mui/material-next/styles';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 30rem;
  margin-top: 1rem;

  button {
    margin: auto;
  }
`;

const ErrorLabel = ({ error }: { error: string | null }) => {
  const theme = useTheme();

  if (!error) {
    return null;
  }
  return (
    <Text size="small" typescale="label" sx={{ color: theme.sys.color.error }}>
      {error}
    </Text>
  );
};

const LoginErrorLabel = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Text size="medium" typescale="body" sx={{ color: theme.sys.color.error, marginTop: '0.5rem' }}>
      {children}
    </Text>
  );
};

export const V2ResetPassword = () => {
  const location = useLocation();
  const params = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return {
      username: query.get('username') ?? '',
      code: query.get('code') ?? '',
    };
  }, [location]);
  const intl = useIntl();
  const { state, loading, handleChange, handleResetPassword, loginError } = useResetPassword(
    params.username,
    params.code
  );
  return (
    <Container>
      <Logo height="70px" style={{ marginTop: '1rem', marginBottom: '2rem', marginLeft: '1rem' }} />
      <Text size="large" typescale="title">
        <FormattedMessage id="login.resetPassword.title" />
      </Text>
      <Text size="large" typescale="label">
        <FormattedMessage id="login.resetPassword.subheading" />
      </Text>

      <Form name="reset-password__form" onSubmit={handleResetPassword}>
        <TextField
          name="username"
          error={!!state.username.error}
          placeholder={intl.formatMessage({ id: 'login.resetPassword.emailPlaceholder' })}
          value={state.username.value}
          onChange={handleChange}
        />
        <ErrorLabel error={state.username.error} />

        <TextField
          name="confirmationCode"
          error={!!state.confirmationCode.error}
          placeholder={intl.formatMessage({ id: 'login.resetPassword.codePlaceholder' })}
          value={state.confirmationCode.value}
          onChange={handleChange}
        />
        <ErrorLabel error={state.confirmationCode.error} />

        <TextField
          name="password1"
          error={!!state.password1.error}
          placeholder={intl.formatMessage({ id: 'login.resetPassword.passwordPlaceholder' })}
          type="password"
          value={state.password1.value}
          onChange={handleChange}
          autoComplete="new-password"
        />

        <ErrorLabel error={state.password1.error} />
        <TextField
          name="password2"
          error={!!state.password2.error}
          placeholder={intl.formatMessage({ id: 'login.resetPassword.confirmPasswordPlaceholder' })}
          type="password"
          value={state.password2.value}
          onChange={handleChange}
        />
        <ErrorLabel error={state.password2.error} />

        <Button
          disabled={loading}
          loading={loading}
          variant="filled"
          type="submit"
          name="reset-password-container__button"
        >
          <FormattedMessage
            id={loading ? 'login.resetPassword.buttonSubmitting' : 'login.resetPassword.submitButton'}
          />
        </Button>
      </Form>

      {loginError && (
        <LoginErrorLabel>
          <FormattedMessage id={loginError} />
        </LoginErrorLabel>
      )}
    </Container>
  );
};

export default V2ResetPassword;
