import React from 'react';
import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { OptionsProps, Select } from '@rio/ui-components';
import { GET_ALL_PROJECTS } from '../index.queries';

interface ProjectSelectProps {
  accountId: string;
  name: string;
  value: string;
  onChange: (selectedOption: { value: string; label: string }) => void;
  canBeEmpty?: boolean;
  disabled?: boolean;
}

const loadingOption = {
  label: 'Loading...',
  value: '',
  disabled: true,
};
const noResultsFoundOption = { label: 'No projects found', value: '', disabled: true };

export function ProjectSelect({ accountId, name, value, onChange, disabled }: ProjectSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getProjects'>>(GET_ALL_PROJECTS, {
    variables: {
      accountId,
    },
  });

  const projects = (data?.getProjects || []).map((p) => ({ value: p.id, label: p.name || '' })) as OptionsProps[];

  if (!loading && !error && projects.length === 0) {
    projects.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label="Project"
      value={value || ''}
      options={loading ? [loadingOption] : projects}
      error={Boolean(error)}
      helperText={error?.message}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
