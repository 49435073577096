import { mathRound } from '~/utils';

export const columnDefs = [
  {
    headerName: 'Scope',
    field: 'scope',
  },
  {
    headerName: 'Source',
    field: 'source',
  },
  {
    headerName: 'Gross carbon emissions',
    field: 'gross',
    aggFunc: 'sum',
    valueFormatter: ({ value }) => mathRound(value || 0),
  },
  {
    headerName: 'Net carbon emissions',
    field: 'net',
    aggFunc: 'sum',
    valueFormatter: ({ value }) => mathRound(value || 0),
  },
];
