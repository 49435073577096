import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

export const LinkRef = styled(({ ...props }) => <Link {...props} />)`
  cursor: ${(props) => (props.restricted ? 'default' : 'pointer')};
`;

const CustomRouteLink = (props) => {
  const location = useLocation();
  const { children, to = '/', base, activeClassName = 'active', ...rest } = props;
  const isActive = location.pathname.includes(base || to);
  return (
    <Link {...rest} to={to}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          ...rest,
          key: index,
          className: isActive ? activeClassName : ''
        });
      })}
    </Link>
  );
};

export default CustomRouteLink;
