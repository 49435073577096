import { useParams } from 'react-router-dom';
import { useGetAuditByEntityId, useCurrentAccountId } from '~/hooks';
import { ContributorAuditLogGridV2 } from '../ReceivedSurveyAuditLogContainer';
import { useGetMyContributor } from './queries';

export const MyContributorAuditLogContainerV2 = () => {
  const accountId = useCurrentAccountId();
  const { contributorId, surveyId } = useParams();
  const [contributorAccountId, contributorUserId] = contributorId!.split(':');

  const { data } = useGetMyContributor(accountId, contributorAccountId!, contributorUserId);
  const surveys = data?.getMySurveyContributorById?.surveys;
  const survey = surveys?.find((s) => s.id === surveyId);

  const { data: audit } = useGetAuditByEntityId(survey?.submission?.id);
  const auditList = audit?.getAuditByEntityId || [];

  return <ContributorAuditLogGridV2 rows={auditList} />;
};
