import { Link } from 'react-router-dom';
import { Text, Button, styled, Grid } from '@rio/ui-components';

export const TitleStyled = styled(Text)`
  padding: 24px 0;
`.withComponent('h2');

export const TextStyled = styled(Text)`
  padding: 0 0 24px;
`;
export const LinkToTerms = styled('a')`
  color: ${(props) => props.theme.sys.color.primary};
`;
export const TextLink = styled(Link)``;

export const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const FormStyled = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ErrorTextStyled = styled(Text)`
  padding: 16px 0;
  color: ${({ theme }) => theme.sys.color.error};
`;

export const GridStyled = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const AuthContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  max-width: 480px;
  width: 100%;
`;
