import { Query } from '@rio/rio-types';
import { FetchPolicy } from '@apollo/client';

import { useCurrentAccountId, usePageSuspendingQuery, useUserToken } from '~/hooks';

import { SEARCH_AVALIABLE_COURSES, SEARCH_AVALIABLE_COURSES_WITH_ONE_BUNDLE } from '../../Courses/index.queries';

type Response = Pick<Query, 'searchCourses' | 'getLearnBundlesForAccount' | 'getLearnBundleById'>;

interface Variables {
  accountId: string;
  pageNumber: number;
  searchTerm: string;
  userId: string;
  section: string;
  bundleId?: string | null;
}

export function useSearchCourses(
  searchTerm: string,
  bundleId?: string | null,
  shouldSkip = true,
  fetchPolicy?: FetchPolicy
) {
  const accountId = useCurrentAccountId();
  const userToken = useUserToken();

  return usePageSuspendingQuery<Response, Variables>(
    !bundleId ? SEARCH_AVALIABLE_COURSES : SEARCH_AVALIABLE_COURSES_WITH_ONE_BUNDLE,
    {
      variables: {
        searchTerm,
        accountId,
        pageNumber: 1,
        userId: userToken.token.sub,
        section: bundleId ?? '',
        bundleId,
      },
      skip: !searchTerm && shouldSkip,
      fetchPolicy,
    }
  );
}
