import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from '../ProgressChart';

interface Score {
  name: string;
  description: string;
  color: string;
}

interface ScoreChartProps {
  scores: Score[];
  currentScore?: {
    name: string;
    progress: number;
  };
}

interface ScoreRowProps {
  percentage: number;
  color: string;
}

const ScoreTable = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const ScoreRow = styled.div<ScoreRowProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.percentage}%;
  height: ${(props) => props.theme.geometry.md.spacing};
  background-color: ${(props) => props.color};
`;

const CurrentScoreBar = styled(ProgressBar)`
  position: absolute;
  z-index: 0;
`;

const ScoreSpan = styled.span`
  z-index: 1;
`;

export function ScoreChart({ scores, currentScore }: ScoreChartProps) {
  return (
    <ScoreTable>
      {scores.map((score: Score, i: number) => (
        <ScoreRow key={`ScoreChartRow_${score.name}`} percentage={(i + 1) * (100 / scores.length)} color={score.color}>
          {currentScore && currentScore.name === score.name && <CurrentScoreBar completed={currentScore.progress} />}
          <ScoreSpan>{score.name}</ScoreSpan>
          <ScoreSpan>{score.description}</ScoreSpan>
        </ScoreRow>
      ))}
    </ScoreTable>
  );
}
