import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Fuel', to: '/data/fuel/view' }, { label: 'Uploads' }];

export const V2FuelUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      gridKey="fuelUploadGridV2"
      title="Here's your upload history"
      breadcrumbs={breadcrumbs}
      uploadLink="../fuel/uploads"
      config={config}
      dataType={TransactionType.Fuel}
    />
  );
};
