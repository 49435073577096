import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useCurrentAccountId } from '~/hooks';
import { SurveyWorkSheetHeader } from '~/components/Surveys/v2';
import { getPathnameId, hasUUID } from '../../../../utils/surveyNavigation';
import { GET_RECEIVED_SURVEY_BY_ID } from '../queries';

export const ReceivedHeader = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathId = getPathnameId(pathname);

  const id = useMemo(() => hasUUID(pathname) && pathId, [pathId, pathname]);

  const accountId = useCurrentAccountId();
  const { data } = useQuery(GET_RECEIVED_SURVEY_BY_ID, {
    variables: {
      id,
      accountId,
    },
  });

  return data ? <SurveyWorkSheetHeader survey={data.getReceivedSurveyById} /> : null;
};
