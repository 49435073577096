import { Grid } from '@rio/ui-components';
import { ButtonStyled, ButtonsWrapper, ModalStyled } from './style';

export const DocumentDeleteModal = ({ showDeleteModal, setShowDeleteModal, onDelete }) => {
  return (
    <ModalStyled
      open={showDeleteModal}
      titleClassName="TitleClassName"
      title="Are you sure you want to delete this Document?"
      onClose={() => setShowDeleteModal(false)}
      grid={{ xs: 8, md: 6 }}
    >
      <ButtonsWrapper container>
        <Grid xs={5.5}>
          <ButtonStyled variant="filled" onClick={onDelete}>
            Yes
          </ButtonStyled>
        </Grid>
        <Grid xs={5.5}>
          <ButtonStyled variant="filled" onClick={() => setShowDeleteModal(false)}>
            No
          </ButtonStyled>
        </Grid>
      </ButtonsWrapper>
    </ModalStyled>
  );
};
