import { NamedEntity, TransactionType } from '@rio/rio-types';
import { v4 as uuid } from 'uuid';
import { LocationSelect } from '~/components/LocationSelect/v2';
import { CREATE_ENERGY_SOURCES } from '../index.queries';
import { EnergySourcesSelection } from './EnergySourcesSelection';
import { useMeterModalForm } from '../useMeterModalForm';
import { Button, Checkbox, Grid, TextField, DatePicker, styled, ToastColor, Text } from '@rio/ui-components';
import { useMutation } from '@apollo/client';
import { useCurrentAccountId } from '../../../../hooks';
import { useCallback, useState } from 'react';

export const getSuppliersUtility = (meterType) =>
  meterType === 'Heat & Steam' ? TransactionType.Electricity : meterType.toUpperCase();

export type CreateEnergyMeterProps = {
  onSuccess: (msg: string, newMeter: { id: string; name: string }) => void;
  onError: (msg: string, color: ToastColor) => void;
  energyType?: string;
};

const SubmitButton = styled(Button)`
  width: 100%;
`;

const DatePickersRow = styled('div')`
  width: 100%;
  display: flex;
  gap: 16px;
`;

const DatePickerContainer = styled('div')`
  width: 50%;
`;

const CreateEnergyMeter = ({ onError, onSuccess, energyType }: CreateEnergyMeterProps) => {
  const {
    state,
    changeMeterName,
    changeMeterType,
    changeLocation,
    isValidData,
    changeFieldValue,
    waterMeterType,
    toggleWaterMeterIn,
    toggleWaterMeterOut,
  } = useMeterModalForm();

  const [didEnergySourcesLoad, setDidEnergySourcesLoad] = useState(energyType ? false : true);

  const accountId = useCurrentAccountId();
  const handleEnergySourcesLoadComplete = useCallback(
    (energySources: NamedEntity[]) => {
      setDidEnergySourcesLoad(true);

      if (!energyType) {
        return;
      }

      const preSelectedEnergySource = energySources.find((es) => es.name.toLowerCase() === energyType.toLowerCase());

      if (!preSelectedEnergySource) {
        return;
      }

      changeMeterType({
        label: preSelectedEnergySource.name,
        value: preSelectedEnergySource,
      });
    },
    [energyType, changeMeterType]
  );

  const handleCreateEnergySourceClick = (createFunc) => {
    createFunc({
      variables: {
        energySources: [
          {
            id: uuid(),
            name: state.meterName.value,
            locationId: state.locationId.value,
            typeId: state.typeId.value.id,
            accountId,
            inOut: waterMeterType,
            isGeneration:
              state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam'
                ? state.isGeneration || false
                : null,
            meterCode: state.code,
            hardwareProviderName: state.hardwareProviderName,
            operatorProviderName: state.operatorProviderName,
            startDate: state.startDate,
            endDate: state.endDate,
          },
        ],
      },
    });
  };

  const handleCreateOnComplete = ({ createEnergySources }) => {
    const [newMeter] = createEnergySources;
    const msg = `The Meter ${newMeter.name} was created successfully`;
    onSuccess(msg, { name: newMeter.name, id: newMeter.id });
  };

  const handleCreateOnError = () => {
    onError('Something went wrong. If the problem persists, contact support.', 'danger');
  };

  const [createEnergySources, { called: createdEnergySources, loading: creatingEnergySources }] = useMutation(
    CREATE_ENERGY_SOURCES,
    {
      onCompleted: handleCreateOnComplete,
      onError: handleCreateOnError,
    }
  );

  const selectStartDate = useCallback(
    (value) => {
      changeFieldValue({
        fieldName: 'startDate',
        value,
      });
    },
    [changeFieldValue]
  );
  const selectEndDate = useCallback(
    (value) => {
      changeFieldValue({
        fieldName: 'endDate',
        value,
      });
    },
    [changeFieldValue]
  );

  return (
    <Grid container columns={12} rowGap={3} columnGap={2}>
      <Grid item xs={12}>
        <TextField
          label="What do you want to call this meter?"
          value={state.meterName.value || ''}
          error={Boolean(state.meterName.error)}
          helperText={state.meterName.error}
          onChange={(e) => changeMeterName({ value: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <EnergySourcesSelection
          label="What is the meter type?"
          value={state.typeId?.value?.id || ''}
          onChange={(e) => changeMeterType({ label: e.label, value: { id: e.value, name: e.label } })}
          error={state.typeId.error}
          disabled={Boolean(energyType)}
          onEnergySourceLoadComplete={energyType ? handleEnergySourcesLoadComplete : undefined}
        />
      </Grid>
      {state.typeId.value.name === 'Water' && (
        <>
          <Grid item xs={6}>
            <Checkbox
              name="in"
              id="create-meter-in"
              label={`${state.typeId.value.name} In`}
              onChange={() => toggleWaterMeterIn()}
              checked={state.meterIn}
              value={state.meterIn}
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              id="create-meter-out"
              name="out"
              label={`${state.typeId.value.name} Out`}
              onChange={() => toggleWaterMeterOut()}
              checked={state.meterOut}
              value={state.meterOut}
            />
          </Grid>
        </>
      )}
      {(state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam') && (
        <Grid item xs={12}>
          <Checkbox
            disabled={state.isGeneration}
            label="Renewable energy generating meter"
            id="is-generating-meter"
            name="isGeneration"
            onChange={() =>
              changeFieldValue({
                fieldName: 'isGeneration',
                value: !state.isGeneration,
              })
            }
            checked={state.isGeneration || false}
            value={state.isGeneration || false}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <LocationSelect
          label="Which location is this meter at?"
          value={state.locationId}
          onChange={changeLocation}
          error={state.locationId.error}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={state.code || ''}
          label="Meter code"
          onChange={(e) => changeFieldValue({ fieldName: 'code', value: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <Text typescale="body" size="medium">
          Has this meter closed?
        </Text>
        <DatePickersRow>
          <DatePickerContainer>
            <DatePicker
              name="startDate"
              label="Start date"
              onChange={(val) => selectStartDate(new Date(val))}
              value={state.startDate ? new Date(state.startDate) : undefined}
            />
          </DatePickerContainer>
          <DatePickerContainer>
            <DatePicker
              name="endDate"
              label="End date"
              onChange={(val) => selectEndDate(new Date(val))}
              value={state.endDate ? new Date(state.endDate) : undefined}
            />
          </DatePickerContainer>
        </DatePickersRow>
      </Grid>
      <Grid item xs={12}>
        <SubmitButton
          name="create-energyMeter__button--create"
          color="primary"
          variant="filled"
          disabled={!isValidData || createdEnergySources || !didEnergySourcesLoad}
          loading={creatingEnergySources}
          onClick={() => handleCreateEnergySourceClick(createEnergySources)}
        >
          Add meter
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

export default CreateEnergyMeter;
