import { gql } from '@apollo/client';

export const TASK_FIELDS = gql`
  fragment TaskFields on Task {
    id
    accountId
    author {
      id
      first_name
      last_name
      profilePicture
    }
    scope
    locations {
      id
      name
    }
    tags {
      id
      tagName
    }
    name
    description
    category
    subject
    item {
      ... on Legislation {
        id
        title
      }
      ... on Aspect {
        id
        activity {
          id
          name
        }
      }
      ... on Target {
        id
        name
      }
    }
    dueDate
    createdAt
    completedAt
    isCompleted
    owners {
      id
      first_name
      last_name
      profilePicture
    }
    priority
    projectId {
      id
      name
    }
  }
`;
