import * as utilityTypes from './utilityTypes';

export const dashboardsByUtilityType = {
  [utilityTypes.CARBON]: '/reporting/v2/c03a4b07-ce4b-48f4-96c9-957f481bb3f0/Carbon',
  [utilityTypes.ELECTRICITY]: '/reporting/v2/305b11dc-65da-40c5-b321-f41bd0569c5b/Electricity',
  [utilityTypes.GAS]: '/reporting/v2/8d98b676-c131-45ab-a8c7-c5bdf96366c2/Natural Gas',
  [utilityTypes.TRANSPORT]: '/reporting/v2/76bdc04f-58ee-4f3c-840f-64309b78d23c/Transport',
  [utilityTypes.WASTE]: '/reporting/v2/9c64c17b-21a0-4b8d-a8c4-e630d92e2880/Waste',
  [utilityTypes.WATER]: '/reporting/v2/69050da5-5851-4a23-ac84-012b4cf7d6e7/Water',
};
