import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CREATE_TAG, UPDATE_LOCATION_TAG } from './index.queries';
import { GET_LOCATIONS_BY_ACCOUNT } from '../ConfigurationLocationsContainer/index.queries';
import {
  Checkbox,
  Notification,
  PropertySheet,
  Row,
  Col,
  Label,
  ErrorMessage,
  InlineError,
  MultiSelect,
  LoadingIndicator
} from 'rio-ui-components';
import { v4 as uuidv4 } from 'uuid';
import { usePermissions } from '../../../hooks/usePermissions';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const OptionalSpan = styled.span`
  color: grey;
  font-style: italic;
`;

const InlineErrorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  padding-top: ${(props) => props.theme.geometry.md.spacing};
`;

const queryVariables = {
  offset: 0,
  limit: 10,
  filters: {},
  sort: [{ field: 'name', order: 'asc' }]
};

const CreateTag = ({ onSuccess, accountId }) => {
  const [state, setState] = useState({
    hasError: false,
    isSuccess: false,
    values: {
      tagName: { text: '', error: '' },
      isSystemLevelTag: { checked: false, error: '' },
      legislationTag: { checked: false, error: '' },
      description: '',
      locations: []
    },
    notification: { message: '', color: 'success', id: null }
  });
  const apolloClient = useApolloClient();
  const permissions = usePermissions();

  const onCreateError = () => {
    setState({
      ...state,
      notification: {
        show: true,
        message: `Something went wrong. If the problem persists, contact support.`,
        color: 'danger'
      },
      hasError: true,
      isSuccess: false,
      isSubmitted: false
    });
  };

  const onCreateComplete = async (data) => {
    const newTag = data.createTag;
    const locations = state.values.locations.map((item) => item.value);
    if (locations && locations.length > 0) {
      await apolloClient.mutate({
        mutation: UPDATE_LOCATION_TAG,
        variables: { id: newTag.id, locationsId: state.values.locations.map((item) => item.value), accountId }
      });
    }

    onSuccess(`The tag: ${newTag.tagName} has been created!`, 'success', newTag.id);
  };

  const [createTag] = useMutation(CREATE_TAG, {
    onError: onCreateError,
    onCompleted: onCreateComplete
  });

  const validateField = (value, name) => {
    return value === '' ? `${name} cannot be empty.` : null;
  };

  const hideNotification = () => {
    setState({ ...state, notification: { message: null, color: null } });
  };

  const handleChange = (e) => {
    setState({ ...state, values: { ...state.values, [e.target.name]: { text: e.target.value, error: null } } });
  };

  const handleCheckBoxChange = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [e.target.name]: {
            checked: !prevState.values[e.target.name].checked,
            error: null
          }
        }
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const v = state.values;
    v.tagName.error = validateField(v.tagName.text, 'Tag Name');

    if (v.tagName.error) {
      setState({ ...state, values: v });
      return;
    }
    const id = uuidv4();
    createTag({
      variables: {
        id: id,
        tagName: v.tagName.text.trim(),
        accountId: accountId,
        description: v.description.trim(),
        locationsId: state.values.locations.map((item) => item.value),
        isSystemLevelTag: state.values.isSystemLevelTag.checked,
        legislationTag: state.values.legislationTag.checked
      }
    });
    setState({
      ...state,
      isSubmitted: true
    });
  };

  const onTagChange = (event) => {
    const tagArray = event.target.select;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        locations: tagArray
      }
    }));
  };

  const SelectLoadingIndicator = () => {
    return <LoadingIndicator name="tag-location-Loading" size="xxs" />;
  };

  const { data, loading, error } = useQuery(GET_LOCATIONS_BY_ACCOUNT, { variables: { ...queryVariables, accountId } });
  const defaultLocations =
    data && data.getLocationsPage.rows
      ? data.getLocationsPage.rows.map((location) => ({ label: location.name, value: location.id }))
      : [];

  return (
    <PropertySheet id="CreateTagPropSheet">
      {state.notification.message && (
        <Notification name="create-tag__notification" show color={state.notification.color} onClose={hideNotification}>
          {state.notification.message}
        </Notification>
      )}
      <PropertySheet.HeaderRow>Create Tag</PropertySheet.HeaderRow>

      <form name="create-Tag__form">
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            name="tagName"
            disabled={state.isSubmitted}
            onChange={handleChange}
            value={state.values.tagName.text}
            error={state.values.tagName.error}
            label="Tag Name"
          />
        </PropertySheet.Row>

        <Row container align="between">
          <ColStyled container item span={12}>
            <LabelStyled id="create-location-tags-label" name="create-location-tags-label">
              Locations <OptionalSpan>(Optional)</OptionalSpan>
              {error ? (
                <ErrorMessage error={error}>
                  {({ icon }) => (
                    <InlineErrorContainer>
                      <InlineError name="location-tag__error" icon={icon} title="Locations failed to load" />
                    </InlineErrorContainer>
                  )}
                </ErrorMessage>
              ) : (
                <MultiSelect
                  id="create-location-tag-multiselection"
                  name="create-location-tag-multiselection"
                  pill={false}
                  isLoading={loading}
                  components={{ LoadingIndicator: SelectLoadingIndicator }}
                  onChange={onTagChange}
                  isDisabled={loading || error}
                  multiValueColor="tertiary"
                  defaultOptions={defaultLocations}
                  value={state.values.locations}
                  loadOptions={async (filterValue) => {
                    const {
                      data: { getLocationsPage }
                    } = await apolloClient.query({
                      query: GET_LOCATIONS_BY_ACCOUNT,
                      variables: {
                        ...queryVariables,
                        filters: {
                          text: [
                            {
                              filterType: 'text',
                              type: 'contains',
                              filter: filterValue,
                              field: 'name'
                            }
                          ]
                        },
                        accountId
                      }
                    });
                    const locations = getLocationsPage.rows
                      ? getLocationsPage.rows.map((location) => ({ label: location.name, value: location.id }))
                      : [];

                    return { options: locations, total: getLocationsPage.totalRows };
                  }}
                />
              )}
            </LabelStyled>
          </ColStyled>
        </Row>

        {permissions.tag.includes('createAll') && (
          <PropertySheet.Row align="center" distribution="between">
            <PropertySheet.Column label="System Level Tag">
              <Checkbox
                id="isSystemLevelCheck"
                onChange={handleCheckBoxChange}
                name="isSystemLevelTag"
                checked={state.values.isSystemLevelTag.checked}
              />
            </PropertySheet.Column>
            <PropertySheet.Column label="Create Legal Register">
              <Checkbox
                id="legislationCheck"
                onChange={handleCheckBoxChange}
                name="legislationTag"
                checked={state.values.legislationTag.checked}
              />
            </PropertySheet.Column>
          </PropertySheet.Row>
        )}
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn
            name="create-tag__Button"
            disabled={state.values.tagName.text === '' || state.isSubmitted}
            onClick={handleSubmit}
          >
            Create Tag
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </form>
    </PropertySheet>
  );
};
export default CreateTag;
