import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import statuses from './legislationStatuses';

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors[props.colour].normal.background};
`;

export const LegislationStatusIcon = ({ status }) => {
  if (!status) return <>TBD</>;
  const selectedStatus = statuses.find((item) => item.value === status);

  return <StyledIcon title={selectedStatus.label} icon={selectedStatus.icon} colour={selectedStatus.colour} />;
};
