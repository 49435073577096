/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentAccountId, useGetAuditByEntityId, GetAuditByEntityIdResponse } from '../../../hooks';
import { useGetReceivedSurveyById, GetReceivedSurveyByIdResponse } from '../ReceivedSurveyContainer/hooks';
import ContainerHeader from '../../../components/ContainerHeader';
import { Async } from '../../../components/Async';
import { Page } from '../SurveyAuditLogContainer/styles';
import { PageFailed } from '../../../components/Errors';
import { ContributorAuditLogGrid } from './ReceivedSurveyAuditLogGrid';
import { SurveysPlaceholder } from '../../../components/SurveysPlaceholder';

const defaultBreadcrumbs = [{ to: '../received', title: 'Surveys received' }];

const Placeholder = () => (
  <SurveysPlaceholder
    title="You don't have any logged responses"
    description="Responses will be logged soon after you submit your answers to this survey"
    icon={['rio', 'clipboard-check-custom']}
  />
);
export function ReceivedSurveyAuditLogContainer() {
  const { surveyId } = useParams();
  const accountId = useCurrentAccountId();

  const surveyData = useGetReceivedSurveyById(surveyId!, accountId);
  const audit = useGetAuditByEntityId(surveyData.data?.getReceivedSurveyById?.submission?.id);

  const breadcrumbs = useMemo(() => {
    if (surveyData.data?.getReceivedSurveyById) {
      const survey = surveyData.data?.getReceivedSurveyById;
      return [...defaultBreadcrumbs, { to: `../received/${survey.id}`, title: survey.name }, { title: 'Response log' }];
    }
    return defaultBreadcrumbs;
  }, [surveyData]);

  const renderError = useCallback((error: Error) => {
    return error.message.toLowerCase().includes('not found') ? <Placeholder /> : <PageFailed error={error} />;
  }, []);

  return (
    <Page>
      <Async<GetReceivedSurveyByIdResponse>
        result={surveyData}
        data={useCallback(
          ({ getReceivedSurveyById }) => (
            <>
              <ContainerHeader
                name="SurveyContributorAuditLog"
                icon="clipboard-check"
                iconColor="quaternary"
                title="Response log"
                breadcrumb={breadcrumbs}
              />
              {getReceivedSurveyById?.submission ? (
                <Async<GetAuditByEntityIdResponse>
                  result={audit}
                  data={({ getAuditByEntityId }) => <ContributorAuditLogGrid rows={getAuditByEntityId} />}
                />
              ) : (
                <Placeholder />
              )}
            </>
          ),
          [audit, breadcrumbs]
        )}
        error={renderError}
      />
    </Page>
  );
}
