import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Label, TextInput } from 'rio-ui-components';
import { Button, LoginError } from '../../components/AuthPage';
import { Form } from './Form';

const LabelContainer = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  align-items: center;
`;
const ColStyled = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.xs.spacing};
`;

export function ContactsForm({ onSubmit, error, form, onChange, intl }) {
  return (
    <Form
      onChange={onChange}
      onSubmit={onSubmit}
      form={form}
      validate={() => ({})}
      errors={{}}
      setErrors={() => {}}
      name="contacts"
    >
      <Row container>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.contactPage.jobTitle' })}</Label>
          </LabelContainer>
          <TextInput type="text" value={form.jobTitle} autoComplete="job" name="jobTitle" />
        </ColStyled>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.contactPage.department' })}</Label>
          </LabelContainer>
          <TextInput type="text" value={form.department} autoComplete="department" name="department" />
        </ColStyled>
      </Row>
      <Row container>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.contactPage.phoneNumber' })}</Label>
          </LabelContainer>
          <TextInput type="phone" value={form.phone} autoComplete="tel" name="phone" />
        </ColStyled>
        <ColStyled item span={6}>
          <LabelContainer>
            <Label>{intl.formatMessage({ id: 'login.registration.contactPage.mobileNumber' })}</Label>
          </LabelContainer>
          <TextInput type="phone" value={form.mobile} autoComplete="tel" name="mobile" />
        </ColStyled>
      </Row>
      <LoginError color="danger" size="sm" weight="bold">
        {error}
      </LoginError>
      <Row container>
        <ColStyled item span={6}>
          <Link to="/register/profile">
            <Button type="button" color="info">
              {intl.formatMessage({ id: 'login.registration.contactPage.backButtonText' })}
            </Button>
          </Link>
        </ColStyled>
        <ColStyled item span={6}>
          <Button type="submit">{intl.formatMessage({ id: 'login.registration.contactPage.finishButtonText' })}</Button>
        </ColStyled>
      </Row>
    </Form>
  );
}
