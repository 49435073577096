import React from 'react';
import styled from 'styled-components';
import { Icon, Text } from 'rio-ui-components';
import { FormattedMessage } from 'react-intl';
import { COMPLETE, INPROGRESS } from '../../../constants/courseProgress';

const CompletedIconStyled = styled(Icon)`
  margin-left: auto;
  padding-left: ${({ theme }) => theme.geometry.sm.spacing};
  color: ${({ theme }) => theme.colors.success.normal.background};
`;
const InProgressIconStyled = styled(Icon)`
  margin-left: auto;
  padding-left: ${({ theme }) => theme.geometry.sm.spacing};
  color: ${({ theme }) => theme.colors.primary.normal.background};
`;

const statusText = {
  PDF: {
    NOTSTARTED: '',
    COMPLETE: 'You have downloaded this file.'
  },
  EMBED: {
    NOTSTARTED: '',
    INPROGRESS: 'pages.coursesPreview.statuses.embed.inprogress',
    COMPLETE: 'You have completed this course.'
  },
  EXTERNAL: {},
  IFRAME: {},
  DOWNLOAD: {}
};

const CourseStatus = ({ courseProgress, handleType }) => {
  const text = statusText[handleType][courseProgress];
  return (
    <>
      {courseProgress === COMPLETE && <CompletedIconStyled icon="check" size="lg" />}
      {courseProgress === INPROGRESS && <InProgressIconStyled icon="clock" size="lg" />}
      <Text color="light" size="sm">
        {text && <FormattedMessage id={text} />}
      </Text>
    </>
  );
};

export default CourseStatus;
