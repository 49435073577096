import { Checkbox, Row, Button, PropertySheet } from 'rio-ui-components';
import { useEffect } from 'react';
import { DataFormRowsFillingStrategy, DateFrequency, DataFormInputStrategy } from '../../constants';
import { Controller } from 'react-hook-form';
import { capitalize, isNil } from 'lodash';
import {
  ColStyled,
  Container,
  ButtonContainer,
  LabelStyled,
  LabelContainerStyled,
  DatePicker,
  TimePicker,
  SelectStyled,
  HeaderStyled,
  CenteredCol,
  SubHeader,
  CenteredErrorText,
} from './DataFormStyles';

const getErrorMessage = (errors) => {
  if (errors.uploadReference?.type === 'maxLength') {
    return 'Upload reference max length is 80 characters';
  }

  if (errors.data) {
    return 'Please fill all mandatory fields';
  }
};

export function DataForm(props) {
  const { form, onSubmit = () => {}, children, showEndDate, showSubmitButton = true, showTime } = props;

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    formState: { isValid, errors },
  } = form || {};

  const errMsg = getErrorMessage(errors);

  // TODO: remove or refactor when will be implemented proper ref forwarding in rio-ui-components
  useEffect(() => {
    register('dataFormInputStrategy');
    register('dataFormRowsFillingStrategy');
    register('data.currency');
    register('startDate', { required: true });
    register('endDate');
    register('startTime');
    register('endTime');
  }, [register]);

  const [
    dataFormRowsFillingStrategyValue,
    dataFormInputStrategyValue,
    startDate,
    endDate,
    startTime,
    endTime,
    dateFrequencyValue,
  ] = watch([
    'dataFormRowsFillingStrategy',
    'dataFormInputStrategy',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'dateFrequency',
  ]);

  const markAsSingle = () => setValue('dataFormInputStrategy', DataFormInputStrategy.Single);
  const markAsAggregated = () => setValue('dataFormInputStrategy', DataFormInputStrategy.Aggregated);
  const markFillingStrategyAsDistributed = () =>
    setValue('dataFormRowsFillingStrategy', DataFormRowsFillingStrategy.Distributed);
  const markFillingStrategyAsReplicated = () =>
    setValue('dataFormRowsFillingStrategy', DataFormRowsFillingStrategy.Replicated);
  const selectStartDate = (value) => setValue('startDate', value);
  const selectEndDate = (value) => setValue('endDate', value);
  const selectedStartTime = (value) => setValue('startTime', value);
  const selectedEndTime = (value) => setValue('endTime', value);

  const isSingle = dataFormInputStrategyValue === DataFormInputStrategy.Single;
  const isAggregated = dataFormInputStrategyValue === DataFormInputStrategy.Aggregated;
  const isRowsFillingStrategyDistributed = dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed;
  const isDefault = !isSingle && !isAggregated;
  const isRowsFillingStrategyReplicated = dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Replicated;
  const isRowsFillingStrategyVisible = !isNil(dataFormInputStrategyValue) || isDefault;
  const isDateFrequencyVisible = isRowsFillingStrategyDistributed;
  const isDateRangeVisible = isRowsFillingStrategyReplicated || !isNil(dateFrequencyValue);
  const isStartDateNotEmpty = !isNil(startDate) && startDate !== '';
  const isDataFormVisible =
    ((isRowsFillingStrategyDistributed && !isNil(dateFrequencyValue) && isStartDateNotEmpty) ||
      (isRowsFillingStrategyReplicated && startDate)) &&
    (!showEndDate || endDate);
  const isSecondDatePickerVisible =
    dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed || showEndDate;
  const isErrorVisible = !isValid && isDataFormVisible;
  const frequencyDropdownOptions = Object.entries(DateFrequency).map(([, value]) => ({
    label: capitalize(value),
    value,
  }));

  if (isDefault) setValue('dataFormInputStrategy', DataFormInputStrategy.Single);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PropertySheet>
        <Container>
          <HeaderStyled>Direct Data Upload</HeaderStyled>

          <Row container align="between">
            <SubHeader>Is this data entry for a single transaction or for aggregated set of data?</SubHeader>
          </Row>

          <Row container align="between">
            <CenteredCol item span={6}>
              <Checkbox
                size="md"
                id="isSingle"
                checked={isSingle || isDefault}
                value={DataFormInputStrategy.Single}
                onChange={markAsSingle}
              />

              <LabelContainerStyled>
                <LabelStyled labelFor="isSingle">
                  Single <br />{' '}
                  <small>
                    (i.e. single commuter journey, <br /> regular waste collection <br /> or meter reading)
                  </small>
                </LabelStyled>
              </LabelContainerStyled>
            </CenteredCol>

            <CenteredCol item span={6}>
              <Checkbox
                size="md"
                id="isAggregated"
                name="Aggregated__Container"
                checked={isAggregated}
                value={DataFormInputStrategy.Aggregated}
                onChange={markAsAggregated}
              />

              <LabelContainerStyled>
                <LabelStyled labelFor="isAggregated">
                  Aggregated data <br />{' '}
                  <small>
                    (i.e annual waste tonnage, <br />
                    quarterly energy bill)
                  </small>
                </LabelStyled>
              </LabelContainerStyled>
            </CenteredCol>
          </Row>

          {isRowsFillingStrategyVisible && (
            <>
              <Row container align="between">
                <SubHeader>
                  Do you want this data entry to be {isSingle || isDefault ? 'replicated' : 'distributed'} in your
                  database and reports?
                </SubHeader>
              </Row>

              <Row container align="between">
                <CenteredCol item span={6}>
                  <Checkbox
                    size="md"
                    id="isReplicated"
                    checked={isRowsFillingStrategyDistributed}
                    onChange={markFillingStrategyAsDistributed}
                  />

                  <LabelContainerStyled>
                    <LabelStyled labelFor="isReplicated">Yes</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>

                <CenteredCol item span={6}>
                  <Checkbox
                    size="md"
                    id="isNotReplicated"
                    checked={isRowsFillingStrategyReplicated}
                    onChange={markFillingStrategyAsReplicated}
                  />

                  <LabelContainerStyled>
                    <LabelStyled labelFor="isNotReplicated">No</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>
              </Row>
            </>
          )}

          {isDateFrequencyVisible && (
            <>
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Frequency of data to be {isSingle ? 'replicated' : 'distributed'}:</LabelStyled>
                  </LabelContainerStyled>
                </ColStyled>
              </Row>

              <Row container align="between">
                <ColStyled item>
                  <Controller
                    name="dateFrequency"
                    render={({ field }) => (
                      <SelectStyled
                        {...field}
                        onChange={({ target: { value } } = {}) => field.onChange(value)}
                        options={frequencyDropdownOptions}
                      />
                    )}
                    control={control}
                  />
                </ColStyled>
              </Row>
            </>
          )}

          {isDateRangeVisible && (
            <>
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Start date</LabelStyled>
                  </LabelContainerStyled>
                  <DatePicker
                    onDayClick={selectStartDate}
                    name="startDate"
                    selectedDate={startDate}
                    disabledDatesAfter={endDate}
                    box
                  />
                </ColStyled>
              </Row>
              {showTime && (
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>Start time (optional)</LabelStyled>
                    </LabelContainerStyled>
                    <TimePicker
                      onTimeChange={selectedStartTime}
                      name="startTime"
                      selectedTime={startTime || null}
                      box
                    />
                  </ColStyled>
                </Row>
              )}

              {isSecondDatePickerVisible && (
                <>
                  <Row container align="between">
                    <ColStyled item>
                      <LabelContainerStyled>
                        <LabelStyled>End date</LabelStyled>
                      </LabelContainerStyled>
                      <DatePicker
                        onDayClick={selectEndDate}
                        name="endDate"
                        selectedDate={endDate}
                        disabledDatesBefore={startDate}
                        box
                      />
                    </ColStyled>
                  </Row>
                  {showTime && (
                    <Row container align="between">
                      <ColStyled item>
                        <LabelContainerStyled>
                          <LabelStyled>End time (optional)</LabelStyled>
                        </LabelContainerStyled>
                        <TimePicker onTimeChange={selectedEndTime} name="endTime" selectedTime={endTime || null} box />
                      </ColStyled>
                    </Row>
                  )}
                </>
              )}
            </>
          )}

          {isDataFormVisible && children}

          {isErrorVisible && <CenteredErrorText>{errMsg}</CenteredErrorText>}

          {isDataFormVisible && showSubmitButton && (
            <ButtonContainer>
              <Button type="submit" inline>
                Confirm data entry
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </PropertySheet>
    </form>
  );
}
