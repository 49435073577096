import { gql } from '@apollo/client';

export const GET_GICS_CODES = gql`
  query getGicsCodes {
    getGicsCodes {
      id
      name
      code
    }
  }
`;
