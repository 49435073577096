import { useQuery } from '@apollo/client';
import GET_USER_INFO_BY_ACCOUNT_ID from '../../graphql/queries/user/GetUsersInfoByAccountId.query.graphql';

export function useGetUsersInfoByAccountIdPagedQuery(accountId) {
  const {
    data: { getUserInfoByAccountId = {} } = {},
    loading,
    error
  } = useQuery(GET_USER_INFO_BY_ACCOUNT_ID, {
    variables: {
      limit: 1000,
      accountId
    },
    skip: !accountId
  });

  return {
    data: {
      ...getUserInfoByAccountId,
      content: getUserInfoByAccountId.users || []
    },
    loading,
    error
  };
}
