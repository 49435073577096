import DromoUploader from 'dromo-uploader-react';
import { GridApi } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import chunk from 'lodash/chunk';
import { useMutation } from '@apollo/client';
import { useAccountById, useNotification, useCurrentUser } from '~/hooks';
import { getFullName, prepareDromoResultsToGraphql } from '~/utils';
import { helpTextHtml } from '~/containers/DataContainer/DromoConfiguration/utils';
import { baseDromoConfiguration } from '../DromoConfiguration';
import { useDromoFields } from './useDromoFields';
import { UPSERT_LOCATIONS } from './index.queries';
import excelTemplateUrl from './locations-template.xlsx';
import { useValidateLocationIds } from './useValidateLocationIds';
import { getEnvVar } from '../../../env';

const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), excelTemplateUrl].join('');

interface LocationUploaderProps {
  accountId: string;
  gridApi: GridApi;
}

const UPLOAD_CHUNK_SIZE = 1000;

export function LocationUploader({ accountId, gridApi }: LocationUploaderProps) {
  const account = useAccountById(accountId);
  const validateLocationIds = useValidateLocationIds();
  const currentUser = useCurrentUser();
  const { showNotification } = useNotification();

  const [upsertLocations] = useMutation(UPSERT_LOCATIONS);

  const fields = useDromoFields(accountId);

  const handleResults = useCallback(
    async (results) => {
      try {
        const preparedResults = results.map(prepareDromoResultsToGraphql);
        await Promise.all(
          chunk(preparedResults, UPLOAD_CHUNK_SIZE).map(async (locations) =>
            upsertLocations({
              variables: {
                locations,
                accountId,
              },
            })
          )
        );

        gridApi.refreshServerSide({
          purge: true,
        });
        showNotification('The locations have been uploaded!', 'success');
      } catch (error) {
        showNotification(
          'Something unexpected happened. Please review your upload document and try again. If the problem persists, please contact support.',
          'danger'
        );
      }
    },
    [accountId, gridApi, showNotification, upsertLocations]
  );

  const user = useMemo(
    () =>
      account && currentUser
        ? {
            id: currentUser.id,
            name: getFullName(currentUser),
            email: currentUser.email,
            companyId: account.id,
            companyName: account.name,
          }
        : null,
    [account, currentUser]
  );

  if (!user) {
    return null;
  }

  return (
    <DromoUploader
      licenseKey={getEnvVar('REACT_APP_DROMO_LICENSE_KEY')!}
      user={user}
      style={baseDromoConfiguration.style}
      settings={{
        ...baseDromoConfiguration.settings,
        importIdentifier: 'Locations',
        uploadStep: {
          ...baseDromoConfiguration.settings.uploadStep,
          helpText: helpTextHtml(templateUrl),
        },
      }}
      columnHooks={[
        {
          fieldName: 'id',
          callback: validateLocationIds,
        },
      ]}
      fields={fields}
      onResults={handleResults}
    >
      Upload
    </DromoUploader>
  );
}
