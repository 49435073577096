import React, { useState } from 'react';
import {
  AssignText,
  CardHolder,
  CartFooter,
  CartIcon,
  CenteredDiv,
  EmptyCartMessage,
  PayButton,
  SubTotal,
  Total,
  DiscountCodeColumn
} from './CartStyles';
import { CartLines } from './CartLines';
import { LoadingIndicator, Modal, Button, Row, Col } from 'rio-ui-components';
import { Link, useNavigate } from 'react-router-dom';
import { marketProductType } from '../../constants';
import { useCurrentAccountId, useGetAccountInfoByIdQuery, useGetProductsQuery } from '../../hooks';
import styled from 'styled-components';
import { useGetAccountProductLicencesCountQuery } from '../../hooks/licence';
import { Container } from '../DataFormModal';
import { CountPicker } from '../UI/CountPicker';
import { DiscountCode } from './DiscountCode';

const Loader = styled(LoadingIndicator)`
  margin: 0 auto;
  position: relative;
  top: 70px;
`;

const formatDiscount = (discount, fixedDiscount, currency) =>
  discount.type === 'percentage'
    ? `${discount.discount}% (-${currency}${fixedDiscount})`
    : `${currency}${discount.discount}`;

export function Cart({ cart }) {
  const {
    state: {
      data: lineList,
      isCartEmpty,
      subTotal,
      newSubTotal,
      total,
      discount,
      discountFixed,
      currency: { symbol }
    },
    addProduct,
    removeProduct,
    removeLine,
    setDiscount
  } = cart;
  const navigate = useNavigate();
  const accountId = useCurrentAccountId();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { data: { capacity: accountCurrentCapacity = 1 } = {}, loading: isAccountFetching } =
    useGetAccountInfoByIdQuery(accountId);
  const { data: licences, loading: isLicencesCountFetching } = useGetAccountProductLicencesCountQuery(accountId);
  const {
    data: { content = {} },
    loading: isProductsLoading
  } = useGetProductsQuery();
  const accountCapacityLines =
    lineList.filter(
      ([
        ,
        {
          product: { productType }
        }
      ]) => productType === marketProductType.ACCOUNT_CAPACITY
    ) || [];
  const learnLines = lineList.filter(
    ([
      ,
      {
        product: { productType }
      }
    ]) => productType === marketProductType.COURSE || productType === marketProductType.COURSE_BUNDLE
  );
  const complyLines = lineList.filter(
    ([
      ,
      {
        product: { productType }
      }
    ]) => productType === marketProductType.MANAGEMENT_SYSTEM
  );
  const subscriptionLines = lineList.filter(
    ([
      ,
      {
        product: { productType }
      }
    ]) => productType === marketProductType.SUBSCRIPTION || productType === marketProductType.ACCOUNT_CAPACITY
  );

  const forwardToCheckout = () => navigate('/upgrade/checkout');
  const closePopup = () => setPopupVisible(false);

  const isLearnLinesAvailable = learnLines.length > 0;
  const isComplyLinesAvailable = complyLines.length > 0;
  const isSubscriptionLinesAvailable = subscriptionLines.length > 0;
  const accountCapacityProduct = content[marketProductType.ACCOUNT_CAPACITY][0] || {};
  const isLoading = isLicencesCountFetching || isProductsLoading || isAccountFetching;
  const additionalAccountUserCapacity = {
    ...accountCapacityProduct,
    title: 'Additional users'
  };
  const userCapacityQuantity = new Map(lineList).get('accountCapacity_id')?.quantity || 0;

  const addAdditionalAccountUserCapacity = () => addProduct(additionalAccountUserCapacity, 1, null, true);
  const removeAdditionalAccountUserCapacity = (value) => {
    value < 1 ? removeLine(additionalAccountUserCapacity.id) : removeProduct(additionalAccountUserCapacity);
  };

  const upgrade = () => {
    let mostLicences = 0;
    let isEngageInCart = false;

    const accountLine = accountCapacityLines[0] ? accountCapacityLines[0][1] : {};
    const totalUsers = (accountLine?.quantity || 0) + accountCurrentCapacity;
    /**
     * licences = [{productId: "d86dbf83-31d3-427e-ada5-34f3d1acd91a", count: [{status: "NEW", count: 3]}]
     * totalLicences = ["d86dbf83-31d3-427e-ada5-34f3d1acd91a", 5]
     */
    const totalLicences = new Map(
      licences.map(({ productId, count }) => {
        const totalLicences = count.reduce((acc, { count }) => acc + count, 0);

        return [productId, totalLicences];
      })
    );

    learnLines.forEach(([productId, { quantity }]) => {
      const value = totalLicences.has(productId) ? totalLicences.get(productId) + quantity : quantity;

      if (value > mostLicences) {
        mostLicences = value;
      }

      if (quantity > 0) {
        isEngageInCart = true;
      }
    });

    if (totalUsers < mostLicences && isEngageInCart) {
      forwardToCheckout();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CardHolder>
      <div>
        {isCartEmpty && (
          <EmptyCartMessage>
            <h2>
              Your Basket is empty
              <CartIcon icon="cart-arrow-down" />
            </h2>
            <p>
              View courses in the <Link to="/upgrade/courses">Market section</Link>
            </p>
          </EmptyCartMessage>
        )}

        {!isCartEmpty && (
          <>
            <h2>
              Your Basket <CartIcon icon="cart-arrow-down" />
            </h2>
            <div>
              {isSubscriptionLinesAvailable && <CartLines title="Subscription" cart={cart} lines={subscriptionLines} />}

              {isLearnLinesAvailable && (
                <>
                  <CartLines title="Learn Items" cart={cart} styles="learn" lines={learnLines} />
                </>
              )}

              {isComplyLinesAvailable && (
                <CartLines title="Governance Items" cart={cart} styles="comply" lines={complyLines} />
              )}
            </div>

            <CartFooter>
              <Row container itemAlign="center" distribution="between">
                <Col span={6}>
                  <SubTotal>
                    Subtotal: {symbol}
                    {subTotal}
                  </SubTotal>
                  <br />
                  {discount && (
                    <>
                      <Total name="Cart-Discount">Discount: {formatDiscount(discount, discountFixed, symbol)}</Total>
                      <br />
                      <Total>
                        New subtotal: {symbol}
                        {newSubTotal}
                      </Total>
                      <br />
                    </>
                  )}
                  <Total>
                    Total including VAT: {symbol}
                    {total}
                  </Total>
                </Col>
                <DiscountCodeColumn span={3}>
                  <DiscountCode defaultValue={discount?.code} applyDiscount={setDiscount} />
                </DiscountCodeColumn>
                <Col span={3}>
                  <PayButton name="Cart-Pay-Button" onClick={upgrade}>
                    Buy Now
                  </PayButton>
                </Col>
              </Row>
            </CartFooter>
          </>
        )}
      </div>
    </CardHolder>
  );
}
