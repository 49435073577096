import React, { useRef } from 'react';
import styled from 'styled-components';
import { Col, Button, Row } from 'rio-ui-components';
import { Query } from '@apollo/client/react/components';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { useCurrentAccountId } from '../../../hooks';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import PageHeader from '../../../components/PageHeader';
import { LoadFailed, NotAvailable } from '../../../components/Errors';
import { QuicksightFrame } from '../../../components/QuicksightFrame/quicksightFrame';
import { rearrangeFilterParameters } from '../../../utils/utils';
import { GET_QUICKSIGHT_DASHBOARD } from './index.queries';
import { defaultDashboardParams } from './defaultDashboardParams';

const ContainerStyled = styled(Col)`
  height: 100%;
  overflow: hidden;
`;

const DashboardContainer = styled(Col)`
  position: relative;
`;

const QuicksightFrameStyled = styled(QuicksightFrame)`
  height: 100%;
`;

const HeaderButton = styled(Button)`
  white-space: nowrap;
  margin-right: ${(props) => props.mr};
`;

const ImageContainer = styled.div`
  height: 80vh;
  overflow: scroll;
`;

const StaticDashboard = styled.img`
  max-width: 100%;
`;

const QuicksightReport = () => {
  const { id: dashboardId, name } = useParams();
  const accountId = useCurrentAccountId();
  const dashboardInstance = useRef();
  const initialFilters = useRef();
  const idToken = localStorage.getItem('ID_TOKEN');
  let variables;
  if (idToken) {
    const { sub } = jwt_decode(idToken);
    variables = {
      username: sub,
      dashboardId,
      accountId,
    };
  }

  const onResetFilters = () => {
    if (dashboardInstance.current) {
      if (dashboardInstance.current.setParameters) {
        dashboardInstance.current.setParameters({
          ...(defaultDashboardParams[dashboardId] || initialFilters.current),
          timestamp: Date.now(), // It's needed for event to be unique and to avoid de-deduplication
        });
      }
    }
  };

  return (
    <ContainerStyled container>
      <PageHeader
        name="ReportingReport"
        title={`Report - ${name}`}
        breadcrumbs={[{ title: 'Reporting', to: '/reporting' }, { title: 'Report' }]}
        icon="chart-line"
        iconColor="quaternary"
      >
        <Row container distribution="between">
          <HeaderButton name="Reset_filters" inline mr={'20px'} color="tertiary" onClick={onResetFilters}>
            Reset Filters
          </HeaderButton>
          <HeaderButton name="ReportingReport__Button-back" inline color="info" component="routerLink" to="/reporting">
            Back to Reports
          </HeaderButton>
        </Row>
      </PageHeader>
      {(function () {
        if (name === 'CleanScore') {
          // CleanScore dashboard mock
          return (
            <ImageContainer>
              <StaticDashboard src="https://dashboard-thumbnails-dev.s3.eu-west-1.amazonaws.com/clean-score.png" />
            </ImageContainer>
          );
        }

        return (
          <DashboardContainer item>
            <Query query={GET_QUICKSIGHT_DASHBOARD} variables={variables} fetchPolicy="network-only">
              {({ data, loading, error, refetch }) => {
                if (loading) return <ContainerLoadingIndicator name="ReportingReport__Loading" />;
                if (error) {
                  const isForbidden = error.graphQLErrors && error.graphQLErrors[0].message === 'Forbidden';
                  const errorComponentName = 'ReportingReports__error';

                  return isForbidden ? (
                    <NotAvailable name={errorComponentName} error={error} />
                  ) : (
                    <LoadFailed name={errorComponentName} retry={refetch} error={error} />
                  );
                }

                return (
                  <QuicksightFrameStyled
                    id="quicksightFrame"
                    name="quicksightFrame"
                    dashboardurl={data.getQuicksightDashboard}
                    accountId={accountId}
                    key={accountId}
                    onDashboardInit={(dashboard) => {
                      dashboardInstance.current = dashboard;
                      dashboardInstance.current.on('load', () => {
                        dashboardInstance.current.getActiveParameterValues((val) => {
                          initialFilters.current = rearrangeFilterParameters(val);
                        });
                      });
                    }}
                  />
                );
              }}
            </Query>
          </DashboardContainer>
        );
      })()}
    </ContainerStyled>
  );
};

export default QuicksightReport;
