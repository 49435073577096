import { useCallback, useState, useEffect } from 'react';
import { Model } from 'survey-core';
import styled from 'styled-components';
import { Button, Icon } from 'rio-ui-components';

type Props = {
  model: Model | null;
};

const PageButtonsContainer = styled.div<{ isFirstPage: boolean; isLastPage: boolean }>`
  display: flex;
  justify-content: ${(props) => {
    if (props.isFirstPage) {
      return 'flex-end';
    }
    if (props.isLastPage) {
      return 'flex-start';
    }
    return 'space-between';
  }};
  padding: 0 25px;
`;

const ChevronStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurveyPagination = ({ model }: Props) => {
  const [page, setPage] = useState(model?.currentPageNo || 0);
  const isFirstPage = page === 0;
  const isLastPage = page === (model?.pageCount || 0) - 1;

  useEffect(() => {
    setPage(model?.currentPageNo || 0);
  }, [model?.currentPageNo]);

  const isMultiPage = (model?.pageCount || 0) > 1;

  const onNextPageClick = useCallback(() => {
    if (model?.nextPage()) {
      setPage((_page) => _page + 1);
    }
  }, [model]);

  const onPrevPageClick = useCallback(() => {
    if (model?.prevPage()) {
      setPage((_page) => _page - 1);
    }
  }, [model]);

  if (!isMultiPage || !model) {
    return null;
  }

  return (
    <PageButtonsContainer isFirstPage={isFirstPage} isLastPage={isLastPage}>
      {!isFirstPage && (
        <ChevronStyled onClick={onPrevPageClick} color="tertiary" size="md" outline inline>
          <Icon icon="chevron-left" />
        </ChevronStyled>
      )}
      {!isLastPage && (
        <ChevronStyled onClick={onNextPageClick} color="tertiary" size="md" outline inline>
          <Icon icon="chevron-right" />
        </ChevronStyled>
      )}
    </PageButtonsContainer>
  );
};

export default SurveyPagination;
