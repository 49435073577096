import React from 'react';
import { Row, Col, Heading, ProfilePicture, Checkbox, Text, Button } from 'rio-ui-components';
import { orderBy, keyBy } from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { makeUrl } from '../../../utils';
import { usePermissions, useRoutes } from '../../../hooks';
import { LicencesCount } from '../../../components/AccountProductLicence';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const SeparateLine = styled.div`
  height: 3px;
  background-color: rgb(109, 108, 122);
  flex-direction: row;
  flex: 0 0 auto;
`;

const CustomCol = styled(Col)`
  min-width: ${(props) => props.minWidth};
  text-align: center;
  & p {
    text-align: center;
  }
  & div {
    display: inline-block;
  }
`;

export const PurpleWrapper = styled.div`
  & input:checked + div {
    border-color: ${(props) => props.theme.colors.secondary.light.text} !important;
    background-color: ${(props) => props.theme.colors.secondary.light.text};
  }
`;

const BundleRows = ({
  accountId,
  bundle,
  getAllLearnSubscriptions,
  onClickBundle,
  onClickMandatory,
  licencesCountMap = new Map(),
}) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const routes = useRoutes();

  const bundleCopy = orderBy(bundle, [(item) => item.title.toLowerCase()], 'asc');

  const { subscriptions, bundleShow, bundleHidden, mandatoryBundleId } = getAllLearnSubscriptions;

  const bundleShowObj = keyBy(bundleShow);
  const bundleHiddenObj = keyBy(bundleHidden);

  const onClickBundleShow = (event) => {
    onClickBundle({
      type: 'bundleShow',
      courseId: event.target.name,
      subscriptions,
      bundleShow,
      bundleHidden,
      mandatoryBundleId,
    });
  };

  const onClickBundleHidden = (event) => {
    onClickBundle({
      type: 'bundleHidden',
      courseId: event.target.name,
      subscriptions,
      bundleShow,
      bundleHidden,
      mandatoryBundleId,
    });
  };

  const clickMandatory = (event) => {
    onClickMandatory({
      courseId: event.target.name,
      subscriptions,
      bundleShow,
      bundleHidden,
      mandatoryBundleId: mandatoryBundleId,
    });
  };

  const isManageLicenceVisible = permissions.licenceService.assignLicence;

  return bundleCopy && bundleCopy.length ? (
    <>
      <SubscriptionRowStyled name={`account-row--${accountId}`} container itemAlign="center">
        <Col container item></Col>
        <CustomCol minWidth="100px">
          <Text color="dark" weight="bold">
            MANDATORY
          </Text>
        </CustomCol>
        <CustomCol minWidth="85px">
          <Text color="dark" weight="bold">
            HIDE
          </Text>
        </CustomCol>
        <CustomCol minWidth="50px">
          <Text color="dark" weight="bold">
            SHOW
          </Text>
        </CustomCol>
      </SubscriptionRowStyled>
      {bundleCopy.map((element) => {
        return (
          <SubscriptionRowStyled key={element.id} name={`account-row--${accountId}`} container itemAlign="center">
            <SubscriptionRowPictureStyled
              name={`account-row__profile-picture--${accountId}`}
              size="xs"
              accountName="B"
              color="secondary"
            />

            <Col container item>
              <Heading inline name={`account-row__name--${accountId}`} size="md">
                {element.title}
              </Heading>
            </Col>

            {isManageLicenceVisible && (
              <CustomCol minWidth="150px">
                <Button
                  onClick={() => {
                    const url = makeUrl(
                      `/${routes.configuration.root}/${routes.configuration.menu.learn.assignLicense}`,
                      {
                        productId: element.id,
                        productType: 'bundle',
                        accountId,
                      }
                    );
                    navigate(url);
                  }}
                  size="sm"
                >
                  <LicencesCount id={element.id} licencesCountMap={licencesCountMap} />
                </Button>
              </CustomCol>
            )}

            <CustomCol minWidth="85px">
              <PurpleWrapper>
                <Checkbox
                  id={`update-subscription-${element.id}`}
                  name={element.id}
                  onChange={clickMandatory}
                  checked={mandatoryBundleId === element.id}
                  disabled={mandatoryBundleId && mandatoryBundleId !== element.id}
                />
              </PurpleWrapper>
            </CustomCol>

            <CustomCol minWidth="85px">
              <PurpleWrapper>
                <Checkbox
                  id={`update-subscription-${element.id}`}
                  name={element.id}
                  onChange={onClickBundleHidden}
                  checked={bundleHiddenObj.hasOwnProperty(element.id)}
                />
              </PurpleWrapper>
            </CustomCol>

            <CustomCol minWidth="50px">
              <PurpleWrapper>
                <Checkbox
                  id={`update-subscription-${element.id}`}
                  name={element.id}
                  onChange={onClickBundleShow}
                  checked={bundleShowObj.hasOwnProperty(element.id)}
                  color="purple"
                />
              </PurpleWrapper>
            </CustomCol>
          </SubscriptionRowStyled>
        );
      })}
      <SeparateLine />
    </>
  ) : null;
};

export default BundleRows;
