export function toSentenceCase(str: string) {
  // Split the string into an array of sentences
  const words = str.split(/[\s\/]/);

  // Iterate through each sentence
  for (let i = 0; i < words.length; i++) {
    // Trim the sentence and get the first character
    const word = words[i];

    // Check if the first character is alphabetic
    if (word.match(/^[A-Z][a-z]+/)) {
      // Convert the first character to uppercase and concatenate it with the rest of the sentence
      words[i] = words[i].toLowerCase();
    }
  }

  // Join the sentences back into a single string
  const allWords = words.join(' ').trim();
  return allWords.charAt(0)?.toUpperCase() + allWords.slice(1);
}
