import { useQuery } from "@apollo/client";
import React, { FC, useMemo } from 'react';
import { Tag, Query } from '@rio/rio-types';
import { MultiSelect } from 'rio-ui-components';
import { GET_TAGS_BY_ACCOUNT_ID } from './index.queries';
import { sortBy } from 'lodash';
import { Option, Nullable, MultiSelectEvent } from '../../types';

function tagToSelectOptions(tag: Tag) {
  return {
    value: tag.id,
    label: tag.tagName
  };
}

interface TagsMultiSelectProps {
  accountId: string;
  value: Nullable<Option[]>;
  onChange: (event: MultiSelectEvent) => void;
  error?: Nullable<Error | string>;
  isSubmitting?: boolean;
  name?: string;
  defaultOptions?: Option[];
  disabled?: boolean;
}

const TagsMultiSelect: FC<TagsMultiSelectProps> = ({
  accountId,
  value,
  onChange,
  error,
  isSubmitting,
  name = 'tagIds',
  defaultOptions,
  disabled
}) => {
  const { data, loading } = useQuery<Query>(GET_TAGS_BY_ACCOUNT_ID, {
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });
  // eslint-disable-next-line
  const tags: Tag[] = (data?.getTagsByAccountId as Tag[]) || [];
  const options = useMemo(
    () => sortBy(tags.map(tagToSelectOptions), (item) => item?.label.trim().toLowerCase()),
    [tags]
  );

  return (
    <MultiSelect
      name={name}
      isLoading={loading}
      isDisabled={loading || isSubmitting || disabled}
      onChange={onChange}
      multiValueColor="tertiary"
      value={value}
      error={error}
      defaultOptions={defaultOptions}
      options={options}
    />
  );
};

export { TagsMultiSelect };
