import { DataType } from '@rio/rio-types';
import { HEAT_AND_STEAM_TYPE_ID, ELECTRICITY_TYPE_ID } from '../../constants/common';

export const getSetFilterModel = (locations: string[], isGenerationMeters?: boolean) => {
  const set: any[] = [];
  if (Array.isArray(locations) && locations.length) {
    set.push({
      filterType: DataType.Set,
      field: 'locationPointId',
      values: locations,
    });
  }
  if (isGenerationMeters) {
    set.push(
      {
        filterType: DataType.Set,
        field: 'typeId',
        values: [HEAT_AND_STEAM_TYPE_ID, ELECTRICITY_TYPE_ID],
      },
      {
        filterType: DataType.Set,
        field: 'isGeneration',
        values: ['1'],
      }
    );
  }
  return set.length ? { set } : {};
};
