import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  CircleIcon,
  Label,
  Col,
  Row,
  Modal,
  Heading,
  TextInput,
  DatePicker,
  Button,
  InputFile
} from 'rio-ui-components';
import { isPoliciesDocument, validatePolicy } from './utils';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const Form = styled.form`
  padding: 32px;
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonColStyled = styled(ColStyled)`
  flex-basis: 50%;
`;
const ExpiryDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -200px;
    left: 50%;
  }
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;
const FileName = styled(Col)`
  text-align: left;
  align-items: center;
  flex-flow: row nowrap;
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const NoDocumentsContainer = styled(Row)`
  color: ${(props) => (props.error ? props.theme.colors.danger.normal.background : 'initial')};
  text-align: left;
`;
const DocumentFileButtonColumn = styled(ColStyled)`
  flex-basis: 120px;
  max-width: 120px;
`;

function File({ file, onDelete, readOnly }) {
  const isFile = file instanceof window.File;
  const isDocument = isPoliciesDocument(file);
  return (
    <Row container>
      <ColStyled grow="0">
        <CircleIcon size="xxs" icon="file" color="tretiary" />
      </ColStyled>
      <FileName grow="1" item container>
        {isFile && file.name}
        {isDocument && file.fileName}
      </FileName>
      {isDocument && (
        <DocumentFileButtonColumn item grow="0">
          <Link to={`/documents/governance/Policies/${file.id}`}>
            <Button type="button" color="secondary">
              View
            </Button>
          </Link>
        </DocumentFileButtonColumn>
      )}
      {!readOnly && (
        <DocumentFileButtonColumn item grow="0">
          <Button type="button" color="danger" onClick={onDelete}>
            Remove
          </Button>
        </DocumentFileButtonColumn>
      )}
    </Row>
  );
}

export function DetailsModal({
  show,
  title,
  submitTitle,
  onDismiss,
  onChange,
  onSubmit,
  policy,
  isSubmitting,
  readOnly
}) {
  const [errors, setErrors] = useState({});
  const updateErrors = (p) => {
    if (Object.keys(errors).length) {
      setErrors(validatePolicy(p));
    } else {
      setErrors({});
    }
  };
  return (
    !!show && (
      <Modal
        size="md"
        height="auto"
        maxHeight="90vh"
        onDismiss={() => {
          onDismiss();
        }}
        dismissable
        show
      >
        <Form>
          <HeaderStyled>{title}</HeaderStyled>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Policy Title</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={isSubmitting}
                onChange={({ target: { value: title } }) => {
                  const updatedPolicy = { ...policy, title };
                  updateErrors(updatedPolicy);
                  onChange(updatedPolicy);
                }}
                name="title"
                value={policy.title}
                error={errors.title}
                readOnly={readOnly}
                box
              />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Review Date</LabelStyled>
              </LabelContainerStyled>
              <ExpiryDatePicker
                onDayClick={(reviewDate) => {
                  onChange({
                    ...policy,
                    reviewDate: reviewDate.toISOString()
                  });
                }}
                name="reviewDate"
                placeholder="YYYY/MM/DD"
                selectedDate={policy.reviewDate ? new Date(policy.reviewDate) : null}
                disabledDatesBefore={policy.createdAt ? new Date(policy.createdAt) : null}
                error={errors.reviewDate}
                readOnly={readOnly}
                disabled={isSubmitting || readOnly}
                box
              />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Version</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                name="version"
                disabled={isSubmitting}
                onChange={(e) => {
                  onChange({
                    ...policy,
                    version: e.target.value
                  });
                }}
                value={policy.version}
                readOnly={readOnly}
              />
            </ColStyled>
          </Row>
          <Row container>
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Attachments</LabelStyled>
              </LabelContainerStyled>
              {policy.documents && policy.documents.length ? (
                policy.documents.map((document) => (
                  <File
                    file={document}
                    key={document instanceof window.File ? document.name : document.fileName}
                    onDelete={() => {
                      onChange({
                        ...policy,
                        documents: policy.documents.filter((doc) => doc !== document)
                      });
                    }}
                    readOnly={readOnly}
                  />
                ))
              ) : (
                <NoDocumentsContainer error={errors.documents} container>
                  {errors.documents || 'No attachments yet'}
                </NoDocumentsContainer>
              )}
            </ColStyled>
          </Row>
          {!readOnly && (
            <Row container>
              <ButtonColStyled item grow="1">
                <InputFile
                  multiple
                  onChange={async (files) => {
                    const documents = policy.documents.concat(files);
                    const updatedPolicy = {
                      ...policy,
                      documents
                    };
                    updateErrors(updatedPolicy);
                    onChange(updatedPolicy);
                  }}
                >
                  {(pickFile) => (
                    <ButtonStyled
                      type="button"
                      color="tertiary"
                      disabled={isSubmitting}
                      onClick={() => {
                        pickFile();
                      }}
                    >
                      Attach Documents
                    </ButtonStyled>
                  )}
                </InputFile>
              </ButtonColStyled>
              <ButtonColStyled item grow="1">
                <ButtonStyled
                  disabled={isSubmitting}
                  onClick={(e) => {
                    e.preventDefault();
                    const errors = validatePolicy(policy);
                    if (Object.keys(errors).length) {
                      setErrors(errors);
                    } else {
                      onSubmit(policy);
                    }
                  }}
                >
                  {submitTitle}
                </ButtonStyled>
              </ButtonColStyled>
            </Row>
          )}
        </Form>
      </Modal>
    )
  );
}
