import { useApolloClient } from '@apollo/client';
import { DataImportBatch, Query, QueryGetDataImportBatchImportFileUrlArgs } from '@rio/rio-types';

import { downloadFileFromUrl } from '../../../../utils';
import { GET_DATA_BATCH_IMPORT_FILE_URL } from '../../index.queries';
import { TextLink } from '../../CellRenderers/elements';

type GetDataImportBatchImportFileUrl = Pick<Query, 'getDataImportBatchImportFileUrl'>;

export type ImportFileCellProps = {
  data: DataImportBatch;
};

const ImportFileCell = ({ data }: ImportFileCellProps) => {
  const client = useApolloClient();
  const onExportClick = async (id: string) => {
    const {
      data: { getDataImportBatchImportFileUrl: url },
    } = await client.query<GetDataImportBatchImportFileUrl, QueryGetDataImportBatchImportFileUrlArgs>({
      query: GET_DATA_BATCH_IMPORT_FILE_URL,
      variables: { id },
    });

    if (url) {
      downloadFileFromUrl(url);
    }
  };

  return data.fileName ? <TextLink onClick={() => onExportClick(data.id)}>{data.fileName}</TextLink> : null;
};

export { ImportFileCell };
export default ImportFileCell;
