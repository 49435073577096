import { useCallback, useMemo, useState } from 'react';
import { DataImportBatch, DataType, TransactionType } from '@rio/rio-types';
import {
  Page,
  Button,
  setAgGridLicenseKey,
  usePageErrorContext,
  usePageLoadingContext,
  Text,
  useTheme,
  Icons,
  Modal,
  styled,
  IconButton,
} from '@rio/ui-components';
import { Check, PendingActions, Undo, ErrorOutlined, AccessTimeOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { GridOptions, RowModelType, Column, GridReadyEvent } from 'ag-grid-community';
import { DataFormModal } from '~/components/DataFormModal/v2';
import { mapSortModel, mapFilterModel, toSentenceCase } from '~/utils';
import { useAgGrid, useCurrentAccountId, useNotification, usePermissions } from '~/hooks';
import { AgGridStyled, ButtonGrid } from './style';
import { useLocation } from 'react-router-dom';
import { V2DromoUploaderButton } from '~/containers/DataContainer/DataOverview/DromoUploader/V2DromoUploadButton';
import { TranspositionCellProps } from '~/containers/DataContainer/UploadContainer/GridCell/TranspositionCell';
import { RollbackCellProps } from '~/containers/DataContainer/UploadContainer/GridCell/RollbackCell';
import { RollbackCell } from './Cells/RollbackCell';
import { useV2UploadGrid } from './useV2UploadGrid';
import { QuickViewModal } from '~/containers/DataContainer/UploadContainer/QuickViewModal';
import { useApolloClient, useMutation } from '@apollo/client';
import { DELETE_BATCH, GET_BATCHES_PAGE, ROLLBACK_BATCH } from '~/containers/DataContainer/index.queries';
import ImportFileCell, { ImportFileCellProps } from './Cells/ImportFileCell';
import { BatchStatus } from '~/types';
import { BatchErrors } from '~/containers/DataContainer/V2/BatchErrors';
import { StatusCell, StatusCellProps, StatusProps } from './Cells/StatusCell';
import { SummaryCell, SummaryCellProps } from './Cells/SummaryCell';
import { ConfirmActionModal } from '../ConfirmAction/v2';
import { getEnvVar } from '../../env';
import { useInterval } from '~/hooks/useInterval';

const StyledButton = styled(Button)`
  height: 40px;
`;
const TooltipInner = styled('span')`
  height: fit-content;
`;

setAgGridLicenseKey(getEnvVar('REACT_APP_AG_GRID_LICENSE_KEY'));

const defaultConfig: GridOptions = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: [10, 25, 50, 100],
  cacheBlockSize: 25,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

type CrumbDefinition = {
  label: string;
  to?: string;
};

export type UploadGridProps = {
  dataType: TransactionType;
  breadcrumbs: CrumbDefinition[];
  title: string;
  config?: GridOptions;
  uploadLink?: string;
  gridKey: string;
};

const defineStatusProps = (
  status: BatchStatus,
  theme: ReturnType<typeof useTheme>,
  legacy: boolean,
  onDataClick: StatusProps['onClick'],
  onErrorClick: StatusProps['onClick'],
  onActionClick: StatusProps['onClick']
): StatusProps => {
  switch (status) {
    case BatchStatus.DONE:
      return {
        icon: 'check',
        iconColor: theme.palette.success.main,
        linkText: legacy ? 'Migrated' : 'Export Data',
        onClick: onDataClick,
      };
    case BatchStatus.PENDING:
      return {
        icon: 'clock',
        iconColor: theme.palette.grey[400],
        linkText: 'In Progress',
      };
    case BatchStatus.ABANDONED:
      return {
        icon: 'undo',
        iconColor: theme.palette.info.main,
        linkText: 'Rolled Back',
      };
    case BatchStatus.FAILED:
      return {
        icon: 'minus-circle',
        iconColor: theme.palette.error.main,
        linkText: `View Errors`,
        onClick: onErrorClick,
      };
    case BatchStatus.ACTION_REQUIRED:
      return {
        icon: 'exclamation-circle',
        iconColor: theme.palette.warning.main,
        linkText: 'Action Required',
        onClick: onActionClick,
      };

    default:
      return {
        icon: '',
        iconColor: '',
        linkText: '',
      };
  }
};

const checkPermissions = (permissions, action, isOwnResource) =>
  !!permissions.data.find((a) => a.startsWith(action) && !a.endsWith('Own')) ||
  (permissions.data.includes(`${action}Own`) && isOwnResource);

const textLinkRequired = (status: BatchStatus, legacy: boolean) =>
  (status === BatchStatus.DONE || status === BatchStatus.FAILED || status === BatchStatus.ACTION_REQUIRED) && !legacy;

const getReadOnlyStatusText = (status: BatchStatus, defaultText: string) => {
  switch (status) {
    case BatchStatus.DONE:
      return 'Success';
    case BatchStatus.FAILED:
      return 'Error';
    default:
      return defaultText;
  }
};

const BatchStatusLink = (
  status: BatchStatus,
  legacy: boolean,
  onErrorClick: () => void,
  onDataClick: () => void,
  onActionClick: () => void,
  statusProps: StatusProps,
  download: boolean,
  permissions: object,
  isOwnResource: boolean,
  isTextLink: boolean
) => {
  const allowedToExport = checkPermissions(permissions, 'exportImportBatch', isOwnResource);
  const allowedToEdit = checkPermissions(permissions, 'editImportBatch', isOwnResource);
  const showExport = status === BatchStatus.DONE && allowedToExport;
  const showActionRequired = status === BatchStatus.ACTION_REQUIRED && allowedToEdit;
  const showError = status === BatchStatus.FAILED;

  if (isTextLink || (textLinkRequired(status, legacy) && (showExport || showActionRequired || showError))) {
    return (
      <Text
        size="medium"
        typescale="body"
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => {
          switch (status) {
            case BatchStatus.FAILED:
              return onErrorClick();
            case BatchStatus.DONE:
              return onDataClick();
            case BatchStatus.ACTION_REQUIRED:
              return onActionClick();
          }
        }}
      >
        {download ? 'Downloading' : statusProps?.linkText}
      </Text>
    );
  }

  return (
    <Text size="medium" typescale="body">
      {getReadOnlyStatusText(status, statusProps?.linkText)}
    </Text>
  );
};

export const UploadGridV2 = ({ gridKey, title, breadcrumbs, config, uploadLink, dataType }: UploadGridProps) => {
  const {
    confirmDeleteId,
    confirmQuickViewId,
    confirmRollbackId,
    handleRollbackClick,
    handleDeleteClick,
    handleQuickviewClick,
    erroredRowId,
    handleErrorClick,
    handleDownloadClick,
    handleActionClick,
    fetchRows,
  } = useV2UploadGrid(dataType);
  const [batches, setBatches] = useState<DataImportBatch[]>([]);
  const [isDataFormModal, setIsDataFormModal] = useState(false);
  const accountId = useCurrentAccountId();
  const agGrid = useAgGrid({ autoFit: true });
  const theme = useTheme();
  const permissions = usePermissions();

  const allUploadsAvailable = [
    TransactionType.Waste,
    TransactionType.Electricity,
    TransactionType.Gas,
    TransactionType.Transport,
    TransactionType.Water,
    TransactionType.Heatsteam,
    TransactionType.Hotelstays,
  ];
  const directUploadsAvailable = [];

  const isDirectUploadsAvailable = [...allUploadsAvailable, ...directUploadsAvailable].includes(dataType);

  const showDirectDataUploadModal = () => setIsDataFormModal(true);

  const gridConfig = useMemo(
    () => ({
      ...defaultConfig,
      ...config,
      components: {
        fileName: (props: ImportFileCellProps) => <ImportFileCell {...props} />,
        status: (props: StatusCellProps) => (
          <StatusCell
            {...props}
            done={
              <Check
                onClick={() => handleDownloadClick(props.data.id)}
                sx={{ color: theme.palette.success.main, cursor: 'pointer' }}
              />
            }
            actionRequired={
              <PendingActions
                onClick={() => handleActionClick(props.data.id)}
                sx={{ color: theme.palette.warning.main, cursor: 'pointer' }}
              />
            }
            abandoned={<Undo sx={{ color: theme.palette.info.main }} />}
            failed={
              <ErrorOutlined
                onClick={() => handleErrorClick(props.data.id)}
                sx={{ color: theme.palette.error.main, cursor: 'pointer' }}
              />
            }
            pending={<AccessTimeOutlined sx={{ color: theme.palette.grey[400] }} />}
            onErrorClick={handleErrorClick}
            onDataClick={handleDownloadClick}
            onActionClick={handleActionClick}
            defineStatusProps={defineStatusProps}
            statusLink={BatchStatusLink}
          />
        ),
        transposition: (props: TranspositionCellProps) => {
          const transposition = props.data?.transposition?.transpositionName;
          return <>{transposition ? transposition : 'Default Template'}</>;
        },
        rollback: (props: RollbackCellProps) => <RollbackCell onClick={handleRollbackClick} {...props} />,
        summary: (props: SummaryCellProps) => (
          <SummaryCell {...props} onDeleteClick={handleDeleteClick} onQuickViewClick={handleQuickviewClick} />
        ),
      },
      columnDefs: config?.columnDefs?.map((d) => ({
        ...d,
        headerName: d.headerName ? toSentenceCase(d.headerName) : d.headerName,
      })),
    }),
    [
      config,
      handleDeleteClick,
      handleQuickviewClick,
      handleDownloadClick,
      handleActionClick,
      handleRollbackClick,
      handleErrorClick,
      theme,
    ]
  );

  const resetFilters = useCallback(() => {
    if (agGrid.api && agGrid.columnApi) {
      agGrid.api.setFilterModel(null);
      agGrid?.columnApi?.getColumns()?.forEach((column: Column) => {
        agGrid?.columnApi?.setColumnVisible(column?.getColId(), true);
      });
    }
  }, [agGrid.api, agGrid.columnApi]);

  const { setLoading } = usePageLoadingContext();
  const { setError } = usePageErrorContext();

  const gridOptions = useMemo(
    () => ({
      ...gridConfig,
      onColumnVisible: agGrid.onSaveGridColumnState,
      onColumnPinned: agGrid.onSaveGridColumnState,
      onColumnResized: agGrid.onSaveGridColumnState,
      onColumnMoved: agGrid.onSaveGridColumnState,
      onColumnRowGroupChanged: agGrid.onSaveGridColumnState,
      onColumnValueChanged: agGrid.onSaveGridColumnState,
      onColumnPivotChanged: agGrid.onSaveGridColumnState,
      /*
      TODO fix ts error https://www.ag-grid.com/javascript-data-grid/grid-lifecycle/
    */
      // onGridPreDestroyed: agGrid.onDestroy,
      onGridReady: (gridReadyParams: GridReadyEvent) => {
        agGrid.onGridReady(gridReadyParams);
        gridReadyParams.api.sizeColumnsToFit();
        gridReadyParams.api.setServerSideDatasource({
          async getRows(params) {
            try {
              const { startRow = 0, endRow = 25, sortModel, filterModel } = params.request;
              for (const columnName in filterModel) {
                if (filterModel[columnName].filterType === DataType.Set) {
                  const filterValues = filterModel[columnName].values;
                  if (filterValues.includes(null)) {
                    filterValues.push(' ');
                  }
                }
              }
              setLoading(true);
              const { rows, totalRows } = await fetchRows({
                offset: startRow,
                limit: endRow - startRow,
                sort: mapSortModel(sortModel),
                filters: mapFilterModel(filterModel),
              });
              setBatches(rows);
              params.success({
                rowData: rows,
                rowCount: totalRows,
              });
              return {
                rows,
                totalRows,
              };
            } catch (err) {
              params.fail();
              setError(new Error(err as unknown as string));
            } finally {
              setLoading(false);
            }
          },
        });
      },
    }),
    [fetchRows, agGrid, gridConfig, setError, setLoading, setBatches]
  );

  const location = useLocation();

  const transactionPath = useMemo(() => {
    return location.pathname.split('/').slice(0, 4).concat('view').join('/');
  }, [location.pathname]);

  const refreshGrid = useCallback(() => agGrid?.api?.refreshServerSide({ purge: true }), [agGrid]);

  useInterval(() => {
    refreshGrid();
  }, 1000 * 30);

  const client = useApolloClient();

  const pageTitle = useMemo(
    () => ({
      crumbs: breadcrumbs,
      content: title,
      actionButton: (
        <ButtonGrid>
          <StyledButton
            variant="text"
            color="primary"
            startIcon={<Icons.TableView />}
            to={transactionPath}
            disabled={!agGrid.api}
          >
            View transactions
          </StyledButton>
          <IconButton variant="filled" color="secondary" disabled={!agGrid.api} onClick={resetFilters}>
            <Icons.FilterAltOff />
          </IconButton>
          {!!permissions.data.find((action) => action.startsWith('createImportBatch')) && isDirectUploadsAvailable && (
            <Tooltip title="Manually enter data into Rio">
              <TooltipInner>
                <IconButton variant="filled" onClick={showDirectDataUploadModal}>
                  <Icons.Create fontSize="small" />
                </IconButton>
              </TooltipInner>
            </Tooltip>
          )}
          {!!uploadLink && (
            <Tooltip title="Bulk upload your data using our uploader">
              <TooltipInner>
                <IconButton>
                  <V2DromoUploaderButton iconOnly transactionType={dataType} onCompleted={refreshGrid} />
                </IconButton>
              </TooltipInner>
            </Tooltip>
          )}
        </ButtonGrid>
      ),
    }),
    [
      breadcrumbs,
      title,
      transactionPath,
      agGrid.api,
      resetFilters,
      permissions.data,
      isDirectUploadsAvailable,
      uploadLink,
      dataType,
      refreshGrid,
    ]
  );

  const { showNotification } = useNotification();

  const [deleteBatch] = useMutation(DELETE_BATCH, {
    onCompleted: () => {
      agGrid.api?.refreshServerSide({
        purge: true,
      });
      handleDeleteClick(null);
      showNotification('Batch deleted', 'success');
    },
    onError: () => {
      showNotification('Error deleting batch', 'danger');
    },
  });

  const [rollBack] = useMutation(ROLLBACK_BATCH, {
    onCompleted: () => {
      agGrid.api?.refreshServerSide({
        purge: true,
      });
      handleRollbackClick(null);
      showNotification('Batch roll back succeeded', 'success');
    },
    onError: () => {
      showNotification('Error rolling back batch', 'danger');
    },
  });

  const handleRollbackConfirm = useCallback(() => {
    rollBack({ variables: { id: confirmRollbackId } });
    showNotification('Rollback has been started', 'success');
  }, [confirmRollbackId, rollBack, showNotification]);

  const handleDeleteConfirm = useCallback(() => {
    deleteBatch({ variables: { id: confirmDeleteId } });
    showNotification('Batch deletion has been started', 'success');
  }, [confirmDeleteId, deleteBatch, showNotification]);

  const handleOnDataFormUploadComplete = () => {
    refreshGrid();
    showNotification('Upload has completed', 'success');
  };

  const handleOnDataFormUploadError = () => {
    showNotification('Upload has failed', 'danger');
  };

  const handleOnDismissModal = () => setIsDataFormModal(false);

  const batch = useMemo(
    () => batches.find((b: DataImportBatch) => b.id === confirmQuickViewId),
    [batches, confirmQuickViewId]
  );

  return (
    <>
      <Page title={pageTitle}>
        {confirmQuickViewId && batch && (
          <QuickViewModal
            batch={batch}
            uploadType={TransactionType.Electricity}
            onDismiss={() => {
              handleQuickviewClick(null);
            }}
            refetchBatches={() => {
              client.refetchQueries({ include: [GET_BATCHES_PAGE] });
            }}
            accountId={accountId}
          />
        )}

        <ConfirmActionModal
          open={Boolean(confirmRollbackId)}
          onClose={() => handleRollbackClick(null)}
          title="Start Rollback"
          body="Are you sure you want to roll back this data?"
          onConfirm={handleRollbackConfirm}
          confirmButtonText="Confirm"
          closeButtonText="Cancel"
        />

        <ConfirmActionModal
          open={Boolean(confirmDeleteId)}
          title="Are you sure you want to delete this data upload attempt?"
          onConfirm={handleDeleteConfirm}
          onClose={() => handleDeleteClick(null)}
          withFullWidthButtons
        />

        {erroredRowId && (
          <Modal grid={{ xs: 6 }} open onClose={() => handleErrorClick(null)} title="Batch Errors">
            <BatchErrors batchId={erroredRowId} withTitle={false} />
          </Modal>
        )}

        <AgGridStyled key={accountId} gridKey={gridKey} gridOptions={gridOptions} />
        {isDataFormModal && (
          <DataFormModal
            onComplete={handleOnDataFormUploadComplete}
            onError={handleOnDataFormUploadError}
            onDismiss={handleOnDismissModal}
            dataSection={dataType}
          />
        )}
      </Page>
    </>
  );
};
