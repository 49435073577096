import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { JsxSynopsis } from '../../../../../components/ManagementSystemChapterLayout';
import {
  ReportingFrameworkLayout,
  ReportProgressByChapters,
  useGetReportProgressChapterBreakdown
} from '../../../../../components/ReportingFramework';
import { CarbonDisclosureScoreChart } from '../components/CarbonDisclosureScoreChart';
import { ManagementSystemPage } from '../../../../../types';

interface CarbonDisclosureProgressProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureProgress({ page, framework }: CarbonDisclosureProgressProps) {
  const { data } = useGetReportProgressChapterBreakdown(framework.id);

  const synopsis = useMemo(
    (): JsxSynopsis => ({
      mode: 'jsx',
      content: <ReportProgressByChapters managementSystem={framework} />
    }),
    [framework]
  );

  const content = useMemo(
    () => ({
      header: 'Scoring progress:',
      render: () => (
        <CarbonDisclosureScoreChart
          currentScore={{
            name: 'D-',
            progress: data?.getReportProgressChapterBreakdown.totalProgress || 0
          }}
        />
      )
    }),
    [data?.getReportProgressChapterBreakdown]
  );

  return (
    <ReportingFrameworkLayout
      title="Completion"
      page={page}
      framework={framework}
      synopsis={synopsis}
      content={content}
    />
  );
}
