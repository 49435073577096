import { gql } from '@apollo/client';

export const DATA_ANOMALY_FRAGMENT = gql`
  fragment DataAnomalyFields on DataAnomaly {
    id
    type
    reason
    ignored
    uploadedIn {
      id
      fileName
    }
    user {
      id
      first_name
      last_name
    }
    transaction {
      __typename

      ... on ElectricityTransactionPageItem {
        startDate
        endDate
      }

      ... on GasTransactionPageItem {
        startDate
        endDate
      }

      ... on HeatSteamTransactionPageItem {
        startDate
        endDate
      }

      ... on WaterTransaction {
        startDate
        endDate
      }

      ... on WasteTransactionPageItem {
        individualCollectionDate
      }

      ... on FuelTransactionPageItem {
        date
      }

      ... on MaterialsTransactionPageItem {
        date
      }

      ... on HotelStaysTransactionPageItem {
        startDate
        endDate
      }

      ... on TransportTransaction {
        date
      }

      ... on RefrigerantTransactionPageItem {
        date
      }
    }
    detectedAt
    varianceTimeseries {
      error
      data {
        dimension
        value
        time
      }
    }
    varianceReason
    notes
    locationName
    meterName
    percentDiff
    startDate
    endDate
    __typename
  }
`;

export const GET_DATA_ANOMALIES = gql`
  query GetDataAnomaliesByAccountId(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getDataAnomaliesByAccountId(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...DataAnomalyFields
      }
    }
  }
  ${DATA_ANOMALY_FRAGMENT}
`;

export const IGNORE_DATA_ANOMALIES = gql`
  mutation IgnoreDataAnomalies($anomalyIds: [ID!]!, $varianceReason: VarianceReason, $notes: String) {
    ignoreDataAnomalies(anomalyIds: $anomalyIds, varianceReason: $varianceReason, notes: $notes) {
      totalRows
      rows {
        ...DataAnomalyFields
      }
    }
  }
  ${DATA_ANOMALY_FRAGMENT}
`;

export const GET_DATA_ANOMALIES_COUNT = gql`
  query GetDataAnomaliesByAccountIdCount($accountId: ID!) {
    getDataAnomaliesByAccountId(accountId: $accountId) {
      totalRows
    }
  }
`;

export const GET_DATA_ANOMALIES_FILTER = gql`
  query GetDataAnomaliesFilter($accountId: ID!, $field: String!, $filters: Filters) {
    getDataAnomaliesFilter(accountId: $accountId, field: $field, filters: $filters)
  }
`;
