import { gql } from '@apollo/client';

export const ENERGY_SOURCE_FRAGMENT = gql`
  fragment EnergySourceFields on EnergySource {
    id
    name
    locationPoint {
      location {
        code
        region {
          id
          name
        }
        address {
          country {
            name
            alpha2Code
          }
        }
      }
    }
  }
`;
