import { useEffect, useState } from 'react';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { AG_GRID_PARAMS } from '../../../constants/common';
import { Nullable } from '../../../types';

type FilterStructure = {
  filterType: string;
  values: Array<string>;
};

type Model<T> = {
  [key: string]: T;
};

type FilterModel = {
  filterModel?: Model<FilterStructure>;
};

const checkAndSetFilterModel = (source: FilterModel, filterCriteria: string, stateSetter: (value: boolean) => void, value: string): void => {
  const checkFilterModel = source?.filterModel?.[filterCriteria]?.values as Array<string>;
  const isCriteriaSet = checkFilterModel?.length === 1 && checkFilterModel[0] === value;
  stateSetter(isCriteriaSet);
};

export function useInitialFilterModel() {
  const [showCompleted, setShowCompleted] = useState<Nullable<boolean>>(null);
  const [showMine, setShowMine] = useState<Nullable<boolean>>(null);
  const [showHighPriority, setShowHighPriority] = useState<Nullable<boolean>>(null);
  const { token } = useUserToken();
  const accountId = useCurrentAccountId();

  useEffect(() => {
    const agGridParamsJson = localStorage.getItem(AG_GRID_PARAMS);
    const agGridParams = agGridParamsJson !== null ? JSON.parse(agGridParamsJson) : {};
    const userId = token?.sub;
    const savedFilterModel = agGridParams?.[`${userId}-${accountId}-/tasks`];

    checkAndSetFilterModel(savedFilterModel, 'priority', setShowHighPriority, 'HIGH');
    checkAndSetFilterModel(savedFilterModel, 'status', setShowCompleted, 'Completed');
    checkAndSetFilterModel(savedFilterModel, 'owners', setShowMine, `${token.given_name} ${token.family_name}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showCompleted,
    setShowCompleted,
    showMine,
    setShowMine,
    showHighPriority,
    setShowHighPriority
  };
}
