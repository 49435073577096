import React from 'react';
import { Text } from 'rio-ui-components';
import { formatDate } from '../../../utils';

type StartDateCellProps = {
  data: {
    startDate: Date
  }
}

export function StartDateCell(props: StartDateCellProps) {
  const {
    data: { startDate } = {}
  } = props;

  const date = formatDate(startDate);

  return (
    startDate && <Text align="left">{date}</Text>
  )
}
