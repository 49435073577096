import React, { useState } from 'react';
import { Modal } from 'rio-ui-components';
import { useCurrentAccountId, useNotification } from '../../hooks';
import CreateEnergyMeter from '../../containers/ConfigurationContainer/ConfigurationEnergyMetersContainer/CreateEnergyMeterModal';
import CreateLocation from '../../containers/ConfigurationContainer/ConfigurationLocationsContainer/CreateLocation';

export function CreateMeterModal(props) {
  const { onSuccess, onDismiss, showModal, energyType } = props;

  const [showCreateLocation, setShowCreateLocation] = useState(false);
  const [locationData, setNewLocationData] = useState(null);
  const [meterName, setMeterName] = useState(null);
  const { showNotification } = useNotification();

  const accountId = useCurrentAccountId();
  const onLocationCreated = (message, color, _, location) => {
    showNotification(message, color);
    setNewLocationData(location);
    setShowCreateLocation(false);
  };

  const handleOnSuccess = (arg1, arg2, arg3, { id, name, locationPoint } = {}) => onSuccess('data.energySource', { id, name, locationPoint });

  return (
    <Modal span={5} size="md" show={true} name="CreateWasteStream__Modal--create" dismissable onDismiss={onDismiss}>
      {!showCreateLocation && (
        <CreateEnergyMeter
          onSuccess={handleOnSuccess}
          onError={showNotification}
          showModal={showModal}
          accountId={accountId}
          defaultEnergyType={energyType}
          disableType={true}
          onCreateClick={() => setShowCreateLocation(true)}
          locationData={locationData}
          onMeterNameChange={setMeterName}
          createItem={{ value: meterName }}
        />
      )}
      {showCreateLocation && <CreateLocation accountId={accountId} onSuccess={onLocationCreated} />}
    </Modal>
  );
}
