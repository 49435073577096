import { gql } from "@apollo/client";

export const GET_ALL_PROJECTS = gql`
  query GetProjects($accountId: ID!) {
    getProjects(accountId: $accountId) {
      id
      name
    }
  }
`;
