import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Notification, PropertySheet } from 'rio-ui-components';
import { useCurrentAccount } from '../../../hooks/useCurrentAccount';
import { v4 as uuid } from 'uuid';
import { isPostalCode } from 'validator';
import { isPhoneNumber } from '../../../utils/validation';
import { CREATE_CONTRACTOR } from './index.queries';
import AddressPropertySheet from '../../../components/AddressPropertySheet';
import { useCountries } from '../../../hooks/useCountries';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const CreateContractor = ({ refetchQuery, onUpdate, onSuccess }) => {
  const { countryList, defaultCountry } = useCountries();
  const [state, setState] = useState({
    hasError: false,
    isSuccess: false,
    notification: { show: false, message: '', color: 'success' },
    contractorName: { value: '', success: null },
    address1: { text: '', error: '' },
    address2: { text: '', error: '' },
    address3: { text: '', error: '' },
    city: { text: '', error: '' },
    postcode: { text: '', error: '' },
    phone: { text: '', error: '' },
    selectCountry: null
  });
  const currentAccount = useCurrentAccount();
  const [createContractor] = useMutation(CREATE_CONTRACTOR, {
    refetchQueries: refetchQuery,
    onError: (error) => {
      if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].errorType === 400) {
        setState({
          ...state,
          notification: {
            show: true,
            message: 'Contractor name must be unique.',
            color: 'danger'
          },
          hasError: true,
          isSuccess: false
        });
        return;
      }
      setState({
        ...state,
        notification: {
          show: true,
          message: 'Something went wrong. If the problem continues please contact support.',
          color: 'danger'
        },
        hasError: true,
        isSuccess: false
      });
    },
    onCompleted: (data) => {
      setState({
        ...state,
        hasError: false,
        isSuccess: true
      });
      onSuccess('Contractor has been created!', 'success', data.createWasteContractor.id, data.createWasteContractor);
    },
    update: (cache, data) => {
      if (onUpdate) onUpdate(cache, data);
    }
  });

  const handleSaveContractor = () => {
    if (!isValid()) {
      return;
    }
    createContractor({
      variables: {
        id: uuid(),
        name: state.contractorName.value,
        addressId: uuid(),
        add1: state.address1.text || null,
        add2: state.address2.text || null,
        add3: state.address3.text || null,
        city: state.city.text,
        post: state.postcode.text,
        phone: state.phone.text ? state.phone.text.trim() : null,
        accountId: currentAccount.id,
        countryId: state.selectCountry?.value || defaultCountry.value,
        aliases: []
      }
    });
  };

  const handleChange = (e) => setState({ ...state, [e.target.name]: { text: e.target.value, error: null } });

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.select.value, label: e.target.select.label }
    }));
  };

  const handleContractorNameChange = (event) => {
    const value = event.target.value;
    const success = value ? true : null;
    setState({
      ...state,
      contractorName: { ...state.contractorName, value, success }
    });
  };

  const isValid = () =>
    state.contractorName.success &&
    validateField(state.address1.text) &&
    validateField(state.city.text) &&
    validateField(state.postcode.text) &&
    isPostalCode(state.postcode.text, 'GB') &&
    (!state.phone.text || isPhoneNumber(state.phone.text));

  const validateField = (value) => !(value.trim() === '');

  const hideNotification = () => {
    setState({
      ...state,
      notification: { ...state.notification, show: false }
    });
  };

  return (
    <Container name="create-contractor">
      {state.notification.show && (
        <Notification
          name="create-account__notification"
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}

      <PropertySheet id={'createContractor--propertySheet'}>
        <PropertySheet.HeaderRow name="createContractor--header">Create Contractor</PropertySheet.HeaderRow>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            name="contractor--name"
            onChange={handleContractorNameChange}
            label="Contractor Name"
            value={state.contractorName.value}
            success={state.contractorName.success}
          />
        </PropertySheet.Row>
        <AddressPropertySheet
          id={'ContractorAddressPropertySheet'}
          name={'ContractorAddressPropertySheet'}
          address1={state.address1}
          address2={state.address2}
          address3={state.address3}
          city={state.city}
          postcode={state.postcode}
          phone={state.phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          countryList={countryList || []}
          selectCountry={state.selectCountry || defaultCountry}
        />

        <PropertySheet.Row>
          <PropertySheet.ButtonColumn name="createContractor" onClick={handleSaveContractor} disabled={!isValid()}>
            Save
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
};

export default CreateContractor;
