import get from 'lodash/get';
import { Drawer, DrawerDetails, DrawerSummary, styled, Icons } from '@rio/ui-components';
import { DataAnomaliesGrid } from './DataAnomaliesGrid';

const DrawerStyled = styled(Drawer)`
  .DrawerSummaryClass {
    border-radius: 14px;

    &:hover {
      background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
    }
  }
`;

export const DrawerText = styled('div')<{ color: string }>`
  font-size: ${({ theme }) => theme.sys.typescale.body.medium.size};

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${({ theme, color }) => get(theme, color)};
    margin-right: 10px;
    position: relative;
    top: 1px;
    flex-shrink: 0;
  }
`;

export const AnomaliesDrawer = () => {
  return (
    <DrawerStyled>
      <DrawerSummary className="DrawerSummaryClass" expandIcon={<Icons.ExpandMore />} aria-controls="panel1a-content">
        <DrawerText color="customColors.warning.color">Anomalies found in your data</DrawerText>
      </DrawerSummary>
      <DrawerDetails>
        <DataAnomaliesGrid />
      </DrawerDetails>
    </DrawerStyled>
  );
};
