import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { AuditEvent } from '@rio/rio-types';
import { toDateTime } from '../../../utils';
import { GridContainer, replaceEmpty, getUserNames, feedbackGetter } from '../SurveyAuditLogContainer';

interface ContributorAuditLogGridProps {
  rows: AuditEvent[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Date & Time',
    field: 'createdDate',
    valueFormatter: (data: { value: string }) => toDateTime(data.value),
  },
  {
    headerName: 'User',
    valueFormatter: replaceEmpty,
    valueGetter: getUserNames,
  },
  {
    headerName: 'Action',
    field: 'type',
    flex: 1,
    valueFormatter: replaceEmpty,
  },
  {
    headerName: 'Manager comments',
    field: 'payload',
    valueFormatter: feedbackGetter,
  },
];
export function ContributorAuditLogGrid({ rows }: ContributorAuditLogGridProps) {
  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact rowData={rows} columnDefs={columnDefs} />
    </GridContainer>
  );
}
