import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as JsSearch from 'js-search';
import { Col, Search, Button, Modal, Notification, ErrorMessage, ContainerError } from 'rio-ui-components';
import { sortBy } from 'lodash';
import { GET_TAGS_BY_ACCOUNT_ID } from './index.queries';
import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import AccountSelector from '../../../components/AccountSelector';
import { usePermissions } from '../../../hooks/usePermissions';

import TagRows from './TagRows';
import UpdateTag from './UpdateTag';
import CreateTag from './CreateTag';

const UnderlinedContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const CreateButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ErrorContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ConfigurationTagsContainer = ({ accountId }) => {
  const permissions = usePermissions();

  const [state, setState] = useState({
    search: { value: '', error: '' },
    createModal: { show: false },
    updateModal: { show: false, tag: null },
    selected: [],
    notification: { message: null, color: null },
    account: { id: accountId },
    shouldRefetch: false
  });

  const handleAccountChange = (selected) => setState({ ...state, account: { id: selected.id } });
  const handleSearchChange = (event) => setState({ ...state, search: { ...state.search, value: event.target.value } });

  const getTagResult = ({ data, error, loading }) => {
    if (error)
      return (
        <ErrorContainerStyled>
          <ErrorMessage error={error}>
            {({ title, body, icon }) => (
              <ContainerError name="ConfigurationTagsContainer__Error" icon={icon} title={title} body={body} />
            )}
          </ErrorMessage>
        </ErrorContainerStyled>
      );

    if (loading) return <ContainerLoadingIndicator name="ConfigurationTagsContainer__Loading" />;

    const tags = sortBy(search(data.getTagsByAccountId, state.search.value), 'tagName');
    return tags.length ? (
      <TagRows tags={tags} onUpdateTag={onUpdateTag} />
    ) : (
      <NoResults
        name="ConfigurationTagsContainer__NoResults"
        title="There are no results for this search."
        id="tags-no-results"
      />
    );
  };

  const dismissModals = (notification = {}, refetch) => {
    setState((prevState) => ({
      ...prevState,
      createModal: { show: false },
      updateModal: { show: false },
      notification: { message: notification.message, color: notification.color, id: notification.id }
    }));
    if (refetch) refetch();
  };

  const showModal = (type, tag) => {
    if (type === 'create') return setState((prevState) => ({ ...prevState, createModal: { show: true } }));
    if (type === 'update') return setState((prevState) => ({ ...prevState, updateModal: { show: true, tag } }));
  };

  const hideNotification = () =>
    setState((prevState) => ({ ...prevState, notification: { message: null, color: null, id: null } }));

  const search = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('tagName');
    search.addIndex('tagName');
    search.addDocuments(data);
    return search.search(term);
  };

  const renderCreateModal = (refetchFn) => {
    const { notification, createModal, account } = state;
    return (
      <>
        {notification.message && (
          <Notification name="ConfigurationTagsContainer__Notification" show color="success" onClose={hideNotification}>
            {notification.message}
            <div hidden name="ConfigurationTagsContainer__Notification__createtag_id">
              {notification.id}
            </div>
          </Notification>
        )}
        {createModal.show && (
          <Modal
            span={5}
            size="md"
            show
            name="ConfigurationTagsContainer__Modal--create"
            dismissable
            onDismiss={dismissModals}
            height="auto"
            maxHeight="90vh"
          >
            <CreateTag
              accountId={account.id}
              onSuccess={(message, color, id) => dismissModals({ message, color, id }, refetchFn)}
            />
          </Modal>
        )}
      </>
    );
  };

  const renderUpdateModal = () => {
    const { notification, updateModal, account } = state;
    return (
      <>
        {notification.message && (
          <Notification name="ConfigurationTagsContainer__Notification" show color="success" onClose={hideNotification}>
            {notification.message}
            <div hidden name="ConfigurationTagsContainer__Notification__createtag_id">
              {notification.id}
            </div>
          </Notification>
        )}
        {updateModal.show && (
          <Modal
            span={5}
            size="md"
            show
            name="ConfigurationTagsContainer__Modal--create"
            dismissable
            onDismiss={dismissModals}
            height="auto"
            maxHeight="90vh"
          >
            <UpdateTag
              accountId={account.id}
              onSuccess={(message, color, id) => dismissModals({ message, color, id })}
              tag={state.updateModal.tag}
            />
          </Modal>
        )}
      </>
    );
  };

  const onUpdateTag = (tag) => {
    showModal('update', tag);
  };

  const { data, error, loading, refetch } = useQuery(GET_TAGS_BY_ACCOUNT_ID, {
    variables: { accountId: state.account.id },
    fetchPolicy: 'network-only'
  });
  const tagList = getTagResult({ data, error, loading });

  return (
    <Col name="ConfigurationTagsContainer" container fullHeight>
      {renderCreateModal(refetch)}
      {renderUpdateModal()}
      <ContainerHeader name="ConfigurationTagsContainer__Controls" icon="tags" iconColor="primary" title="Tags">
        <Col container item>
          <Search
            name="ConfigurationTagsContainer__Controls__Search"
            value={state.search.value}
            onChange={handleSearchChange}
            hideButton
          />
        </Col>
        <CreateButtonStyled
          name="ConfigurationTagsContainer__Controls__Button--create"
          size="md"
          color="primary"
          inline
          onClick={() => showModal('create')}
        >
          + Create Tag
        </CreateButtonStyled>
      </ContainerHeader>

      {permissions.tag.includes('getAll') && (
        <UnderlinedContainer>
          <AccountSelector value={state.account.id} onChange={handleAccountChange} />
        </UnderlinedContainer>
      )}

      {tagList}
    </Col>
  );
};

export default ConfigurationTagsContainer;
