import { Checkbox, Text } from '@rio/ui-components';
import { FormattedMessage } from 'react-intl';
import { LinkToTerms } from './style';

export const CheckboxTerms = ({ terms, checked, onChange }) => {
  return (
    <Checkbox
      name={terms}
      checked={checked}
      label={
        <Text size="medium" typescale="body">
          <FormattedMessage id="login.termsAgreement.termsAgreementText" />
          <LinkToTerms href={`http://www.rio.ai/terms-of-${terms}`} target="_blank">
            <FormattedMessage
              id="login.termsAgreement.termsLinkText"
              values={{
                terms: <FormattedMessage id={`login.termsAgreement.${terms}`} />,
              }}
            />
          </LinkToTerms>
        </Text>
      }
      onChange={onChange}
    />
  );
};
