import {
  Project,
  ProjectInput,
  ProjectPriority,
  User,
  Task,
  TaskInput,
  ProjectEvidenceInput,
  ProjectEvidenceNotes,
} from '@rio/rio-types';
import { get } from 'lodash';
import { ProjectFormattedDetails } from './types';
import { toSelectOptions, mapSelectedOptionsToInputValues } from '../../../utils';

const mapOwnersToValues = (owners: User[]) => {
  return owners?.map((owner) => ({ label: owner.first_name + ' ' + owner.last_name, value: owner.id }));
};

export const formatProjectData = (project: Project): ProjectFormattedDetails => {
  return {
    id: project.id,
    accountId: project.accountId,
    owners: mapOwnersToValues(project.owners),
    locations: toSelectOptions(project?.locations),
    name: project.name,
    description: project.description || '',
    priority: project.priority,
    scheme: {
      label: project.scheme?.name,
      value: project.scheme?.id,
    },
    impact: project.impact || '',
    cost: project.cost || undefined,
    currencyId: project.currencyId || undefined,
    baselineStartDate: project.baselineStartDate,
    startDate: project.startDate,
    endDate: project.endDate,
    frequency: project.frequency || '',
    plannedBenefit: project.plannedBenefit,
    plannedCostChange: project.plannedCostChange || 0,
    plannedCO2eChange: project.plannedCO2eChange || 0,
    tasks: project.tasks,
    evidenceNotes: project.evidenceNotes,
    meters: toSelectOptions(project.meters),
  };
};

export const countCompletedTasks = (tasks: Task[]) => {
  const completedArray = tasks.map((task: Task) => task.isCompleted);
  const allTasksNumber = completedArray.length;
  const completedTasksNumber = completedArray.filter((item: boolean) => item === true).length;
  const completedPercentage = (completedTasksNumber / allTasksNumber) * 100;
  return completedPercentage;
};

export const prepareProjectEvidence = (evidence: ProjectEvidenceNotes[]) => {
  return evidence.map((evidenceNote) => ({
    ...evidenceNote,
    documents: evidenceNote?.documents?.map((document) => document?.id),
  }));
};

export const prepareProjectInput = (project: Partial<ProjectFormattedDetails>): ProjectInput => {
  return {
    accountId: project.accountId || '',
    id: project.id || '',
    owners: mapSelectedOptionsToInputValues(project?.owners || []) as string[],
    locations: mapSelectedOptionsToInputValues(project?.locations || []) as string[],
    priority: project.priority as ProjectPriority,
    name: project.name || '',
    schemeId: project?.scheme?.value || '',
    baselineStartDate: project?.baselineStartDate || '',
    startDate: project?.startDate || '',
    endDate: project?.endDate || '',
    plannedBenefit: project?.plannedBenefit || 0,
    description: project.description || null,
    impact: project.impact || null,
    cost: project.cost || null,
    currencyId: project.cost ? project.currencyId : null,
    frequency: project.frequency || null,
    plannedCostChange: (project.plannedCostChange && Math.abs(+project.plannedCostChange)) || null,
    plannedCO2eChange: (project.plannedCO2eChange && Math.abs(+project.plannedCO2eChange)) || null,
    evidenceNotes: prepareProjectEvidence(project?.evidenceNotes || []) as ProjectEvidenceInput[],
    meters: mapSelectedOptionsToInputValues(project?.meters || []) as string[],
  };
};

export const prepareTaskForUpdate = (task: Task): TaskInput => {
  return {
    id: task.id,
    accountId: task.accountId,
    name: task.name,
    description: task.description,
    category: task.category,
    subject: task.subject || null,
    itemId: task?.item?.id || null,
    priority: task.priority,
    dueDate: task.dueDate,
    createdAt: task.createdAt || '',
    completedAt: task.completedAt || null,
    isCompleted: task.isCompleted,
    scope: task.scope || null,
    locationIds: (task.locations?.map((location) => location?.id) as string[]) || null,
    owners: task.owners?.map((owner) => owner?.id) || null,
    tagIds: (task.tags?.map((tag) => tag?.id) as string[]) || null,
    projectId: null,
  };
};

export const isTextEvent = (event: object) => {
  const type = get(event, 'target.type');
  return type === 'text' || type === 'textarea';
};
