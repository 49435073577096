import { IDeveloperField } from 'dromo-uploader-react';
import {
  DromoConfiguration,
  dromoConfiguration as baseDromoConfiguration,
  ICustomDeveloperSettings,
  EnvironmentSchemaIds,
} from '../../DataContainer/DromoConfiguration/base';

// See docs: https://developer.dromo.io/fields/
const fields: IDeveloperField[] = [];

// See docs: https://developer.dromo.io/settings/
const settings: ICustomDeveloperSettings = {
  ...baseDromoConfiguration.settings,
};

const schemaIds: EnvironmentSchemaIds = {
  ...baseDromoConfiguration.schemaIds,
};

const style: React.CSSProperties = {
  ...baseDromoConfiguration.style,
};

export const dromoConfiguration: DromoConfiguration = {
  fields,
  settings,
  style,
  schemaIds,
};
