import { useCallback, useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { useQueryParam, ArrayParam } from 'use-query-params';
import {
  GridReadyEvent,
  FilterChangedEvent,
  SortChangedEvent,
  GridApi,
  ColumnApi,
  IRowNode,
  FirstDataRenderedEvent,
} from 'ag-grid-community';
import { AG_GRID_PARAMS } from '../constants/common';
import { useCurrentAccountId } from './useCurrentAccountId';
import { useUserToken } from './useUserToken';

interface UseAgGridOptions {
  autoFit?: boolean;
}
export interface AgGrid {
  onFirstDataRendered: (event: FirstDataRenderedEvent) => void;
  onGridReady: (event: GridReadyEvent) => void;
  onFilterChanged: (event: FilterChangedEvent) => void;
  onSortChanged: (event: SortChangedEvent) => void;
  onSaveGridColumnState: () => void;
  onDestroy: () => void;
  api?: GridApi;
  columnApi?: ColumnApi;
}
type UseAgGridState = Partial<{
  gridColumnApi: ColumnApi;
  gridApi: GridApi;
}> | null;

function useSelectedRows(api?: GridApi) {
  const [selectedRows] = useQueryParam('selectedRows', ArrayParam);
  const numberOfSelectedRow = selectedRows?.length || 0;
  useEffect(() => {
    if (numberOfSelectedRow && api) {
      api.forEachNodeAfterFilter((node: IRowNode) => {
        if (node.id && selectedRows?.includes(node.id)) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedRows, numberOfSelectedRow, api]);
}

/**
 *
 * @deprecated - has performance issues and uses deprecated APIs
 */
export function useAgGrid({ autoFit }: UseAgGridOptions = {}): AgGrid {
  const [state, setState] = useState<UseAgGridState>();
  useSelectedRows(state?.gridApi);
  const accountId = useCurrentAccountId();
  const {
    token: { sub: userId },
  } = useUserToken();

  const getWriteKey = () => {
    const pathname = window.location.pathname;
    return `${userId}-${accountId}-${pathname}`;
  };

  const onFirstDataRendered = useCallback(({ api, columnApi }: FirstDataRenderedEvent) => {
    const writeKey = getWriteKey();
    const agGridParamStorage = localStorage.getItem(AG_GRID_PARAMS);
    let agGridAll: any = null;

    if (agGridParamStorage) {
      agGridAll = JSON.parse(agGridParamStorage);
    }

    const agGridParams = agGridAll ? agGridAll[writeKey] : null;
    if (agGridParams?.columnState) columnApi.applyColumnState(agGridParams.columnState);
    if (agGridParams?.filterModel) api.setFilterModel(agGridParams?.filterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setState((prevState) => ({ ...prevState, gridApi: params.api, gridColumnApi: params.columnApi }));
      if (autoFit) {
        params.api.sizeColumnsToFit();
      }
    },
    [autoFit]
  );

  const saveAgGridToLocalStorage = (saveObject: object) => {
    const writeKey = getWriteKey();

    const agGridParamStorage = localStorage.getItem(AG_GRID_PARAMS);
    let agGridPrev: object | null = null;

    if (agGridParamStorage) {
      agGridPrev = JSON.parse(agGridParamStorage);
    }

    const agGridParams =
      agGridPrev && writeKey
        ? { ...agGridPrev, [writeKey]: { ...agGridPrev[writeKey], ...saveObject } }
        : { [writeKey]: saveObject };
    localStorage.setItem(AG_GRID_PARAMS, JSON.stringify(agGridParams));
  };

  const debounceSaveGridColumnState = debounce((columnState) => {
    saveAgGridToLocalStorage({ columnState });
  }, 100);

  const onFilterChanged = useCallback((params: FilterChangedEvent) => {
    const filterModel = params.api.getFilterModel();
    saveAgGridToLocalStorage({ filterModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDestroy = useCallback(() => {
    setState(null);
  }, []);

  const onSaveGridColumnState = useCallback(() => {
    if (!state?.gridColumnApi) return;
    const columnState = state?.gridColumnApi?.getColumnState();
    debounceSaveGridColumnState(columnState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.gridColumnApi]);

  // eslint-disable-next-line
  const onSortChanged = useCallback(onSaveGridColumnState, []);
  const api = useMemo(
    () => ({
      onFirstDataRendered,
      onGridReady,
      onFilterChanged,
      onSortChanged,
      onSaveGridColumnState,
      onDestroy,
      api: state?.gridApi,
      columnApi: state?.gridColumnApi,
    }),
    [onFirstDataRendered, onGridReady, onFilterChanged, onSortChanged, onSaveGridColumnState, onDestroy, state]
  );
  return api;
}
