import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Gas', to: '/data/gas/view' }, { label: 'Uploads' }];

export const V2GasUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      gridKey="gasUploadGridV2"
      title="Here's your upload history"
      breadcrumbs={breadcrumbs}
      uploadLink="../gas/uploads"
      config={config}
      dataType={TransactionType.Gas}
    />
  );
};
