import { Auth } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import { useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorState, FormState, RegistrationHook } from './type';
import { validateCredentialsForm } from '.././validators';

export const useRegistrationForm = (): RegistrationHook => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ hubspotutk }] = useCookies(['hubspotutk']);

  const initialValue = {
    email: '',
    password: '',
    passwordConfirmed: '',
    agreedWithTermsOfService: false,
    agreedWithTermsOfUse: false,
    firstName: '',
    lastName: '',
    companyName: '',
    preferPersonalUse: false,
    phone: '',
    mobile: '',
    jobTitle: '',
    department: '',
    hutk: hubspotutk || '',
  };

  const [form, setForm] = useState<FormState>(initialValue);
  const [errors, setErrors] = useState<ErrorState>({});
  const [promptShown, setPromptShown] = useState<boolean>(false);
  const queryParams: string | undefined = location?.search;

  const handleClosePrompt = useCallback(() => {
    setPromptShown(false);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      const formInvalid = !!Object.keys(errors).length;
      const newForm = { ...form, [name]: value };

      if (formInvalid) {
        setErrors(validateCredentialsForm(newForm));
      }
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    },
    [errors, form]
  );

  const handleProfileChange = useCallback((profile: Partial<FormState>) => {
    setForm((prevForm) => ({
      ...prevForm,
      ...profile,
    }));
  }, []);

  const handleCheckboxChange = useCallback(
    (terms) => {
      const formInvalid = !!Object.keys(errors).length;
      const newForm =
        terms === 'use'
          ? {
              ...form,
              agreedWithTermsOfUse: !form.agreedWithTermsOfUse,
            }
          : {
              ...form,
              agreedWithTermsOfService: !form.agreedWithTermsOfService,
            };
      if (formInvalid) {
        setErrors(validateCredentialsForm(newForm));
      }
      setForm(newForm);
    },
    [errors, form]
  );

  const handleCredentialsSubmit = useCallback(() => {
    localStorage.setItem('MEMOIZED_EMAIL', form.email);
    navigate(`/v2/register/profile${queryParams}`);
  }, [form, navigate, queryParams]);

  const handleProfileSubmit = useCallback(async () => {
    navigate(`/v2/register/contacts${queryParams}`);
  }, [navigate, queryParams]);

  const handleContactsSubmit = useCallback(
    async (errorMessages: { [key: string]: string }) => {
      const email = form.email.trim().toLowerCase();

      try {
        await Auth.signUp({
          password: form.password,
          username: email,
          attributes: {
            email,
            given_name: form.firstName.trim(),
            family_name: form.lastName.trim(),
            'custom:phone': form.phone.trim(),
            'custom:mobile_number': form.mobile.trim(),
            'custom:company_name': form.companyName.trim(),
            'custom:prefer_personal_use': String(form.preferPersonalUse),
            'custom:job_title': form.jobTitle.trim(),
            'custom:department': form.department.trim(),
            'custom:hutk': form.hutk?.trim(),
          },
        });
        setPromptShown(true);
      } catch (err: unknown) {
        if (err instanceof Error) {
          const message = errorMessages[err.message] || err.message;
          setErrors({
            ...errors,
            submit: message,
          });
        }
      }
    },
    [errors, form, setPromptShown]
  );

  return useMemo(
    () => ({
      form,
      errors,
      setErrors,
      promptShown,
      queryParams,
      handleChange,
      handleClosePrompt,
      handleProfileSubmit,
      handleProfileChange,
      handleContactsSubmit,
      handleCheckboxChange,
      handleCredentialsSubmit,
    }),
    [
      form,
      errors,
      setErrors,
      promptShown,
      queryParams,
      handleChange,
      handleClosePrompt,
      handleProfileSubmit,
      handleProfileChange,
      handleContactsSubmit,
      handleCheckboxChange,
      handleCredentialsSubmit,
    ]
  );
};
