import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Row } from 'rio-ui-components';
import * as Sentry from '@sentry/react';
import { Aspect } from '@rio/rio-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ICellRendererParams, CellClickedEvent, FilterModel } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { SearchableCell } from '../../../components/CellRenderers';
import { useCurrentAccountId, AgGrid as AgGridApi, useNotification } from '../../../hooks';
import { useAspectsGridConfig } from './useAspectsGridConfig';
import { PAGE_SIZE } from './constants';
import { LegislationReferenceCell, ActionCell } from './CellRenderers';
import { mapAspectFilterModel, mapAspectSortModel } from './utils';
import { getAspectSearchValue } from './useAspectSearchValueFromQueryParam';
import { getOptionNameToDeleteFromQueryParam } from './useOptionToDeleteFromQueryParam';
import { useGetAspectsByAccountId } from './useGetAspectsByAccountId';

const RowStyled = styled(Row)`
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
interface GridProps {
  showEditModal: (id: string) => void;
  showCopyModal: (aspect: Aspect) => void;
  canEdit: boolean;
  agGrid: AgGridApi;
}

export function AspectsGrid({ showEditModal, showCopyModal, canEdit, agGrid }: GridProps) {
  const accountId = useCurrentAccountId();
  const navigate = useNavigate();
  const canEditRef = useRef(false);
  canEditRef.current = canEdit;
  const config = useAspectsGridConfig();
  const location = useLocation();
  const hashLink = location.hash?.slice?.(1);
  const { showNotification } = useNotification();
  const getAspectsByAccountId = useGetAspectsByAccountId();
  const onCellClicked = (e: CellClickedEvent) => {
    if (e.colDef.cellRenderer !== 'action') {
      navigate(`/governance/environmental-aspects/${e.data.id}`);
    }
  };
  return (
    <RowStyled container item>
      <AgGrid
        {...config}
        components={{
          legislationReference: (props: ICellRendererParams) => (
            <LegislationReferenceCell
              {...props}
              searchValue={getOptionNameToDeleteFromQueryParam() || getAspectSearchValue()}
            />
          ),
          action: (props: ICellRendererParams) =>
            canEditRef.current && <ActionCell {...props} onEdit={showEditModal} onCopy={showCopyModal} />,
          searchable: (props: ICellRendererParams) => (
            <SearchableCell {...props} searchValue={getOptionNameToDeleteFromQueryParam() || getAspectSearchValue()} />
          ),
        }}
        onFirstDataRendered={(e) => {
          if (hashLink) {
            agGrid?.api?.forEachNodeAfterFilter((node) => {
              if (node.data.id === hashLink) {
                node.setSelected(true);
              }
            });
          }
          agGrid.onFirstDataRendered(e);
        }}
        onGridReady={(params) => {
          agGrid.onGridReady(params);
          params.api.setServerSideDatasource({
            async getRows({ request, success, fail }) {
              try {
                const { rows, totalRows } = await getAspectsByAccountId({
                  accountId,
                  offset: request.startRow,
                  limit: PAGE_SIZE,
                  sort: mapAspectSortModel(request.sortModel),
                  filters: mapAspectFilterModel(
                    request.filterModel as FilterModel,
                    getOptionNameToDeleteFromQueryParam() || getAspectSearchValue()
                  ),
                });
                success({
                  rowData: rows,
                  rowCount: totalRows,
                });
                return {
                  rows,
                  totalRows,
                };
              } catch (err) {
                showNotification(`Failed to update grid: ${err}`, 'danger');
                Sentry.captureException(err);
                fail();
              }
            },
          });
        }}
        onCellClicked={onCellClicked}
        onFilterChanged={agGrid.onFilterChanged}
        onSortChanged={agGrid.onSortChanged}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        rowSelection="single"
      />
    </RowStyled>
  );
}
