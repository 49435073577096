import { useQuery } from '@apollo/client';
import { GET_ALL_SUPPLIERS } from '../queries/suppliers';
import { useCurrentAccountId } from './useCurrentAccountId';
import { MAX_ITEMS_IN_DOM_LIMIT } from '../constants';

export function useSuppliers(variables = {}) {
  const currentAccountId = useCurrentAccountId();

  const { data } = useQuery(GET_ALL_SUPPLIERS, {
    variables: {
      ...variables,
      pageSize: variables.pageSize || MAX_ITEMS_IN_DOM_LIMIT,
      accountId: variables.accountId || currentAccountId
    }
  });

  return data && data.getSuppliers ? data.getSuppliers.suppliers : null;
}
