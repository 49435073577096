import { useEffect, useCallback, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, Tabs, styled } from '@rio/ui-components';

import { getUploadCurrentTabsV2, setUploadCurrentTabsV2, tabsItems } from '../../utils/uploadTabs';
import StatusContainer from '../StatusContainer';
import { UploadDataItems } from './UploadDataItems';
import { DataQualityAndCompleteness } from '../DataQualityAndCompleteness';

const DataContainerStyle = styled('div')`
  width: 100%;
`;

const HeaderContainer = styled(Text)`
  margin-bottom: 24px;
`;

export const TopTextContainer = styled(Text)`
  gap: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.sys.color.onSurfaceVariant};
    }
  }
`.withComponent('div');

type InfoContainerProps = {
  activeAccessControls: string[];
  investment?: boolean;
};

const InfoContainer = ({ activeAccessControls, investment }: InfoContainerProps) => {
  const tab = getUploadCurrentTabsV2();
  const tabState = useMemo(() => (investment && tab ? Number(tab) : 0), [investment, tab]);
  const [currentTab, setCurrentTab] = useState<number>(Number(tabState));

  const filteredTabs = useCallback(() => {
    const organisationTab = {
      label: tabsItems[0],
      children: <UploadDataItems activeAccessControls={activeAccessControls} />,
    };

    return !investment
      ? [organisationTab]
      : [
          organisationTab,

          {
            label: tabsItems[1],
            children: <UploadDataItems isPortfolio />,
          },
        ];
  }, [activeAccessControls, investment]);

  const handlerChangeTab = useCallback((val: number) => setUploadCurrentTabsV2(String(val)), []);

  useEffect(() => {
    if (!investment) {
      setCurrentTab(0);
      setUploadCurrentTabsV2(String(0));
    } else {
      setCurrentTab(Number(tab) ?? 0);
    }
  }, [investment, tab]);

  const renderTabs = useMemo(
    () => <Tabs tabs={filteredTabs()} currentTab={currentTab} handlerChangeTab={handlerChangeTab} />,
    [currentTab, filteredTabs, handlerChangeTab]
  );

  return (
    <DataContainerStyle>
      <HeaderContainer typescale="headline" size="medium">
        <FormattedMessage id="pages.data.overview.uploadHeader" />
      </HeaderContainer>
      {renderTabs}
      <StatusContainer />
      <DataQualityAndCompleteness />
    </DataContainerStyle>
  );
};

export default InfoContainer;
