import { keyframes, css } from 'styled-components';

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ellipsis = keyframes`
  to {
    width: 1.25em;
  }
`;

export const ellipsisElement = css`
  &:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    width: 0px;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: '\\2026';
  }
`;

export const splash = () => keyframes`
  from {
    background-color: rgba(125,104,171,0.2);
  }
`;
