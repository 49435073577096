import React, { useState } from 'react';
import styled from 'styled-components';
import { MarketCarbonConversionFactor, MarketCarbonConversionFactorInput } from '@rio/rio-types';
import { Modal, Button, Row, Col } from 'rio-ui-components';
import { MarketCarbonConversionFactorDetails } from './MarketCarbonConversionFactorDetails';
import { mapFactorToInput } from './utils';
import { useNotification } from '../../../hooks';
import { useCreateMarketCarbonConversionFactor } from './useCreateMarketCarbonConversionFactors';
import { validateInput } from './utils';

interface CreateMarketCarbonConversionFactorModalProps {
  onDismiss: () => void;
  onComplete: (factors: MarketCarbonConversionFactor[]) => void;
}
const RowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
export function CreateMarketCarbonConversionFactorModal({
  onDismiss,
  onComplete
}: CreateMarketCarbonConversionFactorModalProps) {
  const [factor, setFactor] = useState<MarketCarbonConversionFactorInput>(mapFactorToInput(null));
  const [errors, setErrors] = useState({});
  const [createFactor] = useCreateMarketCarbonConversionFactor({
    onComplete
  });
  const { showNotification } = useNotification();
  return (
    <Modal size="md" onDismiss={onDismiss} show>
      <Row container>
        <MarketCarbonConversionFactorDetails
          heading="Create Market Based Carbon Conversion Factor"
          isSubmitting={false}
          onChange={setFactor}
          values={factor}
          errors={errors}
          setErrors={setErrors}
        />
      </Row>
      <RowStyled container>
        <Col item span={12}>
          <Button
            color="success"
            onClick={async () => {
              try {
                const errors = validateInput(factor);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }
                await createFactor({
                  variables: {
                    factor
                  }
                });
                showNotification('Tariff has been created', 'success');
              } catch (err) {
                showNotification((err as Error).message, 'danger');
              }
            }}>
            Create
          </Button>
        </Col>
      </RowStyled>
    </Modal>
  );
}
