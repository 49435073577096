import { Page, Button, CustomIcons, styled } from '@rio/ui-components';
import { useState } from 'react';
import { UploadDocumentModal } from '../DocumentModal/v2';
import { useAccessControls, useNotification } from '~/hooks';
import { DocumentLibraries } from '../documentLibraries';
import { DocumentTypeFolder } from '../types';
import { useParams } from 'react-router-dom';
import { DocumentLibrary } from '@rio/rio-types';
import { FolderGrid } from '../DocumentExplorer/Folders';
import { getLibraryColour } from '../utils';
import _ from 'lodash';

interface DocumentLandingPageProps {
  libraries: DocumentLibraries;
  types: DocumentTypeFolder[];
}

const ButtonStyled = styled(Button)`
  height: 40px;
`;

const DocumentLandingV2 = ({ libraries, types }: DocumentLandingPageProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { showNotification } = useNotification();
  const { data: accessControls } = useAccessControls();
  const { library } = useParams();

  const createBreadcrumbs = () => {
    const crumbs: { label: string; to?: string }[] = [{ label: 'Documents', to: '../documents' }];

    if (library) {
      crumbs.push({ label: _.capitalize(library), to: `../../documents/${library}` });
    } else {
      crumbs.push({ label: 'Overview' });
    }
    return crumbs;
  };

  const filteredFolders = library
    ? types.filter((folder) => folder.libraries.includes(library.toUpperCase() as DocumentLibrary))
    : libraries;
  const folders = filteredFolders.map((folder) => ({
    name: folder.title,
    url: `./${folder.link}`,
    icon: {
      color: getLibraryColour(folder.library || library),
      name: 'folder',
    },
    disabled: !('accessControlIndexer' in folder ? _.get(accessControls, folder.accessControlIndexer) : true),
  }));

  return (
    <Page
      title={{
        content: library ? `${_.capitalize(library)} Documents` : 'Documents',
        crumbs: createBreadcrumbs(),
        actionButton: (
          <ButtonStyled variant="filled" onClick={() => setShowCreateModal(!showCreateModal)}>
            <CustomIcons.Upload color="onPrimary" />
            Upload
          </ButtonStyled>
        ),
      }}
    >
      {!!folders.length && <FolderGrid folders={folders} />}

      {showCreateModal && (
        <UploadDocumentModal
          onDismiss={() => setShowCreateModal(false)}
          onError={(err: Error) => {
            showNotification(err.message, 'danger');
          }}
          predefinedValues={{
            library: (library?.toUpperCase() || null) as DocumentLibrary,
          }}
        />
      )}
    </Page>
  );
};

export default DocumentLandingV2;
