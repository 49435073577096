import { useCurrentAccountId } from '~/hooks';
import { QuicksightFrameV2 } from '../../../components/QuicksightFrame';
import { useDashboard } from '~/hooks/useDashboard';
import { styled } from '@mui/material-next/styles';

const QuicksightFrameStyled = styled(QuicksightFrameV2)`
  height: 100%;
  width: 100%;
`;

const ContainerStyled = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardFrame = ({ setDashFrame, id, dashboardId, setIsContentReady }) => {
  const accountId = useCurrentAccountId();
  const { data } = useDashboard(dashboardId!);

  return (
    <ContainerStyled>
      {data && (
        <QuicksightFrameStyled
          setIsContentReady={setIsContentReady}
          setDashFrame={setDashFrame}
          id={id}
          dashboardurl={data.getQuicksightDashboard}
          accountId={accountId}
          key={accountId}
          parameters={{}}
        />
      )}
    </ContainerStyled>
  );
};
