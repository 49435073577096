import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import { LoadingIndicator, PropertySheet, ErrorMessage, InlineError } from 'rio-ui-components';
import { GET_TREATMENT_PROCESSES } from './index.queries';

const InlineErrorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  padding-top: ${(props) => props.theme.geometry.md.spacing};
`;

function getOptions(data) {
  if (data && data.getAllWasteTreatmentProcesses) {
    return data.getAllWasteTreatmentProcesses.map((item) => ({ label: item.name, value: item.id }));
  }
}

export function TreatmentProcessSelect({ onWasteTreatmentChange, wasteTreatmentId, disabled, validationError }) {
  const { loading, error, data } = useQuery(GET_TREATMENT_PROCESSES);

  return (
    <>
      {error && (
        <ErrorMessage error={error}>
          {({ icon }) => (
            <InlineErrorContainer>
              <InlineError icon={icon} title="Treatment Processes failed to load" />
            </InlineErrorContainer>
          )}
        </ErrorMessage>
      )}
      <PropertySheet.SelectColumn
        isDisabled={disabled}
        error={validationError}
        label="Treatment Process"
        name="treatmentProcessSelectBox"
        isLoading={loading}
        components={{ LoadingIndicator: () => <LoadingIndicator size="xxs" /> }}
        options={getOptions(data)}
        onChange={onWasteTreatmentChange}
        value={wasteTreatmentId}
      />
    </>
  );
}
