import { gql } from '@apollo/client';
import { EVALUATION_FRAGMENT } from '../../ConfigurationContainer/ConfigurationLearnEvaluations/index.queries';

export const BANNER_FRAGMENT = gql`
  fragment BannersFields on Banner {
    accountId
    name
    type
    url
    thumbnail
  }
`;

export const GET_AVAILABLE_COURSES = gql`
  query GetAvailableCourses($accountId: String!, $pageNumber: Int!, $userId: ID) {
    getAvailableCourses(accountId: $accountId, pageNumber: $pageNumber, userId: $userId) {
      isLastPage
      totalItemCount
      data {
        id
        name
        type
        accountId
        bundleIdList
        metaData {
          viewed
          link
          thumbnail
          cpd
          description
          categories
        }
        userCourseMetaData(userId: $userId) {
          lastUpdatedAt
          courseProgress
        }
      }
      learnBundleList {
        id
        title
        description
        restricted
        startDate
        endDate
        courses
        isBundleHidden
        isbBundleShow
        isMandatoryBundle
      }
    }
  }
`;

export const SEARCH_AVALIABLE_COURSES = gql`
  query SearchCourses($accountId: String!, $pageNumber: Int!, $searchTerm: String!, $userId: ID, $section: String!) {
    searchCourses(
      accountId: $accountId
      pageNumber: $pageNumber
      searchTerm: $searchTerm
      section: $section
      userId: $userId
    ) {
      isLastPage
      totalItemCount
      data {
        id
        name
        type
        accountId
        bundleIdList
        metaData {
          viewed
          link
          thumbnail
          cpd
          categories
        }
        userCourseMetaData(userId: $userId) {
          lastUpdatedAt
          courseProgress
        }
      }
    }

    getLearnBundlesForAccount(accountId: $accountId) {
      id
      title
      description
      restricted
      startDate
      endDate
      courses
      isBundleHidden
      isbBundleShow
      isMandatoryBundle
    }
  }
`;

export const SEARCH_AVALIABLE_COURSES_WITH_ONE_BUNDLE = gql`
  query SearchCoursesWithOneBundle(
    $accountId: String!
    $pageNumber: Int!
    $searchTerm: String!
    $userId: ID
    $section: String!
    $bundleId: String!
  ) {
    searchCourses(accountId: $accountId, pageNumber: $pageNumber, searchTerm: $searchTerm, section: $section) {
      isLastPage
      totalItemCount
      data {
        id
        name
        type
        accountId
        bundleIdList
        metaData {
          viewed
          link
          thumbnail
          cpd
          categories
        }
        userCourseMetaData(userId: $userId) {
          lastUpdatedAt
          courseProgress
        }
      }
    }
    getLearnBundleById(accountId: $accountId, bundleId: $bundleId) {
      id
      title
      description
      restricted
      startDate
      endDate
      courses
      isBundleHidden
      isbBundleShow
      isMandatoryBundle
    }
  }
`;

export const GET_BANNER = gql`
  query GetBanners($accountId: ID!) {
    getBanners(accountId: $accountId) {
      ...BannersFields
    }
  }
  ${BANNER_FRAGMENT}
`;

export const GET_COURSE_BY_ID = gql`
  query GetCourseById($id: ID!, $userIdNullable: ID, $userId: ID!, $accountId: ID!) {
    getCourseById(id: $id, accountId: $accountId) {
      name
      id
      type
      accountId
      metaData {
        link
        viewed
        thumbnail
        description
        timeToComplete
        progress
        duration
        views
        highQualityLink
        lowQualityLink
        downloadLink
        cpd
        passScore
        categories
        url
        openIframe
      }
      assessment {
        ...EvaluationFields
        questions {
          id
          text
          answers(userId: $userId, courseId: $id) {
            givenAt
            value
          }
        }
      }
      reaction {
        ...EvaluationFields
        questions {
          id
          text
          answers(userId: $userId, courseId: $id) {
            givenAt
            value
          }
        }
      }
      userCourseMetaData(userId: $userIdNullable) {
        lastUpdatedAt
        courseProgress
      }
    }
  }
  ${EVALUATION_FRAGMENT}
`;
