import { Query } from '@rio/rio-types';
import { GET_HOME_PAGE_EMISSION_DATA } from './index.queries';
import { usePageSuspendingQuery } from '~/hooks';
import { oneYearBefore, today } from '~/utils';
import { useMemo } from 'react';

type Response = Pick<Query, 'getEmissionsPercentageBreakdownByScope'>;

type Variables = {
  accountId: string;
  from: string;
  to: string;
};

type UseGetHomePageDataArgs = {
  accountId: string;
};

export function useGetHomePageEmissionData({ accountId }: UseGetHomePageDataArgs) {
  const dates = useMemo(() => {
    return {
      from: oneYearBefore().format('YYYY-MM-DD'),
      to: today().format('YYYY-MM-DD'),
    };
  }, []);

  return usePageSuspendingQuery<Response, Variables>(GET_HOME_PAGE_EMISSION_DATA, {
    variables: {
      accountId,
      ...dates,
    },
  });
}
