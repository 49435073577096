import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Page } from '@rio/ui-components';
import { RelatedCourseGrid } from './RelatedCourseGrid';
import SelectedCoursePreview from './SelectedCoursePreview';
import { useCoursePreviewPage, Response } from './useCoursePreviewPage';
import { ApolloError } from '@apollo/client';
import { useUserToken } from '~/hooks';

const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

const crumbs = [
  { label: <FormattedMessage id={'pages.coursesPreview.breadcrumbs.one'} />, to: '/v2/engage' },
  { label: <FormattedMessage id={'pages.coursesPreview.breadcrumbs.two'} /> },
];

const isError = (loading: boolean, error?: ApolloError, response?: Response): Error | undefined => {
  if (loading) {
    return;
  }
  if (error) {
    return new Error(error.message);
  }
  if (!response?.getUserCourse) {
    return new Error('Not available');
  }
  if (!response?.getUserCourse?.licence) {
    return new Error('No licence');
  }
};

const CoursePreview = () => {
  const { bundleId, id } = useParams();
  const { token } = useUserToken();
  const [coursePreviewResponse, getAvailableCoursesResponse] = useCoursePreviewPage({
    courseId: id!,
    userId: token.sub,
    bundleId,
  });

  return (
    <Page
      title={{
        crumbs: crumbs,
        content: <FormattedMessage id={'pages.coursesPreview.breadcrumbs.two'} />,
        actionButton: (
          <Button to="../" name="CoursePreview__Button-back" variant="filled">
            <FormattedMessage id="pages.coursesPreview.backToEngage" />
          </Button>
        ),
      }}
      error={isError(coursePreviewResponse.loading, coursePreviewResponse.error, coursePreviewResponse.data)}
    >
      <ContentContainer>
        {coursePreviewResponse.data?.getUserCourse && (
          <SelectedCoursePreview
            userId={token.sub}
            course={coursePreviewResponse.data?.getUserCourse}
            bundleId={bundleId}
            refetch={coursePreviewResponse.refetch}
          />
        )}
        {coursePreviewResponse.data?.getUserCourse && getAvailableCoursesResponse.data?.getAvailableCourses && (
          <RelatedCourseGrid
            relatedCourses={getAvailableCoursesResponse.data.getAvailableCourses?.data.filter((c) => c.id !== id)}
          />
        )}
      </ContentContainer>
    </Page>
  );
};

export { CoursePreview };
