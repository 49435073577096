import { ACCOUNT, LOCATION, TAG, DEPARTMENT, REGION, labels } from './innerConstant';
export * from './innerConstant';

export const scopeOptions = [
  {
    value: ACCOUNT,
    label: labels[ACCOUNT]
  },
  {
    value: LOCATION,
    label: labels[LOCATION]
  },
  {
    value: TAG,
    label: labels[TAG]
  }
];

export const scopeLegislationOptions = {
  [TAG]: labels[TAG],
  [LOCATION]: labels[LOCATION],
  [DEPARTMENT]: labels[DEPARTMENT],
  [REGION]: labels[REGION]
};
