import React from 'react';
import styled, { useTheme } from 'styled-components';

const Dot = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
`;

const determineDot = (reviewDateStatus, theme) => {
  const RedDot = styled(Dot)`
    background-color: ${theme.colors.danger.dark.background};
  `;

  const GreenDot = styled(Dot)`
    background-color: ${theme.colors.success.dark.background};
  `;

  const OrangeDot = styled(Dot)`
    background-color: ${theme.colors.warning.dark.background};
  `;

  switch (reviewDateStatus) {
    case 'Within date':
      return <GreenDot />;
    case 'Due for review':
      return <OrangeDot />;
    case 'Overdue':
      return <RedDot />;
    default:
      return null;
  }
};

export function ReviewStatusCell(props) {
  const theme = useTheme();

  const { data: { reviewDateStatus } = {} } = props;

  if (reviewDateStatus) {
    return determineDot(reviewDateStatus, theme);
  }

  return null;
}
