import { useMemo } from 'react';
import { Task } from '@rio/rio-types';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { styled, AgGrid } from '@rio/ui-components';
import { GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { toSentenceCase } from '~/utils';
import {
  StatusCell,
  OwnersCell,
  CreatorCell,
  DueDateCell,
  CategoryCell,
  PriorityCell,
  DescriptionCell,
  CompletedDateCell,
} from '../../GridCell/v2';
import { defaultConfig, options } from './options';

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 268px); // header + page title height

  .ag-sort-indicator-icon {
    display: flex;
    align-items: center;
  }
`;

interface GridComponentProps {
  tasks: Task[];
  canEdit?: boolean;
  onGridReady: (e: GridReadyEvent) => void;
}

export const GridComponent = (props: GridComponentProps) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { tasks, onGridReady } = props;
  const { columnDefs, ...agGridConfig } = options(formatMessage);

  const gridOptions = useMemo(() => {
    const components = {
      status: StatusCell,
      priority: PriorityCell,
      description: DescriptionCell,
      category: CategoryCell,
      owners: OwnersCell,
      creator: CreatorCell,
      dueDate: DueDateCell,
      completedAt: CompletedDateCell,
    };

    const onRowClicked = (e: RowClickedEvent) => {
      navigate(`/tasks/${e.data.id}`);
    };

    return {
      ...defaultConfig,
      ...agGridConfig,
      columnDefs: columnDefs?.map((d) => ({
        ...d,
        headerName: d.headerName ? toSentenceCase(d.headerName) : d.headerName,
      })),
      components,
      onGridReady,
      onRowClicked,
      rowData: tasks,
    };
  }, [agGridConfig, columnDefs, navigate, onGridReady, tasks]);

  return <AgGridStyled gridKey="tasksGrid" gridOptions={gridOptions} />;
};
