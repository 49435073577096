import { gql } from '@apollo/client';
import { ANOMALY_THRESHOLD_FRAGMENT } from '../../../queries/anomaly-threshold';

export const GET_ANOMALY_THRESHOLD = gql`
  query GetAnomalyThreshold($accountId: ID!) {
    getAnomalyThreshold(accountId: $accountId) {
      ...AnomalyThresholdFields
    }
  }
  ${ANOMALY_THRESHOLD_FRAGMENT}
`;

export const CREATE_ANOMALY_THRESHOLD = gql`
  mutation CreateAnomalyThreshold($accountId: ID!, $thresholdPercentage: Float!, $utilityType: UtilityTypeInput!) {
    createAnomalyThreshold(
      input: { accountId: $accountId, thresholdPercentage: $thresholdPercentage, utilityType: $utilityType }
    ) {
      ...AnomalyThresholdFields
    }
  }
  ${ANOMALY_THRESHOLD_FRAGMENT}
`;

export const UPDATE_ANOMALY_THRESHOLD = gql`
  mutation UpdateAnomalyThreshold(
    $id: ID!
    $accountId: ID!
    $thresholdPercentage: Float!
    $utilityType: UtilityTypeInput!
  ) {
    updateAnomalyThreshold(
      input: { id: $id, accountId: $accountId, thresholdPercentage: $thresholdPercentage, utilityType: $utilityType }
    ) {
      ...AnomalyThresholdFields
    }
  }
  ${ANOMALY_THRESHOLD_FRAGMENT}
`;

export const DELETE_ANOMALY_THRESHOLD = gql`
  mutation DeleteAnomalyThreshold($id: ID!) {
    deleteAnomalyThreshold(id: $id)
  }
`;

export const GET_DATA_TRACKER_ANOMALY_FILTERS = gql`
  query GetDataTrackerAnomalyFilters($accountId: ID, $field: String!) {
    getDataTrackerAnomalyFilters(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
