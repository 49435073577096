import { useLazyQuery, QueryHookOptions, OperationVariables, QueryTuple } from '@apollo/client';
import { usePageErrorContext, usePageLoadingContext } from '@rio/ui-components';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';

/**
 * @description Wrapper around useLazyQuery that encapsulates loading and error state
 * @param queryDocument DocumentNode - options that will be bypassed to useLazyQuery
 * @param options QueryHookOptions<Response, Variables> = {} - will be bypassed to useLazyQuery
 * @returns QueryTuple<Response, Variables>
 */
export function usePageSuspendingLazyQuery<Response = any, Variables = OperationVariables>(
  queryDocument: DocumentNode,
  options: QueryHookOptions<Response, Variables> = {}
): QueryTuple<Response, Variables> {
  const { setLoading: setPageLoading } = usePageLoadingContext();

  const { setError } = usePageErrorContext();

  const [query, state] = useLazyQuery<Response, Variables>(queryDocument, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  /**
   * Wraps function to add handling of loading and error state
   */
  const wrapper = useCallback(
    async (...args) => {
      try {
        setPageLoading(true);
        return await query(...args);
      } catch (err) {
        setError(err as unknown as Error);
        throw err;
      } finally {
        setPageLoading(false);
      }
    },
    [setPageLoading, setError, query]
  );
  return [wrapper, state];
}
