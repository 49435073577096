import { UserPermissions } from '@rio/rio-types';
import { usePermissions, useUserToken } from '~/hooks';
import { DateLike } from '~/types';

export interface RollbackCellProps {
  data: {
    lastUpdated: DateLike;
    id: string;
    status: string;
    userId: string;
  };
  rollBackStatusLink: (id: string) => React.ReactNode;
}

const checkPermissions = (permissions: UserPermissions, action: string, isOwnResource: boolean) =>
  !!permissions.data?.find((a) => a?.startsWith?.(action) && !a?.endsWith?.('Own')) ||
  (permissions.data?.includes(`${action}Own`) && isOwnResource);

export const RollbackCell = ({ data, rollBackStatusLink }: RollbackCellProps) => {
  const { token } = useUserToken();

  const permissions = usePermissions();

  const isOwnResource = data.userId === token.sub;

  const isCompleted = data.status === 'Completed';

  const showRollbackButton = isCompleted && checkPermissions(permissions, 'rollbackImportBatch', isOwnResource);

  return <>{showRollbackButton && rollBackStatusLink(data.id)}</>;
};
