import { gql } from '@apollo/client';

export const GET_WASTE_CONTRACTORS_PAGED = gql`
  query GetWasteContractorsPaged($page: Int!, $pageSize: Int!, $filterValue: String, $filterBy: String) {
    getWasteContractorsPaged(
      page: $page
      pageSize: $pageSize
      filterValue: $filterValue
      filterExpression: EQUAL
      filterBy: $filterBy
    ) {
      count
      hasNextPage
      hasPreviousPage
      isFirstPage
      isLastPage
      pageCount
      totalItemCount
      page
      pageSize
      wasteContractors {
        name
        id
        address {
          id
          address1
          address2
          address3
          city
          phone1
          postcode
          country {
            alpha2Code
            name
            id
          }
        }
      }
    }
  }
`;

export const CREATE_CONTRACTOR = gql`
  mutation CreateWasteContractor(
    $id: ID!
    $name: String!
    $addressId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $countryId: ID!
  ) {
    createAddress(
      input: {
        id: $addressId
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
      }
    ) {
      id
    }
    createWasteContractor(input: { id: $id, name: $name, addressId: $addressId }) {
      id
      name
    }
  }
`;

export const UPDATE_CONTRACTOR = gql`
  mutation UpdateWasteContractor(
    $id: ID!
    $name: String!
    $addressId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $countryId: ID!
  ) {
    updateAddress(
      input: {
        id: $addressId
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
      }
    ) {
      id
      address1
      address2
      address3
      city
      postcode
      phone1
      country {
        alpha2Code
        name
        id
      }
    }
    updateWasteContractor(input: { id: $id, name: $name, addressId: $addressId }) {
      id
      name
    }
  }
`;

export const GET_PAGE = gql`
  {
    wasteContractorsGridPage @client
  }
`;

export const GET_TOTAL_PAGES = gql`
  {
    wasteContractorsGridTotalPages @client
  }
`;

export const SET_PAGE = gql`
  mutation SetWasteContractorsGridPage($page: Int!) {
    setWasteContractorsGridPage(page: $page) @client
  }
`;

export const SET_TOTAL_PAGES = gql`
  mutation SetWasteContractorsGridTotalPages($totalPages: Int!) {
    setWasteContractorsGridTotalPages(totalPages: $totalPages) @client
  }
`;

export const GET_SEARCH = gql`
  {
    wasteContractorsGridSearch @client
  }
`;

export const SET_SEARCH = gql`
  mutation SetWasteContractorsGridSearch($search: String!) {
    setWasteContractorsGridSearch(search: $search) @client
  }
`;
