import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { EnergySource, SupplierType } from '@rio/rio-types';
import { LinearProgress, Checkbox, Grid, Text, OptionsProps } from '@rio/ui-components';
import { DIRECT, INDIRECT } from '~/constants/transactionActivityTypes';
import { useGetMeters, useCurrentAccountId, usePermissions, useCurrency } from '~/hooks';
import { DataForm } from '../DataFormModal/v2';
import { TariffSelectV2 as TariffSelect } from '../TariffSelect';
import { CreateMeterModal } from '../CreateMeterModal/v2';
import { SupplierSelector } from '../SuppliersSelector/v2';
import { SelectRenderV2 as SelectRender } from '../DataForm';
import { GridStyled, SectionStyled, TextFieldStyled } from '../WasteDataForm/style';
import { NumberInput, NumberInputWithNegativeOption } from '../UI/V2';

const CREATE_NEW_METER_OPTION = {
  value: 'createNew',
  label: 'Create new ...',
};

export const EnergyDataFormV2 = (props) => {
  const { form = {}, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, register, watch } = form;

  const [isMeterFormVisible, setMeterFormVisible] = useState(false);
  const [currencyInitialState] = useState(form.getValues('data.currency'));
  const [metersOptions, setMetersOptions] = useState<OptionsProps[]>([]);

  const permissions = usePermissions();
  const canCreateMeters = permissions.data.find((action) => action.startsWith('createEnergySource'));
  const handleGetMetersCompleted = useCallback(
    (res) => {
      const metersList = res?.getEnergySourceByAccountIdAndType?.rows || [];

      const result = metersList.map((m) => ({ value: m.id, label: m.name }));

      if (canCreateMeters) {
        result.unshift(CREATE_NEW_METER_OPTION);
      }

      return setMetersOptions(result);
    },
    [canCreateMeters]
  );

  const accountId = useCurrentAccountId();
  const { data: currenciesList, loading: currenciesListLoading } = useCurrency();
  const {
    data: meters,
    loading: metersLoading,
    refetch: refetchMeters,
  } = useGetMeters(dataSection, {
    onCompleted: handleGetMetersCompleted,
    withLocationData: true,
    fetchPolicy: 'network-only',
  });

  const getFullMeterById = useCallback(
    (id: string, metersList?: EnergySource[]) => (metersList || meters).find((m) => m.id === id),
    [meters]
  );

  useEffect(() => {
    register('isReferenceDataRequired');
  }, [register]);

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const setReferenceDataRequired = (value) => setValue('isReferenceDataRequired', value);

  const onCreateNewMeterClick = useCallback(() => {
    setMeterFormVisible(true);
  }, []);
  const closeCreatedMeterForm = useCallback(() => setMeterFormVisible(false), []);
  const selectCreatedMeter = useCallback(
    async (key: string, newMeter: OptionsProps) => {
      const { data } = await refetchMeters();
      const refetchedMeters = data?.getEnergySourceByAccountIdAndType?.rows || [];

      const newFullMeter = getFullMeterById(newMeter.value, refetchedMeters);
      if (newFullMeter) {
        setValue(key, newFullMeter);
      }

      closeCreatedMeterForm();
    },
    [closeCreatedMeterForm, setValue, refetchMeters, getFullMeterById]
  );

  const showReferenceData = () => setReferenceDataRequired(true);
  const hideReferenceData = () => setReferenceDataRequired(false);

  const handleOnSubmit = ({ data, financial, reference, ...others }) => {
    const formData = {
      ...others,
      data: {
        ...data,
        accuracy: data?.accuracy?.label || 'ACT',
        activity: data.activity.label,
        currency: financial?.currency?.label || currencyInitialState,
      },
    };

    if (isReferenceDataRequired) {
      formData.data = { ...formData.data, ...reference };
    }

    onSubmit(formData);
  };

  const dropdownOption = (el) => ({
    label: el.name,
    value: el.id,
  });

  const actEstOptions = [
    { id: 'ACT', name: 'ACT' },
    { id: 'EST', name: 'EST' },
  ]?.map(dropdownOption);

  const activityOptions = [
    { id: DIRECT, name: DIRECT },
    { id: INDIRECT, name: INDIRECT },
  ]?.map(dropdownOption);

  const currencyOptions = (currenciesList || [])?.map(dropdownOption);

  const currencyDefaultValue = currencyOptions.find((item) => item.label === currencyInitialState)?.value;

  const isLoading = metersLoading || currenciesListLoading;

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showEndDate showTime>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Grid container>
          {isMeterFormVisible && (
            <CreateMeterModal
              energyType={dataSection}
              onSuccess={selectCreatedMeter}
              onDismiss={() => setMeterFormVisible(false)}
            />
          )}

          <SectionStyled isVisible={true}>
            <Text typescale="title" size="small">
              Please enter the following transactional {dataSection.toLowerCase()} data:
            </Text>

            <Controller
              name="data.energySource"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  label="Meter name"
                  onCreateNew={onCreateNewMeterClick}
                  options={metersOptions}
                  error={fieldState.error?.message}
                  onChange={(e) => {
                    if (e.value === CREATE_NEW_METER_OPTION.value) {
                      return;
                    }

                    const selectedFullMeter = getFullMeterById(e.value);
                    if (selectedFullMeter) {
                      setValue('data.energySource', selectedFullMeter);
                    }
                  }}
                  value={field.value?.id || ''}
                />
              )}
              rules={{ required: 'Meter name is required' }}
              defaultValue={null}
              control={control}
            />

            <Controller
              name="data.tariff"
              render={({ field }) => (
                <TariffSelect
                  {...field}
                  value={field?.value?.value}
                  label="Tariff (optional)"
                  onChange={(e) => setValue('data.tariff', e)}
                  accountId={accountId}
                  valueAsLabel
                />
              )}
              defaultValue={null}
              control={control}
            />

            <Controller
              name="data.quantity"
              render={({ field, fieldState }) => (
                <NumberInputWithNegativeOption label="Quantity (kWh)" {...field} error={fieldState.error?.message} />
              )}
              rules={{ required: 'Quantity is required' }}
              control={control}
            />

            <Controller
              name="data.totalCost"
              render={({ field }) => <NumberInput label="Total cost (optional)" {...field} />}
              control={control}
              defaultValue=""
            />

            <Controller
              name="financial.currency"
              render={({ field }) => (
                <SelectRender
                  {...field}
                  value={field?.value?.value}
                  label="Currency"
                  onChange={(e) => setValue('financial.currency', e)}
                  options={currencyOptions}
                />
              )}
              defaultValue={currencyDefaultValue}
              control={control}
            />

            <Controller
              name="data.accuracy"
              render={({ field }) => (
                <SelectRender
                  {...field}
                  value={field?.value?.value}
                  label="Act/Est (optional)"
                  onChange={(e) => setValue('data.accuracy', e)}
                  options={actEstOptions}
                />
              )}
              defaultValue={null}
              control={control}
            />

            <Controller
              name="data.supplier"
              render={({ field, fieldState }) => (
                <SupplierSelector
                  {...field}
                  value={field?.value?.value}
                  accountId={accountId}
                  label="Supplier (optional)"
                  onChange={(e) => setValue('data.supplier', e)}
                  error={fieldState.error?.message}
                  utility={dataSection}
                  supplierType={SupplierType.Supplier}
                />
              )}
              defaultValue={null}
              control={control}
            />

            <Controller
              name="data.charge/kwh"
              render={({ field }) => <NumberInputWithNegativeOption label="Charge/kWh (optional)" {...field} />}
              defaultValue=""
              control={control}
            />

            <Controller
              name="data.activity"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  value={field?.value?.value}
                  label="Activity"
                  onChange={(e) => setValue('data.activity', e)}
                  options={activityOptions}
                  error={fieldState.error?.message}
                />
              )}
              rules={{ required: 'Activity is required' }}
              defaultValue={DIRECT}
              control={control}
            />

            <Controller
              name="data.comment"
              render={({ field }) => <TextFieldStyled label="Comment (optional)" {...field} />}
              control={control}
              defaultValue=""
            />

            <GridStyled container>
              <Text typescale="title" size="medium">
                Do you want to add any references?
              </Text>

              <Grid container>
                <Checkbox
                  name="Reference"
                  label="Yes"
                  checked={!!isReferenceDataRequired}
                  onChange={showReferenceData}
                />
                <Checkbox
                  name="NoReference"
                  label="No"
                  checked={!isReferenceDataRequired}
                  onChange={hideReferenceData}
                />
              </Grid>
            </GridStyled>

            <SectionStyled isVisible={isReferenceDataRequired}>
              <Controller
                name="reference.invoiceNumber"
                render={({ field }) => <TextFieldStyled label="Invoice number (optional)" {...field} />}
                defaultValue=""
                control={control}
              />

              <Controller
                name="reference.evidenceReference"
                render={({ field }) => <TextFieldStyled label="Evidence reference (optional)" {...field} />}
                defaultValue=""
                control={control}
              />

              <Controller
                name="reference.otherReference"
                render={({ field }) => <TextFieldStyled label="Other reference (optional)" {...field} />}
                defaultValue=""
                control={control}
              />
            </SectionStyled>
            {props.children}
          </SectionStyled>
        </Grid>
      )}
    </DataForm>
  );
};
