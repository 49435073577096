import { useCallback } from 'react';
import { get } from 'lodash';
import { AutocompleteField as Field } from 'rio-ui-components';
import { FloatQuestion, IntegerQuestion } from '@rio/rio-types';

interface NumericQuestionFieldProps {
  question: FloatQuestion | IntegerQuestion;
  answerOnBlur?: boolean;
  onAnswer: (updatedQuestion: FloatQuestion | IntegerQuestion) => void;
}

export function NumericQuestionField({ question, onAnswer, answerOnBlur }: NumericQuestionFieldProps) {
  const saveAnswer = useCallback(
    (answer: number) =>
      onAnswer({
        ...question,
        [question.__typename === 'FloatQuestion' ? 'reportedFloat' : 'reportedInt']: answer
      }),
    [question, onAnswer]
  );
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      saveAnswer(+e.target.value);
    },
    [saveAnswer]
  );
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      saveAnswer(+e.target.value);
    },
    [saveAnswer]
  );
  const handleProps = answerOnBlur ? { onBlur: handleBlur } : { onChange: handleChange };

  return (
    <Field
      fullWidth
      name={question.id}
      type="number"
      id={question.id}
      defaultValue={
        question.__typename === 'FloatQuestion' ? get(question, 'reportedFloat', '') : get(question, 'reportedInt', '')
      }
      placeholder={question.shortFormulation || question.formulation}
      label={question.shortFormulation || question.formulation}
      {...handleProps}
    />
  );
}
