import { useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'rio-ui-components';
import { FlowSubscription } from '@rio/rio-types';
import FlowDetails from './FlowDetails';
import { CREATE_FLOW_SUBSCRIPTION, GET_ALL_FLOW_SUBSCRIPTIONS } from './index.queries';
import { ValidateFlowDetails } from './validate';
import { FlowSubscriptionDetails } from './types';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

interface GetAllFlowSubscriptionsData {
  getFlowSubscriptions: FlowSubscription[];
}

interface CreateFlowSubscriptionModalProps {
  onDismiss: () => void;
  onComplete: (result: { message: string; colour: string }) => void;
}

const CreateFlowSubscriptionModal: React.FC<CreateFlowSubscriptionModalProps> = ({ onDismiss, onComplete }) => {
  const [flowSubscriptionDetails, setFlowSubscriptionDetails] = useState<FlowSubscriptionDetails>({
    accountId: '',
    flowId: '',
  });

  const [createFlowSubscriptionMutation] = useMutation(CREATE_FLOW_SUBSCRIPTION, {
    onCompleted: () => {
      onComplete({
        message: 'Flow subscription has been created.',
        colour: 'success',
      });
      onDismiss();
    },
    onError: () => {
      onComplete({
        message: 'Something went wrong! Please try again later.',
        colour: 'danger',
      });
    },
    update: (cache, { data: { createFlowSubscription } }) => {
      const existingFlowSubscriptions = cache.readQuery<GetAllFlowSubscriptionsData>({
        query: GET_ALL_FLOW_SUBSCRIPTIONS,
      });
      if (existingFlowSubscriptions && createFlowSubscription) {
        const newFlowSubscriptions = [...existingFlowSubscriptions.getFlowSubscriptions, createFlowSubscription];
        cache.writeQuery({
          query: GET_ALL_FLOW_SUBSCRIPTIONS,
          data: { getFlowSubscriptions: newFlowSubscriptions },
        });
      }
    },
  });

  const handleSubmit = useCallback(() => {
    createFlowSubscriptionMutation({
      variables: {
        accountId: flowSubscriptionDetails.accountId,
        flowId: flowSubscriptionDetails.flowId,
      },
    });
  }, [createFlowSubscriptionMutation, flowSubscriptionDetails]);

  return (
    <Modal size="md" show onDismiss={onDismiss}>
      <ContainerStyled>
        <FlowDetails
          heading="Create new Flow Subscription"
          flowSubscriptionDetails={flowSubscriptionDetails}
          setFlowSubscriptionDetails={setFlowSubscriptionDetails}
        />
        <Row container align="between">
          <ColStyled item>
            <Button disabled={!ValidateFlowDetails(flowSubscriptionDetails)} onClick={handleSubmit}>
              Submit
            </Button>
          </ColStyled>
        </Row>
      </ContainerStyled>
    </Modal>
  );
};

export default CreateFlowSubscriptionModal;
