import React from 'react';
import styled from 'styled-components';

const UploadMessage = styled.div`
  color: ${({ theme, error }) => (error ? theme.colors.danger.normal.background : 'black')};
`;

const ModalContentContainer = styled.div`
  margin: 15px;
  margin-top: 35px;
  padding: 15px;
  border-radius: 25px;
  background: ${({ theme }) => theme.colors.neutral.normal.background};
  max-height: 580px;
  overflow-y: auto;
`;

const ModalTitle = styled.div`
  margin: 20px;
`;

const sortUploads = (a, b) => {
  if (a.row > b.row) return 1;
  if (a.row < b.row) return -1;
  return 0;
};

const mapUploads = (item) => {
  return (
    <UploadMessage error={item.type === 'error'}>
      {item.message}
      {item.type === 'error' ? ` at row ${item.row + 2}` : ''}
    </UploadMessage>
  );
};

const displayUploads = (uploadedItems) => uploadedItems.sort(sortUploads).map(mapUploads);

const ModalContent = ({ uploadedItems }) => {
  return (
    <>
      <ModalTitle>Your uploads</ModalTitle>
      <ModalContentContainer>{displayUploads(uploadedItems)}</ModalContentContainer>
    </>
  );
};

export default ModalContent;
