import React, { Fragment } from 'react';
import { Row, Col, Text } from 'rio-ui-components';
import styled from 'styled-components';
import { ContainerTypeRow } from './ContainerTypeRow';
import { gridConfig } from './gridConfig';
import alphabetise from '../../../utils/alphabetise';

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const RowsContainer = styled.div`
  flex: 1 1 auto;
  overflow: scroll;
`;

function sorter(t1, t2) {
  return alphabetise(t1.name, t2.name);
}

export function ContainerTypesGrid({ typeSizes, config = gridConfig }) {
  return (
    <Fragment>
      <RowStyled align="between" container>
        {Object.keys(config).map((column) => (
          <Col item key={column} span={config[column].width}>
            <Text weight="bold">{config[column].columnName}</Text>
          </Col>
        ))}
      </RowStyled>
      <RowsContainer>
        {typeSizes
          .slice()
          .sort(sorter)
          .map((t) => (
            <ContainerTypeRow containerType={t} key={t.id} config={config} />
          ))}
      </RowsContainer>
    </Fragment>
  );
}
