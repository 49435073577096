import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { IServerSideGetRowsParams, GridReadyEvent, GridApi } from 'ag-grid-community';
import styled from 'styled-components';
import { getAgRowId, mapFilterModel, mapSortModel } from '~/utils';
import { useGetDataAnomalies, useIgnoreDataAnomalies } from '../../queries';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { PAGE_SIZE } from '../../constants';
import { Placeholder } from '../DataAnomalies';
import { handleSelection } from './handle-selection';
import { useColumnDefs } from './useColumnDefs';
import { usePermissions } from '~/hooks';

interface DataAnomaliesGridProps {
  accountId: string;
  onError: (err: Error) => void;
  showPlaceholder: () => void;
}

export const GridContainer = styled.div`
  & .grid-body {
    display: flex;
    flex-direction: column;
    min-height: 300px;
  }
  & .ag-root-wrapper {
    flex: 1;
  }
  .ag-root-wrapper-body {
    flex: 1;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonContainer = styled.div`
  margin-right: 10px;
`;

const defaultColDef = {
  resizable: true,
};

export function DataAnomaliesGrid({ accountId, onError, showPlaceholder }: DataAnomaliesGridProps) {
  const intl = useIntl();

  const [getDataAnomalies] = useGetDataAnomalies();

  const gridApiRef = useRef<GridApi>();

  const [dialogShown, setDialogShown] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const permissions = usePermissions();

  const handleToggleDialog = useCallback(() => setDialogShown((shown) => !shown), []);

  const [ignoreDataAnomalies] = useIgnoreDataAnomalies();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApiRef.current = event.api;
    event.api.setFilterModel({
      ignored: {
        values: ['0'],
        filterType: 'set',
      },
    });
  }, []);

  const dataSource = useMemo(
    () => ({
      getRows: async ({ request, success, fail }: IServerSideGetRowsParams) => {
        const sort = mapSortModel(request.sortModel);
        const filters = mapFilterModel(request.filterModel);
        try {
          const { data, error } = await getDataAnomalies({
            variables: {
              accountId,
              sort,
              filters,
              limit: PAGE_SIZE,
              offset: request.startRow || 0,
            },
          });
          if (!data) {
            throw new Error('Error in get getDataAnomalies');
          }
          if (error) {
            throw error;
          }
          if (!data.getDataAnomaliesByAccountId.rows.length && !Object.keys(request.filterModel!).length) {
            return showPlaceholder();
          }
          success({
            rowData: data.getDataAnomaliesByAccountId.rows,
            rowCount: data.getDataAnomaliesByAccountId.totalRows,
          });
        } catch (err) {
          onError(err as Error);
          fail();
        }
      },
    }),
    [getDataAnomalies, accountId, onError, showPlaceholder]
  );

  const handleAccept = useCallback(() => {
    if (!gridApiRef.current) {
      throw new Error('Anomalies are not ready');
    }
    const rows = gridApiRef.current.getSelectedRows();

    ignoreDataAnomalies({
      variables: {
        anomalyIds: rows.map((row) => row.id),
      },
      onCompleted: () => {
        gridApiRef.current?.refreshServerSide();
      },
    });
  }, [ignoreDataAnomalies]);

  const onSelectionChanged = () => {
    if (!gridApiRef.current) {
      throw new Error('Anomalies are not ready');
    }

    const selectedRowsList = gridApiRef.current.getSelectedRows();
    setIsSelected(!!selectedRowsList?.length);
  };

  const columnDefs = useColumnDefs();

  return (
    <GridContainer className="ag-theme-alpine">
      {permissions.dataTrackerAnomalies.find((a) => a.startsWith('ignoreDataAnomalies')) && (
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              variant="outlined"
              onClick={() => handleSelection(gridApiRef.current, PAGE_SIZE)}
              disabled={!gridApiRef.current}
              title={intl.formatMessage({ id: 'pages.data.overview.selectAll' })}
            >
              <FormattedMessage id="pages.data.overview.selectAllButtonText" />
            </Button>
          </ButtonContainer>
          {isSelected && (
            <ButtonContainer>
              <Button
                variant="outlined"
                onClick={() => handleSelection(gridApiRef.current, PAGE_SIZE, true)}
                disabled={!gridApiRef.current}
                title={intl.formatMessage({ id: 'pages.data.overview.deselectAll' })}
              >
                <FormattedMessage id="pages.data.overview.deselectAllButtonText" />
              </Button>
            </ButtonContainer>
          )}
          <ButtonContainer>
            <Button
              variant="outlined"
              onClick={handleToggleDialog}
              title={intl.formatMessage({ id: 'pages.data.overview.acceptInfo' })}
            >
              <FormattedMessage id="pages.data.overview.accept" />
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      )}
      <AgGridReact
        suppressRowClickSelection={true}
        pagination
        paginationPageSize={PAGE_SIZE}
        cacheBlockSize={PAGE_SIZE}
        rowModelType="serverSide"
        serverSideDatasource={dataSource}
        className="grid-body"
        rowSelection="multiple"
        onGridReady={handleGridReady}
        defaultColDef={defaultColDef}
        getRowId={getAgRowId('id')}
        noRowsOverlayComponent={Placeholder}
        columnDefs={columnDefs}
        onSelectionChanged={onSelectionChanged}
      />

      {dialogShown && (
        <ConfirmationDialog
          title={<FormattedMessage id="pages.data.overview.confirmationDialog.title" />}
          agreeButtonCaption={<FormattedMessage id="pages.data.overview.confirmationDialog.agree" />}
          disagreeButtonCaption={<FormattedMessage id="pages.data.overview.confirmationDialog.disagree" />}
          onAgree={handleAccept}
          onClose={handleToggleDialog}
        />
      )}
    </GridContainer>
  );
}
