import * as Sentry from '@sentry/react';
import { useRouteError } from 'react-router-dom';
import { LoadFailed, styled } from '@rio/ui-components';
import { useEffect } from 'react';

const Container = styled(`div`)`
  height: 100%;
`;

export const ErrorElement = () => {
  const error = useRouteError();

  useEffect(() => {
    window.console.error(error);
    Sentry.captureException(error);
  }, [error]);

  if (error) {
    return (
      <Container>
        <LoadFailed title="This page failed to load" error={error as any} />
      </Container>
    );
  }

  return null;
};
