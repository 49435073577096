import { Label, Text } from 'rio-ui-components';
import { PropertySheet } from 'rio-ui-components';
import { RadioButton, Checkbox, styled } from '@rio/ui-components';
import { FormControlLabel, FormGroup } from '@mui/material';
import { RecipientsGrid } from '../../SurveysContainer/RecipientsGridV2';
import { RecipientSurveyType, useSurveyForm } from '../../SurveysContainer/hooks/useSurveyForm';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionSubText = styled(Text)`
  padding: 0;
  padding-left: 30px;
  font-family: ${({ theme }) => theme.sys.typescale.label.large.family};
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const RowStyle = styled(PropertySheet.TextColumn)`
  font-family: ${({ theme }) => theme.sys.typescale.label.large.family};
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const LabelStyle = styled(Label)`
  font-family: ${({ theme }) => theme.sys.typescale.label.large.family};
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const ColumnStyle = styled('div')``;

export type RecipientsProp = {
  hideNotifyByEmail?: boolean;
};

const FormControlLabelStyle = styled(FormControlLabel)`
  .Mui-checked ~ svg {
    path {
      fill: ${({ theme }) => theme.sys.color.primary} !important;
    }
  }
`;

export const Recipients = ({ hideNotifyByEmail }: RecipientsProp) => {
  const {
    state: survey,
    surveyType,
    toggleNotifyByEmail,
    selectIndividualContributors,
    selectContributors,
  } = useSurveyForm();

  return (
    <>
      <PropertySheet.Row>
        {!hideNotifyByEmail && (
          <RowStyle size="sm" color={'normal'}>
            Your survey recipients will be asked to fill in these survey/s
          </RowStyle>
        )}
      </PropertySheet.Row>
      <PropertySheet.Row>
        <LabelStyle>Recipients</LabelStyle>
      </PropertySheet.Row>
      <FormGroup aria-label="position" row>
        <FormControlLabelStyle
          control={
            <RadioButton
              name="contributorsType"
              checked={surveyType === RecipientSurveyType.user}
              value={RecipientSurveyType.user}
              onChange={() => selectIndividualContributors([])}
            />
          }
          label={
            <Text size="md" color="normal">
              One survey per user
            </Text>
          }
          labelPlacement="end"
        />
        <FormControlLabelStyle
          control={
            <RadioButton
              name="contributorsType"
              checked={surveyType === RecipientSurveyType.contributor}
              value={RecipientSurveyType.contributor}
              onChange={() => selectContributors([])}
            />
          }
          label={
            <Text size="md" color="normal">
              One survey per account
            </Text>
          }
          labelPlacement="end"
        />
      </FormGroup>
      {!hideNotifyByEmail && (
        <ColumnStyle>
          <Checkbox
            id="AddSurveyForm_Checkbox_SendAllContributors"
            onChange={() => toggleNotifyByEmail()}
            name="notifyByEmail"
            label="Notify recipients by email"
            checked={survey.notifyByEmail}
          />
          <Container>
            <OptionSubText size="sm" color="normal">
              Contact a system administrator to add, list and manage contributors.
              <br />
              All users listed for each contributor will receive the survey.
            </OptionSubText>
          </Container>
        </ColumnStyle>
      )}
      <RecipientsGrid />
    </>
  );
};
