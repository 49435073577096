import { gql } from '@apollo/client';

export const GET_SUBSCRIBED_DASHBOARDS = gql`
  query GetSubscribedDashboards($accountId: ID!) {
    getSubscribedDashboards(accountId: $accountId) {
      id
      name
      description
      area
      provider
      providerDashboardId
      thumbnailUrl
    }
  }
`;
