import { ConfigurationAliases } from './index';
import { AliasType } from '@rio/rio-types';

const AliasTypes = [
  { aliasType: AliasType.Transportfueltype, label: 'Transport Fuel Type' },
  { aliasType: AliasType.Transport, label: 'Transport' },
  { aliasType: AliasType.Journeytarget, label: 'Purpose' },
  { aliasType: AliasType.Transportsubtype, label: 'Transport Subtype' }
];

function ConfigurationAliasesTransport({ accountId }) {
  return <ConfigurationAliases accountId={accountId} aliasTypes={AliasTypes} />;
}
export default ConfigurationAliasesTransport;
