import styled from 'styled-components';
import { Heading, Text, PropertySheet } from 'rio-ui-components';
import { useApolloClient, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD, GET_LOGIN_ERROR_MESSAGE } from '../../queries/auth';
import { useNotification } from '~/hooks';

const HeadingStyled = styled(Heading)`
  padding-left: ${(p) => p.theme.geometry.xs.spacing};
  margin-bottom: ${(p) => p.theme.geometry.md.spacing};
  text-align: center;
`;
const TextStyled = styled(Text)`
  padding-left: ${(p) => p.theme.geometry.xs.spacing};
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
  text-align: center;
`;
interface SetPasswordIssueProps {
  email: string;
}
export function SetPasswordIssue({ email }: SetPasswordIssueProps) {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const client = useApolloClient();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      showNotification(`Verification code has been sent to ${email}. Please enter it below to set your new password.`);
      client.cache.writeQuery({
        query: GET_LOGIN_ERROR_MESSAGE,
        data: {
          loginError: '',
        },
      });
      navigate(`/reset-password?username=${email}`);
    },
    onError: () => {
      showNotification("Unfortunately we coulndn't reset your password. Please contact support@rio.ai for help.");
    },
  });

  return (
    <PropertySheet>
      <HeadingStyled size="lg">We think you’ve already set your password.</HeadingStyled>
      <TextStyled>Please use one of the options below</TextStyled>

      <PropertySheet.Row>
        <PropertySheet.ButtonColumn size="sm" span={6} color="primary" onClick={() => navigate('/login')}>
          Login
        </PropertySheet.ButtonColumn>
        <PropertySheet.ButtonColumn
          size="sm"
          span={6}
          color="secondary"
          onClick={() => forgotPassword({ variables: { username: email } })}
        >
          Reset password
        </PropertySheet.ButtonColumn>
      </PropertySheet.Row>
    </PropertySheet>
  );
}
