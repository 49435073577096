import { useMutation } from '@apollo/client';
import { Mutation, Query, Account } from '@rio/rio-types';
import { useNotification } from '../../../hooks';
import { CREATE_ACCOUNT, GET_ACCOUNTS, GET_ACCOUNT_BY_ID } from './index.queries';
import { GET_ACCOUNTS_VARIABLES } from './useGetAccounts';

export function useCreateAccount(callback: Function) {
  const { showNotification } = useNotification();
  return useMutation<Mutation>(CREATE_ACCOUNT, {
    onCompleted: ({ createAccount }) => {
      showNotification(`The account ${createAccount.name} has been created!`, 'success');
      callback();
    },
    onError: () => {
      showNotification('Something went wrong. If the problem persists, contact support.', 'danger');
    },
    update: (cache, result) => {
      const { getAccounts } = cache.readQuery<Query>({
        query: GET_ACCOUNTS,
        variables: GET_ACCOUNTS_VARIABLES,
      }) as Query;
      const createAccount = result.data?.createAccount;
      const createdAccount: Account = {
        ...createAccount,
        reportingYear: result.data?.setAccountReportingYear,
      } as Account;
      const createdAccountId = createdAccount.id;
      cache.writeQuery({
        query: GET_ACCOUNTS,
        variables: GET_ACCOUNTS_VARIABLES,
        data: {
          getAccounts: {
            ...getAccounts,
            accounts: getAccounts.rows.concat([
              {
                id: createdAccount.id,
                name: createdAccount.name,
                accessControls: createdAccount.accessControls,
                type: createdAccount.type,
                children: createdAccount.children,
              },
            ]),
          },
        },
      });
      cache.writeQuery({
        query: GET_ACCOUNT_BY_ID,
        variables: {
          id: createdAccountId,
        },
        data: { getAccountInfo: createdAccount },
      });
    },
  });
}
