import { read, write, utils, writeFile, ParsingOptions, BookType } from 'xlsx';
import moment, { Moment } from 'moment';
import { mapValues } from 'lodash';
import { stringify } from 'csv-stringify/browser/esm';
import { DateLike, Dictionary, TimeTuple } from '../types';
import { isDateLike, ensureIsDate } from './dateUtils';

export function getHeadersFromBinary(data: string | ArrayBuffer | null, options: ParsingOptions) {
  const workbook = read(data, options);
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  if (!sheet['!ref']) {
    return [];
  }
  const range = utils.decode_range(sheet['!ref']);
  let C;
  const R = range.s.r; /* start in the first row */
  /* move to every column in the range */
  const headers: unknown[] = [];
  for (C = range.s.c; C <= range.e.c; C += 1) {
    const cell = utils.encode_cell({ c: C, r: R });
    const value = sheet[cell]; /* find the cell in the first row */
    if (value) {
      headers.push(value.v);
    }
  }
  return headers;
}

export function getHeaders(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const headers = getHeadersFromBinary(reader.result, { type: 'array' });
      resolve(headers);
    };
    reader.onerror = reject;

    reader.readAsArrayBuffer(blob);
  });
}

export const parseSpreadsheet = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const workbook = read(reader.result, { cellDates: true, dateNF: 'dd/MM/YYYY' });
        const sheetNames = workbook.SheetNames;
        const sheet = workbook.Sheets[sheetNames[0]];
        resolve(utils.sheet_to_json(sheet));
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const generateAndDownloadTemplate = (columns: string[], fileName: string, exampleValues: unknown[] = []) => {
  const wb = utils.book_new();
  const fakeRow: Dictionary<unknown> = {};
  columns.forEach((element, index) => {
    fakeRow[element] = exampleValues[index];
  });
  const ws = utils.json_to_sheet([fakeRow]);
  utils.book_append_sheet(wb, ws, 'Data');
  writeFile(wb, `${fileName}.xlsx`);
};

// This function differs from the function above. generateSpreadsheet allows for generating
// multiple rows, where the values are keyed by the column name (not supplied in an ordered array)
// along with supplying a custome parsing function
export const generateSpreadsheet = (
  columns: string[],
  fileName: string,
  rows: object[] = [],
  parser: (answer: unknown) => string = (a) => a as string
) => {
  const wb = utils.book_new();
  const generatedRows = rows.map((submission) => {
    const row = {};
    columns.forEach((element) => {
      row[element] = parser(submission[element]);
    });
    return row;
  });
  const ws = utils.json_to_sheet(generatedRows);
  utils.book_append_sheet(wb, ws, 'Data');
  writeFile(wb, `${fileName}.xlsx`);
};

export async function tryParseSpreadsheet(file: File) {
  try {
    const rows = await parseSpreadsheet(file);
    return [null, rows];
  } catch (err) {
    return [err, null];
  }
}

export function parseDate(yourNumber: number) {
  const utcValue = Math.floor(yourNumber - 25569) * 86400;
  const date = new Date(utcValue * 1000);
  return date;
}

export function getTimeFromDate(date: Date): TimeTuple {
  return [date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()];
}

export function parseTime(time: DateLike): TimeTuple {
  if (typeof time === 'string' && time.split(':').length >= 2) {
    const [hours, minutes, seconds = 0, milliseconds = 0] = time
      .split(':')
      .slice(0, 4)
      .map((s: string) => (s ? parseInt(s, 10) : 0)) as TimeTuple;
    return [hours, minutes, seconds, milliseconds];
  }
  if (typeof time === 'number') {
    const date = parseDate(time);
    return getTimeFromDate(date);
  }
  if (moment.isMoment(time)) {
    return [time.get('hours'), time.get('minutes'), time.get('seconds'), time.get('milliseconds')];
  }
  if (time instanceof Date) {
    return getTimeFromDate(time);
  }
  return [0, 0, 0, 0];
}

export function parseExcelDate(date: DateLike, time?: DateLike | null, inputFormat: string = 'DD/MM/YYYY'): Moment {
  let parsedDate: Date;
  if (typeof date === 'number') {
    parsedDate = parseDate(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    parsedDate = moment.utc(date, inputFormat).toDate();
  }

  const result: moment.Moment = moment.utc(parsedDate);
  if (time) {
    const [hours, minutes, seconds, milliseconds] = parseTime(time);
    return result.set('hours', hours).set('minutes', minutes).set('seconds', seconds).set('milliseconds', milliseconds);
  }
  return result;
}

export const createCsv = async <T = unknown>(rows: T[], fileName: string, columns: string[]) => {
  return new Promise((resolve, reject) => {
    stringify(rows, { header: true, columns: columns }, (err: Error | undefined, output: string) => {
      if (err) reject(err);
      const wb = read(output, { type: 'string' });
      const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const file = new File([blob], `${fileName.split('.')[0]}.xlsx`, {
        type: 'application/octet-stream',
        lastModified: new Date().getTime(),
      });
      resolve(file);
    });
  });
};

export function createXls(rows: object[] = [], fileName: string = 'data', ext: BookType = 'xlsx', sheetName?: string) {
  const book = utils.book_new();

  const sheet = sheetName || fileName;
  book.SheetNames.push(sheet);

  const workSheet = utils.json_to_sheet(
    rows.map((row) => mapValues(row, (v) => (isDateLike(v) ? ensureIsDate(v) : v))),
    { cellDates: true }
  );

  book.Sheets[sheet] = workSheet;

  const buffer = write(book, { bookType: ext, type: 'array', cellDates: true });

  return new File([buffer], `${fileName}.${ext}`, { type: 'application/octet-stream' });
}
