import { useState, useCallback } from 'react';
import partition from 'lodash/partition';
import { useIntl } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import { useQuery } from '@apollo/client';
import { Button, Page, styled } from '@rio/ui-components';
import { useParams, useLocation } from 'react-router-dom';
import { useSearch, useNotification, usePermissions, useCurrentAccountId } from '~/hooks';
import { Folder } from '../../types';
import { getAllFilters } from '../../utils';
import { useGetDocuments } from '../../useGetDocuments';
import { DocumentExplorer } from '../../DocumentExplorer/v2';
import { GET_MANAGEMENT_SYSTEM_BY_ID_WITH_SIBLINGS } from '../../index.queries';
import {
  LIBRARY,
  CATEGORY,
  mapChapterToFolder,
  createManagementSystemTree,
  createBreadcrumbsForManagementSystem,
  getFoldersAndFilesFromManagementSystem,
} from '../utils';
import { AttachDocumentsModal } from './AttachDocumentsModal';

const ButtonStyled = styled(Button)`
  height: 40px;
`;

export function ManagementSystem() {
  const intl = useIntl();
  const { id, itemId } = useParams();
  const { pathname } = useLocation();
  const { showNotification } = useNotification();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [showAttachModal, setShowAttachModal] = useState(false);
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { data, loading, error, refetch } = useQuery(GET_MANAGEMENT_SYSTEM_BY_ID_WITH_SIBLINGS, {
    variables: {
      id,
      accountId,
    },
    partialRefetch: true,
    returnPartialData: true,
    errorPolicy: 'all',
  });
  const retry = () => refetch({ id, accountId });
  const managementSystem = data?.getManagementSystemById;
  const title = managementSystem?.title || 'Management System';
  const { tree } = createManagementSystemTree(managementSystem);
  const { folders } = getFoldersAndFilesFromManagementSystem(managementSystem, tree, itemId, pathname);
  const allChapters = Object.values(tree);
  const allFolders = allChapters.map((chapter) => mapChapterToFolder(chapter, pathname, itemId));
  const [searchValue, setSearchValue, searchResult] = useSearch(allFolders, [
    'name',
    'title',
    'referenceId',
    'managementSystemCode',
  ]);
  const [, searchedFolders] = partition(searchResult, (result) => result.__typename === 'DocumentRecord');
  const foldersToShow = searchValue ? searchedFolders : folders;
  const crumbs = createBreadcrumbsForManagementSystem(intl, managementSystem, tree, tree[itemId]);
  const breadcrumbs = crumbs.map((el) => ({ to: el.to, label: el.title }));
  const variables = {
    accountId,
    filters: getAllFilters(searchValue, LIBRARY, CATEGORY, id, itemId || null),
  };
  const { documentsExist } = useGetDocuments(variables);

  const handlerDismiss = useCallback(() => setShowAttachModal(false), []);

  const handlerComplete = useCallback(
    (message) => {
      showNotification(message, 'success');
      handlerDismiss();
    },
    [showNotification, handlerDismiss]
  );

  const handlerError = useCallback(
    (err) => {
      showNotification(err.message, 'danger');
      handlerDismiss();
    },
    [showNotification, handlerDismiss]
  );

  return (
    <>
      <Page
        title={{
          crumbs: breadcrumbs,
          content: title,
          actionButton: !!permissions.governance.find((action) =>
            action.startsWith('attachDocumentToManagementSystem')
          ) && (
            <ButtonStyled variant="filled" onClick={() => setShowAttachModal(true)}>
              Attach Documents
            </ButtonStyled>
          ),
        }}
        loading={loading && !documentsExist}
        error={error}
      >
        <DocumentExplorer
          folders={foldersToShow as Folder[]}
          library={LIBRARY}
          category={CATEGORY}
          isManagementSystem
          documentsExist={documentsExist}
          searchValue={searchValue as string}
          onSearch={setSearchValue}
          gridApi={gridApi}
          setGridApi={setGridApi}
          managementSystemId={id}
          managementSystemChapterId={itemId as string}
        />
      </Page>

      {showAttachModal && (
        <AttachDocumentsModal
          managementSystem={managementSystem}
          chapter={tree[itemId]}
          onComplete={(message) => handlerComplete(message)}
          onError={(err) => handlerError(err)}
          onDismiss={handlerDismiss}
          refetch={retry}
        />
      )}
    </>
  );
}
