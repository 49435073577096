import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Col, Row, Button } from 'rio-ui-components';
import { CREATE_NORMALISERS } from './index.queries';
import NormaliserDetails from './NormaliserDetails';
import { useNormaliserForm } from './useNormaliserForm';
import * as scopes from '../../../constants/scopes';
import { formatDatetime } from '../../../utils/formatDate';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

function CreateNormaliser({ accountId, showNotification }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createNormalisers] = useMutation(CREATE_NORMALISERS);
  const [values, setValues, errors, validate] = useNormaliserForm();

  return (
    <Container name="create-normaliser">
      <form
        name="create-normaliser__form"
        onSubmit={async (e) => {
          e.preventDefault();
          const valid = validate();
          if (valid && !isSubmitting) {
            const id = uuid();
            try {
              setIsSubmitting(true);
              const { data } = await createNormalisers({
                variables: {
                  normalisers: [
                    {
                      id,
                      accountId,
                      name: values.name,
                      description: values.description,
                      quantity: values.quantity,
                      locationId: values.locationId,
                      startDate: values.startDate ? formatDatetime(values.startDate, 'YYYY-MM-DD HH:mm:ss') : null,
                      endDate: values.endDate ? formatDatetime(values.endDate, 'YYYY-MM-DD HH:mm:ss') : null,
                      tagId: values.scope === scopes.TAG ? values.tagId : null,
                      type: values.type
                    }
                  ]
                }
              });

              const errors = data?.createNormalisers?.errors;
              if (errors) {
                showNotification(errors.join('\n'), 'danger', 'create');
              } else {
                showNotification(`The Normaliser ${values.name} has been created!`, 'success');
              }
            } catch {
              showNotification(`Something went wrong. If the problem persists, contact support.`, 'danger');
            } finally {
              setIsSubmitting(false);
            }
          }
        }}
      >
        <NormaliserDetails
          setValues={setValues}
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          heading="Create Normaliser"
          accountId={accountId}
        />
        <Row container align="between">
          <ColStyled item>
            <Button name="create-normaliser__button--create" color="success" submit disabled={isSubmitting}>
              Create Normaliser
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
}

export default CreateNormaliser;
