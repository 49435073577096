import React from 'react';
import { Row, Text, CircleIcon } from 'rio-ui-components';
import styled from 'styled-components';

const GovernanceAreaRowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${(props) => (props.styleSelected ? props.theme.colors.overlay.light.background : 'none')};
`;

const AreaDetails = styled(Row)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

const ComingSoonStyled = styled.div`
  flex: 0 0 auto;
  opacity: 0.7;
  display: inline-block;
  background: ${(props) => props.theme.colors.text.dark.background};
  padding: ${(props) => props.theme.geometry.xxs.spacing} ${(props) => props.theme.geometry.xs.spacing};
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
`;

const GovernanceAreaRow = ({ botId, title, icon, iconColor, comingSoon, fullAccess }) => {
  return (
    <GovernanceAreaRowStyled name={`BotRow--${botId}`} container itemAlign="center">
      <AreaDetails container item itemAlign="center" distribution="between" disabled={comingSoon || !fullAccess}>
        <Row container item itemAlign="center">
          <CircleIconStyled height="30px" size="md" icon={icon} iconColor={iconColor} circleColor={iconColor} />
          <Text weight="bold" color="dark" inline name={`BotRow__Title--${botId}`} size="md">
            {title}
          </Text>
        </Row>
        {(comingSoon || !fullAccess) && (
          <ComingSoonStyled>
            <Text inline size="xs" color="white">
              {comingSoon ? 'Coming Soon' : 'Upgrade Now'}
            </Text>
          </ComingSoonStyled>
        )}
      </AreaDetails>
    </GovernanceAreaRowStyled>
  );
};

export default GovernanceAreaRow;
