import styled from 'styled-components';
import { Heading, PreContainer, LoadingIndicator } from 'rio-ui-components';
import { LoadFailed } from '../../../components/Errors';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_DATA_IMPORT_BATCH_BY_ID } from '../AliasContainer/index.queries';
import { ValidationErrors } from './ValidationErrors';
import { ServerError } from './ServerError';

const Container = styled.div`
  padding: ${(p) => p.theme.geometry.md.spacing};
  display: flex;
  flex-direction: column;
  align-items: center;
  pre {
    width: 100%;
  }
`;
const Title = styled(Heading)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.geometry.md.spacing};
`;
const Subtitle = styled(Heading)`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const RowOfErrors = styled.div`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const Ul = styled.ul`
  list-style-type: disc;
  margin-bottom: ${(p) => p.theme.geometry.xs.spacing};
`;
const Li = styled.li`
  margin-left: ${(p) => p.theme.geometry.md.spacing};
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.geometry.sm.spacing};
`;
const StyledLoadingContainer = styled(LoadingIndicator)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
`;

export function BatchErrors({ batchId }) {
  const { data, error, loading, refetch } = useQuery(GET_DATA_IMPORT_BATCH_BY_ID, {
    variables: {
      id: batchId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { getDataImportBatchById: batch } = data || {};

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <StyledLoadingContainer />
        </LoadingContainer>
      )}
      {error && <LoadFailed error={error} retry={refetch} />}
      {batch && !loading && !error && (
        <>
          <Title size="lg">Batch Errors</Title>
          {batch.failedReason ? (
            <ServerError error={batch.failedReason} />
          ) : (
            <ValidationErrors errors={batch.errors} errorsCount={batch.errorsCount} />
          )}
        </>
      )}
    </Container>
  );
}
