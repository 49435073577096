import { Query, QueryGetSurveyLatestArgs as Variables } from '@rio/rio-types';
import { GET_SURVEY_LATEST } from './queries';
import { usePageSuspendingQuery } from '~/hooks';

export type GetSurveyLatestResponse = Pick<Query, 'getSurveyLatest'>;

export function useGetByLatest(accountId: string) {
  return usePageSuspendingQuery<GetSurveyLatestResponse, Variables>(GET_SURVEY_LATEST, {
    variables: { accountId },
  });
}
