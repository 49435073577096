import { gql } from '@apollo/client';

export const DATA_TRACKER_CONFIG_FRAGMENT = gql`
  fragment DataTrackerConfigFields on DataTrackerConfig {
    id
    account {
      id
      name
    }
    supplier {
      id
      name
    }
    frequency
    recurrence
    locations {
      id
      name
      tags {
        id
        tagName
      }
    }
    meters {
      id
      name
    }
    tags
  }
`;

export const GET_DATA_TRACKER_CONFIGURATION = gql`
  query GetDataTrackerConfig($id: ID!) {
    getDataTrackerConfig(id: $id) {
      ...DataTrackerConfigFields
    }
  }
  ${DATA_TRACKER_CONFIG_FRAGMENT}
`;

export const GET_DATA_TRACKER_CONFIGURATIONS = gql`
  query GetDataTrackerConfigs(
    $accountId: ID
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getDataTrackerConfigs(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...DataTrackerConfigFields
      }
    }
  }
  ${DATA_TRACKER_CONFIG_FRAGMENT}
`;

export const GET_DATA_TRACKER_CONFIGURATION_FILTERS = gql`
  query GetDataTrackerConfigFilters($accountId: ID, $field: String!) {
    getDataTrackerConfigFilters(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;

export const CREATE_DATA_TRACKER_CONFIGURATION = gql`
  mutation CreateDataTrackerConfig(
    $id: ID!
    $accountId: ID!
    $supplierId: ID!
    $frequency: Frequency!
    $recurrence: Int!
    $locationIds: [ID!]!
    $metersIds: [ID!]!
  ) {
    createDataTrackerConfig(
      input: {
        id: $id
        accountId: $accountId
        supplierId: $supplierId
        frequency: $frequency
        recurrence: $recurrence
        locationIds: $locationIds
        metersIds: $metersIds
      }
    ) {
      ...DataTrackerConfigFields
    }
  }
  ${DATA_TRACKER_CONFIG_FRAGMENT}
`;

export const UPDATE_DATA_TRACKER_CONFIGURATION = gql`
  mutation UpdateDataTrackerConfig(
    $id: ID!
    $accountId: ID!
    $supplierId: ID!
    $frequency: Frequency!
    $recurrence: Int!
    $locationIds: [ID!]!
    $metersIds: [ID!]!
  ) {
    updateDataTrackerConfig(
      input: {
        id: $id
        accountId: $accountId
        supplierId: $supplierId
        frequency: $frequency
        recurrence: $recurrence
        locationIds: $locationIds
        metersIds: $metersIds
      }
    ) {
      ...DataTrackerConfigFields
    }
  }
  ${DATA_TRACKER_CONFIG_FRAGMENT}
`;

export const DELETE_DATA_TRACKER_CONFIGURATION = gql`
  mutation DeleteDataTrackerConfig($id: ID!) {
    deleteDataTrackerConfig(id: $id)
  }
`;
