import { useGetProductsLazyQuery } from './useGetProductsLazyQuery';
import { useEffect } from 'react';

export function useGetProductsQuery() {
  const [fetch, data] = useGetProductsLazyQuery();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return data;
}
