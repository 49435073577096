import { useMemo } from 'react';
import { ColDef, ValueGetterParams, GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { Grid } from '../../../../../components/UI';
import { DisclosureScore } from '../types';
import { disclosureScores } from '../constants';

function formatClimateChange(score: DisclosureScore) {
  return `${score.climateChangeMinPercentage}-${score.climateChangeMaxPercentage}%`;
}

export function CarbonDisclosureScoreGrid() {
  const columnDefs = useMemo(
    (): ColDef[] => [
      {
        field: 'level',
        headerName: 'Level'
      },
      {
        headerName: 'Climate change',
        valueGetter: ({ data }: ValueGetterParams) => formatClimateChange(data)
      },
      {
        field: 'name',
        headerName: 'Score band'
      }
    ],
    []
  );
  return (
    <Grid
      rowIdField="name"
      suppressRowTransform
      onGridReady={({ api }: GridReadyEvent) => {
        api.sizeColumnsToFit();
      }}
      getRowStyle={(params: RowClassParams) => ({
        backgroundColor: params.data.color
      })}
      columnDefs={columnDefs}
      rowData={disclosureScores.slice().sort()}
      domLayout="autoHeight"
    />
  );
}
