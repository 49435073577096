import { gql } from '@apollo/client';

export const GET_HOME_PAGE_LEARN_PROGRESS_DATA = gql`
  query GetHomePageLearnProgressData($userId: ID!, $continueLearningLimit: Int!) {
    getLearnProgressLogByUserId(userId: $userId, limit: $continueLearningLimit, status: INPROGRESS) {
      courseProgress
      lastUpdatedAt
      course {
        id
        name
        metaData {
          cpd
          thumbnail
          link
        }
      }
    }
  }
`;

export const GET_HOME_PAGE_EMISSION_DATA = gql`
  query GetHomePageEmissionData($accountId: ID!, $from: AWSDate!, $to: AWSDate!) {
    getEmissionsPercentageBreakdownByScope(accountId: $accountId, from: $from, to: $to) {
      series {
        id
        relativeValue
        absoluteValue
      }
      drilldown {
        id
        parentId
        relativeValue
        absoluteValue
      }
    }
  }
`;
