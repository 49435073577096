import { Icon } from 'rio-ui-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { SurveyContributor } from '@rio/rio-types';
import { ColDef, GetRowIdParams, RowClickedEvent } from 'ag-grid-community';

import styled from 'styled-components';
import { alphabetiseStrings } from '~/utils';
import { AgGridSimple } from '../../../components/AgGridStyled';
import { useIsV2 } from '~/hooks/useIsV2';

interface ContributorsGridProps {
  contributors: SurveyContributor[];
}

const ROW_HEIGHT = 50;

/**
 * @see https://ag-grid.com/react-data-grid/global-style-customisation-variables/#reference-variables---ag-data-color
 */
const GridContain = styled(AgGridSimple)`
  --ag-row-height: ${ROW_HEIGHT}px;
  --ag-row-hover-color: #e8e9ec;

  & .ag-paging-panel {
    justify-content: flex-start;
  }

  && .ag-row:hover {
    cursor: pointer;
  }
  &&&& .ag-row .ag-cell {
    line-height: 17px;
  }
  &&& .ag-row .ag-cell:first-child {
    padding-left: 12px;
  }
`;

const GridContainerV2 = styled(AgGridSimple)`
  --ag-row-height: ${ROW_HEIGHT}px;
  --ag-row-hover-color: #e8e9ec;

  & .ag-paging-panel {
    justify-content: flex-start;
  }

  && .ag-row:hover {
    cursor: pointer;
  }
  &&&& .ag-row .ag-cell {
    line-height: 17px;
  }
  &&& .ag-row .ag-cell:first-child {
    padding-left: 12px;
  }

  .ag-root-wrapper-body {
    height: 100%;
  }

  .ag-layout-normal {
    min-height: 350px;
  }

  .ag-paging-panel,
  .ag-header {
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  &&&& .ag-row {
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center
  justify-content: center;
`;

const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    valueGetter: ({ data }) =>
      data.user ? `${data.user.first_name} ${data.user.last_name} (${data.user.email})` : data.account.name,
    sort: 'asc' as 'asc',
    comparator: alphabetiseStrings,
    flex: 100,
  },
  {
    headerName: 'Contributor Type',
    valueGetter: ({ data }) => (data.user ? 'Contributor' : 'Child Account'),
    flex: 100,
  },
  {
    headerName: 'Chevron',
    cellRenderer: 'chevron',
    flex: 1,
  },
];
const defaultColDef = {
  filter: false,
};

const components = {
  chevron: () => (
    <IconContainer>
      <Icon icon="chevron-right" color="#83838e" />
    </IconContainer>
  ),
};

const getRowId = (params: GetRowIdParams) => params.data.account.id;

const getContributorId = (data) => {
  return data.user ? `${data.account.id}:${data.user.id}` : data.account.id;
};

export function ContributorsGrid({ contributors }: ContributorsGridProps) {
  const navigate = useNavigate();
  const v2 = useIsV2();
  const GridContainer = v2 ? GridContainerV2 : GridContain;
  const handleRowClick = useCallback((e: RowClickedEvent) => navigate(`./${getContributorId(e.data)}`), [navigate]);

  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        defaultColDef={defaultColDef}
        rowHeight={ROW_HEIGHT}
        components={components}
        rowData={contributors}
        paginationPageSize={25}
        getRowId={getRowId}
        columnDefs={columnDefs}
        onRowClicked={handleRowClick}
        pagination
      />
    </GridContainer>
  );
}
