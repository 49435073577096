import React, { useState } from 'react';
import { ColDef, ValueFormatterParams, GridReadyEvent } from 'ag-grid-community';
import { MarketCarbonConversionFactor, MarketCarbonConversionFactorInput } from '@rio/rio-types';
import { Grid } from '../../../components/UI';
import { dateComparator, formatDate } from '../../../utils';
import { EditCell, LinkCell } from '../../../components/CellRenderers';
import { UpdateMarketCarbonConversionFactorModal } from './UpdateMarketCarbonConversionFactorModal';
import { mapFactorToInput } from './utils';
import { AgGrid } from '../../../hooks';

interface MarketCarbonConversionFactorsGridProps {
  factors: MarketCarbonConversionFactor[];
  editAllowed: boolean;
  agGrid: AgGrid;
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Supplier',
    field: 'supplierName',
    filter: 'agSetColumnFilter',
    resizable: true,
    tooltipField: 'supplierName',
  },
  {
    headerName: 'Tariff',
    field: 'tariff',
    filter: 'agSetColumnFilter',
    sort: 'asc',
    resizable: true,
    tooltipField: 'tariff',
  },
  {
    headerName: 'Renewable (%)',
    field: 'renewablePercentage',
    valueFormatter: ({ value }: ValueFormatterParams) => `${value}%`,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Generation (kgCO2e)',
    filter: 'agNumberColumnFilter',
    field: 'generationFactor',
  },
  {
    headerName: 'WTT (Generation) (kgCO2e)',
    filter: 'agNumberColumnFilter',
    field: 'wellToTankForGenerationFactor',
  },
  {
    headerName: 'WTT (T&D) (kgCO2e)',
    filter: 'agNumberColumnFilter',
    field: 'wellToTankForTransmissionAndDistributionFactor',
  },
  {
    headerName: 'Transmission & Distribution (kgCO2e)',
    filter: 'agNumberColumnFilter',
    field: 'transmissionAndDistributionFactor',
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
    valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'link',
    filter: 'agSetColumnFilter',
    resizable: true,
    tooltipField: 'reference',
  },
  {
    headerName: 'Notes',
    field: 'notes',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Edit',
    cellRenderer: 'edit',
    filter: false,
    sortable: false,
  },
];

export function MarketCarbonConversionFactorsGrid({
  factors,
  editAllowed,
  agGrid,
}: MarketCarbonConversionFactorsGridProps) {
  const [editedFactor, setEditedFactor] = useState<MarketCarbonConversionFactorInput | null>(null);
  const components = {
    edit: (props: { data: { id: string } }) => (
      <EditCell
        {...props}
        onEdit={(_: string, factor: MarketCarbonConversionFactor) => {
          const factorInput: MarketCarbonConversionFactorInput = mapFactorToInput(factor);
          setEditedFactor(factorInput);
        }}
      />
    ),
    link: LinkCell,
  };
  return (
    <>
      <Grid
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        onDestroy={agGrid.onDestroy}
        onGridReady={(params: GridReadyEvent) => {
          agGrid.onGridReady(params);
        }}
        components={components}
        columnDefs={editAllowed ? columnDefs : columnDefs.filter((colDef: ColDef) => colDef.headerName !== 'Edit')}
        rowData={factors}
      />
      {editedFactor && (
        <UpdateMarketCarbonConversionFactorModal
          factor={editedFactor}
          onComplete={(f: MarketCarbonConversionFactor[]) => {
            agGrid.api?.setRowData(f);
            setEditedFactor(null);
          }}
          onDismiss={() => setEditedFactor(null)}
        />
      )}
    </>
  );
}
