export const en = {
  home: {
    title: 'Logged in home page',
    subtitle: 'This is some simple localized text',
    lastCommit: 'Last commit {commitId} by {author} at {timestamp}',
    logoutButtonLabel: 'Log out',
    myProfile: 'My Profile',
    contact: 'Contact',
    search: 'Search',
  },
  marketingHome: {
    title: 'Logged out home page',
    loginButtonLabel: 'Log in',
  },
  login: {
    registration: {
      mainPage: {
        title: 'Welcome to Rio!',
        subtitle: 'To get started please fill in your details below to set up an account',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        confirmPasswordPlaceholder: 'Confirm password',
        nextButtonText: 'Next',
        submitButton: 'Imposta password',
      },
      detailsPage: {
        title: 'Now tell us more about you',
        firstName: 'First Name',
        lastName: 'Last Name',
        companyName: 'Company Name',
        personalUse: 'This account is for personal use',
        backButtonText: 'Back to credentials',
        nextButtonText: 'Next',
      },
      contactPage: {
        title: 'How would we contact you?',
        jobTitle: 'Job Title (Optional)',
        department: 'Department (Optional)',
        phoneNumber: 'Phone Number (Optional)',
        mobileNumber: 'Mobile Number (Optional)',
        backButtonText: 'Back to Profile',
        finishButtonText: 'Finish',
      },
    },
    sidebar: {
      title: 'Account Summary',
      step1: 'About You',
      step2: 'Your Plan',
      step3: 'Your Subscription',
      trusted: 'TRUSTED BY',
    },
    main: {
      title: 'Welcome back!',
      titleV2: 'Welcome!',
      subheading: 'Please login to your account.',
      subheadingV2: 'Please login to your account or sign up below',
      emailPlaceholder: 'Email address',
      emailError: 'Please enter a valid email address',
      passwordPlaceholder: 'Password',
      passwordError: 'Please enter a valid password',
      rememberLabel: 'Remember me',
      loginButton: 'Login',
      loginButtonLoading: 'Logging in',
      loginButtonSSO: 'Login with {provider}',
      userNotFound:
        'The username and password you entered did not match our records. Please double-check and try again.',
      incorrectPassword: 'Incorrect credentials. Failure to log in 5 times will result in your account being locked.',
      userDisabled: 'Your account has been disabled. Please contact an administrator.',
      generalError: 'Something seems to have gone wrong. Please refresh and try again.',
      forgotPassword: 'Forgot Password',
      newRioUser: 'New to Rio? Register here',
      providerNotFound: 'Provider not found, verify browser url.',
    },
    setPassword: {
      title: 'Set your password.',
      subheading: 'Please set your new password.',
      oldPassword: 'Temporary password',
      passwordPlaceholder: 'New password',
      confirmPasswordPlaceholder: 'Confirm password',
      submitButton: 'Set password',
      userNotFound: 'User not found. Please refresh and try again.',
      incorrectPassword: 'Incorrect password. Please refresh and try again.',
      generalError: 'Something seems to have gone wrong. Please refresh and try again.',
    },
    resetPassword: {
      title: 'Reset your password.',
      subheading: 'Please reset your password to continue.',
      emailPlaceholder: 'Email address',
      codePlaceholder: 'Reset code',
      passwordPlaceholder: 'New password',
      confirmPasswordPlaceholder: 'Confirm password',
      submitButton: 'Reset password',
      buttonSubmitting: 'Resetting password',
      userNotFound: 'User not found. Please refresh and try again.',
      incorrectPassword: 'Incorrect password. Please refresh and try again.',
      confirmationCodeRequired: 'Confirmation code is required.',
      invalidCode: 'Invalid code provided, please go back and request a new code.',
      generalError: 'Something seems to have gone wrong. Please refresh and try again.',
      limitExceeded: 'Reset password limit exceeded please try again later.',
      expiredCode: 'Invalid code provided, please go back and request a new code.',
      noCodeEntered: 'Please enter the confirmation code - it can be found in your email.',
      noUsernameEntered: 'Please enter the email of the account for which you have requested a password reset.',
      passwordsDontMatch: 'Passwords do not match.',
    },
    forgotPassword: {
      success: {
        title: 'Password reset sent',
        subheading: 'Please check your email for your password reset code',
      },
      title: 'Forgotten your password?',
      subheading: "Enter your email below and we'll send you a password reset code",
      usernamePlaceholder: 'Email address',
      submitButton: 'Send Password Reset Code',
      buttonSubmitting: 'Sending Password Reset Code',
      goToResetButton: 'Enter your reset code',
    },
    termsAgreement: {
      termsAgreementText: 'I agree to the ',
      termsLinkText: 'Rio ESG LTD Terms of {terms}',
      service: 'Service',
      use: 'Use',
    },
    errors: {
      existAccount: 'An account with the given email {email} already exists',
      changeEmail: 'Change Email Address',
      validation: {
        missingEmail: 'Please provide an email',
        invalidEmail: 'The value you provided is invalid email address',
        missingPassword: 'Please provide a password',
        confirmPassword: 'Please confirm your password',
        mismatchPassword: `Passwords don't match`,
        passwordPattern:
          'Password should contain at least 10 characters and shouldn’t contain leading and trailing white-spaces',
        companyName: `Please provide a company name`,
        firstName: `Please provide your first name`,
        lastName: 'Please provide your last name',
      },
    },
  },
  sidebar: {
    homepage: 'Home',
    learn: 'Engage',
    governance: 'Governance',
    data: 'Data',
    surveys: 'Surveys',
    reporting: 'Reporting',
    documents: 'Documents',
    intelligence: 'Intelligence',
    flows: 'Flows',
    configuration: 'Configuration',
    tasks: 'Tasks',
    support: 'Support',
  },
  role: {
    SYSADMIN: 'System Admin',
    BUSINESSADMIN: 'Business Admin',
    BUSINESS: 'Business User',
  },
  pages: {
    support: {
      contactInformation: 'Contact information',
      header: 'Send us a message',
      fullName: 'Full name',
      companyName: 'Company name',
      phoneNumber: 'PhoneNumber',
      enquiryQuestion: 'What is your enquiry about?',
      enquiry: 'Enquiry',
      enquirySelectPlaceholder: 'Please choose',
      enquiryPlaceholder: 'Please type your message here...',
      send: 'Send Enquiry',
      banner: {
        header: 'Have you got a question?',
        message: 'Head over to our support page {Link} for tips, advice, and answers to frequently asked questions.',
        link: 'here',
      },
    },
    home: {
      welcome: "Welcome [FIRSTNAME], here's a few suggestions...",
      guidanceText: ' ',
      dashboard: {
        learn: 'Engage',
        governance: 'Governance',
        analyse: 'Analyse',
      },
      engageTasksStatuses: {
        incomplete: 'Incomplete',
        completed: 'Completed',
      },
    },
    learn: {
      heading: 'Engage',
      breadcrumbs: {
        one: 'Engage',
        two: 'Courses',
      },
      banners: {
        explore: 'Explore',
        continue: 'Continue',
        completed: 'Completed',
      },
      viewLink: 'View All ({amount} Courses)',
    },
    coursesPreview: {
      breadcrumbs: {
        one: 'Engage',
        two: 'Course preview',
      },
      backToEngage: 'Back to Engage',
      startCourse: 'Start course',
      statuses: {
        embed: {
          inprogress: 'You have started this course.',
        },
      },
      relatedCourses: 'Related couses',
      noResults:
        'No related content found. This course is one of a kind; explore more subject areas from the Learn Homepage.',
    },
    surveys: {
      nav: {
        heading: 'Surveys',
      },
      subjects: {
        surveysSent: 'Surveys sent',
        contributors: 'Contributors',
        surveys: 'All surveys',
        overview: 'Overview',
        surveysReceived: 'Surveys received',
        received: 'Received',
      },
    },
    governance: {
      heading: 'Governance',
      breadcrumbs: {
        one: 'Governance',
        two: 'Areas',
      },
      permissionsProblem:
        'Currently Governance is not included in your subscription, if you’d like to use this tool to assess your compliance risk, upload evidence and get automated alerts please contact Rio ESG LTD or your Rio Business Admin.',
    },
    data: {
      heading: 'Data',
      breadcrumbs: {
        one: 'Data',
        two: 'Subjects',
      },
      subjects: {
        wasteAlias: 'Waste and Waste Locations', // Waste, admin friendly naming
        waste: 'Waste',
        electricity: 'Electricity',
        gas: 'Natural Gas',
        fuel: 'Fuel',
        water: 'Water',
        materials: 'Materials',
        refrigerants: 'Refrigerants and Other Gases',
        heatSteam: 'Heat and Steam',
        transport: 'Transport',
        safety: 'Health and Safety',
        employees: 'Employees',
        engagement: 'Community and Customer Engagement',
        ethics: 'Business Ethics',
        biodiversity: 'Biodiversity',
        buildingEnergyRatings: 'Building Energy Ratings',
        hotelStays: 'Hotel Stays & Home Working',
        location: 'Location',
        overview: 'Overview',
        investment: 'Investment Transactions',
        fundsAssets: 'Funds & Assets',
      },
      overview: {
        upgrade: 'Upgrade',
        collect: 'COLLECT',
        about: 'About Data Collection',
        dataCollection: 'Data Collection',
        dataNotifications: 'Data notifications',
        dataCompleteness: 'Data completeness',
        dataQuality: 'Data quality',
        missingTransactions: 'Missing data and empty transactions',
        pendingTransactions: 'transactions are pending and require approval',
        upload: 'Upload',
        uploadHeader: 'Quick Data Uploads',
        uploadText: 'Uploading actual sustainability data is the best way to assess your impact',
        utilities: 'Utilities',
        latestTransactions: 'Latest transactions',
        utilityCol: 'Utility',
        locationCol: 'Location/Supplier',
        dateCol: 'Date',
        uploadedCol: 'Uploaded',
        accept: 'Accept',
        acceptInfo: 'This clears the items from this section',
        clear: 'This clears all selected filters',
        selectAll: 'This selects all options on the current page',
        deselectAll: 'This deselects all options on the current page',
        selectAllButtonText: 'Select all',
        deselectAllButtonText: 'Deselect all',
        clearButtonText: 'Clear filters',
        confirmationDialog: {
          title: 'Are you sure you want to accept and clear these items?',
          agree: 'Yes, accept',
          disagree: 'No, keep these visible',
        },
      },
    },
    reporting: {
      heading: 'Reporting',
      breadcrumbs: {
        one: 'Reporting',
        two: 'Reports',
      },
    },
    intelligence: {
      heading: 'Knowledge Bases',
      breadcrumbs: {
        one: 'Intelligence',
        two: 'Knowledge Bases',
      },
    },
    documents: {
      heading: 'Documents',
      breadcrumbs: {
        one: 'Documents',
        two: 'Libraries',
      },
    },
    configuration: {
      account: {
        sections: {
          data: {
            name: 'Data',
            buildingEnergyRatings: 'Building Energy Ratings',
            electricity: 'Electricity',
            fuel: 'Fuel',
            gas: 'Gas',
            heatSteam: 'Heat & Steam',
            hotelStays: 'Hotel Stays',
            materials: 'Materials',
            refrigerants: 'Refrigerants',
            transport: 'Transport',
            waste: 'Waste',
            water: 'Water',
            investment: 'Investment Transactions',
          },
          governance: {
            name: 'Governance',
            agreements: 'Agreements',
            frameworks: 'Frameworks',
            legislation: 'Legislation',
            policies: 'Policies',
            systems: 'Systems',
            tagLevelLegislation: 'Tag Level Legislation',
            targets: 'Targets',
          },
          other: {
            name: 'Other',
            chatBot: 'Chat Bot',
            dromoUpload: 'Intelligent Upload',
            v2Design: 'New Design',
            dataTracker: 'Data Tracker',
            engage: 'Engage',
            flows: 'Flows',
            intelligence: 'RioBot',
            projects: 'Projects',
            surveys: 'Surveys',
            tasks: 'Tasks',
          },
        },
      },
      nav: {
        heading: 'Configuration',
        accounts: 'Accounts',
        contractors: 'Contractors',
        dashboards: {
          list: 'Dashboards',
          subscriptions: 'Dashboard Subscriptions',
        },
        meters: 'Meters',
        learnContent: 'Engage Content',
        learnSubscriptions: 'Engage Subscriptions',
        evaluations: 'Engage Evaluations',
        locations: 'Locations',
        suppliers: 'Suppliers',
        tags: 'Tags',
        users: 'Users',
        wasteStreams: 'Streams',
        wasteHierarchies: 'Waste Hierarchies',
        carbonConversionFactors: 'Carbon Conversion Factors',
        locationBased: 'Location Based',
        marketBased: 'Market Based',
        containerTypes: 'Container Types',
        normalisers: 'Normalisers',
        aliases: 'Aliases',
        others: 'Others',
        schemes: 'Schemes',
        formTemplates: 'Templates',
        formSubscriptions: 'Template Subscriptions',
        questionsCategories: 'Question Categories',
        questions: 'Questions',
        flows: {
          configuration: 'Configuration',
          subscriptions: 'Subscriptions',
        },
        assetManagement: 'Assets',
        funds: 'Funds',
        engageBanners: 'Engage Banners',
      },
      dataTracker: {
        container: {
          add: '+ Add Data Tracker Configuration',
          header: {
            anomaly: 'Anomalies Thresholds',
            missing: 'Frequency & Recurrence',
          },
        },
        modal: {
          account: 'Account',
          supplier: 'Supplier',
          thresholdPercentage: 'Threshold Percentage',
          utilityType: 'Utility Type (optional)',
          frequency: 'Frequency',
          recurrence: 'Recurrence',
          locations: 'Locations (optional)',
          meters: 'Meters (optional)',
          create: 'Create',
          update: 'Update',
          createHeader: 'Create Data Tracker Configuration',
          updateHeader: 'Update Data Tracker Configuration',
        },
      },
    },
    task: {
      grid: {
        header: {
          task: 'Task',
          category: 'Category',
          subject: 'Subject',
          item: 'Item',
          owners: 'Owners',
          creator: 'Creator',
          date: 'Due Date',
          completedDate: 'Completion Date',
          link: 'Go To Task',
          project: 'Project',
        },
        column: {
          emptyOwners: 'Unassigned',
        },
      },
      details: {
        name: 'Task Name',
        description: 'Description',
        creator: 'Creator',
        createdAt: 'Created at',
        impact: 'Scope of Area Project Is Impacting',
        locations: 'Location(s)',
        priority: 'Priority',
        category: 'Category',
        scope: 'Scope',
        owner: 'Owners',
        relatedItem: 'Related item',
        tags: 'Tags',
        isCompleted: 'Completed',
        completedAt: 'Completion Date',
        subject: 'Subject',
        dueDate: 'Due Date',
        project: 'Project',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        evidence: 'Evidence',
        status: 'Status',
        incomplete: 'Incomplete',
        completed: 'Completed',
      },
    },
    project: {
      grid: {
        header: {
          name: 'Project name',
          impact: 'Impact',
          description: 'Description',
          locations: 'Locations',
          category: 'Category',
          scheme: 'Scheme',
          meters: 'Meters',
          owners: 'Owners',
          cost: 'Project cost',
          implementationDate: 'Date of implementation',
          baselineDate: 'Baseline year',
          benefitsDate: 'Date for benefits to be realised',
          plannedBenefit: 'Total planned benefit vs baseline year CONSUMPTION',
          plannedCostChange: 'Total planned cost saving v baseline year COST',
          plannedCO2eChange: 'Total planned carbon saving v baseline year CARBON',
          frequency: 'Milestone frequency',
          notes: 'Notes',
          evidence: 'Evidence',
          action: 'Action',
        },
        value: {
          emptyOwners: 'Unassigned',
        },
      },
      create: {
        header: 'Create New Project',
        submit: 'Submit',
      },
      details: {
        name: 'Project Name',
        description: 'Description',
        impact: 'Scope of Area Project Is Impacting',
        locations: 'Location(s)',
        priority: 'Priority',
        meters: 'Meters',
        category: 'Category',
        scheme: 'Scheme',
        owner: 'Owners',
        cost: 'Project Cost (£)',
        currency: 'Currency',
        startDate: 'Implementation Date (Start Date)',
        endDate: 'Date for planned benefits to be achieved (End Date)',
        baselineStartDate: 'Baseline Year Start Date',
        plannedBenefit: 'Planned Action (Consumption)',
        plannedBenefitGeneration: 'Planned Action (Generation kwh)',
        plannedCarbonChange: 'Planned Benefit (Carbon)',
        plannedCostChange: 'Planned Benefit (Cost Saved)',
        frequency: 'Milestone Frequency',
        tasks: 'Tasks',
        addNewTask: 'Add new task',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        noTasks: 'There are no tasks for this project.',
        evidence: 'Evidence',
      },
      messages: {
        created: 'Project has been created.',
        error: 'Something went wrong! Please try again later.',
      },
    },
    dataAnomalies: {
      transactionTypes: {
        ELECTRICITY: 'Electricity',
        FUEL: 'Fuel',
        GAS: 'Gas',
        MATERIALS: 'Materials',
        TRANSPORT: 'Transport',
        WASTE: 'Waste',
        WATER: 'Water',
        HOTELSTAYS: 'Hotel stays',
        HEATSTEAM: 'Heat & steam',
        ElectricityDataAnomaly: 'Electricity',
        FuelDataAnomaly: 'Fuel',
        GasDataAnomaly: 'Gas',
        MaterialDataAnomaly: 'Materials',
        TransportDataAnomaly: 'Transport',
        WasteDataAnomaly: 'Waste',
        WaterDataAnomaly: 'Water',
        HotelStayDataAnomaly: 'Hotel stays',
        HeatAndSteamDataAnomaly: 'Heat & steam',
        RefrigerantDataAnomaly: 'Refrigerants',
      },
    },
  },
  profile: {
    editProfilePicture: 'Edit Profile Picture',
    firstName: 'First name',
    lastName: 'Last name',
    jobTitle: 'Job title (optional)',
    department: 'Department (optional)',
    phoneNumber: 'Phone number (optional)',
    mobileNumber: 'Mobile number (optional)',
    emailAddress: 'Email address',
    update: 'Update',
    userHaveBeenUpdated: 'User details have been updated.',
  },
  components: {
    selectPlaceholder: 'Select...',
  },
  learnTour: {
    next: 'Next',
    skip: 'Skip',
    close: 'Got it!',
    back: 'Back',
    letsGo: "Let's go!",
    preview: {
      item1: {
        title: 'And begin...',
        content: 'Click start and enjoy…',
      },
    },
    home: {
      item1: {
        title: 'Welcome {name},',
        content: 'Make the world sustainable with Rio. Let’s show you how…',
      },
    },
    explore: {
      item1: {
        title: 'Keep learning',
        content: 'Explore our expert-authored sustainability content',
      },
      item2: {
        title: 'See more',
        content: 'Click the tile to understand the course',
      },
    },
  },
};
