import React, { Component } from 'react';
import { Heading, Text, Button, TextInput, Row, Col, TextLink, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/fp/flowRight';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LOG_IN } from '../../queries/auth';
import ForgotPassword from './ForgotPassword';
import NotificationBanner from '../../components/NotificationBanner';
import RioLogo from '../../assets/img/RioLogo2021.png';
import { getEnvVar } from '../../env';

const ContainerStyled = styled(Col)`
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ColStyled = styled(Col)`
  text-align: center;
`;

const TextInputStyled = styled(TextInput)`
  width: 100%;
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: 5px;
  margin-right: 5px;
`;

const LogoStyled = styled.div`
  height: 70px;
  width: 125px;
  background: no-repeat top center / contain url(${RioLogo});
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  align-self: center;
  flex: 0 0 auto;
`;

const LoginErrorStyled = styled(Text)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  text-align: center;
`;

const RowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing} ${(props) => props.theme.geometry.xs.spacing};
  flex-basis: initial;
  width: 100%;
`;

const RowForLinks = styled(RowStyled)`
  justify-content: space-between;
`;

const TextLinkStyled = styled(TextLink)`
  flex: 1;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const FormStyled = styled.form`
  width: 100%;
`;

export const getLoginException = ({ message }) => {
  switch (message) {
    case 'Incorrect username or password.':
      return 'NotAuthorizedException';
    case 'User does not exist.':
      return 'UserNotFoundException';
    case 'User is disabled.':
      return 'UserDisabledException';
    case 'Confirmation code cannot be empty':
      return 'ConfirmationCodeEmptyException';
    case 'Invalid code provided, please request a code again.':
      return 'InvalidCodeException';
    default:
      return 'GeneralError';
  }
};

const GetLoginErrorMessageId = (loginError) => {
  return {
    UserNotFoundException: 'login.main.userNotFound',
    NotAuthorizedException: 'login.main.incorrectPassword',
    UserDisabledException: 'login.main.userDisabled',
    GeneralError: 'login.main.generalError',
  }[loginError];
};

const RenderLoginError = ({ loginError }) => {
  if (!loginError) return null;
  const messageId = GetLoginErrorMessageId(loginError);
  return (
    <LoginErrorStyled name="formError" color="danger" size="sm" weight="bold">
      <FormattedMessage id={messageId} />
    </LoginErrorStyled>
  );
};

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      remember: { value: false },
      formError: '',
      loginError: null,
      forgotPasswordModal: false,
    };
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: { ...this.state[name], value },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const newEmailState = { ...this.state.email };
    const newPasswordState = { ...this.state.password };

    newEmailState.error = !this.state.email.value ? this.props.intl.formatMessage({ id: 'login.main.emailError' }) : '';
    newPasswordState.error = !this.state.password.value
      ? this.props.intl.formatMessage({ id: 'login.main.passwordError' })
      : '';

    this.setState({ email: newEmailState, password: newPasswordState });

    if (this.state.email.value && this.state.password.value) {
      this.props.mutate({
        variables: {
          email: this.state.email.value,
          password: this.state.password.value,
        },
        onError: (err) => {
          this.enableSubmitButton();
          this.setLoginError(err);
        },
      });
    }

    if (newEmailState.error || newPasswordState.error) {
      this.setState({ isLoading: false });
    }

    return;
  };

  setLoginError = (loginError) => {
    this.setState({ loginError: getLoginException(loginError) });
  };

  enableSubmitButton = () => {
    if (this.state.isLoading) this.setState({ isLoading: false });
  };

  handleForgotPassword = () => {
    this.setState({ forgotPasswordModal: true });
  };

  handleForgotPasswordDismiss = () => {
    this.setState({ forgotPasswordModal: false });
  };

  render() {
    return (
      <ContainerStyled container item distribution="center" name="login-container">
        {(getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'Develop' ||
          getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'QA' ||
          getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'UAT') && (
          <NotificationBanner
            name="LoginContainer__NotificationBanner"
            color="primary"
            icon="exclamation-triangle"
            title={`This is the ${getEnvVar('REACT_APP_ENVIRONMENT_NAME')} site`}
            body="Please note that we regularly reset the data and anything you enter may be overwritten. We recommend that personal details of real individuals are not entered"
          />
        )}
        {this.state.forgotPasswordModal && (
          <Modal
            size="sm"
            show
            name="account-create-modal"
            dismissable
            onDismiss={this.handleForgotPasswordDismiss}
            height="auto"
            maxHeight="90vh"
          >
            <ForgotPassword id="forgotPasswordModal__Contents" username={this.state.email.value} />
          </Modal>
        )}
        <Row container item distribution="center">
          <ColStyled container vdistribution="center" item itemAlign="center" span={3}>
            <LogoStyled />
            <Heading size="lg">
              <FormattedMessage id="login.main.title" />
            </Heading>
            <Text align="center">
              <FormattedMessage id="login.main.subheading" />
            </Text>

            <FormStyled name="login__form" onSubmit={this.handleSubmit}>
              <TextInputStyled
                box
                name="email"
                placeholder={this.props.intl.formatMessage({ id: 'login.main.emailPlaceholder' })}
                value={this.state.email.value}
                error={this.state.email.error}
                onChange={this.handleChange}
              />
              <TextInputStyled
                box
                name="password"
                type="password"
                placeholder={this.props.intl.formatMessage({ id: 'login.main.passwordPlaceholder' })}
                value={this.state.password.value}
                error={this.state.password.error}
                onChange={this.handleChange}
              />

              <RowForLinks container distribution="between">
                <TextLinkStyled inline id={'forgotPasswordTextLink'} onClick={this.handleForgotPassword}>
                  <FormattedMessage id="login.main.forgotPassword" />
                </TextLinkStyled>
                <TextLinkStyled to="/register" component="routerLink">
                  <FormattedMessage id="login.main.newRioUser" />
                </TextLinkStyled>
              </RowForLinks>

              <Button name="loginbutton" loading={this.state.isLoading} submit>
                <FormattedMessage id="login.main.loginButton" />
              </Button>
            </FormStyled>
            {this.state.loginError && <RenderLoginError loginError={this.state.loginError} />}
          </ColStyled>
        </Row>
      </ContainerStyled>
    );
  }
}

export default compose(graphql(LOG_IN), injectIntl)(LoginContainer);
