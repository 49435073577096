import { useQuery } from "@apollo/client";
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_JOURNEY_TARGETS } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface JourneyTargetSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function JourneyTargetSelect({ value, onChange }: JourneyTargetSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllJourneyTargets'>>(GET_ALL_JOURNEY_TARGETS);
  return <Select placeholder="Please select purpose of journey" value={value} options={toSelectOptions(data?.getAllJourneyTargets)} error={error} isLoading={loading} onChange={onChange} />;
}
