import React from 'react';
import styled from 'styled-components';
import { Col, Row, Heading, Notification, ImageEditor } from 'rio-ui-components';
import { Mutation } from '@apollo/client/react/components';
import { v4 as uuidv4 } from 'uuid';

import { UPLOAD_LOGO } from './index.queries';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const UploadColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

class AccountLogoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isSuccess: false,
      notification: { show: false, message: '', color: 'success' },
      isSubmitted: false
    };
  }

  hideNotification = () => this.setState({ notification: { ...this.state.notification, show: false } });

  onUploadError = () => {
    this.setState({
      notification: {
        show: true,
        message: 'Something went wrong. If the problem persists, contact support.',
        color: 'danger'
      },
      hasError: true,
      isSuccess: false,
      isSubmitted: false
    });
  };

  onLogoSave = (data, callback) => {
    callback({
      variables: {
        fileName: `${this.props.accountData.id}.png`,
        data: data,
        contentType: 'image/png',
        accountId: this.props.accountData.id,
        accountName: this.props.accountData.name,
        accountType: this.props.accountData.type,
        accessControls: this.props.accountData.accessControls
      }
    });
    this.setState({
      isSubmitted: true
    });
  };

  render() {
    return (
      <Container name="upload-account">
        {this.state.notification.show && (
          <Notification
            name="upload-account__notification"
            show
            color={this.state.notification.color}
            onClose={this.hideNotification}
          >
            {this.state.notification.message}
          </Notification>
        )}

        <Mutation
          mutation={UPLOAD_LOGO}
          onCompleted={() => this.props.onSuccess('The logo has been uploaded!', 'success')}
          onError={this.onUploadError}
        >
          {(uploadLogo) => (
            <div>
              <HeaderStyled name="upload-account__heading" size="lg">
                Add/Edit Logo
              </HeaderStyled>
              <Row container align="between">
                <UploadColStyled item container>
                  <ImageEditor
                    width={141}
                    height={69}
                    border={50}
                    defaultImage={
                      this.props.accountData.logoPath ? `${this.props.accountData.logoPath}?v=${uuidv4()}` : ''
                    }
                    onSave={(data) => this.onLogoSave(data, uploadLogo)}
                    acceptedFormats={['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']}
                    outputFormat={{ format: 'image/png', width: 141, height: 69 }}
                  />
                </UploadColStyled>
              </Row>
            </div>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default AccountLogoUpload;
