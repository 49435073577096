import { gql } from "@apollo/client";

export const GET_CONTAINER_TYPE_SIZES = gql`
  query getContainerTypeSizes {
    getContainerTypeSizePage(page: 1, pageSize: 5000) {
      count
      hasNextPage
      hasPreviousPage
      isFirstPage
      isLastPage
      pageCount
      totalItemCount
      page
      pageSize
      typeSizes {
        id
        name
      }
    }
  }
`;
