import { TransactionType } from '@rio/rio-types';
import wasteColumns from '../../../containers/DataContainer/WasteContainer/UploadContainer/columns.json';
import electricityColumns from '../../../containers/DataContainer/ElectricityContainer/UploadContainer/columns.json';
import heatSteamColumns from '../../../containers/DataContainer/HeatSteamContainer/UploadContainer/columns.json';
import gasColumns from '../../../containers/DataContainer/GasContainer/UploadContainer/columns.json';
import waterColumns from '../../../containers/DataContainer/WaterContainer/UploadContainer/columns.json';
import transportColumns from '../../../containers/DataContainer/TransportContainer/UploadTransportTransactions/columns.json';
import fuelColumns from '../../../containers/DataContainer/FuelContainer/UploadFuelTransactions/columns.json';
import materialsColumns from '../../../containers/DataContainer/MaterialsContainer/UploadContainer/columns.json';
import hotelStaysColumns from '../../../containers/DataContainer/HotelStaysContainer/UploadContainer/columns.json';
import refrigerantsColumns from '../../../containers/DataContainer/RefrigerantsContainer/UploadContainer/columns.json';

export const DEFAULT_TRANSPOSITION_ID = 'DEFAULT_TRANSPOSITION';
export const DEFAULT_TYPE_COLUMNS = {
  WASTE: wasteColumns,
  ELECTRICITY: electricityColumns,
  GAS: gasColumns,
  WATER: waterColumns,
  TRANSPORT: transportColumns,
  FUEL: fuelColumns,
  [TransactionType.Heatsteam]: heatSteamColumns,
  [TransactionType.Materials]: materialsColumns,
  [TransactionType.Hotelstays]: hotelStaysColumns,
  [TransactionType.Refrigerant]: refrigerantsColumns,
};

export const getDefaultTransposition = (type) => {
  const defaultTransposition = {
    id: DEFAULT_TRANSPOSITION_ID,
    transpositionName: 'Default',
    columnMapper: {
      mappings: DEFAULT_TYPE_COLUMNS[type].map((columnName) => ({
        columnName,
        transposition: columnName,
      })),
      ignored: [],
    },
  };

  if (type === 'TRANSPORT') {
    defaultTransposition.columnMapper.mappings.push({
      columnName: 'Distance',
      transposition: 'Total Distance',
    });
  }

  return defaultTransposition;
};
