import { useEffect, useState } from 'react';
import { Checkbox, Row, TextInput } from 'rio-ui-components';
import { NumberInput, NumberInputWithNegativeOption } from '../UI';
import { CreateMeterModal } from './../CreateMeterModal';
import { useGetMeters, useCurrentAccountId, usePermissions, useCurrency } from '../../hooks';
import { DIRECT, INDIRECT } from '../../constants/transactionActivityTypes';
import { Controller } from 'react-hook-form';
import { DataForm, SelectRender } from '../DataForm';
import { SupplierSelector } from '../SuppliersSelector';
import { TariffSelect } from '../TariffSelect';
import {
  CenteredCol,
  ColStyled,
  LabelContainerStyled,
  LabelStyled,
  Loader,
  RowWithPadding,
  SectionStyled,
  SubHeader,
} from '../DataForm/DataFormStyles';

export function EnergyDataForm(props) {
  /**
   * PROPS
   */
  const { form = {}, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, trigger, register, watch } = form;

  /**
   * STATE
   */
  const [isMeterFormVisible, setMeterFormVisible] = useState(false);
  const [currencyInitialState] = useState(form.getValues('data.currency'));

  /**
   * HOOKS
   */
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const { data: metersList, loading: metersLoading } = useGetMeters(dataSection);
  const { data: currenciesList, loading: currenciesListLoading } = useCurrency();

  const canCreateMeters = permissions.data.find((action) => action.startsWith('createEnergySource'));

  useEffect(() => {
    register('isReferenceDataRequired');
  }, [register]);

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const newOptionStrategy = {
    'data.energySource': () => setMeterFormVisible(true),
  };

  const createNewOption = (name) => newOptionStrategy[name] && newOptionStrategy[name]();

  const setReferenceDataRequired = (value) => setValue('isReferenceDataRequired', value);

  const closeCreatedMeterForm = () => setMeterFormVisible(false);
  const selectCreatedMeter = (key, value) => {
    setValue(key, value);
    trigger();

    closeCreatedMeterForm();
  };

  const showReferenceData = () => setReferenceDataRequired(true);
  const hideReferenceData = () => setReferenceDataRequired(false);

  /**
   * SUBMIT
   * mapping data on submit event
   */
  const handleOnSubmit = ({ data, financial, reference, ...others }) => {
    const formData = {
      ...others,
      data: {
        ...data,
        accuracy: data?.accuracy?.name || 'ACT',
        activity: data.activity.name,
        currency: financial?.currency?.name || currencyInitialState,
      },
    };

    if (isReferenceDataRequired) {
      formData.data = { ...formData.data, ...reference };
    }

    onSubmit(formData);
  };

  const entityToDropdownOption = ({ id, name } = {}) => ({
    label: name,
    value: { id, name },
  });

  const meterToDropdownOption = ({ id, name, locationPoint } = {}) => ({
    label: name,
    value: { id, name, locationPoint },
  });

  /**
   * OPTIONS
   */
  const metersOptions = metersList.map(meterToDropdownOption);
  if (canCreateMeters) {
    metersOptions.unshift({
      label: 'Create new ...',
      value: { id: 'createNew' },
    });
  }
  const actEstOptions = [
    { id: 'ACT', name: 'ACT' },
    { id: 'EST', name: 'EST' },
  ].map(entityToDropdownOption);
  const activityOptions = [
    { id: DIRECT, name: DIRECT },
    { id: INDIRECT, name: INDIRECT },
  ].map(entityToDropdownOption);
  const currencyOptions = currenciesList.map(entityToDropdownOption);

  /**
   * DEFAULT FORM VALUES
   */
  const activityDefaultValues = { id: DIRECT, name: DIRECT };
  const currencyDefaultValue = currencyOptions.find((item) => item.label === currencyInitialState)?.value;

  const isLoading = metersLoading || currenciesListLoading;

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showEndDate showTime>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          {isMeterFormVisible && (
            <CreateMeterModal
              energyType={dataSection}
              onSuccess={selectCreatedMeter}
              onDismiss={setMeterFormVisible}
              showModal={isMeterFormVisible}
              accountId={accountId}
            />
          )}

          <Row>
            <SubHeader>Please enter the following transactional {dataSection.toLowerCase()} data:</SubHeader>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Meter Name</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.energySource"
                render={({ field, fieldState }) => (
                  <SelectRender
                    {...field}
                    onCreateNew={createNewOption}
                    mapper={meterToDropdownOption}
                    options={metersOptions}
                    error={fieldState.error?.message}
                  />
                )}
                rules={{ required: 'Meter Name is required' }}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Tariff (optional)</LabelStyled>
              </LabelContainerStyled>
              <Controller
                name="data.tariff"
                render={({ field }) => <TariffSelect {...field} accountId={accountId} valueAsLabel />}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Quantity (kWh)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.quantity"
                render={({ field, fieldState }) => (
                  <NumberInputWithNegativeOption {...field} error={fieldState.error?.message} />
                )}
                rules={{ required: 'Quantity is required' }}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Total Cost (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.totalCost"
                render={({ field }) => <NumberInput {...field} />}
                control={control}
                defaultValue=""
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Currency</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.currency"
                render={({ field }) => (
                  <SelectRender {...field} mapper={entityToDropdownOption} options={currencyOptions} />
                )}
                defaultValue={currencyDefaultValue}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Act/Est (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.accuracy"
                render={({ field }) => (
                  <SelectRender {...field} mapper={entityToDropdownOption} options={actEstOptions} />
                )}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Supplier (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.supplier"
                render={({ field, fieldState }) => (
                  <SupplierSelector
                    accountId={accountId}
                    value={field.value}
                    onChange={(e) => setValue('data.supplier', e.target.select)}
                    error={fieldState.error?.message}
                    utility={dataSection}
                    isClearable
                  />
                )}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Charge/kWh (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.charge/kwh"
                render={({ field }) => <NumberInputWithNegativeOption {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Activity</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.activity"
                render={({ field, fieldState }) => (
                  <SelectRender
                    {...field}
                    mapper={entityToDropdownOption}
                    options={activityOptions}
                    error={fieldState.error?.message}
                  />
                )}
                rules={{ required: 'Activity is required' }}
                defaultValue={activityDefaultValues}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Comment (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.comment"
                render={({ field }) => <TextInput {...field} />}
                control={control}
                defaultValue=""
              />
            </ColStyled>
          </Row>

          <RowWithPadding container itemAlign="center">
            <div style={{ width: '50%' }}>Do you want to add any references?</div>

            <div>
              <Row container align="center">
                <CenteredCol item>
                  <Checkbox size="md" checked={isReferenceDataRequired} onChange={showReferenceData} />

                  <LabelContainerStyled>
                    <LabelStyled>Yes</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>

                <CenteredCol item>
                  <Checkbox size="md" checked={!isReferenceDataRequired} onChange={hideReferenceData} />

                  <LabelContainerStyled>
                    <LabelStyled>No</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>
              </Row>
            </div>
          </RowWithPadding>

          <SectionStyled isVisible={isReferenceDataRequired}>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Invoice Number (optional)</LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.invoiceNumber"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>

            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Evidence Reference (optional) </LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.evidenceReference"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>

            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Other Reference (optional)</LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.otherReference"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>
          </SectionStyled>
          {props.children}
        </div>
      )}
    </DataForm>
  );
}
