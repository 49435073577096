import React, { useCallback, createContext, useState, useContext, useMemo } from 'react';
import { Notification as ToastV1 } from 'rio-ui-components';
import { styled, ToastColor, ToastProvider as ToastProviderV2, useToast as useToastV2 } from '@rio/ui-components';
import { useIsV2 } from './useCurrentVersion';

type Action = { text?: string; callback?: () => any; autoClose?: number };
type Message = string | React.ReactNode;
type ShowNotification = (message: Message, color?: ToastColor, action?: Action) => void;

const NotificationContext = createContext<{
  showNotification: ShowNotification;
}>({
  showNotification: () => {},
});

const ActionContainer = styled('div')`
  margin: 15px -38px 0 -24px;
  padding-top: 15px;
  text-align: center;
  border-top: 1px solid #fff;
`;

type Props = { children: React.ReactNode };

const Notifications = ({ children }: Props) => {
  const { showToast: showToastV2 } = useToastV2();

  const [snackPack, setSnackPack] = useState<{ message: Message; color: ToastColor; action?: Action }[]>([]);
  const messageInfo = snackPack[0];

  const isV2 = useIsV2();
  const isV1 = !isV2;

  const showNotification = useCallback<ShowNotification>(
    (message, color, action) => {
      if (isV2) {
        showToastV2(message as string, color || 'success');
      } else {
        setSnackPack((prev) => {
          const isDuplicate = prev.find((msg) => msg.message === message) || prev[0]?.message === message;
          return isDuplicate ? prev : [...prev, { message, action, color: color || 'success' }];
        });
      }
    },
    [setSnackPack, isV2, showToastV2]
  );

  const value = useMemo(() => ({ showNotification }), [showNotification]);

  const handleClose = (_event?: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackPack((prev) => prev.slice(1));
  };

  const { autoClose = 5000 } = messageInfo?.action || {};

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {isV1 && (
        <ToastV1
          name="general__notification"
          show={!!snackPack.length}
          color={messageInfo?.color}
          autoClose={autoClose}
          onClose={handleClose}
        >
          {messageInfo?.action?.callback ? (
            <div
              onClick={() => {
                handleClose();
                messageInfo.action?.callback?.();
              }}
            >
              <div>{messageInfo?.message as string}</div>
              <ActionContainer>{messageInfo?.action?.text || 'OK'}</ActionContainer>
            </div>
          ) : (
            <span>{messageInfo?.message as string}</span>
          )}
        </ToastV1>
      )}
    </NotificationContext.Provider>
  );
};

export const NotificationProvider = (props: Props) => (
  <ToastProviderV2>
    <Notifications {...props} />
  </ToastProviderV2>
);

export const useNotification = () => useContext(NotificationContext);
