import { useApolloClient } from '@apollo/client';
import { QueryGetAspectsByAccountIdArgs } from '@rio/rio-types';
import { GetAspectsByAccountIdResponse, GetAspectsCountByAccountIdResponse } from './types';
import { GET_ASPECTS_BY_ACCOUNT_ID, GET_ASPECTS_COUNT_BY_ACCOUNT_ID } from './index.queries';

export function useGetAspectsByAccountId() {
  const client = useApolloClient();
  return async (variables: QueryGetAspectsByAccountIdArgs) => {
    const {
      data: { getAspectsByAccountId },
    } = await client.query<GetAspectsByAccountIdResponse>({
      query: GET_ASPECTS_BY_ACCOUNT_ID,
      variables,
    });
    return getAspectsByAccountId;
  };
}

export function useGetAspectsCountByAccountId() {
  const client = useApolloClient();
  return async (variables: QueryGetAspectsByAccountIdArgs) => {
    const {
      data: { getAspectsByAccountId },
    } = await client.query<GetAspectsCountByAccountIdResponse>({
      query: GET_ASPECTS_COUNT_BY_ACCOUNT_ID,
      variables,
    });
    return getAspectsByAccountId;
  };
}
