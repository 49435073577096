import { gql } from '@apollo/client';
import LEGISLATION_FIELDS from '../../../graphql/fragments/LegislationFields.fragment.graphql';

export const GET_LEGISLATION_BY_ID = gql`
  query GetLegislationById($id: ID!, $accountId: ID!) {
    getLegislationById(id: $id, accountId: $accountId) {
      ...LegislationFields
    }
  }
  ${LEGISLATION_FIELDS}
`;

export const UPDATE_LEGISLATION = gql`
  mutation updateLegislation($legislation: LegislationInput, $userId: ID!, $accountId: ID!) {
    updateLegislation(input: { legislation: $legislation, userId: $userId, accountId: $accountId }) {
      ...LegislationFields
    }
  }
  ${LEGISLATION_FIELDS}
`;
