import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Col, Search, Button, LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';

import { GET_ALL_KBS } from './index.queries';

import ContainerHeader from '../../../components/ContainerHeader';
import { LoadFailed } from '../../../components/Errors';
import { useSearch } from '../../../hooks/useSearch';
import { useNotification } from '../../../hooks/useNotification';
import NoResults from '../../../components/NoResults';
import KbRow from './KnowledgeBaseRow';
import CreateTemplateModal from './CreateKnowledgeBaseModal';
import UpdateTemplateModal from './UpdateKnowledgeBaseModal';
import alphabetise from '../../../utils/alphabetise';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const ConfigurationKbContainer = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState('');
  const [selectedKb, setSelectedKb] = useState(null);
  const { showNotification } = useNotification();

  const { data, loading, error, refetch } = useQuery(GET_ALL_KBS);

  const [search, setSearch, searched] = useSearch(data?.getAllKnowledgeBases || [], ['title']);

  return (
    <Col name="ConfigurationKbContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationKbContainer__Controls"
        icon="brain"
        iconColor="primary"
        title="Knowledge Bases"
      >
        <Col container item>
          <Search
            name="ConfigurationKbContainer__Controls__Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            hideButton
          />
        </Col>
        <ButtonStyled
          name="ConfigurationKbContainer__Controls__Button--create"
          size="md"
          color="primary"
          inline
          onClick={() => setShowCreateModal(true)}
        >
          + Add KB
        </ButtonStyled>
      </ContainerHeader>

      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}

      {error && <LoadFailed error={error} retry={refetch} />}

      {showCreateModal && (
        <CreateTemplateModal onComplete={showNotification} onDismiss={() => setShowCreateModal(false)} />
      )}
      {showUpdateModal && (
        <UpdateTemplateModal
          onComplete={showNotification}
          onDismiss={() => setShowUpdateModal(false)}
          selectedKb={selectedKb}
        />
      )}
      {!loading && !error && !searched.length && (
        <NoResults name="ConfigurationKbsContainer__NoResults" title="There are no results for this search." />
      )}
      {!loading &&
        !!searched.length &&
        searched
          .slice()
          .sort((kbA, KbB) => alphabetise(kbA.title, KbB.title))
          .map((kb) => (
            <KbRow
              key={kb.id}
              kb={kb}
              onEdit={() => {
                setSelectedKb(kb);
                setShowUpdateModal(true);
              }}
            />
          ))}
    </Col>
  );
};

export default ConfigurationKbContainer;
