import { useApolloClient } from '@apollo/client';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { DocumentRecord, QueryGetDocumentsPageArgs } from '@rio/rio-types';
import { FolderGrid } from '../Folders';
import { Grid } from '../Documents/v2/Grid';
import { GET_DOCUMENTS_PAGE } from '../index.queries';
import UserBannersSection from '~/components/UserBannersSection';
import { useCurrentAccountId } from '~/hooks';
import { Folder } from '../../types';
import { getAllFilters } from '../../utils';
import { SearchHeader } from './SearchHeader';
import { styled, Text } from '@rio/ui-components';
import { GridApi } from 'ag-grid-community';

const NoResultsTextStyled = styled(Text)`
  text-align: center;
  margin: 16px auto;
`;

const SeparatoryLine = styled('hr')`
  height: 1px;
  background-color: ${(props) => props.theme.sys.color.onSurface};
  margin: 16px 0;
`;

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface DocumentExplorerProps {
  showGrid?: boolean;
  isManagementSystem?: boolean;
  folders?: Folder[];
  documentsExist?: boolean;
  searchValue?: string;
  library?: string;
  managementSystemId?: string;
  managementSystemChapterId?: string;
  category?: string;
  banners?: object[];
  searchPlaceholder?: string;
  gridApi: GridApi | undefined;
  setGridApi?: Dispatch<SetStateAction<GridApi | undefined>>;
  onDocumentSelect?: (document: DocumentRecord) => void;
  onSearch?: (searchValue: string) => void;
}

export function DocumentExplorer({
  isManagementSystem,
  library,
  category,
  folders = [],
  documentsExist = false,
  searchValue = '',
  searchPlaceholder,
  banners = [],
  onSearch = () => {},
  gridApi,
  setGridApi = () => {},
  showGrid,
  managementSystemId,
  managementSystemChapterId,
}: DocumentExplorerProps) {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const noResults = !folders.length && !documentsExist && searchValue;

  const renderGrid = useMemo(() => {
    return ((documentsExist && (searchValue || isManagementSystem)) || showGrid) && (
      <Grid
        library={library}
        category={category}
        accountId={accountId}
        key={accountId}
        setGridApi={setGridApi}
        gridApi={gridApi}
        fetchRows={async (vars: QueryGetDocumentsPageArgs) => {
          const variables = { ...vars };
          if (!variables.filters) {
            variables.filters = {};
          }
          const extraFilters = getAllFilters(
            searchValue,
            library,
            category,
            managementSystemId,
            managementSystemChapterId
          );

          const { filters } = variables;

          if (!!extraFilters.set) {
            if (!filters?.set) {
              filters.set = [];
            }
            filters.set = filters.set.concat(extraFilters.set).filter((f) => !!f.values.length);
          }

          if (!!extraFilters.text) {
            if (!filters?.text) {
              filters.text = [];
            }
            filters.text.push(...extraFilters.text);
          }

          const {
            data: {
              getDocumentsPage: { totalRows, rows },
            },
          } = await client.query({
            query: GET_DOCUMENTS_PAGE,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows,
            totalRows,
          };
        }}
      />
    );
  }, [documentsExist, searchValue, showGrid, library, category, accountId, gridApi, setGridApi]);

  return (
    <div>
      {!showGrid && (
        <WrapperStyled>
          <SearchHeader
            search={searchValue}
            onSearchValueChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
              onSearch(value);
            }}
            placeholder={searchPlaceholder}
          />
          <UserBannersSection id="documentBanners" staticBanners={banners} />
        </WrapperStyled>
      )}
      {noResults ? (
        <NoResultsTextStyled typescale="title" size="large">{`No Results found for ${
          searchValue || 'your search.'
        }`}</NoResultsTextStyled>
      ) : (
        <>
          {!!folders.length && <FolderGrid folders={folders} />}
          {documentsExist && !!folders.length && (searchValue || managementSystemId) && <SeparatoryLine />}
          {renderGrid}
        </>
      )}
    </div>
  );
}
