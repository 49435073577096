import { useMutation } from '@apollo/client';
import { Mutation } from '@rio/rio-types';
import { GetSurveyTemplatesResponse } from './useGet';
import { DELETE_SURVEY_TEMPLATE, GET_SURVEY_TEMPLATES } from './queries';
import { useNotification } from '../../../../hooks';

type DeleteFormTemplateResponse = Pick<Mutation, 'deleteSurveyTemplate'>;

export function useDelete() {
  const { showNotification } = useNotification();
  return useMutation<DeleteFormTemplateResponse>(DELETE_SURVEY_TEMPLATE, {
    onCompleted: () => {
      showNotification('Template has been deleted.', 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },
    update: (cache, result) => {
      const { getSurveyTemplates } = cache.readQuery<GetSurveyTemplatesResponse>({
        query: GET_SURVEY_TEMPLATES
      }) as GetSurveyTemplatesResponse;
      const id = result.data?.deleteSurveyTemplate;
      if (id) {
        cache.writeQuery({
          query: GET_SURVEY_TEMPLATES,
          data: {
            getSurveyTemplates: getSurveyTemplates.filter((t) => t.id !== id)
          }
        });
      }
    }
  });
}
