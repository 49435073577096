import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Query,
  Mutation,
  QueryGetQuestionsArgs,
  MutationUpdateSurveyQuestionArgs,
  QueryGetQuestionsFilterArgs,
} from '@rio/rio-types';
import { GET_QUESTIONS, UPDATE_QUESTION, GET_QUESTIONS_FILTER } from './queries';

export type GetResponse = Pick<Query, 'getQuestions'>;
export type GetFilterResponse = Pick<Query, 'getQuestionsFilter'>;

export function useGet() {
  return useLazyQuery<GetResponse, QueryGetQuestionsArgs>(GET_QUESTIONS);
}
export function useGetFilter() {
  return useLazyQuery<GetFilterResponse, QueryGetQuestionsFilterArgs>(GET_QUESTIONS_FILTER);
}

export function useUpdateQuestion() {
  return useMutation<Pick<Mutation, 'updateSurveyQuestion'>, MutationUpdateSurveyQuestionArgs>(UPDATE_QUESTION, {
    update: (cache, { data }) => {
      cache.updateQuery({ query: GET_QUESTIONS }, (cacheResponse) => {
        const questions =
          cacheResponse?.getQuestions.rows.map((q) => {
            if (q.id === data?.updateSurveyQuestion.id) {
              return {
                ...q,
                ...data?.updateSurveyQuestion,
              };
            }
            return q;
          }) || [];
        return {
          getQuestions: {
            totalRows: cacheResponse?.getQuestions.totalRows,
            rows: questions,
          },
        };
      });
    },
  });
}
