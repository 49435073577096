import { useCallback } from 'react';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { YesNoQuestion } from '@rio/rio-types';
import { Autocomplete, AutocompleteField } from 'rio-ui-components';
import { AnswerQuestionFunction } from './types';

interface ChoiceQuestionSelectProps {
  question: YesNoQuestion;
  onAnswer: AnswerQuestionFunction;
}

function getOptionFromAnswer(answer: boolean) {
  return answer ? 'Yes' : 'No';
}

function getAnswerFromOption(option: string) {
  if (option === 'Yes') {
    return true;
  }
  if (option === 'No') {
    return false;
  }
  return null;
}

export function YesNoQuestionSelect({ question, onAnswer }: ChoiceQuestionSelectProps) {
  const handleChange = useCallback(
    (_, answer: string | null) => {
      onAnswer({
        ...question,
        reportedAnswer: getAnswerFromOption(answer!)
      });
    },
    [question, onAnswer]
  );
  return (
    <Autocomplete
      fullWidth
      value={
        isUndefined(question.reportedAnswer) || isNull(question.reportedAnswer)
          ? null
          : getOptionFromAnswer(question.reportedAnswer)
      }
      onChange={handleChange}
      options={['Yes', 'No']}
      renderInput={(props: object) => (
        <AutocompleteField
          {...props}
          label={question.shortFormulation || question.formulation}
          placeholder={question.shortFormulation || 'Yes/No'}
        />
      )}
    />
  );
}
