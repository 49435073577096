import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CreateDocumentRecordInput, DocumentLibrary, Tag } from '@rio/rio-types';
import { DatePicker, Select, Row, Col, Label, Checkbox, MultiSelect, TextInput } from 'rio-ui-components';
import get from 'lodash/get';
import { useAccessControls } from '../../../hooks/useAccessControls';
import documentLibraries from '../documentLibraries';
import documentCategories from '../documentCategories.json';
import consignmentNoteTypes from '../consignmentNoteTypes.json';
import { getReferenceIdFromFileName } from '../utils';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { MultiSelectEvent, SelectEvent, NullableObject } from '../../../types';
import { DocumentLibraryFolder } from '../types';
import { SupplierSelector } from '../../../components/SuppliersSelector';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

export const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

export const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

export const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const allLibraries: DocumentLibraryFolder[] = documentLibraries as DocumentLibraryFolder[];

interface DocumentDetailsProps {
  document: NullableObject<CreateDocumentRecordInput>;
  isSubmitting?: boolean;
  onChange: (values: NullableObject<CreateDocumentRecordInput>) => void;
  predefinedValues?: NullableObject<CreateDocumentRecordInput>;
}

export const DocumentDetails: FC<DocumentDetailsProps> = ({
  document,
  isSubmitting,
  onChange,
  predefinedValues = {},
}) => {
  const currentUser = useCurrentUser();
  const { data: accessControls } = useAccessControls();
  const accountId = useCurrentAccountId();
  const [parseIdFromFileName, setParseIdFromFileName] = useState(true);
  const needsType = document.category === 'Consignment Note (EA)' || document.category === 'Consignment Note (SEPA)';
  const tags = document.tags as Tag[] | undefined;
  const [notesWarning, setNotesWarning] = useState<boolean>(false);
  // eslint-disable-next-line
  const allowedTags = currentUser?.allowedTags || [];

  const filteredLibraries = useMemo(
    () => allLibraries.filter((item) => get(accessControls, item.accessControlIndexer!)),
    [accessControls]
  );

  useEffect(() => {
    if (!tags && allowedTags?.length) {
      onChange({
        ...document,
        tags: allowedTags.map((tag) => ({ value: tag?.id, label: tag?.tagName })) as any,
      });
    }
  }, [tags, allowedTags, document, onChange]);

  return (
    <>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Select document library</LabelStyled>
          </LabelContainerStyled>
          <Select
            value={document.library}
            options={filteredLibraries.map((library) => ({
              label: library.title,
              value: library.library,
            }))}
            isDisabled={!!predefinedValues.library || isSubmitting}
            onChange={(e: SelectEvent) => {
              onChange({
                ...document,
                library: e.target.value as DocumentLibrary,
              });
            }}
          />
        </ColStyled>
      </Row>
      {document.library && (
        <>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Select document category</LabelStyled>
              </LabelContainerStyled>
              <Select
                value={document.category}
                options={documentCategories.filter((cat) =>
                  cat.libraries.includes(document.library as DocumentLibrary)
                )}
                isDisabled={!!predefinedValues.category || isSubmitting}
                onChange={(e: SelectEvent) => {
                  onChange({
                    ...document,
                    category: e.target.value,
                  });
                }}
              />
            </ColStyled>
          </Row>
          {document.category && (
            <>
              {needsType && (
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>Select document type</LabelStyled>
                    </LabelContainerStyled>
                    <MultiSelect
                      value={
                        Array.isArray(document.type)
                          ? document.type.map((type: string) => ({
                              label: type,
                              value: type,
                            }))
                          : []
                      }
                      options={consignmentNoteTypes}
                      isDisabled={isSubmitting}
                      onChange={(e: MultiSelectEvent) => {
                        onChange({
                          ...document,
                          type: e.target.select.map((option) => option.value),
                        });
                      }}
                      multi
                    />
                  </ColStyled>
                </Row>
              )}
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Parse ID From Filename</LabelStyled>
                  </LabelContainerStyled>
                  <Checkbox
                    checked={parseIdFromFileName}
                    onChange={() => {
                      const shouldParse = !parseIdFromFileName;
                      setParseIdFromFileName(shouldParse);
                      if (shouldParse) {
                        onChange({
                          ...document,
                          referenceId: getReferenceIdFromFileName(get(document, 'fileName')!),
                        });
                      }
                    }}
                  />
                </ColStyled>
              </Row>
              {!parseIdFromFileName && (
                <Row container align="between">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>{document.category} ID</LabelStyled>
                    </LabelContainerStyled>
                    <TextInput
                      value={document.referenceId}
                      onChange={(e: SelectEvent) => {
                        onChange({
                          ...document,
                          referenceId: e.target.value,
                        });
                      }}
                    />
                  </ColStyled>
                </Row>
              )}
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Review date (optional)</LabelStyled>
                  </LabelContainerStyled>
                  <DatePicker
                    placeholder="Select review date"
                    disabled={isSubmitting}
                    onDayClick={(reviewDate: Date) => {
                      onChange({
                        ...document,
                        reviewDate: reviewDate.toISOString(),
                      });
                    }}
                    selectedDate={document.reviewDate ? new Date(document.reviewDate) : null}
                  />
                </ColStyled>
              </Row>
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Select supplier (optional)</LabelStyled>
                  </LabelContainerStyled>
                  <SupplierSelector
                    accountId={accountId}
                    value={document.supplierId}
                    onChange={(e) =>
                      onChange({
                        ...document,
                        supplierId: e.target.value,
                      })
                    }
                  />
                </ColStyled>
              </Row>
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Select Tags (optional)</LabelStyled>
                  </LabelContainerStyled>
                  <TagsMultiSelect
                    value={(tags as any)?.map((tag: any) => {
                      return { value: tag.id || tag.value, label: tag.tagName || tag.label };
                    })}
                    accountId={accountId}
                    onChange={(e: MultiSelectEvent) => {
                      onChange({
                        ...document,
                        tags: e.target.select as any,
                      });
                    }}
                  />
                </ColStyled>
              </Row>
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Notes</LabelStyled>
                  </LabelContainerStyled>
                  <TextInput
                    value={document.notes}
                    error={notesWarning && 'Notes can not contain more than 255 symbols'}
                    onChange={(e: SelectEvent) => {
                      setNotesWarning(false);
                      if (e?.target?.value?.length > 255) {
                        setNotesWarning(true);
                        return;
                      }
                      onChange({
                        ...document,
                        notes: e.target.value,
                      });
                    }}
                  />
                </ColStyled>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
