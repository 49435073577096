import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Heading, TextLink, Notification } from 'rio-ui-components';
import { Auth } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import { AuthPage } from '../../components/AuthPage';
import { CredentialsForm } from './CredentialsForm';
import { ProfileForm } from './ProfileForm';
import { ContactsForm } from './ContactsForm';
import { validateCredentialsForm, validateProfileForm } from './validators';
import { VerifyEmailPrompt } from './VerifyEmailPrompt';

const RegistrationContainer = ({ intl }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ hubspotutk }] = useCookies(['hubspotutk']);

  const [form, setForm] = useState({
    email: '',
    password: '',
    passwordConfirmed: '',
    agreedWithTermsOfService: false,
    agreedWithTermsOfUse: false,
    firstName: '',
    lastName: '',
    companyName: '',
    preferPersonalUse: false,
    phone: '',
    mobile: '',
    jobTitle: '',
    department: '',
    hutk: hubspotutk || '',
  });
  const [promptShown, setPromptShown] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState(null);
  const credentialsHasSomeErrors = !!Object.keys(validateCredentialsForm(form)).length;
  const profileHasSomeErrors = !!Object.keys(validateProfileForm(form)).length;
  const queryParams = location?.search;

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <AuthPage span={3}>
              <Heading size="lg">
                <FormattedMessage id="login.registration.mainPage.title" />
              </Heading>
              <Heading size="sm" align="center">
                <FormattedMessage id="login.registration.mainPage.subtitle" />
              </Heading>
              <CredentialsForm
                form={form}
                onChange={(credentials) => {
                  setForm({
                    ...form,
                    ...credentials,
                  });
                }}
                onSubmit={() => {
                  localStorage.setItem('MEMOIZED_EMAIL', form.email);
                  navigate(`/register/profile${queryParams}`);
                }}
                intl={intl}
              />
            </AuthPage>
          }
        />
        <Route
          path="/profile"
          element={
            credentialsHasSomeErrors ? (
              <Navigate to={`/register${queryParams}`} />
            ) : (
              <AuthPage span={4}>
                <Heading size="lg">
                  <FormattedMessage id="login.registration.detailsPage.title" />
                </Heading>
                <ProfileForm
                  form={form}
                  onChange={(profile) => {
                    setForm({
                      ...form,
                      ...profile,
                    });
                  }}
                  onSubmit={async () => {
                    navigate(`/register/contacts${queryParams}`);
                  }}
                  intl={intl}
                />
              </AuthPage>
            )
          }
        />
        <Route
          path="/contacts"
          element={
            profileHasSomeErrors ? (
              <Navigate to={`/register/profile${queryParams}`} />
            ) : (
              <AuthPage span={4}>
                <Heading size="lg">
                  {' '}
                  <FormattedMessage id="login.registration.contactPage.title" />
                </Heading>
                <ContactsForm
                  form={form}
                  error={errors.submit}
                  onChange={(contacts) => {
                    setForm({
                      ...form,
                      ...contacts,
                    });
                  }}
                  onSubmit={async () => {
                    const email = form.email.trim().toLowerCase();
                    try {
                      await Auth.signUp({
                        password: form.password,
                        username: email,
                        attributes: {
                          email,
                          given_name: form.firstName.trim(),
                          family_name: form.lastName.trim(),
                          'custom:phone': form.phone.trim(),
                          'custom:mobile_number': form.mobile.trim(),
                          'custom:company_name': form.companyName.trim(),
                          'custom:prefer_personal_use': String(form.preferPersonalUse),
                          'custom:job_title': form.jobTitle.trim(),
                          'custom:department': form.department.trim(),
                          'custom:hutk': form.hutk?.trim(),
                        },
                      });
                      setPromptShown(true);
                    } catch (err) {
                      const errorMessages = {
                        'An account with the given email already exists.': (
                          <>
                            <FormattedMessage
                              id="login.errors.existAccount"
                              values={{
                                email: form.email,
                              }}
                            />
                            <TextLink
                              component="routerLink"
                              to={`/register${queryParams}`}
                              onClick={() => {
                                setErrors({});
                              }}
                            >
                              <FormattedMessage id="login.errors.changeEmail" />
                            </TextLink>
                          </>
                        ),
                      };
                      const message = errorMessages[err.message] || err.message;
                      setErrors({
                        ...errors,
                        submit: message,
                      });
                    }
                  }}
                  intl={intl}
                />
              </AuthPage>
            )
          }
        />
      </Routes>
      <VerifyEmailPrompt
        show={promptShown}
        email={form.email}
        onDismiss={() => {
          setPromptShown(false);
        }}
        queryParams={queryParams}
      />
      {notification && (
        <Notification name={notification.message} color={notification.color} onClose={() => setNotification(null)} show>
          {notification.message}
        </Notification>
      )}
    </>
  );
};

export default injectIntl(RegistrationContainer);
