/* eslint-disable react/no-unknown-property */
import { useQuery } from '@apollo/client';
import { LearnContentType } from '@rio/rio-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  Col,
  Row,
  Button,
  Heading,
  TextInput,
  Label,
  UploadFiles,
  TextArea,
  Select,
  MultiSelect,
  Checkbox
} from 'rio-ui-components';
import { CATEGORIES } from './constants';
import {
  getThumbnailSource,
  checkFileSizeIsValid,
  isFormInvalid,
  getFolderErrorMessage,
  findInvalidFiles,
  fileInvalidErrorMessage
} from './utils';
import ViewCourseButton from '../../../components/ViewCourseButton';
import { useUserToken } from '../../../hooks/useUserToken';
import { CourseTypeHandler } from '../../../utils/learn/CourseTypeHandler';
import { GET_ALL_EVALUATIONS } from '../ConfigurationLearnEvaluations/index.queries';
import { REACTION, ASSESSMENT } from '../../../constants/evaluationTypes';
import { usePermissions } from '../../../hooks/usePermissions';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  width: 200px;
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const ThumbnailUrl = styled.a`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  text-decoration-line: underline;
  cursor: pointer;
`;

const HiddenContainer = styled.div`
  display: none;
`;

const FileName = styled.p`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const RemoveFileButton = styled(Button)`
  width: 200px;
`;

const courseTypeOptions = [
  { label: 'PDF', value: LearnContentType.Pdf },
  { label: 'Video', value: LearnContentType.Video },
  { label: 'SCORM', value: LearnContentType.Scorm },
  { label: 'URL', value: LearnContentType.Url }
];

const notSelectedOption = {
  label: 'Not selected',
  value: null
};

function UploadContent({ course, uploadContent, onSuccess, edit }) {
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const [state, setState] = useState({
    id: course.id || uuid(),
    title: course.name || '',
    description: course.metaData.description || '',
    isSubmitted: false,
    invalidFiles: [],
    type: course.type || '',
    categories: course.metaData.categories
      ? course.metaData.categories.map((value) => CATEGORIES.find((c) => c.value === value))
      : [],
    thumbnail: course.metaData.thumbnail || null,
    thumbnailSource: null,
    content: course.metaData.link || null,
    fileError: null,
    cpd: course.metaData.cpd || 0,
    passScore: course.metaData.passScore || 0,
    assessmentId: course.assessment?.id,
    reactionId: course.reaction?.id,
    isEngage: course.isEngage,
    url: course.metaData.url,
    openIframe: course.metaData.openIframe
  });
  const getAllEvaluationsResult = useQuery(GET_ALL_EVALUATIONS, {
    fetchPolicy: 'network-only'
  });
  const allEvaluations = getAllEvaluationsResult?.data?.getAllEvaluations || [];
  const reactions = allEvaluations.filter((e) => e.type === REACTION);
  const assessments = allEvaluations.filter((e) => e.type === ASSESSMENT);

  const filterEvaluationsForAccount = (data) => {
    if (permissions.learn.includes('createEvaluationAll')) {
      return data;
    } else {
      return data.filter((evaluation) => evaluation.accountId === accountId);
    }
  };
  const thumbnailFilePicker = useRef(null);
  const contentFilePicker = useRef(null);
  const { sub: userId } = useUserToken();
  const handleChange = (e) => {
    e.persist();
    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      };
    });
  };
  const handleTypeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      fileError: null,
      content: null
    }));
  };
  const onThumbnailFileInputClick = () => {
    thumbnailFilePicker.current.click();
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (!checkFileSizeIsValid(file)) {
      setState((prevState) => ({
        ...prevState,
        fileError: fileInvalidErrorMessage(file)
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: file,
      fileError: ''
    }));
  };
  const onThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (!checkFileSizeIsValid(file)) {
      setState((prevState) => ({
        ...prevState,
        thumbnailFileError: fileInvalidErrorMessage(file)
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: file,
      thumbnailFileError: ''
    }));
  };
  const onFolderChange = (e) => {
    const files = e.target.files;
    const invalidFiles = findInvalidFiles(files);
    if (invalidFiles.length > 0) {
      setState((prevState) => ({
        ...prevState,
        invalidFiles,
        fileError: getFolderErrorMessage(invalidFiles)
      }));
      return;
    }
    files.name = files[0].webkitRelativePath.split('/')[0];
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: files,
      invalidFiles: []
    }));
  };
  const onContentFileInputClick = () => {
    contentFilePicker.current.click();
  };
  const removeThumbnail = () => {
    setState((prevState) => ({
      ...prevState,
      thumbnail: null
    }));
  };
  const removeContent = () => {
    setState((prevState) => ({
      ...prevState,
      content: null
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    uploadContent({
      id: state.id,
      title: state.title,
      description: state.description,
      thumbnail: state.thumbnail,
      content: state.content,
      type: state.type,
      categories: state.categories.map((c) => c.value),
      cpd: parseInt(state.cpd),
      passScore: parseInt(state.passScore),
      assessmentId: state.assessmentId,
      reactionId: state.reactionId,
      isEngage: state.isEngage,
      url: state.url,
      openIframe: state.openIframe
    });
    onSuccess();
  };
  useEffect(() => {
    getThumbnailSource(state.thumbnail).then((thumbnailSource) =>
      setState((prevState) => ({
        ...prevState,
        thumbnailSource
      }))
    );
  }, [state.thumbnail]);
  const courseTypeHandler = new CourseTypeHandler(course);
  const handleType = courseTypeHandler.getHandleMethod();
  return (
    <Container name="create-content">
      <HeaderStyled size="lg">Engage Content Details</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Title</LabelStyled>
          </LabelContainerStyled>
          <TextInput disabled={state.isSubmitted} onChange={handleChange} name="title" value={state.title} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Content Type</LabelStyled>
          </LabelContainerStyled>
          <Select
            disabled={state.isSubmitted}
            onChange={handleTypeChange}
            name="type"
            value={state.type}
            box
            options={courseTypeOptions}
          />
        </ColStyled>
      </Row>
      {state.type === LearnContentType.Url && (
        <>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>URL</LabelStyled>
              </LabelContainerStyled>
              <TextInput disabled={state.isSubmitted} onChange={handleChange} name="url" value={state.url} box />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Open URL in Iframe</LabelStyled>
              </LabelContainerStyled>
              <Checkbox
                checked={state.openIframe === undefined ? true : state.openIframe}
                size="md"
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    openIframe: e.target.checked
                  }));
                }}
              />
            </ColStyled>
          </Row>
        </>
      )}
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Categories</LabelStyled>
          </LabelContainerStyled>
          <MultiSelect
            multiValueColor="tertiary"
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                categories: e.target.select
              }));
            }}
            isDisabled={state.isSubmitted}
            value={state.categories}
            options={CATEGORIES}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Description</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            disabled={state.isSubmitted}
            onChange={handleChange}
            name="description"
            value={state.description}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>CPD Value (Minutes)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            disabled={state.isSubmitted}
            onChange={handleChange}
            name="cpd"
            value={parseInt(state.cpd)}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Pass Score</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            type="number"
            disabled={state.isSubmitted}
            onChange={handleChange}
            name="passScore"
            value={parseInt(state.passScore)}
            box
          />
        </ColStyled>
      </Row>
      {permissions.learn.includes('createCourseAll') && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Include into Engage subscription</LabelStyled>
            </LabelContainerStyled>
            <Checkbox
              checked={state.isEngage}
              size="md"
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  isEngage: e.target.checked
                }));
              }}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Assessment</LabelStyled>
          </LabelContainerStyled>
          <Select
            disabled={state.isSubmitted}
            onChange={(e) => {
              setState({
                ...state,
                assessmentId: e.target.select.value
              });
            }}
            name="assessmentId"
            value={state.assessmentId}
            options={[notSelectedOption].concat(
              filterEvaluationsForAccount(assessments).map((a) => ({
                label: a.name,
                value: a.id
              }))
            )}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Reaction</LabelStyled>
          </LabelContainerStyled>
          <Select
            disabled={state.isSubmitted}
            onChange={(e) => {
              setState({
                ...state,
                reactionId: e.target.select.value
              });
            }}
            name="reactionId"
            value={state.reactionId}
            options={[notSelectedOption].concat(
              filterEvaluationsForAccount(reactions).map((r) => ({
                label: r.name,
                value: r.id
              }))
            )}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Thumbnail</LabelStyled>
          </LabelContainerStyled>
          {!state.thumbnail && (
            <>
              <UploadFiles
                background
                disabled={state.isSubmitting}
                text={state.thumbnailFileError || 'Select a file'}
                buttonText="Select File"
                onClick={onThumbnailFileInputClick}
              />
              <HiddenContainer>
                <input
                  name="thumbnail"
                  type="file"
                  ref={thumbnailFilePicker}
                  onChange={onThumbnailChange}
                  accept="image/*"
                />
              </HiddenContainer>
            </>
          )}
          {state.thumbnail && (
            <Row container>
              <Col container item fullWidth>
                <Thumbnail src={state.thumbnailSource} alt="" />
                {state.thumbnail.name ? (
                  <FileName>{state.thumbnail.name}</FileName>
                ) : (
                  <ThumbnailUrl href={state.thumbnail}>Download Thumbnail</ThumbnailUrl>
                )}
                <RemoveFileButton color="danger" onClick={removeThumbnail}>
                  Remove thumbnail
                </RemoveFileButton>
              </Col>
            </Row>
          )}
        </ColStyled>
      </Row>
      {state.type !== LearnContentType.Url && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Content</LabelStyled>
            </LabelContainerStyled>
            {!state.content && (
              <>
                <UploadFiles
                  background
                  name="contentfile"
                  disabled={state.isSubmitting || !state.type}
                  text={state.fileError || 'Select a file'}
                  buttonText="Select File"
                  onClick={onContentFileInputClick}
                />
                <HiddenContainer>
                  {state.type === LearnContentType.Scorm && (
                    <input
                      name="content"
                      type="file"
                      ref={contentFilePicker}
                      onChange={onFolderChange}
                      multiple
                      mozdirectory="true"
                      directory=""
                      webkitdirectory=""
                    />
                  )}
                  {state.type === LearnContentType.Pdf && (
                    <input
                      name="content"
                      type="file"
                      accept="application/pdf"
                      ref={contentFilePicker}
                      onChange={onFileChange}
                    />
                  )}
                  {state.type === LearnContentType.Video && (
                    <input
                      name="content"
                      type="file"
                      accept="video/mp4,video/x-m4v,video/*"
                      ref={contentFilePicker}
                      onChange={onFileChange}
                    />
                  )}
                </HiddenContainer>
              </>
            )}
            {state.content && (
              <Row container>
                <Col container item fullWidth>
                  {state.content.name ? (
                    <FileName>{state.content.name}</FileName>
                  ) : (
                    <ViewCourseButton
                      course={course}
                      handleType={handleType}
                      downloadLink={course.metaData.link}
                      userId={userId}
                      courseRefetch={() => {}}
                      name="StartCourse-Button"
                      steps={[]}
                      component={({ onClick }) => <ThumbnailUrl onClick={onClick}>View</ThumbnailUrl>}
                    />
                  )}
                  <RemoveFileButton color="danger" onClick={removeContent}>
                    Remove content
                  </RemoveFileButton>
                </Col>
              </Row>
            )}
          </ColStyled>
        </Row>
      )}
      <form onSubmit={onSubmit}>
        <Row container align="between">
          <ColStyled item>
            <Button color="success" submit disabled={isFormInvalid(state)}>
              {edit ? 'Update Engage Content' : 'Upload Engage Content'}
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
}

export default UploadContent;
