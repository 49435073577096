import { useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { S3 } from '../../../constants/documentSources';
import { SAVE_POLICY, GET_POLICIES_BY_ACCOUNT_ID, CREATE_DOCUMENT_RECORD } from './index.queries';
import UPLOAD_DOCUMENT from '../../../graphql/mutations/UploadDocument.graphql';
import { isPoliciesDocument } from './utils';
import { createS3Key } from '../../DocumentContainer/utils';

export function useSavePolicyWithDocuments() {
  const {
    token: { sub: userId }
  } = useUserToken();
  const accountId = useCurrentAccountId();
  const [savePolicy] = useMutation(SAVE_POLICY);
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord] = useMutation(CREATE_DOCUMENT_RECORD);

  return async (policy) => {
    const documents = Array.isArray(policy.documents) ? policy.documents : [];
    const newDocumentIds = await Promise.all(
      documents
        .filter((d) => d instanceof window.File)
        .map(async (document) => {
          const id = uuid();
          await uploadDocument({
            variables: {
              accountId,
              id,
              userId,
              file: document,
              fileName: policy.title
            }
          });
          const variables = {
            id,
            userId,
            accountId,
            fileName: policy.title,
            category: 'Policies',
            key: createS3Key(accountId, id),
            library: 'GOVERNANCE',
            referenceId: policy.id,
            reviewDate: policy.reviewDate || null,
            source: S3
          };
          const {
            data: {
              createDocumentRecord: { id: documentId }
            }
          } = await createDocumentRecord({
            variables
          });
          return documentId;
        })
    );
    const oldDocumentIds = documents.filter(isPoliciesDocument).map((doc) => doc.id);
    const documentIds = oldDocumentIds.concat(newDocumentIds);
    await savePolicy({
      variables: {
        policy: {
          accountId,
          version: policy.version,
          id: policy.id,
          title: policy.title,
          reviewDate: policy.reviewDate,
          createdAt: new Date().toISOString(),
          documents: documentIds
        },
        accountId
      },
      update: (cache, result) => {
        const variables = {
          accountId
        };
        const oldResults = cache.readQuery({
          query: GET_POLICIES_BY_ACCOUNT_ID,
          variables
        });
        const oldPolicies = Array.isArray(oldResults.getPoliciesByAccountId) ? oldResults.getPoliciesByAccountId : [];
        const newPolicy = result.data.savePolicy;
        const isUpdate = !!oldPolicies.find((p) => p.id === newPolicy.id);
        const newResults = {
          ...oldResults,
          getPoliciesByAccountId: isUpdate
            ? oldResults.getPoliciesByAccountId.map((p) =>
                p.id === newPolicy.id ? { ...newPolicy, __typename: 'PolicyOverview' } : p
              )
            : [...oldPolicies, newPolicy]
        };
        cache.writeQuery({
          query: GET_POLICIES_BY_ACCOUNT_ID,
          variables,
          data: newResults
        });
      }
    });
  };
}
