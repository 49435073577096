import React from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Text, Heading, Notification } from 'rio-ui-components';
import { Mutation } from '@apollo/client/react/components';

import { DELETE_USER } from './index.queries';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const TextStyled = styled(Text)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const DeleteColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

class DeleteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isSuccess: false,
      notification: { message: null, color: null },
      isSubmitted: false
    };
  }

  hideNotification = () => this.setState({ notification: { message: null, color: null } });

  onDeleteError = () => {
    this.setState({
      notification: { message: 'Something went wrong. If the problem persists, contact support.', color: 'danger' },
      hasError: true,
      isSuccess: false,
      isSubmitted: false
    });
  };

  onDeleteClick = (callback) => {
    callback({ variables: { username: this.props.userId } });
    this.setState({ isSubmitted: true });
  };

  render() {
    return (
      <Container name="delete-user">
        {this.state.notification.message && (
          <Notification
            name="delete-user__notification"
            show
            color={this.state.notification.color}
            onClose={this.hideNotification}
          >
            {this.state.notification.message}
          </Notification>
        )}

        <Mutation
          mutation={DELETE_USER}
          onCompleted={() => this.props.onSuccess('The user has been deleted!', 'success', this.props.userId)}
          onError={this.onDeleteError}
        >
          {(deleteUser) => (
            <div>
              <HeaderStyled name="delete-user__heading" size="lg">
                Delete User
              </HeaderStyled>
              <TextStyled size="md">Are you sure you want to delete this user?</TextStyled>
              <Row container align="between">
                <DeleteColStyled item container>
                  <Button
                    disabled={this.state.isSubmitted}
                    color="info"
                    name="delete-user__button--cancel"
                    onClick={this.props.onDismiss}
                  >
                    Cancel
                  </Button>
                </DeleteColStyled>
                <DeleteColStyled item container>
                  <Button
                    disabled={this.state.isSubmitted}
                    color="danger"
                    name="delete-user__button--confirm"
                    onClick={() => this.onDeleteClick(deleteUser)}
                  >
                    Confirm
                  </Button>
                </DeleteColStyled>
              </Row>
            </div>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default DeleteUser;
