import React from 'react';
import styled from 'styled-components';
import { Row, Icon, Text } from 'rio-ui-components';

const RowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  margin-top: ${(props) => props.theme.geometry.md.spacing};
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const IconStyled = styled(Icon)`
  flex: 0 0 auto;
  color: ${(props) => props.theme.colors.text.light.background};
  font-size: ${(props) => props.theme.geometry.xl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

interface FileCardProps {
  fileName: string;
}

export function FileCard({ fileName }: FileCardProps) {
  return (
    <RowStyled container itemAlign="center">
      <IconStyled name="file-upload-icon" icon="file-upload" />
      <Text>{fileName}</Text>
    </RowStyled>
  );
}
