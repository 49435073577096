import { useLazyQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { IColumnHookInput, IColumnHookOutput } from 'dromo-uploader-react';
import { useCurrentAccountId } from '~/hooks';
import { GET_ALL_LOCATION_IDS } from './index.queries';

type Response = Pick<Query, 'getLocationsPage'>;

const INVALID_ID_MESSAGE =
  'One or more locations you’re trying to update weren’t recognised in your upload, please review';

export function useValidateLocationIds() {
  const accountId = useCurrentAccountId();
  const [queryLocationIds] = useLazyQuery<Response>(GET_ALL_LOCATION_IDS, {
    fetchPolicy: 'network-only',
  });
  return async (rows: IColumnHookInput[]): Promise<IColumnHookOutput[]> => {
    const response = await queryLocationIds({
      variables: {
        accountId,
      },
    });
    const set = new Set(response.data?.getLocationsPage.rows.map((r) => r.id));
    const validate = (row: IColumnHookInput): IColumnHookOutput =>
      set.has(row.value)
        ? row
        : {
            ...row,
            info: [
              {
                message: INVALID_ID_MESSAGE,
                level: 'error',
              },
            ],
          };
    return rows.map((row: IColumnHookInput) => (row.value ? validate(row) : row));
  };
}
