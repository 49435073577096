import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { MutationUpdateSurveyArgs, Survey, SurveyInput } from '@rio/rio-types';
import { useUpdate, useDelete } from '../queries';
import { SurveyForm } from './SurveyForm';
import { validate } from './validate';
import { Errors } from '../../../types';
import { useCurrentAccountId } from '../../../hooks';
import { useSurveyForm } from './hooks/useSurveyForm';
import { FormEvent } from 'react';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

interface UpdateSurveyProps {
  existingSurvey: Survey;
  onDismiss: () => void;
  onDelete?: () => void;
}

export function UpdateSurvey({ onDismiss, existingSurvey, onDelete }: UpdateSurveyProps) {
  const accountId = useCurrentAccountId();
  const [formErrors, setErrors] = useState<Errors<SurveyInput>>({});
  const { state: survey } = useSurveyForm();

  const [updateSurvey, { loading: updating }] = useUpdate();
  const [deleteSurvey, { loading: deleting }] = useDelete();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setErrors({});

      const [errors, validSurvey] = validate(survey);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors,
        }));
      }

      const variables: MutationUpdateSurveyArgs = {
        id: existingSurvey.id,
        survey: {
          ...validSurvey,
          ownerAccountId: existingSurvey.owner.id,
        },
      };

      await updateSurvey({
        variables,
        onCompleted: () => onDismiss(),
      });
    },
    [survey, setErrors, updateSurvey, onDismiss, existingSurvey]
  );

  const handleDelete = useCallback(() => {
    deleteSurvey({
      variables: { id: existingSurvey.id },
      onCompleted: () => {
        onDismiss();
        onDelete?.();
      },
    });
  }, [existingSurvey.id, deleteSurvey, onDismiss, onDelete]);

  const disabled = updating || deleting;
  return (
    <Container>
      <PropertySheet container onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>Update Survey</PropertySheet.HeaderRow>
        <SurveyForm errors={formErrors} disabled={!!(updating || deleting)} accountId={accountId} isUpdatingSurvey />
        <PropertySheet.Row container>
          <PropertySheet.ButtonColumn span={6} disabled={disabled} onClick={handleSubmit} name="UpdateSurveyButton">
            Update survey
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            name="DeleteSurveyButton"
            span={6}
            disabled={disabled}
            onClick={handleDelete}
            color="danger"
          >
            Delete survey
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
