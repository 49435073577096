import { v4 as uuid } from 'uuid';
import { MarketCarbonConversionFactor, MarketCarbonConversionFactorInput } from '@rio/rio-types';
import { today, nextYear } from '../../../utils';
import { Errors } from '../../../types';

export function mapFactorToInput(factor: MarketCarbonConversionFactor | null): MarketCarbonConversionFactorInput {
  if (factor) {
    return {
      id: factor.id,
      supplierId: factor.supplierId,
      tariff: factor.tariff,
      renewablePercentage: factor.renewablePercentage,
      generationFactor: factor.generationFactor,
      wellToTankForGenerationFactor: factor.wellToTankForGenerationFactor,
      wellToTankForTransmissionAndDistributionFactor: factor.wellToTankForTransmissionAndDistributionFactor,
      transmissionAndDistributionFactor: factor.transmissionAndDistributionFactor,
      startDate: factor.startDate,
      endDate: factor.endDate,
      isGlobal: factor.isGlobal,
      accountIds: factor.accountIds,
      reference: factor.reference,
      notes: factor.notes,
    };
  }
  return {
    id: uuid(),
    supplierId: '',
    tariff: '',
    renewablePercentage: 0,
    generationFactor: 0,
    wellToTankForGenerationFactor: 0,
    wellToTankForTransmissionAndDistributionFactor: 0,
    transmissionAndDistributionFactor: 0,
    startDate: today().toISOString(),
    endDate: nextYear().toISOString(),
    isGlobal: true,
    accountIds: [],
    reference: '',
    notes: '',
  };
}

export function validateInput(input: MarketCarbonConversionFactorInput): Errors<MarketCarbonConversionFactorInput> {
  const errors: Errors<MarketCarbonConversionFactorInput> = {};
  if (!input.supplierId) {
    errors.supplierId = 'Supplier should be selected';
  }
  if (!input.tariff) {
    errors.tariff = `Tariff shouldn't be empty`;
  }
  if (
    typeof input.renewablePercentage !== 'number' ||
    input.renewablePercentage < 0 ||
    input.renewablePercentage > 100
  ) {
    errors.renewablePercentage = 'Renewable (%) should be not greater than 100 or less 0';
  }
  return errors;
}
