import { gql } from '@apollo/client';

export const UPDATE_USER_COURSE_PROGRESS = gql`
  mutation updateUserCourseProgress($userId: ID!, $courseId: ID!, $courseProgress: CourseProgress!) {
    updateUserCourseProgress(input: { userId: $userId, courseId: $courseId, courseProgress: $courseProgress }) {
      lastUpdatedAt
      courseProgress
    }
  }
`;

export const COMPLETE_EVALUATION = gql`
  mutation completeEvaluation($answers: [AnswerInput!]!, $courseId: ID!, $evaluationId: ID!, $userId: ID!) {
    completeEvaluation(answers: $answers, courseId: $courseId, evaluationId: $evaluationId, userId: $userId)
  }
`;
