import React from 'react';
import { Select } from 'rio-ui-components';
import { DateRange } from '../../types';
import { formatDateRange, formatRanges } from '../../utils';

export interface DateRangeSelectProps {
  ranges: DateRange[];
  value: DateRange | null;
  onChange: (r: DateRange) => void;
  includeReportingYear?: boolean;
}

export function DateRangeSelect({ ranges, value, onChange, includeReportingYear }: DateRangeSelectProps) {
  const { rangesFormatted, mapOfDates } = formatRanges(ranges, {
    includeReportingYear,
  });
  return (
    <Select
      placeholder="Select date range"
      value={
        value
          ? formatDateRange(value, {
              includeReportingYear,
            })
          : null
      }
      options={rangesFormatted.map((r) => ({
        label: r,
        value: r,
      }))}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(mapOfDates[e.target.value])}
    />
  );
}
