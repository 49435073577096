import { gql } from '@apollo/client';

export const GET_QUESTIONS = gql`
  query GetQuestions($offset: Int, $limit: Int, $filters: Filters, $sort: [SortCommand!]) {
    getQuestions(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      totalRows
      rows {
        id
        name
        page
        title
        description
        type
        required
        payload
        order
        createdDate
        options {
          id
          name
          score
        }
        category {
          id
          name
        }
        template {
          id
          name
          description
        }
      }
    }
  }
`;
export const GET_QUESTIONS_FILTER = gql`
  query GetQuestionsFilter($field: String!) {
    getQuestionsFilter(field: $field) {
      label
      value
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateSurveyQuestion($question: SurveyQuestionInput!) {
    updateSurveyQuestion(question: $question) {
      id
      name
      options {
        id
        name
        score
      }
      category {
        id
        name
      }
    }
  }
`;

export const GET_QUESTION_CATEGORIES = gql`
  query {
    getSurveyQuestionCategories {
      id
      name
    }
  }
`;
