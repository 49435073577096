import styled from 'styled-components';
import { Col, Label, Row, TextInput, DatePicker, TextArea } from 'rio-ui-components';
import LocationMetaOptionSelect from '../../../components/LocationMetaOptionSelect';
import { TENURE, REGION, BUILDINGTYPE } from '../../../constants/locationMetaOptionTypes';
import { CreateDepartmentModal } from '../../../components/CreateDepartmentModal';
import { useCreateDepartment } from '../../../hooks/useCreateDepartment';
import { useAccountById } from '../../../hooks/useAccountById';
import { toDateObj, formatDateLocal } from '~/utils';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const LocationMetadata = ({ accountId, selectedOptions = {}, setSelectedOptions = () => {} }) => {
  const accountInfo = useAccountById(accountId);

  const { departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    accountInfo?.departments,
    selectedOptions.departments,
    accountId
  );

  return (
    <>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Location Code (Location ID / ePIM) (Optional)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={(e) => setSelectedOptions({ ...selectedOptions, code: e.target.value })}
            value={selectedOptions.code}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Departments (Optional)</LabelStyled>
          </LabelContainerStyled>
          {departmentMultipleSelect((e) => {
            setSelectedOptions({
              ...selectedOptions,
              departments: e.target.select.map((dep) => {
                return {
                  id: dep.value,
                  name: dep.label,
                };
              }),
            });
          })}
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Tenure (Optional)</LabelStyled>
          </LabelContainerStyled>
          <LocationMetaOptionSelect
            accountId={accountId}
            optionType={TENURE}
            selectedOption={selectedOptions.tenure}
            setSelectedOption={(id) => setSelectedOptions({ ...selectedOptions, tenure: id })}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Region (Optional)</LabelStyled>
          </LabelContainerStyled>
          <LocationMetaOptionSelect
            accountId={accountId}
            optionType={REGION}
            selectedOption={selectedOptions.region}
            setSelectedOption={(id) => setSelectedOptions({ ...selectedOptions, region: id })}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Building Type (Optional)</LabelStyled>
          </LabelContainerStyled>
          <LocationMetaOptionSelect
            accountId={accountId}
            optionType={BUILDINGTYPE}
            selectedOption={selectedOptions.buildingType}
            setSelectedOption={(id) => setSelectedOptions({ ...selectedOptions, buildingType: id })}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Floor Space (Square Meters) (Optional)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={(e) => setSelectedOptions({ ...selectedOptions, floorSpace: e.target.value })}
            type="number"
            min="0"
            value={selectedOptions.floorSpace}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Occupation Start Date (Optional)</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            onDayClick={(date) => setSelectedOptions({ ...selectedOptions, startDate: date || null })}
            name="startDate"
            selectedDate={selectedOptions.startDate ? new Date(selectedOptions.startDate) : selectedOptions.startDate}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Occupation End Date (Optional)</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            onDayClick={(date) => setSelectedOptions({ ...selectedOptions, endDate: date || null })}
            name="endDate"
            selectedDate={selectedOptions.endDate ? new Date(selectedOptions.endDate) : selectedOptions.endDate}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Lease Renewal Date (Optional)</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            onDayClick={(date) =>
              setSelectedOptions({
                ...selectedOptions,
                leaseRenewalDate: date || null,
              })
            }
            name="leaseDate"
            selectedDate={
              selectedOptions.leaseRenewalDate
                ? new Date(selectedOptions.leaseRenewalDate)
                : selectedOptions.leaseRenewalDate
            }
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Notes (Optional)</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            onChange={(e) => setSelectedOptions({ ...selectedOptions, notes: e.target.value })}
            value={selectedOptions.notes}
            box
          />
        </ColStyled>
      </Row>
      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        passedAccountId={accountId}
        onComplete={() => {}}
      />
    </>
  );
};

export default LocationMetadata;
