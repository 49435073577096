import { useLazyQuery } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { GET_INVESTMENT_TRANSACTIONS_BY_ACCOUND_ID } from './index.queries';
import { useGridConfig } from './useGridConfig';
import { useCallback } from 'react';

export function InvestmentTransactionsGrid() {
  const [query] = useLazyQuery(GET_INVESTMENT_TRANSACTIONS_BY_ACCOUND_ID, {
    fetchPolicy: 'network-only',
  });
  const config = useGridConfig();

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getInvestmentTransactionsByAccountId: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );
  return (
    <TransactionGrid
      title="Investment Transactions"
      defaultExportFileName="investment-transactions"
      breadcrumbs={[{ title: 'Data', to: '..' }, { title: 'Investment Transactions' }]}
      uploadLink="../investment/uploads"
      config={config}
      fetchRows={fetchRows}
    />
  );
}
