import { InOut } from '@rio/rio-types';
import { v4 as uuid } from 'uuid';

const ELECTRICITY_TYPE = 'Electricity';
const HEAT_AND_STEAM_TYPE = 'Heat & Steam';

const requiredColumns = ['Meter Name', 'Meter Type', 'Meter Location'];

const meterStartDateColumn = 'Meter Start Date (Optional dd/mm/yyyy)';
const meterEndDateColumn = 'Meter End Date (Optional dd/mm/yyyy)';

const templateColumns = [
  ...requiredColumns,
  'Generation',
  'Water In / Water Out',
  'Meter Code (Optional)',
  'Hardware Provider (Optional)',
  'Operator Provider (Optional)',
  meterStartDateColumn,
  meterEndDateColumn,
];

const fieldRequiredMsg = (field) => `${field} is a required field`;

const blankFieldRequired = (rowNumber, field) => `Row ${rowNumber} - '${field}' should be blank`;

const valueNotProvidedMsg = (rowNumber) => (column) => `Row ${rowNumber}: value not provided for ${column}`;

const invalidFormatMsg = (rowNumber) => (column, value, format) =>
  `Row ${rowNumber}: invalid value ${value} for ${column}, format should be ${format}`;

const createInvalidMessageTemplate =
  (rowNumber) =>
  (column, value, allowedValues = []) =>
    `Row ${rowNumber}: invalid ${column} - ${value}.${
      allowedValues.length ? ` Should be one of ${allowedValues.join(', ')}` : ''
    }`;

const getGenerationValue = (value) => (value === undefined ? 'blank' : value.toString().toLowerCase());

const allowedInOutValues = [InOut.In, InOut.Out, InOut.Both];

const allowedGenerationValues = ['true', 'false', 'blank'];

const validateRows = (rows, types, locations, suppliers) => {
  const errors = [];
  const uniqueNames = [];

  rows.forEach((row, rowIndex) => {
    const rowNumber = rowIndex + 2;
    const meterName = row['Meter Name'].toString();
    const valueNotProvidedErr = valueNotProvidedMsg(rowNumber);
    const invalidMessageTemplate = createInvalidMessageTemplate(rowNumber);
    const invalidFormatTemplate = invalidFormatMsg(rowNumber);
    const generationValue = getGenerationValue(row.Generation);

    requiredColumns.forEach((field) => {
      if (!row[field]) {
        errors.push(fieldRequiredMsg(field));
      }
    });

    if (uniqueNames.includes(meterName.toLowerCase())) {
      errors.push(`Row ${rowNumber}: name duplicate - ${row['Meter Name']}`);
    } else {
      uniqueNames.push(meterName.toLowerCase());
    }

    if (!row['Meter Name']) {
      errors.push(valueNotProvidedErr('Meter Name'));
    }

    if (!row['Meter Type']) {
      errors.push(valueNotProvidedErr('Meter Type'));
    }

    if (!row['Meter Location']) {
      errors.push(valueNotProvidedErr('Meter Location'));
    }

    if (row['Meter Type'] === 'Water' && !row['Water In / Water Out']) {
      errors.push(valueNotProvidedErr('Water In / Water Out'));
    } else if (
      row['Meter Type'] === 'Water' &&
      !allowedInOutValues.includes(String(row['Water In / Water Out']).toUpperCase())
    ) {
      errors.push(invalidMessageTemplate('Water In / Water Out', row['Water In / Water Out'], allowedInOutValues));
    }

    if (
      row['Meter Type'] !== ELECTRICITY_TYPE &&
      row['Meter Type'] !== HEAT_AND_STEAM_TYPE &&
      row.Generation !== undefined
    ) {
      errors.push(blankFieldRequired(rowNumber, 'Generation'));
    }

    if (
      (row['Meter Type'] === ELECTRICITY_TYPE || row['Meter Type'] === HEAT_AND_STEAM_TYPE) &&
      !allowedGenerationValues.includes(generationValue)
    ) {
      errors.push(invalidMessageTemplate('Generation', row.Generation, allowedGenerationValues));
    }
    if (!types[row['Meter Type']]) {
      errors.push(
        invalidMessageTemplate(
          'Meter Type',
          row['Meter Type'],
          Object.values(types).map((t) => t.name)
        )
      );
    }

    if (!locations[row['Meter Location']]) {
      errors.push(invalidMessageTemplate('Meter Location', row['Meter Location']));
    }

    if (row['Hardware Provider (Optional)'] && !suppliers[row['Hardware Provider (Optional)']]) {
      errors.push(invalidMessageTemplate('Hardware Provider (Optional)', row['Hardware Provider (Optional)']));
    }

    if (row['Operator Provider (Optional)'] && !suppliers[row['Operator Provider (Optional)']]) {
      errors.push(invalidMessageTemplate('Operator Provider (Optional)', row['Operator Provider (Optional)']));
    }

    function validateDateColumn(columnName) {
      const cellValue = row[columnName].toString();
      if (new Date(cellValue).toString() === 'Invalid Date') {
        errors.push(invalidFormatTemplate(columnName, cellValue, 'dd/mm/yyyy'));
      }
    }

    if (row[meterStartDateColumn]) {
      validateDateColumn(meterStartDateColumn);
    }

    if (row[meterEndDateColumn]) {
      validateDateColumn(meterEndDateColumn);
    }
  });
  return errors;
};

const isGenerationMeter = (meter) => {
  if (
    (meter['Meter Type'] === ELECTRICITY_TYPE || meter['Meter Type'] === HEAT_AND_STEAM_TYPE) &&
    allowedGenerationValues.includes(getGenerationValue(meter.Generation))
  ) {
    return getGenerationValue(meter.Generation) === 'true' ? true : false;
  }

  return null;
};

const convertMeters = (rows, accountId, types, locations, suppliers) =>
  rows.map((meter) => {
    return {
      id: uuid(),
      name: meter['Meter Name'],
      locationId: locations[meter['Meter Location']].id,
      typeId: types[meter['Meter Type']].id,
      accountId,
      isGeneration: isGenerationMeter(meter),
      inOut: meter['Water In / Water Out'] ? meter['Water In / Water Out'].toUpperCase() : null,
      meterCode: meter['Meter Code (Optional)'] || null,
      hardwareProviderName: meter['Hardware Provider (Optional)']
        ? suppliers[meter['Hardware Provider (Optional)']].id
        : null,
      operatorProviderName: meter['Operator Provider (Optional)']
        ? suppliers[meter['Operator Provider (Optional)']].id
        : null,
      startDate: meter[meterStartDateColumn] ? new Date(meter[meterStartDateColumn]) : null,
      endDate: meter[meterEndDateColumn] ? new Date(meter[meterEndDateColumn]) : null,
    };
  });

const validateMeters = async (rows, types, locations, suppliers, accountId) => {
  const validationErrors = validateRows(rows, types, locations, suppliers);
  if (validationErrors.length) {
    return [validationErrors, null];
  }

  return [null, convertMeters(rows, accountId, types, locations, suppliers)];
};

export { validateMeters, templateColumns };
