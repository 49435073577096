import { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, Text, Icons } from '@rio/ui-components';
import { ReceivedSurvey, SurveySubmission, Survey } from '@rio/rio-types';
import { generateSpreadsheet } from '~/utils';
import { useLazyGetMyContributors } from '~/containers/SurveysContainer/MyContributorsContainer/queries';
import { useCurrentAccountId, useRoutes } from '~/hooks';
import { isSurvey, parseSurveyAnswer } from '../utils';

const StyledOverflowMenu = styled('ul')`
  width: 180px;
  top: 48px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.sys.elevation[1]};
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};

  li:hover {
    background-color: ${({ theme }) => theme.sys.color.inverseOnSurface};
  }
`;

export const isOverflowMenu = (e: React.MouseEvent) => {
  const node = (e.target as HTMLElement).firstChild as HTMLSpanElement;
  const isSpan = node?.tagName?.toLowerCase() === 'span';
  const isEmpty = !node?.textContent;
  return isSpan && isEmpty;
};

const MoreIcon = styled(Icons.MoreVert)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

const LinkText = styled(Text)`
  cursor: pointer;
  padding: 8px;
  width: 100%;
  text-align: center;
`.withComponent('li');

export interface SurveyOverflowMenuProps {
  survey: ({ submissions?: SurveySubmission[] } & ReceivedSurvey) | Survey;
}

export const SurveyOverflowMenu = ({ survey }: SurveyOverflowMenuProps) => {
  const routes = useRoutes();
  const navigate = useNavigate();
  const accountId = useCurrentAccountId();
  const [getContributors] = useLazyGetMyContributors(accountId);
  const [showMenu, setShowMenu] = useState(false);

  const type = useMemo(() => (survey.__typename === 'Survey' ? 'survey' : 'response'), [survey]);

  const handlerShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const auditLogLink = useMemo(() => {
    const { pathname } = window.location;
    const contributorPage = pathname.includes(routes.surveys.sent.contributor.replace('/:contributorId', ''));
    const sentSurveysPage = pathname.includes(routes.surveys.sent.surveys);
    const overviewSurveyPage = pathname.includes(routes.surveys.sent.overview);
    const surveyPage = pathname.includes(routes.surveys.sent.survey.replace(':surveyId', survey.id));
    const receivedSurveysPage = pathname.includes(routes.surveys.received.surveys);
    const receivedSurveyPage = pathname.includes(routes.surveys.received.survey.replace(':surveyId', survey.id));
    const reviewSubmissionPage = pathname.includes('submission');

    if (surveyPage) {
      return `./survey-log`;
    }
    if ((contributorPage || sentSurveysPage) && type === 'survey') {
      return `./${survey.id}/survey-log`;
    }
    if (overviewSurveyPage || type === 'survey') {
      return `../sent/surveys/${survey.id}/survey-log`;
    }
    if (reviewSubmissionPage) {
      return `./sent/contributors/${(survey as ReceivedSurvey)?.submission?.owner?.id}/response-log/${survey.id}`;
    }
    if (receivedSurveysPage && !receivedSurveyPage) {
      return `./${survey.id}/response-log`;
    }
    if (receivedSurveyPage) {
      return `./received/${survey.id}/response-log`;
    }

    return `./response-log/${survey.id}`;
  }, [type, survey, routes.surveys]);

  const navigateToAuditLog = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(auditLogLink);
    },
    [navigate, auditLogLink]
  );

  const handlerExportToXLSX = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      const { data } = await getContributors();
      const contributors = data?.getMySurveyContributors || [];
      const headers = ['contributor', ...(survey?.template?.questions?.map((q) => `${q.title} - (${q.name})`) || [])];

      const results = survey.submissions?.map((s) => {
        return s.answers.reduce(
          (acc, cur) => {
            return { ...acc, [`${cur.question.title} - (${cur.question.name})`]: JSON.parse(cur.answer) };
          },
          { contributor: contributors.find((c) => c.account.id === s.owner.id)?.account.name }
        );
      });

      generateSpreadsheet(headers, `${survey.name} Results`, results, parseSurveyAnswer);
    },
    [getContributors, survey]
  );

  const overflowMenuLinks = useMemo(() => {
    const menuItems = [
      {
        text: `View ${type} log`,
        onClick: navigateToAuditLog,
      },
    ];

    if (isSurvey(survey)) {
      menuItems.push({
        text: `Export to XLSX`,
        onClick: handlerExportToXLSX,
      });
    }

    return menuItems;
  }, [type, navigateToAuditLog, survey, handlerExportToXLSX]);

  const renderOverflowMenu = useMemo(
    () =>
      overflowMenuLinks.map((el) => {
        return (
          <LinkText typescale="body" size="medium" key={el.text} onClick={el.onClick}>
            {el.text}
          </LinkText>
        );
      }),
    [overflowMenuLinks]
  );

  return (
    <>
      <MoreIcon fontSize="small" onClick={handlerShowMenu} />
      {showMenu && <StyledOverflowMenu>{renderOverflowMenu}</StyledOverflowMenu>}
    </>
  );
};
