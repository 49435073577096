import { useQuery } from '@apollo/client';
import React from 'react';
import { LoadingIndicator } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SubscriptionSelector from './SubscriptionSelector';
import { GET_ACCOUNT_BY_ID } from './index.queries';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const IntelligenceSelectionContainer = () => {
  const { accountId } = useParams();
  const { loading, error, data } = useQuery(GET_ACCOUNT_BY_ID, { variables: { id: accountId } });

  return (
    <>
      {(loading || error) && (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      )}
      {!loading && !error && (
        <SubscriptionSelector
          accountId={accountId}
          title={`Intelligence Subscriptions - ${data.getAccountInfo.name}`}
        />
      )}
    </>
  );
};

export default IntelligenceSelectionContainer;
