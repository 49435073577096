import { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Col } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import { Model } from 'survey-core';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { SubmissionOverview } from '../../../components/Surveys';
import { ExpandButton } from '../../../components/Surveys';
import { transformSubmissionToAnswers } from '../ReceivedSurveyContainer/utils';
import ContainerHeader from '../../../components/ContainerHeader';
import { PageFailed, NotFound } from '../../../components/Errors';
import { useGetMyContributorSubmission, useSendFeedback } from './queries';
import { useUploadedDocuments } from '../ReceivedSurveyContainer/hooks';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { useNotification } from '../../../hooks';

const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

export function MyContributorSubmissionContainer() {
  const { submissionId: id } = useParams();
  const { data, loading, error, refetch } = useGetMyContributorSubmission(id!);
  const { showNotification } = useNotification();
  const [sendFeedbackOnSurveySubmission] = useSendFeedback();
  const submission = data?.getSurveySubmissionById;
  const retry = useCallback(() => refetch({ id }), [id, refetch]);
  const { uploadedDocuments } = useUploadedDocuments(submission);
  const [expanded, setExpanded] = useState(false);

  const expand = useCallback(() => setExpanded(true), []);
  const collapse = useCallback(() => setExpanded(false), []);

  const sendFeedback = useCallback(
    (status: SurveySubmissionFeedbackStatus, feedback: string | null) => {
      const approved = status === SurveySubmissionFeedbackStatus.Approved;
      const client = submission?.owner.name || 'recipient';
      return sendFeedbackOnSurveySubmission({
        variables: {
          status,
          feedback,
          submissionId: id!,
        },
        onCompleted: () => {
          if (approved) {
            showNotification(`The response from ${client} was approved`, 'success');
          } else {
            showNotification(`Change requests sent to ${client}`, 'warning');
          }
        },
        onError: (err) => {
          showNotification(
            `We couldn't send feedback. Please try again later or contact support@rio.ai: ${err}`,
            'danger'
          );
        },
      });
    },
    [id, sendFeedbackOnSurveySubmission, showNotification, submission?.owner]
  );

  const formModel = useMemo(() => {
    if (!submission) {
      return null;
    }
    const model = new Model(submission.survey.template.formContent);
    model.showPreviewBeforeComplete = 'showAnsweredQuestions';
    if (submission.answers) {
      model.data = transformSubmissionToAnswers(submission.answers, uploadedDocuments);
      model.mode = 'display';
    }
    return model;
  }, [submission, uploadedDocuments]);

  if (loading) {
    return <ContainerLoadingIndicator />;
  }

  if (error) {
    return <PageFailed error={error} retry={retry} />;
  }

  if (!submission) {
    return <NotFound error="Not found" />;
  }

  const owner = submission?.owner;
  const survey = submission?.survey;

  return (
    <Page>
      <ContainerHeader
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title={submission.survey.name}
        breadcrumb={[
          { to: '../sent/overview', title: 'Surveys sent' },
          { to: '../sent/contributors', title: 'Contributors' },
          { to: `../sent/contributors/${owner.id}`, title: owner.name },
          {
            title: survey.name,
          },
        ]}
      >
        <ExpandButton onClick={expand} />
      </ContainerHeader>
      <SubmissionOverview
        model={formModel}
        expanded={expanded}
        onDismiss={collapse}
        survey={{ ...survey, submission }}
        contributor={owner.name}
        sendFeedback={sendFeedback}
      />
    </Page>
  );
}
