import validator from 'validator';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';

const Link = styled.a`
  text-decoration: underline;
`;

interface LinkCellProps {
  value: string;
  to?: string;
  icon?: string;
}

export function LinkCell({ value, to, icon }: LinkCellProps) {
  const href = to || value;
  const element = icon ? <Icon icon={icon} /> : value;
  // @ts-ignore
  if (validator.isURL(href || '') || href?.startsWith('/')) {
    return (
      <Link href={href} target="_blank" rel="noopener noreferrer">
        {element}
      </Link>
    );
  }
  return element;
}
