import {
  GET_PAGE,
  GET_SEARCH,
  GET_TOTAL_PAGES
} from '../../containers/ConfigurationContainer/ConfigurationWasteStreamContainer/index.queries';

const wasteStreamGridPage = 1;
const wasteStreamGridSearch = '';
const wasteStreamGridTotalPages = 1;

export const wasteStreamGridDefaults = [
  { query: GET_PAGE, data: { wasteStreamGridPage } },
  { query: GET_SEARCH, data: { wasteStreamGridSearch } },
  { query: GET_TOTAL_PAGES, data: { wasteStreamGridTotalPages } }
];

export const wasteStreamGridResolvers = {
  Mutation: {
    setWasteStreamGridPage: async (_, { page }, { cache }) => {
      cache.writeQuery({
        query: GET_PAGE,
        data: {
          wasteStreamGridPage: page
        }
      });
      return page;
    },
    setWasteStreamGridSearch: async (_, { search }, { cache }) => {
      cache.writeQuery({
        query: GET_SEARCH,
        data: {
          wasteStreamGridSearch: search
        }
      });
      return search;
    },
    setWasteStreamGridTotalPages: async (_, { totalPages }, { cache }) => {
      cache.writeQuery({
        query: GET_TOTAL_PAGES,
        data: {
          wasteStreamGridTotalPages: totalPages
        }
      });
      return totalPages;
    }
  }
};
