import { Heading } from 'rio-ui-components';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

export const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.geometry.md.spacing};
`;
