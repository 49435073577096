import { styled } from '@rio/ui-components';
import { Survey as SurveyInterface } from '@rio/rio-types';
import { SurveyInfoStatus } from '~/components/Surveys/v2';

interface SurveyProps {
  survey: SurveyInterface;
  latest: boolean;
}

const Header = styled('div')`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled('div')`
  position: relative;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: auto;
  justify-content: flex-end;
  font-size: 1em;
`;

export function Survey({ survey }: SurveyProps) {
  return (
    <Header>
      <HeaderRight>
        <SurveyInfoStatus deadlineDate={survey.deadlineDate} survey={survey} />
      </HeaderRight>
    </Header>
  );
}
