export const FundsAssetsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <rect width="64" height="64" />
    <path
      d="M44.5259 28.177C44.2455 28.177 43.9675 28.1851 43.692 28.1979V10.432C43.692 4.23979 33.2049 1 22.846 1C12.487 1 2 4.23979 2 10.432V42.541H2.01772C2.40285 48.5076 12.6812 51.6278 22.846 51.6278C24.5903 51.6278 26.3339 51.5275 28.0347 51.3436C30.4261 58.1243 36.912 63 44.5251 63C54.1605 63 62 55.1895 62 45.5881C62.0008 35.9883 54.1613 28.177 44.5259 28.177ZM22.846 5.81634C33.246 5.81634 38.8578 9.09226 38.8578 10.432C38.8578 11.7718 33.246 15.0477 22.846 15.0477C12.4451 15.0477 6.83423 11.7718 6.83423 10.432C6.83423 9.09226 12.4451 5.81634 22.846 5.81634ZM6.83423 16.6828C10.8571 18.7836 16.8709 19.864 22.846 19.864C28.8211 19.864 34.8333 18.7836 38.8578 16.6828V21.1941C38.8578 22.5339 33.246 25.8098 22.846 25.8098C12.4451 25.8098 6.83423 22.5339 6.83423 21.1941V16.6828ZM27.0816 46.6092C25.7699 46.7336 24.368 46.8115 22.846 46.8115C12.4451 46.8115 6.83423 43.5355 6.83423 42.1958V37.6997C10.8571 39.7997 16.8709 40.8801 22.846 40.8801C24.5033 40.8801 26.1574 40.7878 27.7769 40.6225C27.3056 42.1974 27.0493 43.8631 27.0493 45.5889C27.0493 45.9309 27.0614 46.2712 27.0816 46.6092ZM22.846 36.0638C12.4451 36.0638 6.83423 32.7879 6.83423 31.4489V27.4449C10.8571 29.5457 16.8709 30.6261 22.846 30.6261C28.8211 30.6261 34.8333 29.5457 38.8578 27.4449V29.1226C35.4134 30.3034 32.4524 32.5254 30.3576 35.4136C28.2297 35.8069 25.7296 36.0638 22.846 36.0638ZM44.5259 58.1845C37.5549 58.1845 31.8836 52.5341 31.8836 45.5889C31.8836 38.6446 37.5549 32.9942 44.5259 32.9942C51.4952 32.9942 57.1666 38.6446 57.1666 45.5889C57.1666 52.5341 51.4952 58.1845 44.5259 58.1845Z"
      fill="#0B4EE6"
    />
    <path
      d="M44.3504 43.9324C42.3302 43.6922 41.3045 43.4039 41.3045 42.4934C41.3045 41.1353 43.1311 40.9327 44.2192 40.9327C45.5098 40.9327 46.9379 41.5573 47.4028 42.3253L47.5868 42.632L50.536 41.2667L50.3559 40.8991C49.3182 38.7779 47.4788 38.1373 46.0586 37.8762V36H42.611V37.8658C39.5731 38.339 37.7729 40.0511 37.7729 42.4934C37.7729 46.5125 41.5373 46.9329 44.0264 47.2108C46.2891 47.4798 47.3436 48.0235 47.3436 48.922C47.3436 50.6605 44.8889 50.7958 44.1352 50.7958C42.4478 50.7958 40.822 49.959 40.3548 48.8491L40.1988 48.4792L37 49.8357L37.1576 50.2048C38.0577 52.3157 39.9859 53.6449 42.6094 53.9765V56H46.057V53.8748C48.5741 53.5641 51 51.9537 51 48.9212C51.0008 44.7516 46.9987 44.2583 44.3504 43.9324Z"
      fill="#0B4EE6"
    />
  </svg>
);
