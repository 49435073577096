import * as React from 'react';
import styled from 'styled-components';
import { useAccessControls } from '../../../hooks';
import DataOverviewNavigator from './DataOverviewNavigator';
import StatusContainer from './StatusContainer';
import UploadContainer from './UploadContainer';
// hide component as requested in RIO-6251
// import UtilitiesContainer from './UtilitiesContainer';
import { accessControls } from './types';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing} ${(props) => props.theme.geometry.md.spacing};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  min-width: 100px;
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

export default function DataOverviewContainer() {
  const { data: accountAccessControls } = useAccessControls();
  const activeAccessControls = Object.entries(accountAccessControls.data).reduce((accum: string[], [key, value]) => {
    if ((accessControls as unknown as string[]).includes(key) && value) {
      accum.push(key);
    }
    return accum;
  }, []);
  const inactiveAccessControls = Object.entries(accountAccessControls.data).reduce((accum: string[], [key, value]) => {
    if ((accessControls as unknown as string[]).includes(key) && !value) {
      accum.push(key);
    }
    return accum;
  }, []);

  return (
    <Container>
      <DataOverviewNavigator
        activeAccessControls={activeAccessControls}
        inactiveAccessControls={inactiveAccessControls}
      />
      <InfoContainer>
        <StatusContainer />
        <UploadContainer activeAccessControls={activeAccessControls} />
        {/* hide component as requested in RIO-6251 */}
        {/* <UtilitiesContainer /> */}
      </InfoContainer>
    </Container>
  );
}
