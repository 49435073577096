import { useMutation } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_MARKET_CARBON_CONVERSION_FACTORS, CREATE_MARKET_CARBON_CONVERSION_FACTOR } from './index.queries';
import { useGetMarketCarbonConversionFactorsVariables } from './useGetMarketCarbonConversionFactors';

export function useCreateMarketCarbonConversionFactor({ onComplete }: { onComplete: Function }) {
  const readVariables = useGetMarketCarbonConversionFactorsVariables();
  return useMutation(CREATE_MARKET_CARBON_CONVERSION_FACTOR, {
    update: (dataProxy, fetchResult) => {
      const newFactor = fetchResult.data?.createMarketCarbonConversionFactor;
      if (newFactor) {
        const result = dataProxy.readQuery<Query>({
          query: GET_MARKET_CARBON_CONVERSION_FACTORS,
          variables: readVariables,
        });
        const factors = result?.getMarketCarbonConversionFactors || [];
        const updatedFactors = factors.concat([newFactor]);
        dataProxy.writeQuery({
          query: GET_MARKET_CARBON_CONVERSION_FACTORS,
          variables: readVariables,
          data: {
            getMarketCarbonConversionFactors: updatedFactors,
          },
        });
        onComplete(updatedFactors);
      }
    },
  });
}
