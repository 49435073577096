import { Query, QueryGetSurveysPageArgs as Variables } from '@rio/rio-types';
import { GET_SURVEYS_PAGE } from './queries';
import { usePageSuspendingQuery } from '~/hooks';

export type GetSurveysResponse = Pick<Query, 'getSurveysPage'>;

export function useGet({ accountId, offset = 0, limit, sort = [], filters = {} }: Variables) {
  return usePageSuspendingQuery<GetSurveysResponse>(GET_SURVEYS_PAGE, {
    variables: {
      accountId,
      offset,
      limit,
      sort,
      filters,
    },
    fetchPolicy: 'network-only',
    keepErrorAfterUnmount: true,
  });
}
