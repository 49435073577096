"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material-next/styles");
const ErrorContext_1 = require("./ErrorContext");
const LoadingContext_1 = require("./LoadingContext");
const LinearProgress_1 = require("../LinearProgress");
const PageTitle_1 = require("../PageTitle");
const Grid_1 = require("../Grid");
const Errors_1 = require("../Errors");
const Main = (0, styles_1.styled)('main') `
  display: flex;
  flex-flow: column nowrap;
  padding-left: 32px;
  padding-right: 32px;
  flex: 1;
`;
const MainContent = (0, styles_1.styled)(Grid_1.Grid) `
  flex: 1;
`;
const PageContent = (0, styles_1.styled)(Grid_1.Grid) `
  flex: 1;
  position: relative;
  padding-top: 32px;
`;
const LinearProgressStyled = (0, styles_1.styled)(LinearProgress_1.LinearProgress) `
  position: absolute;
  top: 12px;
  left: 0;
  height: 1px;
  width: 100%;
  transform: translateY(-150%);
`;
function Page({ loading: loadingProp, error, title, children }) {
    const { ...titleRest } = title;
    const errorContext = (0, ErrorContext_1.usePageErrorContext)();
    const loadingContext = (0, LoadingContext_1.usePageLoadingContext)();
    return ((0, jsx_runtime_1.jsx)(Main, { children: (0, jsx_runtime_1.jsxs)(MainContent, { direction: "column", wrap: "nowrap", container: true, children: [title && ((0, jsx_runtime_1.jsx)(Grid_1.Grid, { xs: 12, container: true, children: (0, jsx_runtime_1.jsx)(PageTitle_1.PageTitle, { ...titleRest }) })), (0, jsx_runtime_1.jsxs)(PageContent, { direction: "column", xs: 12, children: [(loadingProp || loadingContext.loading) && (0, jsx_runtime_1.jsx)(LinearProgressStyled, {}), error || errorContext.error ? ((0, jsx_runtime_1.jsx)(Errors_1.LoadFailed, { error: error || errorContext.error, title: "Something went wrong" })) : (children)] })] }) }));
}
exports.Page = Page;
