import { LearnContentType } from '@rio/rio-types';

const CourseHandleType = {
  Embed: 'EMBED',
  Download: 'DOWNLOAD',
  Iframe: 'IFRAME',
  External: 'EXTERNAL',
  PDF: 'PDF'
};

class CourseTypeHandler {
  course;

  constructor(course) {
    this.course = course;
  }

  getHandleMethod() {
    switch (this.course.type) {
      case LearnContentType.Video:
        return CourseHandleType.Embed;
      case LearnContentType.Scorm:
        return CourseHandleType.Embed;
      case LearnContentType.Pdf:
        return CourseHandleType.PDF;
      case LearnContentType.Url:
        return this.course.metaData.openIframe ? CourseHandleType.Iframe : CourseHandleType.External;
      default:
        return CourseHandleType.External;
    }
  }
}

export { CourseTypeHandler, CourseHandleType };
