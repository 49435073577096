import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { SurveyTemplate, SurveyTemplateInput } from '@rio/rio-types';
import { useUpdate, useDelete } from './queries';
import { TemplateDetails } from './TemplateDetails';
import { validate } from './validate';
import { Errors } from '../../../types';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

interface UpdateTemplateProps {
  existingTemplate: SurveyTemplate;
  onDismiss: () => void;
}

export function UpdateTemplate({ onDismiss, existingTemplate }: UpdateTemplateProps) {
  const [template, setTemplate] = useState<Partial<SurveyTemplateInput>>({
    name: existingTemplate.name,
    description: existingTemplate.description,
    formContent: existingTemplate.formContent,
    expiryDate: existingTemplate.expiryDate ? new Date(existingTemplate.expiryDate) : null,
    ownerId: existingTemplate.owner.id,
    version: existingTemplate.version
  });
  const [formErrors, setErrors] = useState<Errors<SurveyTemplateInput>>({});

  const [updateFormTemplate, { loading: updating }] = useUpdate();
  const [deleteFormTemplate, { loading: deleting }] = useDelete();

  const handleChange = useCallback((payload: Partial<SurveyTemplateInput>) => {
    setTemplate((t) => ({
      ...t,
      ...payload
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors, validTemplate] = validate(template);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }

      await updateFormTemplate({
        variables: {
          id: existingTemplate.id,
          template: validTemplate
        },
        onCompleted: () => onDismiss()
      });
    },
    [template, updateFormTemplate, onDismiss, existingTemplate.id]
  );

  const handleDelete = useCallback(() => {
    deleteFormTemplate({
      variables: { id: existingTemplate.id },
      onCompleted: () => onDismiss()
    });
  }, [existingTemplate.id, deleteFormTemplate, onDismiss]);

  const disabled = updating || deleting;

  return (
    <Container>
      <PropertySheet container onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>Update Form Template</PropertySheet.HeaderRow>
        <TemplateDetails
          onChange={handleChange}
          template={template}
          owner={existingTemplate.owner}
          errors={formErrors}
          disabled={disabled}
        />
        <PropertySheet.Row container>
          <PropertySheet.ButtonColumn
            span={6}
            disabled={disabled}
            onClick={handleSubmit}
            name="UpdateTemplate__UpdateTemplateButton"
          >
            Update template
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            span={6}
            disabled={disabled}
            onClick={handleDelete}
            color="danger"
            name="UpdateTemplate__DeleteTemplateButton"
          >
            Delete template
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
