import React from 'react';
import styled, { useTheme } from 'styled-components';
import { components } from 'react-select';
import { Icon } from 'rio-ui-components';
import { useNotification } from '../../hooks';

const OptionContainerStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const IconStyled = styled(Icon)`
  position: absolute;
  right: ${(props) => props.theme.geometry.xs.spacing};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.colors.danger.light.text};
  }
`;

const Option = ({ ...props }) => {
  const theme = useTheme();
  const { children, onDelete, data, innerProps, canDelete, clearValue, isSelected } = props;
  const { showNotification } = useNotification();
  const handleClick = () => {
    if (!canDelete) {
      return showNotification('You are not permitted to delete this option, please contact your business admin.', 'warning');
    }
    onDelete(data.id, data.type);
    if (isSelected) {
      clearValue();
    }
  };
  return (
    <OptionContainerStyled>
      <components.Option {...props} {...innerProps} />
      {!data.isGlobal && !data.isDeleted && data.value !== 'NEW' && <IconStyled onClick={handleClick} id={children} name={children} icon="times" color={theme.colors.text.light.background} size="1x" />}
    </OptionContainerStyled>
  );
};

export default Option;
