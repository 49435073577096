import { SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { MD3ColorSchemeTokens } from '@mui/material-next/styles';
import { Text, styled, Icons, useTheme } from '@rio/ui-components';

import { BoxWrapperStyled, ToolsContainerStyled } from './style';

const TextStyled = styled(Text)`
  margin-bottom: 8px;
  color: ${(p) => p.theme.sys.color.onSurface};
`;

const LinkContainer = styled('div')`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.sys.color.outlineVariant};
  background-color: ${(p) => p.theme.sys.color.surface};
  box-shadow: ${(p) => p.theme.sys.elevation[1]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;

const TextContainer = styled('div')`
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;

  p {
    margin: 0px;
  }
`;

interface IconOwnProps {
  color?: keyof MD3ColorSchemeTokens;
}

interface GradientCircleIconProps {
  icon: React.ComponentType<IconOwnProps & SVGProps<SVGSVGElement>> | SvgIconComponent;
  id: string;
  color: string;
  hideShadowGradient?: boolean;
}

export const GradientCircleIcon = ({ icon, color, id, hideShadowGradient }: GradientCircleIconProps) => {
  const StyledIcon = styled(icon as React.ComponentClass<React.ComponentProps<'svg'>>)`
    color: ${color};
    position: absolute;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    left: 8px;
    top: 8px;
  `;

  const IconContainer = styled('div')`
    height: 40px;
    width: 40px;
    position: relative;
  `;
  return (
    <IconContainer>
      <StyledIcon />
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle cx="20" cy="20" r="20" fill={`url(#paint0_linear_${id})`} />
        {!hideShadowGradient && (
          <defs>
            <linearGradient id={`paint0_linear_${id}`} x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
              <stop stopColor={color} stopOpacity="0.15" />
              <stop offset="1" stopColor={color} stopOpacity="0" />
            </linearGradient>
          </defs>
        )}
      </svg>
    </IconContainer>
  );
};

export const QuickTools = () => {
  const theme = useTheme();

  const quickLinks = [
    {
      id: 'survey-tool',
      title: 'Run a social survey',
      link: 'surveys/sent/overview',
      icon: Icons.PeopleOutline,
      color: theme.extendedColors.economic.color,
    },
    { id: 'reporting-tool', title: 'Check your Carbon', link: 'reporting', icon: Icons.Co2, color: '#006C4B' },
  ];

  return (
    <div>
      <TextStyled typescale="title" size="medium">
        Quick Tools
      </TextStyled>
      <BoxWrapperStyled>
        <ToolsContainerStyled>
          {quickLinks.map((linkItem) => (
            <Link key={linkItem.title} to={`/v2/${linkItem.link}`}>
              <LinkContainer>
                <GradientCircleIcon icon={linkItem.icon} color={linkItem.color} id={linkItem.id} />
                <TextContainer>
                  <TextStyled typescale="body" size="medium">
                    {linkItem.title}
                  </TextStyled>
                </TextContainer>
              </LinkContainer>
            </Link>
          ))}
        </ToolsContainerStyled>
      </BoxWrapperStyled>
    </div>
  );
};
