import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, LoadingIndicator, Search, ErrorMessage, ContainerError } from 'rio-ui-components';

import {
  GET_USERS_BY_ACCOUNT_ID,
  GET_EXCLUSIONS_FOR_ACCOUNT,
  SAVE_USER_EXCLUSION,
  BULK_UPDATE_EXCLUSIONS
} from './index.queries';
import ContainerHeader from '../../../components/ContainerHeader';
import SubscriptionRow from './SubscriptionRow';

import * as _ from 'lodash';
import { useSearch } from '../../../hooks/useSearch';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SubscriptionSelector = ({ title, courseId, accountId }) => {
  const [userExclusions, setUserExclusions] = useState({});
  const [toggleIsChecked, setToggleIsChecked] = useState(false);

  const [updateExclusions] = useMutation(BULK_UPDATE_EXCLUSIONS);

  const { loading, error, data, refetch } = useQuery(GET_USERS_BY_ACCOUNT_ID, {
    variables: { accountId: accountId, limit: 999 }
  });

  const {
    loading: exclusionLoading,
    error: exclusionError,
    data: exclusionData
  } = useQuery(GET_EXCLUSIONS_FOR_ACCOUNT, {
    variables: { id: accountId },
    fetchPolicy: 'network-only'
  });

  const queriesFinished = data && exclusionData && !loading && !exclusionLoading;

  const [search, setSearch, searched] = useSearch(data?.getUserInfoByAccountId.users || [], [
    'first_name',
    'last_name'
  ]);

  useEffect(() => {
    if (!exclusionLoading && exclusionData) {
      const userExclusionsKeyed = _.keyBy(exclusionData?.getExclusionsByAccountId, 'userId');
      const anyExclusions = !!exclusionData?.getExclusionsByAccountId.filter((userExclusion) =>
        userExclusion.exclusions.includes(courseId)
      ).length;
      setUserExclusions(userExclusionsKeyed);
      setToggleIsChecked(!anyExclusions);
    }
  }, [exclusionLoading, exclusionData, courseId]);

  const [saveExclusion] = useMutation(SAVE_USER_EXCLUSION);

  const handleToggleAllUsers = (newToggleState) => {
    const currentExclusions = { ...userExclusions };
    const userIds = data.getUserInfoByAccountId.users.map((user) => user.id);
    userIds.forEach((user) => {
      const userExclusions = currentExclusions[user] || { exclusions: [] };
      if (!newToggleState) {
        currentExclusions[user] = { exclusions: [...new Set([...userExclusions.exclusions, courseId])] };
      } else {
        currentExclusions[user] = {
          exclusions: [...new Set([...userExclusions.exclusions.filter((id) => id !== courseId)])]
        };
      }
    });
    setUserExclusions(currentExclusions);
    setToggleIsChecked(newToggleState);
    updateExclusions({
      variables: {
        userIds,
        accountId,
        courseId,
        operation: newToggleState ? 'REMOVE' : 'ADD'
      }
    });
  };

  const handleCheckboxClick = (userId) => {
    const currentExclusions = userExclusions[userId]?.exclusions || [];

    let newExclusions;
    if (currentExclusions.includes(courseId)) {
      newExclusions = currentExclusions.filter((exclusion) => exclusion !== courseId);
    } else {
      newExclusions = [...currentExclusions, courseId];
    }

    saveExclusion({
      variables: {
        userId,
        accountId,
        exclusions: newExclusions
      }
    });

    const constructedExclusions = {
      ...userExclusions,
      [userId]: {
        exclusions: newExclusions
      }
    };

    setToggleIsChecked(
      !Object.keys(constructedExclusions).filter((userId) => {
        return constructedExclusions[userId] ? constructedExclusions[userId].exclusions.includes(courseId) : false;
      }).length
    );

    setUserExclusions(constructedExclusions);
  };

  return (
    <Col name="ConfigurationAccountsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationLearnSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title={title}
      >
        <Col container item>
          <Search
            name="ConfigurationLearnSubscriptionsContainer__Controls__Search"
            hideButton
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Col>
      </ContainerHeader>
      {loading || exclusionLoading ? (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      ) : error || exclusionError ? (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError
              name="ConfigurationLearnSubscriptionsContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetch}
            />
          )}
        </ErrorMessage>
      ) : null}

      {queriesFinished && (
        <SubscriptionRow
          hideProfile
          id={'ToggleAllUserExclusions'}
          courseName={'Add/Remove All Users'}
          onCheckboxClick={() => handleToggleAllUsers(!toggleIsChecked)}
          checked={toggleIsChecked}
        />
      )}

      {queriesFinished &&
        searched.map((user) => {
          const isChecked = userExclusions[user.id] ? !userExclusions[user.id].exclusions.includes(courseId) : true;

          return (
            <SubscriptionRow
              key={user.id}
              id={courseId}
              courseName={`${user.first_name} ${user.last_name}`}
              accountId={accountId}
              onCheckboxClick={() => handleCheckboxClick(user.id)}
              checked={isChecked}
            />
          );
        })}
    </Col>
  );
};

export default SubscriptionSelector;
