import { useQuery } from '@apollo/client';
import { Select, OptionsProps } from '@rio/ui-components';
import { toSelectOptions } from '~/utils';
import { GET_ALL_CURRENCY_TYPES } from './index.queries';

interface CurrencySingleSelectProps {
  isMulti?: false;
  onChange: (e: OptionsProps) => void;
  name?: string;
  value?: string;
  label?: string;
}

export const CurrencySelectV2 = ({ name, value, onChange, label }: CurrencySingleSelectProps) => {
  const { data, loading, error } = useQuery(GET_ALL_CURRENCY_TYPES);

  const options = toSelectOptions(data?.getAllCurrencyTypes);

  return (
    <Select
      name={name}
      placeholder="Please select currency"
      value={value}
      options={options}
      error={!!error}
      helperText={(error && error.message) || ''}
      isLoading={loading}
      label={label}
      onChange={(e) => onChange(e)}
    />
  );
};
