import React, { useEffect } from 'react';
import { Dictionary } from 'lodash';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

export interface EmbeddingApi extends EventTarget {
  setParameters: (parameters: object) => void;
  getActiveParameterValues: (callback: (parameters: object) => void) => void;
  on: (event: string, callback: Function) => void;
}
interface QuicksightFrameProps {
  id: string;
  dashboardurl: string;
  accountId: string;
  parameters: Dictionary<string | number>;
  onDashboardInit?: (dashboard: EmbeddingApi) => void;
}

export const QuicksightFrame = (props: QuicksightFrameProps) => {
  const { id, dashboardurl, accountId, onDashboardInit, parameters = {}, ...rest } = props;

  useEffect(() => {
    async function embed() {
      const containerDiv = document.getElementById(id);
      const options = {
        url: dashboardurl,
        container: containerDiv,
        parameters: {
          accountId: accountId,
          ucAccountId: accountId.toUpperCase(),
          ...parameters,
        },
        scrolling: 'yes',
        height: '100%',
        width: '100%',
      };
      const contentOptions = {
        toolbarOptions: {
          undoRedo: false,
          export: true,
        },
      };
      const embeddingContext = await createEmbeddingContext();
      const { embedDashboard } = embeddingContext;

      // anys to make TS work for V1 - implemented properly in V2
      const dashboard = embedDashboard(options as any, contentOptions);
      onDashboardInit?.(dashboard as any);
    }

    embed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={id} {...rest} />;
};
