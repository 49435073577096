import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

export const replaceEmpty = ({ value }: ValueFormatterParams) => (!value ? '-' : value);

export const getUserNames = ({ data }: ValueGetterParams) => {
  if (!data.user) {
    return null;
  }

  const { first_name, last_name } = data.user;
  return `${first_name} ${last_name}`;
};

export const feedbackGetter = ({ value }: ValueFormatterParams) => (value ? JSON.parse(value).feedback || '-' : '-');
