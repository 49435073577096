import React from 'react';
import styled from 'styled-components';

const CircleContainer = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: red;
  top: 0px;
  right: 0px;
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationBadge = ({ count }) => {
  return <CircleContainer>{count}</CircleContainer>;
};

export default NotificationBadge;
