import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Select, Text } from 'rio-ui-components';
import { Link } from 'react-router-dom';

export const timeOptions = [
  { value: 'desc', label: 'Newest' },
  { value: 'asc', label: 'Oldest' }
];

export const RowSearch = styled(Row)`
  padding: 40px 50px 40px 50px;
`;

export const ColSearch = styled.div`
  background: #fff;
  padding: 2px 10px 2px 10px;
  border-radius: 2px;
`;

const sharedStyle = css`
  border: none;
  background: none;
  &:focus-within,
  &:hover,
  .focus {
    background: none;
  }
`;

export const SelectNewest = styled(Select)`
  ${sharedStyle}
  min-width: 120px;
`;

export const SelectAllTopics = styled(Select)`
  ${sharedStyle}
  min-width: 165px;
`;

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 25px;
  padding: 0px 50px 0px 50px;
`;

export const ProductWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  &.isCartVisible {
    margin-bottom: 130px;
  }
`;

const ContactText = styled(Text)`
  text-align: center;
  margin: 20px 0 15px 0;
  a:hover {
    text-decoration: underline;
  }
`;

export const ContactUs = () => (
  <ContactText>
    <Link to="/contact">Can’t find what you are looking for? Contact us to find out more</Link>
  </ContactText>
);
