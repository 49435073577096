import { useQuery } from "@apollo/client";
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_TRANSPORT_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface TransportTypeSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function TransportTypeSelect({ value, onChange }: TransportTypeSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllTransportTypes'>>(GET_ALL_TRANSPORT_TYPES);
  return <Select placeholder="Please select type of transport" value={value} options={toSelectOptions(data?.getAllTransportTypes)} error={error} isLoading={loading} onChange={onChange} />;
}
