export const getPriorityIconProps = (priority, theme) => {
  const options = {
    HIGH: {
      icon: 'exclamation-triangle',
      color: theme.colors.danger.normal.background
    },
    MEDIUM: {
      icon: 'exclamation-circle',
      color: theme.colors.warning.normal.background
    },
    LOW: {
      icon: 'exclamation-circle',
      color: theme.colors.text.normal.background
    },
    COMPLETED: {
      icon: 'check',
      color: theme.colors.success.normal.background
    },
    INCOMPLETED: {
      icon: 'times',
      color: theme.colors.danger.normal.background
    },
    OVERDUE: {
      icon: 'exclamation-circle',
      color: theme.colors.warning.normal.background
    }
  };

  return options[priority] || {};
};
