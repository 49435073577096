import React from 'react';
import styled from 'styled-components';
import { Nullable } from '../../types';

const Mark = styled.mark`
  background-color: yellow;
`;

interface MatchMarkProps {
  text: Nullable<string>;
  searchValue?: Nullable<string>;
}

function getParts(text: string, searchValue: Nullable<string>) {
  if (!searchValue) {
    return [text];
  }
  const reg = new RegExp(`(${searchValue})`, 'ig');
  const result = reg.exec(text);
  return result ? [text.slice(0, result.index), <Mark>{result[0]}</Mark>, text.slice(result.index + searchValue.length)] : [text];
}

export function MatchMark({ text, searchValue }: MatchMarkProps) {
  return <>{text ? getParts(text, searchValue) : null}</>;
}
