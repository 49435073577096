import React from 'react';
import { Checkbox, Col, Heading, ProfilePicture, Row, Button } from 'rio-ui-components';
import styled from 'styled-components';
import { PurpleWrapper } from './BundleRows';
import { useNavigate } from 'react-router-dom';
import { makeUrl } from '../../../utils';
import { LicencesCount } from '../../../components/AccountProductLicence';
import { useRoutes } from '~/hooks';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ShowCol = styled(Col)`
  min-width: 50px;
  text-align: center;
  & div {
    display: inline-block;
  }
`;

const StyledCol = styled(Col)`
  min-width: 170px;
`;
const ButtonCol = styled(Col)`
  min-width: 150px;
`;
const WrapperCheckbox = ({ id, onCheckboxClick, disabledCheckbox, checked }) => {
  return disabledCheckbox ? (
    <PurpleWrapper>
      <Checkbox
        id={`update-subscription-${id}`}
        name={id}
        onChange={onCheckboxClick}
        checked={disabledCheckbox ? disabledCheckbox : checked}
        disabled={disabledCheckbox}
      />
    </PurpleWrapper>
  ) : (
    <Checkbox
      id={`update-subscription-${id}`}
      name={id}
      onChange={onCheckboxClick}
      checked={disabledCheckbox ? disabledCheckbox : checked}
      disabled={disabledCheckbox}
    />
  );
};

const SubscriptionRow = (props) => {
  const {
    courseName,
    accountId,
    id,
    onCheckboxClick,
    checked,
    disabledCheckbox,
    hideProfile,
    permissions,
    licencesCountMap = new Map(),
  } = props;
  const routes = useRoutes();
  const navigate = useNavigate();

  const isManageLicenceVisible = permissions.licenceService.assignLicence;

  return (
    <SubscriptionRowStyled key={id} name={`account-row--${accountId}`} container itemAlign="center">
      {!hideProfile && (
        <SubscriptionRowPictureStyled
          name={`account-row__profile-picture--${accountId}`}
          size="xs"
          accountName={courseName}
        />
      )}
      <Col container item>
        <Heading inline name={`account-row__name--${accountId}`} size="md">
          {courseName}
        </Heading>
      </Col>
      {isManageLicenceVisible && (
        <ButtonCol>
          <Button
            size="sm"
            onClick={() => {
              const url = makeUrl(`/${routes.configuration.root}/${routes.configuration.menu.learn.assignLicense}`, {
                productId: id,
                accountId,
                productType: 'course',
              });
              navigate(url);
            }}
          >
            <LicencesCount id={id} licencesCountMap={licencesCountMap} />
          </Button>
        </ButtonCol>
      )}
      <StyledCol />
      <ShowCol>
        <WrapperCheckbox
          id={id}
          onCheckboxClick={onCheckboxClick}
          disabledCheckbox={disabledCheckbox}
          checked={checked}
        />
      </ShowCol>
    </SubscriptionRowStyled>
  );
};

export default SubscriptionRow;
