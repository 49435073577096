import { Icon } from 'rio-ui-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { AccordionContainer, AccordionSummaryPendingTextContainer } from '../Elements';
import { DataAnomalies } from './types';

export const AnomaliesAccordion = () => {
  return (
    <AccordionContainer>
      <Accordion>
        <AccordionSummary expandIcon={<Icon icon="chevron-down" />} aria-controls="panel1a-content">
          <AccordionSummaryPendingTextContainer>Anomalies found in your data</AccordionSummaryPendingTextContainer>
        </AccordionSummary>
        <AccordionDetails>
          <DataAnomalies />
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  );
};
