import React from 'react';
import styled from 'styled-components';
import { RelatedProject } from '../types';
import { LinkStyled } from '../../../DataContainer/CellRenderers/elements';

export const ProjectLinkStyled = styled(LinkStyled)`
  margin-right: 4px;
  &:hover {
    color: ${(props) => props.theme.colors.text.normal.background};
  }
`;

export interface RelatedProjectsCellProps {
  data: {
    relatedProjects: RelatedProject[];
  };
}

export const RelatedProjectsCell = ({ data: { relatedProjects } }: RelatedProjectsCellProps) => {
  return (
    <div>
      {relatedProjects.map((proj: RelatedProject) => (
        <ProjectLinkStyled key={proj.id} to={`/projects/${proj.id}`} target="_blank" rel="noopener noreferrer">
          {proj.name}
        </ProjectLinkStyled>
      ))}
    </div>
  );
};
