import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReportingReports from './ReportingReports';
import ReportingContainerV2 from './v2';
import QuicksightReport from './QuicksightReport';

const TempReportingContainer = () => {
  const location = useLocation();
  const v2 = location.pathname.startsWith('/v2');

  return (
    <Routes>
      {v2 && (
        <>
          <Route path="/overview" element={<ReportingContainerV2 />} />
          <Route path=":name/:id" element={<ReportingContainerV2 />} />
          <Route path="*" element={<Navigate to="./overview" />} />
        </>
      )}

      {!v2 && (
        <>
          <Route path="" element={<ReportingReports />} />
          <Route path=":folderId" element={<ReportingReports />} />
          <Route path=":id/:name" element={<QuicksightReport />} />
        </>
      )}
    </Routes>
  );
};

export default TempReportingContainer;
