import { gql } from '@apollo/client';
import DOCUMENT_FIELDS from './DocumentFields.fragment';

export default gql`
  fragment ChapterFields on Chapter {
    id
    title
    number
    synopsis
    type
    content {
        headerButton {
          text
          link
        }
      }
    documents {
      ...DocumentFields
      __typename
    }
    ... on ManagementSystemChapter {
      id
      title
      number
      synopsis
      type
      content {
        headerButton {
          text
          link
        }
      }
      documents {
        ...DocumentFields
        __typename
      }
    }
    __typename
  }
  ${DOCUMENT_FIELDS}
`;
