import { SummarizeStatus } from '@rio/rio-types';
import { Nullable } from '../../../types';
import { useTheme, Icons } from '@rio/ui-components';

interface SummarizeButtonProps {
  showDeleteButton: boolean;
  isCompleted: boolean;
  duplicatesNumber: number;
  approved: boolean;
  summarizeStatus: SummarizeStatus;
  onDeleteClick: () => void;
  handleQuickViewClick: () => void;
}

function getData(
  theme: ReturnType<typeof useTheme>,
  summarizeStatus: SummarizeStatus,
  duplicatesNumber: number,
  approved: boolean,
  handleQuickViewClick: () => void
) {
  let title = '';
  let color = '';
  let cursor = 'pointer';
  let onClick: Nullable<() => void> = handleQuickViewClick;

  switch (summarizeStatus) {
    case SummarizeStatus.Processing:
      title = 'Processing summary...';
      color = theme.palette.info.main;
      cursor = 'progress';
      onClick = undefined;
      break;
    case SummarizeStatus.Completed:
      title = 'Quick View';
      color = duplicatesNumber && !approved ? theme.palette.warning.main : theme.palette.success.main;
      break;
    case SummarizeStatus.Failed:
      title = 'Summary failed with errors';
      color = theme.palette.error.main;
      cursor = 'not-allowed';
      onClick = undefined;
      break;
    case SummarizeStatus.NotStarted:
    default:
      title = 'Prepare summary';
      color = theme.palette.info.main;
      break;
  }

  return {
    title,
    color,
    cursor,
    onClick,
  };
}

export function SummarizeButton({
  showDeleteButton,
  isCompleted,
  onDeleteClick,
  duplicatesNumber,
  approved,
  summarizeStatus,
  handleQuickViewClick,
}: SummarizeButtonProps) {
  const theme = useTheme();
  const { color } = getData(theme, summarizeStatus, duplicatesNumber, approved, handleQuickViewClick);

  return (
    <>
      {showDeleteButton && (
        <Icons.Clear
          sx={{ color: theme.palette.grey[400], cursor: 'pointer', marginTop: '10px' }}
          onClick={onDeleteClick}
        />
      )}
      {isCompleted && <Icons.Visibility sx={{ color: color, marginTop: '10px' }} />}
    </>
  );
}
