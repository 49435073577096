import React from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div`
  ${({theme, color = 'primary'}) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    -webkit-transition: background .5s ease-out, border .5s ease-out;
    -moz-transition: background .5s ease-out, border .5s ease-out;
    -o-transition: background .5s ease-out, border .5s ease-out;
    transition: background .5s ease-out, border .5s ease-out;

    border: 1px solid transparent;
    border-radius: 3px;

    :hover {
      background: ${theme.colors[color].light.background};
      border: 1px solid ${theme.colors[color].dark.background} !important;
    }
  `}
`;

const InnerContainer = styled.div`
  padding: 1.2rem 1.8rem 1.2rem 1.6rem;
  width: 2rem;
`;

const Arrow = styled.p`
  ${({theme, color = 'primary'}) => `
    border: solid ${theme.colors[color].dark.background};
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.3rem;
  `}
`;

const LeftArrowIcon = styled(Arrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const RightArrowIcon = styled(Arrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const UpArrowIcon = styled(Arrow)`
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const DownArrowIcon = styled(Arrow)`
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

type Props = {
  className?: string,
  onClick: React.MouseEventHandler<HTMLDivElement>,
}

const LeftArrow = ({ className, onClick }: Props) => (
  <OuterContainer className={className} onClick={onClick}>
    <InnerContainer>
      <LeftArrowIcon />
    </InnerContainer>
  </OuterContainer>
);

const RightArrow = ({ className, onClick }: Props) => (
  <OuterContainer className={className} onClick={onClick}>
    <InnerContainer>
      <RightArrowIcon />
    </InnerContainer>
  </OuterContainer>
);

const UpArrow = ({ className, onClick }: Props) => (
  <OuterContainer className={className} onClick={onClick}>
    <InnerContainer>
      <UpArrowIcon />
    </InnerContainer>
  </OuterContainer>
);

const DownArrow = ({ className, onClick }: Props) => (
  <OuterContainer className={className} onClick={onClick}>
    <InnerContainer>
      <DownArrowIcon />
    </InnerContainer>
  </OuterContainer>
);

export {
  LeftArrow,
  RightArrow,
  UpArrow,
  DownArrow,
}