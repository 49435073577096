import { useState, useCallback, FormEvent } from 'react';
import { Button, styled } from '@rio/ui-components';
import { MutationUpdateSurveyArgs, Survey, SurveyInput } from '@rio/rio-types';
import { Errors } from '../../../../types';
import { useCurrentAccountId } from '../../../../hooks';
import { validate } from '../../SurveysContainer/validate';
import { useSurveyForm } from '../../SurveysContainer/hooks/useSurveyForm';
import { useUpdate, useDelete } from '../../queries';
import { SurveyForm } from './SurveyForm';

const SurveyFormWrapper = styled('div')`
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 32px;
`;

const ButtonStyle = styled(Button)`
  max-width: 50%;
  width: 100%;
`;

interface UpdateSurveyProps {
  existingSurvey: Survey;
  onDismiss: () => void;
  onDelete?: () => void;
}

export function UpdateSurvey({ onDismiss, existingSurvey, onDelete }: UpdateSurveyProps) {
  const accountId = useCurrentAccountId();
  const { state: survey } = useSurveyForm();
  const [formErrors, setErrors] = useState<Errors<SurveyInput>>({});

  const [updateSurvey, { loading: updating }] = useUpdate();
  const [deleteSurvey, { loading: deleting }] = useDelete();
  const disabled = updating || deleting;

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setErrors({});

      const [errors, validSurvey] = validate(survey);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors,
        }));
      }

      const variables: MutationUpdateSurveyArgs = {
        id: existingSurvey.id,
        survey: {
          ...validSurvey,
          ownerAccountId: existingSurvey.owner.id,
        },
      };

      await updateSurvey({
        variables,
        onCompleted: () => onDismiss(),
      });
    },
    [survey, setErrors, updateSurvey, onDismiss, existingSurvey]
  );

  const handleDelete = useCallback(() => {
    deleteSurvey({
      variables: { id: existingSurvey.id },
      onCompleted: () => {
        onDismiss();
        onDelete?.();
      },
    });
  }, [existingSurvey.id, deleteSurvey, onDismiss, onDelete]);

  return (
    <SurveyFormWrapper onSubmit={handleSubmit}>
      <SurveyForm errors={formErrors} disabled={!!(updating || deleting)} accountId={accountId} isUpdatingSurvey />
      <ButtonWrapper>
        <ButtonStyle variant="filled" name="AddSurvey__Submit" disabled={disabled} onClick={handleSubmit}>
          Update survey
        </ButtonStyle>
        <ButtonStyle variant="outlined" name="DeleteSurveyButton" disabled={disabled} onClick={handleDelete}>
          Delete survey
        </ButtonStyle>
      </ButtonWrapper>
    </SurveyFormWrapper>
  );
}
