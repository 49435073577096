import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Dictionary } from 'lodash';
import downloadFile from 'js-file-download';
import { v4 as uuid } from 'uuid';
import { useNotification } from '../useNotification';
import { useCurrentAccountId } from '../useCurrentAccountId';
import { createXls } from '../../utils';
import { updateProcess, mapProcess } from './utils';
import { LIMIT, GENERATED_FILE_EXTENSION } from './constants';
import { ExportOptions, ExportProcess, ExportApi } from './types';

export const IN_PROGRESS_MESSAGE = `Rio exports your data. You can safely navigate away from this page.`;

export function useInitializeExportApi(): ExportApi {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const results = useRef<Dictionary<object[]>>({});
  const [processes, setProcesses] = useState<ExportProcess[]>([]);

  useEffect(() => {
    processes
      .filter((p) => !p.loading && p.offset !== null && !p.completed && !p.error)
      .forEach((process) => {
        setProcesses(updateProcess(process.id, { loading: true }));
        process.options
          .fetchRows({
            accountId,
            offset: process.offset,
            limit: process.step,
            sort: process.options.sort,
            filters: process.options.filters,
          })
          .then(({ rows, totalRows }) => {
            if (process.error || process.completed) {
              return;
            }
            rows.forEach((row: object) => {
              const formattedRow = process.options.formatRow(row);
              results.current[process.id]?.push(formattedRow);
            });
            const newOffset = (process?.offset || 0) + process.step;
            if (newOffset < totalRows) {
              setProcesses(
                updateProcess(process.id, {
                  totalRows,
                  offset: newOffset,
                  loading: false,
                })
              );
            } else {
              if (results.current[process.id].length) {
                const file = createXls(results.current[process.id], process.options.exportFileName);
                downloadFile(file, `${process.options.exportFileName}.${GENERATED_FILE_EXTENSION}`);
                showNotification('Export completed! Please check your downloads.', 'success');
              } else {
                showNotification('Nothing to export.', 'warning');
              }
              setProcesses(
                updateProcess(process.id, {
                  totalRows,
                  completed: true,
                  offset: totalRows,
                  loading: false,
                })
              );
            }
          })
          .catch((error) => {
            setProcesses(
              updateProcess(process.id, {
                error,
              })
            );
            showNotification(`Export failed: ${error}`, 'danger');
          });
      });
    // eslint-disable-next-line
  }, [processes]);

  const start = useCallback(
    (options: ExportOptions) => {
      const id = uuid();
      const newProcess = {
        id,
        options,
        offset: 0,
        totalRows: null,
        loading: false,
        completed: false,
        error: null,
        step: options.step || LIMIT,
      };
      results.current[id] = [];
      setProcesses((p) => p.concat([newProcess]));
      showNotification(IN_PROGRESS_MESSAGE, 'success');
      return id;
    },
    [showNotification]
  );

  const stop = useCallback((id: string) => {
    delete results.current[id];
    setProcesses((ps) => ps.filter((p) => p.id !== id));
  }, []);

  const getExport = useCallback(
    (id: string) => {
      const process = processes.find((p) => p.id === id);
      if (!process) {
        throw new Error('Export not found');
      }
      return mapProcess(process);
    },
    [processes]
  );

  const getExports = useCallback(() => processes.map((p) => mapProcess(p)), [processes]);

  return useMemo(
    () => ({
      stop,
      start,
      getExport,
      getExports,
    }),
    [stop, start, getExport, getExports]
  );
}
