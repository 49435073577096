import priorities from './taskPriorities.json';
import categories from './taskCategories.json';
import subjects from './taskSubjects.json';
import moment from 'moment';

const requiredFieldMessage = 'Required field';
const invalidValueMeggase = 'Invalid value';

export const ValidateTask = (task, allowEmptyOwners = false) => {
  const errors = {};
  const hasName = !!task.name;
  const hasDescription = !!task.description;
  const hasValidPriority = !!priorities.find((pri) => pri.id === task.priority);
  const hasPriority = !!task.priority;
  const hasCategory = !!categories.find((cat) => cat.value === task.category);
  const hasValidSubject =
    task.category === 'GOVERNANCE' || task.category === 'DATA'
      ? !!subjects[task.category].find((sub) => sub.value === task.subject)
      : task.subject === null;
  const hasOwner = !!task.owners.length;
  const hasValidDate = moment(task.dueDate).isValid();

  if (!hasName) errors.name = requiredFieldMessage;
  if (!hasDescription) errors.description = requiredFieldMessage;
  if (!hasValidPriority) errors.priority = invalidValueMeggase;
  if (!hasPriority) errors.priority = requiredFieldMessage;
  if (!hasCategory) errors.category = requiredFieldMessage;
  if (!hasValidSubject) errors.subject = requiredFieldMessage;
  if (!hasOwner && !allowEmptyOwners) errors.owner = requiredFieldMessage;
  if (!hasValidDate) errors.dueDate = requiredFieldMessage;

  return errors;
};
