import { TextField, DatePicker } from '@rio/ui-components';
import { useAccountById } from '~/hooks/useAccountById';
import { useCreateDepartment } from '~/hooks/useCreateDepartmentV2';
import { CreateDepartmentModal } from '~/components/CreateDepartmentModal/CreateDepartmentModalV2';
import { LocationMetaOptionSelect } from '~/components/LocationMetaOptionSelect/LocationMetaOptionSelectV2';
import { TENURE, REGION, BUILDINGTYPE } from '~/constants/locationMetaOptionTypes';
import { GridStyled } from './styles';
import { LocationMetadataProps } from './type';

type SelectOption = {
  value: string;
  label: string;
};

export const LocationMetadata = ({ accountId, selectedOptions, setSelectedOptions }: LocationMetadataProps) => {
  const accountInfo = useAccountById(accountId);

  const mappedDepartments = accountInfo?.departments?.map((dept) => ({
    value: dept.id ?? '',
    label: dept.name ?? '',
  })) as SelectOption[];

  const mappedSelectedDepartments = selectedOptions?.departments?.map((dept) => ({
    value: dept.id ?? '',
    label: dept.name ?? '',
  })) as SelectOption[];

  const { departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    mappedDepartments,
    mappedSelectedDepartments,
    accountId
  );

  return (
    <GridStyled container>
      <TextField
        label="Location Code (Location ID / ePIM) (Optional)"
        onChange={(e) => setSelectedOptions({ ...selectedOptions, code: e.target.value })}
        value={selectedOptions.code || ''}
      />

      {departmentMultipleSelect((e) => {
        setSelectedOptions({
          ...selectedOptions,
          departments: e.map((dep) => {
            return {
              id: dep.value,
              name: dep.label,
            };
          }),
        });
      })}

      <LocationMetaOptionSelect
        label="Tenure (Optional)"
        accountId={accountId}
        optionType={TENURE}
        selectedOption={selectedOptions.tenure?.value || ''}
        setSelectedOption={(e) => setSelectedOptions({ ...selectedOptions, tenure: e })}
      />

      <LocationMetaOptionSelect
        label="Region (Optional)"
        accountId={accountId}
        optionType={REGION}
        selectedOption={selectedOptions.region?.value || ''}
        setSelectedOption={(e) => setSelectedOptions({ ...selectedOptions, region: e })}
      />

      <LocationMetaOptionSelect
        label="Building Type (Optional)"
        accountId={accountId}
        optionType={BUILDINGTYPE}
        selectedOption={selectedOptions.buildingType?.value || ''}
        setSelectedOption={(e) => setSelectedOptions({ ...selectedOptions, buildingType: e })}
      />

      <TextField
        label="Floor Space (Square Meters) (Optional)"
        onChange={(e) => setSelectedOptions({ ...selectedOptions, floorSpace: e.target.value })}
        type="number"
        inputProps={{ min: 0 }}
        value={selectedOptions.floorSpace || ''}
      />

      <DatePicker
        label="Occupation Start Date (Optional)"
        onChange={(date) => setSelectedOptions({ ...selectedOptions, startDate: date })}
        name="startDate"
        value={selectedOptions.startDate || ''}
      />

      <DatePicker
        label="Occupation End Date (Optional)"
        onChange={(date) => setSelectedOptions({ ...selectedOptions, endDate: date })}
        name="endDate"
        value={selectedOptions?.endDate || ''}
      />

      <DatePicker
        label="Lease Renewal Date (Optional)"
        onChange={(date) =>
          setSelectedOptions({
            ...selectedOptions,
            leaseRenewalDate: date,
          })
        }
        name="leaseDate"
        value={selectedOptions?.leaseRenewalDate || ''}
      />

      <TextField
        label="Notes (Optional)"
        onChange={(e) => setSelectedOptions({ ...selectedOptions, notes: e.target.value })}
        value={selectedOptions?.notes || ''}
      />

      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        passedAccountId={accountId}
        onComplete={() => {}}
        onError={() => {}}
      />
    </GridStyled>
  );
};
