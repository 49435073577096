import { NavigationRail } from '@rio/ui-components';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Col } from 'rio-ui-components';
import { Account, ReceivedSurvey, RioModule, Task, User } from '@rio/rio-types';
import { useCurrentAccountId, useUserToken } from '~/hooks';
import { accountsWithHiddenContentParts } from '~/constants';
import { countIncompleteSurveys } from '~/hooks/surveys/useGetReceivedSurveysCount';

const ContainerStyled = styled(Col)`
  min-width: 100px;
  height: calc(100% - 32px);
  position: fixed;
  flex: 0 0 auto;
  margin-top: -10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  },
`;

interface SideBarProps {
  account: Account;
  user: User;
  receivedSurveys: ReceivedSurvey[];
  userTasks: Task[];
}

const SideBar = ({ account, user, receivedSurveys, userTasks }: SideBarProps) => {
  const accountId = useCurrentAccountId();
  const { token: idToken } = useUserToken();
  const { permissions } = user.role;
  const { count: incompleteSurveysCount } = useMemo(() => countIncompleteSurveys(receivedSurveys), [receivedSurveys]);

  const incompleteNotification = userTasks.filter(
    (item) => !item.isCompleted && !!item.owners.filter(({ id }) => id.toLowerCase() === idToken.sub)
  ).length;

  const showMenuItems = accountsWithHiddenContentParts.includes(accountId);

  return (
    <ContainerStyled>
      <NavigationRail
        button={{
          learn: { show: permissions.allowedRioModules.includes(RioModule.Engage) },
          govenance: { show: permissions.allowedRioModules.includes(RioModule.Governance) },
          surveys: { show: !!account.accessControls.surveys, badge: incompleteSurveysCount },
          data: { show: permissions.allowedRioModules.includes(RioModule.Data) && !showMenuItems },
          reporting: { show: permissions.allowedRioModules.includes(RioModule.Reporting) && !showMenuItems },
          intelligence: {
            show:
              !!account.accessControls.intelligence &&
              permissions.allowedRioModules.includes(RioModule.Intelligence) &&
              !showMenuItems,
          },
          docs: { show: permissions.allowedRioModules.includes(RioModule.Documents) && !showMenuItems },
          flows: { show: !!account.accessControls.flows },
          tasks: { show: permissions.allowedRioModules.includes(RioModule.Tasks), badge: incompleteNotification },
          configuration: { show: permissions.allowedRioModules.includes(RioModule.Configuration) && !showMenuItems },
        }}
        v2ShowItems={!!account.accessControls.intelligence ? ['flows', 'docs', 'intelligence'] : ['flows', 'docs']}
      />
    </ContainerStyled>
  );
};

export { SideBar as Navigation };
