import { gql } from '@apollo/client';

export const GET_REPORTING_OVERVIEW = gql`
  query getReportingOverview(
    $accountId: ID!
    $from: AWSDate!
    $to: AWSDate!
    $timeGranularity: TimeGranularity!
  ) {
    getTotalEmissions(accountId: $accountId, from: $from, to: $to)
    getEmissionsPercentageBreakdownByScope(accountId: $accountId, from: $from, to: $to) {
      series {
        id
        relativeValue
        absoluteValue
      }
      drilldown {
        id
        parentId
        relativeValue
        absoluteValue
      }
    }
    getEmissionsBySource(accountId: $accountId, timeGranularity: $timeGranularity, from: $from, to: $to) {
      dimension
      time
      value
    }
    getEmissionsByScopeAndSource(accountId: $accountId, from: $from, to: $to) {
      totalNet
      totalGross
      emissions {
        scope
        source
        gross
        net
      }
    }
    getEmissionsNetAndGross(accountId: $accountId, timeGranularity: $timeGranularity, from: $from, to: $to) {
      dimension
      time
      value
    }
  }
`;
