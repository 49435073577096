import { dateValueFormatter } from '../../utils';

export const OccupationEndDateGridColumn = {
  headerName: 'Occupation End Date',
  field: 'locationEndDate',
  filter: 'agDateColumnFilter',
  valueFormatter: dateValueFormatter,
  hide: true,
  cellStyle: {
    textAlign: 'center'
  }
};
