import { Dictionary } from 'lodash';
import { Option } from '../../../types';

export const subjectOptions: Dictionary<Option[]> = {
  DATA: [
    { label: 'Waste', value: 'WASTE' },
    { label: 'Electricity', value: 'ELECTRICITY' },
    { label: 'Gas', value: 'GAS' },
    { label: 'Water', value: 'WATER' },
    { label: 'Transport', value: 'TRANSPORT' }
  ],
  GOVERNANCE: [
    { label: 'Legislation', value: 'LEGISLATION' },
    { label: 'Policies', value: 'POLICIES' },
    { label: 'Management Systems', value: 'MANAGEMENTSYSTEMS' },
    { label: 'Targets', value: 'TARGETS' },
    { label: 'Aspects & Impacts', value: 'ASPECTS' },
    { label: 'H&S Risk Register', value: 'RISKREGISTER' }
  ]
};
