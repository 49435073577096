import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';

import CoursesGrid from '../Courses/CoursesGrid';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { SEARCH_AVALIABLE_COURSES } from '../Courses/index.queries';
import { ErrorMessage, ContainerError, Button } from 'rio-ui-components';
import NotificationBanner from '../../../components/NotificationBanner';
import NoResults from '../../../components/NoResults';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const LoadMore = styled(Button)`
  margin: ${(props) => props.theme.geometry.md.spacing} 0;
  border-radius: 0;
`;

const ErrorMessageStyled = styled.div`
  margin-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchCourses = ({ learnAccess, searchValue }) => {
  const userId = jwt_decode(localStorage.getItem('ID_TOKEN')).sub;
  const accountId = useCurrentAccountId();

  return (
    <Query
      query={SEARCH_AVALIABLE_COURSES}
      variables={{ pageNumber: 1, searchTerm: searchValue, userId, accountId, section: 'all' }}
    >
      {({ loading, error, data, fetchMore, refetch, networkStatus }) => {
        if ((loading && networkStatus !== 3) || networkStatus === 4)
          return <ContainerLoadingIndicator name="Courses__Loading" />;
        if (error)
          return (
            <ErrorMessageStyled>
              <ErrorMessage error={error}>
                {({ title, body, icon }) => (
                  <ContainerError name="Courses__Error" icon={icon} title={title} body={body} retry={refetch} />
                )}
              </ErrorMessage>
            </ErrorMessageStyled>
          );

        const restrictedCourseIds = data?.getLearnBundlesForAccount?.reduce((acc, item) => {
          if (item.restricted && item.courses.length > 1) {
            acc.push(...item.courses.slice(1));
          }
          return acc;
        }, []);

        const searchCourses =
          data?.searchCourses?.data.map((course) => ({
            ...course,
            restricted: restrictedCourseIds.includes(course.id)
          })) || [];

        const courses = Array.from(new Set(searchCourses));

        return (
          <>
            {learnAccess && !courses.length && !searchValue && (
              <Link name={'ContactUs__Link'} to={'/contact'} key={'contactUsLink'}>
                <NotificationBannerStyled
                  name="LearnContainer_Access_NotificationBanner"
                  icon="info-circle"
                  color="quinary"
                  title="Engage"
                  body={
                    'You are currently not subscribed to any Engage content. Please contact your Customer Success Manager to find out more or Rio ESG LTD at support@rio.ai.'
                  }
                />
              </Link>
            )}
            {!!courses.length && (
              <>
                <br />
                <CoursesGrid items={data.searchCourses.data} learnAccess={learnAccess} />

                {!data.searchCourses.isLastPage && (
                  <LoadMore
                    color="primary"
                    name="Courses__Pagination__Button--loadmore"
                    onClick={() => this.handleLoadMore(fetchMore, this.state.pageNumber + 1)}
                  >
                    Load more
                  </LoadMore>
                )}
              </>
            )}

            {!courses.length && (
              <ErrorMessageStyled>
                <NoResults name="Courses__NoResults" title="There are no results for this search." />
              </ErrorMessageStyled>
            )}
          </>
        );
      }}
    </Query>
  );
};

export default SearchCourses;
