import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
`;

const EmbeddedFlowsFrame = () => {
  return <Frame id="flowsFrame" src={'/rio-flows/flows/'}></Frame>;
};

export default injectIntl(EmbeddedFlowsFrame);
