import { useApolloClient, useQuery } from '@apollo/client';
import { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Col, Button, Modal, Notification } from 'rio-ui-components';
import { GET_ROLES, GET_USERS } from './index.queries';
import { alphabetiseByField } from '../../../utils/alphabetise';
import { usePermissions, useGetUserById } from '../../../hooks';
import ContainerHeader from '../../../components/ContainerHeader';
import { ClearButton } from '../../../components/ClearButton';
import UserModal from './UserModal';
import DeleteUser from './DeleteUser';
import { UploadUsersModal } from './UploadUsersModal';
import ResetPasswordModal from './resetPasswordModal';
import { getRestrictedScopeOptions } from './utils';

import { UserGrid } from '../../../components/UserGrid';
import { ExportButton } from '../../../components/ExportButton';

const ButtonContainer = styled(ContainerHeader)`
  justify-content: space-between;
`;

const defaultExportFileName = 'users';

const ConfigurationUsersContainer = (props) => {
  const permissions = usePermissions();

  const [state, setState] = useState({
    account: { id: props.accountId },
    createModal: { show: false },
    uploadModal: { show: false },
    updateModal: { id: '' },
    deleteModal: { id: '' },
    resetPasswordModal: { id: '' },
    notification: { message: null, color: null, id: null },
  });

  const [gridApi, setGridApi] = useState({});

  const client = useApolloClient();

  const refreshGrid = useCallback(
    () =>
      gridApi?.api?.refreshServerSide({
        purge: true,
      }),
    [gridApi]
  );

  const selectedUserInfo = useGetUserById(state.updateModal.id);

  const dismissModals = useCallback(
    (notification = {}) =>
      setState((prevState) => ({
        ...prevState,
        createModal: { show: false },
        uploadModal: { show: false },
        updateModal: { id: null },
        deleteModal: { id: null },
        resetPasswordModal: { id: null },
        notification: { message: notification.message, color: notification.color, id: notification.id },
      })),
    []
  );

  const showModal = useCallback((type, id) => {
    if (type === 'create') return setState((prevState) => ({ ...prevState, createModal: { show: true } }));
    if (type === 'upload') return setState((prevState) => ({ ...prevState, uploadModal: { show: true } }));
    if (type === 'update') return setState((prevState) => ({ ...prevState, updateModal: { id } }));
    if (type === 'delete') return setState((prevState) => ({ ...prevState, deleteModal: { id } }));
    if (type === 'resetPassword') return setState((prevState) => ({ ...prevState, resetPasswordModal: { id } }));
  }, []);

  const hideNotification = () =>
    setState((prevState) => ({ ...prevState, notification: { message: null, color: null, id: null } }));

  const renderModals = useCallback(
    (roleOptions) => {
      const { notification, createModal, uploadModal, updateModal, deleteModal, resetPasswordModal, account } = state;
      const currentUserAccessLevel = permissions.currentUser.accessLevel;
      const scopeOptions = getRestrictedScopeOptions(currentUserAccessLevel);

      return (
        <>
          {notification.message && (
            <Notification
              name="ConfigurationUsersContainer__Notification"
              show
              color="success"
              onClose={hideNotification}
            >
              {notification.message}
              <div hidden name="ConfigurationUsersContainer__Notification__Id">
                {notification.id}
              </div>
            </Notification>
          )}
          {createModal.show && (
            <UserModal
              onDismiss={dismissModals}
              onSuccess={(message, color, id) => dismissModals({ message, color, id })}
              accountId={account.id}
              startUploadingUsers={props.massUploadFunction}
              setCreateMutation={props.setCreateMutation}
              roleOptions={roleOptions}
              accessLevel={permissions.currentUser.accessLevel}
              allowedLocations={permissions.currentUser.allowedLocations}
              allowedTags={permissions.currentUser.allowedTags}
              currentUser={permissions.currentUser}
              scopeOptions={scopeOptions}
            />
          )}
          {uploadModal.show && (
            <UploadUsersModal
              onDismiss={dismissModals}
              accountId={account.id}
              startUploadingUsers={props.massUploadFunction}
              setCreateMutation={props.setCreateMutation}
              roleOptions={roleOptions}
            />
          )}
          {updateModal.id && (
            <>
              {!!selectedUserInfo && (
                <UserModal
                  userId={selectedUserInfo.id}
                  firstName={selectedUserInfo.first_name}
                  lastName={selectedUserInfo.last_name}
                  phone={selectedUserInfo.phone}
                  mobile={selectedUserInfo.mobile}
                  jobTitle={selectedUserInfo.jobTitle}
                  department={selectedUserInfo.department}
                  email={selectedUserInfo.email}
                  profilePicture={selectedUserInfo.profilePicture}
                  role={selectedUserInfo.role}
                  disabled={selectedUserInfo.disabled}
                  accountId={selectedUserInfo.account.id}
                  accessLevel={selectedUserInfo.accessLevel}
                  allowedLocations={selectedUserInfo.allowedLocations}
                  allowedTags={selectedUserInfo.allowedTags}
                  onSuccess={(message, color, id) => {
                    dismissModals({ message, color, id });
                    refreshGrid();
                  }}
                  onDismiss={dismissModals}
                  roleOptions={roleOptions}
                  currentUser={permissions.currentUser}
                  scopeOptions={scopeOptions}
                />
              )}
            </>
          )}
          {deleteModal.id && (
            <Modal
              size="sm"
              show
              dismissable
              name="ConfigurationUsersContainer__Modal--delete"
              onDismiss={dismissModals}
            >
              <DeleteUser
                userId={deleteModal.id}
                onSuccess={(message, color, id) => dismissModals({ message, color, id })}
                onDismiss={dismissModals}
              />
            </Modal>
          )}
          {resetPasswordModal.id && (
            <Modal
              size="sm"
              show
              dismissable
              name="ConfigurationUsersContainer__Modal--resetpassword"
              onDismiss={dismissModals}
              height="auto"
              maxHeight="90vh"
            >
              <ResetPasswordModal userId={resetPasswordModal.id} onDissmiss={dismissModals} />
            </Modal>
          )}
        </>
      );
    },
    [
      dismissModals,
      permissions.currentUser,
      props.massUploadFunction,
      props.setCreateMutation,
      refreshGrid,
      selectedUserInfo,
      state,
    ]
  );

  const { data: rolesData } = useQuery(GET_ROLES);
  const roleOptions = useMemo(() => {
    return (
      rolesData?.getUserRoles
        .filter((role) => !role.readonly)
        .map(({ role, name, restrictedAccess }) => ({ value: role, label: name, restrictedAccess }))
        .sort(alphabetiseByField('label')) || []
    );
  }, [rolesData?.getUserRoles]);
  const roles = useMemo(() => {
    return rolesData?.getUserRoles.reduce((acc, { role, name }) => {
      acc[role] = name;
      return acc;
    }, {});
  }, [rolesData?.getUserRoles]);

  const fetchRows = useCallback(
    async (variables) => {
      console.log('variables', variables);
      const {
        data: {
          getUsers: { rows, totalRows },
        },
      } = await client.query({
        query: GET_USERS,
        variables,
        fetchPolicy: 'network-only',
      });

      return {
        rows,
        totalRows,
      };
    },
    [client]
  );

  return (
    <Col name="ConfigurationUsersContainer" container fullHeight>
      <ButtonContainer name="ConfigurationUsersContainer__Controls" icon="user" iconColor="primary" title="Users">
        <Col span={3}>
          <ClearButton gridApi={gridApi.api} columnApi={gridApi.columnApi} />
        </Col>
        <Col span={3}>
          <Button
            name="ConfigurationUsersContainer__Controls__Button--create"
            color="primary"
            onClick={() => showModal('create')}
          >
            + Add Users
          </Button>
        </Col>
        <Col span={3}>
          <Button
            name="ConfigurationUsersContainer__Controls__Button--upload"
            color="secondary"
            onClick={() => showModal('upload')}
          >
            Upload users
          </Button>
        </Col>
        <Col span={3}>
          <ExportButton
            fetchRows={fetchRows}
            gridApi={gridApi.api}
            columnApi={gridApi.columnApi}
            defaultExportFileName={defaultExportFileName}
            context={{ roles }}
            columnsToSkip={['Actions']}
            label="Export Users"
          />
        </Col>
      </ButtonContainer>
      <>{renderModals(roleOptions)}</>
      <UserGrid
        accountId={props.accountId}
        key={props.accountId}
        setGridApi={setGridApi}
        fetchRows={fetchRows}
        showModal={showModal}
        roles={roles}
      />
    </Col>
  );
};

export default ConfigurationUsersContainer;
