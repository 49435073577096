import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import ProjectsGridContainer from './ProjectsGrid';
import ProjectViewContainer from './ProjectView';

const ContainerStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ProjectContainer = () => {
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<ProjectsGridContainer />} />
        <Route path="/:id" element={<ProjectViewContainer />} />
      </Routes>
    </ContainerStyled>
  );
};

export default ProjectContainer;
