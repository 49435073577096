import { useQueryParam, StringParam } from 'use-query-params';
import { parseUrl } from 'query-string';
import { Nullable } from '../../../types';

const PARAM_NAME = 'aspectSearchValue';

export function useAspectSearchValueFromQueryParam() {
  return useQueryParam(PARAM_NAME, StringParam);
}

export function getAspectSearchValue() {
  const { query } = parseUrl(window.location.href);
  return query[PARAM_NAME] as Nullable<string>;
}
