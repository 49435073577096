import get from 'lodash/get';
import { Icons } from '@rio/ui-components';

export const getPriorityIconProps = (priority, theme) => {
  const options = {
    HIGH: {
      icon: Icons.Warning,
      fill: theme.ref.palette.error[70],
    },
    MEDIUM: {
      icon: Icons.Error,
      fill: get(theme, 'customColors.warning.color'),
    },
    LOW: {
      icon: Icons.Error,
      fill: theme.ref.palette.secondary[50],
    },
    COMPLETED: {
      icon: Icons.Done,
      fill: get(theme, 'extendedColors.environmental.color'),
    },
    INCOMPLETED: {
      icon: Icons.Close,
      fill: theme.ref.palette.error[70],
    },
    OVERDUE: {
      icon: Icons.Error,
      fill: get(theme, 'customColors.warning.color'),
    },
  };

  return options[priority] || {};
};
