import React from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Error, FormContainer, Input, InputHolder, Loader, LoaderContainer, PayButton } from './StripeFormStyles';
import { Col, Row } from 'rio-ui-components';
import { useCreateStripePaymentMethod, useStripePayment } from '../../hooks';
import { Controller, useForm } from 'react-hook-form';
import { marketProductType } from '../../constants';

const STRIPE_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      padding: '5px'
    }
  }
};

export function StripeForm(props) {
  const { cartLineList = [], onComplete = () => {}, onError = () => {}, discount } = props;
  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm();

  const [pay, { loading: isPaying, isNotLoaded: isDisabled }] = useStripePayment();
  const [createStripePaymentMethod, { loading: isCreatingPaymentMethod }] = useCreateStripePaymentMethod();

  const isSubscriptionItem = (productType) =>
    productType === marketProductType.SUBSCRIPTION || productType === marketProductType.ACCOUNT_CAPACITY;
  const isEngageItem = (productType) => productType === marketProductType.COURSE_BUNDLE;
  const isGovernanceItem = (productType) => productType === marketProductType.MANAGEMENT_SYSTEM;
  const isFormHasError = Object.keys(errors).length > 0;
  const isLoading = isPaying || isCreatingPaymentMethod;
  const isErrorVisible = !isValid && !isLoading && isFormHasError;

  const findInCartByFilter = (filter) =>
    cartLineList
      .filter(
        ([
          ,
          {
            quantity,
            product: { productType }
          }
        ]) => filter(productType) && quantity > 0
      )
      .map(
        ([
          ,
          {
            quantity,
            product: { id }
          }
        ]) => [id, quantity]
      ) || [];

  const submitForm = async (form) => {
    try {
      const teamSubscriptionItems = findInCartByFilter(isSubscriptionItem);
      const engageItems = findInCartByFilter(isEngageItem);
      const governanceItems = findInCartByFilter(isGovernanceItem);

      const { id: paymentMethod } = await createStripePaymentMethod(form);

      /**
       * TODO: move to promise all
       */
      await pay({ items: teamSubscriptionItems, paymentMethod, couponId: discount?.couponId });
      await pay({ items: governanceItems, paymentMethod, couponId: discount?.couponId });
      await pay({ items: engageItems, paymentMethod, couponId: discount?.couponId }, false);

      onComplete();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <>
      <FormContainer name="Stripe-Form">
        <form onSubmit={handleSubmit(submitForm)}>
          <Row container align="between">
            <Col span={5}>
              <h4>
                <b>02</b> Billing Address
              </h4>

              <Row container align="between">
                <Col name="Stripe-Form__Full-Name" span={12}>
                  <h6>FULL NAME</h6>
                  <Controller
                    name="billing_details.name"
                    render={({ field }) => <Input {...field} />}
                    defaultValue={''}
                    rules={{ required: true }}
                    control={control}
                  />
                </Col>
              </Row>

              <Row container align="between">
                <Col name="Stripe-Form__Billing-Address" span={12}>
                  <h6>BILLING ADDRESS</h6>
                  <Controller
                    name="billing_details.address.line1"
                    render={({ field }) => <Input {...field} />}
                    defaultValue={''}
                    rules={{ required: true }}
                    control={control}
                  />
                </Col>
              </Row>

              <Row container align="between">
                <Col name="Stripe-Form__Town" span={5}>
                  <h6>TOWN</h6>
                  <Controller
                    name="billing_details.address.city"
                    render={({ field }) => <Input {...field} />}
                    defaultValue={''}
                    rules={{ required: true }}
                    control={control}
                  />
                </Col>

                <Col span={2} />

                <Col name="Stripe-Form__Post-Code" span={5}>
                  <h6>POST CODE</h6>
                  <Controller
                    name="billing_details.address.postal_code"
                    render={({ field }) => <Input {...field} />}
                    defaultValue={''}
                    rules={{ required: true }}
                    control={control}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={1} />

            <Col span={5}>
              <h4>
                <b>03</b> Debit/Credit Card Details
              </h4>

              <Row container align="between">
                <Col name="Stripe-Form__Cardholder-Name" span={12}>
                  <h6>CARDHOLDER’S NAME</h6>
                  <Controller
                    name="cardholderName"
                    render={({ field }) => <Input {...field} />}
                    defaultValue={''}
                    rules={{ required: true }}
                    control={control}
                  />
                </Col>
              </Row>

              <Row container align="between">
                <Col name="Stripe-Form__Card-Number" span={12}>
                  <h6>CARD NUMBER</h6>
                  <InputHolder>
                    <CardNumberElement options={STRIPE_OPTIONS} />
                  </InputHolder>
                </Col>
              </Row>

              <Row container align="between">
                <Col name="Stripe-Form__Expiry-Date" span={5}>
                  <h6>EXPIRY DATE (MM/YY)</h6>
                  <InputHolder>
                    <CardExpiryElement options={STRIPE_OPTIONS} />
                  </InputHolder>
                </Col>

                <Col span={2} />

                <Col name="Stripe-Form__Cvv-Number" span={5}>
                  <h6>CVV NUMBER</h6>
                  <InputHolder>
                    <CardCvcElement options={STRIPE_OPTIONS} />
                  </InputHolder>
                </Col>
              </Row>
            </Col>

            <Col span={1} />
          </Row>

          {isErrorVisible && <Error>Please fill all fields</Error>}

          {!isLoading && (
            <div>
              <PayButton name="Stripe-Form__Pay-Button" disabled={isDisabled}>
                Pay now
              </PayButton>
            </div>
          )}

          {isLoading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </form>
      </FormContainer>
    </>
  );
}
