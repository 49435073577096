import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [
  { label: 'Data', to: `..` },
  { label: 'Transport', to: '/data/transport/view' },
  { label: 'Uploads' },
];

export const V2TransportUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      gridKey="transportUploadGridV2"
      title="Here's your upload history"
      breadcrumbs={breadcrumbs}
      uploadLink="../transport/uploads"
      config={config}
      dataType={TransactionType.Transport}
    />
  );
};
