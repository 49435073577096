import { dromoConfiguration, DromoConfiguration } from './base';
import { validateDates } from './hooks';
import template from '../WaterContainer/UploadContainer/water-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Water';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getWaterConfiguration(): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
    bulkRowHooks: [(records) => validateDates(['start date (dd/mm/yyyy)', 'end date (dd/mm/yyyy)'], records)],
  };
}
