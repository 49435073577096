import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { FactorUsedCell } from '../CellRenderers';
import { useWaterGridConfig } from '../WaterContainer/TransactionsContainer/useWaterGridConfig';
import { GET_WATER_TRANSACTIONS_PAGE } from '../WaterContainer/TransactionsContainer/index.queries';
import { UploadReferenceCell, DocumentReferenceV2 as DocumentReference } from '../CellRenderers/index';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Water' }];

export const WaterTransactionsContainer = () => {
  const config = useWaterGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_WATER_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables: object) => {
      const {
        data: {
          getWaterTransactionPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title="Your water data"
      defaultExportFileName="water-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../water/uploads"
      config={{
        ...config,
        components: {
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={fetchRows}
    />
  );
};
