import styled from 'styled-components';
import * as _ from 'lodash';
import { Col, Row, Label, TextInput, Heading, Text, Icon } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import { Account, AccessControls } from '@rio/rio-types';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { AccountsMultiSelect } from '../../../components/AccountsMultiSelect';
import MarkdownViewEditor from '../../../components/MarkdownViewEditor';
import SectorSelector from '../../../components/SectorSelector';
import { ReportingYearSelect } from './ReportingYearSelect';
import { Option, MultiSelectEvent } from '../../../types';

const StyledLink = styled(Link)`
  text-decoration: underline;
`;
const LabelStyled = styled(Label)`
  margin: 0 ${(props) => props.theme.geometry.xs.spacing};
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const TextStyled = styled(Text)`
  margin-top: ${(props) => props.theme.fonts.xs.size};
  margin-bottom: ${(props) => props.theme.fonts.xs.size};
  padding-left: ${(props) => props.theme.fonts.xs.size};
  padding-right: ${(props) => props.theme.fonts.xs.size};
`;
const AccordionStyled = styled(Accordion)`
  border-radius: 7.5px !important;
  margin-top: ${(props) => props.theme.fonts.xs.size};
`;

interface Field {
  text: string;
  error?: string;
}
interface AccountDetailsProps {
  heading: string;
  accountId?: string;
  isSubmitted: boolean;
  handleChange: (...params: any) => void;
  onChildAccountsChange: (...params: any) => void;
  onSectorChange: (...params: any) => void;
  onCheckboxClick: (...params: any) => void;
  selectedChildAccounts: Option[];
  selectedSector: string | null;
  businessName: Field;
  allAccounts: Account[];
  accessControls: AccessControls;
  onReportingYearChange: (reportingYearId: string) => void;
  onShowChatBotChange: (...params: any) => void;
  onNotesChange: (...params: any) => void;
  showChatBot: boolean;
  notes: string;
  reportingYearId: string;
  intl?: { formatMessage: (...args: any) => string };
}

const switchSections: Partial<Record<keyof AccessControls, string>> = {
  comply: 'governance',
  data: 'data',
};

function AccountDetailsPage(props: AccountDetailsProps) {
  const intl = useIntl();

  return (
    <>
      <HeaderStyled size="lg">{props.heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Company Name</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            disabled={props.isSubmitted}
            onChange={props.handleChange}
            name="businessName"
            value={props.businessName.text}
            error={props.businessName.error}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Sector</LabelStyled>
          </LabelContainerStyled>
          <SectorSelector
            name="Sector__Select"
            isSubmitting={props.isSubmitted}
            value={props.selectedSector}
            onChange={(e: MultiSelectEvent) => {
              props.onSectorChange(e.target.select);
            }}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Child Accounts</LabelStyled>
          </LabelContainerStyled>
          <AccountsMultiSelect
            name="Child-Accounts__MultiSelect"
            multiValueColor="tertiary"
            onChange={(e: MultiSelectEvent) => {
              props.onChildAccountsChange(e.target.select);
            }}
            isSubmitting={props.isSubmitted}
            value={props.selectedChildAccounts}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled container item>
          {Object.keys(switchSections).map((sectionName) => {
            const sectionAccessControls = props.accessControls[sectionName];
            const sectionValue = switchSections[sectionName];
            const translationPath = `pages.configuration.account.sections.${sectionValue}`;
            return (
              <AccordionStyled key={sectionName}>
                <AccordionSummary expandIcon={<Icon icon="chevron-down" />} aria-controls="panel1a-content">
                  <FormattedMessage id={`${translationPath}.name`} />
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup aria-label="position" row>
                    {Object.keys(sectionAccessControls).map((controlName) => {
                      if (controlName === '__typename') return null;
                      const checked = _.isBoolean(sectionAccessControls[controlName])
                        ? sectionAccessControls[controlName]
                        : undefined;
                      return (
                        <FormControlLabel
                          key={controlName}
                          value="top"
                          control={
                            <Switch
                              id={`update-account-access-${controlName}`}
                              name={`${sectionName}.${controlName}`}
                              checked={Boolean(checked)}
                              onChange={props.onCheckboxClick}
                            />
                          }
                          label={intl.formatMessage({ id: `${translationPath}.${controlName}` })}
                          labelPlacement="top"
                        />
                      );
                    })}
                  </FormGroup>
                </AccordionDetails>
              </AccordionStyled>
            );
          })}
          <AccordionStyled key="other">
            <AccordionSummary expandIcon={<Icon icon="chevron-down" />} aria-controls="panel1a-content">
              <FormattedMessage id="pages.configuration.account.sections.other.name" />
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      name="showChatBot"
                      checked={_.isBoolean(props.showChatBot) ? props.showChatBot : undefined}
                      onChange={props.onShowChatBotChange}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.chatBot' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      name="dataTracker"
                      checked={
                        _.isBoolean(props.accessControls.dataTracker) ? props.accessControls.dataTracker : undefined
                      }
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.dataTracker' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch name="learn" checked={props.accessControls.learn} onChange={props.onCheckboxClick} />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.engage' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      data-cy="AccountDetails_accessControls_flows"
                      name="flows"
                      checked={_.isBoolean(props.accessControls.flows) ? props.accessControls.flows : undefined}
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.flows' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      name="intelligence"
                      checked={props.accessControls.intelligence}
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.intelligence' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch name="projects" checked={props.accessControls.projects} onChange={props.onCheckboxClick} />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.projects' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      data-cy="AccountDetails_accessControls_surveys"
                      name="surveys"
                      checked={_.isBoolean(props.accessControls.surveys) ? props.accessControls.surveys : undefined}
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.surveys' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch name="tasks" checked={props.accessControls.tasks} onChange={props.onCheckboxClick} />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.tasks' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      name="dromoUpload"
                      checked={
                        _.isBoolean(props.accessControls.dromoUpload) ? props.accessControls.dromoUpload : undefined
                      }
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.dromoUpload' })}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      name="v2Design"
                      checked={_.isBoolean(props.accessControls.v2Design) ? props.accessControls.v2Design : undefined}
                      onChange={props.onCheckboxClick}
                    />
                  }
                  label={intl.formatMessage({ id: 'pages.configuration.account.sections.other.v2Design' })}
                  labelPlacement="top"
                />
              </FormGroup>
            </AccordionDetails>
          </AccordionStyled>
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Reporting Year:</LabelStyled>
          </LabelContainerStyled>
          <ReportingYearSelect
            name="reportingYearId"
            onChange={props.onReportingYearChange}
            value={props.reportingYearId}
          />
          <TextStyled>
            Please select the reporting year relevant for your account. Your reporting year will affect the conversion
            factors used for calculating your carbon emissions as explained{' '}
            <StyledLink to="/configuration/carbonconversionfactors/location">here</StyledLink>. After configuring your
            account here you later wish to change you reporting year you will need to contact your Customer Support
            Manager, as Rio will not re-calculate carbon emissions.
          </TextStyled>
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Notes</LabelStyled>
          </LabelContainerStyled>
          <MarkdownViewEditor source={props.notes || 'Add Notes here.'} onSave={props.onNotesChange} />
        </ColStyled>
      </Row>
    </>
  );
}

export const AccountDetails = AccountDetailsPage;
