import { useMemo } from 'react';
import { Survey, SurveySubmissionStatus } from '@rio/rio-types';
import { countBy, keyBy } from 'lodash';
import { useTheme, PieChart } from '@rio/ui-components';
import { Options } from 'highcharts';
import { formatSubmissionStatus, getStatusColor as formatStatusColor } from '~/components/Surveys/v2/utils';
import { basePieChart } from './chartConfigs/basePieChart';

interface ChartProps {
  contributors: Survey['contributors'];
  submissions: Survey['submissions'];
}

export type SubmissionStatusCount = {
  [key in SurveySubmissionStatus]: number;
};

const formatDataName = (status: string) => status;

const countSubmissionStatuses = ({ contributors, submissions }: ChartProps): SubmissionStatusCount => {
  const submissionsByOwner = keyBy(submissions, 'owner.id');
  const statuses = contributors.map(({ account }) => submissionsByOwner[account.id]?.status);
  return countBy(statuses) as SubmissionStatusCount;
};

const customOptions: Options = {
  ...basePieChart,
  title: { text: '' },
  chart: {
    height: 350,
    reflow: true,
  },
  plotOptions: {
    pie: {
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
    },
  },
  legend: {
    align: 'center',
    layout: 'horizontal',
    verticalAlign: 'bottom',
    margin: 30,
    itemStyle: {
      fontSize: '1.27em',
      fontWeight: 'normal',
    },
  },
  tooltip: { enabled: false },
  credits: { enabled: false },
};
export function ResponseStatusChart({ contributors, submissions }: ChartProps) {
  const theme = useTheme();
  const submissionsCount = countSubmissionStatuses({ contributors, submissions });
  const data = useMemo(
    () =>
      Object.entries(submissionsCount).map(([status, count]) => ({
        y: count,
        name: formatDataName(formatSubmissionStatus(status as SurveySubmissionStatus)),
        color: formatStatusColor(theme, status as SurveySubmissionStatus),
      })),
    [submissionsCount, theme]
  );
  return <PieChart customOptions={customOptions} data={data} />;
}
