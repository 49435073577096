import { useCallback, useState } from 'react';
import { Col, Button, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContainerHeader from '../../../components/ContainerHeader';
import { useNotification } from '../../../hooks/useNotification';
import { CreateCategory } from './CreateCategory';
import { UpdateCategory } from './UpdateCategory';
import { useGetSurveyQuestionCategories } from './queries';
import { LoadFailed } from '../../../components/Errors';
import { GridOfCategories } from './GridOfCategories';
import { CategoryDetails } from './CategoryDetails';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ContainerHeaderStyled = styled(ContainerHeader)`
  justify-content: space-between;
`;

export const ConfigurationSurveyQuestionCategoriesContainer = () => {
  const { data, loading, error, refetch, networkStatus } = useGetSurveyQuestionCategories();
  const [createModalShown, setCreateModalShown] = useState(false);
  const [categoryEditing, setCategoryEditing] = useState<string | null>(null);
  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const handleEditButtonClick = useCallback((id: string) => setCategoryEditing(id), []);
  const closeEditModal = useCallback(() => setCategoryEditing(null), []);
  const { showNotification } = useNotification();

  const onCategoryCreated = useCallback(() => {
    toggleCreateModal();
    showNotification('Category created successfully', 'success');
  }, [toggleCreateModal, showNotification]);

  const onCategoryDeleted = useCallback(() => {
    closeEditModal();
    showNotification('Category deleted successfully', 'success');
  }, [closeEditModal, showNotification]);

  const onCategoryUpdated = useCallback(() => {
    closeEditModal();
    showNotification('Category updated successfully', 'success');
  }, [closeEditModal, showNotification]);

  const categoryEditingObject = categoryEditing
    ? data?.getSurveyQuestionCategories.find((c) => c.id === categoryEditing)
    : null;

  return (
    <Col container fullHeight>
      <ContainerHeaderStyled icon="briefcase" iconColor="primary" title="Question Categories">
        <Col span={4} container item>
          <ButtonStyled size="md" color="primary" onClick={toggleCreateModal} name="AddCategoryButton" inline>
            + Add Category
          </ButtonStyled>
        </Col>
      </ContainerHeaderStyled>
      {((loading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />
      )}
      {error && <LoadFailed error={error} retry={refetch} />}
      {!error && !loading && data?.getSurveyQuestionCategories && (
        <GridOfCategories rows={data?.getSurveyQuestionCategories} onEdit={handleEditButtonClick} />
      )}
      {categoryEditingObject && (
        <Modal size="md" onDismiss={closeEditModal} dismissable show>
          <UpdateCategory
            existingCategory={categoryEditingObject}
            onDeleted={onCategoryDeleted}
            onUpdated={onCategoryUpdated}
          />
        </Modal>
      )}
      {createModalShown && (
        <Modal size="md" onDismiss={toggleCreateModal} dismissable show>
          <CreateCategory onCompleted={onCategoryCreated} CategoryDetails={CategoryDetails} />
        </Modal>
      )}
    </Col>
  );
};
