import {
  QuestionUnion,
  AnswerReportingQuestionInput,
  BreakdownQuestion,
  SequenceRepeatableQuestionRow,
  ReportProgress,
  ReportSubProgressBreakdownItem,
  ManagementSystem,
} from '@rio/rio-types';
import _ from 'lodash';
import { createManagementSystemTree } from '../../utils';
import { DraftBreakdown, NumericQuestion, VerbalQuestion } from './types';
import { Nullable, ManagementSystemPage } from '../../types';

export function updateRecursively(allQuestions: QuestionUnion[], updatedQuestion: QuestionUnion): QuestionUnion[] {
  return allQuestions.map((q) => {
    if (updatedQuestion.id === q.id) {
      return updatedQuestion;
    }
    if (q.__typename === 'QuestionSequence') {
      return {
        ...q,
        questions: updateRecursively(q.questions, updatedQuestion),
      };
    }
    return q;
  });
}

export function getAnswerKeysAndValue(question: QuestionUnion): [keyof AnswerReportingQuestionInput, string, unknown] {
  let inputKey: keyof AnswerReportingQuestionInput;
  let responseKey: string;
  switch (question.__typename) {
    case 'YesNoQuestion':
      inputKey = 'yesNo';
      responseKey = 'reportedAnswer';
      break;
    case 'ChoiceQuestion':
      inputKey = 'choice';
      responseKey = 'reportedChoice';
      break;
    case 'MultipleChoiceQuestion':
      inputKey = 'multipleChoice';
      responseKey = 'reportedChoices';
      break;
    case 'DateQuestion':
      inputKey = 'date';
      responseKey = 'reportedDate';
      break;
    case 'FloatQuestion':
      inputKey = 'float';
      responseKey = 'reportedFloat';
      break;
    case 'IntegerQuestion':
      inputKey = 'int';
      responseKey = 'reportedInt';
      break;
    case 'OpenEndedQuestion':
      inputKey = 'openEnded';
      responseKey = 'reportedText';
      break;
    case 'OpenEndedRichQuestion':
      inputKey = 'openEndedRich';
      responseKey = 'reportedText';
      break;
    case 'BreakdownQuestion':
      inputKey = 'breakdown';
      responseKey = 'reportedBreakdown';
      break;
    case 'QuestionSequenceRepeatable':
      inputKey = 'sequenceRepeatable';
      responseKey = 'reportedRows';
      break;
    default:
      throw new Error(`${question.__typename} is invalid __typename `);
  }
  return [inputKey, responseKey, _.get(question, responseKey)];
}

export function mapQuestionToInput(question: QuestionUnion): AnswerReportingQuestionInput {
  const [inputType, , answer] = getAnswerKeysAndValue(question);
  return { [inputType]: [{ questionId: question.id, answer }] };
}

export function splitToFiguresAndPartitions(question: BreakdownQuestion): {
  figures: NumericQuestion[];
  partition: VerbalQuestion[];
} {
  const figures = question.questions.filter((i: QuestionUnion) =>
    ['IntegerQuestion', 'FloatQuestion'].includes(i.__typename!)
  ) as NumericQuestion[];
  const partition = question.questions.filter((i: QuestionUnion) =>
    ['ChoiceQuestion', 'MultipleChoiceQuestion', 'OpenEndedQuestion'].includes(i.__typename!)
  ) as VerbalQuestion[];
  return {
    figures,
    partition,
  };
}

export function createBreakdownDraft(question: BreakdownQuestion): DraftBreakdown {
  const { figures, partition } = splitToFiguresAndPartitions(question);

  return {
    figures: Array(figures.length).fill(null),
    partition: Array(partition.length).fill(null),
  };
}
export function mapSequenceRepeatableRowToSequence(
  row: Nullable<SequenceRepeatableQuestionRow>,
  sequence: QuestionUnion[]
): QuestionUnion[] {
  if (!row) {
    return sequence;
  }
  const answersByQuestionId = _.keyBy(row.answers, 'questionId');
  return sequence.map((q: QuestionUnion) => {
    const [, answerKey] = getAnswerKeysAndValue(q);
    const answerValue = answersByQuestionId[q.id]?.answer ? JSON.parse(answersByQuestionId[q.id].answer!) : null;
    return {
      ...q,
      [answerKey]: answerValue,
    };
  });
}

export function formatChaptersProgressBreakdown(
  reportProgress: Nullable<ReportProgress>,
  managementSystem: ManagementSystem
): Nullable<ReportProgress> {
  if (!reportProgress) {
    return null;
  }
  const { tree: chaptersIndex } = createManagementSystemTree(managementSystem);
  const breakdownIndex = _.keyBy(reportProgress.subProgressBreakdown, 'id');
  const subProgressBreakdown = _(chaptersIndex)
    .filter((c: ManagementSystemPage) => !!breakdownIndex[c.id])
    .map((c: ManagementSystemPage) => ({
      id: c.id,
      name: _.get(chaptersIndex, [c.id, 'tocTitle']),
      progress: _.round(_.get(breakdownIndex, [c.id, 'progress'], 0), 0),
    }))
    .value();
  return {
    ...reportProgress,
    subProgressBreakdown,
  };
}

interface GroupSubProgressBreakdownItem extends ReportSubProgressBreakdownItem {
  index?: number;
}
interface GroupReportProgress {
  totalProgress: number;
  subProgressBreakdown: GroupSubProgressBreakdownItem[];
}
interface ReportProgressBreakdown {
  id: string;
  title: string;
  progressBreakdown: GroupReportProgress;
}

export function formatParentChapterProgressBreakdown(
  reportProgress: Nullable<GroupReportProgress>,
  managementSystem: ManagementSystem
): Nullable<ReportProgressBreakdown[]> {
  if (!reportProgress) {
    return null;
  }
  const { tree: chaptersIndex } = createManagementSystemTree(managementSystem) as {
    tree: { [key: string]: ManagementSystemPage };
    documents: unknown;
  };
  const breakdownIndex = _.keyBy(reportProgress.subProgressBreakdown, 'id');
  const excludeHeadingsIds = managementSystem.chapters.map((c) => c.id);
  const progressBreakdown = _.orderBy(
    [
      ...reportProgress.subProgressBreakdown
        .reduce((acc, chapter) => {
          const parentId = excludeHeadingsIds.includes(chaptersIndex[chapter.id].parent.id)
            ? null
            : chaptersIndex[chapter.id].parent.id;
          const parentTitle = excludeHeadingsIds.includes(chaptersIndex[chapter.id].parent.id)
            ? null
            : chaptersIndex[chapter.id].parent.title;
          const parentIndex = excludeHeadingsIds.includes(chaptersIndex[chapter.id].parent.id)
            ? 0
            : chaptersIndex[chapter.id].parent.index;
          const foundItem = acc.get(parentId);
          if (foundItem) {
            foundItem.subProgressBreakdown.push({
              id: chapter.id,
              name: _.get(chaptersIndex, [chapter.id, 'tocTitle']),
              progress: _.round(_.get(breakdownIndex, [chapter.id, 'progress'], 0)),
              index: _.get(chaptersIndex, [chapter.id, 'index']),
            });
          } else {
            acc.set(parentId, {
              id: parentId,
              title: parentTitle,
              index: parentIndex,
              subProgressBreakdown: [
                {
                  id: chapter.id,
                  name: _.get(chaptersIndex, [chapter.id, 'tocTitle']),
                  progress: _.round(_.get(breakdownIndex, [chapter.id, 'progress'], 0)),
                  index: _.get(chaptersIndex, [chapter.id, 'index']),
                },
              ],
            });
          }
          return acc;
        }, new Map())
        .values(),
    ].map(
      ({ id, title, index, subProgressBreakdown }) =>
        ({
          id,
          title,
          index,
          progressBreakdown: {
            subProgressBreakdown: _.orderBy(subProgressBreakdown, ['index'], ['asc']),
            totalProgress: _.round(
              _.meanBy(subProgressBreakdown, (o: ReportSubProgressBreakdownItem) => o.progress),
              0
            ),
          },
        } as unknown as ReportProgressBreakdown)
    ),
    ['index'],
    ['asc']
  );
  return progressBreakdown;
}

export function formatQuestionFormulation(question: QuestionUnion) {
  return question.tableOfContentsNumber
    ? `**${question.tableOfContentsNumber} ${question.formulation}**`
    : question.formulation;
}

export function formatManagementSystemCode(
  framework: ManagementSystem,
  question: QuestionUnion,
  parent?: QuestionUnion
) {
  return `${framework.shortCode} ${question.tableOfContentsNumber || parent?.tableOfContentsNumber}`;
}
