import { Course } from '@rio/rio-types';
import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { GridOptions, GridReadyEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { ActionCell, ActionCellProps, ActionFunction } from './GridCell/ActionCell';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { capitalize, keyBy } from 'lodash';
import { boolYesNo, getAgRowId } from '../../../utils';
import { CATEGORIES } from './constants';
const categories = keyBy(CATEGORIES, 'value');

const RowStyled = styled(Row)`
  flex: 1;
`;

interface GridComponentProps {
  courses: Course[];
  onEdit: ActionFunction;
}

const typeValueFormatter = ({ value }: ValueFormatterParams) => capitalize(value);

const categoryFilterValueFormatter = ({ value }: ValueFormatterParams) => {
  return value === null ? '(Blanks)' : capitalize(categories[value].label);
};

const categoryValueFormatter = ({ value }: ValueFormatterParams) => {
  return value?.map((category: string) => ' ' + capitalize(categories[category].label));
};

function GridComponent({ courses, onEdit }: GridComponentProps) {
  const agGrid = useAgGrid();

  const components = {
    action: (props: ActionCellProps) => <ActionCell {...props} onEdit={onEdit} />,
  };

  const options: GridOptions = {
    rowHeight: 120,
    columnDefs: [
      {
        headerName: 'Title',
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep',
        },
        field: 'name',
      },
      {
        headerName: 'Content Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: typeValueFormatter,
        },
        valueFormatter: typeValueFormatter,
        field: 'type',
      },
      {
        headerName: 'Category',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: categoryFilterValueFormatter,
        },
        valueFormatter: categoryValueFormatter,
        field: 'metaData.categories',
        width: 300,
      },

      {
        headerName: 'Is Engage',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: boolYesNo,
        },
        valueGetter: ({ data }: ValueGetterParams) => {
          return data.isEngage === null ? false : data.isEngage;
        },
        valueFormatter: boolYesNo,
        field: 'isEngage',
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
      },
    ],

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        padding: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
    },

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <RowStyled container>
      <AgGrid
        rowData={courses}
        components={components}
        getRowId={getAgRowId('id')}
        onGridReady={(params: GridReadyEvent) => agGrid.onGridReady(params)}
        onFilterChanged={agGrid.onFilterChanged}
        onFirstDataRendered={agGrid.onFirstDataRendered}
        {...options}
      />
    </RowStyled>
  );
}

export const CoursesGrid = GridComponent;
