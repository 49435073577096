import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Select, Heading, Label, Col, Row, Button, TextInput } from 'rio-ui-components';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { useNotification } from '../../hooks/useNotification';
import { GET_LOCATION_META_OPTIONS, CREATE_LOCATION_META_OPTION } from './index.queries';
import { v4 as uuid } from 'uuid';
import { TENURE, BUILDINGTYPE, REGION } from '../../constants/locationMetaOptionTypes';

const FormStyled = styled.form`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonRow = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonCol = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const getOptionAccesser = (type) => {
  switch (type) {
    case REGION:
      return 'regions';
    case BUILDINGTYPE:
      return 'buildingTypes';
    default:
    case TENURE:
      return 'tenures';
  }
};

const LocationMetaOptionSelect = ({ optionType = TENURE, accountId, selectedOption, setSelectedOption }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [optionName, setOptionName] = useState('');
  const { showNotification } = useNotification();
  const currentAccountId = useCurrentAccountId();
  const [createOption] = useMutation(CREATE_LOCATION_META_OPTION, {
    onCompleted: () => {
      setShowCreate(false);
      setOptionName('');
      showNotification('Option has been created.');
    },
    onError: () => {
      showNotification('Something went wrong please try again later.', 'danger');
    }
  });
  const { data } = useQuery(GET_LOCATION_META_OPTIONS, { variables: { accountId: accountId || currentAccountId } });

  const options = [...(data?.getLocationMetadataOptionsForAccount[getOptionAccesser(optionType)] || [])];
  if (!options.find((op) => op.id === 'NEW')) {
    options.unshift({ name: 'Not Specified', id: null });
    options.unshift({ name: 'Create New...', id: 'NEW' });
  }

  const handleChange = (e) => {
    if (e.target.value === 'NEW') {
      setShowCreate(true);
    } else {
      setSelectedOption(e.target.value);
    }
  };

  const handleCreate = () => {
    if (!!optionName) {
      const newId = uuid();
      createOption({
        variables: {
          id: newId,
          accountId: accountId || currentAccountId,
          name: optionName,
          type: optionType
        },
        refetchQueries: ['GetLocationMetaOptions']
      });
      setSelectedOption(newId);
    } else {
      showNotification('Name is a required field.', 'danger');
    }
  };

  return (
    <>
      {showCreate && (
        <Modal show size="sm" onDismiss={() => setShowCreate(false)} span={6} height="auto" maxHeight="90vh">
          <FormStyled>
            <HeaderStyled size="lg">Create Option</HeaderStyled>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Name (Required)</LabelStyled>
                </LabelContainerStyled>
                <TextInput onChange={(e) => setOptionName(e.target.value)} name="name" value={optionName} box />
              </ColStyled>
            </Row>
            <ButtonRow container>
              <ButtonCol container item>
                <Button color="info" onClick={() => setShowCreate(false)}>
                  Cancel
                </Button>
              </ButtonCol>
              <ButtonCol container item>
                <Button type="button" onClick={() => handleCreate()}>
                  Create
                </Button>
              </ButtonCol>
            </ButtonRow>
          </FormStyled>
        </Modal>
      )}
      <Select
        options={options.map((op) => {
          return { label: op.name, value: op.id };
        })}
        value={selectedOption}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

export default LocationMetaOptionSelect;
