import styled from 'styled-components';
import { formatDate } from '../../utils';

export interface DueDateProps {
  deadlineDate: Date;
  color: string;
  fontWeight: string;
}

export interface StyledDueDateProps {
  color: string;
  fontWeight: string;
}

const StyledDueDate = styled.div<StyledDueDateProps>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
`;

export const DueDate = ({ deadlineDate, color, fontWeight }: DueDateProps) => (
  <StyledDueDate color={color} fontWeight={fontWeight}>
    Due {formatDate(deadlineDate, 'LL')}
  </StyledDueDate>
);
