import { useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/client';
import { Modal, Grid, styled, TextField, Button } from '@rio/ui-components';
import { useCurrentUserId } from '~/hooks/useCurrentUserId';
import { TYPES_LABELS, TYPES } from '~/constants/aspects';
import { CREATE_ASPECT_OPTION } from '~/containers/GovernanceContainer/Aspects/index.queries';
import { useUpdateCacheAccount } from '~/hooks/useUpdateCacheAccount';
import CREATE_DEPARTMENT from '../../graphql/mutations/account/CreateDepartment.mutation.graphql';

type AspectOption = {
  name: string;
  departmentId: string;
};

type Errors = {
  name: string | null;
};

const FormStyled = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  width: calc(50% - 12px);
`;

const GridWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const validateAspectOption = (aspectOption: AspectOption): Errors => {
  const errors: Errors = { name: null };
  if (!aspectOption.name || aspectOption.name.trim() === '') {
    errors.name = 'Name is required';
  }
  return errors;
};

export const CreateOptionModal = ({ onDismiss, onCompleted, onError, type, accountId }) => {
  const userId = useCurrentUserId();
  const updateCacheAccount = useUpdateCacheAccount();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [aspectOption, setAspectOption] = useState<AspectOption>({
    name: '',
    departmentId: uuid(),
  });
  const [errors, setErrors] = useState<Errors>({ name: null });
  const [createOption] = useMutation(TYPES.DEPARTMENT === type ? CREATE_DEPARTMENT : CREATE_ASPECT_OPTION, {
    onError,
    onCompleted: (response) => {
      type === TYPES.DEPARTMENT
        ? onCompleted(response, response.createDepartment[0])
        : onCompleted(response, response.createAspectOption);
    },
    update(cache, { data: { createDepartment } }) {
      updateCacheAccount(cache, createDepartment, accountId, userId);
    },
  });

  const handlerSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const errors = validateAspectOption(aspectOption);

      if (!!errors?.name?.length) {
        return setErrors(errors);
      }

      setIsSubmitting(true);
      const name = aspectOption.name.trim();

      const createOptionInput =
        TYPES.DEPARTMENT === type
          ? { name, accountId, departmentId: aspectOption.departmentId }
          : { name, type, accountId };

      await createOption({
        variables: {
          input: createOptionInput,
        },
      });
      setIsSubmitting(false);
      onDismiss();
    },
    [accountId, aspectOption, createOption, onDismiss, type]
  );

  const handleChangeInput = useCallback(
    (e) => {
      if (e.target.value) {
        setErrors({
          ...errors,
          name: null,
        });
      }
      setAspectOption({
        ...aspectOption,
        name: e.target.value,
        departmentId: uuid(),
      });
    },
    [aspectOption, errors]
  );

  return (
    <Modal onClose={onDismiss} title={`Create ${TYPES_LABELS[type]}`} grid={{ xs: 8, md: 6 }} open>
      <FormStyled name="create-option__form">
        <TextField
          label="Name"
          disabled={isSubmitting}
          type="text"
          onChange={handleChangeInput}
          name="name"
          value={aspectOption.name}
          error={!!errors?.name}
          helperText={errors?.name || ''}
        />
        <GridWrapper>
          <ButtonStyled variant="outlined" onClick={onDismiss}>
            Cancel
          </ButtonStyled>
          <ButtonStyled onClick={handlerSubmit} variant="filled">
            Create
          </ButtonStyled>
        </GridWrapper>
      </FormStyled>
    </Modal>
  );
};
