import { Scope } from '@rio/rio-types';
import { AspectFormState } from './types';
import { useSaveEvidence } from '../../../components/EvidenceNotes';

export function useSaveEvidenceNotes() {
  const saveEvidence = useSaveEvidence();
  let newDocumentIds: any[];

  return async (evidenceDocuments: any[], notes: string, aspect: AspectFormState) => {
    if (!evidenceDocuments.length && !notes) return [];
    if (!!evidenceDocuments.length) {
      const tags = aspect.scope === Scope.Tag ? aspect.tagIds?.map(({ value }: { value: string }) => value) : [];
      newDocumentIds = await saveEvidence(evidenceDocuments, tags, 'Aspects and Impacts');
    }
    return [
      {
        notes,
        documents: newDocumentIds,
      },
    ];
  };
}
