import React, { useState } from 'react';

import { Col, Search, ErrorMessage, ContainerError, Button } from 'rio-ui-components';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { FlowSubscription } from '@rio/rio-types';
import { useFlowSubscriptions } from '../../../hooks';

import { NoResults, ContainerHeader, ContainerLoadingIndicator } from '../../../components';

import { useNotification } from '../../../hooks';
import AccountRow from './AccountRow';
import CreateSubscriptionModal from './SubscriptionsSelectionContainer/CreateSubscriptionModal';
import { ToastColor } from '@rio/ui-components';

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const StyledSearch = styled(Search)`
  min-width: 150px;
`;

const ButtonStyled = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

interface NotificationType {
  colour: string;
  message: string;
}

const ConfigurationFlowsSubscriptionsContainer = () => {
  const location = useLocation();
  const [search, setSearch] = useState<string>('');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationType | null>(null);
  const { showNotification } = useNotification();
  const { data, loading, networkStatus, error, refetch } = useFlowSubscriptions();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const flowSubscriptions = data?.getFlowSubscriptions || [];

  const uniqueIds = new Set();
  const uniqueSubscriptions = flowSubscriptions?.filter((subscription: FlowSubscription) => {
    const duplicate = uniqueIds.has(subscription.account.id);
    uniqueIds.add(subscription.account.id);
    return !duplicate;
  });

  const filteredSubscriptions = uniqueSubscriptions.filter((subscription: FlowSubscription) =>
    subscription?.account?.name?.includes(search)
  );

  if (showNotification && notification?.message && notification?.colour) {
    showNotification(notification.message, notification.colour as ToastColor);
  }

  return (
    <Col name="ConfigurationFlowsSubscriptionsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationFlowsSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title="Accounts with Flow Subscriptions"
      >
        <StyledCol container item>
          <ButtonStyled
            name="ConfigurationLegislationContainer__Controls__Button--add"
            color="primary"
            inline
            component="button"
            onClick={() => setShowCreateModal(true)}
          >
            + Add Subscription
          </ButtonStyled>
          <StyledSearch
            name="ConfigurationFlowsSubscriptionsContainer__Controls__Search"
            value={search}
            onChange={onSearchChange}
            hideButton
          />
        </StyledCol>
      </ContainerHeader>

      {((loading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="ConfigurationFlowsSubscriptionsContainer__Loading" />
      )}
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError
              name="ConfigurationFlowsSubscriptionsContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetch}
            />
          )}
        </ErrorMessage>
      )}

      {!loading && !filteredSubscriptions.length && (
        <NoResults
          name="ConfigurationFlowsSubscriptionsContainer__NoResults"
          title="There are no results for this search."
        />
      )}

      {!loading && showCreateModal && (
        <CreateSubscriptionModal onDismiss={() => setShowCreateModal(false)} onComplete={setNotification} />
      )}

      {filteredSubscriptions.map((subscription) => {
        const account = subscription.account;
        const profileImage = typeof account.logoPath === 'string' ? account.logoPath : '';

        return (
          <AccountRow
            key={account.id}
            accountId={account.id}
            businessName={account.name}
            profileImage={profileImage}
            path={location.pathname}
          />
        );
      })}
    </Col>
  );
};

export default ConfigurationFlowsSubscriptionsContainer;
