import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NoAccessContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 99;
  margin -10px 0 0 -10px;
  box-shadow: inset 0px 0px 100px 40px rgba(0, 0, 0, 0.04);
`;

const FindOutMore = styled(Link)`
  padding: 15px;
  background: ${({ theme }) => theme.colors.primary.normal.background};
  border-radius: 100px;

  :hover {
    background: ${({ theme }) => theme.colors.primary.dark.background};
  }
`;

const NoAccessMessage = styled.div`
  padding: 10px;
  font-size: ${({ theme }) => theme.fonts.lg.size};
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
`;

const NoAccess = ({ contentSection }) => {
  return (
    <NoAccessContainer>
      <NoAccessMessage>You do not currently subscribe to Rio {contentSection}.</NoAccessMessage>
      <FindOutMore to="/contact">Find out more</FindOutMore>
    </NoAccessContainer>
  );
};

export default NoAccess;
