import React, { Component } from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';

import { Text, SelectTemplate, LoadingIndicator, Modal } from 'rio-ui-components';

import { DELETE_TRANSPOSITION } from './index.queries';
import { ConfirmAction } from '../../ConfirmAction/ConfirmAction';
import { DEFAULT_TRANSPOSITION_ID } from './constants';
import withPermissions from '../../../hocs/withPermissions';

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`;

const PickerContainer = styled.div`
  flex: ${(props) => (props.noflex ? '0 0 auto' : '1 1 auto')};
  display: flex;
  flex-direction: column;
`;

const TemplatePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background: ${({ theme }) => theme.colors.inputs.normal.background};
  margin-top: ${({ theme }) => theme.geometry.md.spacing};
`;

const StyledModal = styled(Modal)`
  z-index: 999 !important;
`;

class PickTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateSelected: false,
      showModal: false,
      idToBeDeleted: ''
    };
  }

  handleChange = (event) => {
    const { onTemplateChange = () => {} } = this.props;
    this.setState({ templateSelected: true });
    onTemplateChange(event);
  };

  deleteFunction = (id, deleteTransposition) => {
    const { onTemplateChange = () => {} } = this.props;
    deleteTransposition({ variables: { ID: id } });
    onTemplateChange({ target: { value: null } });
  };

  onDismiss = () => {
    this.setState({ showModal: false, idToBeDeleted: '' });
  };

  deleteModal = (deleteTransposition) => {
    const id = this.state.idToBeDeleted;
    return (
      <StyledModal name="PickTemplate__DeleteModal" size="sm" show dismissable onDismiss={this.onDismiss}>
        <ConfirmAction
          name="deleteTranspositions"
          notification={{ show: false }}
          headingText="Delete Transposition"
          bodyText="Are you sure you want to delete this transposition?"
          onConfirmAction={() => {
            this.deleteFunction(id, deleteTransposition);
            this.onDismiss();
          }}
          onCancelAction={this.onDismiss}
        />
      </StyledModal>
    );
  };

  onDelete = (el, id) => {
    el.preventDefault();
    this.setState({ showModal: true, idToBeDeleted: id });
  };

  render() {
    const { loading, error, transpositions, value, defaultValue, refetch, permissions } = this.props;
    const canDeleteValue = !!permissions.transposition.find((action) => action.startsWith('delete'));

    if (loading)
      return (
        <CenterContainer>
          <LoadingIndicator size="lg" />
        </CenterContainer>
      );
    if (error)
      return (
        <CenterContainer>
          <Text>error</Text>
        </CenterContainer>
      );

    const templateOptions = transpositions.map((v) => {
      return { label: v.transpositionName, value: v.id, hiddenIcon: v.isHalfHourly };
    });
    templateOptions.unshift({ value: DEFAULT_TRANSPOSITION_ID, label: 'Default', hiddenIcon: true });
    templateOptions.unshift({ value: defaultValue, label: 'New Template...', hiddenIcon: true });

    return (
      <PickerContainer noflex={this.state.templateSelected}>
        <Mutation mutation={DELETE_TRANSPOSITION} onCompleted={refetch}>
          {(deleteTransposition) => (
            <>
              {this.state.showModal && this.deleteModal(deleteTransposition)}
              <>
                <SelectTemplate
                  id="uploadContainer__SelectTemplate"
                  name="uploadContainer__SelectTemplate"
                  fileName={this.props.fileName}
                  templateLabel="Choose a template"
                  size="md"
                  value={value}
                  options={templateOptions}
                  onChange={this.handleChange}
                  onDelete={this.onDelete}
                  canDelete={canDeleteValue}
                />
              </>
            </>
          )}
        </Mutation>
        {!this.state.templateSelected && (
          <TemplatePlaceholder>
            <Text size="lg" weight="bold">
              Choose template...
            </Text>
          </TemplatePlaceholder>
        )}
      </PickerContainer>
    );
  }
}

export default withPermissions(PickTemplate);
