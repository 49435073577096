import { Fragment } from 'react';
import styled from 'styled-components';
import { QuestionUnion, ManagementSystem } from '@rio/rio-types';
import { PropertySheet } from 'rio-ui-components';
import { QuestionText } from './Elements';
import { RenderQuestionUnion } from './RenderQuestionUnion';
import { QuestionRow } from './Elements';
import { AnswerQuestionFunction } from './types';
import { formatQuestionFormulation } from './utils';

interface ReportingFrameworkFormProps {
  questions: QuestionUnion[];
  onAnswer: AnswerQuestionFunction;
  framework: ManagementSystem;
}

const PropertySheetStyled = styled(PropertySheet)`
  & .MuiInputLabel-root {
    z-index: 0; /* Fix of labels overlapping the modal window */
  }
`;

export function ReportingFrameworkForm({ framework, questions, onAnswer }: ReportingFrameworkFormProps) {
  return (
    <PropertySheetStyled container>
      {questions.map((question: QuestionUnion) => (
        <Fragment key={question.id}>
          <QuestionText source={formatQuestionFormulation(question)} />
          {['BreakdownQuestion', 'QuestionSequence'].includes(question.__typename!) ? (
            <RenderQuestionUnion framework={framework} question={question} onAnswer={onAnswer!} />
          ) : (
            <QuestionRow container item>
              <RenderQuestionUnion framework={framework} question={question} onAnswer={onAnswer!} />
            </QuestionRow>
          )}
        </Fragment>
      ))}
    </PropertySheetStyled>
  );
}
