import { styled, Text } from '@rio/ui-components';
import { SurveySubmissionStatus } from '@rio/rio-types';
import { getStatusColor } from './utils';

interface StatusSpanProps {
  status?: SurveySubmissionStatus;
}

export const StatusSpan = styled(Text)<StatusSpanProps>`
  position: relative;
  display: inline-block;
  padding-left: 20px;
  white-space: nowrap;
  color: ${(props) => props.theme.sys.color.onSurface};
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ status, theme }) => getStatusColor(theme, status)};
  }
`.withComponent('span');
