import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type ConfirmationDialogArgs = {
  title?: React.ReactNode | string;
  body?: React.ReactNode | string;
  agreeButtonCaption?: React.ReactNode | string;
  disagreeButtonCaption?: React.ReactNode | string;
  onAgree?: () => void;
  onDisagree?: () => void;
  onClose?: () => void;
};

const ConfirmationDialog = ({
  title = 'Confrimation required',
  body = '',
  agreeButtonCaption = 'Agree',
  disagreeButtonCaption = 'Disagree',
  onAgree,
  onDisagree,
  onClose,
}: ConfirmationDialogArgs) => {
  const [open, setOpen] = React.useState(true);

  const handleClickAgree = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
    if (onAgree) {
      onAgree();
    }
  };

  const handleClickDisagree = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
    if (onDisagree) {
      onDisagree();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClickDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickDisagree}>{disagreeButtonCaption}</Button>
        <Button onClick={handleClickAgree} autoFocus>
          {agreeButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
export default ConfirmationDialog;
