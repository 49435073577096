import { useCallback, useState } from 'react';
import { Errors, SurveysPlaceholder } from '~/components';
import { useCurrentAccountId } from '~/hooks';
import { DataAnomaliesGrid } from './grids';

const { PageFailed } = Errors;

export const Placeholder = () => (
  <SurveysPlaceholder
    title="Nothing to display..."
    description="If any validation or anomalies are found they will be displayed here."
    icon={['rio', 'clipboard-check-custom']}
  />
);

export const DataAnomalies = () => {
  const accountId = useCurrentAccountId();

  const [error, setError] = useState<Error | null>(null);

  const [placeholderShown, setPlaceholderShown] = useState<boolean>(false);

  const showPlaceholder = useCallback(() => setPlaceholderShown(true), []);

  const handleError = useCallback((err) => {
    setError(err);
  }, []);

  if (error) {
    return <PageFailed error={error} />;
  }
  if (placeholderShown) {
    return <Placeholder />;
  }
  return <DataAnomaliesGrid accountId={accountId} onError={handleError} showPlaceholder={showPlaceholder} />;
};
