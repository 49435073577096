import { gql } from "@apollo/client";

export const GET_TARIFFS_BY_ACCOUNT_ID = gql`
  query getMarketCarbonConversionFactors($accountId: ID) {
    getMarketCarbonConversionFactors(accountId: $accountId) {
      id
      tariff
    }
  }
`;
