import { SurveySubmissionStatus } from '@rio/rio-types';
import { useGetReceivedSurveysPage } from '~/containers/SurveysContainer/ReceivedSurveysContainer/queries';
import { getStatus, isWithinFiveDaysFromNow } from '~/components/Surveys';

const completeStatuses = [
  SurveySubmissionStatus.ReadyForReview,
  SurveySubmissionStatus.Approved,
  SurveySubmissionStatus.InProgress,
];

export const countIncompleteSurveys = (rows) =>
  rows?.filter((item) => {
    const { isOverDue } = getStatus(item.deadlineDate);
    const deadlineWithinFiveDays = isWithinFiveDaysFromNow(item.deadlineDate);

    return (
      (!isOverDue && deadlineWithinFiveDays) ||
      (!isOverDue && (!item.submission || !completeStatuses.includes(item.submission.status)))
    );
  })?.length;

export function useGetReceivedSurveysCount(accountId: string, userId: string) {
  const variables = {
    accountId,
    userId,
  };

  const { data: notificationSurveysData, loading } = useGetReceivedSurveysPage(variables);

  const incompleteSurveysCount = countIncompleteSurveys(notificationSurveysData?.getReceivedSurveysPage?.rows);

  return {
    count: incompleteSurveysCount,
    loading,
  };
}
