import React from 'react';
import { UserPermissions } from '@rio/rio-types';
import { BatchStatus } from '~/types';
import { useIsV2, usePermissions, useUserToken } from '~/hooks';
import { mapBatchStatus } from '~/containers/DataContainer/UploadContainer/utils';
import { styled } from '@rio/ui-components';
import { useTheme as useV1Theme } from '@mui/material-next/styles';
import { useTheme as useV2Theme } from '@rio/ui-components';

const GridCellContainer = styled('div')`
  display: inline-flex;
  place-items: center;
`;

const IconContainer = styled('span')`
  display: inline-flex;
  place-items: center;
  margin: 0 10px;
`;

export type StatusProps = {
  icon: string;
  iconColor: string;
  linkText: string;
  onClick?: (id: string) => void;
};

export interface StatusCellProps {
  data: {
    isLegacy: boolean;
    id: string;
    status: string;
    userId: string;
  };
  download: {
    [id: string]: boolean;
  };
  defineStatusProps: (
    batchStatus: BatchStatus,
    theme: any,
    isLegacy: boolean,
    onActionClick: (id: string) => void,
    onDataClick: (id: string) => void,
    onErrorClick: (id: string) => void
  ) => StatusProps;
  statusLink: (
    batchStatus: BatchStatus,
    isLegacy: boolean,
    onErrorClick: () => void,
    onDataClick: () => void,
    onActionClick: () => void,
    statusProps: StatusProps,
    download: boolean,
    permissions: UserPermissions,
    isOwnResource: boolean,
    isTextLink: boolean
  ) => React.ReactNode;
  onErrorClick: (id: string) => void;
  onDataClick: (id: string) => void;
  onActionClick: (id: string) => void;
  done: React.ReactNode;
  pending: React.ReactNode;
  abandoned: React.ReactNode;
  failed: React.ReactNode;
  actionRequired: React.ReactNode;
}

export const StatusCell = ({
  data,
  download,
  defineStatusProps,
  done,
  pending,
  abandoned,
  failed,
  actionRequired,
  statusLink,
  onActionClick,
  onDataClick,
  onErrorClick,
}: StatusCellProps) => {
  const isV2 = useIsV2();
  const v1Theme = useV1Theme();
  const v2Theme = useV2Theme();
  const theme = isV2 ? v2Theme : v1Theme;
  const { token } = useUserToken();
  const permissions = usePermissions();
  const isOwnResource = data?.userId?.toLowerCase() === token.sub;
  const batchStatus = mapBatchStatus(data.status);
  const statusProps = defineStatusProps(batchStatus, theme, data.isLegacy, onErrorClick, onDataClick, onActionClick);

  return (
    <GridCellContainer>
      <IconContainer>
        {batchStatus === BatchStatus.DONE && done}
        {batchStatus === BatchStatus.ACTION_REQUIRED && actionRequired}
        {batchStatus === BatchStatus.ABANDONED && abandoned}
        {batchStatus === BatchStatus.FAILED && failed}
        {batchStatus === BatchStatus.PENDING && pending}
      </IconContainer>
      {statusLink(
        batchStatus,
        data.isLegacy,
        () => onErrorClick(data.id),
        () => onDataClick(data.id),
        () => onActionClick(data.id),
        statusProps,
        download?.[data.id],
        permissions,
        isOwnResource,
        batchStatus === BatchStatus.ACTION_REQUIRED // isTextLink props.
      )}
    </GridCellContainer>
  );
};
