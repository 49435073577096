import { styled, Text, Icons } from '@rio/ui-components';

const ContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const IconBackgroundStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.sys.color.primaryContainer};
  margin-bottom: 24px;
`;

const CheckIconStyled = styled(Icons.Check)`
  width: 40px;
  color: ${(props) => props.theme.sys.color.primary};
`;

const TitleStyled = styled(Text)`
  margin-bottom: 12px;
  text-align: center;
`;

const BodyTextStyled = styled(Text)`
  text-align: center;
`;

export const MissingDataPlaceholder = () => {
  return (
    <ContainerStyled>
      <IconBackgroundStyled>
        <CheckIconStyled fontSize="large" />
      </IconBackgroundStyled>
      <TitleStyled typescale="title" size="large">
        Nothing to display...
      </TitleStyled>
      <BodyTextStyled typescale="body" size="large">
        If any missing pieces of data are found they will be displayed here
      </BodyTextStyled>
    </ContainerStyled>
  );
};
