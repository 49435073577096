import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import GovernanceAreaTile from '../GovernanceAreaTile';

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const GovernanceAreaGrid = ({ items, fullAccess }) => {
  return (
    <TileContainer>
      {items.map((item) => {
        if (item.comingSoon || !fullAccess[item.accessIndex]) {
          return (
            <Link
              key={item.id}
              name={`GovernanceGrid__Link__${item.title}`}
              to={item.accessIndex === 'systems' ? '/upgrade/compliance' : '/contact'}
            >
              <GovernanceAreaTile
                key={item.id}
                title={item.title}
                description={item.description}
                icon={item.icon}
                iconColor={item.iconColor}
                comingSoon={item.comingSoon}
                fullAccess={fullAccess[item.accessIndex]}
              />
            </Link>
          );
        } else {
          return (
            <Link name={`GovernanceGrid__Link__${item.title}`} key={item.id} to={item.link}>
              <GovernanceAreaTile
                title={item.title}
                description={item.description}
                icon={item.icon}
                iconColor={item.iconColor}
                comingSoon={item.comingSoon}
                fullAccess={fullAccess[item.accessIndex]}
              />
            </Link>
          );
        }
      })}
    </TileContainer>
  );
};

export default GovernanceAreaGrid;
