import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Modal, PropertySheet } from 'rio-ui-components';
import { CREATE_EVALUATION } from './index.queries';
import { v4 as uuid } from 'uuid';
import { labels, ASSESSMENT, REACTION } from '../../../constants/evaluationTypes';
import { List } from '../../../components/List';
import { GET_ALL_EVALUATIONS } from './index.queries';
import { usePermissions } from '../../../hooks/usePermissions';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';

function validateEvaluation(evaluation) {
  const errors = {};
  if (!evaluation.name) {
    errors.name = 'Name is required';
  }
  if (!evaluation.description) {
    errors.description = 'Description is required';
  }
  if (!evaluation.scale) {
    errors.scale = 'Please select minimum and maximum value of the scale';
  }
  if (parseFloat(evaluation.scale.min, 10) < 0) {
    errors.scale = errors.scale || {};
    errors.scale.min = 'Minimum value of scale should be greater or equal to 0';
  }
  if (parseFloat(evaluation.scale.max, 10) <= 0) {
    errors.scale = errors.scale || {};
    errors.scale.max = 'Maximum value of scale should be greater than 0';
  }
  if (!evaluation.questions.length) {
    errors.questions = 'At least one question should be present';
  }
  return errors;
}
const defaultState = {
  name: '',
  description: '',
  type: ASSESSMENT,
  scale: {
    min: 1,
    max: 5
  },
  questions: []
};
export function EvaluationModal({ defaultEvaluation, heading, onDismiss, onCompleted, onError }) {
  const [evaluation, setEvaluation] = useState(defaultEvaluation || defaultState);
  const [errors, setErrors] = useState({});
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const [createEvaluation, { loading }] = useMutation(CREATE_EVALUATION, {
    onCompleted,
    onError,
    update: (cache, result) => {
      const newEvaluation = result.data.createEvaluation;
      const { getAllEvaluations } = cache.readQuery({
        query: GET_ALL_EVALUATIONS,
        variables: { accountId: permissions.learn.includes('createEvaluationAll') ? null : accountId }
      });
      if (!getAllEvaluations.find((e) => e.id === newEvaluation.id)) {
        cache.writeQuery({
          query: GET_ALL_EVALUATIONS,
          variables: { accountId: permissions.learn.includes('createEvaluationAll') ? null : accountId },
          data: {
            getAllEvaluations: getAllEvaluations.concat([newEvaluation])
          }
        });
      }
    }
  });
  return (
    <Modal onDismiss={onDismiss} size="md" show>
      <PropertySheet>
        <PropertySheet.HeaderRow>{heading}</PropertySheet.HeaderRow>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            label="Name"
            value={evaluation.name}
            error={errors.name}
            disabled={loading}
            onChange={(e) => {
              setEvaluation({ ...evaluation, name: e.target.value });
            }}
          />
        </PropertySheet.Row>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            label="Description"
            value={evaluation.description}
            error={errors.description}
            disabled={loading}
            onChange={(e) => {
              setEvaluation({ ...evaluation, description: e.target.value });
            }}
          />
        </PropertySheet.Row>
        <PropertySheet.Row>
          <PropertySheet.SelectColumn
            label="Type"
            value={evaluation.type}
            error={errors.type}
            disabled={loading}
            onChange={(e) => {
              setEvaluation({
                ...evaluation,
                type: e.target.select.value
              });
            }}
            options={[
              {
                value: ASSESSMENT,
                label: labels[ASSESSMENT]
              },
              {
                value: REACTION,
                label: labels[REACTION]
              }
            ]}
          />
        </PropertySheet.Row>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            label="Minimum score"
            value={evaluation.scale.min}
            error={errors.scale?.min}
            disabled={loading}
            onChange={(e) => {
              setEvaluation({
                ...evaluation,
                scale: {
                  ...evaluation.scale,
                  min: e.target.value
                }
              });
            }}
          />
          <PropertySheet.TextInputColumn
            label="Maximum score"
            value={evaluation.scale.max}
            error={errors.scale?.max}
            disabled={loading}
            onChange={(e) => {
              setEvaluation({
                ...evaluation,
                scale: {
                  ...evaluation.scale,
                  max: e.target.value
                }
              });
            }}
          />
        </PropertySheet.Row>
        <List
          label="Questions"
          placeholder="New question"
          items={evaluation.questions}
          error={errors.questions}
          onChange={(questions) => {
            setEvaluation({
              ...evaluation,
              questions
            });
          }}
        />
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn
            color="info"
            disabled={loading}
            onClick={() => {
              onDismiss();
            }}
          >
            Cancel
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            onClick={() => {
              const errors = validateEvaluation(evaluation);
              if (Object.keys(errors).length) {
                return setErrors(errors);
              }
              const input = {
                ...evaluation,
                accountId: permissions.learn.includes('createEvaluationAll') ? null : accountId
              };
              if (!evaluation.id) {
                input.id = uuid();
              }
              createEvaluation({
                variables: {
                  input
                }
              });
            }}
            disabled={loading}
          >
            Save
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Modal>
  );
}
