import { Select } from '@rio/ui-components';
import _ from 'lodash';
import { useGetReceivedSurveysPageFilter } from './queries/useGetReceivedSurveysPageFilter';

interface StatusFilterProps {
  accountId: string;
  status: string;
  setStatus: (value: string) => void;
}

const templateToOption = (survey) => ({
  value: survey.value || null,
  label: survey.label || '',
});

export const StatusFilterV2 = ({ accountId, status, setStatus }: StatusFilterProps) => {
  const { data, error } = useGetReceivedSurveysPageFilter({ accountId, field: 'submissionStatus' });

  const options = (data?.getReceivedSurveysPageFilter ?? []).map(templateToOption);
  const changeOption = options.map((el) => (el.value === null ? { ...el, value: _.snakeCase(el.label) } : el));
  const selectedValue = changeOption.find((opt) => opt.value === status);

  return (
    <Select
      id="StatusFilter__MultiSelect"
      name="received-surveys-select-checkbox"
      value={selectedValue}
      onChange={(e) => setStatus(e.value)}
      label="Survey statuses"
      error={!!error}
      options={changeOption}
    />
  );
};
