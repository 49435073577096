import { Fragment, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import HubspotTrackerWrapper from './HubspotTrackerWrapper';

interface HubspotTrackerProps {
  children: ReactNode;
}

const HideHubspotGlobalStyle = createGlobalStyle`
  div#hubspot-messages-iframe-container iframe {
    display: none !important;
  }
`;

const excludedPathnames = ['engage'];

const HubspotTracker = ({ children }: HubspotTrackerProps) => {
  const location = useLocation();
  const hideHubspotWrapper = excludedPathnames.some((pathname) => location.pathname.includes(pathname));

  useLayoutEffect(() => {
    const hubspotScript = document.getElementById('hs-script-loader');
    const container = document.getElementById('hubspot-messages-iframe-container');

    if (hubspotScript && hideHubspotWrapper) {
      document.head.removeChild(hubspotScript);
    }

    if (container && hideHubspotWrapper) {
      container.remove();
    }
  }, [hideHubspotWrapper]);

  return hideHubspotWrapper ? (
    <Fragment>
      <HideHubspotGlobalStyle />
      {children}
    </Fragment>
  ) : (
    <HubspotTrackerWrapper>{children}</HubspotTrackerWrapper>
  );
};

export default HubspotTracker;
