import { useQuery, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  Col,
  Row,
  Label,
  TextInput,
  Button,
  InlineError,
  Heading,
  Notification,
  Checkbox,
  ErrorMessage,
  MultiSelect,
  LoadingIndicator,
} from 'rio-ui-components';
import { isPostalCode } from 'validator';
import { isPhoneNumber } from '../../../utils/validation';
import withPermissions from '../../../hocs/withPermissions';
import { CREATE_LOCATION, GET_TAGS_BY_ACCOUNT_ID } from './index.queries';
import AddressPropertySheet from '../../../components/AddressPropertySheet';
import LocationMetadata from './LocationMetadata';
import { useCountries } from '../../../hooks/useCountries';
import { validateField, getTagsToBeSaved } from './utils';
import { toIsoIfDate } from '~/utils';
import { get } from 'lodash';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const InlineErrorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  padding-top: ${(props) => props.theme.geometry.md.spacing};
`;

const CreateLocation = (props) => {
  const {
    accountId,
    onSuccess,
    refreshGrid,
    onUpdate,
    showModal,
    locationPoints: prefilledLocationPoints = {
      wasteOut: false,
      wasteIn: false,
    },
  } = props;

  const { countryList, defaultCountry } = useCountries();

  const defaultState = {
    hasError: false,
    isSuccess: false,

    values: {
      locationId: { text: uuid() },
      locationName: { text: '', error: '' },
      address1: { text: '', error: '' },
      address2: { text: '', error: '' },
      address3: { text: '', error: '' },
      city: { text: '', error: '' },
      postcode: { text: '', error: '' },
      phone: { text: '', error: '' },
      selectCountry: null,
      locationPoints: {
        wasteOut: { checked: get(prefilledLocationPoints, 'wasteOut', false) },
        wasteIn: { checked: get(prefilledLocationPoints, 'wasteIn', false) },
      },
      tags: [],
      aliases: [],
    },
    notification: { message: '', color: 'success', id: null },
    isSubmitted: false,
  };

  const [state, setState] = useState(defaultState);

  const [metadataOptions, setMetadataOptions] = useState({
    code: '',
    tenure: null,
    region: null,
    buildingType: null,
    floorSpace: null,
    startDate: null,
    endDate: null,
    leaseRenewalDate: null,
    notes: '',
    departments: [],
  });

  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: null, color: null },
    }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      values: { ...prevState.values, [e.target.name]: { text: e.target.value, error: null } },
    }));
  };

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      values: { ...prevState.values, [e.target.name]: { value: e.target.select.value, label: e.target.select.label } },
    }));
  };

  const onTagChange = (event) => {
    const tagArray = event.target.select;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        tags: tagArray,
      },
    }));
  };

  const onCreateComplete = (data) => {
    setState((prevState) => ({
      ...prevState,
      createdAccountId: data.createLocationWithLocationPoints.id,
    }));
    // TODO this was activated on this.setState callback previously, check if it works properly
    onSuccess(
      `The location ${data.createLocationWithLocationPoints.name} has been created!`,
      'success',
      showModal
        ? data.createLocationWithLocationPoints.id
        : [data.createLocationWithLocationPoints.id, data.createLocationWithLocationPoints.address.id],
      data.createLocationWithLocationPoints
    );

    refreshGrid();
  };

  const onCreateError = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: `Something went wrong. If the problem persists, contact support.`, color: 'danger' },
      hasError: true,
      isSuccess: false,
      isSubmitted: false,
    }));
  };

  const onCheckboxClick = (data) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...state.values,
        locationPoints: {
          ...state.values.locationPoints,
          [data.target.name]: { checked: data.target.checked },
        },
      },
    }));
  };

  const update = (cache, data) => {
    if (onUpdate) {
      onUpdate(cache, data);
    }
  };

  const SelectLoadingIndicator = () => {
    return <LoadingIndicator name="tag-location-Loading" size="xxs" />;
  };

  const { loading, error, data } = useQuery(GET_TAGS_BY_ACCOUNT_ID, { variables: { accountId: accountId } });
  const options =
    !loading && !error ? data.getTagsByAccountId.map((tag) => ({ label: tag.tagName, value: tag.id })) : [];

  const isCheckboxVisible = !(
    get(prefilledLocationPoints, 'wasteIn', false) || get(prefilledLocationPoints, 'wasteOut', false)
  );

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onError: onCreateError,
    onCompleted: onCreateComplete,
    update: update,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const {
        values: { locationName, address1, city, postcode, phone },
      } = state;
      const locationNameError = validateField(locationName.text, 'Location Name');
      const address1Error = validateField(address1.text, 'Address 1');
      const cityError = validateField(city.text, 'City');
      const postcodeError =
        validateField(postcode.text, 'Postcode') ||
        (isPostalCode(postcode.text, 'any') ? null : 'Please enter a valid postcode.');
      const phoneError = !phone.text
        ? null
        : isPhoneNumber(phone.text)
        ? null
        : 'Please enter a valid phone number using only characters 0 - 9';

      if (locationNameError || address1Error || cityError || postcodeError || phoneError) {
        setState((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            locationName: {
              ...prevState.values.locationName,
              error: locationNameError,
            },
            address1: {
              ...prevState.values.address1,
              error: address1Error,
            },
            city: {
              ...prevState.values.city,
              error: cityError,
            },
            postcode: {
              ...prevState.values.postcode,
              error: postcodeError,
            },
            phone: {
              ...prevState.values.phone,
              error: phoneError,
            },
          },
        }));
        return;
      }

      createLocation({
        variables: {
          locId: uuid(),
          locName: state.values.locationName.text,
          accId: accountId,
          add1: state.values.address1.text || null,
          add2: state.values.address2.text || null,
          add3: state.values.address3.text || null,
          city: state.values.city.text,
          post: state.values.postcode.text,
          phone: state.values.phone.text ? state.values.phone.text.trim() : null,
          wasteOut: state.values.locationPoints.wasteOut.checked ? uuid() : null,
          wasteIn: state.values.locationPoints.wasteIn.checked ? uuid() : null,
          tags: getTagsToBeSaved(state.values.tags),
          aliases: getTagsToBeSaved(state.values.tags),
          countryId: state.values.selectCountry?.value || defaultCountry.value,
          /* Metadata fields */
          ...metadataOptions,
          departments: metadataOptions?.departments.map((dep) => dep.id) || [],
          startDate: toIsoIfDate(metadataOptions?.startDate),
          endDate: toIsoIfDate(metadataOptions?.endDate),
          leaseRenewalDate: toIsoIfDate(metadataOptions?.leaseRenewalDate),
        },
      });
      setState((prevState) => ({
        ...prevState,
        isSubmitted: true,
      }));
    },
    [accountId, defaultCountry?.value, metadataOptions, state, createLocation]
  );

  return (
    <Container name="create-location">
      {state.notification.message && (
        <Notification
          name="create-location__notification"
          LocationId={state.createdAccountId}
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}

      <form name="create-location__form">
        <HeaderStyled name="create-location__heading" size="lg">
          Add New Location
        </HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Location Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              disabled={state.isSubmitted}
              onChange={handleChange}
              value={state.values.locationName.text}
              error={state.values.locationName.error}
              name="locationName"
              box
            />
          </ColStyled>
        </Row>
        <AddressPropertySheet
          id={'LocationAddressPropertySheet'}
          name={'LocationAddressPropertySheet'}
          address1={state.values.address1}
          address2={state.values.address2}
          address3={state.values.address3}
          city={state.values.city}
          postcode={state.values.postcode}
          phone={state.values.phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          disabled={state.isSubmitted}
          countryList={countryList || []}
          selectCountry={state.values.selectCountry || defaultCountry}
        />
        {isCheckboxVisible && (
          <Row container align="between">
            <ColStyled container item span={6}>
              <LabelStyled id="create-location-wasteout-label" name="create-location-wasteout-label">
                Waste Out
              </LabelStyled>
              <Checkbox
                disabled={state.isSubmitted}
                id="create-location-wasteout"
                name="wasteOut"
                onChange={onCheckboxClick}
                checked={state.values.locationPoints.wasteOut.checked}
              />
            </ColStyled>
            <ColStyled container item span={6}>
              <LabelStyled id="create-location-wastein-label" name="create-location-wastein-label">
                Waste In
              </LabelStyled>
              <Checkbox
                disabled={state.isSubmitted}
                id="create-location-wastein"
                name="wasteIn"
                onChange={onCheckboxClick}
                checked={state.values.locationPoints.wasteIn.checked}
              />
            </ColStyled>
          </Row>
        )}
        <Row container align="between">
          <ColStyled container item span={12}>
            <LabelStyled id="create-location-tags-label" name="create-location-tags-label">
              Tags (Optional)
              {error ? (
                <ErrorMessage error={error}>
                  {({ icon }) => (
                    <InlineErrorContainer>
                      <InlineError name="location-tag__error" icon={icon} title="Tags failed to load" />
                    </InlineErrorContainer>
                  )}
                </ErrorMessage>
              ) : (
                <MultiSelect
                  id="create-location-tag-multiselection"
                  name="create-location-tag-multiselection"
                  pill={false}
                  isLoading={loading}
                  components={{ LoadingIndicator: SelectLoadingIndicator }}
                  onChange={onTagChange}
                  isDisabled={loading || error}
                  multiValueColor="tertiary"
                  options={options}
                  value={state.values.tags}
                />
              )}
            </LabelStyled>
          </ColStyled>
        </Row>
        <LocationMetadata
          accountId={accountId}
          selectedOptions={metadataOptions}
          setSelectedOptions={setMetadataOptions}
        />
        <Row container align="between">
          <ColStyled item>
            <Button
              name="create-location--create"
              color="success"
              type="button"
              onClick={handleSubmit}
              disabled={!state.values.locationName.text || state.isSubmitted}
            >
              Create Location
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
};

export default withPermissions(CreateLocation);
