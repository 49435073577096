import { useMutation, MutationHookOptions } from '@apollo/client';
import { MutationDeleteAspectArgs } from '@rio/rio-types';
import { useNotification } from '../../../hooks';
import { DELETE_ASPECT } from './index.queries';
import { DeleteAspectResponse } from './types';

export function useDeleteAspect(options: MutationHookOptions<DeleteAspectResponse, MutationDeleteAspectArgs> = {}) {
  const { showNotification } = useNotification();
  return useMutation<DeleteAspectResponse, MutationDeleteAspectArgs>(DELETE_ASPECT, {
    ...options,
    onCompleted: (...args) => {
      showNotification('Aspect has been deleted', 'success');
      if (options.onCompleted) {
        options.onCompleted(...args);
      }
    },
    onError: (error) => {
      showNotification(error.message, 'danger');
      if (options.onError) {
        options.onError(error);
      }
    },
  });
}
