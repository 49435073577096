import { LearnBundle } from '@rio/rio-types';
import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { GridOptions, GridReadyEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { ActionCell, ActionCellProps, ActionFunction } from './GridCell/ActionCell';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { boolYesNo, getAgRowId } from '../../../utils';

const RowStyled = styled(Row)`
  flex: 1;
`;

interface GridComponentProps {
  bundles: LearnBundle[];
  onEdit: ActionFunction;
}

const contentTypeValueFormatter = ({ value }: ValueFormatterParams) => {
  // check if value is a string with boolean value like 'false' to avoid unexpected output
  if (value === 'true') return 'Restricted';
  if (value === 'false') return 'Free';
  return value ? 'Restricted' : 'Free';
};

function GridComponent({ bundles, onEdit }: GridComponentProps) {
  const agGrid = useAgGrid();

  const components = {
    action: (props: ActionCellProps) => <ActionCell {...props} onEdit={onEdit} />,
  };

  const options: GridOptions = {
    rowHeight: 120,
    columnDefs: [
      {
        headerName: 'Unique Identifier',
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep',
        },
        field: 'id',
        width: 300,
        hide: true,
      },
      {
        headerName: 'Title',
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep',
        },
        field: 'title',
      },
      {
        headerName: 'Content Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: contentTypeValueFormatter,
        },
        valueFormatter: contentTypeValueFormatter,
        field: 'restricted',
      },
      {
        headerName: 'Is Engage',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: boolYesNo,
        },
        valueGetter: ({ data }: ValueGetterParams) => {
          return data.isEngage === null ? false : data.isEngage;
        },
        valueFormatter: boolYesNo,
        field: 'isEngage',
      },
      {
        headerName: 'Actions',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
      },
    ],

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        padding: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
    },

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <RowStyled container>
      <AgGrid
        rowData={bundles}
        components={components}
        getRowId={getAgRowId('id')}
        onGridReady={(params: GridReadyEvent) => agGrid.onGridReady(params)}
        onFilterChanged={agGrid.onFilterChanged}
        onFirstDataRendered={agGrid.onFirstDataRendered}
        {...options}
      />
    </RowStyled>
  );
}

export const BundlesGrid = GridComponent;
