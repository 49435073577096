import { Grid, styled, AgGrid } from '@rio/ui-components';

export const ButtonGridColumn = styled(Grid)`
  & > button,
  & > a {
    width: 100%;
  }
`;

export const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 216px); // header + page title height

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: calc(100vh - 316px); // header + page title height in lg
  }
`;
