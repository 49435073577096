import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { WasteStream } from '@rio/rio-types';
import { Select, Modal, ToastColor, OptionsProps } from '@rio/ui-components';
import { CreateWasteStream } from '~/containers/ConfigurationContainer/ConfigurationWasteStreamContainer/V2';
import { CREATE_NEW_OPTION, CREATE_NEW_OPTION_ID } from '~/utils';
import { usePermissions, useNotification, useCurrentAccountId, usePrevious } from '~/hooks';
import { GET_WASTE_STREAMS_BY_ACCOUNT_ID } from './index.queries';

interface WasteStreamSelectProps {
  value?: string;
  onChange: (wasteStream: OptionsProps) => void;
  createNew?: boolean;
  createNewModalSize?: number;
  error?: string;
  label?: string;
}

export const wasteStreamToSelectOptions = (wasteStream: WasteStream) => {
  return {
    value: wasteStream?.id,
    label: wasteStream?.name,
  };
};

export const WasteStreamSelectV2 = ({ value, onChange, createNew, label, error }: WasteStreamSelectProps) => {
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const [createModalShown, setCreateModalShown] = useState(false);

  const {
    data,
    loading,
    refetch,
    error: queryError,
  } = useQuery(GET_WASTE_STREAMS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
      value,
    },
  });

  const newOption =
    createNew && permissions.data.find((action: string) => action.startsWith('createWasteStream'))
      ? CREATE_NEW_OPTION
      : '';

  const options: OptionsProps[] = [
    newOption,
    ...(data?.getWasteStreamsByAccountId?.wasteStreams ?? []).map(wasteStreamToSelectOptions),
  ];

  const prevOptions: OptionsProps[] = usePrevious(options) || [];
  const currentError = queryError?.message || error;

  const handleCreateSuccess = useCallback(
    async (message: string, color: string, id: string, wasteStream: OptionsProps) => {
      await refetch({
        accountId,
        value: wasteStream.value,
      });
      showNotification(message, color as ToastColor);
      setCreateModalShown(false);
      onChange(wasteStream);
    },
    [accountId, onChange, refetch, showNotification]
  );

  const changeHandler = useCallback(
    (e) => {
      if (e.value === CREATE_NEW_OPTION_ID) {
        setCreateModalShown(true);
      } else {
        const wasteStream = data?.getWasteStreamsByAccountId?.wasteStreams?.find(
          (ws: WasteStream) => ws.id === e.value
        );

        if (wasteStream) {
          const select = wasteStreamToSelectOptions(wasteStream);
          onChange(select);
        }
      }
    },
    [data?.getWasteStreamsByAccountId?.wasteStreams, onChange]
  );

  return (
    <>
      <Select
        key={Number(options.length !== prevOptions?.length)}
        name="wasteStreamId"
        placeholder="Begin typing to find waste stream"
        label={label}
        error={!!currentError}
        helperText={currentError}
        options={options}
        value={value}
        isLoading={loading}
        onChange={changeHandler}
      />

      {createModalShown && (
        <Modal
          title="New Waste Stream"
          onClose={() => {
            setCreateModalShown(false);
          }}
          grid={{ xs: 8, md: 6 }}
          open
        >
          <CreateWasteStream accountId={accountId} onSuccess={handleCreateSuccess} />
        </Modal>
      )}
    </>
  );
};
