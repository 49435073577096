import React from 'react';
import { MarketBody } from './Catalog/MarketBody';
import { Checkout as CheckoutComponent } from '../../components/Checkout';
import { useCart } from '../../hooks/market';

export function Checkout() {
  const cart = useCart();

  return (
    <MarketBody route="checkout" cart={cart}>
      <CheckoutComponent cart={cart} />
    </MarketBody>
  );
}
