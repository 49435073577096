import React from 'react';
import styled from 'styled-components';
import { TabPanel, LoadingIndicator } from 'rio-ui-components';
import { LoadFailed } from '../Errors';

export const TabContentContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  & > div {
    max-height: 100%;
  }
`;
const ChildrenContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
`;

export interface TabContentProps {
  currentTab?: number | string;
  retry: (...args: any[]) => unknown;
  index: number | string;
  error?: Error;
  loading?: boolean;
  children?: React.ReactNode;
}

export function TabContent({ error, loading, currentTab, retry, index, children }: TabContentProps) {
  const loadingOrError = !!error || loading;
  return (
    <TabPanel value={currentTab} index={index}>
      {loadingOrError && (
        <TabContentContainer>
          {error && <LoadFailed name={error.message} error={error} retry={retry} />}
          {loading && <LoadingIndicator />}
        </TabContentContainer>
      )}
      {!error && !loading && <ChildrenContainer>{children}</ChildrenContainer>}
    </TabPanel>
  );
}
