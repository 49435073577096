import { gql } from '@apollo/client';

export const SURVEY_FRAGMENT = gql`
  fragment SurveyFields on Survey {
    id
    name
    status
    startDate
    deadlineDate
    notes
    template {
      id
      formContent
      questions {
        id
        name
        title
        type
        category {
          name
        }
        options {
          name
          score
        }
      }
    }
    submissions {
      id
      status
      answers {
        id
        answer
        question {
          id
          title
          name
          type
        }
      }
      owner {
        id
      }
    }
    contributors {
      ... on AccountSurveyContributor {
        account {
          id
          name
        }
        user {
          id
          first_name
          last_name
          email
        }
        surveys {
          id
          name
          lockedBy {
            id
            first_name
            last_name
          }
        }
      }
    }
    automatedScoring
    feedbackEnabled
    owner {
      id
    }
  }
`;

export const GET_SURVEYS_PAGE = gql`
  query GetSurveysPage($accountId: ID!, $offset: Int, $limit: Int, $sort: [SortCommand!], $filters: Filters) {
    getSurveysPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...SurveyFields
      }
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const CREATE_SURVEY = gql`
  mutation CreateSurvey($survey: SurveyInput!) {
    createSurvey(survey: $survey) {
      ...SurveyFields
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const GET_ACCOUNT_INFO = gql`
  query GetAccountInfo($id: ID!) {
    getAccountInfo(id: $id) {
      children {
        id
        name
      }
    }
  }
`;

export const DELETE_SURVEY = gql`
  mutation DeleteSurvey($id: ID!) {
    deleteSurvey(id: $id)
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey($id: ID!, $survey: SurveyInput!) {
    updateSurvey(id: $id, survey: $survey) {
      ...SurveyFields
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const GET_SURVEY = gql`
  query GetSurveyById($id: ID!) {
    getSurveyById(id: $id) {
      ...SurveyFields
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const GET_SURVEY_LATEST = gql`
  query getSurveyLatest($accountId: ID!) {
    getSurveyLatest(accountId: $accountId) {
      ...SurveyFields
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const GET_SURVEY_NAME = gql`
  query GetSurveyById($id: ID!) {
    getSurveyById(id: $id) {
      name
    }
  }
`;
