import React, { FC } from 'react';
import { Row, Col, Heading, ProfilePicture, Icon } from 'rio-ui-components';
import styled from 'styled-components';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const DeleteLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 ${(props) => props.theme.geometry.xxl.spacing} 0 0;

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface SubscriptionRowProps {
  name: string;
  id: string;
  onDelete: (id: string, accountId: string) => void;
  accountId: string;
}

const SubscriptionRow: FC<SubscriptionRowProps> = ({ name, id, onDelete, accountId }) => {
  return (
    <div key={id}>
      <SubscriptionRowStyled name={`kb-row--${id}`} container itemAlign="center">
        <SubscriptionRowPictureStyled name={`kb-row__profile-picture--${id}`} src={''} size="xs" accountName={name} />

        <Col container item>
          <Heading inline name={`account-row__name--${id}`} size="md">
            {name}
          </Heading>
        </Col>
        <DeleteLinkStyled onClick={() => onDelete(id, accountId)}>
          <Icon name={`kb-row__edit`} icon="trash-alt" />
        </DeleteLinkStyled>
      </SubscriptionRowStyled>
    </div>
  );
};

export default SubscriptionRow;
