import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import GovernanceAreaRow from './GovernanceAreaRow';

const ResultsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background: ${(props) => props.theme.colors.basic.white};
  margin-top: ${(props) => props.theme.geometry.md.spacing};
`;

const GovernanceAreaList = ({ items, fullAccess }) => {
  return (
    <ResultsContainer>
      {items.map((item) => {
        if (item.comingSoon || !fullAccess[item.accessIndex]) {
          return (
            <Link
              key={item.id}
              name={`GovernanceAreaList__Link__${item.id}`}
              to={item.accessIndex === 'systems' ? '/upgrade/compliance' : '/contact'}
            >
              <GovernanceAreaRow
                fullAccess={fullAccess[item.accessIndex]}
                title={item.title}
                description={item.description}
                icon={item.icon}
                iconColor={item.iconColor}
                key={item.id}
                comingSoon={item.comingSoon}
              />
            </Link>
          );
        } else {
          return (
            <Link name={`GovernanceAreaList__Link__${item.id}`} to={item.link} key={item.id}>
              <GovernanceAreaRow
                fullAccess={fullAccess[item.accessIndex]}
                title={item.title}
                description={item.description}
                icon={item.icon}
                iconColor={item.iconColor}
                key={item.id}
                comingSoon={item.comingSoon}
              />
            </Link>
          );
        }
      })}
    </ResultsContainer>
  );
};

export default GovernanceAreaList;
