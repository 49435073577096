import { MultiSelect } from 'rio-ui-components';
import styled from 'styled-components';
import { MultiSelectEvent, Option } from '../../../types';
import { useGetReceivedSurveysPageFilter } from './queries/useGetReceivedSurveysPageFilter';

interface StatusFilterProps {
  accountId: string;
  status: any;
  setStatus: (e: Option[]) => void;
}

const StatusFilterContainer = styled(MultiSelect)`
  width: 60%;
`;

export function StatusFilter({ accountId, status, setStatus }: StatusFilterProps) {
  const { data, error } = useGetReceivedSurveysPageFilter({ accountId, field: 'submissionStatus' });

  return (
    <StatusFilterContainer
      id="StatusFilter__MultiSelect"
      name="received-surveys-select-checkbox"
      options={data?.getReceivedSurveysPageFilter || []}
      onChange={(e: MultiSelectEvent) => setStatus(e.target.select)}
      error={error}
      value={status}
      placeholder={'Start typing to search for a survey submission status'}
      multiValueColor="tertiary"
      size="sm"
      box
    />
  );
}
