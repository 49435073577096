import { Grid } from '@rio/ui-components';
import { SectionStyled, GridStyed } from './style';
import { Reporting, YourData, QuickTools } from './index';

export const ReportingSection = ({ data }) => {
  return (
    <SectionStyled container>
      <Grid md={8} sm={12}>
        <Reporting scopeBreakdown={data?.getEmissionsPercentageBreakdownByScope} />
      </Grid>
      <GridStyed>
        <QuickTools />
        <YourData />
      </GridStyed>
    </SectionStyled>
  );
};
