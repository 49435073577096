import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Col, Row, Label, Heading } from 'rio-ui-components';
import { AccountSelector, FlowSelector } from '../../../../components';
import { Option } from '../../../../types';
import { FlowSubscriptionDetails } from './types';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

interface FlowDetailsProps {
  heading: string;
  flowSubscriptionDetails: FlowSubscriptionDetails;
  setFlowSubscriptionDetails: (details: FlowSubscriptionDetails) => void;
}

interface ChangeEvent {
  id?: string;
  target?: EventTarget & { value: string; name: string; select: Option; label?: string | undefined };
}

const FlowDetails: React.FC<FlowDetailsProps> = ({ heading, flowSubscriptionDetails, setFlowSubscriptionDetails }) => {
  const handleChange = useCallback(
    (event: ChangeEvent, field: keyof FlowSubscriptionDetails) => {
      setFlowSubscriptionDetails({
        ...flowSubscriptionDetails,
        [field]: event.id || event.target?.value,
      });
    },
    [setFlowSubscriptionDetails, flowSubscriptionDetails]
  );

  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Account</LabelStyled>
          </LabelContainerStyled>
          <AccountSelector value={flowSubscriptionDetails.accountId} onChange={(e) => handleChange(e, 'accountId')} />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Flow</LabelStyled>
          </LabelContainerStyled>
          <FlowSelector value={flowSubscriptionDetails.flowId} onChange={(e) => handleChange(e, 'flowId')} />
        </ColStyled>
      </Row>
    </>
  );
};

export default FlowDetails;
