import { useParams } from 'react-router-dom';
import { useGetAuditByEntityId } from '~/hooks';
import { ContributorAuditLogGridV2 } from '../ReceivedSurveyAuditLogContainer';

export const SurveyAuditLogContainerV2 = () => {
  const { surveyId } = useParams();

  const { data: audit } = useGetAuditByEntityId(surveyId!);
  const auditList = audit?.getAuditByEntityId || [];

  return <ContributorAuditLogGridV2 rows={auditList} />;
};
