import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import * as JsSearch from 'js-search';

import { Row, Search, Icon, TextLink, LoadingIndicator } from 'rio-ui-components';
import { useAccessControls } from '../../../../hooks/useAccessControls';
import NotificationBanner from '../../../../components/NotificationBanner';
import PageHeader from '../../../../components/PageHeader';
import {
  MANAGEMENT_SYSTEM,
  REPORTING_FRAMEWORK,
  VOLUNTARY_AGREEMENT,
} from '../../../../constants/managementSystemTypes';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS } from './index.queries';
import { useCurrentAccountId } from '../../../../hooks/useCurrentAccountId';
import ManagementSystemGrid from './ManagementSystemGrid';
import ManagementSystemList from './ManagementSystemList';
import areas from '../../areas';
import { useLocation } from 'react-router-dom';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const ManagementSystemLandingPage = (props) => {
  const { fullAccess } = props;

  const location = useLocation();
  const { data: accessControls } = useAccessControls();
  const [showGrid, setShowGrid] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const accountId = useCurrentAccountId();

  const typeData = {
    systems: { type: MANAGEMENT_SYSTEM, title: 'Management Systems and Standards' },
    agreements: { type: VOLUNTARY_AGREEMENT, title: 'Voluntary Agreements' },
    frameworks: { type: REPORTING_FRAMEWORK, title: 'Reporting Frameworks' },
  };

  const path = typeData[location.pathname.split('/').pop()];

  const { loading, data } = useQuery(GET_AVAILABLE_MANAGEMENT_SYSTEMS, {
    variables: { accountId, type: path.type || typeData[0].type },
  });

  const search = (d, term) => {
    if (!term) return d;
    const searcher = new JsSearch.Search('id');
    searcher.addIndex('title');
    searcher.addDocuments(data);
    return searcher.search(term);
  };

  const searchedSystems = !loading && data ? search(data.getAvailableManagementSystems, searchValue) : [];

  const availableGovernancePages = areas.filter((area) => !area.comingSoon && accessControls.comply[area.accessIndex]);

  return (
    <ContainerStyled name="ManagementSystemLandingPage">
      <PageHeader
        name="ManagementSystemLandingPageHeader"
        breadcrumbs={[{ title: 'Governance', to: '..' }, { title: path.title }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown={true}
        dropdownItems={availableGovernancePages}
        dropdownDefault={path.title}
      />

      <ContentContainer>
        <SearchHeader container item itemAlign="center">
          <SearchStyled
            name="DataSubjects__Search"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            hideButton
          />
          <TextLinkStyled
            name="DataSubjects__ShowGrid"
            size="md"
            color={showGrid ? 'primary' : 'normal'}
            weight={showGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={() => setShowGrid(!showGrid)}
            inline
          >
            <Icon icon="th" />
          </TextLinkStyled>
          <TextLinkStyled
            name="DataSubjects__ShowList"
            size="md"
            color={!showGrid ? 'primary' : 'normal'}
            weight={!showGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={() => setShowGrid(!showGrid)}
            inline
          >
            <Icon icon="th-list" />
          </TextLinkStyled>
        </SearchHeader>

        {loading && (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        )}

        {!loading && !searchedSystems.length && (
          <NotificationBannerStyled
            name="GovContainer_NoManagementSystems_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title={`Management Systems and Standards`}
            body={`Currently this account does not have any assigned Management Systems. Please contact support for more information.`}
          />
        )}

        {showGrid && <ManagementSystemGrid items={searchedSystems} fullAccess={fullAccess} />}
        {!showGrid && <ManagementSystemList items={searchedSystems} fullAccess={fullAccess} />}
      </ContentContainer>
    </ContainerStyled>
  );
};

export default ManagementSystemLandingPage;
