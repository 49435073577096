import React, { useState } from 'react';
import TetherComponent from 'react-tether';
import styled, { keyframes, css } from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { FormattedMessage } from 'react-intl';
import { Row, Icon, Text, ProfilePicture, TextLink } from 'rio-ui-components';
import { LOG_OUT } from '../../queries/auth';
import { useGetCurrentUserQuery, usePermissions } from '../../hooks';

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const AccountContainer = styled.div`
  flex: 0 0 auto;
  height: 100%;
  margin-left: auto;
  border-left: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const AccountWrapper = styled(Row)`
  flex: 0 0 auto;
  height: 100%;
  padding: 0 ${(props) => props.theme.geometry.sm.spacing};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.overlay.light.background};
  }
`;

const ProfilePictureStyled = styled(ProfilePicture)`
  flex: 0 0 auto;
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const AccountNameContainer = styled(Row)`
  flex: 0 0 auto;
`;

const AccountName = styled(Text)`
  flex: 0 0 auto;
`;

const ChevronStyled = styled(Icon)`
  flex: 0 0 auto;
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
  color: ${(props) => props.theme.colors.text.normal.background};
`;

const pulseAnimation = keyframes`
  0% { opacity: 0.4; }
  40% { opacity: 0.15; } 
  100% { opacity: 0.4; } 
`;

const pulseAnimationCss = css`
  ${pulseAnimation} infinite 2s;
`;

const ProfilePicturePlaceholder = styled.div`
  height: 44px;
  width: 44px;
  background-color: ${(props) => props.theme.colors.overlay.dark.background};
  opacity: 0.4;
  border-radius: 5000px;
  animation: ${pulseAnimationCss};
`;

const TextPlaceholder = styled.div`
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
  height: ${(props) => props.theme.fonts.md.size};
  width: ${(props) => props.width};
  background-color: ${(props) => props.theme.colors.text.normal.background};
  opacity: 0.4;
  border-radius: ${(props) => props.theme.geometry.md.radius};
  animation: ${pulseAnimation} infinite 2s;
  animation-delay: 0.1s;
`;

const DropdownContainer = styled.div`
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: ${({ theme }) => theme.colors.basic.white};
  border: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
  border-top: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.4);
`;

const TextLinkStyled = styled(TextLink)`
  padding: ${({ theme }) => theme.geometry.md.spacing};
  padding-right: ${({ theme }) => theme.geometry.xxl.spacing};
  border-top: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};

  &:hover {
    background: ${({ theme }) => theme.colors.overlay.light.background};
  }
`;

const AccountDropdown = () => {
  const [state, setState] = useState({
    showDropdown: false
  });
  const { data } = useGetCurrentUserQuery();

  const toggleDropdown = () => setState({ showDropdown: !state.showDropdown });

  const formattedName = (first_name, last_name) => {
    const usersName = `${first_name} ${last_name}`;
    const shortenedName = usersName.length > 25 ? `${usersName.slice(0, 22)}...` : usersName;
    return shortenedName;
  };

  const resetTours = () => {
    localStorage.removeItem('TOUR_STATUS');
  };

  const permissions = usePermissions();
  const { showDropdown } = state;

  return (
    <>
      {showDropdown && <Overlay onClick={toggleDropdown} />}
      <TetherComponent
        style={{ 'z-index': '1' }}
        attachment="bottom right"
        targetAttachment="top right"
        constraints={[{ to: 'scrollParent', attachment: 'together' }]}
        renderTarget={(ref) => {
          if (!data) {
            return (
              <AccountContainer
                ref={ref}
                name="AccountDropdown__User--Placeholder"
                container
                itemAlign="center"
                onClick={toggleDropdown}
              >
                <ProfilePicturePlaceholder name="AccountDropdown__User-Image--Placeholder" />
                <TextPlaceholder width="70px" name="AccountDropdown__User-Name--Placeholder" />
                <TextPlaceholder width="16px" />
              </AccountContainer>
            );
          }
          return (
            <AccountContainer name="AccountDropdown__User" container ref={ref}>
              <AccountWrapper container itemAlign="center" onClick={toggleDropdown}>
                <ProfilePictureStyled
                  name="AccountDropdown__User-Name"
                  size="xxs"
                  src={data.profilePicture}
                  accountName={data.first_name}
                />
                <AccountNameContainer container itemAlign="center">
                  <AccountName name="AccountDropdown__User-Name" inline size="md">
                    {formattedName(data.first_name, data.last_name)}
                  </AccountName>
                  <ChevronStyled icon="chevron-down" />
                </AccountNameContainer>
              </AccountWrapper>
            </AccountContainer>
          );
        }}
        renderElement={(ref) =>
          showDropdown && (
            <DropdownContainer ref={ref}>
              <TextLinkStyled
                name="AccountDropdown__Link--Profile"
                underline={false}
                color="dark"
                component="routerLink"
                to="/profile"
              >
                <FormattedMessage id="home.myProfile" />
              </TextLinkStyled>

              <TextLinkStyled
                name="AccountDropdown__Link--Contact"
                underline={false}
                color="dark"
                component="routerLink"
                to="/contact"
              >
                <FormattedMessage id="home.contact" />
              </TextLinkStyled>
              {permissions.account.includes('getAll') && (
                <TextLinkStyled
                  name="AccountDropdown__Link--ResetTours"
                  underline={false}
                  color="dark"
                  onClick={resetTours}
                >
                  Reset tours
                </TextLinkStyled>
              )}
              <Mutation mutation={LOG_OUT}>
                {(logout) => (
                  <TextLinkStyled
                    name="AccountDropdown__Link--Logout"
                    underline={false}
                    color="dark"
                    component="routerLink"
                    to="/logout"
                    onClick={logout}
                  >
                    <FormattedMessage id="home.logoutButtonLabel" />
                  </TextLinkStyled>
                )}
              </Mutation>
            </DropdownContainer>
          )
        }
      />
    </>
  );
};

export default AccountDropdown;
