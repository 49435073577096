import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

export type ActionFunction = (id: string) => void;

export interface ActionCellProps {
  data: {
    id: string;
  };
  onEdit: ActionFunction;
}

export const ActionCell = ({ data: { id }, onEdit }: ActionCellProps) => (
  <GridCellContainer>
    <LinkStyled onClick={() => onEdit(id)}>
      <Icon name={`scheme-row__edit--${id}`} icon="pencil-alt" />
    </LinkStyled>
  </GridCellContainer>
);
