import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'rio-ui-components';

import { UPDATE_NORMALISER } from './index.queries';
import NormaliserDetails from './NormaliserDetails';
import * as scopes from '../../../constants/scopes';
import { useNormaliserFormWithDefaultsFromServer } from './useNormaliserForm';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { formatDatetime } from '../../../utils/formatDate'

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

function UpdateNormaliser({ id, accountId, showNotification }) {
  const { values, setValues, errors, validate, loading } = useNormaliserFormWithDefaultsFromServer(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateNormaliser] = useMutation(UPDATE_NORMALISER);

  return (
    <Container name="update-normaliser">
      {loading ? (
        <ContainerLoadingIndicator name="ConfigurationNormaliserContainer__Loading" />
      ) : (
        <form
          name="update-normaliser__form"
          onSubmit={async (e) => {
            e.preventDefault();
            const valid = validate();
            if (valid && !isSubmitting) {
              try {
                setIsSubmitting(true);
                const { data } = await updateNormaliser({
                  variables: {
                    id,
                    accountId,
                    name: values.name,
                    description: values.description,
                    quantity: values.quantity,
                    locationId: values.locationId,
                    startDate: values.startDate ? formatDatetime(values.startDate, 'YYYY-MM-DD HH:mm:ss') : null,
                    endDate: values.endDate ? formatDatetime(values.endDate, 'YYYY-MM-DD HH:mm:ss') : null,
                    tagId: values.scope === scopes.TAG ? values.tagId : null,
                    type: values.type
                  }
                });

                const error = data?.updateNormaliser?.error;
                if (error) {
                  showNotification(error, 'danger', 'update');
                } else {
                  showNotification(`The Normaliser ${values.name} has been updated!`, 'success');
                }
              } catch {
                showNotification(`Something went wrong. If the problem persists, contact support.`, 'danger');
              } finally {
                setIsSubmitting(false);
              }
            }
          }}
        >
          <NormaliserDetails
            setValues={setValues}
            values={values}
            errors={errors}
            isSubmitted={isSubmitting}
            heading="Update Normaliser"
            accountId={accountId}
          />
          <Row container align="between">
            <ColStyled item>
              <Button name="update-normaliser__button--update" color="success" submit disabled={isSubmitting}>
                Update Normaliser
              </Button>
            </ColStyled>
          </Row>
        </form>
      )}
    </Container>
  );
}

export default UpdateNormaliser;
