import styled from 'styled-components';
import { Col, PreContainer, Text, withDropzone } from 'rio-ui-components';

const ErrorContainer = styled(Col)`
  ${({ isDragActive, isDragEnabled, theme, background }) => `
    background: ${background ? theme.uploadColors.background : null};
    opacity: ${isDragEnabled && isDragActive ? 0.5 : 1};
  `};
`;

const TextStyled = styled(Text)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

interface MassUploadErrorsProps {
  errors: string[];
  isDragActive: boolean;
  isDragEnabled: boolean;
}

export const MassUploadErrors = withDropzone(({ errors, isDragActive, isDragEnabled }: MassUploadErrorsProps) => (
  <ErrorContainer span={12} isDragActive={isDragActive} isDragEnabled={isDragEnabled}>
    <TextStyled>We found these errors in your spreadsheet:</TextStyled>
    <PreContainer>
      {errors.map((error) => (
        <Text>{error}</Text>
      ))}
    </PreContainer>
  </ErrorContainer>
));
