import { useApolloClient } from '@apollo/client';
import { UploadReferenceCell, DocumentReference } from '../../CellRenderers/index';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { GET_TRANSPORT_TRANSACTION_PAGE } from './index.queries';
import { useTransportGridConfig } from './useTransportGridConfig';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Transport Transactions' }];

function TransportTransactionsComponent() {
  const client = useApolloClient();
  const config = useTransportGridConfig();
  return (
    <>
      <TransactionGrid
        title="Transport Transactions"
        defaultExportFileName="transport-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../transport/uploads"
        config={{
          ...config,
          components: {
            uploadReference: UploadReferenceCell,
            documentReference: DocumentReference,
          },
        }}
        fetchRows={async (variables) => {
          const {
            data: {
              getTransportTransactionPage: { totalRows, rows },
            },
          } = await client.query({
            query: GET_TRANSPORT_TRANSACTION_PAGE,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows,
            totalRows,
          };
        }}
      />
    </>
  );
}

export const TransportTransactions = TransportTransactionsComponent;
