import { TAG, LOCATION } from '../../../constants/scopes';
import { REDUCE } from '../../../constants/targetTypes';

export function validate(target, targetTypes) {
  const errors = {};
  const targetType = targetTypes.find(({ value }) => value === target.typeId);
  if (!target.name) {
    errors.name = 'Name is required';
  }

  if (!target.scope) {
    errors.scope = 'Scope is required';
  }

  if (target.scope === LOCATION && !target.locationId) {
    errors.locationId = 'Location is required';
  }

  if (target.scope === TAG && !target.tagId) {
    errors.tagId = 'Tag is required';
  }

  if (!target.typeId) {
    errors.typeId = 'Type is required';
  }

  if (!target.topicId) {
    errors.topicId = 'Topic is required';
  }

  if (!target.startDate) {
    errors.startDate = 'Start Date is required';
  }

  if (!target.endDate) {
    errors.endDate = 'End Date is required';
  }

  if (!target.value && target.value !== 0) {
    errors.value = 'Value is required';
  }

  if (targetType && targetType.label === REDUCE) {
    if (!target.baselineStartDate) {
      errors.baselineStartDate = 'Baseline Start Date is required';
    }
    if (!target.baselineEndDate) {
      errors.baselineEndDate = 'Baseline Start Date is required';
    }
  }

  return errors;
}
