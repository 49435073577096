import React from 'react';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const IconLink = ({ id, name, icon, size = 'md', ...rest }) => (
  <LinkStyled {...rest}>
    <Icon id={id} name={name} icon={icon} size={size} />
  </LinkStyled>
);

export default IconLink;
