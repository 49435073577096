import styled from 'styled-components';
import { Text, Button } from '@rio/ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.xxs.spacing};
`;

interface CreateEditingProps {
  onDismiss: () => void;
  buttonDescription: string;
  submitButtonText: string;
  description: string;
}

const RowStyled = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  max-width: 250px;
  width: 100%;
`;

const TextStyled = styled(Text)`
  margin-bottom: 30px;
`;

export function Editing({ onDismiss, submitButtonText, description }: CreateEditingProps) {
  return (
    <Container>
      <TextStyled typescale="body" size="medium">
        {description}
      </TextStyled>
      <RowStyled>
        <ButtonStyled variant="filled" name="LockedEditing__Submit" onClick={onDismiss}>
          {submitButtonText}
        </ButtonStyled>
      </RowStyled>
    </Container>
  );
}
