import { useRef } from 'react';
import { NamedEntity, Query, QueryGetWasteInsByAccountIdArgs, DataType, FilterStrategy } from '@rio/rio-types';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { useCurrentAccountId } from '../../hooks';
import { GET_WASTE_IN_LOCATIONS_BY_ACCOUNT_ID } from './index.queries';
import { Nullable, SelectEvent, Option } from '../../types';
import { BaseLocationSelect } from './BaseLocationSelect';
import { mapLocationToOption } from './utils';

interface WasteInLocationSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: Nullable<Error | string>;
  createNew?: boolean;
  createNewModalSize?: number;
  selectedOption?: Nullable<Option>;
  createNewAccessor?: string;
}

const order = [
  {
    order: 'asc',
    field: 'name'
  }
];

const getLocationsFromResponse = (res: ApolloQueryResult<Query>): NamedEntity[] =>
  res?.data?.getWasteInsByAccountId || [];

export function WasteInLocationSelect({
  value,
  onChange,
  error,
  createNew,
  createNewModalSize = 5,
  selectedOption,
  createNewAccessor
}: WasteInLocationSelectProps) {
  const accountId = useCurrentAccountId();
  const apolloClient = useApolloClient();
  const variables = useRef<QueryGetWasteInsByAccountIdArgs>({
    id: accountId
  });
  return (
    <BaseLocationSelect
      value={value}
      locationPoints={{ wasteIn: true }}
      onChange={onChange}
      accountId={accountId}
      error={error}
      createNew={createNew}
      createNewModalSize={createNewModalSize}
      accessor={createNewAccessor}
      selectedOption={selectedOption}
      loadOptions={async (filterValue: string) => {
        variables.current = {
          id: accountId,
          sort: order,
          filters: {
            text: [
              {
                filterType: DataType.Text,
                type: FilterStrategy.Contains,
                filter: filterValue,
                field: 'name'
              }
            ]
          }
        };
        const response = await apolloClient.query<Query>({
          query: GET_WASTE_IN_LOCATIONS_BY_ACCOUNT_ID,
          variables: variables.current
        });
        return { options: getLocationsFromResponse(response).map(mapLocationToOption) };
      }}
    />
  );
}
