import { get, forIn, mapValues } from 'lodash';
import { useState } from 'react';
import moment from 'moment';
import { DataFormRowsFillingStrategy, DateFrequency, DataFormInputStrategy } from '../../constants';
import { toNumeric, fixFloat, calculateVat } from '../../utils';

export function useConvertTransportDataFormToRows() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  return [
    (form = {}, columns = []) => {
      if (!columns.length || loading) {
        return;
      }

      setLoading(true);

      // TODO: normal optimization
      const {
        data,
        startDate,
        endDate,
        dataFormRowsFillingStrategy: dataFormRowsFillingStrategyValue,
        dateFrequency: dateFrequencyValue,
        dataFormInputStrategy: dataFormInputStrategyValue
      } = form;

      const mappedData = mapValues(data, (value) => get(value, 'label', value));

      // need a structure for a table component
      const row = {
        ...mappedData,
        date: startDate,
        cost: data.cost || undefined,
        distance: data.distance || undefined,
        volume: data.volume || undefined,
        freight: data.freight || undefined,
        carrier: data.carrier || undefined,
        notes: data.notes || undefined
      };

      row['costVat'] = calculateVat(row.cost);

      const list = [];

      if (dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed) {
        let rowsCount;

        const momentMeasure = {
          [DateFrequency.Daily]: 'days',
          [DateFrequency.Weekly]: 'weeks',
          [DateFrequency.Monthly]: 'months',
          [DateFrequency.Quarterly]: 'quarters',
          [DateFrequency.Annually]: 'years'
        }[dateFrequencyValue];

        rowsCount = moment(endDate).diff(moment(startDate), momentMeasure) + 1;
        rowsCount = rowsCount < 1 ? 1 : rowsCount;

        if (rowsCount > 100000) {
          throw new Error('Too much rows');
        }

        if (dataFormInputStrategyValue === DataFormInputStrategy.Single) {
          for (let i = 0; i < rowsCount; i++) {
            list.push({
              ...row,
              date: moment(startDate).add(i, momentMeasure).toDate()
            });
          }
        } else {
          const cachedDividedValues = {};
          const columnsWithNumber = new Map([
            ['cost', 'currency'],
            ['costVat', 'currency']
          ]);

          columns
            .filter(({ dataType }) => dataType === 'number' || dataType === 'currency')
            .forEach(({ id, dataType }) => columnsWithNumber.set(id, dataType));

          forIn(row, (value, key) => {
            if (!columnsWithNumber.has(key) || !value) {
              return;
            }

            const newValue = toNumeric(value) / rowsCount;

            const isNumber = columnsWithNumber.get(key) === 'number';

            cachedDividedValues[key] = newValue > 0 ? (isNumber ? Math.round(newValue) : fixFloat(newValue)) : 0;
          });

          cachedDividedValues['costVat'] = calculateVat(cachedDividedValues['cost']);

          for (let i = 0; i < rowsCount; i++) {
            list.push({
              ...row,
              ...cachedDividedValues,
              date: moment(startDate).add(i, momentMeasure).toDate()
            });
          }
        }
      } else {
        list.push(row);
      }

      // add a fake id for a compatibility in the table component
      const mappedList = list.map((item, i) => ({ ...item, id: i }));

      setRows(mappedList);

      setLoading(false);
    },

    {
      data: rows,
      loading
    }
  ];
}
