import { gql } from '@apollo/client';

export const GET_ALL_KBS = gql`
  query GetAllKnowledgeBases {
    getAllKnowledgeBases {
      id
      externalID
      title
      synopsis
      demoMode
    }
  }
`;

export const CREATE_KB = gql`
  mutation CreateKnowledgeBase($details: KnowledgeBaseDetailsInput!) {
    createKnowledgeBase(input: $details) {
      id
      externalID
      title
      synopsis
      demoMode
    }
  }
`;

export const UPDATE_KB = gql`
  mutation UpdateKnowledgeBase($details: KnowledgeBaseDetailsInput!) {
    updateKnowledgeBase(input: $details) {
      id
      externalID
      title
      synopsis
      demoMode
    }
  }
`;
