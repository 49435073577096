import styled from 'styled-components';

import { PropertySheet } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.xxs.spacing};
`;

interface CreateEditingProps {
  onDismiss: () => void;
  onSecondaryButtonClick: () => void;
  buttonDescription?: string;
  title: string;
  submitButtonText: string;
  secondaryButtonText: string;
  description: string;
}

const StyledTextColumn = styled(PropertySheet.TextColumn)`
  text-align: center;
`;

export function Editing({
  onDismiss,
  title,
  submitButtonText,
  description,
  onSecondaryButtonClick,
  secondaryButtonText,
}: CreateEditingProps) {
  return (
    <Container>
      <PropertySheet>
        <PropertySheet.HeaderRow>{title}</PropertySheet.HeaderRow>
        <StyledTextColumn>{description}</StyledTextColumn>
        <PropertySheet.Row container align="between">
          <PropertySheet.ButtonColumn name="LockedEditing__Submit" span={6} onClick={onDismiss}>
            {submitButtonText}
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            name="UnlockEditing__Submit"
            color="primary"
            span={6}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonText}
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
