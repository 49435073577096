import { SvgIconProps } from './types';
import { Svg } from './Svg';

export const Tick = ({ width = 16, height = 16, fill = '#00C8B5', ...rest }: SvgIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.2759 3.31055L13.7242 4.68986L6.33335 12.4502L2.27588 8.18986L3.72415 6.81055L6.33335 9.5502L12.2759 3.31055Z"
      fill={fill}
    />
  </Svg>
);
