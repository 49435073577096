"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("@mui/material-next/styles");
const _1 = require("./");
const HiddenFileInput = (0, styles_1.styled)('input') `
  display: none;
`;
const FileUploadButton = ({ onClick, multiple, onFileUpload, children, ...buttonProps }) => {
    const hiddenFileInput = (0, react_1.useRef)(null);
    const handleClick = (0, react_1.useCallback)((event) => {
        onClick?.(event);
        hiddenFileInput.current?.click();
    }, [onClick]);
    const handleChange = (0, react_1.useCallback)((event) => {
        const files = event.target?.files;
        if (files) {
            onFileUpload(Array.from(files));
        }
    }, [onFileUpload]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(_1.Button, { variant: "filled", color: "primary", ...buttonProps, onClick: handleClick, children: children }), (0, jsx_runtime_1.jsx)(HiddenFileInput, { type: "file", onChange: handleChange, ref: hiddenFileInput, multiple: multiple })] }));
};
exports.FileUploadButton = FileUploadButton;
