import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { TransactionType, Accuracy, EnergySource } from '@rio/rio-types';
import { Option } from '~/types';
import { NumberInput, NumberInputWithNegativeOption } from '../../UI/V2';
import { CreateMeterModal } from '../../CreateMeterModal/v2';
import { useGetMeters, useCurrentAccountId, usePermissions, useCurrency } from '~/hooks';
import { Controller, Control } from 'react-hook-form';
import { DataForm } from '../../DataFormModal/v2';
import { SelectRenderV2 as SelectRender } from '../../DataForm';
import { SupplierSelector } from '../../SuppliersSelector/v2';
import { Checkbox, Grid, LinearProgress, Text, styled, TextField, OptionsProps } from '@rio/ui-components';

const LinearProgressContainer = styled('div')`
  width: 100%;
  margin-top: 16px;
  height: 4px;
`;

const LinearProgressStyled = styled(LinearProgress)`
  width: 100%;
`;

const CheckboxesContainerStyled = styled('div')`
  display: flex;
  gap: 16px
  margin-left: 16px;
  flex-shrink: 0;
`;

type Reference = Partial<{
  invoiceNumber: string | number;
  evidenceReference: string | number;
  otherReference: string | number;
}>;

type Data = {
  tariff?: number;
  totalCost?: number;
  quantity?: number;
  currency?: string;
};

type FormData = Partial<{
  data: Data;
  reference: Reference;
}>;

interface FormProps {
  setValue: (field: string, value: string | number | boolean | Option) => void;
  getValues: (name?: string) => FormData;
  control: Control;
  trigger: () => void;
  register: (field: string) => void;
  watch: (fields: string[]) => boolean[];
}

interface WaterDataProps {
  form: FormProps;
  onSubmit: (formData: object) => void;
  dataSection: TransactionType;
  children: React.ReactNode;
}

type SubmitProps = {
  data: { accuracy: OptionsProps; currency: OptionsProps; [key: string]: unknown };
  financial: object;
  reference: object;
};

const CREATE_NEW_METER_OPTION = {
  value: 'createNew',
  label: 'Create new ...',
};

export function WaterDataForm(props: WaterDataProps) {
  /**
   * PROPS
   */
  const { form, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, register, watch } = form;

  /**
   * STATE
   */
  const [isMeterFormVisible, setMeterFormVisible] = useState(false);
  const [currencyInitialState] = useState(form.getValues('data.currency'));
  const [metersOptions, setMetersOptions] = useState<OptionsProps[]>([]);

  /**
   * HOOKS
   */
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const canCreateMeters = useMemo(
    () => permissions.data.find((action: string) => action.startsWith('createEnergySource')),
    [permissions.data]
  );
  const handleGetMetersCompleted = useCallback(
    (res) => {
      const metersList = res?.getEnergySourceByAccountIdAndType?.rows || [];

      const result = metersList.map((m) => ({ value: m.id, label: m.name }));

      if (canCreateMeters) {
        result.unshift(CREATE_NEW_METER_OPTION);
      }

      return setMetersOptions(result);
    },
    [canCreateMeters]
  );
  const {
    loading: metersLoading,
    data: meters,
    refetch: refetchMeters,
  } = useGetMeters(dataSection, {
    onCompleted: handleGetMetersCompleted,
    withLocationData: true,
    fetchPolicy: 'network-only',
  });
  const { data: currenciesList, loading: currenciesListLoading } = useCurrency();
  const getFullMeterById = useCallback(
    (id: string, metersList?: EnergySource[]) => (metersList || meters).find((m) => m.id === id),
    [meters]
  );

  useEffect(() => {
    register('isReferenceDataRequired');
  }, [register]);

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const onCreateNewMeterClick = useCallback(() => {
    setMeterFormVisible(true);
  }, []);

  const closeCreatedMeterForm = useCallback(() => setMeterFormVisible(false), []);
  const selectCreatedMeter = useCallback(
    async (key: string, newMeter: OptionsProps) => {
      const { data } = await refetchMeters();
      const refetchedMeters = data?.getEnergySourceByAccountIdAndType?.rows || [];

      const newFullMeter = getFullMeterById(newMeter.value, refetchedMeters);
      if (newFullMeter) {
        setValue(key, newFullMeter);
      }

      closeCreatedMeterForm();
    },
    [closeCreatedMeterForm, setValue, refetchMeters, getFullMeterById]
  );

  const showReferenceData = useCallback(() => setValue('isReferenceDataRequired', true), [setValue]);
  const hideReferenceData = useCallback(() => setValue('isReferenceDataRequired', false), [setValue]);

  /**
   * SUBMIT
   * mapping data on submit event
   */
  const handleOnSubmit = useCallback(
    ({ data, financial, reference, ...others }: SubmitProps) => {
      const formData = {
        ...others,
        data: {
          ...data,
          accuracy: data.accuracy.label,
          currency:
            (financial as { currency: { label: string } })?.currency?.label ||
            data.currency?.label ||
            currencyInitialState,
        },
      };

      if (isReferenceDataRequired) {
        formData.data = { ...formData.data, ...reference };
      }

      onSubmit(formData);
    },
    [currencyInitialState, isReferenceDataRequired, onSubmit]
  );

  /**
   * OPTIONS
   */
  const actEstOptions = useMemo(
    () => [
      {
        value: Accuracy.Act,
        label: Accuracy.Act,
      },
      {
        value: Accuracy.Est,
        label: Accuracy.Est,
      },
    ],
    []
  );

  const currencyOptions = useMemo(
    () => currenciesList.map((cur) => ({ value: cur.id, label: cur.name })),
    [currenciesList]
  );

  /**
   * DEFAULT FORM VALUES
   */
  const accuracyDefaultValue = useMemo(() => ({ value: Accuracy.Act, label: Accuracy.Act }), []);
  const currencyDefaultValue = useMemo(
    () => currencyOptions.find((item) => item.label === currencyInitialState),
    [currencyInitialState, currencyOptions]
  );

  const isLoading = metersLoading || currenciesListLoading;

  const calculateTariff = useCallback(() => {
    const totalCost = form.getValues().data?.totalCost || 0;
    const quantity = form.getValues().data?.quantity || 0;

    if (totalCost > 0 && quantity > 0) {
      setValue('data.tariff', totalCost / quantity);
    }
  }, [form, setValue]);

  const calculateTotalCost = useCallback(() => {
    const tariff = form.getValues().data?.tariff || 0;
    const quantity = form.getValues().data?.quantity || 0;

    if (tariff > 0 && quantity > 0) {
      setValue('data.totalCost', tariff * quantity);
    }
  }, [form, setValue]);

  const handleQuantityChange = useCallback(
    (value: number) => {
      setValue('data.quantity', value);
      calculateTariff();
      calculateTotalCost();
    },
    [calculateTariff, calculateTotalCost, setValue]
  );

  const handleTariffChange = useCallback(
    (value: number) => {
      setValue('data.tariff', value);
      calculateTotalCost();
    },
    [calculateTotalCost, setValue]
  );

  const handleTotalCostChange = useCallback(
    (value: number) => {
      setValue('data.totalCost', value);
      calculateTariff();
    },
    [calculateTariff, setValue]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showEndDate>
      {isLoading && (
        <LinearProgressContainer>
          <LinearProgressStyled />
        </LinearProgressContainer>
      )}
      {!isLoading && (
        <Grid container columns={12} rowGap={4}>
          {isMeterFormVisible && (
            <CreateMeterModal
              energyType={dataSection}
              onSuccess={selectCreatedMeter}
              onDismiss={() => setMeterFormVisible(false)}
            />
          )}

          <Grid item xs={12}>
            <Text typescale="body" size="large">
              Please enter the following transactional {dataSection.toLowerCase()} data:
            </Text>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.energySource"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  label="Meter name"
                  onCreateNew={onCreateNewMeterClick}
                  options={metersOptions}
                  error={fieldState.error?.message}
                  onChange={(e) => {
                    if (e.value === CREATE_NEW_METER_OPTION.value) {
                      return;
                    }

                    const selectedFullMeter = getFullMeterById(e.value);
                    if (selectedFullMeter) {
                      setValue('data.energySource', selectedFullMeter);
                    }
                  }}
                  value={field.value?.id || ''}
                />
              )}
              rules={{ required: 'Meter name is required' }}
              defaultValue={null}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.reading"
              render={({ field, fieldState }) => (
                <NumberInput {...field} label="Reading (optional)" error={fieldState.error?.message} />
              )}
              defaultValue=""
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.supplier"
              render={({ field, fieldState }) => (
                <SupplierSelector
                  accountId={accountId}
                  label="Supplier (optional)"
                  value={field.value?.value}
                  onChange={(e) => setValue('data.supplier', e)}
                  error={fieldState.error?.message}
                  utility={TransactionType.Water}
                />
              )}
              defaultValue={null}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.quantity"
              render={({ field, fieldState }) => (
                <NumberInputWithNegativeOption
                  {...field}
                  label="Quantity (m3)"
                  error={fieldState.error?.message}
                  onChange={(value: number) => {
                    field.onChange(value);
                    handleQuantityChange(value);
                  }}
                />
              )}
              rules={{ required: 'Quantity is required' }}
              defaultValue=""
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.accuracy"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  label="Act/Est"
                  value={field.value?.value}
                  options={actEstOptions}
                  error={fieldState.error?.message}
                  onChange={(e) => setValue('data.accuracy', e)}
                />
              )}
              rules={{ required: 'Act/Est is required' }}
              defaultValue={accuracyDefaultValue}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.tariff"
              render={({ field, fieldState }) => (
                <NumberInput
                  {...field}
                  label="Tariff (cost per m3) (optional)"
                  error={fieldState.error?.message}
                  onChange={(value) => {
                    field.onChange(Number(value));
                    handleTariffChange(Number(value));
                  }}
                />
              )}
              defaultValue=""
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.totalCost"
              render={({ field, fieldState }) => (
                <NumberInput
                  {...field}
                  label="Total cost (optional)"
                  error={fieldState.error?.message}
                  onChange={(value) => {
                    field.onChange(Number(value));
                    handleTotalCostChange(Number(value));
                  }}
                />
              )}
              defaultValue=""
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="financial.currency"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  label="Currency"
                  value={field.value?.value}
                  options={currencyOptions}
                  error={fieldState.error?.message}
                  onChange={(e) => setValue('financial.currency', e)}
                />
              )}
              rules={{ required: 'Currency is required' }}
              defaultValue={currencyDefaultValue}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="data.comment"
              render={({ field }) => <TextField {...field} label="Comment" />}
              control={control}
              defaultValue=""
            />
          </Grid>

          <Grid container xs={12} alignItems="center" justifyContent="space-between">
            <Text typescale="body" size="large">
              Do you want to add any references?
            </Text>

            <CheckboxesContainerStyled>
              <Checkbox label="Yes" checked={Boolean(isReferenceDataRequired)} onChange={showReferenceData} />
              <Checkbox label="No" checked={!Boolean(isReferenceDataRequired)} onChange={hideReferenceData} />
            </CheckboxesContainerStyled>
          </Grid>

          {isReferenceDataRequired && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="reference.invoiceNumber"
                  render={({ field }) => <TextField {...field} label="Invoice number (optional)" />}
                  defaultValue=""
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="reference.evidenceReference"
                  render={({ field }) => <TextField {...field} label="Evidence reference (optional)" />}
                  defaultValue=""
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="reference.otherReference"
                  render={({ field }) => <TextField {...field} label="Other reference (optional)" />}
                  defaultValue=""
                  control={control}
                />
              </Grid>
            </>
          )}
          {props.children}
        </Grid>
      )}
    </DataForm>
  );
}
