import React from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const TemplateDetails = ({ heading, template, setTemplate }) => {
  const handleChange = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Template Name</LabelStyled>
          </LabelContainerStyled>
          <TextInput onChange={handleChange} name="name" value={template.name} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Doc Parser ID</LabelStyled>
          </LabelContainerStyled>
          <TextInput onChange={handleChange} name="parserId" value={template.parserId} box />
        </ColStyled>
      </Row>
    </>
  );
};

export default TemplateDetails;
