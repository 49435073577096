import { useCallback } from 'react';
import { Autocomplete, AutocompleteField } from 'rio-ui-components';
import { ChoiceQuestion } from '@rio/rio-types';

interface ChoiceQuestionSelectProps {
  question: ChoiceQuestion;
  onAnswer: (question: ChoiceQuestion) => void;
}

export function ChoiceQuestionSelect({ question, onAnswer }: ChoiceQuestionSelectProps) {
  const handleChange = useCallback(
    (_, answer: string | null) => {
      onAnswer({
        ...question,
        reportedChoice: answer
      });
    },
    [question, onAnswer]
  );
  return (
    <Autocomplete
      fullWidth
      value={question.reportedChoice || null}
      onChange={handleChange}
      options={question.variants || []}
      renderInput={(props: object) => (
        <AutocompleteField
          {...props}
          label={question.shortFormulation || question.formulation}
          placeholder={question.shortFormulation || question.formulation}
        />
      )}
    />
  );
}
