import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Text, TextLink, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import IconLink from '../../../components/IconLink';

const RowStyled = styled(Row)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  ${(p) => {
    if (p.action) return `background-color: ${p.theme.colors.warning.highlight.background};`;
  }};
`;

const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
  word-break: break-all;
`;

const WasteHierarchyRow = (props) => {
  const { id, wasteTreatmentProcessName, prevention, preparingForReuse, recycling, otherRecovery, disposal, unknown, reference, isGlobal, showViewModal } = props;

  return (
    <RowStyled container id={`${id}__WasteHierarchy__Row`} name={`${id}__WasteHierarchy__Row`}>
      <ColStyled item span={2}>
        <Text id={`${id}__WasteHierarchy__wasteTreatmentProcessName`} name={`${id}__WasteHierarchy__wasteTreatmentProcessName`}>
          {wasteTreatmentProcessName}
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__prevention`} name={`${id}__WasteHierarchy__prevention`}>
          {prevention || '0.00'}%
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__preparingForReuse`} name={`${id}__WasteHierarchy__preparingForReuse`}>
          {preparingForReuse || '0.00'}%
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__recycling`}>{recycling || '0.00'}%</Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__otherRecovery`} name={`${id}__WasteHierarchy__otherRecovery`}>
          {otherRecovery || '0.00'}%
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__disposal`} name={`${id}__WasteHierarchy__disposal`}>
          {disposal || '0.00'}%
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__unknown`} name={`${id}__WasteHierarchy__unknown`}>
          {unknown || '0.00'}%
        </Text>
      </ColStyled>
      <ColStyled item span={1.25}>
        {reference && (
          <TextLink id={`${id}__WasteHierarchy__reference`} name={`${id}__WasteHierarchy__reference`} href={reference} target="_blank">
            <Icon icon="external-link-alt" />
          </TextLink>
        )}
        {!reference && (
          <Text id={`${id}__WasteHierarchy__reference`} name={`${id}__WasteHierarchy__reference`}>
            -
          </Text>
        )}
      </ColStyled>
      <ColStyled item span={1.25}>
        <Text id={`${id}__WasteHierarchy__defaultOrCustom`} name={`${id}__WasteHierarchy__defaultOrCustom`}>
          {isGlobal ? 'Default' : 'Custom'}
        </Text>
      </ColStyled>

      <ColStyled item span={1.25}>
        <IconLink id={`${id}__WasteHierarchy__view`} name={`${id}__WasteHierarchy__view`} icon="eye" size="lg" onClick={showViewModal} />
      </ColStyled>
    </RowStyled>
  );
};

WasteHierarchyRow.propTypes = {
  id: PropTypes.string,
  wasteTreatmentProcessName: PropTypes.string,
  prevention: PropTypes.number,
  preparingForReuse: PropTypes.number,
  recycling: PropTypes.number,
  otherRecovery: PropTypes.number,
  disposal: PropTypes.number,
  reference: PropTypes.string
};

export default WasteHierarchyRow;
