import React from 'react';
import { Row, Col } from 'rio-ui-components';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl'
import EmailForm from './EmailForm';
import ContactDetails from './ContactDetails';
import NotificationBanner from '../../components/NotificationBanner';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ContentContainerStyled = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.basic.white};
`;

const ColStyled = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;
const Link = styled.a`
  text-decoration: underline;
`;
const RowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing} 0;
`;

const ContactContainer = ({ intl }) => {
  return (
    <Container>
      <RowStyled>
        <NotificationBanner
          title={intl.formatMessage({ id: 'pages.support.banner.header' })}
          body={
            <FormattedMessage 
              id="pages.support.banner.message"
              values={{
                Link: <Link 
                  href="https://support.rio.ai/knowledge" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  data-cy="Contact__SupportPageLink"
                >
                  <FormattedMessage id="pages.support.banner.link"/>
                </Link>
              }}
            />
          }
          dismissable={false}
          icon="question"
        />
      </RowStyled>
      <RowStyled container>
        <ColStyled container item span={3}>
          <ContentContainerStyled>
            <ContactDetails />
          </ContentContainerStyled>
        </ColStyled>

        <Col container item span={9}>
          <ContentContainerStyled>
            <EmailForm />
          </ContentContainerStyled>
        </Col>
      </RowStyled>
    </Container>
  );
};

export default injectIntl(ContactContainer);
