import { GET_CURRENT_ACCOUNT_ID } from '../queries/currentAccount';
import { getUsersCurrentAccountId, storeCurrentAccount } from '../utils/currentAccount';

export const defaults = {
  query: GET_CURRENT_ACCOUNT_ID,
  data: {
    currentAccountId: getUsersCurrentAccountId()
  }
};

export const resolvers = {
  Mutation: {
    setCurrentAccountId: (_, { currentAccountId }, { cache }) => {
      if (currentAccountId) {
        storeCurrentAccount(currentAccountId);
        cache.writeQuery({
          query: GET_CURRENT_ACCOUNT_ID,
          data: {
            currentAccountId
          }
        });
      }
    }
  }
};
