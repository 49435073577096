import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { DocumentLibrary } from '@rio/rio-types';
import { useCurrentAccountId } from '~/hooks/useCurrentAccountId';
import { makeManagementSystemCode } from '~/utils/managementSystemUtils';
import { UploadDocumentModal } from '../../DocumentModal/v2';
import { ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM } from '../../index.queries';

type ManagementSystem = {
  id: string;
};

type AttachDocumentsModalProps = {
  onError: (error: Error) => void;
  refetch: () => void;
  onDismiss: () => void;
  onComplete: (message: string) => void;
  managementSystem: ManagementSystem;
  chapter?: ManagementSystem;
};

export function AttachDocumentsModal({
  onDismiss,
  onError,
  onComplete,
  managementSystem,
  chapter,
  refetch,
}: AttachDocumentsModalProps) {
  const accountId = useCurrentAccountId();
  const [attachDocumentToManagementSystem] = useMutation(ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM);

  const managementCode = useMemo(
    () => makeManagementSystemCode(managementSystem, chapter || managementSystem),
    [chapter, managementSystem]
  );

  const handlerComplete = useCallback(
    async (message, uploadedDocuments, errors) => {
      if (errors.length) {
        return onError(new Error(`${errors.length} of your documents failed to upload and thus weren't attached.`));
      }
      await Promise.all(
        uploadedDocuments.map(async (document) => {
          try {
            await attachDocumentToManagementSystem({
              variables: {
                accountId,
                documentId: document.id,
                systemId: managementSystem.id,
                linkId: chapter ? chapter.id : managementSystem.id,
              },
            });
          } catch (error) {
            if (error instanceof Error) {
              onError(error);
            }
          }
        })
      );
      onComplete(`Documents have been successfully attached.`);
      refetch();
    },
    [accountId, attachDocumentToManagementSystem, chapter, managementSystem.id, onComplete, onError, refetch]
  );

  return (
    <UploadDocumentModal
      predefinedValues={{
        library: DocumentLibrary.Governance,
        category: 'Management Systems',
        managementSystemCode: managementCode,
      }}
      onDismiss={onDismiss}
      onError={onError}
      onComplete={handlerComplete}
    />
  );
}
