import {
  GET_PAGE,
  GET_SEARCH,
  GET_TOTAL_PAGES
} from '../../containers/ConfigurationContainer/ConfigurationMySuppliersContainer/index.queries';

const suppliersGridPage = 1;
const suppliersGridSearch = '';
const suppliersGridTotalPages = 1;

export const suppliersGridDefaults = [
  { query: GET_PAGE, data: { suppliersGridPage } },
  { query: GET_SEARCH, data: { suppliersGridSearch } },
  { query: GET_TOTAL_PAGES, data: { suppliersGridTotalPages } }
];

export const suppliersGridResolvers = {
  Mutation: {
    setSuppliersGridPage: async (_, { page }, { cache }) => {
      cache.writeQuery({
        query: GET_PAGE,
        data: {
          suppliersGridPage: page
        }
      });
      return page;
    },
    setSuppliersGridSearch: async (_, { search }, { cache }) => {
      cache.writeQuery({
        query: GET_SEARCH,
        data: {
          suppliersGridSearch: search
        }
      });
      return search;
    },
    setSuppliersGridTotalPages: async (_, { totalPages }, { cache }) => {
      cache.writeQuery({
        query: GET_TOTAL_PAGES,
        data: {
          suppliersGridTotalPages: totalPages
        }
      });
      return totalPages;
    }
  }
};
