import { gql } from '@apollo/client';

export const FLOW_DETAILS = gql`
  fragment FlowDetails on Flow {
    id
    progress {
      id
      remainingEstimate
    }
    title
    description
    thumbnailUrl
    totalEstimate
    tags
  }
`;

export const ACCOUNT_DETAILS = gql`
  fragment AccountDetails on Account {
    id
    name
    logoPath
    children {
      id
      name
      logoPath
    }
  }
`;

export const GET_ALL_FLOW_SUBSCRIPTIONS = gql`
  query GetFlowSubscriptions {
    getFlowSubscriptions {
      flow {
        ...FlowDetails
      }
      account {
        ...AccountDetails
      }
    }
  }
  ${FLOW_DETAILS}
  ${ACCOUNT_DETAILS}
`;

export const CREATE_FLOW_SUBSCRIPTION = gql`
  mutation CreateFlowSubscription($accountId: ID!, $flowId: ID!) {
    createFlowSubscription(accountId: $accountId, flowId: $flowId) {
      flow {
        ...FlowDetails
      }
      account {
        ...AccountDetails
      }
    }
  }
  ${FLOW_DETAILS}
  ${ACCOUNT_DETAILS}
`;

export const DELETE_FLOW_SUBSCRIPTION = gql`
  mutation DeleteFlowSubscription($accountId: ID!, $flowId: ID!) {
    deleteFlowSubscription(accountId: $accountId, flowId: $flowId) {
      flow {
        ...FlowDetails
      }
      account {
        ...AccountDetails
      }
    }
  }
  ${FLOW_DETAILS},
  ${ACCOUNT_DETAILS}
`;
