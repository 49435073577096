import { gql } from '@apollo/client';

export const GET_PROJECT_CATEGORIES = gql`
  query GetProjectCategories {
    getProjectCategories {
      id
      name
    }
  }
`;
