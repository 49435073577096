import React, { useState } from 'react';
import styled from 'styled-components';
import { Tabs, Tab, Col, Button } from 'rio-ui-components';
import { MarketCarbonConversionFactor } from '@rio/rio-types';
import ContainerHeader from '../../../components/ContainerHeader';
import { useGetMarketCarbonConversionFactors } from './useGetMarketCarbonConversionFactors';
import { TabContent } from '../../../components/TabContent';
import { MarketCarbonConversionFactorsGrid } from './MarketCarbonConversionFactorsGrid';
import { CreateMarketCarbonConversionFactorModal } from './CreateMarketCarbonConversionFactorModal';
import { usePermissions, useAgGrid } from '../../../hooks';

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;
const ActionsColumn = styled(Col)`
  justify-content: flex-end;
`;
const CreateButton = styled(Button)`
  align-self: flex-end;
  width: 200px;
`;

export function MarketBasedFactors() {
  const [tab, setTab] = useState(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const { loading, data, error, refetch } = useGetMarketCarbonConversionFactors();
  const factors = data?.getMarketCarbonConversionFactors || [];
  const permissions = usePermissions();
  const agGrid = useAgGrid();
  const editOrCreateAllowed = permissions.data.includes('createMarketCarbonConversionFactorAll');

  return (
    <Page>
      <ContainerHeader
        name="MarketBasedCarbonConversionFactors__Controls"
        icon="recycle"
        iconColor="primary"
        title="Market Based Carbon Conversion Factors"
      >
        <ActionsColumn span={6} container>
          {editOrCreateAllowed && (
            <CreateButton
              onClick={() => {
                setCreateModalVisible(true);
              }}
            >
              + Electricity Tariff
            </CreateButton>
          )}
        </ActionsColumn>
      </ContainerHeader>
      <Tabs variant="fullWidth" value={tab} onChange={(_: unknown, currentTab: number) => setTab(currentTab)}>
        <Tab label="Electricity" />
      </Tabs>
      <TabContent error={error} loading={loading} currentTab={tab} index={0} retry={refetch}>
        <MarketCarbonConversionFactorsGrid agGrid={agGrid} editAllowed={editOrCreateAllowed} factors={factors} />
      </TabContent>
      {createModalVisible && (
        <CreateMarketCarbonConversionFactorModal
          onComplete={(currentFactors: MarketCarbonConversionFactor[]) => {
            agGrid.api?.setRowData(currentFactors);
            setCreateModalVisible(false);
          }}
          onDismiss={() => {
            setCreateModalVisible(false);
          }}
        />
      )}
    </Page>
  );
}
