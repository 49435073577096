import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import GET_ELECTRICITY_TRANSACTIONS_PAGE from '../../../graphql/queries/electricity/GetElectricityTransactionsPage.query.graphql';
import {
  UploadReferenceCell,
  FactorUsedCell,
  TariffReference,
  DocumentReferenceV2 as DocumentReference,
} from '../../DataContainer/CellRenderers';
import { useElectricityGridConfig } from '../../DataContainer/ElectricityContainer/TransactionsContainer/useElectricityGridConfig';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Electricity' }];

export const ElectricityTransactionsGrid = () => {
  const config = useElectricityGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_ELECTRICITY_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables: object) => {
      const {
        data: {
          getElectricityTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title="Your electricity data"
      defaultExportFileName="electricity-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../electricity/uploads"
      config={{
        ...config,
        components: {
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          tariffReference: TariffReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={fetchRows}
    />
  );
};
