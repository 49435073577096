import { TransactionType } from '@rio/rio-types';
import UploadContainer from '../../UploadContainer';
import { TemplateType } from '../../../../types';
import wasteTemplateUrl from './waste-template.xlsx';

function WasteDataUploadContainer(props) {
  return (
    <UploadContainer
      {...props}
      template={{
        fileName: wasteTemplateUrl,
        type: TemplateType.Static,
        url: wasteTemplateUrl,
      }}
      dataSection={TransactionType.Waste}
    />
  );
}

export default WasteDataUploadContainer;
