import { isEmail } from 'validator';

export const passwordPattern = /^.{10,}$/;

export function validateCredentialsForm(form) {
  const errors = {};
  if (!form.email?.trim()) {
    errors.email = 'missingEmail';
  }
  if (form.email?.trim() && !isEmail(form.email?.trim())) {
    errors.email = 'invalidEmail';
  }
  if (!form.password?.trim()) {
    errors.password = 'missingPassword';
  }
  if (form.password && !passwordPattern.test(form.password)) {
    errors.password = 'passwordPattern';
  }
  if (!form.passwordConfirmed?.trim()) {
    errors.passwordConfirmed = 'confirmPassword';
  }
  if (form.password && form.passwordConfirmed && form.password !== form.passwordConfirmed) {
    errors.passwordConfirmed = `mismatchPassword`;
  }

  return errors;
}

export function validateProfileForm(form) {
  const errors = {};
  if (!form.preferPersonalUse && !form.companyName?.trim()) {
    errors.companyName = `companyName`;
  }
  if (!form.firstName?.trim()) {
    errors.firstName = `firstName`;
  }
  if (!form.lastName?.trim()) {
    errors.lastName = 'lastName';
  }
  return errors;
}
