import React, { useState } from 'react';
import { CounterPickerWrapper, DeleteIcon, LineHolder, PreviewText, Price, Title } from './CartStyles';
import { Col, Row } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import { COURSE_BUNDLE } from '../../constants/marketProductType';
import ProductModal from '../../components/ProductModal';
import { CountPicker } from '../UI/CountPicker';

export function CartLines(props) {
  const [modal, setModal] = useState({
    show: false,
    bundleInfo: null
  });
  const { cart = {}, title = '', lines = [], styles = 'base' } = props;

  const {
    addProduct,
    removeProduct,
    removeLine,
    state: {
      currency: { symbol }
    }
  } = cart;

  const className = `cart-lines cart-lines-${styles}`;

  const onDismiss = () => setModal((prevState) => ({ ...prevState, show: false }));

  const clickByPreview = (item) => {
    const firstCourse = item.previewProductInfo;
    setModal({
      show: true,
      bundleInfo: {
        id: item.id,
        thumbnail: firstCourse.metaData.thumbnail,
        title: firstCourse.name,
        description: firstCourse.metaData.description,
        price: item.price,
        product: item,
        hideBuyButton: true
      }
    });
  };

  return (
    <>
      {modal.show ? <ProductModal bundleInfo={modal.bundleInfo} addProduct={addProduct} onDismiss={onDismiss} /> : null}
      <div className={className}>
        <Row container>
          <Col span={9}>
            <h4 className="cart-lines-title">{title}</h4>
          </Col>

          <Col span={2}>
            <h4>Total Cost</h4>
          </Col>

          <Col span={1} />
        </Row>

        <LineHolder>
          {lines.map(([id, { totalAmount, parent, resizable, quantity, product = {} } = {}]) => {
            return (
              <div className="cart-line" key={id}>
                <Row container itemAlign="center" align="between" name="Cart-Lines__Container">
                  <Col span={4}>
                    <Title>{product.title}</Title>
                  </Col>

                  <Col span={3}>
                    {product.productType === COURSE_BUNDLE ? (
                      <Link
                        to={`/engage/${product.id}`}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          clickByPreview(product);
                        }}
                      >
                        <PreviewText>Preview</PreviewText>
                      </Link>
                    ) : null}
                  </Col>

                  <Col span={2}>
                    {resizable && (
                      <CounterPickerWrapper>
                        <CountPicker
                          value={quantity}
                          onIncrement={() => addProduct(product)}
                          onDecrement={() => removeProduct(product)}
                        />
                      </CounterPickerWrapper>
                    )}
                  </Col>

                  <Col span={2}>
                    <Price>
                      {symbol}
                      {totalAmount}
                    </Price>
                  </Col>

                  <Col span={1}>
                    {!parent && (
                      <DeleteIcon
                        name="Cart-Lines__Delete-Button"
                        onClick={() => removeLine(id)}
                        icon="times"
                        size="1x"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
        </LineHolder>
      </div>
    </>
  );
}
