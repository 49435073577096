"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_spinners_1 = require("react-spinners");
const options = {
    BarLoader: react_spinners_1.BarLoader,
    BeatLoader: react_spinners_1.BeatLoader,
    BounceLoader: react_spinners_1.BounceLoader,
    ClipLoader: react_spinners_1.ClipLoader,
    DotLoader: react_spinners_1.DotLoader,
    FadeLoader: react_spinners_1.FadeLoader,
    HashLoader: react_spinners_1.HashLoader,
    MoonLoader: react_spinners_1.MoonLoader,
    PropagateLoader: react_spinners_1.PropagateLoader,
    PulseLoader: react_spinners_1.PulseLoader,
    RotateLoader: react_spinners_1.RotateLoader,
    ScaleLoader: react_spinners_1.ScaleLoader,
    SyncLoader: react_spinners_1.SyncLoader,
};
const Loader = (props) => {
    const { spinner = 'SyncLoader', color = "#fff", ...rest } = props;
    const Spinner = options[spinner];
    return (0, jsx_runtime_1.jsx)(Spinner, { color: color, ...rest });
};
exports.Loader = Loader;
