export const colors = {
  grey: '#949597',
  pink: '#FD919C',
  green: '#00C8B5',
  yellow: '#FFCA83',
};

export const colorsScore = {
  positive: colors.green,
  neutral: colors.yellow,
  negative: colors.pink,
};
