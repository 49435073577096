import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Col, Row, Heading, TextInput, Label, Search, Button, Select, Checkbox, TextArea } from 'rio-ui-components';
import { orderBy } from 'lodash';
import LearnBundleRows from './LearnBundleRows';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { saveLearnBundle } from './api';
import { usePermissions } from '../../../hooks';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const ColStyledSearch = styled(Col)`
  padding-top: ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: ${(props) => props.theme.geometry.xs.spacing};
  padding-left: ${(props) => props.theme.geometry.xs.spacing};
  padding-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const HeadingStyled = styled(Heading)`
  margin: auto;
  padding-left: 100px;
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
`;

const HeaderCol = styled(Col)`
  flex-direction: unset;
  align-items: center;
  justify-content: center;
`;

const SaveCol = styled(Col)`
  position: inherit;
  white-space: nowrap;
`;

const contentType = [
  { label: 'Free', value: 'Free' },
  { label: 'Restricted', value: 'Restricted' }
];

const getCheckedElements = (bundle, courses) => {
  const result = {};
  if (bundle.hasOwnProperty('courses') && bundle.courses.length) {
    const coursesObject = {};
    courses.forEach((course) => (coursesObject[course.id] = course));
    bundle.courses.forEach((id, index) => {
      result[id] = {
        checked: true,
        order: index + 1,
        typingOrder: null,
        course: coursesObject[id]
      };
    });
  }
  return result;
};

const AddLearnBundle = ({ bundle, client, courses, onSuccess, edit, loading }) => {
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value.toLowerCase());
  };
  const permissions = usePermissions();

  if (!bundle) {
    bundle = { id: null, title: null, description: null };
  }

  const [state, setState] = useState({
    id: bundle.id || uuid(),
    title: bundle.title || '',
    description: bundle.description || '',
    restricted: bundle.restricted ? 'Restricted' : 'Free',
    checkedElements: getCheckedElements(bundle, courses),
    enableSaveButton: false,
    isEngage: bundle.isEngage
  });
  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleTypeChange = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        restricted: e.target.value
      };
    });
  };

  const onChecked = (course) => {
    const checkedElements = { ...state.checkedElements };

    if (checkedElements.hasOwnProperty(course.id)) {
      checkedElements[course.id].checked = !state.checkedElements[course.id].checked;
    } else {
      const changeItem = {
        checked: true,
        order: '',
        typingOrder: null,
        course: course
      };
      checkedElements[course.id] = changeItem;
    }

    setState((prevState) => {
      return {
        ...prevState,
        checkedElements: checkedElements
      };
    });
  };

  const saveButton = async () => {
    let [sortedElement, unsortedElement] = [[], []];
    Object.values(state.checkedElements).forEach((element) => {
      if (!element.checked) return;
      if (element.order) {
        sortedElement.push(element);
      } else {
        unsortedElement.push(element);
      }
    });

    sortedElement = orderBy(sortedElement, 'order', 'asc');
    unsortedElement = orderBy(unsortedElement, [(item) => item.course.name.toLowerCase()], 'asc');
    const sortedArray = [...sortedElement, ...unsortedElement];

    const listIdArray = sortedArray.map(({ course }) => course.id);

    const saveValue = {
      id: state.id,
      title: state.title,
      description: state.description,
      restricted: state.restricted === 'Restricted',
      courses: listIdArray,
      isEngage: state.isEngage
    };

    await saveLearnBundle(saveValue, client);
    onSuccess();
  };

  const changeTyping = (e, course) => {
    const order = e.target.value;
    if (order.match(/^\d+$/) || !order) {
      const checkedElements = { ...state.checkedElements };

      if (checkedElements?.[course.id]) {
        checkedElements[course.id].typingOrder = +order;
      } else {
        const changeItem = {
          checked: false,
          order: '',
          typingOrder: +order,
          course: course
        };
        checkedElements[course.id] = changeItem;
      }

      setState((prevState) => {
        return {
          ...prevState,
          checkedElements
        };
      });
    }
  };

  const changeOrder = (course) => {
    const checkedElements = { ...state.checkedElements };
    Object.keys(checkedElements).map((id) => {
      if (checkedElements[id].order === checkedElements[course.id].typingOrder && course.id !== id) {
        checkedElements[id].order = '';
      }
      return id;
    });
    checkedElements[course.id].order = checkedElements[course.id].typingOrder;
    checkedElements[course.id].typingOrder = null;

    setState({
      ...state,
      checkedElements
    });
  };

  return (
    <Container name="create-content">
      <Row container align="between">
        <HeaderCol container span="24">
          <HeadingStyled size="lg">Bundle content</HeadingStyled>

          <SaveCol container>
            <Button color="success" submit disabled={!state.title || !state.description} onClick={saveButton}>
              {edit ? 'Update bundle' : 'Save bundle'}
            </Button>
          </SaveCol>
        </HeaderCol>
      </Row>

      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Title</LabelStyled>
          </LabelContainerStyled>
          <TextInput onChange={handleChange} name="title" value={state.title} box />
        </ColStyled>
      </Row>

      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Content type</LabelStyled>
          </LabelContainerStyled>
          <Select onChange={handleTypeChange} name="type" value={state.restricted} box options={contentType} />
        </ColStyled>
      </Row>

      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Description</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            disabled={state.isSubmitted}
            onChange={handleChange}
            name="description"
            value={state.description}
            box
          />
        </ColStyled>
      </Row>

      {permissions.learn.includes('createCourseAll') && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Include into Engage subscription</LabelStyled>
            </LabelContainerStyled>
            <Checkbox
              checked={state.isEngage}
              size="md"
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  isEngage: e.target.checked
                }));
              }}
            />
          </ColStyled>
        </Row>
      )}

      <Row container align="between">
        <ColStyledSearch item>
          <Search
            container
            name="ConfigurationLearnUploadContainer__Controls__Search"
            hideButton
            onChange={handleSearchChange}
            value={search}
          />
        </ColStyledSearch>
      </Row>

      {loading ? (
        <ContainerLoadingIndicator name="CoursePreview__Loading" />
      ) : (
        <LearnBundleRows
          client={client}
          search={search}
          courses={courses}
          checkedElements={state.checkedElements}
          changeTyping={changeTyping}
          changeOrder={changeOrder}
          onChecked={onChecked}
        />
      )}
    </Container>
  );
};
export default AddLearnBundle;
