import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'rio-ui-components';
import LegislationDetails from './LegislationDetails';
import { CREATE_LEGISLATION } from './index.queries';
import { v4 as uuid } from 'uuid';
import { ValidateLegislation } from './validate';
import { useCurrentUserId, useCurrentAccountId } from '../../../hooks';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const CreateLegislationModal = ({ onDismiss, onComplete }) => {
  const userId = useCurrentUserId();
  const accountId = useCurrentAccountId();
  const [createLegislation] = useMutation(CREATE_LEGISLATION, {
    onCompleted: () => {
      onComplete({
        message: 'Legislation has been created.',
        colour: 'success'
      });
      onDismiss();
    },
    onError: () => {
      onComplete({
        message: 'Something went wrong! Please try again later.',
        colour: 'danger'
      });
    }
  });

  const [legislation, setLegislation] = useState({
    title: '',
    synopsis: '',
    links: '',
    countries: [],
    apiData: ''
  });

  const handleSubmit = () => {
    createLegislation({
      variables: {
        legislation: { ...convertToLegislationInput(legislation) },
        userId,
        accountId
      }
    });
  };

  const convertToLegislationInput = (legislation) => {
    return {
      id: uuid(),
      ...legislation,
      countries: legislation.countries.map((country) => country.value),
      links: legislation.links.split(','),
      isActive: true,
      type: 'LEGAL',
      apiData: {
        path: legislation.apiData
      }
    };
  };

  return (
    <Modal size="md" show onDismiss={() => onDismiss()}>
      <ContainerStyled>
        <LegislationDetails
          heading="Create New Legislation"
          legislation={legislation}
          setLegislation={setLegislation}
        />
        <Row container align="between">
          <ColStyled item>
            <Button
              disabled={!ValidateLegislation(convertToLegislationInput(legislation))}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </ColStyled>
        </Row>
      </ContainerStyled>
    </Modal>
  );
};

export default CreateLegislationModal;
