import { gql } from '@apollo/client';

export const NORMALISER_FIELDS = gql`
  fragment NormaliserFields on Normaliser {
    id
    name
    description
    quantity
    accountId
    locationId
    location {
      name
    }
    tagId
    startDate
    endDate
    type {
      id
      name
    }
  }
`;

export const GET_NORMALISERS_PAGE = gql`
  query GetNormalisersPage(
    $accountId: ID!
    $limit: Int = 25
    $offset: Int = 0
    $sort: [SortCommand!] = [{ field: "name", order: "asc" }]
    $filters: Filters = {}
  ) {
    getNormalisersPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...NormaliserFields
      }
    }
  }
  ${NORMALISER_FIELDS}
`;

export const GET_NORMALISERS_PAGE_FILTER = gql`
  query GetNormalisersPageFilter($accountId: ID!, $field: String!) {
    getNormalisersPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;

export const GET_NORMALISERS_BY_ID = gql`
  query GetNormaliserById($id: ID!) {
    getNormaliserById(id: $id) {
      ...NormaliserFields
    }
  }
  ${NORMALISER_FIELDS}
`;

export const UPDATE_NORMALISER = gql`
  mutation UpdateNormaliser(
    $id: ID!
    $name: String!
    $description: String!
    $quantity: Float!
    $accountId: String!
    $locationId: String
    $startDate: String!
    $endDate: String!
    $tagId: String
    $type: ID!
  ) {
    updateNormaliser(
      input: {
        id: $id
        name: $name
        description: $description
        quantity: $quantity
        accountId: $accountId
        locationId: $locationId
        startDate: $startDate
        endDate: $endDate
        tagId: $tagId
        type: $type
      }
    ) {
      ... on Normaliser {
        ...NormaliserFields
      }
      ... on UpdateNormaliserError {
        error
      }
    }
  }
  ${NORMALISER_FIELDS}
`;

export const CREATE_NORMALISERS = gql`
  mutation CreateNormalisers($normalisers: [NormaliserInput!]!) {
    createNormalisers(normalisers: $normalisers) {
      ... on Normalisers {
        normalisers {
          ...NormaliserFields
        }
      }
      ... on CreateNormalisersErrors {
        errors
      }
    }
  }
  ${NORMALISER_FIELDS}
`;

export const GET_NORMALISER_TYPES = gql`
  {
    getNormaliserTypes {
      id
      name
      scopes
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: ID!) {
    getLocationById(id: $id) {
      id
      name
    }
  }
`;
