import { useApolloClient } from '@apollo/client';
import { SetFilterValuesFuncParams } from 'ag-grid-community';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import GET_WATER_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/water/GetWaterTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useWaterGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getWaterTransactionsPageFilter: values },
      } = await client.query({
        query: GET_WATER_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(
        values.map((item: { value: Record<string, unknown> }) => (item.value ? JSON.stringify(item) : item.value))
      );
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Meter Name',
        field: 'meterName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location',
        field: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location Code',
        field: 'locationCode',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Supplier',
        field: 'supplier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Water In (m³)',
        field: 'waterIn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Water Out (m³)',
        field: 'waterOut',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Reading',
        field: 'reading',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Actual/Estimated',
        field: 'accuracy',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }: { value: string }) => (value === 'ACT' ? 'Actual' : 'Estimated'),
      },
      {
        headerName: 'Tariff',
        field: 'tariff',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Cost (VAT)',
        field: 'totalCostVat',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Emissions from Water Supply (kgCO2e)',
        headerTooltip: 'Emissions from Water Supply (kgCO2e)',
        field: 'inEmissions',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Emissions from Water Treatment (kgCO2e)',
        headerTooltip: 'Emissions from Water Treatment (kgCO2e)',
        field: 'outEmissions',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Scope 3 emissions (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope3',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Comment',
        field: 'comment',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Invoice Number',
        field: 'invoiceNumber',
        filter: 'agTextColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Country',
        field: 'energySource.locationPoint.location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'energySource.locationPoint.location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filter: 'agTextColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Factors used',
          headerTooltip: 'The factor set applied to this transaction, click the link to see the factors for this year in more detail',
        field: 'reportingYearOfFactors',
        cellRenderer: 'factorReference',
        cellRendererParams: {
          to: '/configuration/carbonconversionfactors/location/water',
        },
        filter: false,
      },
    ],
  };
}
