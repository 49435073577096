import { useMemo } from 'react';
import { Survey } from '@rio/rio-types';
import { ProgressBarChart } from './ProgressBarChart';
import { calculateScores } from './chartConfigs';
import { BottomChart, Heading3 } from './styles';

interface CategoryPerformanceChartProps {
  survey: Survey;
}

export function CategoryPerformanceChart({ survey }: CategoryPerformanceChartProps) {
  const { categoryNames, totalCategoryScores, categoryScores } = useMemo(() => calculateScores(survey), [survey]);

  return !!categoryNames.length ? (
    <BottomChart>
      <Heading3 typescale="title" size="medium">
        Category performance
      </Heading3>
      {categoryNames.map((categoryName, index) => {
        const score = categoryScores[categoryName] || 0;
        const total = totalCategoryScores[categoryName];

        return (
          <ProgressBarChart key={`catergory__progress-bar-${index}`} text={categoryName} totals={[score, total]} />
        );
      })}
    </BottomChart>
  ) : null;
}
