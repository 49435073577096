import { useMutation, useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { CREATE_CUSTOM_WASTE_HIERARCHY } from './index.queries';
import { TreatmentProcessSelect } from './TreatmentProcessSelect';
import { checkValuesSumsEqual100, checkValues } from './validator';
import { resolveHierarchyByTreatmentId } from './resolveHierarchyByTreatmentId';
import { updateHierarchiesInCache } from './dataAccessors';

const OuterContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const defaultState = {
  treatmentProcessId: { value: '', select: { value: '', label: '' }, error: '' },
  prevention: { value: '', error: '', name: 'prevention', label: 'Prevention' },
  preparingForReuse: { value: '', error: '', name: 'preparingForReuse', label: 'Preparing for Reuse' },
  recycling: { value: '', error: '', name: 'recycling', label: 'Recycling' },
  otherRecovery: { value: '', error: '', name: 'otherRecovery', label: 'Other Recovery' },
  disposal: { value: '', error: '', name: 'disposal', label: 'Disposal' },
  unknown: { value: '', error: '', name: 'unknown', label: 'Unknown' },
  reference: { value: '', error: '' },
  isSubmitting: false,
};

const Title = styled(PropertySheet.HeaderRow)`
  margin-bottom: 0;
`;

const Subtitle = styled(PropertySheet.HeaderRow)`
  font-weight: 400;
`;

export function CreateWasteHierarchies({ onNotification, onDismiss, accountId, currentWasteIn }) {
  const [state, setState] = useState(defaultState);
  const [createWasteHierarchy] = useMutation(CREATE_CUSTOM_WASTE_HIERARCHY);
  const client = useApolloClient();

  const getDefaultWasteHierarchyPercentages = async (treatmentProcessId) => {
    const currentWasteInId = currentWasteIn ? currentWasteIn.id : null;
    const placeholders = await resolveHierarchyByTreatmentId(client, treatmentProcessId, accountId, currentWasteInId);
    const propertySheetArr = ['prevention', 'preparingForReuse', 'recycling', 'otherRecovery', 'disposal', 'unknown'];
    setState((prevState) => {
      const chunk = {};
      propertySheetArr.forEach((prop) => {
        chunk[prop] = {
          ...prevState[prop],
          value: placeholders ? String(placeholders[prop]) : '',
        };
      });
      return {
        ...prevState,
        ...chunk,
      };
    });
  };
  const onWasteTreatmentChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      treatmentProcessId: {
        ...prevState.treatmentProcessId,
        error: '',
        value: e.target.value,
        select: e.target.select,
      },
    }));
  };
  const onChange = ({ target: { name, value } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: '',
        value: value,
      },
    }));
  };
  const onSubmit = async () => {
    const {
      treatmentProcessId,
      prevention,
      preparingForReuse,
      recycling,
      otherRecovery,
      disposal,
      unknown,
      reference,
    } = state;
    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));
    treatmentProcessId.error = checkValues(treatmentProcessId.value, 'Treatment Process');
    prevention.error = checkValues(prevention.value, 'Prevention');
    preparingForReuse.error = checkValues(preparingForReuse.value, 'Preparing for Reuse');
    recycling.error = checkValues(recycling.value, 'Recycling');
    otherRecovery.error = checkValues(otherRecovery.value, 'Other Recovery');
    disposal.error = checkValues(disposal.value, 'Disposal');
    unknown.error = checkValues(unknown.value, 'Unknown');

    if (
      treatmentProcessId.error ||
      prevention.error ||
      preparingForReuse.error ||
      recycling.error ||
      otherRecovery.error ||
      disposal.error ||
      unknown.error
    ) {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
      return;
    }

    let httpRef = reference.value;

    if (!httpRef.match(/https?:\/\/.*/g) && httpRef.trim() !== '') {
      httpRef = `https://${httpRef}`;
    }

    const values = [
      prevention.value,
      preparingForReuse.value,
      recycling.value,
      otherRecovery.value,
      disposal.value,
      unknown.value,
    ];
    const sumTo100 = checkValuesSumsEqual100(values);

    if (sumTo100) {
      onNotification(sumTo100, 'danger');
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
      return;
    }
    try {
      const {
        data: { createCustomWasteHierarchy },
      } = await createWasteHierarchy({
        variables: {
          input: {
            accountId,
            treatmentProcessId: treatmentProcessId.value,
            prevention: prevention.value,
            preparingForReuse: preparingForReuse.value,
            recycling: recycling.value,
            otherRecovery: otherRecovery.value,
            disposal: disposal.value,
            unknown: unknown.value,
            reference: httpRef,
            wasteInId: currentWasteIn ? currentWasteIn.id : null,
          },
        },
      });
      await updateHierarchiesInCache(client, accountId, createCustomWasteHierarchy);
      onNotification('Waste Hierarchy successfully set', 'success', createCustomWasteHierarchy.id);
      onDismiss();
    } catch (err) {
      onNotification('Waste Hierarchy was not set', 'danger');
    }
  };
  const { isSubmitting, prevention, preparingForReuse, recycling, otherRecovery, disposal, unknown } = state;
  const propertySheetArr = [prevention, preparingForReuse, recycling, otherRecovery, disposal, unknown];

  return (
    <OuterContainer>
      <Title>Set Custom Waste Hierarchy Percentages</Title>
      {currentWasteIn && (
        <Subtitle>
          For Waste In location: <em>{currentWasteIn.name}</em>
        </Subtitle>
      )}
      <PropertySheet.Row>
        <TreatmentProcessSelect
          onWasteTreatmentChange={(e) => {
            onWasteTreatmentChange(e);
            getDefaultWasteHierarchyPercentages(e.target.value);
          }}
          disabled={isSubmitting}
          validationError={state.treatmentProcessId.error}
          wasteTreatmentId={state.treatmentProcessId.select}
        />
      </PropertySheet.Row>
      {propertySheetArr.map((form) => (
        <PropertySheet.Row key={form.name}>
          <PropertySheet.TextInputColumn
            disabled={isSubmitting}
            name={form.name}
            type="number"
            label={form.label}
            error={state[form.name].error}
            value={state[form.name].value}
            onChange={onChange}
            placeholder={state[form.name].placeholder}
          />
        </PropertySheet.Row>
      ))}
      <PropertySheet.Row>
        <PropertySheet.TextInputColumn
          disabled={isSubmitting}
          name="reference"
          type="text"
          label="Reference"
          error={state.reference.error}
          value={state.reference.value}
          onChange={onChange}
        />
      </PropertySheet.Row>
      <PropertySheet.Row>
        <PropertySheet.ButtonColumn disabled={isSubmitting} onClick={onSubmit} name="wasteHierarchySubmitButton">
          Set Percentages
        </PropertySheet.ButtonColumn>
      </PropertySheet.Row>
    </OuterContainer>
  );
}
