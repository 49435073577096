import { gql } from '@apollo/client';
export const GET_All_COUNTRIES = gql`
  query GetAllCountries {
    getAllCountries {
      id
      numericCode
      name
      alpha2Code
    }
  }
`;
