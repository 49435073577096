"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAgGrid = void 0;
const react_1 = require("react");
const AG_GRID_PARAMS = 'AG_GRID_PARAMS_V2';
function useAgGrid({ gridKey }) {
    const [state, setState] = (0, react_1.useState)();
    const agGridAll = (0, react_1.useMemo)(() => {
        const agGridParamStorage = localStorage.getItem(AG_GRID_PARAMS);
        let agGridAll = null;
        if (agGridParamStorage) {
            agGridAll = JSON.parse(agGridParamStorage);
        }
        return agGridAll;
    }, []);
    const agGridParams = agGridAll ? agGridAll[gridKey] : null;
    const onGridReady = (0, react_1.useCallback)((params) => {
        setState((prevState) => ({
            ...prevState,
            gridApi: params.api,
        }));
    }, []);
    const saveAgGridToLocalStorage = (saveObject) => {
        const agGridParamStorage = localStorage.getItem(AG_GRID_PARAMS);
        let agGridPrev = null;
        if (agGridParamStorage) {
            agGridPrev = JSON.parse(agGridParamStorage);
        }
        const agGridParams = agGridPrev && gridKey
            ? { ...agGridPrev, [gridKey]: { ...agGridPrev[gridKey], ...saveObject } }
            : { [gridKey]: saveObject };
        localStorage.setItem(AG_GRID_PARAMS, JSON.stringify(agGridParams));
    };
    const onFilterChanged = (0, react_1.useCallback)((params) => {
        const filterModel = params.api.getFilterModel();
        saveAgGridToLocalStorage({ filterModel });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDestroy = (0, react_1.useCallback)(() => {
        setState(null);
    }, []);
    const onSaveGridColumnState = (0, react_1.useCallback)(() => {
        if (!state?.gridApi)
            return;
        const columnState = state?.gridApi?.getColumnState();
        saveAgGridToLocalStorage({ columnState });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.gridApi]);
    const onSortChanged = (0, react_1.useCallback)((sortState) => {
        if (!sortState?.api)
            return;
        const columnState = sortState?.api?.getColumnState();
        saveAgGridToLocalStorage({ columnState });
    }, []);
    const onPaginationChanged = (0, react_1.useCallback)((paginationState) => {
        if (!paginationState?.api)
            return;
        if (paginationState.newPage || paginationState.newPageSize) {
            const page = paginationState?.api.paginationGetCurrentPage();
            const pageSize = paginationState?.api.paginationGetPageSize();
            saveAgGridToLocalStorage({
                paginationState: { page, pageSize },
            });
        }
    }, []);
    const api = (0, react_1.useMemo)(() => {
        const hiddenColIds = agGridParams?.columnState
            ?.filter((colState) => colState.hide)
            ?.map((colStateInner) => colStateInner.colId);
        const pinnedState = agGridParams?.columnState?.reduce((acc, curVal) => {
            if (!hiddenColIds?.includes(curVal.colId)) {
                switch (curVal.pinned) {
                    case 'left': {
                        acc.leftColIds.push(curVal.colId);
                        break;
                    }
                    case 'right': {
                        acc.rightColIds.push(curVal.colId);
                        break;
                    }
                    default:
                        break;
                }
            }
            return acc;
        }, {
            leftColIds: [],
            rightColIds: [],
        });
        const initialColStates = agGridParams?.columnState
            ? {
                columnPinning: {
                    leftColIds: pinnedState?.leftColIds,
                    rightColIds: pinnedState?.rightColIds,
                },
                columnVisibility: {
                    hiddenColIds,
                },
                sort: {
                    sortModel: agGridParams?.columnState?.reduce((acc, curVal) => {
                        if (curVal.sort) {
                            acc.push({
                                colId: curVal.colId,
                                sort: curVal.sort,
                            });
                        }
                        return acc;
                    }, []),
                },
            }
            : {};
        const initialFilterState = agGridParams?.filterModel
            ? {
                filter: { filterModel: agGridParams?.filterModel },
            }
            : {};
        return {
            onGridReady,
            onFilterChanged,
            onSortChanged,
            onSaveGridColumnState,
            onDestroy,
            onPaginationChanged,
            api: state?.gridApi,
            initialState: agGridParams
                ? {
                    ...initialColStates,
                    ...initialFilterState,
                    pagination: agGridParams?.paginationState,
                }
                : {},
        };
    }, [
        onGridReady,
        onFilterChanged,
        onSortChanged,
        onPaginationChanged,
        onSaveGridColumnState,
        onDestroy,
        state,
        agGridParams,
    ]);
    return api;
}
exports.useAgGrid = useAgGrid;
