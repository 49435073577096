import { v4 as uuidv4 } from 'uuid';
import { LearnContentType } from '@rio/rio-types';
import { convertFileToBase64String } from '../../../utils/file';

export const sortCourses = (courses) => {
  const compare = (a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
  const usedIds = [];
  const sortedCourses = courses
    .slice()
    .sort(compare)
    .filter((course) => {
      if (!usedIds.includes(course.id)) {
        usedIds.push(course.id);
        return course;
      }
      return null;
    });
  return sortedCourses;
};

export const getThumbnailSource = async (thumbnail) => {
  if (typeof thumbnail === 'string') {
    return thumbnail;
  } else if (thumbnail instanceof File) {
    return convertFileToBase64String(thumbnail);
  }
  return null;
};

export const getScormPath = (initiateScormUploadResponse) => {
  for (const file of initiateScormUploadResponse.values()) {
    const { contentPath } = file;
    const [s, courseType, id, version, ...filePath] = contentPath.split('/');

    if (contentPath.includes('indexAPI.html') || contentPath.includes('index_lms.html')) {
      return { id, contentPath, version, courseType, filePath, s };
    }
  }
  return { isNotScorm: true };
};

export const getContentPath = (type, id, name, version) => {
  return `/${type}/${id}/${version}/${name}`;
};

export const checkFileSizeIsValid = (file) => {
  return file.size < 5000000000;
};

export const fileInvalidErrorMessage = (file) => {
  return `File ${file.webkitRelativePath || file.name} is ${Math.round(
    file.size / 5000000000
  )}MB which exceeds the maximum upload limit of 5 GB.\n`;
};

export const isFormInvalid = (form) => {
  return (
    !form.title ||
    form.isSubmitted ||
    !form.description ||
    (!form.content && form.type !== LearnContentType.Url) ||
    (!form.url && form.type === LearnContentType.Url) ||
    !form.type ||
    !form.thumbnail ||
    form.invalidFiles.length > 0
  );
};

export const getFolderErrorMessage = (invalidFiles) => {
  if (invalidFiles.length <= 0) {
    return null;
  }
  const errorMessage = invalidFiles.map((f) => {
    return fileInvalidErrorMessage(f);
  });
  return errorMessage.join('\n');
};

export const findInvalidFiles = (files) => {
  const invalidFiles = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    if (!checkFileSizeIsValid(file)) {
      invalidFiles.push(file);
    }
  }
  return invalidFiles;
};

export const getLearnContentUrl = (path) => `${window.location.origin}${path}`;

export const enhanceMetadata = (contentData, presignedUrl) => {
  if (!presignedUrl) {
    return contentData;
  }
  const { initiateLearnFileUpload, initiateScormFileUpload } = presignedUrl;
  if (initiateLearnFileUpload) {
    const version = uuidv4();
    // eslint-disable-next-line prefer-const
    let { id, contentPath, url } = initiateLearnFileUpload;
    if (contentData.id) {
      id = contentData.id;
    }
    return { ...contentData, id, contentPath, version, url };
  }
  if (initiateScormFileUpload) {
    // eslint-disable-next-line prefer-const
    let { id, contentPath, version, isNotScorm } = getScormPath(initiateScormFileUpload);

    if (contentData.id) {
      id = contentData.id;
    }
    if (isNotScorm) {
      throw new Error('No main Scorm file found');
    }
    const presignedLinks = initiateScormFileUpload.map((result) => result.url);
    return { ...contentData, id, contentPath, version, presignedLinks };
  }
  return contentData;
};
