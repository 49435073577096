import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useGridConfig } from '../InvestmentContainer/TransactionsContainer/useGridConfig';
import { GET_INVESTMENT_TRANSACTIONS_BY_ACCOUND_ID } from '../InvestmentContainer/TransactionsContainer/index.queries';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Investment' }];

export const InvestmentTransactionsGrid = () => {
  const config = useGridConfig();

  const [query] = usePageSuspendingLazyQuery(GET_INVESTMENT_TRANSACTIONS_BY_ACCOUND_ID, {
    fetchPolicy: 'network-only',
  });

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getInvestmentTransactionsByAccountId: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title="Your investment data"
      defaultExportFileName="investment-transactions"
      breadcrumbs={breadcrumbs}
      uploadLink="../investment/uploads"
      config={config}
      fetchRows={fetchRows}
    />
  );
};
