import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Modal, Text, Button, styled } from '@rio/ui-components';
import { useNotification } from '~/hooks';

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TextStyled = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
  font-size: ${({ theme }) => theme.sys.typescale.body.large.size}px;
  font-family: ${({ theme }) => theme.sys.typescale.body.large.family};
  text-decoration: underline;
  text-align: center;
`;

const ModalStyled = styled(Modal)`
  .TitleClassName {
    text-align: center;
  }
`;

export const VerifyEmailPrompt = ({ email, show, onDismiss, queryParams }) => {
  const { showNotification } = useNotification();

  const resend = useCallback(async () => {
    if (email) {
      try {
        await Auth.resendSignUp(email.trim().toLowerCase());
        showNotification('Email has been sent again!');
      } catch (err) {
        if (err instanceof Error) {
          showNotification(`Email wasn't sent: ${err.message}`);
        } else {
          showNotification(`An unexpected error occurred: ${String(err)}`);
        }
      }
    }
  }, [email, showNotification]);

  return (
    <ModalStyled title="Verify your email address" open={show} onClose={onDismiss} titleClassName="TitleClassName">
      <WrapperStyled>
        <TextStyled typescale="body" size="large">
          In order to start using your Rio account, you need to confirm your email address.
        </TextStyled>
        <ButtonStyled to={`/verify-email${queryParams}`} variant="filled">
          Verify Email Address
        </ButtonStyled>
        <LinkStyled to="" onClick={resend}>
          Not received your confirmation? Resend Email
        </LinkStyled>
        <LinkStyled to="/v2/register" onClick={onDismiss}>
          Change Email Address
        </LinkStyled>
      </WrapperStyled>
    </ModalStyled>
  );
};
