import React from 'react';
import { Row, Col, Heading, ProfilePicture } from 'rio-ui-components';
import styled from 'styled-components';

import CustomRouteLink from '../../../components/CustomRouteLink';

const LegislationRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const LegislationProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const LegislationRow = ({ id, title, path }) => {
  return (
    <CustomRouteLink name="configuration-menu__item--contractors" to={`${path}/${id}`}>
      <LegislationRowStyled name={`legislation-row--${id}`} container itemAlign="center">
        <LegislationProfilePictureStyled name={`legislation-row__profile-picture--${id}`} size="xs" accountName={title} />
        <Col container item>
          <Heading inline name={`legislation-row__name--${id}`} size="md">
            {title}
          </Heading>
        </Col>
      </LegislationRowStyled>
    </CustomRouteLink>
  );
};

export default LegislationRow;
