import { styled, Text } from '@rio/ui-components';
import { FormattedMessage } from 'react-intl';

type StyleType = {
  idx: number;
};

const ProfileContainer = styled('div')<StyleType>`
  position: absolute;
  background-color: ${({ theme }) => theme.sys.color.surface};
  border: 2px solid ${({ theme }) => theme.sys.color.outlineVariant};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  left: ${(props) => props.idx * 20}px;
`;

const ProfilePicture = styled('img')<StyleType>`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.sys.color.outlineVariant};
  object-fit: contain;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  z-index: 2;
  left: ${(props) => props.idx * 20}px;
`;

const WrapperStyled = styled('div')`
  position: relative;
  height: 46px;
  padding: 0;
`;

export const OwnersCell = (props) => {
  const { data: { owners = [] } = {} } = props;

  const pictures = owners
    .slice()
    .reverse()
    .map((owner, idx) => {
      return owner.profilePicture ? (
        <ProfilePicture idx={idx} key={owner.profilePicture + owner.first_name} src={owner.profilePicture} />
      ) : (
        <ProfileContainer idx={idx}>
          <Text typescale="headline" size="small">
            {owner.first_name.charAt(0).toUpperCase()}
          </Text>
        </ProfileContainer>
      );
    });

  return (
    <>
      {!!owners.length ? (
        <WrapperStyled>{pictures}</WrapperStyled>
      ) : (
        <Text typescale="body" size="medium">
          <FormattedMessage id={'pages.task.grid.column.emptyOwners'} />,
        </Text>
      )}
    </>
  );
};
