import { SvgIconProps } from './types';
import { Svg } from './Svg';

export const Survey = ({ width = 50, height = 50, fill = '#fd978b', ...rest }: SvgIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M25.0023 10C23.6783 10 22.5856 11.073 22.5856 12.3731V12.7207H21.4695C21.2417 12.7217 21.0236 12.8113 20.863 12.9703C20.7025 13.129 20.6127 13.3441 20.6137 13.5679V16.4204C20.6127 16.6442 20.7025 16.8591 20.863 17.018C21.0236 17.1768 21.2416 17.2666 21.4695 17.2674H28.5303C28.7593 17.2683 28.9791 17.1795 29.1412 17.0203C29.303 16.8613 29.3938 16.6453 29.3928 16.4205V13.5679C29.3938 13.3431 29.303 13.127 29.1412 12.9679C28.9791 12.8089 28.7593 12.7201 28.5303 12.7208H27.4192V12.3731C27.4192 11.073 26.3263 10 25.0023 10Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7737 14.1545C15.5616 14.1754 15.3648 14.273 15.2219 14.4282C15.0788 14.5836 14.9998 14.7856 15 14.9949V36.1595C15.001 36.3834 15.0923 36.5975 15.2541 36.7552C15.4158 36.9128 15.6347 37.0009 15.8628 37H34.1442C34.3709 36.9991 34.588 36.9103 34.7484 36.7529C34.9085 36.5954 34.999 36.3822 35 36.1594V14.9948C34.999 14.7722 34.9085 14.559 34.7484 14.4016C34.588 14.2441 34.3707 14.1552 34.144 14.1545H31.1114V16.4137C31.1165 17.7988 29.9341 18.9602 28.5234 18.955H21.4695L21.4628 18.9548C20.0596 18.9487 18.8898 17.7914 18.8951 16.4135V14.1543H15.8625C15.833 14.1528 15.8034 14.153 15.7737 14.1545ZM31.2191 22.9759C31.7695 23.4403 31.8191 24.2403 31.3299 24.7627L24.2188 32.3565C23.9657 32.6267 23.6031 32.7812 23.2222 32.7812C22.8414 32.7812 22.4787 32.6267 22.2257 32.3565L18.6701 28.5596C18.1809 28.0372 18.2305 27.2372 18.7809 26.7728C19.3312 26.3084 20.174 26.3555 20.6632 26.8779L23.2222 29.6106L29.3368 23.0811C29.826 22.5586 30.6688 22.5116 31.2191 22.9759Z"
      fill={fill}
    />
  </Svg>
);
