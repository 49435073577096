import { redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { InMemoryCache, ApolloCache, ApolloClient } from '@apollo/client';
import apolloClient from '../../../apollo-client';
import { saveUserSession, sessionNotLoaded, logOut } from '../../../resolvers/auth';
import { GET_APPLICATION_DATA, GET_CURRENT_ACCOUNT_ID, GET_ID_TOKEN } from './index.queries';
import { ApplicationData } from '~/types';

interface ApplicationRemoteDataVariables {
  userId: string;
  userIdString: string;
  accountId: string;
}
export const loader = async ({ request }) => {
  try {
    let idTokenResponse = await apolloClient.query({
      query: GET_ID_TOKEN,
    });

    if (!idTokenResponse.data.idToken) {
      try {
        const session = await Auth.currentSession();
        await saveUserSession(session, apolloClient as unknown as ApolloClient<InMemoryCache>);
        idTokenResponse = await apolloClient.query({
          query: GET_ID_TOKEN,
        });
      } catch (err) {
        await sessionNotLoaded(apolloClient.cache as unknown as ApolloCache<InMemoryCache>);
        return redirect('/v2/login');
      }
    }

    const {
      data: { currentAccountId: currentAccountIdFromCache },
    } = await apolloClient.query({
      query: GET_CURRENT_ACCOUNT_ID,
    });

    const { idToken } = idTokenResponse.data;

    const currentAccountId = currentAccountIdFromCache || idToken.name;

    if (!currentAccountIdFromCache) {
      apolloClient.writeQuery({ query: GET_CURRENT_ACCOUNT_ID, data: { currentAccountId } });
    }

    try {
      const { data } = await apolloClient.query<ApplicationData, ApplicationRemoteDataVariables>({
        query: GET_APPLICATION_DATA,
        variables: {
          accountId: currentAccountId,
          userId: idToken.sub,
          userIdString: idToken.sub,
        },
      });

      const req = new URL(request.url);

      if (!data.getAccountInfo.accessControls.v2Design) {
        return redirect(req.pathname.replace(/\/v2\/?/, '/'));
      }

      return {
        ...data,
        idToken,
        currentAccountId: currentAccountId,
      };
    } catch (error) {
      // Usually happens when you have a valid session from another environment but the account is not found in the current environment
      await logOut(apolloClient.cache as unknown as ApolloCache<InMemoryCache>);
      return redirect('/v2/login');
    }
  } catch (error) {
    captureException(error);
    throw error;
  }
};
