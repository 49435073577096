import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../../types';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';

interface EbaDisclosureProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function EbaDisclosure({
  page,
  framework
}: EbaDisclosureProps) {
  const buttons = useMemo(
    () => page?.content?.headerButton ? [
      {
        title: page?.content?.headerButton?.text || 'Learn more',
        color: 'primary',
        onClick: () => {
          window.open(page?.content?.headerButton?.link);
        }
      }
    ]: [],
    [page]
  );
  return <ReportingFrameworkLayout page={page} framework={framework} buttons={buttons} />;
}
