import { Location, Query } from '@rio/rio-types';
import { useApolloClient } from '@apollo/client';
import { useCurrentAccountId } from '../../hooks';
import { GET_LOCATIONS_BY_ACCOUNT } from './index.queries';
import { Nullable, SelectEvent, Option } from '../../types';
import { BaseLocationSelect } from './BaseLocationSelect';
import { mapLocationToOption } from './utils';

interface LocationSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: Nullable<Error | string>;
  selectedOption?: Location;
  createNew?: boolean;
  createNewModalSize?: number;
  labelledBy?: string;
}

const order = [
  {
    order: 'asc',
    field: 'name'
  }
];

function LocationSelect({
  value,
  onChange,
  error,
  selectedOption,
  createNew,
  createNewModalSize = 5,
  labelledBy
}: LocationSelectProps) {
  const accountId = useCurrentAccountId();
  const apolloClient = useApolloClient();
  const loadOptions = async (filterValue: string) => {
    const response = await apolloClient.query<Pick<Query, 'getLocationsPage'>>({
      query: GET_LOCATIONS_BY_ACCOUNT,
      variables: {
        accountId,
        sort: order,
        filters: {
          text: [
            {
              filterType: 'text',
              type: 'contains',
              filter: filterValue,
              field: 'name'
            }
          ]
        }
      }
    });
    const { getLocationsPage } = response.data;
    const options = getLocationsPage.rows ? getLocationsPage.rows.map(mapLocationToOption) : [];

    return { options, total: getLocationsPage.totalRows };
  };

  return (
    <BaseLocationSelect
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      accountId={accountId}
      error={error}
      selectedOption={selectedOption ? mapLocationToOption(selectedOption) : null}
      createNew={createNew}
      createNewModalSize={createNewModalSize}
      labelledBy={labelledBy}
    />
  );
}

export { LocationSelect };
