import { useApolloClient } from '@apollo/client';
import { SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
// import GET_TRANSPORT_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/transport/GetTransportTransactionsPageFilter.query.graphql';
import GET_TRANSPORT_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/transport/GetTransportTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useTransportGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getTransportTransactionsPageFilter: values },
      } = await client.query({
        query: GET_TRANSPORT_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(
        values.map((item: { label: string; value: string; __typename: string }) =>
          item.value ? JSON.stringify(item) : item.value
        )
      );
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };
  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date',
        valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        headerTooltip: 'Date',
      },
      {
        headerName: 'Location',
        field: 'location.name',
        colId: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location Code',
        field: 'locationCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      { ...OccupationEndDateGridColumn, field: 'location.endDate' },
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Data Provider',
        field: 'dataProvider',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Sub Type',
        field: 'subtype',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Fuel Type',
        field: 'fuelType',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Origin of Travel',
        field: 'origin',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Destination of Travel',
        field: 'destination',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Volume',
        field: 'volume',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Volume Unit',
        field: 'volumeUnit',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Number of Journeys',
        field: 'numberOfJourneys',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Journey Distance',
        field: 'journeyDistance',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Distance',
        field: 'distance',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Distance Unit',
        field: 'distanceUnit',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Freight (tonne.km)',
        field: 'freight',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Carrier',
        field: 'carrier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Purpose of Journey',
        field: 'purpose',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Cost',
        field: 'cost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Cost (VAT)',
        field: 'costVat',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Scope 1 emissions - fuel use (kgCO2e)',
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope1',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Scope 2 emissions for EV electricity’ (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope2',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Scope 3 emissions from fuel use (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope3Indirect',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Scope 3 WTT emissions (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope3WTT',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Scope 3 T&D emissions (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope3TD',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Scope 3 emissions (kgCO2e)',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        field: 'carbonEmissionsScope3',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'kg CO2e of CO2',
        headerTooltip: 'This shows how much CO2 was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionCO2',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'kg CO2e of N2O',
        headerTooltip: 'This shows how much N2O was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionN2O',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'kg CO2e of CH4',
        headerTooltip: 'This shows how much CH4 was emitted in terms of CO2e (Converted to CO2e via the IPCC’s Global warming potentials to show emissions from various GHGs in a common unit of CO2e)',
        field: 'carbonEmissionCH4',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Activity',
        headerTooltip: 'This shows if the activity falls under your organisation’s direct (company owned emission sources) or indirect (non company owned, employee, contractor or third party emission sources) activities',
        field: 'activity',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Document Reference',
        field: 'documentReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Notes',
        field: 'notes',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Country',
        field: 'location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Reference',
        field: 'reference',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
