import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'rio-ui-components';
import KnowledgeBaseDetails from './KnowledgeBaseDetails';
import { UPDATE_KB } from './index.queries';
import { v4 as uuid } from 'uuid';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const UpdateTemplateModal = ({ onDismiss, onComplete, selectedKb }) => {
  const [updateKb] = useMutation(UPDATE_KB, {
    onCompleted: () => {
      onComplete('Knowledge Base has been updated.', 'success');
      onDismiss();
    },
    onError: () => {
      onComplete('Something went wrong! Please try again later.', 'danger');
    }
  });

  const [kb, setKb] = useState(selectedKb);

  const handleSubmit = () => {
    updateKb({
      variables: {
        details: {
          id: uuid(),
          ...kb
        }
      },
      refetchQueries: ['GetAllKnowledgeBases']
    });
  };

  return (
    <Modal size="md" show onDismiss={() => onDismiss()} height="auto" maxHeight="90vh">
      <ContainerStyled>
        <KnowledgeBaseDetails heading="Update Knowledge Base" knowledgeBase={kb} setKnowledgeBase={setKb} />
        <Row container align="between">
          <ColStyled item>
            <Button disabled={!(kb.title, kb.synopsis, kb.externalID)} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </ColStyled>
        </Row>
      </ContainerStyled>
    </Modal>
  );
};

export default UpdateTemplateModal;
