import React from 'react';
import styled from 'styled-components';
import { Text, Icon } from 'rio-ui-components';
import { didReviewDateExceed, isReviewDateApproaching } from '../../containers/DocumentContainer/utils';

const ReviewDueContainer = styled.div`
  position: absolute;
  right: ${(props) => (props.isTile ? props.theme.geometry.sm.spacing : '30%')};
  top: 30%;
  flex: 0 0 auto;
  display: inline-block;
  background: ${(props) =>
    props.isDanger ? props.theme.colors.danger.normal.background : props.theme.colors.warning.normal.background};
  padding: ${(props) => props.theme.geometry.xs.spacing} ${(props) => props.theme.geometry.sm.spacing};
`;

const ReviewTextContainer = styled.div`
  display: flex;
`;

const IconStyled = styled(Icon)`
  color: white;
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const ReviewDateIcon = ({ date, tile }) => {
  const reviewDateExceeded = didReviewDateExceed(date);
  const reviewDateApproaching = isReviewDateApproaching(date);
  return (
    <>
      {(reviewDateExceeded || reviewDateApproaching) && (
        <ReviewDueContainer isTile={tile} isDanger={reviewDateExceeded}>
          <ReviewTextContainer>
            <IconStyled icon="exclamation-circle" />
            <Text align="center" size="xs" color="white">
              Review
            </Text>
          </ReviewTextContainer>
        </ReviewDueContainer>
      )}
    </>
  );
};

export { ReviewDateIcon };
