import { DataType, SortCommand } from '@rio/rio-types';
import { get, isNil, round } from 'lodash';
import { formatDate, formatDateLocal, formatDatetime } from './formatDate';
import { ColumnState, SortModelItem, ValueFormatterFunc, FilterModel, AdvancedFilterModel } from 'ag-grid-community';

export function mapFilterModel(filterModel: FilterModel | AdvancedFilterModel | null = {}) {
  const filters = {};
  for (const field in filterModel) {
    const filterObject = filterModel[field];
    const dataType = filterObject.filterType;

    const filterType = filterObject.type;

    // date filter should include edge dates
    const greaterOrLessThanOperator = (filterType === 'greaterThan' || filterType === 'lessThan') && filterType;

    if (greaterOrLessThanOperator && dataType === 'date') {
      filterObject.type = `${greaterOrLessThanOperator}OrEqual`;
    }

    // parsing json object to retrieve value
    if (dataType === DataType.Set) {
      filterObject.values = filterObject.values.flatMap((item) => {
        try {
          // process original stringified value
          const filterItem = JSON.parse(item);
          return filterItem?.value || filterItem;
        } catch (err) {
          // fallback on error for already processed items
          return item;
        }
      });
    }

    if (!filters[dataType]) {
      filters[dataType] = [];
    }
    filters[dataType].push({
      ...filterObject,
      field,
    });
  }

  return filters;
}

export function mapSortModel(sortModel: Array<SortModelItem | ColumnState> = []): SortCommand[] {
  return sortModel
    .filter((s) => s.sort !== null)
    .map((s) => ({
      field: s.colId,
      order: s.sort || 'asc',
    }));
}

export const defaultFilterParams = () => ({
  buttons: ['reset', 'apply'],
  closeOnApply: true,
});

export const gridValueFormatter = ({ value }) => {
  if (!value) {
    return value;
  }

  try {
    const { label } = JSON.parse(value);
    return label;
  } catch (err) {
    return value;
  }
};

export const booleanValueFormatter = ({ value }) => {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  return '';
};

export const dateValueFormatter = ({ value }) => {
  if (value) {
    return formatDate(value);
  }
  return '';
};

export const dateLocalValueFormatter = ({ value }) => {
  if (value) {
    return formatDateLocal(value);
  }
  return '';
};

export const dateTimeValueFormatter = ({ value }) => {
  if (value) {
    return formatDatetime(value);
  }
  return '';
};

export const bitFormatter = ({ value }) => {
  if (value?.toString() === '1') {
    return 'Yes';
  }
  if (value?.toString() === '0') {
    return 'No';
  }
  return '';
};

export const fractionFormatter = ({ value }) => {
  if (isNil(value)) {
    return value;
  }
  return `${value * 100}%`;
};

export const percentageFormatter = ({ value }) => {
  if (isNil(value)) {
    return value;
  }
  return `${value}%`;
};

export const gridNumberFormatter: ValueFormatterFunc = ({ value }): string => {
  if (!isNil(value)) {
    return round(Number(value), 4).toFixed(4);
  }

  return '';
};

export const getAgRowId = (field) => (params) => get(params.data, field);

export const gridFilterValueFormatter = (params) => params.value?.label || params.value?.value;

export const gridFilterKeyCreator = (params) => params.value?.value || params.value?.id;
