import _ from 'lodash';
import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { Select, OptionsProps } from '@rio/ui-components';
import { useCountries } from '~/hooks';

type CountrySelectProps = {
  value?: string;
  onChange: (e: OptionsProps) => void;
  name?: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
};
export const CountrySelectV2 = ({ name, value, error, label, onChange, disabled, placeholder }: CountrySelectProps) => {
  const { allCountries, loading, error: queryError } = useCountries();
  const currentError = error || queryError;
  const helperText = currentError instanceof ApolloError ? currentError.message : currentError;

  const options = useMemo(
    () =>
      _(allCountries)
        .map((l) => ({
          value: l.id,
          label: l.name,
        }))
        .orderBy(['label'], ['asc'])
        .value(),
    [allCountries]
  );

  return (
    <Select
      name={name}
      placeholder={placeholder || 'Please select the country'}
      value={value}
      label={label}
      isLoading={loading}
      helperText={helperText ?? ''}
      options={options as OptionsProps[]}
      error={!!currentError}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    />
  );
};
