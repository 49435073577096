import { useQuery, useApolloClient } from '@apollo/client';
import { TransactionType, Supplier, Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import _ from 'lodash';
import { GET_ALL_SUPPLIERS } from '../../queries/suppliers';
import { NullableOption, Option, Nullable, SelectEvent } from '../../types';
import { MAX_ITEMS_IN_DOM_LIMIT } from '../../constants';

const suppliersToSelectOption = (suppliers: Supplier[] | null, includeEmpty: boolean): NullableOption[] => {
  const emptyValue = {
    value: null,
    label: '(No supplier)',
  };

  if (!suppliers && includeEmpty) {
    return [emptyValue];
  }

  const values: NullableOption[] = _(suppliers)
    .map((l) => ({
      value: l.id,
      label: l.name,
    }))
    .orderBy(['label'], ['asc'])
    .value();

  if (includeEmpty) {
    values.unshift(emptyValue);
  }

  return values;
};

export type SelectSupplierEvent = {
  entity: Supplier;
} & SelectEvent;

export interface SupplierSelectorProps {
  value: Nullable<string> | Nullable<Option>;
  utility?: TransactionType;
  accountId: string;
  defaultOptions?: Option[];
  error?: Nullable<string>;
  name?: string;
  filter?: (s: Supplier) => boolean;
  onChange: (e: SelectSupplierEvent) => void;
  includeEmpty?: boolean;
  isClearable?: boolean;
}

const defaultFilter = () => true;

export function SupplierSelector({
  value,
  error,
  name,
  onChange,
  accountId,
  utility,
  filter = defaultFilter,
  includeEmpty = false,
  isClearable = true,
}: SupplierSelectorProps) {
  const client = useApolloClient();
  const variables = {
    accountId,
    utility: utility === TransactionType.Heatsteam ? 'ELECTRICITY' : utility,
    filterValue: '',
    filterBy: 'name',
    pageSize: MAX_ITEMS_IN_DOM_LIMIT,
  };
  const { data } = useQuery<Query>(GET_ALL_SUPPLIERS, {
    variables,
    fetchPolicy: 'network-only',
  });
  let suppliers = data?.getSuppliers?.suppliers || [];
  const defaultOptions = suppliersToSelectOption(suppliers.filter(filter) || [], includeEmpty);

  return (
    <Select
      name={name}
      placeholder="Start typing to view suppliers..."
      value={value}
      loadOptions={async (filterValue: string) => {
        const response = await client.query({
          query: GET_ALL_SUPPLIERS,
          variables: {
            ...variables,
            filterValue,
          },
        });
        const { suppliers: filteredSuppliers, totalItemCount } = response.data?.getSuppliers;
        suppliers = filteredSuppliers || [];

        return {
          options: suppliersToSelectOption(suppliers.filter(filter) || [], includeEmpty),
          total: totalItemCount,
        };
      }}
      debounceTimeout={1000}
      defaultOptions={defaultOptions}
      options={defaultOptions}
      error={error}
      onChange={(item: SelectSupplierEvent) => {
        onChange({
          ...item,
          entity: suppliers.find((entity) => entity.id === item.target.value)!,
        });
      }}
      classPrefix="supplier-select"
      isClearable={isClearable}
    />
  );
}
