import { useParams } from 'react-router-dom';
import { useCurrentAccountId, useGetAuditByEntityId } from '~/hooks';
import { useGetReceivedSurveyByIdV2 } from '../ReceivedSurveyContainer/hooks';
import { ContributorAuditLogGridV2 } from '.';

export const ReceivedSurveyAuditLogContainerV2 = () => {
  const { surveyId } = useParams();
  const accountId = useCurrentAccountId();

  const { data } = useGetReceivedSurveyByIdV2(surveyId!, accountId);
  const auditID = data?.getReceivedSurveyById?.submission?.id || '';

  const { data: audit } = useGetAuditByEntityId(auditID);
  const auditList = audit?.getAuditByEntityId || [];

  return <ContributorAuditLogGridV2 rows={auditList} />;
};
