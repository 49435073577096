import { useMutation } from '@apollo/client';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Button, Heading, Select, Modal, ProfilePicture } from 'rio-ui-components';
import * as copy from 'clipboard-copy';
import password from 'generate-password';
import { usePermissions } from '../../../hooks/usePermissions';
import { useNotification } from '../../../hooks/useNotification';
import { useCreateDepartment } from '../../../hooks/useCreateDepartment';
import { useAccountById } from '../../../hooks/useAccountById';
import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import AccountSelector from '../../../components/AccountSelector';
import { CreateDepartmentModal } from '../../../components/CreateDepartmentModal';
import { ACCOUNT, LOCATION, TAG } from '../../../constants/scopes';
import { CREATE_USER, UPDATE_USER, UPDATE_USER_ROLE, RESEND_EMAIL, GET_USER_BY_ID } from './index.queries';
import { DisableUserButton } from './DisableUserButton';
import { isNumeric, isMobilePhone } from 'validator';
import { getRestrictedLocationsOptions, getRestrictedTagOptions, isRestrictedAccess } from './utils';
import { getEnvVar } from '../../../env';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ProfilePictureStyled = styled(ProfilePicture)`
  margin: ${(props) => props.theme.geometry.sm.spacing} auto;
`;

const defaultState = {
  hasError: false,
  isSuccess: false,
  values: {
    emailAddress: { text: '', error: '' },
    firstName: { text: '', error: '' },
    lastName: { text: '', error: '' },
    phone: { text: '', error: '' },
    mobile: { text: '', error: '' },
    jobTitle: { text: '', error: '' },
    department: { text: '', error: '' },
    role: { text: null, error: '' },
    accountId: { text: { value: null }, error: '' },
    accessLevel: {
      text: null,
      error: '',
    },
    allowedTags: {
      text: null,
      error: '',
    },
    allowedLocations: {
      text: null,
      error: '',
    },
  },
  isSubmitted: false,
};

const UserModal = (props) => {
  const [state, setState] = useState(defaultState);

  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const isUpdate = !!props.userId;

  const restrictedAccess = isRestrictedAccess(permissions.currentUser?.accessLevel);

  const defaultRole = useMemo(
    () =>
      isUpdate
        ? { value: props.role.role, label: props.role.name, restrictedAccess: props.role.restrictedAccess }
        : null,
    [isUpdate, props.role]
  );

  const roleOptions = [...props.roleOptions];
  if (isUpdate && !props.roleOptions.find(({ value }) => value === defaultRole.value)) {
    roleOptions.push(defaultRole);
  }

  useEffect(() => {
    const actualState = {
      hasError: false,
      isSuccess: false,
      values: {
        emailAddress: { text: props.email || '', error: '' },
        firstName: { text: props.firstName || '', error: '' },
        lastName: { text: props.lastName || '', error: '' },
        phone: { text: props.phone || '', error: '' },
        mobile: { text: props.mobile || '', error: '' },
        jobTitle: { text: props.jobTitle || '', error: '' },
        department: { text: props.department || '', error: '' },
        role: { text: isUpdate ? defaultRole : null, error: '' },
        accountId: { text: props.accountId, error: '' },
        accessLevel: {
          text: props.scopeOptions.find(({ value }) => value === (props.accessLevel || ACCOUNT)),
          error: '',
        },
        allowedTags: {
          text: props.allowedTags?.map(({ id, tagName }) => ({ value: id, label: tagName })) || [],
          error: '',
        },
        allowedLocations: {
          text: props.allowedLocations?.map(({ id, name }) => ({ value: id, label: name })) || [],
          error: '',
        },
      },
      isSubmitted: false,
    };
    setState(actualState);

    return () => {
      setState(defaultState);
    };
  }, [defaultRole, isUpdate, props]);

  const selectAccount = useAccountById(
    typeof state.values.accountId.text === 'string' ? props.accountId : state.values.accountId.text.value
  );

  const { departmentSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    selectAccount?.departments,
    state.values.department.text,
    selectAccount?.id || props.accountId
  );

  const validateField = (value) => (value === '' ? 'Field cannot be empty' : null);
  const validateEmail = (email) => validateField(email);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'select' ? target.select : target.value;
    const newState = { ...state, values: { ...state.values, [target.name]: { text: value, error: null } } };
    setState(() => newState);
  };

  const handleRoleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        role: { text: e.target.select, error: null },
        accessLevel: {
          text: props.scopeOptions.find(({ value }) => value === ACCOUNT) || props.scopeOptions[0],
          error: null,
        },
      },
    }));
  };

  const handleAccountChange = ({ id }) => {
    if (!!state.values.allowedTags.text.length || !!state.values.allowedLocations.text.length) {
      showNotification('Unable to change account until current Tag/Location restrictions are removed.', 'danger');
    } else {
      setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          accountId: { text: id, error: '' },
          department: {
            ...prevState.values.department,
            text: state.values.accountId.text.value !== id ? null : prevState.values.department.text,
          },
        },
      }));
    }
  };

  const onCompleted = () =>
    props.onSuccess(
      `The user ${state.values.firstName.text} ${state.values.lastName.text} has been ${
        isUpdate ? 'updated' : 'created'
      }!`,
      'success'
    );

  const onError = (err) => {
    showNotification(err.message.replace('GraphQL error:', '').trim(), 'danger');
    setState((prevState) => ({
      ...prevState,
      hasError: true,
      isSuccess: false,
      isSubmitted: false,
    }));
  };

  const [createUser] = useMutation(CREATE_USER, { onCompleted, onError });
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted,
    onError,
    refetchQueries: ['GetUsers', { query: GET_USER_BY_ID, variables: { id: props.userId } }],
  });
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, { onError });

  const onResendEmailClick = (mutation) => {
    const newPassword = password.generate({ length: 32, numbers: true, symbols: false });
    mutation({
      variables: {
        username: state.values.emailAddress.text,
        password: newPassword,
      },
    });
    copy(`${getEnvVar('REACT_APP_BASE_URL')}/login?username=${state.values.emailAddress.text}&token=${newPassword}`);
  };

  const [resendEmail] = useMutation(RESEND_EMAIL, {
    onCompleted: () =>
      props.onSuccess(`User email has been sent! The unique link is now on your clipboard.`, 'success'),
    onError: () => {
      setState((prevState) => ({
        ...prevState,
        hasError: true,
        isSuccess: false,
        isSubmitted: false,
      }));
      showNotification('This user has already completed the sign up process. Email can not be resent.', 'danger');
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const v = state.values;
    v.emailAddress.error = validateEmail(v.emailAddress.text);
    v.firstName.error = validateField(v.firstName.text);
    v.lastName.error = validateField(v.lastName.text);
    v.role.error = v.role.text && validateField(v.role.text.value);
    v.phone.error = !v.phone.text || isNumeric(v.phone.text) ? '' : 'Must be a valid phone number.';
    v.mobile.error = !v.mobile.text || isMobilePhone(v.mobile.text) ? '' : 'Must be a valid mobile number.';

    if (
      v.emailAddress.error ||
      v.firstName.error ||
      v.lastName.error ||
      v.role.error ||
      v.phone.error ||
      v.mobile.error
    ) {
      setState((prevState) => ({ ...prevState, values: v }));
      return;
    }

    const saveUser = isUpdate ? updateUser : createUser;
    const variables = {
      email: state.values.emailAddress.text.toLowerCase(),
      previousEmail: props.email || null,
      first_name: state.values.firstName.text,
      last_name: state.values.lastName.text,
      jobTitle: state.values.jobTitle.text,
      department: state.values.department.text !== 'notSelected' ? state.values.department.text : null,
      phone: state.values.phone.text,
      mobile: state.values.mobile.text,
      roles: state.values.role.text.value,
      account_id:
        typeof state.values.accountId.text === 'string'
          ? state.values.accountId.text
          : state.values.accountId.text.value,
      accessLevel: state.values.accessLevel.text.value,
      allowedTagsIds:
        state.values.accessLevel.text.value === TAG ? state.values.allowedTags.text.map(({ value }) => value) : [],
      allowedLocationsIds:
        state.values.accessLevel.text.value === LOCATION
          ? state.values.allowedLocations.text.map(({ value }) => value)
          : [],
      profile: props.profilePicture,
    };

    if (isUpdate) {
      variables.id = props.userId;
    } else {
      variables.password = password.generate({ length: 32, numbers: true, symbols: false });
    }

    if (isUpdate && variables.roles !== props.role.role) {
      await updateUserRole({ variables: { id: props.userId, roles: variables.roles } });
    }
    setState((prevState) => ({
      ...prevState,
      isSubmitted: true,
    }));
    await saveUser({ variables });
  };

  const setDepartment = async (data, newDepartment) => {
    setState((prevState) => ({
      ...prevState,
      values: { ...prevState.values, department: { text: newDepartment.departmentId, error: null } },
    }));
  };

  return (
    <Modal
      size="md"
      show
      loading={props.loading}
      dismissable
      name="ConfigurationUsersContainer__Modal"
      onDismiss={props.onDismiss}
    >
      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        passedAccountId={selectAccount?.id || props.accountId}
        onComplete={setDepartment}
      />
      <Container name="user-modal">
        <form name="user__form" onSubmit={handleSubmit}>
          <HeaderStyled name="user__heading" size="lg">
            {isUpdate ? 'Update' : 'Add New'} User
          </HeaderStyled>
          {isUpdate && (
            <ProfilePictureStyled
              accountName={state.values.firstName.text}
              src={props.profilePicture}
              border
              name="update-user__profile-picture"
            />
          )}
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Email Address</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.emailAddress.text}
                error={state.values.emailAddress.error}
                name="emailAddress"
                box
              />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>First Name</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                id="firstName"
                aria-label="first name"
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.firstName.text}
                error={state.values.firstName.error}
                name="firstName"
                box
              />
            </ColStyled>
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>Last Name</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.lastName.text}
                error={state.values.lastName.error}
                name="lastName"
                box
              />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>Phone Number (Optional)</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.phone.text}
                error={state.values.phone.error}
                name="phone"
                box
              />
            </ColStyled>
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>Mobile Number (Optional)</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.mobile.text}
                error={state.values.mobile.error}
                name="mobile"
                box
              />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>Job Title (Optional)</LabelStyled>
              </LabelContainerStyled>
              <TextInput
                disabled={state.isSubmitted}
                onChange={handleChange}
                value={state.values.jobTitle.text}
                name="jobTitle"
                box
              />
            </ColStyled>
            <ColStyled item span={6}>
              <LabelContainerStyled>
                <LabelStyled>Department (Optional)</LabelStyled>
              </LabelContainerStyled>
              {departmentSelect(handleChange)}
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>User Type</LabelStyled>
              </LabelContainerStyled>
              <Select
                name="role"
                isDisabled={state.isSubmitted || (props.currentUser.accessLevel === LOCATION && props.email)}
                onChange={handleRoleChange}
                value={state.values.role.text}
                error={state.values.role.error}
                options={roleOptions}
              />
            </ColStyled>
          </Row>
          {permissions.user.includes('editAll') && (
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Company</LabelStyled>
                </LabelContainerStyled>
                <AccountSelector
                  disabled={state.isSubmitted}
                  name="accountId"
                  value={state.values.accountId.text}
                  onChange={handleAccountChange}
                  error={state.values.accountId.error}
                  customOption={selectAccount ? { value: selectAccount.id, label: selectAccount.name } : undefined}
                />
              </ColStyled>
            </Row>
          )}
          {state.values.role.text && state.values.role.text?.restrictedAccess && (
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Access Level</LabelStyled>
                </LabelContainerStyled>
                <Select
                  name="accessLevel"
                  options={props.scopeOptions}
                  value={state.values.accessLevel.text || { value: null }}
                  onChange={handleChange}
                  isDisabled={props.currentUser.accessLevel === LOCATION}
                />
              </ColStyled>
            </Row>
          )}
          {state.values.role.text &&
            state.values.role.text?.restrictedAccess &&
            state.values.accessLevel.text.value === LOCATION && (
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Location(s)</LabelStyled>
                  </LabelContainerStyled>
                  <LocationsMultiSelect
                    name="allowedLocations"
                    accountId={state.values.accountId.text?.value || state.values.accountId.text}
                    value={state.values.allowedLocations.text}
                    onChange={handleChange}
                    error={state.values.allowedLocations.error}
                    isSubmitting={state.isSubmitted}
                    disabled={props.currentUser.accessLevel === LOCATION}
                    defaultOptions={restrictedAccess ? getRestrictedLocationsOptions(props.currentUser) : null}
                  />
                </ColStyled>
              </Row>
            )}
          {state.values.role.text &&
            state.values.role.text?.restrictedAccess &&
            state.values.accessLevel.text.value === TAG && (
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>Tag(s)</LabelStyled>
                  </LabelContainerStyled>
                  <TagsMultiSelect
                    name="allowedTags"
                    accountId={state.values.accountId.text?.value || state.values.accountId.text}
                    value={state.values.allowedTags.text}
                    onChange={handleChange}
                    error={state.values.allowedTags.error}
                    isSubmitting={state.isSubmitted}
                    disabled={props.currentUser.accessLevel === LOCATION}
                    defaultOptions={restrictedAccess ? getRestrictedTagOptions(props.currentUser) : null}
                  />
                </ColStyled>
              </Row>
            )}
          {!isUpdate && (
            <Row container align="between">
              <ColStyled item>
                <Button
                  name="create-user__button--create"
                  color="success"
                  submit
                  disabled={
                    state.isSubmitted ||
                    !(
                      state.values.emailAddress.text &&
                      state.values.firstName.text &&
                      state.values.lastName.text &&
                      state.values.role.text
                    )
                  }
                >
                  Send Invitation
                </Button>
              </ColStyled>
            </Row>
          )}
          {isUpdate && (
            <Row container align="between">
              <ColStyled item container>
                <Button
                  disabled={state.isSubmitted}
                  name="update-user__button--cancel"
                  color="info"
                  onClick={props.onDismiss}
                >
                  Cancel
                </Button>
              </ColStyled>
              <ColStyled item container>
                <Button
                  name="update-user__button--save"
                  color="success"
                  submit
                  disabled={
                    state.isSubmitted ||
                    !(
                      state.values.emailAddress.text &&
                      state.values.firstName.text &&
                      state.values.lastName.text &&
                      state.values.role.text &&
                      state.values.accountId.text
                    )
                  }
                >
                  Save
                </Button>
              </ColStyled>
            </Row>
          )}
        </form>
        {isUpdate && permissions.user.find((action) => action.startsWith('resendEmail')) && (
          <Row container align="between">
            <ColStyled item container>
              <Button
                disabled={state.isSubmitted}
                name="update-user__button--resend"
                color="primary"
                onClick={() => onResendEmailClick(resendEmail)}
              >
                Resend Email
              </Button>
            </ColStyled>
          </Row>
        )}
        {isUpdate && permissions.user.find((action) => action.startsWith('toggleStatus')) && (
          <Row container align="between">
            <ColStyled item container>
              <DisableUserButton userId={props.userId} disabled={props.disabled} showNotification={showNotification} />
            </ColStyled>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

export default UserModal;
