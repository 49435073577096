import React, { useState } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import CourseBanner from '../Courses/CourseBanner';
import PageHeader from '../../../components/PageHeader';

import CourseOverviewContent from './CourseOverviewContent';
import SearchBar from './SearchBar';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
  height: 100%;
`;

const CourseBannerStyled = styled(CourseBanner)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const CoursesOverview = (props) => {
  const { intl } = props;
  const [state, setState] = useState({
    search: { value: '', error: '' },
    pageNumber: 1,
    showGrid: true,
    showList: false
  });

  const onSearch = (event) => {
    setState((prevState) => ({
      ...prevState,
      search: { ...prevState.search, value: event.target.value },
      pageNumber: 1
    }));
  };

  return (
    <ContainerStyled name="Course Overview">
      <PageHeader
        name="Course Overview"
        title={intl.formatMessage({ id: 'pages.learn.heading' })}
        breadcrumbs={[
          { title: intl.formatMessage({ id: 'pages.learn.breadcrumbs.one' }), to: '/engage' },
          { title: intl.formatMessage({ id: 'pages.learn.breadcrumbs.two' }) }
        ]}
        icon="graduation-cap"
        iconColor="secondary"
      />
      <ContentContainer>
        <CourseBannerStyled />
        <SearchBar searchValue={state.search.value} onSearch={onSearch} />
        <CourseOverviewContent learnAccess={props.learnAccess} searchValue={state.search.value} />
      </ContentContainer>
    </ContainerStyled>
  );
};

export default injectIntl(CoursesOverview);
