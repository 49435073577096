"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_next_1 = require("@mui/material-next");
const react_1 = require("react");
const FooterStyled = (0, material_next_1.styled)('footer') `
  padding-left: 32px;
  font-family: ${(p) => p.theme.sys.typescale.body.small.family};
  font-size: ${(p) => p.theme.sys.typescale.label.small.size}px;
  font-style: normal;
  font-weight: ${(p) => p.theme.sys.typescale.label.small.weight};
  line-height: ${(p) => p.theme.sys.typescale.label.small.lineHeight}px;
  color: ${(p) => p.theme.sys.color.onSurfaceVariant};

  background-color: ${({ theme }) => theme.sys.color.surface};
`;
function Footer(props) {
    const year = (0, react_1.useMemo)(() => {
        return new Date().getFullYear();
    }, []);
    return (0, jsx_runtime_1.jsxs)(FooterStyled, { ...props, children: ["\u00A9 ", year, " Rio ESG LTD"] });
}
exports.Footer = Footer;
