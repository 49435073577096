import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';
import { ManagementSystemPage } from '../../../../../types';
import { useNotification } from '../../../../../hooks';

interface CarbonDisclosureRisksAndOpportunitiesProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureRisks({ page, framework }: CarbonDisclosureRisksAndOpportunitiesProps) {
  const { showNotification } = useNotification();

  const buttons = useMemo(
    () => [
      {
        title: 'Export disclosure',
        color: 'primary',
        onClick: () => {
          showNotification('Not implemented', 'warning');
        }
      }
    ],
    [showNotification]
  );

  return <ReportingFrameworkLayout framework={framework} page={page} buttons={buttons} />;
}
