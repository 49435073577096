import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { MutationCreateSurveyTemplateSubscriptionArgs as Variables, NamedEntity } from '@rio/rio-types';
import { PropertySheet } from 'rio-ui-components';
import AccountSelector from '../../../components/AccountSelector';
import { useCreate } from './queries/useCreate';
import { validate } from './validate';
import { Errors, SelectEvent } from '../../../types';
import { useGet as useGetTemplates } from '../ConfigurationSurveyContainer/queries';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

interface CreateSubscriptionProps {
  onDismiss: () => void;
}

export function CreateSubscription({ onDismiss }: CreateSubscriptionProps) {
  const [variables, setVariables] = useState({ accountId: '', templateId: '' });
  const [errors, setErrors] = useState<Errors<Variables>>({});
  const { data } = useGetTemplates();
  const [createSubscription, { loading }] = useCreate();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors, validVariables] = validate(variables);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }
      await createSubscription({
        variables: validVariables,
        onCompleted: () => onDismiss()
      });
    },
    [variables, createSubscription, onDismiss]
  );

  return (
    <Container>
      <PropertySheet onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>Subscribe template</PropertySheet.HeaderRow>
        <PropertySheet.Row container>
          <PropertySheet.Column label="Accounts">
            <AccountSelector
              value={variables.accountId}
              error={errors.accountId}
              onChange={useCallback(
                (account: NamedEntity) => setVariables((prev) => ({ ...prev, accountId: account.id })),
                []
              )}
            />
          </PropertySheet.Column>
        </PropertySheet.Row>
        <PropertySheet.Row container>
          <PropertySheet.SelectColumn
            label="Template"
            options={data?.getSurveyTemplates?.map((t) => ({ value: t.id, label: t.name }))}
            error={errors.templateId}
            onChange={useCallback(
              (e: SelectEvent) => setVariables((prev) => ({ ...prev, templateId: e.target.value })),
              []
            )}
          ></PropertySheet.SelectColumn>
        </PropertySheet.Row>
        <PropertySheet.ButtonColumn disabled={loading} onClick={handleSubmit}>
          Subscribe
        </PropertySheet.ButtonColumn>
      </PropertySheet>
    </Container>
  );
}
