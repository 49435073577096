import { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import { Col, Text } from 'rio-ui-components';

import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContainerHeader from '../../../components/ContainerHeader';
import { PageFailed } from '../../../components/Errors';
import { useGetMyContributors, GetMyContributorsResponse } from './queries';
import { ContributorsGrid } from './ContributorsGrid';
import { useCurrentAccountId } from '../../../hooks';
import { Async } from '../../../components/Async';
import { SurveysPlaceholder } from '../../../components/SurveysPlaceholder';

const Page = styled(Col)`
  display: flex;
  height: 100%;
`;
const IconStyled = styled(Icon)`
  margin-right: 3px;
  color: ${(props) => props.theme.colors.danger.light.background};
`;
const StatusContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

const breadcrumbs = [{ to: '../sent/overview', title: 'Surveys sent' }, { title: 'Contributors' }];

export function MyContributorsContainer() {
  const accountId = useCurrentAccountId();
  const result = useGetMyContributors(accountId);
  const retry = useCallback(() => result.refetch({ accountId }), [accountId, result]);
  const numberOfUsersNotRegistered = 0;
  const numberOfUsersRegistered = result.data?.getMySurveyContributors.length;

  return (
    <Page>
      <ContainerHeader
        name="FormsContributors"
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title="Contributors"
        breadcrumb={breadcrumbs}
      />
      {numberOfUsersNotRegistered > 0 && (
        <StatusContainer>
          <IconStyled icon="times" />
          <Text size="sm">{`${numberOfUsersNotRegistered} not registered for RIO out of ${numberOfUsersRegistered} invited.`}</Text>
        </StatusContainer>
      )}
      <Async<GetMyContributorsResponse>
        result={result}
        error={(error: Error) => <PageFailed error={error} retry={retry} />}
        data={(data: GetMyContributorsResponse) =>
          data.getMySurveyContributors.length ? (
            <ContributorsGrid contributors={data.getMySurveyContributors} />
          ) : (
            <SurveysPlaceholder
              title="You don’t have any contributors yet"
              description="Contributors are teams that can receive surveys. Contributors will appear once you assign a survey to teams in Surveys > Overview."
              icon={['rio', 'clipboard-check-custom']}
            />
          )
        }
        loading={() => <ContainerLoadingIndicator />}
      />
    </Page>
  );
}
