import { gql } from '@apollo/client';
import { submissionFragment } from '../../MyContributorContainer/queries/queries';

export const GET_RECEIVED_SURVEYS_PAGE = gql`
  query GetReceivedSurveysPage(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getReceivedSurveysPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      rows {
        id
        name
        lockedBy {
          id
          first_name
          last_name
        }
        deadlineDate
        template {
          id
          formContent
          questions {
            id
            name
          }
        }
        submission {
          ...SubmissionFields
        }
        owner {
          id
          name
        }
      }
      totalRows
    }
  }
  ${submissionFragment}
`;

export const GET_RECEIVED_SURVEYS_PAGE_FILTER = gql`
  query GetReceivedSurveysPageFilter($accountId: ID!, $field: String!) {
    getReceivedSurveysPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
