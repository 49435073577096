import { LearnContentType } from '@rio/rio-types';
import {
  GET_PRESIGNED_LEARN_URL,
  GET_PRESIGNED_LEARN_URL_LIST,
  UPLOAD_LEARN_CONTENT,
  SAVE_LEARN_CONTENT_METADATA,
  SAVE_LEARN_BUNDLE,
  GET_ALL_COURSES_AND_LEARN_BUNDLES,
  GET_SUBSCRIPTIONS,
  SAVE_SUBSCRIPTIONS
} from './index.queries';

export const getPresignedUrl = (contentData, client, accountId) => {
  const fetchPolicy = 'network-only';
  const { type } = contentData;

  if (type === LearnContentType.Scorm) {
    const fileArray = [...contentData.content].map((file) => {
      const { name, webkitRelativePath } = file;
      return { name, webkitRelativePath };
    });

    return client.query({
      query: GET_PRESIGNED_LEARN_URL_LIST,
      variables: {
        accountId,
        type,
        files: fileArray
      },
      fetchPolicy
    });
  } else {
    return client.query({
      query: GET_PRESIGNED_LEARN_URL,
      variables: {
        accountId,
        type,
        fileName: contentData.content.name
      },
      fetchPolicy
    });
  }
};

export const getSubscriptions = async (accountId, client) => {
  const { data } = await client.query({
    query: GET_SUBSCRIPTIONS,
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });

  return data.getAllLearnSubscriptions;
};

export const addSubscription = async (subscriptions, client) => {
  await client.mutate({
    mutation: SAVE_SUBSCRIPTIONS,
    variables: {
      ...subscriptions
    }
  });
};

export const uploadFiles = async (
  { url, contentPath, id, title, description, cpd, thumbnail, content, type, version, presignedLinks },
  client,
  accountId
) => {
  await client.mutate({
    mutation: UPLOAD_LEARN_CONTENT,
    variables: {
      accountId,
      url,
      presignedLinks,
      contentPath,
      id,
      title,
      description,
      cpd,
      thumbnail,
      content,
      type,
      version
    }
  });
};

export const saveMetadata = (variables, client) =>
  client.mutate({
    mutation: SAVE_LEARN_CONTENT_METADATA,
    variables,
    update: (apolloCache, fetchResult) => {
      const oldResult = apolloCache.readQuery({ query: GET_ALL_COURSES_AND_LEARN_BUNDLES });
      const saveCourse = fetchResult.data.saveLearnContentMetadata;
      const newResult = {
        ...oldResult,
        getAllCourses: {
          ...oldResult.getAllCourses,
          data: [...oldResult.getAllCourses.data.filter((elem) => elem.id !== saveCourse.id), saveCourse]
        }
      };
      apolloCache.writeQuery({ query: GET_ALL_COURSES_AND_LEARN_BUNDLES, data: newResult });
    }
  });

export const saveLearnBundle = (variables, client) =>
  client.mutate({
    mutation: SAVE_LEARN_BUNDLE,
    variables,
    update: (apolloCache, fetchResult) => {
      const oldResult = apolloCache.readQuery({ query: GET_ALL_COURSES_AND_LEARN_BUNDLES });
      const saveBundle = fetchResult.data.saveLearnBundle;
      const newResult = {
        ...oldResult,
        getAllLearnBundles: [...oldResult.getAllLearnBundles.filter((elem) => elem.id !== saveBundle.id), saveBundle]
      };
      apolloCache.writeQuery({ query: GET_ALL_COURSES_AND_LEARN_BUNDLES, data: newResult });
    }
  });
