import { useQuery } from '@apollo/client';
import { AspectOption } from '@rio/rio-types';
import { useCurrentAccountId } from '../../../hooks';
import { GET_ALL_ASPECT_OPTIONS } from './index.queries';

interface Response {
  activities: AspectOption[];
  aspects: AspectOption[];
  emergencyConditions: AspectOption[];
  impacts: AspectOption[];
  controlMeasures: AspectOption[];
  statuses: AspectOption[];
}

export function useGetAllAspectOptions() {
  const accountId = useCurrentAccountId();
  return useQuery<Response>(GET_ALL_ASPECT_OPTIONS, {
    variables: {
      accountId,
    },
  });
}
