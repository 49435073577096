import { gql } from '@apollo/client';

export const GET_NORMALISER_TYPES = gql`
  {
    getNormaliserTypes {
      id
      name
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: ID!) {
    getLocationById(id: $id) {
      id
      name
    }
  }
`;
