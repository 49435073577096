import { styled, Text } from '@rio/ui-components';

export const ListContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;
  padding: 2px 0;
`;

export const ItemContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.sys.color.outlineVariant};
  background-color: ${(p) => p.theme.sys.color.surface};
  box-shadow: ${(p) => p.theme.sys.elevation[1]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;

export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
`;

export const TextStyled = styled(Text)`
  color: ${(p) => p.theme.sys.color.onSurface};
`;

export const ContentWrapperStyled = styled('div')`
  display: flex;
  align-items: flex-start;
  height: calc(100vh - 216px); // header + page title height
  gap: 24px;
`;

export const ChatbotStyled = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;

  iframe {
    max-width: 100%;
  }
`;
