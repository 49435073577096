import React from 'react';
import { ProfilePicture } from 'rio-ui-components';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function CreatorCell(props) {
  const {
    data: { author }
  } = props;

  const name = `${author.first_name} ${author.last_name}`;

  return (
    <ProfileContainer>
      <ProfilePicture title={name} outline size="xxs" src={author.profilePicture} accountName={name} />
    </ProfileContainer>
  );
}
