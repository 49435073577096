import { useState } from 'react';
import { ContainerError, PreContainer, TextLink } from 'rio-ui-components';
import { ApolloError } from '@apollo/client';
import styled from 'styled-components';
import { useReportError } from '../../hooks';
import { formatError } from '../../utils';

interface PageFailedProps {
  error: Error | ApolloError | string;
  name?: string;
  retry?: (...args: any[]) => unknown;
  report?: () => void;
}

const ContainerErrorStyled = styled(ContainerError)`
  height: 100%;
`;

const PreStyled = styled(PreContainer)`
  text-align: left;
`;

const UserFriendlyMessage = styled.div`
  text-align: left;
`;

const TextLinkStyled = styled(TextLink)`
  display: inline-block;
  width: auto;
`;

export const PageFailed = ({ error, retry }: PageFailedProps) => {
  const reportError = useReportError();
  const [reportSent, setReportSent] = useState(false);
  const ensuredError = error instanceof Error ? error : new Error(error);

  const executeReport = async () => {
    if (!reportSent) {
      await reportError(ensuredError);
      setReportSent(true);
    }
  };

  return (
    <ContainerErrorStyled
      icon="exclamation-triangle"
      title="This page failed to load"
      body="There was a problem loading this page, please try again."
      retry={retry}
      report={executeReport}
      details={
        <>
          <UserFriendlyMessage>
            Please reload the page and if the problem persists please{' '}
            <TextLinkStyled to="/contact" onClick={executeReport}>
              report it
            </TextLinkStyled>
          </UserFriendlyMessage>
          <PreStyled size="md" wrap>
            {formatError(ensuredError)}
          </PreStyled>
        </>
      }
    />
  );
};

export { PageFailed as LoadFailed };
