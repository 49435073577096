import NotificationBanner from '~/components/NotificationBanner';
import { areasV2 as areas } from '../../areas';
import * as _ from 'lodash';
import { usePermissions, useManagementSystems } from '~/hooks';
import { styled, Page } from '@rio/ui-components';
import GovernanceAreaGrid from './GovernanceAreaGrid';
import { checkRestrictions } from './utils';

const MainContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
`;

type Props = {
  isShouldUserUpgrade: boolean;
  accessControls: { [accessIndex: string]: boolean };
};

const GovernanceLandingPage = (props: Props) => {
  const { accessControls, isShouldUserUpgrade } = props;
  const availableSystems = useManagementSystems();
  const permissions = usePermissions();

  const allowedAreas = areas.filter(
    (item) => !!permissions[item.permissionSection]?.find((perm) => perm.startsWith(item.basePermission))
  );

  const [restrictedAreas, openAreas] = _.partition(allowedAreas, (area) => {
    return !!area.restricted;
  });
  const allowedRestrictions = checkRestrictions(restrictedAreas, { systems: availableSystems });

  const validAreas = openAreas.concat(allowedRestrictions);

  return (
    <Page title={{ content: 'Governance', crumbs: [{ label: 'Governance' }, { label: 'Areas' }] }}>
      <MainContainerStyled>
        {isShouldUserUpgrade && (
          <NotificationBanner
            name="ComplyContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title="Governance"
            body="Currently Governance is not included in your subscription, if you'd like to use this tool to assess your compliance risk, upload evidence and get automated alerts please contact Rio ESG LTD or your Rio Business Admin."
            dismissable={false}
            onDismiss={() => {}}
          />
        )}

        <GovernanceAreaGrid areas={validAreas} accessControls={accessControls} />
      </MainContainerStyled>
    </Page>
  );
};

export default GovernanceLandingPage;
