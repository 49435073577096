import { Icon } from 'rio-ui-components';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import styled from 'styled-components';
import { SurveyTemplate } from '@rio/rio-types';
import { RowNode, ValueFormatterParams } from 'ag-grid-community';
import { formatDate, getAgRowId, getFullName } from '../../../utils';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xxl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface ActionCellProps {
  data: RowNode;
  onEdit: (row: RowNode) => void;
}

const GridContainer = styled.div`
  height: 100%;
`;

const ActionCell = ({ data, onEdit }: ICellRendererParams & ActionCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(data)}>
      <Icon icon="pencil-alt" />
    </EditLinkStyled>
  </GridCellContainer>
);

interface SurveyTemplatesGridProps {
  rows: SurveyTemplate[];
  onEdit: (template: SurveyTemplate) => void;
}
const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  cellStyle: {
    height: 'auto',
  },
};

const getRowId = getAgRowId('id');

const columnDefs: ColDef[] = [
  {
    headerName: 'Title',
    field: 'name',
  },
  {
    headerName: 'Date Created',
    field: 'createdDate',
    valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
  },
  {
    headerName: 'Expiry Date',
    field: 'expiryDate',
    valueFormatter: ({ value }: ValueFormatterParams) => formatDate(value),
  },
  {
    headerName: 'Description',
    field: 'description',
  },
  {
    headerName: 'Version',
    field: 'version',
  },
  {
    headerName: 'Owner',
    field: 'owner',
    valueFormatter: ({ value }: ValueFormatterParams) => getFullName(value),
  },
  {
    headerName: 'Actions',
    field: 'id',
    cellRenderer: 'edit',
    colId: 'edit',
  },
];
export function GridOfTemplates({ rows, onEdit }: SurveyTemplatesGridProps) {
  const components = {
    edit: (props: ICellRendererParams) => (
      <ActionCell {...props} onEdit={(row) => onEdit(row as unknown as SurveyTemplate)} />
    ),
  };
  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        rowSelection="single"
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        components={components}
        rowData={rows}
        paginationPageSize={25}
        getRowId={getRowId}
        pagination
      />
    </GridContainer>
  );
}
