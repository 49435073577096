import { gql } from '@apollo/client';

export const GET_METERS_BY_ACCOUNT_ID_AND_TYPE = gql`
  query getEnergySourceByAccountIdAndType(
    $accountId: ID!
    $type: TransactionType
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getEnergySourceByAccountIdAndType(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;

export const GET_METERS_WITH_LOCATION_BY_ACCOUNT_ID_AND_TYPE = gql`
  query getEnergySourceByAccountIdAndType(
    $accountId: ID!
    $type: TransactionType
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getEnergySourceByAccountIdAndType(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        name
        inOut
        locationPoint {
          id
          name
          location {
            id
            name
            code
          }
        }
      }
    }
  }
`;
