"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("@mui/material-next/styles");
const Text_1 = require("../Text");
const utils_1 = require("../../utils");
const Icons_1 = require("../Icons");
const Chip_1 = require("../Chip");
const ThemeProvider_1 = require("../../ThemeProvider");
const shouldForwardProp = (prop) => prop !== 'disabled';
const CardStyle = (0, styles_1.styled)('div', { shouldForwardProp }) `
  display: flex;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  box-shadow: ${({ theme }) => theme.sys.elevation[1]};
  color: ${({ theme }) => theme.sys.color.onSurface};
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  cursor: 'pointer';

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;
const Img = (0, styles_1.styled)('div') `
  align-self: flex-start;
  width: 100%;
  height: 168px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-color lightgray;
  background-size: cover;
  border-radius: 12px 12px 0 0;
`;
const CardInfoContent = (0, styles_1.styled)('div') `
  margin-top: ${(p) => (p.hasImg ? 'auto' : '20px')};
  width: 100%;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  max-width: 100%;
`;
const TextStyled = (0, styles_1.styled)(Text_1.Text) `
  display: flex;
  margin: 0;
  flex: 1;
`.withComponent('h2');
const CardWrapperStyle = (0, styles_1.styled)('div') `
  height: ${(p) => (p.hasImg ? '240px' : '100%')};
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
`;
const IconChipContainerStyled = (0, styles_1.styled)('div') `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  margin-bottom: -16px;
`;
const Card = (props) => {
    const { src, title, subContent, subTitle, children, onClick, iconName, chipContent, disabled = false, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const handleCardClick = (0, react_1.useCallback)((event) => {
        onClick?.(event);
    }, [onClick]);
    const IconComponent = iconName && Icons_1.Icons[iconName];
    return ((0, jsx_runtime_1.jsxs)(CardWrapperStyle, { hasImg: 'src' in props, children: [children, (0, jsx_runtime_1.jsxs)(CardStyle, { onClick: handleCardClick, disabled: disabled, children: ['src' in props && (0, jsx_runtime_1.jsx)(Img, { src: src }), (IconComponent || chipContent) && ((0, jsx_runtime_1.jsxs)(IconChipContainerStyled, { children: [IconComponent && ((0, jsx_runtime_1.jsx)(IconComponent, { fontSize: "large", sx: { color: theme.sys.color.primary } })), chipContent && (0, jsx_runtime_1.jsx)(Chip_1.Chip, { label: chipContent })] })), (0, jsx_runtime_1.jsxs)(CardInfoContent, { hasImg: 'src' in props, children: [(0, jsx_runtime_1.jsx)(TextStyled, { typescale: "body", size: "medium", title: (0, utils_1.getNodeText)(title), oneLine: true, children: title }), subTitle && ((0, jsx_runtime_1.jsx)(TextStyled, { typescale: "body", size: "small", title: (0, utils_1.getNodeText)(subTitle), oneLine: true, children: subTitle })), subContent && ((0, jsx_runtime_1.jsx)(TextStyled, { typescale: "body", size: "small", title: (0, utils_1.getNodeText)(subContent), children: subContent }))] })] })] }));
};
exports.Card = Card;
