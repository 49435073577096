import { useParams, Navigate } from 'react-router-dom';
import { useAccessControls } from '../../../hooks/useAccessControls';
import documentLibraries from '../documentLibraries';
import * as _ from 'lodash';

interface DocumentLibraryAccessControllerProps {
  children: JSX.Element;
}
const DocumentLibraryAccessController = ({ children }: DocumentLibraryAccessControllerProps) => {
  const { library } = useParams();
  const { data: accessControls } = useAccessControls();
  const filteredLibraries = documentLibraries.filter((item) =>
    item.accessControlIndexer ? _.get(accessControls, item.accessControlIndexer) : true
  );
  const hasAccess = filteredLibraries.some((item) => item.library === library?.toUpperCase());

  return hasAccess ? children : <Navigate to="/documents" />;
};

export default DocumentLibraryAccessController;
