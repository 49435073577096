import { css } from 'styled-components';

export const tileCss = css`
  width: auto;
  height: 200px;
  padding: 24px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 233, 236);
  background-color: white;
`;
