import { gql } from '@apollo/client';

export const POLICY_FIELDS = gql`
  fragment PolicyFields on Policy {
    id
    accountId
    title
    version
    createdAt
    reviewDate
  }
`;

export const DOCUMENT_FIELDS = gql`
  fragment PolicyDocumentFields on DocumentRecord {
    id
    link
    fileName
  }
`;

export const GET_POLICIES_BY_ACCOUNT_ID = gql`
  query GetPoliciesByAccountId($accountId: ID!) {
    getPoliciesByAccountId(accountId: $accountId) {
      id
      accountId
      title
      version
      createdAt
      reviewDate
    }
  }
`;

export const GET_POLICY_BY_ID = gql`
  query GetPolicyById($id: ID!, $accountId: ID!) {
    getPolicyById(id: $id, accountId: $accountId) {
      ...PolicyFields
      documents {
        ...PolicyDocumentFields
      }
    }
  }
  ${POLICY_FIELDS}
  ${DOCUMENT_FIELDS}
`;

export const SAVE_POLICY = gql`
  mutation SavePolicy($policy: SavePolicyInput!, $accountId: String!) {
    savePolicy(policy: $policy, accountId: $accountId) {
      ...PolicyFields
      documents {
        ...PolicyDocumentFields
      }
    }
  }
  ${POLICY_FIELDS}
  ${DOCUMENT_FIELDS}
`;

export const CREATE_DOCUMENT_RECORD = gql`
  mutation CreateDocumentRecord(
    $id: ID!
    $accountId: ID!
    $referenceId: String
    $key: String!
    $userId: ID!
    $category: String!
    $type: [String!]
    $fileName: String!
    $library: DocumentLibrary!
    $reviewDate: String
    $source: Source!
  ) {
    createDocumentRecord(
      input: {
        id: $id
        userId: $userId
        accountId: $accountId
        referenceId: $referenceId
        key: $key
        category: $category
        type: $type
        fileName: $fileName
        library: $library
        reviewDate: $reviewDate
        source: $source
      }
    ) {
      id
    }
  }
`;
