import { useCallback } from 'react';
import { TYPES } from '~/constants/aspects';
import { useCurrentAccountId } from '~/hooks/useCurrentAccountId';
import { CreateOptionModal } from '../CreateOptionModal/CreateOptionModalV2';

export const CreateDepartmentModal = ({
  onError,
  onComplete,
  passedAccountId,
  showCreateDepartment,
  setShowCreateDepartment,
}) => {
  const currentAccountId = useCurrentAccountId();
  const accountId = passedAccountId || currentAccountId;

  const handlerComplete = useCallback(
    (data, newDepartmentName) => {
      onComplete(data, newDepartmentName);
      setShowCreateDepartment(false);
    },
    [onComplete, setShowCreateDepartment]
  );

  return (
    showCreateDepartment && (
      <CreateOptionModal
        accountId={accountId}
        type={TYPES.DEPARTMENT}
        onDismiss={() => setShowCreateDepartment(false)}
        onError={(error) => onError && onError(error)}
        onCompleted={handlerComplete}
      />
    )
  );
};
