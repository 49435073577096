import { Course } from '@rio/rio-types';
import { Link } from 'react-router-dom';
import { Card, styled } from '@rio/ui-components';

import { formatCpd } from '../utils';

import { CourseProgressIcon } from './CourseProgressIcon';

type CoursesStyledProp = {
  useGridView: boolean;
};

interface CoursesSectionProps {
  courses: Course[];
  useGridView?: boolean;
}

const CardStyled = styled(Card)`
  &:hover {
    cursor: pointer;
  }
`;

const ItemStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'useGridView',
})<CoursesStyledProp>(({ useGridView }) => ({
  minWidth: 260,
  width: useGridView ? '100%' : 260,
}));

const Courses = styled('div', {
  shouldForwardProp: (prop) => prop !== 'useGridView',
})<CoursesStyledProp>(({ useGridView }) =>
  useGridView
    ? {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
        gridGap: 25,
        padding: '5px 0',
      }
    : {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        gap: '25px',
        minHeight: '246px',
        overflowX: 'auto',
        padding: '0 3px',
      }
);

const CourseInfo = styled('span')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 21px;
`;

const CourseInfoName = styled('span')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export function CourseCards({ courses, useGridView }: CoursesSectionProps) {
  return (
    <Courses useGridView={!!useGridView}>
      {courses.map((c: Course) => (
        <ItemStyle useGridView={!!useGridView} key={c.id}>
          <Link to={`/v2/engage/course/${c.id}`}>
            <CardStyled
              title={
                (
                  <CourseInfo title={c.name}>
                    <CourseInfoName title={c.name}>{c.name}</CourseInfoName>
                    <CourseProgressIcon status={c.userCourseMetaData?.courseProgress} />
                  </CourseInfo>
                ) as any
              }
              subTitle={formatCpd(c?.metaData?.cpd)}
              src={c.metaData?.thumbnail}
            />
          </Link>
        </ItemStyle>
      ))}
    </Courses>
  );
}
