import { gql } from '@apollo/client';

export const GET_TAGS_BY_ACCOUNT_ID = gql`
  query GetTags($accountId: String!) {
    getTagsByAccountId(input: { accountId: $accountId, applicationId: "RIO" }) {
      id
      tagName
      isSystemLevelTag
      legislationTag
      locations {
        id
      }
    }
  }
`;

