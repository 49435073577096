import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { TaskSubject, Task } from '@rio/rio-types';
import { Col, LoadingIndicator } from 'rio-ui-components';
import GET_TASKS_BY_ACCOUNT from '../../../graphql/queries/tasks/GetTasksByAccountId.query.graphql';
import { Grid } from '../../TasksContainer/TasksGrid/Grid';
import { EditTaskModal } from '../../TasksContainer/TasksGrid/EditTaskModal';
import { useCurrentAccountId, useUserToken, usePermissions, useAgGrid } from '../../../hooks';
import { LoadFailed } from '../../../components/Errors';

const ContentContainer = styled(Col)`
  display: flex;
  min-height: 300px;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const TasksGridContainer = () => {
  const { id: aspectId } = useParams();
  const accountId = useCurrentAccountId();
  const [showEditModal, setShowEditModal] = useState<Task | null>(null);
  const permissions = usePermissions();
  const { token } = useUserToken();
  const agGrid = useAgGrid();
  const { data, loading, refetch, error } = useQuery(GET_TASKS_BY_ACCOUNT, {
    variables: { accountId, userId: token.sub },
  });
  const tasks = data?.getTasksByAccountId || [];
  const tasksOfThisAspect = tasks.filter((task: Task) => task.item?.id === aspectId);

  return (
    <ContentContainer container item>
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {showEditModal && (
        <EditTaskModal
          selectedTask={showEditModal}
          onDismiss={() => setShowEditModal(null)}
          disabled={{ [TaskSubject.Aspects]: true }}
          sourcePage="ASPECTS"
        />
      )}

      {error ? (
        <LoadFailed error={error} retry={() => refetch({ accountId, userId: token.sub })} />
      ) : (
        !loading && (
          <Grid
            canEdit={!!permissions.task.find((action: string) => action.startsWith('edit'))}
            tasks={tasksOfThisAspect}
            agGrid={agGrid}
          />
        )
      )}
    </ContentContainer>
  );
};

export default TasksGridContainer;
