import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Text, Icon } from 'rio-ui-components';
import styled, { withTheme } from 'styled-components';

const RowStyled = styled(Row)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  ${(p) => {
    if (p.action) return `background-color: ${p.theme.colors.warning.highlight.background};`;
  }};
`;

const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
`;

const ColFlexEnd = styled(ColStyled)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: ${(props) => `${props.theme.geometry.md.spacing} ${props.theme.geometry.sm.spacing}`};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

const WasteStreamRow = (props) => {
  const { name, id, streamId, ewc, description, isHazardous, onEdit } = props;

  const handleEdit = () => {
    if (onEdit) onEdit(streamId);
  };

  return (
    <RowStyled container name={`${id}__WasteStream__Row`}>
      <ColStyled span={2}>
        <Text name={`${id}__WasteStream__Name`}>{name}</Text>
      </ColStyled>
      <ColStyled span={2}>
        <Text name={`${id}__WasteStream__ewc`}>{ewc}</Text>
      </ColStyled>
      <ColStyled span={4}>
        <Text name={`${id}__WasteStream__description`}>{description}</Text>
      </ColStyled>
      <ColStyled span={2}>
        <Text name={`${id}__WasteStream__hazardous`}>{isHazardous}</Text>
      </ColStyled>
      <ColFlexEnd span={2}>
        <EditLinkStyled onClick={handleEdit}>
          <Icon name={`${id}__WasteStream__edit`} icon="pencil-alt" />
        </EditLinkStyled>
      </ColFlexEnd>
    </RowStyled>
  );
};

WasteStreamRow.propTypes = {
  id: PropTypes.string,
  streamId: PropTypes.string,
  name: PropTypes.string,
  ewc: PropTypes.string,
  isHazardous: PropTypes.string
};

export default withTheme(WasteStreamRow);
