import styled from 'styled-components';
import { CarbonDisclosureScoreChart } from './CarbonDisclosureScoreChart';
import { CarbonDisclosureScoreGrid } from './CarbonDisclosureScoreGrid';

const ScoreChartWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const ScoreGridWrapper = styled.div`
  display: flex;
  height: auto;
`;

export function CarbonDisclosureFrameworkOverviewForm() {
  return (
    <>
      <ScoreChartWrapper>
        <CarbonDisclosureScoreChart />
      </ScoreChartWrapper>
      <ScoreGridWrapper>
        <CarbonDisclosureScoreGrid />
      </ScoreGridWrapper>
    </>
  );
}
