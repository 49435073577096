import React from 'react';
import styled from 'styled-components';
import { Row, Col, CircleIcon, Heading, Text } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Thumbnail = styled(Row)`
  padding: 0 0 60% 0;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.overlay.light.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
  position: relative;
  &:hover .overlay {
    opacity: 1;
    div {
      display: block;
    }
  }
`;

const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.basic.white};
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
  margin-top: -${(props) => props.theme.geometry.xl.spacing};
`;

const HeadingStyled = styled(Heading)`
  margin-top: -${(props) => props.theme.geometry.xs.spacing};
`;

const TextContainer = styled(Col)`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: ${({ theme }) => theme.colors.basic.white};
  position: relative;
`;

const ComingSoonPill = styled.div`
  position: absolute;
  right: ${(props) => props.theme.geometry.sm.spacing};
  top: ${(props) => props.theme.geometry.sm.spacing};
  flex: 0 0 auto;
  opacity: 0.7;
  display: inline-block;
  background: ${(props) => props.theme.colors.text.dark.background};
  padding: ${(props) => props.theme.geometry.xs.spacing} ${(props) => props.theme.geometry.sm.spacing};
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: ${(p) => p.theme.colors[p.color].normal.background};
`;

const OverlayText = styled.div`
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  color: white;
  font-size: 20px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  display: none;
`;

const SubjectTile = ({ title, icon, iconColor, comingSoon, image, fullAccess, description }) => {
  return (
    <ContainerStyled name={`SubjectTile SubjectTile--${title}`} container item disabled={comingSoon || !fullAccess}>
      <Thumbnail name="SubjectTile__Thumbnail" container item distribution="between" src={image}>
        {description && (
          <OverlayContainer className="overlay" color={iconColor}>
            <OverlayText>{description}</OverlayText>
          </OverlayContainer>
        )}
        {comingSoon && (
          <ComingSoonPill>
            <Text align="center" size="xs" color="white">
              Upgrade here
            </Text>
          </ComingSoonPill>
        )}
      </Thumbnail>

      <TextContainer container item vdistribution="between">
        <Row container item itemAlign="center">
          <CircleIconStyled name="SubjectTile__Icon" height="70px" size="lg" icon={icon} iconColor={iconColor} circleColor={iconColor} />
          <HeadingStyled name="SubjectTile__Title" size="lg" color="dark">
            {title}
          </HeadingStyled>
        </Row>
      </TextContainer>
    </ContainerStyled>
  );
};

export default SubjectTile;
