import { Fragment, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { startCase } from 'lodash';
import { useIsV2 } from '~/hooks';

interface TitleProviderProps {
  children: ReactNode;
}

const TitleProvider = ({ children }: TitleProviderProps) => {
  const location = useLocation();
  const v2 = useIsV2();

  useLayoutEffect(() => {
    const parts = location.pathname.split('/').map((part) => startCase(part.toString().toLowerCase()));
    const v1Parts = parts.length > 1 ? parts[1] : '';
    const v2Parts = parts.length > 2 ? parts[2] : '';

    const titleParts = v2 ? v2Parts : v1Parts;

    document.title = `Rio AI ${titleParts ? `- ${titleParts}` : ''}`;
  }, [location, v2]);

  return <Fragment>{children}</Fragment>;
};

export default TitleProvider;
