import { gql } from '@apollo/client';

export const GET_ALL_DASHBOARDS = gql`
  query GetAllDashboards {
    getAllDashboards {
      id
      name
      description
      area
      provider
      providerDashboardId
    }
  }
`;

export const GET_DASHBOARD = gql`
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      description
      area
      provider
      providerDashboardId
      thumbnailType
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  mutation CreateDashboard(
    $id: ID!
    $name: String!
    $description: String!
    $provider: DashboardProvider!
    $area: DashboardArea!
    $providerId: String!
    $thumbnail: ThumbnailType
  ) {
    createDashboard(
      input: {
        id: $id
        name: $name
        description: $description
        area: $area
        provider: $provider
        providerDashboardId: $providerId
        thumbnailType: $thumbnail
      }
    )
  }
`;

export const UPDATE_DASHBOARD = gql`
  mutation UpdateDashboard(
    $id: ID!
    $name: String!
    $description: String!
    $provider: DashboardProvider!
    $area: DashboardArea!
    $providerId: String!
    $thumbnail: ThumbnailType
  ) {
    updateDashboard(
      input: {
        id: $id
        name: $name
        description: $description
        area: $area
        provider: $provider
        providerDashboardId: $providerId
        thumbnailType: $thumbnail
      }
    )
  }
`;
