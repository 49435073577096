import { useApolloClient, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_NORMALISER_TYPES } from './index.queries';
import { uploadNormalisers } from './uploadNormalisersFromSpreadsheet';
import { MassUploadModal } from '../../../components/MassUploadModal';
import { TemplateType } from '../../../types';

const columns = ['Name', 'Description', 'Quantity', 'Start Date', 'End Date', 'Location', 'Tag', 'Scope', 'Type'];

export function UploadNormalisersModal({ accountId, showNotification, onDismiss }) {
  const [problems, setProblems] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const apolloClient = useApolloClient();
  const { data: normaliserTypesData } = useQuery(GET_NORMALISER_TYPES);
  const normaliserTypes = normaliserTypesData ? normaliserTypesData.getNormaliserTypes : [];
  const startUploading = async ([file]) => {
    setIsUploading(true);
    try {
      const [problemsFound, result] = await uploadNormalisers(file, apolloClient, accountId, normaliserTypes);
      const errors = result?.data?.createNormalisers?.errors || problemsFound;
      setProblems(errors);
      setIsUploading(false);
      if (!errors.length) {
        showNotification('Your normalisers have been successfully uploaded!', 'success');
      }
    } catch (err) {
      showNotification(
        `Something went wrong, normalisers weren't uploaded. If the problem persists, contact support. ${err}`,
        'danger'
      );
    }
  };
  return (
    <MassUploadModal
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      template={{
        columns,
        type: TemplateType.Dynamic,
        fileName: 'normalisers-upload'
      }}
      isUploading={isUploading}
      errors={problems}
      onFileInput={startUploading}
      onDismiss={onDismiss}
    />
  );
}
