import { useCallback } from 'react';
import { Mutation, AnswerReportingQuestionInput, QuestionUnion } from '@rio/rio-types';
import { useCurrentAccountId } from '../../hooks';
import { useMutation } from '@apollo/client';
import { ANSWER_REPORTING_QUESTIONS } from './index.queries';
import { useNotification } from '../../hooks';
import { useReadGetReportingQuestions, useWriteGetReportingQuestions } from './useGetReportingQuestions';
import { mapQuestionToInput, updateRecursively } from './utils';

export type MutationResponse = Pick<Mutation, 'answerReportingQuestions'>;

export interface MutationVariables {
  accountId: string;
  input: AnswerReportingQuestionInput;
}

function useUpdateCache() {
  const readGetReportingQuestions = useReadGetReportingQuestions();
  const writeGetReportingQuestions = useWriteGetReportingQuestions();
  return useCallback(
    (question: QuestionUnion, frameworkId: string, chapterId?: string) => {
      const data = readGetReportingQuestions(frameworkId, chapterId);
      if (data?.getReportingQuestions) {
        const updatedQuestions = updateRecursively(data.getReportingQuestions, question);
        writeGetReportingQuestions(updatedQuestions, frameworkId, chapterId);
      }
    },
    [readGetReportingQuestions, writeGetReportingQuestions]
  );
}

export function useAnswerReportingQuestions() {
  const { showNotification } = useNotification();
  return useMutation<MutationResponse, MutationVariables>(ANSWER_REPORTING_QUESTIONS, {
    onError: (err: Error) => {
      showNotification(`Couldn't update the answer: ${err}`, 'danger');
    },
  });
}

export function useAnswerQuestion() {
  const [answerReportingQuestions] = useAnswerReportingQuestions();
  const accountId = useCurrentAccountId();
  const updateCache = useUpdateCache();
  return useCallback(
    (q: QuestionUnion) => {
      updateCache(q, q.reportingFrameworkId!, q.reportingFrameworkChapterId!);
      answerReportingQuestions({
        variables: {
          accountId,
          input: mapQuestionToInput(q),
        },
      });
    },
    [answerReportingQuestions, accountId, updateCache]
  );
}
