import { ReceivedSurvey, SurveySubmissionStatus } from '@rio/rio-types';
import styled from 'styled-components';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { Row } from 'rio-ui-components';
import { StatusSpan, TaskIcon, formatSubmissionStatus, getProgress, SurveyInfoStatus } from '../Surveys';
import { SendFeedbackButtons } from './SendFeedbackButtons';
import { ReviewFeedbackButton } from './ReviewFeedbackButton';
import { AutoSave } from './AutoSave';

type SendFeedbackFunction = (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;

interface BaseProps {
  survey: ReceivedSurvey;
  newSubscriptionUpdate?: boolean;
  showAutoSave?: boolean;
}

interface ReadonlyProps extends BaseProps {
  readonly: true;
  contributor: string;
  sendFeedback: SendFeedbackFunction;
}

interface WorksheetProps extends BaseProps {
  readonly: false;
  feedback?: string | null;
}

type SurveyInfoProps = ReadonlyProps | WorksheetProps;

const Section = styled.section<{ name: string }>`
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 24px 0 24px;
  flex-shrink: 0;
  background-color: white;
`;
const SectionRow = styled(Row)`
  justify-content: space-between;
  flex-flow: row nowrap;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.text.normal.background};
`;
const SurveyInfoPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const ResponseFrom = styled.div`
  & svg {
    margin-right: 12px;
  }
`;
const TaskIconStyled = styled(TaskIcon)`
  margin-right: 12px;
`;
const Completion = styled.span<{ status?: SurveySubmissionStatus }>`
  text-align: right;
  display: flex;
  align-items: center;
`;

export function SurveyInfo(props: SurveyInfoProps) {
  const { survey, newSubscriptionUpdate } = props;
  const { template } = survey;

  return (
    <Section name="SubmissionInfo__Section">
      <SectionRow item container>
        <InfoContainer>
          <SurveyInfoPanel>
            {props.readonly && (
              <ResponseFrom>
                <TaskIconStyled />
                <span>Viewing response from {props.contributor}</span>
              </ResponseFrom>
            )}
          </SurveyInfoPanel>
        </InfoContainer>

        <Completion status={survey.submission?.status}>
          {props.showAutoSave && <AutoSave />}
          <StatusSpan status={survey.submission?.status}>
            {formatSubmissionStatus(survey.submission?.status, getProgress(template, survey.submission))}
          </StatusSpan>
          <SurveyInfoStatus
            newSubscriptionUpdate={newSubscriptionUpdate}
            showAutoSave
            deadlineDate={survey.deadlineDate}
            survey={survey}
          />
        </Completion>
      </SectionRow>
      {props.readonly && survey.feedbackEnabled && survey.submission && (
        <SendFeedbackButtons submission={survey.submission} sendFeedback={props.sendFeedback} />
      )}
      {!props.readonly && survey.submission?.feedback && <ReviewFeedbackButton survey={survey} />}
    </Section>
  );
}
