import { gql } from '@apollo/client';

export const GET_SCHEMES_BY_ACCOUNT_ID = gql`
  query GetSchemesByAccountId($accountId: ID!) {
    getProjectSchemes(accountId: $accountId) {
      id
      name
      category {
        id
        name
      }
    }
  }
`;

export const GET_SCHEMES_PAGE = gql`
  query GetSchemesPage(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getProjectSchemesPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
        description
        category {
          id
          name
        }
      }
    }
  }
`;
