import { useMutation } from '@apollo/client';
import { Query, Mutation, MutationUpdateSurveyQuestionCategoryArgs as Variables } from '@rio/rio-types';
import { UPDATE_SURVEY_QUESTION_CATEGORY, GET_SURVEY_QUESTION_CATEGORIES } from './queries';

type Response = Pick<Mutation, 'updateSurveyQuestionCategory'>;

export function useUpdateSurveyQuestionCategory() {
  return useMutation<Response, Variables>(UPDATE_SURVEY_QUESTION_CATEGORY, {
    update: (cache, { data }) => {
      cache.updateQuery<Pick<Query, 'getSurveyQuestionCategories'>>(
        { query: GET_SURVEY_QUESTION_CATEGORIES },
        (cacheResponse) => {
          const categories = cacheResponse?.getSurveyQuestionCategories;
          if (!categories || !data?.updateSurveyQuestionCategory) {
            return;
          }
          return {
            getSurveyQuestionCategories: categories.map((c) =>
              c.id === data.updateSurveyQuestionCategory.id ? data.updateSurveyQuestionCategory : c
            ),
          };
        }
      );
    },
  });
}
