import { gql } from "@apollo/client";

export const GET_ALL_FUEL_TYPES = gql`
  query GetAllFuelTypes {
    getAllFuelTypes {
      id
      name
    }
  }
`;
