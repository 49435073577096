import { useApolloClient } from '@apollo/client';
import { useRef, RefObject, useState } from 'react';
import { Query, WasteStream } from '@rio/rio-types';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import { GET_WASTE_STREAMS_BY_ACCOUNT_ID } from './index.queries';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { Select, Modal } from 'rio-ui-components';
import { SelectEvent, Nullable, Option } from '../../types';
import { CREATE_NEW_OPTION, CREATE_NEW_OPTION_ID } from '../../utils';
import { usePermissions, useNotification } from '../../hooks';
import CreateWasteStream from '../../containers/ConfigurationContainer/ConfigurationWasteStreamContainer/CreateWasteStream';
import { ToastColor } from '@rio/ui-components';

interface WasteStreamSelectProps {
  value?: Nullable<string | Option>;
  onChange: (wasteStream: WasteStream) => void;
  createNew?: boolean;
  createNewModalSize?: number;
  error?: string;
}

function wasteStreamToSelectOptions(wasteStream: WasteStream) {
  return {
    value: wasteStream.id,
    label: wasteStream.name,
  };
}

export function WasteStreamSelect({
  value,
  onChange,
  createNew,
  error,
  createNewModalSize = 6,
}: WasteStreamSelectProps) {
  const accountId = useCurrentAccountId();
  const apolloClient = useApolloClient();
  const wasteStreamsById: RefObject<Dictionary<WasteStream>> = useRef({} as Dictionary<WasteStream>);
  const [createModalShown, setCreateModalShown] = useState(false);
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  return (
    <>
      <Select
        name="wasteStreamId"
        placeholder="Begin typing to find waste stream"
        value={value}
        error={error}
        defaultOptions
        cacheOptions
        loadOptions={async (filterValue: string) => {
          const response = await apolloClient.query<Query>({
            query: GET_WASTE_STREAMS_BY_ACCOUNT_ID,
            variables: {
              accountId,
              filterValue,
            },
          });
          const { wasteStreams, totalItemCount } = response.data.getWasteStreamsByAccountId;
          Object.assign(wasteStreamsById.current as Dictionary<WasteStream>, keyBy(wasteStreams, 'id'));
          const options = wasteStreams.map(wasteStreamToSelectOptions);
          if (createNew && permissions.data.find((action: string) => action.startsWith('createWasteStream'))) {
            options.unshift(CREATE_NEW_OPTION);
          }
          return { options, total: totalItemCount };
        }}
        onChange={({ target }: SelectEvent) => {
          if (target.select.value === CREATE_NEW_OPTION_ID) {
            setCreateModalShown(true);
          } else {
            onChange(wasteStreamsById.current?.[target.value] as WasteStream);
          }
        }}
      />
      {createModalShown && (
        <Modal
          size="md"
          span={createNewModalSize}
          onDimiss={() => {
            setCreateModalShown(false);
          }}
          show
        >
          <CreateWasteStream
            accountId={accountId}
            onSuccess={(message: string, color: string, id: string, wasteStream: WasteStream) => {
              showNotification(message, color as ToastColor);
              setCreateModalShown(false);
              onChange(wasteStream);
            }}
          />
        </Modal>
      )}
    </>
  );
}
