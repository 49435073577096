import { Routes, Route } from 'react-router-dom';
import { styled } from '@rio/ui-components';
import TasksViewContainer from '../TaskView/v2';
import { TasksGridContainer } from '../TasksGrid/v2';

const ContainerStyled = styled('div')`
  width: 100%;
  height: 100%;
`;

export const TasksRoutes = ({ hasProjectsAccess }: { hasProjectsAccess: boolean }) => {
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<TasksGridContainer hasProjectsAccess={hasProjectsAccess} />} />
        <Route path="/:id" element={<TasksViewContainer />} />
      </Routes>
    </ContainerStyled>
  );
};
