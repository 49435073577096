import { gql } from '@apollo/client';

export const GET_ALL_SUPPLIERS = gql`
  query GetSuppliers(
    $accountId: ID!
    $filterValue: String
    $filterBy: String
    $utility: TransactionType
    $pageSize: Int!
  ) {
    getSuppliers(
      accountId: $accountId
      filterValue: $filterValue
      filterBy: $filterBy
      utility: $utility
      page: 1
      pageSize: $pageSize
    ) {
      suppliers {
        id
        name
        accountId
        type
        utility
        address {
          id
          address1
          address2
          address3
          city
          postcode
          phone1
        }
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      name
      accountId
      type
      utility
      address {
        id
        address1
        address2
        address3
        city
        postcode
        phone1
      }
    }
  }
`;
