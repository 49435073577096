import styled from 'styled-components';
import { Col, Button, Row, LoadingIndicator } from 'rio-ui-components';

export const AccountLicencesConfigurationRow = styled(Row)`
  margin: 0 auto 40px;
  width: 80%;

  h2 {
    margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
    font-size: ${(props) => props.theme.fonts.lg.size};
  }
`;

export const CourseBlock = styled.div`
  margin: 20px auto;
`;

export const LicenceBlock = styled.div`
  margin: 20px auto;

  label {
    display: inline-block;
    margin-bottom: 15px;
  }
`;

export const EmptyLicencesMessage = styled.div`
  text-align: center;
  padding-top: 40px;

  h2 {
    font-size: ${(props) => props.theme.fonts.lg.size};
  }

  a {
    text-transform: uppercase;
    text-decoration: underline;
  }
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 30px auto 0;
`;

export const StyledButton = styled(Button)`
  width: 150px;
  float: right;
  margin-left: 10px;
`;

export const FormHolder = styled.div`
  margin: 0 auto;
  width: 85%;
  padding-top: 40px;

  h1 {
    width: 80%;
    font-size: ${(props) => props.theme.fonts.md.size};
    margin: 0 auto;
  }

  p {
    margin: 0 auto 20px;
    font-size: 20px;
    width: 80%;
  }
`;

export const FirstLatterUpper = styled.span`
  text-transform: capitalize;
`;

export const UserCount = styled.span`
  font-size: ${(props) => props.theme.fonts.md.size}
  font-weight: bold;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  margin-bottom: 20px;
`;

export const CreateButton = styled(Button)`
  width: 200px;
  margin: 0 auto;
`;

export const ColTextLeft = styled(Col)`
  text-align: left;
`;

export const ColTextRight = styled(Col)`
  text-align: right;
`;

export const AddAllButton = styled(Button)`
  width: 160px;
  display: inline-block;
`;

export const ManageLicencesButton = styled(Button)`
  width: 160px;
  float: right;
`;

export const CounterHolder = styled.div`
  display: inline-block;
  margin-left: 20px;

  & > div {
    float: none;
  }
`;

export const ButtonHolder = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
