import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_INFO } from '../queries/accounts';
import { useCurrentAccountId } from './useCurrentAccountId';

export function useCurrentAccount() {
  const { data } = useQuery(GET_ACCOUNT_INFO, {
    variables: { id: useCurrentAccountId() }
  });

  return data && data.getAccountInfo ? data.getAccountInfo : null;
}
