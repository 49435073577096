import { TransactionType } from '@rio/rio-types';
import { TemplateType } from '../../../../types';
import UploadContainer from '../../UploadContainer';
import template from './transport-template.xlsx';

export function UploadTransportTransactions({ ...props }) {
  return (
    <UploadContainer
      {...props}
      dataSection={TransactionType.Transport}
      template={{
        type: TemplateType.Static,
        fileName: 'advanced-transport-template',
        url: template,
      }}
    />
  );
}
