import { TransactionType, AliasType, Supplier, SupplierType, WasteStream } from '@rio/rio-types';
import { Option } from '../../types';
import { TransportTypeSelect } from '../TransportTypeSelect';
import { FuelTypeSelect } from '../FuelTypeSelect';
import { SubTypeSelect } from '../SubtypeSelect';
import { SelectEvent, Nullable } from '../../types';
import { LocationSelect, WasteInLocationSelect, WasteOutLocationSelect } from '../LocationSelect';
import { AccuracySelect } from '../AccuracySelect';
import { WasteStreamSelect } from '../WasteStreamSelect';
import { ContainerTypeSelect } from '../ContainerTypeSelect';
import { SupplierSelector } from '../SuppliersSelector';
import { MeterSelect } from '../MeterSelect';
import { TreatmentProcessSelect } from '../TreatmentProcessSelect';
import { TransportFuelTypeSelect } from '../TransportFuelTypeSelect';
import { JourneyTargetSelect } from '../JourneyTargetSelect';
import { MeasurementUnitSelect } from '../MeasurementUnitSelect';
import { CurrencySelect } from '../CurrencySelect';
import { MaterialSelect } from '../MaterialSelect';
import { MaterialOriginSelect } from '../MaterialOriginSelect';
import { TariffSelect } from '../TariffSelect';

interface AliasParentSelectProps {
  transactionType: TransactionType;
  accountId: string;
  aliasType: AliasType;
  onChange: (id: string, name: string) => void;
  value: Nullable<string | Option>;
  possibleOptions?: Nullable<string[]>;
}

export function AliasParentSelect({
  transactionType,
  aliasType,
  onChange,
  value,
  accountId,
  possibleOptions = [],
}: AliasParentSelectProps) {
  const handleChange = ({ target: { select } }: SelectEvent) => {
    onChange(select.value, select.label);
  };

  switch (aliasType) {
    case AliasType.Accuracy:
      return <AccuracySelect onChange={handleChange} value={value} />;
    case AliasType.Transport:
      return <TransportTypeSelect onChange={handleChange} value={value} />;
    case AliasType.Locationpoint:
      return <LocationSelect value={value} onChange={handleChange} createNew />;
    case AliasType.Wastein:
      return <WasteInLocationSelect onChange={handleChange} value={value} createNew createNewAccessor="wasteIn." />;
    case AliasType.Wasteout:
      return <WasteOutLocationSelect onChange={handleChange} value={value} createNew createNewAccessor="wasteOut." />;
    case AliasType.Wastestream:
      return (
        <WasteStreamSelect
          onChange={(wasteStream: WasteStream) => onChange(wasteStream.id, wasteStream.name)}
          value={value}
          createNew
        />
      );
    case AliasType.Containertypesize:
      return <ContainerTypeSelect onChange={onChange} value={value} />;
    case AliasType.Wastecontractor:
      return (
        <SupplierSelector
          onChange={handleChange}
          value={value}
          accountId={accountId}
          utility={TransactionType.Waste}
          filter={(s: Supplier) => s.type.includes(SupplierType.Contractor)}
        />
      );
    case AliasType.Wastecarrier:
      return (
        <SupplierSelector
          onChange={handleChange}
          value={value}
          accountId={accountId}
          utility={TransactionType.Waste}
          filter={(s: Supplier) => s.type.includes(SupplierType.Carrier)}
        />
      );
    case AliasType.Meter:
      return (
        <MeterSelect
          type={transactionType}
          onChange={(id, name) => onChange(id, name)}
          value={value}
          accountId={accountId}
          createNew
        />
      );
    case AliasType.Wastetreatmentprocess:
      return <TreatmentProcessSelect onChange={handleChange} value={value} />;
    case AliasType.Fueltype:
      return <FuelTypeSelect onChange={handleChange} value={value} />;
    case AliasType.Transportfueltype:
      return <TransportFuelTypeSelect onChange={handleChange} value={value} />;
    case AliasType.Transportsubtype:
      return <SubTypeSelect onChange={handleChange} value={value} />;
    case AliasType.Journeytarget:
      return <JourneyTargetSelect onChange={handleChange} value={value} />;
    case AliasType.Unit:
      return <MeasurementUnitSelect onChange={handleChange} value={value} possibleOptions={possibleOptions} />;
    case AliasType.Currency:
      return <CurrencySelect onChange={handleChange} value={value} />;
    case AliasType.Tariff:
      return <TariffSelect onChange={handleChange} value={value} accountId={accountId} />;
    case AliasType.Material:
      return <MaterialSelect onChange={handleChange} value={value} accountId={accountId} />;
    case AliasType.Materialorigin:
      return <MaterialOriginSelect onChange={handleChange} value={value} />;
    case AliasType.Counterparty:
      return <SupplierSelector onChange={handleChange} value={value} accountId={accountId} utility={transactionType} />;
    default:
      return null;
  }
}
