import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import TetherComponent from 'react-tether';
import { SET_CURRENT_ACCOUNT_ID } from '../../queries/currentAccount';
import { AccountLogo } from './AccountLogo';
import { useUserAccounts } from '../../hooks/useUserAccounts';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { useUserToken } from '../../hooks/useUserToken';
import { alphabetiseByName } from '../../utils';

const pulseAnimation = keyframes`
  0% { opacity: 0.4; }
  40% { opacity: 0.15; } 
  100% { opacity: 0.4; } 
`;

const pulseAnimationCss = css`
  ${pulseAnimation} infinite 2s;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const AccountNamePlaceholder = styled.div`
  height: ${(props) => props.theme.fonts.md.size};
  width: ${(props) => props.width};
  background-color: ${(props) => props.theme.colors.text.normal.background};
  opacity: 0.4;
  border-radius: ${(props) => props.theme.geometry.md.radius};
  animation: ${pulseAnimationCss};
  animation-delay: 0.1s;
`;

const AccountNameContainer = styled.div`
  position: relative;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 1 1 auto;
  flex-direction: column;
  place-content: stretch center;
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
    background: ${(props) => (props.clickable ? props.theme.colors.overlay.light.background : 'initial')};
  }
`;

const AccountList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 300px;
  max-height: 350px;
  overflow-y: auto;
  background-color: white;
`;

const AccountListItem = styled.li`
  display: flex;
  width: 100%;
  height: 70px;
  min-height: 70px;
  align-items: center;
  padding-left: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.xs.spacing};
  padding-bottom: ${(props) => props.theme.geometry.xs.spacing};
  border-top: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};

  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: ${({ theme }) => theme.colors.basic.white};
  border: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
  border-top: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.overlay.light.background};
  }
`;

const AccountListItemLogoContainer = styled.div`
  min-width: 110px;
  max-width: 110px;
  height: 100%;
`;

const AccountListItemLogo = styled.img`
  min-width: 110px;
  max-width: 110px;
  height: 100%;
`;

const AccountNameSpan = styled.span`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

function getAccountNameString(accountName) {
  const shortenedName = accountName.length > 25 ? `${accountName.slice(0, 22)}...` : accountName;
  return shortenedName;
}

const AccountNameComponent = () => {
  const { token: idToken } = useUserToken();
  const collapsed = localStorage.getItem('SIDEBAR_COLLAPSED') === 'collapsed';
  const [accountListCollapsed, setAccountListCollapsed] = useState(true);
  const toggleAccountList = (e) => {
    if (
      e.target.id !== 'Sidebar__AccountSection__AcountName--Container' &&
      e.target.id !== 'Sidebar__AccountSection__AcountName--Logo'
    ) {
      setAccountListCollapsed(true);
      return;
    }
    setAccountListCollapsed(!accountListCollapsed);
  };
  const currentAccountId = useCurrentAccountId();
  const { accountIds, accounts, user } = useUserAccounts(idToken.sub);
  const userAccount = user && user.account;
  const isClickable = collapsed ? false : userAccount ? accountIds.length > 1 : true;

  const accountRefs = useRef([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleKeyDown = useCallback(
    (event) => {
      const alphabetisedAccounts = Object.keys(accounts)
        .map((key) => accounts[key])
        .sort(alphabetiseByName);
      const account = alphabetisedAccounts.find((acc) => acc.name.toLowerCase()[0] === event.key);
      if (account) {
        const refIndex = accountIds.findIndex((accountId) => accountId === account.id);
        accountRefs.current[refIndex].scrollIntoView(false);
      }
    },
    [accounts, accountIds]
  );

  useEffect(() => {
    if (!accountListCollapsed) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [accountListCollapsed, handleKeyDown]);

  return (
    <Mutation mutation={SET_CURRENT_ACCOUNT_ID}>
      {(setCurrentAccountId) => (
        <>
          {!accountListCollapsed && <Overlay onClick={toggleAccountList} />}
          <TetherComponent
            attachment="bottom left"
            targetAttachment="bottom left"
            constraints={[{ to: 'scrollParent', attachment: 'together' }]}
            renderTarget={(ref) => (
              <AccountNameContainer
                container
                item
                ref={ref}
                vdistribution="center"
                id="Sidebar__AccountSection__AcountName--Container"
                collapsed={collapsed}
                clickable={isClickable}
                onClick={(e) => {
                  if (isClickable) {
                    toggleAccountList(e);
                  }
                }}
              >
                {!userAccount || !currentAccountId ? (
                  <AccountNamePlaceholder width="110px" name="Sidebar__AccountSection__AccountName--Placeholder" />
                ) : (
                  accounts[currentAccountId] && (
                    <AccountLogo account={accounts[currentAccountId]} id="Sidebar__AccountSection__AcountName--Logo" />
                  )
                )}
              </AccountNameContainer>
            )}
            renderElement={(ref) =>
              !accountListCollapsed && (
                <AccountList ref={ref}>
                  {accountIds.length > 1
                    ? accountIds.map((accountId, index) => (
                        <AccountListItem
                          ref={(el) => (accountRefs.current[index] = el)}
                          key={accountId}
                          onClick={(e) => {
                            setCurrentAccountId({
                              variables: {
                                currentAccountId: accountId,
                              },
                            });
                            toggleAccountList(e);
                          }}
                        >
                          <AccountListItemLogoContainer>
                            {accounts[accountId].logoPath ? (
                              <AccountListItemLogo src={accounts[accountId].logoPath} />
                            ) : null}
                          </AccountListItemLogoContainer>
                          <AccountNameSpan>
                            {getAccountNameString(accounts[accountId].name)}
                            {accountId === currentAccountId ? ' (Current)' : ''}
                          </AccountNameSpan>
                        </AccountListItem>
                      ))
                    : null}
                </AccountList>
              )
            }
          />
        </>
      )}
    </Mutation>
  );
};

export const AccountName = withApollo(AccountNameComponent);
