import { ReactElement } from 'react';
import { QueryResult } from '@apollo/client';
import ContainerLoadingIndicator from '../ContainerLoadingIndicator';
import { PageFailed } from '../Errors';

interface AsyncProps<T> {
  result: QueryResult<T>;
  data: (data: T) => ReactElement;
  error?: (error: Error, result: QueryResult<T>) => ReactElement;
  loading?: () => ReactElement;
}

export function Async<T>({ result, loading, error, data }: AsyncProps<T>): ReactElement | null {
  if (result.loading) {
    return loading ? loading() : <ContainerLoadingIndicator />;
  }

  if (result.error) {
    return error ? (
      error(result.error, result)
    ) : (
      <PageFailed error={result.error} retry={() => result.refetch(result.variables)} />
    );
  }

  if (result.data) {
    return data(result.data);
  }

  return null;
}
