import { useCallback, useState, useMemo, ReactNode } from 'react';
import { NumberInput, NumberInputWithNegativeOption } from '../../UI/V2';
import { DIRECT, INDIRECT } from '~/constants/transactionActivityTypes';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SelectRenderV2 as SelectRender } from '../../DataForm';
import { NamedEntity, TransactionType } from '@rio/rio-types';
import { SupplierSelector } from '../../SuppliersSelector/v2';
import { useGetTransportFuelTypes, useGetJourneyTargets, useGetTransportTypes } from '~/hooks/transport';
import { useCurrency, useCurrentAccountId } from '~/hooks';
import { DataForm } from '../../DataFormModal/v2';
import { Checkbox, Grid, OptionsProps, styled, Text, TextField } from '@rio/ui-components';
import { LocationSelect } from '../../LocationSelect/v2';

const CheckboxesContainerStyled = styled('div')`
  display: flex;
  gap: 16px
  margin-left: 16px;
  flex-shrink: 0;
`;

type FormData = {
  [key: string]: any;
  financial: { [key: string]: any };
  data: { [key: string]: any; journeyDistance: number; distanceUnit: { id: string; name: string } };
  reference: { [key: string]: any };
};

type Props = {
  dataSection: TransactionType;
  onSubmit: (formData: { data: FormData['data'] }) => void;
  form: UseFormReturn<FormData>;
  children: ReactNode;
};

export function TransportDataForm(props: Props) {
  /**
   * PROPS
   */

  const { form, onSubmit, dataSection } = props;
  const { setValue, control, watch, getValues, getFieldState, formState } = form;

  /**
   * STATE
   */
  const [currencyInitialState] = useState(form.getValues('data.currency'));

  /**
   * HOOKS
   */

  const { data: fuelTypes, refetch: refetchFuelTypes } = useGetTransportFuelTypes();
  const journeyTargets = useGetJourneyTargets();
  const transportTypes = useGetTransportTypes();
  const { data: currenciesList } = useCurrency();
  const accountId = useCurrentAccountId();

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const setReferenceDataRequired = useCallback((value) => setValue('isReferenceDataRequired', value), [setValue]);

  const showReferenceData = useCallback(() => setReferenceDataRequired(true), [setReferenceDataRequired]);
  const hideReferenceData = useCallback(() => setReferenceDataRequired(false), [setReferenceDataRequired]);

  /**
   * SUBMIT
   * mapping data on submit event
   */
  const handleOnSubmit = useCallback(
    ({ data, financial, reference, ...others }) => {
      const formData = {
        ...others,
        data: {
          ...data,
          activity: data.activity.id,
          location: data.location.name,
          type: data.type.name,
          subtype: data.subtype.name,
          fuelType: data.fuelType.name,
          volumeUnit: data.volume ? data.volumeUnit?.name : null,
          distanceUnit: data.journeyDistance ? data.distanceUnit?.name : null,
          dataProvider: data.dataProvider ? data.dataProvider : null,
          purpose: data.purpose.name,
          currency: financial?.currency?.name || currencyInitialState,

          // numeric fields
          volume: data.volume && Number(data.volume),
          freight: data.freight && Number(data.freight),
          distance: data.distance && Number(data.distance),
          journeyDistance: data.journeyDistance && Number(data.journeyDistance),
        },
      };

      if (isReferenceDataRequired) {
        formData.data = { ...formData.data, ...reference };
      }

      onSubmit(formData);
    },
    [currencyInitialState, isReferenceDataRequired, onSubmit]
  );

  const entityToDropdownOption = useCallback(
    ({ id, name }: NamedEntity) => ({
      value: id,
      label: name,
    }),
    []
  );

  const dropdownOptionToNamedEntity = useCallback(
    ({ value, label }: OptionsProps) => ({
      id: value,
      name: label,
    }),
    []
  );

  /**
   * OPTIONS
   */
  const defaultActivity = useMemo(() => ({ id: DIRECT, name: 'Yes' }), []);
  const activityOptions = useMemo(
    () => [defaultActivity, { id: INDIRECT, name: 'No' }].map(entityToDropdownOption),
    [defaultActivity, entityToDropdownOption]
  );
  const defaultVolumeUnit = useMemo(() => ({ id: 'L', name: 'L' }), []);
  const volumeUnitOptions = useMemo(
    () => [defaultVolumeUnit, { id: 'gal', name: 'gal' }].map(entityToDropdownOption),
    [defaultVolumeUnit, entityToDropdownOption]
  );
  const defaultDistanceUnit = useMemo(() => ({ id: 'km', name: 'km' }), []);
  const distanceUnitOptions = useMemo(
    () =>
      [defaultDistanceUnit, { id: 'mi', name: 'mi' }, { id: 'passenger.km', name: 'passenger.km' }].map(
        entityToDropdownOption
      ),
    [defaultDistanceUnit, entityToDropdownOption]
  );
  const currencyOptions = useMemo(
    () => currenciesList.map(entityToDropdownOption),
    [currenciesList, entityToDropdownOption]
  );

  /**
   * DEFAULT FORM VALUES
   */
  const currencyDefaultValue = useMemo(
    () => currencyOptions.find((item) => item.label === currencyInitialState)?.value,
    [currencyInitialState, currencyOptions]
  );

  const formData = form.getValues().data;

  // Required if none present / non required if ANY present
  const isFreightRequired = !(formData?.distance || formData?.volume || formData?.journeyDistance);
  const isVolumeRequired = !(formData?.distance || formData?.freight || formData?.journeyDistance);
  const isDistanceRequired = !(formData?.volume || formData?.freight);
  const isDistanceUnitRequired = formData?.distance && 'Distance Unit is required';

  const calculateDistance = useCallback(() => {
    const journeyDistance = form.getValues().data?.journeyDistance;
    const numberOfJourneys = form.getValues().data?.numberOfJourneys;
    if (journeyDistance !== undefined && numberOfJourneys !== undefined) {
      setValue('data.distance', journeyDistance * numberOfJourneys);
    }
  }, [form, setValue]);

  const handleNumberOfJourneysChange = useCallback(
    (value: number | string) => {
      setValue('data.numberOfJourneys', Number.isNaN(value) ? 1 : Number(value));
      calculateDistance();
    },
    [calculateDistance, setValue]
  );
  const handleJourneyDistanceChange = useCallback(
    (value: number | string) => {
      setValue('data.journeyDistance', Number.isNaN(value) ? 0 : Number(value));
      calculateDistance();
    },
    [calculateDistance, setValue]
  );
  const handleTypeChange = useCallback(
    (value: NamedEntity) => {
      setValue('data.type', value);
      setValue('data.subtype', null);
      refetchFuelTypes({ typeId: value.id });
    },
    [refetchFuelTypes, setValue]
  );

  const getConditionalValidateRules = useCallback(
    (fieldName: string, message: string) => {
      const { isTouched: IsParentTouched } = getFieldState(fieldName, formState);
      return {
        required: {
          value: IsParentTouched,
          message,
        },
      };
    },
    [getFieldState, formState]
  );

  const renderPurpose = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.purpose"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Purpose of journey"
              options={journeyTargets?.map(entityToDropdownOption)}
              value={field.value?.id}
              onChange={(e) => setValue('data.purpose', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Purpose of journey is required ' }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, entityToDropdownOption, journeyTargets, setValue, dropdownOptionToNamedEntity]
  );

  const renderActivity = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.activity"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Account holder's owned and controlled vehicles"
              options={activityOptions}
              value={field.value?.id}
              onChange={(e) => setValue('data.activity', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: "Account holder's owned and controlled vehicles is required" }}
          control={control}
        />
      </Grid>
    ),
    [activityOptions, control, dropdownOptionToNamedEntity, setValue]
  );

  const renderLocation = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.location"
          render={({ field, fieldState }) => (
            <LocationSelect
              {...field}
              label="Location"
              value={{ value: field.value?.id, label: field.value?.name }}
              createNew
              onChange={(e) => setValue('data.location', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Location is required' }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, dropdownOptionToNamedEntity, setValue]
  );

  const renderType = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.type"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Type"
              options={transportTypes?.map(entityToDropdownOption)}
              value={field.value?.id}
              onChange={(e) => {
                setValue('data.type', dropdownOptionToNamedEntity(e));
                handleTypeChange(dropdownOptionToNamedEntity(e));
              }}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Type is required' }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, transportTypes, entityToDropdownOption, setValue, dropdownOptionToNamedEntity, handleTypeChange]
  );

  const renderSubtype = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.subtype"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Subtype"
              options={transportTypes.find(({ id }) => id === formData?.type?.id)?.subtypes.map(entityToDropdownOption)}
              value={field.value?.id}
              onChange={(e) => setValue('data.subtype', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Subtype is required' }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, transportTypes, entityToDropdownOption, formData?.type?.id, setValue, dropdownOptionToNamedEntity]
  );

  const renderFuelType = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.fuelType"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Fuel type"
              options={fuelTypes?.map(entityToDropdownOption)}
              value={field.value?.id}
              onChange={(e) => setValue('data.fuelType', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Fuel type is required' }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, fuelTypes, entityToDropdownOption, setValue, dropdownOptionToNamedEntity]
  );

  const renderSupplier = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.dataProvider"
          render={({ field, fieldState }) => (
            <SupplierSelector
              accountId={accountId}
              label="Data provider (optional)"
              value={field.value?.value}
              onChange={(e) => setValue('data.dataProvider', e)}
              error={fieldState.error?.message}
              utility={TransactionType.Transport}
            />
          )}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [accountId, control, setValue]
  );

  const renderOrigin = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.origin"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value}
              label="Origin of travel"
              id="originOfTravel"
              onChange={(e) => setValue('data.origin', e.target.value)}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Origin of travel is required' }}
          control={control}
          defaultValue=""
        />
      </Grid>
    ),
    [control, setValue]
  );

  const renderDestination = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.destination"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value}
              label="Destination of travel"
              id="destinationOfTravel"
              onChange={(e) => setValue('data.destination', e.target.value)}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          control={control}
          defaultValue=""
          rules={{ required: 'Destination of travel is required' }}
        />
      </Grid>
    ),
    [control, setValue]
  );

  const renderVolume = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.volume"
          render={({ field, fieldState }) => (
            <NumberInputWithNegativeOption
              {...field}
              label={`Fuel Volume${isVolumeRequired ? '' : ' (optional)'}`}
              value={field.value}
              onChange={(e) => setValue('data.volume', e)}
              id="fuelVolume"
              error={Boolean(fieldState.error?.message)}
              helperText={
                fieldState.error?.message ||
                "If no Volume figure is entered you will need to provide a value for 'Journey distance'"
              }
            />
          )}
          rules={{ required: isVolumeRequired ? 'Fuel volume is required' : false }}
          control={control}
        />
      </Grid>
    ),
    [control, isVolumeRequired, setValue]
  );

  const renderVolumeUnit = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.volumeUnit"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label={`Volume Unit ${!getValues('data.volume') && '(optional)'}`}
              options={volumeUnitOptions}
              value={field.value?.id}
              onChange={(e) => setValue('data.volumeUnit', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          defaultValue={defaultVolumeUnit}
          control={control}
          rules={getConditionalValidateRules('data.volumeUnit', 'Unit of Volume is required')}
        />
      </Grid>
    ),
    [
      control,
      defaultVolumeUnit,
      dropdownOptionToNamedEntity,
      getConditionalValidateRules,
      getValues,
      setValue,
      volumeUnitOptions,
    ]
  );

  const renderNumberOfJourneys = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.numberOfJourneys"
          control={control}
          defaultValue={1}
          rules={{ required: false }}
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              id="numberOfJourneys"
              label="Number of journeys"
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
              value={field.value}
              onChange={(e) => {
                setValue('data.numberOfJourneys', e);
                handleNumberOfJourneysChange(e as string);
              }}
            />
          )}
        />
      </Grid>
    ),
    [control, handleNumberOfJourneysChange, setValue]
  );

  const renderJourneyDistance = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.journeyDistance"
          render={({ field, fieldState }) => (
            <NumberInputWithNegativeOption
              {...field}
              label={`Journey Distance${isDistanceRequired ? '' : ' (optional)'}`}
              id="journeyDistance"
              value={field.value}
              onChange={(e) => {
                setValue('data.journeyDistance', e);
                handleJourneyDistanceChange(e);
              }}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          rules={{ required: isDistanceRequired }}
          control={control}
        />
      </Grid>
    ),
    [control, handleJourneyDistanceChange, isDistanceRequired, setValue]
  );

  const renderDistance = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.distance"
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              label={`Total Distance${isDistanceRequired ? '' : ' (optional)'}`}
              id="totalDistance"
              value={field.value}
              onChange={(e) => {
                setValue('data.distance', e);
                handleJourneyDistanceChange(e as string);
              }}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          rules={{ required: isDistanceRequired }}
          defaultValue={null}
          control={control}
        />
      </Grid>
    ),
    [control, handleJourneyDistanceChange, isDistanceRequired, setValue]
  );

  const renderDistanceUnit = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.distanceUnit"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label={`Distance unit${isDistanceUnitRequired ? '' : ' (optional)'}`}
              options={distanceUnitOptions}
              value={field.value?.id}
              onChange={(e) => setValue('data.distanceUnit', dropdownOptionToNamedEntity(e))}
              error={fieldState.error?.message}
            />
          )}
          defaultValue={defaultDistanceUnit}
          control={control}
          rules={getConditionalValidateRules('data.distance', 'Unit of distance is required')}
        />
      </Grid>
    ),
    [
      control,
      defaultDistanceUnit,
      distanceUnitOptions,
      dropdownOptionToNamedEntity,
      getConditionalValidateRules,
      isDistanceUnitRequired,
      setValue,
    ]
  );

  const renderFreight = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.freight"
          render={({ field, fieldState }) => (
            <NumberInputWithNegativeOption
              {...field}
              label={`Freight (tonne.km)${isFreightRequired ? '' : ' (optional)'}`}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
              value={field.value}
              onChange={(e) => {
                setValue('data.freight', e);
              }}
            />
          )}
          rules={{ required: isFreightRequired }}
          defaultValue={''}
          control={control}
        />
      </Grid>
    ),
    [control, isFreightRequired, setValue]
  );

  const renderCarrier = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.carrier"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={`Carrier${!formData?.freight ? ' (optional)' : ''}`}
              value={field.value}
              onChange={(e) => {
                setValue('data.carrier', e.target.value);
              }}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          control={control}
          defaultValue={''}
          rules={getConditionalValidateRules('data.freight', 'Carrier is required')}
        />
      </Grid>
    ),
    [control, getConditionalValidateRules, formData?.freight, setValue]
  );

  const renderCost = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.cost"
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              label="Cost (optional)"
              value={field.value}
              onChange={(e) => setValue('data.cost', e)}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          control={control}
          defaultValue={''}
        />
      </Grid>
    ),
    [control, setValue]
  );

  const renderCurrency = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="financial.currency"
          render={({ field, fieldState }) => (
            <SelectRender
              {...field}
              label="Currency"
              onChange={(e) => setValue('financial.currency', dropdownOptionToNamedEntity(e))}
              value={field.value?.id}
              options={currencyOptions}
              error={fieldState.error?.message}
            />
          )}
          rules={{ required: 'Currency is required' }}
          defaultValue={currencyDefaultValue}
          control={control}
        />
      </Grid>
    ),
    [control, currencyDefaultValue, currencyOptions, dropdownOptionToNamedEntity, setValue]
  );

  const renderNotes = useCallback(
    () => (
      <Grid item xs={12}>
        <Controller
          name="data.notes"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Notes (optional)"
              value={field.value}
              onChange={(e) => {
                setValue('data.notes', e.target.value);
              }}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
            />
          )}
          control={control}
          defaultValue=""
        />
      </Grid>
    ),
    [control, setValue]
  );

  const renderReferenceCheckbox = useCallback(
    () => (
      <Grid container xs={12} alignItems="center" justifyContent="space-between">
        <Text typescale="body" size="large">
          Do you want to add any references?
        </Text>

        <CheckboxesContainerStyled>
          <Checkbox label="Yes" checked={Boolean(isReferenceDataRequired)} onChange={showReferenceData} />
          <Checkbox label="No" checked={!Boolean(isReferenceDataRequired)} onChange={hideReferenceData} />
        </CheckboxesContainerStyled>
      </Grid>
    ),
    [hideReferenceData, isReferenceDataRequired, showReferenceData]
  );

  const renderReference = useCallback(
    () => (
      <>
        <Grid item xs={12}>
          <Controller
            name="reference.reference"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Reference"
                value={field.value}
                onChange={(e) => setValue('reference.reference', e.target.value)}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error?.message)}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="reference.documentReference"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Document reference"
                value={field.value}
                onChange={(e) => setValue('reference.documentReference', e.target.value)}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error?.message)}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
      </>
    ),
    [control, setValue]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showSubmitButton={!!watch('data.activity')}>
      <Text typescale="body" size="large">
        Please enter the following transactional {dataSection.toLowerCase()} data:
      </Text>
      {renderLocation()}

      {renderPurpose()}
      {!!watch('data.purpose') && renderActivity()}
      {!!watch('data.activity') && (
        <>
          {renderType()}
          {!!watch('data.type') && renderSubtype()}

          {renderFuelType()}
          {renderSupplier()}

          {renderVolume()}
          {!!watch('data.volume') && renderVolumeUnit()}

          {renderOrigin()}
          {renderDestination()}

          {renderNumberOfJourneys()}
          {renderJourneyDistance()}
          {!!watch('data.journeyDistance') && renderDistance()}
          {!!watch('data.journeyDistance') && renderDistanceUnit()}

          {renderFreight()}
          {renderCarrier()}
          {renderCost()}
          {renderCurrency()}
          {renderNotes()}

          {renderReferenceCheckbox()}
          {isReferenceDataRequired && renderReference()}
          {props.children}
        </>
      )}
    </DataForm>
  );
}
