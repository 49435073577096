import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { DocumentRecord, TaskSubject, Scope } from '@rio/rio-types';
import {
  Button,
  Carousel,
  EvidenceUploadItem,
  Heading,
  LoadingIndicator,
  Row,
  Checkbox,
  Modal,
} from 'rio-ui-components';
import {
  DocumentContainer,
  DocumentAndChildContainer,
  FlexRow,
  PaddedCol,
  TitleContainerStyled,
  CircleIconStyled,
  PaddedContainer,
  MainContainer,
  ContentContainer,
  DatePickerContainer,
  StyledLabel,
  StyledNameInput,
  StyledRow,
  StyledTextArea,
  StyledSelect,
  StyledTextInput,
  StyledDatePicker,
  LoadingContainer,
  MultiSelectContainer,
  SaveButton,
  CancelButton,
  DeleteButton,
  ControlButtonsContainer,
  StyledLabelWithRequiredBlock,
  PageHeaderStyled,
  StyledSelectWrapper,
} from '../../../components/StyledElements';
import priorityOptions from '../TasksGrid/taskPriorities.json';
import categoryOptions from '../TasksGrid/taskCategories.json';
import { subjectOptions } from './taskSubjects';
import GET_TASK_BY_ID from '../../../graphql/queries/tasks/GetTaskById.query.graphql';
import CREATE_TASK_EVIDENCE from '../../../graphql/mutations/tasks/CreateTaskEvidence.mutation.graphql';
import UPDATE_TASK from '../../../graphql/mutations/tasks/UpdateTask.mutation.graphql';
import DELETE_TASK from '../../../graphql/mutations/tasks/DeleteTask.mutation.graphql';
import PageHeader from '../../../components/PageHeader';
import { LegislationSelect } from '../../../components/LegislationSelect';
import { AspectsSelect } from '../../../components/AspectsSelect';
import { TargetsSelect } from '../../../components/TargetsSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { ProjectSelect } from '../../../components/ProjectSelect';
import UserSelect from '../../../components/UserSelect';
import { formatDate } from '../../../utils/formatDate';
import { LoadFailed } from '../../../components/Errors';
import { useSaveEvidence, EvidencePicker } from '../../../components/EvidenceNotes';
import { AUDIT } from '../../../constants/taskEvidenceTypes';
import { calculateEvidenceTileNumber } from '../../../utils/managementSystemUtils';
import AddDocumentTile from '../../../components/AddDocumentTile/AddDocumentTile';
import { SelectEvent, MultiSelectEvent, InputEvent } from '../../../types';
import { formatTaskData, prepareTaskInput } from './utils';
import { TaskDetails } from './types';
import { ValidateTask } from '../TasksGrid/validate';
import { DeleteModal } from '../../../components/DeleteModal';
import GET_TASKS_BY_ACCOUNT from '../../../graphql/queries/tasks/GetTasksByAccountId.query.graphql';
import {
  useUserToken,
  useNotification,
  usePermissions,
  useScopeOptions,
  useCurrentAccountId,
  useRoutes,
  usePageSuspendingMutation,
  usePageSuspendingQuery,
} from '~/hooks';

type Error = Partial<{
  name: string;
  description: string;
  priority: string;
  category: string;
  subject: string;
  owner: string;
  dueDate: string;
}>;

const RequiredLabel = ({ id }: { id: string }) => {
  return (
    <StyledLabelWithRequiredBlock size="lg">
      <FormattedMessage id={id} />
    </StyledLabelWithRequiredBlock>
  );
};

const OptionalLabel = ({ id }: { id: string }) => {
  return (
    <StyledLabel size="lg">
      <FormattedMessage id={id} />
    </StyledLabel>
  );
};

const hasPreviousPage = window.history.state && window.history.state.idx > 0;

const TasksViewContainer = ({ hasProjectsAccess = false }) => {
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const scopeOptions = useScopeOptions();
  const canEditTask = permissions.task.some((action: string) => action.startsWith('edit'));
  const canDeleteTask = permissions.task.some((action: string) => action.startsWith('delete'));
  const isReadOnly = !permissions.document.find((action: string) => action.startsWith('create'));
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');
  const { token } = useUserToken();
  const { showNotification } = useNotification();
  const [taskDocuments, setTaskDocuments] = useState<DocumentRecord[]>([]);
  const [taskData, setTaskData] = useState<TaskDetails>({} as TaskDetails);
  const { id: taskId } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Error>({});
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const routes = useRoutes();

  const { data, error, loading, refetch } = usePageSuspendingQuery(GET_TASK_BY_ID, {
    variables: { id: taskId },

    onCompleted: (receivedData) => {
      const task = receivedData?.getTaskById;
      setTaskData(formatTaskData(task));
      const documents: DocumentRecord[] = [];
      task?.evidence
        ?.filter((evidence: any) => evidence?.documents?.length > 0)
        .map((evidence: any) => evidence?.documents?.map((document: any) => documents.push(document)));
      setTaskDocuments(documents);
    },
  });

  const navigateOnButtonClick = (hasPrevPage: boolean) => (hasPrevPage ? navigate(-1) : navigate(routes.tasks.root));

  useEffect(() => {
    refetch({ id: taskId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createTaskEvidence, { loading: taskEvidenceLoading }] = usePageSuspendingMutation(CREATE_TASK_EVIDENCE, {
    onCompleted: () => {
      showNotification('Evidence has been attached to the current task.', 'success');
      setShowEvidenceModal(false);
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const [updateTask, { loading: taskUpdateLoading }] = usePageSuspendingMutation(UPDATE_TASK, {
    onCompleted: () => {
      showNotification('Task has been successfully updated');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const [deleteTask, { loading: taskDeleteLoading }] = usePageSuspendingMutation(DELETE_TASK, {
    onCompleted: () => {
      navigateOnButtonClick(hasPreviousPage);
      showNotification('Task has been successfully deleted');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
    refetchQueries: [{ query: GET_TASKS_BY_ACCOUNT, variables: { accountId, userId: token.sub } }],
  });

  const handleInputAndSelectChange = (event: SelectEvent | InputEvent) => {
    if (Object.keys(errors).find((name) => name === event.target.name)) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }

    setTaskData({
      ...taskData,
      [event.target.name]: event.target.value,
    });
  };

  const handleMultiSelectChange = (event: MultiSelectEvent) => {
    const owners =
      event.target.name === 'owner'
        ? event.target?.select?.map(({ value, label }: { value: string; label: string }) => {
            return { value, label };
          })
        : taskData.owners;

    setTaskData({
      ...taskData,
      [event.target.name]: event.target.select,
      owners,
    });
  };
  const saveEvidence = useSaveEvidence();

  const saveEvidenceDocuments = async () => {
    if (!!evidenceDocuments.length) {
      const newDocumentIds = await saveEvidence(evidenceDocuments, [], 'Other Reference');

      createTaskEvidence({
        variables: {
          id: uuid(),
          taskId,
          evidenceType: AUDIT,
          notes: evidenceNotes,
          documents: newDocumentIds,
        },
        refetchQueries: ['GetTaskById'],
      });
    }
  };

  const handleSaveTask = useCallback(() => {
    const taskInput = prepareTaskInput(taskData);
    const validationErrors = ValidateTask(taskInput);

    if (isEmpty(validationErrors)) {
      setErrors({});
      updateTask({
        variables: taskInput,
        refetchQueries: ['GetTaskById'],
      });
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
    }
  }, [taskData, updateTask]);

  const handleTaskDelete = (id: string | undefined) => {
    if (id === undefined) return;
    deleteTask({
      variables: { id },
    });
  };

  return (
    <MainContainer>
      <PageHeaderStyled>
        <PageHeader
          name="Tasks_Header"
          title={taskData?.name}
          breadcrumbs={[{ title: 'Tasks' }]}
          icon="tasks"
          iconColor="tertiary"
          leftButtonClick={() => navigateOnButtonClick(hasPreviousPage)}
        />
        <ControlButtonsContainer>
          {canEditTask && (
            <SaveButton
              data-testid="Save_Button"
              onClick={handleSaveTask}
              disabled={taskEvidenceLoading || taskUpdateLoading || taskDeleteLoading}
            >
              Save
            </SaveButton>
          )}
          <CancelButton
            data-testid="Cancel_Button"
            onClick={() => navigateOnButtonClick(hasPreviousPage)}
            disabled={taskEvidenceLoading || taskUpdateLoading || taskDeleteLoading}
          >
            Cancel
          </CancelButton>
          {canDeleteTask && (
            <DeleteButton
              data-testid="Delete_Button"
              onClick={() => setShowDeleteTaskModal(true)}
              disabled={taskEvidenceLoading || taskUpdateLoading || taskDeleteLoading}
            >
              Delete
            </DeleteButton>
          )}
        </ControlButtonsContainer>
      </PageHeaderStyled>
      <ContentContainer>
        {loading && (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        )}

        {showEvidenceModal && (
          <Modal show size="md" onDismiss={() => setShowEvidenceModal(false)}>
            <ContentContainer>
              <Heading>Add Evidence</Heading>
              <EvidencePicker
                evidenceDocuments={evidenceDocuments}
                setEvidenceDocuments={setEvidenceDocuments}
                evidenceNotes={evidenceNotes}
                setEvidenceNotes={setEvidenceNotes}
              />
              <Button onClick={() => saveEvidenceDocuments()}>Save Evidence</Button>
            </ContentContainer>
          </Modal>
        )}

        {showDeleteTaskModal && (
          <DeleteModal
            deleteObjectName="task"
            onConfirmClick={() => handleTaskDelete(taskId)}
            onDismissClick={() => setShowDeleteTaskModal(false)}
          />
        )}

        {error && <LoadFailed name={error.message} retry={() => refetch({ id: taskId })} error={error} />}
        {!loading && data && (
          <ContentContainer>
            <Row>
              <RequiredLabel id="pages.task.details.name" />
              <StyledNameInput
                data-testid="Task_Name"
                onChange={handleInputAndSelectChange}
                name="name"
                value={taskData.name}
                box={false}
                error={errors.name}
                readOnly={!canEditTask}
              />
            </Row>
            <Row>
              <RequiredLabel id="pages.task.details.description" />
              <StyledTextArea
                data-testid="Task_Description"
                onChange={handleInputAndSelectChange}
                name="description"
                value={taskData.description}
                box
                readOnly={!canEditTask}
                error={errors.description}
              />
            </Row>
            <Row container span={12}>
              <StyledRow item span={6}>
                <OptionalLabel id="pages.task.details.creator" />
                <StyledTextInput
                  data-testid="Task_Creator"
                  size="md"
                  name="author"
                  onChange={handleInputAndSelectChange}
                  value={taskData.author}
                  readOnly={true}
                />
              </StyledRow>
              <StyledRow item span={6}>
                <OptionalLabel id="pages.task.details.createdAt" />
                <StyledTextInput
                  data-testid="Task_Creator"
                  size="md"
                  name="author"
                  onChange={handleInputAndSelectChange}
                  value={formatDate(taskData.createdAt)}
                  readOnly={true}
                />
              </StyledRow>
            </Row>
            <Row container span={12}>
              <StyledRow item span={6}>
                <OptionalLabel id="pages.task.details.completed" />
                <Checkbox
                  size="md"
                  checked={taskData.isCompleted}
                  onClick={() =>
                    setTaskData({
                      ...taskData,
                      isCompleted: !taskData.isCompleted,
                    })
                  }
                />
              </StyledRow>

              {taskData.isCompleted && (
                <StyledRow item span={6}>
                  <OptionalLabel id="pages.task.details.completedAt" />
                  <DatePickerContainer>
                    <StyledDatePicker
                      data-testid="Completed_Date"
                      name="completedAt"
                      onDayClick={(date: Date) => {
                        setTaskData({
                          ...taskData,
                          completedAt: date ? date.toISOString() : null,
                        });
                      }}
                      readOnly={!canEditTask}
                      disabledDatesAfter={new Date()}
                      selectedDate={
                        taskData.isCompleted && !!taskData.completedAt
                          ? new Date(taskData.completedAt)
                          : taskData.isCompleted
                          ? new Date()
                          : null
                      }
                    />
                  </DatePickerContainer>
                </StyledRow>
              )}
            </Row>
            <Row container span={12}>
              <StyledRow item span={6}>
                <OptionalLabel id="pages.task.details.status" />
                <StyledTextInput
                  data-testid="Task_Status"
                  size="md"
                  name="status"
                  onChange={handleInputAndSelectChange}
                  value={
                    taskData.isCompleted
                      ? 'Completed'
                      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      new Date(taskData.dueDate!) > new Date()
                      ? 'Incompleted'
                      : 'Overdue'
                  }
                  readOnly={true}
                />
              </StyledRow>
              <StyledRow item span={6}>
                <RequiredLabel id="pages.task.details.dueDate" />
                <DatePickerContainer>
                  <StyledDatePicker
                    data-testid="Due_Date"
                    name="dueDate"
                    onDayClick={(date: Date) => {
                      setTaskData({
                        ...taskData,
                        dueDate: date ? date.toISOString() : null,
                      });
                    }}
                    readOnly={!canEditTask}
                    selectedDate={taskData.dueDate ? new Date(taskData.dueDate) : null}
                    error={errors.dueDate}
                  />
                </DatePickerContainer>
              </StyledRow>
            </Row>
            <Row container distribution="between" span={12} itemAlign="bottom">
              <StyledRow item span={4}>
                <RequiredLabel id="pages.task.details.scope" />
                <StyledSelect
                  data-testid="Task_scope"
                  name="scope"
                  options={scopeOptions}
                  value={taskData.scope}
                  onChange={handleInputAndSelectChange}
                  isDisabled={!canEditTask}
                />
              </StyledRow>
              <StyledRow item span={4}>
                <RequiredLabel id="pages.task.details.priority" />
                <StyledSelect
                  data-testid="Task_priority"
                  size="md"
                  onChange={handleInputAndSelectChange}
                  name="priority"
                  value={taskData.priority}
                  options={priorityOptions}
                  readOnly={!canEditTask}
                  error={errors.priority}
                />
              </StyledRow>
              <StyledRow item span={4}>
                <RequiredLabel id="pages.task.details.category" />
                <StyledSelect
                  data-testid="Task_category"
                  size="md"
                  name="category"
                  onChange={(e: SelectEvent) => {
                    setTaskData({
                      ...taskData,
                      [e.target.name]: e.target.value,
                      subject: null,
                      item: null,
                    });
                  }}
                  value={taskData.category}
                  options={categoryOptions}
                  readOnly={!canEditTask}
                  error={errors.category}
                />
              </StyledRow>
            </Row>
            {taskData.scope === Scope.Location && (
              <Row>
                <RequiredLabel id="pages.task.details.locations" />
                <MultiSelectContainer>
                  <LocationsMultiSelect
                    name="locations"
                    accountId={accountId}
                    value={taskData.locations}
                    onChange={handleInputAndSelectChange}
                    disabled={!canEditTask}
                  />
                </MultiSelectContainer>
              </Row>
            )}
            {taskData.scope === Scope.Tag && (
              <Row>
                <RequiredLabel id="pages.task.details.tags" />
                <MultiSelectContainer>
                  <TagsMultiSelect
                    name="tags"
                    accountId={accountId}
                    value={taskData.tags}
                    onChange={handleInputAndSelectChange}
                    disabled={!canEditTask}
                  />
                </MultiSelectContainer>
              </Row>
            )}
            {`${taskData.category}` in subjectOptions ? (
              <Row>
                <RequiredLabel id="pages.task.details.subject" />
                <StyledSelect
                  name="subject"
                  classPrefix="subject-select"
                  disabled={!canEditTask}
                  onChange={(e: SelectEvent) => handleInputAndSelectChange(e)}
                  value={taskData.subject?.toUpperCase() || null}
                  options={subjectOptions[taskData.category]}
                  error={errors.subject}
                />
              </Row>
            ) : null}
            {taskData.subject &&
              [TaskSubject.Legislation, TaskSubject.Aspects, TaskSubject.Targets].includes(
                taskData.subject as TaskSubject
              ) && (
                <Row>
                  <OptionalLabel id="pages.task.details.relatedItem" />
                  {taskData.subject === TaskSubject.Legislation && (
                    <StyledSelectWrapper>
                      <LegislationSelect
                        name="itemId"
                        onChange={handleInputAndSelectChange}
                        value={taskData.itemId}
                        isDisabled={!canEditTask}
                        error={false}
                      />
                    </StyledSelectWrapper>
                  )}
                  {taskData.subject === TaskSubject.Aspects && (
                    <StyledSelectWrapper>
                      <AspectsSelect
                        name="itemId"
                        onChange={handleInputAndSelectChange}
                        value={taskData.itemId || taskData.item?.id}
                        isDisabled={!canEditTask}
                        error={null}
                      />
                    </StyledSelectWrapper>
                  )}
                  {taskData.subject === TaskSubject.Targets && (
                    <StyledSelectWrapper>
                      <TargetsSelect
                        name="itemId"
                        onChange={handleInputAndSelectChange}
                        value={taskData.itemId}
                        error={null}
                      />
                    </StyledSelectWrapper>
                  )}
                </Row>
              )}

            <Row>
              <RequiredLabel id="pages.task.details.owner" />
              <MultiSelectContainer>
                <UserSelect
                  onChange={handleMultiSelectChange}
                  value={taskData.owners}
                  accountId={accountId}
                  isDisabled={!canEditTask}
                  error={errors.owner}
                />
              </MultiSelectContainer>
            </Row>
            {hasProjectsAccess && (
              <Row>
                <OptionalLabel id="pages.task.details.project" />
                <StyledSelectWrapper>
                  <ProjectSelect
                    name="projectId"
                    accountId={accountId}
                    onChange={handleInputAndSelectChange}
                    value={taskData.projectId || taskData?.project?.id}
                  />
                </StyledSelectWrapper>
              </Row>
            )}
            <Row container>
              <PaddedCol item span={12}>
                <DocumentAndChildContainer container item>
                  <DocumentContainer>
                    <FlexRow>
                      <TitleContainerStyled span={12}>
                        <CircleIconStyled
                          inline
                          height="50px"
                          size="xl"
                          circleColor="tertiary"
                          iconColor="tertiary"
                          icon="file-alt"
                        />
                        <Heading inline>Evidence Library</Heading>
                      </TitleContainerStyled>
                    </FlexRow>
                    <PaddedContainer>
                      <Carousel show={3} scroll={1} width={'675px'} infinite={false}>
                        {new Array(calculateEvidenceTileNumber(taskDocuments)).fill(0).map((doc, i) => {
                          return (
                            <AddDocumentTile
                              id={doc.id}
                              key={`upload-evidence${i}`}
                              disabled={isReadOnly}
                              onClick={() => setShowEvidenceModal(true)}
                            >
                              <EvidenceUploadItem title="Upload Evidence" />
                            </AddDocumentTile>
                          );
                        })}
                        {taskData?.evidence
                          ?.filter((evidence: any) => evidence?.documents?.length > 0)
                          .map((evidence) => {
                            return evidence?.documents?.map((document) => {
                              return (
                                <Link
                                  key={document.id}
                                  to={{ pathname: `/documents/all/${document.id}` }}
                                  state={{ taskId: taskId }}
                                >
                                  <EvidenceUploadItem title={evidence.notes} desc={document.fileName} uploaded={true} />
                                </Link>
                              );
                            });
                          })}
                      </Carousel>
                    </PaddedContainer>
                  </DocumentContainer>
                </DocumentAndChildContainer>
              </PaddedCol>
            </Row>
          </ContentContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export default TasksViewContainer;
