import { useApolloClient } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GET_USER_BY_USERNAME } from '../queries/users';
import keyBy from 'lodash/keyBy';

export function useUserAccounts(userId) {
  const client = useApolloClient();
  const [userAccounts, setUserAccounts] = useState({
    user: null,
    currentAccountId: null,
    accounts: {},
    accountIds: []
  });

  useEffect(() => {
    const getAccounts = async () => {
      const { data } = await client.query({
        query: GET_USER_BY_USERNAME,
        variables: {
          name: userId
        }
      });

      if (data && data.getUserInfoById && data.getUserInfoById.account) {
        const { account } = data.getUserInfoById;
        const { permissions } = data.getUserInfoById.role;

        const canRoutesAccounts = permissions.account.includes('getChild') || permissions.account.includes('getAll');
        const children = canRoutesAccounts ? account.children : [];
        const accountsById = keyBy([account, ...children], 'id');

        const childrenAccountIdsReceivedAndSorted = children.slice().sort((a, b) => {
          if (accountsById[a.id].name < accountsById[b.id].name) {
            return -1;
          }
          if (accountsById[a.id].name > accountsById[b.id].name) {
            return 1;
          }
          return 0;
        });

        setUserAccounts({
          accountIds: [account.id].concat(childrenAccountIdsReceivedAndSorted.map(({ id }) => id)),
          accounts: accountsById,
          user: data.getUserInfoById
        });
      }
    };

    getAccounts();
  }, [userId, client]);
  return userAccounts;
}
