import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NotAvailable } from '../../../components/Errors';
import { Row, Search, Col, Button, Heading, Text, Modal, PreContainer } from 'rio-ui-components';
import RiskModal from './RiskModal';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import PageHeader from '../../../components/PageHeader';
import { useAccessControls, useCurrentAccount, useCurrentAccountId, useSearch, useAgGrid } from '../../../hooks';
import areas from '../areas';
import { Grid } from './Grid';
import { ACCOUNT, TAG, LOCATION } from '../../../constants/scopes';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS } from '../ManagementSystems/LandingPage/index.queries';
import { LoadFailed } from '../../../components/Errors';
import { ClearButton } from '../../../components/ClearButton';
import fakeData from './fakeHealthAndSafetyData.json';

const ControlsContainerStyled = styled(Row)`
  flex: 1;
  align-items: center;
`;

const ButtonContainerStyled = styled(Col)`
  padding-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const ModalContainer = styled.div`
  padding: ${(p) => p.theme.geometry.md.spacing};
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  pre {
    width: 100%;
  }
`;

const ModalHeadingStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;

export const getScopeLevel = (risk) => {
  switch (risk.scope) {
    case LOCATION:
      return 'Location';
    case TAG:
      return 'Tag';
    case ACCOUNT:
    default:
      return 'Organisation';
  }
};

export const getScopeLocations = (risk) => {
  if (risk.scope === ACCOUNT) {
    return getScopeLevel(risk);
  }
  if (risk.scope === LOCATION) {
    return risk.locations
      .filter(Boolean)
      .map(({ name }) => name)
      .join(', ');
  }
  return null;
};

export const getScopeTags = (risk) => {
  if (risk.scope === ACCOUNT) {
    return getScopeLevel(risk);
  }
  if (risk.scope === TAG) {
    return risk.tags
      .filter(Boolean)
      .map(({ tagName }) => tagName)
      .join(', ');
  }
  if (risk.scope === LOCATION) {
    const tagsCollection = risk.locations.filter(Boolean).map((loc) => {
      return loc.tags.filter(Boolean);
    });
    return []
      .concat(...tagsCollection)
      .filter(Boolean)
      .map(({ tagName }) => tagName)
      .join(', ');
  }
};

export const prepareRiskInput = (risk) => ({
  ...risk,
  locationIds: risk.locationIds?.map(({ value }) => value),
  tagIds: risk.tagIds?.map(({ value }) => value),
  legislationIds: risk.legislationIds?.map(({ value }) => value),
  impacts: risk.impacts?.map(({ value }) => value),
  risks: risk.risks?.map(({ value }) => value),
  emergencyConditions: risk.emergencyConditions?.map(({ value }) => value),
  controlMeasures: risk.controlMeasures?.map(({ value }) => value),
  departments: risk.departments?.map(({ value }) => value),
});

export const HealthAndSafetyRiskRegister = () => {
  const currentAccount = useCurrentAccount();
  const accountId = useCurrentAccountId();
  const { data: accessControls } = useAccessControls();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [actionsRequiredShown, setShowActionRequiredModal] = useState(false);
  const agGrid = useAgGrid();

  const { loading: managementSystemsLoading, data: managementSystemsData } = useQuery(
    GET_AVAILABLE_MANAGEMENT_SYSTEMS,
    {
      variables: { accountId },
    }
  );

  // TODO: replace these with ones from actual query
  const loading = false;
  const networkStatus = 1;
  const error = null;

  const risks = (fakeData.rawRisks || []).map((risk) => {
    return {
      ...risk,
      scopeLevel: getScopeLevel(risk),
      scopeLocations: getScopeLocations(risk),
      scopeTags: getScopeTags(risk),
      formattedLegislations: risk.legislations.map(({ title }) => title).join(' '),
    };
  });

  const [searchValue, setSearchValue, searchedRisks] = useSearch(risks, [
    'formattedScope',
    'activity',
    ['risks'],
    ['impacts'],
    'emergencyConditions',
    'controlMeasures',
    'likelihoodNoControl',
    'severityNoControl',
    'likelihoodControl',
    'severityControl',
    'formattedLegislations',
    'status',
  ]);

  const availableGovernancePages = areas.filter((area) => !area.comingSoon && accessControls.comply[area.accessIndex]);
  const { title, restrictionId } = areas.find((area) => area.link.includes('health-and-safety-risk-register'));

  if (
    !managementSystemsLoading &&
    !managementSystemsData.getAvailableManagementSystems.find(({ id }) => id === restrictionId)
  ) {
    return <NotAvailable name={'Forbidden_Risks'} error={new Error('Forbidden')} />;
  }

  return managementSystemsLoading ? null : (
    <Col container item>
      {showCreateModal && (
        <RiskModal
          title="Create Risk"
          submitTitle="Create Risk"
          onDismiss={() => setShowCreateModal(false)}
          accountId={accountId}
          onSubmit={() => {}}
        />
      )}
      {!!actionsRequiredShown && (
        <Modal size="lg" show onDismiss={() => setShowActionRequiredModal(null)}>
          <ModalContainer>
            <ModalHeadingStyled size="lg">Actions Required</ModalHeadingStyled>
            <PreContainer size="md" wrap>
              {actionsRequiredShown.map((action) => (
                <Text key={action}>{action}</Text>
              ))}
            </PreContainer>
          </ModalContainer>
        </Modal>
      )}
      <PageHeader
        title="Risks"
        breadcrumbs={[{ title: 'Governance', to: '..' }, { title }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown
        dropdownItems={availableGovernancePages}
        dropdownDefault={title}
      >
        <ControlsContainerStyled name="ConfigurationRiskContainer__Controls" container alignItems="center">
          <Col item>
            <Search
              name="ConfigurationRiskContainer__Controls__Search"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              hideButton
            />
          </Col>
          <ButtonContainerStyled span={4} item>
            <ClearButton gridApi={agGrid.api} columnApi={agGrid.columnApi} />
          </ButtonContainerStyled>
          <ButtonContainerStyled span={4} item>
            <ButtonStyled
              name="ConfigurationRiskContainer__Controls__Button--create"
              size="md"
              color="primary"
              inline
              onClick={() => setShowCreateModal(true)}
            >
              + Add Risk
            </ButtonStyled>
          </ButtonContainerStyled>
        </ControlsContainerStyled>
      </PageHeader>
      {loading && networkStatus !== 3 && <ContainerLoadingIndicator name="ConfigurationNormaliserContainer__Loading" />}
      {error ? (
        <LoadFailed error={error} retry={() => {}} name={error.message} />
      ) : (
        <Grid
          risks={searchedRisks}
          showEditModal={() => {}}
          showCopyModal={() => {}}
          showActionRequiredModal={() => {}}
          canEdit={true}
          currentAccount={currentAccount}
          agGrid={agGrid}
        />
      )}
    </Col>
  );
};
