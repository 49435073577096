import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col } from 'rio-ui-components';
import { DocumentLibrary } from '@rio/rio-types';
import { useQueryParam, StringParam } from 'use-query-params';
import { useIntl } from 'react-intl';
import { GridApi, ColumnApi } from 'ag-grid-community';
import _ from 'lodash';

import PageHeader from '../../../components/PageHeader';
import { ClearButton } from '../../../components/ClearButton';
import { ControlPanel } from '../../../components/ControlPanel';
import { UploadDocumentModal } from '../DocumentModal';
import { useAccessControls } from '../../../hooks/useAccessControls';
import documentLibraries from '../documentLibraries';
import documentTypes from '../documentTypes';
import { DocumentExplorer } from '../DocumentExplorer';
import { usePermissions } from '../../../hooks/usePermissions';
import { useNotification, useCurrentAccountId } from '../../../hooks';
import { DocumentTypeFolder } from '../types';
import ExportButtons from '../ExportButtons';
import { useGetDocuments } from '../useGetDocuments';
import { getAllFilters } from '../utils';

interface DocumentFilesProps {
  documentFolders: DocumentTypeFolder[];
}

const DocumentFiles = (props: DocumentFilesProps) => {
  const intl = useIntl();
  const { documentFolders } = props;
  const accountId = useCurrentAccountId();
  const { library, type } = useParams();
  const [gridApi, setGridApi] = useState<{ api: GridApi; columnApi: ColumnApi }>();
  const accessConrols = useAccessControls();
  const permissions = usePermissions();
  const userCanUpload = !!permissions.document.find((action: string) => action.startsWith('create'));
  const appliedDocumentType =
    library && type && type !== 'all'
      ? documentFolders.find((folder: DocumentTypeFolder) => folder.link === type)
      : null;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useQueryParam('search', StringParam);

  const { showNotification } = useNotification();

  const filteredLibraries = documentLibraries.filter((item) =>
    item.accessControlIndexer ? _.get(accessConrols, item.accessControlIndexer) : true
  );
  const createBreadcrumbs = () => {
    const crumbs = [{ title: intl.formatMessage({ id: 'pages.documents.breadcrumbs.one' }), to: '/documents' }];
    if (library) {
      crumbs.push({ title: _.capitalize(library), to: `/documents/${library}` });
    } else {
      crumbs.push({ title: 'All', to: `/documents/all` });
    }
    return crumbs;
  };
  const { documentsExist, error, loading, refetch } = useGetDocuments(
    {
      accountId,
      filters: getAllFilters(searchValue || ''),
    },
    !searchValue
  );

  const onComplete = (message: string) => {
    setShowCreateModal(false);
    setTimeout(() => {
      refetch();
    }, 5000);
    showNotification(message, 'success');
  };

  const onError = (err: Error) => {
    setShowCreateModal(false);
    showNotification(err.message, 'danger');
  };

  return (
    <Col container fullHeight>
      <PageHeader
        name="DocumentFiles"
        title="Documents"
        breadcrumbs={createBreadcrumbs()}
        icon="file-alt"
        iconColor="quaternary"
        isDropdown
        dropdownItems={
          library
            ? documentFolders.filter((folder) => folder.libraries.includes(library.toUpperCase() as DocumentLibrary))
            : filteredLibraries
        }
        dropdownDefault={appliedDocumentType ? appliedDocumentType.title : 'All Documents'}
      >
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <Col span={3}>
            <ClearButton gridApi={gridApi?.api} columnApi={gridApi?.columnApi} />
          </Col>
          {userCanUpload && (
            <Col span={6}>
              <ExportButtons gridApi={gridApi} accountId={accountId} />
            </Col>
          )}
          {userCanUpload && (
            <Col span={3}>
              <Button name="DocumentFiles__Button--upload" color="primary" onClick={() => setShowCreateModal(true)}>
                + Upload Documents
              </Button>
            </Col>
          )}
        </ControlPanel>
      </PageHeader>
      <DocumentExplorer
        showGrid
        library={library}
        category={appliedDocumentType?.filterValue}
        setGridApi={setGridApi}
        searchValue={searchValue!}
        onSearch={setSearchValue}
        banners={[]}
        documentsExist={documentsExist}
        error={error}
        retry={refetch}
        loading={loading && !documentsExist}
      />
      {showCreateModal && (
        <UploadDocumentModal
          predefinedValues={{
            library: library?.toUpperCase() as DocumentLibrary,
            category: documentTypes.find((item) => item.link === type)?.filterValue,
          }}
          onDismiss={() => {
            setShowCreateModal(false);
          }}
          onComplete={onComplete}
          onUpload={onComplete}
          onError={onError}
        />
      )}
    </Col>
  );
};

export default DocumentFiles;
