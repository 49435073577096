import { useEffect } from 'react';
import { TransactionTable } from '../../TransactionTable/v2';
import { styled, Button, LinearProgress } from '@rio/ui-components';
import { TransactionType } from '@rio/rio-types';
import { ConvertTuple, useGridHooksByDataType } from '~/hooks/useGridHooksByDataType';
import { ColumnHeaderModel } from '../../TransactionTable/v2/TransactionTable';

export const TableContainer = styled('div')`
  width: 100%;
  padding-bottom: 15px;
  overflow-y: auto;
`;

export const ButtonContainer = styled('div')`
  margin-top: 32px;
  width: 100%;
  display: flex;
  gap: 16px;
`;

const LinearProgressContainer = styled('div')`
  width: 100%;
  margin-top: 16px;
  height: 4px;
`;

const LinearProgressStyled = styled(LinearProgress)`
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  width: 50%;
`;

type Props = {
  formData?: Record<string, unknown>;
  onConfirm?: () => void;
  onBack?: () => void;
  dataSection: TransactionType;
};

export function DataFormPreview(props: Props) {
  const { formData = {}, onConfirm = () => {}, onBack = () => {}, dataSection } = props;
  const [columns, convertTuple] = useGridHooksByDataType(dataSection);
  const [convert, { data: rows, loading }] = convertTuple as ConvertTuple;

  useEffect(() => {
    convert(formData, columns);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && (
        <LinearProgressContainer>
          <LinearProgressStyled />
        </LinearProgressContainer>
      )}

      {!loading && (
        <TableContainer>
          <TransactionTable
            columnHeaderModel={columns as ColumnHeaderModel[]}
            data={rows as Record<string, unknown>[]}
          />
        </TableContainer>
      )}

      <ButtonContainer>
        <ButtonStyled onClick={onBack} variant="outlined" disabled={loading}>
          Back
        </ButtonStyled>

        <ButtonStyled onClick={onConfirm} variant="filled" loading={loading}>
          Confirm data import to RIO
        </ButtonStyled>
      </ButtonContainer>
    </>
  );
}
