import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'rio-ui-components';
import { useNavigate } from 'react-router-dom';
import { Search } from 'rio-ui-components';
import { DataType, Maybe, SetFilter } from '@rio/rio-types';
import ContainerHeader from '../../../components/ContainerHeader';
import { SurveysGrid } from '../../../components/Surveys';
import { useCurrentAccountId } from '../../../hooks';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { PageFailed } from '../../../components/Errors';
import { Async } from '../../../components/Async';
import PaginationContainer from '../../../components/PaginationContainer';
import { getPageSize } from '../../../utils/width';
import { SelectEvent, Option } from '../../../types';
import { useGetReceivedSurveysPage, GetReceivedSurveysPageResponse } from './queries/useGetReceivedSurveysPage';
import { StatusFilter } from './StatusFilter';

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

export const SearchContainer = styled(Search)`
  width: 95%;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const breadcrumbs = [
  {
    title: 'Surveys received',
  },
];

function getSetFilters(status: Option[]) {
  const filters: Maybe<Array<SetFilter>> = [];
  if (status.length) {
    filters.push({
      field: 'submissionStatus',
      filterType: DataType.Text,
      values: status.map((s) => s.value!),
    });
  }

  return filters;
}

export function ReceivedSurveysContainer() {
  const [searchValue, setSearchValue] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState<Option[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = getPageSize(window.innerWidth);

  const accountId = useCurrentAccountId();

  const variables = {
    accountId,
    filters: {
      search: {
        field: ['name'],
        value: searchValue,
      },
      set: getSetFilters(submissionStatus),
    },
    offset: (currentPage - 1) * pageSize,
    limit: pageSize,
  };

  const result = useGetReceivedSurveysPage(variables);

  const navigate = useNavigate();
  const handleSurveyClick = useCallback((id: string) => navigate(`./${id}`), [navigate]);

  const renderData = useCallback(
    ({ getReceivedSurveysPage }) => <SurveysGrid surveys={getReceivedSurveysPage} onSurveyClick={handleSurveyClick} />,
    [handleSurveyClick]
  );

  const renderError = useCallback(
    (error: Error, { refetch }) => <PageFailed error={error} retry={() => refetch({ accountId })} />,
    [accountId]
  );

  const renderLoading = useCallback(() => <ContainerLoadingIndicator name="ReceivedSurveysContainer__Loading" />, []);

  // checks and changes total pages each time query succeeds
  useEffect(() => {
    if (result?.data?.getReceivedSurveysPage?.totalRows) {
      setTotalPages(Math.ceil(result.data.getReceivedSurveysPage.totalRows / pageSize));
    }
  }, [pageSize, result]);

  return (
    <Page>
      <ContainerHeader
        name="FormsContributors"
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title="Surveys received"
        breadcrumb={breadcrumbs}
      >
        <SearchContainer
          name="ConfigurationAccountsContainer__Controls__Search"
          value={searchValue}
          onChange={(e: SelectEvent) => setSearchValue(e.target.value)}
          size="sm"
          hideButton
        />
        <StatusFilter accountId={accountId} status={submissionStatus} setStatus={setSubmissionStatus} />
        {totalPages > 1 && (
          <PaginationContainer
            id="received-survey-container-pagination"
            name="received-survey-container-pagination"
            currentPage={currentPage}
            itemsCountPerPage={pageSize}
            totalPagesCount={totalPages}
            onChange={setCurrentPage}
            size={'sm'}
          />
        )}
      </ContainerHeader>
      <Async<GetReceivedSurveysPageResponse>
        result={result}
        data={renderData}
        error={renderError}
        loading={renderLoading}
      />
    </Page>
  );
}
