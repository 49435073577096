import { gql } from '@apollo/client';

export const GET_MY_CONTRIBUTORS = gql`
  query GetMyContributors($accountId: ID!) {
    getMySurveyContributors(accountId: $accountId) {
      __typename
      ... on AccountSurveyContributor {
        account {
          id
          name
        }
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`;
