import React, { useState } from 'react';
import { Button, Text, TextField, styled } from '@rio/ui-components';
import { validate } from '~/components/Evaluation/validate';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const HeadingStyled = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.sys.color.onSurface};
  margin-bottom: 24px;
`.withComponent('h2');

const DescriptionStyled = styled(Text)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.sys.color.onSurface};
`;

const RowStyled = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const QuestionsColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({ theme }) => theme.sys.color.onSurface};
`;

const QuestionNumber = styled(Text)``.withComponent('span');

const ButtonsRow = styled('div')`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  margin-top: 16px;
`;

const ButtonV2Styled = styled(Button)`
  width: 100%;
`;

const TextFieldStyled = styled(TextField)`
  width: 196px;
`;

function getAnswersHashMap(questions, index) {
  return questions?.reduce(
    (map, q) => ({
      ...map,
      [q.id]: q.answers?.[index]?.value,
    }),
    {}
  );
}
export function Evaluation({ evaluation, onSubmit, onGoNext, goNextButtonTitle, reevaluation, type, isSubmitting }) {
  const [answers, setAnswers] = useState(getAnswersHashMap(evaluation?.questions, 0));
  const [newAnswers, setNewAnswers] = useState(getAnswersHashMap(evaluation?.questions, 1));
  const [errors, setErrors] = useState({});
  const preAssessmentCompleted = evaluation?.questions.every((q) => q?.answers?.length >= 1);
  const postAssessmentCompleted = evaluation?.questions.every((q) => q?.answers?.length >= 2);

  const handleChangeButton = () => {
    const answersToSubmit = reevaluation ? newAnswers : answers;
    const previousAnswers = reevaluation ? answers : null;
    const newErrors = validate(evaluation, answersToSubmit, previousAnswers);
    if (Object.keys(newErrors).length) {
      return setErrors(newErrors);
    }
    if (Object.keys(errors).length) {
      setErrors({});
    }
    onSubmit(
      Object.keys(answersToSubmit).map((questionId) => ({
        questionId,
        value: answersToSubmit[questionId],
      }))
    );
  };

  return (
    <Container>
      {evaluation ? (
        <>
          <HeadingStyled typescale="headline" size="small">
            {evaluation?.name}
          </HeadingStyled>
          <DescriptionStyled typescale="body" size="large">
            {evaluation?.description}
          </DescriptionStyled>
          <QuestionsColumn>
            {evaluation?.questions?.map((question, index) => (
              <RowStyled key={question.id}>
                <Text typescale="body" size="large">
                  <QuestionNumber typescale="title" size="medium">
                    Q{index + 1}:
                  </QuestionNumber>
                  {question.text}
                </Text>
                <TextFieldStyled
                  type="number"
                  value={answers[question.id]}
                  error={reevaluation ? null : errors[question.id]}
                  placeholder={`${evaluation.scale.min}-${evaluation.scale.max}`}
                  disabled={preAssessmentCompleted}
                  inputProps={{ min: evaluation.scale.min, max: evaluation.scale.max }}
                  onChange={(e) => {
                    setAnswers({
                      ...answers,
                      [question.id]: e.target.value,
                    });
                  }}
                />
                {reevaluation && (
                  <TextFieldStyled
                    type="number"
                    value={newAnswers[question.id]}
                    error={errors[question.id]}
                    placeholder={`${answers[question.id] || evaluation.scale.min}-${evaluation.scale.max}`}
                    inputProps={{ min: evaluation.scale.min, max: evaluation.scale.max }}
                    disabled={postAssessmentCompleted}
                    onChange={(e) => {
                      setNewAnswers({
                        ...newAnswers,
                        [question.id]: e,
                      });
                    }}
                  />
                )}
              </RowStyled>
            ))}
          </QuestionsColumn>
          <ButtonsRow>
            <ButtonV2Styled variant="filled" onClick={onGoNext}>
              {goNextButtonTitle}
            </ButtonV2Styled>
            <ButtonV2Styled
              variant="elevated"
              disabled={isSubmitting || (reevaluation ? postAssessmentCompleted : preAssessmentCompleted)}
              onClick={handleChangeButton}
            >
              Submit {type}
            </ButtonV2Styled>
          </ButtonsRow>
        </>
      ) : null}
    </Container>
  );
}
