import { gql } from '@apollo/client';

export const INITIATE_DROMO_UPLOAD = gql`
  mutation InitiateDromoUpload(
    $transactionType: TransactionType!
    $accountId: ID!
    $fileName: String!
    $dromoId: ID!
    $isDirectActivity: Boolean
  ) {
    initiateDromoUpload(
      transactionType: $transactionType
      accountId: $accountId
      fileName: $fileName
      dromoId: $dromoId
      isDirectActivity: $isDirectActivity
    )
  }
`;

export const GET_DROMO_SCHEMA = gql`
  query GetDromoSchema($transactionType: TransactionType!, $accountId: ID!) {
    getDromoSchema(transactionType: $transactionType, accountId: $accountId)
  }
`;
