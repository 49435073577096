import { useState, useRef } from 'react';
import { LoadingIndicator, Col, Text, Heading, Button, Modal } from 'rio-ui-components';
import { Link, useParams } from 'react-router-dom';
import { TaskSubject, Legislation, Tag, Location, TaskCategory } from '@rio/rio-types';
import styled from 'styled-components';
import { useGetAccountAspectById } from '../../../hooks/governance';
import { NotAvailable } from '../../../components/Errors';
import { formatDate } from '../../../utils';
import { Nullable } from '../../../types';
import { useCurrentAccountId, useNotification, usePermissions, useUserToken } from '../../../hooks';
import { CreateTaskModal } from '../../TasksContainer/TasksGrid/CreateTaskModal';
import { useCreateAspect } from './useCreateAspect';
import PageHeader from '../../../components/PageHeader';
import { Forbidden } from '../../../components/Errors';
import { AspectModal } from './AspectModal';
import Tasks from './Tasks';
import { formatAspect, formatScope, formatList, prepareAspectInput } from './utils';
import { AspectFormState } from './types';
import { ModalPortal } from './ModalPortal';
import { EvidencePicker, EvidenceRow } from '../../../components/EvidenceNotes';
import { useSaveEvidenceNotes } from './useSaveEvidenceDocuments';
import { ToastColor } from '@rio/ui-components';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentOuterContainer = styled.div`
  overflow: auto;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const TopButton = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing};
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
  background-color: ${(props) => props.theme.colors.basic.white};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const PaddedCol = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.md.spacing};
`;

const RowPaddedCol = styled(PaddedCol)`
  flex-direction: row;
  display: flex;

  h2:first-child {
    margin-right: ${(props) => props.theme.geometry.sm.spacing};
  }
`;

const CustomRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const StatusContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const EvidenceContainter = styled.div`
  margin-top: 32px;
`;

export function AspectView() {
  const { id } = useParams();
  const modalPortalRef = useRef<HTMLDivElement>(null);
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { token } = useUserToken();
  const { showNotification } = useNotification();
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const { data, loading: isLoading, error, refetch } = useGetAccountAspectById(id!);
  const aspect = data?.getAspectById;
  const formattedAspect = aspect && formatAspect(aspect, accountId, token);
  const [updateAspect] = useCreateAspect();
  const scope = formatScope(aspect);
  const isError = !!error;
  const notFound = !aspect;
  const isVisible = !isError && !notFound && !isLoading;
  const isEditButtonVisible = !!permissions.governance.find((action: string) => action.startsWith('createAspect'));
  const saveEvidenceNotes = useSaveEvidenceNotes();

  const clearEvidenceModal = () => {
    setShowEvidenceModal(false);
    setEvidenceNotes('');
    setEvidenceDocuments([]);
  };

  const saveAspect = async (aspectArg: AspectFormState, evidenceNotesArg: string, evidenceDocumentsArg: any[]) => {
    try {
      const aspectEvidenceNotes = await saveEvidenceNotes(evidenceDocumentsArg, evidenceNotesArg, aspectArg);

      await updateAspect({
        variables: {
          input: prepareAspectInput(aspectArg, aspectEvidenceNotes, true, false, token),
        },
      });

      setEditModalVisible(false);
      clearEvidenceModal();
      refetch({ id });

      showNotification('Aspect successfully updated', 'success');
    } catch {
      showNotification('Something went wrong, please try again or contact support', 'danger');
    }
  };

  return (
    <ContainerStyled>
      <ModalPortal ref={modalPortalRef} />
      <PageHeader
        name="Aspect"
        title={'Aspect'}
        breadcrumbs={[
          { title: 'Governance', to: '/governance' },
          { title: 'Aspects & Impacts Register', to: '/governance/environmental-aspects' },
        ]}
        icon="pencil-alt"
        iconColor="tertiary"
      >
        {isVisible && (
          <div>
            {isEditButtonVisible && (
              <TopButton name="TasksHome__Button--create" inline color="info" onClick={() => setEditModalVisible(true)}>
                Edit Aspect
              </TopButton>
            )}
            {!!permissions.task.find((action: string) => action.startsWith('create')) && (
              <TopButton
                data-cy="TasksHome__Button--create"
                inline
                color="primary"
                onClick={() => setShowCreateTaskModal(true)}
              >
                + Create Task
              </TopButton>
            )}
            {!!permissions.task.find((action: string) => action.startsWith('edit')) && (
              <TopButton
                data-cy="TasksHome__Button--create"
                inline
                color="tertiary"
                onClick={() => setShowEvidenceModal(true)}
              >
                Add Evidence
              </TopButton>
            )}
          </div>
        )}
      </PageHeader>

      <ContentOuterContainer>
        {isLoading && (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        )}
        {isError && <NotAvailable error={new Error('Not available')} />}
        {showCreateTaskModal && (
          <CreateTaskModal
            accountId={accountId}
            onDismiss={() => setShowCreateTaskModal(false)}
            onComplete={(event) => {
              const message = event?.message as string;
              const colour = event?.colour;
              showNotification(message, colour as ToastColor);
            }}
            defaultValues={{
              scope: aspect?.scope,
              tagIds: aspect?.tags
                ?.filter(Boolean)
                .map((tag: Nullable<Tag>) => ({ value: tag?.id, label: tag?.tagName })),
              locationIds: aspect?.locations
                ?.filter(Boolean)
                .map((location: Nullable<Location>) => ({ value: location?.id, label: location?.name })),
              category: TaskCategory.Governance,
              subject: TaskSubject.Aspects,
              item: { id },
            }}
            disabled={{
              scope: true,
              category: true,
              subject: true,
              locations: true,
              tags: true,
              [TaskSubject.Aspects]: true,
            }}
          />
        )}
        {isEditModalVisible && (
          <AspectModal
            title="Edit Aspect"
            submitTitle="Save Aspect"
            onDismiss={() => setEditModalVisible(false)}
            accountId={accountId}
            onSubmit={saveAspect}
            values={aspect as unknown as object}
            isCopy={false}
            modalPortalRef={modalPortalRef}
          />
        )}
        {showEvidenceModal && (
          <Modal show size="md" onDismiss={clearEvidenceModal} height="auto" maxHeight="90vh">
            <ContentContainer>
              <Heading>Add Evidence</Heading>
              <EvidencePicker
                evidenceDocuments={evidenceDocuments}
                setEvidenceDocuments={setEvidenceDocuments}
                evidenceNotes={evidenceNotes}
                setEvidenceNotes={setEvidenceNotes}
              />
              <Button
                disabled={!evidenceDocuments.length && !evidenceNotes}
                onClick={formattedAspect && (() => saveAspect(formattedAspect, evidenceNotes, evidenceDocuments))}
              >
                Save Evidence
              </Button>
            </ContentContainer>
          </Modal>
        )}
        {isVisible && (
          <ContentContainer>
            <StatusContainer>
              <RowPaddedCol span={6}>
                <Text>Status:&nbsp;</Text>
                <Text color="dark" weight="bold">
                  {aspect?.status?.name}
                </Text>
              </RowPaddedCol>
              <RowPaddedCol span={6}>
                <Text>Review Date:&nbsp;</Text>
                <Text color="dark" weight="bold">
                  {formatDate(aspect?.reviewDate)}
                </Text>
              </RowPaddedCol>
            </StatusContainer>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Scope:</Text>
                <Text color="dark" weight="bold">
                  {scope}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Departments:</Text>
                <Text color="dark" weight="bold">
                  {formatList(aspect?.departments)}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Activity / Process:</Text>
                <Text color="dark" weight="bold">
                  {aspect?.activity?.name}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Environmental Aspect(s):</Text>
                <Text color="dark" weight="bold">
                  {formatList(aspect?.aspects)}
                </Text>
              </PaddedCol>
            </CustomRow>
            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Potential Environmental Impact(s):</Text>
                <Text color="dark" weight="bold">
                  {formatList(aspect?.impacts)}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Legislation Reference(s):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.legislations?.map(
                    (legislation: Nullable<Legislation>, i: number) =>
                      legislation && (
                        <>
                          <StyledLink to={'/governance/legislation/' + id}>{legislation.title}</StyledLink>
                          {i + 1 !== aspect?.legislations?.length ? <br /> : null}
                        </>
                      )
                  ) || '-'}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Relevant Control Measure(s):</Text>
                <Text color="dark" weight="bold">
                  {formatList(aspect?.controlMeasures)}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Potential Emergency Condition(s):</Text>
                <Text color="dark" weight="bold">
                  {formatList(aspect?.emergencyConditions)}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Likelihood (no control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.likelihoodNoControl || 0}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Likelihood (with control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.likelihoodControl || 0}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Severity (no control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.severityNoControl || 0}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Severity (with control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.severityControl || 0}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Significance (no control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.significanceNoControl || 0}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Significance (with control):</Text>
                <Text color="dark" weight="bold">
                  {aspect?.significanceControl || 0}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Aspect Creator:</Text>
                <Text color="dark" weight="bold">
                  {aspect?.author?.name}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Edited by:</Text>
                <Text color="dark" weight="bold">
                  {aspect?.editor?.name || '-'}
                </Text>
              </PaddedCol>
            </CustomRow>

            <CustomRow>
              <PaddedCol item span={6}>
                <Text>Date Created:</Text>
                <Text color="dark" weight="bold">
                  {aspect?.createdDate ? formatDate(aspect?.createdDate) : '-'}
                </Text>
              </PaddedCol>
              <PaddedCol item span={6}>
                <Text>Date last edited:</Text>
                <Text color="dark" weight="bold">
                  {aspect?.editedDate ? formatDate(aspect?.editedDate) : '-'}
                </Text>
              </PaddedCol>
            </CustomRow>

            <StatusContainer>
              <RowPaddedCol span={12}>
                <Heading>Tasks</Heading>
              </RowPaddedCol>
            </StatusContainer>
            <Tasks />

            <EvidenceContainter>
              <CustomRow>
                <PaddedCol span={12}>
                  <Heading>Evidence</Heading>
                  {!!aspect.evidenceNotes?.length &&
                    aspect.evidenceNotes.map((evidence) => {
                      return <EvidenceRow key={JSON.stringify(evidence)} evidence={evidence} />;
                    })}
                  {!aspect.evidenceNotes?.length && <Text>Currently there is no evidence assigned to this task.</Text>}
                </PaddedCol>
              </CustomRow>
            </EvidenceContainter>
          </ContentContainer>
        )}
        {!isLoading && !isVisible && (
          <Forbidden name="Aspect_Forbidden" error={new Error('You do not have access to this aspect')} />
        )}
      </ContentOuterContainer>
    </ContainerStyled>
  );
}
