import { useMutation } from '@apollo/client';
import { find } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, MultiSelect } from 'rio-ui-components';
import { usePermissions } from './usePermissions';
import { useUserToken } from './useUserToken';
import { useCurrentAccountId } from './useCurrentAccountId';
import Option from '../components/Option';
import DELETE_DEPARTMENT from '../graphql/mutations/account/DeleteDepartment.mutation.graphql';
import { useUpdateCacheAccount } from './useUpdateCacheAccount';

export const getDepartments = (departments, departmentIds) => {
  if (!departments || !departmentIds) return null;
  const listDepartments = [];
  departments.forEach((item) => {
    if (departmentIds.includes(item.id)) listDepartments.push({ value: item.id, label: item.name });
  });
  return listDepartments;
};

export function useCreateDepartment(departments, currentDepartment, passedAccountId) {
  const [showCreateDepartment, setShowCreateDepartment] = useState(false);
  const permissions = usePermissions();
  const { token } = useUserToken();
  const updateCacheAccount = useUpdateCacheAccount();
  const currentAccountId = useCurrentAccountId();
  const accountId = passedAccountId || currentAccountId;
  const userId = token ? token.sub : null;
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT);

  const canCreateDepartment = permissions.account.find((action) => action.startsWith('createDepartment'));
  const canDeleteDepartment = permissions.account.find((action) => action.startsWith('deleteDepartment'));

  const listDepartments = departments
    ? departments.map((item) => ({ id: item.id, value: item.id, label: item.name }))
    : [];

  if (currentDepartment && !Array.isArray(currentDepartment) && find(departments, { id: currentDepartment }))
    listDepartments.unshift({ value: 'notSelected', label: 'Not selected' });
  if (canCreateDepartment) listDepartments.unshift({ value: 'NEW', label: 'Create New ...' });

  const currentDepartmentValue = Array.isArray(currentDepartment)
    ? currentDepartment.map((dep) => {
        return { label: dep.name || dep.label, value: dep.id || dep.value };
      }) || []
    : currentDepartment;

  const onDelete = (departmentId) => {
    deleteDepartment({
      variables: { accountId, departmentId },
      update(cache, { data: { deleteDepartment } }) {
        updateCacheAccount(cache, deleteDepartment, accountId, userId);
      }
    });
  };

  const optionsComponents = {
    Option: ({ ...props }) => (
      <Option {...props} onDelete={onDelete} canDelete={canDeleteDepartment && props.data.value !== 'notSelected'} />
    )
  };

  const onSelectChange = (event, onCreate, onChange) => {
    const createNew = Array.isArray(event.target.select)
      ? event.target.select.find(({ value }) => value === 'NEW')
      : event.target.select?.value === 'NEW';

    if (createNew) {
      onCreate(event);
    } else {
      onChange(event);
    }
  };

  const departmentSelect = (handleChange) => {
    return (
      <Select
        options={listDepartments}
        value={currentDepartmentValue ? find(listDepartments, { value: currentDepartmentValue }) : null}
        onChange={(e) => {
          onSelectChange(
            e,
            () => setShowCreateDepartment(true),
            (event) =>
              handleChange({
                target: {
                  name: 'department',
                  value: event.target.value
                }
              })
          );
        }}
        components={optionsComponents}
        placeholder={<FormattedMessage id="components.selectPlaceholder" />}
      />
    );
  };

  const departmentMultipleSelect = (handleChange) => {
    return (
      <MultiSelect
        options={listDepartments}
        value={currentDepartmentValue}
        onChange={(e) => {
          onSelectChange(
            e,
            () => setShowCreateDepartment(true),
            () => handleChange(e)
          );
        }}
        components={optionsComponents}
      />
    );
  };

  return { departmentSelect, departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment };
}
