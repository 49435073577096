"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationRailItem = exports.NavItemLabel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_next_1 = require("@mui/material-next");
const react_router_dom_1 = require("react-router-dom");
const Badge_1 = require("../Badge/Badge");
const system_1 = require("@mui/system");
exports.NavItemLabel = (0, material_next_1.styled)('p') `
  font-family: ${({ theme }) => theme.sys.typescale.label.medium.family};
  font-size: ${({ theme }) => theme.sys.typescale.label.medium.size}px;
  font-weight: ${({ theme }) => theme.sys.typescale.label.medium.weight};
  line-height: ${({ theme }) => theme.sys.typescale.label.medium.lineHeight}px;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0px;
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;
const StyledIconContainer = (0, material_next_1.styled)('div') `
  display: flex;
  padding: 4px 16px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;

  & svg {
    width: 24px;
    height: 24px;
  }
`;
const StyledLinkCommonCSS = (0, system_1.css) `
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  height: 56px;
`;
const StyledNavLink = (0, material_next_1.styled)(react_router_dom_1.NavLink) `
  ${StyledLinkCommonCSS}
  &:hover div {
    background-color: ${({ theme }) => theme.ref.palette.primary[90]};
  }

  &:active div {
    background-color: ${({ theme }) => theme.ref.palette.primary[80]};
  }

  &:not(.active) {
    & svg {
      color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
    }
  }

  &.active div {
    background-color: ${({ theme }) => theme.ref.palette.primary[95]};
  }

  &.active {
    & svg {
      color: ${({ theme }) => theme.sys.color.primary};
    }
  }

  &.active > p {
    font-weight: 600;
    color: ${({ theme }) => theme.sys.color.primary};
  }
`;
const StyledExternalLink = (0, material_next_1.styled)('a') `
  ${StyledLinkCommonCSS}

  &:hover div {
    background-color: ${({ theme }) => theme.ref.palette.primary[90]};
  }

  &:active div {
    background-color: ${({ theme }) => theme.ref.palette.primary[80]};
  }

  &.active {
    & svg {
      color: ${({ theme }) => theme.sys.color.primary};
    }
  }

  &:not(.active) {
    & svg {
      color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
    }
  }
`;
const NavigationRailItem = (props) => {
    const { href, className, icon: Icon, label, badgeContent, showDot, id, name, external, openInNewTab, end, ...rest } = props;
    if (external) {
        return ((0, jsx_runtime_1.jsxs)(StyledExternalLink, { className: className, href: href, id: id, target: openInNewTab ? '_blank' : undefined, ...rest, children: [(0, jsx_runtime_1.jsx)(StyledIconContainer, { children: (0, jsx_runtime_1.jsx)(Badge_1.Badge, { badgeContent: badgeContent, variant: showDot ? 'dot' : 'standard', children: (0, jsx_runtime_1.jsx)(Icon, {}) }) }), (0, jsx_runtime_1.jsx)(exports.NavItemLabel, { children: label })] }));
    }
    return ((0, jsx_runtime_1.jsxs)(StyledNavLink, { className: className, to: href, id: id, end: end, ...rest, children: [(0, jsx_runtime_1.jsx)(StyledIconContainer, { children: (0, jsx_runtime_1.jsx)(Badge_1.Badge, { badgeContent: badgeContent, variant: showDot ? 'dot' : 'standard', children: (0, jsx_runtime_1.jsx)(Icon, {}) }) }), (0, jsx_runtime_1.jsx)(exports.NavItemLabel, { children: label })] }));
};
exports.NavigationRailItem = NavigationRailItem;
