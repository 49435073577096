import groupBy from 'lodash/groupBy';
import { hierarchyPriorities } from './constants';
import alphabetise from '../../../utils/alphabetise';
import { GET_WASTE_HIERARCHIES_DATA } from './index.queries';

export function getWasteHierarchiesFromData(data) {
  return data && data.getWasteHierarchiesByAccountId ? data.getWasteHierarchiesByAccountId : [];
}

export function getWasteInLocationsFromData(data) {
  return data && data.getWasteInsByAccountId ? data.getWasteInsByAccountId : [];
}

export function makeHierarchiesFilter(wasteInId) {
  return (h) => {
    const wasteInSpecific = !!h.wasteIn;
    const wasteInMatches = wasteInSpecific && h.wasteIn.id === wasteInId;
    const isGlobal = h.isGlobal;
    const accountSpecific = h.accountId && !wasteInSpecific;
    if (wasteInId === 'Default') {
      return isGlobal || accountSpecific;
    }
    return wasteInMatches || accountSpecific || isGlobal;
  };
}

export function filterWasteHierarchiesByWasteIn(hierarchies, wasteInId) {
  const filteredForThisWasteIn = hierarchies.filter(makeHierarchiesFilter(wasteInId));
  const hierarchiesByTreatmentId = groupBy(filteredForThisWasteIn, (h) => h.treatmentProcess.id);
  return Object.keys(hierarchiesByTreatmentId).map((treatmentId) => {
    const hierarchies = hierarchiesByTreatmentId[treatmentId];
    return hierarchies.sort(sortHierarchyByPriority).shift();
  });
}

export function getHierarchyPriority(h) {
  if (h.wasteIn) {
    return hierarchyPriorities.HIGH;
  }
  if (h.accountId) {
    return hierarchyPriorities.MEDIUM;
  }
  return hierarchyPriorities.LOW;
}

export function sortHierarchyByPriority(a, b) {
  return getHierarchyPriority(b) - getHierarchyPriority(a);
}

export function sortHiearachiesInView(a, b) {
  const alphabeticalDiff = alphabetise(a.treatmentProcess.name, b.treatmentProcess.name);
  if (alphabeticalDiff !== 0) {
    return alphabeticalDiff;
  }
  const customOrGlobalDiff = +b.isGlobal - +a.isGlobal;
  return customOrGlobalDiff;
}

export async function updateHierarchiesInCache(client, accountId, newCustomWasteHierarchy) {
  const { data } = await queryWasteHierarchiesData(client, accountId);
  const alreadyExists = data.getWasteHierarchiesByAccountId.find((w) => w.id === newCustomWasteHierarchy.id);
  if (alreadyExists) {
    return;
  }
  await client.cache.writeQuery({
    query: GET_WASTE_HIERARCHIES_DATA,
    variables: {
      accountId
    },
    data: {
      ...data,
      getWasteHierarchiesByAccountId: [...data.getWasteHierarchiesByAccountId, newCustomWasteHierarchy]
    }
  });
}

export function queryWasteHierarchiesData(client, accountId) {
  return client.query({
    query: GET_WASTE_HIERARCHIES_DATA,
    variables: {
      accountId
    }
  });
}
