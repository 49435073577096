import { Icon } from 'rio-ui-components';
import { AgGridReact } from 'ag-grid-react';
import * as Sentry from '@sentry/react';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SetFilterValuesFuncParams,
} from 'ag-grid-community';
import styled from 'styled-components';
import { SurveyQuestion } from '@rio/rio-types';
import { useCallback, useMemo } from 'react';
import { RowNode, ICellRendererParams } from 'ag-grid-community';
import { getAgRowId, mapFilterModel, mapSortModel } from '../../../utils';
import { useGet, useGetFilter } from './queries';
import { useNotification } from '~/hooks';

const GridContainer = styled.div`
  height: 100%;
`;

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xxl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface ActionCellProps {
  data: RowNode;
  onEdit: (row: RowNode) => void;
}

interface GridOfQuestionsProps {
  onEdit: (catgory: SurveyQuestion) => void;
  setAgGridApi: (api: GridApi) => void;
}
const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  cellStyle: {
    height: 'auto',
  },
};

const ActionCell = ({ data, onEdit }: ICellRendererParams & ActionCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(data)}>
      <Icon icon="pencil-alt" />
    </EditLinkStyled>
  </GridCellContainer>
);

const getRowId = getAgRowId('id');

const PAGE_SIZE = 20;

export function GridOfQuestions({ onEdit, setAgGridApi }: GridOfQuestionsProps) {
  const [fetchRows] = useGet();

  const [getFilter] = useGetFilter();

  const { showNotification } = useNotification();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setAgGridApi(event.api);
    event.api.sizeColumnsToFit();
  }, [setAgGridApi]);

  const components = useMemo(
    () => ({
      edit: (props: ICellRendererParams) => (
        <ActionCell {...props} onEdit={(row) => onEdit(row as unknown as SurveyQuestion)} />
      ),
    }),
    [onEdit]
  );

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const field = params.colDef.colId || params.colDef.field;
        if (!field) {
          throw new Error('Unfilterable');
        }
        const response = await getFilter({
          variables: { field },
        });
        if (response.data?.getQuestionsFilter) {
          params.success(response.data.getQuestionsFilter.map((f) => f.label!));
        } else {
          throw new Error('Filter values not loaded');
        }
      } catch (err) {
        Sentry.captureException(err);
        showNotification(new Error(err as string).message, 'danger');
      }
    },
    [getFilter, showNotification]
  );

  const datasource = useMemo(
    () => ({
      async getRows(params: IServerSideGetRowsParams) {
        try {
          const { startRow, sortModel, filterModel } = params.request;
          const variables = {
            offset: startRow,
            limit: PAGE_SIZE,
            sort: mapSortModel(sortModel),
            filters: mapFilterModel(filterModel),
          };
          const response = await fetchRows({
            variables,
          });
          if (response.data?.getQuestions) {
            const { rows, totalRows } = response.data?.getQuestions;
            params.success({
              rowData: rows,
              rowCount: totalRows,
            });
          } else {
            throw new Error('Error fetching questions');
          }
        } catch (err) {
          Sentry.captureException(err);
          params.fail();
        }
      },
    }),
    [fetchRows]
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Form Template Name',
        field: 'template.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
        },
      },
      {
        headerName: 'Title',
        field: 'title',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: getFilterValues,
        },
      },
      {
        headerName: 'Category',
        field: 'category.name',
      },
      {
        headerName: 'Actions',
        field: 'id',
        cellRenderer: 'edit',
        colId: 'edit',
      },
    ],
    [getFilterValues]
  );

  return (
    <GridContainer className="ag-theme-alpine" id="FormConfiguration__Grid__Questions">
      <AgGridReact
        rowModelType="serverSide"
        rowSelection="single"
        defaultColDef={defaultColDef}
        serverSideDatasource={datasource}
        components={components}
        getRowId={getRowId}
        columnDefs={columnDefs}
        onGridReady={handleGridReady}
        paginationPageSize={PAGE_SIZE}
        cacheBlockSize={PAGE_SIZE}
        pagination
      />
    </GridContainer>
  );
}
