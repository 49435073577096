import React, { Component } from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import * as Sentry from '@sentry/react';
import compose from 'lodash/fp/flowRight';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Text, Heading } from 'rio-ui-components';
import { LOG_IN, RESET_PASSWORD, GET_LOGIN_ERROR_MESSAGE } from '../../queries/auth';
import { AuthPage, LoginError, Button, TextInput, Form } from '../../components/AuthPage';
import { isInvalidPassword, getFromQueryString } from './utils';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    // TODO: refactor to functional component and use useLocation hook
    const code = getFromQueryString('code', window.location.search);
    const username = getFromQueryString('username', window.location.search);

    this.state = {
      password1: { value: '', error: null, success: null },
      password2: { value: '', error: null, success: null },
      username: { value: username || '', error: null, success: null },
      confirmationCode: { value: code || '', error: null, success: null },
    };
  }

  hideNotification = () => {
    this.setState({ notification: { message: null, color: null } });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: { ...this.state[event.target.name], value: event.target.value } });
  };

  handleResetPasswordButtonClick = (event, callback) => {
    event.preventDefault();

    const password1 = this.state.password1.value;
    const password2 = this.state.password2.value;
    if (password1 !== password2)
      return this.setState({ password2: { ...this.state.password2, error: 'Passwords do not match' } });
    const invalidPassword = isInvalidPassword(password1);
    if (invalidPassword)
      return this.setState({
        password1: { ...this.state.password1, error: invalidPassword },
        password2: { ...this.state.password2, error: null },
      });

    callback({
      variables: {
        username: this.state.username.value.toLocaleLowerCase(),
        password: this.state.password1.value,
        confirmationCode: this.state.confirmationCode.value,
      },
    });
  };

  getResetPasswordErrorMessageId = (loginError) => {
    return {
      UserNotFoundException: 'login.resetPassword.userNotFound',
      NotAuthorizedException: 'login.resetPassword.incorrectPassword',
      UserDisabledException: 'login.main.userDisabled',
      ConfirmationCodeEmptyException: 'login.resetPassword.confirmationCodeRequired',
      InvalidCodeException: 'login.resetPassword.invalidCode',
      GeneralError: 'login.resetPassword.generalError',
    }[loginError];
  };

  render() {
    return (
      <AuthPage>
        <Heading size="lg">
          <FormattedMessage id="login.resetPassword.title" />
        </Heading>
        <Text align="center">
          <FormattedMessage id="login.resetPassword.subheading" />
        </Text>

        <Mutation
          mutation={RESET_PASSWORD}
          onCompleted={() =>
            this.props.mutate({ variables: { email: this.state.username.value, password: this.state.password1.value } })
          }
          onError={(error) => {
            Sentry.captureException(error);
          }}
        >
          {(resetPassword) => (
            <Form
              name="reset-password__form"
              onSubmit={(event) => this.handleResetPasswordButtonClick(event, resetPassword)}
            >
              {!this.state.username.hide && (
                <TextInput
                  name="username"
                  error={this.state.username.error}
                  box
                  placeholder={this.props.intl.formatMessage({ id: 'login.resetPassword.emailPlaceholder' })}
                  value={this.state.username.value}
                  onChange={this.handleChange}
                />
              )}
              {!this.state.confirmationCode.hide && (
                <TextInput
                  name="confirmationCode"
                  error={this.state.confirmationCode.error}
                  box
                  placeholder={this.props.intl.formatMessage({ id: 'login.resetPassword.codePlaceholder' })}
                  value={this.state.confirmationCode.value}
                  onChange={this.handleChange}
                  autoComplete={false}
                />
              )}
              <TextInput
                name="password1"
                error={this.state.password1.error}
                box
                type="password"
                placeholder={this.props.intl.formatMessage({ id: 'login.resetPassword.passwordPlaceholder' })}
                value={this.state.password1.value}
                onChange={this.handleChange}
                autoComplete={false}
              />
              <TextInput
                name="password2"
                error={this.state.password2.error}
                box
                type="password"
                placeholder={this.props.intl.formatMessage({ id: 'login.resetPassword.confirmPasswordPlaceholder' })}
                value={this.state.password2.value}
                onChange={this.handleChange}
                autoComplete={false}
              />

              <Button name="reset-password-container__button">
                <FormattedMessage id="login.resetPassword.submitButton" />
              </Button>
            </Form>
          )}
        </Mutation>

        <Query query={GET_LOGIN_ERROR_MESSAGE}>
          {({ data: { loginError } }) => {
            if (loginError) {
              return (
                <LoginError name="set-password-container__form-error" color="danger" size="sm" weight="bold">
                  <FormattedMessage id={this.getResetPasswordErrorMessageId(loginError)} />
                </LoginError>
              );
            }
            return null;
          }}
        </Query>
      </AuthPage>
    );
  }
}

export default compose(graphql(LOG_IN), injectIntl)(ResetPasswordContainer);
