import { useCallback, useMemo } from 'react';
import { AgGrid, styled } from '@rio/ui-components';
import { booleanValueFormatter, dateLocalValueFormatter, downloadFileFromUrl, getAgRowId } from '~/utils';
import { CellRenderer } from './CellRenderer';
import { useApolloClient } from '@apollo/client';
import { GET_DATA_BATCH_EXPORT_URL } from '../../TransactionRow/index.queries';
import { ColDef } from 'ag-grid-community';

const AgGridContainerStyled = styled('div')`
  min-height: 350px;
`;

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 316px); // header + page title height
`;

export type ColumnHeaderModel = {
  id: string;
  field?: string;
  width: string | number;
  displayName: string;
  dataType: string;
  display?: boolean;
  filterable?: boolean;
};

type Props = {
  columnHeaderModel: ColumnHeaderModel[];
  data: Record<string, unknown>[];
};

export const TransactionTable = (props: Props) => {
  const { columnHeaderModel, data } = props;

  const apolloClient = useApolloClient();

  const onExportClick = useCallback(
    async (batchId: string) => {
      const { data } = await apolloClient.query({ query: GET_DATA_BATCH_EXPORT_URL, variables: { id: batchId } });
      downloadFileFromUrl(data.getDataImportBatchExportUrl);
    },
    [apolloClient]
  );

  const columnDefs = useMemo(
    () =>
      columnHeaderModel
        .map((col) => {
          if (!col.display) {
            return null;
          }

          let valueFormatter: (({ value }: { value: string }) => string) | undefined = undefined;
          switch (col.dataType) {
            case 'boolean': {
              valueFormatter = booleanValueFormatter;
              break;
            }
            case 'date': {
              valueFormatter = dateLocalValueFormatter;
              break;
            }
            default:
              break;
          }

          return {
            sortable: true,
            headerName: col.displayName,
            filterable: col.filterable,
            field: col.field || col.id,
            width: col.width || undefined,
            valueFormatter,
            cellRenderer: CellRenderer,
            cellRendererParams: { ...col, onExportClick },
          };
        })
        .filter(Boolean) as ColDef[],
    [columnHeaderModel, onExportClick]
  );

  const gridOptions = useMemo(
    () => ({
      rowData: data,
      pagination: true,
      paginationPageSize: 10,
      cacheBlockSize: 10,
      paginationPageSizeSelector: false,
      suppressLoadingOverlay: true,
      getRowId: getAgRowId('id'),
      columnDefs,
    }),
    [data, columnDefs]
  );

  return (
    <AgGridContainerStyled>
      <AgGridStyled gridKey="transactionTableGridV2" gridOptions={gridOptions} />
    </AgGridContainerStyled>
  );
};

export default TransactionTable;
