import { Text, Icons, styled } from '@rio/ui-components';

const TitleStyled = styled(Text)`
  margin-bottom: 32px;
`;

const ContactDetailsContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContactDetailItemContainerStyled = styled('div')`
  display: flex;
  align-items: top;
  gap: 8px;
`;

export const ContactDetails = () => {
  return (
    <div>
      <TitleStyled typescale="title" size="large">
        Contact information
      </TitleStyled>
      <ContactDetailsContainerStyled>
        <ContactDetailItemContainerStyled>
          <Icons.HomeRounded />
          <Text typescale="body" size="medium">
            Rio ESG LTD <br />
            1 Mark Square <br />
            London <br />
            EC2A 4EG <br />
            UK
          </Text>
        </ContactDetailItemContainerStyled>
        <ContactDetailItemContainerStyled>
          <Icons.LocalPhoneRounded />
          <Text typescale="body" size="medium">
            020 3371 7630
          </Text>
        </ContactDetailItemContainerStyled>
        <ContactDetailItemContainerStyled>
          <Icons.EmailRounded />
          <Text typescale="body" size="medium">
            support@rio.ai
          </Text>
        </ContactDetailItemContainerStyled>
      </ContactDetailsContainerStyled>
    </div>
  );
};
