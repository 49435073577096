import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { TransactionType } from '@rio/rio-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Notification, PropertySheet, Row, Col, Label, TextArea, MultiSelect } from 'rio-ui-components';
import AddressPropertySheet from '../../../components/AddressPropertySheet';
import { UPDATE_SUPPLIER_AND_ADDRESS, UPDATE_SUPPLIER_AND_CREATE_ADDRESS } from './index.queries';
import { SupplierTypeCheckboxes } from './SupplierTypeCheckboxes';
import { validateForm } from './validateForm';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const UpdateSupplier = ({
  defaultCountry,
  onDismiss,
  countryList,
  accountId,
  supplierId,
  supplierName,
  addressId,
  address1,
  address2,
  address3,
  city,
  postcode,
  phone,
  utility,
  type,
  heading,
  allCountries,
  materials,
  notes,
  materialTypesOptions
}) => {
  const [state, setState] = useState({
    hasError: false,
    isSuccess: false,
    notification: { show: false, message: '', color: 'success' },
    supplierId: { value: supplierId },
    supplierName: { text: supplierName, error: '' },
    addressId: { value: addressId },
    address1: { text: address1 || '', error: '' },
    address2: { text: address2 || '', error: '' },
    address3: { text: address3 || '', error: '' },
    city: { text: city, error: '' },
    postcode: { text: postcode, error: '' },
    phone: { text: phone || '', error: '' },
    options: [],
    selectCountry: { value: defaultCountry?.value, label: defaultCountry?.label },
    materials: materials?.map(({ id, name }) => ({ label: name, value: id })),
    notes: { text: notes || '', error: '' },
    type: { value: type, error: '' }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateSupplier] = useMutation(addressId ? UPDATE_SUPPLIER_AND_ADDRESS : UPDATE_SUPPLIER_AND_CREATE_ADDRESS, {
    onError: () => {
      setIsSubmitting(false);
      setState({
        ...state,
        notification: {
          show: true,
          message: 'Something went wrong. If the problem continues please contact support.',
          color: 'danger'
        },
        hasError: true,
        isSuccess: false
      });
    },
    onCompleted: () => {
      setIsSubmitting(false);
      onDismiss({ show: false, message: 'Supplier has been updated!', color: 'success' });
    }
  });

  const hideNotification = () => {
    setState({
      ...state,
      notification: { ...state.notification, show: false }
    });
  };

  const handleSaveSupplier = () => {
    const countryId = state.selectCountry?.value || defaultCountry?.value;
    const [newState, valid] = validateForm(
      state,
      allCountries.find((c) => c.id === countryId)
    );
    if (!valid) {
      setState({ ...newState });
    } else {
      const {
        supplierId,
        supplierName,
        addressId,
        address1,
        address2,
        address3,
        city,
        postcode,
        phone,
        selectCountry,
        type
      } = state;
      setIsSubmitting(true);
      updateSupplier({
        variables: {
          utility,
          accountId: type.value.includes('Contractor') ? null : accountId,
          id: supplierId.value,
          name: supplierName.text,
          addressId: addressId.value || uuid(),
          add1: address1.text || '',
          add2: address2.text || '',
          add3: address3.text || '',
          city: city.text,
          post: postcode.text,
          phone: phone.text ? phone.text.trim() : '',
          countryId: selectCountry.value,
          type: type.value,
          materialIds: state.materials?.map(({ value }) => value),
          notes: state.notes?.text
        }
      });
    }
  };

  const handleChange = (e) => setState({ ...state, [e.target.name]: { text: e.target.value, error: '' } });

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.select.value, label: e.target.select.label }
    }));
  };

  const handleSupplierNameChange = (event) => {
    const text = event.target.value;
    setState({
      ...state,
      supplierName: { ...state.supplierName, text }
    });
  };

  return (
    <Container name="update-supplier">
      {state.notification.show && (
        <Notification
          name="update-supplier__notification"
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}

      <PropertySheet id={'PropertySheet'}>
        <PropertySheet.HeaderRow>{heading}</PropertySheet.HeaderRow>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            name="supplier--name"
            onChange={handleSupplierNameChange}
            label="Supplier Name"
            value={state.supplierName.text}
          />
        </PropertySheet.Row>
        <AddressPropertySheet
          id="SupplierAddressPropertySheet"
          name="SupplierAddressPropertySheet"
          address1={state.address1}
          address2={state.address2}
          address3={state.address3}
          city={state.city}
          postcode={state.postcode}
          phone={state.phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          countryList={countryList}
          selectCountry={state.selectCountry}
        />
        {utility !== TransactionType.Materials && (
          <SupplierTypeCheckboxes
            utility={utility}
            error={state.type.error}
            defaultSelected={state.type.value}
            onChange={(value) => {
              setState({
                ...state,
                type: {
                  value,
                  error: ''
                }
              });
            }}
          />
        )}
        {utility === TransactionType.Materials && (
          <>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Material Supplied</LabelStyled>
                </LabelContainerStyled>
                <MultiSelect
                  value={state.materials}
                  options={materialTypesOptions}
                  isDisabled={isSubmitting}
                  onChange={(e) => setState((prev) => ({ ...prev, materials: e.target.select }))}
                  multi
                />
              </ColStyled>
            </Row>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Notes</LabelStyled>
                </LabelContainerStyled>
                <TextArea
                  size="lg"
                  disabled={isSubmitting}
                  onChange={(e) => handleChange(e)}
                  name="notes"
                  value={state.notes?.text}
                  box
                />
              </ColStyled>
            </Row>
          </>
        )}
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn name="updateSupplier" onClick={handleSaveSupplier} disabled={isSubmitting}>
            Update
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
};

export default UpdateSupplier;
