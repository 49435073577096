import { useCallback, useState, useEffect } from 'react';
import { Model } from 'survey-core';
import { styled, Button, Icons } from '@rio/ui-components';

type SurveyPaginationProps = {
  model: Model | null;
};

type PageButtonsContainerProps = {
  isFirstPage: boolean;
  isLastPage: boolean;
};

const PageButtonsContainer = styled('div')<PageButtonsContainerProps>`
  display: flex;
  padding: 2px 0;
  justify-content: ${(props) => (props.isFirstPage ? 'flex-end' : props.isLastPage ? 'flex-start' : 'space-between')};
`;

export const SurveyPagination = ({ model }: SurveyPaginationProps) => {
  const [page, setPage] = useState(model?.currentPageNo || 0);
  const isFirstPage = page === 0;
  const isLastPage = page === (model?.pageCount || 0) - 1;

  useEffect(() => {
    setPage(model?.currentPageNo || 0);
  }, [model?.currentPageNo]);

  const isMultiPage = (model?.pageCount || 0) > 1;

  const onNextPageClick = useCallback(() => {
    if (model?.nextPage()) {
      setPage((_page) => _page + 1);
    }
  }, [model]);

  const onPrevPageClick = useCallback(() => {
    if (model?.prevPage()) {
      setPage((_page) => _page - 1);
    }
  }, [model]);

  if (!isMultiPage || !model) {
    return null;
  }

  return (
    <PageButtonsContainer isFirstPage={isFirstPage} isLastPage={isLastPage}>
      {!isFirstPage && (
        <Button onClick={onPrevPageClick} variant="outlined">
          <Icons.ChevronLeft fontSize="medium" />
        </Button>
      )}
      {!isLastPage && (
        <Button onClick={onNextPageClick} variant="outlined">
          <Icons.ChevronRight fontSize="medium" />
        </Button>
      )}
    </PageButtonsContainer>
  );
};
