import { useState } from 'react';
import { isEmpty, round } from 'lodash';
import { fixFloat } from '../../utils';
import { COMMON_VAT_RATE } from '../../constants/vatRates';

const CART_KEY = 'market_cart';

const DISCOUNT_KEY = 'market_discount';

function subtractDiscount(price, discount) {
  if (!discount) {
    return price;
  }
  const discountFixed = getDiscountFixed(price, discount);
  return discountFixed < price ? price - discountFixed : 0;
}
function getDiscountFixed(price, discount) {
  const value = discount.type === 'percentage' ? (price / 100) * discount.discount : discount.discount;
  return round(value, 2);
}
export function useCart() {
  const [, setRevision] = useState(Math.random());

  const updateRevision = () => setRevision(Math.random());

  const getCartLines = () => {
    const string = localStorage.getItem(CART_KEY);

    return string ? JSON.parse(string) : [];
  };

  const getCartLinesAsMap = () => new Map(getCartLines());

  const addProduct = (product, quantity = 1, parent = null, resizable = false) => {
    const map = getCartLinesAsMap();

    let line;

    if (map.has(product.id)) {
      line = map.get(product.id);

      line.quantity = line.quantity + quantity;
      line.totalAmount = fixFloat(line.quantity * parseFloat(product.price));
    } else {
      line = {
        resizable,
        parent,
        quantity,
        product,
        totalAmount: fixFloat(quantity * parseFloat(product.price))
      };
    }

    map.set(product.id, line);

    localStorage.setItem(CART_KEY, JSON.stringify([...map]));

    updateRevision();
  };

  // replace line if exists
  const setProduct = (product, quantity = 1, parent = null, resizable = false) => {
    const map = getCartLinesAsMap();

    const line = {
      resizable,
      parent,
      quantity,
      product,
      totalAmount: fixFloat(quantity * parseFloat(product.price))
    };

    map.set(product.id, line);

    localStorage.setItem(CART_KEY, JSON.stringify([...map]));

    updateRevision();
  };

  const findLine = (id) => {
    const map = getCartLinesAsMap();

    return map.get(id);
  };

  // remove product by quantity
  const removeProduct = (product, quantity = 1) => {
    const map = getCartLinesAsMap();

    if (!map.has(product.id)) {
      return;
    }

    const line = map.get(product.id);

    const q = line.quantity - quantity;

    line.quantity = q < 0 ? 0 : q;
    line.totalAmount = fixFloat(line.quantity * parseFloat(product.price));

    map.set(product.id, line);

    localStorage.setItem(CART_KEY, JSON.stringify([...map]));

    updateRevision();
  };

  // remove whole line
  const removeLine = (id) => {
    const map = getCartLinesAsMap();

    if (!map) {
      return;
    }

    // TODO: refactor in the future
    // this need for deleting account capacity, not the best but fast solution, at least all logic are in same place
    Array.from(map)
      .filter(([key, { parent }]) => key === id || parent === id)
      .forEach(([key]) => map.delete(key));

    localStorage.setItem(CART_KEY, JSON.stringify([...map]));

    updateRevision();
  };

  const hasProduct = (id) => getCartLinesAsMap().has(id);

  const clearCart = () => {
    localStorage.removeItem(CART_KEY);
    localStorage.removeItem(DISCOUNT_KEY);

    updateRevision();
  };

  const setDiscount = (discount) => {
    localStorage.setItem(DISCOUNT_KEY, JSON.stringify(discount));
    updateRevision();
  };

  const cartLines = getCartLines();

  // by filtering, we remove count with parent, remove Account Capacity as item count
  const discount = JSON.parse(localStorage.getItem(DISCOUNT_KEY));
  const linesCount = cartLines.filter(([, { parent }]) => isEmpty(parent)).length;
  const subTotal = cartLines.reduce((acc, [, { totalAmount }]) => acc + parseFloat(totalAmount), 0);
  const newSubTotal = discount ? subtractDiscount(subTotal, discount) : subTotal;
  const discountFixed = discount ? getDiscountFixed(subTotal, discount) : null;
  const total = newSubTotal * COMMON_VAT_RATE + newSubTotal;

  return {
    addProduct,
    setProduct,
    removeProduct,
    removeLine,
    hasProduct,
    clearCart,
    findLine,
    setDiscount,
    state: {
      data: cartLines,
      isCartEmpty: linesCount < 1,
      subTotal: fixFloat(subTotal),
      newSubTotal: fixFloat(newSubTotal),
      total: fixFloat(total),
      currency: {
        symbol: '£'
      },
      discount,
      discountFixed,
      linesCount: linesCount
    }
  };
}
