import React from 'react';
import { Nullable } from '../../types';
import { ICellRendererParams } from 'ag-grid-community';
import { MatchMark } from '../MatchMark';

interface SearchableCellProps extends ICellRendererParams {
  searchValue: Nullable<string>;
}

export function SearchableCell({ value, valueFormatted, searchValue }: SearchableCellProps) {
  const content = valueFormatted || value || null;
  return <MatchMark text={content ? String(content) : null} searchValue={searchValue} />;
}
