import { Card } from '@rio/ui-components';
import {
  SurveyInfoStatus,
  isReceivedSurvey,
  formatSubmissionStatus,
  getProgress,
  SurveyOverflowMenu,
  calculateResponseRate,
  StatusSpan,
} from '~/components/Surveys';
import { Survey } from '@rio/rio-types';
import React, { forwardRef, useMemo } from 'react';
import { EllipsisMenu, ListItemWrapper, ListSurvey, Virtuoso } from './styles';

type Props = {
  surveys: Survey[];
  handleSurveyClick: (id: string) => void;
};

// eslint-disable-next-line react/display-name
export const SurveysVirtualList = React.memo(({ surveys, handleSurveyClick }: Props) => {
  const gridComponents = useMemo(() => {
    const components = {
      List: forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ style, children, ...props }, ref) => (
        <ListSurvey
          ref={ref}
          {...props}
          style={{
            ...style,
          }}
        >
          {children}
        </ListSurvey>
      )),
    };

    components.List.displayName = 'List';

    return components;
  }, []);

  return (
    <Virtuoso
      totalCount={surveys.length}
      components={gridComponents}
      itemContent={(index) => (
        <ListItemWrapper key={surveys[index].id}>
          <Card
            subContent={<SurveyInfoStatus deadlineDate={surveys[index]?.deadlineDate} fill />}
            onClick={() => handleSurveyClick(surveys[index].id)}
            title={surveys[index].name}
            subTitle={
              isReceivedSurvey(surveys[index])
                ? `From ${surveys[index].owner.name}`
                : `${calculateResponseRate(surveys[index])}% response rate`
            }
          >
            <EllipsisMenu>
              {isReceivedSurvey(surveys[index]) && (
                <StatusSpan status={surveys[index].submissions[0].status}>
                  {formatSubmissionStatus(
                    surveys[index].submissions[0].status,
                    getProgress(surveys[index].template, surveys[index].submissions[0])
                  )}
                </StatusSpan>
              )}
              <SurveyOverflowMenu survey={surveys[index]} />
            </EllipsisMenu>
          </Card>
          {index === surveys.length - 1 && <div id="sentinel" />}
        </ListItemWrapper>
      )}
    />
  );
});
