import { dromoConfiguration, DromoConfiguration } from './base';
import { validateCombinedMandatoryFields, validateDependency } from './hooks';
import template from '../FuelContainer/UploadFuelTransactions/fuel-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Fuel';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getFuelConfiguration(data: any): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
    bulkRowHooks: [
      (records) => validateDependency(data?.fuelTypeQuantityUnit, ['quantity units', 'fuel type'], records),
      (records) => validateCombinedMandatoryFields([['total cost', 'currency']], records),
    ],
  };
}
