import React from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading, TextArea, Checkbox } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const KnowledgeBaseDetails = ({ heading, knowledgeBase, setKnowledgeBase }) => {
  const handleChange = (e) => {
    let newValue = e.target.value;
    if (e.target.name === 'demoMode') {
      newValue = !knowledgeBase.demoMode;
    }
    setKnowledgeBase({
      ...knowledgeBase,
      [e.target.name]: newValue
    });
  };

  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Title</LabelStyled>
          </LabelContainerStyled>
          <TextInput onChange={handleChange} name="title" value={knowledgeBase.title} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Description</LabelStyled>
          </LabelContainerStyled>
          <TextArea onChange={handleChange} name="synopsis" value={knowledgeBase.synopsis} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>External ID (Builder Public Token)</LabelStyled>
          </LabelContainerStyled>
          <TextInput onChange={handleChange} name="externalID" value={knowledgeBase.externalID} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Demo Mode (Only visible in Rio ESG owned accounts)</LabelStyled>
          </LabelContainerStyled>
          <Checkbox onChange={handleChange} name="demoMode" checked={knowledgeBase.demoMode} />
        </ColStyled>
      </Row>
    </>
  );
};

export default KnowledgeBaseDetails;
