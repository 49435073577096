import {
  Survey,
  SurveyTemplate,
  ReceivedSurvey,
  SurveySubmission,
  SurveySubmissionStatus,
  Maybe,
  SurveyAnswer,
} from '@rio/rio-types';
import { keyBy, sum, round } from 'lodash';
import moment from 'moment';
import { SurveyModel } from 'survey-core';
import type { Theme } from '@rio/ui-components';

import { SurveyJSJSON, SurveyJSAnswers, SurveyJSPage, SurveyJSElement, Nullable } from '~/types';

const flattenElements = (pages: SurveyJSPage[]) => {
  const allElements: SurveyJSElement[] = [];

  pages.forEach((page) => {
    allElements.push(...page.elements);
  });

  return allElements;
};

function transformAnswersToSurveyJSFormat(answers: SurveyAnswer[] | undefined) {
  const surveyJSAnswers: SurveyJSAnswers = {};

  answers?.forEach((answer) => {
    const questionName = answer.question.name;
    const questionAnswer = JSON.parse(answer.answer);
    surveyJSAnswers[questionName] = questionAnswer;
  });

  return surveyJSAnswers;
}

const transformSurvey = (
  template: SurveyTemplate,
  submission: Maybe<SurveySubmission> | undefined
): { surveyJSON: SurveyJSJSON; answers: SurveyJSAnswers } => {
  const templateObject = JSON.parse(template.formContent as unknown as string);

  const surveyJSON = { elements: flattenElements(templateObject.pages) || [] };
  const answers = transformAnswersToSurveyJSFormat(submission?.answers) || {};

  return { surveyJSON, answers };
};

export const getProgress = (template: SurveyTemplate, submission: Maybe<SurveySubmission> | undefined) => {
  if (!submission?.status) return 0;
  if (submission?.status !== SurveySubmissionStatus.InProgress) return 100;

  const { surveyJSON, answers } = transformSurvey(template, submission);

  if (!surveyJSON) {
    return;
  }

  const survey = new SurveyModel(surveyJSON);

  // Other options can be "pages", "questions" and "correctQuestions".
  survey.progressBarType = 'requiredQuestions';

  survey.data = answers;

  const progress = survey.getProgress();

  return progress;
};

export const calculateResponseRate = (survey: Survey): number => {
  const submissions = keyBy(survey.submissions, (c: SurveySubmission) => c.owner.id);
  const rates = survey.contributors.map(({ account }) =>
    submissions[account.id] ? getProgress(survey.template, submissions[account.id]) : 0
  );
  return survey.contributors.length ? round(sum(rates) / rates.length, 2) : 0;
};

export const getStatusColor = (theme: Theme, status?: SurveySubmissionStatus) => {
  switch (status) {
    case SurveySubmissionStatus.Approved:
      return theme.extendedColors.environmental.color;
    case SurveySubmissionStatus.InProgress:
      return theme.customColors.warning.color;
    case SurveySubmissionStatus.RequiresChanges:
    case SurveySubmissionStatus.ReadyForReview:
      return theme.customColors.warning.color;
    default:
      return theme.sys.color.error;
  }
};

export function formatSubmissionStatus(status?: SurveySubmissionStatus, percentage?: number) {
  let progressText = 'Not started';

  switch (status) {
    case SurveySubmissionStatus.RequiresChanges:
      progressText = 'Changes requested';
      break;
    case SurveySubmissionStatus.ReadyForReview:
      progressText = 'Requires approval';
      break;
    case SurveySubmissionStatus.InProgress:
      progressText = typeof percentage === 'number' ? `${percentage}% complete` : 'In progress';
      break;
    case SurveySubmissionStatus.Approved:
      progressText = 'Complete';
      break;
  }

  return progressText;
}

export const isReceivedSurvey = (survey: ReceivedSurvey | Survey): survey is ReceivedSurvey =>
  survey.__typename === 'ReceivedSurvey';

export const isWithinFiveDaysFromNow = (date: Maybe<Date> | undefined) => {
  if (!date) {
    return false;
  }
  const currentDate = moment();
  const fiveDaysFromNow = moment().add(5, 'days');
  const deadlineDate = moment(date);
  return deadlineDate.isSameOrAfter(currentDate) && deadlineDate.isBefore(fiveDaysFromNow);
};

export const isBeyondFiveDaysFromNow = (date: Maybe<Date> | undefined) => {
  if (!date) {
    return false;
  }
  const fiveDaysFromNow = moment().add(5, 'days');
  const deadlineDate = moment(date);
  return deadlineDate.isAfter(fiveDaysFromNow);
};

export const isSubmissionReviewable = (status?: SurveySubmissionStatus) =>
  !!status &&
  [
    SurveySubmissionStatus.ReadyForReview,
    SurveySubmissionStatus.RequiresChanges,
    SurveySubmissionStatus.Approved,
  ].includes(status);

export const getStatus = (deadlineDate: Nullable<Date>, theme: Theme) => {
  const deadlineHasPassed = moment(deadlineDate).isBefore(moment());
  const deadlineWithinFiveDays = isWithinFiveDaysFromNow(deadlineDate);
  const deadlineBeyondFiveDays = isBeyondFiveDaysFromNow(deadlineDate);

  let color;
  let isOverDue;

  switch (true) {
    case deadlineWithinFiveDays:
      color = theme.sys.color.error;
      isOverDue = false;
      break;
    case deadlineHasPassed:
      color = theme.sys.color.onSurface;
      isOverDue = true;
      break;
    case deadlineBeyondFiveDays:
      color = theme.sys.color.onSurface;
      isOverDue = false;
      break;
    default:
      color = theme.sys.color.primary;
      isOverDue = false;
      break;
  }

  return { color, isOverDue };
};

export function isSurvey(arg: any): arg is Survey {
  return arg.submissions !== undefined;
}

export const parseSurveyAnswer = (answer: unknown): string => {
  if (typeof answer === 'number' || typeof answer === 'boolean') {
    return answer.toString();
  }
  if (answer === null || undefined) {
    return '';
  }
  if (Array.isArray(answer)) {
    return answer.join(', ');
  }
  if (typeof answer === 'object') {
    const answers = Object.keys(answer).map((key) => `${key}: ${answer[key]}`);
    return answers.join(', ');
  }
  return answer as string;
};
