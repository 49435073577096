import { useCallback } from 'react';
import { SurveyQuestionCategoryInput, User } from '@rio/rio-types';
import { PropertySheet } from 'rio-ui-components';
import { Errors, InputEvent } from '../../../types';

interface CategoryDetailsProps {
  onChange: (payload: Partial<SurveyQuestionCategoryInput>) => void;
  category: Partial<SurveyQuestionCategoryInput>;
  owner?: User;
  errors: Errors<SurveyQuestionCategoryInput>;
  disabled?: boolean;
}

export function CategoryDetails({ category, errors, onChange, disabled }: CategoryDetailsProps) {
  const handleInputChange = useCallback((e: InputEvent) => onChange({ [e.target.name]: e.target.value }), [onChange]);
  return (
    <PropertySheet.Row container>
      <PropertySheet.TextInputColumn
        label="Name"
        name="name"
        onChange={handleInputChange}
        value={category.name}
        error={errors.name}
        disabled={disabled}
      />
    </PropertySheet.Row>
  );
}
