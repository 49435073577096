import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { hasUUID } from '../../../../utils/surveyNavigation';
import { ActionTypes } from './SurveyOverviewHeader';
import { SurveyByIdRetriever } from './SurveyByIdRetriever';
import { SurveyLatestRetriever } from './SurveyLatestRetriever';

type SurveyOverviewContainerProps = {
  action?: ActionTypes;
};

export function SurveyOverviewContainer({ action }: SurveyOverviewContainerProps) {
  const location = useLocation();
  const pathname = location.pathname;
  const pathId = pathname.split('/').pop();
  const id = useMemo(() => hasUUID(pathname) && pathId, [pathId, pathname]);

  const renderCurrentRetriever = useMemo(
    () => (id ? <SurveyByIdRetriever action={action} /> : <SurveyLatestRetriever action={action} />),
    [action, id]
  );

  return renderCurrentRetriever;
}
