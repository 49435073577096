import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'rio-ui-components';
import { getIconFromName } from './Icons';
import { Icons } from './types';
import {
  Container,
  MenuContainer,
  MenuItemContainer,
  LinkStyled,
  IconContainer,
  HideShowContainer,
  HideShowText,
  HideShowIconContainer,
} from './Elements';

interface MenuItemProps {
  iconName: Icons;
  label: string;
  to: string;
  isDisabledAccess: boolean;
  show: boolean;
}

export const MenuItem = ({ iconName, label, to, isDisabledAccess, show }: MenuItemProps) => {
  const icon = getIconFromName(iconName);
  const navigate = useNavigate();
  return (
    <MenuItemContainer onClick={() => navigate(to)}>
      <IconContainer isDisabledAccess={isDisabledAccess}>{icon}</IconContainer>
      {!show && (
        <LinkStyled to={to}>
          <FormattedMessage id={label} />
        </LinkStyled>
      )}
    </MenuItemContainer>
  );
};

const HideShowItem = ({ show, onClick }: { show: boolean; onClick: () => void }) => {
  return (
    <HideShowContainer onClick={onClick}>
      <HideShowIconContainer>
        <Icon size="0.5x" icon={show ? 'chevron-right' : 'chevron-left'} />
      </HideShowIconContainer>
      <HideShowText>{show ? 'Show' : 'Hide'}</HideShowText>
    </HideShowContainer>
  );
};

const DataOverviewNavigator = ({
  activeAccessControls,
  inactiveAccessControls,
}: {
  activeAccessControls: string[];
  inactiveAccessControls: string[];
}) => {
  const [show, switchShow] = useState<boolean>(false);
  return (
    <Container>
      <MenuContainer>
        <MenuItem
          iconName="overview"
          label="pages.data.subjects.overview"
          to="data"
          isDisabledAccess={false}
          show={show}
        />
        {activeAccessControls.map((item: string, index: number) => (
          <MenuItem
            key={`${item} - ${index}`}
            iconName={item as Icons}
            label={`pages.data.subjects.${item}`}
            to={`./${item}/view`}
            isDisabledAccess={false}
            show={show}
          />
        ))}
        {inactiveAccessControls.map((item: string, index: number) => (
          <MenuItem
            key={`${item} - ${index}`}
            iconName={item as Icons}
            label={`pages.data.subjects.${item}`}
            to="contact"
            isDisabledAccess={true}
            show={show}
          />
        ))}
      </MenuContainer>
      <HideShowItem show={show} onClick={() => switchShow(!show)} />
    </Container>
  );
};

export default DataOverviewNavigator;
