import { useLazyQuery } from '@apollo/client';
import { Query, QueryGetDataAnomaliesFilterArgs as Variables } from '@rio/rio-types';
import { GET_DATA_ANOMALIES_FILTER } from './queries';

export type GetDataAnomaliesFilterResponse = Pick<Query, 'getDataAnomaliesFilter'>;

export function useGetDataAnomaliesFilter() {
  return useLazyQuery<GetDataAnomaliesFilterResponse, Variables>(GET_DATA_ANOMALIES_FILTER, {
    fetchPolicy: 'network-only',
  });
}
