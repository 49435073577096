import { useCallback, useEffect } from 'react';
import { Checkbox, Row, TextInput } from 'rio-ui-components';
import { UseFormReturn } from 'react-hook-form';
import { Supplier, SupplierType, WasteStream, Accuracy } from '@rio/rio-types';
import { Controller } from 'react-hook-form';
import { Dictionary } from 'lodash';
import { ContainerTypeSelect } from '../ContainerTypeSelect';
import { TreatmentProcessSelect } from '../TreatmentProcessSelect';
import { NumberInput, NumberInputWithNegativeOption } from '../UI';
import { WasteStreamSelect } from '../WasteStreamSelect';
import { WasteInLocationSelect, WasteOutLocationSelect } from '../LocationSelect';
import { useCurrentAccountId } from '../../hooks';
import { SupplierSelector } from '../SuppliersSelector';
import { DataForm, SelectRender } from '../DataForm';
import { CurrencySelect } from '../CurrencySelect';
import {
  CenteredCol,
  ColStyled,
  LabelContainerStyled,
  LabelStyled,
  RowWithPadding,
  SectionStyled,
  SubHeader,
} from '../DataForm/DataFormStyles';
import { SelectEvent } from '../../types';
import { MeasurementUnitSelect } from '../MeasurementUnitSelect';

type FormData = Dictionary<any>;

interface WasteDataFormProps {
  form: UseFormReturn;
  onSubmit: (formData: FormData) => void;
  children: React.ReactNode;
}

export const WasteDataForm = (props: WasteDataFormProps) => {
  const { form, onSubmit = () => {} } = props;
  const { setValue, control, register, watch } = form;

  useEffect(() => {
    register('isFinancialDataRequired');
    register('isReferenceDataRequired');
  }, [register]);
  const accountId = useCurrentAccountId();
  const [isFinancialDataRequired, isReferenceDataRequired] = watch([
    'isFinancialDataRequired',
    'isReferenceDataRequired',
  ]);

  const setFinancialDataRequired = (value: boolean) => setValue('isFinancialDataRequired', value);
  const setReferenceDataRequired = (value: boolean) => setValue('isReferenceDataRequired', value);

  const showFinanceData = () => setFinancialDataRequired(true);
  const hideFinanceData = () => setFinancialDataRequired(false);
  const showReferenceData = () => setReferenceDataRequired(true);
  const hideReferenceData = () => setReferenceDataRequired(false);

  const handleOnSubmit = useCallback(
    ({ data, financial, reference, ...others }: FormData) => {
      const formData = {
        ...others,
        data,
      };
      if (isFinancialDataRequired) {
        formData.data = {
          ...formData.data,
          ...financial,
        };
      }
      if (isReferenceDataRequired) {
        formData.data = { ...formData.data, ...reference };
      }
      onSubmit(formData);
    },
    [onSubmit, isFinancialDataRequired, isReferenceDataRequired]
  );
  return (
    <DataForm onSubmit={handleOnSubmit} form={form}>
      <div>
        <Row>
          <SubHeader>Please enter the following mandatory transactional waste data:</SubHeader>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Waste-Out Location</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.wasteOut"
              render={({ field, fieldState }) => (
                <WasteOutLocationSelect
                  value={field.value}
                  onChange={(e: SelectEvent) => setValue('data.wasteOut', e.target.select)}
                  error={fieldState.error?.message}
                  createNewModalSize={11}
                  createNew
                />
              )}
              rules={{ required: 'Waste-Out Location is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Waste Stream</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.wasteStream"
              render={({ field, fieldState }) => (
                <WasteStreamSelect
                  createNew
                  value={field.value}
                  onChange={(s: WasteStream) => setValue('data.wasteStream', { value: s.id, label: s.name })}
                  createNewModalSize={11}
                  error={fieldState.error?.message}
                />
              )}
              rules={{ required: 'Waste Stream is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Waste-In Location</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.wasteIn"
              render={({ field, fieldState }) => (
                <WasteInLocationSelect
                  value={field.value}
                  onChange={(e: SelectEvent) => setValue('data.wasteIn', e.target.select)}
                  error={fieldState.error?.message}
                  createNewModalSize={11}
                  createNew
                />
              )}
              rules={{ required: 'Waste-In Location is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Waste Contractor</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.wasteContractor"
              render={({ field, fieldState }) => (
                <SupplierSelector
                  value={field.value}
                  onChange={(e: SelectEvent) => setValue('data.wasteContractor', e.target.select)}
                  accountId={accountId}
                  error={fieldState.error?.message}
                  filter={(s: Supplier) => s.type.includes(SupplierType.Contractor)}
                  includeEmpty
                />
              )}
              rules={{ required: 'Waste Contractor is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Waste Carrier</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.wasteCarrier"
              render={({ field }) => (
                <SupplierSelector
                  value={field.value}
                  onChange={(e: SelectEvent) => setValue('data.wasteCarrier', e.target.select)}
                  accountId={accountId}
                  filter={(s: Supplier) => s.type.includes(SupplierType.Carrier)}
                  includeEmpty
                />
              )}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Container Type Size</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.containerTypeSize"
              render={({ field, fieldState }) => (
                <ContainerTypeSelect
                  value={field.value}
                  onChange={(id, name) => setValue('data.containerTypeSize', { value: id, label: name })}
                  error={fieldState.error?.message}
                />
              )}
              rules={{ required: 'Container Type Size is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Number Of Collections</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.numberOfCollections"
              render={({ field }) => <NumberInput {...field} />}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Quantity</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.quantity"
              render={({ field, fieldState }) => (
                <NumberInputWithNegativeOption error={fieldState.error?.message} {...field} />
              )}
              rules={{ required: 'Quantity is required' }}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Unit of Weight</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.unitOfWeight"
              render={({ field, fieldState }) => (
                <MeasurementUnitSelect
                  onChange={(e: SelectEvent) => setValue('data.unitOfWeight', e.target.select)}
                  value={field.value}
                  possibleOptions={['kg', 't']}
                  error={fieldState.error?.message}
                  placeholder={'Please select unit of weight'}
                />
              )}
              rules={{ required: 'Unit of Weight is required' }}
              defaultValue={null}
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Number Of Containers</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.numberOfContainers"
              render={({ field }) => <NumberInput {...field} />}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Treatment Process</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.treatmentProcess"
              render={({ field, fieldState }) => (
                <TreatmentProcessSelect
                  value={field.value}
                  onChange={(e: SelectEvent) => setValue('data.treatmentProcess', e.target.select)}
                  error={fieldState.error?.message}
                />
              )}
              rules={{ required: 'Treatment Process is required' }}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Act/Est</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="data.accuracy"
              render={({ field, fieldState }) => (
                <SelectRender
                  {...field}
                  options={[
                    { value: Accuracy.Act, label: Accuracy.Act },
                    { value: Accuracy.Est, label: Accuracy.Est },
                  ]}
                  error={fieldState.error?.message}
                />
              )}
              rules={{ required: 'Act/Est is required' }}
              control={control}
            />
          </ColStyled>
        </Row>

        <RowWithPadding container itemAlign="center">
          <div style={{ width: '50%' }}>Do you want to add Financial data?</div>

          <div>
            <Row container align="center">
              <CenteredCol item>
                <Checkbox size="md" checked={isFinancialDataRequired} onChange={showFinanceData} />

                <LabelContainerStyled>
                  <LabelStyled>Yes</LabelStyled>
                </LabelContainerStyled>
              </CenteredCol>

              <CenteredCol item>
                <Checkbox size="md" checked={!isFinancialDataRequired} onChange={hideFinanceData} />

                <LabelContainerStyled>
                  <LabelStyled>No</LabelStyled>
                </LabelContainerStyled>
              </CenteredCol>
            </Row>
          </div>
        </RowWithPadding>

        <SectionStyled isVisible={isFinancialDataRequired}>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Gate Fee</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.gateFee"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Management Fee</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.managementFee"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Bin Hire</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.binHire"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Bin Exchange Fee</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.binExchangeFee"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Lift Charge</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.liftCharge"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Labour Cost</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.labourCost"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Equipment Hire</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.equipmentHire"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Misc Cost</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.miscCost"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Misc Cost Rate</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.miscCostRate"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Transport Charge</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.transportCharge"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Rebate</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.rebate"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Penalty</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.penalty"
                render={({ field }) => <NumberInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Currency</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.currency"
                render={({ field }) => (
                  <CurrencySelect
                    value={field.value}
                    onChange={(e: SelectEvent) => setValue('financial.currency', e.target.select)}
                  />
                )}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Notes</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.notes"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>
        </SectionStyled>

        <RowWithPadding container itemAlign="center">
          <div style={{ width: '50%' }}>Do you want to add any references?</div>

          <div>
            <Row container align="center">
              <CenteredCol item>
                <Checkbox size="md" checked={isReferenceDataRequired} onChange={showReferenceData} />

                <LabelContainerStyled>
                  <LabelStyled>Yes</LabelStyled>
                </LabelContainerStyled>
              </CenteredCol>

              <CenteredCol item>
                <Checkbox size="md" checked={!isReferenceDataRequired} onChange={hideReferenceData} />

                <LabelContainerStyled>
                  <LabelStyled>No</LabelStyled>
                </LabelContainerStyled>
              </CenteredCol>
            </Row>
          </div>
        </RowWithPadding>

        <SectionStyled isVisible={isReferenceDataRequired}>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Waste Transfer Note ID</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.wasteTransferNote"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Consignment Note (SEPA) ID </LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.consignmentNoteId"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Consignment Note (EA) ID</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.consignmentNoteEA"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Invoice Number</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.invoiceNumber"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Sales Order ID</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.salesOrder"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Reference</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.reference"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Evidence Reference</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.evidenceReference"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Other Reference</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="reference.otherReference"
                render={({ field }) => <TextInput {...field} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>
        </SectionStyled>
        {props.children}
      </div>
    </DataForm>
  );
};
