import { useApolloClient } from '@apollo/client';
import { Text, Checkbox, TextLink } from 'rio-ui-components';
import styled from 'styled-components';
import Moment from 'moment';
import Accounting from 'accounting';
import currencySymbols from './currencySymbols.json';
import { get } from 'lodash';
import { GET_DATA_BATCH_EXPORT_URL } from './index.queries';
import { downloadFileFromUrl } from '../../utils';
import { Link } from 'react-router-dom';

const ColContainer = styled.td`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.overlay.normal.background};
  border-right: 1px solid ${(p) => p.theme.colors.overlay.normal.background};
`;

const WidthDiv = styled.div`
  width: ${(props) => props.width}px;
`;

const LinkStyled = styled(Link)`
  font-family: inherit;
  display: inline-block;
  color: inherit;
  opacity: 1;
  &:hover {
    text-decoration: none;
  }
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts.sm.size};
`;

const toDate = (date) => {
  const formatted = Moment(date).local().format('DD/MM/YYYY');
  return formatted === 'Invalid date' ? '-' : formatted;
};
const toCurrency = (currency, rowData) => {
  if (rowData.currency) {
    const symbol = currencySymbols[rowData.currency] || '';
    return currency !== null ? Accounting.formatMoney(currency, symbol) : '-';
  }
  return currency !== null ? Accounting.formatMoney(currency) : '-';
};
const toNumber = (number) => (number !== null || number !== undefined ? number : '-');

const TransactionRow = ({ id, rowData, columnHeaderModel }) => {
  const client = useApolloClient();

  const onExportClick = async (batchId) => {
    const { data } = await client.query({ query: GET_DATA_BATCH_EXPORT_URL, variables: { id: batchId } });
    downloadFileFromUrl(data.getDataImportBatchExportUrl);
  };

  return (
    <tr name={`wasteTransaction-row--${id}`} data-id={id}>
      {columnHeaderModel.map((header) => {
        const data = header.field ? get(rowData, header.field) : rowData[header.id];

        if (!header.display) {
          return null;
        }

        const key = `${id}-${header.id}`;

        let child;
        switch (header.dataType) {
          case 'boolean':
            child = (
              <Checkbox
                id={`${header.id}__checkBox--${id}`}
                name={`${header.id}__checkBox--${id}`}
                size="md"
                disabled={true}
                checked={data}
              />
            );
            break;

          case 'time':
            child = <Text>{data}</Text>;
            break;

          case 'date':
            child = <Text>{toDate(data)}</Text>;
            break;

          case 'currency':
            child = <Text>{toCurrency(data, rowData)}</Text>;
            break;

          case 'number':
            child = <Text>{data ? toNumber(data) : '-'}</Text>;
            break;

          case 'linkToDocument':
            child = <LinkStyled to={`/documents/all?search=${data}`}>{data}</LinkStyled>;
            break;

          case 'uploadReference':
            child = data ? (
              <TextLink onClick={() => data && onExportClick(rowData[header.id].id)}>{data}</TextLink>
            ) : (
              <Text>-</Text>
            );
            break;

          default:
            child = <Text>{data || '-'}</Text>;
        }

        return (
          <ColContainer key={key}>
            <WidthDiv width={header.width}>{child}</WidthDiv>
          </ColContainer>
        );
      })}
    </tr>
  );
};

export default TransactionRow;
