import { useMemo, useCallback, useRef } from 'react';
import { ScopeSourceEmissions } from '@rio/rio-types';
import { styled, Text, AgGrid } from '@rio/ui-components';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { columnDefs } from './emissionColumns';

type EmissionsByScopeSourceProps = {
  emissions: ScopeSourceEmissions[];
};

const EmissionsByScopeSourceWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: 276px;

  && .ag-layout-normal {
    min-height: 100px;
  }

  & .ag-root-wrapper {
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  }

  & .ag-row-footer > div:first-child:after {
    content: 'Total';
    font-family: ${(p) => p.theme.sys.typescale.label.medium.family};
    font-size: ${(p) => p.theme.sys.typescale.label.medium.size}px;
    color: ${({ theme }) => theme.sys.color.onSurface};
    font-weight: 600;
  }
`;

export const EmissionsByScopeSource = ({ emissions }: EmissionsByScopeSourceProps) => {
  const gridApiRef = useRef<GridApi>();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApiRef.current = event.api;
  }, []);

  const gridOptions = useMemo(
    () => ({
      onGridReady: handleGridReady,
      rowData: emissions,
      columnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
      },
      grandTotalRow: 'bottom',
      groupIncludeTotalFooter: true,
    }),
    [emissions, handleGridReady]
  );

  return (
    <EmissionsByScopeSourceWrapperStyled>
      <Text typescale="label" size="small">
        Emissions by scope & source
      </Text>
      <AgGridStyled gridKey="emissionsByScopeSourceGridV2" gridOptions={gridOptions} />
    </EmissionsByScopeSourceWrapperStyled>
  );
};
