import React from 'react';
import styled from 'styled-components';
import InitialiseScormApi from './InitialiseScormApi';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { getAllCourseData } from './index.queries';
import jwt_decode from 'jwt-decode';
import { NOTSTARTED } from '../../constants/courseProgress';
import { LoadFailed } from '../Errors';

const StyledIFrame = styled.iframe`
  padding-top: 50px;
  width: 100%;
  height: 100%;
`;

const LearnEmbeddedScormViewer = ({ course, link, client, courseRefetch, onCompleted }) => {
  const currentCourseProgress = course.userCourseMetaData ? course.userCourseMetaData.courseProgress : NOTSTARTED;
  const variables = { courseId: course.id };
  return (
    <Query query={getAllCourseData} variables={variables} fetchPolicy="network-only">
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }
        if (error) {
          return <LoadFailed error={error} retry={() => refetch(variables)} />;
        }

        const ScormApi = new InitialiseScormApi(data.getAllCourseData);
        ScormApi.initialise(
          client,
          course.id,
          course.name,
          course.metaData.cpd,
          course.metaData.passScore,
          getUserName(),
          currentCourseProgress,
          getUserId(),
          courseRefetch,
          onCompleted
        );
        return <StyledIFrame src={link} />;
      }}
    </Query>
  );
};

const getUserName = () => {
  const idToken = localStorage.getItem('ID_TOKEN');
  const { given_name, family_name } = jwt_decode(idToken);
  return `${given_name} ${family_name}`;
};

const getUserId = () => {
  const idToken = localStorage.getItem('ID_TOKEN');
  const { sub } = jwt_decode(idToken);
  return sub;
};

export default withApollo(LearnEmbeddedScormViewer);
