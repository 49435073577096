import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [
  { label: 'Data', to: `..` },
  { label: 'Materials', to: '/data/materials/view' },
  { label: 'Uploads' },
];

export const V2MaterialsUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      gridKey="materialsUploadGridV2"
      title="Here's your upload history"
      breadcrumbs={breadcrumbs}
      uploadLink="../materials/uploads"
      config={config}
      dataType={TransactionType.Materials}
    />
  );
};
