export function getContainerTypeVolumes(containerTypes) {
  const typeSizes = [];

  for (const containerType of containerTypes) {
    for (const size of containerType.sizes) {
      typeSizes.push({
        id: `${containerType.id}_${size.id}`,
        name: containerType.name,
        volume: size.volume,
        unit: size.volumeUnit ? size.volumeUnit.name : '-'
      });
    }
  }
  return typeSizes;
}
