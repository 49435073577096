import { ConfigurationAliases } from './index';
import { AliasType } from '@rio/rio-types';

const AliasTypes = [
  { aliasType: AliasType.Meter, energyType: 'ELECTRICITY', label: 'Electricity' },
  { aliasType: AliasType.Meter, energyType: 'GAS', label: 'Gas' },
  { aliasType: AliasType.Meter, energyType: 'WATER', label: 'Water' }
];

function ConfigurationAliasesMeters({ accountId }) {
  return <ConfigurationAliases accountId={accountId} aliasTypes={AliasTypes} />;
}
export default ConfigurationAliasesMeters;
