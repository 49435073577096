import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { UploadReferenceCell, DocumentReference } from '../../CellRenderers/index';
import { GET_WATER_TRANSACTIONS_PAGE } from './index.queries';
import { useWaterGridConfig } from './useWaterGridConfig';
import { FactorUsedCell } from '../../CellRenderers';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Water Transactions' }];

export function WaterTransactionsContainer() {
  const client = useApolloClient();
  const config = useWaterGridConfig();

  return (
    <TransactionGrid
      title="Water Transactions"
      defaultExportFileName="water-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../water/uploads"
      config={{
        ...config,
        components: {
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={async (variables: object) => {
        const {
          data: {
            getWaterTransactionPage: { totalRows, rows },
          },
        } = await client.query({
          query: GET_WATER_TRANSACTIONS_PAGE,
          variables,
          fetchPolicy: 'network-only',
        });
        return {
          rows,
          totalRows,
        };
      }}
    />
  );
}
