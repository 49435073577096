import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PropertySheet } from 'rio-ui-components';
import { SurveyQuestionCategoryInput } from '@rio/rio-types';
import { validate } from './validate';
import { Errors } from '../../../types';
import { useNotification } from '../../../hooks/useNotification';
import { useCreateSurveyQuestionCategory } from './queries';

const Container = styled.div<{ name: string }>`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const StyledTextColumn = styled(PropertySheet.TextColumn)`
  align-self: center;
`;

interface CreateFormCategoryProps {
  onCompleted: () => void;
  CategoryDetails: any;
  buttonDescription?: string;
}

export function CreateCategory({ onCompleted, buttonDescription, CategoryDetails }: CreateFormCategoryProps) {
  const [category, setCategory] = useState<Partial<SurveyQuestionCategoryInput>>({
    name: ''
  });
  const [errors, setErrors] = useState<Errors<SurveyQuestionCategoryInput>>({});
  const { showNotification } = useNotification();

  const [createCategory, { loading }] = useCreateSurveyQuestionCategory();

  const handleChange = useCallback((payload: Partial<SurveyQuestionCategoryInput>) => {
    setCategory((t) => ({
      ...t,
      ...payload
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [errors, validCategory] = validate(category);
      if (errors) {
        return setErrors((e) => ({
          ...e,
          ...errors
        }));
      }
      await createCategory({
        variables: { category: validCategory },
        onCompleted,
        onError: (e: Error) => {
          showNotification(e.message, 'danger');
        }
      });
    },
    [category, createCategory, onCompleted]
  );

  return (
    <Container name="CreateCategory">
      <PropertySheet onSubmit={handleSubmit}>
        <PropertySheet.HeaderRow>Add Survey Category</PropertySheet.HeaderRow>
        <CategoryDetails onChange={handleChange} category={category} errors={errors} disabled={loading} />
        <PropertySheet.ButtonColumn disabled={loading} onClick={handleSubmit} name="CreateCategory__SubmitButton">
          Add Category
        </PropertySheet.ButtonColumn>
        <PropertySheet.Row>
          <StyledTextColumn span={12} size="xs" color={'normal'}>
            {buttonDescription}
          </StyledTextColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
}
