import { useApolloClient } from '@apollo/client';
import { useCurrentAccountId, useNotification } from '../../../hooks';
import { gridValueFormatter } from '../../../utils';
import { GET_USERS_FILTER } from './index.queries';
import { ColDef, GridOptions, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { Dictionary } from '../../../types';
import { formatDatetime, formatDuration } from '../../../utils/formatDate';
import { useCallback, useMemo } from 'react';

export const userTypeValueFormatter = ({ value, context: { roles } }: ValueFormatterParams) => {
  return value && roles[value];
};

const valueFormatterStatus = ({ value }: { value: boolean }) => (value ? 'Deactivated' : 'Active');

const valueFormatterFilter = ({ value }: { value: any }) => {
  const parsedValue = JSON.parse(value);
  const { label } = parsedValue;
  return valueFormatterStatus({ value: JSON.parse(label) });
};

export const userTypeFilterGridValueFormatter = ({ value, context: { roles } }: ValueFormatterParams) => {
  if (!value) {
    return value;
  }

  try {
    const { label } = JSON.parse(value);
    return label && roles[label];
  } catch (err) {
    return value;
  }
};

export function useUserGridConfig(roles: Dictionary<string>) {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const field = params.colDef.colId || params.colDef.field;
        const {
          data: { getUsersFilter: values },
        } = await client.query({
          query: GET_USERS_FILTER,
          variables: {
            accountId,
            field,
          },
        });

        let updatedValues = [...values];

        if (field === 'department') {
          updatedValues = values.map((item: any) => ({ ...item, label: item.value }));
        }

        params.success(updatedValues.map((item: any) => (item.value ? JSON.stringify(item) : item.value)));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [accountId, client, showNotification]
  );

  const config: GridOptions = useMemo(
    () => ({
      context: {
        roles,
      },
      columnDefs: [
        {
          headerName: 'First Name',
          field: 'firstName',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Last Name',
          field: 'lastName',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Account',
          field: 'accountName',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: 'agTextColumnFilter',
          cellStyle: {
            'text-overflow': 'ellipsis',
            overflow: 'hidden',
            'white-space': 'nowrap',
          },
        },
        {
          headerName: 'User Type',
          field: 'role',
          filterParams: {
            values: getFilterValues,
            valueFormatter: (params: ValueFormatterParams) => userTypeFilterGridValueFormatter(params),
          },
          valueFormatter: (params: ValueFormatterParams) => userTypeValueFormatter(params),
        },
        {
          headerName: 'Tags',
          field: 'tags',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Locations',
          field: 'locations',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Last Logged in',
          field: 'lastActiveAt',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }: { value: Date }) => formatDatetime(value),
          cellStyle: { textAlign: 'center' },
          initialSort: 'desc',
        } as ColDef,
        {
          headerName: 'Total Time in System',
          field: 'totalTimeSpentMs',
          filter: 'agNumberColumnFilter',
          valueFormatter: ({ value }: { value: number }) => formatDuration(value),
        },
        {
          headerName: 'Department',
          field: 'department',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Status',
          field: 'isDisabled',
          valueFormatter: valueFormatterStatus,
          filterParams: {
            values: getFilterValues,
            valueFormatter: valueFormatterFilter,
          },
        },
        {
          headerName: 'Actions',
          cellRenderer: 'actions',
          field: 'readonly',
          sortable: false,
          filter: false,
          cellStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          },
        } as ColDef,
      ],
    }),
    [getFilterValues, roles]
  );

  return config;
}
