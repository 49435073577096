import { gql } from "@apollo/client";

export const GET_ALL_UNIT_TYPES = gql`
  query getAllUnits {
    getAllUnits {
      id
      name
    }
  }
`;

