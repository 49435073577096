export const it = {
  home: {
    title: 'Registrato in home page',
    subtitle: 'Questo è un semplice testo localizzato',
    lastCommit: 'Ultimo commit {commitId} da {autore} a {timestamp}',
    logoutButtonLabel: 'Log out',
    myProfile: 'Il mio profilo',
    contact: 'Contattaci',
    search: 'Cerca',
  },
  marketingHome: {
    title: 'Registrati home page',
    loginButtonLabel: 'Log in',
  },
  login: {
    registration: {
      mainPage: {
        title: 'Benvenuto su Rio!',
        subtitle: 'Per cominciare, per favore compila i campi sottostanti per creare un account',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        confirmPasswordPlaceholder: 'Conferma la password',
        nextButtonText: 'Successivo',
        submitButton: 'Imposta password',
      },
      detailsPage: {
        title: 'Ora dicci di più su di te',
        firstName: 'Nome',
        lastName: 'Cognome',
        companyName: 'Azienda',
        personalUse: 'Questo account è per uso personale',
        backButtonText: 'Torna alle credenziali',
        nextButtonText: 'Successivo',
      },
      contactPage: {
        title: 'Come possiamo contattarti?',
        jobTitle: 'Professione (opzionale)',
        department: 'Settore (opzionale)',
        phoneNumber: 'Telefono fisso (opzionale)',
        mobileNumber: 'Cellulare (opzionale)',
        backButtonText: 'Torna al profilo',
        finishButtonText: 'Termina',
      },
    },
    sidebar: {
      title: 'Riepilogo Account',
      step1: 'Su di Te',
      step2: 'il Tuo Piano',
      step3: 'il Tuo Abbonamento',
      trusted: 'di FIDUCIA DA',
    },
    main: {
      title: 'Bentornato!',
      subheading: 'Si prega di accedere al tuo account.',
      emailPlaceholder: 'Indirizzo email',
      emailError: 'si Prega di inserire un indirizzo email valido',
      passwordPlaceholder: 'Password',
      passwordError: 'inserire una password valida',
      rememberLabel: 'Ricordati di me',
      loginButton: 'Login',
      loginButtonLoading: 'Attendi',
      loginButtonSSO: 'Login with {provider}',
      userNotFound:
        'Il nome utente e la password che hai inserito non corrispondono. Si prega di controllare e provare di nuovo.',
      incorrectPassword:
        'Le credenziali inserite non sono corrette. Dopo 5 tentativi falliti, il tuo account sarà sospeso.',
      userDisabled: 'Your account è stato disabilitato. Si prega di contattare un administrator.',
      generalError: 'Something sembra essere andato storto. Si prega di aggiornare e provare di nuovo.',
      forgotPassword: 'Password dimenticata',
      newRioUser: 'Nuovo utente? Registrati qui',
      providerNotFound: 'Provider not found, verify browser url.',
    },
    setPassword: {
      title: 'Cambia la tua password.',
      subheading: 'Per cortesia, imposta la tua nuova password.',
      oldPassword: 'Password temporanea',
      passwordPlaceholder: 'Nuova password',
      confirmPasswordPlaceholder: 'Conferma password',
      submitButton: 'Imposta password',
      userNotFound: 'Utente non trovato. Si prega di aggiornare e provare again.',
      incorrectPassword: 'Password errata. Si prega di aggiornare e provare again.',
      generalError: 'Something sembra essere andato storto. Si prega di aggiornare e provare di nuovo.',
    },
    resetPassword: {
      title: 'Reimpostare la password.',
      subheading: 'Reimposta la password per continuare.',
      emailPlaceholder: 'Indirizzo email',
      codePlaceholder: 'codice di Reset',
      passwordPlaceholder: 'Nuova password',
      confirmPasswordPlaceholder: 'Conferma password',
      submitButton: 'Resetta la password',
      buttonSubmitting: 'Attendi',
      userNotFound: 'Utente non trovato. Si prega di aggiornare e riprovare.',
      incorrectPassword: 'Password errata. Si prega di aggiornare e provare again.',
      confirmationCodeRequired: 'È richiesto il codice di conferma.',
      invalidCode: 'È stato fornito un codice non valido, torna indietro e richiedi un nuovo codice.',
      generalError: 'Something sembra essere andato storto. Si prega di aggiornare e provare di nuovo.',
      limitExceeded: 'Limite superato, riprova più tardi.',
      expiredCode: 'È stato fornito un codice non valido, torna indietro e richiedi un nuovo codice.',
      noCodeEntered: 'Inserisci il codice di conferma: puoi trovarlo nella tua email.',
      noUsernameEntered: "Inserisci l'e-mail dell'account per il quale hai richiesto la reimpostazione della password.",
      passwordsDontMatch: 'Le passwords non corrispondono.',
    },
    forgotPassword: {
      success: {
        title: 'Reimpostazione della Password inviata',
        subheading: 'per Favore controlla la tua e-mail per la reimpostazione della password codice',
      },
      title: 'Hai dimenticato la password?',
      subheading: 'Inserisci la tua mail qui e ti invieremo un codice per reimpostarla.',
      usernamePlaceholder: 'Indirizzo email',
      submitButton: 'Invia codice',
      buttonSubmitting: 'Attendi',
      goToResetButton: 'Inserisci il tuo codice di reset',
    },
    termsAgreement: {
      termsAgreementText: 'Accetto i ',
      termsLinkText: 'Termini di {terms} di Rio ESG LTD',
      service: 'servizio',
      use: 'utilizzo',
    },
    errors: {
      existAccount: 'Esiste già un account associato alla mail {email}',
      changeEmail: 'Cambia indirizzo email',
      validation: {
        missingEmail: 'Inserisci un indirizzo email',
        invalidEmail: `L’indirizzo che hai inserito non è valido`,
        missingPassword: 'Inserisci una password',
        confirmPassword: 'Conferma la tua password',
        mismatchPassword: `Le password non corrispondono`,
        passwordPattern: 'La password deve contenere almeno 10 caratteri e non devono esserci spazi vuoti',
        companyName: `Inserisci il nome dell’azienda`,
        firstName: `Inserisci il tuo nome`,
        lastName: 'Inserisci il tuo cognome',
      },
    },
  },
  sidebar: {
    homepage: 'Home',
    learn: 'Formazione',
    governance: 'Conformità',
    data: 'Dati',
    surveys: 'Sondaggi',
    reporting: 'Report',
    documents: 'Archivio',
    intelligence: 'Consulente IA',
    flows: 'Flussi',
    configuration: 'Configurazione',
    tasks: 'Compiti',
    support: 'Supporto',
  },
  role: {
    SYSADMIN: 'Sistema Admin',
    BUSINESSADMIN: 'Business Admin',
    BUSINESS: 'Utente Aziendale',
  },
  pages: {
    support: {
      contactInformation: 'Informazioni di contatto',
      header: 'Inviaci un messaggio',
      fullName: 'Nome',
      companyName: 'Azienda',
      phoneNumber: 'Telefono',
      enquiryQuestion: 'Cosa riguarda la tua richiesta?',
      enquiry: 'Richiesta',
      enquirySelectPlaceholder: 'Per favore, scegli',
      enquiryPlaceholder: 'Digita il tuo messaggio...',
      send: 'Invia richiesta',
      banner: {
        header: 'Ti serve aiuto?',
        message: 'Controlla la nostra {Link} per consigli e FAQ (in inglese).',
        link: 'pagina di supporto',
      },
    },
    home: {
      welcome: 'Benvenuto [FIRSTNAME], ecco un paio di suggerimenti...',
      guidanceText: 'Clicca su «Formazione» nel menu a sinistra per vedere tutti i corsi disponibili.',
      dashboard: {
        learn: 'Imparare',
        governance: 'Rispettare',
        analyse: 'Analizzare',
      },
      engageTasksStatuses: {
        incomplete: 'Non completato',
        completed: 'Completato',
      },
    },
    learn: {
      heading: 'Formazione',
      breadcrumbs: {
        one: 'Formazione',
        two: 'Corsi',
      },
      banners: {
        explore: 'Esplora',
        continue: 'Continua',
        completed: 'Completato',
      },
      viewLink: 'Vedi tutti ({amount} corsi)',
    },
    coursesPreview: {
      breadcrumbs: {
        one: 'Formazione',
        two: 'Anteprima del corso',
      },
      backToEngage: 'Torna alla pagina Formazione',
      startCourse: 'Inizia il corso',
      statuses: {
        embed: {
          inprogress: 'Hai già iniziato questo corso.',
        },
      },
      relatedCourses: 'Corsi correlati',
      noResults: 'Non ci sono contenuti correlati. Esplora altri argomenti dalla pagina Formazione',
    },
    governance: {
      heading: 'Libreria',
      breadcrumbs: {
        one: 'Rispettare',
        two: 'Libreria',
      },
    },
    surveys: {
      nav: {
        heading: 'Sondaggi',
      },
      subjects: {
        surveysSent: 'Sondaggi inviati',
        contributors: 'Contributori',
        surveys: 'Tutti i sondaggi',
        overview: 'Panoramica',
        surveysReceived: 'Sondaggi ricevute',
        received: 'Ricevute',
      },
    },
    data: {
      heading: 'Dati',
      breadcrumbs: {
        one: 'Dati',
        two: 'Soggetti',
      },
      subjects: {
        wasteAlias: 'Rifiuti e luoghi dei rifiuti',
        waste: 'Rifiuti',
        electricity: 'Elettricità',
        gas: 'Gas',
        fuel: 'Carburante',
        water: 'Acqua',
        materials: 'Materiali',
        transport: 'Trasporto',
        refrigerants: 'Refrigeranti',
        heatSteam: 'Calore e Vapore',
        buildingEnergyRatings: 'Classificazioni energetiche degli edifici',
        hotelStays: 'Soggiorni in hotel e lavoro a domicilio',
        location: 'Posizione',
        overview: 'Panoramica',
        investment: 'Transazioni di investimento',
        fundsAssets: 'Fondi e Asset',
      },
      overview: {
        upgrade: 'Aggiornamento',
        collect: 'RACCOGLIERE',
        about: 'Informazioni sulla raccolta dei dati',
        dataCollection: 'Raccolta Dati',
        dataNotifications: 'Notifiche dei dati',
        dataCompleteness: 'Completezza dei dati',
        dataQuality: 'Qualità dei dati',
        missingTransactions: 'Dati mancanti e transazioni vuote',
        pendingTransactions: "transazioni sono in sospeso e richiedono l'approvazione'",
        upload: 'Caricamento',
        uploadHeader: 'Caricamenti rapidi dei dati',
        uploadText: 'Caricare dati reali sulla sostenibilità è il modo migliore per valutare il tuo impatto',
        utilities: 'Utilità',
        latestTransactions: 'Ultime transazioni',
        utilityCol: 'Utilità',
        locationCol: 'Posizione/Fornitore',
        dateCol: 'Data',
        uploadedCol: 'Caricato',
        accept: 'Accettare',
        acceptInfo: 'Questo cancella gli elementi da questa sezione',
        clear: 'Questo cancella tutti i filtri selezionati',
        deselectAll: 'Ciò deseleziona tutte le opzioni nella pagina corrente',
        deselectAllButtonText: 'Deselezionare tutto',
        clearButtonText: 'Filtri chiari',
        confirmationDialog: {
          title: 'Sei sicuro di voler accettare e cancellare questi elementi?',
          agree: 'Sì, accetta',
          disagree: 'No, tienili visibili',
        },
      },
    },
    reporting: {
      heading: 'Segnalazione',
      breadcrumbs: {
        one: 'Segnalazione',
        two: 'Rapporti',
      },
    },
    intelligence: {
      heading: 'Le Basi Di Conoscenza',
      breadcrumbs: {
        one: 'Intelligenza',
        two: 'Basi di Conoscenza',
      },
    },
    documents: {
      heading: 'Libreria',
      breadcrumbs: {
        one: 'Documenti',
        two: 'Libreria',
      },
    },
    configuration: {
      account: {
        sections: {
          data: {
            name: 'Dati',
            buildingEnergyRatings: `Classificazione energetica dell'edificio`,
            electricity: 'Elettricità',
            fuel: 'Carburante',
            gas: 'Gas',
            heatSteam: 'Calore e vapore',
            hotelStays: 'Soggiorni in hotel',
            materials: 'Materiali',
            refrigerants: 'Refrigeranti',
            transport: 'Trasporto',
            waste: 'Rifiuti',
            water: 'Acqua',
            investment: 'Transazioni di investimento',
          },
          governance: {
            name: 'Governance',
            agreements: 'Accordi',
            frameworks: 'Quadri',
            legislation: 'Legislazione',
            policies: 'Politiche',
            systems: 'Sistemi',
            tagLevelLegislation: 'Legislazione a livello di tag',
            targets: 'Obiettivi',
          },
          other: {
            name: 'Altro',
            chatBot: 'Bot di chat',
            dromoUpload: 'Dromo Caricamento',
            dataTracker: 'Localizzatore di dati',
            engage: 'Coinvolgere',
            flows: 'Flussi',
            intelligence: 'Intelligenza',
            projects: 'Progetti',
            surveys: 'Sondaggi',
            tasks: 'Attività',
          },
        },
      },
      nav: {
        heading: 'Configurazione',
        accounts: 'Acconto',
        dataTrackerConfig: {
          missingThresholds: 'Soglie mancanti',
          anomaliesThresholds: 'Soglie di anomalie',
        },
        contractors: 'Appaltatori',
        dashboards: 'Cruscotti',
        dashboardSubscriptions: 'Cruscotto Abbonamenti',
        meters: 'Contatori Di Energia',
        learnContent: 'Imparare Contenuti',
        learnSubscriptions: 'Imparare Abbonamenti',
        evaluations: 'Valutazioni',
        locations: 'Posizioni',
        suppliers: 'Fornitori',
        tags: 'Etichetta',
        users: 'Utenti',
        wasteStreams: 'Flussi Di Rifiuti',
        wasteHierarchies: 'Rifiuti Gerarchie',
        carbonConversionFactors: 'Fattori di conversione del carbonio',
        marketBased: 'Basato sulla mercato',
        locationBased: 'Basato sulla posizione',
        containerTypes: 'Tipi di container',
        others: 'Altri',
        schemes: 'Schemi',
        formTemplates: 'Modelli di modulo',
        formSubscriptions: 'Sottoscrizioni di moduli',
        questionsCategories: 'Categorie di domande',
        questions: 'domande',
        flows: {
          configuration: 'Configurazione',
          subscriptions: 'Sottoscriziones',
        },
        assetManagement: 'Risorse',
        funds: 'Fondi',
        engageBanners: 'Striscioni',
      },
      dataTracker: {
        container: {
          add: '+ Aggiungi configurazione Data Tracker',
          header: 'Data Tracker',
        },
        modal: {
          account: 'Account',
          thresholdPercentage: 'Percentuale di soglia',
          supplier: 'Fornitore',
          frequency: 'Frequenza traguardo',
          recurrence: 'Ricorrenza',
          locations: 'Località/i (opzionale)',
          meters: 'Meters (opzionale)',
          create: 'Creare',
          update: 'Aggiorna',
          createHeader: 'Crea configurazione Data Tracker',
          updateHeader: 'Aggiorna configurazione Data Tracker',
        },
      },
    },
    task: {
      grid: {
        header: {
          task: 'Compito',
          category: 'Categoria',
          subject: 'Soggetto',
          item: 'Oggetto oggetto',
          owners: 'Assegnatari',
          creator: 'Creatore',
          date: 'Scadenza',
          completedDate: 'Data di completamento',
          link: "Vai all'attività",
          project: 'Progetto',
        },
        column: {
          emptyOwners: 'Non assegnato',
        },
      },
      details: {
        name: 'Nome Compito',
        description: 'Descrizione',
        creator: 'Creatore',
        createdAt: 'Creato a',
        impact: "L'ambito del progetto dell'area sta avendo un impatto",
        locations: 'Località/i',
        priority: 'Priorità',
        meters: 'Contatori Di Energia',
        category: 'Categoria',
        scope: 'Scopo',
        owner: 'Proprietari',
        relatedItem: 'Related item',
        tags: 'Etichetta',
        isCompleted: 'Completato',
        completedAt: 'Data di completamento',
        subject: 'Soggetto',
        dueDate: 'Scadenza',
        project: 'Progetto',
        save: 'Salva',
        delete: 'Elimina',
        cancel: 'Cancellare',
        evidence: 'Evidenza',
        status: 'Stato',
        incomplete: 'Non completato',
        completed: 'Completato',
      },
    },
    project: {
      grid: {
        header: {
          name: 'Nome del progetto',
          impact: 'Impatto',
          description: 'Descrizione',
          locations: 'Località',
          category: 'Categoria',
          scheme: 'Schema',
          meters: 'Сontatori',
          owners: 'Proprietari',
          cost: 'Costo del progetto',
          implementationDate: 'Data di attuazione',
          baselineDate: 'Anno di riferimento',
          benefitsDate: 'Data di realizzazione dei benefici',
          plannedBenefit: "Beneficio pianificato totale rispetto al CONSUMO dell'anno di riferimento",
          plannedCostChange: "Risparmio sui costi pianificato totale rispetto al COST dell'anno di riferimento",
          plannedCO2eChange: "Risparmio di carbonio totale pianificato rispetto all'anno di riferimento CARBONIO",
          frequency: 'Frequenza traguardo',
          notes: 'Note',
          evidence: 'Prove',
          action: 'Azione',
        },
        value: {
          emptyOwners: 'Non assegnato',
        },
      },
      create: {
        header: 'Crea nuovo Progetto',
        submit: 'Invia',
      },
      details: {
        name: 'Nome Progetto',
        description: 'Descrizione',
        impact: "L'ambito del progetto dell'area sta avendo un impatto",
        locations: 'Località/i',
        priority: 'Priorità',
        category: 'Categoria',
        scheme: 'Schema',
        owner: 'Proprietari',
        cost: 'Progetto Costo (£)',
        currency: 'Valuta',
        startDate: 'Data di implementazione (data di inizio)',
        endDate: 'Data per il raggiungimento dei benefici pianificati (Data di fine)',
        baselineStartDate: "Data di inizio dell'anno di riferimento",
        plannedBenefit: 'Azione pianificata (consumo)',
        plannedBenefitGeneration: 'Azione pianificata (Generazione kwh)',
        plannedCarbonChange: 'Benefit previsto (carbonio)',
        plannedCostChange: 'Vantaggio pianificato (costo risparmiato)',
        frequency: 'Frequenza del traguardo',
        tasks: 'Compiti',
        addNewTask: 'Aggiungi una nuova attività',
        save: 'Salva',
        delete: 'Elimina',
        cancel: 'Cancellare',
        noTasks: 'Non ci sono azioni relative a questo progetto.',
        evidence: 'Evidenza',
      },
      messages: {
        created: 'Il progetto è stato creato.',
        error: 'Qualcosa è andato storto! Per favore riprova più tardi.',
      },
    },
    dataAnomalies: {
      transactionTypes: {
        ELECTRICITY: 'Elettricità',
        FUEL: 'Carburante',
        GAS: 'Gas',
        MATERIALS: 'Materiali',
        TRANSPORT: 'Trasporto',
        WASTE: 'Rifiuti',
        WATER: 'Acqua',
        HOTELSTAYS: 'Soggiorni in hotel e lavoro a domicilio',
        HEATSTEAM: 'Calore e vapore',
        ElectricityDataAnomaly: 'Elettricità',
        FuelDataAnomaly: 'Carburante',
        GasDataAnomaly: 'Gas',
        MaterialDataAnomaly: 'Materiali',
        TransportDataAnomaly: 'Trasporto',
        WasteDataAnomaly: 'Rifiuti',
        WaterDataAnomaly: 'Acqua',
        HotelStayDataAnomaly: 'Soggiorni in hotel e lavoro a domicilio',
        HeatAndSteamDataAnomaly: 'Calore e vapore',
        RefrigerantDataAnomaly: 'Refrigeranti',
      },
    },
  },
  profile: {
    editProfilePicture: 'Modifica immagine del profilo',
    firstName: 'Nome',
    lastName: 'Cognome',
    jobTitle: 'Titolo professionale (opzionale)',
    department: 'Settore (opzionale)',
    phoneNumber: 'Telefono (opzionale)',
    mobileNumber: 'Cellulare (opzionale)',
    emailAddress: 'Indirizzo  email',
    update: 'Aggiorna',
    userHaveBeenUpdated: "Dati del'utente aggiornati con successo.",
  },
  components: {
    selectPlaceholder: 'Seleciona',
  },
  learnTour: {
    next: 'Successivo',
    skip: 'Salta',
    close: 'Ho capito!',
    back: 'Indietro',
    letsGo: 'Andiamo!',
    preview: {
      item1: {
        title: 'Inizia…',
        content: 'Clicca su Inizia il Corso, e buon proseguimento!',
      },
    },
    home: {
      item1: {
        title: 'Benvenuto {name},',
        content: 'Rendi il mondo più sostenibile con Rio. Ecco come…',
      },
    },
    explore: {
      item1: {
        title: 'Continua ad imparare',
        content: 'Esplora i nostri contenuti realizzati da persone esperte in sostenibilità',
      },
      item2: {
        title: 'Scopri di più',
        content: 'Clicca il titolo per saperne di più',
      },
    },
  },
};
