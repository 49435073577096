import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import partition from 'lodash/partition';
import { getAllFilters } from '../utils';
import { useIntl } from 'react-intl';
import { Col, Button, Notification } from 'rio-ui-components';
import { useGetDocuments } from '../useGetDocuments';
import { GET_MANAGEMENT_SYSTEM_BY_ID_WITH_SIBLINGS } from '../index.queries';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import PageHeader from '../../../components/PageHeader';
import { DocumentExplorer } from '../DocumentExplorer';
import { useSearch } from '../../../hooks/useSearch';
import {
  LIBRARY,
  CATEGORY,
  createManagementSystemTree,
  mapChapterToFolder,
  getFoldersAndFilesFromManagementSystem,
  createBreadcrumbsForManagementSystem,
} from './utils';
import { AttachDocumentsModal } from './AttachDocumentsModal';
import { usePermissions } from '../../../hooks/usePermissions';

export function ManagementSystem() {
  const intl = useIntl();
  const { id, itemId } = useParams();
  const { pathname } = useLocation();
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { data, loading, error, refetch } = useQuery(GET_MANAGEMENT_SYSTEM_BY_ID_WITH_SIBLINGS, {
    variables: {
      id,
      accountId,
    },
    partialRefetch: true,
    returnPartialData: true,
    errorPolicy: 'all',
  });
  const retry = () => refetch({ id, accountId });
  const managementSystem = data?.getManagementSystemById;
  const title = managementSystem?.title || 'Management System';
  const { tree } = createManagementSystemTree(managementSystem);
  const { folders } = getFoldersAndFilesFromManagementSystem(managementSystem, tree, itemId, pathname);
  const allChapters = Object.values(tree);
  const allFolders = allChapters.map((chapter) => mapChapterToFolder(chapter, pathname, itemId));
  const [searchValue, setSearchValue, searchResult] = useSearch(allFolders, [
    'name',
    'title',
    'referenceId',
    'managementSystemCode',
  ]);
  const [, searchedFolders] = partition(searchResult, (result) => result.__typename === 'DocumentRecord');
  const foldersToShow = searchValue ? searchedFolders : folders;
  const breadcrumbs = createBreadcrumbsForManagementSystem(intl, managementSystem, tree, tree[itemId]);
  const siblings = (itemId ? tree[itemId]?.siblings : data?.getAvailableManagementSystems) || [];
  const navigationDropdownDefault = (itemId ? tree[itemId]?.tocTitle : title) || '';
  const siblingFolders =
    siblings.map((sibling) => ({
      id: sibling.id,
      title: sibling.tocTitle || sibling.title,
      filterValue: '',
      link: sibling.id,
      icon: 'folder',
      libraries: [],
    })) || [];

  const variables = {
    accountId,
    filters: getAllFilters(searchValue, LIBRARY, CATEGORY, id, itemId || null),
  };
  const { documentsExist } = useGetDocuments(variables);

  return (
    <Col container fullHeight>
      <PageHeader
        title={title}
        icon="file-alt"
        iconColor="quaternary"
        breadcrumbs={breadcrumbs}
        dropdownItems={siblingFolders}
        dropdownDefault={navigationDropdownDefault}
        isDropdown
      >
        {!!permissions.governance.find((action) => action.startsWith('attachDocumentToManagementSystem')) && (
          <Button color="primary" onClick={() => setShowAttachModal(true)} inline>
            Attach Documents
          </Button>
        )}
      </PageHeader>
      <DocumentExplorer
        folders={foldersToShow}
        library={LIBRARY}
        category={CATEGORY}
        isManagementSystem
        documentsExist={documentsExist}
        searchValue={searchValue}
        onSearch={setSearchValue}
        loading={loading}
        basePath="/documents/all"
        error={error}
        retry={retry}
        managementSystemId={id}
        managementSystemChapterId={itemId || null}
      />
      {showAttachModal && (
        <AttachDocumentsModal
          managementSystem={managementSystem}
          chapter={tree[itemId]}
          onComplete={(message) => {
            setNotifications((n) =>
              n.concat([
                {
                  message,
                  color: 'success',
                  id: Date.now(),
                },
              ])
            );
          }}
          onError={(err) => {
            setNotifications((n) =>
              n.concat([
                {
                  message: err.message,
                  color: 'danger',
                  id: Date.now(),
                },
              ])
            );
          }}
          onDismiss={() => setShowAttachModal(false)}
          refetch={retry}
        />
      )}
      {!!notifications.length &&
        notifications.map((notification) => (
          <Notification
            key={notification.id}
            name={notification.id}
            color={notification.color}
            onClose={() => setNotifications(notifications.filter((n) => n.id !== notification.id))}
            show
          >
            {notification.message}
          </Notification>
        ))}
    </Col>
  );
}
