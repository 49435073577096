import React from 'react';
import { LearnContentType } from '@rio/rio-types';
import LearnEmbeddedVideoPlayer from './LearnEmbeddedVideoPlayer';
import LearnEmbeddedScormViewer from './LearnEmbeddedScormViewer';
import LearnEmbeddedIframe from './LearnEmbeddedIframe';

const LearnEmbeddedViewer = ({ course, link, onCompleted, courseRefetch = () => void 0 }) => {
  if (course.type === LearnContentType.Video) {
    return <LearnEmbeddedVideoPlayer course={course} courseRefetch={courseRefetch} onCompleted={onCompleted} />;
  } else if (course.type === LearnContentType.Scorm) {
    return (
      <LearnEmbeddedScormViewer course={course} link={link} courseRefetch={courseRefetch} onCompleted={onCompleted} />
    );
  } else if (course.type === LearnContentType.Url) {
    return <LearnEmbeddedIframe course={course} />;
  }
  return null;
};

export default LearnEmbeddedViewer;
