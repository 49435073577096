import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { GET_INVESTMENT_TRANSACTIONS_FILTER } from './index.queries';

const formatDate = (date) => moment(date).format('DD/MM/YYYY');

const formatCurrency = ({ value }) =>
  value
    ? new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
      }).format(value)
    : value;

export function useGridConfig() {
  const [query] = useLazyQuery(GET_INVESTMENT_TRANSACTIONS_FILTER);
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getInvestmentTransactionsFilter: values },
      } = await query({
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date',
        valueFormatter: ({ value }) => formatDate(value),
        filter: 'agDateColumnFilter',
        initialSort: 'desc' as const,
      },
      {
        headerName: 'Parent Fund',
        field: 'parentFund.name',
        colId: 'parentFund',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        hide: true,
      },
      {
        headerName: 'Parent Fund Commitment',
        field: 'parentFundCommitment',
        filter: 'agNumberColumnFilter',
        valueFormatter: formatCurrency,
        hide: true,
      },
      {
        headerName: 'Parent Fund Currency',
        field: 'parentFundCurrencyCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        hide: true,
      },
      {
        headerName: 'Parent Fund Ownership',
        field: 'parentFundOwnership',
        filter: 'agNumberColumnFilter',
        hide: true,
      },
      {
        headerName: 'Fund',
        field: 'fund.name',
        colId: 'fund',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Fund Commitment',
        field: 'fundCommitment',
        filter: 'agNumberColumnFilter',
        valueFormatter: formatCurrency,
        hide: true,
      },
      {
        headerName: 'Fund Currency',
        field: 'fundCurrencyCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Fund Ownership',
        field: 'fundOwnership',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Fund Revenue',
        field: 'fundRevenue',
        filter: 'agNumberColumnFilter',
        hide: true,
      },
      {
        headerName: 'Asset',
        field: 'asset.name',
        colId: 'asset',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Asset Revenue',
        field: 'assetRevenue',
        filter: 'agNumberColumnFilter',
        valueFormatter: formatCurrency,
      },
      {
        headerName: 'Asset Valuation',
        field: 'assetValuation',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Asset Currency',
        field: 'assetCurrencyCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Asset Revenue Date',
        field: 'assetRevenueDate',
        valueFormatter: ({ value }) => (value ? formatDate(value) : value),
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Estimated Factor Name',
        field: 'asset.factorName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Estimated Factor Value',
        field: 'asset.factorValue',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Financed Emissions (tCO2e)',
        field: 'financedEmissions',
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ value }) => (value ? value.toFixed(2) : value),
      },
      {
        headerName: 'Comment',
        field: 'comment',
        filter: 'agTextColumnFilter',
      },
    ],
  };
}
