import { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { styled, useTheme, Theme, Text } from '@rio/ui-components';
import { baseChart } from './chartConfigs';

interface ProgressBarChartProps {
  text: string;
  totals: [number, number];
  className?: string;
}

const getPerformanceColor = (percent: number, theme: Theme): string => {
  if (percent > 33.3 && percent < 66.7) {
    return theme.customColors.warning.color;
  } else if (percent > 66.7) {
    return theme.extendedColors.environmental.color;
  } else if (percent < 33.3) {
    return theme.sys.color.error;
  }
  return theme.customColors.warning.color;
};

const chart = (percent: number, theme: Theme): Highcharts.Options => ({
  ...baseChart,
  chart: {
    ...baseChart.chart,
    type: 'bar',
    height: 14,
    spacing: [0, 0, 0, 0],
  },
  plotOptions: {
    bar: { showInLegend: false },
  },
  xAxis: { visible: false },
  yAxis: { visible: false, max: 100 },
  series: [
    {
      data: [100] as Highcharts.XrangePointOptionsObject[],
      type: 'bar',
      grouping: false,
      color: theme.sys.color.primaryContainer,
      borderRadius: 6,
    },
    {
      data: [percent],
      color: getPerformanceColor(percent, theme),
      type: 'bar',
      borderRadius: 6,
    },
  ],
});

const ChartText = styled(Text)`
  margin-top: 8px;
  flex: 1;
`;
const ChartTextRight = styled(Text)`
  margin-top: 8px;
  text-align: right;
`;

const FlexContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-flow: wrap;
`;
const ProgressBarChartContainer = styled('div')``;

export function ProgressBarChart({ text, totals }: ProgressBarChartProps) {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const percent = totals[0] > 0 ? (totals[0] / totals[1]) * 100 : 0;
  const chartCached = useMemo(() => chart(percent, theme), [theme, percent]);

  return (
    <ProgressBarChartContainer>
      <HighchartsReact highcharts={Highcharts} options={chartCached} ref={chartRef} />
      <FlexContainer>
        <ChartText typescale="body" size="medium" color="normal">
          {text}
        </ChartText>
        <ChartTextRight typescale="body" size="medium" color="normal">
          {totals[0]} / {totals[1]}
        </ChartTextRight>
      </FlexContainer>
    </ProgressBarChartContainer>
  );
}
