import { Dictionary, get, mapValues } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import moment, { unitOfTime } from 'moment';
import { round } from 'lodash';
import { DataFormRowsFillingStrategy, DateFrequency, DataFormInputStrategy } from '../../constants';
import { toNumeric } from '../../utils';
import { Option } from '../../types';

const durations: Dictionary<unitOfTime.DurationConstructor> = {
  [DateFrequency.Daily]: 'days',
  [DateFrequency.Weekly]: 'weeks',
  [DateFrequency.Monthly]: 'months',
  [DateFrequency.Quarterly]: 'quarters',
  [DateFrequency.Annually]: 'years',
};

interface HotelStaysData {
  location: Option;
  country: Option;
  dataProvider: Option;
  electricityTariff: Option;
  currency: Option;
  totalCost: string;
  hotelNights: string;
  homeWorkingDays: string;
  notes: string;
  reference: string;
  invoiceNumber: string;
  evidenceReference: string;
  otherReference: string;
}
interface HotelStaysForm {
  data: HotelStaysData;
  startDate: Date;
  endDate: Date;
  dataFormRowsFillingStrategy: DataFormRowsFillingStrategy;
  dateFrequency: DateFrequency;
  dataFormInputStrategy: DataFormInputStrategy;
}

const getTransactionDates = (
  momentMeasure: unitOfTime.DurationConstructor,
  startDate: Date,
  endDate: Date,
  i: number
) => {
  const transactionStartDate = moment(startDate).clone().add(i, momentMeasure);
  const transactionEndDate = moment(startDate)
    .clone()
    .add(i + 1, momentMeasure)
    .subtract(1, 'days');

  return {
    startDate: transactionStartDate.toDate(),
    endDate: transactionEndDate.isAfter(endDate) ? endDate : transactionEndDate.toDate(),
  };
};

const divideQuantity = (quantity: string, divider: number) => {
  const numeric = toNumeric(quantity);
  if (Number.isNaN(numeric)) {
    return quantity;
  }
  return round(numeric / divider, 2);
};

export function useConvertHotelStaysFormToRows() {
  const [data, setData] = useState<object[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const convert = useCallback((form: HotelStaysForm) => {
    const { data, startDate, endDate, dataFormRowsFillingStrategy, dateFrequency, dataFormInputStrategy } = form;

    // Map objects to strings
    const mappedData = mapValues(data, (value) => get(value, 'label', <any>value));

    // Add start and end dates of a period
    const row = {
      ...mappedData,
      startDate,
      endDate,
    };

    const list: any[] = [];

    if (dataFormRowsFillingStrategy === DataFormRowsFillingStrategy.Distributed) {
      const momentMeasure = durations[dateFrequency];
      const diff = moment(endDate).diff(moment(startDate), momentMeasure) + 1;
      const rowsCount = diff < 1 ? 1 : diff;

      for (let i = 0; i < rowsCount; i++) {
        const dates = getTransactionDates(momentMeasure, startDate, endDate, i);

        const distributedRow = {
          ...row,
          ...dates,
        };
        if (dataFormInputStrategy === DataFormInputStrategy.Single) {
          list.push(distributedRow);
        } else {
          list.push({
            ...distributedRow,
            totalCost: divideQuantity(row.totalCost, rowsCount),
            hotelNights: divideQuantity(row.hotelNights, rowsCount),
            homeWorkingDays: divideQuantity(row.homeWorkingDays, rowsCount),
          });
        }
      }
    } else {
      list.push(row);
    }

    // Add a fake id for a compatibility in the table component
    const mappedList = list.map((item, i) => ({ ...item, id: i }));

    setData(mappedList);

    setLoading(false);
  }, []);

  return useMemo(() => [convert, { data, loading }], [convert, data, loading]);
}
