import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Tab, Tabs, TabPanel } from 'rio-ui-components';
import TaskRoutes from './TasksRoutes';
import ProjectContainer from '../ProjectContainer';
import { TabContent } from '../../components/TabContent';
import NoResults from '../../components/NoResults';
import UpgradeBanner from '../../components/UpgradeBanner/UpgradeBanner';
import { FEATURES } from '../../types';

const ContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const tabs = [
  { label: 'Tasks', to: '../tasks', value: '/tasks' },
  { label: 'Projects', to: '../projects', value: '/projects' },
];

const TasksContainer = ({ tab, hasProjectsAccess, hasTasksAccess }) => {
  const [tabIndex, setTabIndex] = useState(tab);

  const tabChanged = (event, value) => {
    setTabIndex(value);
  };

  return (
    <ContentContainerStyled>
      <Tabs variant="standard" value={tabIndex} onChange={tabChanged}>
        {tabs.map(({ label, to, value }) => (
          <Tab data-cy={`Tab__${label}`} key={label} label={label} component={Link} value={value} to={to} />
        ))}
      </Tabs>
      <TabContent>
        <TabPanel value={tabIndex} index="/tasks">
          {!hasTasksAccess && (
            <NoResults name="TasksAndProjects__NoResults" title="Your account has no access to this section" />
          )}
          {hasTasksAccess && <TaskRoutes hasProjectsAccess={hasProjectsAccess} />}
        </TabPanel>
        <TabPanel value={tabIndex} index="/projects">
          {!hasProjectsAccess && <UpgradeBanner featureName={FEATURES.PROJECTS} link="/contact" />}
          {hasProjectsAccess ? <ProjectContainer /> : ''}
        </TabPanel>
      </TabContent>
    </ContentContainerStyled>
  );
};

export default TasksContainer;
