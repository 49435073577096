import { useState, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { CreateDocumentRecordInput, Tag } from '@rio/rio-types';
import { NullableObject } from '~/types';
import { useCurrentAccountId, useCurrentUserId } from '~/hooks';
import { CREATE_DOCUMENT_RECORD } from '../../index.queries';
import { DocumentModal } from './DocumentModal';
import { DocumentDetails } from './DocumentDetails';
import { OptionsProps } from '@rio/ui-components';

export interface DocumentProps extends CreateDocumentRecordInput {
  tags: string[];
}

interface EditDocumentModalProps {
  onComplete: (document: NullableObject<CreateDocumentRecordInput>) => void;
  onError: (error: Error) => void;
  onDismiss: () => void;
  document: DocumentProps;
}

export function EditDocumentModal({ onComplete, onError, onDismiss, document }: EditDocumentModalProps) {
  const accountId = useCurrentAccountId();
  const userId = useCurrentUserId();
  const [editedDocument, setEditedDocument] = useState<NullableObject<CreateDocumentRecordInput>>(document);
  const [createDocumentRecord, { loading: isSubmitting }] = useMutation(CREATE_DOCUMENT_RECORD);
  const newTags = useMemo(
    () =>
      editedDocument?.tags?.map((tag: Tag | OptionsProps | string) =>
        typeof tag === 'string' ? tag : (tag as OptionsProps).value || (tag as Tag).id
      ),
    [editedDocument?.tags]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const variables = {
        ...editedDocument,
        notes: editedDocument.notes?.trim(),
        tags: newTags,
        accountId,
        userId,
        fileName: document.fileName,
      };
      await createDocumentRecord({
        variables,
      });
      onComplete(variables);
    } catch (err) {
      onError(new Error(`Document ${document.fileName} failed to update`));
    }
  }, [accountId, createDocumentRecord, document.fileName, editedDocument, newTags, onComplete, onError, userId]);

  return (
    <DocumentModal
      heading="Edit Document"
      onDismiss={onDismiss}
      submitButtonTitle={isSubmitting ? 'Updating...' : 'Update'}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    >
      <DocumentDetails document={editedDocument} onChange={setEditedDocument} isSubmitting={isSubmitting} />
    </DocumentModal>
  );
}
