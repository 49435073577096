import { QuantityInput } from './CountPickerStyles';
import { Icon } from 'rio-ui-components';
import { NumberInput } from '../NumberInput';
import React, { useState, forwardRef, useEffect } from 'react';

export const CountPicker = forwardRef((props, ref) => {
  const { value: initValue = 0, onIncrement = () => {}, onDecrement = () => {}, ...others } = props;

  const [value, setValue] = useState(initValue);

  useEffect(() => {
    value !== initValue && setValue(initValue);
    // eslint-disable-next-line
  }, [initValue]);

  const handleOnIncrement = () => {
    const newValue = value + 1;

    setValue(newValue);

    onIncrement(newValue);
  };

  const handleOnDecrement = () => {
    const newValue = value > 0 ? value - 1 : 0;

    setValue(newValue);

    onDecrement(newValue);
  };

  return (
    <QuantityInput>
      <div className="cart-line-quantity">
        <NumberInput {...others} ref={ref} value={value} name="Count-Picker__Number-Input" />
      </div>
      <div className="cart-line-arrow-holder">
        <span onClick={handleOnIncrement} name="Count-Picker__Increment">
          <Icon icon="angle-up" size="1x" />
        </span>

        <span onClick={handleOnDecrement} name="Count-Picker__Decrement">
          <Icon icon="angle-down" size="1x" />
        </span>
      </div>
    </QuantityInput>
  );
});
