import React from 'react';
import { Search } from 'rio-ui-components';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';

export class SearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
  }

  onChange = (event, setSearch, setPage) => {
    this.setState({ search: event.target.value }, () => {
      this.onSearch(setSearch, setPage);
    });
  };

  onSearch = (setSearch, setPage) => {
    const { search } = this.state;
    setPage({
      variables: {
        page: 1
      }
    });
    setSearch({
      variables: {
        search
      }
    });
  };

  render() {
    const { setPageMutation, setSearchMutation, ...rest } = this.props;
    return (
      <Mutation mutation={setPageMutation}>
        {(setPage) => (
          <Mutation mutation={setSearchMutation}>
            {(setSearch) => (
              <Search
                {...rest}
                name="account-controls__search"
                value={this.state.search}
                onChange={(event) => this.onChange(event, setSearch, setPage)}
                onSearch={() => this.onSearch(setSearch, setPage)}
              />
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }

  componentWillUnmount() {
    const { client, setSearchMutation } = this.props;
    client.mutate({ mutation: setSearchMutation, variables: { search: '' } });
  }
}

export default withApollo(SearchContainer);
