import React from 'react';
import { Catalog } from './Catalog';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Checkout } from './Checkout';
import { Cart } from './Cart';
import { PageNotFound } from '../../components/Errors';
import { useRoutes } from '~/hooks';

const MarketContainer = () => {
  const routes = useRoutes();
  return (
    <Routes>
      <Route path={routes.market.checkout} element={<Checkout />} />
      <Route path={routes.market.cart} element={<Cart />} />
      <Route path="/:route" element={<Catalog />} />
      <Route path="/" element={<Navigate to="/upgrade/courses" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MarketContainer;
