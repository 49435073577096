import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { GET_MATERIALS_TRANSACTIONS_PAGE } from './index.queries';
import { useMaterialsGridConfig } from './useMaterialsGridConfig';
import { DocumentReference, UploadReferenceCell } from '../../CellRenderers';

export const MaterialsTransactionsGrid = () => {
  const client = useApolloClient();
  const config = useMaterialsGridConfig();
  return (
    <>
      <TransactionGrid
        title="Materials Transactions"
        defaultExportFileName="materials-export"
        breadcrumbs={[{ title: 'Data', to: '..' }, { title: 'Materials Transactions' }]}
        uploadLink="../materials/uploads"
        config={{
          ...config,
          components: {
            documentReference: DocumentReference,
            uploadReference: UploadReferenceCell,
          },
        }}
        fetchRows={async (variables) => {
          const {
            data: {
              getMaterialsTransactionsPage: { totalRows, rows },
            },
          } = await client.query({
            query: GET_MATERIALS_TRANSACTIONS_PAGE,
            variables,
            fetchPolicy: 'network-only',
          });
          return {
            rows,
            totalRows,
          };
        }}
      />
    </>
  );
};
