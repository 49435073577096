import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccountInfo($id: ID!) {
    getAccountInfo(id: $id) {
      id
      name
      accessControls {
        learn
        intelligence
        tasks
        projects
        v2Design
        comply {
          legislation
          tagLevelLegislation
          policies
          systems
          targets
          frameworks
          agreements
        }
        data {
          waste
          electricity
          gas
          water
          materials
          fuel
          transport
          refrigerants
          heatSteam
        }
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTIONS = gql`
  query GetIntelligenceSubscriptions($accountId: ID!) {
    getIntelligenceSubscriptions(accountId: $accountId)
  }
`;

export const SAVE_SUBSCRIPTIONS = gql`
  mutation SaveIntelligenceSubscriptions($accountId: ID!, $subscriptions: [String!]!) {
    saveIntelligenceSubscriptions(input: { accountId: $accountId, subscriptions: $subscriptions }) {
      subscriptions
    }
  }
`;

export const GET_DASHBOARD_BY_ID = gql`
  query GetDashboardById($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      description
      area
      provider
      providerDashboardId
    }
  }
`;

export const GET_ALL_DASHBOARDS = gql`
  query GetAllDashboards {
    getAllDashboards {
      id
      name
      description
      area
      provider
      providerDashboardId
    }
  }
`;
