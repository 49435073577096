import { Account, Survey, SurveyInput, SurveyTemplate } from '@rio/rio-types';
import { useReducer } from 'react';

export type UseSurveyFormState = Omit<SurveyInput, 'ownerAccountId'>;

export type UseSurveyRecipientsEditActions =
  | {
      type: 'toggleNotifyByEmail' | 'toggleFeedbackEnabled' | 'toggleAutomatedScoring';
    }
  | {
      type: 'selectContributors' | 'selectIndividualContributors';
      ids: string[];
    }
  | {
      type: 'changeName' | 'changeNote' | 'changeSurveyTemplateId';
      value: string;
    }
  | {
      type: 'changeStartDate' | 'changeDeadlineDate';
      value: Date;
    };

function reducer(state: UseSurveyFormState, action: UseSurveyRecipientsEditActions): UseSurveyFormState {
  switch (action.type) {
    case 'toggleNotifyByEmail':
      return { ...state, notifyByEmail: !state.notifyByEmail };
    case 'selectContributors':
      return {
        ...state,
        individualContributors: undefined,
        contributors: action.ids.map((id) => id),
      };
    case 'selectIndividualContributors':
      return {
        ...state,
        contributors: undefined,
        individualContributors: action.ids.map((id) => id),
      };
    case 'changeName':
      return { ...state, name: action.value };
    case 'toggleFeedbackEnabled':
      return { ...state, feedbackEnabled: !state.feedbackEnabled };
    case 'toggleAutomatedScoring':
      return { ...state, automatedScoring: !state.automatedScoring };
    case 'changeNote':
      if (action.value.length > 1000) {
        return state;
      }
      return { ...state, notes: action.value };
    case 'changeStartDate':
      return { ...state, startDate: action.value };
    case 'changeDeadlineDate':
      return { ...state, deadlineDate: action.value };
    case 'changeSurveyTemplateId':
      return { ...state, surveyTemplateId: action.value };
  }
}

export type ExistingSurvey = Omit<Survey, 'owner' | 'status' | 'submissions' | 'template'> & {
  owner: Pick<Account, 'id'>;
  template: Pick<SurveyTemplate, 'id'>;
};

const initialState: ExistingSurvey = {
  id: '',
  name: '',
  owner: { id: '' },
  automatedScoring: true,
  feedbackEnabled: true,
  template: { id: '' },
  contributors: [],
};

function isContributor(survey: ExistingSurvey) {
  if (survey.contributors.length > 0) {
    return !survey.contributors[0].user;
  }
  return false;
}

function isIndividualContributors(survey: ExistingSurvey) {
  return !isContributor(survey);
}

export function useSurveyFormReducer(survey: ExistingSurvey = initialState) {
  const state: UseSurveyFormState = {
    automatedScoring: survey.automatedScoring,
    feedbackEnabled: survey.feedbackEnabled,
    name: survey.name,
    notifyByEmail: false,
    surveyTemplateId: survey.template.id,
    contributors: isContributor(survey) ? survey.contributors.map((c) => c.account.id) : undefined,
    individualContributors: isIndividualContributors(survey) ? survey.contributors?.map((c) => c.user!.id) : undefined,
    notes: survey.notes,
    deadlineDate: survey.deadlineDate ? new Date(survey.deadlineDate) : null,
    startDate: survey.startDate ? new Date(survey.startDate) : null,
  };
  return useReducer(reducer, state);
}
