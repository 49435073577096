import { useQuery, QueryHookOptions } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_REPORT_PROGRESS_CHAPTER_BREAKDOWN } from './index.queries';
import { useCurrentAccountId, useNotification } from '../../hooks';

export type QueryResponse = Pick<Query, 'getReportProgressChapterBreakdown'>;

export interface QueryVariables {
  accountId: string;
  frameworkId: string;
}

export function useGetReportProgressChapterBreakdown(
  frameworkId: string,
  queryOptions?: QueryHookOptions<QueryResponse, QueryVariables>
) {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  return useQuery<QueryResponse, QueryVariables>(GET_REPORT_PROGRESS_CHAPTER_BREAKDOWN, {
    ...queryOptions,
    variables: {
      accountId,
      frameworkId,
    },
    fetchPolicy: 'network-only',
    onError: (err: Error) => {
      showNotification(`Couldn't load report progress: ${err}`, 'danger');
    },
  });
}
