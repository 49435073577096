import { useApolloClient } from '@apollo/client';
import { downloadFileFromUrl } from '../../../utils';
import { GET_DATA_BATCH_EXPORT_URL } from '../../../components/TransactionRow/index.queries';
import { TextLink } from './elements';

export const UploadReferenceCell = ({ data }) => {
  const client = useApolloClient();
  const onExportClick = async (batchId) => {
    const { data } = await client.query({ query: GET_DATA_BATCH_EXPORT_URL, variables: { id: batchId } });
    downloadFileFromUrl(data.getDataImportBatchExportUrl);
  };
  return data?.importBatchId ? (
    <TextLink onClick={() => onExportClick(data.importBatchId)}>{data.importBatchFileName}</TextLink>
  ) : (
    ''
  );
};
