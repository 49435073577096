import { styled, Grid, Text, Icons, Button, Modal } from '@rio/ui-components';
import { ButtonGrid } from '~/components/TransactionGrid/style';

export const GridStyled = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const ButtonsWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GridWrapper = styled(ButtonsWrapper)`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.sys.color.surfaceVariant};
`;

export const TextTitle = styled(Text)``.withComponent('h4');

export const TextWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  gap: 4px;
`;

export const SubTextWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileIconStyle = styled(Icons.TextSnippetOutlined)`
  height: 56px;
  width: 56px;
`;

export const ButtonGridStyled = styled(ButtonGrid)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const ModalStyled = styled(Modal)`
  .TitleClassName > h2 {
    text-align: center;
  }
`;
