import React from 'react';
import {
  ViewListOutlined,
  ElectricalServices,
  DeleteOutlineOutlined,
  PropaneTankOutlined,
  WaterOutlined,
  TrainOutlined,
  LocalGasStationOutlined,
  HomeWorkOutlined,
  ThermostatOutlined,
  LocalFireDepartmentOutlined,
  ParkOutlined,
  HotelOutlined,
  HealthAndSafetyOutlined,
  PeopleOutlined,
  ThumbUpAltOutlined,
  BusinessCenterOutlined,
  ForestOutlined,
} from '@mui/icons-material';

import { Icons } from './types';

export const getIconFromName = (iconName: Icons): React.ReactNode => {
  switch (iconName) {
    case 'overview':
      return <ViewListOutlined />;
    case 'waste':
      return <DeleteOutlineOutlined />;
    case 'electricity':
      return <ElectricalServices />;
    case 'gas':
      return <PropaneTankOutlined />;
    case 'water':
      return <WaterOutlined />;
    case 'transport':
      return <TrainOutlined />;
    case 'fuel':
      return <LocalGasStationOutlined />;
    case 'buildingEnergyRatings':
      return <HomeWorkOutlined />;
    case 'refrigerants':
      return <ThermostatOutlined />;
    case 'heatSteam':
      return <LocalFireDepartmentOutlined />;
    case 'materials':
      return <ParkOutlined />;
    case 'hotelStays':
      return <HotelOutlined />;
    case 'safety':
      return <HealthAndSafetyOutlined />;
    case 'employees':
      return <PeopleOutlined />;
    case 'community':
      return <ThumbUpAltOutlined />;
    case 'business':
      return <BusinessCenterOutlined />;
    case 'biodiversity':
      return <ForestOutlined />;
  }
};
