import * as JsSearch from 'js-search';
import { useState } from 'react';

export type ISearchValue = string;

export function search<T extends object>(data: T[], term: string, indices: string[], idField: string = 'id'): T[] {
  if (!term) {
    return data;
  }
  const search = new JsSearch.Search(idField);
  for (const index of indices) {
    search.addIndex(index);
  }
  search.addDocuments(data);

  // @ts-expect-error 'term' is defined in dependency as Object[].
  return search.search(term);
}

export function useSearch<T extends object>(data: T[], indices: string[] = [], defaultSearch: string = '') {
  const [searchValue, setSearchValue] = useState<ISearchValue>(defaultSearch);
  const searchResult = search(data, searchValue, indices);
  return [searchValue, setSearchValue, searchResult] as const;
}
