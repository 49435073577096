import { Text } from '@rio/ui-components';
import { formatDate } from '~/utils';

type StartDateCellProps = {
  data: {
    startDate: Date;
  };
};

export const StartDateCell = (props: StartDateCellProps) => {
  const {
    data: { startDate },
  } = props;

  const date = formatDate(startDate);

  return (
    startDate && (
      <Text typescale="body" size="medium">
        {date}
      </Text>
    )
  );
};
