import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_CONTAINER_TYPE_SIZES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface ContainerTypeSelectProps {
  value: Nullable<string | Option>;
  onChange: (id: string, name: string) => void;
  error?: string;
}

export function ContainerTypeSelect({ value, onChange, error }: ContainerTypeSelectProps) {
  const {
    data,
    loading,
    error: queryError
  } = useQuery<Pick<Query, 'getContainerTypeSizePage'>>(GET_CONTAINER_TYPE_SIZES);
  const options = toSelectOptions(data?.getContainerTypeSizePage?.typeSizes);
  return (
    <Select
      placeholder="Please select type of container"
      value={value}
      options={options}
      error={error || queryError}
      isLoading={loading}
      onChange={({ target: { select } }: SelectEvent) => {
        onChange(select.value, select.label);
      }}
    />
  );
}
