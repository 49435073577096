import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DocumentRecord, NamedEntity, Task, Query } from '@rio/rio-types';
import {
  LoadingIndicator,
  Col,
  Row,
  Text,
  Icon,
  Modal,
  Heading,
  Button,
  Carousel,
  EvidenceUploadItem,
} from 'rio-ui-components';
import { usePermissions, useCurrentAccountId, useNotification, useRoutes } from '../../../hooks';
import UserSelect from '../../../components/UserSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { CreateTaskModal } from '../../TasksContainer/TasksGrid/CreateTaskModal';
import { EvidencePicker } from '../../../components/EvidenceNotes';
import { MetersMultiSelect } from '../../../components/MetersMultiSelect';
import { GET_PROJECT_BY_ID } from '../../../graphql/queries/projects/GetProjectById.query';
import { UPDATE_PROJECT } from '../../../graphql/mutations/projects/UpdateProject.mutation.js';
import { DELETE_PROJECT } from '../../../graphql/mutations/projects/DeleteProject.mutation.js';
import { UPDATE_TASK } from '../../../graphql/mutations/tasks/UpdateTask.mutation.js';
import { GET_PROJECT_CATEGORIES } from '../../../graphql/queries/projectCategory/GetProjectCategories.query';
import { formatProjectData, prepareProjectInput, prepareTaskForUpdate } from './utils';
import { ProjectFormattedDetails } from './types';
import { TaskSection } from './TasksSection';
import { ValidateProject, ValidateErrors } from '../Validate';
import { frequencyOptions, priorityOptions } from '../constants';
import { ProjectCategorySelect } from '../../../components/ProjectCategorySelect';
import { SchemeSelect } from '../../../components/SchemeSelect';
import { SelectEvent, MultiSelectEvent, InputEvent, Nullable, Option } from '../../../types';
import {
  DocumentContainer,
  DocumentAndChildContainer,
  FlexRow,
  PaddedCol,
  TitleContainerStyled,
  CircleIconStyled,
  PaddedContainer,
  MainContainer,
  ContentContainer,
  DatePickerContainer,
  InfoLabel,
  StyledLabel,
  StyledNameInput,
  StyledRow,
  StyledTextArea,
  StyledSelect,
  StyledTextInput,
  StyledDatePicker,
  LoadingContainer,
  MultiSelectContainer,
  SaveButton,
  CancelButton,
  DeleteButton,
  ControlButtonsContainer,
  SectionWithTooltip,
  StyledLabelWithRequiredBlock,
  StyledIcon,
  Tooltip,
  PageHeaderStyled,
} from '../../../components/StyledElements';
import PageHeader from '../../../components/PageHeader';
import { useSaveEvidenceNotes } from '../useSaveEvidenceNotes';
import { calculateEvidenceTileNumber } from '../../../utils/managementSystemUtils';
import { AddDocumentTile } from '../../../components/AddDocumentTile';
import { DeleteModal } from '../../../components/DeleteModal';
import { namedEntityToSelectOption } from '../../../utils';
import { ENERGY_GENERATION_CATEGORY_NAME } from '../../../constants/common';

const StyledSchemeSelect = styled(SchemeSelect)`
  border-radius: 5px;
`;
const StyledProjectCategorySelect = styled(ProjectCategorySelect)`
  border-radius: 5px;
`;

const RequiredLabel = ({ id }: { id: string }) => {
  return (
    <StyledLabelWithRequiredBlock size="lg">
      <FormattedMessage id={id} />
    </StyledLabelWithRequiredBlock>
  );
};

const OptionalLabel = ({ id }: { id: string }) => {
  return (
    <StyledLabel size="lg">
      <FormattedMessage id={id} />
    </StyledLabel>
  );
};

const ProjectViewContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const [projectData, setProjectData] = useState<Partial<ProjectFormattedDetails>>({});
  const [initialProjectData, setInitialProjectData] = useState<Partial<ProjectFormattedDetails>>({});
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [evidenceNotes, setEvidenceNotes] = useState('');
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const canEditProject = permissions.project.some((action: string) => action.startsWith('edit'));
  const canDeleteProject = permissions.project.some((action: string) => action.startsWith('delete'));
  const saveEvidenceNotes = useSaveEvidenceNotes();
  const [projectDocuments, setProjectDocuments] = useState<DocumentRecord[]>([]);
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<Nullable<NamedEntity>>(null);
  const [projectCategories, setProjectCategories] = useState<Nullable<NamedEntity[]>>(null);
  const hasPreviousPage = window.history.state && window.history.state.idx > 0;
  const routes = useRoutes();

  useQuery<Pick<Query, 'getProjectCategories'>>(GET_PROJECT_CATEGORIES, {
    onCompleted: (data) => {
      setProjectCategories(data?.getProjectCategories || []);
    },
    onError: () => {
      setProjectCategories([]);
    },
  });

  const energyGenerationId = projectCategories?.find(
    (category: NamedEntity) => category.name === ENERGY_GENERATION_CATEGORY_NAME
  )?.id;

  const { data, loading: loadingProject } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: (completionData) => {
      const completionProjectData = formatProjectData(completionData?.getProject);
      setProjectData(completionProjectData);
      setInitialProjectData(completionProjectData);
      setSelectedProjectCategory(completionData?.getProject.scheme?.category || null);
      const documents: DocumentRecord[] = (completionProjectData?.evidenceNotes || [])
        .filter((evidence) => evidence.documents.length > 0)
        .reduce((acc, evidence) => {
          if (evidence.documents && evidence.documents.length) {
            acc.push(...evidence.documents);
          }
          return acc;
        }, [] as DocumentRecord[]);
      setProjectDocuments(documents);
    },
  });

  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showStartDateTooltip, setShowStartDateTooltip] = useState(false);
  const [showEndDateTooltip, setShowEndDateTooltip] = useState(false);
  const [showBaselineDateTooltip, setShowBaselineDateTooltip] = useState(false);
  const [showCostSavingsPrompt, setShowCostSavingsPrompt] = useState(false);
  const [showEnergyGenerationSavingsPrompt, setShowEnergyGenerationSavingsPrompt] = useState(false);
  const [validationError, setErrors] = useState<ValidateErrors>({});
  const showTaskCreatedNotification = useCallback(
    ({ message, colour }) => {
      showNotification(message, colour);
    },
    [showNotification]
  );

  useEffect(() => {
    const invalidInput = document.querySelector("[color='danger']");
    invalidInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [validationError, setErrors]);

  const handleInputAndSelectChange = (event: SelectEvent | InputEvent) => {
    const scheme =
      event.target.name === 'scheme' && 'select' in event.target ? event.target.select : projectData.scheme;

    if (Object.keys(validationError).find((name) => name === event.target.name)) {
      setErrors({
        ...validationError,
        [event.target.name]: null,
      });
    }

    if (validationError.schemeId && event.target.name === 'scheme') {
      setErrors({
        ...validationError,
        schemeId: undefined,
      });
    }

    setProjectData({
      ...projectData,
      [event.target.name]: event.target.value,
      scheme,
    });
  };

  const handleMultiSelectChange = (event: MultiSelectEvent) => {
    const owners =
      event.target.name === 'owner'
        ? event.target?.select?.map(({ value, label }: Option) => {
            return { value, label };
          })
        : projectData.owners;

    if (event.target.name === 'locations') {
      setProjectData({
        ...projectData,
        [event.target.name]: event.target.select,
        meters: [],
      });
      return;
    }

    setProjectData({
      ...projectData,
      [event.target.name]: event.target.select,
      owners,
    });
  };

  const [updateTask, { loading: taskUpdateLoading }] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      showNotification('Task has been deleted from this project');
    },

    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const [updateProject, { loading: projectUpdateLoading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      showNotification('Project has been successfully updated');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const [deleteProject, { loading: projectDeleteLoading }] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      if (hasPreviousPage) {
        navigate(-1);
      } else {
        navigate(routes.projects.root);
      }
      showNotification('Project has been successfully deleted');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const handleTaskDelete = useCallback(
    (task: Task) => {
      const taskInput = prepareTaskForUpdate(task);
      updateTask({
        variables: taskInput,
        refetchQueries: ['GetProjectById'],
      });
    },
    [updateTask]
  );

  const handleSaveProject = useCallback(() => {
    const projectInput = prepareProjectInput(projectData);
    const validationErrors = ValidateProject(projectInput);
    const isDataValid = !Object.values(validationErrors).some(Boolean);

    if (isDataValid) {
      setErrors({});
      updateProject({
        variables: projectInput,
        refetchQueries: ['GetProjectById'],
      });
    }

    if (!isDataValid) {
      setErrors(validationErrors);
    }
  }, [projectData, updateProject]);

  const handleProjectDelete = (deletionId: string | undefined) => {
    if (deletionId === undefined) return;
    deleteProject({
      variables: { id: deletionId },
    });
  };

  const clearEvidenceModal = () => {
    setShowEvidenceModal(false);
    setEvidenceNotes('');
    setEvidenceDocuments([]);
  };

  const handleAddEvidence = async () => {
    const newEvidenceNotes = await saveEvidenceNotes(evidenceDocuments, evidenceNotes);
    const projectInput = prepareProjectInput(initialProjectData);
    const updatedProjectInput = {
      ...projectInput,
      evidenceNotes: projectInput.evidenceNotes.concat(newEvidenceNotes),
    };

    updateProject({
      variables: updatedProjectInput,
      refetchQueries: ['GetProjectById'],
    });

    clearEvidenceModal();
  };

  const isLoading = loadingProject || projectUpdateLoading || taskUpdateLoading || projectDeleteLoading;

  // console.log('isLoading', isLoading);
  // console.log('data', data);

  return (
    <MainContainer>
      {showEvidenceModal && (
        <Modal show size="md" onDismiss={clearEvidenceModal} height="auto" maxHeight="90vh" style={{ 'z-index': '2' }}>
          <ContentContainer>
            <Heading>Add Evidence</Heading>
            <EvidencePicker
              evidenceDocuments={evidenceDocuments}
              setEvidenceDocuments={setEvidenceDocuments}
              evidenceNotes={evidenceNotes}
              setEvidenceNotes={setEvidenceNotes}
            />
            <Button disabled={!evidenceDocuments.length && !evidenceNotes} onClick={handleAddEvidence}>
              Save Evidence
            </Button>
          </ContentContainer>
        </Modal>
      )}
      <PageHeaderStyled>
        <PageHeader
          name="Projects"
          title="Projects"
          breadcrumbs={[]}
          icon="tasks"
          iconColor="tertiary"
          leftButtonClick={() => (hasPreviousPage ? navigate(-1) : navigate(routes.projects.root))}
        />
        <ControlButtonsContainer>
          {canEditProject && (
            <SaveButton data-testid="Save_Button" onClick={handleSaveProject} disabled={isLoading}>
              <FormattedMessage id="pages.project.details.save" />
            </SaveButton>
          )}
          <CancelButton
            data-testid="Cancel_Button"
            onClick={() => (hasPreviousPage ? navigate(-1) : navigate(routes.projects.root))}
            disabled={isLoading}
          >
            <FormattedMessage id="pages.project.details.cancel" />
          </CancelButton>
          {canDeleteProject && (
            <DeleteButton
              data-testid="Delete_Button"
              onClick={() => setShowDeleteProjectModal(true)}
              disabled={isLoading}
            >
              <FormattedMessage id="pages.project.details.delete" />
            </DeleteButton>
          )}
        </ControlButtonsContainer>
      </PageHeaderStyled>
      {isLoading && (
        <LoadingContainer data-testid="Loading_Indicator">
          <LoadingIndicator />
        </LoadingContainer>
      )}

      {showCreateTaskModal && (
        <CreateTaskModal
          onComplete={(notification: any) => {
            showTaskCreatedNotification(notification);
            handleSaveProject();
          }}
          accountId={accountId}
          onDismiss={() => setShowCreateTaskModal(false)}
          sourcePage="TASKS"
          projectId={id}
          hasProjectsAccess={true}
        />
      )}

      {showDeleteProjectModal && (
        <DeleteModal
          deleteObjectName="project"
          onConfirmClick={() => handleProjectDelete(id)}
          onDismissClick={() => setShowDeleteProjectModal(false)}
        />
      )}

      {!isLoading && data && (
        <ContentContainer>
          <Row>
            <RequiredLabel id="pages.project.details.name" />
            <StyledNameInput
              data-testid="Project_Name"
              onChange={handleInputAndSelectChange}
              name="name"
              value={projectData?.name}
              box={false}
              error={validationError.name}
              readOnly={!canEditProject}
            />
          </Row>
          <Row>
            <OptionalLabel id="pages.project.details.description" />
            <StyledTextArea
              data-testid="Project_Description"
              onChange={handleInputAndSelectChange}
              name="description"
              value={projectData?.description}
              box
              readOnly={!canEditProject}
            />
          </Row>
          <Row>
            <OptionalLabel id="pages.project.details.impact" />
            <StyledTextArea
              data-testid="Project_Impact"
              size="md"
              onChange={handleInputAndSelectChange}
              name="impact"
              value={projectData?.impact}
              box
              readOnly={!canEditProject}
            />
          </Row>
          <Row>
            <OptionalLabel id="pages.project.details.locations" />
            <MultiSelectContainer>
              <LocationsMultiSelect
                name="locations"
                accountId={accountId}
                value={projectData?.locations}
                disabled={!canEditProject}
                onChange={handleMultiSelectChange}
              />
            </MultiSelectContainer>
          </Row>
          {selectedProjectCategory?.id === energyGenerationId && !!projectData?.locations?.length && (
            <Row>
              <OptionalLabel id="pages.project.details.meters" />
              <MultiSelectContainer>
                <MetersMultiSelect
                  name="meters"
                  accountId={accountId}
                  value={projectData?.meters}
                  onChange={handleMultiSelectChange}
                  disabled={!canEditProject}
                  locationsToFilter={projectData?.locations as unknown as Option[]}
                  defaultOptions={false}
                  error={undefined}
                />
              </MultiSelectContainer>
            </Row>
          )}
          <Row>
            <RequiredLabel id="pages.project.details.owner" />
            <MultiSelectContainer>
              <UserSelect
                onChange={handleMultiSelectChange}
                value={projectData.owners}
                accountId={accountId}
                isDisabled={!canEditProject}
                error={validationError.owners}
                data-testid="Project_Owner_Input"
              />
            </MultiSelectContainer>
          </Row>
          <Row container span={12}>
            <StyledRow item span={6}>
              <RequiredLabel id="pages.project.details.priority" />
              <StyledSelect
                size="md"
                onChange={handleInputAndSelectChange}
                name="priority"
                value={projectData.priority}
                options={priorityOptions}
                readOnly={!canEditProject}
              />
            </StyledRow>
            <Row item span={6}>
              <OptionalLabel id="pages.project.details.category" />
              <StyledProjectCategorySelect
                onChange={(event: React.ChangeEvent<{ value: string; label: string }>) => {
                  setSelectedProjectCategory(
                    event.target.value ? { id: event.target.value, name: event.target.label } : null
                  );
                  setProjectData({
                    ...projectData,
                    scheme: null,
                    meters: [],
                  });
                }}
                value={selectedProjectCategory ? namedEntityToSelectOption(selectedProjectCategory) : null}
                readOnly={!canEditProject}
                isClearable={true}
                data-testid="Project_Category_Input"
              />
            </Row>
          </Row>
          <Row container span={12}>
            <StyledRow item span={6}>
              <RequiredLabel id="pages.project.details.frequency" />
              <StyledSelect
                size="md"
                onChange={handleInputAndSelectChange}
                name="frequency"
                value={projectData.frequency}
                options={frequencyOptions}
                readOnly={!canEditProject}
                data-testid="Project_Milestone_Frequency_Input"
              />
            </StyledRow>
            <Row item span={6}>
              <RequiredLabel id="pages.project.details.scheme" />
              <StyledSchemeSelect
                readOnly={!canEditProject}
                accountId={accountId}
                onChange={handleInputAndSelectChange}
                value={projectData.scheme}
                projectCategory={selectedProjectCategory}
                isClearable={true}
                error={validationError.schemeId}
              />
            </Row>
          </Row>
          <Row container span={12}>
            <StyledRow item span={6}>
              <OptionalLabel id="pages.project.details.cost" />
              <StyledTextInput
                data-testid="Project_Cost"
                size="md"
                name="cost"
                onChange={handleInputAndSelectChange}
                value={projectData.cost}
                readOnly={!canEditProject}
                error={validationError.cost}
              />
            </StyledRow>
          </Row>
          <Row container span={12}>
            <StyledRow item span={6}>
              <InfoLabel>
                <RequiredLabel id="pages.project.details.startDate" />
                <Icon
                  color="#ffe5b4"
                  icon={showStartDateTooltip ? 'times-circle' : 'info-circle'}
                  onClick={() => setShowStartDateTooltip(!showStartDateTooltip)}
                />
              </InfoLabel>
              <SectionWithTooltip>
                <DatePickerContainer>
                  <StyledDatePicker
                    data-testid="Start_Date"
                    name="startDate"
                    onDayClick={(date: Date) => {
                      setProjectData({
                        ...projectData,
                        startDate: date ? date.toISOString() : null,
                      });
                    }}
                    readOnly={!canEditProject}
                    selectedDate={projectData.startDate ? new Date(projectData.startDate) : null}
                    error={validationError.startDate}
                  />
                </DatePickerContainer>
                {showStartDateTooltip ? (
                  <Tooltip>The date you would expect to start making savings from your project.</Tooltip>
                ) : null}
              </SectionWithTooltip>
            </StyledRow>
            <Row item span={6}>
              <InfoLabel>
                <RequiredLabel id="pages.project.details.baselineStartDate" />
                <Icon
                  color="#ffe5b4"
                  icon={showBaselineDateTooltip ? 'times-circle' : 'info-circle'}
                  onClick={() => setShowBaselineDateTooltip(!showBaselineDateTooltip)}
                />
              </InfoLabel>
              <SectionWithTooltip>
                <DatePickerContainer>
                  <StyledDatePicker
                    name="baselineYear"
                    readOnly={!canEditProject}
                    onDayClick={(date: Date) => {
                      setProjectData({
                        ...projectData,
                        baselineStartDate: date ? date.toISOString() : null,
                      });
                    }}
                    selectedDate={projectData.baselineStartDate ? new Date(projectData.baselineStartDate) : null}
                  />
                </DatePickerContainer>
                {showBaselineDateTooltip ? (
                  <Tooltip>The annual period to compare project performance against.</Tooltip>
                ) : null}
              </SectionWithTooltip>
            </Row>
          </Row>
          <Row>
            <InfoLabel>
              <RequiredLabel id="pages.project.details.endDate" />
              <Icon
                color="#ffe5b4"
                icon={showEndDateTooltip ? 'times-circle' : 'info-circle'}
                onClick={() => setShowEndDateTooltip(!showEndDateTooltip)}
              />
            </InfoLabel>
            <SectionWithTooltip>
              <DatePickerContainer>
                <StyledDatePicker
                  name="endDate"
                  readOnly={!canEditProject}
                  onDayClick={(date: Date) => {
                    setProjectData({
                      ...projectData,
                      endDate: date ? date.toISOString() : null,
                    });
                  }}
                  selectedDate={projectData.endDate ? new Date(projectData.endDate) : null}
                  error={validationError.endDate}
                />
              </DatePickerContainer>
              {showEndDateTooltip ? (
                <Tooltip style={{ width: '20%' }}>
                  The date which you anticipate your planned savings to be realised.
                </Tooltip>
              ) : null}
            </SectionWithTooltip>
          </Row>
          <Row container distribution="between" span={12} itemAlign="bottom">
            <StyledRow item span={4}>
              <RequiredLabel
                id={
                  selectedProjectCategory?.id === energyGenerationId
                    ? 'pages.project.details.plannedBenefitGeneration'
                    : 'pages.project.details.plannedBenefit'
                }
              />
              <StyledTextInput
                data-testid="Project_Planned_Benefit"
                size="md"
                name="plannedBenefit"
                onChange={handleInputAndSelectChange}
                value={projectData.plannedBenefit}
                readOnly={!canEditProject}
                error={validationError.plannedBenefit}
              />
            </StyledRow>
            <StyledRow item span={4}>
              <InfoLabel>
                <OptionalLabel id="pages.project.details.plannedCostChange" />
                <Icon
                  color="#ffe5b4"
                  icon={showCostSavingsPrompt ? 'times-circle' : 'info-circle'}
                  onClick={() => setShowCostSavingsPrompt(!showCostSavingsPrompt)}
                />
              </InfoLabel>
              <SectionWithTooltip>
                <StyledTextInput
                  data-testid="Project_Cost_Change"
                  size="md"
                  name="plannedCostChange"
                  onChange={handleInputAndSelectChange}
                  value={projectData.plannedCostChange}
                  readOnly={!canEditProject}
                />
                {showCostSavingsPrompt ? <Tooltip>This figure should be positive.</Tooltip> : null}
              </SectionWithTooltip>
            </StyledRow>
            <StyledRow item span={4}>
              <InfoLabel>
                <OptionalLabel id="pages.project.details.plannedCarbonChange" />
                <Icon
                  color="#ffe5b4"
                  icon={showEnergyGenerationSavingsPrompt ? 'times-circle' : 'info-circle'}
                  onClick={() => setShowEnergyGenerationSavingsPrompt(!showEnergyGenerationSavingsPrompt)}
                />
              </InfoLabel>
              <SectionWithTooltip>
                <StyledTextInput
                  data-testid="Project_CO2_Change"
                  size="md"
                  name="plannedCO2eChange"
                  onChange={handleInputAndSelectChange}
                  value={projectData.plannedCO2eChange}
                  readOnly={!canEditProject}
                />
                {showEnergyGenerationSavingsPrompt ? <Tooltip>This figure should be positive.</Tooltip> : null}
              </SectionWithTooltip>
            </StyledRow>
          </Row>
          <Col container distribution="center">
            <Row container distribution="between" itemAlign="center" span={12}>
              <OptionalLabel id="pages.project.details.tasks" />
              {canEditProject && <StyledIcon icon="plus" onClick={() => setShowCreateTaskModal(true)} />}
            </Row>
            {!projectData?.tasks?.length && (
              <Text size="lg">
                <FormattedMessage id="pages.project.details.noTasks" />
              </Text>
            )}
            {!!projectData?.tasks?.length && (
              <TaskSection tasks={projectData.tasks} onTaskDelete={handleTaskDelete} canEditProject={canEditProject} />
            )}
          </Col>
          <Row container>
            <PaddedCol item span={12}>
              <DocumentAndChildContainer container item>
                <DocumentContainer>
                  <FlexRow>
                    <TitleContainerStyled span={12}>
                      <CircleIconStyled
                        inline
                        height="50px"
                        size="xl"
                        circleColor="tertiary"
                        iconColor="tertiary"
                        icon="file-alt"
                      />
                      <Heading inline>Evidence Library</Heading>
                    </TitleContainerStyled>
                  </FlexRow>
                  <PaddedContainer>
                    <Carousel show={3} scroll={1} width={'675px'} infinite={false}>
                      {new Array(calculateEvidenceTileNumber(projectDocuments)).fill(0).map((doc, i) => {
                        return (
                          <AddDocumentTile
                            id={doc.id}
                            key={`upload-evidence${i}`}
                            disabled={!canEditProject}
                            onClick={() => setShowEvidenceModal(true)}
                          >
                            <EvidenceUploadItem title="Upload Evidence" />
                          </AddDocumentTile>
                        );
                      })}
                      {projectData?.evidenceNotes
                        ?.filter((evidence) => evidence.documents.length > 0)
                        .map((evidence) => {
                          return evidence.documents.map((document) => {
                            return (
                              <Link key={document.id} to={{ pathname: `/documents/all/${document.id}` }}>
                                <EvidenceUploadItem title={evidence.notes} desc={document.fileName} uploaded={true} />
                              </Link>
                            );
                          });
                        })}
                    </Carousel>
                  </PaddedContainer>
                </DocumentContainer>
              </DocumentAndChildContainer>
            </PaddedCol>
          </Row>
        </ContentContainer>
      )}
    </MainContainer>
  );
};

export default ProjectViewContainer;
