import React, { useMemo } from 'react';
import _ from 'lodash';
import { Select } from 'rio-ui-components';
import { Option, Nullable, SelectEvent, MultiSelectEvent } from '../../types';
import { useCountries } from '../../hooks';

interface CountrySingleSelectProps {
  isMulti?: false;
  onChange: (e: SelectEvent) => void;
  value: Nullable<string | Option>;
}
interface CountryMultiSelectProp {
  isMulti: true;
  onChange: (e: MultiSelectEvent) => void;
  value: Nullable<string[] | Option[]>;
}
type CountrySelectProps = (CountrySingleSelectProps | CountryMultiSelectProp) & {
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  error?: string;
  value: Nullable<string | string[] | Option | Option[]>;
};
export function CountrySelect({ value, error, name, placeholder, isMulti, onChange, disabled }: CountrySelectProps) {
  const { allCountries } = useCountries();
  const options = useMemo(
    () =>
      _(allCountries)
        .map((l) => ({
          value: l.id,
          label: l.name
        }))
        .orderBy(['label'], ['asc']),
    [allCountries]
  );
  return (
    <Select
      isMulti={isMulti}
      name={name}
      placeholder={placeholder || 'Please select the country'}
      value={value}
      options={options}
      error={error}
      onChange={onChange}
      isDisabled={disabled}
    />
  );
}
