import { GET_DEFAULT_WASTE_HIERARCHY } from './index.queries';
import { sortHierarchyByPriority, queryWasteHierarchiesData, makeHierarchiesFilter } from './dataAccessors';

export async function resolveDefaultHierarchyByTreatmentId(client, treatmentProcessId) {
  try {
    const { data } = await client.query({
      query: GET_DEFAULT_WASTE_HIERARCHY,
      variables: {
        treatmentProcessId
      }
    });
    return data.getDefaultWasteHierarchyByWasteTreatmentId;
  } catch {
    return null;
  }
}

export async function resolveCustomHierarchyByTreatmentId(client, treatmentProcessId, accountId, wasteInId) {
  try {
    const { data } = await queryWasteHierarchiesData(client, accountId);
    const doesMatchThisWasteIn = makeHierarchiesFilter(wasteInId);
    return data.getWasteHierarchiesByAccountId
      .filter((h) => {
        const treatmentMatches = h.treatmentProcess.id === treatmentProcessId;
        return treatmentMatches && doesMatchThisWasteIn(h);
      })
      .sort(sortHierarchyByPriority)
      .shift();
  } catch (err) {
    return null;
  }
}
export async function resolveHierarchyByTreatmentId(client, treatmentProcessId, accountId, wasteInId) {
  const customHierarchy = await resolveCustomHierarchyByTreatmentId(client, treatmentProcessId, accountId, wasteInId);
  return customHierarchy || resolveDefaultHierarchyByTreatmentId(client, treatmentProcessId);
}
