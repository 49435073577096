import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { AccountDataState } from './types';
import { ATTACH_CONTENT } from './index.queries';
import { Option } from '../../../../types';
import { LegislationData } from './types';
import { Dispatch, SetStateAction } from 'react';

interface AttachContent {
  id: string;
  tagId: string;
  accountId: string;
  accountData: AccountDataState;
  data: LegislationData | undefined;
  setAccountData: Dispatch<SetStateAction<AccountDataState>>;
}

export function useAttachContent({ id, tagId, accountId, accountData, data, setAccountData }: AttachContent) {
  const [attachContent] = useMutation(ATTACH_CONTENT);
  useEffect(() => {
    if (accountData.shouldUpdate) {
      attachContent({
        variables: {
          id,
          accountId,
          tagId,
          linkId: id,
          content: {
            applicability: accountData.applicability,
            reviewDate: accountData.reviewDate,
            status: accountData.status,
            lastClearedReviewFlag:
              accountData.flagCleared || accountData.reviewDate !== data?.getLegislationById.reviewDate
                ? new Date().toISOString()
                : data?.getLegislationById.lastClearedReviewFlag,
            regionIds: accountData.regionIds?.map((item: Option) => item.value),
            departmentIds: accountData.departmentIds?.map((item: Option) => item.value),
            locationIds: accountData.locationIds?.map((item: Option) => item.value),
            tagIds: accountData.tagIds?.map((item: Option) => item.value),
            title: data?.getLegislationById.title,
          },
        },
        refetchQueries: ['GetLegislationById'],
      });
      setAccountData({ ...accountData, shouldUpdate: false, flagCleared: accountData.flagCleared && false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);
}
