import { PossibleTypesMap } from '@apollo/client/cache';

interface PossibleType {
  name: string;
}

interface Supertype {
  name: string;
  possibleTypes: PossibleType[];
}

interface IntrospectionData {
  __schema: {
    types: Supertype[];
  };
}

const introspectionToPossibleTypes = (introspectionQueryResultData: IntrospectionData) => {
  const possibleTypes: PossibleTypesMap = {};

  introspectionQueryResultData.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
    }
  });

  return possibleTypes;
};

export default introspectionToPossibleTypes;
