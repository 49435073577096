import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { Col, ContainerError, ErrorMessage, Heading, ProfilePicture, Row, Search, Button } from 'rio-ui-components';
import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import AccountRow from './AccountRow';
import alphabetiseStrings from '../../../utils/alphabetise';
import { useGetAvailableCoursesQuery } from '../../../hooks/engage';
import {
  useCurrentAccountId,
  useGetActivatedLicencesCountByAccountIdQuery,
  usePermissions,
  useSearch,
  useRoutes,
} from '../../../hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeUrl } from '../../../utils';
import styled from 'styled-components';
import { LicencesCount } from '../../../components/AccountProductLicence';
import { GET_ACCOUNTS } from '../../../components/AccountsMultiSelect/index.queries';

const SubscriptionRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const SubscriptionRowPictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const SeparateLine = styled.div`
  height: 3px;
  background-color: rgb(109, 108, 122);
  flex-direction: row;
  flex: 0 0 auto;
`;

const CustomCol = styled(Col)`
  min-width: ${(props) => props.minWidth};
  text-align: center;
  & p {
    text-align: center;
  }
  & div {
    display: inline-block;
  }
`;

const ConfigurationLearnSubscriptionsContainer = () => {
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const navigate = useNavigate();
  const location = useLocation();
  const routes = useRoutes();

  const { isSystemAdmin, licenceService: { assignLicence } = {} } = permissions;

  const {
    courses,
    bundles,
    loading: isCoursesFetching,
  } = useGetAvailableCoursesQuery({
    skip: isSystemAdmin,
  });

  const [accountsSearch, setAccountsSearch] = useState();

  const {
    data: { getAccounts: { rows: accounts = [] } = {} } = {},
    error,
    loading: isAccountsFetching,
    refetch,
  } = useQuery(GET_ACCOUNTS, {
    variables: {
      limit: 100,

      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: accountsSearch,
            field: 'name',
          },
        ],
      },
    },
    skip: !isSystemAdmin,
  });

  const { productLicences = [], loading: isLicencesCountFetching } =
    useGetActivatedLicencesCountByAccountIdQuery(accountId);
  const licencesCountMap = new Map(productLicences.map(({ productId, count }) => [productId, count]));

  const data = isSystemAdmin ? accounts : courses;
  const [search, setSearchCourses, searchedCourses] = useSearch(data || [], ['name']);

  const [, setSearchBundles, searchedBundles] = useSearch(bundles || [], ['title']);

  const setSearch = (value) => {
    setSearchCourses(value);

    if (isSystemAdmin) {
      setAccountsSearch(value);
    }

    if (assignLicence) {
      setSearchBundles(value);
    }
  };

  const isCourseRows = !isSystemAdmin;
  const isLoading = isAccountsFetching || isCoursesFetching || isLicencesCountFetching;

  return (
    <>
      <Col name="ConfigurationAccountsContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationLearnSubscriptionsContainer__Controls"
          icon="briefcase"
          iconColor="primary"
          title="Engage Subscriptions"
        >
          <Col container item>
            <Search
              name="ConfigurationLearnSubscriptionsContainer__Controls__Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              hideButton
            />
          </Col>
        </ContainerHeader>

        {isLoading && <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />}
        {error && (
          <ErrorMessage error={error}>
            {({ title, body, icon }) => (
              <ContainerError
                name="ConfigurationAccountsContainer__Error"
                icon={icon}
                title={title}
                body={body}
                retry={refetch}
              />
            )}
          </ErrorMessage>
        )}

        {!isLoading && !error && !searchedCourses.length && !searchedBundles.length && (
          <NoResults
            name="ConfigurationLearnSubscriptionsContainer__NoResults"
            title="There are no results for this search."
          />
        )}

        {assignLicence && (
          <>
            {searchedBundles.map((element) => (
              <SubscriptionRowStyled key={element.id} name={`account-row--${accountId}`} container itemAlign="center">
                <SubscriptionRowPictureStyled
                  name={`account-row__profile-picture--${accountId}`}
                  size="xs"
                  accountName="B"
                  color="secondary"
                />

                <Col container item>
                  <Heading inline name={`account-row__name--${accountId}`} size="md">
                    {element.title}
                  </Heading>
                </Col>

                <CustomCol minWidth="120px">
                  <Button
                    size="sm"
                    onClick={() =>
                      navigate(
                        makeUrl(`/${routes.configuration.root}/${routes.configuration.menu.learn.assignLicense}`, {
                          productId: element.id,
                          productType: 'bundle',
                          accountId,
                        })
                      )
                    }
                  >
                    <LicencesCount id={element.id} licencesCountMap={licencesCountMap} />
                  </Button>
                </CustomCol>
              </SubscriptionRowStyled>
            ))}

            <SeparateLine />
          </>
        )}

        {data &&
          !!searchedCourses.length &&
          searchedCourses
            .slice()
            .sort((accountA, accountB) => alphabetiseStrings(accountA.name, accountB.name))
            .map((account) => {
              /**
               * why do we use an account row
               * for rendering subscriptions and accounts
               * it is weird and unacceptable approach
               * TODO: refactor it in the future
               */
              return (
                <AccountRow
                  key={account.id}
                  licencesCountMap={licencesCountMap}
                  accountId={isCourseRows ? accountId : account.id}
                  businessName={account.name}
                  profileImage={account.profile}
                  record={account}
                  isCourseRows={isCourseRows}
                  permissions={permissions}
                  path={location.pathname}
                />
              );
            })}
      </Col>
    </>
  );
};

export default ConfigurationLearnSubscriptionsContainer;
