import { useCallback } from 'react';
import styled from 'styled-components';
import { Col } from 'rio-ui-components';
import { useParams, useNavigate } from 'react-router-dom';
import ContainerHeader from '../../../components/ContainerHeader';
import { SurveyTile, InfoButton, TileContainer, isSubmissionReviewable } from '../../../components/Surveys';
import { PageFailed, NotFound } from '../../../components/Errors';
import { useGetMyContributorOverview, GetMyContributorOverviewResponse } from './queries';
import { useCurrentAccountId } from '../../../hooks';
import { Async } from '../../../components/Async';

const Page = styled(Col)`
  display: flex;
  height: 100%;
`;
const Tiles = styled(TileContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ActionsColumn = styled(Col)`
  justify-content: flex-end;
`;

export function MyContributorContainer() {
  const { contributorId } = useParams();
  const [contributorAccountId, contributorUserId] = contributorId!.split(':');
  const accountId = useCurrentAccountId();
  const result = useGetMyContributorOverview(accountId, contributorAccountId!, contributorUserId);
  const retry = useCallback(() => result.refetch({ accountId }), [accountId, result]);
  const navigate = useNavigate();
  return (
    <Async<GetMyContributorOverviewResponse>
      result={result}
      error={useCallback(
        (error: Error) =>
          error.message.includes('Not found') ? <NotFound error={error} /> : <PageFailed error={error} retry={retry} />,
        [retry]
      )}
      data={useCallback(
        ({ getMySurveyContributorById: { account, surveys } }: GetMyContributorOverviewResponse) => (
          <Page>
            <ContainerHeader
              icon={['rio', 'clipboard-check-custom']}
              iconColor="quaternary"
              title={account.name}
              breadcrumb={[
                { to: '../sent/overview', title: 'Surveys sent' },
                { to: '../sent/contributors', title: 'Contributors' },
                { title: account.name },
              ]}
            >
              <ActionsColumn span={9} container>
                <InfoButton>More information</InfoButton>
              </ActionsColumn>
            </ContainerHeader>
            <Tiles>
              {surveys.map((c) => {
                const reviewable = isSubmissionReviewable(c.submission?.status);

                return (
                  <SurveyTile
                    key={c.id}
                    survey={c}
                    onClick={() => {
                      if (reviewable && c.submission) {
                        navigate(`./submission/${c.submission.id}`);
                      }
                    }}
                    disabled={!reviewable}
                  />
                );
              })}
            </Tiles>
          </Page>
        ),
        [navigate]
      )}
    />
  );
}
