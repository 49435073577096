import { useMutation } from "@apollo/client";
import { GET_MARKET_CARBON_CONVERSION_FACTORS, DELETE_MARKET_CARBON_CONVERSION_FACTOR } from './index.queries';
import { useGetMarketCarbonConversionFactorsVariables } from './useGetMarketCarbonConversionFactors';

export function useDeleteMarketCarbonConversionFactor({ onComplete }: { onComplete: Function }) {
  const readVariables = useGetMarketCarbonConversionFactorsVariables();
  return useMutation(DELETE_MARKET_CARBON_CONVERSION_FACTOR, {
    update: (dataProxy, fetchResult) => {
      const newFactors = fetchResult.data?.deleteMarketCarbonConversionFactor;
      dataProxy.writeQuery({
        query: GET_MARKET_CARBON_CONVERSION_FACTORS,
        variables: readVariables,
        data: {
          getMarketCarbonConversionFactors: newFactors
        }
      });
      onComplete(newFactors);
    }
  });
}
