import { FormattedMessage } from 'react-intl';
import { Text, styled } from '@rio/ui-components';

const TextStyle = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
  margin-bottom: 16px;
`;

const statusText = {
  PDF: {
    NOTSTARTED: '',
    COMPLETE: 'You have downloaded this file.',
  },
  EMBED: {
    NOTSTARTED: '',
    INPROGRESS: 'pages.coursesPreview.statuses.embed.inprogress',
    COMPLETE: 'You have completed this course.',
  },
  EXTERNAL: {},
  IFRAME: {},
  DOWNLOAD: {},
};

const CourseStatus = ({ courseProgress, handleType }) => {
  const text = statusText[handleType][courseProgress];

  return (
    <>
      <TextStyle typescale="body" size="large">
        {text && <FormattedMessage id={text} />}
      </TextStyle>
    </>
  );
};

export default CourseStatus;
