import { useMemo } from 'react';
import { useReportingQuestions } from './useReportingQuestions';
import { ManagementSystem } from '@rio/rio-types';
import {
  ManagementSystemChapterLayout,
  MarkdownSynopsis,
  JsxSynopsis,
  ButtonInfo,
  ContentInfo,
  DocumentInfo
} from '../ManagementSystemChapterLayout';
import { ManagementSystemPage } from '../../types';
import { ReportingFrameworkForm } from './ReportingFrameworkForm';

interface ReportingFrameworkLayoutProps {
  framework: ManagementSystem;
  page: ManagementSystemPage;
  synopsis?: JsxSynopsis | MarkdownSynopsis;
  buttons?: ButtonInfo[];
  content?: ContentInfo;
  documents?: DocumentInfo;
  title?: string;
}

export function ReportingFrameworkLayout({
  framework,
  page,
  buttons,
  title,
  synopsis,
  content,
  documents
}: ReportingFrameworkLayoutProps) {
  const [questions, onAnswer, { loading }] = useReportingQuestions(framework.id, page.id);

  const header = useMemo(
    () => ({
      buttons,
      title: title || page.tocTitle
    }),
    [title, page.tocTitle, buttons]
  );

  const defaultSynopsis = useMemo(
    (): MarkdownSynopsis => ({
      mode: 'markdown',
      content: page.synopsis,
      editable: false
    }),
    [page.synopsis]
  );
  const defaultContent = useMemo(
    () => ({
      loading,
      render: () => <ReportingFrameworkForm framework={framework} questions={questions} onAnswer={onAnswer} />
    }),
    [questions, onAnswer, loading, framework]
  );

  const defaultDocuments = useMemo(
    (): DocumentInfo => ({
      records: page.documents || [],
      readonly: false,
    }),
    [page.documents]
  );

  return (
    <ManagementSystemChapterLayout
      header={header}
      synopsis={synopsis || defaultSynopsis}
      content={content || defaultContent}
      documents={documents || defaultDocuments}
      managementSystem={framework}
      page={page}
    />
  );
}
