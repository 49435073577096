import React, { createContext, useContext, useState } from 'react';

interface SurveyContextType {
  expanded: boolean;
  setExpanded: (val: boolean) => void;
}

const defaultValue = {
  expanded: false,
  setExpanded: (_: boolean) => {},
};

const SurveyContext = createContext<SurveyContextType>(defaultValue);

export const SurveyProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  return <SurveyContext.Provider value={{ expanded, setExpanded }}>{children}</SurveyContext.Provider>;
};

export const useSurveyContext = () => useContext(SurveyContext);
