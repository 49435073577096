import { useQuery } from '@apollo/client';
import { GET_ALL_KBS } from '../containers/ConfigurationContainer/ConfigurationKnowledgeBaseContainer/index.queries';

export function useRioKnowledgeBases(accountId) {
  const { data, loading } = useQuery(GET_ALL_KBS, {
    variables: {
      accountId
    }
  });

  const results = data?.getAllKnowledgeBases || [];

  return { data: results, loading };
}
