import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tab } from 'rio-ui-components';

export const MainContainer = styled.div`
  padding: 32px 24px;
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  height: fit-content;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary.normal.text};
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
`;

export const TopInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.regularText.text};
  font-size: ${(props) => props.theme.fonts.sm.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  &:hover {
    cursor: pointer;
  }
`;

export const AboutIconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 224px;
`;

export const TabsButtonContainer = styled.div`
  display: block;
  border-bottom: 1px solid #dedede;
  margin-right: 22px;

  &.Mui-selected {
    color: #000 !important;
  }

  button {
    font-size: ${(props) => props.theme.fonts.sm.size};
    padding: 0;
    text-align: center;
    max-width: 112px;
    width: 100%;
    color: #46464f;
    text-transform: none;
  }
`;

export const TabsButtonContainerTwo = styled.div`
  display: flex;

  .Mui-selected {
    color: #2e3eff !important;
  }

  .MuiTabs-indicator {
    background-color: #2e3eff !important;
  }

  button {
    font-size: ${(props) => props.theme.fonts.sm.size};
    padding: 0;
    margin-right: 16px;
    text-align: center;
    max-width: 89px;
    width: 100%;
    color: #46464f;
    text-transform: none;
  }
`;

export const TabsStyled = styled(Tab)`
  &.Mui-selected {
    color: #1b1b1f !important;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TextContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.xs.size};
  text-transform: uppercase;
  vertical-align: middle;
`;

export const RegularTextContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sm.size};
`;

export const HeaderContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.xxl.size};
`;

export const AccordionContainer = styled.div`
  margin-bottom: 15px;

  .MuiAccordionSummary-expandIconWrapper {
    color: ${(props) => props.theme.colors.regularText.text};
  }
`;

export const AccordionSummaryMissingTextContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sm.size};

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${(props) => props.theme.colors.danger.normal.background};
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
`;

export const AccordionSummaryPendingTextContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sm.size};

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${(props) => props.theme.colors.warning.light.background};
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
`;

export const Container = styled.div`
  max-width: 450px;
  flex-shrink: 0;
`;

export const MenuContainer = styled.div`
  max-width: 450px;
  background-color: ${(props) => props.theme.colors.primary.normal.text};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: fit-content;
  margin-bottom: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  font-size: ${(props) => props.theme.fonts.sm.size};
  &:first-child {
    background-color: ${(props) => props.theme.colors.redesignPrimary.background};
    color: ${(props) => props.theme.colors.regularText.text};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.redesignPrimary.background};
    color: ${(props) => props.theme.colors.regularText.text};
    cursor: pointer;
  }
`;

export const LinkStyled = styled(Link)`
  display: block;
  text-decoration: none;
  margin-left: 10px;
  flex-shrink: 3;
`;

export const HideShowIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: flex-start;
  svg {
    font-size: 20px;
  }
`;

type IconContainerParams = {
  isDisabledAccess?: boolean;
};
export const IconContainer = styled.div<IconContainerParams>`
  width: 40px;
  height: 40px;
  color: ${(props) => props.theme.colors.regularText.text};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    props.isDisabledAccess &&
    `
    &:before {
      color: ${props.theme.colors.basic.black};
      background-color: ${props.theme.colors.redesignTertiary.background};
      content: 'Upgrade';
      font-weight: bold;
      text-align: center;
      font-size: 10px;
      padding: 0 3px;
      border-radius: 8px;
      margin-top: -1em;
    }
  `}
  svg {
    font-size: 20px;
  }
`;

export const HideShowContainer = styled.div`
  color: ${(props) => props.theme.colors.regularText.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  svg {
    font-size: 14px;
  }
`;

export const HideShowText = styled(RegularTextContainer)`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

export const ItemContainer = styled.div`
  width: 77px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid #F3F0F4
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1), 0px 8px 12px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 3px rgb(0 0 0 /0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 48px;
    color: ${(props) => props.theme.colors.regularText.text};
  }
`;

export const DataItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 25px;
  gap: 16px;
`;

export const DataItemContainer = styled.div`
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`;

export const IconTextContainer = styled(TextContainer)`
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  color: #000;
`;

export const TooltipText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
