const typeDefs = `
    extend type Query {
        wasteTransactionGridColumns: [Column!]
        electricityTransactionGridColumns: [Column!]
        gasTransactionGridColumns: [Column!]
        transportTransactionGridColumns: [Column!]
        idToken: IdToken!
        loginError: String
        currentAccountId: String
    }
    
    type Column {
        id: String!
        defaultName: String!
        displayName: String!
        display: Boolean!
        width: Int!
        dataType: String!
    }
    
    type IdToken {
        sub: String!
        aud: String!
        event_id: String
        auth_time: Int!
        exp: Int!
        iat: Int!
        email: String!
        roles: [String!]!
        given_name: String
        name: String
        family_name: String
        isAuthed: Boolean
    }

    extend type Mutation {
        setWasteTransactionGridColumns(columns: [Column!]): [Column!]
    }

    type WasteTreatmentProcessHierarchy {
        wasteTreatmentProcessName: String!
        prevention: Number
        preparingForReuse: Number
        recycling: Number
        otherRecovery: Number
        disposal: Number
        reference: string
    }
`;

export default typeDefs;
