import { Scope } from '@rio/rio-types';
import { capitalize } from 'lodash';

import subjectOptions from './TasksGrid/taskSubjects.json';

export function getSubjectItemName(task) {
  if (!task.item) {
    return null;
  }
  switch (task.item?.__typename) {
    case 'Aspect':
      return task.item?.activity?.name;
    case 'Target':
      return task.item?.name;
    case 'Legislation':
      return task.item?.title;
    default:
      return null;
  }
}

export function getSubjectLink(task) {
  return `/tasks/${task.id}`;
}

export function mapSubjectsValuesToLabels(data) {
  return data.map((task) => ({
    ...task,
    subject: task.subject && subjectOptions[task.category].find((i) => i.value === task.subject).label
  }));
}

export const formatScope = (task) => {
  switch (task.scope) {
    case Scope.Location:
      return task.locations.map((location) => location?.name || '');
    case Scope.Tag:
      return task.tags.map(({ tagName }) => tagName);
    case Scope.Account:
    default:
      return 'Organisation';
  }
};
export function priorityValueFormatter(params) {
  const priorityValue = params.value;

  return capitalize(priorityValue);
}

export const formatStatus = (task) => {
  const { isCompleted, dueDate } = task;

  return isCompleted ? 'Completed' : new Date(dueDate) > new Date() ? 'Incompleted' : 'Overdue';
};
