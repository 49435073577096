import React from 'react';
import styled from 'styled-components';
import strip from 'strip';
import { Col, ErrorMessage, ContainerError, MarkdownRenderer, Row, Heading, CircleIcon } from 'rio-ui-components';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ViewCourseButton from '../../../components/ViewCourseButton';
import { NotAvailable, NotLicence } from '../../../components/Errors';
import CourseStatus from './CourseStatus';
import { CourseTypeHandler } from '../../../utils/learn/CourseTypeHandler';
import { learnTourStepsPreview } from '../../../hocs/withTour/steps';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { useGetUserCourseQuery } from '../../../hooks/engage/useGetUserCourseQuery';

const CourseContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  background: ${(props) => props.theme.colors.basic.white};
`;

const CourseImage = styled.div`
  position: relative;
  padding: 0 0 75% 0;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.secondary.dark.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-flex;
  flex: 0 0 auto;
`;

const CourseTextContainer = styled(Col)`
  padding-left: ${(props) => props.theme.geometry.md.spacing};
`;

const CourseHeading = styled(Heading)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const SelectedCoursePreview = ({ bundleId, id }) => {
  const {
    token: { sub: userId },
  } = useUserToken();
  const accountId = useCurrentAccountId();
  const variables = {
    userId,
    userIdNullable: userId,
    accountId,
    courseId: id,
    bundleId,
  };

  const { data: course, loading, error, refetch } = useGetUserCourseQuery(variables);

  if (!course && loading) {
    return <ContainerLoadingIndicator name="CoursePreview__Loading" />;
  }

  if (error) {
    return (
      <ErrorMessage error={error}>
        {({ title, body, icon }) => (
          <ContainerError
            name="CoursePreview__Error"
            icon={icon}
            title={title}
            body={body}
            retry={() => refetch(variables)}
          />
        )}
      </ErrorMessage>
    );
  }
  if (!course) {
    return <NotAvailable error={new Error('Not available')} />;
  }
  if (!course?.licence) {
    return <NotLicence error={new Error('No licence')} />;
  }

  const courseTypeHandler = new CourseTypeHandler(course);
  const handleType = courseTypeHandler.getHandleMethod();

  return (
    <CourseContainer container item>
      <Col item span={4}>
        <CourseImage name="CoursePreview__Thumbnail" src={course.metaData.thumbnail}>
          {!course.metaData.thumbnail && (
            <CircleIconStyled
              name="CoursePreview__Icon"
              height="100px"
              size="lg"
              icon="graduation-cap"
              iconColor="white"
              circleColor="white"
            />
          )}
        </CourseImage>
      </Col>
      <CourseTextContainer item span={8}>
        <CourseHeading size="xxl" weight="light" color="dark">
          {course.name}
          <CourseStatus
            courseProgress={course.userCourseMetaData && course.userCourseMetaData.courseProgress}
            handleType={handleType}
          />
        </CourseHeading>

        {!!course.metaData.description && (
          <MarkdownRenderer name="CoursePreview__MarkDown--Description" source={strip(course.metaData.description)} />
        )}

        <ViewCourseButton
          course={course}
          handleType={handleType}
          downloadLink={course.metaData.link}
          userId={userId}
          courseRefetch={() => refetch(variables)}
          name="StartCourse-Button"
          steps={learnTourStepsPreview}
        />
      </CourseTextContainer>
    </CourseContainer>
  );
};

export default SelectedCoursePreview;
