import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Col, Icon } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  border: ${(props) => (props.hasChildren ? 'none' : `1px solid ${props.theme.colors.overlay.normal.background}`)};
  background-color: ${(props) => props.theme.colors.basic.white};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AddDocumentTile = (props) => {
  const { id, onClick, children, disabled } = props;
  const theme = useTheme();
  return (
    <ContainerStyled
      container
      hasChildren={children}
      item
      key={id}
      disabled={disabled}
      onClick={disabled ? null : onClick}
    >
      {children && <>{children}</>}
      {!children && (
        <IconContainer>
          <Icon icon="plus-circle" size="6x" color={theme.colors.success.normal.background} />
        </IconContainer>
      )}
    </ContainerStyled>
  );
};

export default AddDocumentTile;
