import React from 'react';
import { Modal } from 'rio-ui-components';
import { CreateWasteHierarchies } from './CreateWasteHierarchies';
import ViewWasteHierarchies from './ViewWasteHierarchies';

export const Modals = (props) => {
  const { showCreateModal, onDismiss, onNotification, accountId, viewHierarchyModal, currentWasteIn } = props;
  return (
    <>
      {showCreateModal && (
        <Modal span={4} show name="CreateWasteHierarchyModal" dismissable onDismiss={onDismiss}>
          <CreateWasteHierarchies accountId={accountId} onNotification={onNotification} onDismiss={onDismiss} currentWasteIn={currentWasteIn} />
        </Modal>
      )}

      {viewHierarchyModal.show && (
        <Modal show name="ViewWasteHierarchyModal" dismissable onDismiss={onDismiss} height="auto" maxHeight="90vh">
          <ViewWasteHierarchies accountId={accountId} hierarchy={viewHierarchyModal.hierarchy} />
        </Modal>
      )}
    </>
  );
};
