import { gql } from '@apollo/client';
import { EVALUATION_FRAGMENT } from '../ConfigurationLearnEvaluations/index.queries';
import GET_AVAILABLE_COURSES from '../../../graphql/queries/engage/GetAvailableCourses.query.graphql';

export { GET_AVAILABLE_COURSES };

export const UPLOAD_LEARN_CONTENT = gql`
  mutation UploadLearnContent(
    $accountId: ID
    $url: String
    $contentPath: String
    $id: ID
    $title: String!
    $description: String
    $cpd: Int
    $thumbnail: File!
    $content: File!
    $type: LearnContentType!
    $version: String
    $presignedLinks: [String]
  ) {
    uploadLearnContent(
      accountId: $accountId
      url: $url
      contentPath: $contentPath
      id: $id
      title: $title
      description: $description
      cpd: $cpd
      thumbnail: $thumbnail
      content: $content
      type: $type
      version: $version
      presignedLinks: $presignedLinks
    ) @client {
      status
      fileUploaded
    }
  }
`;

export const GET_PRESIGNED_LEARN_URL = gql`
  query InitiateLearnFileUpload($accountId: ID!, $type: String!, $fileName: String!) {
    initiateLearnFileUpload(accountId: $accountId, type: $type, fileName: $fileName) {
      url
      contentPath
      id
    }
  }
`;

export const GET_PRESIGNED_LEARN_URL_LIST = gql`
  query InitiateLearnFileUpload($accountId: ID!, $type: String!, $files: [ScormFileMetadata!]) {
    initiateScormFileUpload(accountId: $accountId, type: $type, files: $files) {
      url
      contentPath
      id
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query GetAllLearnSubscriptions($accountId: String!) {
    getAllLearnSubscriptions(accountId: $accountId) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }
  }
`;

export const SAVE_SUBSCRIPTIONS = gql`
  mutation SaveLearnSubscriptions(
    $accountId: String!
    $subscriptions: [String!]!
    $bundleShow: [String!]!
    $bundleHidden: [String!]!
    $mandatoryBundleId: String
  ) {
    saveLearnSubscriptions(
      input: {
        accountId: $accountId
        subscriptions: $subscriptions
        bundleShow: $bundleShow
        bundleHidden: $bundleHidden
        mandatoryBundleId: $mandatoryBundleId
      }
    ) {
      subscriptions
      bundleShow
      bundleHidden
      mandatoryBundleId
    }
  }
`;

export const SAVE_LEARN_CONTENT_METADATA = gql`
  mutation SaveLearnContentMetadata(
    $id: ID!
    $title: String!
    $description: String
    $cpd: Int
    $passScore: Int
    $contentPath: String
    $type: LearnContentType!
    $categories: [CourseCategory!]
    $assessmentId: ID
    $reactionId: ID
    $accountId: ID
    $isEngage: Boolean
    $url: String
    $openIframe: Boolean
  ) {
    saveLearnContentMetadata(
      input: {
        id: $id
        title: $title
        description: $description
        contentPath: $contentPath
        type: $type
        cpd: $cpd
        passScore: $passScore
        categories: $categories
        assessmentId: $assessmentId
        reactionId: $reactionId
        accountId: $accountId
        isEngage: $isEngage
        url: $url
        openIframe: $openIframe
      }
    ) {
      id
      accountId
      name
      type
      isEngage
      assessment {
        ...EvaluationFields
      }
      reaction {
        ...EvaluationFields
      }
      metaData {
        viewed
        link
        thumbnail
        description
        categories
        cpd
        passScore
        url
        openIframe
      }
    }
  }
  ${EVALUATION_FRAGMENT}
`;

export const SAVE_LEARN_BUNDLE = gql`
  mutation SaveLearnBundle(
    $id: ID!
    $title: String!
    $description: String!
    $restricted: Boolean!
    $startDate: String
    $endDate: String
    $courses: [ID!]!
    $isEngage: Boolean
  ) {
    saveLearnBundle(
      input: {
        id: $id
        title: $title
        description: $description
        restricted: $restricted
        startDate: $startDate
        endDate: $endDate
        courses: $courses
        isEngage: $isEngage
      }
    ) {
      id
      title
      description
      restricted
      startDate
      endDate
      courses
      isEngage
    }
  }
`;

export const GET_ALL_COURSES_AND_LEARN_BUNDLES = gql`
  query GetAllCoursesAndLearnData {
    getAllCourses {
      isLastPage
      totalItemCount
      data {
        id
        name
        type
        accountId
        isEngage
        reaction {
          ...EvaluationFields
        }
        assessment {
          ...EvaluationFields
        }
        metaData {
          viewed
          link
          thumbnail
          description
          categories
          cpd
          passScore
          url
          openIframe
        }
      }
    }

    getAllLearnBundles {
      id
      title
      description
      restricted
      startDate
      endDate
      isEngage
      courses
    }
  }
  ${EVALUATION_FRAGMENT}
`;
