import { gql } from '@apollo/client';

export const getValue = gql`
  query getValue($key: String!, $courseId: String!) {
    getValue(input: { key: $key, courseId: $courseId })
  }
`;

export const setValue = gql`
  mutation setValue($key: String!, $value: String!, $courseId: String!) {
    setValue(input: { key: $key, value: $value, courseId: $courseId })
  }
`;

export const getAllCourseData = gql`
  query getAllCourseData($courseId: String!) {
    getAllCourseData(input: { courseId: $courseId }) {
      key
      value
    }
  }
`;

export const UPDATE_USER_COURSE_PROGRESS = gql`
  mutation updateUserCourseProgress($userId: ID!, $courseId: ID!, $courseProgress: CourseProgress!) {
    updateUserCourseProgress(input: { userId: $userId, courseId: $courseId, courseProgress: $courseProgress }) {
      lastUpdatedAt
      courseProgress
    }
  }
`;
