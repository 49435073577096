import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import { Text, LoadingIndicator } from 'rio-ui-components';

import TranspositionSection from './TranspositionSection';
import { DEFAULT_TRANSPOSITION_ID, getDefaultTransposition } from './constants';
import { GET_TRANSPOSITION_BY_ID } from '../index.queries';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  height: 100%;
`;

const ViewTranspositionSection = ({ template, onConfirm, headers, type }) => {
  const isDefault = template === DEFAULT_TRANSPOSITION_ID;
  const { data, error, loading } = useQuery(GET_TRANSPOSITION_BY_ID, {
    variables: {
      id: template
    },
    skip: isDefault,
    fetchPolicy: 'network-only'
  });

  if (loading)
    return (
      <CenterContainer>
        <LoadingIndicator name="viewTranspositionSection-loading" size="md" />
      </CenterContainer>
    );
  if (error)
    return (
      <CenterContainer>
        <Text name="viewTranspositionSection-error">error</Text>
      </CenterContainer>
    );
  const transposition = isDefault ? getDefaultTransposition(type) : data.getTranspositionById;
  return (
    <TranspositionSection
      key={template}
      type={type}
      name="viewTranspositionSection-TranspositionSection"
      readOnly
      onSave={() => onConfirm(transposition)}
      headers={headers}
      templateName={transposition.transpositionName}
      transpositions={transposition.columnMapper}
      isHalfHourly={transposition.isHalfHourly}
    />
  );
};

export default ViewTranspositionSection;
