import React, { useState } from 'react';
import styled from 'styled-components';
import * as JsSearch from 'js-search';
import { injectIntl } from 'react-intl';

import { Row, Search, Icon, TextLink } from 'rio-ui-components';

import NoResults from '../../../components/NoResults';
import PageHeader from '../../../components/PageHeader';
import NotificationBanner from '../../../components/NotificationBanner';

import DataSubjectsGrid from './DataSubjectsGrid';
import DataSubjectsList from './DataSubjectsList';
import UserBannersSection from '../../../components/UserBannersSection';
import subjects from './subjects';
import { useIsDemoAccount } from '../../../hooks/useIsDemoAccount';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const DataSubjects = ({ fullAccess, intl }) => {
  const [searchValue, setSearchValue] = useState('');
  const [showGrid, setShowGrid] = useState(true);

  const showDemoSubjects = useIsDemoAccount();

  const applySearch = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('id');
    search.addIndex('title');
    search.addDocuments(data);
    return search.search(term);
  };

  //filter out demo only tiles
  const filtered = !showDemoSubjects ? subjects.filter((subject) => !subject.demo) : subjects;

  const searched = applySearch(filtered, searchValue);
  const results = searched.length > 0;
  return (
    <ContainerStyled name="DataSubjects">
      <PageHeader
        name="DataSubjects"
        title={intl.formatMessage({ id: 'pages.data.heading' })}
        breadcrumbs={[
          { title: intl.formatMessage({ id: 'pages.data.breadcrumbs.one' }) },
          { title: intl.formatMessage({ id: 'pages.data.breadcrumbs.two' }) }
        ]}
        icon="chart-line"
        iconColor="quaternary"
      />

      <ContentContainer>
        <SearchHeader container item itemAlign="center">
          <SearchStyled
            name="DataSubjects__Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            hideButton
          />
          <TextLinkStyled
            name="DataSubjects__ShowGrid"
            size="md"
            color={showGrid ? 'primary' : 'normal'}
            weight={showGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={() => setShowGrid(!showGrid)}
            inline
          >
            <Icon icon="th" />
          </TextLinkStyled>
          <TextLinkStyled
            name="DataSubjects__ShowList"
            size="md"
            color={!showGrid ? 'primary' : 'normal'}
            weight={!showGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={() => setShowGrid(!showGrid)}
            inline
          >
            <Icon icon="th-list" />
          </TextLinkStyled>
        </SearchHeader>

        {!Object.values(fullAccess).some((item) => item === true) && (
          <NotificationBannerStyled
            name="DataContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title={`Data`}
            body={`Currently Data is not included in your subscription, if you’d like access to Data please contact Rio ESG LTD or get in touch with your Business Admin.`}
          />
        )}

        <UserBannersSection id="data" />

        {!!results && showGrid && <DataSubjectsGrid items={searched} fullAccess={fullAccess} />}
        {!!results && !showGrid && <DataSubjectsList items={searched} fullAccess={fullAccess} />}
        {!results && <NoResults name="DataSubjects__NoResults" title="There are no results for this search." />}
      </ContentContainer>
    </ContainerStyled>
  );
};

export default injectIntl(DataSubjects);
