import styled from 'styled-components';
import { SurveySubmissionStatus } from '@rio/rio-types';
import { getStatusColor } from './utils';

interface StatusSpanProps {
  status?: SurveySubmissionStatus;
}

export const StatusSpan = styled.span<StatusSpanProps>`
  position: relative;
  display: inline-block;
  padding-left: 20px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.text.light.text};
  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ status, theme }) => getStatusColor(theme, status)};
  }
`;
