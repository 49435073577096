import React, { Component } from 'react';
import { Col, LoadingIndicator, Search, ErrorMessage, ContainerError } from 'rio-ui-components';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import * as JsSearch from 'js-search';

import NoResults from '../../../../components/NoResults';
import ContainerHeader from '../../../../components/ContainerHeader';
import SubscriptionRows from './SubscriptionRows';
import { GET_ALL_DASHBOARDS, GET_ALL_SUBSCRIPTIONS } from './index.queries';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

class SubscriptionSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        value: '',
        error: ''
      }
    };
  }

  handleSearchChange = (event) => {
    this.setState({ search: { ...this.state.search, value: event.target.value } });
  };

  search = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('id');
    search.addIndex('name');
    search.addDocuments(data);
    return search.search(term);
  };

  render() {
    const { title, accountId, permissions } = this.props;
    return (
      <>
        <Col name="ConfigurationAccountsContainer" container fullHeight>
          <ContainerHeader
            name="ConfigurationLearnSubscriptionsContainer__Controls"
            icon="briefcase"
            iconColor="primary"
            title={title}
          >
            <Col container item>
              <Search
                name="ConfigurationLearnSubscriptionsContainer__Controls__Search"
                value={this.state.search.value}
                onChange={this.handleSearchChange}
                hideButton
              />
            </Col>
          </ContainerHeader>
          <Query query={GET_ALL_SUBSCRIPTIONS} variables={{ accountId }} fetchPolicy="network-only">
            {({ loading, error, data: dataSubscriptions, refetch }) => {
              if (error) {
                return (
                  <ErrorMessage error={error}>
                    {({ title, body, icon }) => (
                      <ContainerError
                        name="ConfigurationLearnSubscriptionsContainer__Error"
                        icon={icon}
                        title={title}
                        body={body}
                        retry={refetch}
                      />
                    )}
                  </ErrorMessage>
                );
              }
              if (loading) {
                return (
                  <CenterContainer>
                    <LoadingIndicator size="md" />
                  </CenterContainer>
                );
              }
              return (
                <Query query={GET_ALL_DASHBOARDS}>
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return (
                        <ErrorMessage error={error}>
                          {({ title, body, icon }) => (
                            <ContainerError
                              name="ConfigurationLearnSubscriptionsContainer__Error"
                              icon={icon}
                              title={title}
                              body={body}
                              retry={refetch}
                            />
                          )}
                        </ErrorMessage>
                      );
                    }
                    if (loading) {
                      return (
                        <CenterContainer>
                          <LoadingIndicator size="md" />
                        </CenterContainer>
                      );
                    }

                    const allDashboards = Array.from(new Set(data.getAllDashboards));
                    const searched = this.search(allDashboards, this.state.search.value);
                    if (!searched.length)
                      return (
                        <NoResults
                          name="ConfigurationUsersContainer__NoResults"
                          title="There are no results for this search."
                        />
                      );

                    return (
                      <SubscriptionRows
                        permissions={permissions}
                        dashboards={searched}
                        accountId={accountId}
                        selectedDashboards={dataSubscriptions.getDashboardSubscriptions}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </Col>
      </>
    );
  }
}

export default SubscriptionSelector;
