"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodeText = exports.isElement = void 0;
function isElement(node) {
    if (node && typeof node === 'object') {
        return 'type' in node;
    }
    return false;
}
exports.isElement = isElement;
function getNodeText(node) {
    if (typeof node === 'string') {
        return node;
    }
    if (typeof node === 'number' && !Number.isNaN(node)) {
        return node.toString();
    }
    if (node instanceof Array) {
        return node.map(getNodeText).join('');
    }
    if (isElement(node)) {
        return getNodeText(node.props.children);
    }
    return '';
}
exports.getNodeText = getNodeText;
