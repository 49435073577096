import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Target, TargetInput, TargetTopic, NamedEntity, Maybe, NormaliserType } from '@rio/rio-types';
import { Label, Col, Row, Modal, Heading, TextInput, DatePicker, Button, Select, Text } from 'rio-ui-components';
import { validate } from './validate';
import { LocationSelect } from '../../../components/LocationSelect';
import { TagSelect } from '../../../components/TagSelect';
import { scopeOptions, TAG, LOCATION } from '../../../constants/scopes';
import { unitsByType } from '../../../constants/normaliserTypes';
import GET_TARGET_TYPES from '../../../graphql/queries/targets/GetTargetTypes.query.graphql';
import GET_TARGET_TOPICS from '../../../graphql/queries/targets/GetTargetTopics.query.graphql';
import { GET_NORMALISER_TYPES, GET_LOCATION_BY_ID } from './index.queries';
import { alphabetiseByField } from '../../../utils/alphabetise';
import { toDateObj } from '../../../utils/formatDate';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const Form = styled.form`
  padding: 32px;
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonColStyled = styled(ColStyled)`
  flex-basis: 50%;
`;
const ExpiryDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -200px;
    left: 50%;
  }
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;

interface Unit {
  label: string;
  value: {
    normaliserTypeId: Maybe<string>;
    isRelative: boolean;
  };
}

const generateUnits = (
  topics?: { getTargetTopics: TargetTopic[] },
  normaliserTypes?: { getNormaliserTypes: NormaliserType[] },
  selectedTopic?: string,
  isReduceTarget?: boolean
): Unit[] => {
  if (!topics || !normaliserTypes || !selectedTopic) {
    return [];
  }

  const topic = topics?.getTargetTopics.find(({ id }) => id === selectedTopic);
  const units: Unit[] = [{ label: topic?.unit?.name || '', value: { normaliserTypeId: null, isRelative: false } }];

  if (isReduceTarget) {
    units.push({ label: '%', value: { normaliserTypeId: null, isRelative: true } });
  }

  normaliserTypes.getNormaliserTypes
    .slice()
    .sort(alphabetiseByField('name'))
    .forEach((normaliserType) => {
      units.push({
        label: `${topic?.unit?.name || ''}/${unitsByType[normaliserType.name as keyof typeof unitsByType]}`,
        value: { normaliserTypeId: normaliserType.id, isRelative: false }
      });
      if (isReduceTarget) {
        units.push({
          label: `%/${unitsByType[normaliserType.name as keyof typeof unitsByType]}`,
          value: { normaliserTypeId: normaliserType.id, isRelative: true }
        });
      }
    });

  return units;
};

interface TargetModalProps {
  editedTarget?: Target;
  errors: string[];
  accountId: string;
  title: string;
  submitTitle: string;
  onDismiss(): void;
  onSubmit(input: TargetInput): void;
  readOnly?: boolean;
}

type Errors = {
  [P in keyof TargetInput]?: Maybe<string>;
} & { unit?: Maybe<string> };

const TargetModal = ({
  accountId,
  title,
  submitTitle,
  onDismiss,
  onSubmit,
  readOnly,
  errors: serverErrors,
  editedTarget
}: TargetModalProps) => {
  const defaultTarget = {
    accountId,
    name: editedTarget?.name,
    scope: editedTarget?.scope,
    locationId: editedTarget?.locationId,
    tagId: editedTarget?.tagId,
    typeId: editedTarget?.type?.id,
    topicId: editedTarget?.topic?.id,
    normaliserTypeId: editedTarget?.normaliserType?.id || null,
    value: editedTarget?.value,
    isRelative: editedTarget?.isRelative || false,
    baselineStartDate: editedTarget?.baselineStartDate,
    baselineEndDate: editedTarget?.baselineEndDate,
    startDate: editedTarget?.startDate,
    endDate: editedTarget?.endDate
  };

  const [errors, setErrors] = useState<Errors>({});
  const [target, setTarget] = useState(defaultTarget);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: targetTypesData }: { data?: { getTargetTypes: NamedEntity[] } } = useQuery(GET_TARGET_TYPES);
  const targetTypes =
    targetTypesData?.getTargetTypes
      ?.map(({ id, name }) => ({ label: name, value: id }))
      .sort(alphabetiseByField('label')) || [];

  const { data: targetTopicsData }: { data?: { getTargetTopics: TargetTopic[] } } = useQuery(GET_TARGET_TOPICS);
  const targetTopics =
    targetTopicsData?.getTargetTopics
      ?.map(({ id, name }) => ({ label: name, value: id }))
      .sort(alphabetiseByField('label')) || [];

  const { data: normaliserTypesData }: { data?: { getNormaliserTypes: NormaliserType[] } } =
    useQuery(GET_NORMALISER_TYPES);

  const { data: locationData } = useQuery(GET_LOCATION_BY_ID, {
    variables: { id: editedTarget?.locationId },
    skip: !editedTarget?.locationId
  });

  const isReduceTarget =
    !!target.typeId && target.typeId === targetTypes.find(({ label }) => label === 'Reduce')?.value;

  const units = generateUnits(targetTopicsData, normaliserTypesData, target.topicId, isReduceTarget);
  const selectedUnit = units.find(
    ({ value }) => value.isRelative === target.isRelative && value.normaliserTypeId === target.normaliserTypeId
  );

  return (
    <Modal size="md" onDismiss={onDismiss} dismissable show>
      <Form>
        <HeaderStyled>{title}</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              type="text"
              disabled={isSubmitting}
              value={target.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTarget({ ...target, name: e.target.value });
                setErrors({ ...errors, name: null });
              }}
              name="name"
              error={errors.name}
              box
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Scope</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={scopeOptions}
              value={target.scope}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setTarget({ ...target, scope: e.target.value as Target['scope'], tagId: null, locationId: null });
                setErrors({ ...errors, scope: null, tagId: null, locationId: null });
              }}
              error={errors.scope}
            />
          </ColStyled>
        </Row>
        {target.scope === LOCATION && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Location</LabelStyled>
              </LabelContainerStyled>
              <LocationSelect
                value={target.locationId}
                onChange={(e) => {
                  setTarget({ ...target, locationId: e.target.value });
                  setErrors({ ...errors, locationId: null });
                }}
                error={errors.locationId}
                selectedOption={locationData?.getLocationById}
              />
            </ColStyled>
          </Row>
        )}
        {target.scope === TAG && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Tag</LabelStyled>
              </LabelContainerStyled>
              <TagSelect
                accountId={accountId}
                value={target.tagId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setTarget({ ...target, tagId: e.target.value });
                  setErrors({ ...errors, tagId: null });
                }}
                error={errors.tagId}
              />
            </ColStyled>
          </Row>
        )}
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Type</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={targetTypes}
              value={target.typeId}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setTarget({ ...target, typeId: e.target.value, isRelative: false });
                setErrors({ ...errors, typeId: null });
              }}
              error={errors.typeId}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Topic</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={targetTopics}
              value={target.topicId}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setTarget({ ...target, topicId: e.target.value });
                setErrors({ ...errors, topicId: null });
              }}
              error={errors.topicId}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Value</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              type="number"
              disabled={isSubmitting}
              value={target.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTarget({ ...target, value: parseInt(e.target.value) });
                setErrors({ ...errors, value: null });
              }}
              name="value"
              error={errors.value}
              box
            />
          </ColStyled>
        </Row>
        {target.topicId && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Unit</LabelStyled>
              </LabelContainerStyled>
              <Select
                options={units}
                value={selectedUnit}
                onChange={(e: { target: { value: { normaliserTypeId: string; isRelative: boolean } } }) => {
                  setTarget({ ...target, ...e.target.value });
                  setErrors({ ...errors, unit: null });
                }}
                error={errors.unit}
              />
            </ColStyled>
          </Row>
        )}
        {isReduceTarget && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Baseline From</LabelStyled>
              </LabelContainerStyled>
              <ExpiryDatePicker
                onDayClick={(date: Date) => {
                  setTarget({
                    ...target,
                    baselineStartDate: date.toISOString()
                  });

                  setErrors({ ...errors, baselineStartDate: null });
                }}
                name="reviewDate"
                placeholder="YYYY/MM/DD"
                selectedDate={toDateObj(target.baselineStartDate)}
                disabledDatesAfter={toDateObj(target.baselineEndDate) || new Date()}
                error={errors.baselineStartDate}
                readOnly={readOnly}
                disabled={isSubmitting || readOnly}
                box
              />
            </ColStyled>
          </Row>
        )}
        {isReduceTarget && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Baseline To</LabelStyled>
              </LabelContainerStyled>
              <ExpiryDatePicker
                onDayClick={(date: Date) => {
                  setTarget({
                    ...target,
                    baselineEndDate: date.toISOString()
                  });
                  setErrors({ ...errors, baselineEndDate: null });
                }}
                name="reviewDate"
                placeholder="YYYY/MM/DD"
                selectedDate={toDateObj(target.baselineEndDate)}
                disabledDatesBefore={toDateObj(target.baselineStartDate)}
                disabledDatesAfter={new Date()}
                error={errors.baselineEndDate}
                readOnly={readOnly}
                disabled={isSubmitting || readOnly}
                box
              />
            </ColStyled>
          </Row>
        )}
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Target Start</LabelStyled>
            </LabelContainerStyled>
            <ExpiryDatePicker
              onDayClick={(date: Date) => {
                setTarget({
                  ...target,
                  startDate: date.toISOString()
                });
                setErrors({ ...errors, startDate: null });
              }}
              name="reviewDate"
              placeholder="YYYY/MM/DD"
              selectedDate={toDateObj(target.startDate)}
              disableDatesAfter={toDateObj(target.endDate)}
              error={errors.startDate}
              readOnly={readOnly}
              disabled={isSubmitting || readOnly}
              box
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Target End</LabelStyled>
            </LabelContainerStyled>
            <ExpiryDatePicker
              onDayClick={(date: Date) => {
                setTarget({
                  ...target,
                  endDate: date.toISOString()
                });
                setErrors({ ...errors, endDate: null });
              }}
              name="reviewDate"
              placeholder="YYYY/MM/DD"
              selectedDate={toDateObj(target.endDate)}
              disabledDatesBefore={toDateObj(target.startDate)}
              error={errors.endDate}
              readOnly={readOnly}
              disabled={isSubmitting || readOnly}
              box
            />
          </ColStyled>
        </Row>
        {serverErrors.length > 0 && (
          <Row container>
            {serverErrors.map((error) => (
              <Text size="md" color="danger" key={error}>
                {error}
              </Text>
            ))}
          </Row>
        )}
        {!readOnly && (
          <Row container>
            <ButtonColStyled item grow="1">
              <ButtonStyled
                disabled={isSubmitting}
                onClick={async (e: SubmitEvent) => {
                  e.preventDefault();
                  const errors = validate(target, targetTypes);
                  if (Object.keys(errors).length) {
                    setErrors(errors);
                  } else {
                    setIsSubmitting(true);
                    await onSubmit(target as TargetInput);
                    setIsSubmitting(false);
                  }
                }}
              >
                {submitTitle}
              </ButtonStyled>
            </ButtonColStyled>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default TargetModal;
