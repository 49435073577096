import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../../types';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';

interface CarbonDisclosureRisksAndOpportunitiesProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureRisksAndOpportunities({
  page,
  framework
}: CarbonDisclosureRisksAndOpportunitiesProps) {
  const buttons = useMemo(
    () => [
      {
        title: 'View guidance',
        color: 'primary',
        onClick: () => {
          window.open('https://www.cdp.net/en/guidance', 'noopener', 'noreferrer');
        }
      }
    ],
    []
  );
  return <ReportingFrameworkLayout page={page} framework={framework} buttons={buttons} />;
}
