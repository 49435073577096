import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'rio-ui-components';
import TemplateDetails from './TemplateDetails';
import { UPDATE_TEMPLATE } from './index.queries';
import { v4 as uuid } from 'uuid';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const UpdateTemplateModal = ({ onDismiss, onComplete, selectedTemplate }) => {
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: () => {
      onComplete({
        message: 'Template has been updated.',
        colour: 'success'
      });
      onDismiss();
    },
    onError: () => {
      onComplete({
        message: 'Something went wrong! Please try again later.',
        colour: 'danger'
      });
    }
  });

  const [template, setTemplate] = useState(selectedTemplate);

  const handleSubmit = () => {
    updateTemplate({
      variables: {
        id: uuid(),
        ...template
      },
      refetchQueries: ['GetAllTemplates']
    });
  };

  return (
    <Modal size="md" show onDismiss={() => onDismiss()} height="auto" maxHeight="90vh">
      <ContainerStyled>
        <TemplateDetails heading="Update Template" template={template} setTemplate={setTemplate} />
        <Row container align="between">
          <ColStyled item>
            <Button disabled={!(template.name, template.parserId)} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </ColStyled>
        </Row>
      </ContainerStyled>
    </Modal>
  );
};

export default UpdateTemplateModal;
