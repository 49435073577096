import { CreateOptionModal } from '../CreateOptionModal';
import { TYPES } from '../../constants/aspects';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';

function CreateDepartmentModal({ showCreateDepartment, setShowCreateDepartment, passedAccountId, onComplete }) {
  const currentAccountId = useCurrentAccountId();
  const accountId = passedAccountId || currentAccountId;
  return (
    showCreateDepartment && (
      <CreateOptionModal
        accountId={accountId}
        type={TYPES.DEPARTMENT}
        onDismiss={() => setShowCreateDepartment(false)}
        onCompleted={(data, newDepartmentName) => {
          setShowCreateDepartment(false);
          onComplete(data, newDepartmentName);
        }}
      />
    )
  );
}

export { CreateDepartmentModal };
