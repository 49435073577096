import { useState, useRef } from 'react';
import { Banner, LearnContentType } from '@rio/rio-types';
import { Mutation } from '@apollo/client/react/components';
import { Row, Col, Heading, TextInput, Select, Label, Notification, Button, ImageEditor } from 'rio-ui-components';
import styled from 'styled-components';
import { SAVE_BANNER, DELETE_BANNER } from './index.queries';
import { isUrl } from '../../../utils/validation';
import { useApolloClient } from '@apollo/client';
import { Nullable } from '~/types';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const RowStyled = styled(Row)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const LabelStyled = styled(Label)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonStyled = styled(Button)`
  width: 656px;
`;

const availableBanners = [
  {
    value: LearnContentType.Scorm,
    label: 'Scorm',
  },
  {
    value: LearnContentType.Video,
    label: 'Video',
  },
  {
    value: 'HYPERLINK',
    label: 'Hyperlink',
  },
];

function validateBanner(banner) {
  const problems = {
    thumbnail: !banner.thumbnail ? 'Please upload a thumbnail picture' : null,
    url: !isUrl(banner.url) ? (!banner.url.trim() ? 'The url is required' : 'Incorrect url format') : null,
    type: !banner.type ? 'Please select the type of the banner' : null,
    get any() {
      const { thumbnail, url, type } = problems;
      return Object.values({ thumbnail, url, type }).some(Boolean);
    },
  };
  return problems;
}

type ProblemsState = {
  type?: Nullable<string>;
  url?: Nullable<string>;
  name?: Nullable<string>;
  thumbnail?: Nullable<string>;
};

export type AccountBannerUploadProps = {
  banner: Banner;
  onBannerSaved: () => void;
  onBannerDeleted?: () => void;
  showDelete?: boolean;
};

function AccountBannerUpload({ banner, onBannerSaved, onBannerDeleted, showDelete = false }: AccountBannerUploadProps) {
  const [problems, setProblems] = useState<ProblemsState>({});
  const editorRef = useRef<any>(null);
  const resetProblems = () => setProblems({});

  const client = useApolloClient();

  const [mutatedBanner, setMutatedBanner] = useState(banner);
  return (
    <Mutation mutation={SAVE_BANNER} onCompleted={onBannerSaved}>
      {(uploadBanner) => (
        <Mutation
          mutation={DELETE_BANNER}
          update={() => {
            client.clearStore();
            editorRef.current.setState({
              image: '',
            });
          }}
          onCompleted={onBannerDeleted}
        >
          {(deleteBanner) => (
            <Container id="upload-account-banner">
              {problems.thumbnail && (
                <Notification
                  id={`validate-thumbnail__notification`}
                  name={`validate-thumbnail__notification`}
                  color="danger"
                  show
                >
                  {problems.thumbnail}
                </Notification>
              )}
              <HeaderStyled size="lg">Add/Edit Banner</HeaderStyled>
              <Row container>
                <LabelStyled for="upload-account-banner-url">Name</LabelStyled>
              </Row>
              <RowStyled container>
                <TextInput
                  onChange={(e) => {
                    setMutatedBanner((b) => {
                      return {
                        ...b,
                        name: e.target.value,
                      };
                    });
                    resetProblems();
                  }}
                  id="upload-account-banner-name"
                  value={mutatedBanner.name}
                  error={problems.name}
                  required
                  box
                />
              </RowStyled>
              <Row container>
                <LabelStyled for="upload-account-banner-url">URL</LabelStyled>
              </Row>
              <RowStyled container>
                <TextInput
                  onChange={(e) => {
                    setMutatedBanner((b) => {
                      return {
                        ...b,
                        url: e.target.value,
                      };
                    });
                    resetProblems();
                  }}
                  id="upload-account-banner-url"
                  value={mutatedBanner.url}
                  error={problems.url}
                  required
                  box
                />
              </RowStyled>
              <Row container>
                <LabelStyled for="upload-account-banner-type">Type</LabelStyled>
              </Row>
              <RowStyled container>
                <Select
                  id="upload-account-banner-type"
                  placeholder="Select banner type..."
                  onChange={(e) => {
                    const { value } = e.target;
                    setMutatedBanner((b) => {
                      return {
                        ...b,
                        type: value,
                      };
                    });
                    if (value) {
                      resetProblems();
                    }
                  }}
                  value={mutatedBanner.type}
                  options={availableBanners}
                  error={problems.type}
                  required
                />
              </RowStyled>
              <Row container>
                <LabelStyled for="upload-account-banner-type">Thumbnail</LabelStyled>
              </Row>
              <RowStyled distribution="center" container>
                <Col>
                  <ImageEditor
                    width={616}
                    height={75}
                    border={20}
                    defaultImage={mutatedBanner.thumbnail}
                    ref={editorRef}
                    onSave={(thumbnail) => {
                      const bannerToSave = {
                        ...mutatedBanner,
                        thumbnail,
                      };
                      const validationResult = validateBanner(bannerToSave);
                      setProblems(validationResult);
                      if (!validationResult.any) {
                        uploadBanner({
                          variables: {
                            input: bannerToSave,
                          },
                        });
                      }
                    }}
                    acceptedFormats={['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']}
                    outputFormat={{ format: 'image/jpeg', width: 1553, height: 189, background: '#e8e9ec' }}
                  />
                </Col>
              </RowStyled>
              <Row distribution="center" container>
                <ButtonStyled
                  color="warning"
                  onClick={() => {
                    setMutatedBanner((b) => {
                      return {
                        ...b,
                        visible: !b.visible,
                      };
                    });
                  }}
                >
                  {mutatedBanner.visible ? 'Hide Banner' : 'Show Banner'}
                </ButtonStyled>
              </Row>
              {showDelete && (
                <Row distribution="center" container style={{ marginTop: '1rem' }}>
                  <ButtonStyled
                    color="danger"
                    onClick={() => {
                      const yes = window.confirm('Are you sure you want to reset the banner to its default state?');
                      if (yes) {
                        deleteBanner({
                          variables: {
                            accountId: banner.accountId,
                            id: banner.id,
                          },
                        });
                      }
                    }}
                  >
                    Delete Banner
                  </ButtonStyled>
                </Row>
              )}
            </Container>
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

export default AccountBannerUpload;
