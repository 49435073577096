import { gql } from '@apollo/client';

export const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($id: ID!, $accountId: ID!, $file: File!, $fileName: String!, $key: String!) {
    uploadDocument(id: $id, accountId: $accountId, file: $file, fileName: $fileName, key: $key) @client {
      status
      fileUploaded
    }
  }
`;

export const CREATE_DOCUMENT_RECORD = gql`
  mutation CreateDocumentRecord($input: CreateDocumentRecordInput!) {
    createDocumentRecord(input: $input) {
      id
      link
    }
  }
`;

export const DELETE_DOCUMENT_RECORDS = gql`
  mutation DeleteDocumentRecords($ids: [ID!]!) {
    deleteDocumentRecords(ids: $ids)
  }
`;
