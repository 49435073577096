import styled from 'styled-components';

export const CarouselContainer = styled.div`
  max-width: 100%;
  height: auto;
`;

export const Slide = styled.div`
  img {
    object-fit: contain;
    max-width: 100%;
    height: auto;
  }
`;
