import { useMutation, useQuery } from '@apollo/client';
import { CreateTargetResponse, UpdateTargetResponse, TargetInput, Target } from '@rio/rio-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { Row, Search, Col, Button, Heading, Text, Modal, PreContainer } from 'rio-ui-components';
import TargetModal from './TargetModal';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import PageHeader from '../../../components/PageHeader';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useSearch } from '../../../hooks/useSearch';
import { usePermissions } from '../../../hooks/usePermissions';
import { useAccessControls } from '../../../hooks/useAccessControls';
import areas from '../areas';
import CREATE_TARGET from '../../../graphql/mutations/targets/CreateTarget.mutation.graphql';
import UPDATE_TARGET from '../../../graphql/mutations/targets/UpdateTarget.mutation.graphql';
import GET_TARGETS_BY_ACCOUNT_ID from '../../../graphql/queries/targets/GetTargetsByAccountId.query.graphql';
import { Grid } from './Grid';
import NoResults from '../../../components/NoResults';
import { useNotification } from '../../../hooks';

const ControlsContainerStyled = styled(Row)`
  flex: 1;
  align-items: center;
`;

const ButtonContainerStyled = styled(Col)`
  padding-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const ModalContainer = styled.div`
  padding: ${(p) => p.theme.geometry.md.spacing};
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  pre {
    width: 100%;
  }
`;

const ModalHeadingStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;

export const Targets = () => {
  const accountId = useCurrentAccountId();
  const { data: accessControls } = useAccessControls();
  const permissions = usePermissions();
  const [showCreateModal, setShowCreateModal] = useQueryParam('showCreateModal', BooleanParam);
  const [editTargetId, setShowEditModal] = useState(null);
  const [actionsRequired, setActionsRequired] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [createTargetMutation] = useMutation(CREATE_TARGET);
  const [updateTargetMutation] = useMutation(UPDATE_TARGET);
  const { showNotification } = useNotification();

  const { loading, data, refetch, networkStatus } = useQuery(GET_TARGETS_BY_ACCOUNT_ID, {
    variables: { accountId },
  });

  const targets: Target[] = data?.getTargetsByAccountId || [];

  const [searchValue, setSearchValue, searchedTargets] = useSearch(targets, ['name']);

  const saveTarget = async (target: TargetInput) => {
    setErrors([]);
    try {
      const result: { data?: { createTarget: CreateTargetResponse } } = await createTargetMutation({
        variables: { target },
      });
      if (result.data) {
        const {
          data: { createTarget },
        } = result;
        if (createTarget.__typename === 'Target') {
          showNotification('Target successfully created', 'success');
          setShowCreateModal(false);
          refetch({ accountId });
        } else if (createTarget.__typename === 'CreateTargetErrors') {
          setErrors(createTarget.errors);
        }
      }
    } catch {
      showNotification('Something went wrong, please try again or contact support', 'danger');
    }
  };

  const editTarget = async (target: TargetInput) => {
    setErrors([]);
    try {
      const result: { data?: { updateTarget: UpdateTargetResponse } } = await updateTargetMutation({
        variables: { target, id: editTargetId },
      });
      if (result?.data) {
        const {
          data: { updateTarget },
        } = result;
        if (updateTarget.__typename === 'Target') {
          showNotification('Target successfully updated', 'success');
          setShowEditModal(null);
          refetch({ accountId });
        } else if (updateTarget.__typename === 'UpdateTargetErrors') {
          setErrors(updateTarget.errors);
        }
      }
    } catch {
      showNotification('Something went wrong, please try again or contact support', 'danger');
    }
  };

  const editedTarget = editTargetId ? targets.find(({ id }) => id === editTargetId) : undefined;
  const availableGovernancePages = areas.filter((area) => !area.comingSoon && accessControls.comply[area.accessIndex]);

  return (
    <>
      {showCreateModal && (
        <TargetModal
          title="Create Target"
          submitTitle="Create Target"
          onDismiss={() => setShowCreateModal(false)}
          accountId={accountId}
          onSubmit={saveTarget}
          errors={errors}
        />
      )}
      {editTargetId && (
        <TargetModal
          title="Edit Target"
          submitTitle="Edit Target"
          onDismiss={() => setShowEditModal(null)}
          accountId={accountId}
          onSubmit={editTarget}
          editedTarget={editedTarget}
          errors={errors}
        />
      )}
      {actionsRequired.length > 0 && (
        <Modal size="lg" show onDismiss={() => setActionsRequired([])}>
          <ModalContainer>
            <ModalHeadingStyled size="lg">Actions Required</ModalHeadingStyled>
            <PreContainer size="md" wrap>
              {actionsRequired.map((action) => (
                <Text key={action}>{action}</Text>
              ))}
            </PreContainer>
          </ModalContainer>
        </Modal>
      )}
      <PageHeader
        title="Targets"
        name={''}
        breadcrumbs={[{ title: 'Governance', to: '..' }, { title: 'Targets' }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown
        dropdownItems={availableGovernancePages}
        dropdownDefault="Targets"
      >
        <ControlsContainerStyled name="ConfigurationTargetContainer__Controls" container alignItems="center">
          <Col item>
            <Search
              name="ConfigurationTargetContainer__Controls__Search"
              value={searchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
              hideButton
            />
          </Col>
          {!!permissions.data.find((action: string) => action.startsWith('createTarget')) && (
            <ButtonContainerStyled span={4} item>
              <ButtonStyled
                name="ConfigurationTargetContainer__Controls__Button--create"
                size="md"
                color="primary"
                inline
                onClick={() => setShowCreateModal(true)}
              >
                + Add Targets
              </ButtonStyled>
            </ButtonContainerStyled>
          )}
        </ControlsContainerStyled>
      </PageHeader>
      {loading && networkStatus !== 3 && <ContainerLoadingIndicator name="ConfigurationNormaliserContainer__Loading" />}
      {!!searchedTargets && !searchedTargets.length && !loading && searchValue ? (
        <NoResults name="ConfigurationTargetContainer__NoResults" title="There are no results for this search." />
      ) : (
        !loading && (
          <Grid
            targets={searchedTargets}
            showEditModal={setShowEditModal}
            showActionRequiredModal={setActionsRequired}
            canEdit={!!permissions.data.find((action: string) => action.startsWith('editTarget'))}
          />
        )
      )}
    </>
  );
};
