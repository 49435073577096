import { gql } from "@apollo/client";

export const GET_ALL_ACCURACY_TYPES = gql`
  query GetAllAccuracyTypes {
    getAllAccuracyTypes {
      id
      name
    }
  }
`;
