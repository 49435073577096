import {
  GET_PAGE,
  GET_SEARCH,
  GET_TOTAL_PAGES
} from '../../containers/ConfigurationContainer/ConfigurationContractorsContainer/index.queries';

const wasteContractorsGridPage = 1;
const wasteContractorsGridSearch = '';
const wasteContractorsGridTotalPages = 1;

export const wasteContractorsGridDefaults = [
  { query: GET_PAGE, data: { wasteContractorsGridPage } },
  { query: GET_SEARCH, data: { wasteContractorsGridSearch } },
  { query: GET_TOTAL_PAGES, data: { wasteContractorsGridTotalPages } }
];

export const wasteContractorsGridResolvers = {
  Mutation: {
    setWasteContractorsGridPage: async (_, { page }, { cache }) => {
      cache.writeQuery({
        query: GET_PAGE,
        data: {
          wasteContractorsGridPage: page
        }
      });
      return page;
    },
    setWasteContractorsGridSearch: async (_, { search }, { cache }) => {
      cache.writeQuery({
        query: GET_SEARCH,
        data: {
          wasteContractorsGridSearch: search
        }
      });
      return search;
    },
    setWasteContractorsGridTotalPages: async (_, { totalPages }, { cache }) => {
      cache.writeQuery({
        query: GET_TOTAL_PAGES,
        data: {
          wasteContractorsGridTotalPages: totalPages
        }
      });
      return totalPages;
    }
  }
};
