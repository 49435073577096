import React from 'react';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

type DescriptionCellProps = {
  data: {
    description: string,
  },
}

const DescriptionIconContainer = styled.div`
  color: ${(props) => props.theme.colors.text.normal.background};

  :hover > span {
    visibility: visible;
    opacity: 1;
  }
`;

const ToolTip = styled.span`
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;

  position: fixed;
  height: auto;
  width: auto;
  margin-left: 20px;
  padding: 5px 10px;

  word-break: break-word;
  text-align: center;
  border-radius: 6px;

  font-size: 14px;
  background-color: #ddd;
  color: black;

  ::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #ddd transparent transparent;
  }
`;

export function DescriptionCell(props: DescriptionCellProps) {
  const {
    data: { description }
  } = props;

  return (
    description && (
      <DescriptionIconContainer>
        <Icon name={`project-row__info`} icon="info-circle" />
        <ToolTip>{description}</ToolTip>
      </DescriptionIconContainer>
    )
  );
}
