import { styled } from '@rio/ui-components';
import { Routes, Route } from 'react-router-dom';
import { PageNotFound } from '~/components/Errors';
import NotificationBanner from '~/components/NotificationBanner';
import { useCurrentAccount } from '~/hooks';
import { FlowsHome } from './FlowsHome';

const ContainerStyled = styled('div')`
  height: 100%;
  overflow: visible;
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: ${({ theme }) => theme.sys.color.onPrimary};
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;

export const FlowsContainer = () => {
  const account = useCurrentAccount();
  const hasSubscription = account ? account.accessControls.flows : false;

  return (
    <>
      {!hasSubscription && (
        <>
          <Overlay />
          <NotificationBanner
            name="FormsContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title="Flows"
            body="Currently Flows are not included in your subscription, if you’d like access to Flows please contact Rio ESG LTD or get in touch with your Business Admin."
            dismissable={true}
            onDismiss={() => {}}
          />
        </>
      )}
      {hasSubscription && (
        <ContainerStyled>
          <Routes>
            <Route path="/" element={<FlowsHome />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ContainerStyled>
      )}
    </>
  );
};
