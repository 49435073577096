import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import { ColDef } from 'ag-grid-community';
import { useGetRecipients } from './hooks/useGetRecipients';
import { LoadingIndicator } from 'rio-ui-components';
import { ContainerLoadingIndicator } from '~/components';
import { RecipientSurveyType, useSurveyForm } from './hooks/useSurveyForm';
import { useCurrentAccountId } from '~/hooks';

const GridContainer = styled.div`
  height: 400px;

  .ag-header {
    display: none;
  }

  .ag-sticky-top {
    top: 0 !important;
  }
`;

export type RecipientsGridProps = {
  surveyType: RecipientSurveyType;
};

const columnDefs: ColDef[] = [];

export function RecipientsGrid() {
  const accountId = useCurrentAccountId();
  const { surveyType, selectContributors, selectIndividualContributors, selectedIds } = useSurveyForm();
  const { rows, loading } = useGetRecipients();

  if (loading) {
    return <ContainerLoadingIndicator />;
  }

  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        key={surveyType}
        onGridReady={(e) => {
          e.api.forEachNode((node) => {
            if (node.id && selectedIds.has(node.id)) {
              node.setSelected(true);
            }
          });
        }}
        onSelectionChanged={(e) => {
          const selectedRows = e.api.getSelectedRows();
          if (surveyType === RecipientSurveyType.contributor) {
            const contributorIds = selectedRows
              .filter((row) => row.__typename === 'Account' && row.id !== accountId)
              .map((row) => row.id);
            return selectContributors(contributorIds);
          }
          const individualContributorIds = selectedRows
            .filter((row) => row.__typename === 'MysqlUser')
            .map((row) => row.id);
          return selectIndividualContributors(individualContributorIds);
        }}
        treeData={true}
        rowSelection="multiple"
        columnDefs={columnDefs}
        rowData={rows}
        getDataPath={(data) => {
          return data.orgHierarchy;
        }}
        groupSelectsChildren={true}
        suppressRowClickSelection={true}
        groupDefaultExpanded={1}
        loadingOverlayComponent={LoadingIndicator}
        getRowId={(params) => params.data.id}
        autoGroupColumnDef={{
          headerName: 'Name',
          flex: 1,
          valueGetter: (params) => {
            if (!params.data) {
              return undefined;
            }
            switch (params.data.__typename) {
              case 'Account':
                return params.data.name;
              case 'MysqlUser':
                return `${params.data.firstName} ${params.data.lastName} <${params.data.email}>`;
            }
          },
          cellRendererParams: {
            checkbox: true,
            suppressCount: true,
          },
        }}
      />
    </GridContainer>
  );
}
