import { gql } from '@apollo/client';

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: ID!
    $accountId: String!
    $name: String!
    $description: String!
    $scope: Scope
    $locationIds: [ID]
    $tagIds: [ID]
    $category: TaskCategory!
    $subject: TaskSubject
    $itemId: String
    $owners: [String!]
    $priority: TaskPriority!
    $dueDate: String!
    $isCompleted: Boolean!
    $createdAt: String!
    $completedAt: String
    $projectId: ID
  ) {
    updateTask(
      input: {
        id: $id
        accountId: $accountId
        name: $name
        description: $description
        scope: $scope
        locationIds: $locationIds
        tagIds: $tagIds
        category: $category
        subject: $subject
        itemId: $itemId
        dueDate: $dueDate
        owners: $owners
        priority: $priority
        isCompleted: $isCompleted
        createdAt: $createdAt
        completedAt: $completedAt
        projectId: $projectId
      }
    ) {
      id
      accountId
      name
      description
      category
      subject
      dueDate
      createdAt
      completedAt
      isCompleted
      owners {
        id
        first_name
        last_name
        profilePicture
      }
      locations {
        id
        name
      }
      tags {
        id
        tagName
      }
      priority
      projectId {
        id
        name
      }
    }
  }
`;
