import { memo, useMemo } from 'react';
import { Survey } from '@rio/rio-types';
import { Charts, Heading3, LeftChart, RightChart, TopCharts } from './styles';
import { ResponseStatusChart } from './ResponseStatusChart';
import { ContributorPerformanceChart } from './ContributorPerformanceChart';
import { CategoryPerformanceChart } from './CategoryPerformanceChart';

interface SurveyOverviewChartProps {
  survey: Survey;
}

const hasSurveyCategories = (survey: Survey): boolean => {
  const categoryNames: Set<string> = new Set();

  for (const question of survey?.template?.questions || []) {
    const categoryName = question.category?.name;
    if (categoryName !== undefined) {
      categoryNames.add(categoryName);
    }
  }

  return categoryNames.size > 0;
};

function DefaultCharts({ survey }: SurveyOverviewChartProps) {
  const { contributors, submissions } = survey;

  return (
    <>
      <Heading3 typescale="title" size="medium">
        Response status
      </Heading3>
      <ResponseStatusChart contributors={contributors} submissions={submissions} />
    </>
  );
}

function AutomatedScoringCharts({ survey }: SurveyOverviewChartProps) {
  const { contributors, submissions } = survey;

  const foundSurveyCategories = useMemo(() => hasSurveyCategories(survey), [survey]);

  if (foundSurveyCategories) {
    return <DefaultCharts survey={survey} />;
  }

  return (
    <Charts>
      <TopCharts>
        <LeftChart>
          <Heading3 typescale="title" size="medium">
            Response status
          </Heading3>
          <ResponseStatusChart contributors={contributors} submissions={submissions} />
        </LeftChart>
        <RightChart>
          <ContributorPerformanceChart survey={survey}>
            <Heading3 typescale="title" size="medium">
              Contributor performance
            </Heading3>
          </ContributorPerformanceChart>
        </RightChart>
      </TopCharts>
      <CategoryPerformanceChart survey={survey} />
    </Charts>
  );
}

export const SurveyOverviewChart = memo(function SurveyOverviewChart({ survey }: SurveyOverviewChartProps) {
  const { automatedScoring } = survey;

  if (automatedScoring) {
    return <AutomatedScoringCharts survey={survey} />;
  }
  return <DefaultCharts survey={survey} />;
});
