import { useCallback, useMemo } from 'react';
import { Model } from 'survey-core';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { useNotification, usePageSuspendingQuery } from '~/hooks';
import { useSurveyContext } from '~/components/SurveyProvider';
import { useUploadedDocuments } from '../../ReceivedSurveyContainer/hooks';
import { transformSubmissionToAnswers } from '../../ReceivedSurveyContainer/utils';
import { useSendFeedback } from '../queries';
import { GET_MY_CONTRIBUTOR_SUBMISSION } from '../queries/queries';

export const useMyContributorSubmission = (submissionId?: string) => {
  const id = submissionId || '';
  const { showNotification } = useNotification();
  const { data } = usePageSuspendingQuery(GET_MY_CONTRIBUTOR_SUBMISSION, {
    variables: {
      id,
    },
    skip: !id,
  });
  const submission = data?.getSurveySubmissionById;
  const { uploadedDocuments } = useUploadedDocuments(submission);
  const { expanded, setExpanded } = useSurveyContext();
  const [sendFeedbackOnSurveySubmission] = useSendFeedback();

  const expand = useCallback(() => setExpanded(true), [setExpanded]);
  const collapse = useCallback(() => setExpanded(false), [setExpanded]);

  const sendFeedback = useCallback(
    (status: SurveySubmissionFeedbackStatus, feedback: string | null) => {
      const approved = status === SurveySubmissionFeedbackStatus.Approved;
      const client = submission?.owner.name || 'recipient';
      return sendFeedbackOnSurveySubmission({
        variables: {
          status,
          feedback,
          submissionId: id!,
        },
        onCompleted: () => {
          if (approved) {
            showNotification(`The response from ${client} was approved`, 'success');
          } else {
            showNotification(`Change requests sent to ${client}`, 'warning');
          }
        },
        onError: (err) => {
          showNotification(
            `We couldn't send feedback. Please try again later or contact support@rio.ai: ${err}`,
            'danger'
          );
        },
      });
    },
    [id, sendFeedbackOnSurveySubmission, showNotification, submission?.owner]
  );

  const formModel = useMemo(() => {
    if (!submission) {
      return null;
    }
    const model = new Model(submission.survey.template.formContent);
    model.showPreviewBeforeComplete = 'showAnsweredQuestions';
    if (submission.answers) {
      model.data = transformSubmissionToAnswers(submission.answers, uploadedDocuments);
      model.mode = 'display';
    }
    return model;
  }, [submission, uploadedDocuments]);

  const survey = submission?.survey;

  return {
    survey,
    expand,
    expanded,
    collapse,
    formModel,
    submission,
    sendFeedback,
  };
};
