import { useUserToken } from './useUserToken';
import { ACCOUNT, TAG, LOCATION, scopeOptions } from '../constants/scopes';

export function useScopeOptions() {
  const { token } = useUserToken();
  const { accessLevel } = token;

  if (accessLevel === ACCOUNT) {
    return scopeOptions;
  }

  if (accessLevel === TAG) {
    return scopeOptions.filter(({ value }) => [TAG, LOCATION].includes(value));
  }

  if (accessLevel === LOCATION) {
    return scopeOptions.filter(({ value }) => LOCATION === value);
  }

  return [];
}
