import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'rio-ui-components';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 20px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 5px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 30px 0px 30px;
`;

const DeleteRowModal = ({ onDismissClick, onConfirmClick }) => {
  return (
    <Modal size="sm" show dismissable onDismiss={onDismissClick} height="auto" maxHeight="90vh">
      <TextContainer>Are you sure you want to delete this data upload attempt?</TextContainer>
      <ButtonRow>
        <ButtonStyled color="info" onClick={onDismissClick}>
          Cancel
        </ButtonStyled>
        <ButtonStyled onClick={onConfirmClick} color={'danger'}>
          Confirm
        </ButtonStyled>
      </ButtonRow>
    </Modal>
  );
};

export default DeleteRowModal;
