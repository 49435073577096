import { gql } from '@apollo/client';
import { AUDIT_EVENT_FRAGMENT } from '../../queries/audit';

export const CREATE_AUDIT_EVENT = gql`
  mutation CreateAuditEvent($event: AuditEventInput!) {
    createAuditEvent(event: $event) {
      ...AuditEventFields
    }
  }
  ${AUDIT_EVENT_FRAGMENT}
`;
