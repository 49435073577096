import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  Box,
  Container,
  Icon,
  PaymentMethod,
  PaymentMethodList,
  PaymentProcessingErrorMessage,
  PaymentProcessingMessage,
  ServiceProviderBox,
  TermsAndConditionMessage,
} from './CheckoutStyles';
import { Col, Row } from 'rio-ui-components';
import { StripeForm } from '../StripeForm';
import { Link, useNavigate } from 'react-router-dom';
import { useMakeStripeLoader } from '../../hooks';

export function Checkout({ cart }) {
  const [isPaid, setPaid] = useState(false);
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  const {
    clearCart,
    state: {
      data: cartLineList,
      discount,
      isCartEmpty,
      subTotal,
      total,
      currency: { symbol },
    },
  } = cart;
  const header = cartLineList.map(([, { product }]) => product?.title || '').join(', ');

  const stripeLoader = useMakeStripeLoader();

  useEffect(() => {
    if (isPaid) {
      clearCart();
    }
    // eslint-disable-next-line
  }, [isPaid]);

  if (isCartEmpty && !isPaid) {
    navigate('/upgrade/cart');
  }

  const isFormVisible = !isPaid && !isError;

  const markAsPaid = () => setPaid(true);
  const markAsError = (e) => {
    console.error(e);
    setError(true);
  };
  const tryAgain = (e) => {
    e.preventDefault();
    setError(false);
  };

  return (
    <Container>
      <div>
        <ServiceProviderBox>
          <Row container itemAlign="center" align="between">
            <Col span={1}>
              <Icon icon="briefcase" size="3x" />
            </Col>

            <Col span={5}>
              <div>
                <h3>{header}</h3>
                <p>
                  {symbol}
                  {subTotal}
                </p>
                <p>
                  <small>
                    {symbol}
                    {total} including VAT
                  </small>
                </p>
              </div>
            </Col>

            <Col span={2} />

            <Col span={2} />
          </Row>
        </ServiceProviderBox>

        <Box>
          <h4>
            <b>01</b> Choose Payment Method
          </h4>

          <PaymentMethodList>
            <PaymentMethod isSelected={true}>
              <Icon icon="credit-card" size="2x" />
              <br />
              CARD
            </PaymentMethod>
          </PaymentMethodList>
        </Box>

        <Box>
          {isPaid && (
            <PaymentProcessingMessage name="Checkout-Payment-Processing-Message">
              <p>
                <Icon icon="check-circle" size="3x" />
              </p>
              <p>Your payment is being processed.</p>
              <p>It may take a moment for your account to be upgraded.</p>
              <p>
                Please refresh your page or re-login, and <Link to="/contact">contact support</Link> if you have any
                issues.
              </p>
            </PaymentProcessingMessage>
          )}

          {isError && (
            <PaymentProcessingErrorMessage>
              <p>
                <Icon icon="exclamation-triangle" size="3x" />
              </p>
              <p>Something went wrong with your payment.</p>
              <p>
                Please try again{' '}
                <a href="/" onClick={tryAgain}>
                  here
                </a>
                . If the issue persists, please contact support.
              </p>
            </PaymentProcessingErrorMessage>
          )}

          {isFormVisible && (
            <>
              <Elements stripe={stripeLoader}>
                <StripeForm
                  cartLineList={cartLineList}
                  onComplete={markAsPaid}
                  onError={markAsError}
                  discount={discount}
                />
              </Elements>

              <TermsAndConditionMessage>
                By selecting &apos;Pay Now&apos;, you are agreeing to Rio ESG LTD&apos;s{' '}
                <a href={'https://www.rio.ai/terms-of-service'} rel="noopener noreferrer" target="_blank">
                  Terms and Conditions
                </a>
              </TermsAndConditionMessage>
            </>
          )}
        </Box>
      </div>
    </Container>
  );
}
