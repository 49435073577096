import { useApolloClient } from '@apollo/client';
import { formatDate, gridNumberFormatter, gridValueFormatter } from '../../../../utils';
import { useNotification } from '../../../../hooks';
import { GET_FUEL_CONVERSION_FACTORS_FILTER } from './index.queries';
import { GridOptions, SetFilterValuesFuncParams, SortDirection } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { FilterData } from '@rio/rio-types';

export function useFuelConversionFactorsGridConfig(): GridOptions {
  const client = useApolloClient();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getFuelConversionFactorsFilter: values },
        } = await client.query<{ getFuelConversionFactorsFilter: FilterData[] }>({
          query: GET_FUEL_CONVERSION_FACTORS_FILTER,
          variables: {
            field: params.colDef.colId || params.colDef.field,
          },
        });

        const filterValues: (string | null)[] = values
          .map((item) => item.value)
          .filter((value): value is string | null => value !== undefined);

        params.success(filterValues);
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [client, showNotification]
  );

  const config = useMemo(
    () => ({
      columnDefs: [
        {
          headerName: 'Reporting Year',
          field: 'reportingYear',
          filter: 'agSetColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Fuel Type',
          field: 'fuelType',
          filter: 'agSetColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          initialSort: 'asc' as SortDirection,
        },
        {
          headerName: 'Fuel Activity',
          field: 'fuelActivity',
          filter: 'agSetColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Unit',
          field: 'unit',
          filter: 'agSetColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Combustion (kgCO2e)',
          field: 'comKgCo2e',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Well-to-tank (kgCO2e)',
          field: 'wttKgCO2e',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Start Date',
          field: 'startDate',
          filter: 'agDateColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: ({ value }: { value: Date }) => formatDate(value),
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          filter: 'agDateColumnFilter',
          minWidth: 100,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: ({ value }: { value: Date }) => formatDate(value),
        },
        {
          headerName: 'Combustion (kgCO2)',
          field: 'comKgCo2',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Combustion (kgCH4)',
          field: 'comKgCh4',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Combustion (kgN2O)',
          field: 'comKgN20',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'GJ Gross',
          field: 'gjGross',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'GJ Net',
          field: 'gjNet',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'kWh Gross',
          field: 'kWhGross',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'kWh Net',
          field: 'kWhNet',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Density (kg)',
          field: 'densityKg',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Density (m3)',
          field: 'densityM3',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Density (t)',
          field: 'densityT',
          minWidth: 100,
          filter: 'agNumberColumnFilter',
          initialHide: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          valueFormatter: gridNumberFormatter,
        },
        {
          headerName: 'Reference',
          field: 'reference',
          cellRenderer: 'reference',
          cellRendererParams: {
            icon: 'external-link-alt',
          },
          minWidth: 100,
        },
      ],
    }),
    [getFilterValues]
  );

  return config;
}
