import React from 'react';
import { useQuery } from "@apollo/client";
import { Query } from '@rio/rio-types';
import GET_ALL_CURRENCY_TYPES from '../../../graphql/queries/GetAllCurrencyTypes.graphql';
import { Text } from 'rio-ui-components';

const CurrencySymbols: Record<string,string> = {
  'BGN': 'лв',
  'CZK': 'Kč',
  'DKK': 'kr',
  'EUR': '€',
  'GBP': '£',
  'HRK': 'kn',
  'GEL': '₾',
  'HUF': 'ft',
  'NOK': 'kr',
  'PLN': 'zł',
  'RUB': '₽',
  'RON': 'lei',
  'SEK': 'kr',
  'TRY': '₺',
  'UAH': '₴',
  'BRL': 'R$',
  'CAD': '$',
  'CLP': '$',
  'COP': '$',
  'MXN': '$',
  'PEN': 'S/.',
  'USD': '$',
  'AUD': '$',
  'BDT': '৳',
  'CNY': '¥',
  'HKD': 'HK$',
  'IDR': 'Rp',
  'INR': '₹',
  'JPY': '¥',
  'MYR': 'RM',
  'NZD': '$',
  'PHP': '₱',
  'PKR': 'Rs',
  'SGD': '$',
  'KRW': '₩',
  'LKR': 'Rs',
  'THB': '฿',
  'VND': '₫',
}

type CostCellProps = {
  data: {
    cost: number,
    currencyId: string,
  },
}

export function CostCell(props: CostCellProps) {
  const { data: { cost, currencyId } } = props;

  const { data } = useQuery<Pick<Query, 'getAllCurrencyTypes'>>(GET_ALL_CURRENCY_TYPES);
  const allCurrencies = data?.getAllCurrencyTypes || [];
  const currency = allCurrencies.find((currency) => currency.id === currencyId);
  const currencySymbol = CurrencySymbols[currency?.name || ''] ?? currency?.name;

  return (
    <Text align="center">
      {cost} {currencySymbol}
    </Text>
  );
}