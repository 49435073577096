// import IE11 fetch polyfill and other setup stuff
import 'isomorphic-fetch';

// Import basic React dependencies
import { createRoot } from 'react-dom/client';

// Import GraphQL/Apollo dependencies
import { Amplify } from 'aws-amplify';

import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Import i18n dependencies

import { RouterProvider } from 'react-router-dom';

//Import google analytics

import { router } from './routes/Router';
import { getEnvVar } from './env';

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: getEnvVar('REACT_APP_PINPOINT_APP_CLIENT_ID'),
    // Amazon service region
    region: getEnvVar('REACT_APP_PINPOINT_REGION'),
    mandatorySignIn: false,
    disabled: window.Cypress,
  },
};

const amplifyConfig = {
  aws_project_region: getEnvVar('REACT_APP_AWS_REGION'),
  aws_cognito_region: getEnvVar('REACT_APP_AWS_REGION'),
  Auth: {
    identityPoolId: getEnvVar('REACT_APP_IDENTITY_POOL_ID'),
    region: getEnvVar('REACT_APP_AWS_REGION'),
    userPoolId: getEnvVar('REACT_APP_USER_POOL_ID'),
    userPoolWebClientId: getEnvVar('REACT_APP_USER_POOL_WEB_CLIENT_ID'),
    oauth: {
      domain: getEnvVar('REACT_APP_COGNITO_DOMAIN'),
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${getEnvVar('REACT_APP_BASE_URL')}/login`,
      redirectSignOut: getEnvVar('REACT_APP_BASE_URL'),
      responseType: 'code',
    },
  },
  Analytics: analyticsConfig,
};
//Initialize Amplify
Amplify.configure(amplifyConfig);

const initialiseReactGA = () => {
  if (getEnvVar('REACT_APP_GOOGLE_ANALYTIC_KEY')) {
    ReactGA.initialize(getEnvVar('REACT_APP_GOOGLE_ANALYTIC_KEY'), {
      debug: false,
    });
  }
};

if (!window.Cypress) {
  initialiseReactGA();
}

Sentry.init({
  dsn: 'https://c712cec004ed470f9d1f7041cd1ee7e3@o925114.ingest.sentry.io/5873743',
  environment: getEnvVar('REACT_APP_SENTRY_ENV'),
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  enabled: getEnvVar('NODE_ENV') !== 'development',
});

// Attach react app to DOM, wrapped with i18n provider and Apollo GraphQL provider
const root = createRoot(document.getElementById('root'));

/**
 * TODO: Enable StrictMode when react-select issue is fixed (Select component throws on componentWillUnmount in StrictMode)
 */
root.render(<RouterProvider router={router} />);
