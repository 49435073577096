import React from 'react';
import { Row, Col, Heading, ProfilePicture, Icon } from 'rio-ui-components';
import styled from 'styled-components';

const KbRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const KbProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 ${(props) => props.theme.geometry.xxl.spacing} 0 0;

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const KbRow = ({ kb, onEdit }) => {
  return (
    <KbRowStyled name={`kb-row--${kb.id}`} container itemAlign="center">
      <KbProfilePictureStyled name={`kb-row__profile-picture--${kb.id}`} size="xs" accountName={kb.title} />

      <Col container item>
        <Heading inline name={`kb-row__tile`} size="md">
          {kb.title}
        </Heading>
      </Col>

      <EditLinkStyled onClick={onEdit}>
        <Icon name={`kb-row__edit`} icon="pencil-alt" />
      </EditLinkStyled>
    </KbRowStyled>
  );
};

export default KbRow;
