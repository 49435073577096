import { useApolloClient } from '@apollo/client';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import GET_FUEL_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/fuel/GetFuelTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useFuelGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getFuelTransactionsPageFilter: values },
      } = await client.query({
        query: GET_FUEL_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Location',
        field: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location Code',
        field: 'locationCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Supplier',
        field: 'supplier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Date',
        field: 'date',
        valueFormatter: ({ value }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
      },
      {
        headerName: 'Fuel Activity',
        field: 'fuelActivityName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Fuel Type',
        field: 'fuelType',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'Quantity',
      },
      {
        headerName: 'Quantity Units',
        field: 'unit',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Energy (kWh)',
        field: 'energyConsumption',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Act/Est',
        field: 'accuracy',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Currency',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Carbon Emission Scope 1 (kgCO2e)',
        field: 'carbonScope1',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Carbon Emission Scope 3 (kgCO2e)',
        field: 'carbonScope3',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 3 WTT',
        field: 'carbonEmissionScope3WTT',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 1 kgCO2',
        field: 'kgCO2Scope1',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 3 kgCO2',
        field: 'kgCO2Scope3',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 1 kgCH4',
        field: 'kgCH4Scope1',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 3 kgCH4',
        field: 'kgCH4Scope3',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 1 kgN2O',
        field: 'kgN2OScope1',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Scope 3 kgN2O',
        field: 'kgN2OScope3',
        filter: 'agNumberColumnFilter',
        initialHide: true,
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Comment',
        field: 'comment',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Invoice Number',
        field: 'invoiceNumber',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Country',
        field: 'location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filter: 'agTextColumnFilter',
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
