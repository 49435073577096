import { styled, Text } from '@rio/ui-components';

export const colorCodes = {
  positive: 'extendedColors.environmental.color',
  neutral: 'sys.color.onSurfaceVariant',
  negative: 'sys.color.error',
};
export const Heading3 = styled(Text)`
  margin-bottom: 12px;
`.withComponent('h3');

export const Charts = styled('div')`
  display: flex;
  flex-flow: wrap;
  width: 100%;
`;
export const TopCharts = styled(Charts)`
  display: flex;
  flex-flow: nowrap;
`;
export const Chart = styled('div')`
  padding: 8px 12px;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.sys.color.secondaryContainer};
`;
export const LeftChart = styled(Chart)`
  overflow: hidden;
  flex: 0 1 50%;
`;
export const RightChart = styled(Chart)`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 50%;
  border-left: 0;
`;
export const BottomChart = styled(Chart)`
  display: flex;
  padding: 32px 20px 20px;
  border-top: 0;
`;
