import capitalize from 'lodash/capitalize';
import * as TOPICS from '../../../constants/targetTopics';
import * as STATUSES from '../../../constants/targetStatuses';

const statusTitleMap = {
  [STATUSES.ACHIEVING]: {
    [TOPICS.PAPER_REDUCTION]: 'You have used less paper than your target.',
    [TOPICS.DOMESTIC_FLIGHTS]: 'You have travelled less distance by flight than your target.',
    [TOPICS.REUSE]: 'You have achieved or exceeded your reuse target.',
    [TOPICS.NON_TRADED_EMISSIONS]: 'You have emitted less carbon from non-traded emissions than your target.',
    [TOPICS.WATER_CONSUMPTION]: 'You have used less water than your target.',
    [TOPICS.FOOD_WASTE]: 'You have generated less food waste than your target.',
    [TOPICS.NATURAL_GAS_CONSUMPTION]: 'You have used less natural gas than your target.',
    [TOPICS.RECYCLED_WASTE]: 'You have achieved or exceeded your recycling target.',
    [TOPICS.WASTE_GENERATION]: 'You have generated less waste than your target.',
    [TOPICS.INTERNATIONAL_FLIGHTS]: 'You have travelled less distance by flight than your target.',
    [TOPICS.ELECTRICITY_CONSUMPTION]: 'You have used less electricity than your target.',
    [TOPICS.LANDFILLED_WASTE]: 'You have landfilled less waste than your target.',
    [TOPICS.TRANSPORT]: 'You have travelled less distance than your target.',
    [TOPICS.CARBON_EMISSIONS]: 'You have emitted less carbon than your target.'
  },
  [STATUSES.NOT_ACHIEVING]: {
    [TOPICS.PAPER_REDUCTION]: 'You have used more paper than your target.',
    [TOPICS.DOMESTIC_FLIGHTS]: 'You have travelled more distance by flight than your target',
    [TOPICS.REUSE]: 'You have reused less waste than your target.',
    [TOPICS.NON_TRADED_EMISSIONS]: 'You have emitted more carbon from non-traded emissions than your target.',
    [TOPICS.WATER_CONSUMPTION]: 'You have used more water than your target.',
    [TOPICS.FOOD_WASTE]: 'You have generated more food waste than your target',
    [TOPICS.NATURAL_GAS_CONSUMPTION]: 'You have used more natural gas than your target.',
    [TOPICS.RECYCLED_WASTE]: 'You have recycled less waste than your target.',
    [TOPICS.WASTE_GENERATION]: 'You have generated more waste than your target.',
    [TOPICS.INTERNATIONAL_FLIGHTS]: 'You have travelled more distance by flight than your target',
    [TOPICS.ELECTRICITY_CONSUMPTION]: 'You have used more electricity than your target.',
    [TOPICS.LANDFILLED_WASTE]: 'You have landfilled more waste than your target.',
    [TOPICS.TRANSPORT]: 'You have travelled more distance than your target.',
    [TOPICS.CARBON_EMISSIONS]: 'You have emitted more carbon than your target.'
  }
};

export const getStatusTitle = (status, topicName) => {
  return statusTitleMap[status]?.[topicName] || capitalize(status);
};
