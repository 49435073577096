import { styled, Text } from '@rio/ui-components';
import { Survey as SurveyInterface, Maybe } from '@rio/rio-types';

import { Survey } from './Survey';
import { SurveyContributors } from './SurveyContributors';
import { SurveyOverviewChart } from './SurveyOverviewChart';
import { EmptySurveys } from './EmptySurveys';

interface SurveyOverviewContainerProps {
  survey: Maybe<SurveyInterface> | undefined;
  latest?: boolean;
}

export const SurveyOverviewSection = styled('div')`
  font-family: ${({ theme }) => theme.sys.typescale.label.small.family};
  height: 100%;
`;

const SurveyTitle = styled(Text)`
  margin-bottom: 12px;
`;

export function SurveyOverviewPage({ survey, latest = true }: SurveyOverviewContainerProps) {
  if (!survey) {
    return <EmptySurveys />;
  }

  return (
    <SurveyOverviewSection>
      <SurveyTitle typescale="title" size="large">
        {survey.name}
      </SurveyTitle>
      <SurveyOverviewChart survey={survey} />
      <Survey survey={survey} latest={latest} />
      <SurveyContributors survey={survey} />
    </SurveyOverviewSection>
  );
}
