import { Text, styled } from '@rio/ui-components';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';
import Accounting from 'accounting';
import currencySymbols from '../../TransactionRow/currencySymbols.json';

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const TextLink = styled(Text)`
  text-decoration: underline;
`;

const toCurrency = (currency: string, rowData?: { currency?: string }) => {
  if (rowData?.currency) {
    const symbol = currencySymbols[rowData.currency] || '';
    return currency !== null ? Accounting.formatMoney(currency, symbol) : '-';
  }
  return currency !== null ? Accounting.formatMoney(currency) : '-';
};

const toNumber = (number: string | number | null | undefined) =>
  number !== null || number !== undefined ? number : '-';

export const CellRenderer = (
  props: ICellRendererParams & { dataType: string; onExportClick?: (batchId: string) => Promise<void> }
) => {
  const { dataType, value, valueFormatted, onExportClick = () => {}, data: rowData } = props;

  const defaultDisplayValue = valueFormatted || value || '-';

  let children: React.ReactNode = null;
  switch (dataType) {
    case 'linkToDocument': {
      children = (
        <Link to={`/documents/all?search=${value}`}>
          <TextLink typescale="body" size="medium">
            {defaultDisplayValue}
          </TextLink>
        </Link>
      );
      break;
    }
    case 'uploadReference': {
      children = value?.id ? (
        <TextLink typescale="body" size="medium" onClick={() => onExportClick(value.id as string)}>
          {value.id}
        </TextLink>
      ) : (
        <Text typescale="body" size="medium">
          {defaultDisplayValue}
        </Text>
      );
      break;
    }
    case 'number': {
      children = (
        <Text typescale="body" size="medium">
          {value ? toNumber(value) : '-'}
        </Text>
      );
      break;
    }
    case 'currency': {
      children = (
        <Text typescale="body" size="medium">
          {toCurrency(value, rowData)}
        </Text>
      );
      break;
    }
    default: {
      children = (
        <Text typescale="body" size="medium">
          {defaultDisplayValue}
        </Text>
      );
      break;
    }
  }

  return <Container>{children}</Container>;
};
