import { useRef, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { Col, Button, InputFile, UploadFiles, Modal } from 'rio-ui-components';
import ContainerLoadingIndicator from '../ContainerLoadingIndicator';
import { DownloadTemplateButton, MassUploadProp } from '../DownloadTemplateButton';
import { MassUploadErrors } from './MassUploadErrors';

const Container = styled(Col)`
  padding: ${(props) => props.theme.geometry.lg.spacing};
  min-height: 550px;
`;
const ButtonsContainer = styled(Col)`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
`;
const ButtonContainer = styled(Col)`
  min-width: 200px;
  padding: 0 ${(props) => props.theme.geometry.sm.spacing};
`;
const ContentContainer = styled(Col)`
  flex: 1;
`;
const UploadFilesStyled = styled(UploadFiles)`
  & > div {
    height: 400px;
  }
`;

interface MassUploadModalProps {
  template: MassUploadProp;
  isUploading: boolean;
  errors: string[] | null;
  onFileInput: (files: File[], ...rest: unknown[]) => void;
  onDismiss: () => void;
  accept?: string;
  multiple?: boolean;
  children?: ReactNode;
  size?: string;
  handleFileChange?: (files: File[]) => void;
}

export function MassUploadModal({
  template,
  isUploading,
  errors,
  onFileInput,
  onDismiss,
  accept,
  multiple,
  handleFileChange,
  children,
  size,
}: MassUploadModalProps) {
  const inputRef = useRef<{ value: string }>(null);
  const acceptFiles = accept || '*';

  const handleUserFile = useCallback(
    (files, ...rest) => {
      onFileInput(files, ...rest);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [onFileInput]
  );

  return (
    <Modal size={size || 'md'} onDismiss={onDismiss} show dismissable height="auto" maxHeight="90vh">
      <Container container>
        <ButtonsContainer container>
          <ButtonContainer span={5} align="center" container>
            <InputFile
              multiple={multiple}
              accept={acceptFiles}
              onChange={handleFileChange || handleUserFile}
              inputRef={inputRef}
              name="MassUploadModal__FileInput"
            >
              {(pickFile: () => void) => (
                <Button
                  onClick={pickFile}
                  size="md"
                  color="primary"
                  data-cy="MassUploadModal__UploadSpreadsheet"
                  name="MassUploadModal__UploadSpreadsheet"
                  inline
                >
                  Upload spreadsheet
                </Button>
              )}
            </InputFile>
          </ButtonContainer>
          <ButtonContainer span={5} align="center" container>
            <DownloadTemplateButton template={template} />
          </ButtonContainer>
        </ButtonsContainer>
        {errors && !!errors.length ? (
          <MassUploadErrors errors={errors} onDrop={onFileInput} dragText="Drop file" />
        ) : (
          <ContentContainer align="center" vdistribution="center" container>
            {isUploading ? (
              <ContainerLoadingIndicator />
            ) : (
              children || (
                <UploadFilesStyled
                  accept={acceptFiles}
                  multiple={multiple}
                  onDrop={onFileInput}
                  dragText="Drop file"
                  text="Select an xlsx, xls or csv file..."
                  background
                />
              )
            )}
          </ContentContainer>
        )}
      </Container>
    </Modal>
  );
}
