import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TaskCategory, DataImportBatch, TransactionType } from '@rio/rio-types';
import { Modal, LoadingIndicator, Button } from 'rio-ui-components';
import { GET_UPLOAD_SUMMARY_DASHBOARD_URL } from '../index.queries';
import { LoadFailed } from '../../../components/Errors';
import { QuicksightFrame } from '../../../components/QuicksightFrame/quicksightFrame';
import { CreateTaskModal } from '../../../containers/TasksContainer/TasksGrid/CreateTaskModal';
import { useNotification, usePermissions } from '../../../hooks';
import { getMinutesTillNow } from '../../../utils';
import { BatchStatus } from '../../../types';
import { useRollbackBatch } from './useRollbackBatch';
import { useApproveUpload } from './useApproveUpload';
import { getTaskSubjectByUploadType, mapBatchStatus } from './utils';

const ModalStyled = styled(Modal)`
  & > div {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
  }
`;
const ModalBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  flex: 1;
`;
const QuickSightFrameStyled = styled(QuicksightFrame)`
  width: 100%;
  height: 100%;
  flex: 1;
`;
const ButtonsPanel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 12px 0;
  flex: 0;
`;
const ButtonStyled = styled(Button)`
  width: 20%;
`;
interface QuickViewModalProps {
  accountId: string;
  batch: DataImportBatch;
  uploadType: TransactionType;
  onDismiss: () => void;
  onRollBack?: (batchId: string) => void;
  refetchBatches: () => void;
}

export const QuickViewModal = ({ batch, accountId, uploadType, onDismiss, refetchBatches }: QuickViewModalProps) => {
  const { duplicatesNumber, approved, id: batchId, lastUpdated, status } = batch;
  const permissions = usePermissions();
  const needsApproval = !!duplicatesNumber && !approved;
  const allowedToApprove = permissions.data.find((action: string) => action.startsWith('approveUpload'));
  const { showNotification } = useNotification();
  const { data, loading, error, refetch } = useQuery(GET_UPLOAD_SUMMARY_DASHBOARD_URL, {
    variables: {
      uploadType,
      batchId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [rollback, { loading: rollingBack }] = useRollbackBatch();
  const [approve, { loading: approving }] = useApproveUpload();
  const [taskModalShown, setTaskModalShown] = useState(false);
  const showTaskModal = useCallback(() => {
    setTaskModalShown(true);
  }, []);
  const closeTaskModal = useCallback(() => {
    setTaskModalShown(false);
  }, []);
  const showTaskCreatedNotification = useCallback(
    ({ message, colour }) => {
      showNotification(message, colour);
    },
    [showNotification]
  );
  const handleRollback = useCallback(async () => {
    const yes = window.confirm(`Are you sure you want to rollback the data?`);
    if (yes) {
      await rollback({
        variables: {
          id: batchId,
        },
      });
      onDismiss();
      refetchBatches();
    }
  }, [rollback, batchId, refetchBatches, onDismiss]);
  const handleApprove = useCallback(async () => {
    if (needsApproval) {
      const yes = window.confirm(`This batch has ${duplicatesNumber} duplicates, do you want to approve it?`);
      if (yes) {
        await approve({
          variables: {
            batchId,
          },
        });
        refetchBatches();
      }
    }
    onDismiss();
  }, [approve, duplicatesNumber, batchId, needsApproval, onDismiss, refetchBatches]);
  const buttonsDisabled = loading || rollingBack || approving;
  const isCompleted = mapBatchStatus(status) === BatchStatus.DONE;
  const processingPeriodPassed = getMinutesTillNow(lastUpdated) > 15;
  const showRollbackButton = isCompleted && processingPeriodPassed;
  return (
    <ModalStyled span={11} height="95%" onDismiss={onDismiss} show dismissable>
      <ModalBody>
        {error ? (
          <LoadFailed error={error} retry={() => refetch({ uploadType, batchId })} />
        ) : loading ? (
          <LoadingIndicator />
        ) : (
          <QuickSightFrameStyled
            parameters={{
              ImportBatchId: batchId,
            }}
            id={`QuickViewModal__${batchId}`}
            accountId={accountId}
            dashboardurl={data.getUploadSummaryDashboard}
          />
        )}
      </ModalBody>
      <ButtonsPanel>
        <ButtonStyled color="info" disabled={buttonsDisabled} onClick={onDismiss}>
          Close
        </ButtonStyled>
        {showRollbackButton && (
          <ButtonStyled color="danger" disabled={buttonsDisabled} onClick={handleRollback}>
            Roll Back
          </ButtonStyled>
        )}
        <ButtonStyled color="warning" disabled={buttonsDisabled} onClick={showTaskModal}>
          Create Task
        </ButtonStyled>
        {needsApproval && allowedToApprove && (
          <ButtonStyled color="success" disabled={buttonsDisabled} onClick={handleApprove}>
            Approve Upload
          </ButtonStyled>
        )}
      </ButtonsPanel>
      {taskModalShown && (
        <CreateTaskModal
          accountId={accountId}
          // @ts-ignore
          onComplete={showTaskCreatedNotification}
          onDismiss={closeTaskModal}
          defaultValues={{
            category: TaskCategory.Data,
            subject: getTaskSubjectByUploadType(uploadType),
          }}
        />
      )}
    </ModalStyled>
  );
};
