import { Query } from '@rio/rio-types';
import { useApolloClient } from '@apollo/client';
import { useCurrentAccountId } from '../../hooks';
import { GET_WASTE_OUT_LOCATIONS_BY_ACCOUNT_ID } from './index.queries';
import { Nullable, SelectEvent, Option } from '../../types';
import { BaseLocationSelect } from './BaseLocationSelect';
import { mapLocationToOption } from './utils';

interface WasteOutLocationSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: Nullable<Error | string>;
  createNew?: boolean;
  createNewModalSize?: number;
  selectedOption?: Nullable<Option>;
  createNewAccessor?: string;
}

const order = [
  {
    order: 'asc',
    field: 'name'
  }
];

export function WasteOutLocationSelect({
  value,
  onChange,
  error,
  createNew,
  createNewModalSize = 5,
  selectedOption,
  createNewAccessor
}: WasteOutLocationSelectProps) {
  const accountId = useCurrentAccountId();
  const apolloClient = useApolloClient();
  return (
    <BaseLocationSelect
      value={value}
      locationPoints={{ wasteOut: true }}
      onChange={onChange}
      accountId={accountId}
      error={error}
      createNew={createNew}
      createNewModalSize={createNewModalSize}
      accessor={createNewAccessor}
      selectedOption={selectedOption}
      loadOptions={async (filterValue: string) => {
        const response = await apolloClient.query<Pick<Query, 'getWasteOutsByAccountId'>>({
          query: GET_WASTE_OUT_LOCATIONS_BY_ACCOUNT_ID,
          variables: {
            id: accountId,
            sort: order,
            filters: {
              text: [
                {
                  filterType: 'text',
                  type: 'contains',
                  filter: filterValue,
                  field: 'name'
                }
              ]
            }
          }
        });

        const options = response.data?.getWasteOutsByAccountId
          ? response.data.getWasteOutsByAccountId.map(mapLocationToOption)
          : [];

        return { options };
      }}
    />
  );
}
