import { Maybe } from '@rio/rio-types';
import React, { useState, useEffect } from 'react';
import { MarkdownRenderer, ContentEditor, Icon, Modal, Heading, Row, Button } from 'rio-ui-components';
import styled from 'styled-components';
import { escape } from '../../utils/escape';
import { HtmlDiv, Nullable } from '../../types';

export interface MarkdownViewEditorProps {
  onSave: Function;
  name?: string;
  source: Maybe<string> | undefined;
}

const ContainerStyled = styled.div<HtmlDiv>`
  display: flex;
  position: relative;
  padding-bottom: ${(p) => p.theme.geometry.md.spacing};
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: -15px;
  right: 5px;
  cursor: pointer;
`;

const ModalInner = styled.div<HtmlDiv>`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.geometry.md.spacing};
`;

const EditorContainer = styled.div<HtmlDiv>`
  display: block;
  height: 350px;
  padding-bottom: ${(p) => p.theme.geometry.md.spacing};
`;

const MarkdownViewEditor = ({ onSave, source }: MarkdownViewEditorProps) => {
  const [editing, setEditing] = useState(false);
  const [content, setContent] = useState<Nullable<string>>('');

  useEffect(() => {
    setContent(source);
  }, [source]);

  const onTickClicked = () => {
    setEditing(false);
    onSave(escape(content));
  };

  return (
    <ContainerStyled>
      <IconStyled icon={'pencil-alt'} onClick={() => setEditing(true)} />
      <MarkdownRenderer source={content} />
      {editing && (
        <>
          <Modal size="lg" show onDismiss={() => setEditing(false)} height="auto" maxHeight="80vh">
            <ModalInner>
              <Row container item distribution="center" itemAlign="center">
                <Heading>Edit Content</Heading>
              </Row>
              <EditorContainer>
                <ContentEditor sourceContent={content} onMarkdownChange={setContent} />
              </EditorContainer>
              <Button color="success" onClick={onTickClicked}>
                Save Content
              </Button>
            </ModalInner>
          </Modal>
        </>
      )}
    </ContainerStyled>
  );
};

export default MarkdownViewEditor;
