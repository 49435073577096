import { useCallback, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SupplierType, Accuracy, TransactionType } from '@rio/rio-types';
import { Checkbox, Grid, Text, TextField, OptionsProps } from '@rio/ui-components';
import { useCurrentAccountId } from '~/hooks';
import { DataForm } from '../DataFormModal/v2';
import { SupplierSelector } from '../SuppliersSelector/v2';
import { SelectRenderV2 as SelectRender } from '../DataForm';
import { CurrencySelectV2 as CurrencySelect } from '../CurrencySelect';
import { WasteStreamSelectV2 as WasteStreamSelect } from '../WasteStreamSelect';
import { NumberInput, NumberInputWithNegativeOption } from '../UI/V2';
import { ContainerTypeSelectV2 as ContainerTypeSelect } from '../ContainerTypeSelect';
import { WasteOutLocationSelect, WasteInLocationSelect } from '../LocationSelect/v2';
import { MeasurementUnitSelectV2 as MeasurementUnitSelect } from '../MeasurementUnitSelect';
import { TreatmentProcessSelectV2 as TreatmentProcessSelect } from '../TreatmentProcessSelect';
import { GridStyled, SectionStyled } from './style';

type SubmitProps = {
  data: object;
  financial: object;
  reference: object;
};

interface WasteDataFormProps {
  form: UseFormReturn;
  onSubmit: (data: object) => void;
  children: React.ReactNode;
  dataSection: TransactionType;
}

export const WasteDataFormV2 = (props: WasteDataFormProps) => {
  const { form, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, register, watch } = form;
  useEffect(() => {
    register('isFinancialDataRequired');
    register('isReferenceDataRequired');
  }, [register]);
  const accountId = useCurrentAccountId();
  const [isFinancialDataRequired, isReferenceDataRequired] = watch([
    'isFinancialDataRequired',
    'isReferenceDataRequired',
  ]);

  const setFinancialDataRequired = (value: boolean) => setValue('isFinancialDataRequired', value);
  const setReferenceDataRequired = (value: boolean) => setValue('isReferenceDataRequired', value);

  const showFinanceData = () => setFinancialDataRequired(true);
  const hideFinanceData = () => setFinancialDataRequired(false);
  const showReferenceData = () => setReferenceDataRequired(true);
  const hideReferenceData = () => setReferenceDataRequired(false);

  const handleOnSubmit = useCallback(
    ({ data, financial, reference, ...others }: SubmitProps) => {
      const formData = {
        ...others,
        data,
      };
      if (isFinancialDataRequired) {
        formData.data = {
          ...formData.data,
          ...financial,
        };
      }
      if (isReferenceDataRequired) {
        formData.data = { ...formData.data, ...reference };
      }
      onSubmit(formData);
    },
    [onSubmit, isFinancialDataRequired, isReferenceDataRequired]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form}>
      <Text typescale="title" size="small">
        Please enter the following mandatory transactional waste data:
      </Text>

      <Controller
        name="data.wasteOut"
        render={({ field, fieldState }) => (
          <WasteOutLocationSelect
            {...field}
            value={field?.value}
            label="Waste-out location"
            onChange={(e) => setValue('data.wasteOut', e)}
            error={fieldState.error?.message}
            createNew
          />
        )}
        rules={{ required: 'Waste-out location is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.wasteStream"
        render={({ field, fieldState }) => (
          <WasteStreamSelect
            createNew
            label="Waste Stream"
            value={field?.value?.value}
            onChange={(e) => setValue('data.wasteStream', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Waste stream is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.wasteIn"
        render={({ field, fieldState }) => (
          <WasteInLocationSelect
            {...field}
            value={field?.value}
            label="Waste-in location"
            onChange={(e) => setValue('data.wasteIn', e)}
            error={fieldState.error?.message}
            createNew
          />
        )}
        rules={{ required: 'Waste-in location is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.wasteContractor"
        render={({ field, fieldState }) => (
          <SupplierSelector
            value={field?.value?.value}
            label="Waste Contractor"
            utility={dataSection}
            supplierType={SupplierType.Contractor}
            onChange={(s: OptionsProps) => setValue('data.wasteContractor', s)}
            accountId={accountId}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Waste contractor is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.wasteCarrier"
        render={({ field }) => (
          <SupplierSelector
            value={field?.value?.value}
            label="Waste Carrier"
            utility={dataSection}
            onChange={(s: OptionsProps) => setValue('data.wasteCarrier', s)}
            accountId={accountId}
            supplierType={SupplierType.Carrier}
          />
        )}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.containerTypeSize"
        render={({ field, fieldState }) => (
          <ContainerTypeSelect
            value={field?.value?.value}
            label="Container Type Size"
            onChange={(e: OptionsProps) => setValue('data.containerTypeSize', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Container type size is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.numberOfCollections"
        render={({ field }) => <NumberInput label="Number of collections" {...field} />}
        defaultValue=""
        control={control}
      />

      <Controller
        name="data.quantity"
        render={({ field, fieldState }) => (
          <NumberInputWithNegativeOption label="Quantity" error={fieldState.error?.message} {...field} />
        )}
        rules={{ required: 'Quantity is required' }}
        defaultValue=""
        control={control}
      />

      <Controller
        name="data.unitOfWeight"
        render={({ field, fieldState }) => (
          <MeasurementUnitSelect
            label="Unit of weight"
            onChange={(e: OptionsProps) => setValue('data.unitOfWeight', e)}
            value={field?.value?.value}
            possibleOptions={['kg', 't']}
            error={fieldState.error?.message}
            placeholder={'Please select unit of weight'}
          />
        )}
        rules={{ required: 'Unit of Weight is required' }}
        defaultValue={null}
        control={control}
      />

      <Controller
        name="data.numberOfContainers"
        render={({ field }) => <NumberInput label="Number of containers" {...field} />}
        defaultValue=""
        control={control}
      />

      <Controller
        name="data.treatmentProcess"
        render={({ field, fieldState }) => (
          <TreatmentProcessSelect
            value={field?.value?.value}
            label="Treatment Process"
            onChange={(e: OptionsProps) => setValue('data.treatmentProcess', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Treatment process is required' }}
        defaultValue=""
        control={control}
      />
      <Controller
        name="data.accuracy"
        render={({ field, fieldState }) => (
          <SelectRender
            label="Act/Est"
            value={field?.value?.value}
            options={[
              { value: Accuracy.Act, label: Accuracy.Act },
              { value: Accuracy.Est, label: Accuracy.Est },
            ]}
            onChange={(e: OptionsProps) => setValue('data.accuracy', e)}
            error={fieldState.error?.message}
          />
        )}
        rules={{ required: 'Act/Est is required' }}
        defaultValue=""
        control={control}
      />

      <GridStyled container>
        <Text typescale="title" size="medium">
          Do you want to add financial data?
        </Text>

        <Grid container>
          <Checkbox name="Financial" label="Yes" checked={!!isFinancialDataRequired} onChange={showFinanceData} />
          <Checkbox name="NoFinancial" label="No" checked={!isFinancialDataRequired} onChange={hideFinanceData} />
        </Grid>
      </GridStyled>

      <SectionStyled isVisible={isFinancialDataRequired}>
        <Controller
          name="financial.gateFee"
          render={({ field }) => <NumberInput label="Gate fee" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.managementFee"
          render={({ field }) => <NumberInput label="Management fee" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.binHire"
          render={({ field }) => <NumberInput label="Bin hire" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.binExchangeFee"
          render={({ field }) => <NumberInput label="Bin exchange fee" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.liftCharge"
          render={({ field }) => <NumberInput label="Lift charge" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.labourCost"
          render={({ field }) => <NumberInput label="Labour cost" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.equipmentHire"
          render={({ field }) => <NumberInput label="Equipment hire" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.miscCost"
          render={({ field }) => <NumberInput label="Misc cost" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.miscCostRate"
          render={({ field }) => <NumberInput label="Misc cost rate" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.transportCharge"
          render={({ field }) => <NumberInput label="Transport charge" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.rebate"
          render={({ field }) => <NumberInput label="Rebate" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.penalty"
          render={({ field }) => <NumberInput label="Penalty" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="financial.currency"
          render={({ field }) => (
            <CurrencySelect
              label="Currency"
              value={field?.value?.value}
              onChange={(e: OptionsProps) => setValue('financial.currency', e)}
            />
          )}
          control={control}
        />

        <Controller
          name="financial.notes"
          render={({ field }) => <TextField label="Notes" {...field} />}
          defaultValue=""
          control={control}
        />
      </SectionStyled>

      <GridStyled container>
        <Text typescale="title" size="medium">
          Do you want to add any references?
        </Text>

        <Grid container>
          <Checkbox name="Reference" label="Yes" checked={!!isReferenceDataRequired} onChange={showReferenceData} />
          <Checkbox name="NoReference" label="No" checked={!isReferenceDataRequired} onChange={hideReferenceData} />
        </Grid>
      </GridStyled>

      <SectionStyled isVisible={isReferenceDataRequired}>
        <Controller
          name="reference.wasteTransferNote"
          render={({ field }) => <TextField label="Waste transfer note ID" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.consignmentNoteId"
          render={({ field }) => <TextField label="Consignment note (SEPA) ID" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.consignmentNoteEA"
          render={({ field }) => <TextField label="Consignment note (EA) ID" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.invoiceNumber"
          render={({ field }) => <TextField label="Invoice number" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.salesOrder"
          render={({ field }) => <TextField label="Sales order ID" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.reference"
          render={({ field }) => <TextField label="Reference" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.evidenceReference"
          render={({ field }) => <TextField label="Evidence reference" {...field} />}
          defaultValue=""
          control={control}
        />

        <Controller
          name="reference.otherReference"
          render={({ field }) => <TextField label="Other reference" {...field} />}
          defaultValue=""
          control={control}
        />
      </SectionStyled>
      {props.children}
    </DataForm>
  );
};
