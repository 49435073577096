import { useQuery } from '@apollo/client';
import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { AccountType } from '@rio/rio-types';

import { ErrorMessage, ContainerError } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import NotificationBanner from '../../../components/NotificationBanner';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { useCurrentAccount, useCurrentAccountId } from '../../../hooks';
import ReportingReports from './ReportingReportsPage';

import { GET_SUBSCRIBED_DASHBOARDS } from './index.queries';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

// Patron Capital
const DEMO_ACCOUNT_IDS = ['b1f7fc4e-714d-4271-bb3f-d736dff38a14', '608877fc-83e7-4487-b5a4-493e0ba05903'];
const DEMO_DASHBOARDS = [
  { id: '0f7db846-67a6-4d3e-807d-67c3ef5f4f30', name: 'Patron Capital - Carbon Report' },
  { id: '65236e34-ca0d-42e1-a8f1-b7c71ca08555', name: 'Patron Capital - Dalton Park' },
  { id: '1a8ee51b-e5c9-483a-85d6-86e9117e0aa1', name: 'Patron Capital - MK Gateway' },
  { id: '3e75ba9e-3c96-4396-b1b7-ba4f64680907', name: 'Patron Capital - GRESB' },
];

const ReportingReportsContainer = ({ intl }) => {
  const accountId = useCurrentAccountId();
  const { folderId } = useParams();
  const [subjectFilters, setSubjectFilers] = useState({
    showLearn: true,
    showComply: true,
    showData: true,
  });
  const account = useCurrentAccount();
  const isEngage = account?.type === AccountType.Engage;
  const {
    data: dashboardData,
    loading,
    error,
    refetch,
  } = useQuery(GET_SUBSCRIBED_DASHBOARDS, { variables: { accountId } });
  const [dashboards, setDashboards] = useState(dashboardData);

  const defaultBreakcrumbs = useMemo(
    () => [
      { title: intl.formatMessage({ id: 'pages.reporting.breadcrumbs.one' }), to: '/reporting' },
      { title: intl.formatMessage({ id: 'pages.reporting.breadcrumbs.two' }), to: '/reporting' },
    ],
    [intl]
  );

  useEffect(() => {
    if (!loading) {
      if (DEMO_ACCOUNT_IDS.includes(account.id)) {
        // exclude demo dashboards from the list
        const defaultDashboards = dashboardData?.getSubscribedDashboards.filter(
          (item) => !DEMO_DASHBOARDS.map(({ id }) => id).includes(item.id)
        );
        setDashboards({
          getSubscribedDashboards: defaultDashboards,
        });
      } else {
        setDashboards(dashboardData);
      }
    }
  }, [loading, dashboardData, account]);

  const toggleSubject = (subject) => {
    const subjects = { ...subjectFilters };

    if (subject === 'Learn') {
      subjects.showLearn = !subjectFilters.showLearn;
    } else if (subject === 'Comply') {
      subjects.showComply = !subjectFilters.showComply;
    } else if (subject === 'Data') {
      subjects.showData = !subjectFilters.showData;
    }

    setSubjectFilers({
      ...subjects,
    });
  };

  const filtered = dashboards?.getSubscribedDashboards
    .filter((dash) => dash?.folderId === folderId)
    .filter((dash) => {
      const learn = subjectFilters.showLearn ? dash.area === 'LEARN' : false;
      const comply = subjectFilters.showComply ? dash.area === 'COMPLY' : false;
      const data = subjectFilters.showData ? dash.area === 'DATA' : false;

      return learn || data || comply;
    })
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    });

  return (
    <ContainerStyled name="ReportingReports">
      <PageHeader
        name="ReportingReports"
        title={intl.formatMessage({ id: 'pages.reporting.heading' })}
        breadcrumbs={defaultBreakcrumbs}
        icon="chart-line"
        iconColor="quaternary"
      />

      {isEngage && (
        <NotificationBannerStyled
          name="ComplyContainer_Access_NotificationBanner"
          icon="exclamation-triangle"
          color="danger"
          title={`Reporting`}
          body={`Currently Reporting is not included in your subscription, if you’d like access to Reporting please contact Rio ESG LTD or get in touch with your Business Admin.`}
        />
      )}

      {!isEngage && loading && <ContainerLoadingIndicator name="ReportingReports__Loading" />}
      {!isEngage && error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError name="ReportingReports__error" icon={icon} title={title} body={body} retry={refetch} />
          )}
        </ErrorMessage>
      )}

      {!isEngage && dashboardData && (
        <ReportingReports
          dashboards={filtered}
          accountId={accountId}
          subjectFilters={subjectFilters}
          filterOnClick={toggleSubject}
        />
      )}
    </ContainerStyled>
  );
};

export default injectIntl(ReportingReportsContainer);
