export const LOCATION = 'LOCATION';
export const ACCOUNT = 'ACCOUNT';
export const TAG = 'TAG';
export const DEPARTMENT = 'DEPARTMENT';
export const REGION = 'REGION';

export const labels = {
  [LOCATION]: 'Location',
  [ACCOUNT]: 'Account',
  [TAG]: 'Tag',
  [DEPARTMENT]: 'Department',
  [REGION]: 'Region'
};
