import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Checkbox, CheckboxLabel } from './FormElements';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  padding-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const LinkToTerms = styled.a`
  color: ${(props) => props.theme.colors.primary.light.background};
`;

export const TermsAndConditionsCheckbox = ({ terms, termsAgreement, onChange }) => {
  return (
    <CheckboxContainer>
      <Checkbox id={terms} checked={termsAgreement} onChange={onChange} />
      <CheckboxLabel for={terms}>
        <FormattedMessage id="login.termsAgreement.termsAgreementText" />
        <LinkToTerms href={`http://www.rio.ai/terms-of-${terms}`} target="_blank">
          <FormattedMessage
            id="login.termsAgreement.termsLinkText"
            values={{
              terms: <FormattedMessage id={`login.termsAgreement.${terms}`} />
            }}
          />
        </LinkToTerms>
      </CheckboxLabel>
    </CheckboxContainer>
  );
};
