import { useCallback, useState } from 'react';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { Modal, Grid, Button, Text, styled, TextArea } from '@rio/ui-components';

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const Buttons = styled(Grid)`
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;

const WrapperTextArea = styled('div')`
  position: relative;

  .MuiFormHelperText-root {
    padding-right: 80px;
  }
`;

const WordCount = styled(Text)`
  position: absolute;
  bottom: 0px;
  right: 16px;
`;

interface SendFeedbackModalProps {
  intention: SurveySubmissionFeedbackStatus;
  onDismiss: () => void;
  onFeedback: (intention: SurveySubmissionFeedbackStatus, feedback: string | null) => void;
}

export function SendFeedbackModal({ intention, onDismiss, onFeedback }: SendFeedbackModalProps) {
  const approve = intention === SurveySubmissionFeedbackStatus.Approved;
  const CHARACTER_LIMIT = 1000;
  const [sending, setSending] = useState(false);
  const [feedback, setFeedback] = useState<string>('');

  const title = approve
    ? 'Are you sure you want to approve this response?'
    : 'Are you sure you want to request changes this response?';
  const buttonText = approve ? 'Yes, approve' : 'Yes, request changes';

  const handleFeedbackChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newText = event.target.value.trimStart();
      if (newText.length <= CHARACTER_LIMIT) {
        setFeedback(newText);
      }
    },
    [setFeedback]
  );

  const handleConfirm = useCallback(async () => {
    try {
      setSending(true);
      await onFeedback(intention, feedback);
    } finally {
      setSending(false);
      onDismiss();
    }
  }, [onFeedback, feedback, intention, onDismiss]);

  return (
    <Modal open onClose={onDismiss} title={title} grid={{ xs: 8, md: 'auto' }}>
      <Form>
        <WrapperTextArea>
          <TextArea
            value={feedback || ''}
            onChange={handleFeedbackChange}
            rows={3}
            error={false}
            label="Comments for recipients (optional)"
            helperText="These notes will appear in the email sent to your recipients"
          />
          <WordCount typescale="body" size="large">{`${feedback.length}/${CHARACTER_LIMIT}`}</WordCount>
        </WrapperTextArea>
        <Buttons container>
          <Grid xs={12} md={5.5}>
            <ButtonStyled variant="outlined" disabled={sending} onClick={onDismiss}>
              No, keep as is
            </ButtonStyled>
          </Grid>
          <Grid xs={12} md={5.5}>
            <ButtonStyled variant="filled" onClick={handleConfirm} disabled={sending}>
              {buttonText}
            </ButtonStyled>
          </Grid>
        </Buttons>
      </Form>
    </Modal>
  );
}
