import { useQuery } from '@apollo/client';
import { OptionsProps, Select } from '@rio/ui-components';

import { NamedEntity, Query } from '@rio/rio-types';
import { Option, Nullable } from '~/types';
import { GET_ALL_ENERGY_TYPES } from '../index.queries';

const loadingOption = { label: 'Loading...', value: '', disabled: true };
const noResultsFoundOption = { label: 'No energy sources found', value: '', disabled: true };

export interface EnergySourcesSelectionProps {
  value: string;
  label?: string;
  defaultOptions?: Option[];
  error?: Nullable<string>;
  name?: string;
  filter?: (s?: OptionsProps) => boolean;
  onChange: (s: OptionsProps) => void;
  disabled?: boolean;
  onEnergySourceLoadComplete?: (energySources: NamedEntity[]) => void;
}

export function EnergySourcesSelection({
  value,
  error,
  name,
  onChange,
  label = 'Energy type',
  disabled,
  onEnergySourceLoadComplete,
}: EnergySourcesSelectionProps) {
  const {
    data,
    loading,
    error: loadError,
  } = useQuery<Pick<Query, 'getAllEnergyType'>>(GET_ALL_ENERGY_TYPES, {
    onCompleted: onEnergySourceLoadComplete
      ? (res) => onEnergySourceLoadComplete((res.getAllEnergyType as NamedEntity[]) || [])
      : undefined,
  });
  const energyTypes = data?.getAllEnergyType || [];
  const options = energyTypes
    .map((e) => ({
      value: e?.id,
      label: e?.name,
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 1) as OptionsProps[];

  if (!loading && !loadError && options.length === 0) {
    options.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label={label}
      value={value}
      options={loading ? [loadingOption] : options}
      isLoading={loading}
      error={Boolean(error)}
      helperText={error || ''}
      disabled={disabled}
      onChange={(option: OptionsProps) => onChange(option)}
    />
  );
}
