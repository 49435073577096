import { useQuery, QueryHookOptions, useApolloClient } from '@apollo/client';
import { Query, QuestionUnion } from '@rio/rio-types';
import { GET_REPORTING_QUESTIONS } from './index.queries';
import { useCurrentAccountId, useNotification } from '../../hooks';
import { useCallback } from 'react';

export type QueryResponse = Pick<Query, 'getReportingQuestions'>;

export interface QueryVariables {
  accountId: string;
  frameworkId: string;
  chapterId?: string;
}

export function useGetReportingQuestions(
  input: { frameworkId: string; chapterId?: string },
  queryOptions?: QueryHookOptions<QueryResponse, QueryVariables>
) {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  return useQuery<QueryResponse, QueryVariables>(GET_REPORTING_QUESTIONS, {
    ...queryOptions,
    variables: {
      accountId,
      frameworkId: input.frameworkId,
      chapterId: input.chapterId,
    },
    onError: (err: Error) => {
      showNotification(`Couldn't load reporting framework: ${err}`, 'danger');
    },
  });
}

export function useReadGetReportingQuestions() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  return useCallback(
    (frameworkId: string, chapterId?: string) => {
      return client.readQuery<QueryResponse, QueryVariables>({
        query: GET_REPORTING_QUESTIONS,
        variables: {
          accountId,
          frameworkId,
          chapterId,
        },
      });
    },
    [client, accountId]
  );
}

export function useWriteGetReportingQuestions() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  return useCallback(
    (updatedQuestions: QuestionUnion[], frameworkId: string, chapterId?: string) => {
      return client.writeQuery<QueryResponse, QueryVariables>({
        query: GET_REPORTING_QUESTIONS,
        data: {
          getReportingQuestions: updatedQuestions,
        },
        variables: {
          accountId,
          frameworkId,
          chapterId,
        },
      });
    },
    [client, accountId]
  );
}
