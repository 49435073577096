import React from 'react';
import { Row, Col, Text, TextLink, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import { getColumnWidth } from './gridConfig';

const RowStyled = styled(Row)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  ${(p) => {
    if (p.action) return `background-color: ${p.theme.colors.warning.highlight.background};`;
  }};
`;

const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
  word-break: break-all;
`;

const getDisplayedValue = (config, column, carbonConversionFactor) => {
  return config[column] && typeof config[column].format === 'function'
    ? config[column].format(carbonConversionFactor[column])
    : carbonConversionFactor[column];
};

export function CarbonConversionFactorRow({ factor, config }) {
  return (
    <RowStyled container>
      {Object.keys(config).map((column) =>
        column === 'reference' ? (
          <ColStyled item span={getColumnWidth(config, column)} key={column}>
            <TextLink href={factor.reference} target="_blank">
              <Icon icon="external-link-alt" />
            </TextLink>
          </ColStyled>
        ) : (
          <ColStyled item span={getColumnWidth(config, column)} key={column}>
            <Text>{getDisplayedValue(config, column, factor)}</Text>
          </ColStyled>
        )
      )}
    </RowStyled>
  );
}
