import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Modal, Row, Col, TextInput, Select, Label, Heading, Button } from 'rio-ui-components';
import { CREATE_CONTAINER_TYPE, GET_ALL_UNITS } from './index.queries';

const FormStyled = styled.form`
  min-height: 50vh;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonRow = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonCol = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

function validateContainer(container) {
  const errors = {};
  if (!container.name) {
    errors.name = 'Name is required';
  }
  if (!container.volume) {
    errors.volume = 'Volume is required';
  }
  if (!(parseFloat(container.volume, 10) > 0)) {
    errors.volume = 'Volume should be greater than 0';
  }
  if (!container.volumeUnitId) {
    errors.volumeUnitId = 'Please select a unit';
  }
  return errors;
}

export function CreateContainerTypeModal({ onDismiss, onCompleted, onError }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [container, setContainer] = useState({
    name: '',
    volume: 0,
    volumeUnitId: null
  });
  const [errors, setErrors] = useState({});
  const [createContainerType] = useMutation(CREATE_CONTAINER_TYPE, {
    onError,
    onCompleted
  });
  const { data } = useQuery(GET_ALL_UNITS);
  const units = data?.getAllUnits || [];

  return (
    <Modal onDismiss={onDismiss} size="md" show height="auto" maxHeight="90vh">
      <FormStyled>
        <HeaderStyled size="lg">Add Container Type</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Container Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              disabled={isSubmitting}
              onChange={(e) => {
                if (e.target.value) {
                  setErrors({
                    ...errors,
                    name: null
                  });
                }
                setContainer({
                  ...container,
                  name: e.target.value
                });
              }}
              name="name"
              value={container.name}
              error={errors.name}
              box
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Volume</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              name="volume"
              type="number"
              disabled={isSubmitting}
              onChange={(e) => {
                if (e.target.value) {
                  setErrors({
                    ...errors,
                    volume: null
                  });
                }
                setContainer({
                  ...container,
                  volume: e.target.value
                });
              }}
              value={container.volume}
              error={errors.volume}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Unit</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="volumeUnitId"
              disabled={isSubmitting}
              error={errors.volumeUnitId}
              onChange={(e) => {
                if (e.target.select.value) {
                  setErrors({
                    ...errors,
                    volumeUnitId: null
                  });
                }
                setContainer({
                  ...container,
                  volumeUnitId: e.target.select.value
                });
              }}
              value={container.volumeUnitId}
              options={units.map((unit) => ({
                value: unit.id,
                label: unit.name
              }))}
            />
          </ColStyled>
        </Row>
        <ButtonRow container>
          <ButtonCol container item>
            <Button color="info" onClick={onDismiss}>
              Cancel
            </Button>
          </ButtonCol>
          <ButtonCol container item>
            <Button
              type="button"
              onClick={async () => {
                const errors = validateContainer(container);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }
                setIsSubmitting(true);
                await createContainerType({
                  variables: {
                    input: {
                      id: uuid(),
                      name: container.name,
                      volume: parseFloat(container.volume, 10),
                      volumeUnitId: container.volumeUnitId
                    }
                  }
                });
                setIsSubmitting(false);
              }}
            >
              Add
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormStyled>
    </Modal>
  );
}
