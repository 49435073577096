import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentAccount } from '~/hooks';
import Courses from './Courses';
import CoursePreview from './CoursePreview';
import CoursesOverview from './CoursesOverview';

const ContainerStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const LearnContainer = () => {
  const account = useCurrentAccount();
  const hasLearnAccess = !account || account.accessControls.learn;

  return (
    <ContainerStyled>
      {hasLearnAccess ? (
        <Routes>
          <Route path="" element={<CoursesOverview learnAccess={hasLearnAccess} />} />
          <Route path=":id" element={<Courses learnAccess={hasLearnAccess} />} />
          <Route path="course/:id" element={<CoursePreview learnAccess={hasLearnAccess} />} />
          <Route path=":bundleId/course/:id" element={<CoursePreview learnAccess={hasLearnAccess} />} />
          <Route path="course/SCORM/:id" element={<CoursePreview learnAccess={hasLearnAccess} />} />
          <Route path=":bundleId/course/SCORM/:id" element={<CoursePreview learnAccess={hasLearnAccess} />} />
          <Route path="*" element={<Navigate to="engage" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="" element={<CoursesOverview learnAccess={hasLearnAccess} />} />
          <Route path="*" element={<Navigate to="engage" />} />
        </Routes>
      )}
    </ContainerStyled>
  );
};

export default LearnContainer;
