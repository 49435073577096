import React, { Component } from 'react';
import styled from 'styled-components';
import NotificationBanner from '../NotificationBanner';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_USER_NOTIFICATIONS } from './index.queries';

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const Container = styled.div`
  padding-right: ${(props) => props.padding};
  padding-left: ${(props) => props.padding};
`;

class UserBannersSection extends Component {
  constructor(props) {
    super(props);

    this.state = { dismissedBanners: [] };
    this.staticBanners = props.staticBanners || [];

    try {
      const localStorageDismissableBanner = localStorage.getItem('DISMISSED_BANNERS') || [];

      if (Array.isArray(localStorageDismissableBanner)) {
        this.state.dismissedBanners = localStorageDismissableBanner;
      } else {
        this.state.dismissedBanners = localStorageDismissableBanner.split(',');
      }

      this.userNotifications = this.props.client
        .query({
          query: GET_USER_NOTIFICATIONS
        })
        .then((d) => this.setState({ notifications: [...this.staticBanners, ...d.data.getNotifications] }))
        .catch(console.log);
    } catch (error) {
      console.log(error);
    }
  }

  dismissBanner = (id) => {
    const dismissedBanners = this.state.dismissedBanners;
    dismissedBanners.push(id);
    this.setState({ dismissedBanners }, localStorage.setItem('DISMISSED_BANNERS', dismissedBanners));
  };

  render() {
    return (
      <Container
        id={`${this.props.id || ''}__UserBannersSection--container`}
        name={`${this.props.id || ''}__UserBannersSection--container`}
        padding={this.props.padding || 0}
      >
        {this.state.notifications &&
          this.state.notifications.length > 0 &&
          this.state.notifications.map((n) => {
            if (this.state.dismissedBanners.includes(n.id)) {
              return null;
            } else {
              return (
                <NotificationBannerStyled
                  id={`UserBannersSection__NotificationBanner--${n.id}`}
                  name={`UserBannersSection__NotificationBanner--${n.id}`}
                  icon={n.icon || 'info-circle'}
                  color={n.color || 'quinary'}
                  title={n.title}
                  body={n.message}
                  key={n.id}
                  dismissable
                  onDismiss={() => this.dismissBanner(n.id)}
                />
              );
            }
          })}
      </Container>
    );
  }
}

export default withApollo(UserBannersSection);
