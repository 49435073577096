import React from 'react';
import styled from 'styled-components';
import { Heading, Row, Col, Button, Modal } from 'rio-ui-components';

const ButtonsContainer = styled(Row)`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  min-width: 200px;
`;
const HeadingStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
  padding: 20px;
`;

interface CreateNewTaskSuggestionModalProps {
  onConfirm: (answer: boolean) => void;
}

export const CreateNewTaskSuggestionModal = ({ onConfirm }: CreateNewTaskSuggestionModalProps) => (
  <Modal size="md" onDismiss={() => onConfirm(false)} show>
    <HeadingStyled size="lg">Do you want to create an associated task?</HeadingStyled>
    <ButtonsContainer container>
      <ColStyled span={4}>
        <ButtonStyled color="success" onClick={() => onConfirm(true)}>
          Yes
        </ButtonStyled>
      </ColStyled>
      <ColStyled span={4}>
        <ButtonStyled color="info" onClick={() => onConfirm(false)}>
          No
        </ButtonStyled>
      </ColStyled>
    </ButtonsContainer>
  </Modal>
);

