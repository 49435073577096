import { findKey } from 'lodash';
import { useState, useEffect } from 'react';

export function useObjectFilters(defaultState) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const constructedFilters = { ...state.filters };

    Object.keys(state.filters).forEach((filterKey) => {
      const values = state.filters[filterKey].values;
      if (Array.isArray(values)) {
        const addNullOption = state.filters[filterKey].addNullOption;
        const notSpecifiedOption = {
          id: null,
          name: 'Not specified'
        };
        if (addNullOption && !values.find((cat) => cat.id === null)) values.push(notSpecifiedOption);
        constructedFilters[filterKey] = {
          ...state.filters[filterKey],
          options: values.map((category) => ({ name: category.name, id: category.id, icon: category.icon, colour: category.colour }))
        };
      }
    });

    setState((prevState) => ({
      ...prevState,
      filters: constructedFilters
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAppliedFilters = (newFilterArr) => {
    let newState = { ...state };
    newFilterArr.forEach((item) => {
      const { filterKey, newAppliedList } = item;
      newState = {
        ...newState,
        filters: {
          ...newState.filters,
          [filterKey]: {
            ...newState.filters[filterKey],
            applied: newAppliedList
          }
        }
      };
    });
    setState(newState);
    return newState;
  };
  const handleFilterToggle = (value, filterKey) => {
    const { applied } = state.filters[filterKey];
    const newAppliedList = applied.includes(value) ? applied.filter((item) => item !== value) : [...applied, value];
    return updateAppliedFilters([{ filterKey, newAppliedList }]);
  };
  const removeAllFilters = () => {
    const result = [{ newAppliedList: [], filterKey: state.activeFilter }];
    const showFilterKey = findKey(state.filters, { showIn: state.activeFilter });
    if (showFilterKey) result.push({ newAppliedList: [], filterKey: showFilterKey });
    return updateAppliedFilters(result);
  };
  const addAllFilters = () => {
    const result = [];
    const { options } = state.filters[state.activeFilter];
    result.push({ newAppliedList: typeof options[0] === 'object' ? options.map(({ id }) => id) : [...options], filterKey: state.activeFilter });

    const showFilterKey = findKey(state.filters, { showIn: state.activeFilter });
    if (showFilterKey) result.push({ newAppliedList: state.filters[showFilterKey].values.map(({ id }) => id), filterKey: showFilterKey });

    return updateAppliedFilters(result);
  };
  const setActiveFilter = (activeFilter) => {
    setState((prevState) => ({
      ...prevState,
      previousFilter: prevState.activeFilter,
      activeFilter
    }));
  };
  return {
    ...state,
    handleFilterToggle,
    removeAllFilters,
    addAllFilters,
    setActiveFilter,
    updateAppliedFilters
  };
}
