import { useQuery } from '@apollo/client';
import { GET_ALIASES_PAGE } from '../containers/ConfigurationContainer/ConfigurationAliasesContainer/index.queries';

export function useGetAliasesPage({ accountId, aliasType, energyType }) {
  const {
    data: { getAliasesPage = [] } = {},
    loading,
    error,
    networkStatus,
    refetch
  } = useQuery(GET_ALIASES_PAGE, {
    variables: {
      accountId,
      aliasType,
      energyType
    },
    notifyOnNetworkStatusChange: true,
    skip: !accountId
  });

  return {
    data: getAliasesPage,
    loading,
    error,
    networkStatus,
    refetch
  };
}
