import { useState, useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { QueryGetUsersArgs } from '@rio/rio-types';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { useApolloClient } from '@apollo/client';
import { Col, Search, Button, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { useNotification } from '../../../hooks';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContainerHeader from '../../../components/ContainerHeader';
import { Nullable, SelectEvent } from '../../../types';
import { ExportButton } from '../../../components/ExportButton';
import { LoadFailed } from '../../../components/Errors';
import { Grid } from './Grid';
import { CreateAccount } from './CreateAccount';
import { UpdateAccount } from './UpdateAccount';
import AccountLogoUpload from './AccountLogoUpload';
import { useGetAccounts } from './useGetAccounts';
import { useGetAccountById } from './useGetAccountById';
import { useAccountSearchValueFromQueryParam } from './useAccountSearchValueFromQueryParam';
import { GET_ACCOUNTS } from './index.queries';
import { ToastColor } from '@rio/ui-components';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

export const MODALS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  LOGO: 'LOGO',
  BANNER: 'BANNER',
};

interface Notification {
  message?: Nullable<string>;
  color?: Nullable<string>;
  id?: string;
}
interface ModalInfo {
  type: Nullable<string>;
  accountId: Nullable<string>;
}
const ConfigurationAccountsContainer = () => {
  const client = useApolloClient();
  const [gridApi, setGridApi] = useState<{ api: GridApi; columnApi: ColumnApi }>();
  const [searchValue, setSearchValue] = useAccountSearchValueFromQueryParam();
  const { data, loading, error, refetch: refetchFn, networkStatus } = useGetAccounts();
  const [showModalInfo, setShowModal] = useState<ModalInfo>({ type: null, accountId: null });
  const {
    data: selectedAccountData,
    loading: selectedAccountLoading,
    error: selectedAccountError,
  } = useGetAccountById(showModalInfo?.accountId, !showModalInfo?.accountId);
  const { showNotification } = useNotification();

  const allAccounts = data?.getAccounts?.rows || [];

  const refreshGrid = useCallback(
    () =>
      gridApi?.api?.refreshServerSide({
        purge: true,
      }),
    [gridApi]
  );

  const refreshGridCb = useMemo(() => debounce(refreshGrid, 250), [refreshGrid]);

  useEffect(() => {
    refreshGridCb();
  }, [searchValue, refreshGridCb]);

  const showModal = (type: Nullable<string>) => (accountId?: Nullable<string>) => setShowModal({ type, accountId });

  const dismissModals = (notification: Notification = {}, refetch = () => {}) => {
    showModal(null)(null);
    if (notification.message) {
      showNotification(notification.message, notification.color as ToastColor);
    }
    refetch();
  };

  const fetchRows = async (variables: QueryGetUsersArgs) => {
    const {
      data: {
        getAccounts: { rows, totalRows },
      },
    } = await client.query({
      query: GET_ACCOUNTS,
      variables,
      fetchPolicy: 'network-only',
    });

    return {
      rows,
      totalRows,
    };
  };

  return (
    <Col name="ConfigurationAccountsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationAccountsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title="Accounts"
      >
        <Col span={6} container item>
          <Search
            name="ConfigurationAccountsContainer__Controls__Search"
            value={searchValue}
            onChange={(e: SelectEvent) => setSearchValue(e.target.value)}
            hideButton
          />
        </Col>
        <Col span={4} container item>
          <ButtonStyled
            name="ConfigurationAccountsContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => showModal(MODALS.CREATE)()}
          >
            + Add Account
          </ButtonStyled>
        </Col>
        <Col span={4} container item>
          <ExportButton
            fetchRows={fetchRows}
            gridApi={gridApi?.api}
            columnApi={gridApi?.columnApi}
            defaultExportFileName={'Account Exports'}
            label="Export Data"
          />
        </Col>
      </ContainerHeader>
      {((loading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />
      )}
      {error ? (
        <LoadFailed error={error} retry={fetchRows} />
      ) : (
        <Grid
          fetchRows={fetchRows}
          setGridApi={setGridApi}
          onEdit={showModal(MODALS.UPDATE)}
          onLogoEdit={showModal(MODALS.LOGO)}
          onBannerEdit={showModal(MODALS.BANNER)}
        />
      )}
      {showModalInfo.type === MODALS.UPDATE && (
        <Modal
          size="lg"
          show
          loading={selectedAccountLoading}
          dismissable
          name="ConfigurationAccountsContainer__Modal--update"
          onDismiss={dismissModals}
        >
          {selectedAccountError && `Error! ${selectedAccountError.message}`}
          {!selectedAccountLoading && !selectedAccountError && selectedAccountData?.getAccountInfo ? (
            <UpdateAccount
              account={selectedAccountData.getAccountInfo}
              businessName={selectedAccountData.getAccountInfo.name || ''}
              accessControls={selectedAccountData.getAccountInfo.accessControls || []}
              notes={selectedAccountData.getAccountInfo.notes || ''}
              showChatBot={!!selectedAccountData.getAccountInfo.showChatBot}
              reportingYearId={selectedAccountData.getAccountInfo.reportingYear?.id || ''}
              dismiss={dismissModals}
              allAccounts={allAccounts}
            />
          ) : null}
        </Modal>
      )}
      {showModalInfo.type === MODALS.LOGO && (
        <Modal
          size="sm"
          show
          dismissable
          loading={selectedAccountLoading}
          name="ConfigurationAccountsContainer__Modal--Logo"
          onDismiss={dismissModals}
        >
          {selectedAccountError && `Error! ${selectedAccountError.message}`}
          {!selectedAccountLoading && !selectedAccountError && (
            <AccountLogoUpload
              accountData={selectedAccountData?.getAccountInfo}
              onSuccess={(message: string, color: string) => dismissModals({ message, color }, refetchFn)}
              dismiss={dismissModals}
            />
          )}
        </Modal>
      )}
      {showModalInfo.type === MODALS.BANNER && (
        <Modal
          size="lg"
          show
          dismissable
          loading={loading}
          name="ConfigurationAccountsContainer__Modal--Logo"
          onDismiss={dismissModals}
        >
          {selectedAccountError && `Error! ${selectedAccountError.message}`}
        </Modal>
      )}
      {showModalInfo.type === MODALS.CREATE && (
        <Modal
          size="lg"
          show
          name="ConfigurationAccountsContainer__Modal--create"
          dismissable
          onDismiss={dismissModals}
        >
          <CreateAccount onDismiss={() => dismissModals()} allAccounts={allAccounts} />
        </Modal>
      )}
    </Col>
  );
};

export default ConfigurationAccountsContainer;
