import { useState } from 'react';
import styled from 'styled-components';
import { TaskSubject } from '@rio/rio-types';
import { Col, Row, Label, TextInput, TextArea, Select, DatePicker, Checkbox } from 'rio-ui-components';
import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { LOCATION, TAG } from '../../../constants/scopes';
import { useScopeOptions } from '../../../hooks';
import UserSelect from '../../../components/UserSelect';
import categoryOptions from './taskCategories.json';
import subjectOptions from './taskSubjects.json';
import priorityOptions from './taskPriorities.json';
import { LegislationSelect } from '../../../components/LegislationSelect';
import { AspectsSelect } from '../../../components/AspectsSelect';
import { TargetsSelect } from '../../../components/TargetsSelect';
import { ProjectSelect } from '../../../components/ProjectSelect';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const RowStyled = styled(Row)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
`;

const StartDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -354px;
    left: 50%;
  }
`;

const TaskDetails = ({
  isSubmitting,
  task,
  setTask,
  accountId,
  isEditing,
  sourcePage,
  disabled = {},
  projectId = '',
  hasProjectsAccess = false
}) => {
  const [selectedUsers, setSelectedUsers] = useState(task.selectedUsers || null);
  const scopeOptions = useScopeOptions();
  const handleChange = (event) => {
    if (event.target.name === 'projectId') {
      event.target.select = event.target.value;
    }
    setTask({
      ...task,
      [event.target.name]: event.target.value || event.target.select
    });
    if (event.target.name === 'subject' && (task.item?.id || task?.itemId)) {
      setTask({
        ...task,
        itemId: null,
        item: null
      });
    }
  };
  const taskItemId = task.itemId || task.item?.id;
  return (
    <>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Task Name</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            data-cy="TaskDetails__Input--name"
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="name"
            value={task.name}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Description</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            data-cy="TaskDetails__Input--description"
            size="lg"
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="description"
            value={task.description}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="TaskDetails__Input-scope">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Scope</LabelStyled>
          </LabelContainerStyled>
          <Select
            name="scope"
            options={scopeOptions}
            value={task.scope}
            onChange={handleChange}
            isDisabled={disabled.scope}
          />
        </ColStyled>
      </Row>
      {task.scope === LOCATION && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Location(s)</LabelStyled>
            </LabelContainerStyled>
            <LocationsMultiSelect
              name="locationIds"
              accountId={accountId}
              value={task.locationIds}
              onChange={handleChange}
              disabled={disabled.locations}
            />
          </ColStyled>
        </Row>
      )}
      {task.scope === TAG && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Tag(s)</LabelStyled>
            </LabelContainerStyled>
            <TagsMultiSelect
              name="tagIds"
              accountId={accountId}
              value={task.tagIds}
              onChange={handleChange}
              disabled={disabled.tags}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between" data-cy="TaskDetails__Input--priority">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Priority</LabelStyled>
          </LabelContainerStyled>
          <Select
            name="priority"
            classPrefix="priority-select"
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            value={task.priority}
            options={priorityOptions}
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="TaskDetails__Input--category">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Category</LabelStyled>
          </LabelContainerStyled>
          <Select
            name="category"
            classPrefix="category-select"
            isDisabled={disabled.category || isSubmitting}
            onChange={(e) => {
              setTask({
                ...task,
                [e.target.name]: e.target.value,
                subject: null,
                itemId: null,
                item: null
              });
            }}
            value={task.category}
            options={categoryOptions}
          />
        </ColStyled>
      </Row>
      {subjectOptions[task.category] && (
        <Row container align="between" data-cy="TaskDetails__Input--subject">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Subject</LabelStyled>
            </LabelContainerStyled>
            <Select
              name="subject"
              classPrefix="subject-select"
              disabled={isSubmitting}
              onChange={(e) => handleChange(e)}
              value={task.subject?.toUpperCase()}
              options={subjectOptions[task.category]}
              isDisabled={disabled.subject}
            />
          </ColStyled>
        </Row>
      )}
      {task.subject && [TaskSubject.Legislation, TaskSubject.Aspects, TaskSubject.Targets].includes(task.subject) && (
        <RowStyled container align="between" data-cy="TaskDetails__Input--item" disabled={disabled[task.subject]}>
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>
                Related item{' '}
                {disabled[task.subject] &&
                  task.subject === TaskSubject.Aspects &&
                  sourcePage === 'TASKS' &&
                  '(Please go to your Aspect and Impact Register to create a task relating to a single aspect or impact.)'}
              </LabelStyled>
            </LabelContainerStyled>
            {task.subject === TaskSubject.Legislation && (
              <LegislationSelect
                name="itemId"
                onChange={handleChange}
                value={taskItemId}
                isDisabled={disabled[TaskSubject.Legislation]}
              />
            )}
            {task.subject === TaskSubject.Aspects && (
              <AspectsSelect
                name="itemId"
                onChange={handleChange}
                value={taskItemId}
                isDisabled={disabled[TaskSubject.Aspects]}
              />
            )}
            {task.subject === TaskSubject.Targets && (
              <TargetsSelect name="itemId" onChange={handleChange} value={taskItemId} />
            )}
          </ColStyled>
        </RowStyled>
      )}
      <Row container align="between" data-cy="TaskDetails__Input--owners">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Owners</LabelStyled>
          </LabelContainerStyled>
          <UserSelect
            name="owners"
            onChange={(e) => {
              handleChange({
                target: {
                  value: e.target.select.map((item) => item.value),
                  name: 'owners'
                }
              });
              setSelectedUsers(e.target.select);
            }}
            value={selectedUsers}
            accountId={accountId}
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="TaskDetails__Input--dueDate">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Due Date</LabelStyled>
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date) => {
              handleChange({
                target: {
                  value: date ? date.toISOString() : date,
                  name: 'dueDate'
                }
              });
            }}
            name="dueDate"
            selectedDate={task.dueDate ? new Date(task.dueDate) : task.dueDate}
            box
          />
        </ColStyled>
      </Row>
      {hasProjectsAccess ? (
        <Row container align="between" data-cy="TaskDetails__Input-project">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Project</LabelStyled>
            </LabelContainerStyled>
            <ProjectSelect
              name="projectId"
              accountId={accountId}
              onChange={handleChange}
              value={task.projectId || projectId}
            />
          </ColStyled>
        </Row>
      ) : (
        ''
      )}
      {isEditing && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Completed</LabelStyled>
            </LabelContainerStyled>
            <Checkbox
              size="md"
              checked={task.isCompleted}
              onClick={() =>
                setTask({
                  ...task,
                  isCompleted: !task.isCompleted
                })
              }
            />
          </ColStyled>
        </Row>
      )}
    </>
  );
};

export default TaskDetails;
