import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { TransactionGrid } from '~/components/TransactionGrid';
// import GET_ELECTRICITY_TRANSACTIONS_PAGE from '../../../../graphql/electricity/GetElectricityTransactionsPage.query.graphql';
import GET_ELECTRICITY_TRANSACTIONS_PAGE from '../../../../graphql/queries/electricity/GetElectricityTransactionsPage.query.graphql';
import { useElectricityGridConfig } from './useElectricityGridConfig';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Electricity Transactions' }];

export function ElectricityTransactionsGrid() {
  const [query] = useLazyQuery(GET_ELECTRICITY_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const config = useElectricityGridConfig();
  const fetchRows = useCallback(
    async (variables: object) => {
      const {
        data: {
          getElectricityTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );
  return (
    <TransactionGrid
      title="Electricity Transactions"
      defaultExportFileName="electricity-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../electricity/uploads"
      config={config}
      fetchRows={fetchRows}
    />
  );
}
