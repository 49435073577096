import { gql } from '@apollo/client';

export const REPORTING_YEAR_FRAGMENT = gql`
  fragment ReportingYearFields on ReportingYear {
    id
    name
    startDate
    endDate
  }
`;

export const ACCESS_CONTROLS_FRAGMENT = gql`
  fragment AccessControlsFields on AccessControls {
    learn
    intelligence
    tasks
    projects
    surveys
    flows
    dataTracker
    dromoUpload
    v2Design
    comply {
      legislation
      tagLevelLegislation
      policies
      systems
      targets
      frameworks
      agreements
    }
    data {
      waste
      electricity
      gas
      water
      materials
      fuel
      transport
      refrigerants
      heatSteam
      buildingEnergyRatings
      hotelStays
      investment
    }
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFields on Account {
    id
    name
    type
    notes
    showChatBot
    sector {
      id
      name
    }
    reportingYear {
      ...ReportingYearFields
    }
    children {
      id
      name
      logoPath
    }
    accessControls {
      ...AccessControlsFields
    }
  }
  ${REPORTING_YEAR_FRAGMENT}
  ${ACCESS_CONTROLS_FRAGMENT}
`;

export const BANNER_FRAGMENT = gql`
  fragment BannerFields on Banner {
    id
    accountId
    url
    type
    thumbnail
    visible
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $id: ID!
    $name: String!
    $type: AccountType!
    $accessControls: AccessControlsInput!
    $children: [ID]
    $notes: String
    $showChatBot: Boolean
    $reportingYearId: ID
    $sectorId: String
  ) {
    createAccount(
      input: {
        id: $id
        name: $name
        type: $type
        accessControls: $accessControls
        children: $children
        notes: $notes
        showChatBot: $showChatBot
        sectorId: $sectorId
      }
    ) {
      ...AccountFields
    }
    setAccountReportingYear(accountId: $id, reportingYearId: $reportingYearId) {
      ...ReportingYearFields
    }
  }
  ${REPORTING_YEAR_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount(
    $id: ID!
    $name: String!
    $type: AccountType!
    $accessControls: AccessControlsInput!
    $children: [ID]
    $notes: String
    $showChatBot: Boolean
    $reportingYearId: ID
    $sectorId: String
  ) {
    updateAccount(
      input: {
        id: $id
        name: $name
        type: $type
        accessControls: $accessControls
        children: $children
        notes: $notes
        showChatBot: $showChatBot
        sectorId: $sectorId
      }
    ) {
      ...AccountFields
    }
    setAccountReportingYear(accountId: $id, reportingYearId: $reportingYearId) {
      ...ReportingYearFields
    }
  }
  ${REPORTING_YEAR_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }
`;

export const UPLOAD_LOGO = gql`
  mutation UploadAccountLogo(
    $fileName: String!
    $data: String!
    $contentType: String!
    $accountId: ID!
    $accountName: String!
    $accountType: AccountType!
    $accessControls: AccessControlsInput!
  ) {
    uploadLogo(input: { fileName: $fileName, contentType: $contentType, imageData: $data, accountId: $accountId })

    updateAccount(
      input: {
        id: $accountId
        name: $accountName
        type: $accountType
        accessControls: $accessControls
        logoPath: $fileName
      }
    ) {
      id
      logoPath
    }
  }
`;

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccountInfo($id: ID!) {
    getAccountInfo(id: $id) {
      ...AccountFields
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const GET_BANNER = gql`
  query GetBanner($accountId: ID!) {
    getBanner(accountId: $accountId) {
      ...BannerFields
    }
  }
  ${BANNER_FRAGMENT}
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($limit: Int!, $offset: Int, $sort: [SortCommand!], $filters: Filters) {
    getAccounts(offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
        type
        accessControls {
          ...AccessControlsFields
        }
      }
    }
  }
  ${ACCESS_CONTROLS_FRAGMENT}
`;

export const SAVE_BANNER = gql`
  mutation SaveBanner($input: SaveBannerInput!) {
    saveBanner(input: $input) {
      ...BannerFields
    }
  }
  ${BANNER_FRAGMENT}
`;

export const DELETE_BANNER = gql`
  mutation DeleteBanner($accountId: ID!, $id: ID!) {
    deleteBanner(accountId: $accountId, id: $id)
  }
`;

export const GET_REPORTING_YEARS = gql`
  query GetReportingYears {
    getReportingYears {
      ...ReportingYearFields
    }
  }
  ${REPORTING_YEAR_FRAGMENT}
`;
