import { SurveyQuestionCategoryInput } from '@rio/rio-types';
import { Errors } from '../../../types';

type Invalid = [Errors<SurveyQuestionCategoryInput>, null];

type Valid = [null, SurveyQuestionCategoryInput];

export function validate(category: Partial<SurveyQuestionCategoryInput>): Valid | Invalid {
  const errors: Errors<SurveyQuestionCategoryInput> = {};

  if (!category.name) {
    errors.name = 'Name is required';
  }
  if (Object.keys(errors).length) {
    return [errors, null];
  }
  return [null, category as SurveyQuestionCategoryInput];
}
