import { ApolloError, useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { sortBy, pickBy } from 'lodash';
import { GovernanceAccessControls, Legislation, Tag, NamedEntity, Maybe } from '@rio/rio-types';
import { HtmlDiv, Option, Nullable, MultiSelectEvent, Dictionary, SelectEvent } from '../../../../types';
import { AccountDataState, LegislationData } from './types';
import { UserPermissionsFull } from '../LegislationLibrary/types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Heading,
  CircleIcon,
  TextLink,
  MarkdownRenderer,
  Button,
  ErrorMessage,
  ContainerError,
  Select,
  DatePicker,
  MultiSelect,
} from 'rio-ui-components';
import { injectIntl } from 'react-intl';
import { LegislationTagSelect } from '../../../../components/LegislationTagSelect';
import { useCurrentAccountId } from '../../../../hooks/useCurrentAccountId';
import { usePermissions } from '../../../../hooks/usePermissions';
import { escape } from '../../../../utils/escape';
import { toSelectOptions } from '../../../../utils';
import { UploadDocumentModal } from '../../../DocumentContainer/DocumentModal/UploadDocumentModal';
import { LegislationStatusIcon } from '../LegislationLibrary/statusIcon';
import { NOT_APPLICABLE } from '../../../../constants/legislationStatusTypes';
import { CreateTaskModal } from '../../../TasksContainer/TasksGrid/CreateTaskModal';
import { CreateDepartmentModal } from '../../../../components/CreateDepartmentModal';
import ContainerLoadingIndicator from '../../../../components/ContainerLoadingIndicator';
import { GET_LEGISLATION_BY_ID, ATTACH_DOCUMENT } from './index.queries';
import { GET_LOCATION_META_OPTIONS } from './../../../../components/LocationMetaOptionSelect/index.queries';
import { useTags } from '../../../../hooks/useTags';
import { useNotification } from '../../../../hooks';
import { scopeLegislationOptions, labels, LOCATION, TAG, DEPARTMENT, REGION } from './../../../../constants/scopes';
import { TagsMultiSelect } from '../../../../components/TagsMultiSelect';
import { LocationsMultiSelect } from '../../../../components/LocationsMultiSelect';
import { useCreateDepartment, getDepartments } from '../../../../hooks/useCreateDepartment';
import { useAccountById } from '../../../../hooks/useAccountById';
import { useAttachContent } from './useAttachContent';
import MarkdownViewEditor from '../../../../components/MarkdownViewEditor';
import PageHeader from '../../../../components/PageHeader';
import DocumentTile from '../../../../components/DocumentTile/DocumentTile';
import AddDocumentTile from '../../../../components/AddDocumentTile/AddDocumentTile';
import LegislationStatusOptions from '../LegislationLibrary/legislationStatuses.json';
import { checkForActiveReviewFlag } from '../LegislationLibrary/common';
import { Grid as TaskGrid } from '../../../../containers/TasksContainer/TasksGrid/Grid';
import * as iconIds from '../../../../constants/iconIds';
import NotificationBanner from '../../../../components/NotificationBanner';
import { useAgGrid } from '../../../../hooks';
import { EditTaskModal } from '../../../../containers/TasksContainer/TasksGrid/EditTaskModal';
import { Task, TaskSubject } from '@rio/rio-types';

export const canManageScope = (permissions: UserPermissionsFull): boolean => {
  return (
    permissions?.governance?.some((permit) =>
      ['attachContentToLegislationAll', 'attachContentToLegislationParent'].includes(permit || '')
    ) || false
  );
};

interface LegislationProps {
  accessControls: GovernanceAccessControls;
  intl: any;
  isShouldUserUpgrade: boolean;
}

type Flag = keyof typeof iconIds;
type ScopeOption = keyof typeof scopeLegislationOptions;

const ContainerStyled = styled.div<HtmlDiv>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const PaddedCol = styled(Col)`
  padding: ${(props) => {
    const size = props.theme.geometry.xs.spacing;
    return `${size} 0 ${size} ${size}`;
  }};
`;

const ColStyled = styled(Col)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const ContentOuterContainer = styled(Col)`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ContentContainer = styled(Row)`
  background-color: ${(props) => props.theme.colors.basic.white};
`;

const GridContainer = styled(Row)`
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 300px;
  overflow: scroll;
`;

const RowStyled = styled.div<HtmlDiv>`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const StatusContainer = styled.div<HtmlDiv>`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  display: flex;
  align-items: center;
`;

const FlexRow = styled.div<HtmlDiv>`
  display: flex;
  align-items: center;
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

const HeadingStyled = styled(Heading)`
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const FlagContaniner = styled.img<HtmlDiv>`
  height: 22px;
  width: 32px;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const TitleContainerStyled = styled.div<HtmlDiv>`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const HeaderRightContainer = styled.div<HtmlDiv>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const HeaderInnerContainer = styled.div<HtmlDiv>`
  width: 50%;
`;

const TileContainer = styled.div<HtmlDiv>`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const RowFlexStyled = styled.div<HtmlDiv>`
  display: flex;
`;

const removeTagScope = (scope: Dictionary<string>, isHaveTagLibraryAccess: boolean) => {
  return pickBy(scope, (value, key) => !isHaveTagLibraryAccess || (key !== TAG && isHaveTagLibraryAccess));
};

const wrapToSelectOptions = (options: Nullable<NamedEntity[]>) => {
  return options && options?.length ? toSelectOptions(options) : [];
};

const LegislationItem = ({ accessControls, intl, isShouldUserUpgrade }: LegislationProps) => {
  const HAS_TAG_LIBRARY_ACCESS = accessControls.tagLevelLegislation;
  const { showNotification } = useNotification();
  const { id, tagId } = useParams();
  const navigate = useNavigate();
  const allTags = useTags();
  const agGrid = useAgGrid();
  const [selectedTag, setSelectedTag] = useState(tagId || '');
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const accountId = useCurrentAccountId();
  const accountInfo = useAccountById(accountId);
  const permissions: UserPermissionsFull = usePermissions();
  const [uploadModalShown, setUploadModalShown] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { data, error, loading, refetch } = useQuery<LegislationData>(GET_LEGISLATION_BY_ID, {
    variables: { id, accountId, tagId },
    fetchPolicy: 'network-only',
  });

  const tasks: Task[] = data?.getLegislationById.tasks ?? [];

  const legislation: Nullable<Legislation> = data ? data.getLegislationById : null;

  const [accountData, setAccountData] = useState<AccountDataState>({
    departmentIds: undefined,
    locationIds: undefined,
    tagIds: undefined,
    regionIds: undefined,
    applicability: null,
    status: null,
    reviewDate: null,
    shouldUpdate: null,
    showScope: [],
    flagCleared: false,
  });

  useAttachContent({ id: id || '', tagId: tagId || '', accountId, accountData, data, setAccountData });

  const [attachDocument, { error: mutationError }] = useMutation(ATTACH_DOCUMENT);

  const isFlagged = checkForActiveReviewFlag(
    data?.getLegislationById?.lastFlaggedForReview,
    data?.getLegislationById?.lastClearedReviewFlag
  );
  const { departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    accountInfo?.departments,
    accountData?.departmentIds,
    accountId
  );

  const mappingScope = {
    [DEPARTMENT]: 'departmentIds',
    [LOCATION]: 'locationIds',
    [TAG]: 'tagIds',
    [REGION]: 'regionIds',
  };
  const existedScope = removeTagScope(
    pickBy(
      mappingScope,
      (value, key) =>
        (accountData[value as keyof AccountDataState] as Nullable<Option[]>)?.length ||
        accountData?.showScope?.includes(key)
    ),
    HAS_TAG_LIBRARY_ACCESS
  );
  const scope = removeTagScope(
    pickBy(mappingScope, (value, key) => !existedScope[key]?.length),
    HAS_TAG_LIBRARY_ACCESS
  );

  const accountDataRef: { current: any } = useRef(null);
  accountDataRef.current = accountData;

  const { data: metaData } = useQuery<any>(GET_LOCATION_META_OPTIONS, { variables: { accountId: accountId } });
  const existedDepartments = accountInfo?.departments;

  useEffect(() => {
    navigate(`/governance/legislation/${id}${selectedTag ? `/${selectedTag}` : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag]);

  useEffect(() => {
    if (data) {
      setAccountData({
        ...accountDataRef.current,
        departmentIds:
          accountDataRef.current?.departmentIds ||
          getDepartments(existedDepartments, data.getLegislationById?.departmentIds),
        locationIds:
          accountDataRef.current.locationIds ||
          wrapToSelectOptions(data.getLegislationById?.locations as Nullable<NamedEntity[]>),
        tagIds:
          accountDataRef.current.tagIds ||
          data.getLegislationById.tags?.map((item: Tag) => ({ value: item.id, label: item.tagName })),
        regionIds: accountDataRef.current.regionIds || wrapToSelectOptions(data.getLegislationById?.regions),
        applicability: data.getLegislationById.applicability,
        status: data.getLegislationById.status,
        reviewDate: data.getLegislationById.reviewDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, metaData, existedDepartments]);

  const onAddDocumentComplete = (err: ApolloError | Error | undefined) => {
    if (err) {
      showNotification('Something went wrong. Please try again later. ', 'danger');
    } else {
      showNotification('Document succesfully added. Now scanning for viruses.', 'success');
    }
    refetch({ id, accountId });
  };

  const updateReviewDate = (status: string) => {
    if (accountData.reviewDate) return accountData.reviewDate;
    if (status !== NOT_APPLICABLE) return new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();
    return null;
  };

  const canEdit = permissions.governance?.some((action: Maybe<string>) =>
    (action as string).startsWith('editLegislation')
  );

  const RenderSelectedScope = (selectedScope: keyof typeof labels) => {
    let result = <></>;
    switch (selectedScope) {
      case LOCATION:
        const selectedLocations =
          accountData?.locationIds || wrapToSelectOptions(legislation?.locations as Nullable<NamedEntity[]>);
        result = (
          <LocationsMultiSelect
            key={selectedScope}
            accountId={accountId}
            value={sortBy(selectedLocations, 'label')}
            onChange={(e: MultiSelectEvent) => {
              setAccountData({ ...accountData, locationIds: e.target.select, shouldUpdate: true });
            }}
            error={null}
            disabled={false}
          />
        );
        break;
      case TAG:
        const selectedTags =
          accountData?.tagIds || legislation?.tags?.map((item: Tag) => ({ value: item.id, label: item.tagName }));
        result = (
          <TagsMultiSelect
            key={selectedScope}
            accountId={accountId}
            value={sortBy(selectedTags, 'label')}
            onChange={(e: MultiSelectEvent) => {
              setAccountData({ ...accountData, tagIds: e.target.select, shouldUpdate: true });
            }}
            error={''}
            defaultOptions={undefined}
            disabled={false}
          />
        );
        break;
      case DEPARTMENT:
        result = departmentMultipleSelect((e: MultiSelectEvent) => {
          setAccountData({
            ...accountData,
            departmentIds: e.target.select,
            shouldUpdate: true,
          });
        });
        break;
      case REGION:
        result = (
          <MultiSelect
            name="Child-Accounts__MultiSelect"
            id="Child-Accounts__MultiSelect"
            multiValueColor="tertiary"
            onChange={(e: MultiSelectEvent) => {
              setAccountData({ ...accountData, regionIds: e.target.select, shouldUpdate: true });
            }}
            isDisabled={false}
            value={accountData?.regionIds}
            options={wrapToSelectOptions(metaData?.getLocationMetadataOptionsForAccount?.regions)}
          />
        );

        break;
    }
    return result;
  };

  const tagLevelOption = tagId
    ? [{ value: tagId, label: allTags?.find((tag: Tag) => tag.id === tagId)?.tagName }]
    : null;

  return (
    <ContainerStyled name="LegislationItem">
      <PageHeader
        name="LegislationItem"
        title="Legislation Item"
        breadcrumbs={[{ title: 'Legislation', to: '../legislation' }, { title: 'Legislation Item' }]}
        icon="pencil-alt"
        iconColor="tertiary"
        dropdownItems={undefined}
        isDropdown={undefined}
        dropdownDefault={undefined}
      >
        {HAS_TAG_LIBRARY_ACCESS && (
          <HeaderRightContainer>
            <HeaderInnerContainer>
              <LegislationTagSelect
                tags={allTags}
                value={selectedTag}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAccountData({
                    ...accountData,
                    departmentIds: undefined,
                    locationIds: undefined,
                    tagIds: undefined,
                    regionIds: undefined,
                  });
                  setSelectedTag(e.target.value);
                }}
                error=""
              />
            </HeaderInnerContainer>
          </HeaderRightContainer>
        )}
      </PageHeader>

      {showEditModal && (
        <EditTaskModal
          disabled={false}
          selectedTask={showEditModal}
          onDismiss={() => setShowEditModal(false)}
          sourcePage="TASKS"
        />
      )}

      {loading && <ContainerLoadingIndicator name="LegislationItem__Loading" />}
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError name="LegislationItem__Error" icon={icon} title={title} body={body} retry={refetch} />
          )}
        </ErrorMessage>
      )}

      {!loading && legislation && (
        <ContentOuterContainer container item>
          {isShouldUserUpgrade && (
            <NotificationBannerStyled
              name="ComplyContainer_Access_NotificationBanner"
              icon="exclamation-triangle"
              color="danger"
              title={`Governance`}
              body={intl.formatMessage({ id: 'pages.governance.permissionsProblem' })}
            />
          )}

          <ContentContainer item>
            <StatusContainer>
              <Col item span={8}>
                <TitleContainerStyled>
                  <CircleIconStyled
                    inline
                    height="50px"
                    size="xl"
                    circleColor="tertiary"
                    iconColor="tertiary"
                    icon="file-alt"
                  />
                  <Heading name="LegislationItem__Title" inline>
                    {legislation.title}
                    <FlexRow>
                      {legislation.countries.map((value: Flag) => {
                        return (
                          <FlagContaniner
                            name="LegislationItem__Flag"
                            src={iconIds[value]}
                            key={value}
                            alt={value}
                            title={value}
                          />
                        );
                      })}
                    </FlexRow>
                  </Heading>
                </TitleContainerStyled>
              </Col>
              <ColStyled item span={2}>
                <ButtonStyled
                  name="LegislationItem__Button-back"
                  inline
                  color="info"
                  component="routerLink"
                  to="governance/legislation"
                >
                  Back to Library
                </ButtonStyled>
              </ColStyled>
              <Col item span={2}>
                <ButtonStyled
                  fullWidth
                  name="LegislationItem__Button-create-task"
                  onClick={() => setTaskModalVisible(true)}
                  inline
                >
                  Create task
                </ButtonStyled>
              </Col>
            </StatusContainer>
            {uploadModalShown && (
              <UploadDocumentModal
                onDismiss={() => {
                  setUploadModalShown(false);
                }}
                onUpload={async (_, document) => {
                  await attachDocument({
                    variables: {
                      id: document.id,
                      accountId,
                      tagId,
                      legislationId: id,
                    },
                  });
                  onAddDocumentComplete(mutationError);
                }}
                onError={onAddDocumentComplete}
                predefinedValues={
                  {
                    category: 'Legislation',
                    library: 'GOVERNANCE',
                    tags: tagLevelOption || [],
                  } as any
                }
                onComplete={() => {}}
              />
            )}
            {!!taskModalVisible && (
              <CreateTaskModal
                accountId={accountId}
                onDismiss={() => setTaskModalVisible(false)}
                onComplete={() => showNotification(`The associated task has been created`)}
                defaultValues={{
                  category: 'GOVERNANCE',
                  subject: 'LEGISLATION',
                  item: { id: legislation?.id },
                  locationIds: accountData.locationIds,
                  tagIds: tagLevelOption || accountData.tagIds,
                }}
                disabled={{ category: true, subject: true, [TaskSubject.Legislation]: !!legislation?.id }}
              />
            )}
            <StatusContainer>
              <PaddedCol container item>
                <HeadingStyled size="md">
                  Status: <LegislationStatusIcon status={accountData.status} />
                </HeadingStyled>
                {canEdit && (
                  <Select
                    value={accountData.status}
                    options={[...LegislationStatusOptions, { label: 'To Be Determined', value: null }].filter(
                      (status) => !!status.label
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAccountData({
                        ...accountData,
                        status: e.target.value,
                        reviewDate: updateReviewDate(e.target.value),
                        shouldUpdate: true,
                      })
                    }
                    placeholder="To Be Determined"
                  />
                )}
              </PaddedCol>
              <PaddedCol container item>
                <HeadingStyled size="md">{`Review Date: ${
                  accountData.reviewDate ? moment(accountData.reviewDate).format('DD/MM/YY') : 'Not Set'
                }`}</HeadingStyled>
                {canEdit && (
                  <DatePicker
                    disabled={!canEdit}
                    placeholder="Review Date..."
                    onDayClick={(date: Date) =>
                      setAccountData({
                        ...accountData,
                        reviewDate: date ? date.toISOString() : null,
                        shouldUpdate: true,
                      })
                    }
                    box
                    selectedDate={accountData.reviewDate ? new Date(accountData.reviewDate) : null}
                  />
                )}
              </PaddedCol>
              {isFlagged && (
                <PaddedCol container item>
                  <HeadingStyled size="md">Legislation is flagged as changed.</HeadingStyled>
                  <Button onClick={() => setAccountData({ ...accountData, flagCleared: true, shouldUpdate: true })}>
                    Clear Flag
                  </Button>
                </PaddedCol>
              )}
            </StatusContainer>
            <RowStyled container>
              <HeadingStyled size="md">Synopsis:</HeadingStyled>
              <MarkdownRenderer name="LegislationItem__Synopsis" source={legislation.synopsis} />
            </RowStyled>
            <RowStyled container>
              <HeadingStyled size="md">Applicability:</HeadingStyled>
              {canEdit && (
                <MarkdownViewEditor
                  name="LegislationItem__Applicability"
                  onSave={(content: any) =>
                    setAccountData({ ...accountData, applicability: escape(content), shouldUpdate: true })
                  }
                  source={accountData.applicability}
                />
              )}
              {!canEdit && (
                <MarkdownRenderer name="LegislationItem__Applicability" source={accountData.applicability} />
              )}
            </RowStyled>

            {canManageScope(permissions) && !!Object.keys(scope)?.length && (
              <RowStyled container>
                <HeadingStyled size="md">Scope:</HeadingStyled>
                <RowFlexStyled>
                  <Col span="6">
                    <Select
                      options={Object.keys(scope).map((key) => ({
                        value: key,
                        label: scopeLegislationOptions[key as ScopeOption],
                      }))}
                      value={null}
                      onChange={(e: SelectEvent) => {
                        const selectValue = e.target.value;
                        setAccountData({
                          ...accountData,
                          showScope: [...accountData.showScope, selectValue],
                        });
                      }}
                    />
                  </Col>
                </RowFlexStyled>
              </RowStyled>
            )}

            {canManageScope(permissions) &&
              existedScope &&
              Object.keys(existedScope).map((key) => {
                return (
                  <RowStyled container key={key}>
                    <HeadingStyled size="md">{scopeLegislationOptions[key as ScopeOption]}s</HeadingStyled>
                    <RowFlexStyled>
                      <Col span="6">{RenderSelectedScope(key as ScopeOption)}</Col>
                    </RowFlexStyled>
                  </RowStyled>
                );
              })}

            <RowStyled container>
              <HeadingStyled size="md">Links:</HeadingStyled>
              <div>
                {legislation.links.map((item: string) => {
                  return (
                    <TextLink name="LegislationItem__Link" key={item} href={item} target="_blank">
                      {item}
                    </TextLink>
                  );
                })}
              </div>
            </RowStyled>
            <RowStyled container>
              <HeadingStyled size="md">Attached Documents:</HeadingStyled>
              <TileContainer>
                {permissions.governance?.some((action: Maybe<string>) =>
                  (action as string).startsWith('attachDocumentToLegislation')
                ) && (
                  <>
                    <AddDocumentTile
                      id={`${legislation.title}-addDocuments`}
                      onClick={() => setUploadModalShown(true)}
                      disabled={undefined}
                    />
                    {legislation?.documents?.map((doc: any) => {
                      return (
                        <Link key={doc.id} target="_blank" to={`/documents/all/${doc.id}`}>
                          <DocumentTile
                            title={doc.fileName}
                            category={doc.category}
                            id={doc.id}
                            iconColor={'tertiary'}
                            code={undefined}
                          />
                        </Link>
                      );
                    })}
                  </>
                )}
              </TileContainer>
            </RowStyled>
            <RowStyled container>
              <HeadingStyled size="md">Tasks:</HeadingStyled>
              {!loading && !error && tasks.length === 0 && <p>There are no tasks associated with this Legislation.</p>}
              {!loading && !error && tasks.length > 0 && (
                <GridContainer name="gridContainers">
                  <TaskGrid
                    canEdit={!!permissions.task?.find((action) => action?.startsWith('edit'))}
                    tasks={tasks}
                    agGrid={agGrid}
                  />
                </GridContainer>
              )}
            </RowStyled>
          </ContentContainer>
        </ContentOuterContainer>
      )}
      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        passedAccountId={accountId}
        onComplete={() => {}}
      />
    </ContainerStyled>
  );
};

export default injectIntl(LegislationItem);
