import { useNavigate } from 'react-router-dom';
import { ManagementSystem, DocumentLibrary } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../types';
import { ManagementSystemChapterLayout } from '../../../../components/ManagementSystemChapterLayout';
import { makeManagementSystemCode } from '../../../../utils/managementSystemUtils';

interface ManagementSystemChapterProps {
  managementSystem: ManagementSystem;
  page: ManagementSystemPage;
  canEditContent: boolean;
  isReadOnly: boolean;
}

export function DefaultChapter({ managementSystem, page, canEditContent, isReadOnly }: ManagementSystemChapterProps) {
  const navigate = useNavigate();

  function getButtons(managementSystem: ManagementSystem) {
    if (managementSystem?.shortCode === 'EMS') {
      return [
        {
          color: 'tertiary',
          title: 'Aspects & Impacts Register',
          onClick: () => {
            navigate('/governance/environmental-aspects');
          }
        }
      ];
    } else if (managementSystem?.content?.headerButton) {
      const {
        content: {
          headerButton: { link, text }
        }
      } = managementSystem;
      return [
        {
          color: 'tertiary',
          title: text,
          onClick: () => {
            if (link.indexOf('http') >= 0) {
              window.open(link);
            } else {
              navigate(link);
            }
          }
        }
      ];
    }
    return null;
  }

  return (
    <ManagementSystemChapterLayout
      header={{
        title: page.title || managementSystem.title,
        buttons: getButtons(managementSystem)
      }}
      page={page}
      managementSystem={managementSystem}
      synopsis={{
        mode: 'markdown',
        content: page.synopsis || 'Add your own content here.',
        editable: canEditContent
      }}
      documents={{
        records: page.documents || [],
        readonly: isReadOnly,
        predefinedValues: {
          managementSystemId: managementSystem.id,
          managementSystemChapterId: page.id,
          category: 'Management Systems',
          library: DocumentLibrary.Governance,
          managementSystemCode: makeManagementSystemCode(managementSystem, page)
        }
      }}
    />
  );
}
