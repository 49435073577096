import { useQuery } from '@apollo/client';
import { useCurrentAccountId } from './useCurrentAccountId';
import {
  GET_METERS_BY_ACCOUNT_ID_AND_TYPE,
  GET_METERS_WITH_LOCATION_BY_ACCOUNT_ID_AND_TYPE,
} from '../components/MeterSelect/index.queries';

export function useGetMeters(
  type,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { withLocationData = false, onCompleted = (_) => {}, onError = () => {}, fetchPolicy = '' } = {}
) {
  const accountId = useCurrentAccountId();
  const {
    data: { getEnergySourceByAccountIdAndType } = {},
    loading,
    error,
    refetch,
  } = useQuery(withLocationData ? GET_METERS_WITH_LOCATION_BY_ACCOUNT_ID_AND_TYPE : GET_METERS_BY_ACCOUNT_ID_AND_TYPE, {
    variables: {
      accountId,
      type,
      limit: 1000,
    },
    fetchPolicy: fetchPolicy || undefined,
    onCompleted,
    onError,
    skip: !accountId,
  });

  return {
    data: getEnergySourceByAccountIdAndType?.rows || [],
    loading,
    error,
    refetch,
  };
}
