import { FetchResult } from '@apollo/client';
import { Mutation, MutationCreateSurveyArgs as Variables } from '@rio/rio-types';
import { CREATE_SURVEY, GET_SURVEYS_PAGE, GET_SURVEY_LATEST } from './queries';
import { GetSurveysResponse } from './useGet';
import { useCurrentAccountId, useNotification, usePageSuspendingMutation } from '../../../hooks';

type CreateFormResponse = Pick<Mutation, 'createSurvey'>;

export function useCreate() {
  const { showNotification } = useNotification();
  const accountId = useCurrentAccountId();
  const variables = {
    accountId,
  };
  return usePageSuspendingMutation<CreateFormResponse, Variables>(CREATE_SURVEY, {
    onCompleted: () => {
      showNotification('Your survey has been sent to its recipients', 'success');
    },
    onError: (err) => {
      showNotification(
        err ? `${err}` : `We’re sorry but we are unable to publish right now - please try again later`,
        'danger'
      );
    },
    update: (cache, result: FetchResult<CreateFormResponse>) => {
      // Get the current list of survey
      const getSurveyResult = cache.readQuery<GetSurveysResponse>({
        query: GET_SURVEYS_PAGE,
        variables,
      }) as GetSurveysResponse;

      const newSurvey = result.data?.createSurvey;

      if (!newSurvey) {
        return;
      }

      // Update the current list of survey if it exists in the cache
      if (getSurveyResult?.getSurveysPage) {
        cache.writeQuery({
          variables,
          query: GET_SURVEYS_PAGE,
          data: {
            rows: getSurveyResult.getSurveysPage.rows.concat(newSurvey),
          },
        });
      }

      // Update the latest survey
      cache.writeQuery({
        variables,
        query: GET_SURVEY_LATEST,
        data: {
          getSurveyLatest: newSurvey,
        },
      });
    },
  });
}
