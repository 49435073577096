import { TransactionType } from '@rio/rio-types';
import { TemplateType } from '~/types';
import { UploadContainer } from '~/containers/DataContainer';
import columns from './columns.json';

function WaterUploadContainer({ ...props }) {
  return (
    <UploadContainer
      {...props}
      dataSection={TransactionType.Water}
      template={{
        columns,
        fileName: 'water-template',
        type: TemplateType.Dynamic,
      }}
      showInvoiceUpload={true}
    />
  );
}
export default WaterUploadContainer;
