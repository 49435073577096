import { useEffect } from 'react';
import { Heading, LoadingIndicator } from 'rio-ui-components';
import { createXls, objectInRowsToStringValue, formatDate } from '../../utils';
import { useGridHooksByDataType, useUploadFileMutation } from '../../hooks';
import { Container } from './DataFormXlsUploaderStyles';
import { DIRECT } from '../../constants/transactionActivityTypes';

const getDirectActivity = (formData) => {
  return formData.data.activity === DIRECT;
};

const getUploadFileName = ({ startDate, endDate, uploadReference }) => {
  const dateFormat = 'DD-MM-YYYY';
  const tillDate = endDate ? `—${formatDate(endDate, dateFormat)}` : '';
  const dateRange = `${formatDate(startDate, dateFormat)}${tillDate}`;

  const reference = uploadReference.replace(/[^a-zA-Z0-9]/g, ' ');
  return { sheetName: dateRange, fileName: `${dateRange} ${reference}` };
};

export function DataFormXlsUploader(props) {
  const { onUploadCompleted = () => {}, onError = () => {}, formData = {}, dataSection } = props;

  const [columns, convertTuple] = useGridHooksByDataType(dataSection);
  const [convert, { data: rows }] = convertTuple;

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    convert(formData, columns);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!columns.length || !rows.length) {
      return;
    }

    const run = async () => {
      const mappedRows = objectInRowsToStringValue(rows, columns);

      const { sheetName, fileName } = getUploadFileName(formData);
      const file = createXls(mappedRows, fileName, 'xlsx', sheetName);

      const isWaste = dataSection === 'WASTE';

      try {
        await uploadFile({
          file,
          fileName: file.name,
          transactionType: dataSection,
          isDirectActivity: isWaste ? false : getDirectActivity(formData),
        });
        onUploadCompleted();
      } catch (e) {
        onError(e);
      }
    };

    run();
    // eslint-disable-next-line
  }, [rows, columns]);

  return (
    <>
      <Container>
        <Heading>Data uploading</Heading>

        <LoadingIndicator />
      </Container>
    </>
  );
}
