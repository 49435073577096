import { useMutation } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmpty from 'validator/es/lib/isEmpty';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { TextField, Button, Grid, Select } from '@rio/ui-components';
import { UPDATE_USER_PROFILE } from '../index.queries';
import { useCreateDepartment } from '~/hooks/useCreateDepartment';
import { useNotification } from '~/hooks/useNotification';
import { CreateDepartmentModal } from '~/components/CreateDepartmentModal';

const validators = {
  email: (input) => (isEmail(input) ? '' : 'Must be a valid email address'),
  firstName: (input) => (isEmpty(input) ? 'First Name is required.' : ''),
  lastName: (input) => (isEmpty(input) ? 'Last Name is required.' : ''),
  mobile: (input) => (isEmpty(input) || isMobilePhone(input) ? '' : 'Must be a valid mobile number.'),
  phone: (input) => (isEmpty(input) || isNumeric(input) ? '' : 'Must be a valid phone number.'),
};

export const EditUserDetails = ({ user }) => {
  const { showNotification } = useNotification();

  const {
    first_name: firstName,
    last_name: lastName,
    jobTitle,
    phone,
    department,
    mobile,
    email,
    id,
    profilePicture,
    account,
  } = user;
  const [userInputs, setUserInputs] = useState({
    firstName: { value: firstName, error: '' },
    lastName: { value: lastName, error: '' },
    jobTitle: { value: jobTitle, error: '' },
    phone: { value: phone, error: '' },
    department: { value: department, error: '' },
    mobile: { value: mobile, error: '' },
    email: { value: email, error: '' },
  });

  const { showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    account.departments,
    userInputs.department.value,
    account.id
  );

  const [isEdited, setIsEdited] = useState(false);
  const [updateUserProfile, { loading: isUpdating }] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => {
      showNotification('User details have been updated.', 'success');
    },
    onError: () => {
      showNotification('Something went wrong. Please try again later.', 'danger');
    },
  });

  const hasError = useMemo(() => {
    let error = false;
    Object.keys(userInputs).forEach((key) => {
      if (!!userInputs[key].error) {
        error = true;
      }
    });
    return error;
  }, [userInputs]);

  const handleInputChange = useCallback((event) => {
    setIsEdited(true);
    setUserInputs((prevState) => ({
      ...prevState,
      [event.target.name]: { value: event.target.value, error: validators[event.target.name]?.(event.target.value) },
    }));
  }, []);

  const handleDepartmentChange = useCallback((option) => {
    setIsEdited(true);
    setUserInputs((prevState) => ({
      ...prevState,
      department: { value: option.value, error: '' },
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    updateUserProfile({
      variables: {
        id,
        account_id: user.account.id,
        email: userInputs.email.value,
        previousEmail: user.email,
        first_name: userInputs.firstName.value,
        last_name: userInputs.lastName.value,
        jobTitle: userInputs.jobTitle.value,
        department: userInputs.department.value !== 'notSelected' ? userInputs.department.value : null,
        phone: userInputs.phone.value,
        mobile: userInputs.mobile.value,
        profile: profilePicture,
      },
    });
    setIsEdited(false);
  }, [id, userInputs, user, profilePicture, updateUserProfile]);

  const setDepartment = useCallback((_, newDepartment) => {
    setUserInputs((prevState) => ({ ...prevState, department: { value: newDepartment.departmentId, error: '' } }));
  }, []);

  return (
    <React.Fragment>
      <CreateDepartmentModal
        passedAccountId={account.id}
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        onComplete={setDepartment}
      />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            label="First name"
            name="firstName"
            onChange={handleInputChange}
            value={userInputs.firstName.value}
            helperText={userInputs.firstName.error}
            error={Boolean(userInputs.firstName.error)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last name"
            name="lastName"
            onChange={handleInputChange}
            value={userInputs.lastName.value}
            helperText={userInputs.lastName.error}
            error={Boolean(userInputs.lastName.error)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Job title (optional)"
            name="jobTitle"
            onChange={handleInputChange}
            value={userInputs.jobTitle.value}
            helperText={userInputs.jobTitle.error}
            error={Boolean(userInputs.jobTitle.error)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Department (optional)"
            name="department"
            options={account.departments.map((d) => ({ value: d.id, label: d.name }))}
            onChange={handleDepartmentChange}
            value={userInputs.department.value}
            helperText={userInputs.department.error}
            error={Boolean(userInputs.department.error)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone number (optional)"
            name="phone"
            onChange={handleInputChange}
            value={userInputs.phone.value}
            helperText={userInputs.phone.error}
            error={Boolean(userInputs.phone.error)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Mobile number (optional)"
            name="mobile"
            onChange={handleInputChange}
            value={userInputs.mobile.value}
            helperText={userInputs.mobile.error}
            error={Boolean(userInputs.mobile.error)}
          />
        </Grid>
        <Grid item xs={16}>
          <TextField
            label="Email address"
            name="email"
            onChange={handleInputChange}
            value={userInputs.email.value}
            helperText={userInputs.email.error}
            error={Boolean(userInputs.email.error)}
          />
        </Grid>
        <Grid item xs={16}>
          <Button
            disabled={!isEdited || hasError || isUpdating}
            onClick={handleSubmit}
            variant="filled"
            color="primary"
            loading={isUpdating}
            sx={{ width: '100%' }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
