import { Text, styled, Icons } from '@rio/ui-components';

const TextStyled = styled(Text)`
  color: ${(p) => p.theme.sys.color.onSurface};
`;

const LinkContainer = styled('div')`
  display: flex;
  max-width: 300px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.sys.color.outlineVariant};
  background: ${(p) => p.theme.sys.color.surface};
  box-shadow: ${(p) => p.theme.sys.elevation[1]};
  cursor: pointer;
`;

const TextContainer = styled('div')`
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;

  p {
    margin: 0px;
  }
`;

const v2ColourMap = {
  quaternary: '#08EBA7',
  tertiary: '#001AFF',
};

const FolderIconContainer = styled('div')`
  display: flex;
  padding: 0px 8px;
  svg {
    color: ${(p) => v2ColourMap[p.color]};
  }
`;

export function FolderGridTileV2({ folder }) {
  return (
    <LinkContainer disabled={folder.disabled}>
      <FolderIconContainer color={folder.icon.color}>
        <Icons.FolderOutlined />
      </FolderIconContainer>
      <TextContainer>
        <TextStyled typescale="body" size="medium">
          {folder.name}
        </TextStyled>
      </TextContainer>
    </LinkContainer>
  );
}
