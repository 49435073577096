import { SurveyInput } from '@rio/rio-types';
import { createContext } from 'react';

export type SurveyFormState = Omit<SurveyInput, 'ownerAccountId'>;

const defaultValues: SurveyFormState = {
  automatedScoring: true,
  feedbackEnabled: true,
  name: '',
  notifyByEmail: true,
  contributors: undefined,
  individualContributors: [],
  surveyTemplateId: '',
};
export const SurveyFormStateContext = createContext<SurveyFormState>(defaultValues);
