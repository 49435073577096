import { gql } from '@apollo/client';

export const GET_ALL_MANAGEMENT_SYSTEMS = gql`
  query GetAllManagementSystems {
    getAllManagementSystems {
      id
      title
    }
  }
`;

export const GET_MANAGEMENT_SYSTEM_SUBSCRIPTION = gql`
  query GetManagementSystemSubscription($accountId: ID!) {
    getManagementSystemSubscription(accountId: $accountId)
  }
`;

export const SET_MANAGEMENT_SYSTEM_SUBSCRIPTION = gql`
  mutation SetManagementSystemSubscription($accountId: ID!, $subscriptions: [String!]!) {
    setManagementSystemSubscription(accountId: $accountId, subscriptions: $subscriptions)
  }
`;
