import { useCallback, useState, useMemo } from 'react';
import { Checkbox, Row, TextInput } from 'rio-ui-components';
import { NumberInput, NumberInputWithNegativeOption } from '../UI';
import { LocationSelectRender } from './LocationSelectRender';
import { DIRECT, INDIRECT } from '../../constants/transactionActivityTypes';
import { Controller } from 'react-hook-form';
import { DataForm, SelectRender } from '../DataForm';
import { TransactionType } from '@rio/rio-types';
import { SupplierSelector } from '../SuppliersSelector';
import {
  CenteredCol,
  ColStyled,
  LabelContainerStyled,
  LabelStyled,
  RowWithPadding,
  SectionStyled,
  SubHeader,
  DescriptionStyled,
  LabelDescriptionContainerStyled,
} from '../DataForm/DataFormStyles';
import { useGetTransportFuelTypes, useGetJourneyTargets, useGetTransportTypes } from '../../hooks/transport';
import { useCurrency, useCurrentAccountId } from '../../hooks';

export function TransportDataForm(props) {
  /**
   * PROPS
   */
  const { form = {}, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, watch, getValues, getFieldState, formState } = form;

  /**
   * STATE
   */
  const [currencyInitialState] = useState(form.getValues('data.currency'));

  /**
   * HOOKS
   */

  const { data: fuelTypes, refetch: refetchFuelTypes } = useGetTransportFuelTypes();
  const journeyTargets = useGetJourneyTargets();
  const transportTypes = useGetTransportTypes();
  const { data: currenciesList } = useCurrency();
  const accountId = useCurrentAccountId();

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const setReferenceDataRequired = useCallback((value) => setValue('isReferenceDataRequired', value), [setValue]);

  const showReferenceData = useCallback(() => setReferenceDataRequired(true), [setReferenceDataRequired]);
  const hideReferenceData = useCallback(() => setReferenceDataRequired(false), [setReferenceDataRequired]);

  /**
   * SUBMIT
   * mapping data on submit event
   */
  const handleOnSubmit = ({ data, financial, reference, ...others }) => {
    const formData = {
      ...others,
      data: {
        ...data,
        activity: data.activity.id,
        location: data.location.name,
        type: data.type.name,
        subtype: data.subtype.name,
        fuelType: data.fuelType.name,
        volumeUnit: data.volume ? data.volumeUnit?.name : null,
        distanceUnit: data.journeyDistance ? data.distanceUnit?.name : null,
        dataProvider: data.dataProvider ? data.dataProvider : null,
        purpose: data.purpose.name,
        currency: financial?.currency?.name || currencyInitialState,

        // numeric fields
        volume: data.volume && Number(data.volume),
        freight: data.freight && Number(data.freight),
        distance: data.distance && Number(data.distance),
        journeyDistance: data.journeyDistance && Number(data.journeyDistance),
      },
    };

    if (isReferenceDataRequired) {
      formData.data = { ...formData.data, ...reference };
    }

    onSubmit(formData);
  };

  const entityToDropdownOption = ({ id, name } = {}) => ({
    label: name,
    value: { id, name },
  });

  /**
   * OPTIONS
   */
  const defaultActivity = { id: DIRECT, name: 'Yes' };
  const activityOptions = [defaultActivity, { id: INDIRECT, name: 'No' }].map(entityToDropdownOption);
  const defaultVolumeUnit = useMemo(() => ({ id: 'L', name: 'L' }), []);
  const volumeUnitOptions = [defaultVolumeUnit, { id: 'gal', name: 'gal' }].map(entityToDropdownOption);
  const defaultDistanceUnit = useMemo(() => ({ id: 'km', name: 'km' }), []);
  const distanceUnitOptions = [
    defaultDistanceUnit,
    { id: 'mi', name: 'mi' },
    { id: 'passenger.km', name: 'passenger.km' },
  ].map(entityToDropdownOption);
  const currencyOptions = currenciesList.map(entityToDropdownOption);

  /**
   * DEFAULT FORM VALUES
   */
  const currencyDefaultValue = currencyOptions.find((item) => item.label === currencyInitialState)?.value;

  const formData = form.getValues().data;

  // Required if none present / non required if ANY present
  const isFreightRequired = !(formData?.distance || formData?.volume || formData.journeyDistance);
  const isVolumeRequired = !(formData?.distance || formData?.freight || formData.journeyDistance);
  const isDistanceRequired = !(formData?.volume || formData?.freight);
  const isDistanceUnitRequired = formData?.distance && 'Distance Unit is required';

  const calculateDistance = useCallback(() => {
    const journeyDistance = form.getValues().data.journeyDistance;
    const numberOfJourneys = form.getValues().data.numberOfJourneys;
    setValue('data.distance', journeyDistance * numberOfJourneys);
  }, [form, setValue]);

  const handleNumberOfJourneysChange = useCallback(
    (value) => {
      setValue('data.numberOfJourneys', value);
      calculateDistance();
    },
    [calculateDistance, setValue]
  );
  const handleJourneyDistanceChange = useCallback(
    (value) => {
      setValue('data.journeyDistance', value || null);
      calculateDistance();
    },
    [calculateDistance, setValue]
  );
  const handleTypeChange = useCallback(
    (value) => {
      setValue('data.type', value);
      setValue('data.subtype', null);
      refetchFuelTypes({ typeId: value.id });
    },
    [refetchFuelTypes, setValue]
  );

  const getConditionalValidateRules = useCallback(
    (fieldName, message) => {
      const { isTouched: IsParentTouched } = getFieldState(fieldName, formState);
      return {
        required: {
          value: IsParentTouched,
          message,
        },
      };
    },
    [getFieldState, formState]
  );

  const renderPurpose = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="purposeOfJourneyLabel">Purpose of Journey</LabelStyled>
          </LabelContainerStyled>
          <Controller
            name="data.purpose"
            render={({ field, fieldState }) => (
              <SelectRender
                aria-labelledby="purposeOfJourneyLabel"
                mapper={entityToDropdownOption}
                options={journeyTargets?.map(entityToDropdownOption)}
                {...field}
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Purpose of Journey is required ' }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, journeyTargets]
  );

  const renderActivity = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="accountHolderOwnedAndControlled">
              Account holder’s owned and controlled vehicles
            </LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.activity"
            render={({ field, fieldState }) => (
              <SelectRender
                mapper={entityToDropdownOption}
                options={activityOptions}
                {...field}
                aria-labelledby="accountHolderOwnedAndControlled"
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: "Account holder's owned and controlled vehicles is required" }}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [activityOptions, control]
  );

  const renderLocation = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="locationLabel">Location</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.location"
            render={({ field, fieldState }) => (
              <LocationSelectRender
                {...field}
                mapper={entityToDropdownOption}
                labelledBy="locationLabel"
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Location is required' }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control]
  );

  const renderType = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="typeSelect">Type</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.type"
            render={({ field, fieldState }) => (
              <SelectRender
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                  handleTypeChange(value);
                }}
                options={transportTypes?.map(entityToDropdownOption)}
                mapper={entityToDropdownOption}
                aria-labelledby="typeSelect"
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Type is required' }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, transportTypes, handleTypeChange]
  );

  const renderSubtype = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="subtypeSelect">Subtype</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.subtype"
            render={({ field, fieldState }) => (
              <SelectRender
                {...field}
                mapper={entityToDropdownOption}
                options={transportTypes
                  .find(({ id }) => id === formData?.type.id)
                  ?.subtypes.map(entityToDropdownOption)}
                aria-labelledby="subtypeSelect"
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Subtype is required' }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [transportTypes, formData, control]
  );

  const renderFuelType = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="fuelSelect">Fuel Type</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.fuelType"
            render={({ field, fieldState }) => (
              <SelectRender
                {...field}
                error={fieldState.error?.message}
                mapper={entityToDropdownOption}
                options={fuelTypes?.map(entityToDropdownOption)}
                aria-labelledby="fuelSelect"
              />
            )}
            rules={{ required: 'Fuel Type is required' }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [fuelTypes, control]
  );

  const renderSupplier = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="dataProviderSelect">Data Provider (Optional)</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.dataProvider"
            render={({ field, fieldState }) => (
              <SupplierSelector
                accountId={accountId}
                value={field.value}
                onChange={(e) => setValue('data.dataProvider', e.target.select)}
                error={fieldState.error?.message}
                utility={TransactionType.Transport}
              />
            )}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, setValue]
  );

  const renderOrigin = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled labelFor="originOfTravel">Origin of Travel</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.origin"
            render={({ field, fieldState }) => (
              <TextInput id="originOfTravel" {...field} error={fieldState.error?.message} />
            )}
            rules={{ required: 'Origin of Travel is required' }}
            control={control}
            defaultValue=""
          />
        </ColStyled>
      </Row>
    ),
    [control]
  );

  const renderDestination = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled labelFor="destinationOfTravel">Destination of Travel</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.destination"
            render={({ field, fieldState }) => (
              <TextInput id="destinationOfTravel" {...field} error={fieldState.error?.message} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: 'Destination of Travel is required' }}
          />
        </ColStyled>
      </Row>
    ),
    [control]
  );

  const renderVolume = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelDescriptionContainerStyled>
            <LabelStyled labelFor="fuelVolume">Fuel Volume{isVolumeRequired ? '' : ' (optional)'}</LabelStyled>
            <DescriptionStyled>
              If no Volume figure is entered you will need to provide a value for &apos;Journey Distance&apos;
            </DescriptionStyled>
          </LabelDescriptionContainerStyled>

          <Controller
            name="data.volume"
            render={({ field, fieldState }) => (
              <NumberInputWithNegativeOption {...field} id="fuelVolume" error={fieldState.error?.message} />
            )}
            rules={{ required: isVolumeRequired ? 'Fuel Volume is required' : false }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, isVolumeRequired]
  );

  const renderVolumeUnit = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Volume Unit {!getValues('data.volume') && '(optional)'}</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.volumeUnit"
            render={({ field, fieldState }) => (
              <SelectRender
                {...field}
                mapper={entityToDropdownOption}
                options={volumeUnitOptions}
                error={fieldState.error?.message}
              />
            )}
            defaultValue={defaultVolumeUnit}
            control={control}
            rules={getConditionalValidateRules('data.volumeUnit', 'Unit of Volume is required')}
          />
        </ColStyled>
      </Row>
    ),
    [control, defaultVolumeUnit, getConditionalValidateRules, getValues, volumeUnitOptions]
  );

  const renderNumberOfJourneys = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled labelFor="numberOfJourneys">Number of Journeys</LabelStyled>
          </LabelContainerStyled>
          <Controller
            name="data.numberOfJourneys"
            control={control}
            defaultValue={1}
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <NumberInput
                {...field}
                id="numberOfJourneys"
                error={fieldState.error?.message}
                onChange={(value) => {
                  field.onChange(value);
                  handleNumberOfJourneysChange(value);
                }}
              />
            )}
          />
        </ColStyled>
      </Row>
    ),
    [control, handleNumberOfJourneysChange]
  );

  const renderJourneyDistance = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled labelFor="journeyDistance">
              Journey Distance{isDistanceRequired ? '' : ' (optional)'}
            </LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.journeyDistance"
            render={({ field, fieldState }) => (
              <NumberInputWithNegativeOption
                {...field}
                id="journeyDistance"
                onChange={(value) => {
                  field.onChange(value);
                  handleJourneyDistanceChange(value);
                }}
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: isDistanceRequired }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, handleJourneyDistanceChange, isDistanceRequired]
  );

  const renderDistance = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled labelFor="totalDistance">Total Distance{isDistanceRequired ? '' : ' (optional)'}</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.distance"
            render={({ field, fieldState }) => (
              <NumberInput {...field} id="totalDistance" error={fieldState.error?.message} />
            )}
            rules={{ required: isDistanceRequired }}
            defaultValue={null}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, isDistanceRequired]
  );

  const renderDistanceUnit = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled id="distanceUnitLabel">Distance Unit{isDistanceUnitRequired ? '' : ' (optional)'}</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.distanceUnit"
            render={({ field, fieldState }) => (
              <SelectRender
                aria-labelledby="distanceUnitLabel"
                {...field}
                mapper={entityToDropdownOption}
                options={distanceUnitOptions}
                error={fieldState.error?.message}
              />
            )}
            defaultValue={defaultDistanceUnit}
            control={control}
            rules={getConditionalValidateRules('data.distance', 'Unit of Distance is required')}
          />
        </ColStyled>
      </Row>
    ),
    [control, defaultDistanceUnit, distanceUnitOptions, getConditionalValidateRules, isDistanceUnitRequired]
  );

  const renderFreight = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Freight (tonne.km){isFreightRequired ? '' : ' (optional)'}</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.freight"
            render={({ field, fieldState }) => (
              <NumberInputWithNegativeOption {...field} error={fieldState.error?.message} />
            )}
            rules={{ required: isFreightRequired }}
            defaultValue={''}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, isFreightRequired]
  );

  const renderCarrier = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Carrier{!formData?.freight ? ' (optional)' : ''}</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.carrier"
            render={({ field, fieldState }) => <TextInput {...field} error={fieldState.error?.message} />}
            control={control}
            defaultValue={''}
            rules={getConditionalValidateRules('data.freight', 'Carrier is required')}
          />
        </ColStyled>
      </Row>
    ),
    [formData?.freight, control, getConditionalValidateRules]
  );

  const renderCost = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Cost (optional)</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.cost"
            render={({ field, fieldState }) => <NumberInput {...field} error={fieldState.error?.message} />}
            control={control}
            defaultValue={''}
          />
        </ColStyled>
      </Row>
    ),
    [control]
  );

  const renderCurrency = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Currency</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="financial.currency"
            render={({ field, fieldState }) => (
              <SelectRender
                {...field}
                mapper={entityToDropdownOption}
                options={currencyOptions}
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Currency is required' }}
            defaultValue={currencyDefaultValue}
            control={control}
          />
        </ColStyled>
      </Row>
    ),
    [control, currencyDefaultValue, currencyOptions]
  );

  const renderNotes = useCallback(
    () => (
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Notes (optional)</LabelStyled>
          </LabelContainerStyled>

          <Controller
            name="data.notes"
            render={({ field, fieldState }) => <TextInput {...field} error={fieldState.error?.message} />}
            control={control}
            defaultValue=""
          />
        </ColStyled>
      </Row>
    ),
    [control]
  );

  const renderReferenceCheckbox = useCallback(
    () => (
      <RowWithPadding container itemAlign="center">
        <div style={{ width: '50%' }}>Do you want to add any references?</div>

        <div>
          <Row container align="center">
            <CenteredCol item>
              <Checkbox size="md" checked={isReferenceDataRequired} onChange={showReferenceData} />

              <LabelContainerStyled>
                <LabelStyled>Yes</LabelStyled>
              </LabelContainerStyled>
            </CenteredCol>

            <CenteredCol item>
              <Checkbox size="md" checked={!isReferenceDataRequired} onChange={hideReferenceData} />

              <LabelContainerStyled>
                <LabelStyled>No</LabelStyled>
              </LabelContainerStyled>
            </CenteredCol>
          </Row>
        </div>
      </RowWithPadding>
    ),
    [hideReferenceData, isReferenceDataRequired, showReferenceData]
  );

  const renderReference = useCallback(
    () => (
      <SectionStyled isVisible>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Reference</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="reference.reference"
              render={({ field, fieldState }) => <TextInput {...field} error={fieldState.error?.message} />}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Document Reference</LabelStyled>
            </LabelContainerStyled>

            <Controller
              name="reference.documentReference"
              render={({ field, fieldState }) => <TextInput {...field} error={fieldState.error?.message} />}
              defaultValue=""
              control={control}
            />
          </ColStyled>
        </Row>
      </SectionStyled>
    ),
    [control]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showSubmitButton={!!watch('data.activity')}>
      <Row>
        <SubHeader>Please enter the following transactional {dataSection.toLowerCase()} data:</SubHeader>
      </Row>
      {renderLocation()}

      {renderPurpose()}
      {!!watch('data.purpose') && renderActivity()}
      {!!watch('data.activity') && (
        <>
          {renderType()}
          {!!watch('data.type') && renderSubtype()}

          {renderFuelType()}
          {renderSupplier()}

          {renderVolume()}
          {!!watch('data.volume') && renderVolumeUnit()}

          {renderOrigin()}
          {renderDestination()}

          {renderNumberOfJourneys()}
          {renderJourneyDistance()}
          {!!watch('data.journeyDistance') && renderDistance()}
          {!!watch('data.journeyDistance') && renderDistanceUnit()}

          {renderFreight()}
          {renderCarrier()}
          {renderCost()}
          {renderCurrency()}
          {renderNotes()}

          {renderReferenceCheckbox()}
          {isReferenceDataRequired && renderReference()}
          {props.children}
        </>
      )}
    </DataForm>
  );
}
