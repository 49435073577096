import { useQuery } from '@apollo/client';
import { Select } from 'rio-ui-components';

import GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID from '../../graphql/queries/surveys/GetSurveyTemplatesByAccountId.query.graphql';
import { SelectEvent } from '../../types';
import { Query } from '@rio/rio-types';

const templateToOption = (survey: { id: string; name: string }) => {
  return {
    value: survey.id,
    label: survey.name,
  };
};

interface SurveySelectProps {
  value: string;
  onChange: (e: SelectEvent) => void;
  error?: string;
  accountId: string;
}

export const SurveyTemplateSelect = ({ accountId, value, onChange, error = '' }: SurveySelectProps) => {
  const { data } = useQuery<Pick<Query, 'getSurveyTemplatesByAccountId'>>(GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
  });

  const options = (data?.getSurveyTemplatesByAccountId ?? []).map(templateToOption);
  const selectedValue = options.find((opt) => opt.value === value);
  return (
    <Select
      id="AddSurvey__Select"
      name="SurveyTemplateSelect"
      placeholder="Please select a survey"
      multiValueColor="tertiary"
      value={selectedValue}
      defaultOptions
      onChange={onChange}
      classPrefix="user-select"
      error={error}
      options={options}
    />
  );
};
