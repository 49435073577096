import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { Courses } from './CoursesOverview/Courses';
import { CoursePreview } from './CoursePreview';
import { CoursesOverview } from './CoursesOverview';
import { ApplicationData } from '~/types';

const ContainerStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

interface LearnContainerProps {
  applicationData: ApplicationData;
}
const LearnContainer = ({ applicationData: { getAccountInfo } }: LearnContainerProps) => {
  const hasLearnAccess = !getAccountInfo || getAccountInfo.accessControls.learn;

  return (
    <ContainerStyled>
      {hasLearnAccess ? (
        <Routes>
          <Route path="" element={<CoursesOverview />} />
          <Route path=":id" element={<Courses />} />
          <Route path="course/:id" element={<CoursePreview />} />
          <Route path="*" element={<Navigate to="engage" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="" element={<CoursesOverview />} />
          <Route path="*" element={<Navigate to="engage" />} />
        </Routes>
      )}
    </ContainerStyled>
  );
};

export { LearnContainer };
