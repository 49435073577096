import { TransactionType } from '@rio/rio-types';
import UploadContainer from '../../UploadContainer';
import { TemplateType } from '../../../../types';
import advancedHotelStaysTemplate from './advanced-hotel-stays-template.xlsx';
import columns from './columns.json';

function HotelStaysUploadContainer() {
  return (
    <UploadContainer
      dataSection={TransactionType.Hotelstays}
      template={[
        {
          columns,
          name: 'Simple',
          fileName: 'Hotelstays-template',
          type: TemplateType.Dynamic,
        },
        {
          name: 'Advanced',
          fileName: 'advanced-hotel-stays-template',
          url: advancedHotelStaysTemplate,
          type: TemplateType.Static,
        },
      ]}
    />
  );
}

export default HotelStaysUploadContainer;
