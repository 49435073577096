import capitalize from 'lodash/capitalize';
import { NamedEntity, User } from '@rio/rio-types';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { compareDate, defaultFilterParams, formatDateRange, getAgRowId } from '~/utils';

const textCellStyle = {
  lineHeight: '21px',
  wordBreak: 'inherit',
  display: 'inline-block',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

type FormatMessageFunction = (props: { id: string }) => string;

export const options = (formatMessage: FormatMessageFunction): GridOptions => ({
  rowHeight: 56,
  getRowId: getAgRowId('id'),
  columnDefs: [
    {
      headerName: formatMessage({ id: 'pages.project.grid.header.name' }),
      field: 'name',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 160,
      cellStyle: textCellStyle,
    },

    {
      headerName: 'Priority',
      field: 'priority',
      minWidth: 90,
      sortable: false,
      filter: true,
      hide: true,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.impact' }),
      field: 'impact',
      sortable: false,
      filter: false,
      width: 200,
      minWidth: 160,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.description' }),
      cellRenderer: 'description',
      sortable: false,
      filter: false,
      minWidth: 100,
      cellStyle: { textAlign: 'center' },
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.locations' }),
      valueGetter: ({ data: { locations } }) => locations?.map((item: NamedEntity) => item?.name),
      filterParams: {
        ...defaultFilterParams,
      },
      minWidth: 130,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.meters' }),
      valueGetter: ({ data: { meters } }) => meters?.map((item: NamedEntity) => item?.name),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 110,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.category' }),
      cellRenderer: 'category',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 150,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.scheme' }),
      valueGetter: ({ data: { scheme } }) => (scheme ? scheme.name : ''),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 110,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.owners' }),
      field: 'owners',
      cellRenderer: 'owners',
      valueGetter: ({ data: { owners = [] } }) =>
        owners.map(({ first_name, last_name }: User) => `${first_name} ${last_name}`),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 90,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.cost' }),
      field: 'cost',
      cellRenderer: 'costCell',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.implementationDate' }),
      field: 'startDate',
      cellRenderer: 'startDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        ...defaultFilterParams(),
        comparator: compareDate,
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.baselineDate' }),
      cellStyle: textCellStyle,
      valueGetter: ({ data: { baselineStartDate } }: ValueGetterParams) =>
        formatDateRange([
          baselineStartDate,
          new Date(baselineStartDate).setFullYear(new Date(baselineStartDate).getFullYear() + 1),
        ]),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.benefitsDate' }),
      cellStyle: textCellStyle,
      valueGetter: ({ data: { startDate, endDate } }: ValueGetterParams) => formatDateRange([startDate, endDate]),
      filterParams: {
        ...defaultFilterParams(),
        comparator: compareDate,
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.plannedBenefit' }),
      field: 'plannedBenefit',
      cellStyle: textCellStyle,
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.plannedCostChange' }),
      field: 'plannedCostChange',
      cellStyle: textCellStyle,
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.plannedCO2eChange' }),
      field: 'plannedCO2eChange',
      cellStyle: textCellStyle,
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.project.grid.header.frequency' }),
      valueGetter: ({ data: { frequency } }) => capitalize(frequency),
      cellStyle: textCellStyle,
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },
  ],
});

export const defaultConfig: GridOptions = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: [10, 25, 50, 100],
  cacheBlockSize: 25,
  suppressNoRowsOverlay: true,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
    cellStyle: {
      height: 'auto',
    },
  },
  rowStyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

export const defaultSortModel = [
  { colId: 'startDate', sort: 'asc', sortIndex: 0 },
  { colId: 'name', sort: 'asc', sortIndex: 1 },
];

export const segmentedButtonValues = [{ offValue: 'Show My Tasks', onValue: 'Show All Tasks' }];
