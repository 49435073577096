import { Auth, Analytics } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import * as Sentry from '@sentry/react';
import { ApolloCache, ApolloClient, InMemoryCache } from '@apollo/client';
import { GET_ACCOUNT_BY_ID } from '../containers/ConfigurationContainer/ConfigurationAccountsContainer/index.queries';
import { TAG, LOCATION, ACCOUNT } from '../constants/scopes';
import { GET_ID_TOKEN, GET_LOGIN_ERROR_MESSAGE } from '../queries/auth';
import { SET_CURRENT_ACCOUNT_ID } from '../queries/currentAccount';
import { getLoginException } from '../containers/LoginContainer';
import { AccountType } from '@rio/rio-types';

export const defaults = [
  {
    query: GET_ID_TOKEN,
    data: {
      sessionLoading: true,
      idToken: null,
    },
  },
  { query: GET_LOGIN_ERROR_MESSAGE, data: { loginError: '' } },
];

const clearFrontendState = async (cache: ApolloCache<InMemoryCache>, reload: boolean = true) => {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('ID_TOKEN');
  localStorage.removeItem('DISMISSED_BANNERS');
  localStorage.removeItem('CURRENT_ACCOUNT');
  await cache.writeQuery({ query: SET_CURRENT_ACCOUNT_ID, data: { currentAccountId: null } });
  // {@link: https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout}

  if (reload) {
    window.location.reload();
  }
};

export const sessionNotLoaded = (cache: ApolloCache<InMemoryCache>) => {
  return cache.writeQuery({
    query: GET_ID_TOKEN,
    data: {
      sessionLoading: false,
    },
  });
};

// {@link: https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo#h_01GESNQ1BTNYMK9FAFTN46QCEE}
const enablePendoAnalytics = (
  userId: string,
  accountId: string,
  userEmail: string,
  userFullName: string,
  accountName: string,
  accountType: AccountType,
  userRoles: string[]
) => {
  if ((window as any).pendo && !(window as any).Cypress) {
    (window as any).pendo.initialize({
      visitor: {
        id: userId,
        email: userEmail,
        full_name: userFullName,
        roles: userRoles.join(', '),
      },

      account: {
        id: accountId,
        name: accountName,
        type: accountType,
      },
    });
  }
};

export const saveUserSession = async (session: CognitoUserSession, client: ApolloClient<InMemoryCache>) => {
  if (!session) {
    sessionNotLoaded(client.cache);
  }

  /* TODO: remove local storage after refactoring */
  localStorage.setItem('ID_TOKEN', session.getIdToken().getJwtToken());
  localStorage.setItem('ACCESS_TOKEN', session.getAccessToken().getJwtToken());
  const payload = session.getIdToken().decodePayload();
  const accessLevel = payload['custom:access_level'] || ACCOUNT;

  const idToken = {
    event_id: null,
    ...payload,
    roles: payload['cognito:groups'],
    accessLevel,
    allowedLocations: accessLevel === LOCATION ? JSON.parse(payload['custom:allowed_locations'] || null) : null,
    allowedTags: accessLevel === TAG ? JSON.parse(payload['custom:allowed_tags'] || null) : null,
    __typename: 'IdToken',
  };

  const {
    data: { getAccountInfo },
  } = await client.query({
    query: GET_ACCOUNT_BY_ID,
    variables: { id: payload.name },
  });

  Sentry.setUser({ id: payload.sub, email: payload.email });
  Sentry.setContext('account', {
    id: getAccountInfo.id,
    name: getAccountInfo.name,
    type: getAccountInfo.type,
  });

  Sentry.setContext('user', {
    id: payload.sub,
    name: `${payload.given_name} ${payload.family_name}`,
  });

  client.cache.writeQuery({
    query: GET_ID_TOKEN,
    data: {
      idToken,
      sessionLoading: false,
    },
  });

  Analytics.updateEndpoint({
    address: payload.email,
    userAttributes: {
      userId: [payload.sub],
      email: [payload.email],
      name: [`${payload.given_name} ${payload.family_name}`],
      roles: payload['cognito:groups'],
      accountId: [payload.name],
    },
  });

  enablePendoAnalytics(
    payload.sub,
    payload.name,
    payload.email,
    `${payload.given_name} ${payload.family_name}`,
    getAccountInfo.name,
    getAccountInfo.type,
    payload['cognito:groups']
  );
};

export async function logOut(cache: ApolloCache<InMemoryCache>, reload: boolean = true) {
  Sentry.setUser(null);
  Sentry.setContext('account', null);
  Sentry.setContext('user', null);
  try {
    await Auth.signOut();
  } finally {
    await clearFrontendState(cache, reload);
  }
}

export const resolvers = {
  Mutation: {
    login: async (_, { email, password }, { client, cache }) => {
      try {
        const user = await Auth.signIn(email.toLowerCase(), password);
        await saveUserSession(user.getSignInUserSession(), client);
      } catch (error: any) {
        // clear any previous login data
        await logOut(cache, false);
        Sentry.captureException(error);
        throw error;
      }
    },
    loginSSO: async (_, { provider }) => {
      Auth.federatedSignIn({ customProvider: provider });
    },
    logout: async (_, __, { cache }) => logOut(cache),
    setPassword: async (_, { email, oldPassword, password }, { client, cache }) => {
      try {
        const user = await Auth.signIn(email.toLowerCase(), oldPassword);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, password);
        }
        await saveUserSession(user.getSignInUserSession(), client);
      } catch (error: any) {
        cache.writeQuery({
          query: GET_LOGIN_ERROR_MESSAGE,
          data: {
            loginError: getLoginException(error),
          },
        });
      }
    },
    resetPassword: async (_, { username, password, confirmationCode }, { cache }) => {
      try {
        await Auth.forgotPasswordSubmit(username, confirmationCode, password);
      } catch (error: any) {
        cache.writeQuery({
          query: GET_LOGIN_ERROR_MESSAGE,
          data: {
            loginError: getLoginException(error),
          },
        });
      }
    },
    forgotPassword: async (_, { username }, { cache }) => {
      try {
        await Auth.forgotPassword(username.toLowerCase());
      } catch (error: any) {
        cache.writeQuery({ query: GET_LOGIN_ERROR_MESSAGE, data: { loginError: 'GeneralError' } });
      }
    },
  },
};
