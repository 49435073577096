import { useEffect, useState } from 'react';
import { GET_SUBSCRIBED_DASHBOARDS } from '../containers/ReportingContainer/ReportingReports/index.queries';
import { useCurrentAccountId } from './useCurrentAccountId';
import { useQuery } from '@apollo/client';

export const useGetSubscribedDashboards = () => {
  const accountId = useCurrentAccountId();
  const {
    data: dashboardData,
    loading,
    error,
  } = useQuery(GET_SUBSCRIBED_DASHBOARDS, {
    variables: { accountId },
    notifyOnNetworkStatusChange: true,
  });
  const [dashboards, setDashboards] = useState(dashboardData);

  useEffect(() => {
    if (!loading) {
      const DEMO_ACCOUNT_IDS = ['b1f7fc4e-714d-4271-bb3f-d736dff38a14', '608877fc-83e7-4487-b5a4-493e0ba05903'];
      const DEMO_DASHBOARDS = [
        { id: '0f7db846-67a6-4d3e-807d-67c3ef5f4f30', name: 'Patron Capital - Carbon Report' },
        { id: '65236e34-ca0d-42e1-a8f1-b7c71ca08555', name: 'Patron Capital - Dalton Park' },
        { id: '1a8ee51b-e5c9-483a-85d6-86e9117e0aa1', name: 'Patron Capital - MK Gateway' },
        { id: '3e75ba9e-3c96-4396-b1b7-ba4f64680907', name: 'Patron Capital - GRESB' },
      ];
      if (DEMO_ACCOUNT_IDS.includes(accountId)) {
        // exclude demo dashboards from the list
        const defaultDashboards = dashboardData?.getSubscribedDashboards.filter(
          (item) => !DEMO_DASHBOARDS.map(({ id }) => id).includes(item.id)
        );
        setDashboards({
          getSubscribedDashboards: defaultDashboards,
        });
      } else {
        setDashboards(dashboardData);
      }
    }
  }, [loading, dashboardData, accountId]);

  return [dashboards, loading, error];
};
