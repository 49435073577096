import styled from 'styled-components';
import { Col } from 'rio-ui-components';

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

export const GridContainer = styled.div`
  height: 100%;
`;
