import { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import { useTheme } from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import { Survey, SurveySubmissionStatus } from '@rio/rio-types';
import { countBy, keyBy, sum } from 'lodash';
import { Theme } from '../../../../types';
import { formatSubmissionStatus, getStatusColor as formatStatusColor } from '../../../../components/Surveys/utils';
import { basePieChart } from './basePieChart';

interface ChartProps {
  contributors: Survey['contributors'];
  submissions: Survey['submissions'];
}

export type SubmissionStatusCount = {
  [key in SurveySubmissionStatus]: number;
};

const formatDataName = (status: string, count: number, total: number) =>
  `${status}: ${Math.floor((count / total) * 100)}%`;

const responseChart = (theme: Theme, submissionsCount: SubmissionStatusCount): Highcharts.Options => {
  const total = sum(Object.values(submissionsCount));

  return {
    ...basePieChart,
    series: [
      {
        type: 'pie',
        data: Object.entries(submissionsCount).map(([status, count]) => ({
          y: count,
          name: formatDataName(formatSubmissionStatus(status as SurveySubmissionStatus), count, total),
          color: formatStatusColor(theme, status as SurveySubmissionStatus),
        })),
      },
    ],
  };
};

const countSubmissionStatuses = ({ contributors, submissions }: ChartProps): SubmissionStatusCount => {
  const submissionsByOwner = keyBy(submissions, 'owner.id');
  const statuses = contributors.map(({ account }) => submissionsByOwner[account.id]?.status);
  return countBy(statuses) as SubmissionStatusCount;
};

export function ResponseStatusChart({ contributors, submissions }: ChartProps) {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const submissionsCount = countSubmissionStatuses({ contributors, submissions });
  const chartCached = useMemo(() => responseChart(theme, submissionsCount), [theme, submissionsCount]);

  return <HighchartsReact highcharts={Highcharts} options={chartCached} ref={chartRef} />;
}
