import { useApolloClient, useMutation } from '@apollo/client';
import { isEmpty, capitalize } from 'lodash';
import {
  CellEditingStartedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  IServerSideGetRowsParams,
  RowModelType,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import * as Sentry from '@sentry/react';
import { Filters, FilterData, Mutation, MutationUpdateDataTrackerValidationResultsArgs } from '@rio/rio-types';
import { useCurrentAccountId, useNotification, usePermissions } from '~/hooks';
import { mapSortModel, mapFilterModel, defaultFilterParams, formatDate, gridValueFormatter } from '~/utils';
import { GET_DATA_TRACKER_VALIDATION_FILTERS } from '../../grids/index.queries';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AgGrid, Button, styled } from '@rio/ui-components';
import { useIntl } from 'react-intl';
import { FetchRowsParameters } from '../../types';
import { ConfirmActionModal } from '~/components/ConfirmAction/v2';
import {
  GET_DATA_TRACKER_VALIDATION_RESULTS_BY_ACCOUNT_ID,
  UPDATE_DATA_TRACKER_VALIDATION_RESULT,
} from '../../index.queries';
import { MissingDataPlaceholder } from './MissingDataPlaceholder';

const AgGridContainerStyled = styled('div')`
  min-height: 480px;
`;

const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: 480px;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
  gap: 16px;
`;

const ButtonStyled = styled(Button)`
  width: 130px;
`;

const PAGINATION_PAGE_SIZE = 20;

export function MissingDataGrid() {
  const intl = useIntl();
  const gridApiRef = useRef<GridApi>();
  const accountId = useCurrentAccountId();
  const apolloClient = useApolloClient();
  const { showNotification } = useNotification();
  const permissions = usePermissions();
  const [placeholderShown, setPlaceholderShown] = useState<boolean>(false);

  const showPlaceholder = useCallback(() => setPlaceholderShown(true), []);
  const [selectedRowToUpdate, setSelectedRowToUpdate] = useState<IRowNode | null>(null);

  const [isSelected, setIsSelected] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [updateResults] = useMutation<
    Pick<Mutation, 'updateDataTrackerValidationResults'>,
    MutationUpdateDataTrackerValidationResultsArgs
  >(UPDATE_DATA_TRACKER_VALIDATION_RESULT);

  const fetchRows = useCallback(
    async (variables: FetchRowsParameters) => {
      const {
        data: {
          getDataTrackerValidationResults: { rows, totalRows },
        },
      } = await apolloClient.query({
        query: GET_DATA_TRACKER_VALIDATION_RESULTS_BY_ACCOUNT_ID,
        variables: { ...variables, accountId: accountId, isIgnored: false },
        fetchPolicy: 'network-only',
      });

      return { rows, totalRows };
    },
    [apolloClient, accountId]
  );

  const refreshGrid = useCallback(() => {
    gridApiRef.current?.refreshServerSide({ purge: true });
    gridApiRef.current?.deselectAll();
    setIsSelected(false);
  }, []);

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getDataTrackerValidationFilters: values },
        } = await apolloClient.query({
          query: GET_DATA_TRACKER_VALIDATION_FILTERS,
          variables: {
            accountId,
            field: params.colDef.colId || params.colDef.field,
          },
        });

        params.success(values.map((item: FilterData) => (item.value ? JSON.stringify(item) : item.value)));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
        Sentry.captureException(err);
      }
    },
    [accountId, apolloClient, showNotification]
  );

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    gridApiRef.current = event.api;
  }, []);

  const dataSource = useMemo(
    () => ({
      getRows: async ({ request, success, fail }: IServerSideGetRowsParams) => {
        try {
          const { startRow, sortModel, filterModel } = request;
          const filters: Filters = mapFilterModel(filterModel);

          const { rows, totalRows } = await fetchRows({
            offset: startRow || 0,
            limit: PAGINATION_PAGE_SIZE,
            sort: mapSortModel(sortModel),
            filters,
          });
          success({
            rowData: rows,
            rowCount: totalRows,
          });
          if (totalRows === 0 && isEmpty(filters)) {
            return showPlaceholder();
          }
        } catch (err) {
          fail();
          Sentry.captureException(err);
        }
      },
    }),
    [fetchRows, showPlaceholder]
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRowsList = gridApiRef.current?.getSelectedRows();
    setIsSelected(!!selectedRowsList?.length);
  }, []);

  const handleAcceptButtonClick = useCallback(() => {
    const results = gridApiRef.current?.getSelectedRows();
    if (results?.length === 0) {
      return;
    }
    setIsConfirmationModalOpen(true);
  }, []);

  const setRowToUpdate = useCallback((e: CellEditingStartedEvent) => {
    setSelectedRowToUpdate(e.node);
  }, []);

  const handleSelection = useCallback((shouldDeselectAll?: boolean) => {
    if (gridApiRef.current) {
      const currentPage = gridApiRef.current.paginationGetCurrentPage();
      const startRow = currentPage * PAGINATION_PAGE_SIZE;
      const endRow = startRow + PAGINATION_PAGE_SIZE - 1;

      const nodesOnCurrentPage: IRowNode[] = [];
      for (let i = startRow; i <= endRow; i++) {
        const node = gridApiRef.current.getDisplayedRowAtIndex(i);
        if (node) {
          nodesOnCurrentPage.push(node);
        }
      }

      if (shouldDeselectAll) {
        gridApiRef.current.deselectAll();
        setIsSelected(false);
        return;
      }

      nodesOnCurrentPage.forEach((rowNode) => {
        if (!rowNode.isSelected()) {
          rowNode.setSelected(true);
        }
      });
    }
  }, []);

  const updateRow = useCallback(
    async (params) => {
      if (selectedRowToUpdate === null) return;

      // save new value to variable for cases when user hasn't changed note or typed something and then erased the field
      const newValue = params.newValue || null;

      if (newValue !== params.oldValue) {
        try {
          selectedRowToUpdate.updateData({ ...selectedRowToUpdate.data, note: params.newValue });
          await updateResults({
            variables: {
              input: [
                {
                  id: params.data.id,
                  accountId,
                  note: newValue,
                },
              ],
            },
          });
          selectedRowToUpdate.setSelected(false);
          showNotification('The data tracker result note has been successfully updated!');
        } catch (e) {
          selectedRowToUpdate.updateData({ ...selectedRowToUpdate.data, note: params.oldValue });
          showNotification(`Something went wrong. The data tracker result note wasn't updated.`, 'danger');
        }
      }
    },
    [accountId, updateResults, showNotification, selectedRowToUpdate]
  );

  const updateIgnoredRecordsHandler = async () => {
    const ignoreRecords = gridApiRef.current?.getSelectedRows();

    if (ignoreRecords?.length) {
      try {
        await updateResults({
          variables: {
            input: ignoreRecords?.map(({ id }) => ({
              id: id.toString(),
              accountId,
              isIgnored: true,
            })),
          },
        });
        showNotification('The data tracker result(-s) has been successfully updated!');
        refreshGrid();
      } catch (e) {
        showNotification(`Something went wrong. The data tracker result(-s) wasn't updated.`, 'danger');
      }
    }
  };

  const acceptAllowedForRole = useMemo(
    () => permissions.dataTracker.find((a) => a.startsWith('updateDataTrackerValidationResults')),
    [permissions]
  );

  const gridOptions = useMemo(
    () => ({
      onGridReady: handleGridReady,
      onCellEditingStopped: updateRow,
      onCellEditingStarted: setRowToUpdate,
      onSelectionChanged,
      suppressRowClickSelection: true,
      pagination: true,
      paginationPageSize: PAGINATION_PAGE_SIZE,
      cacheBlockSize: PAGINATION_PAGE_SIZE,
      paginationPageSizeSelector: false,
      rowSelection: 'multiple' as const,
      rowModelType: 'serverSide' as RowModelType,
      serverSideDatasource: dataSource,
      defaultColDef: {
        sortable: true,
        filter: true,
        cellStyle: {
          paddingTop: '5px',
          paddingBottom: '5px',
          height: 'auto',
          wordBreak: 'break-word',
          lineHeight: '20px',
        },
      },
      columnDefs: [
        {
          headerName: 'Utility',
          headerTooltip: 'Resource for which data is expected',
          checkboxSelection: true,
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          field: 'utility',
          minWidth: 150,
          valueFormatter: ({ value }: ValueFormatterParams) => capitalize(value),
        },
        {
          headerName: 'Supplier',
          headerTooltip: 'Upload supplier',
          filter: 'agTextColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          field: 'supplierName',
          minWidth: 200,
        },
        {
          headerName: 'Start Date',
          headerTooltip: 'Start date of the expected time frame',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }) => formatDate(value),
          field: 'startDate',
          minWidth: 200,
        },
        {
          headerName: 'End Date',
          headerTooltip: 'End date of the expected time frame',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }) => formatDate(value),
          field: 'endDate',
          minWidth: 200,
        },
        {
          headerName: 'Location',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          field: 'locations',
          minWidth: 200,
        },
        {
          headerName: 'Meter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          field: 'meters',
          minWidth: 200,
        },
        {
          headerName: 'Reason',
          headerTooltip: 'Notification reason',
          filter: 'agTextColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          field: 'reason',
          minWidth: 300,
        },
        {
          headerName: 'Notes',
          headerTooltip: 'Notification notes can be left here',
          filter: 'agTextColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          field: 'note',
          editable: true,
          cellEditor: 'agTextCellEditor',
          valueGetter: ({ data: { note } }) => note,
          valueSetter: (params) => {
            if (params.newValue !== params.oldValue) {
              return true;
            } else {
              return false;
            }
          },
          minWidth: 200,
        },
        {
          headerName: 'Uploader',
          headerTooltip: 'Username',
          valueGetter: () => '-',
          field: 'emptyField',
          sortable: false,
          filter: false,
          minWidth: 200,
        },
        {
          headerName: 'Filename',
          headerTooltip: 'Filename',
          valueGetter: () => '-',
          field: 'emptyField2',
          sortable: false,
          filter: false,
          minWidth: 200,
        },
      ],
      rowStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    }),
    [getFilterValues, handleGridReady, onSelectionChanged, dataSource, updateRow, setRowToUpdate]
  );

  if (placeholderShown) {
    return <MissingDataPlaceholder />;
  }

  return (
    <div>
      <ButtonsContainer>
        {!!acceptAllowedForRole && (
          <ButtonStyled
            variant="outlined"
            onClick={() => handleSelection()}
            title={intl.formatMessage({ id: 'pages.data.overview.selectAll' })}
          >
            {intl.formatMessage({ id: 'pages.data.overview.selectAllButtonText' })}
          </ButtonStyled>
        )}
        {isSelected && (
          <ButtonStyled
            variant="outlined"
            onClick={() => handleSelection(true)}
            title={intl.formatMessage({ id: 'pages.data.overview.deselectAll' })}
          >
            {intl.formatMessage({ id: 'pages.data.overview.deselectAllButtonText' })}
          </ButtonStyled>
        )}
        <ButtonStyled
          variant="outlined"
          onClick={() => {
            if (gridApiRef.current) {
              gridApiRef.current.setFilterModel(null);
            }
          }}
          title={intl.formatMessage({ id: 'pages.data.overview.clear' })}
        >
          {intl.formatMessage({ id: 'pages.data.overview.clearButtonText' })}
        </ButtonStyled>
        {!!acceptAllowedForRole && (
          <ButtonStyled
            variant="filled"
            onClick={handleAcceptButtonClick}
            title={intl.formatMessage({ id: 'pages.data.overview.acceptInfo' })}
          >
            {intl.formatMessage({ id: 'pages.data.overview.accept' })}
          </ButtonStyled>
        )}
      </ButtonsContainer>

      <ConfirmActionModal
        open={isConfirmationModalOpen}
        title={intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.title' })}
        confirmButtonText={intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.agree' })}
        closeButtonText={intl.formatMessage({ id: 'pages.data.overview.confirmationDialog.disagree' })}
        onConfirm={updateIgnoredRecordsHandler}
        onClose={() => setIsConfirmationModalOpen(false)}
      />

      <AgGridContainerStyled>
        <AgGridStyled gridKey="missingDataGridV2" gridOptions={gridOptions} />
      </AgGridContainerStyled>
    </div>
  );
}
