import { useMutation } from '@apollo/client';
import { Mutation, Query, Account } from '@rio/rio-types';
import { UPDATE_ACCOUNT, GET_ACCOUNTS, GET_ACCOUNT_BY_ID } from './index.queries';
import { useNotification } from '../../../hooks';
import { GET_ACCOUNTS_VARIABLES } from './useGetAccounts';

export function useUpdateAccount(callback: Function) {
  const { showNotification } = useNotification();
  return useMutation<Mutation>(UPDATE_ACCOUNT, {
    onCompleted: ({ updateAccount }) => {
      showNotification(`The account ${updateAccount.name} has been updated!`, 'success');
      callback();
    },
    onError: () => {
      showNotification('Something went wrong. If the problem persists, contact support.', 'danger');
    },
    update: (cache, result) => {
      const { getAccounts } = cache.readQuery<Query>({
        query: GET_ACCOUNTS,
        variables: GET_ACCOUNTS_VARIABLES,
      }) as Query;
      const updateAccount = result.data?.updateAccount;
      const updatedAccount: Account = {
        ...updateAccount,
        reportingYear: result.data?.setAccountReportingYear,
      } as Account;
      const updatedAccountId = updatedAccount?.id;
      cache.writeQuery({
        query: GET_ACCOUNTS,
        variables: GET_ACCOUNTS_VARIABLES,
        data: {
          getAccounts: {
            ...getAccounts,
            accounts: getAccounts.rows.map((account) => (account.id === updatedAccountId ? updatedAccount : account)),
          },
        },
      });
      cache.writeQuery({
        query: GET_ACCOUNT_BY_ID,
        variables: {
          id: updatedAccountId,
        },
        data: { getAccountInfo: updatedAccount },
      });
    },
  });
}
