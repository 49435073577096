import { useApolloClient } from '@apollo/client';
import { GridOptions, SetFilterValuesFuncParams, SortDirection, ValueFormatterParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { FilterData } from '@rio/rio-types';
import { formatDate, gridNumberFormatter, gridValueFormatter } from '../../../../utils';
import { useNotification } from '../../../../hooks';
import { GET_HOTEL_STAYS_CONVERSION_FACTORS_FILTER } from './index.queries';

function typeValueFormatter({ value }: ValueFormatterParams) {
  let param;

  try {
    param = JSON.parse(value).value;
  } catch {
    param = value;
  }

  switch (param) {
    case 'HOTEL':
    default:
      return 'Hotel';
    case 'WORK_FROM_HOME':
      return 'Work From Home';
  }
}

export function useHotelStaysConversionFactorsGridConfig(): GridOptions {
  const client = useApolloClient();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getHotelStaysConversionFactorsFilter: values },
        } = await client.query<{ getHotelStaysConversionFactorsFilter: FilterData[] }>({
          query: GET_HOTEL_STAYS_CONVERSION_FACTORS_FILTER,
          variables: {
            field: params.colDef.colId || params.colDef.field,
          },
        });
        params.success(values.map((item) => (item.value ? (item.value as string) : JSON.stringify(item))));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [client, showNotification]
  );

  const config = useMemo(() => ({
    columnDefs: [
      {
        headerName: 'Reporting Year',
        field: 'reportingYear',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        initialSort: 'desc' as SortDirection,
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        valueFormatter: typeValueFormatter,
        filterParams: {
          values: getFilterValues,
          valueFormatter: typeValueFormatter,
        },
        initialSort: 'asc'  as SortDirection,
      },
      {
        headerName: 'Country',
        field: 'country',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        initialSort: 'asc'  as SortDirection,
      },
      {
        headerName: 'Unit',
        field: 'unit',
        filter: 'agSetColumnFilter',
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'kgCO2e',
        field: 'kgCO2e',
        minWidth: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: gridNumberFormatter,
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        initialHide: true,
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        filter: 'agDateColumnFilter',
        initialHide: true,
        minWidth: 100,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        valueFormatter: ({ value }: { value: Date }) => formatDate(value),
      },
      {
        headerName: 'Reference',
        field: 'reference',
        initialHide: true,
        cellRenderer: 'reference',
        cellRendererParams: {
          icon: 'external-link-alt',
        },
        minWidth: 100,
      },
    ],
  }), [getFilterValues]);

  return config;
}
