import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_ASPECT_BY_ID_AND_ACCOUNT_ID } from '../../graphql/queries/governance/GetAspectByIdAndAccountId.query.js';

type Response = Pick<Query, 'getAspectById'>;

export function useGetAspectByIdAndAccountId(id: string, accountId: string) {
  return useQuery<Response>(GET_ASPECT_BY_ID_AND_ACCOUNT_ID, {
    variables: {
      id,
      accountId,
    },
  });
}
