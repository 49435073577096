export * from './base';
import { TransactionType } from '@rio/rio-types';
import { getElectricityConfiguration, getGasConfiguration, getHeatAndSteamConfiguration } from './energy';
import { getHotelstaysConfiguration } from './hotelstays';
import { getMaterialsConfiguration } from './materials';
import { getRefrigerantConfiguration } from './refrigerant';
import { getWaterConfiguration } from './water';
import { getTransportConfiguration } from './transport';
import { getFuelConfiguration } from './fuel';
import { getWasteConfiguration } from './waste';
import { getInvestmentConfiguration } from './investment';

export function useGetDromoConfiguration(transactionType: TransactionType, data?: any) {
  switch (transactionType) {
    case TransactionType.Refrigerant:
      return getRefrigerantConfiguration();
    case TransactionType.Hotelstays:
      return getHotelstaysConfiguration();
    case TransactionType.Materials:
      return getMaterialsConfiguration(data);
    case TransactionType.Electricity:
      return getElectricityConfiguration();
    case TransactionType.Gas:
      return getGasConfiguration();
    case TransactionType.Heatsteam:
      return getHeatAndSteamConfiguration();
    case TransactionType.Water:
      return getWaterConfiguration();
    case TransactionType.Transport:
      return getTransportConfiguration(data);
    case TransactionType.Fuel:
      return getFuelConfiguration(data);
    case TransactionType.Waste:
      return getWasteConfiguration();
    case TransactionType.Investment:
      return getInvestmentConfiguration();
    default:
      throw new Error(`No dromo configuration for ${transactionType}`);
  }
}
