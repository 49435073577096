import { gql } from '@apollo/client';

import { LOCATION_FRAGMENT } from '../../../../graphql/fragments/LocationFields.fragment';

export const GET_WASTE_TRANSACTIONS_PAGE = gql`
  query GetWasteTransactionsPage($accountId: ID!, $offset: Int, $limit: Int, $sort: [SortCommand!], $filters: Filters) {
    getWasteTransactionsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        accountId
        wasteOut
        locationCode
        locationEndDate
        location {
          ...LocationFields
        }
        wasteStream
        ewc
        importBatchFileName
        importBatchId
        currency
        treatmentProcess
        tags
        wasteIn
        individualCollectionDate
        periodStartDate
        periodEndDate
        numberOfContainers
        numberOfCollections
        quantity
        quantityUnitName
        accuracy
        gateFee
        managementFee
        binHire
        binExchangeFee
        transportCharge
        liftCharge
        equipmentHire
        miscCost
        miscCostRate
        rebate
        penalty
        notes
        reference
        consignmentNoteId
        wasteTransferNote
        consignmentNoteEA
        salesOrder
        invoiceNumber
        evidenceReference
        otherReference
        wasteContractor
        containerTypeSize
        labourCost
        wasteCarrier
        totalCost
        totalCostVat
        carbonEmission
        departments
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;
