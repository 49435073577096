"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatters = void 0;
const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};
const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};
const time = new Intl.DateTimeFormat([], timeOptions);
const date = new Intl.DateTimeFormat([], dateOptions);
const dateTime = new Intl.DateTimeFormat([], {
    ...timeOptions,
    ...dateOptions,
});
const list = new Intl.ListFormat();
const number = new Intl.NumberFormat();
exports.formatters = {
    time,
    date,
    dateTime,
    list,
    number,
};
