import React from 'react';
import styled from 'styled-components';
import { Text } from 'rio-ui-components';
import TransactionRow from '../TransactionRow';

const TableStyled = styled.table`
  tr:nth-child(even) {
    background: ${(p) => p.theme.colors.overlay.light.background};
  }
`;

const TableHeaderItem = styled.th`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.overlay.normal.background};
  border-right: 1px solid ${(p) => p.theme.colors.overlay.normal.background};
`;

const WidthDiv = styled.div`
  width: ${(props) => props.width}px;
`;

const TransactionTable = (props) => {
  const { columnHeaderModel, data, onDocumentClick = () => {} } = props;

  return (
    <TableStyled>
      <thead>
        <tr>
          {columnHeaderModel.map((header) =>
            header.display ? (
              <TableHeaderItem key={header.id}>
                <WidthDiv width={header.width}>
                  <Text inline name={`wasteTransaction-row__${header.id}`} size="md" weight="bold">
                    {header.displayName}
                  </Text>
                </WidthDiv>
              </TableHeaderItem>
            ) : null
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <TransactionRow
            key={row.id}
            id={row.id}
            rowData={row}
            columnHeaderModel={columnHeaderModel}
            onDocumentClick={onDocumentClick}
          />
        ))}
      </tbody>
    </TableStyled>
  );
};

export default TransactionTable;
