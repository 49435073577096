import { gql } from '@apollo/client';
import DOCUMENT_FIELDS from '../../../graphql/fragments/DocumentFields.fragment';

export const GET_ZIPPED_EXPORT = gql`
  mutation GetZippedFileExport($files: [NamedEntityInput!]!, $accountId: ID!) {
    getZippedFileExport(input: { files: $files, accountId: $accountId })
  }
`;

export const GET_DOCUMENTS_FILTER = gql`
  query GetDocumentsPageFilter($accountId: ID!, $field: String!, $library: String, $category: String) {
    getDocumentsPageFilter(accountId: $accountId, field: $field, library: $library, category: $category) {
      label
      value
    }
  }
`;

export const GET_DOCUMENTS_PAGE = gql`
  query GetDocumentsPage(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getDocumentsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FIELDS}
`;
