import { MutationHookOptions, useMutation } from '@apollo/client';
import { MutationDeleteAspectOptionArgs } from '@rio/rio-types';
import { DeleteAspectOptionResponse } from './types';
import { DELETE_ASPECT_OPTION } from './index.queries';
import { useNotification } from '../../../hooks';

export function useDeleteAspectOption(
  options: MutationHookOptions<DeleteAspectOptionResponse, MutationDeleteAspectOptionArgs> = {}
) {
  const { showNotification } = useNotification();
  return useMutation<DeleteAspectOptionResponse, MutationDeleteAspectOptionArgs>(DELETE_ASPECT_OPTION, {
    onCompleted: (...args) => {
      showNotification('Deleted', 'success');
      if (options.onCompleted) {
        options.onCompleted(...args);
      }
    },
    onError: (error) => {
      showNotification(error.message, 'danger');
      if (options.onError) {
        options.onError(error);
      }
    },
  });
}
