import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { MarkdownSynopsis } from '../../../../../components/ManagementSystemChapterLayout';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';
import { useNotification } from '../../../../../hooks';
import { ManagementSystemPage } from '../../../../../types';
import { CarbonDisclosureFrameworkOverviewForm } from '../components/CarbonDisclosureFrameworkOverviewForm';

interface CarbonDisclosureFrameworkOverviewProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureFrameworkOverview({ page, framework }: CarbonDisclosureFrameworkOverviewProps) {
  const { showNotification } = useNotification();
  const buttons = useMemo(
    () => [
      {
        title: 'Generate report',
        color: 'primary',
        onClick: () => {
          showNotification('Not implemented', 'warning');
        }
      }
    ],
    [showNotification]
  );

  const synopsis = useMemo(
    (): MarkdownSynopsis => ({
      mode: 'markdown',
      content: page.synopsis,
      editable: false
    }),
    [page.synopsis]
  );

  const content = useMemo(
    () => ({
      header: 'Scoring scheme and parameters:',
      render: () => <CarbonDisclosureFrameworkOverviewForm />
    }),
    []
  );

  return (
    <ReportingFrameworkLayout
      framework={framework}
      page={page}
      buttons={buttons}
      synopsis={synopsis}
      content={content}
    />
  );
}
