import { Text, ProfilePicture, ProfilePictureCluster } from 'rio-ui-components';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function OwnersCellComponent(props) {
  const {
    data: { owners = [] } = {},
    intl: { formatMessage }
  } = props;

  const categoryColor = {
    GOVERNANCE: 'tertiary',
    DATA: 'quaternary',
    REPORTING: 'quaternary',
    DOCUMENTS: 'quaternary',
    ENGAGE: 'secondary'
  };

  const ownerNames = owners.length
    ? owners
        .slice()
        .reverse()
        .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
        .join(', ')
    : [];

  const pictures = owners
    .slice()
    .reverse()
    .map((owner) => {
      return (
        <ProfilePicture
          key={owner.profilePicture + owner.first_name}
          src={owner.profilePicture}
          accountName={owner.first_name}
        />
      );
    });

  return (
    <>
      {!!owners.length && (
        <ProfileContainer>
          <ProfilePictureCluster title={ownerNames} outline size="xxs" color={categoryColor[props.data.category]}>
            {pictures}
          </ProfilePictureCluster>
        </ProfileContainer>
      )}
      {!owners.length && <Text align="left">{formatMessage({ id: 'pages.task.grid.column.emptyOwners' })}</Text>}
    </>
  );
}

export const OwnersCell = injectIntl(OwnersCellComponent);
