"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToast = void 0;
const notistack_1 = require("notistack");
const react_1 = require("react");
const useToast = () => {
    const { enqueueSnackbar, closeSnackbar } = (0, notistack_1.useSnackbar)();
    const showToast = (0, react_1.useCallback)((message, color) => {
        const snackKey = enqueueSnackbar(message, { variant: 'rioToastV2', color });
        // This is more accurate than autoHideDuration
        if (snackKey) {
            setTimeout(() => {
                closeSnackbar(snackKey);
            }, 5000);
        }
    }, []);
    return (0, react_1.useMemo)(() => ({ showToast }), [showToast]);
};
exports.useToast = useToast;
