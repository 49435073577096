import { memo } from 'react';
import { Survey } from '@rio/rio-types';
import { Heading3 } from './styles';
import { isSubmissionReviewable } from '../../../components/Surveys';
import { SurveyContributorsResponseGrid } from './SurveyContributorsResponseGrid';
import { calculateScores, sumPerformancePercent } from './charts/calculateScores';

interface SurveyContributorsProps {
  survey: Survey;
}
interface DefaultContributorsProps extends SurveyContributorsProps {
  showPerformance?: boolean;
}

function DefaultContributors({ survey, showPerformance = false }: DefaultContributorsProps) {
  const { contributors, submissions } = survey;

  const { submissionScores, totalCategoryScores } = calculateScores(survey);

  const submissionInfo = contributors.map((contributor) => {
    const submission = submissions.find((s) => s.owner.id === contributor.account.id);
    const reviewable = isSubmissionReviewable(submission?.status);
    const performace = submission ? sumPerformancePercent(submissionScores, totalCategoryScores, submission.id) : null;

    return {
      contributor,
      submission,
      reviewable,
      performace,
    };
  });

  return (
    <>
      <Heading3>Response by contributor</Heading3>
      <SurveyContributorsResponseGrid submissionDetails={submissionInfo} showPerforance={showPerformance} />
    </>
  );
}

function AutomatedScoringContributors({ survey }: SurveyContributorsProps) {
  return <DefaultContributors survey={survey} showPerformance />;
}

export const SurveyContributors = memo(function SurveyContributors({ survey }: SurveyContributorsProps) {
  const { automatedScoring } = survey;
  if (automatedScoring) {
    return <AutomatedScoringContributors survey={survey} />;
  }
  return <DefaultContributors survey={survey} />;
});
