import React from 'react';
import { Icon } from 'rio-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.text.light.text};
  ${({ noAccess }) => (noAccess ? 'opacity: 0.3;' : '')}
`;

const Message = styled.div`
  padding: 10px;
  font-size: ${({ theme }) => theme.fonts.lg.size};
`;

const ThumbsUp = styled(Icon)`
  color: rgba(0, 0, 0, 0.3);
  margin: ${({ theme }) => theme.geometry.md.spacing};
`;

const NoOutstandingActions = ({ noAccess }) => {
  return (
    <Container noAccess={noAccess}>
      <ThumbsUp icon="thumbs-up" size="4x" />
      <Message>Nice, no outstanding actions</Message>
    </Container>
  );
};

export default NoOutstandingActions;
