import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useCallback } from 'react';
import { truncate } from 'lodash';
import * as Sentry from '@sentry/react';
import { useAgGrid, useNotification } from '../../../../hooks';
import { formatDate, mapFilterModel, mapSortModel, defaultFilterParams, gridValueFormatter } from '../../../../utils';
import { GET_DOCUMENTS_FILTER } from '../index.queries';
import { AntivirusStatusCell } from './AntivirusStatusCell';
import { ReviewStatusCell } from './ReviewStatusCell';
import { CLEAN, S3 } from '~/constants';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { CellStyle, RowModelType } from 'ag-grid-enterprise';

const MAX_NOTES_LENGTH = 45;

export function Grid({ fetchRows, accountId, setGridApi, library, category }) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const agGrid = useAgGrid();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params) => {
      try {
        const {
          data: { getDocumentsPageFilter: values },
        } = await client.query({
          query: GET_DOCUMENTS_FILTER,
          variables: {
            accountId,
            field: params.colDef.colId,
            library: library || null,
            category: category || null,
          },
        });
        params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
        Sentry.captureException(err);
      }
    },
    [accountId, client, showNotification, library, category]
  );

  const datasource = useMemo(
    () => ({
      async getRows(params) {
        try {
          const { startRow, sortModel, filterModel } = params.request;

          const variables = {
            accountId,
            offset: startRow,
            limit: 25,
            sort: mapSortModel(sortModel),
            filters: mapFilterModel(filterModel),
          };

          const { rows, totalRows } = await fetchRows(variables);
          params.success({
            rowData: rows,
            rowCount: totalRows,
          });
        } catch (err) {
          Sentry.captureException(err);
          params.fail();
        }
      },
    }),
    [accountId, fetchRows]
  );

  const options: AgGridReactProps = useMemo(
    () => ({
      pagination: true,
      paginationPageSize: 25,
      paginationPageSizeSelector: false,
      rowModelType: 'serverSide' as RowModelType,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        autoHeight: true,
        cellDataType: false,
      },
      columnDefs: [
        {
          headerName: 'Export',
          field: 'export',
          minWidth: 60,
          checkboxSelection: true,
          filter: false,
          sortable: false,
        },
        {
          colId: 'fileName',
          headerName: 'File Name',
          filter: 'agTextColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          field: 'fileName',
        },
        {
          colId: 'reviewDateStatus',
          headerName: 'Review Date Status',
          field: 'reviewDateStatus',
          filter: 'agSetColumnFilter',
          cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' } as CellStyle,
          cellRenderer: 'reviewStatus',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'reviewDate',
          headerName: 'Review Date',
          field: 'reviewDate',
          filter: 'agDateColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          valueFormatter: ({ value }) => formatDate(value),
        },
        {
          colId: 'referenceId',
          headerName: 'ID',
          field: 'referenceId',
          filter: 'agTextColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
        },
        {
          colId: 'createdByName',
          headerName: 'Uploaded By',
          field: 'createdByName',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'createdAt',
          headerName: 'Uploaded On',
          field: 'createdAt',
          filter: 'agDateColumnFilter',
          valueFormatter: ({ value }) => formatDate(value),
          filterParams: {
            ...defaultFilterParams(),
          },
          initialSort: 'desc',
        },
        {
          colId: 'updatedAt',
          headerName: 'Last Updated On',
          field: 'updatedAt',
          filter: 'agDateColumnFilter',
          filterParams: {
            ...defaultFilterParams(),
          },
          valueFormatter: ({ value }) => formatDate(value),
        },
        {
          colId: 'updatedByName',
          headerName: 'Updated By',
          field: 'updatedByName',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'antivirusStatus',
          headerName: 'Antivirus Status',
          field: 'antivirusStatus',
          cellRenderer: 'antivirusStatus',
          cellStyle: { textAlign: 'center', verticalAlign: 'middle' },
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'category',
          headerName: 'Category',
          field: 'category',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'library',
          headerName: 'Library',
          field: 'library',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'supplierName',
          headerName: 'Supplier',
          field: 'supplierName',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          colId: 'tags',
          headerName: 'Tags',
          field: 'tags',
          valueFormatter: ({ value }) => {
            return value ? value.map((tag) => tag.tagName).join(', ') : '';
          },
          filter: 'agSetColumnFilter',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          sortable: false,
        },
        {
          colId: 'notes',
          headerName: 'Notes',
          field: 'notes',
          filter: 'agTextColumnFilter',
          autoHeight: false,
          filterParams: {
            ...defaultFilterParams(),
          },
          valueFormatter: ({ value }) => {
            return value ? truncate(value, { length: MAX_NOTES_LENGTH }) : '';
          },
          sortable: false,
        },
        {
          colId: 'notes1',
          headerName: 'Notes1',
          field: 'notes1',
          filter: true,
          filterParams: {
            ...defaultFilterParams(),
            values: ['Bill', 'Sales Order', 'Licences and Permits'],
          },
        },
      ],
      rowStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      isRowSelectable: (rowNode) =>
        rowNode.data ? rowNode.data.antivirusStatus === CLEAN && rowNode.data.source === S3 : false,
    }),
    [getFilterValues]
  );

  useEffect(() => {
    if (agGrid.api) {
      if (category) {
        const values = [JSON.stringify({ __typename: 'FilterData', label: category, value: category })];

        try {
          // get filter instance
          agGrid.api.getColumnFilterInstance('category')?.then((instance) => {
            // use set filter instance
            instance!.setModel({ values })!.then(() => {
              agGrid!.api!.onFilterChanged('columnFilter');
            });
          });
        } catch {
          showNotification(`Couldn't set filter for category "${category}"`, 'warning');
        }
      }
    }
  }, [agGrid, agGrid.api, category, showNotification]);

  const components = useMemo(
    () => ({
      antivirusStatus: AntivirusStatusCell,
      reviewStatus: ReviewStatusCell,
    }),
    []
  );

  const onCellClicked = useCallback(
    (e) => {
      const basePath = library && category ? `/documents/${library}/${category}` : `/documents/all`;
      navigate(`${basePath}/${e.data.id}`);
    },
    [category, library, navigate]
  );

  const onGridReady = useCallback(
    (params) => {
      agGrid.onGridReady(params);
      setGridApi({ api: params.api, datasource, columnApi: params.columnApi });
    },
    [agGrid, datasource, setGridApi]
  );

  return (
    <div className="ag-theme-alpine" style={{ height: '100vh' }}>
      <AgGridReact
        cacheBlockSize={options.paginationPageSize}
        key={`${category}-${library}`}
        components={components}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        onCellClicked={onCellClicked}
        serverSideDatasource={datasource}
        onGridReady={onGridReady}
        {...options}
      />
    </div>
  );
}
