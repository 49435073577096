import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Text, Modal, TextLink } from 'rio-ui-components';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { useApolloClient, useMutation } from '@apollo/client';
import { DataImportBatch, TransactionType } from '@rio/rio-types';
import UploadProcess from '../../../components/UploadProcess';
import { ROLLBACK_BATCH, DELETE_BATCH, GET_BATCHES_PAGE, GET_DATA_BATCH_EXPORT_URL } from '../index.queries';
import { AG_GRID_PARAMS } from '../../../constants/common';
import { downloadFileFromUrl } from '../../../utils';
import { useCurrentAccountId, useNotification } from '../../../hooks';
import { DataFormModal } from '../../../components/DataFormModal';
import { BatchStatus } from '../../../types';
import { MassUploadTemplate } from '../../../components/DownloadTemplateButton';
import UploadsPageHeader from './PageHeader';
import ConfirmRollbackModal from './ConfirmRollbackModal';
import { OcrUpload } from './ocrUpload';
import { QuickViewModal } from './QuickViewModal';
import DeleteRowModal from './DeleteRowModal';
import { BatchErrors } from './BatchErrors';
import { Grid } from './Grid';
import { DromoConfiguration } from '../DromoConfiguration';
import { StatusProps } from './GridCell/StatusCell';
import { ActionFunction } from './types';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TextLinkStyled = styled(TextLink)`
  opacity: unset;
  font-size: 14px;
  font-family: unset;
`;

const TextStyled = styled(Text)`
  opacity: unset;
  font-size: 14px;
  font-family: unset;
  text-decoration: none;
  color: unset;
  cursor: default;
`;

interface DataUploadProps {
  showInvoiceUpload?: boolean;
  template: MassUploadTemplate | MassUploadTemplate[];
  dataSection: TransactionType;
  dromoConfiguration?: DromoConfiguration;
}

const initialState = {
  pollForBatch: null,
  isFileUploadModalVisible: false,
  isDataFormModalVisible: false,
  showConfirmRollbackModal: false,
  showOcrModal: false,
  showErrorModal: {
    show: false,
    id: null,
  },
  deleteConfirmation: false,
  batchIdToDelete: null,
  currentPage: 1,
  downloading: {},
  rollbackFunction: () => {},
};

export type State = {
  pollForBatch: any;
  isFileUploadModalVisible: boolean;
  isDataFormModalVisible: boolean;
  showConfirmRollbackModal: boolean;
  showOcrModal: boolean;
  showErrorModal: {
    show: boolean;
    id: string | null;
  };
  deleteConfirmation: boolean;
  batchIdToDelete: string | null;
  currentPage: number;
  downloading: any;
  rollbackFunction: () => void;
};

const DataUpload = (props: DataUploadProps) => {
  const params = useParams();
  const match = { params };
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const [batches, setBatches] = useState<DataImportBatch[]>([]);
  const [gridApi, setGridApi] = useState<{ api: GridApi; columnApi: ColumnApi }>();
  const [quickViewModal, setQuickViewModal] = useState<string | null>(null);
  const [state, setState] = useState<State>(initialState);

  const { dataSection } = props;

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getDataImportBatchesPage: { rows, totalRows },
        },
      } = await client.query({
        query: GET_BATCHES_PAGE,
        variables: { ...variables, accountId: accountId, type: dataSection },
        fetchPolicy: 'network-only',
      });
      // need to have Batches into index as well for consistency
      setBatches(rows);

      return { rows, totalRows };
    },
    [accountId, client, dataSection, setBatches]
  );

  const refreshGrid = useCallback(() => gridApi?.api?.refreshServerSide({ purge: true }), [gridApi]);

  const [rollBack] = useMutation(ROLLBACK_BATCH, {
    onCompleted: () => {
      refreshGrid();
      setState({ ...state, showConfirmRollbackModal: false });
      showNotification('Batch roll back succeeded', 'success');
    },
    onError: () => {
      setState({ ...state, showConfirmRollbackModal: false });
      showNotification('Error rolling back batch', 'danger');
    },
  });

  const [deleteBatch] = useMutation(DELETE_BATCH, {
    onCompleted: () => {
      refreshGrid();
      showNotification('Batch deleted', 'success');
    },
    onError: () => {
      showNotification('Error deleting batch', 'danger');
    },
  });

  useEffect(() => {
    return () => {
      // clear filters after leaving the page
      localStorage.removeItem(AG_GRID_PARAMS);
    };
  }, []);

  const handleUploadComplete = () => {
    refreshGrid();
    setState({ ...state, isFileUploadModalVisible: false });
  };

  const handleOnDataFormUploadComplete = () => {
    handleUploadComplete();
    showNotification('Upload has completed', 'success');
  };

  const handleOnDataFormUploadError = () => {
    showNotification('Upload has failed', 'danger');
  };

  const handleRollbackClick = (batchId: string) => {
    setState({
      ...state,
      showConfirmRollbackModal: true,
      rollbackFunction: () => {
        rollBack({ variables: { id: batchId } });
        showNotification('Batch roll back has been started', 'success');
      },
    });
  };

  const handleDeleteAttemptClick = (id: string) => {
    setState({
      ...state,
      deleteConfirmation: true,
      batchIdToDelete: id,
    });
  };

  const handleDeleteClick = () => {
    deleteBatch({ variables: { id: state.batchIdToDelete } });
    showNotification('Batch deletion has been started', 'success');
  };

  const onDeleteModalConfirmClick = () => {
    setTimeout(handleDeleteClick, 300);
    setState({ ...state, deleteConfirmation: false });
  };

  const onDeleteModalCancelClick = () => {
    setState({ ...state, deleteConfirmation: false });
  };

  const handleOnDismissModal = () =>
    setState({
      ...state,
      isFileUploadModalVisible: false,
      isDataFormModalVisible: false,
      showConfirmRollbackModal: false,
      showOcrModal: false,
    });

  const handleActionRequiredClick = (id) => navigate(`${location.pathname}/alias/${id}`);

  const handleErrorClick = (id) => {
    setState({ ...state, showErrorModal: { show: true, id } });
  };

  const showDirectDataUploadModal = () =>
    setState({
      ...state,
      isFileUploadModalVisible: false,
      isDataFormModalVisible: true,
    });

  const onFileUploadClick = () =>
    setState({
      ...state,
      isFileUploadModalVisible: true,
      isDataFormModalVisible: false,
    });

  const handleDataClick = async (id) => {
    setState((prevState) => ({
      ...state,
      downloading: { ...prevState.downloading, [id]: true },
    }));

    try {
      const { data } = await client.query({
        query: GET_DATA_BATCH_EXPORT_URL,
        variables: { id },
      });

      downloadFileFromUrl(data.getDataImportBatchExportUrl);
    } catch (error) {
    } finally {
      setState((prevState) => {
        return {
          ...state,
          downloading: { ...prevState.downloading, [id]: false },
        };
      });
    }
  };

  const checkPermissions = (permissions, action, isOwnResource) =>
    !!permissions.data.find((a) => a.startsWith(action) && !a.endsWith('Own')) ||
    (permissions.data.includes(`${action}Own`) && isOwnResource);

  const textLinkRequired = (status, legacy) =>
    (status === BatchStatus.DONE || status === BatchStatus.FAILED || status === BatchStatus.ACTION_REQUIRED) && !legacy;

  const defineStatusProps = (
    status: BatchStatus,
    theme: any,
    legacy: boolean,
    onDataClick: StatusProps['onClick'],
    onErrorClick: StatusProps['onClick'],
    onActionClick: StatusProps['onClick']
  ): StatusProps => {
    switch (status) {
      case BatchStatus.DONE:
        return {
          icon: 'check',
          iconColor: theme.colors.success.normal.background,
          linkText: legacy ? 'Migrated' : 'Export Data',
          onClick: onDataClick,
        };
      case BatchStatus.PENDING:
        return {
          icon: 'clock',
          iconColor: theme.colors.text.normal.background,
          linkText: 'In Progress',
        };
      case BatchStatus.ABANDONED:
        return {
          icon: 'undo',
          iconColor: theme.colors.neutral.dark.background,
          linkText: 'Rolled Back',
        };
      case BatchStatus.FAILED:
        return {
          icon: 'minus-circle',
          iconColor: theme.colors.danger.normal.background,
          linkText: `View Errors`,
          onClick: onErrorClick,
        };
      case BatchStatus.ACTION_REQUIRED:
        return {
          icon: 'exclamation-circle',
          iconColor: theme.colors.warning.normal.background,
          linkText: 'Action Required',
          onClick: onActionClick,
        };

      default:
        return {
          icon: '',
          iconColor: '',
          linkText: '',
        };
    }
  };

  const getReadOnlyStatusText = (status, defaultText) => {
    switch (status) {
      case BatchStatus.DONE:
        return 'Success';
      case BatchStatus.FAILED:
        return 'Error';
      default:
        return defaultText;
    }
  };

  const BatchStatusLink = (
    status: BatchStatus,
    legacy: boolean,
    name: string,
    onErrorClick: ActionFunction,
    onDataClick: ActionFunction,
    onActionClick: ActionFunction,
    statusProps: StatusProps,
    download: boolean,
    permissions: any,
    isOwnResource: boolean
  ) => {
    const allowedToExport = checkPermissions(permissions, 'exportImportBatch', isOwnResource);
    const allowedToEdit = checkPermissions(permissions, 'editImportBatch', isOwnResource);
    const showExport = status === BatchStatus.DONE && allowedToExport;
    const showActionRequired = status === BatchStatus.ACTION_REQUIRED && allowedToEdit;
    const showError = status === BatchStatus.FAILED;

    if (textLinkRequired(status, legacy) && (showExport || showActionRequired || showError)) {
      return (
        <TextLinkStyled
          name={`${name}__statusLink`}
          component="a"
          disabled={download}
          onClick={
            (status === BatchStatus.FAILED && onErrorClick) ||
            (status === BatchStatus.DONE && onDataClick) ||
            (status === BatchStatus.ACTION_REQUIRED && onActionClick)
          }
        >
          {download ? 'Downloading' : statusProps?.linkText}
        </TextLinkStyled>
      );
    }

    return <TextStyled name={`${name}__statusText`}>{getReadOnlyStatusText(status, statusProps?.linkText)}</TextStyled>;
  };

  const RollBackStatusLink = (id: string) => {
    return (
      <TextLink name={`Uploads__rollBackLink`} onClick={() => handleRollbackClick(id)}>
        Roll Back
      </TextLink>
    );
  };

  return (
    <OuterContainer>
      <UploadsPageHeader
        dataSection={dataSection}
        match={match}
        onDirectUploadClick={showDirectDataUploadModal}
        onFileUploadClick={onFileUploadClick}
        onOcrClick={() => setState({ ...state, showOcrModal: true })}
        allowOcr={props.showInvoiceUpload}
        gridApi={gridApi}
      />

      <ContentContainer>
        <UploadContainer>
          {state.isFileUploadModalVisible && (
            <UploadProcess
              onComplete={() => {
                handleUploadComplete();
                showNotification('Upload started! Please check back for progress...', 'success');
              }}
              template={props.template}
              transactionType={dataSection}
              onDismiss={handleOnDismissModal}
            />
          )}

          {state.isDataFormModalVisible && (
            <DataFormModal
              onComplete={handleOnDataFormUploadComplete}
              onError={handleOnDataFormUploadError}
              onDismiss={handleOnDismissModal}
              dataSection={dataSection}
            />
          )}

          {state.showOcrModal && (
            <Modal show size="lg" onDismiss={handleOnDismissModal}>
              <OcrUpload
                dataSection={props.dataSection}
                onComplete={(message, colour) => {
                  showNotification(message, colour);
                  setState({ ...state, showOcrModal: false });
                }}
              />
            </Modal>
          )}

          {state.showConfirmRollbackModal && (
            <ConfirmRollbackModal onDismiss={handleOnDismissModal} rollBackFunction={state.rollbackFunction} />
          )}

          {state.deleteConfirmation && (
            <DeleteRowModal onConfirmClick={onDeleteModalConfirmClick} onDismissClick={onDeleteModalCancelClick} />
          )}

          {state.showErrorModal?.show && state.showErrorModal?.id && (
            <Modal
              size="lg"
              height="auto"
              maxHeight="90vh"
              show
              onDismiss={() => setState({ ...state, showErrorModal: { show: false, id: null } })}
            >
              <BatchErrors batchId={state.showErrorModal.id} />
            </Modal>
          )}

          {quickViewModal && (
            <QuickViewModal
              batch={batches.find((b: DataImportBatch) => b.id === quickViewModal)!}
              uploadType={dataSection}
              onDismiss={() => {
                setQuickViewModal(null);
              }}
              refetchBatches={() => {
                client.refetchQueries({ include: [GET_BATCHES_PAGE] });
              }}
              accountId={accountId}
            />
          )}

          <ContainerStyled>
            <ContentContainer>
              <Grid
                dataSection={dataSection}
                download={state.downloading}
                fetchRows={fetchRows}
                setGridApi={setGridApi}
                gridApi={gridApi}
                defineStatusProps={defineStatusProps}
                statusLink={BatchStatusLink}
                rollBackStatusLink={RollBackStatusLink}
                onDataClick={handleDataClick}
                onErrorClick={handleErrorClick}
                onQuickViewClick={setQuickViewModal}
                onRollBackClick={handleRollbackClick}
                onDeleteClick={handleDeleteAttemptClick}
                onActionClick={handleActionRequiredClick}
              />
            </ContentContainer>
          </ContainerStyled>
        </UploadContainer>
      </ContentContainer>
    </OuterContainer>
  );
};

export default DataUpload;
