import { useCallback } from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment, { Moment } from 'moment';
import { DateQuestion } from '@rio/rio-types';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { AutocompleteField } from 'rio-ui-components';
import { Nullable } from '../../types';
import { AnswerQuestionFunction } from './types';

interface ChoiceQuestionSelectProps {
  question: DateQuestion;
  onAnswer: AnswerQuestionFunction;
}

export function DateQuestionField({ question, onAnswer }: ChoiceQuestionSelectProps) {
  const handleChange = useCallback(
    (answer: Nullable<Moment>) => {
      onAnswer({
        ...question,
        reportedDate: answer?.toISOString() || null
      });
    },
    [question, onAnswer]
  );
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label={question.formulation}
        value={question.reportedDate ? moment(question.reportedDate) : null}
        onChange={handleChange}
        renderInput={(params) => (
          <AutocompleteField
            {...params}
            label={question.formulation}
            placeholder={question.shortFormulation || question.formulation}
          />
        )}
      />
    </LocalizationProvider>
  );
}
