import { Query } from '@rio/rio-types';
import { GET_HOME_PAGE_LEARN_PROGRESS_DATA } from './index.queries';
import { usePageSuspendingQuery } from '~/hooks';

type Response = Pick<Query, 'getLearnProgressLogByUserId'>;

type Variables = {
  userId: string;
  continueLearningLimit: number;
};

export function useGetHomePageLearningProgressData({ continueLearningLimit, userId }: Variables) {
  return usePageSuspendingQuery<Response, Variables>(GET_HOME_PAGE_LEARN_PROGRESS_DATA, {
    variables: {
      userId,
      continueLearningLimit,
    },
  });
}
