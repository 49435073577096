import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Modal, Col, Notification } from 'rio-ui-components';
import { Query } from '@apollo/client/react/components';

import { SET_PAGE, GET_SEARCH, SET_SEARCH } from './index.queries';

import withPermissions from '../../../hocs/withPermissions';

import AccountSelector from '../../../components/AccountSelector';
import ContainerHeader from '../../../components/ContainerHeader';

import CreateWasteStream from './CreateWasteStream';
import UpdateWasteStream from './UpdateWasteStream';
import SearchContainer from '../../../components/SearchContainer';
import WasteStreamGrid from './WasteStreamGrid';

const SelectContainer = styled.div`
  padding: ${(p) => p.theme.geometry.md.spacing};
`;

const SearchStyled = styled(SearchContainer)`
  flex: 1;
  margin-right: ${(p) => p.theme.geometry.md.spacing};
`;

class ConfigurationWasteStreamContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: { id: props.accountId },
      createModal: { show: false },
      updateModal: { id: '', name: '', ewc: '' },
      notification: { message: null, color: null, id: null },
      shouldRefetch: false
    };
  }

  dismissModals = (notification = {}, refetch = () => {}) =>
    this.setState(
      {
        createModal: { show: false },
        updateModal: { id: null, name: null, ewc: null },
        notification: { message: notification.message, color: notification.color, id: notification.id }
      },
      refetch
    );

  showModal = (type, id, name, ewc) => {
    if (type === 'create') return this.setState({ createModal: { show: true } });
    if (type === 'update') return this.setState({ updateModal: { id, name, ewc } });
  };

  hideNotification = () => this.setState({ notification: { message: null, color: null, id: null } });
  handleAccountChange = (selected) => this.setState({ account: { id: selected.id } });
  setShouldRefetch = () => this.setState({ shouldRefetch: true });
  onRefetch = () => this.setState({ shouldRefetch: false });

  renderModals = (refetchFn) => {
    const { notification, createModal, updateModal, account } = this.state;

    return (
      <Fragment>
        {notification.message && (
          <Notification
            name="ConfigurationWasteStreamsContainer__Notification"
            show
            color="success"
            onClose={this.hideNotification}
          >
            {notification.message}
            <div hidden name="ConfigurationWasteStreamsContainer__Notification__Id">
              {notification.id}
            </div>
          </Notification>
        )}
        {createModal.show && (
          <Modal
            size="sm"
            show
            dismissable
            name="ConfigurationWasteStreamsContainer__Modal--create"
            onDismiss={this.dismissModals}
            height="auto"
            maxHeight="90vh"
          >
            <CreateWasteStream
              onSuccess={(message, color, id) => this.dismissModals({ message, color, id }, refetchFn)}
              accountId={account.id}
            />
          </Modal>
        )}
        {updateModal.id && (
          <Modal
            size="sm"
            show
            dismissable
            name="ConfigurationUsersContainer__Modal--update"
            onDismiss={this.dismissModals}
            height="auto"
            maxHeight="90vh"
          >
            <UpdateWasteStream
              accountId={account.id}
              streamName={updateModal.name}
              streamId={updateModal.id}
              streamEwc={updateModal.ewc}
              onSuccess={(message, color) => this.dismissModals({ message, color }, refetchFn)}
            />
          </Modal>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Col name="ConfigurationWasteStreamsContainer" container fullHeight>
        {this.renderModals(this.setShouldRefetch)}
        <ContainerHeader
          name="ConfigurationWasteStreamsContainer__Controls"
          icon="trash-alt"
          iconColor="primary"
          title="Waste Streams"
        >
          <SearchStyled setPageMutation={SET_PAGE} setSearchMutation={SET_SEARCH} />
          <Button
            name="ConfigurationWasteStreamsContainer__Controls__Button--add"
            color="primary"
            inline
            component="button"
            onClick={() => this.showModal('create')}
          >
            + Add Waste Stream
          </Button>
        </ContainerHeader>

        {this.props.permissions.data.includes('getWasteStreamsAll') && (
          <SelectContainer>
            <AccountSelector
              onChange={this.handleAccountChange}
              value={this.state.account.id}
              name="ConfigurationWasteStreamsContainer__AccountSelector"
            />
          </SelectContainer>
        )}

        <Query query={GET_SEARCH}>
          {({ data: { wasteStreamGridSearch } }) => {
            const queryVariables = {
              accId: this.state.account.id,
              page: 1,
              pageSize: 200,
              filterValue: wasteStreamGridSearch || null
            };
            return (
              <WasteStreamGrid
                queryVariables={queryVariables}
                shouldRefetch={this.state.shouldRefetch}
                onRefetch={this.onRefetch}
                showModal={this.showModal}
              />
            );
          }}
        </Query>
      </Col>
    );
  }
}

export default withPermissions(ConfigurationWasteStreamContainer);
