import { SurveyQuestionInput } from '@rio/rio-types';
import { Errors } from '../../../types';

type Invalid = [Errors<SurveyQuestionInput>, null];

type Valid = [null, SurveyQuestionInput];

export function validate(question: Partial<SurveyQuestionInput>): Valid | Invalid {
  const errors: Errors<SurveyQuestionInput> = {};

  if (!question.category) {
    errors.category = 'Category is required';
  }
  if (Object.keys(errors).length) {
    return [errors, null];
  }
  return [null, question as SurveyQuestionInput];
}
