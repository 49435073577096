import { useMutation } from '@apollo/client';
import React from 'react';
import { ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM } from '../index.queries';
import { UploadDocumentModal } from '../DocumentModal';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { makeManagementSystemCode } from '../../../utils/managementSystemUtils';

export function AttachDocumentsModal({ onDismiss, onError, onComplete, managementSystem, chapter, refetch }) {
  const accountId = useCurrentAccountId();
  const [attachDocumentToManagementSystem] = useMutation(ATTACH_DOCUMENT_TO_MANAGEMENT_SYSTEM);
  return (
    <UploadDocumentModal
      predefinedValues={{
        library: 'GOVERNANCE',
        category: 'Management Systems',
        managementSystemCode: makeManagementSystemCode(managementSystem, chapter || managementSystem)
      }}
      onDismiss={onDismiss}
      onError={onError}
      onComplete={async (message, uploadedDocuments, errors) => {
        if (errors.length) {
          return onError(new Error(`${errors.length} of your documents failed to upload and thus weren't attached.`));
        }
        await Promise.all(
          uploadedDocuments.map(async (document) => {
            try {
              await attachDocumentToManagementSystem({
                variables: {
                  accountId,
                  documentId: document.id,
                  systemId: managementSystem.id,
                  linkId: chapter ? chapter.id : managementSystem.id
                }
              });
            } catch (error) {
              onError(error);
            }
          })
        );
        onComplete(`Documents have been successfully attached.`);
        refetch();
      }}
    />
  );
}
