import { useQuery } from '@apollo/client';
import { Select } from 'rio-ui-components';
import { GET_ALL_ENERGY_TYPES } from './index.queries';

export type EnergySourcesSelectionProps = {
  onChange: React.SelectHTMLAttributes<HTMLSelectElement>['onChange'];
  value: { value: { id: string; name: string }; label: string };
  error: string | null;
};

export const EnergySourcesSelection = ({ onChange, value, error }: EnergySourcesSelectionProps) => {
  const { data, loading } = useQuery(GET_ALL_ENERGY_TYPES);
  if (loading) {
    return <Select isDisabled placeholder="Loading" />;
  }
  if (error || !data || !data.getAllEnergyType) {
    return <Select isDisabled placeholder="Something went wrong!" />;
  }
  const options = data.getAllEnergyType.map((et: any) => ({ value: { id: et.id, name: et.name }, label: et.name }));

  return <Select value={value} options={options} onChange={onChange} error={error} />;
};
