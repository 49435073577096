import React from 'react';
import PropTypes from 'prop-types';
import { getIconByFileExtension } from '../../utils/utils';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';

const IconStyled = styled(Icon)`
  color: ${(props) => props.theme.colors.text.normal.background};
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const FileIcon = ({ name, fileName, size = '2x', ...rest }) => {
  const icon = getIconByFileExtension(fileName);
  return <IconStyled name={`${name}__FileIcon`} size={size} icon={icon} {...rest} />;
};

FileIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  fileName: PropTypes.string
};

export default FileIcon;
