import { get } from 'lodash';
import { LocationState, PrefilledLocationPoints } from './type';

export const defaultState = (prefilledLocationPoints: PrefilledLocationPoints = {}): LocationState => {
  return {
    hasError: false,
    isSuccess: false,
    values: {
      locationId: { text: '' },
      locationName: { text: '', error: '' },
      address1: { text: '', error: '' },
      address2: { text: '', error: '' },
      address3: { text: '', error: '' },
      city: { text: '', error: '' },
      postcode: { text: '', error: '' },
      phone: { text: '', error: '' },
      selectCountry: null,
      locationPoints: {
        wasteOut: { checked: get(prefilledLocationPoints, 'wasteOut', false) },
        wasteIn: { checked: get(prefilledLocationPoints, 'wasteIn', false) },
      },
      tags: [],
      aliases: [],
    },
    isSubmitted: false,
  };
};
