import { gql } from '@apollo/client';

export const GET_PARENT_ACCOUNT_AND_CHILDREN = gql`
  query MyQuery($accountId: ID!) {
    getAccountInfo(id: $accountId) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;

export const GET_USERS_FOR_ACCOUNTS = gql`
  query GET_USERS_FOR_ACCOUNTS($accountId: String!, $childrenIds: [String!]!) {
    getUsers(
      accountId: $accountId
      filters: { set: [{ field: "accountName", filterType: set, values: $childrenIds }] }
    ) {
      rows {
        id
        accountId
        firstName
        lastName
        email
      }
      totalRows
    }
  }
`;
