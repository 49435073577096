import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MutationAttachContentToManagementSystemArgs } from '@rio/rio-types';
import { ATTACH_CONTENT_TO_MANAGEMENT_SYSTEM } from './index.queries';
import { useNotification } from '../../hooks';

export function useAttachContentToManagementSystem() {
  const { showNotification } = useNotification();
  const handleComplete = useCallback(() => {
    showNotification('Synopsis saved', 'success');
  }, [showNotification]);
  const handleError = useCallback(() => {
    showNotification('Something went wrong. Please try again later.', 'danger');
  }, [showNotification]);
  return useMutation<boolean, MutationAttachContentToManagementSystemArgs>(ATTACH_CONTENT_TO_MANAGEMENT_SYSTEM, {
    onCompleted: handleComplete,
    onError: handleError,
    refetchQueries: ['GetManagementSystemById'],
  });
}
