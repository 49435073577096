import { Row } from 'rio-ui-components';
import styled from 'styled-components';

export const ControlPanel = styled(Row)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  *:not(:last-child) {
    margin-right: ${(props) => props.theme.geometry.xs.spacing};
  }
`;
