import { CourseProgress } from '@rio/rio-types';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DoneIcon from '@mui/icons-material/Done';

interface CourseProgressIconProps {
  status?: CourseProgress | null;
}
export function CourseProgressIcon({ status }: CourseProgressIconProps) {
  if (status === CourseProgress.Complete) {
    return <DoneIcon fontSize="small" color="primary" titleAccess="Complete" />;
  }
  if (status === CourseProgress.Inprogress) {
    return <AccessTimeFilledIcon fontSize="small" color="primary" titleAccess="In progress" />;
  }
  return null;
}
