import { Task } from '@rio/rio-types';
import { Link } from 'react-router-dom';
import { Text, styled, Icons, Button, Grid } from '@rio/ui-components';
import { BoxWrapperStyled, ContainerStyled } from './Reporting/style';

const TextStyled = styled(Text)`
  margin-bottom: 16px;
  color: ${(p) => p.theme.sys.color.onSurface};
`;

const TextContainer = styled('div')`
  width: 133px;
`;

const TaskCheck = styled('div')<{ completed: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${(p) => (p.completed ? 'none' : `1px solid ${p.theme.sys.color.outlineVariant}`)};
  background: ${(p) => (p.completed ? p.theme.extendedColors.environmental.color : p.theme.sys.color.surface)};
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: ${(p) => (p.completed ? 'inline-block' : 'none')};
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    color: ${(p) => p.theme.sys.color.onSurface};
  }
`;

const ButtonStyled = styled(Button)`
  width: 180px;
`;

const TaskTile = styled('div')`
  width: 183px;
  height: 97px;
  flex-shrink: 0;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.sys.color.outlineVariant};
  background: ${(p) => p.theme.sys.color.surface};
  box-shadow: ${(p) => p.theme.sys.elevation[1]};

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;

// filters tasks to those due in next 30 days - limits 5 task for home screen
const sortAndFilterTasks = (tasks: Task[] = []): Task[] => {
  const now = new Date().getTime();
  const thirtyDaysFromNow = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;

  const filteredTasks = tasks.filter((task) => {
    const dueDate = new Date(task.dueDate).getTime();
    return thirtyDaysFromNow > dueDate && dueDate > now;
  });

  return filteredTasks
    .sort(function (a, b) {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    })
    .slice(0, 5);
};

export const Tasks = ({ tasks }: { tasks: Task[] }) => {
  const tasksToRender = sortAndFilterTasks(tasks);

  return (
    <>
      {!!tasksToRender.length && (
        <Grid container>
          <TextStyled typescale="title" size="medium">
            Tasks
          </TextStyled>
          <BoxWrapperStyled>
            <ContainerStyled>
              {tasksToRender?.map((task) => (
                <Link key={task.id} to={`/v2/tasks/${task.id}`}>
                  <TaskTile>
                    <TaskCheck completed={task.isCompleted}>
                      <Icons.Check />
                    </TaskCheck>
                    <TextContainer>
                      <TextStyled typescale="body" size="medium">
                        {task.name}
                      </TextStyled>
                    </TextContainer>
                  </TaskTile>
                </Link>
              ))}
            </ContainerStyled>
          </BoxWrapperStyled>
          <ButtonStyled variant="text" to="/v2/tasks">
            <Icons.ArrowForward fontSize="small" />
            View All Tasks
          </ButtonStyled>
        </Grid>
      )}
    </>
  );
};
