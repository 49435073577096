import { useQuery } from '@apollo/client';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Row, Search, Col, Button } from 'rio-ui-components';
import { useDebouncedCallback } from 'use-debounce';
import { TaskSubject, Task, Aspect, ManagementSystem } from '@rio/rio-types';
import { NotAvailable } from '../../../components/Errors';
import { useUserToken } from '../../../hooks/useUserToken';
import { Nullable } from '../../../types';
import { ExportButton } from '../../../components/ExportButton';
import { ClearButton } from '../../../components/ClearButton';
import GET_TASKS_BY_ACCOUNT from '../../../graphql/queries/tasks/GetTasksByAccountId.query.graphql';
import { CreateTaskModal } from '../../TasksContainer/TasksGrid/CreateTaskModal';
import PageHeader, { DropdownItem } from '../../../components/PageHeader';
import { useNotification, useAccessControls, useCurrentAccountId, usePermissions, useAgGrid } from '../../../hooks';
import areas from '../areas';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS } from '../ManagementSystems/LandingPage/index.queries';
import { AspectsGrid } from './AspectsGrid';
import { useCreateAspect } from './useCreateAspect';
import { useEditAspectFromQueryParam } from './useEditAspectFromQueryParam';
import { mapAspectFilterModel, mapAspectSortModel, prepareAspectInput } from './utils';
import { AspectFormState } from './types';
import { useAspectSearchValueFromQueryParam } from './useAspectSearchValueFromQueryParam';
import { AspectCreatedNotification } from './AspectCreatedNotification';
import { ModalPortal } from './ModalPortal';
import { AspectModal } from './AspectModal';
import { useOptionToDeleteFromQueryParam } from './useOptionToDeleteFromQueryParam';
import { ReviewAspectsRow } from './ReviewAspectsRow';
import { CreateNewTaskSuggestionModal } from './CreateNewTaskSuggestionModal';
import { useSaveEvidenceNotes } from './useSaveEvidenceDocuments';
import { useGetAspectsByAccountId } from './useGetAspectsByAccountId';
import { ToastColor } from '@rio/ui-components';

const ControlsContainerStyled = styled(Row)`
  flex: 1;
  align-items: center;
  & * {
    margin-right: ${(props) => props.theme.geometry.xs.spacing};
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const Aspects = () => {
  const accountId = useCurrentAccountId();
  const { data: accessControls } = useAccessControls();
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [copyAspect, setCopyAspect] = useState<Nullable<Aspect>>(null);
  const [editAspect, setEditAspect] = useEditAspectFromQueryParam();
  const [createTaskSuggestionAspect, setShowCreateNewTaskSuggestionModal] = useState<Nullable<AspectFormState>>(null);
  const [createTaskAspect, setShowCreateNewTaskModal] = useState<Nullable<AspectFormState>>(null);
  const [createAspect] = useCreateAspect();
  const { token } = useUserToken();
  const getAspectsByAccountId = useGetAspectsByAccountId();
  const [aspectSearchValue, setAspectSearchValue] = useAspectSearchValueFromQueryParam();
  const customFilterModelMapper = useCallback(
    (filterModel) => mapAspectFilterModel(filterModel, aspectSearchValue),
    [aspectSearchValue]
  );
  const [optionToDelete, setOptionToDelete] = useOptionToDeleteFromQueryParam();
  const agGrid = useAgGrid();
  const modalPortalRef = useRef<HTMLDivElement>(null);
  const refreshGrid = useDebouncedCallback(() => {
    agGrid?.api?.refreshServerSide({
      purge: true,
    });
  }, 1000);
  useEffect(() => {
    if (optionToDelete) {
      if (optionToDelete?.isReviewingAspects) {
        refreshGrid();
      }
    } else {
      refreshGrid();
    }
  }, [aspectSearchValue, optionToDelete, refreshGrid]);
  const { data: tasks } = useQuery(GET_TASKS_BY_ACCOUNT, { variables: { accountId, userId: token.sub } });

  const { loading: managementSystemsLoading, data: managementSystemsData } = useQuery(
    GET_AVAILABLE_MANAGEMENT_SYSTEMS,
    {
      variables: { accountId },
      notifyOnNetworkStatusChange: true,
    }
  );

  const saveEvidenceNotes = useSaveEvidenceNotes();
  const saveAspect = async (aspect: AspectFormState, evidenceNotes: string, evidenceDocuments: any[]) => {
    try {
      const isUpdate = !!editAspect;

      const aspectEvidenceNotes = await saveEvidenceNotes(evidenceDocuments, evidenceNotes, aspect);

      await createAspect({
        variables: {
          input: prepareAspectInput(aspect, aspectEvidenceNotes, isUpdate, !!copyAspect, token),
        },
      });
      setShowCreateModal(false);
      setEditAspect('');
      setCopyAspect(null);

      if (!isUpdate || !tasks?.getTasksByAccountId?.find(({ item }: Task) => item?.id === editAspect?.id)) {
        setShowCreateNewTaskSuggestionModal(aspect);
      } else {
        refreshGrid();
      }
      showNotification(<AspectCreatedNotification aspectId={aspect.id} isUpdate={isUpdate} />, 'success', {
        autoClose: 20000,
      });
    } catch (e) {
      showNotification('Something went wrong, please try again or contact support', 'danger');
    }
  };

  const availableGovernancePages: DropdownItem[] = areas.filter(
    (area) => !area.comingSoon && accessControls.comply[area.accessIndex]
  );
  const title = 'Aspects & Impacts Register';
  availableGovernancePages.push({ title, link: '/governance/environmental-aspects' });

  if (
    !managementSystemsLoading &&
    !managementSystemsData.getAvailableManagementSystems.find(({ title: ttl }: ManagementSystem) =>
      ttl.includes('ISO14001')
    )
  ) {
    return <NotAvailable name={'Forbidden_Aspects'} error={new Error('Forbidden')} />;
  }
  const createTaskHandler = (shouldCreateTask: boolean) => {
    if (shouldCreateTask) {
      setShowCreateNewTaskModal(createTaskSuggestionAspect);
    } else {
      refreshGrid();
    }
    setShowCreateNewTaskSuggestionModal(null);
  };
  return (
    <Col container item>
      <ModalPortal ref={modalPortalRef} />
      {showCreateModal && (
        <AspectModal
          title="Create Aspect"
          submitTitle="Create Aspect"
          onDismiss={() => setShowCreateModal(false)}
          accountId={accountId}
          onSubmit={saveAspect}
          modalPortalRef={modalPortalRef}
        />
      )}
      {copyAspect && (
        <AspectModal
          title="Duplicate Aspect"
          submitTitle="Create Aspect"
          onDismiss={() => setCopyAspect(null)}
          accountId={accountId}
          onSubmit={saveAspect}
          values={{ ...copyAspect, id: undefined }}
          modalPortalRef={modalPortalRef}
          isCopy
        />
      )}
      {editAspect && (
        <AspectModal
          title="Edit Aspect"
          submitTitle="Save Aspect"
          onDismiss={() => setEditAspect('')}
          accountId={accountId}
          onSubmit={saveAspect}
          values={editAspect}
          modalPortalRef={modalPortalRef}
        />
      )}
      {!!createTaskSuggestionAspect && <CreateNewTaskSuggestionModal onConfirm={createTaskHandler} />}
      {!!createTaskAspect && (
        <CreateTaskModal
          accountId={accountId}
          onDismiss={() => setShowCreateNewTaskModal(null)}
          onComplete={(input) => {
            const message = input?.message;
            const colour = input?.colour;
            showNotification(message as string, colour as ToastColor);
          }}
          defaultValues={{
            scope: createTaskAspect.scope,
            tagIds: createTaskAspect.tagIds,
            locationIds: createTaskAspect.locationIds,
            category: 'GOVERNANCE',
            subject: 'ASPECTS',
            item: { id: createTaskAspect.id },
          }}
          disabled={{
            scope: true,
            category: true,
            subject: true,
            locations: true,
            tags: true,
            [TaskSubject.Aspects]: true,
          }}
        />
      )}
      <PageHeader
        name="Aspects"
        title="Aspects"
        breadcrumbs={[{ title: 'Governance', to: '..' }, { title }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown
        dropdownItems={availableGovernancePages}
        dropdownDefault={title}
      >
        <ControlsContainerStyled name="ConfigurationAspectContainer__Controls" container alignItems="center">
          <Col item>
            <Search
              name="ConfigurationAspectContainer__Controls__Search"
              value={aspectSearchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAspectSearchValue(event.target.value)}
              hideButton
            />
          </Col>
          <Col item>
            <ClearButton gridApi={agGrid.api} columnApi={agGrid.columnApi} />
          </Col>
          <Col item>
            <ExportButton
              step={100}
              fetchRows={getAspectsByAccountId}
              gridApi={agGrid.api}
              columnApi={agGrid.columnApi}
              defaultExportFileName="aspects"
              columnsToSkip={['Actions']}
              label="Export"
              customSortModelMapper={mapAspectSortModel}
              customFilterModelMapper={customFilterModelMapper}
            />
          </Col>
          {!!permissions.governance.find((action: string) => action.startsWith('createAspect')) && (
            <Col item>
              <ButtonStyled
                name="ConfigurationAspectContainer__Controls__Button--create"
                size="md"
                color="primary"
                inline
                onClick={() => setShowCreateModal(true)}
              >
                + Add Aspect
              </ButtonStyled>
            </Col>
          )}
        </ControlsContainerStyled>
      </PageHeader>
      {optionToDelete?.isReviewingAspects && (
        <ReviewAspectsRow
          optionToDelete={optionToDelete}
          accountId={accountId}
          onCancelReview={() => {
            setOptionToDelete(null);
          }}
        />
      )}
      <AspectsGrid
        showEditModal={setEditAspect}
        showCopyModal={setCopyAspect}
        canEdit={!!permissions.governance.find((action: string) => action.startsWith('createAspect'))}
        agGrid={agGrid}
      />
    </Col>
  );
};
