import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS_COUNT } from './index.queries';

export function useGetDocuments(variables: Partial<unknown>, skip?: boolean) {
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTS_COUNT, {
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all'
  });

  return {
    documentsExist: !!data?.getDocumentsPage?.totalRows,
    error,
    loading,
    refetch: () => refetch(variables)
  };
}
