import { useSubscription, useApolloClient } from '@apollo/client';
import { Subscription, SubscriptionSetSurveySubmissionUpdatedArgs, ReceivedSurvey } from '@rio/rio-types';
import { useEffect } from 'react';
import { SET_SURVEY_SUBMISSION_UPDATED } from '../queries';
import { GET_RECEIVED_SURVEYS_PAGE } from '../../ReceivedSurveysContainer/queries/queries';
import { GetReceivedSurveysPageResponse } from '../../ReceivedSurveysContainer/queries';

export const useSetSurveySubmissionUpdated = (surveyId: string, accountId: string) => {
  const client = useApolloClient();
  const { data } = useSubscription<
    Pick<Subscription, 'setSurveySubmissionUpdated'>,
    SubscriptionSetSurveySubmissionUpdatedArgs
  >(SET_SURVEY_SUBMISSION_UPDATED, {
    variables: { accountId, surveyId },
  });

  useEffect(() => {
    if (data?.setSurveySubmissionUpdated) {
      const submission = data.setSurveySubmissionUpdated;

      client.cache.updateQuery<GetReceivedSurveysPageResponse>(
        {
          query: GET_RECEIVED_SURVEYS_PAGE,
          variables: {
            accountId,
            surveyId,
          },
        },
        (_data) => {
          const surveys = _data?.getReceivedSurveysPage.rows || [];
          const surveyIdx = surveys.findIndex((c: ReceivedSurvey) => c.id === surveyId);

          if (surveyIdx === -1) {
            return _data;
          }

          return {
            getReceivedSurveysPage: {
              rows: [
                ...surveys.slice(0, surveyIdx),
                {
                  ...surveys[surveyIdx],
                  submission: submission,
                },
                ...surveys.slice(surveyIdx + 1),
              ],
              totalRows: surveys.length + 1,
            },
          };
        }
      );
    }
  }, [client.cache, data, surveyId, accountId]);

  return { subscriptionUpdate: !!data };
};
