import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      title
      message
      isVisible
    }
  }
`;
