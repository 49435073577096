import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getPathFromType } from '../../../../../utils/managementSystemUtils';

import ManagementSystemRow from './ManagementSystemRow';

const ResultsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background: ${(props) => props.theme.colors.basic.white};
  margin-top: ${(props) => props.theme.geometry.md.spacing};
`;

const ManagementSystemList = ({ items }) => {
  return (
    <ResultsContainer>
      {items.map((item) => {
        return (
          <Link
            name={`ManagementSystemRow__Link__${item.id}`}
            key={item.id}
            to={{ pathname: `${getPathFromType(item.type)}/${item.id}`, state: { title: item.title } }}
          >
            <ManagementSystemRow title={item.title} key={item.id} />
          </Link>
        );
      })}
    </ResultsContainer>
  );
};

export default ManagementSystemList;
