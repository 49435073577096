import { tryParseSpreadsheet, parseExcelDate } from '../../../utils/excelUtils';
import { v4 as uuid } from 'uuid';
import uniq from 'lodash/uniq';
import * as scopes from '../../../constants/scopes';
import { getIndexedTagsAndLocations } from '../../../utils/dataUtils';
import { CREATE_NORMALISERS } from './index.queries';

export function spreadsheetRowToNormaliserInput(row, accountId) {
  return {
    id: uuid(),
    accountId,
    name: row.Name,
    description: row.Description,
    quantity: row.Quantity,
    locationId: row.LocationId,
    startDate: row['Start Date'] ? row['Start Date'].toUTCString() : null,
    endDate: row['End Date'] ? row['End Date'].toUTCString() : null,
    tagId: row.scope.toUpperCase() === scopes.TAG ? row.tagId : null
  };
}

function getRowScope(row) {
  return typeof row.Scope === 'string' ? row.Scope.toUpperCase() : scopes.ACCOUNT;
}

function validateRows(rows, tagIndex, locationIndex, types) {
  const problems = [];
  if (!rows.length) {
    problems.push('Your spreadsheet is empty, please add some rows');
  }
  rows.forEach((row, rowIndex) => {
    const scope = row.Scope || '';
    const rowNumber = rowIndex + 2;
    const quantityParsed = parseInt(row.Quantity, 10);
    const isLocationNormaliser = scope.toUpperCase() === scopes.LOCATION;
    const isTagNormaliser = scope.toUpperCase() === scopes.TAG;
    const noLocation = !row.Location;
    const noTag = !row.Tag;
    const typeNames = types.map((t) => t.name);
    if (!row.Name) {
      problems.push(`Row ${rowNumber}: Name should be defined`);
    }
    if (!row.Description) {
      problems.push(`Row ${rowNumber}: Description should be defined`);
    }
    if (isLocationNormaliser && noLocation) {
      problems.push(`Row ${rowNumber}: Location should be defined if the scope of the normaliser is location`);
    }
    if (isTagNormaliser && noTag) {
      problems.push(`Row ${rowNumber}: Tag should be defined if the scope of the normaliser is tag`);
    }
    if (!(scope.toUpperCase() in scopes)) {
      problems.push(
        `Row ${rowNumber}: Column Scope should be one of: ${scopes.ACCOUNT}, ${scopes.LOCATION} or ${scopes.TAG}`
      );
    }
    if (isLocationNormaliser && !locationIndex[row.Location] && !noLocation) {
      problems.push(`Row ${rowNumber}: Location ${row.Location} wasn't found in your account`);
    }
    if (isTagNormaliser && !tagIndex[row.Tag] && !noTag) {
      problems.push(`Row ${rowNumber}: Tag ${row.Tag} wasn't found in your account`);
    }
    if (typeof quantityParsed !== 'number' || Number.isNaN(quantityParsed)) {
      problems.push(`Row ${rowNumber}: Quantity should be defined`);
    }
    if (!row.Type) {
      problems.push(`Row ${rowNumber}: Type should be defined and equal to one of: ${typeNames.join(', ')}`);
    }
    if (typeof row.Type === 'string' && !typeNames.find((name) => name.toLowerCase() === row.Type.toLowerCase())) {
      problems.push(`Row ${rowNumber}: Type is not valid, should be one of: ${typeNames.join(', ')}`);
    }
  });
  return problems;
}

export async function uploadNormalisers(file, client, accountId, types) {
  const [err, rows] = await tryParseSpreadsheet(file);
  if (err) {
    const parseProblems = ['The file you provided is not a valid XLSX, XLS or CSV file'];
    return [parseProblems, null];
  }
  const uniqLocations = uniq(rows.map((row) => row.Location));
  const { tags, locations } = await getIndexedTagsAndLocations(client, accountId, uniqLocations);
  const problems = validateRows(rows, tags, locations, types);
  if (problems.length) {
    return [problems, null];
  }
  const normalisers = rows.map((row) => {
    const scope = getRowScope(row);
    const location = locations[row.Location];
    const tag = tags[row.Tag];
    const locationId = scope === scopes.LOCATION && location ? location.id : null;
    const tagId = scope === scopes.TAG && tag ? tag.id : null;
    return {
      id: uuid(),
      accountId,
      locationId,
      tagId,
      name: row.Name,
      description: row.Description,
      quantity: parseInt(row.Quantity, 10),
      startDate: row['Start Date'] ? parseExcelDate(row['Start Date']).format('YYYY-MM-DD 00:00:00') : null,
      endDate: row['End Date'] ? parseExcelDate(row['End Date']).format('YYYY-MM-DD 23:59:59') : null,
      type: types.find(({ name }) => name.toLowerCase() === row.Type.toLowerCase()).id
    };
  });
  const result = await client.mutate({
    mutation: CREATE_NORMALISERS,
    variables: {
      normalisers
    }
  });
  return [problems, result];
}
