import React from 'react';
import { orderBy, keyBy } from 'lodash';

import SubscriptionRow from './SubscriptionRow';
import { usePermissions } from '../../../hooks';

const SubscriptionRows = ({
  courses: parentCourses,
  getAllLearnSubscriptions,
  onClickCourses,
  accountId,
  getAllLearnBundles,
  licencesCountMap = new Map(),
}) => {
  const { subscriptions, bundleShow, bundleHidden } = getAllLearnSubscriptions
    ? getAllLearnSubscriptions
    : { subscriptions: [], bundleShow: [], bundleHidden: [] };

  const permissions = usePermissions();

  const subscriptionsObj = keyBy(subscriptions);
  const checkedBundlesObj = keyBy([...bundleShow, ...bundleHidden]);

  const disabledCheckboxArr = getAllLearnBundles.filter((item) => checkedBundlesObj.hasOwnProperty(item.id));
  const extractAllCourses = disabledCheckboxArr.reduce((list, { courses }) => list.concat(courses), []);
  const disabledCheckboxObj = keyBy(extractAllCourses);

  const coursesWithSubscriptions = parentCourses.map((course) => ({
    ...course,
    checked: subscriptionsObj.hasOwnProperty(course.id),
  }));

  const onClickShow = (event) => {
    onClickCourses({ courseId: event.target.name, subscriptions, bundleShow, bundleHidden });
  };

  return (
    <>
      {orderBy(coursesWithSubscriptions, [(item) => item.name.toLowerCase()], 'asc').map((course) => {
        return (
          <SubscriptionRow
            permissions={permissions}
            licencesCountMap={licencesCountMap}
            key={course.id}
            id={course.id}
            courseName={course.name}
            accountId={accountId}
            onCheckboxClick={onClickShow}
            checked={course.checked}
            disabledCheckbox={disabledCheckboxObj.hasOwnProperty(course.id)}
          />
        );
      })}
    </>
  );
};

export default SubscriptionRows;
