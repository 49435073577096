import React from 'react';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';

const GreetingsContainer = styled.div`
  font-size: ${({ theme }) => theme.fonts.xxl.size};
  font-family: ${({ theme }) => theme.fonts.family};
  padding: ${({ theme }) => theme.geometry.xl.spacing};
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.text.light.text};
`;

const getUser = () => {
  const idToken = localStorage.getItem('ID_TOKEN');
  return jwt_decode(idToken);
};

const replaceMessagePlaceholders = (message) => {
  const { given_name, family_name, email } = getUser();
  message = message.replace(/\[FIRSTNAME\]/g, given_name);
  message = message.replace(/\[LASTNAME\]/g, family_name);
  message = message.replace(/\[EMAIL\]/g, email);
  return message;
};

const GreetingsHeader = ({ message }) => {
  return <GreetingsContainer>{replaceMessagePlaceholders(message)}</GreetingsContainer>;
};

export default GreetingsHeader;
