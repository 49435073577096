import { useState, useCallback } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';
import { UserRole, AnomalyThreshold } from '@rio/rio-types';
import { Col, Button, ErrorMessage, ContainerError } from 'rio-ui-components';
import styled from 'styled-components';
import { MODALS, Nullable } from '../../../types';
import {
  useNotification,
  useCurrentAccountId,
  usePermissions,
  useCurrentAccount,
  useGetAnomalyThresholds,
} from '../../../hooks';

import { ContainerHeader, DeleteModal } from '../../../components';
import { GET_ANOMALY_THRESHOLDS } from '../../../queries/anomaly-threshold';
import { Grid } from './Grid';

import AnomalyThresholdModal from './AnomalyThresholdModal';
import { FetchRowsParameters, GridApiType } from './types';
import { DELETE_ANOMALY_THRESHOLD } from './index.queries';
import { formatAnomalyThresholdData } from './utils';
import { ToastColor } from '@rio/ui-components';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

interface Notification {
  message?: Nullable<string>;
  color?: Nullable<string>;
  id?: string;
}

interface ModalInfo {
  id: Nullable<string>;
  type: Nullable<string>;
  anomalyThresholdId?: Nullable<string>;
  utilityType?: Nullable<string>;
}

const ConfigurationAnomalyContainer = () => {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const currentAccount = useCurrentAccount();
  const permissions = usePermissions();
  const isSysAdmin = permissions.role === UserRole.Sysadmin;
  const { showNotification } = useNotification();
  const [anomalyThresholdConfigs, setAnomalyThresholdConfigs] = useState<AnomalyThreshold[]>([]);
  const [gridApi, setGridApi] = useState<GridApiType>();
  const [showModalInfo, setShowModal] = useState<ModalInfo>({
    id: '',
    type: null,
    anomalyThresholdId: null,
    utilityType: '',
  });

  const { error, refetch: refetchFn } = useGetAnomalyThresholds(isSysAdmin ? null : accountId);

  const [deleteAnomalyThresholdConfig] = useMutation(DELETE_ANOMALY_THRESHOLD);

  const anomalyThresholdToUpdate = anomalyThresholdConfigs.find((anomalyThresholdConfig: AnomalyThreshold) => {
    return anomalyThresholdConfig.id === showModalInfo.id;
  });

  const refreshGrid = useCallback(() => {
    try {
      gridApi?.api?.refreshServerSide({ purge: true });
    } catch (gridError) {
      console.error(gridError);
    }
  }, [gridApi]);

  const showModal = (type: Nullable<string>, id: Nullable<string>) => {
    return setShowModal({ type, id });
  };

  const dismissModals = (notification?: Notification) => {
    showModal(null, null);
    if (notification?.message) {
      showNotification(notification?.message, notification?.color as ToastColor);
    }
  };

  const fetchRows = async (variables: FetchRowsParameters) => {
    const result = await client.query({
      query: GET_ANOMALY_THRESHOLDS,
      variables: { ...variables, accountId: isSysAdmin ? null : accountId },
      fetchPolicy: 'network-only',
    });

    const {
      data: {
        getAnomalyThresholds: { rows, totalRows },
      },
    } = result;

    setAnomalyThresholdConfigs(rows);

    return { rows, totalRows };
  };

  const deleteAnomalyThresholdConfigFunc = async () => {
    try {
      await deleteAnomalyThresholdConfig({
        variables: {
          id: anomalyThresholdToUpdate?.id,
        },
      });
      showNotification('DataTracker configuration has been successfully deleted', 'success');
      refreshGrid();
    } catch (err) {
      showNotification('An error has occurred. If the problem persists please contact support', 'danger');
      console.error(err);
    } finally {
      dismissModals();
    }
  };

  return (
    <Col name="ConfigurationAnomalyContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationAnomalyContainer__Controls"
        icon="list"
        iconColor="primary"
        title={<FormattedMessage id="pages.configuration.dataTracker.container.header.anomaly" />}
      >
        {permissions.dataTracker.find((action: string) => action.startsWith('create')) && (
          <ButtonStyled
            name="ConfigurationAnomalyContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => showModal(MODALS.CREATE, null)}
          >
            <FormattedMessage id="pages.configuration.dataTracker.container.add" />
          </ButtonStyled>
        )}
      </ContainerHeader>
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError
              name="ConfigurationAnomalyContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetchFn}
            />
          )}
        </ErrorMessage>
      )}
      <Grid
        isSystemAdmin={isSysAdmin}
        fetchRows={fetchRows}
        setGridApi={setGridApi}
        onEdit={showModal}
        onDelete={showModal}
      />
      {showModalInfo.type === MODALS.CREATE && (
        <AnomalyThresholdModal
          isSysAdmin={isSysAdmin}
          currentAccount={currentAccount}
          refresh={refreshGrid}
          dismiss={dismissModals}
        />
      )}
      {showModalInfo.type === MODALS.UPDATE && (
        <AnomalyThresholdModal
          isSysAdmin={isSysAdmin}
          currentAccount={currentAccount}
          anomalyThresholdToUpdate={formatAnomalyThresholdData(anomalyThresholdToUpdate!)}
          refresh={refreshGrid}
          dismiss={dismissModals}
        />
      )}
      {showModalInfo.type === MODALS.DELETE && (
        <DeleteModal
          deleteObjectName={'Anomaly Threshold'}
          onConfirmClick={deleteAnomalyThresholdConfigFunc}
          onDismissClick={dismissModals}
        />
      )}
    </Col>
  );
};

export default injectIntl(ConfigurationAnomalyContainer);
