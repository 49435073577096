import { gql } from '@apollo/client';

export const SURVEY_TEMPLATE_FRAGMENT = gql`
  fragment SurveyTemplateFields on SurveyTemplate {
    id
    owner {
      id
      first_name
      last_name
    }
    name
    version
    description
    formContent
    createdDate
    expiryDate
  }
`;

export const GET_SURVEY_TEMPLATES = gql`
  query GetSurveyTemplates {
    getSurveyTemplates {
      ...SurveyTemplateFields
    }
  }
  ${SURVEY_TEMPLATE_FRAGMENT}
`;

export const CREATE_SURVEY_TEMPLATE = gql`
  mutation CreateSurveyTemplate($template: SurveyTemplateInput!) {
    createSurveyTemplate(template: $template) {
      ...SurveyTemplateFields
    }
  }
  ${SURVEY_TEMPLATE_FRAGMENT}
`;

export const UPDATE_SURVEY_TEMPLATE = gql`
  mutation UpdateSurveyTemplate($id: ID!, $template: SurveyTemplateInput!) {
    updateSurveyTemplate(id: $id, template: $template) {
      ...SurveyTemplateFields
    }
  }
  ${SURVEY_TEMPLATE_FRAGMENT}
`;

export const DELETE_SURVEY_TEMPLATE = gql`
  mutation DeleteSurveyTemplate($id: ID!) {
    deleteSurveyTemplate(id: $id)
  }
`;
