import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import introspection from '@rio/rio-types/build/introspection.json';
import { Auth } from 'aws-amplify';
import { defaults, resolvers } from './resolvers';
import { LOG_OUT } from './queries/auth';
import typeDefs from './typeDefs';
import introspectionPossibleTypes from './utils/introspectionPossibleTypes.ts';
import { getEnvVar } from './env';

const omitDeep = (obj, key) => {
  if (!obj) {
    return;
  }
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val))
        newObj[i] = val.map((item) => {
          if (Array.isArray(item)) return omitDeep(item, key);
          else if (typeof item === 'object') return omitDeep(item, key);
          return item;
        });
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

const cache = new InMemoryCache({
  typePolicies: {
    InitiateLearnFileUploadResponse: {
      keyFields: ['url'],
    },
    Role: {
      keyFields: ['role'],
    },
    CourseMetaData: {
      keyFields: ['link'],
    },
    SurveyTemplateSubscription: {
      keyFields: ['account', ['id'], 'template', ['id']],
    },
    AccountFormContributor: {
      keyFields: ['account', ['id']],
    },
  },
  possibleTypes: introspectionPossibleTypes(introspection),
});

defaults.forEach(({ query, data }) => cache.writeQuery({ query, data }));

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        return session.getIdToken().getJwtToken();
      }
    } catch {}
  },
};

const url = getEnvVar('REACT_APP_GRAPHQL_URI');
const region = getEnvVar('REACT_APP_AWS_REGION');

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    cleanTypenameLink,
    onError(({ networkError, operation }) => {
      const context = operation.getContext();
      if (
        networkError &&
        context &&
        context.response &&
        (context.response.status === 401 || context.response.status === 403) &&
        operation?.operationName !== 'GetUserByUsername'
      ) {
        client.mutate({ mutation: LOG_OUT });
      }
    }),
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }),
  ]),
  cache,
  resolvers,
  typeDefs,
});

export default client;
