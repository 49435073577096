import React from 'react';
import { PropertySheet } from 'rio-ui-components';
export const LabelValueItem = (props) => {
  const { id, name = 'PropertySheetTextColumn', label, value } = props;
  return (
    <PropertySheet.Row>
      <PropertySheet.TextColumn id={`${name}__label--${id}`} name={`${name}__label--${id}`}>
        {label}
      </PropertySheet.TextColumn>
      <PropertySheet.TextColumn id={`${name}__value--${id}`} name={`${name}__value--${id}`} align="right">
        {value}
      </PropertySheet.TextColumn>
    </PropertySheet.Row>
  );
};
