import { useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import { AuditEvent } from '@rio/rio-types';
import { AgGrid, styled } from '@rio/ui-components';
import { toDateTime } from '~/utils';
import { replaceEmpty, getUserNames, feedbackGetter } from '../SurveyAuditLogContainer';

export const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 216px); // header + page title height
`;
interface ContributorAuditLogGridProps {
  rows: AuditEvent[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Date & Time',
    field: 'createdDate',
    valueFormatter: (data: { value: string }) => toDateTime(data.value),
  },
  {
    headerName: 'User',
    valueFormatter: replaceEmpty,
    valueGetter: getUserNames,
  },
  {
    headerName: 'Action',
    field: 'type',
    flex: 1,
    valueFormatter: replaceEmpty,
  },
  {
    headerName: 'Manager comments',
    field: 'payload',
    valueFormatter: feedbackGetter,
  },
];

export const ContributorAuditLogGridV2 = ({ rows }: ContributorAuditLogGridProps) => {
  const gridOptions = useMemo(
    () => ({
      rowData: rows,
      suppressLoadingOverlay: true,
      columnDefs,
    }),
    [rows]
  );

  return <AgGridStyled gridKey="receivedSurveyAuditLogGridV2" gridOptions={gridOptions} />;
};
