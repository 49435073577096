import { Icons, CustomIcons } from '@rio/ui-components';

export const suggestionsList = (theme) => [
  {
    title: 'What is CSRD?',
    icon: Icons.FactCheckOutlined,
    color: theme.sys.color.shadow,
    id: 'CSRD',
  },
  {
    title: 'How do I upload Electricity Data?',
    icon: CustomIcons.RioBot,
    color: theme.sys.color.shadow,
    id: 'EDQ',
  },
  {
    title: 'What is TCFD?',
    icon: Icons.FactCheckOutlined,
    color: theme.sys.color.shadow,
    id: 'TCFDQ',
  },
  {
    title: 'How do I use tags in Rio?',
    icon: CustomIcons.RioBot,
    color: theme.sys.color.shadow,
    id: 'TQ',
  },
  {
    title: 'How can I improve the energy efficiency of my home?',
    icon: Icons.HomeOutlined,
    color: theme.sys.color.shadow,
    id: 'EEQ',
  },
  {
    title: 'What is greenwashing?',
    icon: Icons.LanguageOutlined,
    color: theme.sys.color.shadow,
    id: 'GQ',
  },
  {
    title: 'What is SFDR?',
    icon: Icons.FactCheckOutlined,
    color: theme.sys.color.shadow,
    id: 'SFDRQ',
  },
  {
    title: 'Which renewable technology is most suitable for my home?',
    icon: Icons.HomeOutlined,
    color: theme.sys.color.shadow,
    id: 'RTQ',
  },
];

export const msg =
  'Hi, <b>I’m Rio</b>, here to help you understand <b>ESG & sustainability</b> and more generally have a <b>positive impact</b>. <br><br> How can I help you today?';

export const configUrl = 'https://storage.googleapis.com/landbot.pro/v3/H-2222625-YEUXJBUO9T7EYUK3/index.json';

export const scriptSrc = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
