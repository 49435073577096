import React from 'react';
import { Row, Col, Heading, ProfilePicture, Icon } from 'rio-ui-components';
import styled from 'styled-components';

const DashboardRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const DashboardProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 ${(props) => props.theme.geometry.xxl.spacing} 0 0;

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const DashboardRow = ({ dashboardId, dashboardName, profileImage, onEdit }) => {
  return (
    <DashboardRowStyled name={`account-row--${dashboardId}`} container itemAlign="center">
      <DashboardProfilePictureStyled name={`account-row__profile-picture--${dashboardId}`} src={profileImage} size="xs" accountName={dashboardName} />

      <Col container item>
        <Heading inline name={`account-row__name--${dashboardId}`} size="md">
          {dashboardName}
        </Heading>
      </Col>

      <EditLinkStyled onClick={onEdit}>
        <Icon name={`account-row__edit--${dashboardId}`} icon="pencil-alt" />
      </EditLinkStyled>
    </DashboardRowStyled>
  );
};

export default DashboardRow;
