import Highcharts from 'highcharts';
import { baseChart } from './baseChart';

export const basePieChart: Highcharts.Options = {
  ...baseChart,
  legend: {
    ...baseChart.legend,
    labelFormatter: function () {
      return this.name.replace(/: .+?$/, '');
    },
  },
  plotOptions: {
    pie: {
      showInLegend: true,
      dataLabels: {
        style: {
          color: '#4d505b',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '19px',
          fontFamily: 'Proxima Nova',
          fontStyle: 'normal',
          textOutline: 'none',
        },
      },
    },
  },
};
