import { Icon } from 'rio-ui-components';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams, RowNode, ColDef } from 'ag-grid-community';
import styled from 'styled-components';
import { SurveyQuestionCategory } from '@rio/rio-types';
import { getAgRowId } from '../../../utils';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xxl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface ActionCellProps {
  data: RowNode;
  onEdit: (id: string) => void;
}

const GridContainer = styled.div`
  height: 100%;
`;

const ActionCell = ({ data, onEdit }: ICellRendererParams & ActionCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(data.id)}>
      <Icon icon="pencil-alt" />
    </EditLinkStyled>
  </GridCellContainer>
);

interface SurveyTemplatesGridProps {
  rows: SurveyQuestionCategory[];
  onEdit: (id: string) => void;
}
const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  cellStyle: {
    height: 'auto',
  },
};

const getRowId = getAgRowId('id');

const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
  },
  {
    headerName: 'Actions',
    field: 'id',
    cellRenderer: 'edit',
    colId: 'edit',
  },
];
export function GridOfCategories({ rows, onEdit }: SurveyTemplatesGridProps) {
  const components = {
    edit: (props: ICellRendererParams) => <ActionCell {...props} onEdit={onEdit} />,
  };
  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        rowSelection="single"
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        components={components}
        rowData={rows}
        paginationPageSize={25}
        getRowId={getRowId}
        pagination
      />
    </GridContainer>
  );
}
