import { useMutation, useApolloClient } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import * as JsSearch from 'js-search';
import { Col, Search } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import SubscriptionRows from './SubscriptionRows';
import BundleRows from './BundleRows';
import NoResults from '../../../components/NoResults';
import { SAVE_SUBSCRIPTION, GET_ALL_SUBSCRIPTION_INFO } from './index.queries';

const searchRow = (data, term, searchField) => {
  if (!term) return data;
  const search = new JsSearch.Search('id');
  search.addIndex(searchField);
  search.addDocuments(data);
  return search.search(term);
};

const SubscriptionWrapper = ({ accountId, title, allSubscriptionInfo, licencesCountMap = new Map() }) => {
  const [state, setState] = useState(allSubscriptionInfo);
  const [search, setSearch] = useState('');
  const [saveSubscription] = useMutation(SAVE_SUBSCRIPTION);
  const client = useApolloClient();

  const searchedCourses = searchRow(state.courses.data, search, 'name');
  const searchedBundle = searchRow(state.getAllLearnBundles, search, 'title');

  useEffect(() => {
    return () => {
      client.writeQuery({ query: GET_ALL_SUBSCRIPTION_INFO, variables: { accountId: accountId }, data: state });
    };
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onClickMandatory = ({ courseId, subscriptions, bundleShow, bundleHidden, mandatoryBundleId }) => {
    const newState = {
      ...state,
      getAllLearnSubscriptions: {
        ...state.getAllLearnSubscriptions,
        subscriptions,
        bundleShow,
        bundleHidden,
        mandatoryBundleId: mandatoryBundleId ? undefined : courseId,
      },
    };

    saveSubscription({ variables: { ...newState.getAllLearnSubscriptions, accountId: accountId } });
    setState(newState);
  };

  const onClickBundle = ({ type, courseId, subscriptions, bundleShow, bundleHidden }) => {
    let [copyBundleShow, copyBundleHidden] = [[...bundleShow], [...bundleHidden]];

    if (type === 'bundleShow') {
      copyBundleHidden = bundleHidden.filter((item) => item !== courseId);
    } else if (type === 'bundleHidden') {
      copyBundleShow = copyBundleShow.filter((item) => item !== courseId);
    }

    const pickBundle = type === 'bundleShow' ? copyBundleShow : copyBundleHidden;

    if (pickBundle.includes(courseId)) {
      const index = pickBundle.findIndex((item) => item === courseId);
      pickBundle.splice(index, 1);
    } else {
      pickBundle.push(courseId);
    }

    const newState = {
      ...state,
      getAllLearnSubscriptions: {
        ...state.getAllLearnSubscriptions,
        subscriptions,
        bundleShow: copyBundleShow,
        bundleHidden: copyBundleHidden,
      },
    };
    saveSubscription({ variables: { ...newState.getAllLearnSubscriptions, accountId: accountId } });
    setState(newState);
  };

  const onClickCourses = ({ courseId, subscriptions, bundleShow, bundleHidden }) => {
    let copySubscriptions = [...subscriptions];
    if (copySubscriptions.includes(courseId)) {
      copySubscriptions = copySubscriptions.filter((item) => item !== courseId);
    } else {
      copySubscriptions.push(courseId);
    }
    const newState = {
      ...state,
      getAllLearnSubscriptions: {
        ...state.getAllLearnSubscriptions,
        subscriptions: copySubscriptions,
        bundleShow,
        bundleHidden,
      },
    };
    saveSubscription({ variables: { ...newState.getAllLearnSubscriptions, accountId: accountId } });
    setState(newState);
  };

  return (
    <>
      <Col name="ConfigurationAccountsContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationLearnSubscriptionsContainer__Controls"
          icon="briefcase"
          iconColor="primary"
          title={title}
        >
          <Col container item>
            <Search
              name="ConfigurationLearnSubscriptionsContainer__Controls__Search"
              value={search}
              onChange={handleSearchChange}
              hideButton
            />
          </Col>
        </ContainerHeader>

        {!searchedCourses.length && !searchedBundle.length ? (
          <NoResults name="ConfigurationUsersContainer__NoResults" title="There are no results for this search." />
        ) : (
          <>
            <BundleRows
              licencesCountMap={licencesCountMap}
              accountId={accountId}
              bundle={searchedBundle}
              getAllLearnSubscriptions={state.getAllLearnSubscriptions}
              onClickBundle={onClickBundle}
              onClickMandatory={onClickMandatory}
            />
            <SubscriptionRows
              licencesCountMap={licencesCountMap}
              courses={searchedCourses}
              accountId={accountId}
              getAllLearnSubscriptions={state.getAllLearnSubscriptions}
              getAllLearnBundles={state.getAllLearnBundles}
              onClickCourses={onClickCourses}
            />
          </>
        )}
      </Col>
    </>
  );
};
export default SubscriptionWrapper;
