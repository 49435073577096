import { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import { styled } from '@mui/material-next/styles';
import { DashboardExperience, createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

interface QuicksightFrameProps {
  id: string;
  dashboardurl: string;
  accountId: string;
  parameters: Dictionary<string | number>;
  setDashFrame: (dashboardExperience: DashboardExperience) => void;
  setIsContentReady: (isReady: boolean) => void;
}

const Conatiner = styled('div')<{ display: boolean }>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  height: 100%;
`;

const Frame = styled('div')``;

export const QuicksightFrameV2 = (props: QuicksightFrameProps) => {
  const [displayDashboard, setDisplayDashboard] = useState(false);
  const { id, dashboardurl, setDashFrame, setIsContentReady, accountId, parameters = {}, ...rest } = props;

  useEffect(() => {
    const embedDashboard = async () => {
      const embeddingContext = await createEmbeddingContext();

      const frameOptions = {
        url: dashboardurl,
        container: `#${id}`,
        height: '100%',
        width: '100%',
        resizeHeightOnSizeChangedEvent: true,
      };

      const contentOptions = {
        parameters: [
          {
            Name: 'accountId',
            Values: [accountId],
          },
          {
            Name: 'ucAccountId',
            Values: [accountId.toUpperCase()],
          },
          ...Object.keys(parameters).map((key) => ({ Name: key, Values: [parameters[key].toString()] })),
        ],
        locale: 'en-GB',
        toolbarOptions: {
          export: true,
          undoRedo: true,
          reset: true,
        },
        attributionOptions: {
          overlayContent: false,
        },
        themeOptions: {
          //Theme named "New Redesign"
          themeArn: 'arn:aws:quicksight:eu-west-2:523523762637:theme/eef82ecb-7a1a-43ad-8636-7e0f800fbe01',
        },
        onMessage: async (messageEvent) => {
          console.log(messageEvent);
          switch (messageEvent.eventName) {
            case 'ERROR_OCCURRED':
            case 'CONTENT_LOADED': {
              setDisplayDashboard(true);
              setIsContentReady(true);
              break;
            }
          }
        },
      };
      const dashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
      setDashFrame(dashboardExperience);
    };

    embedDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Conatiner display={displayDashboard}>
      <Frame id={id} {...rest} />
    </Conatiner>
  );
};
