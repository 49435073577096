import moment from 'moment';
import { v4 as uuid } from 'uuid';

export function createEmptyPolicy(accountId) {
  return {
    accountId,
    id: uuid(),
    title: '',
    version: '1',
    createdAt: moment().toISOString(),
    reviewDate: null,
    documents: []
  };
}

export function sortByVersion(a, b) {
  return parseInt(b.version, 10) - parseInt(a.version, 10);
}

export function isPoliciesDocument(obj) {
  return !!obj && obj.__typename === 'DocumentRecord';
}

export function validatePolicy(policy) {
  const errors = {};
  if (!policy.documents.length) {
    errors.documents = 'No attachments yet. Please attach some documents.';
  }
  if (!policy.title) {
    errors.title = 'Title is required';
  }
  return errors;
}
