import { gql } from '@apollo/client';
import CHAPTER_FIELDS from '../../../../graphql/fragments/ChapterFields.fragment';
import CHAPTER_FIELD_NESTING from '../../../../graphql/fragments/ChapterFieldsNesting.fragment';

export const GET_MANAGEMENT_SYSTEM_BY_ID = gql`
  query GetManagementSystemById($id: ID!, $accountId: ID!) {
    getManagementSystemById(id: $id, accountId: $accountId) {
      id
      title
      synopsis
      shortCode
      type
      content {
        headerButton {
          text
          link
        }
      }
      documents {
        id
        fileName
        managementSystemCode
        category
      }
      chapters {
        ...ChapterFields
        ... on ManagementSystemChapter {
          chapters {
            ...ChapterFields
            ...ChapterFieldsNesting
          }
        }
      }
    }
  }
  ${CHAPTER_FIELDS}
  ${CHAPTER_FIELD_NESTING}
`;

export const ATTACH_DOCUMENT = gql`
  mutation attachDocument($id: ID!, $accountId: ID!, $systemId: ID!, $linkId: ID!) {
    attachDocumentToManagementSystem(input: { id: $systemId, accountId: $accountId, linkId: $linkId, documentId: $id })
  }
`;

export const ATTACH_CONTENT = gql`
  mutation attachContent($id: ID!, $accountId: ID!, $linkId: ID!, $synopsis: String!) {
    attachContentToManagementSystem(input: { id: $id, accountId: $accountId, linkId: $linkId, synopsis: $synopsis })
  }
`;

export const GET_AVAILABLE_MANAGEMENT_SYSTEMS_AND_DOCUMENTS = gql`
  query GetAvailableManagementSystemsAndDocuments($accountId: ID!) {
    getAvailableManagementSystems(accountId: $accountId) {
      id
      title
    }
  }
`;