import { useQuery } from '@apollo/client';
import GET_ALL_LEGISLATION from '../../graphql/queries/governance/GetAllLegislation.query.graphql';
import { useCurrentAccountId } from '../useCurrentAccountId';
import { Query } from '@rio/rio-types';

export function useAllLegislation() {
  const accountId = useCurrentAccountId();
  const {
    data: { getAllLegislation = [] } = {},
    loading,
    error,
  } = useQuery<Pick<Query, 'getAllLegislation'>>(GET_ALL_LEGISLATION, {
    variables: {
      accountId,
    },
    skip: !accountId,
  });

  return { legislations: getAllLegislation, loading, error };
}
