import React from 'react';

export interface TranspositionCellProps {
  data: {
    transposition: {
      transpositionName?: string;
    };
  };
}

export const TranspositionCell = ({ data }: TranspositionCellProps) => {
  const transposition = data?.transposition?.transpositionName;
  return <>{transposition ? transposition : 'Default Template'}</>;
};
