import { Text } from '@rio/ui-components';
import { formatDate } from '~/utils';

export const CompletedDateCell = (props) => {
  const {
    data: { completedAt },
  } = props;

  const date = formatDate(completedAt);

  return (
    completedAt && (
      <Text typescale="body" size="medium">
        {date}
      </Text>
    )
  );
};
