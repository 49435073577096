"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartContainer = void 0;
const styles_1 = require("@mui/material-next/styles");
const Text_1 = require("../Text");
const classNames_1 = require("./classNames");
exports.ChartContainer = (0, styles_1.styled)('div') `
  width: 100%;
  height: 100%;

  & .${classNames_1.chartClassNames.dataLabel} {
    display: flex;
    flex-direction: column;
  }
  & .${classNames_1.chartClassNames.dataLabelText} {
    display: block;
    margin: 0;
    margin-bottom: 4px;
    ${(props) => (0, Text_1.textCss)(props.theme, { typescale: 'body', size: 'medium' })}
  }
`;
