import { gql } from '@apollo/client';

export const GET_BUILDING_ENERGY_RATINGS_BY_ACCOUNT_ID = gql`
  query GetBuildingEnergyRatingsByAccountId(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getBuildingEnergyRatingsByAccountId(
      accountId: $accountId
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        location {
          id
          name
          code
          endDate
          tags {
            id
            tagName
          }
          departments {
            id
            name
          }
          address {
            country {
              id
              name
            }
          }
        }
        heatingFuelType
        nominatedDate
        rating
        score
        dataProvider
        buildingEnvironment
        gia
        issueDate
        certificateValidTo
        certificateReferenceNumber
        otherReference
        resurveyed
        comments
        type
        block
      }
    }
  }
`;

export const GET_BUILDING_ENERGY_RATINGS_FILTER = gql`
  query GetBuildingEnergyRatingsFilter($accountId: ID!, $field: String!) {
    getBuildingEnergyRatingsFilter(accountId: $accountId, field: $field)
  }
`;
