import { Nullable, NamedEntity, Option } from '../types';

export const namedEntityToSelectOption = (entity: NamedEntity) => ({
  label: entity.name,
  value: entity.id,
});

export const toSelectOptions = (list: Nullable<NamedEntity[]>) => list?.map(namedEntityToSelectOption) || [];

export const CREATE_NEW_OPTION_ID = 'CREATE_NEW';

export const CREATE_NEW_OPTION = {
  label: 'Create new...',
  value: CREATE_NEW_OPTION_ID,
};

export const mapSelectedOptionsToInputValues = (selectedOption: Option | Option[]): string | string[] => {
  if (Array.isArray(selectedOption)) {
    return selectedOption.map((item: Option) => item.value);
  }

  return selectedOption.value;
};

export const enumValueToSelectOption = <T>(enumValue: T) => ({
  label: enumValue,
  value: enumValue,
});
