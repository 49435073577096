import React from 'react';
import { Modal } from 'rio-ui-components';
import { ConfirmAction, ConfirmActionProps } from './ConfirmAction';

interface ConfirmActionModalProps extends ConfirmActionProps {
  show: boolean;
  onDismiss: () => void;
  size?: string;
}
export function ConfirmActionModal({ show, size = 'md', onDismiss, ...confirmActionProps }: ConfirmActionModalProps) {
  return (
    <Modal size={size} height="30vh" show={show} onDismiss={onDismiss}>
      <ConfirmAction {...confirmActionProps} />
    </Modal>
  );
}
