import React, { useContext } from 'react';
import { Row, Col, Text, Icon, ProfilePicture } from 'rio-ui-components';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext, css } from 'styled-components';
import { REDUCE } from '../../../constants/targetTypes';
import * as STATUSES from '../../../constants/targetStatuses';
import { alphabetiseByField } from '../../../utils/alphabetise';
import { splash } from '../../../keyframes';
import { formatDate } from '../../../utils/formatDate';
import { getStatusTitle } from './utils';

const ContentContainerStyled = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.basic.white};
  overflow-x: hidden;
  margin: 32px 48px 48px;
`;

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const TargetRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
  background-color: white;
  ${(props) =>
    props.anchored
      ? css`
          animation: ${splash} 2s normal forwards ease-in-out;
        `
      : ''}
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const columnWidth = {
  avatar: 1,
  topic: 1.5,
  target: 3,
  scope: 1,
  baseline: 2,
  start: 1,
  end: 1,
  status: 1,
  editButton: 1
};

export const Status = ({ id, status, topic, onActionRequiredClick }) => {
  const theme = useContext(ThemeContext);

  const statusProps = {
    [STATUSES.ACHIEVING]: {
      icon: 'circle',
      iconColor: theme.colors.success.normal.background
    },
    [STATUSES.NOT_ACHIEVING]: {
      icon: 'circle',
      iconColor: theme.colors.danger.normal.background
    },
    [STATUSES.ACTION_REQUIRED]: {
      icon: 'exclamation-circle'
    }
  }[status];
  const title = getStatusTitle(status, topic.name);

  if (status === STATUSES.ACTION_REQUIRED) {
    return (
      <Icon
        name={`target-row__status--${id}`}
        icon={statusProps.icon}
        size="lg"
        onClick={onActionRequiredClick}
        title={title}
      />
    );
  }

  return (
    <Icon
      name={`target-row__status--${id}`}
      icon={statusProps.icon}
      size="lg"
      color={statusProps.iconColor}
      title={title}
    />
  );
};

export function TargetRow({ target, onEdit, onActionRequiredClick, canEdit, anchored }) {
  return (
    <TargetRowStyled name={`target-row--${target.id}`} container itemAlign="center" id={target.id} anchored={anchored}>
      <Col span={columnWidth.avatar}>
        <ProfilePicture
          name={`target-row__profile-picture--${target.id}`}
          src={null}
          size="xs"
          accountName={target.topic.utility}
        />
      </Col>
      <Col span={columnWidth.topic} container item>
        <Text align="left" name={`target-row__topic--${target.id}`}>
          {target.topic.utility}
        </Text>
      </Col>
      <Col span={columnWidth.name} container item>
        <Text align="left" name={`target-row__target--${target.id}`}>
          {target.name}
        </Text>
      </Col>
      <Col span={columnWidth.scope} container item>
        <Text align="left" name={`target-row__scope--${target.id}`}>
          {`${target.scope.charAt(0) + target.scope.slice(1).toLowerCase()}`}
        </Text>
      </Col>
      <Col span={columnWidth.baseline} container item>
        <Text align="left" name={`target-row__startDate--${target.startDate}`}>
          {target.type.name === REDUCE
            ? `${formatDate(target.baselineStartDate)} - ${formatDate(target.baselineEndDate)}`
            : null}
        </Text>
      </Col>
      <Col span={columnWidth.start} container item>
        <Text align="left" name={`target-row__endDate--${target.endDate}`}>
          {formatDate(target.startDate)}
        </Text>
      </Col>
      <Col span={columnWidth.end} container item>
        <Text align="left" name={`target-row__endDate--${target.endDate}`}>
          {formatDate(target.endDate)}
        </Text>
      </Col>
      <Col span={columnWidth.end} container item>
        <EditLinkStyled title={`${target.status.charAt(0) + target.status.slice(1).toLowerCase()}`}>
          <Status {...target} onActionRequiredClick={onActionRequiredClick} />
        </EditLinkStyled>
      </Col>
      {canEdit && (
        <Col span={columnWidth.editButton} container item>
          <EditLinkStyled onClick={onEdit}>
            <Icon name={`target-row__edit--${target.id}`} icon="pencil-alt" />
          </EditLinkStyled>
        </Col>
      )}
    </TargetRowStyled>
  );
}

export function Grid({ targets, showEditModal, showActionRequiredModal, canEdit }) {
  const location = useLocation();
  const sorted = targets.slice().sort(alphabetiseByField('topic.utility'));
  const hashLink = location.hash?.slice?.(1);
  return (
    <ContentContainerStyled>
      <RowStyled container align="between">
        <Col span={columnWidth.avatar} container />
        <Col span={columnWidth.topic} item container>
          <Text weight="bold" align="left">
            Topic
          </Text>
        </Col>
        <Col span={columnWidth.target} item container>
          <Text weight="bold" align="left">
            Target
          </Text>
        </Col>
        <Col span={columnWidth.scope}>
          <Text weight="bold" align="left">
            Applied to
          </Text>
        </Col>
        <Col span={columnWidth.baseline}>
          <Text weight="bold" align="left">
            Baseline
          </Text>
        </Col>
        <Col span={columnWidth.start}>
          <Text weight="bold" align="left">
            Start
          </Text>
        </Col>
        <Col span={columnWidth.end}>
          <Text weight="bold" align="left">
            End
          </Text>
        </Col>
        <Col span={columnWidth.status}>
          <Text weight="bold" align="left">
            Status
          </Text>
        </Col>
        {canEdit && <Col span={columnWidth.editButton} />}
      </RowStyled>
      {sorted.map((target) => (
        <TargetRow
          key={target.id}
          target={target}
          onEdit={() => showEditModal(target.id)}
          onActionRequiredClick={() => showActionRequiredModal(!!target.configurationErrors.length)}
          canEdit={canEdit}
          anchored={hashLink === target.id}
        />
      ))}
    </ContentContainerStyled>
  );
}
