import { useLazyQuery } from '@apollo/client';
import { findIndex } from 'lodash';
import { COURSE_BUNDLE, SUBSCRIPTION, ACCOUNT_CAPACITY } from '../../constants';
import QUERY from '../../graphql/queries/market/GetProductsPaged.query.graphql';

// "mixing up" entity (courseBoundle from Engage) and Product properties into single object
export function useGetProductsLazyQuery() {
  const [fetch, { data: { getProductsPaged: { content = {}, ...page } = {} } = {}, ...others }] = useLazyQuery(QUERY);

  const mapFunction =
    (type) =>
    ({ id, ...rest }) => {
      if (!content?.itemList) {
        return;
      }

      const i = findIndex(content.itemList, ({ id: itemId, productType }) => itemId === id && productType === type);

      return {
        ...rest,
        ...content.itemList[i],
        id
      };
    };

  return [
    (
      variables = {
        page: 0,
        size: 999
      }
    ) => fetch({ variables }),

    {
      data: {
        ...page,
        content: {
          [COURSE_BUNDLE]: content['bundlesList']?.map(mapFunction(COURSE_BUNDLE)) || [],
          [ACCOUNT_CAPACITY]: [{ id: 'accountCapacity_id' }].map(mapFunction(ACCOUNT_CAPACITY)) || [],
          [SUBSCRIPTION]: [{ id: 'team_sub_id' }].map(mapFunction(SUBSCRIPTION)) || []
        }
      },
      ...others
    }
  ];
}
