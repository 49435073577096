import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Text, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import moment from 'moment';

const ContentContainerStyled = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.basic.white};
  overflow-x: hidden;
`;

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const OcrRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
  background-color: white;
`;

const SubjectPill = styled.div`
  background-color: ${(props) => props.theme.colors[props.colour].normal.background};
  color: white;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  cursor: pointer;
`;

const columnWidth = {
  uploadDate: 2,
  name: 2,
  user: 3,
  document: 2,
  review: 2.5
};

const formatDate = (date) => moment(date).local().format('DD/MM/YYYY');

export function OcrUploadRow({ ocrData, onEdit }) {
  return (
    <OcrRowStyled name={`upload-row--${ocrData.id}`} container itemAlign="center" id={ocrData.id}>
      <Col span={columnWidth.name} container item>
        <Text align="left" name={`upload-row__date--${ocrData.id}`}>
          {formatDate(ocrData.uploadedAt)}
        </Text>
      </Col>
      <Col span={columnWidth.name} container item>
        <Text align="left" name={`upload-row__name--${ocrData.id}`}>
          {ocrData.fileName}
        </Text>
      </Col>
      <Col span={columnWidth.user} container item>
        <Text align="left" name={`upload-user--${ocrData.id}`}>
          {`${ocrData.uploadedBy.first_name} ${ocrData.uploadedBy.last_name}`}
        </Text>
      </Col>
      <Col span={columnWidth.document} container item>
        <Link to={`/documents/all/${ocrData.id}`} target="__blank">
          <Icon name={`upload-row__link`} icon="external-link-alt" />
        </Link>
      </Col>
      <Col span={columnWidth.review} container item>
        <Text align="left" name={`upload-row__subject--${ocrData.id}`}>
          <SubjectPill colour="primary" onClick={() => onEdit(ocrData)}>
            Review Upload
          </SubjectPill>
        </Text>
      </Col>
    </OcrRowStyled>
  );
}

export function Grid({ uploads, onEdit }) {
  return (
    <ContentContainerStyled>
      <RowStyled container align="between">
        <Col span={columnWidth.uploadDate} item container>
          <Text weight="bold" align="left">
            Upload Date
          </Text>
        </Col>
        <Col span={columnWidth.name}>
          <Text weight="bold" align="left">
            File Name
          </Text>
        </Col>
        <Col span={columnWidth.user}>
          <Text weight="bold" align="left">
            User
          </Text>
        </Col>
        <Col span={columnWidth.document}>
          <Text weight="bold" align="left">
            Document Link
          </Text>
        </Col>
        <Col span={columnWidth.review}>
          <Text weight="bold" align="left">
            Review
          </Text>
        </Col>
      </RowStyled>
      {uploads &&
        uploads.map((upload) => {
          return <OcrUploadRow key={upload.id} ocrData={upload} onEdit={onEdit} />;
        })}
    </ContentContainerStyled>
  );
}
