import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { Col, Row, Button, Heading, Label, Modal, ScrollContainer } from 'rio-ui-components';
import { NamedEntity, TransactionType } from '@rio/rio-types';
import { NumberInput } from '../../../components/UI/NumberInput';
import AccountSelector from '../../../components/AccountSelector';
import { UtilityTypeSelector } from '../../../components/UtilityTypeSelector';
import { useNotification } from '../../../hooks';
import { InputEvent } from '../../../types';

import { CREATE_ANOMALY_THRESHOLD, UPDATE_ANOMALY_THRESHOLD } from './index.queries';
import { AnomalyFormattedDetails } from './types';
import { prepareAnomalyThresholdInput } from './utils';
import { ValidateAnomalyThresholdConfig, ValidateErrors } from './Validate';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ModalContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const ScrollableContainerStyled = styled(ScrollContainer)`
  ${({ theme }) => `
    overflow: auto;
    padding: ${theme.geometry.xs.spacing};
  `}
`;

interface AnomalyThresholdModalProps {
  isSysAdmin: boolean;
  currentAccount: NamedEntity;
  anomalyThresholdToUpdate?: AnomalyFormattedDetails;
  refresh: () => void;
  dismiss: () => void;
}

const AnomalyThresholdModal = ({
  isSysAdmin,
  currentAccount,
  anomalyThresholdToUpdate,
  refresh,
  dismiss,
}: AnomalyThresholdModalProps) => {
  const initialAccount = isSysAdmin ? { id: null, name: null } : currentAccount;
  const [selectedAccount, setSelectedAccount] = useState({
    name: anomalyThresholdToUpdate?.account?.label || initialAccount.name,
    id: anomalyThresholdToUpdate?.account?.value || initialAccount.id,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidateErrors>({});

  const [anomalyThresholdConfig, setAnomalyThresholdConfig] = useState<AnomalyFormattedDetails>(
    anomalyThresholdToUpdate || {
      account: isSysAdmin ? null : { value: currentAccount.id, label: currentAccount.name },
      thresholdPercentage: null,
      utilityType: { isDefault: true },
    }
  );

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { showNotification } = useNotification();

  const [createOrUpdateAnomalyThresholdConfig, { loading }] = useMutation(
    anomalyThresholdToUpdate ? UPDATE_ANOMALY_THRESHOLD : CREATE_ANOMALY_THRESHOLD
  );

  const handleInputChange = useCallback(
    (event: InputEvent, alternativeEvent?: InputEvent) => {
      // Check if the first argument is an object and has a target property
      const validEvent = typeof event === 'object' && event.target ? event : alternativeEvent;

      if (!validEvent || !validEvent.target) {
        return;
      }

      const { name, value } = validEvent.target;

      let newState = { ...anomalyThresholdConfig };

      if (name === 'transactionType') {
        const utilityType = { transactionType: value as TransactionType, isDefault: !value };
        newState = {
          ...anomalyThresholdConfig,
          utilityType: utilityType,
        };
      } else {
        newState = {
          ...anomalyThresholdConfig,
          [name]: value,
        };
      }

      setAnomalyThresholdConfig(newState);
      setValidationErrors(ValidateAnomalyThresholdConfig(newState));
    },
    [anomalyThresholdConfig, setAnomalyThresholdConfig, setValidationErrors]
  );

  useEffect(() => {
    const hasErrors = Object.values(validationErrors).some(Boolean);
    const relevantAnomalyThresholdConfig = { ...anomalyThresholdConfig };
    const hasInputs = Object.values(relevantAnomalyThresholdConfig).every(Boolean);
    setIsFormValid(!hasErrors && hasInputs);
  }, [validationErrors, anomalyThresholdConfig]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    const variables = {
      ...prepareAnomalyThresholdInput(anomalyThresholdConfig, !!anomalyThresholdToUpdate),
    };

    try {
      await createOrUpdateAnomalyThresholdConfig({
        variables,
      });

      showNotification(
        `Anomaly Threshold has been successfully ${anomalyThresholdToUpdate ? 'updated' : 'created'}`,
        'success'
      );
      refresh();
    } catch (err: unknown) {
      if (err instanceof Error) {
        if (err.message.includes('Duplicate')) {
          return showNotification('Anomaly Threshold already exists for this account', 'danger');
        }
      }
      showNotification('Something went wrong. If the problem persists, contact support', 'danger');
    } finally {
      dismiss();
    }
    setIsSubmitted(false);
  };

  const selectedAccountId = isSysAdmin ? selectedAccount.id : currentAccount.id;

  return (
    <Modal
      size="md"
      show
      loading={loading}
      dismissable
      name="ConfigurationSchemesContainer__Modal--update"
      onDismiss={dismiss}
    >
      <ModalContainerStyled>
        <HeaderStyled size="lg">
          {anomalyThresholdToUpdate ? (
            <FormattedMessage id="pages.configuration.dataTracker.modal.updateHeader" />
          ) : (
            <FormattedMessage id="pages.configuration.dataTracker.modal.createHeader" />
          )}
        </HeaderStyled>
        <ScrollableContainerStyled>
          <form name="create-or-update-anomalyThresholdConfig__form">
            {isSysAdmin && (
              <Row container align="between">
                <ColStyled item>
                  <LabelContainerStyled>
                    <LabelStyled>
                      <FormattedMessage id="pages.configuration.dataTracker.modal.account" />
                    </LabelStyled>
                  </LabelContainerStyled>
                  <AccountSelector
                    data-cy="AnomalyThresholdConfig__Account-name"
                    onChange={({ name, id }: { name: string; id: string }) => {
                      setSelectedAccount({ name, id });
                      setAnomalyThresholdConfig({
                        ...anomalyThresholdConfig,
                        account: {
                          value: id,
                          label: name,
                        },
                      });
                    }}
                    name="account"
                    value={selectedAccount.id}
                    error={validationErrors.account}
                  />
                </ColStyled>
              </Row>
            )}
            {selectedAccountId && (
              <>
                <Row container align="between" data-cy="ProjectDetails__Input-priority">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.thresholdPercentage" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <NumberInput
                      data-cy="AnomalyThresholdConfigDetails__Input-thresholdPercentage"
                      disabled={isSubmitted}
                      onChange={handleInputChange}
                      name="thresholdPercentage"
                      value={anomalyThresholdConfig.thresholdPercentage}
                      error={validationErrors.thresholdPercentage}
                      box
                      type="number"
                      step="1"
                    />
                  </ColStyled>
                </Row>
                <Row container align="between" data-cy="ProjectDetails__Input-priority">
                  <ColStyled item>
                    <LabelContainerStyled>
                      <LabelStyled>
                        <FormattedMessage id="pages.configuration.dataTracker.modal.utilityType" />
                      </LabelStyled>
                    </LabelContainerStyled>
                    <UtilityTypeSelector
                      data-cy="AnomalyThresholdConfig_TransactionType"
                      onChange={handleInputChange}
                      name="transactionType"
                      value={anomalyThresholdConfig.utilityType?.transactionType}
                    />
                  </ColStyled>
                </Row>
              </>
            )}
          </form>
        </ScrollableContainerStyled>
        <Row container>
          <ColStyled item container>
            <Button
              name="create-or-update-anomalyThresholdConfig__button"
              color="success"
              onClick={(e: React.FormEvent) => {
                handleSubmit(e);
              }}
              disabled={loading || !isFormValid}
            >
              {anomalyThresholdToUpdate ? (
                <FormattedMessage id="pages.configuration.dataTracker.modal.update" />
              ) : (
                <FormattedMessage id="pages.configuration.dataTracker.modal.create" />
              )}
            </Button>
          </ColStyled>
        </Row>
      </ModalContainerStyled>
    </Modal>
  );
};

export default AnomalyThresholdModal;
