import { ManagementSystem } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../types';
import { DefaultChapter } from './DefaultChapter';
import { CdpChapters } from '../CarbonDisclosureProject';
import { EbaChapters } from '../Eba';
import { EcbChapters } from '../Ecb';
import { SfdrChapters } from '../SustainableFinanceDisclosureRegulation';
import { ReportingFrameworkLayout } from '../../../../components/ReportingFramework';

interface ChapterOverviewProps {
  managementSystem: ManagementSystem;
  page: ManagementSystemPage;
  refetch: () => void;
  canEditContent: boolean;
  isReadOnly: boolean;
}
function getAllParentsTitles(page: ManagementSystemPage, path: string[] = []): string[] {
  if (page.parent) {
    return getAllParentsTitles(page.parent, [page.title, ...path]);
  }
  return path;
}
function getPagePath(managementSystem: ManagementSystem, page: ManagementSystemPage) {
  return [managementSystem.shortCode].concat(getAllParentsTitles(page)).join('.');
}

export function ContentOverview({ managementSystem, page, canEditContent, isReadOnly }: ChapterOverviewProps) {
  const pagePath = getPagePath(managementSystem, page);

  switch (pagePath) {
    case 'CDP.Overview':
      return <CdpChapters.CarbonDisclosureFrameworkOverview framework={managementSystem} page={page} />;
    case 'CDP.Overview.Track your progress':
      return <CdpChapters.CarbonDisclosureProgress framework={managementSystem} page={page} />;
    case 'CDP.Introduction':
      return <CdpChapters.CarbonDisclosureIntroduction framework={managementSystem} page={page} />;
    case 'CDP.Governance':
      return <CdpChapters.CarbonDisclosureGovernance framework={managementSystem} page={page} />;
    case 'CDP.Risks and Opportunities':
      return <CdpChapters.CarbonDisclosureRisksAndOpportunities framework={managementSystem} page={page} />;
    case 'CDP.Risks and Opportunities.Risks':
      return <CdpChapters.CarbonDisclosureRisks framework={managementSystem} page={page} />;
    case 'CDP.Risks and Opportunities.Opportunities':
      return <CdpChapters.CarbonDisclosureOpportunities framework={managementSystem} page={page} />;
    case 'CDP.Business Strategy':
      return <CdpChapters.CarbonDisclosureBusinessStrategy framework={managementSystem} page={page} />;
    case 'CDP.Targets and Performance':
      return <ReportingFrameworkLayout framework={managementSystem} page={page} />;
    case 'CDP.Emissions Methodology':
      return <ReportingFrameworkLayout framework={managementSystem} page={page} />;
    case 'CDP.Verification':
      return <CdpChapters.CarbonDisclosureVerification framework={managementSystem} page={page} />;
    case 'CDP.Emissions Data':
      return <ReportingFrameworkLayout framework={managementSystem} page={page} />;
    case 'CDP.Emissions Breakdown':
      return <ReportingFrameworkLayout framework={managementSystem} page={page} />;
    case pagePath.match(/^CDP\.Other land management impacts$/)?.input:
      return (
        <DefaultChapter
          managementSystem={managementSystem}
          page={page}
          canEditContent={canEditContent}
          isReadOnly={isReadOnly}
        />
      );
    case pagePath.match(/^CDP\..+$/)?.input:
      return <ReportingFrameworkLayout framework={managementSystem} page={page} />;
    case pagePath.match(
      /^GRI\.(Content Index|GRI 2|GRI 3|Material Topics)\.(The organization and its reporting practices|Activities and workers|Governance|Strategy, policies and practices|Stakeholder engagement|Material Topics|Management of material topics|3-3 Management of material topics|1 Business model resilience|2 Climate change & GHG emissions|3 Customer privacy & data security|4 Employee diversity & inclusion|5 Employee health & safety|6 Ethical corporate behavior|7 Human rights)$/
    )?.input:
      return (
        <DefaultChapter
          managementSystem={managementSystem}
          page={page}
          canEditContent={canEditContent}
          isReadOnly={isReadOnly}
        />
      );
    case pagePath.match(
      /^EBA\.(Environmental Risk|Social Risk|Governance Risk)\.(Business Strategy and Processes|Governance|Risk Management)\..+$/
    )?.input:
      return <EbaChapters.EbaDisclosure framework={managementSystem} page={page} />;
    case pagePath.match(/^EBA\.Progress(.+$|$)/)?.input:
      return <EbaChapters.EbaProgress framework={managementSystem} page={page} />;
    case pagePath.match(EcbChapters.MATCH_DISCLOSURE)?.input:
      return <EcbChapters.EcbDisclosure framework={managementSystem} page={page} />;
    case pagePath.match(EcbChapters.MATCH_PROGRESS)?.input:
      return <EcbChapters.EcbProgress framework={managementSystem} page={page} />;
    case pagePath.match(/^SFDR\.(Entity)\..+$/)?.input:
      return <SfdrChapters.SfdrDisclosure framework={managementSystem} page={page} />;
    case pagePath.match(/^SFDR\.Performance(.+$|$)/)?.input:
      return <SfdrChapters.SfdrProgress framework={managementSystem} page={page} />;

    default:
      return (
        <DefaultChapter
          managementSystem={managementSystem}
          page={page}
          canEditContent={canEditContent}
          isReadOnly={isReadOnly}
        />
      );
  }
}
