import { Fragment, useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { Col, Button, Modal, Notification } from 'rio-ui-components';
import { useCountries } from '../../../hooks/useCountries';

import { GET_LOCATIONS_BY_ACCOUNT, GET_LOCATION_BY_ID } from './index.queries';

import withPermissions from '../../../hocs/withPermissions';

import ContainerHeader from '../../../components/ContainerHeader';
import AccountSelector from '../../../components/AccountSelector';
import { ControlPanel } from '../../../components/ControlPanel';
import { ClearButton } from '../../../components/ClearButton';
import { ExportButton } from '../../../components/ExportButton/ExportButton';

import CreateLocation from './CreateLocation';
import UpdateLocation from './UpdateLocation';
import { LocationUploader } from './LocationUploader';
import { Grid } from './Grid';

const ColStyled = styled(Col)`
  .ag-header-cell-resize {
    z-index: 0;
  }
`;

const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const ButtonContainer = styled(ContainerHeader)`
  & > div:first-child {
    padding-right: ${(props) => props.theme.geometry.xxxl.spacing};
    width: initial;
  }
  justify-content: space-between;
`;

const ConfigurationLocationsContainer = (props) => {
  const [state, setState] = useState({
    createModal: { show: false },
    updateModal: { id: '' },
    selectedTags: [],
    notification: { message: null, color: null },
    account: { id: props.accountId },
    shouldRefetch: false,
    selectAll: false,
    orderByDesc: true,
    pagination: {
      page: 1,
      totalPages: 0,
    },
    countryList: [],
    defaultCountry: null,
  });

  const [gridApi, setGridApi] = useState({});

  const client = useApolloClient();

  const { countryList } = useCountries();

  const handleAccountChange = (selected) => setState({ ...state, account: { id: selected.id } });
  const { data: locationData } = useQuery(GET_LOCATION_BY_ID, {
    variables: { id: state.updateModal.id },
    skip: !state.updateModal.id,
  });
  const canExport = props.permissions.data.find((action) => action.startsWith('exportLocation'));

  const dismissModals = (notification = {}) =>
    setState((prevState) => ({
      ...prevState,
      createModal: { show: false },
      updateModal: { id: null },
      notification: { message: notification.message, color: notification.color, id: notification.id },
    }));

  const showModal = (type, id) => {
    if (type === 'create') return setState({ ...state, createModal: { show: true } });
    if (type === 'update') return setState({ ...state, updateModal: { id } });
  };

  const refreshGrid = () => {
    gridApi.api.refreshServerSide({
      purge: true,
    });
  };

  const hideNotification = () =>
    setState((prevState) => ({ ...prevState, notification: { message: null, color: null, id: null } }));

  const fetchRows = async (variables) => {
    const {
      data: {
        getLocationsPage: { totalRows, rows },
      },
    } = await client.query({
      query: GET_LOCATIONS_BY_ACCOUNT,
      variables,
      fetchPolicy: 'network-only',
    });

    return {
      rows,
      totalRows,
    };
  };

  const renderModals = () => {
    const addressData = locationData
      ? locationData.getLocationById
        ? locationData.getLocationById.address
        : null
      : null;
    const tagData = locationData ? (locationData.getLocationById ? locationData.getLocationById.tags : null) : null;
    const metadata = locationData && {
      code: locationData.getLocationById.code || '',
      tenure: locationData.getLocationById.tenure || null,
      region: locationData.getLocationById.region || null,
      buildingType: locationData.getLocationById.buildingType || null,
      floorSpace: locationData.getLocationById.floorSpace || null,
      startDate: locationData.getLocationById.startDate || null,
      endDate: locationData.getLocationById.endDate || null,
      leaseRenewalDate: locationData.getLocationById.leaseRenewalDate || null,
      notes: locationData.getLocationById.notes || '',
      departments: locationData.getLocationById.departments || [],
    };

    return (
      <Fragment>
        {state.notification.message && (
          <Notification
            name="ConfigurationLocationsContainer__Notification"
            show
            color="success"
            onClose={hideNotification}
          >
            {state.notification.message}
            <div hidden name="ConfigurationLocationsContainer__Notification__createlocation_id">
              {state.notification.id ? state.notification.id[0] : null}
            </div>
            <div hidden name="ConfigurationLocationsContainer__Notification__createaddress_id">
              {state.notification.id ? state.notification.id[1] : null}
            </div>
          </Notification>
        )}
        {state.createModal.show && (
          <Modal
            span={5}
            size="md"
            show
            name="ConfigurationLocationsContainer__Modal--create"
            dismissable
            onDismiss={dismissModals}
          >
            <CreateLocation
              accountId={state.account.id}
              onSuccess={(message, color, id) => dismissModals({ message, color, id })}
              refreshGrid={refreshGrid}
            />
          </Modal>
        )}
        {state.updateModal.id && locationData && (
          <Modal
            span={5}
            size="sm"
            show
            dismissable
            name="ConfigurationLocationsContainer__Modal--update"
            onDismiss={dismissModals}
          >
            <UpdateLocation
              locationId={locationData.getLocationById.id}
              name={locationData.getLocationById.name}
              add1={addressData ? addressData.address1 : ''}
              add2={addressData ? addressData.address2 : ''}
              add3={addressData ? addressData.address3 : ''}
              city={addressData ? addressData.city : ''}
              postcode={addressData ? addressData.postcode : ''}
              phone={addressData ? addressData.phone1 : ''}
              accountId={state.account.id}
              addressId={addressData ? addressData.id : ''}
              wasteIn={locationData.getLocationById.wasteIn ? locationData.getLocationById.wasteIn.id : false}
              wasteOut={locationData.getLocationById.wasteOut ? locationData.getLocationById.wasteOut.id : false}
              onSuccess={(message, color, id) => dismissModals({ message, color, id })}
              onDismiss={dismissModals}
              tags={tagData}
              countryList={countryList}
              defaultCountry={locationData.getLocationById.address.country}
              metadata={metadata}
              refreshGrid={refreshGrid}
            />
          </Modal>
        )}
      </Fragment>
    );
  };

  return (
    <ColStyled name="ConfigurationLocationsContainer" container fullHeight>
      <ButtonContainer
        name="ConfigurationLocationsContainer__Controls"
        icon="map-marker-alt"
        iconColor="primary"
        title="Locations"
      >
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <Col span={3}>
            <ClearButton gridApi={gridApi.api} columnApi={gridApi.columnApi} title="Clear" />
          </Col>
          <Col span={3}>
            <Button
              name="ConfigurationLocationsContainer__Controls__Button--create"
              size="md"
              color="primary"
              onClick={() => showModal('create')}
            >
              + Add
            </Button>
          </Col>
          <Col span={3}>{gridApi?.api && <LocationUploader accountId={state.account.id} gridApi={gridApi.api} />}</Col>
          {canExport && (
            <Col span={3}>
              <ExportButton
                fetchRows={fetchRows}
                gridApi={gridApi?.api}
                columnApi={gridApi?.columnApi}
                defaultExportFileName={'Location Exports'}
                columnsToSkip={['Actions']}
                label="Export"
              />
            </Col>
          )}
        </ControlPanel>
      </ButtonContainer>

      {props.permissions.account.includes('getAll') && (
        <AccountSelectorContainer>
          <AccountSelector value={state.account.id} onChange={handleAccountChange} />
        </AccountSelectorContainer>
      )}

      <Fragment>{renderModals()}</Fragment>
      <Grid
        accountId={state.account.id}
        key={state.account.id}
        onEdit={(id) => showModal('update', id)}
        setGridApi={setGridApi}
        fetchRows={fetchRows}
      />
    </ColStyled>
  );
};
export default withPermissions(ConfigurationLocationsContainer);
