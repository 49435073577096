import React from 'react';
import { Text } from 'rio-ui-components';
import { formatDate } from '../../../utils';

export function DueDateCell(props) {
  const {
    data: { dueDate } = {}
  } = props;

  const date = formatDate(dueDate);

  return (
    dueDate && <Text align="left">{date}</Text>
  )
}
