import { Col, CircleIcon, Icon } from 'rio-ui-components';
import { Account, AccountType, DataType, FilterStrategy, Filters } from '@rio/rio-types';
import styled from 'styled-components';
import { Grid as AgGrid } from '../../../components/UI';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { mapSortModel, mapFilterModel } from '../../../utils';
import { getAccountSearchValue } from './useAccountSearchValueFromQueryParam';
import { RowModelType } from 'ag-grid-community';

const iconStyles = {
  circleIcon: {
    height: '32px',
    size: 'md',
  },
  Icon: {
    size: '24px',
  },
};
const StyledCircleIconCol = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xxl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const ImageLink = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface ActionCellProps {
  data: Account;
  onEdit: Function;
  onLogoEdit: Function;
}
const ActionCell = ({ data: { id }, onEdit, onLogoEdit }: ActionCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(id)}>
      <Icon name={`account-row__edit--${id}`} icon="pencil-alt" />
    </EditLinkStyled>
    <ImageLink onClick={() => onLogoEdit(id)}>
      <Icon name={`account-row__edit--${id}`} icon="image" />
    </ImageLink>
  </GridCellContainer>
);

interface AccountPermissionsProps {
  data: Account;
}
const AccountPermissions = ({ data: { accessControls, id } }: AccountPermissionsProps) => {
  const hasComply = accessControls.comply;
  const hasLearn = accessControls.learn;
  const hasData = Object.values(accessControls.data).some((item) => item === true);

  return (
    <GridCellContainer>
      <StyledCircleIconCol>
        <CircleIcon
          icon="graduation-cap"
          height={iconStyles.circleIcon.height}
          iconColor={hasLearn ? 'secondary' : 'neutral'}
          size={iconStyles.circleIcon.size}
          circleColor={hasLearn ? 'secondary' : 'neutral'}
          name={hasLearn ? `Account-${id}-Learn` : `Account-${id}-NoLearn`}
        />
      </StyledCircleIconCol>

      <StyledCircleIconCol>
        <CircleIcon
          icon="pencil-alt"
          height={iconStyles.circleIcon.height}
          iconColor={hasComply ? 'tertiary' : 'neutral'}
          size={iconStyles.circleIcon.size}
          circleColor={hasComply ? 'tertiary' : 'neutral'}
          name={hasComply ? `Account-${id}-Comply` : `Account-${id}-NoComply`}
        />
      </StyledCircleIconCol>

      <StyledCircleIconCol>
        <CircleIcon
          icon="chart-line"
          height={iconStyles.circleIcon.height}
          iconColor={hasData ? 'quaternary' : 'neutral'}
          size={iconStyles.circleIcon.size}
          circleColor={hasData ? 'quaternary' : 'neutral'}
          name={hasData ? `Account-${id}-Data` : `Account-${id}-NoData`}
        />
      </StyledCircleIconCol>
    </GridCellContainer>
  );
};

interface GridProps {
  onEdit: Function;
  onLogoEdit: Function;
  onBannerEdit: Function;
  setGridApi: Function;
  fetchRows: Function;
}
export function Grid({ setGridApi, fetchRows, onEdit, onLogoEdit, onBannerEdit }: GridProps) {
  const agGrid = useAgGrid({ autoFit: true });

  const options = {
    pagination: true,
    paginationPageSize: 25,
    rowModelType: 'serverSide' as RowModelType,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        height: 'auto',
      },
    },
    columnDefs: [
      {
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'name',
        resizable: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        filterParams: {
          newRowsAction: 'keep',
          values: Object.values(AccountType),
        },
        resizable: true,
      },
      {
        headerName: '',
        cellRenderer: 'permissions',
        resizable: false,
        filter: false,
        sortable: false,
      },
      {
        headerName: '',
        cellRenderer: 'action',
        filter: false,
        sortable: false,
        resizable: false,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  const components = {
    permissions: AccountPermissions,
    action: (props: any) => (
      <ActionCell {...props} onEdit={onEdit} onLogoEdit={onLogoEdit} onBannerEdit={onBannerEdit} />
    ),
  };

  return (
    <AgGrid
      {...options}
      cacheBlockSize={options.paginationPageSize}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, columnApi: params.columnApi });
        params.api.setServerSideDatasource({
          async getRows({ request, fail, success }) {
            try {
              const { startRow, sortModel, filterModel } = request;

              const searchValue = getAccountSearchValue();
              const filters: Filters = mapFilterModel(filterModel);
              if (searchValue) {
                if (!filters?.text) {
                  filters.text = [];
                }
                filters.text.push({
                  field: 'name',
                  filter: searchValue,
                  filterType: DataType.Text,
                  type: FilterStrategy.Contains,
                });
              }

              const { rows, totalRows } = await fetchRows({
                offset: startRow || 0,
                limit: options.paginationPageSize,
                sort: mapSortModel(sortModel),
                filters,
              });
              success({
                rowCount: totalRows,
                rowData: rows,
              });

              return {
                rows,
                totalRows,
              };
            } catch (err) {
              fail();
              throw err;
            }
          },
        });
      }}
    />
  );
}
