import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/engage/GetAvailableCourses.query.graphql';
import { useCurrentAccountId } from '../useCurrentAccountId';

export function useGetAvailableCoursesQuery(options = {}) {
  const accountId = useCurrentAccountId();

  const { data: { getAvailableCourses = {} } = {}, ...others } = useQuery(QUERY, {
    variables: {
      accountId,
      pageNumber: 0
    },
    ...options
  });

  const bundles = getAvailableCourses?.learnBundleList || [];

  const courses =
    (getAvailableCourses.data || []).map(({ id, ...others }) => {
      const inBundles = bundles.filter(({ courses }) => courses.indexOf(id) > -1).map(({ id }) => id);

      return {
        ...others,
        id,
        inBundles
      };
    }) || [];

  return {
    courses,
    bundles,
    ...others
  };
}
