import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import { Alias } from '@rio/rio-types';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

export type ActionFunction = (alias: Alias) => void;

export interface ActionCellProps {
  data: Alias;
  onEdit: ActionFunction;
}

export const ActionCell = ({ data, onEdit }: ActionCellProps) => (
  <GridCellContainer>
    <LinkStyled onClick={() => onEdit(data)}>
      <Icon name={`alias-row__edit--${data?.id}`} icon="pencil-alt" />
    </LinkStyled>
  </GridCellContainer>
);
