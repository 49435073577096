import { Mutation } from '@rio/rio-types';

import { DELETE_SURVEY, GET_SURVEY_LATEST, GET_SURVEYS_PAGE } from './queries';
import { useCurrentAccountId, useNotification, usePageSuspendingMutation } from '~/hooks';
import { GetSurveysResponse } from './useGet';

type DeleteSurveyResponse = Pick<Mutation, 'deleteSurvey'>;

export function useDelete() {
  const { showNotification } = useNotification();
  const accountId = useCurrentAccountId();
  const variables = {
    accountId,
  };
  return usePageSuspendingMutation<DeleteSurveyResponse>(DELETE_SURVEY, {
    refetchQueries: [{ query: GET_SURVEY_LATEST, variables }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      showNotification('Survey has been deleted.', 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },

    update: (cache, result) => {
      const getSurveysResults = cache.readQuery<GetSurveysResponse>({
        query: GET_SURVEYS_PAGE,
        variables,
      }) as GetSurveysResponse;

      const deleteSurvey = result?.data?.deleteSurvey;

      if (!deleteSurvey) {
        return;
      }

      if (getSurveysResults?.getSurveysPage) {
        cache.writeQuery({
          query: GET_SURVEYS_PAGE,
          variables,
          data: {
            rows: getSurveysResults.getSurveysPage.rows.filter((s) => s.id !== deleteSurvey),
          },
        });
      }
    },
  });
}
