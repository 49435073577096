import styled from 'styled-components';
import { Row, Col } from 'rio-ui-components';
import logoColor from '../../assets/img/RioLogo2021.png';

const ContainerStyled = styled(Row)`
  height: 100%;
  width: 100%;
`;

const ColStyled = styled(Col)`
  text-align: center;
`;

const LogoStyled = styled.div`
  height: 70px;
  width: 125px;
  background: no-repeat top center / contain url(${logoColor});
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  align-self: center;
  flex: 0 0 auto;
`;

export function AuthPage({ children, span = 3 }) {
  return (
    <ContainerStyled container item distribution="center" name="set-password-container">
      <ColStyled container item vdistribution="center" itemAlign="center" span={span}>
        <LogoStyled />
        {children}
      </ColStyled>
    </ContainerStyled>
  );
}
