import React from 'react';
import { OptionsProps, Select } from '@rio/ui-components';
import { useAllLegislation } from '~/hooks';

type Props = {
  value: string;
  name: string;
  disabled?: boolean;
  error?: string;
  onChange: (option: { value: string; label: string }) => void;
};

const loadingOption = { label: 'Loading...', value: '', disabled: true };
const noResultsFoundOption = { label: 'No legislations found', value: '', disabled: true };

export function LegislationSelect({ value, error, name, onChange, disabled }: Props) {
  const { legislations, error: loadingError, loading } = useAllLegislation();

  const options = legislations
    .map((l) => ({
      value: l.id,
      label: l.title,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)) as OptionsProps[];

  if (!loading && !error && options.length === 0) {
    options.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label="Legislation"
      placeholder="Please select the legislation item"
      value={value}
      options={loading ? [loadingOption] : options}
      error={Boolean(error || loadingError)}
      helperText={error || loadingError?.message || undefined}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
