import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Select, ErrorMessage, InlineError } from 'rio-ui-components';
import { GET_ACCOUNTS } from '../AccountsMultiSelect/index.queries';
import { usePermissions, useCurrentAccount } from '../../hooks';
import { Option, SelectEvent, MultiSelectEvent } from '../../types';
import { Account } from '@rio/rio-types';

interface AccountSelectorProps {
  onChange: Function;
  value?: string | string[] | null;
  allAccountsOption?: boolean;
  customOption?: Option;
  isMulti?: boolean;
  name?: string;
  error?: string | null;
}

const AccountSelector = ({
  onChange,
  value,
  allAccountsOption,
  customOption,
  isMulti,
  name = 'AccountSelector',
  ...rest
}: AccountSelectorProps) => {
  const permissions = usePermissions();
  const currentAccount = useCurrentAccount();
  const handleAccountChange = (e: SelectEvent | MultiSelectEvent) => {
    if (!onChange) {
      return;
    }
    if (isMulti) {
      onChange(
        (e as MultiSelectEvent).target.select.map(({ value, label }: Option) => ({
          name: label,
          id: value
        }))
      );
    } else {
      onChange({ name: e.target.label, id: e.target.value });
    }
  };

  const getAllPermitted = useMemo(() => permissions.account.includes('getAll'), [permissions]);

  const { loading, error, data } = useQuery(GET_ACCOUNTS, {
    variables: { limit: 9999 },
    skip: !getAllPermitted
  });

  const options = useMemo(() => {
    if (!data?.getAccounts?.rows || !currentAccount) {
      return [];
    }

    const options = data.getAccounts.rows.map((account: Account) => {
      return { value: account.id, label: account.name };
    });
    if (allAccountsOption) {
      options.unshift({ value: 'ALL_ACCOUNTS', label: 'All Accounts' });
    }
    if (customOption && !options.find((o: Option) => o.value === customOption.value)) {
      options.unshift(customOption);
    }
    if (!options.find((o: Option) => o.value === currentAccount.id)) {
      options.unshift({ value: currentAccount.id, label: currentAccount.name });
    }

    return options;
  }, [data?.getAccounts?.rows, allAccountsOption, customOption, currentAccount]);

  if (!getAllPermitted) {
    return null;
  }

  if (loading) {
    return <Select {...rest} placeHolder="Loading" />;
  }

  if (error) {
    return (
      <ErrorMessage error={error}>
        {({ icon }: { icon: string }) => (
          <InlineError name="AccountSelector__error" icon={icon} title="Account selector failed to load" />
        )}
      </ErrorMessage>
    );
  }

  return (
    <Select
      {...rest}
      value={Array.isArray(value) ? value.map((id) => options.find((o: Option) => o.value === id)) : value}
      name={name}
      onChange={handleAccountChange}
      options={options}
      defaultOptions={options}
      isMulti={isMulti}
    />
  );
};

export default AccountSelector;
