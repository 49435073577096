import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { Select } from 'rio-ui-components';
import { Query, ReportingYear } from '@rio/rio-types';
import { GET_REPORTING_YEARS } from './index.queries';
import { SelectEvent } from '../../../types';

interface ReportingYearSelectProps {
  name?: string;
  onChange: (id: string) => void;
  value: string | null;
}

export function ReportingYearSelect({ name, onChange, value }: ReportingYearSelectProps) {
  const { data } = useQuery<Pick<Query, 'getReportingYears'>>(GET_REPORTING_YEARS);

  const options = useMemo(() => {
    if (data?.getReportingYears) {
      return data.getReportingYears.map((year: ReportingYear) => ({
        value: year.id,
        label: year.name
      }));
    }
    return [];
  }, [data?.getReportingYears]);

  const handleChange = useCallback(
    (e: SelectEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <Select
      placeholder="Please select the reporting year type"
      name={name}
      value={value}
      options={options}
      onChange={handleChange}
    />
  );
}
