import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { GET_USER_BY_ID } from '../../graphql/queries/user/GetUserById.query';

// TODO: rewrite to useQuery after identifying the bug with msw
// which does not respond during the tests
export function useGetUserById(id) {
  const client = useApolloClient();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const { data } = await client.query({
        query: GET_USER_BY_ID,
        variables: { id },
      });

      setUser(data.getUserInfoById);
    };

    if (id) {
      getUser();
    } else {
      setUser(null);
    }
  }, [client, id]);

  return user;
}
