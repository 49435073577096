import React from 'react';
import styled from 'styled-components';
import { MarketBody } from './Catalog/MarketBody';
import { Cart as CartComponent } from '../../components/Cart';
import { useCart } from '../../hooks/market';

const PaddedDiv = styled.div`
  padding: 0px 30px;
`;

export function Cart() {
  const cart = useCart();

  return (
    <MarketBody route="cart" cart={cart}>
      <PaddedDiv>
        <CartComponent cart={cart} />
      </PaddedDiv>
    </MarketBody>
  );
}
