import React from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading, TextArea, Select } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const areaOptions = [
  { value: 'LEARN', label: 'Engage' },
  { value: 'COMPLY', label: 'Comply' },
  { value: 'DATA', label: 'Data' }
];

const thumbnailOptions = [
  { value: 'COMPLY', label: 'Comply' },
  { value: 'ELECTRICITY', label: 'Electricity' },
  { value: 'GAS', label: 'Gas' },
  { value: 'INTEL', label: 'Intelligence' },
  { value: 'LEARN', label: 'Engage' },
  { value: 'MATERIALS', label: 'Materials' },
  { value: 'MIXED', label: 'Mixed' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'WASTE', label: 'Waste' },
  { value: 'WATER', label: 'Water' }
];

class DashboardDetails extends React.Component {
  render() {
    return (
      <>
        <HeaderStyled size="lg">{this.props.heading}</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Dashboard Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput disabled={this.props.isSubmitted} onChange={this.props.handleChange} name="dashboardName" value={this.props.values.dashboardName.text} error={this.props.values.dashboardName.error} box />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Description</LabelStyled>
            </LabelContainerStyled>
            <TextArea size="lg" disabled={this.props.isSubmitted} onChange={this.props.handleChange} name="description" value={this.props.values.description.text} error={this.props.values.description.error} box />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Content Area</LabelStyled>
            </LabelContainerStyled>
            <Select name="area" disabled={this.props.isSubmitted} onChange={this.props.handleChange} value={this.props.values.area.text} error={this.props.values.area.error} options={areaOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Thumbnail Type</LabelStyled>
            </LabelContainerStyled>
            <Select name="thumbnail" disabled={this.props.isSubmitted} onChange={this.props.handleChange} value={this.props.values.thumbnail.text} error={this.props.values.thumbnail.error} options={thumbnailOptions} />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Provider Dashboard Id</LabelStyled>
            </LabelContainerStyled>
            <TextInput disabled={this.props.isSubmitted} onChange={this.props.handleChange} name="providerId" value={this.props.values.providerId.text} error={this.props.values.providerId.error} box />
          </ColStyled>
        </Row>
      </>
    );
  }
}

export { DashboardDetails };
