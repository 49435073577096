import { Model } from 'survey-core';
import styled from 'styled-components';
import { Modal } from 'rio-ui-components';
import 'survey-core/defaultV2.min.css';
import { ReceivedSurvey, SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { Survey as SurveyUi } from 'survey-react-ui';
import ContainerHeader from '../ContainerHeader';
import { Survey as SurveyIcon } from '../SvgIcons';
import { SurveyInfo } from './SurveyInfo';
import SurveyPagination from './SurveyPagination';
import { ThemeV2Json } from './Themes/theme-v2';
import { useEffect, useState } from 'react';
import { useIsV2 } from '~/hooks/useIsV2';

const SurveyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  & > .sd-root-modern {
    height: 100%;
  }
`;

const ModalStyled = styled(Modal)`
  & > div:nth-child(2) {
    width: calc(100vw - ${(props) => props.theme.geometry.xl.spacing});
    height: calc(100vh - ${(props) => props.theme.geometry.xl.spacing});
    border-radius: 0;
    z-index: 9;

    & > * {
      padding: 0;
    }
  }
`;

const StyledSurveyUi = styled(SurveyUi)`
  .sd-title {
    color: #4d505b;
    font-weight: 300;
  }
`;

interface BaseProps {
  model: Model | null;
  onDismiss: () => void;
  expanded: boolean;
  survey: ReceivedSurvey;
  children?: React.ReactNode;
  header?: React.ReactNode;
  newSubscriptionUpdate?: boolean;
}

interface ReadonlyProps extends BaseProps {
  readonly: true;
  contributor: string;
  sendFeedback: (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;
}

interface WorksheetProps extends BaseProps {
  readonly: false;
  onValueChanged: Function;
}

type SurveyLayoutProps = ReadonlyProps | WorksheetProps;

export const SurveyLayout = (props: SurveyLayoutProps) => {
  const v2 = useIsV2();
  const [survey, setSurvey] = useState<Model | null>(null);

  useEffect(() => {
    if (props.model) {
      if (v2) props.model.applyTheme(ThemeV2Json);
      setSurvey(props.model);
    }
  }, [props.model]);

  const body = (
    <SurveyContainer>
      {props.readonly ? (
        <SurveyInfo readonly survey={props.survey} sendFeedback={props.sendFeedback} contributor={props.contributor} />
      ) : (
        <SurveyInfo
          readonly={false}
          showAutoSave
          newSubscriptionUpdate={props.newSubscriptionUpdate}
          survey={props.survey}
        />
      )}

      {props.header}
      <StyledSurveyUi
        model={survey}
        rootNodeId="Survey__element"
        mode={props.readonly ? 'display' : undefined}
        onValueChanged={!props.readonly ? props.onValueChanged : undefined}
      />
      <SurveyPagination model={survey} />
      {props.children}
    </SurveyContainer>
  );
  return props.expanded ? (
    <ModalStyled height="auto" maxHeight="100%" span={12} onDismiss={props.onDismiss} dismissable enableEscapeKey show>
      <ContainerHeader title={props.survey.name} iconColor="#FD978B" icon={<SurveyIcon circle="#FD978B" />} />
      {body}
    </ModalStyled>
  ) : (
    <>{!!survey && body}</>
  );
};
