import { useQuery, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { GET_ID_TOKEN } from '../queries/auth';
import { saveUserSession, sessionNotLoaded } from '../resolvers/auth';

export function useUserToken() {
  const client = useApolloClient();
  const { data } = useQuery(GET_ID_TOKEN);

  useEffect(() => {
    const getToken = async () => {
      try {
        const session = await Auth.currentSession();
        await saveUserSession(session, client);
      } catch {
        sessionNotLoaded(client);
      }
    };

    if (!data || !data.idToken) {
      getToken();
    }
  });

  return {
    token: data ? data.idToken : null,
    loading: data ? data.sessionLoading : true,
  };
}
