import { dromoConfiguration, DromoConfiguration } from './base';

const name = 'Investment Transactions';

export function getInvestmentConfiguration(): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      templateDownloadFilename: 'investment-transactions.csv',
    },
  };
}
