import { Text, Icons, Button, styled } from '@rio/ui-components';

type ExpandButtonProps = {
  expand: () => void;
};

const ButtonStyled = styled(Button)`
  height: 40px;
`;

export const ExpandButton = ({ expand }: ExpandButtonProps) => (
  <ButtonStyled variant="outlined" onClick={expand}>
    <Icons.Fullscreen fontSize="small" />
    <Text typescale="body" size="medium">
      Expand
    </Text>
  </ButtonStyled>
);
