import React, { FC, useEffect, useMemo, useState } from 'react';
import { CreateDocumentRecordInput, DocumentLibrary, Tag } from '@rio/rio-types';
import get from 'lodash/get';
import documentLibraries from '../../documentLibraries';
import documentCategories from '../../documentCategories.json';
import consignmentNoteTypes from '../../consignmentNoteTypes.json';
import { getReferenceIdFromFileName } from '../../utils';
import { useCurrentAccountId, useCurrentUser, useAccessControls } from '~/hooks';
import { TagsMultiSelect } from '~/components/TagsMultiSelect/v2';
import { SupplierSelector } from '~/components/SuppliersSelector/v2';
import { NullableObject } from '~/types';
import { DocumentLibraryFolder } from '../../types';
import { Grid, Select, Checkbox, TextField, DatePicker, MultiSelect } from '@rio/ui-components';

const allLibraries: DocumentLibraryFolder[] = documentLibraries as DocumentLibraryFolder[];

interface DocumentDetailsProps {
  document: NullableObject<CreateDocumentRecordInput>;
  isSubmitting?: boolean;
  onChange: (values: NullableObject<CreateDocumentRecordInput>) => void;
  predefinedValues?: NullableObject<CreateDocumentRecordInput>;
}

export const DocumentDetails: FC<DocumentDetailsProps> = ({
  document,
  isSubmitting,
  onChange,
  predefinedValues = {},
}) => {
  const currentUser = useCurrentUser();
  const { data: accessControls } = useAccessControls();
  const accountId = useCurrentAccountId();
  const [parseIdFromFileName, setParseIdFromFileName] = useState(true);
  const needsType = document.category === 'Consignment Note (EA)' || document.category === 'Consignment Note (SEPA)';
  const tags = document.tags as Tag[] | undefined;
  const [notesWarning, setNotesWarning] = useState<boolean>(false);

  const allowedTags = useMemo(() => currentUser?.allowedTags || [], [currentUser]);

  const filteredLibraries = useMemo(
    () => allLibraries.filter((item) => get(accessControls, item.accessControlIndexer as string)),
    [accessControls]
  );

  useEffect(() => {
    if (!tags && allowedTags?.length) {
      onChange({
        ...document,
        tags: allowedTags.map((tag) => tag?.tagName as string).filter(Boolean),
      });
    }
  }, [tags, allowedTags, document, onChange]);

  return (
    <Grid container columns={12} rowGap={3} columnGap={3}>
      <Grid item xs={12}>
        <Select
          label="Select document library"
          value={document.library}
          options={filteredLibraries.map((library) => ({
            label: library.title,
            value: library.library,
          }))}
          disabled={!!predefinedValues.library || isSubmitting}
          onChange={({ value }) => {
            onChange({
              ...document,
              library: value as DocumentLibrary,
            });
          }}
        />
      </Grid>
      {document.library && (
        <>
          <Grid item xs={12}>
            <Select
              label="Select document category"
              value={document.category}
              options={documentCategories.filter((cat) => cat.libraries.includes(document.library as DocumentLibrary))}
              disabled={!!predefinedValues.category || isSubmitting}
              onChange={({ value }) => {
                onChange({
                  ...document,
                  category: value,
                });
              }}
            />
          </Grid>
          {document.category && (
            <>
              {needsType && (
                <Grid item xs={12}>
                  <MultiSelect
                    label="Select document type"
                    value={Array.isArray(document.type) ? document.type : []}
                    options={consignmentNoteTypes}
                    disabled={isSubmitting}
                    onChange={(value) => {
                      onChange({
                        ...document,
                        type: value.map((option) => option.value),
                      });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Checkbox
                  label="Parse ID From Filename"
                  checked={parseIdFromFileName}
                  value={parseIdFromFileName}
                  onChange={() => {
                    const shouldParse = !parseIdFromFileName;
                    setParseIdFromFileName(shouldParse);
                    if (shouldParse) {
                      onChange({
                        ...document,
                        referenceId: getReferenceIdFromFileName(get(document, 'fileName') as string),
                      });
                    }
                  }}
                />
              </Grid>
              {!parseIdFromFileName && (
                <Grid item xs={12}>
                  <TextField
                    label={`${document.category} ID`}
                    value={document.referenceId || ''}
                    onChange={(e) => {
                      onChange({
                        ...document,
                        referenceId: e.target.value,
                      });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <DatePicker
                  label="Review date (optional)"
                  disabled={isSubmitting}
                  onChange={(reviewDate) => {
                    onChange({
                      ...document,
                      reviewDate: new Date(reviewDate).toISOString(),
                    });
                  }}
                  value={document.reviewDate ? new Date(document.reviewDate) : undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <SupplierSelector
                  label="Select supplier (optional)"
                  accountId={accountId}
                  value={document?.supplierId || ''}
                  onChange={(s) =>
                    onChange({
                      ...document,
                      supplierId: s.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TagsMultiSelect
                  label="Select Tags (optional)"
                  value={
                    tags?.map((tag) => {
                      if (typeof tag === 'string') {
                        return tag;
                      }

                      return {
                        value: tag.id || (tag as unknown as { value: string }).value,
                        label: tag.tagName || (tag as unknown as { label: string }).label,
                      };
                    }) || []
                  }
                  accountId={accountId}
                  onChange={(value) => {
                    onChange({
                      ...document,
                      tags: value.map((o) => o.value).filter(Boolean),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  value={document.notes || ''}
                  error={notesWarning}
                  helperText={notesWarning && 'Notes can not contain more than 255 symbols'}
                  onChange={(e) => {
                    setNotesWarning(false);
                    if (e?.target?.value?.length > 255) {
                      setNotesWarning(true);
                      return;
                    }
                    onChange({
                      ...document,
                      notes: e.target.value,
                    });
                  }}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
