import { useQuery } from '@apollo/client';
import { useQueryParam, StringParam } from 'use-query-params';
import { Query, Aspect } from '@rio/rio-types';
import { useCurrentAccountId } from '../../../hooks';
import { GET_ASPECT_BY_ID_AND_ACCOUNT_ID } from '../../../graphql/queries/governance/GetAspectByIdAndAccountId.query.js';
import { Nullable } from '../../../types';

type Response = Pick<Query, 'getAspectById'>;

type HookResult = [Nullable<Aspect>, (aspectId: string) => void];

export function useEditAspectFromQueryParam(): HookResult {
  const [editAspect, setEditAspect] = useQueryParam('showEditModal', StringParam);
  const accountId = useCurrentAccountId();
  const { data } = useQuery<Response>(GET_ASPECT_BY_ID_AND_ACCOUNT_ID, {
    skip: !accountId || !editAspect,
    variables: {
      accountId,
      id: editAspect,
    },
  });
  return [data?.getAspectById, setEditAspect];
}
