import { useQuery } from '@apollo/client';
import { GovernanceAccessControls, Account } from '@rio/rio-types';
import { RowNode } from 'ag-grid-community';
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Search, LoadingIndicator, ErrorMessage, ContainerError, Button, Icon } from 'rio-ui-components';
import { CellEvent, IRowModel, GetContextMenuItemsParams, GridApi } from 'ag-grid-community';
import { LegislationTagSelect } from '../../../../components/LegislationTagSelect';
import { ClearButton } from '../../../../components/ClearButton';
import { SelectEvent, HtmlDiv, Dictionary, Nullable } from '../../../../types';
import * as iconIds from '../../../../constants/iconIds';
import NoResults from '../../../../components/NoResults';
import PageHeader from '../../../../components/PageHeader';
import { Grid } from '../../../../components/UI';
import { useAgGrid } from '../../../../hooks/useAgGrid';
import { useCurrentAccountId } from '../../../../hooks/useCurrentAccountId';
import { useTags, useSearch } from '../../../../hooks';
import areas from '../../areas';
import { getDepartments } from '../../../../hooks/useCreateDepartment';
import { useAccountById } from '../../../../hooks/useAccountById';
import { checkForActiveReviewFlag, startExportData } from './common';
import { GET_ALL_LEGISLATION } from './index.queries';
import { LegislationStatusIcon } from './statusIcon';
import { ParamsData, CustomLegislation } from './types';
import { columnDefsAll, columnDefsWithoutTag } from './legislationGridConfig';
import statuses from './legislationStatuses.json';

type FlagProps = {
  name: string;
  alt: string;
  flag: keyof typeof iconIds;
};

interface IRowModelFull extends IRowModel {
  rowsToDisplay: RowNode[];
}

const RowStyled = styled(Row)`
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ContainerStyled = styled.div<HtmlDiv>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const LoadingContainerStyled = styled.div<HtmlDiv>`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ContentContainer = styled.div<HtmlDiv>`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchCol = styled(Col)`
  max-width: ${(props) => props.desireWidth || '55%'};
  display: flex;
  flex-direction: revert;
`;

const Flag = styled.div<FlagProps>`
  height: 22px;
  width: 32px;
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: url(${(props: FlagProps) => iconIds[props.flag]}) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  display: inline-block;
`;

const ColLeft = styled(Col)`
  margin-right: 15px;
  flex-grow: 1;
  min-width: 150px;
`;

const ColLeftWide = styled(Col)`
  margin-right: 15px;
  flex-grow: 2;
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.danger.normal.background};
  margin-right: 10px;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const ListCountries: React.FC<ParamsData> = React.memo((params: ParamsData) => {
  return (
    <>
      {params.data.countries.length
        ? params.data.countries.map((value: string) => {
            return (
              <Flag
                name="LegislationTile__Flag"
                flag={value as FlagProps['flag']}
                key={value}
                alt={value}
                title={value}
              />
            );
          })
        : ''}
    </>
  );
});

ListCountries.displayName = 'ListCountriesChild';

const LegislationStatus: React.FC<{ value: Nullable<string> }> = React.memo((props: { value: Nullable<string> }) => {
  const selectedStatus = statuses.find((item) => item.value === props.value);

  return selectedStatus?.name ? (
    <>
      <LegislationStatusIcon status={props.value} /> {selectedStatus?.name}
    </>
  ) : (
    <>{props.value}</>
  );
});

LegislationStatus.displayName = 'LegislationStatusChild';

const FILTER_KEY = 'LEGISLATION_LIBRARY_FILTERS';

const ActiveFlag = (params: ParamsData) => {
  const activeFlag = checkForActiveReviewFlag(params.data.lastFlaggedForReview, params.data.lastClearedReviewFlag);
  return activeFlag && <StyledIcon icon={'history'} title="Rio has detected a change to this legislation." />;
};

const getColumnDefs = (isHaveTagLibraryAccess: boolean) => {
  return isHaveTagLibraryAccess ? columnDefsWithoutTag : columnDefsAll;
};

const components: Dictionary<any> = {
  listCountries: ListCountries,
  legislationStatusIcon: LegislationStatus,
  activeFlag: ActiveFlag,
};

const LegislationLibrary = ({ accessControls }: { accessControls: GovernanceAccessControls }) => {
  const navigate = useNavigate();
  const agGrid = useAgGrid();
  const HAS_TAG_LIBRARY_ACCESS = accessControls.tagLevelLegislation;
  const accountId = useCurrentAccountId();
  const accountInfo: Account = useAccountById(accountId);

  const tags = useTags();
  const [selectedTag, setSelectedTag] = useState('');
  const tagLevelRestrictedUser = HAS_TAG_LIBRARY_ACCESS && !selectedTag;

  const { data, error, loading, refetch } = useQuery(GET_ALL_LEGISLATION, {
    variables: { accountId: accountId, tagId: selectedTag },
    fetchPolicy: 'network-only',
    skip: tagLevelRestrictedUser,
  });

  const results = data?.getAllLegislation || [];
  const availableGovernancePages = areas.filter(
    (area) => !area.comingSoon && accessControls[area.accessIndex as keyof GovernanceAccessControls]
  );

  const savedSearch = localStorage.getItem(FILTER_KEY) ? JSON.parse(localStorage.getItem(FILTER_KEY) as string) : null;
  const [searchValue, setSearchValue, searchedLegislation] = useSearch<CustomLegislation>(
    results,
    ['title'],
    savedSearch?.searchValue
  );

  const exportData = (api: GridApi) => {
    const selectedRows: Nullable<IRowModelFull> = api.getModel() as IRowModelFull;
    const displayRows = selectedRows.rowsToDisplay.map((item: RowNode) => item.data);

    startExportData(displayRows, HAS_TAG_LIBRARY_ACCESS);
  };

  const onCellClicked = (event: CellEvent) => {
    navigate(selectedTag ? `./${event.data.id}/${selectedTag}` : `./${event.data.id}`);
  };

  if (accountInfo?.departments) {
    searchedLegislation.forEach((item: CustomLegislation) => {
      item.departments = getDepartments(accountInfo?.departments || [], item.departmentIds);
    });
  }

  useEffect(() => {
    localStorage.setItem(
      FILTER_KEY,
      JSON.stringify({
        searchValue,
      })
    );
  }, [searchValue]);

  const legislationData = useMemo(
    () =>
      searchedLegislation.map((item: CustomLegislation) =>
        !item.status
          ? {
              ...item,
              status: 'TBD',
            }
          : item
      ),
    [searchedLegislation]
  );

  return (
    <ContainerStyled name="LegislationList">
      <PageHeader
        name="LegislationList"
        breadcrumbs={[{ title: 'Governance', to: '/governance' }, { title: 'Legislation' }]}
        icon="pencil-alt"
        iconColor="tertiary"
        title="PageHeader"
        isDropdown={true}
        dropdownItems={availableGovernancePages}
        dropdownDefault={'Legislation'}
      >
        <SearchCol item desireWidth={HAS_TAG_LIBRARY_ACCESS && '65%'}>
          {HAS_TAG_LIBRARY_ACCESS && (
            <ColLeftWide>
              <HeaderRightContainer>
                <LegislationTagSelect
                  tags={tags}
                  value={selectedTag}
                  onChange={(e: SelectEvent) => setSelectedTag(e.target.value)}
                  error=""
                />
              </HeaderRightContainer>
            </ColLeftWide>
          )}
          <ColLeftWide>
            <Search
              name="ConfigurationAspectContainer__Controls__Search"
              value={searchValue}
              onChange={(event: SelectEvent) => setSearchValue(event.target.value)}
              hideButton
            />
          </ColLeftWide>
          <ColLeft>
            <ClearButton gridApi={agGrid.api} columnApi={agGrid.columnApi} onClick={() => setSearchValue('')} />
          </ColLeft>
          <ColLeft>
            <Button
              color="tertiary"
              onClick={() => {
                if (agGrid?.api) {
                  exportData(agGrid.api);
                }
              }}
            >
              Export Data
            </Button>
          </ColLeft>
        </SearchCol>
      </PageHeader>
      {(loading || !tags) && (
        <LoadingContainerStyled>
          <LoadingIndicator size="md" name="LegislationList__Loading" />
        </LoadingContainerStyled>
      )}
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError name="LegislationList__Error" icon={icon} title={title} body={body} retry={refetch} />
          )}
        </ErrorMessage>
      )}

      <RowStyled container item>
        <Grid
          defaultColDef={{ sortable: true, resizable: true, filter: true, wrapText: true, autoHeight: true }}
          columnDefs={getColumnDefs(HAS_TAG_LIBRARY_ACCESS)}
          components={components}
          rowData={legislationData}
          onFirstDataRendered={agGrid.onFirstDataRendered}
          onGridReady={agGrid.onGridReady}
          onFilterChanged={agGrid.onFilterChanged}
          onSortChanged={agGrid.onSortChanged}
          onColumnVisible={agGrid.onSaveGridColumnState}
          onColumnPinned={agGrid.onSaveGridColumnState}
          onColumnResized={agGrid.onSaveGridColumnState}
          onColumnMoved={agGrid.onSaveGridColumnState}
          onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
          onColumnValueChanged={agGrid.onSaveGridColumnState}
          onColumnPivotChanged={agGrid.onSaveGridColumnState}
          onCellClicked={onCellClicked}
          rowSelection="single"
          suppressCsvExport={true}
          getContextMenuItems={({ api }: GetContextMenuItemsParams) => {
            return [
              'copy',
              'copyWithHeaders',
              'separator',
              {
                name: 'Excel Export',
                action: () => {
                  if (api) {
                    exportData(api);
                  }
                },
              },
            ];
          }}
        />
      </RowStyled>
      {!loading && !error && tags && (!results || tagLevelRestrictedUser) && (
        <ContentContainer>
          {!results && !tagLevelRestrictedUser && (
            <NoResults name="LegislationList__NoResults" title="There are no results for this search." />
          )}
          {tagLevelRestrictedUser && (
            <NoResults
              name="LegislationList__NoResults"
              title="This account operates a tag level legal register however your user is not assigned to any applicable tags. Contact your business admin or support for more information."
            />
          )}
        </ContentContainer>
      )}
    </ContainerStyled>
  );
};

export default React.memo(LegislationLibrary);
