import { RenderQuestionUnion } from './RenderQuestionUnion';
import {
  QuestionUnion,
  ManagementSystem,
  QuestionSequence as IQuestionSequence,
  QuestionSequenceRepeatable
} from '@rio/rio-types';
import { QuestionRow, QuestionCol } from './Elements';
import { AnswerQuestionFunction } from './types';

interface QuestionSequenceProps {
  question: IQuestionSequence | QuestionSequenceRepeatable;
  questions: QuestionUnion[];
  onAnswer: AnswerQuestionFunction;
  framework: ManagementSystem;
}

const getColSpan = (question: QuestionUnion, questions: QuestionUnion[]) => {
  if (question.displaySettings?.colSpan) {
    return question.displaySettings.colSpan;
  } else {
    const containsLongFormulation = questions.some((q) => q.formulation.length >= 75);
    return questions.length === 1 || question.__typename === 'OpenEndedRichQuestion' || containsLongFormulation
      ? 12
      : 6;
  }
};

export function QuestionSequence({ framework, question, questions, onAnswer }: QuestionSequenceProps) {
  return (
    <QuestionRow wrap="wrap" container item>
      {questions.map((q: QuestionUnion) => (
        <QuestionCol key={q.id} span={getColSpan(q, questions)} container item>
          <RenderQuestionUnion framework={framework} question={q} onAnswer={onAnswer} parent={question} />
        </QuestionCol>
      ))}
    </QuestionRow>
  );
}
