import React from 'react';
import styled from 'styled-components';
import LegislationCountrySelect from '../../../components/LegislationCountrySelect';
import { Col, Row, Label, TextInput, Heading, TextArea } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LegislationDetails = ({ heading, isSubmitting, legislation, setLegislation }) => {
  const handleChange = (event) => {
    setLegislation({
      ...legislation,
      [event.target.name]: event.target.value
    });
  };
  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Legislation Title</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="title"
            value={legislation.title}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Synopsis</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="synopsis"
            value={legislation.synopsis}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Links (Comma Seperated)</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="links"
            value={legislation.links}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Countries</LabelStyled>
          </LabelContainerStyled>
          <LegislationCountrySelect
            name="countries"
            onChange={(e) => {
              handleChange({
                target: {
                  value: e.target.select,
                  name: 'countries'
                }
              });
            }}
            value={legislation.countries}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Gov API Path (e.g &ldquo;/asp/2003/3&ldquo;)</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            disabled={isSubmitting}
            onChange={(e) => handleChange(e)}
            name="apiData"
            value={legislation.apiData}
            box
          />
        </ColStyled>
      </Row>
    </>
  );
};

export default LegislationDetails;
