import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PropertySheet, Col } from 'rio-ui-components';

const Column = styled(Col)`
  padding: ${(props) => `${props.theme.geometry.xs.spacing}`};
`;

function AddressPropertySheet(props) {
  const { id, address1, address2, address3, city, postcode, phone, isSubmitted: disabled, handleChange, countryList, selectCountry, handleSelectChange } = props;

  return (
    <>
      <PropertySheet.Row container align="between">
        <PropertySheet.TextInputColumn id={`${id}-address1`} disabled={disabled} name="address1" onChange={handleChange} label="Address 1" value={address1.text} error={address1.error} />
        <PropertySheet.TextInputColumn id={`${id}-address2`} disabled={disabled} name="address2" onChange={handleChange} label=" Address 2 (Optional)" value={address2.text} error={address2.error} />
      </PropertySheet.Row>
      <PropertySheet.Row container align="between">
        <PropertySheet.TextInputColumn id={`${id}-address3`} disabled={disabled} name="address3" onChange={handleChange} label="Address 3 (Optional)" value={address3.text} error={address3.error} />
        <PropertySheet.TextInputColumn id={`${id}-city`} disabled={disabled} name="city" onChange={handleChange} label="City" value={city.text} error={city.error} />
      </PropertySheet.Row>
      <PropertySheet.Row container align="between">
        <Column span="12">
          <PropertySheet.TextInputColumn id={`${id}-postcode`} disabled={disabled} name="postcode" onChange={handleChange} label="Postcode" value={postcode.text} error={postcode.error} />
        </Column>
        <Column span="12">
          <PropertySheet.SelectColumn id={`${id}-country`} disabled={disabled} name="selectCountry" onChange={handleSelectChange} value={selectCountry} options={countryList} label="Country" />
        </Column>
      </PropertySheet.Row>
      <PropertySheet.Row container align="between">
        <PropertySheet.TextInputColumn id={`${id}-phone`} disabled={disabled} name="phone" onChange={handleChange} label="Phone (Optional)" value={phone.text} error={phone.error} />
      </PropertySheet.Row>
    </>
  );
}

AddressPropertySheet.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  address1: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  address2: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  address3: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  city: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  postcode: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  phone: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  selectCountry: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string }),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
};

export default AddressPropertySheet;
