import { useMutation } from '@apollo/client';
import { User } from '@rio/rio-types';
import * as Sentry from '@sentry/react';
import SEND_EMAIL from '../graphql/mutations/contact/sendEmail.mutation.graphql';
import { useCurrentUser } from './useCurrentUser';
import { useNotification } from './useNotification';
import { formatError } from '../utils';

function getFullname(user: User) {
  return `${user.first_name} ${user.last_name}`;
}

export function formatLetter(error: Error, user: User) {
  return `
    <div>Please report this error as a bug.</div>
    <div>User: ${getFullname(user)}</div>
    <div>URL: ${window.location.href}</div>
    ${formatError(error)}
  `;
}

export function useReportError() {
  const user = useCurrentUser();
  const { showNotification } = useNotification();
  const [sendEmail] = useMutation(SEND_EMAIL);
  return async (error: Error) => {
    try {
      Sentry.captureException(error);

      if (!user) throw new Error('Current user is not defined');

      await sendEmail({
        variables: {
          replacements: JSON.stringify({
            topic: 'Bug report',
            name: getFullname(user),
            email: user.email,
            phone: user.phone,
            company: user.account?.name,
            enquiry: formatLetter(error, user),
          }),
        },
      });
      showNotification('Thank you for reporting an issue. We will get onto this soon!', 'success');
    } catch (err) {
      showNotification(
        `Sorry, we couldn't automatically report an issue. Please use hello@dittosustainability.ai to contact us.`,
        'success'
      );
    }
  };
}
