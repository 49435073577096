"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.a11yProps = exports.tabsLink = exports.tabs = void 0;
exports.tabs = [
    {
        label: 'Item One',
        children: 'Item One Panel',
    },
    {
        label: 'Item Two',
        children: 'Item Two Panel',
    },
    {
        label: 'Item Three',
        children: 'Item Three Panel',
    },
];
exports.tabsLink = exports.tabs.map((tab, idx) => {
    return { ...tab, to: `./tab${idx}` };
});
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
exports.a11yProps = a11yProps;
