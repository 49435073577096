import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import GET_HEATSTEAM_TRANSACTIONS_PAGE from '../../../../graphql/queries/heatSteam/GetHeatSteamTransactionsPage.query.graphql';
import { FactorUsedCell } from '../../CellRenderers';
import { TariffReference, UploadReferenceCell, DocumentReference } from '../../CellRenderers';
import { useHeatSteamGridConfig } from './useHeatSteamGridConfig';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title: 'Heat and Steam' }];

export function HeatSteamTransactionsGrid() {
  const client = useApolloClient();
  const config = useHeatSteamGridConfig();
  return (
    <TransactionGrid
      title="Heat and Steam"
      defaultExportFileName="heatsteam-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../heatSteam/uploads"
      config={{
        ...config,
        components: {
          tariffReference: TariffReference,
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={async (variables) => {
        const {
          data: {
            getHeatSteamTransactionsPage: { totalRows, rows },
          },
        } = await client.query({
          query: GET_HEATSTEAM_TRANSACTIONS_PAGE,
          variables,
          fetchPolicy: 'network-only',
        });

        return {
          rows,
          totalRows,
        };
      }}
    />
  );
}
