import styled from 'styled-components';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useAccessControls } from '../../hooks/useAccessControls';
import DataSubjects from './DataSubjects';
import { ElectricityTransactionsGrid } from './ElectricityContainer/TransactionsContainer';
import { RefrigerantsTransactionsGrid } from './RefrigerantsContainer/TransactionsContainer';
import { HeatSteamTransactionsGrid } from './HeatSteamContainer/TransactionsContainer';
import { AliasContainer } from './AliasContainer';
import WasteUploadContainer from './WasteContainer/UploadContainer';
import { WasteTransactionsGrid } from './WasteContainer/WasteTransactionsContainer';
import ElectricityUploadContainer from './ElectricityContainer/UploadContainer';
import HeatSteamUploadContainer from './HeatSteamContainer/UploadContainer';
import GasUploadContainer from './GasContainer/UploadContainer';
import WaterUploadContainer from './WaterContainer/UploadContainer';
import { GasTransactionsGrid } from './GasContainer/GasTransactionsContainer';
import { WaterTransactionsContainer } from './WaterContainer/TransactionsContainer';
import { TransportTransactions, UploadTransportTransactions } from './TransportContainer';
import { FuelTransactionsGrid } from './FuelContainer/FuelTransactions';
import { UploadFuelTransactions } from './FuelContainer/UploadFuelTransactions';
import { MaterialsTransactionsGrid } from './MaterialsContainer/TransactionsContainer';
import MaterialsUploadContainer from './MaterialsContainer/UploadContainer';
import OcrReviewContainer from './OcrReviewContainer';
import { BuildingEnergyRatingsGrid } from './BuildingEnergyRatings';
import { HotelStaysTransactionsGrid } from './HotelStaysContainer';
import HotelStaysUploadContainer from './HotelStaysContainer/UploadContainer';
import DataOverviewContainer from './DataOverview';
import { AccessControls } from '@rio/rio-types';
import RefrigerantsUploadContainer from './RefrigerantsContainer/UploadContainer';
import { InvestmentTransactionsGrid, InvestmentUploadContainer } from './InvestmentContainer';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GetViewContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();
  if (dataAccessControls?.[subject!]) {
    switch (subject!) {
      case 'waste':
        return <WasteTransactionsGrid />;
      case 'electricity':
        return <ElectricityTransactionsGrid />;
      case 'gas':
        return <GasTransactionsGrid />;
      case 'water':
        return <WaterTransactionsContainer />;
      case 'transport':
        return <TransportTransactions />;
      case 'fuel':
        return <FuelTransactionsGrid />;
      case 'materials':
        return <MaterialsTransactionsGrid />;
      case 'refrigerants':
        return <RefrigerantsTransactionsGrid />;
      case 'heatSteam':
        return <HeatSteamTransactionsGrid />;
      case 'buildingEnergyRatings':
        return <BuildingEnergyRatingsGrid />;
      case 'hotelStays':
        return <HotelStaysTransactionsGrid />;
      case 'investment':
        return <InvestmentTransactionsGrid />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

const GetUploadContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();

  if (dataAccessControls[subject!]) {
    switch (subject) {
      case 'waste':
        return <WasteUploadContainer />;
      case 'electricity':
        return <ElectricityUploadContainer />;
      case 'gas':
        return <GasUploadContainer />;
      case 'water':
        return <WaterUploadContainer />;
      case 'transport':
        return <UploadTransportTransactions />;
      case 'fuel':
        return <UploadFuelTransactions />;
      case 'materials':
        return <MaterialsUploadContainer />;
      case 'hotelStays':
        return <HotelStaysUploadContainer />;
      case 'heatSteam':
        return <HeatSteamUploadContainer />;
      case 'refrigerants':
        return <RefrigerantsUploadContainer />;
      case 'investment':
        return <InvestmentUploadContainer />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

const GetOcrReviewContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();

  if (dataAccessControls[subject!]) {
    switch (subject) {
      case 'electricity':
      case 'gas':
      case 'water':
      case 'heatSteam':
        return <OcrReviewContainer utility={subject.toUpperCase()} />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

const DataContainer = () => {
  const { data: accessControls } = useAccessControls();
  const dataAccessControls = accessControls.data;
  const RedirectToView = () => <Navigate to="./view" />;

  function dataHome(access: AccessControls) {
    if (access.dataTracker) {
      return <DataOverviewContainer />;
    }
    // Show old data page for other accounts
    return <DataSubjects fullAccess={access.data} />;
  }

  const dataHomeElement = dataHome(accessControls);

  return (
    <ContainerStyled>
      {dataAccessControls ? (
        <Routes>
          <Route path="" element={dataHomeElement} />
          <Route path=":subject/view" element={<GetViewContainerBySubject dataAccessControls={dataAccessControls} />} />
          <Route
            path=":subject/uploads"
            element={<GetUploadContainerBySubject dataAccessControls={dataAccessControls} />}
          />
          <Route
            path=":subject/uploads/ocr"
            element={<GetOcrReviewContainerBySubject dataAccessControls={dataAccessControls} />}
          />
          <Route path=":subject/uploads/alias/:id" element={<AliasContainer />} />
          <Route path=":subject/" element={<RedirectToView />} />
          <Route path="*" element={<Navigate to="/data" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="" element={dataHomeElement} />
          <Route path="*" element={<Navigate to="/data" />} />
        </Routes>
      )}
    </ContainerStyled>
  );
};

export default DataContainer;

export { dromoConfiguration } from './DromoConfiguration';
export { default as UploadContainer } from './UploadContainer';
