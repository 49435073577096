import { Button, Logo, Text, TextField, styled } from '@rio/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginErrorResult } from './useLoginForm';
import { GetLoginErrorMessageId } from '../utils';

const V2TextFieldStyled = styled(TextField)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export type V2LoginFormProps = {
  handleSubmit: (e: React.FormEvent) => void;
  email: { value: string; error: string };
  password: { value: string; error: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleForgotPassword: () => void;
  isLoading: boolean;
  loginError: LoginErrorResult | null;
};

const FormStyled = styled('form')`
  width: 100%;
`;

const RowForLinks = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
`;

const TextLinkStyled = styled(Button)`
  flex: 1;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }

  font-family: 'Plus Jakarta Sans';
`;

const RenderLoginError = ({ loginError }) => {
  if (!loginError) return null;
  const messageId = GetLoginErrorMessageId(loginError);
  return (
    <Text sx={{ marginTop: '0.5rem', textAlign: 'center' }} color="danger" size="small" typescale="label">
      <FormattedMessage id={messageId} />
    </Text>
  );
};

export function V2LoginForm({
  handleSubmit,
  handleChange,
  handleForgotPassword,
  email,
  password,
  isLoading,
  loginError,
}: V2LoginFormProps) {
  const intl = useIntl();
  return (
    <>
      <Logo height="70px" style={{ marginTop: '1rem', marginBottom: '2rem', marginLeft: '1rem' }} />
      <Text size="large" typescale="title">
        <FormattedMessage id={'login.main.titleV2'} />
      </Text>
      <Text size="large" typescale="label">
        <FormattedMessage id={'login.main.subheadingV2'} />
      </Text>

      <FormStyled name="login__form" onSubmit={handleSubmit}>
        <V2TextFieldStyled
          name="email"
          placeholder={intl.formatMessage({ id: 'login.main.emailPlaceholder' })}
          value={email.value}
          error={!!email.error}
          onChange={handleChange}
        />
        <TextField
          name="password"
          type="password"
          placeholder={intl.formatMessage({ id: 'login.main.passwordPlaceholder' })}
          value={password.value}
          error={!!password.error}
          onChange={handleChange}
        />

        <RowForLinks>
          <TextLinkStyled variant="text" id={'forgotPasswordTextLink'} onClick={handleForgotPassword}>
            <FormattedMessage id="login.main.forgotPassword" />
          </TextLinkStyled>
          <TextLinkStyled variant="text" to="/v2/register">
            <FormattedMessage id="login.main.newRioUser" />
          </TextLinkStyled>
        </RowForLinks>
        {/** TODO: use styled with V2 button instead of inline styles */}
        <Button
          type="submit"
          name="loginbutton"
          loading={isLoading}
          variant="filled"
          style={{ margin: 'auto', paddingRight: '3rem', paddingLeft: '3rem' }}
        >
          <FormattedMessage id={isLoading ? 'login.main.loginButtonLoading' : 'login.main.loginButton'} />
        </Button>
      </FormStyled>
      {loginError && <RenderLoginError loginError={loginError} />}
    </>
  );
}
