import { gql } from '@apollo/client';

export const GET_LOCATION_META_OPTIONS = gql`
  query GetLocationMetaOptions($accountId: ID!) {
    getLocationMetadataOptionsForAccount(accountId: $accountId) {
      tenures {
        id
        name
      }
      regions {
        id
        name
      }
      buildingTypes {
        id
        name
      }
    }
  }
`;

export const CREATE_LOCATION_META_OPTION = gql`
  mutation CreateLocationMetaOption($id: ID!, $accountId: ID!, $name: String!, $type: LocationMetadataOptionType!) {
    createLocationMetadataOption(input: { id: $id, accountId: $accountId, name: $name, type: $type })
  }
`;
