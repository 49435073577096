import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'rio-ui-components';
import { injectIntl } from 'react-intl';

import { withTour } from '../../hocs';
import { CourseHandleType } from '../../utils/learn/CourseTypeHandler';
import { ViewCourseModal } from './ViewCourseModal';
import { useCompleteCourse } from './useCompleteCourse';
import { useDownloadPdf } from './useDownloadPdf';

const ButtonStyled = styled(Button)`
  margin-top: ${(props) => props.theme.geometry.md.spacing};
  border-radius: 0;
`;

function ViewCourseButton({ course, downloadLink, handleType, courseRefetch, component, name, intl }) {
  const [showModal, setShowModal] = useState(false);
  const Button = component || ButtonStyled;
  const completeCourse = useCompleteCourse(course, courseRefetch);
  const downloadPdf = useDownloadPdf(course, downloadLink, courseRefetch);
  const shouldDownload = handleType === CourseHandleType.PDF && course.assessment === null;
  return (
    <>
      {showModal && (
        <ViewCourseModal
          onDismiss={() => {
            setShowModal(false);
          }}
          course={course}
          link={downloadLink}
          courseRefetch={courseRefetch}
        />
      )}
      <Button
        color="success"
        component={shouldDownload ? 'a' : 'button'}
        target="_blank"
        pill={false}
        rel="noopener noreferrer"
        inline
        name={name}
        onClick={() => {
          if (shouldDownload) {
            downloadPdf();
          } else if (handleType === CourseHandleType.External) {
            completeCourse();
            window.open(course.metaData.url, '_blank');
          } else if (handleType === CourseHandleType.Iframe) {
            completeCourse();
            setShowModal(true);
          } else {
            setShowModal(true);
          }
        }}
      >
        {shouldDownload ? 'Download PDF' : intl.formatMessage({ id: 'pages.coursesPreview.startCourse' })}
      </Button>
    </>
  );
}

export default injectIntl(
  withTour(ViewCourseButton, 'learn.preview', {
    closeLabel: 'learnTour.close',
    joyrideOptions: { scrollToFirstStep: false }
  })
);
