import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Text } from 'rio-ui-components';
import styled from 'styled-components';
import WasteHierarchyRow from './WasteHierarchyRow';

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const RowsContainer = styled.div`
  flex: 1 1 auto;
  overflow: scroll;
`;

const WasteHierarchyContainer = (props) => {
  const { wasteTreatmentProcessHierarchies, showViewModal } = props;
  return (
    <Fragment>
      <RowStyled id="WasteHierarchyContainerHeaderRow" name="WasteHierarchyContainerHeaderRow" container align="between">
        <Col item span={2}>
          <Text weight="bold">Waste Treatment Process</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Prevention</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">
            Preparing for
            <br />
            re-use
          </Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Recycling</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Other Recovery</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Disposal</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Unknown</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Reference</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">Default or Custom</Text>
        </Col>
        <Col item span={1.25}>
          <Text weight="bold">View</Text>
        </Col>
      </RowStyled>
      <RowsContainer id="WasteHierarchyContainerRowContainer" name="WasteHierarchyContainerRowContainer">
        {wasteTreatmentProcessHierarchies.map((h) => {
          return (
            <WasteHierarchyRow
              id={h.id}
              key={h.id}
              wasteTreatmentProcessName={h.treatmentProcess.name}
              prevention={h.prevention}
              preparingForReuse={h.preparingForReuse}
              recycling={h.recycling}
              otherRecovery={h.otherRecovery}
              disposal={h.disposal}
              unknown={h.unknown}
              sum={h.sum}
              reference={h.reference}
              isGlobal={h.isGlobal}
              showViewModal={() => showViewModal(h)}
            />
          );
        })}
      </RowsContainer>
    </Fragment>
  );
};

WasteHierarchyContainer.propTypes = {
  wasteTreatmentProcessHierarchies: PropTypes.array
};

export default WasteHierarchyContainer;
