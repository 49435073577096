import { useState } from 'react';
import { styled } from '@mui/material-next/styles';
import { Surface, PageErrorContextProvider, PageLoadingContextProvider } from '@rio/ui-components';
import { Outlet, useLoaderData } from 'react-router-dom';
import UploadBar from '~/components/UploadBar';
import { useLicencesPaidSubscription, useRedirectToDesignVersion } from '~/hooks';
import { ApplicationData } from '~/types';
import { OverlayProgressBars } from '~/components/OverlayProgressBars/v2';
import { Navigation } from '~/components/Navigation';
import { HeaderContainer } from './HeaderContainer';

const Row = styled(Surface)`
  display: flex;
  flex-flow: row nowrap;
  padding: 24px 0;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  height: 100%;
`;

const Col = styled('div')`
  height: 100%;
  min-height: calc(100vh - 66px);
  margin-left: 100px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  overflow: hidden;
`;

const PageContainer = styled(Surface)`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  margin-top: 66px; // header height
`;

const NavigationStyled = styled(Navigation)`
  position: fixed !important;
`;

export const AppContainer = () => {
  const [items, addItems] = useState([]);
  const [createMutation, setCreateMutation] = useState();
  const [onComplete, setOnComplete] = useState();
  const applicationData: ApplicationData = useLoaderData() as ApplicationData;
  useLicencesPaidSubscription();
  useRedirectToDesignVersion();

  return (
    <Row className="v2">
      <NavigationStyled
        account={applicationData.getAccountInfo}
        user={applicationData.getUserInfoById}
        receivedSurveys={applicationData.getReceivedSurveysPage.rows}
        userTasks={applicationData.getTasksByUserId}
      />
      <Col>
        <HeaderContainer />
        <PageContainer>
          <PageErrorContextProvider>
            <PageLoadingContextProvider>
              <Outlet context={[addItems, setCreateMutation, setOnComplete, applicationData]} />
            </PageLoadingContextProvider>
          </PageErrorContextProvider>
        </PageContainer>
      </Col>
      {items.length > 0 && createMutation && (
        <UploadBar items={items} dismiss={() => addItems([])} createMutation={createMutation} onComplete={onComplete} />
      )}
      <OverlayProgressBars />
    </Row>
  );
};
