import { useLazyQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/licence/GetProductLicencesCount.query.graphql';

export function useGetAccountProductLicencesCountLazyQuery(options = {}) {
  const [fetch, { data: { getAccountProductLicencesCount = [] } = {}, ...others }] = useLazyQuery(QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network'
  });

  return [
    (accountId) =>
      fetch({
        variables: {
          accountId
        }
      }),
    {
      data: getAccountProductLicencesCount,
      ...others
    }
  ];
}
