import { useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { useUserToken } from '../../hooks/useUserToken';
import { S3 } from '../../constants/documentSources';
import { CREATE_DOCUMENT_RECORD } from './index.queries';
import UPLOAD_DOCUMENT from '../../graphql/mutations/UploadDocument.graphql';
import { createS3Key } from '../../containers/DocumentContainer/utils';

export function useSaveEvidence() {
  const {
    token: { sub: userId }
  } = useUserToken();
  const accountId = useCurrentAccountId();
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord] = useMutation(CREATE_DOCUMENT_RECORD);

  return async (evidence, tags, category) => {
    const documents = Array.isArray(evidence) ? evidence : [];
    const newDocumentIds = await Promise.all(
      documents
        .filter((d) => d instanceof window.File)
        .map(async (document) => {
          const id = uuid();
          await uploadDocument({
            variables: {
              accountId,
              id,
              userId,
              file: document,
              fileName: document.name
            }
          });
          const variables = {
            id,
            userId,
            accountId,
            fileName: document.name,
            category: category,
            key: createS3Key(accountId, id),
            library: 'GOVERNANCE',
            referenceId: null,
            reviewDate: null,
            source: S3,
            tags
          };
          const {
            data: {
              createDocumentRecord: { id: documentId }
            }
          } = await createDocumentRecord({
            variables
          });
          return documentId;
        })
    );
    return newDocumentIds;
  };
}
