import { FetchResult } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Location } from '@rio/rio-types';
import { Select, Modal } from 'rio-ui-components';
import { DataProxy } from 'apollo-cache';
import { get } from 'lodash';
import { useNotification } from '../../hooks';
import CreateLocation from '../../containers/ConfigurationContainer/ConfigurationLocationsContainer/CreateLocation';
import { Nullable, SelectEvent, Option } from '../../types';
import { ToastColor } from '@rio/ui-components';

interface BaseLocationSelectProps {
  value: Nullable<string | Option>;
  accountId: string;
  onChange: (e: SelectEvent) => void;
  loading?: boolean;
  options?: Option[];
  error?: Nullable<Error | string>;
  selectedOption?: Nullable<Option>;
  createNew?: boolean;
  createNewModalSize?: number;
  loadOptions: (filterValue: string) => Promise<{ options: Option[]; total?: number }>;
  onLocationCreated?: (
    proxy: DataProxy,
    result: FetchResult<{
      createLocationWithLocationPoints: Location;
    }>
  ) => void;
  locationPoints?: {
    wasteOut?: boolean;
    wasteIn?: boolean;
  };
  accessor?: string;
  labelledBy?: string;
}

const CREATE_NEW = 'CREATE_NEW';

function BaseLocationSelect({
  options,
  onChange,
  error,
  accountId,
  value,
  selectedOption,
  loadOptions,
  createNew,
  createNewModalSize = 5,
  loading,
  locationPoints,
  accessor = '',
  labelledBy,
}: BaseLocationSelectProps) {
  const { showNotification } = useNotification();
  const [showCreateLocation, setShowCreateLocation] = useState(false);

  const onSuccess = useCallback(
    (message: string, color: string, _: unknown, location: Location) => {
      showNotification(message, color as ToastColor);
      const payload = {
        value: get(location, `${accessor}id`),
        label: get(location, `${accessor}name`),
      };
      const event = {
        target: {
          select: payload,
          value: payload,
        },
      } as unknown as SelectEvent;
      onChange(event);
      setShowCreateLocation(false);
    },
    [accessor, onChange, showNotification]
  );

  return (
    <>
      {showCreateLocation && (
        <Modal
          span={createNewModalSize}
          size="md"
          show={showCreateLocation}
          name="CreateLocation__Modal--create"
          dismissable
          onDismiss={() => setShowCreateLocation(false)}
        >
          <CreateLocation accountId={accountId} onSuccess={onSuccess} locationPoints={locationPoints} />
        </Modal>
      )}
      <Select
        aria-labelledby={labelledBy}
        name="locationId"
        placeholder="Begin typing to find locations"
        value={selectedOption || value}
        options={options}
        error={error}
        defaultOptions
        debounceTimeout={1000}
        isLoading={loading}
        loadOptions={async (filterValue: string) => {
          const { options: loadedOptions, total } = await loadOptions(filterValue);

          if (
            selectedOption &&
            !loadedOptions.find(({ value: optionValue }: Option) => optionValue === selectedOption.value)
          ) {
            loadedOptions.unshift(selectedOption);
          }

          if (createNew) {
            loadedOptions.unshift({ label: 'Create new...', value: CREATE_NEW });
          }

          return { options: loadedOptions, total };
        }}
        onChange={(event: SelectEvent) => {
          if (event.target.select.value === CREATE_NEW) {
            setShowCreateLocation(true);
          } else {
            onChange(event);
          }
        }}
      />
    </>
  );
}

export { BaseLocationSelect };
