import React from 'react';
import { usePermissions } from '../hooks/usePermissions';

const withPermissions = (ComponentToWrap) => {
  const Wrapper = (props) => {
    const permissions = usePermissions();
    return <ComponentToWrap {...props} permissions={permissions} />;
  };

  return Wrapper;
};

export default withPermissions;
