import { useApolloClient } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { FetchRowsParameters, TransactionGrid } from '../../../components/TransactionGrid';
import { FactorUsedCell, TariffReference, UploadReferenceCell } from '../CellRenderers';
import { GET_HOTELSTAYS_TRANSACTION_PAGE } from '../../../graphql/queries/hotelStays/GetHotelstaysTransactionsPage.query';
import { useHotelStaysGridConfig } from './useHotelStaysGridConfig';

const title = 'Hotel stays and Home working';

const breadcrumbs = [{ title: 'Data', to: '..' }, { title }];

export function HotelStaysTransactionsGrid() {
  const client = useApolloClient();
  const config = useHotelStaysGridConfig();
  const fullConfig = useMemo(
    () => ({
      ...config,
      components: {
        tariffReference: TariffReference,
        uploadReference: UploadReferenceCell,
        link: FactorUsedCell,
      },
    }),
    [config]
  );
  const fetchRows = useCallback(
    async (variables: FetchRowsParameters) => {
      const {
        data: {
          getHotelStaysTransactionsPage: { totalRows, rows },
        },
      } = await client.query({
        query: GET_HOTELSTAYS_TRANSACTION_PAGE,
        variables,
        fetchPolicy: 'network-only',
      });
      return {
        rows,
        totalRows,
      };
    },
    [client]
  );

  return (
    <TransactionGrid
      title={title}
      breadcrumbs={breadcrumbs}
      config={fullConfig}
      fetchRows={fetchRows}
      uploadLink="../hotelStays/uploads"
      defaultExportFileName="Hotel Stays & Home Work Exports"
    />
  );
}
