import { useQuery } from '@apollo/client';
import { OptionsProps, Select } from '@rio/ui-components';

import { TransactionType, Query, SupplierType, Supplier } from '@rio/rio-types';
import { GET_ALL_SUPPLIERS } from '../../../queries/suppliers';
import { Option, Nullable } from '~/types';
import { MAX_ITEMS_IN_DOM_LIMIT } from '~/constants';

const loadingOption = { label: 'Loading...', value: '', disabled: true };
const noResultsFoundOption = { label: 'No suppliers found', value: '', disabled: true };

export interface SupplierSelectorProps {
  value: string;
  label?: string;
  utility?: TransactionType;
  accountId: string;
  defaultOptions?: Option[];
  error?: Nullable<string>;
  name?: string;
  supplierType?: SupplierType;
  filter?: (s?: Supplier) => boolean;
  onChange: (s: OptionsProps) => void;
}

const defaultFilter = () => true;

export function SupplierSelector({
  value,
  error,
  name,
  onChange,
  accountId,
  utility,
  supplierType,
  label = 'Supplier',
  filter = defaultFilter,
}: SupplierSelectorProps) {
  const variables = {
    accountId,
    utility: utility === TransactionType.Heatsteam ? 'ELECTRICITY' : utility || null,
    filterValue: '',
    filterBy: 'name',
    pageSize: MAX_ITEMS_IN_DOM_LIMIT,
  };
  const {
    data,
    loading,
    error: loadError,
  } = useQuery<Pick<Query, 'getSuppliers'>>(GET_ALL_SUPPLIERS, {
    variables,
    fetchPolicy: 'network-only',
  });
  const suppliers = data?.getSuppliers?.suppliers || [];
  const options = suppliers
    .filter((s) => {
      const predicate = filter(s);

      if (predicate === true && supplierType) {
        return s.type.includes(supplierType);
      }

      return predicate;
    })
    .map((s) => ({
      value: s.id,
      label: s.name,
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 1) as OptionsProps[];

  if (!loading && !loadError && options.length === 0) {
    options.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label={label}
      value={value}
      options={loading ? [loadingOption] : options}
      isLoading={loading}
      error={Boolean(error)}
      helperText={error || ''}
      onChange={(option: OptionsProps) => onChange(option)}
    />
  );
}
