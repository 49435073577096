import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_ACCURACY_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface AccuracySelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: string;
}

enum AccuracyId {
  Act = 'ea50d0b7-6a27-11eb-bd03-06b5345f8dda',
  Est = 'ea50d410-6a27-11eb-bd03-06b5345f8dda'
}

export function AccuracySelect({ value, onChange, error }: AccuracySelectProps) {
  const { data, loading, error: queryError } = useQuery<Pick<Query, 'getAllAccuracyTypes'>>(GET_ALL_ACCURACY_TYPES);
  return (
    <Select
      placeholder="Actual/Estimated"
      value={value || AccuracyId.Act}
      options={toSelectOptions(data?.getAllAccuracyTypes)}
      isLoading={loading}
      onChange={onChange}
      error={queryError ? queryError.message : error}
    />
  );
}
