import React, { useState } from 'react';
import { useExportApi, Export } from '~/hooks';
import { Nullable } from '~/types';
import { ConfirmActionModal } from '../../ConfirmAction/v2';
import { Grid, Icons, LinearProgress, Modal, Text, styled } from '@rio/ui-components';

const Overlay = styled('div')`
  position: fixed;
  left: 25%;
  bottom: 16px;
  width: 50%;
  height: auto;
  background: ${(props) => props.theme.sys.color.surface};
  border-radius: 16px;
  padding: 16px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
`;

const CheckIconStyled = styled(Icons.Check)`
  cursor: pointer;
`;

const ErrorIconStyled = styled(Icons.HighlightOff)`
  cursor: pointer;
`;

const ProgressBarRow = styled(Grid)`
  position: relative;
  align-items: center;
  height: 48px;
`;

const ProgressBarStyled = styled(LinearProgress)`
  width: 100%;
`;

const TextLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

const getProgressBarColor = (e: Export) => {
  if (e.error) {
    return 'error';
  }
  if (e.percents === 100) {
    return 'success';
  }
  return 'primary';
};

export function OverlayProgressBars() {
  const exportApi = useExportApi();
  const exports = exportApi?.getExports();
  const [error, setError] = useState<Nullable<Error>>(null);
  const [stopExportModal, setStopExportModal] = useState<Nullable<string>>(null);

  if (!exports?.length) {
    return null;
  }

  return (
    <>
      <Overlay>
        {exports.map((e) => (
          <ProgressBarRow columns={12} key={e.id} container columnGap={2}>
            <Grid item xs={8}>
              <Text typescale="body" size="medium" textAlign="center">
                {e.options.exportFileName}
              </Text>
              <ProgressBarStyled
                variant="buffer"
                color={getProgressBarColor(e)}
                value={e.percents}
                valueBuffer={e.step}
              />
            </Grid>
            <Grid item xs={2}>
              {e.error ? (
                <TextLink typescale="body" size="medium" textAlign="center" onClick={() => setError(e.error)}>
                  Show details
                </TextLink>
              ) : (
                <Text typescale="body" size="medium" textAlign="center">
                  {e.offset} / {e.totalRows || '?'}
                </Text>
              )}
            </Grid>
            <Grid item xs={1} container justifyContent="flex-end">
              {e.completed ? (
                <CheckIconStyled
                  onClick={() => {
                    exportApi?.stop(e.id);
                  }}
                  color="success"
                />
              ) : (
                <ErrorIconStyled
                  onClick={() => {
                    if (e.error) {
                      exportApi?.stop(e.id);
                    } else {
                      setStopExportModal(e.id);
                    }
                  }}
                  color="error"
                />
              )}
            </Grid>
          </ProgressBarRow>
        ))}
      </Overlay>
      <Modal open={!!error} grid={{ xs: 8, md: 6 }} onClose={() => setError(null)} title="Error details:">
        <Text typescale="body" size="medium">
          {error?.message || error?.toString()}
        </Text>
      </Modal>
      <ConfirmActionModal
        open={!!stopExportModal}
        onClose={() => setStopExportModal(null)}
        title="Do you want to abort the export?"
        body="All progress will be lost."
        onConfirm={() => {
          exportApi?.stop(stopExportModal as string);
          setStopExportModal(null);
        }}
      />
    </>
  );
}
