import { createRoutesFromElements, useOutletContext, useRoutes } from 'react-router-dom';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { withTour } from '../../../hocs';

import ConfigurationContainer from '../../ConfigurationContainer';
import IntelligenceContainer from '../../IntelligenceContainer';
import DataContainer from '../../DataContainer';
import ReportingContainer from '../../ReportingContainer';
import ContactContainer from '../../ContactContainer';
import GovernanceContainer from '../../GovernanceContainer';
import SurveysContainer from '../../SurveysContainer';
import HomeContainer from '../../HomeContainer';
import DocumentContainer from '../../DocumentContainer';
import LearnContainer from '../../LearnContainer';
import TasksContainer from '../../TasksContainer';
import ProjetcViewContainer from '../../ProjectContainer/ProjectView';
import TasksViewContainer from '../../TasksContainer/TaskView';
import ProfileContainer from '../../ProfileContainer';
import MarketContainer from '../../MarketContainer';
import FlowsContainer from '../../FlowsContainer';

import { learnTourStepsHome } from '../../../hocs/withTour/steps';
import {
  useCurrentAccount,
  useUserToken,
  usePermissions,
  useAccessControls,
  useLicencesPaidSubscription,
  useRoutes as useRoutesDictionary,
} from '../../../hooks';

import { ErrorElement } from '../../../components';

export function AppRoutes() {
  const account = useCurrentAccount();
  const [addItems, setCreateMutation, setOnComplete] =
    useOutletContext<React.Dispatch<React.SetStateAction<any[]>>[]>();
  const { token } = useUserToken();
  const navigate = useNavigate();
  const permissions = usePermissions();
  useLicencesPaidSubscription();
  const { data: accessControls } = useAccessControls();

  const routes = useRoutesDictionary();

  const userName = token ? token.given_name : '';

  const setMutation = (uploadMutation, onCompleteAction) => {
    setCreateMutation(uploadMutation);
    if (onCompleteAction) {
      setOnComplete(onCompleteAction);
    }
  };

  const HomeContainerWithTour = withTour(
    HomeContainer,
    'learn.home',
    { onFinish: () => navigate(routes.learn.root) },
    learnTourStepsHome(),
    userName
  );

  const hasTasksAccess = account?.accessControls?.tasks;
  const hasProjectsAccess = account?.accessControls?.projects;

  const elements = useRoutes(
    createRoutesFromElements(
      <>
        <Route path={routes.home.root} element={<HomeContainerWithTour />} errorElement={<ErrorElement />} />
        <Route path={`${routes.learn.root}/*`} element={<LearnContainer />} />
        <Route path={`${routes.governance.root}/*`} element={<GovernanceContainer />} />
        <Route path={`${routes.surveys.root}/*`} element={<SurveysContainer />} />
        <Route path={`${routes.data.root}/*`} element={<DataContainer />} />
        <Route path={`${routes.reporting.root}/*`} element={<ReportingContainer />} />
        <Route path={`${routes.docs.root}/*`} element={<DocumentContainer />} />
        <Route
          path={`${routes.tasks.root}/:id`}
          element={<TasksViewContainer hasProjectsAccess={hasProjectsAccess} />}
        />
        <Route
          path={`${routes.tasks.root}/*`}
          element={
            <TasksContainer
              hasProjectsAccess={hasProjectsAccess}
              hasTasksAccess={hasTasksAccess}
              tab={routes.tasks.root}
            />
          }
        />
        <Route path={`${routes.projects.root}/:id`} element={<ProjetcViewContainer />} />
        <Route
          path={`${routes.projects.root}/*`}
          element={
            <TasksContainer
              hasProjectsAccess={hasProjectsAccess}
              hasTasksAccess={hasTasksAccess}
              tab={`/${routes.projects.root}`}
            />
          }
        />
        {!!permissions.configurationRoute && (
          <Route
            path={`${routes.configuration.root}/*`}
            element={<ConfigurationContainer massUploadFunction={addItems} setCreateMutation={setMutation} />}
          />
        )}
        {!!accessControls.intelligence && (
          <Route path={`${routes.intelligence.root}/*`} element={<IntelligenceContainer />} />
        )}
        {!!accessControls.flows && <Route path={`${routes.flows.root}/*`} element={<FlowsContainer />} />}
        <Route path={`${routes.header.contact}/*`} element={<ContactContainer />} />
        <Route path={`${routes.header.profile}/*`} element={<ProfileContainer />} />
        <Route path={`${routes.header.upgrade}/*`} element={<MarketContainer />} />
        <Route path="*" element={<Navigate to={`${routes.home.root}`} />} />
      </>
    )
  );

  return elements;
}
