import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Mutation } from '@rio/rio-types';
import { UNLOCK_CONTRIBUTOR } from '../containers/SurveysContainer/ReceivedSurveyContainer/queries';
import { useGetReceivedSurveysPage } from '../containers/SurveysContainer/ReceivedSurveysContainer/queries/useGetReceivedSurveysPage';

type UnlockContributorVariables = {
  accountId: string;
  campaignId: string;
};

type UnlockContributorResponse = Pick<Mutation, 'unlockContributor'>;

export const useUnlockContributor = (accountId, contributorUnlockedFn?) => {
  const [unlockContributorMutation] = useMutation<UnlockContributorResponse, UnlockContributorVariables>(
    UNLOCK_CONTRIBUTOR
  );

  const [unlockData, setUnlockData] = useState<UnlockContributorResponse | null>(null);

  const unlockContributor = async (campaignId: string | undefined) => {
    if (!campaignId) {
      return;
    }

    const { data } = await unlockContributorMutation({
      variables: { accountId, campaignId },
    });
    setUnlockData(data ?? null);
  };

  const { refetch } = useGetReceivedSurveysPage({ accountId });

  useEffect(() => {
    const fetchData = async () => {
      if (unlockData?.unlockContributor) {
        await refetch();
        if (contributorUnlockedFn) {
          contributorUnlockedFn();
        }
      }
    };

    fetchData();
  }, [refetch, unlockData?.unlockContributor, contributorUnlockedFn]);

  return { unlockContributor };
};
