import { AccordionDropdown, AccordionDropdownItem, AccordionMenu, Col, Row } from 'rio-ui-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RioModule } from '@rio/rio-types';
import { useCurrentAccountId, usePermissions, useRoutes } from '../../hooks';
import CustomRouteLink from '../../components/CustomRouteLink';
import { AssignAccountProductLicences, CreateAccountProductLicence } from '../../components/AccountProductLicence';
import ConfigurationUsersContainer from './ConfigurationUsersContainer';
import ConfigurationAccountsContainer from './ConfigurationAccountsContainer';
import ConfigurationSurveyContainer from './ConfigurationSurveyContainer';
import ConfigurationContractorsContainer from './ConfigurationContractorsContainer';
import ConfigurationLocationsContainer from './ConfigurationLocationsContainer';
import ConfigurationWasteStreamContainer from './ConfigurationWasteStreamContainer';
import ConfigurationTagsContainer from './ConfigurationTagsContainer';
import ConfigurationSchemesContainer from './ConfigurationSchemesContainer';
import ConfigurationWasteHierarchiesContainer from './ConfigurationWasteHierarchiesContainer';
import ConfigurationEnergyMetersContainer from './ConfigurationEnergyMetersContainer';
import ConfigurationLearnUploadContainer from './ConfigurationLearnUploadContainer';
import ConfigurationLearnSubscriptionsContainer from './ConfigurationLearnSubscriptionsContainer';
import { ConfigurationLearnEvaluations } from './ConfigurationLearnEvaluations';
import ConfigurationDashboardSubscriptionsContainer from './ConfigurationDashboardSubscriptionsContainer';
import DashboardSubscriptionsContainer from './ConfigurationDashboardSubscriptionsContainer/DashboardSelectionContainer';
import ConfigurationSubscriptionSelectionContainer from './ConfigurationSubscriptionSelectionContainer';
import ConfigurationMySuppliersContainer from './ConfigurationMySuppliersContainer';
import ConfigurationNormalisersContainer from './ConfigurationNormalisersContainer';
import ConfigurationAliasesWaste from './ConfigurationAliasesContainer/ConfigurationAliasesWaste';
import ConfigurationAliasesMeters from './ConfigurationAliasesContainer/ConfigurationAliasesMeters';
import ConfigurationAliasesTransport from './ConfigurationAliasesContainer/ConfigurationAliasesTransport';
import ConfigurationAliasesLocation from './ConfigurationAliasesContainer/ConfigurationAliasesLocation';
import ConfigurationAliasesOther from './ConfigurationAliasesContainer/ConfigurationAliasesOther';
import ConfigurationDashboardsContainer from './ConfigurationDashboardsContainer';
import ConfigurationKnowledgeBaseContainer from './ConfigurationKnowledgeBaseContainer';
import ConfigurationOcrContainer from './ConfigurationOcrContainer';
import { LocationBasedFactors } from './ConfigurationLocationCarbonConversionFactors';
import { MarketBasedFactors } from './ConfigurationMarketCarbonConversionFactors';
import ConfigurationContainerTypes from './ConfigurationContainerTypes';
import ConfigurationLegislationContainer from './ConfigurationLegislationContainer';
import ConfigurationLegislationEditContainer from './ConfigurationLegislationEditContainer';
import ManagementSystemsSubscriptions from './ConfigurationManagementSystemsSubscriptionsContainer';
import ManagementSystemSubscriptionSelector from './ConfigurationManagementSystemsSubscriptionsContainer/SubscriptionSelector';
import ConfigurationIntelligenceSubscriptionsContainer from './ConfigurationIntelligenceSubscriptionsContainer';
import ConfigurationIntelligenceSubscriptionsSelectionContainer from './ConfigurationIntelligenceSubscriptionsContainer/IntelligenceSelectionContainer';
import { ConfigurationSurveyTemplateSubscriptionsContainer } from './ConfigurationSurveyTemplateSubscriptions';
import { ConfigurationSurveyQuestions } from './ConfigurationSurveyQuestions';
import { ConfigurationSurveyQuestionCategoriesContainer } from './ConfigurationSurveyQuestionCategoriesContainer';
import ConfigurationDataTrackerContainer from './ConfigurationDataTrackerContainer';
import ConfigurationAnomalyContainer from './ConfigurationAnomalyContainer';
import ConfigurationFlowsContainer from './ConfigurationFlowsContainer';
import ConfigurationFlowsSubscriptionsContainer from './ConfigurationFlowsSubscriptionsContainer';
import ConfigurationFlowsSubscriptionsSelectionContainer from './ConfigurationFlowsSubscriptionsContainer/SubscriptionsSelectionContainer';
import { ConfigurationAssetManagementContainer } from './ConfigurationAssetsManagementContainer';
import { ConfigurationFunds } from './ConfigurationFunds';
import ConfigurationLearnBanners from './ConfigurationLearnBanners';

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ContentContainerStyled = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const AccordionMenuStyled = styled(AccordionMenu)`
  overflow: auto;
`;

const RowStyled = styled(Row)`
  height: 100%;
`;

const ColStyled = styled(Col)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ConfigurationContainer = (props) => {
  const { massUploadFunction, setCreateMutation, intl } = props;
  const permissions = usePermissions();

  const { licenceService: licenceServicePermissions, learnService: learnServicePermissions } = permissions;

  const currentAccountId = useCurrentAccountId();
  const routes = useRoutes();

  const isEngageContentVisible = learnServicePermissions.createCourse;
  const isEngageSubscriptionVisible =
    learnServicePermissions.createCourse ||
    licenceServicePermissions.createLicence ||
    licenceServicePermissions.assignLicence;
  const isEngageEvaluationsVisible = learnServicePermissions.createCourse;
  const isEngageMenuVisible = isEngageContentVisible || isEngageSubscriptionVisible || isEngageEvaluationsVisible;

  return (
    <Container>
      <RowStyled container item>
        <ColStyled container item span={3}>
          <AccordionMenuStyled
            id="pic"
            name="configuration-menu"
            inline
            size="md"
            icon="cog"
            text={<FormattedMessage id="pages.configuration.nav.heading" />}
          >
            {!!permissions.allowedRioModules.includes(RioModule.Configuration) && (
              <CustomRouteLink
                name="configuration-menu__item--accounts"
                to="../configuration/accountconfig/accounts"
                base="configuration/accountconfig"
              >
                <AccordionDropdown text={'Account Configuration'} dropdown>
                  {!!permissions.user.includes('createAll') && (
                    <CustomRouteLink
                      name="configuration-menu__item--accounts"
                      to="accountconfig/accounts"
                      base="configuration/accountconfig/accounts"
                    >
                      <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.accounts' })} />
                    </CustomRouteLink>
                  )}
                  <>
                    {!!permissions.data.find((action) => action.startsWith('getLocation')) && (
                      <CustomRouteLink
                        name="configuration-menu__item--locations"
                        to="../configuration/accountconfig/locations"
                        base="configuration/accountconfig/locations"
                      >
                        <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.locations' })} />
                      </CustomRouteLink>
                    )}
                    {!!permissions.data.find((action) => action.startsWith('createEnergySource')) && (
                      <CustomRouteLink
                        name="configuration-menu__item--meters"
                        to="../configuration/accountconfig/meters"
                        base="configuration/accountconfig/meters"
                      >
                        <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.meters' })} />
                      </CustomRouteLink>
                    )}
                    {!!permissions.data.find((action) => action.startsWith('createNormaliser')) && (
                      <CustomRouteLink
                        name="configuration-menu__item--normalisers"
                        to="../configuration/accountconfig/normalisers"
                        base="configuration/accountconfig/normalisers"
                      >
                        <AccordionDropdownItem
                          text={intl.formatMessage({ id: 'pages.configuration.nav.normalisers' })}
                        />
                      </CustomRouteLink>
                    )}
                    {Boolean(permissions.scheme.find((action) => action.startsWith('get'))) && (
                      <CustomRouteLink
                        name="configuration-menu__item--schemes"
                        to="../configuration/accountconfig/schemes"
                        base="configuration/accountconfig/schemes"
                      >
                        <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.schemes' })} />
                      </CustomRouteLink>
                    )}
                    {!!permissions.tag.find((action) => action.startsWith('create')) && (
                      <CustomRouteLink
                        name="configuration-menu__item--tags"
                        to="../configuration/accountconfig/tags"
                        base="configuration/accountconfig/tags"
                      >
                        <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.tags' })} />
                      </CustomRouteLink>
                    )}
                    {!!permissions.user.find((action) => action.startsWith('create')) && (
                      <CustomRouteLink
                        name="configuration-menu__item--users"
                        to="../configuration/accountconfig/users"
                        base="configuration/accountconfig/users"
                      >
                        <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.users' })} />
                      </CustomRouteLink>
                    )}
                  </>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {!!permissions.invest.find((action) => action.startsWith('import')) && (
              <CustomRouteLink
                name="configuration-menu__item--funds"
                to="../configuration/portfoliomanagement/funds"
                base="configuration/portfoliomanagement"
              >
                <AccordionDropdown text={'Portfolio Management'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--funds"
                    to="../configuration/portfoliomanagement/funds"
                    base="configuration/portfoliomanagement/funds"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.funds' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--assets"
                    to="../configuration/portfoliomanagement/asset-management"
                    base="configuration/portfoliomanagement/asset-management"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.assetManagement' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.data.find((action) => action.startsWith('editAlias')) && (
              <CustomRouteLink
                name="configuration-menu__item--alias"
                to="../configuration/aliases/waste"
                base="configuration/aliases"
              >
                <AccordionDropdown text={intl.formatMessage({ id: 'pages.configuration.nav.aliases' })} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--waste"
                    to="../configuration/aliases/waste"
                    base="configuration/aliases/waste"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.data.subjects.wasteAlias' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--meters"
                    to="../configuration/aliases/meters"
                    base="configuration/aliases/meters"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.meters' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--transport"
                    to="../configuration/aliases/transport"
                    base="configuration/aliases/transport"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.data.subjects.transport' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--location"
                    to="../configuration/aliases/location"
                    base="configuration/aliases/location"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.data.subjects.location' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--others"
                    to="../configuration/aliases/others"
                    base="configuration/aliases/others"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.others' })} />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.data.find((action) => action.startsWith('getCarbonConversionFactor')) && (
              <CustomRouteLink
                name="configuration-menu__item--carbon-conversion-factors"
                to="../configuration/carbonconversionfactors/location"
                base="configuration/carbonconversionfactors"
              >
                <AccordionDropdown
                  text={intl.formatMessage({ id: 'pages.configuration.nav.carbonConversionFactors' })}
                  dropdown
                >
                  <CustomRouteLink
                    name="configuration-menu__item--carbon-conversion-factors"
                    to="../configuration/carbonconversionfactors/location"
                    base="configuration/carbonconversionfactors/location"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.locationBased' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--market-carbon-conversion-factors"
                    to="../configuration/carbonconversionfactors/market"
                    base="configuration/carbonconversionfactors/market"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.marketBased' })} />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.dashboard.includes('createAll') && (
              <CustomRouteLink
                name="configuration-menu__item--dashboards"
                to="../configuration/dashboardconfig/dashboards"
                base="configuration/dashboardconfig"
              >
                <AccordionDropdown text={'Dashboard Configuration'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--dashboards"
                    to="../configuration/dashboardconfig/dashboards"
                    base="configuration/dashboardconfig/dashboards"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.dashboards.list' })}
                    />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--dashboardSubscriptions"
                    to="../configuration/dashboardconfig/subscriptions"
                    base="configuration/dashboardconfig/subscriptions"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.dashboards.subscriptions' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {Boolean(permissions.dataTracker.find((action) => action.startsWith('get'))) && (
              <CustomRouteLink
                name="configuration-menu__item--dataTracker"
                to="../configuration/datatracker/frequency-recurrence"
                base="configuration/datatracker"
              >
                <AccordionDropdown text={'Data Tracker Configuration'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--dataTracker"
                    to="../configuration/datatracker/frequency-recurrence"
                    base="configuration/datatracker/frequency-recurrence"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.dataTracker.container.header.missing' })}
                    />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--dataTracker"
                    to="../configuration/datatracker/anomaliesthresholds"
                    base="configuration/datatracker/anomaliesthresholds"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.dataTracker.container.header.anomaly' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {isEngageMenuVisible && (
              <CustomRouteLink
                name="configuration-menu__item--learnconfig"
                to="../configuration/learnconfig/learncontent"
                base="configuration/learnconfig"
              >
                <AccordionDropdown text={'Engage Configuration'} dropdown>
                  {isEngageContentVisible && (
                    <CustomRouteLink
                      name="configuration-menu__item--learn"
                      to="../configuration/learnconfig/learncontent"
                      base="configuration/learnconfig/learncontent"
                    >
                      <AccordionDropdownItem
                        text={intl.formatMessage({ id: 'pages.configuration.nav.learnContent' })}
                      />
                    </CustomRouteLink>
                  )}

                  {isEngageSubscriptionVisible && (
                    <CustomRouteLink
                      name="configuration-menu__item--learnSubscriptions"
                      to="../configuration/learnconfig/learnsubscriptions"
                      base="configuration/learnconfig/learnsubscriptions"
                    >
                      <AccordionDropdownItem
                        text={intl.formatMessage({ id: 'pages.configuration.nav.learnSubscriptions' })}
                      />
                    </CustomRouteLink>
                  )}

                  {isEngageEvaluationsVisible && (
                    <CustomRouteLink
                      name="configuration-menu__item--learnEvaluations"
                      to="../configuration/learnconfig/evaluations"
                      base="configuration/learnconfig/evaluations"
                    >
                      <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.evaluations' })} />
                    </CustomRouteLink>
                  )}

                  {
                    <CustomRouteLink
                      name="configuration-menu__item--learnBanners"
                      to="../configuration/learnconfig/banners"
                      base="configuration/learnconfig/banners"
                    >
                      <AccordionDropdownItem
                        text={intl.formatMessage({ id: 'pages.configuration.nav.engageBanners' })}
                      />
                    </CustomRouteLink>
                  }
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.flows.find((action) => action.startsWith('configureFlows')) && (
              <CustomRouteLink
                name="configuration-menu__item--flows"
                to="../configuration/flows/flows"
                base="configuration/flows"
              >
                <AccordionDropdown text="Flows" dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--flows"
                    to="../configuration/flows/flows"
                    base="configuration/flows/flows"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.flows.configuration' })}
                    />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--flows-subscriptions"
                    to="../configuration/flows/subscriptions"
                    base="configuration/flows/subscriptions"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.flows.subscriptions' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.governance.includes('createLegislationAll') && (
              <CustomRouteLink
                name="configuration-menu__item--govconfig"
                to="../configuration/governanceconfig/managementsubscriptions"
                base="configuration/governanceconfig"
              >
                <AccordionDropdown text={'Governance Configuration'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--legislation"
                    to="../configuration/governanceconfig/legislation"
                    base="configuration/governanceconfig/legislation"
                  >
                    <AccordionDropdownItem text={'Legislation'} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--mss"
                    to="../configuration/governanceconfig/managementsubscriptions"
                    base="configuration/governanceconfig/managementsubscriptions"
                  >
                    <AccordionDropdownItem text={'Management Systems Subscriptions'} />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.intelligence.includes('getAllKnowledgeBasesAll') && (
              <CustomRouteLink
                name="configuration-menu__item--intelconfig"
                to="../configuration/intelligenceconfig/knowledgebases"
                base="configuration/intelligenceconfig"
              >
                <AccordionDropdown text={'Intelligence Configuration'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--kbs"
                    to="../configuration/intelligenceconfig/knowledgebases"
                    base="configuration/intelligenceconfig/knowledgebases"
                  >
                    <AccordionDropdownItem text={'Knowledge Bases'} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--kbsubscriptions"
                    to="../configuration/intelligenceconfig/kbsubscriptions"
                    base="configuration/intelligenceconfig/kbsubscriptions"
                  >
                    <AccordionDropdownItem text={'KB Subscriptions'} />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.ocr.includes('createTemplateAll') && (
              <CustomRouteLink name="configuration-menu__item--ocr" to="../configuration/ocr" base="configuration/ocr">
                <AccordionDropdown text={'OCR Templates'} />
              </CustomRouteLink>
            )}

            {permissions.data.find((action) => action.startsWith('createSupplier')) && (
              <CustomRouteLink
                name="configuration-menu__item--mySuppliers"
                to="../configuration/suppliers"
                base="configuration/suppliers"
              >
                <AccordionDropdown text={intl.formatMessage({ id: 'pages.configuration.nav.suppliers' })} />
              </CustomRouteLink>
            )}

            {permissions.survey.find((action) => action.startsWith('createSurveyTemplate')) && (
              <CustomRouteLink
                name="configuration-menu__item--surveys"
                to="../configuration/surveys/templates"
                base="configuration/surveys"
              >
                <AccordionDropdown text="Surveys" dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--form"
                    to="../configuration/surveys/templates"
                    base="configuration/surveys/templates"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.formTemplates' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--survey-subscriptions-link"
                    to="../configuration/surveys/subscriptions"
                    base="configuration/surveys/subscriptions"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.formSubscriptions' })}
                    />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--survey-questions-link"
                    to="../configuration/surveys/questions"
                    base="configuration/surveys/questions"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.questions' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--survey-questions-categories-link"
                    to="../configuration/surveys/question-categories"
                    base="configuration/surveys/question-categories"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.questionsCategories' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}

            {permissions.data.find((action) => action.startsWith('createWasteStream')) && (
              <CustomRouteLink
                name="configuration-menu__item--wasteconfig"
                to="../configuration/wasteconfig/containertypes"
                base="configuration/wasteconfig"
              >
                <AccordionDropdown text={'Waste Configuration'} dropdown>
                  <CustomRouteLink
                    name="configuration-menu__item--waste"
                    to="../configuration/wasteconfig/containertypes"
                    base="configuration/wasteconfig/containertypes"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.containerTypes' })}
                    />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--wastestream"
                    to="../configuration/wasteconfig/wastestream"
                    base="configuration/wasteconfig/wastestream"
                  >
                    <AccordionDropdownItem text={intl.formatMessage({ id: 'pages.configuration.nav.wasteStreams' })} />
                  </CustomRouteLink>
                  <CustomRouteLink
                    name="configuration-menu__item--wasteHierarchy"
                    to="../configuration/wasteconfig/wastehierarchies"
                    base="configuration/wasteconfig/wastehierarchies"
                  >
                    <AccordionDropdownItem
                      text={intl.formatMessage({ id: 'pages.configuration.nav.wasteHierarchies' })}
                    />
                  </CustomRouteLink>
                </AccordionDropdown>
              </CustomRouteLink>
            )}
          </AccordionMenuStyled>
        </ColStyled>

        <Col container inline span={9}>
          <ContentContainerStyled>
            <Routes>
              <Route path={routes.configuration.menu.accounts} element={<ConfigurationAccountsContainer />} />
              <Route
                path={routes.configuration.menu.tracker.frequency}
                element={<ConfigurationDataTrackerContainer />}
              />
              <Route path={routes.configuration.menu.tracker.anomolies} element={<ConfigurationAnomalyContainer />} />
              <Route
                path={routes.configuration.menu.locations}
                element={
                  <ConfigurationLocationsContainer
                    accountId={currentAccountId}
                    massUploadFunction={massUploadFunction}
                    setCreateMutation={setCreateMutation}
                  />
                }
              />
              <Route
                path={routes.configuration.menu.meters}
                element={<ConfigurationEnergyMetersContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.normalisers}
                element={<ConfigurationNormalisersContainer accountId={currentAccountId} />}
              />
              <Route path={routes.configuration.menu.schemes} element={<ConfigurationSchemesContainer />} />
              <Route
                path={routes.configuration.menu.tags}
                element={<ConfigurationTagsContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.users}
                element={
                  <ConfigurationUsersContainer
                    accountId={currentAccountId}
                    massUploadFunction={massUploadFunction}
                    setCreateMutation={setCreateMutation}
                  />
                }
              />
              <Route
                path={routes.configuration.menu.portfolio.assets}
                element={<ConfigurationAssetManagementContainer accountId={currentAccountId} />}
              />
              <Route path={routes.configuration.menu.portfolio.funds} element={<ConfigurationFunds />} />
              <Route
                path={routes.configuration.menu.aliases.waste}
                element={<ConfigurationAliasesWaste accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.aliases.meters}
                element={<ConfigurationAliasesMeters accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.aliases.transport}
                element={<ConfigurationAliasesTransport accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.aliases.location}
                element={<ConfigurationAliasesLocation accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.aliases.others}
                element={<ConfigurationAliasesOther accountId={currentAccountId} />}
              />

              <Route path={`${routes.configuration.factors.location}/*`} element={<LocationBasedFactors />} />
              <Route path={routes.configuration.factors.market} element={<MarketBasedFactors />} />
              <Route path={routes.configuration.menu.suppliers} element={<ConfigurationMySuppliersContainer />} />
              <Route
                path={routes.configuration.menu.contractors}
                element={<ConfigurationContractorsContainer accountId={currentAccountId} />}
              />
              <Route path={routes.configuration.menu.containerTypes} element={<ConfigurationContainerTypes />} />
              <Route path={routes.configuration.menu.surveys.templates} element={<ConfigurationSurveyContainer />} />
              <Route
                path={routes.configuration.menu.surveys.subscriptions}
                element={<ConfigurationSurveyTemplateSubscriptionsContainer />}
              />
              <Route path={routes.configuration.menu.surveys.questions} element={<ConfigurationSurveyQuestions />} />
              <Route
                path={routes.configuration.menu.surveys.categories}
                element={<ConfigurationSurveyQuestionCategoriesContainer />}
              />
              <Route path={routes.configuration.menu.flows.root} element={<ConfigurationFlowsContainer />} />
              <Route
                path={routes.configuration.menu.wasteStream}
                element={<ConfigurationWasteStreamContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.hierarchies}
                element={<ConfigurationWasteHierarchiesContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.dashboards.root}
                element={<ConfigurationDashboardsContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.dashboards.subscriptions}
                element={<ConfigurationDashboardSubscriptionsContainer accountId={currentAccountId} />}
              />
              <Route
                path={`${routes.configuration.menu.dashboards.subscriptions}/:accountId`}
                element={<DashboardSubscriptionsContainer />}
              />
              <Route
                path={routes.configuration.menu.flows.subscriptions}
                element={<ConfigurationFlowsSubscriptionsContainer />}
              />
              <Route
                path={`${routes.configuration.menu.flows.subscriptions}/:accountId`}
                element={<ConfigurationFlowsSubscriptionsSelectionContainer />}
              />
              <Route path={routes.configuration.menu.learn.content} element={<ConfigurationLearnUploadContainer />} />
              <Route
                path={routes.configuration.menu.learn.subscriptions}
                element={<ConfigurationLearnSubscriptionsContainer />}
              />
              <Route
                path={`${routes.configuration.menu.learn.subscriptions}/:accountId`}
                element={<ConfigurationSubscriptionSelectionContainer />}
              />
              <Route path={routes.configuration.menu.learn.assignLicense} element={<AssignAccountProductLicences />} />
              <Route path={routes.configuration.menu.learn.createLicense} element={<CreateAccountProductLicence />} />
              <Route path={routes.configuration.menu.learn.evaluations} element={<ConfigurationLearnEvaluations />} />
              <Route path={routes.configuration.menu.learn.banners} element={<ConfigurationLearnBanners />} />
              <Route
                path={routes.configuration.menu.governance.legislation}
                element={<ConfigurationLegislationContainer accountId={currentAccountId} />}
              />
              <Route
                path={`${routes.configuration.menu.governance.legislation}/:legislationId`}
                element={<ConfigurationLegislationEditContainer accountId={currentAccountId} />}
              />
              <Route
                path={routes.configuration.menu.governance.management}
                element={<ManagementSystemsSubscriptions />}
              />
              <Route
                path={`${routes.configuration.menu.governance.management}/:accountId`}
                element={<ManagementSystemSubscriptionSelector />}
              />
              <Route
                path={routes.configuration.menu.intelligence.knowledgebases}
                element={<ConfigurationKnowledgeBaseContainer />}
              />
              <Route
                path={routes.configuration.menu.intelligence.subscriptions}
                element={<ConfigurationIntelligenceSubscriptionsContainer />}
              />
              <Route
                path={`${routes.configuration.menu.intelligence.subscriptions}/:accountId`}
                element={<ConfigurationIntelligenceSubscriptionsSelectionContainer />}
              />
              <Route path={routes.configuration.menu.ocr} element={<ConfigurationOcrContainer />} />
              <Route path="*" element={<Navigate to={routes.configuration.menu.accounts} />} />
            </Routes>
          </ContentContainerStyled>
        </Col>
      </RowStyled>
    </Container>
  );
};

export default injectIntl(ConfigurationContainer);
