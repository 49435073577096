import { useApolloClient } from '@apollo/client';
import { TransactionGrid } from '../../../../components/TransactionGrid';
import { GET_REFRIGERANT_TRANSACTIONS } from './index.queries';
import { useRefrigerantGridConfig } from './useRefrigerantGridConfig';
import { TariffReference, UploadReferenceCell, DocumentReference } from '../../CellRenderers';
import { FactorUsedCell } from '../../CellRenderers';

export function RefrigerantsTransactionsGrid() {
  const client = useApolloClient();
  const config = useRefrigerantGridConfig();
  return (
    <TransactionGrid
      title="Refrigerants and Other Gases Transactions"
      defaultExportFileName="refrigerants-export"
      breadcrumbs={[{ title: 'Data', to: '..' }, { title: 'Refrigerants and Other Gases Transactions' }]}
      uploadLink="../refrigerants/uploads"
      config={{
        ...config,
        components: {
          tariffReference: TariffReference,
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={async (variables) => {
        const {
          data: {
            getRefrigerantTransactionsPage: { totalRows, rows },
          },
        } = await client.query({
          query: GET_REFRIGERANT_TRANSACTIONS,
          variables,
          fetchPolicy: 'network-only',
        });
        return {
          rows,
          totalRows,
        };
      }}
    />
  );
}
