import { useMutation } from '@apollo/client';
import MUTATION from '../../graphql/mutations/market/BuyProducts.mutation.graphql';

// it is one time purchase
export function useBuyProductMutation() {
  const [execute, data] = useMutation(MUTATION);

  const mapData = ({ data: { buyProducts } = {}, ...others }) => ({ data: buyProducts, ...others });

  return [
    async (variables = {}) => {
      return mapData(await execute({ variables }));
    },
    { ...mapData(data) }
  ];
}
