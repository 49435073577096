import { useCallback, useEffect } from 'react';
import { Page, useTheme } from '@rio/ui-components';
import { GradientCircleIcon } from '../HomeContainer/v2/Reporting/QuickTools';
import { configUrl, msg, scriptSrc, suggestionsList } from './utils';
import {
  TextStyled,
  ChatbotStyled,
  ItemContainer,
  TextContainer,
  ListContainerStyled,
  ContentWrapperStyled,
} from './style';

// need to find correct types for Landbot and myLandbot
declare global {
  interface Window {
    Landbot: any;
    myLandbot: any;
  }
}

export const RioBot = () => {
  const theme = useTheme();

  useEffect(() => {
    const initLandbot = () => {
      window.myLandbot = new window.Landbot.Container({
        container: '#myLandbot',
        configUrl: configUrl,
        customData: { welcome_message: msg },
      });
    };

    // Load Landbot script dynamically
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.onload = initLandbot;
      document.body.appendChild(script);
    };

    loadScript();
  }, []);

  const updateChat = useCallback((subject) => {
    if (window.myLandbot) {
      window.myLandbot.sendMessage({ message: subject });
    } else {
      console.error('myLandbot is not initialized');
    }
  }, []);

  return (
    <Page title={{ content: 'RioBot' }}>
      <ContentWrapperStyled>
        <ChatbotStyled id="myLandbot" />
        <ListContainerStyled>
          {suggestionsList(theme).map((el) => (
            <ItemContainer key={el.title} onClick={() => updateChat(el.title)}>
              <GradientCircleIcon hideShadowGradient icon={el.icon} color={el.color} id={el.id} />
              <TextContainer>
                <TextStyled typescale="body" size="medium">
                  {el.title}
                </TextStyled>
              </TextContainer>
            </ItemContainer>
          ))}
        </ListContainerStyled>
      </ContentWrapperStyled>
    </Page>
  );
};
