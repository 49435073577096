import { gql } from '@apollo/client';

export const GET_USER_INFO_FRAGMENT = gql`
  fragment getUserInfoFragment on User {
    id
    email
    first_name
    last_name
    jobTitle
    department
    phone
    mobile
    profilePicture
    roles
    accessLevel
    allowedLocations {
      id
      name
      tags {
        id
        tagName
      }
    }
    allowedTags {
      id
      tagName
      locations {
        id
        name
      }
    }
    account {
      id
      name
      type
      logoPath
      departments {
        id
        name
      }
      children {
        id
        name
        logoPath
      }
    }
    role {
      role
      name
      restrictedAccess
      permissions {
        allowedRioModules
        user
        account
        data
        governance
        document
        tag
        transposition
        email
        notification
        learn
        licence
        dashboard
        task
        ocr
        intelligence
        project
        scheme
        survey
        dataTracker
        dataTrackerAnomalies
        flows
        invest
      }
    }
  }
`;

export const GET_USER_BY_USERNAME = gql`
  query GetUserByUsername($name: String!) {
    getUserInfoById(id: $name) {
      ...getUserInfoFragment
    }
  }
  ${GET_USER_INFO_FRAGMENT}
`;
