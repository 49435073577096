import { gql } from '@apollo/client';

export const GET_FUEL_CONVERSION_FACTORS = gql`
  query getFuelConversionFactors($filters: Filters, $limit: Int = 100, $offset: Int, $sort: [SortCommand!]) {
    getFuelConversionFactors(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      rows {
        id
        reportingYear
        unit
        fuelType
        fuelActivity
        comKgCo2e
        wttKgCO2e
        reference

        comKgCo2
        comKgCh4
        comKgN20
        gjGross
        gjNet
        kWhGross
        kWhNet
        densityKg
        densityM3
        densityT

        startDate
        endDate
      }
      totalRows
    }
  }
`;

export const GET_FUEL_CONVERSION_FACTORS_FILTER = gql`
  query getFuelConversionFactorsFilter($field: String!) {
    getFuelConversionFactorsFilter(field: $field) {
      label
      value
    }
  }
`;
