import { useMutation } from '@apollo/client';
import MUTATION from '../../graphql/mutations/market/BuySubscription.mutation.graphql';

export function useBuySubscriptionMutation() {
  const [execute, data] = useMutation(MUTATION);

  const mapData = ({ data: { buySubscription } = {}, ...others }) => ({ data: buySubscription, ...others });

  return [async (variables = {}) => mapData(await execute({ variables })), { ...mapData(data) }];
}
