import { Badge } from '@rio/ui-components';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from './utils';

export const navigationItems = (pathname, routes, incompleteSurveysCount, hasFormManagerAccess) => {
  const items = [
    {
      item: <FormattedMessage id={'pages.surveys.subjects.surveysSent'} />,
      dropdownItems: [
        {
          item: <FormattedMessage id={'pages.surveys.subjects.overview'} />,
          to: `./${routes.surveys.sent.overview}`,
          isActive: pathname.includes(routes.surveys.sent.overview),
        },
        {
          item: <FormattedMessage id={'pages.surveys.subjects.contributors'} />,
          to: `./${routes.surveys.sent.contributors}`,
          isActive: pathname.includes(routes.surveys.sent.contributors),
        },
        {
          item: <FormattedMessage id={'pages.surveys.subjects.surveys'} />,
          to: `./${routes.surveys.sent.surveys}`,
          isActive: pathname.includes(routes.surveys.sent.surveys),
        },
      ],
    },
    {
      item: (
        <Badge badgeContent={incompleteSurveysCount} anchorRightVerticalCenter={!!incompleteSurveysCount}>
          <FormattedMessage id={'pages.surveys.subjects.surveysReceived'} />
        </Badge>
      ),
      to: `./${routes.surveys.received.surveys}`,
      isActive: pathname.includes(routes.surveys.received.surveys),
    },
  ];

  if (!hasFormManagerAccess) {
    return items.slice(1);
  }

  return items;
};

export const crumbs = (
  pathname,
  routes,
  surveyName,
  incompleteSurveysCount,
  account,
  submission,
  currentSurvey,
  hasFormManagerAccess
) => {
  const currentItem = navigationItems(
    pathname,
    routes,
    incompleteSurveysCount,
    hasFormManagerAccess
  )?.[0].dropdownItems?.filter((el) => el.isActive && el.item)[0];

  const submissionOwner = submission?.owner;
  const submissionSurvey = submission?.survey;

  return pathname.includes(routes.surveys.received.surveys)
    ? surveyName
      ? pathname.includes('response-log')
        ? [
            { label: 'Surveys Received', to: `./${routes.surveys.received.surveys}` },
            { label: surveyName, to: pathname.substring(0, pathname.lastIndexOf('/response-log')) },
            { label: 'Response log' },
          ]
        : [{ label: 'Surveys Received', to: `./${routes.surveys.received.surveys}` }, { label: surveyName }]
      : [
          { label: 'Surveys Received', to: `./${routes.surveys.received.surveys}` },
          { label: <FormattedMessage id={'pages.surveys.subjects.received'} /> },
        ]
    : surveyName && !pathname.includes(routes.surveys.sent.contributors)
    ? pathname.includes('survey-log')
      ? [
          { label: 'Surveys Sent', to: `./${routes.surveys.sent.overview}` },
          { label: 'All Surveys', to: `./${routes.surveys.sent.surveys}` },
          { label: surveyName, to: pathname.substring(0, pathname.lastIndexOf('/survey-log')) },
          { label: 'Survey log' },
        ]
      : [
          { label: 'Surveys Sent', to: `./${routes.surveys.sent.overview}` },
          { label: 'All Surveys', to: `./${routes.surveys.sent.surveys}` },
          { label: surveyName },
        ]
    : account && pathname.includes(routes.surveys.sent.contributors) && !pathname.includes('response-log')
    ? [
        { to: `./${routes.surveys.sent.overview}`, label: 'Surveys sent' },
        { to: `./${routes.surveys.sent.contributors}`, label: 'Contributors' },
        { label: account.name },
      ]
    : submission && pathname.includes('submission')
    ? [
        { to: `./${routes.surveys.sent.overview}`, label: 'Surveys sent' },
        { to: `./${routes.surveys.sent.contributors}`, label: 'Contributors' },
        { to: `./${routes.surveys.sent.contributors}/${submissionOwner?.id}`, label: submissionOwner?.name },
        {
          label: submissionSurvey?.name,
        },
      ]
    : pathname.includes(routes.surveys.sent.contributors) && pathname.includes('response-log')
    ? [
        { to: `./${routes.surveys.sent.overview}`, label: 'Surveys sent' },
        { to: `./${routes.surveys.sent.contributors}`, label: 'Contributors' },
        { to: `./${routes.surveys.sent.contributors}/${account?.id}`, label: account?.name },
        {
          label: currentSurvey?.name,
          to: `./${routes.surveys.sent.contributors}/${account?.id}/submission/${currentSurvey?.submission?.id}`,
        },
        { label: 'Response log' },
      ]
    : [
        { label: 'Surveys Sent', to: `./${routes.surveys.sent.overview}` },
        { label: currentItem?.item || <FormattedMessage id={'pages.surveys.subjects.overview'} /> },
      ];
};

export const handleChangeTitle = (pathname, routes, surveyName, account, submission) => {
  if (surveyName && pathname.includes(routes.surveys.received.surveys) && !pathname.includes('response-log')) {
    return capitalizeFirstLetter(surveyName);
  }
  if (account && pathname.includes(routes.surveys.sent.contributors) && !pathname.includes('response-log')) {
    return capitalizeFirstLetter(account.name);
  }
  if (submission && pathname.includes('submission')) {
    return capitalizeFirstLetter(submission.survey?.name);
  }
  if (pathname.includes(routes.surveys.received.surveys) && !surveyName && !pathname.includes('response-log')) {
    return 'Surveys received';
  }
  if (!submission && pathname.includes(routes.surveys.sent.contributors) && !pathname.includes('response-log')) {
    return 'Contributors';
  }
  if (pathname.includes(routes.surveys.sent.surveys) && !surveyName && !pathname.includes('survey-log')) {
    return 'All surveys';
  }
  if (pathname.includes(routes.surveys.sent.surveys) && surveyName && !pathname.includes('survey-log')) {
    return capitalizeFirstLetter(surveyName);
  }
  if (pathname.includes('response-log')) {
    return 'Response log';
  }
  if (pathname.includes('survey-log')) {
    return 'Survey log';
  }

  return 'Ready made sustainability surveys';
};

export const getPathnameId = (pathname) => {
  const segments = pathname.split('/');
  const patternLog = /\/surveys\/sent\/surveys\/([a-f0-9-]+)\/survey-log/;
  const pattern = /\/surveys\/received\/([a-f0-9-]+)\/response-log/;

  const isContributor = pathname.includes('contributors');
  const uuid =
    pathname.includes('response-log') && isContributor ? segments[segments.length - 3] : segments[segments.length - 1];

  const match = pathname.includes('survey-log') ? pathname.match(patternLog) : pathname.match(pattern);

  return match ? match[1] : uuid;
};

export const getSurveyId = (pathname) => {
  const segments = pathname.split('/');
  const isContributor = pathname.includes('contributors');
  const uuid = isContributor ? segments[segments.length - 1] : '';

  return uuid;
};

export const extractSurveyIdFromPathname = (pathname) => {
  const idRegex = /\/surveys\/sent\/surveys\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/;
  const match = pathname.match(idRegex);
  return match ? match[1] : null;
};

export const hasUUID = (pathname: string) => {
  const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  return uuidRegex.test(pathname);
};
