import { Query, QueryGetBuildingEnergyRatingsByAccountIdArgs } from '@rio/rio-types';
import { GET_BUILDING_ENERGY_RATINGS_BY_ACCOUNT_ID } from './index.queries';
import { usePageSuspendingLazyQuery } from '~/hooks';

type ResponseType = Pick<Query, 'getBuildingEnergyRatingsByAccountId'>;

export function useGetBuildingEnergyRatingsByAccountId() {
  return usePageSuspendingLazyQuery<ResponseType, QueryGetBuildingEnergyRatingsByAccountIdArgs>(
    GET_BUILDING_ENERGY_RATINGS_BY_ACCOUNT_ID
  );
}
