import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Query, OcrOutput } from '@rio/rio-types';
import { Button } from 'rio-ui-components';
import * as _ from 'lodash';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import NoResults from '../../../components/NoResults';
import PageHeader from '../../../components/PageHeader';
import { Async } from '../../../components/Async';
import { useNotification } from '../../../hooks';
import ReviewOcrModal from './ReviewOcrModal';
import { Grid } from './EnergyGrid';
import { GET_OCR_DATA } from './index.queries';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  overflow: auto;
  margin: 32px 48px 0 48px;
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
`;

interface OcrReviewContainerProps {
  utility: string;
}

type Response = Pick<Query, 'getOcrDataForAccount'>;

const OcrReviewContainer = ({ utility }: OcrReviewContainerProps) => {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const [selectedUpload, setSelectedUpload] = useState<OcrOutput | null>(null);

  const result = useQuery<Response>(GET_OCR_DATA, { variables: { accountId, utility }, pollInterval: 5000 });

  const renderData = useCallback(
    ({ getOcrDataForAccount: ocrUploads }) =>
      ocrUploads.length ? (
        <ContentContainer>
          <Grid uploads={ocrUploads} onEdit={(upload: OcrOutput) => setSelectedUpload(upload)} />
        </ContentContainer>
      ) : (
        <NoResults title="There are currently no OCR results to review." />
      ),
    []
  );

  const handleReviewComplete = useCallback(
    (message: string) => showNotification(message, 'success'),
    [showNotification]
  );

  const handleCloseReviewModal = useCallback(() => setSelectedUpload(null), [setSelectedUpload]);

  const dataSection = _.startCase(utility.toLowerCase());

  const title = `${dataSection} OCR Review`;

  const uploadLink = `/data/${utility.toLowerCase()}/uploads`;

  return (
    <ContainerStyled>
      <PageHeader
        name="Ocr_Header"
        title={title}
        breadcrumbs={[{ title: 'Data', to: '..' }, { title: `${dataSection} Data Uploads`, to: uploadLink }, { title }]}
        icon="microscope"
        iconColor="quaternary"
      >
        <Button inline component="routerLink" color="info" to={uploadLink}>
          View {_.startCase(utility.toLowerCase())} Uploads
        </Button>
      </PageHeader>

      <Async<Response> result={result} data={renderData} />

      {selectedUpload && (
        <ReviewOcrModal
          onDismiss={handleCloseReviewModal}
          selectedUpload={selectedUpload}
          onComplete={handleReviewComplete}
          utility={utility}
        />
      )}
    </ContainerStyled>
  );
};

export default OcrReviewContainer;
