import { ApolloError, useQuery } from '@apollo/client';
import { GET_PARENT_ACCOUNT_AND_CHILDREN, GET_USERS_FOR_ACCOUNTS } from '../query.gql';
import { useCurrentAccountId } from '~/hooks';
import { Account, MysqlUser, Query } from '@rio/rio-types';
import { RecipientSurveyType, useSurveyForm } from './useSurveyForm';

type Row = (Account | MysqlUser) & { orgHierarchy: string[] };

export type UseSurveyRecipientsResult = {
  rows: Row[];
  loading: boolean;
  error?: ApolloError;
};

export function useGetRecipients(): UseSurveyRecipientsResult {
  const accountId = useCurrentAccountId();
  const { surveyType } = useSurveyForm();

  const {
    data: getAccountInfoData,
    loading: getAccountInfoLoading,
    error: getAccountInfoError,
  } = useQuery<Pick<Query, 'getAccountInfo'>>(GET_PARENT_ACCOUNT_AND_CHILDREN, {
    variables: {
      accountId,
    },
  });

  const {
    data: getUsersData,
    loading: getUsersLoading,
    error: getUsersError,
  } = useQuery(GET_USERS_FOR_ACCOUNTS, {
    variables: {
      accountId,
      childrenIds: [accountId].concat(getAccountInfoData?.getAccountInfo.children.map((child) => child.id) ?? []),
    },
  });

  if (getAccountInfoLoading || getUsersLoading) {
    return {
      loading: true,
      rows: [],
    };
  }

  if (getAccountInfoError || getUsersError) {
    return {
      loading: false,
      rows: [],
      error: getAccountInfoError ? getAccountInfoError : getUsersError,
    };
  }

  const { rows: users } = getUsersData.getUsers;

  if (!getAccountInfoData || !getUsersData) {
    return {
      loading: false,
      rows: [],
    };
  }

  return {
    loading: false,
    rows: [
      {
        orgHierarchy: [getAccountInfoData.getAccountInfo.id],
        ...getAccountInfoData.getAccountInfo,
      },
    ]
      .concat(
        users
          .filter((u) => u.accountId === accountId)
          .map((user) => ({
            orgHierarchy: [getAccountInfoData.getAccountInfo.id, user.id],
            ...user,
          }))
      )
      .concat(
        getAccountInfoData.getAccountInfo.children.map((child) => {
          return {
            orgHierarchy: [getAccountInfoData.getAccountInfo.id, child.id],
            ...child,
          };
        })
      )
      .concat(
        ...getAccountInfoData.getAccountInfo.children
          .map((child) => {
            const usersForAccount = users.filter((u) => u.accountId === child.id);
            return usersForAccount.map((user) => ({
              orgHierarchy: [getAccountInfoData.getAccountInfo.id, child.id, user.id],
              ...user,
            }));
          })
          .flat()
      )
      .filter((row) => {
        if (surveyType === RecipientSurveyType.contributor) {
          return row.__typename === 'Account';
        }
        return true;
      }),
  };
}
