import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [
  { label: 'Data', to: `..` },
  { label: 'Investment', to: '/data/investment/view' },
  { label: 'Uploads' },
];

export const V2InvestmentUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      title="Here's your upload history"
      gridKey="investmentUploadGridV2"
      breadcrumbs={breadcrumbs}
      uploadLink="../investment/uploads"
      config={config}
      dataType={TransactionType.Investment}
    />
  );
};
