import { useCallback, useMemo } from 'react';
import { GridReadyEvent, IRowNode, GridOptions } from 'ag-grid-community';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { AgGridReact } from 'ag-grid-react';
import { LinkCell } from '../../../components/CellRenderers';

interface FactorsGridProps extends GridOptions {}

export function FactorsGrid(props: FactorsGridProps) {
  const components = useMemo(
    () => ({
      reference: LinkCell,
    }),
    []
  );
  const [reportingYear] = useQueryParam('reportingYear', NumberParam);
  const [type] = useQueryParam('type', StringParam);

  const handleGridReady = useCallback(
    (e: GridReadyEvent) => {
      // e.g. for client side filtering
      if (e.api.getModel().getType() !== 'serverSide') {
        e.api.forEachNodeAfterFilter((node: IRowNode) => {
          if (node.data.reportingYear === reportingYear) {
            node.setSelected(true);
          }
        });

        e.api.forEachNodeAfterFilter((node: IRowNode) => {
          if (type && node.data.type === type) {
            node.setSelected(true);
          }
        });
      } else {
        // e.g. for server side filtering
        let model = {};
        if (reportingYear) {
          model = {
            ...model,
            ...{
              reportingYear: {
                filterType: 'set',
                values: [reportingYear.toString()],
              },
            },
          };
        }
        if (type) {
          model = {
            ...model,
            ...{
              type: {
                filterType: 'set',
                values: [type],
              },
            },
          };
        }

        e.api?.setFilterModel(model);
      }
      if (props.onGridReady) {
        props.onGridReady(e);
      }
    },
    [type, reportingYear, props]
  );

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        {...props}
        components={components}
        onGridReady={handleGridReady}
        domLayout="autoHeight"
      />
    </div>
  );
}
