import React, { Component } from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import jwt_decode from 'jwt-decode';
import strip from 'strip';
import { isNumeric } from 'validator';
import { injectIntl } from 'react-intl';
import { Col, Row, Label, TextInput, Button, Heading, Select, TextArea, Notification } from 'rio-ui-components';
import SEND_CONTACT_EMAIL from '../../graphql/mutations/contact/sendEmail.mutation.graphql';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  min-height: 100%;
  display: flex;
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  padding: 0 0 ${(props) => props.theme.geometry.xs.spacing} 0;
`;

const TextInputStyled = styled(TextInput)`
  padding-bottom: ${(props) => props.theme.geometry.xs.spacing};
`;

const TextAreaStyled = styled(TextArea)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const FormStyled = styled.form`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const ButtonStyled = styled(Button)``;

class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: { message: '', color: 'success', id: null },
      values: {
        fullname: {
          value: '',
          error: ''
        },
        companyname: {
          value: '',
          error: ''
        },
        phonenumber: {
          value: '',
          error: ''
        },
        subject: {
          value: '',
          error: '',
          selectedOption: ''
        },
        enquiry: {
          value: '',
          error: ''
        }
      },
      options: [
        { value: 'comply', label: 'Comply' },
        { value: 'data', label: 'Data' },
        { value: 'learn', label: 'Engage' },
        { value: 'other', label: 'Other' },
        { value: 'reporting', label: 'Reporting' },
        { value: 'upgrades', label: 'Upgrades' }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onCreateComplete = this.onCreateComplete.bind(this);
    this.onCreateError = this.onCreateError.bind(this);
    this.onNotificationClose = this.onNotificationClose.bind(this);
  }

  onCreateComplete() {
    this.setState({
      notification: { message: `Your enquiry has been sent!`, color: 'success' },
      values: {
        fullname: {
          value: '',
          error: ''
        },
        companyname: {
          value: '',
          error: ''
        },
        phonenumber: {
          value: '',
          error: ''
        },
        subject: {
          value: '',
          error: '',
          selectedOption: null
        },
        enquiry: {
          value: '',
          error: ''
        }
      }
    });
  }

  onCreateError() {
    this.setState({
      notification: { message: `Something went wrong. If the problem persists, contact support.`, color: 'danger' }
    });
  }

  onNotificationClose() {
    this.setState({
      notification: { message: '', color: '' }
    });
  }

  handleSubmit(e, callback) {
    e.preventDefault();

    const newFullNameState = { ...this.state.values.fullname };
    const newCompanyNameState = { ...this.state.values.companyname };
    const newPhoneNumberState = { ...this.state.values.phonenumber };
    const newSubjectState = { ...this.state.values.subject };
    const newEnquiryState = { ...this.state.values.enquiry };

    newFullNameState.error = !this.state.values.fullname.value ? 'Please enter your full name.' : '';
    newCompanyNameState.error = !this.state.values.companyname.value ? 'Please enter a valid company name.' : '';
    newPhoneNumberState.error = !this.state.values.phonenumber.value
      ? 'Please enter a valid phone number.'
      : !isNumeric(this.state.values.phonenumber.value)
      ? 'Only numbers are valid in this field.'
      : '';
    newEnquiryState.error = !this.state.values.enquiry.value
      ? 'Please enter an enquiry.'
      : this.state.values.enquiry.value.length > 1000
      ? 'You have exceeded the character limit.'
      : '';
    newSubjectState.error = !this.state.values.subject.value ? 'Please choose an option.' : '';

    this.setState({
      values: {
        fullname: newFullNameState,
        companyname: newCompanyNameState,
        phonenumber: newPhoneNumberState,
        subject: newSubjectState,
        enquiry: newEnquiryState
      }
    });

    if (
      !newFullNameState.error &&
      !newCompanyNameState.error &&
      !newPhoneNumberState.error &&
      !newEnquiryState.error &&
      !newSubjectState.error
    ) {
      const replace = {
        name: strip(this.state.values.fullname.value),
        company: strip(this.state.values.companyname.value),
        email: jwt_decode(localStorage.getItem('ID_TOKEN')).email,
        phone: strip(this.state.values.phonenumber.value),
        enquiry: strip(this.state.values.enquiry.value),
        topic: strip(this.state.values.subject.value)
      };

      callback({
        variables: {
          replacements: JSON.stringify(replace)
        }
      });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    if (name === 'subject') {
      this.setState({
        values: {
          ...this.state.values,
          [name]: { value: value, selectedOption: e.option, error: '' }
        }
      });
    } else {
      this.setState({
        values: {
          ...this.state.values,
          [name]: { value: value, error: '' }
        }
      });
    }
  }

  handleSelectChange(option) {
    const e = {
      target: {
        name: option.target.name,
        value: option.target.value,
        option: option
      }
    };

    this.handleChange(e);
  }

  render() {
    const { intl } = this.props;

    return (
      <div>
        {this.state.notification.message && (
          <Notification
            name="send_email__notification"
            show
            color={this.state.notification.color}
            onClose={this.onNotificationClose}
          >
            {this.state.notification.message}
          </Notification>
        )}
        <Mutation mutation={SEND_CONTACT_EMAIL} onCompleted={this.onCreateComplete} onError={this.onCreateError}>
          {(sendEmail) => (
            <FormStyled name="email-form" onSubmit={(e) => this.handleSubmit(e, sendEmail)}>
              <HeaderStyled size="lg" align="left">
                {intl.formatMessage({ id: 'pages.support.header' })}
              </HeaderStyled>
              <Row container>
                <ColStyled span={6}>
                  <LabelContainerStyled>
                    <LabelStyled>{intl.formatMessage({ id: 'pages.support.fullName' })}</LabelStyled>
                  </LabelContainerStyled>
                  <TextInputStyled
                    name="fullname"
                    onChange={this.handleChange}
                    value={this.state.values.fullname.value}
                    error={this.state.values.fullname.error}
                    placeholder="John Doe"
                    box
                  />
                  <LabelContainerStyled>
                    <LabelStyled>{intl.formatMessage({ id: 'pages.support.companyName' })}</LabelStyled>
                  </LabelContainerStyled>
                  <TextInputStyled
                    name="companyname"
                    onChange={this.handleChange}
                    value={this.state.values.companyname.value}
                    error={this.state.values.companyname.error}
                    placeholder="Rio ESG LTD"
                    box
                  />
                  <LabelContainerStyled>
                    <LabelStyled>{intl.formatMessage({ id: 'pages.support.phoneNumber' })}</LabelStyled>
                  </LabelContainerStyled>
                  <TextInputStyled
                    name="phonenumber"
                    onChange={this.handleChange}
                    value={this.state.values.phonenumber.value}
                    error={this.state.values.phonenumber.error}
                    placeholder="07000000000"
                    box
                  />
                  <LabelContainerStyled>
                    <LabelStyled>{intl.formatMessage({ id: 'pages.support.enquiryQuestion' })}</LabelStyled>
                  </LabelContainerStyled>
                  <Select
                    name="subject"
                    options={this.state.options}
                    onChange={this.handleSelectChange}
                    value={this.state.values.subject.selectedOption}
                    error={this.state.values.subject.error}
                    placeholder={intl.formatMessage({ id: 'pages.support.enquirySelectPlaceholder' })}
                  />
                </ColStyled>

                <ColStyled span={6}>
                  <LabelContainerStyled>
                    <LabelStyled>{intl.formatMessage({ id: 'pages.support.enquiry' })}</LabelStyled>
                  </LabelContainerStyled>
                  <TextAreaStyled
                    flex
                    name="enquiry"
                    onChange={this.handleChange}
                    value={this.state.values.enquiry.value}
                    error={this.state.values.enquiry.error}
                    box
                    displayCharCounter
                    maxLength={1000}
                    placeholder={intl.formatMessage({
                      id: 'pages.support.enquiryPlaceholder'
                    })}
                  />
                  <ButtonStyled name="send" submit>
                    {intl.formatMessage({ id: 'pages.support.send' })}
                  </ButtonStyled>
                </ColStyled>
              </Row>
            </FormStyled>
          )}
        </Mutation>
      </div>
    );
  }
}

export default injectIntl(EmailForm);
