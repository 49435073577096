import { useApolloClient } from '@apollo/client';
import { TransactionType } from '@rio/rio-types';
import { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'rio-ui-components';
import EnergyDetails from './EnergyDetails';
import WaterDetails from './WaterDetails';
import { constructEnergyData, constructWaterData } from './createOcrCsv';
import { createCsv } from '../../../utils/excelUtils';
import { MARK_AS_REVIEWED } from './index.queries';
import { UPLOAD_DATA, INITIATE_DATA_UPLOAD } from '../../../components/UploadProcess/index.queries';
import energyColumns from '../GasContainer/UploadContainer/columns.json';
import waterColumns from '../WaterContainer/UploadContainer/columns.json';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const ReviewOcrModal = ({ onDismiss, selectedUpload, onComplete, utility }) => {
  const [upload, setUpload] = useState(selectedUpload);
  const client = useApolloClient();

  const handleSubmit = async () => {
    try {
      const constructedData =
        utility === TransactionType.Water
          ? constructWaterData(upload, selectedUpload.fileName)
          : constructEnergyData(upload, selectedUpload.fileName);
      const file =
        utility === TransactionType.Water
          ? await createCsv(constructedData, selectedUpload.fileName, waterColumns)
          : await createCsv(constructedData, selectedUpload.fileName, energyColumns);
      const {
        data: { initiateDataUpload: signedUrl }
      } = await client.mutate({
        mutation: INITIATE_DATA_UPLOAD,
        variables: {
          transactionType: utility.toUpperCase(),
          accountId: upload.accountId,
          fileName: file.name,
          isDirectActivity: true
        }
      });

      await client.mutate({
        mutation: UPLOAD_DATA,
        variables: {
          file: file,
          fileName: file.name,
          accId: upload.accountId,
          url: signedUrl
        }
      });

      await client.mutate({
        mutation: MARK_AS_REVIEWED,
        variables: {
          id: selectedUpload.id
        },
        refetchQueries: ['GetOcrDataForAccount']
      });

      onComplete({ colour: 'success', message: 'Transaction uploaded. Check uploads page to view progress.' });
      onDismiss();
    } catch (error) {
      onComplete({ colour: 'danger', message: 'Something went wrong. Please try again later or contact support.' });
    }
  };

  return (
    <Modal size="md" show onDismiss={() => onDismiss()}>
      <ContainerStyled>
        {utility !== TransactionType.Water && (
          <EnergyDetails heading="Review OCR Results" upload={upload} setUpload={setUpload} />
        )}
        {utility === TransactionType.Water && (
          <WaterDetails heading="Review OCR Results" upload={upload} setUpload={setUpload} />
        )}
        <Row container align="between">
          <ColStyled item>
            <Button onClick={handleSubmit}>Accept Results</Button>
          </ColStyled>
        </Row>
      </ContainerStyled>
    </Modal>
  );
};

export default ReviewOcrModal;
