import { Routes, Route, Navigate } from 'react-router-dom';
import DocumentFiles from './DocumentFiles';
import DocumentFile from './DocumentFile';
import DocumentLandingPage from './DocumentLandingPage';
import AccessController from './DocumentLibraryAccessController';
import { ManagementSystems, ManagementSystem } from './ManagementSystems';
import libraries from './documentLibraries';
import types from './documentTypes';
import { useRoutes } from '~/hooks';
import { DocumentTypeFolder } from './types';
import { styled } from '@rio/ui-components';

const ContainerStyled = styled('div')`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const DocumentContainer = () => {
  const routes = useRoutes();
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<DocumentLandingPage libraries={libraries} types={types} />} />
        <Route path={routes.docs.all} element={<DocumentFiles documentFolders={types} />} />
        <Route path={`${routes.docs.all}/:id`} element={<DocumentFile />} />
        <Route path={routes.docs.systems} element={<ManagementSystems />} />
        <Route path={`${routes.docs.systems}/:id`} element={<ManagementSystem />} />
        <Route path={`${routes.docs.systems}/:id/:itemId`} element={<ManagementSystem />} />
        <Route
          path="/:library"
          element={
            <AccessController>
              <DocumentLandingPage libraries={libraries} types={types} />
            </AccessController>
          }
        />
        <Route
          path="/:library/:type"
          element={
            <AccessController>
              <DocumentFiles documentFolders={types as DocumentTypeFolder[]} />
            </AccessController>
          }
        />
        <Route
          path="/:library/:type/:id"
          element={
            <AccessController>
              <DocumentFile />
            </AccessController>
          }
        />
        <Route
          path="/:library/:id/edit"
          element={
            <AccessController>
              <DocumentFile />
            </AccessController>
          }
        />
        <Route path="*" element={<Navigate to={routes.docs.root} />} />
      </Routes>
    </ContainerStyled>
  );
};

export default DocumentContainer;
