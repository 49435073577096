interface CircleFragmentProps {
  fill: string;
}

export const CircleFragment = ({ fill }: CircleFragmentProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM46 25C46 36.598 36.598 46 25 46C13.402 46 4 36.598 4 25C4 13.402 13.402 4 25 4C36.598 4 46 13.402 46 25Z"
    fill={fill}
  />
);
