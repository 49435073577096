import { AnomalyThreshold, AnomalyThresholdInput } from '@rio/rio-types';
import { get } from 'lodash';
import { namedEntityToSelectOption } from '../../../utils';
import { AnomalyFormattedDetails } from './types';

export const formatAnomalyThresholdData = (anomalyThreshold: AnomalyThreshold): AnomalyFormattedDetails => {
  return {
    ...anomalyThreshold,
    account: namedEntityToSelectOption(anomalyThreshold.account),
  };
};

export const prepareAnomalyThresholdInput = (
  anomalyThresholdConfig: AnomalyFormattedDetails,
  isUpdate: boolean
): AnomalyThresholdInput => {
  const accountId = anomalyThresholdConfig?.account?.value;

  if (accountId === undefined) {
    throw new Error('Account ID is undefined');
  }

  const thresholdPercentage = Number(anomalyThresholdConfig?.thresholdPercentage);

  if (isNaN(thresholdPercentage)) {
    throw new Error('Threshold percentage is not a number');
  }

  const transactionType = anomalyThresholdConfig.utilityType.transactionType;

  const utilityType = { transactionType, isDefault: !transactionType };

  if (!utilityType) {
    throw new Error('Utility type is required');
  }

  const variables: AnomalyThresholdInput = { accountId, thresholdPercentage, utilityType };

  const id = anomalyThresholdConfig.id;

  if (isUpdate) {
    if (!id) {
      throw new Error('Id is required');
    }
    variables.id = id;
  }

  return variables;
};

export const isTextEvent = (event: object) => {
  const type = get(event, 'target.type');
  return type === 'text' || type === 'textarea';
};
