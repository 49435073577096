import { useQueryParam, StringParam } from 'use-query-params';
import { parseUrl } from 'query-string';
import { Nullable } from '../../../types';

const PARAM_NAME = 'searchValue';

export function useAccountSearchValueFromQueryParam() {
  return useQueryParam(PARAM_NAME, StringParam);
}

export function getAccountSearchValue() {
  const { query } = parseUrl(window.location.href);
  return query[PARAM_NAME] as Nullable<string>;
}
