import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useUserToken } from '../../hooks/useUserToken';
import { getEnvVar } from '../../env';
import { useIsV2 } from '../../hooks/useIsV2';

const HubspotTrackerWrapper = ({ children }) => {
  // eslint-disable-next-line
  const hubspotQueue = (window._hsq = window._hsq || []);
  const location = useLocation();
  const v2 = useIsV2();
  const { token } = useUserToken();

  useLayoutEffect(() => {
    const hubspotScript = document.createElement('script');
    hubspotScript.setAttribute('id', 'hs-script-loader');
    hubspotScript.setAttribute('src', `https://js.hs-scripts.com/${getEnvVar('REACT_APP_HUBSPOT_TRACKER')}.js`);
    document.head.appendChild(hubspotScript);
  }, []);

  useLayoutEffect(() => {
    hubspotQueue.push(['setPath', location.pathname]);
    hubspotQueue.push(['trackPageView']);
  }, [location, hubspotQueue]);

  useEffect(() => {
    if (token) {
      hubspotQueue.push([
        'identify',
        {
          email: token?.email,
        },
      ]);
    }
  }, [hubspotQueue, token]);

  return children;
};

export default HubspotTrackerWrapper;
