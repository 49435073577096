import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Heading, Text, Button, ErrorMessage, ContainerError, Modal } from 'rio-ui-components';
import { Tag, UserPermissions } from '@rio/rio-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import PageHeader from '../../../components/PageHeader';
import FileIcon from '../../../components/FileIcon';
import { NotAvailable } from '../../../components/Errors';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { GET_DOCUMENT, DELETE_DOCUMENT_RECORD } from '../index.queries';
import { toDate, toDateTime, getLibraryColour } from '../utils';
import { EditDocumentModal } from '../DocumentModal';
import { getFullName } from '../../../utils/utils';
import { CLEAN } from '../../../constants/antivirusStatuses';
import { usePermissions } from '../../../hooks/usePermissions';
import { useNotification, useSuppliers } from '../../../hooks';
import { MAX_ITEMS_IN_MEMORY_LIMIT } from '../../../constants';

const ContainerStyled = styled(Col)`
  overflow: hidden;
`;
const ContentOuterContainer = styled(Col)`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ContentContainer = styled(Row)`
  background-color: ${(props) => props.theme.colors.basic.white};
`;
const TitleRowStyled = styled(Row)`
  flex: 0 0 auto;
  padding-bottom: ${(props) => props.theme.geometry.md.spacing};
  padding-top: 0;
`;
const FileIconStyled = styled(FileIcon)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ButtonContainer = styled.div`
  flex: 0 0 auto;
`;
const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
  border: 1px solid ${(props) => props.theme.colors.neutral.dark.background};
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
const RowStyled = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;
const HeadingCenterStyled = styled(Heading)`
  display: flex;
  align-items: center;
`;
const TitleContainerStyled = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;
const Separator = styled.span`
  margin: 0 ${(props) => props.theme.geometry.xs.spacing};
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: ${(p) => p.theme.geometry.lg.spacing};
`;
const NotesText = styled(Text)`
  max-width: 50%;
`;

const MAX_STRING_LENGTH = 50;

const isNotFoundError = (errors: { errorType: string }[]) => errors.some((e) => e.errorType === 'NotFoundException');

const checkPermissions = (permissions: UserPermissions, action: string, isOwnResource: boolean) =>
  !!permissions.document?.find((a) => a?.startsWith(action) && !a.endsWith('Own')) ||
  (permissions.document?.includes(`${action}Own`) && isOwnResource);

function DocumentFile() {
  const { state: routeState } = useLocation();
  const { taskId } = routeState ? (routeState as Record<string, unknown>) : { taskId: null };
  const { projectId } = routeState ? (routeState as Record<string, unknown>) : { projectId: null };
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { id, library, type } = useParams();
  const { showNotification } = useNotification();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const createBreadcrumbs = () => {
    const crumbs = [{ title: 'Documents', to: '/documents' }];

    if (library) {
      crumbs.push({ title: `${_.capitalize(library)}`, to: `/documents/${library}` });
      crumbs.push({ title: `${_.startCase(type)}`, to: `/documents/${library}/${type}` });
    } else {
      crumbs.push({ title: 'All', to: `/documents/all` });
    }
    return crumbs;
  };
  const [deleteDocumentRecord] = useMutation(DELETE_DOCUMENT_RECORD);
  const variables = {
    id,
    accountId
  };
  const { data, error, loading, refetch } = useQuery(GET_DOCUMENT, {
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  });

  const document = data && data.getDocumentRecordById ? data.getDocumentRecordById : {};
  const isClean = document.antivirusStatus === CLEAN;
  const isReadOnly = !permissions.document.find((action: string) => action.startsWith('create'));
  const canDelete = checkPermissions(permissions, 'delete', document?.createdBy?.id === token.sub);

  const apolloClient = useApolloClient();
  const suppliers = useSuppliers({
    pageSize: MAX_ITEMS_IN_MEMORY_LIMIT
  });
  const suppliersIndex = _.keyBy(suppliers, 'id');
  const supplier = suppliersIndex[document.supplierId];
  const goBack = () =>
    navigate(
      library && type
        ? `/documents/${library}/${type}`
        : library
        ? `/documents/${library}`
        : taskId
        ? `/tasks/${taskId}`
        : projectId
        ? `/projects/${projectId}`
        : `/documents/all`
    );

  return (
    <ContainerStyled container item fullHeight name="DocumentFile">
      <PageHeader
        name="DocumentFile"
        title={`${
          document.category || (routeState ? _.startCase((routeState as any).documentType) : _.startCase(type))
        } Document`}
        breadcrumbs={createBreadcrumbs()}
        icon="file-alt"
        iconColor={getLibraryColour(library)}
        isDropdown={false}
        dropdownDefault={null}
        dropdownItems={[]}
      >
        <Button name="DocumentFile__Button--back" inline color="info" onClick={goBack}>
          Back
        </Button>
      </PageHeader>

      {loading && <ContainerLoadingIndicator name="loading" />}
      {error && !data?.getDocumentRecordById && isNotFoundError(error.graphQLErrors as any) && (
        <NotAvailable name="Not available" error={new Error('Not available')} />
      )}
      {error && !data?.getDocumentRecordById && !isNotFoundError(error.graphQLErrors as any) && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError
              name="DocumentFile-Error"
              title={title}
              body={body}
              icon={icon}
              retry={() => refetch(variables)}
            />
          )}
        </ErrorMessage>
      )}
      {!loading && data?.getDocumentRecordById && (
        <ContentOuterContainer container item>
          <TitleRowStyled container distribution="between" itemAlign="center">
            <TitleContainerStyled container itemAlign="center">
              <FileIconStyled name="DocumentFile" size="3x" fileName={document.fileName} />
              <Col container item>
                <Heading nowrap overflow="hidden" name="DocumentFile__Title" weight="light" title={document.fileName}>
                  {_.truncate(document.fileName, {
                    length: MAX_STRING_LENGTH
                  })}
                </Heading>
                <Text name="DocumentFile__Title">
                  <Text name="DocumentFile__Title" inline weight="bold">
                    {document.category}
                  </Text>
                  <Separator>|</Separator> Uploaded on {toDateTime(document.createdAt)}
                </Text>
              </Col>
            </TitleContainerStyled>
            <ButtonContainer>
              <ButtonStyled
                name="DocumentFile__Button--download"
                inline
                color="info"
                disabled={!isClean}
                title={isClean ? 'Open the document' : `Can't retrieve document since it didn't pass antivirus check`}
                onClick={() => {
                  if (isClean) {
                    window.open(document.link);
                  }
                }}
              >
                Open
              </ButtonStyled>
              {!isReadOnly && (
                <ButtonStyled
                  name="DocumentFile__Button--edit"
                  inline
                  color="info"
                  component="a"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </ButtonStyled>
              )}
              <>
                {showDeleteModal && (
                  <>
                    <Modal show onDismiss={() => setShowDeleteModal(false)} height="auto" maxHeight="90vh">
                      <ModalContainer>
                        <Row container>
                          <Heading>Are you sure you want to delete this Document?</Heading>
                        </Row>
                        <Row container distribution="around">
                          <Col item span={4}>
                            <Button
                              onClick={async () => {
                                try {
                                  await deleteDocumentRecord({ variables: { id } });
                                  navigate(-1);
                                  showNotification('Document has been successfully deleted', 'success');
                                } catch (err) {
                                  showNotification('An Error has occurred', 'danger');
                                } finally {
                                  setShowDeleteModal(false);
                                }
                              }}
                            >
                              Yes
                            </Button>
                          </Col>
                          <Col item span={4}>
                            <Button onClick={() => setShowDeleteModal(false)}>No</Button>
                          </Col>
                        </Row>
                      </ModalContainer>
                    </Modal>
                  </>
                )}
                {canDelete && (
                  <ButtonStyled
                    name="DocumentFile__Button--delete"
                    inline
                    color="info"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete
                  </ButtonStyled>
                )}
              </>
            </ButtonContainer>
          </TitleRowStyled>

          <ContentContainer>
            {document.type && Array.isArray(document.type) && !!document.type.length && (
              <RowStyled container distribution="between" vdistribution="center">
                <HeadingCenterStyled size="md">Type:</HeadingCenterStyled>
                <Text>{document.type.join(', ')}</Text>
              </RowStyled>
            )}
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Review Date:</HeadingCenterStyled>
              <Text>{document.reviewDate ? toDate(document.reviewDate) : '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">{document.category} ID:</HeadingCenterStyled>
              <Text>{document.referenceId ? document.referenceId : '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Supplier:</HeadingCenterStyled>
              <Text>{(supplier && supplier.name) || '-'}</Text>
            </RowStyled>
            {document.managementSystemCode && (
              <RowStyled container distribution="between" vdistribution="center">
                <HeadingCenterStyled size="md">Management System Reference:</HeadingCenterStyled>
                <Text>{document.managementSystemCode.toUpperCase()}</Text>
              </RowStyled>
            )}
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Uploaded on:</HeadingCenterStyled>
              <Text>{toDateTime(document.createdAt) || '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Uploaded by:</HeadingCenterStyled>
              <Text>{getFullName(document.createdBy) || '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Last updated on:</HeadingCenterStyled>
              <Text>{toDateTime(document.updatedAt) || '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Last updated by:</HeadingCenterStyled>
              <Text>{getFullName(document.updatedBy) || '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Tags:</HeadingCenterStyled>
              <Text>{!!document.tags.length ? document.tags.map((tag: Tag) => tag.tagName).join(', ') : '-'}</Text>
            </RowStyled>
            <RowStyled container distribution="between" vdistribution="center">
              <HeadingCenterStyled size="md">Notes:</HeadingCenterStyled>
              <NotesText>{document?.notes || '-'}</NotesText>
            </RowStyled>
            {showEditModal && (
              <EditDocumentModal
                document={document}
                onComplete={(updatedDocument) => {
                  apolloClient.writeQuery({
                    query: GET_DOCUMENT,
                    variables,
                    data: {
                      getDocumentRecordById: {
                        ...updatedDocument,
                        updatedAt: new Date().toISOString(),
                        updatedBy: {
                          first_name: token.given_name,
                          last_name: token.family_name,
                          __typename: 'User'
                        }
                      }
                    }
                  });
                  setTimeout(() => refetch(variables), 500);
                  setShowEditModal(false);
                  showNotification('Document has been edited!', 'success');
                }}
                onError={(err) => {
                  showNotification(err.message, 'danger');
                }}
                onDismiss={() => setShowEditModal(false)}
              />
            )}
          </ContentContainer>
        </ContentOuterContainer>
      )}
    </ContainerStyled>
  );
}

export default DocumentFile;
