import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { TransactionType, EnergySource, Accuracy } from '@rio/rio-types';
import { Dictionary, Option, SelectEvent } from './../../types';
import { Checkbox, Row, TextInput } from 'rio-ui-components';
import { NumberInput, NumberInputWithNegativeOption } from '../UI';
import { CreateMeterModal } from '../CreateMeterModal';
import { useGetMeters, useCurrentAccountId, usePermissions, useCurrency } from '../../hooks';
import { Controller, Control } from 'react-hook-form';
import { DataForm, SelectRender } from '../DataForm';
import { SupplierSelector } from '../SuppliersSelector';
import {
  CenteredCol,
  ColStyled,
  LabelContainerStyled,
  LabelStyled,
  Loader,
  RowWithPadding,
  SectionStyled,
  SubHeader,
} from '../DataForm/DataFormStyles';

type Reference = Partial<{
  invoiceNumber: string | number;
  evidenceReference: string | number;
  otherReference: string | number;
}>;

type Data = {
  tariff?: number;
  totalCost?: number;
  quantity?: number;
  currency?: string;
};

type FormData = Partial<{
  data: Data;
  reference: Reference;
}>;

interface FormProps {
  setValue: (field: string, value: string | number | boolean | Option) => void;
  getValues: (name?: string) => FormData;
  control: Control;
  trigger: () => void;
  register: (field: string) => void;
  watch: (fields: string[]) => boolean[];
}

interface WaterDataProps {
  form: FormProps;
  onSubmit: Function;
  dataSection: TransactionType;
  children: React.ReactNode;
}

type SubmitProps = {
  data: { accuracy: Dictionary<string> };
  financial: any;
  reference: object;
};

export function WaterDataForm(props: WaterDataProps) {
  /**
   * PROPS
   */
  const { form, onSubmit = () => {}, dataSection } = props;
  const { setValue, control, trigger, register, watch } = form;

  /**
   * STATE
   */
  const [isMeterFormVisible, setMeterFormVisible] = useState(false);
  const [currencyInitialState] = useState(form.getValues('data.currency'));

  /**
   * HOOKS
   */
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { data: metersList, loading: metersLoading } = useGetMeters(dataSection);
  const { data: currenciesList, loading: currenciesListLoading } = useCurrency();

  const canCreateMeters = permissions.data.find((action: string) => action.startsWith('createEnergySource'));

  useEffect(() => {
    register('isReferenceDataRequired');
  }, [register]);

  const [isReferenceDataRequired] = watch(['isReferenceDataRequired']);

  const newOptionStrategy: Dictionary<Function> = {
    'data.energySource': () => setMeterFormVisible(true),
  };

  const createNewOption = (name: string) => newOptionStrategy[name] && newOptionStrategy[name]();

  const setReferenceDataRequired = (value: boolean) => setValue('isReferenceDataRequired', value);

  const closeCreatedMeterForm = () => setMeterFormVisible(false);
  const selectCreatedMeter = (key: string, value: boolean) => {
    setValue(key, value);
    trigger();

    closeCreatedMeterForm();
  };

  const showReferenceData = () => setReferenceDataRequired(true);
  const hideReferenceData = () => setReferenceDataRequired(false);

  /**
   * SUBMIT
   * mapping data on submit event
   */
  const handleOnSubmit = ({ data, financial, reference, ...others }: SubmitProps) => {
    const formData = {
      ...others,
      data: {
        ...data,
        accuracy: data.accuracy.name,
        currency: financial?.currency?.name || currencyInitialState,
      },
    };

    if (isReferenceDataRequired) {
      formData.data = { ...formData.data, ...reference };
    }

    onSubmit(formData);
  };

  const entityToDropdownOption = useCallback(
    ({ id, name }: Record<string, string>) => ({
      label: name,
      value: { id, name },
    }),
    []
  );

  const meterToDropdownOption = ({ id, name, locationPoint, inOut }: EnergySource) => ({
    label: name,
    value: { id, name, locationPoint, inOut },
  });

  /**
   * OPTIONS
   */
  const actEstOptions = useMemo(
    () =>
      [
        {
          id: Accuracy.Act,
          name: Accuracy.Act,
        },
        {
          id: Accuracy.Est,
          name: Accuracy.Est,
        },
      ].map(entityToDropdownOption),
    [entityToDropdownOption]
  );
  const metersOptions = metersList.map(meterToDropdownOption);
  if (canCreateMeters) {
    metersOptions.unshift({
      label: 'Create new ...',
      value: { id: 'createNew' },
    });
  }
  const currencyOptions = currenciesList.map(entityToDropdownOption);

  /**
   * DEFAULT FORM VALUES
   */
  const accuracyDefaultValues = { id: Accuracy.Act, name: Accuracy.Act };
  const currencyDefaultValue = currencyOptions.find((item: any) => item.label === currencyInitialState)?.value;

  const isLoading = metersLoading || currenciesListLoading;

  const calculateTariff = () => {
    const totalCost = form.getValues().data?.totalCost || 0;
    const quantity = form.getValues().data?.quantity || 0;

    if (totalCost > 0 && quantity > 0) {
      setValue('data.tariff', totalCost / quantity);
    }
  };

  const calculateTotalCost = () => {
    const tariff = form.getValues().data?.tariff || 0;
    const quantity = form.getValues().data?.quantity || 0;

    if (tariff > 0 && quantity > 0) {
      setValue('data.totalCost', tariff * quantity);
    }
  };

  const handleQuantityChange = (value: number) => {
    setValue('data.quantity', value);
    calculateTariff();
    calculateTotalCost();
  };

  const handleTariffChange = (value: number) => {
    setValue('data.tariff', value);
    calculateTotalCost();
  };

  const handleTotalCostChange = (value: number) => {
    setValue('data.totalCost', value);
    calculateTariff();
  };

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showEndDate>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          {isMeterFormVisible && (
            <CreateMeterModal
              energyType={dataSection}
              onSuccess={selectCreatedMeter}
              onDismiss={setMeterFormVisible}
              showModal={isMeterFormVisible}
              accountId={accountId}
            />
          )}

          <Row>
            <SubHeader>Please enter the following transactional {dataSection.toLowerCase()} data:</SubHeader>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Meter Name</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.energySource"
                render={({ field, fieldState }) => (
                  <SelectRender
                    onCreateNew={createNewOption}
                    mapper={meterToDropdownOption}
                    options={metersOptions}
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
                rules={{ required: 'Meter Name is required' }}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Reading (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.reading"
                render={({ field, fieldState }) => <NumberInput {...field} error={fieldState.error?.message} />}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Supplier (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.supplier"
                render={({ field, fieldState }) => (
                  <SupplierSelector
                    accountId={accountId}
                    value={field.value}
                    onChange={(e: SelectEvent) => setValue('data.supplier', e.target.select)}
                    error={fieldState.error?.message}
                    utility={TransactionType.Water}
                  />
                )}
                defaultValue={null}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Quantity (m3)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.quantity"
                render={({ field, fieldState }) => (
                  <NumberInputWithNegativeOption
                    {...field}
                    error={fieldState.error?.message}
                    onChange={(value: number) => {
                      field.onChange(value);
                      handleQuantityChange(value);
                    }}
                  />
                )}
                rules={{ required: 'Quantity is required' }}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Act/Est</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.accuracy"
                render={({ field, fieldState }) => (
                  <SelectRender
                    {...field}
                    mapper={entityToDropdownOption}
                    options={actEstOptions}
                    error={fieldState.error?.message}
                  />
                )}
                rules={{ required: 'Act/Est is required' }}
                defaultValue={accuracyDefaultValues}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Tariff (cost per m3) (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.tariff"
                render={({ field, fieldState }) => (
                  <NumberInput
                    {...field}
                    error={fieldState.error?.message}
                    onChange={(value: number) => {
                      field.onChange(value);
                      handleTariffChange(value);
                    }}
                  />
                )}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Total Cost (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.totalCost"
                render={({ field, fieldState }) => (
                  <NumberInput
                    {...field}
                    error={fieldState.error?.message}
                    onChange={(value: number) => {
                      field.onChange(value);
                      handleTotalCostChange(value);
                    }}
                  />
                )}
                defaultValue=""
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Currency</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="financial.currency"
                render={({ field, fieldState }) => (
                  <SelectRender
                    {...field}
                    mapper={entityToDropdownOption}
                    options={currencyOptions}
                    error={fieldState.error?.message}
                  />
                )}
                rules={{ required: 'Currency is required' }}
                defaultValue={currencyDefaultValue}
                control={control}
              />
            </ColStyled>
          </Row>

          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Comment (optional)</LabelStyled>
              </LabelContainerStyled>

              <Controller
                name="data.comment"
                render={({ field }) => <TextInput {...field} />}
                control={control}
                defaultValue=""
              />
            </ColStyled>
          </Row>

          <RowWithPadding container itemAlign="center">
            <div style={{ width: '50%' }}>Do you want to add any references?</div>

            <div>
              <Row container align="center">
                <CenteredCol item>
                  <Checkbox size="md" checked={isReferenceDataRequired} onChange={showReferenceData} />

                  <LabelContainerStyled>
                    <LabelStyled>Yes</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>

                <CenteredCol item>
                  <Checkbox size="md" checked={!isReferenceDataRequired} onChange={hideReferenceData} />

                  <LabelContainerStyled>
                    <LabelStyled>No</LabelStyled>
                  </LabelContainerStyled>
                </CenteredCol>
              </Row>
            </div>
          </RowWithPadding>

          <SectionStyled isVisible={isReferenceDataRequired}>
            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Invoice Number (optional)</LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.invoiceNumber"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>

            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Evidence Reference (optional)</LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.evidenceReference"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>

            <Row container align="between">
              <ColStyled item>
                <LabelContainerStyled>
                  <LabelStyled>Other Reference (optional)</LabelStyled>
                </LabelContainerStyled>

                <Controller
                  name="reference.otherReference"
                  render={({ field }) => <TextInput {...field} />}
                  defaultValue=""
                  control={control}
                />
              </ColStyled>
            </Row>
          </SectionStyled>
          {props.children}
        </div>
      )}
    </DataForm>
  );
}
