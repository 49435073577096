import cloneDeep from 'lodash/cloneDeep';
import { REPORTING_FRAMEWORK, VOLUNTARY_AGREEMENT } from '../constants/managementSystemTypes';

export function makeTocNumber(...numbers) {
  const prefix = numbers.filter(Boolean).join('.');
  return prefix ? prefix + '.' : '';
}

export function addTableOfContents(chapter, ...parents) {
  const tocNumber = makeTocNumber(...parents.map((p) => p.number), chapter.number);
  const tocTitle = `${tocNumber} ${chapter.title}`;
  chapter.tocNumber = tocNumber;
  chapter.tocTitle = tocTitle;
  chapter.parent = parents[parents.length - 1];
  return chapter;
}

export function addSiblings(chapter, siblings) {
  chapter.siblings = siblings;
  return chapter;
}

export function getAllParents(chapter, parents = []) {
  if (chapter?.parent) {
    return getAllParents(chapter.parent, parents.concat([chapter.parent]));
  }
  return parents;
}

export function createManagementSystemTree(system) {
  const tree = {};
  const documents = {};
  if (!system) {
    return { tree, documents };
  }
  const addDocumentsOfItem = (item) =>
    item.documents?.forEach?.(
      (d) =>
        (documents[d.id] = {
          ...d,
          name: d.fileName,
        })
    );
  const managementSystem = cloneDeep(system);
  addDocumentsOfItem(managementSystem);
  managementSystem.chapters?.forEach((chapter, i) => {
    tree[chapter.id] = addSiblings(addTableOfContents(chapter, managementSystem), managementSystem.chapters);
    tree[chapter.id].index = i;
    addDocumentsOfItem(chapter);
    chapter.chapters?.forEach((clause, j) => {
      tree[clause.id] = addSiblings(addTableOfContents(clause, managementSystem, chapter), chapter.chapters);
      tree[clause.id].index = j;
      addDocumentsOfItem(clause);
      clause.chapters?.forEach((subClause, k) => {
        addDocumentsOfItem(subClause);
        tree[subClause.id] = addSiblings(
          addTableOfContents(subClause, managementSystem, chapter, clause),
          clause.chapters
        );
        tree[subClause.id].index = k;
      });
    });
  });
  return { tree, documents };
}

export const getTitleFromType = (type) => {
  switch (type) {
    case REPORTING_FRAMEWORK:
      return 'Reporting Frameworks';
    case VOLUNTARY_AGREEMENT:
      return 'Voluntary Agreements';
    default:
      return 'Management Systems';
  }
};

export const getPathFromType = (type) => {
  switch (type) {
    case REPORTING_FRAMEWORK:
      return '/governance/frameworks';
    case VOLUNTARY_AGREEMENT:
      return '/governance/agreements';
    default:
      return '/governance/systems';
  }
};

export function getBreadcrumb(type, basePath) {
  return {
    title: getTitleFromType(type),
    to: !basePath ? getPathFromType(type) : basePath,
  };
}

export function createBreadcrumbs(managementSystem, managementSystemTree, chapter, basePath = null) {
  if (!managementSystem) {
    return [];
  }
  const breadcrumbs = [getBreadcrumb(managementSystem.type, basePath)];

  if (chapter && managementSystem) {
    const parentChapters = getAllParents(managementSystemTree[chapter.id]).reverse();
    parentChapters.forEach((parent) => {
      const to =
        parent === managementSystem
          ? `${basePath}/${managementSystem.id}`
          : `${basePath}/${managementSystem.id}/${parent.id}`;
      breadcrumbs.push({
        to,
        title: parent.tocTitle || parent.title,
      });
    });
  }
  return breadcrumbs;
}

export function makeManagementSystemCode(system, page) {
  const number = page?.tocNumber || page?.number;
  return `${system.shortCode}${number ? ` ${number}` : ''} D${(page.documents?.length || 0) + 1}`.toLocaleLowerCase();
}

export function getLinkForChapter(currentChapterId, newChapterId, pathname) {
  return currentChapterId ? pathname.replace(currentChapterId, newChapterId) : `${pathname}/${newChapterId}`;
}

export const calculateEvidenceTileNumber = (documents) => {
  return documents.length >= 3 ? 1 : 3 - documents.length;
};
