import React, { useState } from 'react';
import { Col, LoadingIndicator, Search } from 'rio-ui-components';
import styled from 'styled-components';
import * as JsSearch from 'js-search';

import NoResults from '../../../../components/NoResults';
import ContainerHeader from '../../../../components/ContainerHeader';
import SubscriptionRows from './SubscriptionRows';
import { useRioKnowledgeBases } from '../../../../hooks/useRioKnowledgeBases';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SubscriptionSelector = ({ title, accountId }) => {
  const [search, setSearch] = useState('');
  const { data: kbs, loading: kbsLoading } = useRioKnowledgeBases(accountId);

  const applySearch = (data, term) => {
    if (!term) return data;
    const search = new JsSearch.Search('id');
    search.addIndex('title');
    search.addDocuments(data);
    return search.search(term);
  };

  const searched = applySearch(kbs, search);

  return (
    <>
      <Col name="ConfigurationAccountsContainer" container fullHeight>
        <ContainerHeader name="ConfigurationLearnSubscriptionsContainer__Controls" icon="briefcase" iconColor="primary" title={title}>
          <Col container item>
            <Search name="ConfigurationLearnSubscriptionsContainer__Controls__Search" value={search} onChange={(e) => setSearch(e.target.value)} hideButton />
          </Col>
        </ContainerHeader>
        {kbsLoading && (
          <CenterContainer>
            <LoadingIndicator size="md" />
          </CenterContainer>
        )}

        {!kbsLoading && !searched.length && <NoResults name="ConfigurationUsersContainer__NoResults" title="There are no results for this search." />}
        {!!searched.length && <SubscriptionRows knowledgebases={searched} accountId={accountId} />}
      </Col>
    </>
  );
};

export default SubscriptionSelector;
