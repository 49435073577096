import mime from 'mime-types';
import { LearnContentType } from '@rio/rio-types';
import { uploadFile } from '../upload';
import { getEnvVar } from '../../env';

const getContentType = (file) => {
  const fileParts = file.name.split('.');
  const fileExtension = fileParts[fileParts.length - 1];

  if (fileExtension === 'html' || fileExtension === 'css' || fileExtension === 'js') {
    return mime.contentType(file.name);
  }

  return 'binary/octet-stream';
};

const multiFileUpload = async (content, presignedLinks, cache) => {
  await Promise.all(
    presignedLinks.map(async (url, index) => {
      const file = content[index];
      const headers = {
        'Content-Type': getContentType(file),
      };
      await uploadFile({ url, headers, file, cache });
    })
  );
};

export const resolvers = {
  Mutation: {
    uploadLearnContent: async (_, { url, id, thumbnail, content, type, presignedLinks }, { cache }) => {
      if ((type === LearnContentType.Pdf || type === LearnContentType.Video) && content instanceof File) {
        const headers = {
          'Content-Type': getContentType(content),
        };
        await uploadFile({ url, headers, file: content, cache });
      } else if (content && content.length && content.length > 1 && typeof content !== 'string') {
        await multiFileUpload(content, presignedLinks, cache);
      }
      if (thumbnail instanceof File) {
        const thumbnailPath = `${id}.jpg`;
        const thumbnailUrl = `${getEnvVar('REACT_APP_LEARN_THUMBNAIL_UPLOAD')}/${thumbnailPath}`;
        await uploadFile({
          url: thumbnailUrl,
          cache,
          file: thumbnail,
          headers: {
            'Content-Type': getContentType(thumbnail),
            Authorization: localStorage.getItem('ACCESS_TOKEN'),
          },
        });
      }
    },
  },
};
