import { Query, QueryGetSurveyByIdArgs as Variables } from '@rio/rio-types';
import { GET_SURVEY } from './queries';
import { useNotification, usePageSuspendingQuery } from '../../../hooks';

export type GetSurveyResponse = Pick<Query, 'getSurveyById'>;

export function useGetById(id: string | null) {
  const { showNotification } = useNotification();

  return usePageSuspendingQuery<GetSurveyResponse, Variables>(GET_SURVEY, {
    variables: { id: id as string },
    skip: !id,
    onError: () => {
      showNotification('Survey not found.', 'danger');
    },
  });
}
