import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import alphabetiseStrings from '../../../../utils/alphabetise';

import SubscriptionRow from './SubscriptionRow';
import { SAVE_SUBSCRIPTIONS } from './index.queries';

class SubscriptionRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboards: this.props.dashboards.map((c) => ({
        ...c,
        checked: this.props.selectedDashboards.includes(c.id)
      }))
    };
  }

  onCheckboxClick = (event) => {
    const subscriptions = [];
    const newDashboards = this.state.dashboards.map((c) => {
      let checked = c.checked;
      if (c.id === event.target.name) {
        checked = !c.checked;
      }
      return { ...c, checked };
    });

    newDashboards.map((dash) => {
      if (dash.checked) {
        subscriptions.push(dash.id);
      }
      return null;
    });

    this.props.client.mutate({
      mutation: SAVE_SUBSCRIPTIONS,
      variables: { accountId: this.props.accountId, subscriptions: subscriptions }
    });

    this.setState({
      dashboards: newDashboards
    });
  };

  render() {
    const { accountId, permissions } = this.props;

    return (
      <>
        {this.state.dashboards
          .sort((dashboardA, dashboardB) => alphabetiseStrings(dashboardA.name, dashboardB.name))
          .map((dashboard) => {
            return (
              <SubscriptionRow
                permissions={permissions}
                key={dashboard.id}
                id={dashboard.id}
                dashboardName={dashboard.name}
                accountId={accountId}
                onCheckboxClick={this.onCheckboxClick}
                checked={dashboard.checked || false}
              />
            );
          })}
      </>
    );
  }
}

export default withApollo(SubscriptionRows);
