import { styled, Text, useTheme } from '@rio/ui-components';
import { ReceivedSurvey, Survey } from '@rio/rio-types';
import { Nullable } from '~/types';
import { DueDate } from './DueDate';
import { getStatus } from './utils';
import { SurveyOverflowMenu } from './SurveyOverflowMenu';

export interface DeadlineDateProps {
  deadlineDate?: Nullable<Date>;
  survey?: ReceivedSurvey | Survey;
  fill?: boolean;
  newSubscriptionUpdate?: boolean;
  showAutoSave?: boolean;
}

export interface StatusLabelProps {
  color: string;
}

export interface StatusProps {
  fill?: boolean;
}

const Status = styled('div')<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Span = styled(Text)<StatusLabelProps>`
  color: ${(props) => props.color};
`.withComponent('span');

const SurveyOverflowMenuContainer = styled('div')`
  height: 40px;
  width: 25px;
`;

export const SurveyInfoStatus = ({ deadlineDate, survey }: DeadlineDateProps) => {
  const theme = useTheme();
  const { color, isOverDue } = getStatus(deadlineDate, theme);
  return (
    <Status>
      {deadlineDate && (
        <Span typescale="body" size="medium" color={color}>
          {isOverDue ? 'Closed' : 'Open'}
        </Span>
      )}
      {deadlineDate && (
        <Span typescale="body" size="medium" color={color}>
          &nbsp;•&nbsp;{' '}
        </Span>
      )}
      {deadlineDate && <DueDate color={color} deadlineDate={deadlineDate} />}
      <SurveyOverflowMenuContainer>{survey && <SurveyOverflowMenu survey={survey} />}</SurveyOverflowMenuContainer>
    </Status>
  );
};
