import { gql } from '@apollo/client';

export const GET_ALL_LEGISLATION = gql`
  query GetAllLegislation($accountId: ID!) {
    getAllLegislation(accountId: $accountId) {
      id
      title
    }
  }
`;

export const CREATE_LEGISLATION = gql`
  mutation createLegislation($legislation: LegislationInput, $userId: ID!, $accountId: ID!) {
    createLegislation(input: { legislation: $legislation, userId: $userId, accountId: $accountId }) {
      id
      title
    }
  }
`;
