import { SummarizeStatus } from '@rio/rio-types';
import { usePermissions, useUserToken } from '~/hooks';
import { SummarizeButton } from './SummerizeButton';
import { useMemo } from 'react';

type ActionFunction = (id: string) => void;

export interface SummaryCellProps {
  data: {
    id: string;
    status: string;
    userId: string;
    approved: boolean;
    summarizeStatus: SummarizeStatus;
    duplicatesNumber: number;
  };
  onDeleteClick: ActionFunction;
  onQuickViewClick: ActionFunction;
}

export const SummaryCell = ({ data, onDeleteClick, onQuickViewClick }: SummaryCellProps) => {
  const { token } = useUserToken();
  const permissions = usePermissions();

  const isOwnResource = useMemo(() => data.userId === token.sub, [data, token]);
  const isCompleted = useMemo(() => data.status === 'Completed', [data]);

  const showDeleteButton = useMemo(
    () =>
      (['Failed', 'Action Required', 'Rolled Back'].includes(data.status) &&
        !!permissions.data.find((a: string) => a.startsWith('deleteImportBatch') && !a.endsWith('Own'))) ||
      (permissions.data.includes('deleteImportBatchOwn') && isOwnResource),
    [permissions, data.status, isOwnResource]
  );

  return (
    <>
      <SummarizeButton
        showDeleteButton={showDeleteButton}
        isCompleted={isCompleted}
        onDeleteClick={() => onDeleteClick(data.id)}
        approved={data.approved}
        summarizeStatus={data.summarizeStatus}
        duplicatesNumber={data.duplicatesNumber}
        handleQuickViewClick={() => onQuickViewClick(data.id)}
      />
    </>
  );
};
