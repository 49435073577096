import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import { Col, CircleIcon, Row, Heading, Text } from 'rio-ui-components';
import styled from 'styled-components';
import moment from 'moment';
import { tileCss } from './css';
import { DetailsModal } from './DetailsModal';
import { useSavePolicyWithDocuments } from './useSavePolicyWithDocuments';
import { GET_POLICY_BY_ID } from './index.queries';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';

const Tile = styled.div`
  ${tileCss}
`;

const HeadContainer = styled(Row)`
  padding-bottom: 0;
  flex: 0 0 auto;
  height: 50%;
`;

const CircleIconStyled = styled(CircleIcon)`
  z-index: 1;
  background: #fff;
  display: inline-flex;
`;

const TextContainer = styled(Col)`
  padding-top: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 8px;
`;
const TextStyled = styled(Text)``;

const MAX_TITLE_LENGTH = 28;

export function PolicyTile({ policy, showNotification, canEdit }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const client = useApolloClient();
  const [editedPolicy, setEditedPolicy] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accountId = useCurrentAccountId();
  const savePolicyWithDocuments = useSavePolicyWithDocuments();

  const { title } = policy;
  const tileTitle = title.length > MAX_TITLE_LENGTH ? `${title.substr(0, MAX_TITLE_LENGTH - 4)} ...` : title;

  return (
    <Tile
      onClick={async () => {
        setShowEditModal(true);
        if (!editedPolicy) {
          const {
            data: { getPolicyById }
          } = await client.query({
            query: GET_POLICY_BY_ID,
            variables: {
              accountId,
              id: policy.id
            }
          });
          if (getPolicyById) {
            setEditedPolicy(getPolicyById);
          }
        }
      }}
    >
      <HeadContainer>
        <CircleIconStyled height="70px" size="lg" icon="file-contract" iconColor="tertiary" circleColor="tertiary" />
      </HeadContainer>
      <TextContainer container item vdistribution="between">
        <HeadingStyled size="lg" color="dark" weight="light">
          {tileTitle} (v{policy.version})
        </HeadingStyled>
        <TextStyled>Created: {moment(policy.createdAt).format('DD/MM/YYYY hh:mm:ss')}</TextStyled>
        {policy.reviewDate && (
          <TextStyled>Review Date: {moment(policy.reviewDate).format('DD/MM/YYYY hh:mm:ss')}</TextStyled>
        )}
      </TextContainer>
      {editedPolicy && (
        <DetailsModal
          readOnly={!canEdit}
          key={`${policy.id}-${policy.version}`}
          title={canEdit ? 'Update Policy' : 'View Policy'}
          submitTitle="Update Policy"
          show={showEditModal}
          isSubmitting={isSubmitting}
          policy={editedPolicy}
          onDismiss={() => {
            setTimeout(() => {
              setShowEditModal(false);
              setEditedPolicy(null);
            }, 0);
          }}
          onChange={(editedPolicy) => {
            setEditedPolicy(editedPolicy);
          }}
          onSubmit={async (policyToSubmit) => {
            setIsSubmitting(true);
            try {
              await savePolicyWithDocuments(policyToSubmit);
              showNotification({
                message: 'Your policy has been updated!',
                color: 'success'
              });
            } catch (err) {
              showNotification({
                message: 'Something went wrong. Try again later.',
                color: 'danger'
              });
            } finally {
              setIsSubmitting(false);
              setShowEditModal(false);
              setEditedPolicy(null);
            }
          }}
        />
      )}
    </Tile>
  );
}
