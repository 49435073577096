import { UserPermissions, TransactionType } from '@rio/rio-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { ColumnApi, ColumnState, GridApi, GridReadyEvent, RowModelType } from 'ag-grid-community';

import { Grid as AgGrid } from '../../../components/UI';
import { useAgGrid } from '../../../hooks/useAgGrid';
import { useUploadPolling } from '../../../hooks/useUploadPolling';
import { formatDatetime } from '../../../utils/formatDate';
import { ActionFunction, FetchRowsFunction, GridApiType } from './types';

import { ImportFileCell, ImportFileCellProps } from './GridCell/ImportFileCell';
import { RollbackCell, RollbackCellProps } from './GridCell/RollbackCell';
import { StatusCell, StatusCellProps, StatusProps } from './GridCell/StatusCell';
import { SummaryCell, SummaryCellProps } from './GridCell/SummaryCell';
import { TranspositionCell, TranspositionCellProps } from './GridCell/TranspositionCell';
import { BatchStatus } from '~/types';

const RowStyled = styled(Row)`
  flex: 1;
  height: 100%;
  display: flex;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

interface UploadGridProps {
  onRollBackClick: (batchId: string) => void;
  gridApi?: { api: GridApi; columnApi: ColumnApi };
  fetchRows: FetchRowsFunction;
  setGridApi: React.Dispatch<React.SetStateAction<GridApiType>>;
  download: {
    [id: string]: boolean;
  };
  defineStatusProps: (
    batchStatus: BatchStatus,
    theme: any,
    isLegacy: boolean,
    onActionClick: ActionFunction,
    onDataClick: ActionFunction,
    onErrorClick: ActionFunction
  ) => StatusProps;
  statusLink: (
    batchStatus: BatchStatus,
    isLegacy: boolean,
    name: string,
    onErrorClick: ActionFunction,
    onDataClick: ActionFunction,
    onActionClick: ActionFunction,
    statusProps: StatusProps,
    download: boolean,
    permissions: UserPermissions,
    isOwnResource: boolean
  ) => React.ReactNode;
  onErrorClick: ActionFunction;
  onDataClick: ActionFunction;
  onActionClick: ActionFunction;
  onDeleteClick: ActionFunction;
  onQuickViewClick: ActionFunction;
  rollBackStatusLink: (id: string) => React.ReactNode;
  dataSection: TransactionType;
}

function GridComponent({
  fetchRows,
  setGridApi,
  download,
  rollBackStatusLink,
  statusLink,
  onErrorClick,
  onDataClick,
  onActionClick,
  onDeleteClick,
  onQuickViewClick,
  defineStatusProps,
}: UploadGridProps) {
  console.info('download', download);
  const agGrid = useAgGrid({ autoFit: true });

  const defaultSortModel = [{ colId: 'lastUpdated', sort: 'desc', sortIndex: 0 }];

  const components = {
    fileName: (props: ImportFileCellProps) => <ImportFileCell {...props} />,
    status: (props: StatusCellProps) => (
      <StatusCell
        {...props}
        download={download}
        onErrorClick={onErrorClick}
        onDataClick={onDataClick}
        onActionClick={onActionClick}
        defineStatusProps={defineStatusProps}
        statusLink={statusLink}
      />
    ),
    rollback: (props: RollbackCellProps) => <RollbackCell {...props} rollBackStatusLink={rollBackStatusLink} />,
    summary: (props: SummaryCellProps) => (
      <SummaryCell {...props} onDeleteClick={onDeleteClick} onQuickViewClick={onQuickViewClick} />
    ),
    transposition: (props: TranspositionCellProps) => <TranspositionCell {...props} />,
  };

  const [isGridReady, setIsGridReady] = useState(false);

  const onGridReady = (params: GridReadyEvent) => {
    params.columnApi.applyColumnState({
      state: defaultSortModel as ColumnState[],
    });

    agGrid.onGridReady(params);
    setGridApi({ api: params.api, columnApi: params.columnApi });
    setIsGridReady(true);
  };

  const gridOptions = {
    pagination: true,
    paginationPageSizeSelector: false,
    paginationPageSize: 25,
    rowModelType: 'serverSide' as RowModelType,
    rowHeight: 100,
    defaultColDef: {
      resizable: false,
      sortable: true,
      filter: true,
      cellStyle: {
        paddingLeft: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
    },
    columnDefs: [
      {
        headerName: 'Status',
        field: 'status',
        filter: 'agTextColumnFilter',
        cellRenderer: 'status',
      },

      {
        headerName: 'Filename',
        field: 'fileName',
        filter: 'agTextColumnFilter',
        cellRenderer: 'fileName',
      },

      {
        headerName: 'User First Name',
        field: 'user.first_name',
        filter: 'agTextColumnFilter',
      },

      {
        headerName: 'User Last Name',
        field: 'user.last_name',
        filter: 'agTextColumnFilter',
      },

      {
        headerName: 'Last Updated Date',
        field: 'lastUpdated',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDatetime(new Date(value)),
      },

      {
        headerName: 'Rows',
        field: 'rowsUploaded',
        filter: 'agTextColumnFilter',
        maxWidth: 120,
      },

      {
        headerName: 'Template used',
        cellRenderer: 'transposition',
        filter: false,
        sortable: false,
        maxWidth: 130,
      },

      {
        headerName: 'Rollback',
        filter: false,
        sortable: false,
        cellRenderer: 'rollback',
        maxWidth: 100,
      },

      {
        headerName: 'Summary',
        sortable: false,
        filter: false,
        cellRenderer: 'summary',
        cellStyle: {
          marginLeft: '20px',
        },
        maxWidth: 100,
      },
    ],

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  useUploadPolling(fetchRows, agGrid, isGridReady, gridOptions);

  return (
    <RowStyled>
      <AgGrid
        {...gridOptions}
        cacheBlockSize={gridOptions.paginationPageSize}
        onFirstDataRendered={agGrid.onFirstDataRendered}
        onFilterChanged={agGrid.onFilterChanged}
        onSortChanged={agGrid.onSortChanged}
        onColumnVisible={agGrid.onSaveGridColumnState}
        onColumnPinned={agGrid.onSaveGridColumnState}
        onColumnResized={agGrid.onSaveGridColumnState}
        onColumnMoved={agGrid.onSaveGridColumnState}
        onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
        onColumnValueChanged={agGrid.onSaveGridColumnState}
        onColumnPivotChanged={agGrid.onSaveGridColumnState}
        components={components}
        onGridReady={onGridReady}
      />
    </RowStyled>
  );
}

export const Grid = GridComponent;
