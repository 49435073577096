import { gql } from '@apollo/client';

export const LOCATION_FRAGMENT = gql`
  fragment LocationFields on Location {
    id
    name
    address {
      id
      address1
      address2
      address3
      city
      phone1
      postcode
      country {
        alpha2Code
        name
        id
      }
    }
    wasteIn {
      id
      name
    }
    wasteOut {
      id
      name
    }
    tags {
      id
      tagName
    }
    tenure {
      id
      name
    }
    buildingType {
      id
      name
    }
    region {
      id
      name
    }
    departments {
      id
      name
    }
    code
    floorSpace
    startDate
    endDate
    leaseRenewalDate
    notes
  }
`;
