import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { MassUploadModal } from '../../../components/MassUploadModal';
import { usersParser } from './usersParser';
import { GET_USERS_BY_ACCOUNT_ID } from './index.queries';
import { TemplateType } from '../../../types';

const columns = [
  'Email Address',
  'User Type',
  'First Name',
  'Last Name',
  'Job Title (Optional)',
  'Department (Optional)',
  'Phone Number (Optional)',
  'Mobile Number (Optional)',
  'Access Level',
  'Allowed Resources'
];
const exampleValues = [
  'test@example.com',
  'Read Only',
  'John',
  'Doe',
  'Supervisor',
  'Sales',
  '01610000000',
  '07000000000',
  'Location',
  'London, Manchester'
];

export function UploadUsersModal({ onDismiss, setCreateMutation, startUploadingUsers, accountId, roleOptions }) {
  const [errors, setErrors] = useState([]);
  const client = useApolloClient();
  const startUploading = async ([file]) => {
    setCreateMutation('users');
    const [parseErrors, users] = await usersParser(file, accountId, roleOptions, client);
    if (parseErrors && parseErrors.length) {
      return setErrors(parseErrors);
    }
    onDismiss();
    startUploadingUsers(users);
    const variables = {
      accountId,
      limit: 600
    };
    const cachedResponse = client.readQuery({
      variables,
      query: GET_USERS_BY_ACCOUNT_ID
    });
    client.writeQuery({
      variables,
      query: GET_USERS_BY_ACCOUNT_ID,
      data: {
        getUserInfoByAccountId: {
          ...cachedResponse.getUserInfoByAccountId,
          users: cachedResponse.getUserInfoByAccountId.users.concat(users)
        }
      }
    });
  };
  return (
    <MassUploadModal
      accept=".xlsx, .xls, .csv"
      template={{
        columns,
        exampleValues,
        type: TemplateType.Dynamic,
        fileName: 'users-upload'
      }}
      errors={errors}
      onFileInput={startUploading}
      onDismiss={onDismiss}
    />
  );
}
