import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Task, User } from '@rio/rio-types';
import styled from 'styled-components';
import { Row } from 'rio-ui-components';
import { GridOptions, ValueGetterParams, GridReadyEvent, ColumnState, RowClickedEvent } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import capitalize from 'lodash/capitalize';
import {
  DescriptionCell,
  DueDateCell,
  OwnersCell,
  CreatorCell,
  PriorityCell,
  CategoryCell,
  StatusCell,
  CompletedDateCell,
} from '../GridCell';
import { compareDate, defaultFilterParams } from '../../../utils';
import { getSubjectItemName, formatScope, priorityValueFormatter, formatStatus } from '../utils';
import { AgGrid as AgGridInterface } from '../../../hooks/useAgGrid';

const RowStyled = styled(Row)`
  flex: 1;

  .ag-header-cell-resize {
    z-index: 0;
  }
`;
interface GridComponentProps {
  tasks: Task[];
  canEdit: boolean;
  agGrid: AgGridInterface;
}
const defaultSortModel = [
  { colId: 'dueDate', sort: 'asc', sortIndex: 0 },
  { colId: 'name', sort: 'asc', sortIndex: 1 },
];

function GridComponent(props: GridComponentProps) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { tasks } = props;

  const components = {
    status: StatusCell,
    priority: PriorityCell,
    description: DescriptionCell,
    category: CategoryCell,
    owners: OwnersCell,
    creator: CreatorCell,
    dueDate: DueDateCell,
    completedAt: CompletedDateCell,
  };

  const onRowClicked = (e: RowClickedEvent) => {
    navigate(`/tasks/${e.data.id}`);
  };

  const textCellStyle = {
    lineHeight: '21px',
    wordBreak: 'inherit',
    display: 'flex',
    alignItems: 'center',
  };
  const options: GridOptions = {
    rowHeight: 120,
    columnDefs: [
      {
        headerName: 'Priority',
        field: 'priority',
        cellRenderer: 'priority',
        width: 100,
        cellStyle: { textAlign: 'center', verticalAlign: 'middle' },
        filterParams: {
          ...defaultFilterParams(),
          valueFormatter: priorityValueFormatter,
        },
      },

      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'status',
        valueGetter: ({ data }) => formatStatus(data),
        minWidth: 50,
        cellStyle: { textAlign: 'center', verticalAlign: 'middle' },
        filterParams: {
          ...defaultFilterParams(),
        },
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.task' }),
        field: 'name',
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 90,
        cellStyle: textCellStyle,
      },

      {
        headerName: 'Description',
        field: 'description',
        cellRenderer: 'description',
        sortable: false,
        filter: false,
        cellStyle: { textAlign: 'center' },
      },

      {
        headerName: 'Scope',
        field: 'scope',
        filterParams: {
          ...defaultFilterParams(),
        },
        valueFormatter: ({ data }) => formatScope(data),
        minWidth: 130,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.category' }),
        field: 'category',
        cellRenderer: 'category',
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 150,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.subject' }),
        valueGetter: ({ data }) => capitalize(data.subject),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 110,
        cellStyle: textCellStyle,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.item' }),
        valueGetter: ({ data }) => getSubjectItemName(data),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
        cellStyle: {
          display: 'flex',
          lineHeight: '21px',
          wordBreak: 'inherit',
        },
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.owners' }),
        field: 'owners',
        cellRenderer: 'owners',
        valueGetter: ({ data: { owners = [] } }) =>
          owners.map(({ first_name, last_name }: User) => `${first_name} ${last_name}`),
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 90,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.creator' }),
        field: 'author',
        cellRenderer: 'creator',
        valueGetter: ({ data: { author } }: ValueGetterParams) => `${author.first_name} ${author.last_name}`,
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.date' }),
        field: 'dueDate',
        cellRenderer: 'dueDate',
        filter: 'agDateColumnFilter',
        sort: 'asc',
        filterParams: {
          ...defaultFilterParams(),
          comparator: compareDate,
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.completedDate' }),
        field: 'completedAt',
        cellRenderer: 'completedAt',
        filter: 'agDateColumnFilter',
        sort: 'asc',
        filterParams: {
          ...defaultFilterParams(),
          comparator: compareDate,
        },
        minWidth: 120,
      },

      {
        headerName: formatMessage({ id: 'pages.task.grid.header.project' }),
        valueGetter: ({ data: { projectId } }: ValueGetterParams) => projectId?.name || '',
        filterParams: {
          ...defaultFilterParams(),
        },
        minWidth: 90,
        cellStyle: textCellStyle,
      },
    ],

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: {
        height: 'auto',
      },
    },

    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <RowStyled container>
      <AgGrid
        rowData={tasks}
        components={components}
        getRowId={({ data }) => data.id}
        onGridReady={(params: GridReadyEvent) => {
          params.columnApi.applyColumnState({
            state: defaultSortModel as ColumnState[],
          });
          props.agGrid.onGridReady(params);
        }}
        onRowClicked={onRowClicked}
        onFilterChanged={props.agGrid.onFilterChanged}
        onFirstDataRendered={props.agGrid.onFirstDataRendered}
        {...options}
      />
    </RowStyled>
  );
}

export const Grid = GridComponent;
