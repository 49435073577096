import { useCallback } from 'react';
import { GridOptions } from 'ag-grid-community';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import GET_GAS_TRANSACTIONS_PAGE from '../../../graphql/queries/gas/GetGasTransactionsPage.query.graphql';
import { useGasGridConfig } from '../../DataContainer/GasContainer/GasTransactionsContainer/useGasGridConfig';
import {
  FactorUsedCell,
  UploadReferenceCell,
  DocumentReferenceV2 as DocumentReference,
} from '../../DataContainer/CellRenderers';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Gas' }];

export const GasTransactionsGrid = () => {
  const config = useGasGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_GAS_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getGasTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <>
      <TransactionGrid
        title="Your gas data"
        defaultExportFileName="gas-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../gas/uploads"
        config={
          {
            ...config,
            components: {
              uploadReference: UploadReferenceCell,
              documentReference: DocumentReference,
              link: FactorUsedCell,
            },
          } as GridOptions
        }
        fetchRows={fetchRows}
      />
    </>
  );
};
