import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../../fragments/LocationFields.fragment';

export const GET_HOTELSTAYS_TRANSACTION_PAGE = gql`
  query GetHotelStaysTransactionsPage(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getHotelStaysTransactionsPage(
      accountId: $accountId
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      rows {
        id
        startDate
        endDate
        hotelNights
        homeWorkingDays
        totalCost
        currency
        notes
        reference
        invoiceNumber
        evidenceReference
        otherReference
        carbonScope3
        uploadReference
        importBatchId
        importBatchFileName
        country
        tariff
        dataProvider
        tags
        departments
        location {
          ...LocationFields
        }
        reportingYearOfHSFactors
        reportingYearOfWFHFactors
      }
      totalRows
    }
  }
  ${LOCATION_FRAGMENT}
`;
