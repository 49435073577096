import { gql } from '@apollo/client';

export const ALIASFRAGMENT = gql`
  fragment aliasFragment on Alias {
    id
    name
    parentId
    aliasType
    parentItem {
      id
      name
    }
  }
`;
export const CREATE_ALIAS = gql`
  mutation createAlias(
    $id: ID!
    $name: String!
    $parentId: ID!
    $accountId: ID!
    $aliasType: AliasType!
    $energyType: TransactionType
  ) {
    createAlias(
      input: {
        id: $id
        name: $name
        parentId: $parentId
        accountId: $accountId
        aliasType: $aliasType
        energyType: $energyType
      }
    ) {
      ...aliasFragment
    }
  }
  ${ALIASFRAGMENT}
`;

export const CREATE_ALIASES = gql`
  mutation createAliases($input: [AliasInput!]!) {
    createAliases(input: $input) {
      ...aliasFragment
    }
  }
  ${ALIASFRAGMENT}
`;
export const UPDATE_ALIAS = gql`
  mutation updateAlias(
    $id: ID!
    $name: String!
    $parentId: ID!
    $accountId: ID!
    $aliasType: AliasType!
    $energyType: TransactionType
  ) {
    updateAlias(
      input: {
        id: $id
        name: $name
        parentId: $parentId
        accountId: $accountId
        aliasType: $aliasType
        energyType: $energyType
      }
    ) {
      ...aliasFragment
    }
  }
  ${ALIASFRAGMENT}
`;
export const UPDATE_IMPORT_STATUS = gql`
  mutation UpdateTransactionDataImportBatchStatus($id: ID!) {
    updateTransactionDataImportBatchStatus(id: $id) {
      id
      status
    }
  }
`;
export const GET_ALIAS_BY_VALUE = gql`
  query getAliasByValueBatch($GetAliasBatchInput: AliasValueBatchInput!) {
    getAliasByValueBatch(input: $GetAliasBatchInput) {
      getParentResponses {
        id
        name
        parentId
        aliasType
        parentItem {
          id
          name
        }
      }
    }
  }
`;
export const GET_DATA_IMPORT_BATCH_BY_ID = gql`
  query GetDataImportBatchById($id: ID!) {
    getDataImportBatchById(id: $id) {
      id
      errorsCount
      fileName
      status
      failedReason
      timeStamp
      rowsUploaded
      errors {
        fieldName
        value
        rows
        message
        actionRequired
        possibleAliases
      }
    }
  }
`;

export const GET_ENERGY_SOURCE_BY_ACC_ID_AND_TYPE = gql`
  query getEnergySourceByAccountIdAndType(
    $accountId: ID!
    $type: TransactionType
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getEnergySourceByAccountIdAndType(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        name
        id
        type {
          name
          id
        }
        locationPoint {
          name
          location {
            id
            name
          }
        }
      }
    }
  }
`;
