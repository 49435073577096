import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/engage/GetLearnBundleById.query.graphql';

export function useGetAccountLearnBundleByIdAndAccountIdQuery(bundleId, accountId, options = {}) {
  const { data: { getLearnBundleById } = {}, ...others } = useQuery(QUERY, {
    variables: {
      accountId,
      bundleId
    },
    ...options
  });

  return { data: getLearnBundleById, ...others };
}
