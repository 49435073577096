import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Label, Checkbox, Row, Col, Text } from 'rio-ui-components';
import { usePermissions } from '../../../hooks/usePermissions';

const CheckboxRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
`;
const LabelStyled = styled(Label)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const CheckboxLabel = styled(Label)`
  cursor: pointer;
`;
const CheckboxStyled = styled(Checkbox)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const CheckboxCol = styled(Col)`
  max-height: 100px;
  flex-wrap: wrap;
  flex: 1;
`;
const LinkStyled = styled(Link)`
  text-decoration: underline;
`;
const ErrorMessageRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ErrorMessage = styled(Text)`
  color: ${(props) => props.theme.colors.danger.normal.background};
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 600;
`;
const supplierTypesByUtilityType = {
  WASTE: ['Supplier', 'Carrier', 'Contractor'],
  ELECTRICITY: ['Supplier', 'Broker', 'Contractor'],
  GAS: ['Supplier', 'Broker', 'Contractor'],
  WATER: ['Supplier', 'Contractor'],
  TRANSPORT: ['Supplier', 'Haulier', 'Contractor'],
  MATERIALS: [],
  HOTELSTAYS: ['Supplier'],
  FUEL: ['Supplier'],
};

export const SupplierTypeCheckboxes = ({ onChange, error, utility, defaultSelected = [] }) => {
  const permissions = usePermissions();
  const canCreateContractor = permissions.data.includes('createSupplierAll');
  const checkboxes = {};
  supplierTypesByUtilityType[utility].forEach((type) => {
    checkboxes[type] = defaultSelected.includes(type);
  });
  const [types, setTypes] = useState(checkboxes);

  if (!supplierTypesByUtilityType[utility].length) {
    return null;
  }

  return (
    <Col container>
      <Row container>
        <LabelStyled>Type:</LabelStyled>
      </Row>
      {error && (
        <ErrorMessageRow>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageRow>
      )}
      <Row container>
        <CheckboxCol container>
          {Object.keys(checkboxes).map((type) => {
            const handleChange = () => {
              const newTypes = {
                ...types,
                [type]: !types[type],
              };
              setTypes(newTypes);
              const selectedTypes = Object.keys(types).filter((key) => newTypes[key]);
              onChange(selectedTypes);
            };
            const contractorDisabled = type === 'Contractor' && !canCreateContractor;
            return (
              <CheckboxRow key={type} container>
                <CheckboxStyled
                  name={type}
                  size="md"
                  checked={types[type]}
                  onChange={handleChange}
                  disabled={contractorDisabled}
                />
                <CheckboxLabel onClick={contractorDisabled ? () => {} : handleChange}>{type}</CheckboxLabel>
              </CheckboxRow>
            );
          })}
        </CheckboxCol>
      </Row>
      {!canCreateContractor && (
        <Row container>
          <LabelStyled>
            Please <LinkStyled to="/contact">contact support</LinkStyled> to create a new contractor
          </LabelStyled>
        </Row>
      )}
    </Col>
  );
};
