import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, TextLink, ProgressBar } from 'rio-ui-components';
import { Task } from '@rio/rio-types';
import { countCompletedTasks } from './utils';

const TaskSectionContainer = styled.div`
  margin-bottom: 20px;
`;

const TaskItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #7a7272;
  border-radius: 5px;
`;

const TaskInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 80%;
`;

const IconContainer = styled(Icon)`
  margin-right: 20px;
`;
const TextLinkContainer = styled(TextLink)`
  margin-right: 20px;
  &:hover,
  &:focus {
    color: #0049f9;
  }
`;

const DeleteContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ProgressBarContainer = styled(ProgressBar)`
  &&& {
    height: 15px;
    border-radius: 10px;
  }
  margin-bottom: 20px;
`;

interface TaskSectionProps {
  tasks: Task[];
  onTaskDelete: (task: Task) => void;
  canEditProject: boolean;
}

interface TaskItemProps {
  task: Task;
  onTaskDelete: (task: Task) => void;
  canEditProject: boolean;
}

const TaskItem = ({ task, onTaskDelete, canEditProject }: TaskItemProps) => {
  return (
    <TaskItemContainer>
      <TaskInfo>
        <IconContainer icon="link" color="#0049F9" />
        <Link to={`/tasks/${task.id}`}>
          <TextLinkContainer underline={false}>{task.name}</TextLinkContainer>
        </Link>
        {task.isCompleted && <Icon icon="check" color="green" />}
      </TaskInfo>
      {canEditProject && (
        <DeleteContainer id={task.id}>
          <IconContainer onClick={() => onTaskDelete(task)} icon="times" color="#7a7272" />
        </DeleteContainer>
      )}
    </TaskItemContainer>
  );
};

export const TaskSection = ({ tasks, onTaskDelete, canEditProject }: TaskSectionProps) => {
  const progressValue = countCompletedTasks(tasks);
  return (
    <TaskSectionContainer>
      <ProgressBarContainer value={progressValue} />
      {tasks.map((task: Task, index: number) => {
        return (
          <TaskItem
            key={index}
            task={task}
            onTaskDelete={(task: Task) => onTaskDelete(task)}
            canEditProject={canEditProject}
          />
        );
      })}
    </TaskSectionContainer>
  );
};
