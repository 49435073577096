import React from 'react';
import { useQuery } from "@apollo/client";
import { NamedEntity, Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_PROJECTS } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, NullableOption } from '../../types';

interface ProjectSelectProps {
  accountId: string,
  name: string,
  value: Nullable<string | string[] | NamedEntity>;
  onChange: (e: SelectEvent) => void;
  canBeEmpty?: boolean;
}

export function ProjectSelect({ accountId, name, value, onChange, canBeEmpty = true }: ProjectSelectProps) {
  const emptyOption = {
    label: '(No project)',
    value: null
  };

  const { data, loading, error } = useQuery<Pick<Query, 'getProjects'>>(GET_ALL_PROJECTS, {
    variables: {
      accountId
    }
  });
  const projects: NullableOption[] = toSelectOptions(
    data?.getProjects as NamedEntity[]
  );

  if(canBeEmpty) {
    projects.unshift(emptyOption);
  }
  const selectedValue = Array.isArray(value)
    ? value.map((item) => projects.find((project) => project.value === item))
    : typeof value === 'string'
      ? projects.find((project) => project.value === value)
      : value?.id
        ? projects.find((project) => project.value === value.id)
        : value;

  return <Select
    name={name}
    placeholder="Please select project"
    value={selectedValue}
    options={projects}
    error={error}
    isLoading={loading}
    onChange={onChange} />;
}
