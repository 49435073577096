import { useQuery } from '@apollo/client';
import { GET_USER_BY_USERNAME } from '../queries/users';
import { useCurrentUserId } from './useCurrentUserId';
import { User, Query } from '@rio/rio-types';
import { Nullable } from '../types';

type Response = Pick<Query, 'getUserInfoById'>;

export function useCurrentUser(): Nullable<User> {
  const id = useCurrentUserId();
  const { data } = useQuery<Response>(GET_USER_BY_USERNAME, {
    variables: {
      name: id,
    },
  });

  return data?.getUserInfoById;
}
