import { AuditEventEntity, AuditEventInput, AuditEventService, ReceivedSurvey } from '@rio/rio-types';
import { useCreateAuditEvent } from '../../../../hooks';

export type AuditType = 'opened' | 'closed';

const IS_LOADED_KEY = 'audit_survey_received_loaded';
const IS_UNLOADED_KEY = 'audit_survey_received_unloaded';

const getStorageItem = (key: string, surveyId: string) => {
  const value = JSON.parse(localStorage.getItem(key) || '{}');
  return value[surveyId];
};

const setStorageItem = (key: string, surveyId: string, date: Date) => {
  const value = JSON.parse(localStorage.getItem(key) || '{}');
  value[surveyId] = date;
  localStorage.setItem(key, JSON.stringify(value));
};

const validatePageUrl = (auditType: AuditType, survey: ReceivedSurvey) => {
  const surveyUrl = `received/${survey.id}`;
  if (auditType === 'opened' && window.location.pathname === surveyUrl) {
    return true;
  } else if (auditType === 'closed' && window.location.pathname !== surveyUrl) {
    return true;
  }
  return false;
};

const validateDate = (oldDateString: string) => {
  // Check that old date is within the past hour
  const oldDate = new Date(oldDateString);
  const newDate = new Date();
  newDate.setHours(newDate.getHours() - 1);
  return oldDate > newDate;
};

const mapAuditEvent = (
  survey: ReceivedSurvey,
  auditType: AuditType,
  accountId: string,
  userId?: string
): AuditEventInput => ({
  service: AuditEventService.FormService,
  entityId: survey.id,
  entity: AuditEventEntity.FormCampaign,
  accountId,
  userId,
  type: `Survey ${auditType}`,
});

// Hook to create audit event when user loads or leaves a survey received page
export const useCreateSurveyAuditEvent = () => {
  const [createAuditEvent] = useCreateAuditEvent();

  const getIsLoaded = (surveyId: string) => getStorageItem(IS_LOADED_KEY, surveyId);
  const setIsLoaded = (surveyId: string, date: Date) => setStorageItem(IS_LOADED_KEY, surveyId, date);

  const getIsUnloaded = (surveyId: string) => getStorageItem(IS_UNLOADED_KEY, surveyId);
  const setIsUnloaded = (surveyId: string, date: Date) => setStorageItem(IS_UNLOADED_KEY, surveyId, date);

  const getAuditLog = (auditType: AuditType, survey: ReceivedSurvey) => {
    let auditLog;

    if (auditType === 'opened') {
      auditLog = getIsLoaded(survey.id);
    } else if (auditType === 'closed') {
      auditLog = getIsUnloaded(survey.id);
    }

    return auditLog;
  };

  const setAuditLog = (auditType: AuditType, survey: ReceivedSurvey) => {
    const date = new Date();

    if (auditType === 'opened') {
      setIsLoaded(survey.id, date);
    } else if (auditType === 'closed') {
      setIsUnloaded(survey.id, date);
    }
  };

  const createSurveyAuditEvent = async (
    auditType: AuditType,
    survey: ReceivedSurvey,
    accountId: string,
    userId?: string
  ) => {
    // Return early if user is not on survey received urls
    if (!validatePageUrl(auditType, survey)) {
      return;
    }

    // Return early if handled already
    const auditLog = getAuditLog(auditType, survey);
    if (auditLog && validateDate(auditLog)) {
      return;
    }

    // Save to localStorage
    setAuditLog(auditType, survey);

    // Create audit event
    const auditEvent = mapAuditEvent(survey, auditType, accountId, userId);
    return createAuditEvent({
      variables: {
        event: auditEvent,
      },
    });
  };

  return {
    createSurveyAuditEvent,
  };
};
