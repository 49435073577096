import { styled } from '@mui/material-next/styles';
import { Page } from '@rio/ui-components';

import { useAccessControls, useCurrentAccount } from '~/hooks';
import { accessControls } from '../types';
import InfoContainer from './InfoContainer';
import { NavigationSidbar } from './NavigationSidbar';
import { useEffect, useState } from 'react';

const Wrapper = styled('div')`
  height: 100%;
  gap: 48px;
  display: flex;
`;

export default function DataOverviewContainerV2() {
  const account = useCurrentAccount();
  const { data: accountAccessControls } = useAccessControls();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [setLoading]);

  const activeAccessControls = Object.entries(accountAccessControls.data).reduce((accum: string[], [key, value]) => {
    if ((accessControls as unknown as string[]).includes(key) && value) {
      accum.push(key);
    }
    return accum;
  }, []);

  const inactiveAccessControls = accountAccessControls?.data?.investment ? ['fundsAssets'] : [];

  return (
    <Page
      loading={loading}
      title={{
        crumbs: [
          {
            to: './',
            label: 'Data',
          },
          {
            label: 'Overview',
          },
        ],
        content: 'Upload data to help us assess your impact',
      }}
    >
      <Wrapper>
        <NavigationSidbar activeAccessControls={activeAccessControls} inactiveAccessControls={inactiveAccessControls} />
        {account?.accessControls?.data && (
          <InfoContainer
            investment={account.accessControls.data?.investment}
            activeAccessControls={activeAccessControls}
          />
        )}
      </Wrapper>
    </Page>
  );
}
