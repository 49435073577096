import React from 'react';
import styled from 'styled-components';
import { Button, Col, Icon } from 'rio-ui-components';
import { Link } from 'react-router-dom';

const BasketContainer = styled.div`
  @keyframes fade_in_show {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  position: absolute;
  left: 0px;
  background: #fff;
  width: 100%;
  bottom: 0;
  margin: 0 0 45px 0;
  padding: 25px 70px;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  z-index: 1;
  display: flex;
  flex-direction: row;
  animation: fade_in_show 0.2s;
  svg {
    margin: 0 20px 0 0;
  }
  svg:last-child {
    margin-left: 50px;
  }
`;

const ColLeft = styled(Col)`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 21px;
`;

const ButtonBasket = styled(Button)`
  width: 200px;
`;

const Basket = (props) => {
  const { cart } = props;
  const {
    state: {
      linesCount,
      subTotal,
      currency: { symbol }
    }
  } = cart;

  return (
    <BasketContainer>
      <ColLeft>
        <Icon icon="check" color="#59c5b5" />
        {linesCount} Items added <Icon icon="check" color="#59c5b5" /> Total: {symbol}
        {subTotal}
      </ColLeft>
      <Col>
        <Link to="/upgrade/cart">
          <ButtonBasket name="Basket-Button">Go to Basket</ButtonBasket>
        </Link>
      </Col>
    </BasketContainer>
  );
};

export default Basket;
