import styled, { css } from 'styled-components';
import { Heading, MarkdownRenderer } from 'rio-ui-components';

export const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  font-size: 16px;
  ${(props) =>
    props.active
      ? `
    color: white;
  `
      : ''}
`;

export const StyledText = styled(MarkdownRenderer)`
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${(props) =>
    props.active
      ? `
    color: white;
  `
      : ''}
`;

export const underlineElement = css`
  &:before {
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    position: absolute;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const accordionTitle = css`
  padding: 16px 24px;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: initial;
  overflow: visible;
  font-style: initial;
  font-size: 16px;
  line-height: 1.4em;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: ${(props) => (props.active ? props.theme.colors.tertiary.light.background : 'white')};
  color: #4d505b;
`;
