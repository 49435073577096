import { gql } from '@apollo/client';

export const GET_DATA_QUALITY_PERCENTAGE = gql`
  query GetDataQualityPercentage($accountId: ID!) {
    getDataQualityPercentage(accountId: $accountId) {
      overallDataQuality
      overallDataCompleteness
      dataQualityBreakdown {
        utility
        dataQuality
        dataCompleteness
      }
    }
  }
`;
