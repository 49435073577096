import { gql } from '@apollo/client';

/**
 * @deprecated
 * see '../../../graphql/fragments/SupplierFields.fragment.graphql'
 */
export const SUPPLIER_FRAGMENT = gql`
  fragment SupplierFields on Supplier {
    name
    id
    accountId
    utility
    type
    materials {
      id
      name
    }
    notes
    address {
      id
      address1
      address2
      address3
      city
      phone1
      postcode
      country {
        alpha2Code
        name
        id
      }
    }
  }
`;

export const GET_ALL_SUPPLIERS = gql`
  query GetSuppliers(
    $page: Int!
    $pageSize: Int!
    $filterValue: String
    $orderDir: String
    $filterBy: String
    $accountId: ID!
    $utility: TransactionType
  ) {
    getSuppliers(
      accountId: $accountId
      page: $page
      pageSize: $pageSize
      filterValue: $filterValue
      filterExpression: EQUAL
      orderDir: $orderDir
      filterBy: $filterBy
      utility: $utility
    ) {
      count
      hasNextPage
      hasPreviousPage
      isFirstPage
      isLastPage
      pageCount
      totalItemCount
      page
      pageSize
      suppliers {
        ...SupplierFields
      }
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier(
    $id: ID!
    $name: String!
    $addressId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $accountId: ID
    $countryId: ID!
    $utility: TransactionType!
    $type: [String!]!
    $materialIds: [ID!]
    $notes: String
  ) {
    createAddress(
      input: {
        id: $addressId
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
      }
    ) {
      id
    }
    createSupplier(
      input: {
        id: $id
        name: $name
        addressId: $addressId
        accountId: $accountId
        utility: $utility
        type: $type
        materialIds: $materialIds
        notes: $notes
      }
    ) {
      ...SupplierFields
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const UPDATE_SUPPLIER_AND_ADDRESS = gql`
  mutation UpdateSupplier(
    $id: ID!
    $name: String!
    $addressId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $accountId: ID
    $countryId: ID!
    $utility: TransactionType!
    $type: [String!]!
    $materialIds: [ID!]
    $notes: String
  ) {
    updateAddress(
      input: {
        id: $addressId
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
      }
    ) {
      id
    }
    updateSupplier(
      input: {
        id: $id
        name: $name
        addressId: $addressId
        accountId: $accountId
        utility: $utility
        type: $type
        materialIds: $materialIds
        notes: $notes
      }
    ) {
      ...SupplierFields
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const UPDATE_SUPPLIER_AND_CREATE_ADDRESS = gql`
  mutation UpdateSupplierAndCreateAddress(
    $id: ID!
    $name: String!
    $addressId: ID!
    $add1: String
    $add2: String
    $add3: String
    $city: String!
    $post: String!
    $phone: String
    $accountId: ID
    $countryId: ID!
    $utility: TransactionType!
    $type: [String!]!
    $materialIds: [ID!]
    $notes: String
  ) {
    createAddress(
      input: {
        id: $addressId
        address1: $add1
        address2: $add2
        address3: $add3
        city: $city
        postcode: $post
        coordinates: null
        phone1: $phone
        countryId: $countryId
      }
    ) {
      id
    }
    updateSupplier(
      input: {
        id: $id
        name: $name
        addressId: $addressId
        accountId: $accountId
        utility: $utility
        type: $type
        materialIds: $materialIds
        notes: $notes
      }
    ) {
      ...SupplierFields
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const GET_PAGE = gql`
  {
    suppliersGridPage @client
  }
`;

export const GET_TOTAL_PAGES = gql`
  {
    suppliersGridTotalPages @client
  }
`;

export const SET_PAGE = gql`
  mutation SetSuppliersGridPage($page: Int!) {
    setSuppliersGridPage(page: $page) @client
  }
`;

export const SET_TOTAL_PAGES = gql`
  mutation SetSuppliersGridTotalPages($totalPages: Int!) {
    suppliersGridTotalPages(totalPages: $totalPages) @client
  }
`;

export const GET_SEARCH = gql`
  {
    suppliersGridSearch @client
  }
`;

export const SET_SEARCH = gql`
  mutation SetSuppliersGridSearch($search: String!) {
    setSuppliersGridSearch(search: $search) @client
  }
`;
