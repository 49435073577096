import { UserCourseMetaData } from '@rio/rio-types';
import { Text, HorizontalCard, Button, styled } from '@rio/ui-components';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { formatCpd } from '../../LearnContainer/v2';

const TextStyled = styled(Text)`
  margin-bottom: 24px;
  color: ${(p) => p.theme.sys.color.onSurface};
`;

const CardsContainer = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  margin-bottom: 12px;
`;

const ButtonStyled = styled(Button)`
  width: 196px;
`;

interface ContinueLearningProps {
  userFirstName: string;
  courses: UserCourseMetaData[];
}
export function ContinueLearning({ userFirstName, courses }: ContinueLearningProps) {
  return (
    <div>
      <TextStyled typescale="title" size="medium">
        Continue your learning, {userFirstName}
      </TextStyled>
      <CardsContainer>
        {courses.map((c) => (
          <Link key={c.course.id} to={`/v2/engage/course/${c.course.id}`}>
            <HorizontalCard
              title={c.course.name}
              subTitle={formatCpd(c.course.metaData?.cpd)}
              src={c.course.metaData?.thumbnail}
            />
          </Link>
        ))}
      </CardsContainer>
      <ButtonStyled variant="text" to="/v2/engage">
        <ArrowForward fontSize="small" />
        View all courses
      </ButtonStyled>
    </div>
  );
}
