import React from 'react';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { orderBy, remove, uniq } from 'lodash';

import { GET_AVAILABLE_COURSES } from '../Courses/index.queries';
import SearchCourses from './SearchCourses';
import CoursesBanner from '../Courses/CoursesBanner';
import NotificationBanner from '../../../components/NotificationBanner';
import UserBannersSection from '../../../components/UserBannersSection';
import { ErrorMessage, ContainerError } from 'rio-ui-components';
import { learnTourStepsExplore, withTour } from '../../../hocs';
import jwt_decode from 'jwt-decode';
import { filterComplete, filterContinue, filterExplore, sortByDateDesc } from '../utils';
import { useCurrentAccount, useCurrentAccountId } from '../../../hooks';
import { COMPLETE, COREVE_ACCOUNT_LIVE } from '../../../constants';

const CoursesBannerWithTour = withTour(CoursesBanner, 'learn.explore', {
  closeLabel: 'learnTour.close',
  joyrideOptions: { scrollToFirstStep: false }
});

const ErrorMessageStyled = styled.div`
  margin-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const CourseOverviewContent = ({ learnAccess, searchValue, intl }) => {
  const userId = jwt_decode(localStorage.getItem('ID_TOKEN')).sub;
  const accountId = useCurrentAccountId();
  const accountInfo = useCurrentAccount();
  const displayExploreSection = COREVE_ACCOUNT_LIVE !== accountId;

  return (
    <Query query={GET_AVAILABLE_COURSES} variables={{ pageNumber: 1, userId, accountId }} fetchPolicy="network-only">
      {({ loading, error, data, refetch, networkStatus }) => {
        if ((loading && networkStatus !== 3) || networkStatus === 4)
          return (
            <>
              <CoursesBanner
                key="CoursesBanner__Continue"
                type="Continue"
                sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.continue' })}
                highlightColor="secondary"
                items={[]}
                learnAccess={learnAccess}
                loading
              />
              {displayExploreSection && (
                <CoursesBanner
                  key="CoursesBanner__Explore"
                  type="Explore"
                  sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.explore' })}
                  highlightColor="primary"
                  items={[]}
                  learnAccess={learnAccess}
                  loading
                />
              )}
              <CoursesBanner
                key="CoursesBanner__Completed"
                type="Completed"
                sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.completed' })}
                highlightColor="tertiary"
                items={[]}
                learnAccess={learnAccess}
                loading
              />
            </>
          );
        if (error) {
          return (
            <ErrorMessageStyled>
              <ErrorMessage error={error}>
                {({ title, body, icon }) => (
                  <ContainerError name="Courses__Error" icon={icon} title={title} body={body} retry={refetch} />
                )}
              </ErrorMessage>
            </ErrorMessageStyled>
          );
        }

        const allCourses = data?.getAvailableCourses?.data || [];
        const listBundles = { mandatoryList: [], showList: [], restrictedCourseList: [] };
        data.getAvailableCourses.learnBundleList.forEach((bundle) => {
          if (bundle.isbBundleShow) {
            const fullCourses = allCourses.filter((item) => bundle.courses.includes(item.id));
            const keyName = bundle.isMandatoryBundle ? 'mandatoryList' : 'showList';
            const coursesList = orderBy(fullCourses, (item) => bundle.courses.indexOf(item.id), 'asc');
            remove(
              coursesList,
              (item) => item.userCourseMetaData && item.userCourseMetaData.courseProgress === COMPLETE
            );
            listBundles[keyName].push({ ...bundle, coursesList: coursesList });
            if (bundle.restricted && bundle.courses.length) {
              listBundles.restrictedCourseList = [
                ...listBundles.restrictedCourseList,
                ...coursesList.slice(1, coursesList.length).map((item) => item.id)
              ];
            }
          }
        });

        const accountLevelCourses = allCourses.filter((course) => course.accountId === accountId);

        listBundles.restrictedCourseList = uniq(listBundles.restrictedCourseList);
        listBundles.showList = orderBy(listBundles.showList, (item) => item.title.toLowerCase(), 'asc');
        return (
          <>
            <UserBannersSection id="learnOverview" />
            {!learnAccess && (
              <NotificationBannerStyled
                name="LearnContainer_Access_NotificationBanner"
                icon="exclamation-triangle"
                color="danger"
                title="Engage"
                body={`Currently Engage is not included in your subscription, if you’d like access to Learn please contact Rio ESG LTD or get in touch with your Business Admin.`}
              />
            )}
            {learnAccess && !allCourses.length && !searchValue && (
              <Link name={'ContactUs__Link'} to={'/contact'} key={'contactUsLink'}>
                <NotificationBannerStyled
                  name="LearnContainer_Access_NotificationBanner"
                  icon="info-circle"
                  color="quinary"
                  title="Engage"
                  body={
                    'You are currently not subscribed to any Engage content. Please contact your Customer Success Manager to find out more or Rio ESG LTD at support@rio.ai.'
                  }
                />
              </Link>
            )}
            {searchValue && <SearchCourses learnAccess={learnAccess} searchValue={searchValue} />}
            {!searchValue && (
              <>
                {listBundles.mandatoryList.map((bundle) => {
                  return (
                    <CoursesBanner
                      key={`CoursesBanner__${bundle.id}`}
                      type={bundle.id}
                      sectionTitle={bundle.title}
                      restricted={bundle.restricted}
                      highlightColor="secondary"
                      items={bundle.coursesList}
                      learnAccess={learnAccess}
                      bundleDescription={bundle.description}
                    />
                  );
                })}
                <CoursesBanner
                  key="CoursesBanner__Continue"
                  type="Continue"
                  sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.continue' })}
                  highlightColor="secondary"
                  items={allCourses.filter(filterContinue).sort(sortByDateDesc)}
                  learnAccess={learnAccess}
                />
                {displayExploreSection && (
                  <CoursesBannerWithTour
                    key="CoursesBanner__Explore"
                    type="Explore"
                    sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.explore' })}
                    highlightColor="primary"
                    items={allCourses.filter(filterExplore)}
                    learnAccess={learnAccess}
                    steps={learnTourStepsExplore}
                    restrictedCourseList={listBundles.restrictedCourseList}
                  />
                )}
                {!!accountLevelCourses.length && (
                  <CoursesBanner
                    key="CoursesBanner__Account"
                    type="Account"
                    sectionTitle={`${accountInfo.name} - Uploaded Courses`}
                    highlightColor="secondary"
                    items={accountLevelCourses.sort(sortByDateDesc)}
                    learnAccess={learnAccess}
                  />
                )}

                {listBundles.showList.map((bundle) => {
                  return (
                    <CoursesBanner
                      name="CourseOverviewContent__Courses-Banner--List"
                      key={`CoursesBanner__${bundle.id}`}
                      type={bundle.id}
                      sectionTitle={bundle.title}
                      restricted={bundle.restricted}
                      highlightColor="secondary"
                      items={bundle.coursesList}
                      learnAccess={learnAccess}
                      bundleDescription={bundle.description}
                    />
                  );
                })}

                <CoursesBanner
                  key="CoursesBanner__Completed"
                  type="Completed"
                  sectionTitle={intl.formatMessage({ id: 'pages.learn.banners.completed' })}
                  highlightColor="tertiary"
                  items={allCourses.filter(filterComplete).sort(sortByDateDesc)}
                  learnAccess={learnAccess}
                />
              </>
            )}
          </>
        );
      }}
    </Query>
  );
};

export default injectIntl(CourseOverviewContent);
