import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { isPostalCode } from 'validator';
import {
  Col,
  Row,
  Label,
  TextInput,
  Button,
  LoadingIndicator,
  Heading,
  MultiSelect,
  Notification,
  Checkbox,
  InlineError,
  ErrorMessage,
} from 'rio-ui-components';
import { isPhoneNumber } from '../../../utils/validation';
import withPermissions from '../../../hocs/withPermissions';
import { validateField, getTagsToBeSaved, updateLocationPoint } from './utils';
import { toIsoIfDate } from '~/utils';
import LocationMetadata from './LocationMetadata';
import { UPDATE_LOCATION, GET_TAGS_BY_ACCOUNT_ID } from './index.queries';
import AddressPropertySheet from '../../../components/AddressPropertySheet';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const InlineErrorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.sm.spacing};
  padding-top: ${(props) => props.theme.geometry.md.spacing};
`;

function UpdateLocation({
  locationId,
  accountId,
  addressId,
  name,
  add1,
  add2,
  add3,
  city,
  postcode,
  phone,
  wasteIn,
  wasteOut,
  tags,
  onSuccess,
  refreshGrid,
  countryList,
  defaultCountry,
  metadata,
}) {
  const defaultState = {
    hasError: false,
    isSuccess: false,
    values: {
      locationName: { text: name || '', error: '' },
      address1: { text: add1 || '', error: '' },
      address2: { text: add2 || '', error: '' },
      address3: { text: add3 || '', error: '' },
      city: { text: city || '', error: '' },
      postcode: { text: postcode || '', error: '' },
      phone: { text: phone || '', error: '' },
      locationPoints: {
        wasteIn: { initialCheck: !!wasteIn, checked: !!wasteIn, id: wasteIn },
        wasteOut: { initialCheck: !!wasteOut, checked: !!wasteOut, id: wasteOut },
      },
      tags: tags.map((tag) => {
        return { value: tag.id, label: tag.tagName };
      }),
      aliases: tags.map((tag) => {
        return { value: tag.id, label: tag.tagName };
      }),
      selectCountry: { value: defaultCountry.id, label: defaultCountry.name },
    },
    notification: { message: '', color: 'success', id: null },
    isSubmitted: false,
  };

  const [state, setState] = useState(defaultState);

  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: null, color: null },
    }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const text = e.target.value;
    setState((prevState) => ({
      ...prevState,
      values: { ...state.values, [name]: { text, error: null } },
    }));
  };

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      values: { ...state.values, [e.target.name]: { value: e.target.select.value, label: e.target.select.label } },
    }));
  };

  const handleSubmit = (e, updateLocation) => {
    e.preventDefault();

    const v = state.values;
    v.locationName.error = validateField(v.locationName.text, 'Location Name');
    v.address1.error = validateField(v.address1.text, 'Address 1');
    v.city.error = validateField(v.city.text, 'City');
    v.postcode.error =
      validateField(v.postcode.text, 'Postcode') ||
      (isPostalCode(v.postcode.text, 'any') ? null : 'Please enter a valid postcode.');
    v.phone.error = !v.phone.text
      ? null
      : isPhoneNumber(v.phone.text)
      ? null
      : 'Please enter a valid phone number using only characters 0 - 9';

    if (v.locationName.error || v.address1.error || v.city.error || v.postcode.error || v.phone.error) {
      setState((prevState) => ({ ...prevState, values: v }));
      return;
    }
    updateLocation({
      variables: {
        locId: locationId,
        locName: state.values.locationName.text,
        accId: accountId,
        add1: state.values.address1.text || null,
        add2: state.values.address2.text || null,
        add3: state.values.address3.text || null,
        city: state.values.city.text,
        post: state.values.postcode.text,
        phone: state.values.phone.text ? state.values.phone.text.trim() : null,
        wasteOut: updateLocationPoint(
          state.values.locationPoints.wasteOut.checked,
          state.values.locationPoints.wasteOut.id
        ),
        wasteIn: updateLocationPoint(
          state.values.locationPoints.wasteIn.checked,
          state.values.locationPoints.wasteIn.id
        ),
        tags: getTagsToBeSaved(state.values.tags),
        aliases: getTagsToBeSaved(state.values.tags),
        countryId: state.values.selectCountry.value,
        /* Metadata fields */
        ...metadataOptions,
        departments: metadataOptions?.departments.map((dep) => dep.id) || [],
        startDate: toIsoIfDate(metadataOptions?.startDate),
        endDate: toIsoIfDate(metadataOptions?.endDate),
        leaseRenewalDate: toIsoIfDate(metadataOptions?.leaseRenewalDate),
      },
    });
    setState((prevState) => ({
      ...prevState,
      isSubmitted: true,
    }));
  };

  const [metadataOptions, setMetadataOptions] = useState({
    code: metadata?.code || '',
    tenure: metadata?.tenure?.id || null,
    region: metadata?.region?.id || null,
    buildingType: metadata?.buildingType?.id || null,
    floorSpace: metadata?.floorSpace || null,
    startDate: metadata?.startDate || null,
    endDate: metadata?.endDate || null,
    leaseRenewalDate: metadata?.leaseRenewalDate || null,
    notes: metadata?.notes || '',
    departments: metadata?.departments || [],
  });

  const onCreateComplete = (data) => {
    onSuccess(`The location ${data.updateLocationWithLocationPoints.name} has been updated!`, 'success', null);
    refreshGrid();
  };

  const onCreateError = (err) => {
    const error = err.toString().includes('Name_AccountID_UNIQUE')
      ? 'The location name should be unique.'
      : 'Something went wrong.';
    setState((prevState) => ({
      ...prevState,
      notification: { message: `${error} If the problem persists, contact support.`, color: 'danger' },
      hasError: true,
      isSuccess: false,
      isSubmitted: false,
    }));
  };

  const onCheckboxClick = (data) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...state.values,
        locationPoints: {
          ...state.values.locationPoints,
          [data.target.name]: { ...state.values.locationPoints[data.target.name], checked: data.target.checked },
        },
      },
    }));
  };

  const onTagChange = (event) => {
    const tagArray = event.target.select;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        tags: tagArray,
      },
    }));
  };

  const SelectLoadingIndicator = () => {
    return <LoadingIndicator name="tag-location-Loading" size="xxs" />;
  };

  const { loading, error, data } = useQuery(GET_TAGS_BY_ACCOUNT_ID, { variables: { accountId: accountId } });
  const options =
    !loading && !error ? data.getTagsByAccountId.map((tag) => ({ label: tag.tagName, value: tag.id })) : [];

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onError: onCreateError,
    onCompleted: onCreateComplete,
  });

  return (
    <Container name="update-account">
      {state.notification.message && (
        <Notification
          name="update-location__notification"
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}

      <form
        name="update-location__form"
        onSubmit={(e) => {
          handleSubmit(e, updateLocation);
        }}
      >
        <HeaderStyled name="update-location__heading" size="lg">
          Update Location
        </HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Location Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              disabled={state.isSubmitted}
              onChange={handleChange}
              value={state.values.locationName.text}
              error={state.values.locationName.error}
              name="locationName"
              box
            />
          </ColStyled>
        </Row>
        <AddressPropertySheet
          id="LocationAddressPropertySheet"
          name="LocationAddressPropertySheet"
          address1={state.values.address1}
          address2={state.values.address2}
          address3={state.values.address3}
          city={state.values.city}
          postcode={state.values.postcode}
          phone={state.values.phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          disabled={state.isSubmitted}
          countryList={countryList}
          selectCountry={state.values.selectCountry}
        />
        <Row container align="between">
          <ColStyled container item span={6}>
            <LabelStyled id="update-location-wasteout-label" name="update-location-wasteout-label">
              Waste Out
            </LabelStyled>
            <Checkbox
              disabled={state.isSubmitted || state.values.locationPoints.wasteOut.initialCheck}
              id="update-location-wasteout"
              name="wasteOut"
              onChange={onCheckboxClick}
              checked={state.values.locationPoints.wasteOut.checked}
            />
          </ColStyled>
          <ColStyled container item span={6}>
            <LabelStyled id="update-location-wastein-label" name="update-location-wastein-label">
              Waste In
            </LabelStyled>
            <Checkbox
              disabled={state.isSubmitted || state.values.locationPoints.wasteIn.initialCheck}
              id="update-location-wastein"
              name="wasteIn"
              onChange={onCheckboxClick}
              checked={state.values.locationPoints.wasteIn.checked}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled container item span={12}>
            <LabelStyled id="update-location-tags-label" name="update-location-tags-label">
              Tags (Optional)
              {error ? (
                <ErrorMessage error={error}>
                  {({ icon }) => (
                    <InlineErrorContainer>
                      <InlineError name="location-tag__error" icon={icon} title="Tags failed to load" />
                    </InlineErrorContainer>
                  )}
                </ErrorMessage>
              ) : (
                <MultiSelect
                  name="update-location-tag-multiselection"
                  id="update-location-tag-multiselection"
                  pill={false}
                  isLoading={loading}
                  components={{ LoadingIndicator: SelectLoadingIndicator }}
                  onChange={onTagChange}
                  isDisabled={loading || error}
                  multiValueColor="tertiary"
                  value={state.values.tags}
                  options={options}
                />
              )}
            </LabelStyled>
          </ColStyled>
        </Row>
        <LocationMetadata
          accountId={accountId}
          selectedOptions={metadataOptions}
          setSelectedOptions={setMetadataOptions}
        />
        <Row container align="between">
          <ColStyled item>
            <Button
              name="update-location--create"
              color="success"
              submit
              disabled={!state.values.locationName.text || state.isSubmitted}
            >
              Update Location
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
}

export default withPermissions(UpdateLocation);
