import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentAccount } from '..';

/**
 * Used to redirect to the correct design version based on the account's access controls.
 */
export function useRedirectToDesignVersion() {
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!account) {
      return;
    }

    const designVersion = account?.accessControls?.v2Design ? 2 : 1;
    const currentVersion = location.pathname.startsWith('/v2') ? 2 : 1;

    if (currentVersion !== designVersion) {
      if (currentVersion === 1) {
        // v1 -> v2
        navigate(location.pathname.replace('/', '/v2/'));
      } else {
        // v2 -> v1
        navigate(location.pathname.replace(/\/v2\/?/, '/'));
      }
    }
  }, [account, location.pathname, navigate]);
}
