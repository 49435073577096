import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, Button } from '@rio/ui-components';
import { Form } from './Form';
import { AuthPage } from './AuthPage';
import { TextStyled } from './style';
import { CheckboxTerms } from './CheckboxTerms';

export const CredentialsForm = ({
  form,
  errors,
  handleChange,
  setErrors,
  handleCheckboxChange,
  handleCredentialsSubmit,
}) => {
  const intl = useIntl();

  return (
    <AuthPage title="login.registration.mainPage.title">
      <TextStyled size="small" typescale="title">
        <FormattedMessage id="login.registration.mainPage.subtitle" />
      </TextStyled>
      <Form onSubmit={handleCredentialsSubmit} form={form} setErrors={setErrors} name="credentials">
        <TextField
          type="email"
          value={form.email}
          error={!!errors.email}
          placeholder={intl.formatMessage({ id: 'login.registration.mainPage.emailPlaceholder' })}
          label={intl.formatMessage({ id: 'login.registration.mainPage.emailPlaceholder' })}
          helperText={errors.email && intl.formatMessage({ id: `login.errors.validation.${errors.email}` })}
          autoComplete="email"
          name="email"
          onChange={handleChange}
          required
        />
        <TextField
          type="password"
          value={form.password}
          error={!!errors.password}
          label={intl.formatMessage({ id: 'login.registration.mainPage.passwordPlaceholder' })}
          helperText={errors.password && intl.formatMessage({ id: `login.errors.validation.${errors.password}` })}
          placeholder={intl.formatMessage({ id: 'login.registration.mainPage.passwordPlaceholder' })}
          autoComplete="new-password"
          onChange={handleChange}
          name="password"
          required
        />
        <TextField
          type="password"
          value={form.passwordConfirmed}
          error={!!errors.passwordConfirmed}
          label={intl.formatMessage({ id: 'login.registration.mainPage.confirmPasswordPlaceholder' })}
          helperText={
            errors.passwordConfirmed &&
            intl.formatMessage({ id: `login.errors.validation.${errors.passwordConfirmed}` })
          }
          placeholder={intl.formatMessage({ id: 'login.registration.mainPage.confirmPasswordPlaceholder' })}
          autoComplete="new-password"
          onChange={handleChange}
          name="passwordConfirmed"
          required
        />
        <CheckboxTerms
          terms="service"
          checked={form.agreedWithTermsOfService}
          onChange={() => {
            handleCheckboxChange('service');
          }}
        />
        <CheckboxTerms terms="use" checked={form.agreedWithTermsOfUse} onChange={() => handleCheckboxChange('use')} />
        <Button
          variant="filled"
          type="submit"
          disabled={!!Object.keys(errors).length || !form.agreedWithTermsOfUse || !form.agreedWithTermsOfService}
        >
          <FormattedMessage id={'login.registration.mainPage.nextButtonText'} />
        </Button>
      </Form>
    </AuthPage>
  );
};
