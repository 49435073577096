import { useMutation } from '@apollo/client';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, TextInput, Label, Heading, Button } from 'rio-ui-components';
import { v4 as uuid } from 'uuid';
import { CREATE_ASPECT_OPTION } from '../../containers/GovernanceContainer/Aspects/index.queries';
import CREATE_DEPARTMENT from '../../graphql/mutations/account/CreateDepartment.mutation.graphql';
import { TYPES_LABELS, TYPES } from '../../constants/aspects';
import { useCurrentUserId } from '../../hooks/useCurrentUserId';
import { useUpdateCacheAccount } from '../../hooks/useUpdateCacheAccount';

const FormStyled = styled.form`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonRow = styled(Row)`
  margin-top: ${(props) => props.theme.geometry.xs.spacing};
`;

const ButtonCol = styled(Col)`
  padding: 0 ${(props) => props.theme.geometry.xs.spacing};
`;

function validateAspectOption(aspectOption) {
  const errors = {};
  if (!aspectOption.name) {
    errors.name = 'Name is required';
  }
  return errors;
}

export const CreateOptionModal = ({ onDismiss, onCompleted, onError, type, accountId }) => {
  const userId = useCurrentUserId();
  const updateCacheAccount = useUpdateCacheAccount();
  const inputEl = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [aspectOption, setAspectOption] = useState({
    name: '',
    departmentId: uuid(),
  });
  const [errors, setErrors] = useState({});
  const [createOption] = useMutation(TYPES.DEPARTMENT === type ? CREATE_DEPARTMENT : CREATE_ASPECT_OPTION, {
    onError,
    onCompleted: (response) => {
      type === TYPES.DEPARTMENT
        ? onCompleted(response, response.createDepartment[0])
        : onCompleted(response, response.createAspectOption);
    },
    update(cache, { data: { createDepartment } }) {
      updateCacheAccount(cache, createDepartment, accountId, userId);
    },
  });

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  });

  return (
    <Modal onDismiss={onDismiss} size="md" show height="auto" maxHeight="80vh">
      <FormStyled>
        <HeaderStyled size="lg">Create {TYPES_LABELS[type]}</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Name</LabelStyled>
            </LabelContainerStyled>
            <TextInput
              disabled={isSubmitting}
              textInputRef={inputEl}
              type="text"
              onChange={(e) => {
                if (e.target.value) {
                  setErrors({
                    ...errors,
                    name: null,
                  });
                }
                setAspectOption({
                  ...aspectOption,
                  name: e.target.value,
                  departmentId: uuid(),
                });
              }}
              name="name"
              value={aspectOption.name}
              error={errors.name}
              box
            />
          </ColStyled>
        </Row>
        <ButtonRow container>
          <ButtonCol container item>
            <Button color="info" onClick={onDismiss}>
              Cancel
            </Button>
          </ButtonCol>
          <ButtonCol container item>
            <Button
              type="button"
              onClick={async () => {
                const errors = validateAspectOption(aspectOption);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }
                setIsSubmitting(true);
                const name = aspectOption.name.trim();
                const createOptionInput =
                  TYPES.DEPARTMENT === type
                    ? { name, accountId, departmentId: aspectOption.departmentId }
                    : { name, type, accountId };
                await createOption({
                  variables: {
                    input: createOptionInput,
                  },
                });
                setIsSubmitting(false);
              }}
            >
              Create
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormStyled>
    </Modal>
  );
};
