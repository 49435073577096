import { forIn, get, isObject, isDate } from 'lodash';
import { formatDate } from './formatDate';

export const objectInRowsToStringValue = (rows, columns) => {
  const columnsHeaders = new Map(columns.map(({ defaultName, id }) => [id, defaultName]));

  return rows.map((item) => {
    const row = {};

    forIn(item, (value, key) => {
      if (columnsHeaders.get(key)) {
        if (isDate(value)) {
          row[columnsHeaders.get(key)] = formatDate(value);
        } else if (isObject(value)) {
          row[columnsHeaders.get(key)] = get(value, 'name');
        } else {
          row[columnsHeaders.get(key)] = value;
        }
      }
    });

    return row;
  });
};
