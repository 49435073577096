import React from 'react';
import { Button, Col, Row } from 'rio-ui-components';
import {
  AccountLicencesConfigurationRow,
  CourseBlock,
  EmptyLicencesMessage,
  LicenceBlock,
  StyledLoadingIndicator,
  StyledButton,
} from './Styles';
import {
  useAssignBatchAccountLicencesMutation,
  useAssignBatchLicencesMutation,
  useGetLicencesByProductAndAccountQuery,
} from '../../hooks/licence';
import { useGetAccountCourseById, useGetAccountLearnBundleByIdAndAccountIdQuery } from '../../hooks/engage';
import { useGetUsersInfoByAccountIdPagedQuery, usePermissions } from '../../hooks';
import { Controller, useForm } from 'react-hook-form';
import { SelectRender } from './SelectRender';
import ContainerHeader from '../ContainerHeader';
import { makeUrl } from '../../utils';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotification';
import { useGetBundleByIdQuery, useRoutes } from '../../hooks';

export function AssignAccountProductLicences() {
  const { productId, accountId, productType } = useParams();
  const navigate = useNavigate();
  const isBundle = productType === 'bundle';
  const routes = useRoutes();

  const { control, handleSubmit, setValue } = useForm();
  const { isSystemAdmin, licenceService: licenceServicePermissions } = usePermissions();

  const [assignAccountLicences, { loading: isAssigningAccountLicences }] = useAssignBatchAccountLicencesMutation();
  const [assignLicences, { loading: isAssigningLicences }] = useAssignBatchLicencesMutation();

  const { data: course, loading: isCourseFetching } = useGetAccountCourseById(productId, { skip: isBundle });

  const { data: useGetBundleData, loading: isBundleFetching } = useGetBundleByIdQuery(productId, {
    skip: !isBundle || !licenceServicePermissions.createLicence,
  });
  const { data: useGetAccountBundleData, loading: isAccountBundleFetching } =
    useGetAccountLearnBundleByIdAndAccountIdQuery(productId, accountId, {
      skip: !isBundle || licenceServicePermissions.createLicence,
    });

  const bundle = useGetBundleData || useGetAccountBundleData;

  const { data: licences, loading: isLicencesFetching } = useGetLicencesByProductAndAccountQuery(productId, accountId);
  const {
    data: { content: userList },
    loading: isUsersFetching,
  } = useGetUsersInfoByAccountIdPagedQuery(accountId);
  const { showNotification } = useNotification();

  const product = isBundle ? bundle : course;

  const userMap = new Map(userList.map((user) => [user.id, user]));
  // user licence relation
  const userLicenceSet = new Set(licences.map(({ userId, productId }) => userId + productId));

  const userIdWithoutLicence = userList.filter(({ id }) => !userLicenceSet.has(id + productId)).map(({ id }) => id);

  const isLoading =
    isLicencesFetching ||
    isAssigningLicences ||
    isAssigningAccountLicences ||
    isCourseFetching ||
    isBundleFetching ||
    isAccountBundleFetching ||
    isUsersFetching;

  const isEmpty = !licences.length && !isLoading;

  const filterUsersForSelectOption = (id) =>
    userList
      .filter((user) => !userLicenceSet.has(user.id + id))
      .map(({ id: value, email, first_name, last_name }) => ({
        label: `${first_name} ${last_name} - ${email}`,
        value,
      }));

  const assignAllUsers = (e) => {
    e.preventDefault();

    // licences without user
    licences
      .filter(({ isNew }) => isNew)
      .forEach(({ id }) => {
        if (!userIdWithoutLicence.length) {
          return;
        }

        setValue(id, userIdWithoutLicence.shift());
      });
  };

  const submitForm = async (values = {}) => {
    try {
      const licencesToAssign = Object.entries(values)
        .filter(([, value]) => value)
        .map(([licenceId, userId]) => {
          return isSystemAdmin ? { licenceId, userId, accountId } : { licenceId, userId };
        });

      if (!licencesToAssign.length) {
        return;
      }

      const { data: list } = isSystemAdmin
        ? await assignLicences(licencesToAssign)
        : await assignAccountLicences(licencesToAssign);

      if (!list.length) {
        throw new Error('Not Assigned');
      }

      showNotification('Licences have been assigned');
    } catch (e) {
      showNotification('Licences have not been assigned', 'danger');
    }
  };

  const userWithoutLicenceCount = userIdWithoutLicence.length;
  const licenceCreateUrl = makeUrl(`/${routes.configuration.root}/${routes.configuration.menu.learn.createLicense}`, {
    accountId,
    productType,
    productId,
  });
  const forwardToCreateLicense = () => navigate(licenceCreateUrl);
  const isCreateLicenceVisible = isSystemAdmin && licenceServicePermissions.createLicence;

  return (
    <>
      <ContainerHeader
        name="ConfigurationLearnSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title="Licence assigning"
      />

      {isEmpty && (
        <EmptyLicencesMessage>
          {isCreateLicenceVisible && (
            <>
              <h2>
                There are not licences
                <br />
                <Link to={licenceCreateUrl}>Create licences</Link>
              </h2>
            </>
          )}
          {!isSystemAdmin && (
            <h2>
              You do not currently have any licences. <br />
              Please contact your administrator or purchase licences <Link to={routes.market.engage}>here</Link>
            </h2>
          )}
        </EmptyLicencesMessage>
      )}

      {!isEmpty && (
        <AccountLicencesConfigurationRow container>
          <Col span={12}>
            {isLoading && <StyledLoadingIndicator />}

            {!isLoading && (
              <form onSubmit={handleSubmit(submitForm)}>
                <div>
                  <CourseBlock>
                    <Row container>
                      <Col span={6}>
                        <h2>{isBundle ? product?.title : product?.name}</h2>
                      </Col>

                      <Col span={6}>
                        <StyledButton size="sm" disabled={!userWithoutLicenceCount} onClick={assignAllUsers}>
                          Assign all ({userWithoutLicenceCount}) users
                        </StyledButton>

                        {isCreateLicenceVisible && (
                          <StyledButton onClick={forwardToCreateLicense} size="sm">
                            Create licence
                          </StyledButton>
                        )}
                      </Col>
                    </Row>

                    {licences.map(({ id: licenceId, userId }, key) => (
                      <LicenceBlock key={licenceId}>
                        <label span={4}>Licence # {++key}</label>

                        <div>
                          {userId && userMap.get(userId) ? (
                            <span>
                              Assigned to: {userMap.get(userId).first_name} {userMap.get(userId).last_name} -{' '}
                              {userMap.get(userId).email}
                            </span>
                          ) : (
                            <Controller
                              name={licenceId}
                              control={control}
                              render={({ field }) => (
                                <SelectRender {...field} options={filterUsersForSelectOption(product?.id)} />
                              )}
                            />
                          )}
                        </div>
                      </LicenceBlock>
                    ))}
                  </CourseBlock>
                </div>

                <div>
                  <Button>Save</Button>
                </div>
              </form>
            )}
          </Col>
        </AccountLicencesConfigurationRow>
      )}
    </>
  );
}
