import { Paper } from '@mui/material';
import { DataQualityBreakdownItem } from '@rio/rio-types';
import { CircularProgress, Icons, styled, AgGrid } from '@rio/ui-components';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const AgGridContainerStyled = styled(Paper)`
  width: 100%;
  max-width: 650px;
  min-width: 487px;
  min-height: 397px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.sys.color.outlineVariant};
`;

const ChevronContainerStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => props.theme.sys.color.primary};
  }
`;

type Props = {
  data: DataQualityBreakdownItem[];
};

const components = {
  dataCompleteness: (cell) => {
    return <CircularProgress mode="simple-right" value={cell.value} color="secondary" />;
  },
  dataQuality: (cell) => {
    return <CircularProgress mode="simple-right" value={cell.value} color="primary" />;
  },
  chevron: () => {
    return (
      <ChevronContainerStyled>
        <Icons.ChevronRight />
      </ChevronContainerStyled>
    );
  },
};

const getNavigationDestinationForUtilityType = (utilityType: string) => {
  switch (utilityType.toLowerCase()) {
    case 'waste':
      return '/v2/data/fuel/waste';
    case 'electricity':
      return '/v2/data/electricity/view';
    case 'gas':
    case 'natural gas':
      return '/v2/data/gas/view';
    case 'water':
      return '/v2/data/water/view';
    case 'materials':
      return '/v2/data/materials/view';
    case 'fuel':
      return '/v2/data/fuel/view';
    case 'transport':
      return '/v2/data/transport/view';
    case 'refrigerants & other gases':
    case 'refrigerants and other gases':
      return '/v2/data/refrigerants/view';
    case 'heat & steam':
    case 'heat and steam':
    case 'heatsteam':
      return '/v2/data/heatSteam/view';
    case 'building energy ratings':
      return '/v2/data/buildingEnergyRatings/view';
    case 'home working':
    case 'hotel stays':
    case 'hotel stays & home working':
    case 'hotel stays and home working':
      return '/v2/data/hotelStays/view';
    default:
      return '';
  }
};

export const DataCompletenessGrid = (props: Props) => {
  const navigate = useNavigate();

  const gridOptions = useMemo(
    () => ({
      rowData: [...props.data],
      pagination: false,
      components,
      onRowClicked: (row) => {
        const navigationDestination = getNavigationDestinationForUtilityType(row.data.utility);

        if (navigationDestination) {
          navigate(navigationDestination);
        }
      },
      defaultColDef: {
        sortable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
      rowStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      columnDefs: [
        {
          headerName: 'Utility',
          field: 'utility',
          width: 240,
        },
        {
          headerName: 'Data quality',
          field: 'dataQuality',
          cellRenderer: 'dataQuality',
          width: 160,
        },
        {
          headerName: 'Data completeness',
          field: 'dataCompleteness',
          cellRenderer: 'dataCompleteness',
          width: 200,
        },
        {
          headerName: '',
          field: 'chevron',
          cellRenderer: 'chevron',
          width: 20,
          cellStyle: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      ],
    }),
    [props.data, navigate]
  );

  return (
    <AgGridContainerStyled elevation={1}>
      <AgGrid gridKey="DataCompletenessGridV2" gridOptions={gridOptions} />
    </AgGridContainerStyled>
  );
};
