import React from 'react';
import { usePermissions, useUserToken } from '../../../../hooks';
import { SummarizeStatus } from '@rio/rio-types';
import { ActionFunction } from '../types';
import { SummarizeButton } from '../SummarizeButton';

export interface SummaryCellProps {
  data: {
    id: string;
    status: string;
    userId: string;
    approved: boolean;
    summarizeStatus: SummarizeStatus;
    duplicatesNumber: number;
  };
  onDeleteClick: ActionFunction;
  onQuickViewClick: ActionFunction;
}

export const SummaryCell = ({ data, onDeleteClick, onQuickViewClick }: SummaryCellProps) => {
  const { token } = useUserToken();
  const permissions = usePermissions();
  const checkPermissions = (permissions: any, action: string, isOwnResource: boolean) =>
    !!permissions.data.find((a: string) => a.startsWith(action) && !a.endsWith('Own')) ||
    (permissions.data.includes(`${action}Own`) && isOwnResource);

  const isOwnResource = data.userId === token.sub;
  const isCompleted = data.status === 'Completed';
  const showDeleteButton =
    ['Failed', 'Action Required', 'Rolled Back'].includes(data.status) &&
    checkPermissions(permissions, 'deleteImportBatch', isOwnResource);

  return (
    <>
      <SummarizeButton
        showDeleteButton={showDeleteButton}
        isCompleted={isCompleted}
        onDeleteClick={() => onDeleteClick(data.id)}
        approved={data.approved}
        summarizeStatus={data.summarizeStatus}
        duplicatesNumber={data.duplicatesNumber}
        handleQuickViewClick={() => onQuickViewClick(data.id)}
      />
    </>
  );
};
