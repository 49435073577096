import { gql } from '@apollo/client';

export const GET_WASTE_STREAMS_BY_ACCOUNT_ID = gql`
  query GetWasteStreamByAccountId($accId: ID!, $page: Int!, $pageSize: Int!, $filterValue: String) {
    getWasteStreamsByAccountId(accountId: $accId, page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      pageCount
      wasteStreams {
        id
        name
        ewc {
          description
          code
          id
          hazardous
        }
        isHazardous
      }
    }
  }
`;

export const GET_ALL_EWCS = gql`
  query GetAllEWCs {
    getAllEWCs {
      code
      description
      id
      hazardous
    }
  }
`;

export const CREATE_WASTE_STREAM = gql`
  mutation CreateWasteStream($id: ID!, $name: String!, $ewcId: ID!, $hazardous: Boolean!, $accId: ID!) {
    createWasteStream(input: { id: $id, name: $name, ewcId: $ewcId, accountId: $accId, isHazardous: $hazardous }) {
      id
      name
      ewc {
        description
        code
        id
        hazardous
      }
      isHazardous
    }
  }
`;

export const EDIT_WASTE_STREAM = gql`
  mutation EditWasteStream($id: ID!, $name: String!, $ewcId: ID!, $hazardous: Boolean!, $accId: ID!) {
    editWasteStream(input: { id: $id, name: $name, ewcId: $ewcId, accountId: $accId, isHazardous: $hazardous }) {
      id
    }
  }
`;
export const GET_PAGE = gql`
  {
    wasteStreamGridPage @client
  }
`;

export const GET_TOTAL_PAGES = gql`
  {
    wasteStreamGridTotalPages @client
  }
`;

export const SET_PAGE = gql`
  mutation SetWasteStreamGridPage($page: Int!) {
    setWasteStreamGridPage(page: $page) @client
  }
`;

export const SET_TOTAL_PAGES = gql`
  mutation SetWasteStreamGridTotalPages($totalPages: Int!) {
    setWasteStreamGridTotalPages(totalPages: $totalPages) @client
  }
`;

export const GET_SEARCH = gql`
  {
    wasteStreamGridSearch @client
  }
`;

export const SET_SEARCH = gql`
  mutation SetWasteStreamGridSearch($search: String!) {
    setWasteStreamGridSearch(search: $search) @client
  }
`;
