import { useCurrentAccountId } from './useCurrentAccountId';
import { useUploadFileByAccountIdMutation } from './useUploadFileByAccountIdMutation';

type UploadFilePayload = {
  loading: boolean;
};

export type UploadFileHook = [
  (options?: {
    file?: File | null;
    fileName?: string | null;
    accountId?: string;
    [key: string]: unknown;
  }) => Promise<unknown>,
  UploadFilePayload
];

export function useUploadFileMutation(): UploadFileHook {
  const accountId = useCurrentAccountId();

  const [uploadFile, payload]: UploadFileHook = useUploadFileByAccountIdMutation();

  return [(args) => uploadFile({ ...args, accountId }), payload];
}
