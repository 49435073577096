import { forIn, get, mapValues } from 'lodash';
import { InOut } from '@rio/rio-types';
import { useState } from 'react';
import moment from 'moment';
import { DataFormRowsFillingStrategy, DateFrequency, DataFormInputStrategy } from '../../constants';
import { toNumeric, fixFloat, calculateVat } from '../../utils';

const getTransactionDates = (momentMeasure, startDate, endDate, i) => {
  const transactionStartDate = moment(startDate).clone().add(i, momentMeasure);
  const transactionEndDate = moment(startDate)
    .clone()
    .add(i + 1, momentMeasure)
    .subtract(1, 'days');

  return {
    startDate: transactionStartDate.toDate(),
    endDate: transactionEndDate.isAfter(endDate) ? endDate : transactionEndDate.toDate(),
  };
};

export function useConvertWaterDataFormToRows() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  return [
    (form = {}, columns = []) => {
      if (!columns.length || loading) {
        return;
      }

      setLoading(true);

      const {
        data,
        startDate,
        endDate,
        dataFormRowsFillingStrategy: dataFormRowsFillingStrategyValue,
        dateFrequency: dateFrequencyValue,
        dataFormInputStrategy: dataFormInputStrategyValue,
      } = form;

      const mappedData = mapValues(data, (value) => get(value, 'label', value));

      const row = {
        ...mappedData,
        startDate,
        endDate,
        totalCost: data.totalCost || (data.tariff > 0 ? data.quantity * data.tariff : undefined),
        comment: data.comment || undefined,
        meterName: data.energySource.name,
      };

      if (row['totalCost']) {
        row['totalCostVat'] = calculateVat(row['totalCost']);
      }

      const inOut = data.energySource.inOut;

      row['waterIn'] = inOut === InOut.Both || inOut === InOut.In ? data.quantity : null;
      row['waterOut'] = inOut === InOut.Both || inOut === InOut.Out ? data.quantity : null;

      const list = [];

      if (dataFormRowsFillingStrategyValue === DataFormRowsFillingStrategy.Distributed) {
        let rowsCount;

        const momentMeasure = {
          [DateFrequency.Daily]: 'days',
          [DateFrequency.Weekly]: 'weeks',
          [DateFrequency.Monthly]: 'months',
          [DateFrequency.Quarterly]: 'quarters',
          [DateFrequency.Annually]: 'years',
        }[dateFrequencyValue];

        rowsCount = moment(endDate).diff(moment(startDate), momentMeasure) + 1;
        rowsCount = rowsCount < 1 ? 1 : rowsCount;

        if (rowsCount > 100000) {
          throw new Error('Too much rows');
        }

        if (dataFormInputStrategyValue === DataFormInputStrategy.Single) {
          for (let i = 0; i < rowsCount; i++) {
            const dates = getTransactionDates(momentMeasure, startDate, endDate, i);
            list.push({
              ...row,
              ...dates,
            });
          }
        } else {
          const cachedDividedValues = {};
          const columnsWithNumber = new Map([
            ['totalCost', 'currency'],
            ['totalCostVat', 'currency'],
          ]);

          columns
            .filter(({ dataType }) => dataType === 'number' || dataType === 'currency')
            .forEach(({ id, dataType }) => columnsWithNumber.set(id, dataType));

          forIn(row, (value, key) => {
            if (!columnsWithNumber.has(key) || !value) {
              return;
            }

            const newValue = toNumeric(value) / rowsCount;

            const isNumber = columnsWithNumber.get(key) === 'number';

            cachedDividedValues[key] = newValue > 0 ? (isNumber ? Math.round(newValue) : fixFloat(newValue)) : 0;
          });

          cachedDividedValues['totalCostVat'] = calculateVat(cachedDividedValues['totalCost']);

          for (let i = 0; i < rowsCount; i++) {
            const dates = getTransactionDates(momentMeasure, startDate, endDate, i);
            list.push({
              ...row,
              ...cachedDividedValues,
              ...dates,
            });
          }
        }
      } else {
        list.push(row);
      }

      // add a fake id for a compatibility in the table component
      const mappedList = list.map((item, i) => ({ ...item, id: i }));

      setRows(mappedList);

      setLoading(false);
    },
    {
      data: rows,
      loading,
    },
  ];
}
