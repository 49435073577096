import { gql } from '@apollo/client';

export const GET_LOGIN_ERROR_MESSAGE = gql`
  {
    loginError @client
  }
`;

export const GET_ID_TOKEN = gql`
  {
    idToken @client {
      sub
      given_name
      aud
      event_id
      auth_time
      name
      exp
      iat
      family_name
      email
      roles
      accessLevel
      allowedLocations
      allowedTags
    }
    sessionLoading @client
  }
`;

export const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    login(email: $email, password: $password) @client
  }
`;

export const LOG_IN_SSO = gql`
  mutation LogInSSO($provider: String!) {
    loginSSO(provider: $provider) @client
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($email: String!, $password: String!, $oldPassword: String!) {
    setPassword(email: $email, password: $password, oldPassword: $oldPassword) @client
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) @client
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($username: String!, $password: String!, $confirmationCode: String!) {
    resetPassword(username: $username, password: $password, confirmationCode: $confirmationCode) @client
  }
`;

export const LOG_OUT = gql`
  mutation LogOut {
    logout @client
  }
`;
