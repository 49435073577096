import { MaterialConversionFactor } from '@rio/rio-types';
import { useCallback, useContext, useMemo } from 'react';
import { GridReadyEvent, ColDef } from 'ag-grid-community';
import { formatDate, getGridDateComparator, gridNumberFormatter } from '../../../utils';
import { FactorsGrid } from './FactorsGrid';
import { MaterialConversionFactorsDescription } from './FactorsDescriptions';
import { TabContentContext } from './LocationBasedFactors';
import { TabContent } from '../../../components/TabContent';
import { useRoutes } from '~/hooks';

interface GridProps {
  factors: MaterialConversionFactor[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Reporting Year',
    field: 'reportingYear',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'kgCO2e',
    field: 'kgCO2e',
    filter: 'agNumberColumnFilter',
    valueFormatter: gridNumberFormatter,
    headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
  },
  {
    headerName: 'Start date',
    field: 'startDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: getGridDateComparator(),
    },
  },
  {
    headerName: 'End date',
    field: 'endDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: getGridDateComparator(),
    },
  },
  {
    headerName: 'Material',
    field: 'material',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Origin',
    field: 'origin',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Activity',
    headerTooltip: 'This shows if the activity falls under your organisation’s direct (company owned emission sources) or indirect (non company owned, employee, contractor or third party emission sources) activities',
    field: 'activity',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'reference',
    cellRendererParams: { icon: 'external-link-alt' },
  },
];
export function MaterialConversionFactorsGrid({ factors }: GridProps) {
  const tabContentData = useContext(TabContentContext);
  const routes = useRoutes();

  const handleGridReady = useCallback((e: GridReadyEvent) => e.api.sizeColumnsToFit(), []);

  const rowData = useMemo(
    () =>
      factors.map((factor) => ({
        ...factor,
        startDate: formatDate(factor.startDate),
        endDate: formatDate(factor.endDate),
      })),
    [factors]
  );

  return (
    <TabContent {...tabContentData} index={routes.configuration.factors.material}>
      <div className="ag-theme-alpine">
        <FactorsGrid
          pagination={true}
          paginationPageSize={25}
          domLayout="autoHeight"
          rowData={rowData}
          onGridReady={handleGridReady}
          columnDefs={columnDefs}
        />
      </div>
      <MaterialConversionFactorsDescription />
    </TabContent>
  );
}
