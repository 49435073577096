import { gql } from '@apollo/client';

export const GET_ALL_TRANSPORT_SUB_TYPES = gql`
  query GetAllTransportSubTypes {
    getAllTransportSubTypes {
      id
      name
    }
  }
`;
