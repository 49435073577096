import { gql } from '@apollo/client';

export const GET_RECEIVED_SURVEY_BY_ID = gql`
  query GetReceivedSurvey($id: ID!, $accountId: ID!) {
    getReceivedSurveyById(id: $id, accountId: $accountId) {
      id
      name
      owner {
        id
        name
      }
      lockedBy {
        id
        first_name
        last_name
      }
      deadlineDate
      template {
        id
        formContent
        questions {
          id
        }
      }
      submission {
        id
        status
        version
        feedback
        owner {
          id
          name
        }
        answers {
          id
          answer
          question {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const SET_SUBMISSION = gql`
  mutation SetSubmission($accountId: ID!, $surveyId: ID!, $submission: SurveySubmissionInput!) {
    setSurveySubmission(accountId: $accountId, surveyId: $surveyId, submission: $submission) {
      accountId
      surveyId
      id
      status
      version
      answers {
        id
        answer
      }
    }
  }
`;

export const SET_SURVEY_SUBMISSION_UPDATED = gql`
  subscription SubscribeToSetSurveySubmission($accountId: ID!, $surveyId: ID!) {
    setSurveySubmissionUpdated(accountId: $accountId, surveyId: $surveyId) {
      id
      status
      version
      answers {
        id
        answer
      }
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query GetDocuments($ids: [ID!]!) {
    getDocumentRecordsByIds(ids: $ids) {
      id
      link
      fileName
    }
  }
`;

export const UNLOCK_CONTRIBUTOR = gql`
  mutation UnlockContributor($accountId: ID!, $campaignId: ID!) {
    unlockContributor(accountId: $accountId, campaignId: $campaignId) {
      campaignId
      accountId
    }
  }
`;
