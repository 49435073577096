import { gql } from '@apollo/client';

export const BANNER_FRAGMENT = gql`
  fragment BannerFields on Banner {
    id
    accountId
    name
    thumbnail
    url
    type
    visible
  }
`;
export const GET_BANNERS_FOR_ACCOUNT = gql`
  query getBanners($accountId: ID!) {
    getBanners(accountId: $accountId, includeHidden: true) {
      ...BannerFields
    }
  }
  ${BANNER_FRAGMENT}
`;
