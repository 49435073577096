import { Tag } from '@rio/rio-types';
import { getFullName } from '~/utils/utils';
import { toDate, toDateTime } from '../../utils';

export const fileListData = (document, supplier) => [
  {
    title: 'Type:',
    value: document?.type?.join(', ') || '-',
  },
  {
    title: 'Review Date:',
    value: document.reviewDate ? toDate(document.reviewDate) : '-',
  },
  {
    title: `${document.category} ID:`,
    value: document.referenceId ? document.referenceId : '-',
  },
  {
    title: 'Supplier:',
    value: (supplier && supplier.name) || '-',
  },
  {
    title: 'Management System Reference:',
    value: document?.managementSystemCode?.toUpperCase() || '-',
  },
  {
    title: 'Uploaded on:',
    value: toDateTime(document.createdAt) || '-',
  },
  {
    title: 'Uploaded by:',
    value: getFullName(document.createdBy) || '-',
  },
  {
    title: 'Last updated on:',
    value: toDateTime(document.updatedAt) || '-',
  },
  {
    title: 'Last updated by:',
    value: getFullName(document.updatedBy) || '-',
  },
  {
    title: 'Tags:',
    value: !!document?.tags?.length ? document?.tags?.map((tag: Tag) => tag.tagName).join(', ') : '-',
  },
  {
    title: 'Notes:',
    value: document?.notes || '-',
  },
];
