import strip from 'strip';
import { Text, styled } from '@rio/ui-components';
import { Course } from '@rio/rio-types';
import learnIcon from '~/assets/img/icons/learn.svg';
import { CourseTypeHandler } from '~/utils';
import { learnTourStepsPreview } from '../../../../hocs';
import { CourseProgressIcon } from '../CoursesSection/CourseProgressIcon';
import CourseStatus from './CourseStatus';
import ViewCourseButton from './ViewCourseButton';

type TCourseImage = {
  src?: string | null | undefined;
  name: string;
};

const CourseContainer = styled('div')`
  width: 100%;
  background: ${({ theme }) => theme.sys.color.onPrimary};
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const TextDescriptionStyle = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const CourseImage = styled('div')<TCourseImage>`
  position: relative;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  box-shadow: ${(p) => p.theme.sys.elevation[0]};
  padding: 0 0 75% 0;
  background: ${({ theme }) => theme.sys.color.outlineVariant} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;
`;

const CircleIconStyled = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  border: 4px solid ${({ theme }) => theme.sys.color.onPrimary};
  border-radius: 50%;
  display: inline-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
`;

const LearnIconStyle = styled('img')`
  width: 80%;
  height: 80%;
`;

const CourseTextContainer = styled('div')``;

const ColStyled = styled('div')`
  width: 25%;
`;

const TextStyle = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
  margin-bottom: 16px;
`.withComponent('h2');

const WrapperStyled = styled('div')`
  display: flex;
  gap: 16px;

  svg {
    width: 40px !important;
    height: 40px !important;
  }
`;

interface SelectedCoursePreviewProps {
  course: Course;
  bundleId?: string;
  userId: string;
  refetch: () => void;
}
const SelectedCoursePreview = ({ course, userId, refetch }: SelectedCoursePreviewProps) => {
  const courseTypeHandler = new CourseTypeHandler(course);
  const handleType = courseTypeHandler.getHandleMethod();

  return (
    <CourseContainer>
      <ColStyled>
        <CourseImage name="CoursePreview__Thumbnail" src={course.metaData.thumbnail}>
          {!course.metaData.thumbnail && (
            <CircleIconStyled>
              <LearnIconStyle src={learnIcon} />
            </CircleIconStyled>
          )}
        </CourseImage>
      </ColStyled>
      <CourseTextContainer>
        <WrapperStyled>
          <TextStyle typescale="headline" size="large">
            {course.name}
          </TextStyle>
          <CourseProgressIcon status={course.userCourseMetaData && course.userCourseMetaData.courseProgress} />
        </WrapperStyled>
        <CourseStatus
          courseProgress={course.userCourseMetaData && course.userCourseMetaData.courseProgress}
          handleType={handleType}
        />
        {!!course.metaData.description && (
          <TextDescriptionStyle typescale="body" size="large">
            {strip(course.metaData.description)}
          </TextDescriptionStyle>
        )}
        <ViewCourseButton
          course={course}
          handleType={handleType}
          downloadLink={course.metaData.link}
          userId={userId}
          courseRefetch={refetch}
          name="StartCourse-Button"
          steps={learnTourStepsPreview}
        />
      </CourseTextContainer>
    </CourseContainer>
  );
};

export default SelectedCoursePreview;
