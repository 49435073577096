export const isInvalidPassword = (password: string) => {
  if (password.length < 10) return 'Password must be 10 characters or longer';
  if (password.toUpperCase() === password || password.toLowerCase() === password)
    return 'Password must contain both upper and lower case characters';
  return false;
};

const getKeyValuesFromQueryString = (querystring: string) => {
  const queryStringPairs = querystring.replace('?', '').split('&');
  const keyValuePairs: { key: string; value: string }[] = [];

  for (let i = 0; i < queryStringPairs.length; i++) {
    const queryStringPair = queryStringPairs[i].split('=');
    keyValuePairs[i] = { key: queryStringPair[0], value: queryStringPair[1] };
  }
  return keyValuePairs;
};

export const getFromQueryString = (searchKey: string, querystring: string) => {
  if (!querystring) {
    return '';
  }
  const keyValues = getKeyValuesFromQueryString(querystring);

  for (let i = 0; i < keyValues.length; i++) {
    if (keyValues[i].key === searchKey) {
      return keyValues[i].value;
    }
  }
};
