import { gql } from '@apollo/client';
import TASK_FIELDS from '../../../../graphql/fragments/TaskFields.fragment.graphql';

export const GET_LEGISLATION_BY_ID = gql`
  query GetLegislationById($id: ID!, $accountId: ID!, $tagId: ID) {
    getLegislationById(id: $id, accountId: $accountId, tagId: $tagId) {
      id
      title
      isActive
      synopsis
      links
      countries
      type
      applicability
      reviewDate
      status
      lastFlaggedForReview
      lastClearedReviewFlag
      documents {
        id
        fileName
        managementSystemCode
        category
      }
      tags {
        id
        tagName
      }
      locations {
        id
        name
      }
      regions {
        id
        name
      }
      tasks {
        ...TaskFields
      }
      departmentIds
    }
  }
  ${TASK_FIELDS}
`;

export const ATTACH_CONTENT = gql`
  mutation attachContent($id: ID!, $accountId: ID!, $linkId: ID!, $content: LegislationContentInput!, $tagId: ID) {
    attachContentToLegislation(
      input: { id: $id, accountId: $accountId, linkId: $linkId, content: $content, tagId: $tagId }
    )
  }
`;

export const ATTACH_DOCUMENT = gql`
  mutation attachDocument($id: ID!, $accountId: ID!, $legislationId: ID!, $tagId: ID) {
    attachDocumentToLegislation(
      input: { id: $legislationId, accountId: $accountId, linkId: $legislationId, documentId: $id, tagId: $tagId }
    )
  }
`;
