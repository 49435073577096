import { TransactionType } from '@rio/rio-types';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { Heading, Row, Col, Label, TextInput, Button, Checkbox } from 'rio-ui-components';
import { DatePicker } from 'rio-ui-components';
import { LocationSelect } from '../../../components/LocationSelect';
import { CREATE_ENERGY_SOURCES } from './index.queries';
import { EnergySourcesSelection } from './EnergySourcesSelection';
import { useMeterModalForm } from './useMeterModalForm';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

export const getSuppliersUtility = (meterType) =>
  meterType === 'Heat & Steam' ? TransactionType.Electricity : meterType.toUpperCase();

export type CreateEnergyMeterProps = {
  accountId: string;
  onSuccess: (msg: string) => void;
  onError: (msg: string, color: string) => void;
};
const CreateEnergyMeter = ({ accountId, onError, onSuccess }: CreateEnergyMeterProps) => {
  const {
    state,
    changeMeterName,
    changeMeterType,
    changeLocation,
    isValidData,
    changeFieldValue,
    waterMeterType,
    toggleWaterMeterIn,
    toggleWaterMeterOut,
  } = useMeterModalForm();

  const handleMeterNameChange = (event) => {
    changeMeterName({
      value: event.target.value,
    });
  };

  const handleTypeSelectorChange = (event) => {
    const {
      select: { value },
    } = event.target;

    changeMeterType({
      value,
      label: value.name,
    });
  };

  const handleLocationSelectChange = (event) => {
    const fieldValue = event.target.value;
    changeLocation({
      value: fieldValue,
      label: event.target.label,
    });
  };

  const handleCreateEnergySourceClick = (createFunc) => {
    createFunc({
      variables: {
        energySources: [
          {
            id: uuid(),
            name: state.meterName.value,
            locationId: state.locationId.value,
            typeId: state.typeId.value.id,
            accountId,
            inOut: waterMeterType,
            isGeneration:
              state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam'
                ? state.isGeneration || false
                : null,
            meterCode: state.code,
            hardwareProviderName: state.hardwareProviderName,
            operatorProviderName: state.operatorProviderName,
            startDate: state.startDate,
            endDate: state.endDate,
          },
        ],
      },
    });
  };

  const handleCreateOnComplete = () => {
    const msg = `The Meter ${state.meterName.value} was created successfully`;
    onSuccess(msg);
  };

  const handleCreateOnError = () => {
    onError('Something went wrong. If the problem persists, contact support.', 'danger');
  };

  const RenderLocationSelector = () => {
    return (
      <LocationSelect value={state.locationId} onChange={handleLocationSelectChange} error={state.locationId.error} />
    );
  };

  const RenderCreateLocationsButton = () => {
    return (
      <Row container align="between">
        <ColStyled item>
          <Mutation mutation={CREATE_ENERGY_SOURCES} onCompleted={handleCreateOnComplete} onError={handleCreateOnError}>
            {(createEnergySources, { called }) => {
              return (
                <Button
                  name="create-energyMeter__button--create"
                  color="success"
                  disabled={!isValidData || called}
                  onClick={() => handleCreateEnergySourceClick(createEnergySources)}
                >
                  Add Meter
                </Button>
              );
            }}
          </Mutation>
        </ColStyled>
      </Row>
    );
  };

  const selectStartDate = (value) => {
    changeFieldValue({
      fieldName: 'startDate',
      value,
    });
  };
  const selectEndDate = (value) => {
    changeFieldValue({
      fieldName: 'endDate',
      value,
    });
  };

  return (
    <Container>
      <HeaderStyled name="create-energyMeter__heading" size="lg">
        Add New Meter
      </HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>What do you want to call this meter?</LabelStyled>
          </LabelContainerStyled>
          <TextInput value={state.meterName.value} error={state.meterName.error} onChange={handleMeterNameChange} />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>What is the meter type?</LabelStyled>
          </LabelContainerStyled>
          <EnergySourcesSelection value={state.typeId} onChange={handleTypeSelectorChange} error={state.typeId.error} />
        </ColStyled>
      </Row>
      {state.typeId.value.name === 'Water' && (
        <Row container align="between">
          <ColStyled item>
            <LabelStyled>{state.typeId.value.name} In</LabelStyled>
            <Checkbox id="create-meter-in" name="in" onChange={() => toggleWaterMeterIn()} checked={state.meterIn} />
          </ColStyled>
          <ColStyled item>
            <LabelStyled>{state.typeId.value.name} Out</LabelStyled>
            <Checkbox
              id="create-meter-out"
              name="out"
              onChange={() => toggleWaterMeterOut()}
              checked={state.meterOut}
            />
          </ColStyled>
        </Row>
      )}
      {(state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam') && (
        <Row container align="between">
          <ColStyled item>
            <LabelStyled>Renewable energy generating meter</LabelStyled>
            <Checkbox
              disabled={state.isGeneration}
              id="is-generating-meter"
              name="isGeneration"
              onChange={() =>
                changeFieldValue({
                  fieldName: 'isGeneration',
                  value: !state.isGeneration,
                })
              }
              checked={state.isGeneration || false}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Which location is this meter at?</LabelStyled>
          </LabelContainerStyled>
          <RenderLocationSelector />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Meter Code</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            value={state.code}
            onChange={(e) => changeFieldValue({ fieldName: 'code', value: e.target.value })}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Has this meter closed?</LabelStyled>
          </LabelContainerStyled>

          <LabelContainerStyled>
            <LabelStyled>Start Date</LabelStyled>
          </LabelContainerStyled>

          <DatePicker
            name="startDate"
            box
            onDayClick={selectStartDate}
            selectedDate={state.startDate}
            disabledDatesAfter={state.endDate}
          />

          <LabelContainerStyled>
            <LabelStyled>End Date</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            name="endDate"
            box
            onDayClick={selectEndDate}
            selectedDate={state.endDate}
            disabledDatesBefore={state.startDate}
          />
        </ColStyled>
      </Row>
      <RenderCreateLocationsButton />
    </Container>
  );
};

export default CreateEnergyMeter;
