import { styled, Text, Icons, useTheme } from '@rio/ui-components';

const WrapperStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TextStyled = styled(Text)`
  color: ${({ theme }) => theme.extendedColors.environmental.darkColor};
`;

export const AutoSave = () => {
  const theme = useTheme();

  return (
    <WrapperStyled>
      <Icons.CheckCircleOutline fontSize="small" sx={{ color: theme.extendedColors.environmental.darkColor }} />
      <TextStyled typescale="body" size="medium">
        Auto-save is on
      </TextStyled>
    </WrapperStyled>
  );
};
