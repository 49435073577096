import { dromoConfiguration, DromoConfiguration } from './base';
import { validateDependency, validateEitherFieldsIncluded, validateCombinedMandatoryFields } from './hooks';
import template from '../TransportContainer/UploadTransportTransactions/transport-template.xlsx';
import { helpTextHtml } from './utils';
import { getEnvVar } from '../../../env';

const name = 'Transport';
const templateUrl = [getEnvVar('REACT_APP_BASE_URL'), template].join('');

export function getTransportConfiguration(data: any): DromoConfiguration {
  return {
    ...dromoConfiguration,
    settings: {
      ...dromoConfiguration.settings,
      importIdentifier: name,
      title: `Upload ${name}`,
      uploadStep: {
        ...dromoConfiguration.settings.uploadStep,
        helpText: helpTextHtml(templateUrl),
      },
    },
    bulkRowHooks: [
      (records) =>
        validateCombinedMandatoryFields(
          [
            ['volume', 'unit of volume'],
            ['freight (tonne.km)', 'carrier'],
            ['total distance', 'unit of distance'],
            ['journey distance', 'unit of distance'],
          ],
          records
        ),
      (records) =>
        validateEitherFieldsIncluded(['volume', 'freight (tonne.km)', 'journey distance', 'total distance'], records),
      (records) => validateDependency(data?.typeSubType, ['type', 'subtype'], records),
      (records) => validateDependency(data?.subTypeFuelType, ['subtype', 'fuel type'], records),
    ],
  };
}
