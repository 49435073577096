import { useQuery } from '@apollo/client';
import { GET_MATERIALS_BY_ACCOUNT_ID } from '../../components/MaterialSelect/index.queries';

export function useGetMaterialsTypesByAccountId(accountId) {
  const { data: { getMaterialsByAccountID = [] } = {}, ...others } = useQuery(GET_MATERIALS_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    }
  });

  return { data: getMaterialsByAccountID, ...others };
}
