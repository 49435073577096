import { DEPARTMENT, labels } from './innerConstant';

const ACTIVITY = 'ACTIVITY';
const ASPECT = 'ASPECT';
const RISK = 'RISK';
const HAZZARDS = 'HAZZARDS';
const WHOISEFFECTED = 'WHOISEFFECTED';
const IMPACT = 'IMPACT';
const EMERGENCY_CONDITION = 'EMERGENCY_CONDITION';
const CONTROL_MEASURE = 'CONTROL_MEASURE';
const STATUS = 'STATUS';

export const TYPES = {
  ACTIVITY,
  ASPECT,
  RISK,
  HAZZARDS,
  WHOISEFFECTED,
  IMPACT,
  EMERGENCY_CONDITION,
  CONTROL_MEASURE,
  STATUS,
  DEPARTMENT
};

export const TYPES_LABELS = {
  ACTIVITY: 'Activity / Process',
  ASPECT: 'Environmental Aspect',
  HAZZARDS: 'Identified Hazzards',
  WHOISEFFECTED: 'Who may be effected',
  IMPACT: 'Potential Environmental Impact',
  EMERGENCY_CONDITION: 'Potential Emergency Condition',
  CONTROL_MEASURE: 'Relevant Control Measure',
  STATUS: 'Status',
  DEPARTMENT: labels[DEPARTMENT]
};

export const LIKELIHOOD_OPTIONS = [
  {
    value: '1',
    label: '1 - Very unlikely'
  },
  {
    value: '2',
    label: '2 - Unlikely'
  },
  {
    value: '3',
    label: '3 - Possible'
  },
  {
    value: '4',
    label: '4 - Likely'
  },
  {
    value: '5',
    label: '5 - Very likely'
  }
];

export const SEVERITY_OPTIONS = [
  {
    value: '-1',
    label: '-1 - Positive impact'
  },
  {
    value: '1',
    label: '1 - Negligible'
  },
  {
    value: '2',
    label: '2 - Minimal harm'
  },
  {
    value: '3',
    label: '3 - Some impact'
  },
  {
    value: '4',
    label: '4 - Harmful'
  },
  {
    value: '5',
    label: '5 - Severe impact'
  }
];
