import React, { useCallback, useState, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { NamedEntity, ProjectInput, Query } from '@rio/rio-types';
import { Col, Icon, Row, Label, TextInput, TextArea, Select, DatePicker } from 'rio-ui-components';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { frequencyOptions, priorityOptions, unitsByCategory } from '../constants';
import { SchemeSelect } from '../../../components/SchemeSelect';
import { MetersMultiSelect } from '../../../components/MetersMultiSelect';
import { ValidateErrors } from '../Validate/index';
import { FormattedMessage } from 'react-intl';
import UserSelect from '../../../components/UserSelect';
import { EvidencePicker } from '../../../components/EvidenceNotes';
import { isTextEvent } from '../ProjectView/utils';
import { ProjectCategorySelect } from '../../../components/ProjectCategorySelect';
import { Nullable, Option } from '../../../types';
import { namedEntityToSelectOption } from '../../../utils';
import { ENERGY_GENERATION_CATEGORY_NAME } from '../../../constants/common';
import { GET_PROJECT_CATEGORIES } from '../../../graphql/queries/projectCategory/GetProjectCategories.query';

interface TooltipProps {
  text: string;
  height: string;
}

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  svg {
    cursor: pointer;
  }

  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const Tooltip = ({ text, height }: TooltipProps) => {
  const TooltipContainer = styled.div`
    display: flex;
    margin-top: 36px;
  `;

  const TooltipText = styled.span`
    background-color: #ffe5b4;
    border-radius: 6px;
    color: #83838e;
    cursor: default;
    font-size: 14px;
    height: ${height};
    text-align: left;
    padding: 5px 10px;
    width: 190px;
    word-break: break-word;
  `;

  return (
    <TooltipContainer>
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
};

const StartDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -354px;
    left: 50%;
  }
`;

export type ProjectDetailsProps = {
  isSubmitting: boolean;
  project: ProjectInput;
  setProject: (project: ProjectInput) => void;
  accountId: string;
  errors: ValidateErrors;
  evidenceNotes?: string;
  evidenceDocuments?: any[];
  setEvidenceNotes: React.Dispatch<SetStateAction<string>>;
  setEvidenceDocuments: React.Dispatch<SetStateAction<any>>;
};

const ProjectDetails = ({
  isSubmitting,
  project,
  setProject,
  accountId,
  errors,
  evidenceNotes,
  evidenceDocuments,
  setEvidenceNotes,
  setEvidenceDocuments
}: ProjectDetailsProps) => {
  const [selectedLocations, setSelectedLocations] = useState(project.locations || null);
  const [selectedOwners, setSelectedOwners] = useState(project.owners || null);
  const [selectedMeters, setSelectedMeters] = useState(project.meters || null);
  const [showStartDateTooltip, setShowStartDateTooltip] = useState(false);
  const [showEndDateTooltip, setShowEndDateTooltip] = useState(false);
  const [showBaselineDateTooltip, setShowBaselineDateTooltip] = useState(false);
  const [showEnergyGenerationSavingsPrompt, setShowEnergyGenerationSavingsPrompt] = useState(false);
  const [showCostSavingsPrompt, setShowCostSavingsPrompt] = useState(false);
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<Nullable<NamedEntity>>(null);
  const [projectCategories, setProjectCategories] = useState<Nullable<NamedEntity[]>>(null);

  useQuery<Pick<Query, 'getProjectCategories'>>(GET_PROJECT_CATEGORIES, {
    onCompleted: (data) => {
      setProjectCategories(data?.getProjectCategories || []);
    },
    onError: () => {
      setProjectCategories([]);
    }
  });

  const energyGenerationId = projectCategories?.find(
    (category: NamedEntity) => category.name === ENERGY_GENERATION_CATEGORY_NAME
  )?.id;

  const handleChange = useCallback(
    (event: any) => {
      const locations =
        event.target.name === 'locationsSelect'
          ? event.target.select.map(({ value }: { value: string }) => value)
          : project.locations;
      const owners =
        event.target.name === 'owner'
          ? event.target.select.map(({ value }: { value: string }) => value)
          : project.owners;
      const meters =
        event.target.name === 'meters'
          ? event.target.select.map(({ value }: { value: string }) => value)
          : project.meters;

      if (event.target.name === 'locationsSelect') {
        setSelectedLocations(event.target.select);
        setSelectedMeters([]);
        setProject({
          ...project,
          meters: []
        });
      }
      if (event.target.name === 'owner') {
        setSelectedOwners(event.target.select);
      }
      if (event.target.name === 'meters') {
        setSelectedMeters(event.target.select);
      }

      setProject({
        ...project,
        [event.target.name]: !isTextEvent(event) ? event.target.value || event.target.select : event.target.value,
        locations,
        owners,
        meters
      });
    },
    [project, setProject]
  );

  const baselineEndDate = new Date(project.baselineStartDate);
  baselineEndDate.setFullYear(new Date(project.baselineStartDate).getFullYear() + 1);

  return (
    <div>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.name" />
            </LabelStyled>
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-name"
            disabled={isSubmitting}
            onChange={handleChange}
            name="name"
            value={project.name}
            error={errors.name}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.description" />
            </LabelStyled>
          </LabelContainerStyled>
          <TextArea
            data-cy="ProjectDetails__Input-description"
            size="lg"
            disabled={isSubmitting}
            onChange={handleChange}
            name="description"
            value={project.description}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.impact" />
            </LabelStyled>
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-impact"
            disabled={isSubmitting}
            onChange={handleChange}
            name="impact"
            value={project.impact}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-priority">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.priority" />
            </LabelStyled>
          </LabelContainerStyled>
          <Select
            name="priority"
            classPrefix="priority-select"
            isDisabled={isSubmitting}
            onChange={handleChange}
            value={project.priority}
            options={priorityOptions}
            error={errors.priority}
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-category">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.category" />
            </LabelStyled>
          </LabelContainerStyled>
          <ProjectCategorySelect
            value={selectedProjectCategory ? namedEntityToSelectOption(selectedProjectCategory) : null}
            onChange={(event) => {
              setSelectedProjectCategory(
                event.target.value ? { id: event.target.value, name: event.target.label } : null
              );
              setSelectedMeters([]);
              setProject({
                ...project,
                meters: []
              });
            }}
            isClearable={true}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.locations" />
            </LabelStyled>
          </LabelContainerStyled>
          <LocationsMultiSelect
            name="locationsSelect"
            accountId={accountId}
            value={selectedLocations}
            onChange={handleChange}
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
            defaultOptions={false}
            error={undefined}
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-scheme">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.scheme" />
            </LabelStyled>
          </LabelContainerStyled>
          <SchemeSelect
            name="schemeId"
            accountId={accountId}
            onChange={handleChange}
            value={project.schemeId}
            projectCategory={selectedProjectCategory}
            isClearable={true}
            error={errors.schemeId}
          />
        </ColStyled>
      </Row>
      {selectedProjectCategory?.id === energyGenerationId && !!selectedLocations.length && (
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>
                <FormattedMessage id="pages.project.details.meters" />
              </LabelStyled>
            </LabelContainerStyled>
            <MetersMultiSelect
              name="meters"
              accountId={accountId}
              value={selectedMeters}
              onChange={handleChange}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              locationsToFilter={selectedLocations as unknown as Option[]}
              defaultOptions={false}
              error={undefined}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between" data-cy="ProjectDetails__Input-owners">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.owner" />
            </LabelStyled>
          </LabelContainerStyled>
          <UserSelect
            accountId={accountId}
            onChange={handleChange}
            selectedOption={selectedOwners}
            value={selectedOwners}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.cost" />
            </LabelStyled>
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-cost"
            disabled={isSubmitting}
            onChange={handleChange}
            name="cost"
            value={project.cost}
            error={errors.cost}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-startDate">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.startDate" />
            </LabelStyled>
            <Icon
              color="#ffe5b4"
              icon={showStartDateTooltip ? 'times-circle' : 'info-circle'}
              onClick={() => setShowStartDateTooltip(!showStartDateTooltip)}
            />
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date: Date) => {
              handleChange({
                target: {
                  name: 'startDate',
                  value: date ? date.toISOString() : date
                }
              });
            }}
            name="startDate"
            selectedDate={project.startDate ? new Date(project.startDate) : project.startDate}
            disabledDatesBefore={baselineEndDate}
            error={errors.startDate}
            box
          />
        </ColStyled>
        {showStartDateTooltip ? (
          <Tooltip text="The date you would expect to start making savings from your project." height="60px" />
        ) : null}
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-endDate">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.endDate" />
            </LabelStyled>
            <Icon
              color="#ffe5b4"
              icon={showEndDateTooltip ? 'times-circle' : 'info-circle'}
              onClick={() => setShowEndDateTooltip(!showEndDateTooltip)}
            />
          </LabelContainerStyled>
          <DatePicker
            disabled={isSubmitting}
            onDayClick={(date: Date) => {
              handleChange({
                target: {
                  name: 'endDate',
                  value: date ? date.toISOString() : date
                }
              });
            }}
            name="endDate"
            selectedDate={project.endDate ? new Date(project.endDate) : project.endDate}
            disabledDatesBefore={project.startDate ? new Date(project.startDate) : project.startDate}
            error={errors.endDate}
            box
          />
        </ColStyled>
        {showEndDateTooltip ? (
          <Tooltip text="The date which you anticipate your planned savings to be realised." height="60px" />
        ) : null}
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-baselineStartDate">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.baselineStartDate" />
            </LabelStyled>
            <Icon
              color="#ffe5b4"
              icon={showBaselineDateTooltip ? 'times-circle' : 'info-circle'}
              onClick={() => setShowBaselineDateTooltip(!showBaselineDateTooltip)}
            />
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date: Date) => {
              handleChange({
                target: {
                  name: 'baselineStartDate',
                  value: date ? date.toISOString() : date
                }
              });
            }}
            name="baselineStartDate"
            selectedDate={project.baselineStartDate ? new Date(project.baselineStartDate) : project.baselineStartDate}
            error={errors.baselineStartDate}
            box
          />
        </ColStyled>
        {showBaselineDateTooltip ? (
          <Tooltip text="The annual period to compare project performance against." height="60px" />
        ) : null}
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              {selectedProjectCategory?.id === energyGenerationId && (
                <FormattedMessage id="pages.project.details.plannedBenefitGeneration" />
              )}
              {selectedProjectCategory?.id !== energyGenerationId && (
                <>
                  <FormattedMessage id="pages.project.details.plannedBenefit" />{' '}
                  {unitsByCategory[selectedProjectCategory?.name || 'default']}
                </>
              )}
            </LabelStyled>
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-plannedBenefit"
            disabled={isSubmitting}
            onChange={handleChange}
            name="plannedBenefit"
            value={project.plannedBenefit}
            error={errors.plannedBenefit}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.plannedCarbonChange" />
            </LabelStyled>
            <Icon
              color="#ffe5b4"
              icon={showEnergyGenerationSavingsPrompt ? 'times-circle' : 'info-circle'}
              onClick={() => setShowEnergyGenerationSavingsPrompt(!showEnergyGenerationSavingsPrompt)}
            />
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-plannedCO2eChange"
            disabled={isSubmitting}
            onChange={handleChange}
            name="plannedCO2eChange"
            value={project.plannedCO2eChange}
            error={errors.plannedCO2eChange}
            box
          />
        </ColStyled>
        {showEnergyGenerationSavingsPrompt ? (
          <Tooltip
            text="This figure should be positive. Negative values will be automatically converted to a positive value in the Project Dashboard."
            height="auto"
          />
        ) : null}
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.plannedCostChange" />
            </LabelStyled>
            <Icon
              color="#ffe5b4"
              icon={showCostSavingsPrompt ? 'times-circle' : 'info-circle'}
              onClick={() => setShowCostSavingsPrompt(!showCostSavingsPrompt)}
            />
          </LabelContainerStyled>
          <TextInput
            data-cy="ProjectDetails__Input-plannedCostChange"
            disabled={isSubmitting}
            onChange={handleChange}
            name="plannedCostChange"
            value={project.plannedCostChange}
            error={errors.plannedCostChange}
            box
          />
        </ColStyled>
        {showCostSavingsPrompt ? (
          <Tooltip
            text="This figure should be positive. Negative values will be automatically converted to a positive value in the Project Dashboard."
            height="auto"
          />
        ) : null}
      </Row>
      <Row container align="between" data-cy="ProjectDetails__Input-frequency">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>
              <FormattedMessage id="pages.project.details.frequency" />
            </LabelStyled>
          </LabelContainerStyled>
          <Select
            name="frequency"
            classPrefix="frequency-select"
            isDisabled={isSubmitting}
            onChange={handleChange}
            value={project.frequency}
            options={frequencyOptions}
            error={errors.frequency}
          />
        </ColStyled>
      </Row>
      <EvidencePicker
        evidenceDocuments={evidenceDocuments}
        setEvidenceDocuments={setEvidenceDocuments}
        evidenceNotes={evidenceNotes}
        setEvidenceNotes={setEvidenceNotes}
      />
    </div>
  );
};

export default ProjectDetails;
