import { Button } from 'rio-ui-components';
import styled from 'styled-components';

export const InfoButton = styled(Button)`
  align-self: flex-end;
  width: 200px;
  background-color: ${(props) => props.theme.colors.basic.white};
  color: ${(props) => props.theme.colors.primary.light.text};
  border-color: ${(props) => props.theme.colors.primary.light.text};
  border-width: 1px;
`;
