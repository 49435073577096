import { useMutation } from '@apollo/client';
import { useCallback, useReducer } from 'react';
import { LOG_IN } from '../../../queries/auth';
import { useIntl } from 'react-intl';
import { getLoginException } from '../utils';

const initialValue = {
  isLoading: false,
  email: {
    value: '',
    error: '',
  },
  password: {
    value: '',
    error: '',
  },
  remember: { value: false },
  formError: '',
  loginError: null,
  forgotPasswordModal: false,
};

export type LoginErrorResult = ReturnType<typeof getLoginException>;

type State = Omit<typeof initialValue, 'loginError'> & {
  loginError: LoginErrorResult | null;
};
type Action =
  | {
      type: 'form-value.change';
      name: string;
      value: string;
    }
  | {
      type: 'loading.set';
      value: boolean;
    }
  | {
      type: 'errors.set';
      errors: {
        email: string;
        password: string;
      };
    }
  | {
      type: 'loginError.set';
      error: LoginErrorResult;
    }
  | {
      type: 'forgotPassword.set';
      value: boolean;
    };
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'form-value.change':
      return { ...state, [action.name]: { ...[action.name], value: action.value } };
    case 'loading.set':
      return { ...state, isLoading: action.value };
    case 'errors.set':
      return {
        ...state,
        email: { ...state.email, error: action.errors.email },
        password: { ...state.password, error: action.errors.password },
      };
    case 'loginError.set':
      return {
        ...state,
        loginError: action.error,
      };
    case 'forgotPassword.set':
      return { ...state, forgotPasswordModal: action.value };
  }
}

export function useLoginForm() {
  const [login] = useMutation(LOG_IN);
  const [state, dispatch] = useReducer(reducer, initialValue);
  const intl = useIntl();
  return {
    handleChange: useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        dispatch({
          type: 'form-value.change',
          name,
          value,
        });
      },
      [dispatch]
    ),
    handleSubmit: useCallback(
      (event: React.FormEvent) => {
        event.preventDefault();
        const emailError = !state.email.value ? intl.formatMessage({ id: 'login.main.emailError' }) : '';
        const passwordError = !state.password.value ? intl.formatMessage({ id: 'login.main.passwordError' }) : '';

        dispatch({ type: 'errors.set', errors: { email: emailError, password: passwordError } });

        if (state.email.value && state.password.value) {
          dispatch({ type: 'loading.set', value: true });
          login({
            variables: {
              email: state.email.value,
              password: state.password.value,
            },
            onCompleted() {
              dispatch({ type: 'loading.set', value: false });
            },
            onError: (err) => {
              dispatch({ type: 'loading.set', value: false });
              dispatch({ type: 'loginError.set', error: getLoginException(err) });
            },
          });
        }
      },
      [state, dispatch, intl, login]
    ),
    handleForgotPassword: useCallback(() => {
      dispatch({ type: 'forgotPassword.set', value: true });
    }, [dispatch]),
    dismissForgotPassword: useCallback(() => {
      dispatch({ type: 'forgotPassword.set', value: false });
    }, [dispatch]),
    state,
  };
}
