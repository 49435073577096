import { gql } from '@apollo/client';

export const GET_TAGS_BY_ACCOUNT_ID = gql`
  query getTagsByAccountId($accountId: String!) {
    getTagsByAccountId(input: { accountId: $accountId, applicationId: "RIO" }) {
      tagName
      isSystemLevelTag
      legislationTag
      id
      locations {
        id
        name
      }
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createTag(
    $tagName: String!
    $accountId: String!
    $id: ID!
    $description: String
    $isSystemLevelTag: Boolean!
    $legislationTag: Boolean!
  ) {
    createTag(
      input: {
        tagName: $tagName
        id: $id
        applicationId: "RIO"
        accountId: $accountId
        description: $description
        isSystemLevelTag: $isSystemLevelTag
        legislationTag: $legislationTag
      }
    ) {
      id
      tagName
      description
      applicationId
      isSystemLevelTag
      legislationTag
      locations {
        id
        name
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag(
    $tagName: String!
    $accountId: String!
    $id: ID!
    $description: String
    $isSystemLevelTag: Boolean!
    $legislationTag: Boolean!
  ) {
    updateTag(
      input: {
        tagName: $tagName
        id: $id
        applicationId: "RIO"
        accountId: $accountId
        description: $description
        isSystemLevelTag: $isSystemLevelTag
        legislationTag: $legislationTag
      }
    ) {
      id
      tagName
      description
      applicationId
      isSystemLevelTag
      legislationTag
      locations {
        id
        name
      }
    }
  }
`;

export const UPDATE_LOCATION_TAG = gql`
  mutation updateLocationTag($id: ID!, $locationsId: [ID!]!, $accountId: ID!) {
    updateLocationTag(input: { tagId: $id, locationsId: $locationsId, accountId: $accountId })
  }
`;
