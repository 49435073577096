import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Button, Col, Search, Notification } from 'rio-ui-components';
import AccountSelector from '../../../components/AccountSelector';
import ContainerHeader from '../../../components/ContainerHeader';
import { usePermissions } from '../../../hooks';
import { AG_GRID_PARAMS } from '../../../constants/common';
import { ExportButton } from '../../../components/ExportButton/ExportButton';
import { ControlPanel } from '../../../components/ControlPanel';
import { Grid } from './Grid';
import EnergyMeterModal from './energyMeterModal';
import { UploadMetersModal } from './UploadMetersModal';
import { GET_ENERGY_SOURCE_BY_ACC_ID } from './index.queries';

const SelectContainer = styled.div`
  padding: ${(p) => p.theme.geometry.md.spacing};
`;

const SearchStyled = styled(Search)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ContainerHeaderStyled = styled(ContainerHeader)`
  & > div {
    width: auto;
  }
`;

const defaultModalState = {
  createModal: false,
  uploadModal: false,
  updateModal: false,
  deleteModal: false,
};

export const ConfigurationEnergyMetersContainer = (props) => {
  const [state, setState] = useState({
    accountId: props.accountId,
    modalState: defaultModalState,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [accountId, setAccountId] = useState(props.accountId);
  const [gridApi, setGridApi] = useState();
  const permissions = usePermissions();
  const client = useApolloClient();

  // Handlers
  const HandleAccountChange = (selected) => {
    setAccountId(selected.id);
  };

  const refreshGrid = () => {
    if (!!gridApi?.api && !!gridApi?.datasource) {
      gridApi.api.refreshServerSide({
        purge: true,
      });
    }
  };

  useEffect(() => {
    refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const HandleComplete = (message, color = 'success') => {
    setState((prev) => ({
      ...prev,
      notification: { message: message, color },
      modalState: defaultModalState,
      shouldRefetch: true,
    }));
    refreshGrid();
  };

  const ShowModal = (method, id) => {
    if (method === 'create') {
      setState((prev) => ({ ...prev, modalState: { createModal: true } }));
    }
    if (method === 'upload') {
      setState((prev) => ({ ...prev, modalState: { uploadModal: true } }));
    }
    if (method === 'update') {
      setState((prev) => ({ ...prev, modalState: { updateModal: id } }));
    }
    if (method === 'delete') {
      setState((prev) => ({ ...prev, modalState: { deleteModal: id } }));
    }
  };

  const DismissModal = () => {
    setState((prev) => ({ ...prev, modalState: defaultModalState }));
  };

  const onSearch = useCallback(() => {
    const instance = gridApi?.api.getFilterInstance('name');
    instance?.setModel({
      filterType: 'text',
      type: 'contains',
      filter: searchTerm,
    });
    gridApi?.api.onFilterChanged();
  }, [gridApi?.api, searchTerm]);

  const refreshGridCb = useMemo(() => debounce(onSearch, 250), [onSearch]);

  useEffect(() => {
    refreshGridCb();

    return () => {
      // clear filters after leaving the page
      localStorage.removeItem(AG_GRID_PARAMS);
    };
  }, [searchTerm, refreshGridCb]);

  const fetchRows = async (variables) => {
    const {
      data: {
        getEnergySourceByAccountIdAndType: { totalRows, rows },
      },
    } = await client.query({
      query: GET_ENERGY_SOURCE_BY_ACC_ID,
      variables: {
        ...variables,
      },
      fetchPolicy: 'network-only',
    });

    return {
      rows,
      totalRows,
    };
  };

  return (
    <Col name="ConfigurationEnergyMetersContainer" container fullHeight>
      {state.notification && (
        <Notification
          name="create-energyMeter__notification"
          show
          color={state.notification.color}
          onClose={() => {
            setState((prev) => ({ ...prev, notification: null }));
          }}
        >
          {state.notification.message}
        </Notification>
      )}
      {!!state.modalState.uploadModal ? (
        <UploadMetersModal onComplete={HandleComplete} accountId={accountId} onDismiss={DismissModal} />
      ) : (
        <EnergyMeterModal
          accountId={accountId}
          modalState={state.modalState}
          onDismiss={DismissModal}
          onComplete={HandleComplete}
        />
      )}
      <ContainerHeaderStyled
        name="ConfigurationEnergyMetersContainer__Controls"
        icon="bolt"
        iconColor="primary"
        title="Meters"
      >
        <SearchStyled
          name="ConfigurationEnergyMetersContainer__Controls__Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target?.value);
          }}
          hideButton
        />
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <Col item={1}>
            <Button
              name="ConfigurationEnergyMetersContainer__Controls__Button--add"
              color="primary"
              inline={true}
              onClick={() => ShowModal('create')}
            >
              + Add Meter
            </Button>
          </Col>
          <Col item={2}>
            <Button
              name="ConfigurationEnergyMetersContainer__Controls__Button--upload"
              color="secondary"
              inline={true}
              onClick={() => ShowModal('upload')}
            >
              Upload Meters
            </Button>
          </Col>
          <Col item={3}>
            <ExportButton
              fetchRows={fetchRows}
              gridApi={gridApi?.api}
              columnApi={gridApi?.columnApi}
              defaultExportFileName={'Meters Exports'}
              label="Export Data"
              columnsToSkip={['Actions']}
            />
          </Col>
        </ControlPanel>
      </ContainerHeaderStyled>
      {!!permissions.account.includes('getAll') && (
        <SelectContainer>
          <AccountSelector
            onChange={HandleAccountChange}
            value={accountId}
            name="ConfigurationEnergyMetersContainer__AccountSelector"
          />
        </SelectContainer>
      )}
      <Grid
        accountId={accountId}
        key={accountId}
        onEdit={(id) => ShowModal('update', id)}
        setGridApi={setGridApi}
        fetchRows={fetchRows}
      />
    </Col>
  );
};

export default ConfigurationEnergyMetersContainer;
