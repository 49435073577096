export const CARBON = 'Carbon';
export const ELECTRICITY = 'Electricity';
export const WATER = 'Water';
export const GAS = 'Gas';
export const WASTE = 'Waste';
export const TRANSPORT = 'Transport';
export const MATERIALS = 'Materials';

export const utilityIcons = {
  [WASTE]: 'trash',
  [CARBON]: 'recycle',
  [ELECTRICITY]: 'bolt',
  [GAS]: 'burn',
  [WATER]: 'tint',
  [TRANSPORT]: 'truck',
  [MATERIALS]: 'box'
};

export const meterTypeList = [ELECTRICITY, GAS, WATER];
