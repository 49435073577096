import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { styled, Text } from '@rio/ui-components';

import { FundsAssetsIcon } from '~/components/SvgIcons/FundsAssets';
import { Icons, DataItemProps, UploadDataItemsProps } from '../../types';
import { getIconFromName } from '../../Icons';

export const DataItemContainer = styled('div')`
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`;

export const DataItemsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const TextContainer = styled('div')`
  text-transform: uppercase;
  vertical-align: middle;
`;

export const IconTextContainer = styled(Text)`
  width: 80px;
  text-align: center;
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
  line-height: 16px;
  text-transform: none;
`;

export const ItemContainer = styled('div')`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  border: 1px solid #c7c5d0;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.sys.color.primary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;

const dataWithoutUploads = ['buildingEnergyRatings'];

const DataItem = ({ kind, to }: DataItemProps) => {
  const navigate = useNavigate();
  const icon = getIconFromName(kind);
  const buildingPath = './buildingEnergyRatings/view';
  const hasUploadNavigation = !dataWithoutUploads.includes(kind);
  const correctRout = hasUploadNavigation ? to : buildingPath;

  return (
    <DataItemContainer onClick={() => navigate(correctRout)}>
      <ItemContainer>{icon}</ItemContainer>
      <IconTextContainer typescale="label" size="small">
        <FormattedMessage id={`pages.data.subjects.${kind}`} />
      </IconTextContainer>
    </DataItemContainer>
  );
};

export const UploadDataItems = ({ activeAccessControls, isPortfolio }: UploadDataItemsProps) => {
  const navigate = useNavigate();
  const to = './investment/uploads';
  const portfolioItemTitle = 'Funds & Assets';

  if (isPortfolio) {
    return (
      <DataItemsContainer>
        <DataItemContainer onClick={() => navigate(to)}>
          <ItemContainer>
            <FundsAssetsIcon />
          </ItemContainer>
          <IconTextContainer typescale="label" size="small">
            {portfolioItemTitle}
          </IconTextContainer>
        </DataItemContainer>
      </DataItemsContainer>
    );
  }

  return (
    <DataItemsContainer>
      {activeAccessControls?.map((control, index) => (
        <DataItem key={`${control} - ${index}`} kind={control as Icons} to={`./${control}/uploads`} />
      ))}
    </DataItemsContainer>
  );
};
