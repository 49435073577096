import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { Select, OptionsProps } from '@rio/ui-components';
import { toSelectOptions } from '~/utils';
import { GET_CONTAINER_TYPE_SIZES } from './index.queries';

interface ContainerTypeSelectProps {
  value: string;
  onChange: (e: OptionsProps) => void;
  error?: string;
  label?: string;
}

export function ContainerTypeSelectV2({ value, onChange, error, label }: ContainerTypeSelectProps) {
  const {
    data,
    loading,
    error: queryError,
  } = useQuery<Pick<Query, 'getContainerTypeSizePage'>>(GET_CONTAINER_TYPE_SIZES);

  const options = toSelectOptions(data?.getContainerTypeSizePage?.typeSizes);

  return (
    <Select
      placeholder="Please select type of container"
      value={value}
      label={label}
      options={options}
      isLoading={loading}
      error={!!error || !!queryError}
      helperText={error || queryError?.message}
      onChange={(val) => onChange(val)}
    />
  );
}
