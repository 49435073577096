import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TaskSubject } from '@rio/rio-types';
import { Col, Button, Notification, Text, LoadingIndicator } from 'rio-ui-components';
import GET_TASKS_BY_ACCOUNT from '../../../graphql/queries/tasks/GetTasksByAccountId.query.graphql';
import { Grid } from './Grid';
import { CreateTaskModal } from './CreateTaskModal';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { usePermissions } from '../../../hooks/usePermissions';
import { useUserToken } from '../../../hooks/useUserToken';
import { LoadFailed } from '../../../components/Errors';
import { ClearButton } from '../../../components/ClearButton';
import { useAgGrid } from '../../../hooks';
import { mapSubjectsValuesToLabels } from '../utils';
import { useInitialFilterModel } from './useInitialFilterModel';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled(Col)`
  overflow: auto;
  height: 100%;
  padding: 0 ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const FilterContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextStyled = styled(Text)`
  cursor: pointer;
  margin-right: 15px;

  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'inherit')};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const HeaderButtonsContainerStyled = styled.div`
  display: flex;
  margin-left: auto;

  * {
    width: auto;
    min-width: 150px;
    margin-left: 1rem;
  }
`;

const TasksGridContainer = ({ hasProjectsAccess = false }) => {
  const agGrid = useAgGrid();
  const accountId = useCurrentAccountId();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [notification, setNotification] = useState(null);
  const permissions = usePermissions();
  const { token } = useUserToken();
  const canAccessAccountTasks = permissions.task.find((action) => action === 'getAll' || action === 'getParent');
  const { data, loading, refetch, error } = useQuery(GET_TASKS_BY_ACCOUNT, {
    variables: { accountId, userId: token.sub }
  });
  const { showCompleted, setShowCompleted, showMine, setShowMine, showHighPriority, setShowHighPriority } =
    useInitialFilterModel();

  const tasks = data?.getTasksByAccountId || [];

  const sorted = data ? mapSubjectsValuesToLabels(tasks) : [];

  const filterMine = () => {
    if (showMine) {
      agGrid.api.setFilterModel(null);
      setShowMine(false);

      return;
    }

    const newFilter = {
      owners: {
        type: 'set',
        values: [`${token.given_name} ${token.family_name}`]
      }
    };
    agGrid.api.setFilterModel(newFilter);
    setShowMine(true);
  };

  const filterCompleted = () => {
    if (showCompleted) {
      agGrid.api.setFilterModel(null);
      setShowCompleted(false);

      return;
    }

    const newFilter = {
      status: {
        type: 'set',
        values: ['Completed']
      }
    };
    agGrid.api.setFilterModel(newFilter);
    setShowCompleted(true);
  };

  const filterHighPriority = () => {
    if (showHighPriority) {
      agGrid.api.setFilterModel(null);
      setShowHighPriority(false);

      return;
    }

    const newFilter = {
      priority: {
        type: 'set',
        values: ['HIGH']
      }
    };

    agGrid.api.setFilterModel(newFilter);
    setShowHighPriority(true);
  };

  return (
    <ContainerStyled name="Tasks">
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {notification && (
        <Notification name="Task__Notification" show color={notification.colour} onClose={() => setNotification(null)}>
          {notification.message}
        </Notification>
      )}
      {showCreateModal && (
        <CreateTaskModal
          onComplete={setNotification}
          accountId={accountId}
          onDismiss={() => setShowCreateModal(false)}
          sourcePage="TASKS"
          disabled={{ [TaskSubject.Aspects]: true }}
          hasProjectsAccess={hasProjectsAccess}
        />
      )}
      {error ? (
        <LoadFailed error={error} message={error.message} retry={() => refetch({ accountId, userId: token.sub })} />
      ) : (
        !loading && (
          <ContentContainer container item>
            <HeaderContainer>
              <FilterContainerStyled>
                <TextContainerStyled>
                  <TextStyled onClick={filterCompleted} isActive={showCompleted}>
                    {showCompleted ? 'Hide Completed' : 'Show Completed'}
                  </TextStyled>
                  {canAccessAccountTasks ? (
                    <TextStyled onClick={filterMine} isActive={showMine}>
                      {showMine ? 'Show All Tasks' : 'Show My Tasks'}
                    </TextStyled>
                  ) : (
                    ''
                  )}
                  <TextStyled onClick={filterHighPriority} isActive={showHighPriority}>
                    {showHighPriority ? 'Show All Priorities' : 'Show High Priority'}
                  </TextStyled>
                </TextContainerStyled>
              </FilterContainerStyled>

              <HeaderButtonsContainerStyled>
                <ClearButton
                  gridApi={agGrid.api}
                  columnApi={agGrid.columnApi}
                  onClick={() => {
                    setShowMine(false);
                    setShowCompleted(false);
                    setShowHighPriority(false);
                  }}
                />

                {!!permissions.task.find((action) => action.startsWith('create')) && (
                  <Button
                    data-cy="TasksHome__Button--create"
                    inline
                    color="primary"
                    onClick={() => setShowCreateModal(true)}
                  >
                    + Create Task
                  </Button>
                )}
              </HeaderButtonsContainerStyled>
            </HeaderContainer>
            <Grid tasks={sorted} agGrid={agGrid} />
          </ContentContainer>
        )
      )}
    </ContainerStyled>
  );
};

export default TasksGridContainer;
