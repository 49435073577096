import { useApolloClient } from '@apollo/client';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Col } from 'rio-ui-components';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { DocumentRecord, QueryGetDocumentsPageArgs } from '@rio/rio-types';
import { FolderGrid } from './Folders';
import { Grid } from './Documents';
import { GET_DOCUMENTS_PAGE } from './index.queries';
import { SearchHeader } from './SearchHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import NoResults from '../../../components/NoResults';
import UserBannersSection from '../../../components/UserBannersSection';
import { useCurrentAccountId } from '../../../hooks';
import { LoadFailed } from '../../../components/Errors';
import { Folder } from '../types';
import { getAllFilters } from '../utils';

const Explorer = styled(Col)`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;
const SeparatoryLine = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.overlay.light.text};
  margin: 16px 0;
`;

interface DocumentExplorerProps {
  showGrid?: boolean;
  isManagementSystem?: boolean;
  folders?: Folder[];
  documentsExist?: boolean;
  searchValue: string;
  library?: string;
  managementSystemId?: string;
  managementSystemChapterId?: string;
  category?: string;
  banners?: object[];
  error?: Error | string | null;
  searchPlaceholder?: string;
  loading?: boolean;
  retry?: () => void;
  setGridApi?: Dispatch<SetStateAction<{ api: GridApi; columnApi: ColumnApi } | undefined>>;
  onDocumentSelect?: (document: DocumentRecord) => void;
  onSearch?: (searchValue: string) => void;
}
export function DocumentExplorer({
  isManagementSystem,
  library,
  category,
  folders = [],
  documentsExist = false,
  searchValue = '',
  searchPlaceholder,
  loading,
  banners = [],
  error,
  retry,
  onSearch = () => {},
  setGridApi = () => {},
  showGrid,
  managementSystemId,
  managementSystemChapterId,
}: DocumentExplorerProps) {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const noResults = !loading && !folders.length && !documentsExist && searchValue;

  return (
    <Explorer container item>
      {!showGrid && (
        <SearchHeader
          search={searchValue}
          onSearchValueChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            onSearch(value);
          }}
          placeholder={searchPlaceholder}
        />
      )}
      <UserBannersSection id="documentBanners" staticBanners={banners} />
      {error ? (
        <LoadFailed name="Something went wrong" error={error} retry={retry} />
      ) : loading ? (
        <ContainerLoadingIndicator name="DocumentFiles__Loading" />
      ) : noResults ? (
        <NoResults id="documents" title={`No Results found for ${searchValue || 'your search.'}`} />
      ) : (
        <>
          <FolderGrid folders={folders} />
          {documentsExist && !!folders.length && (searchValue || managementSystemId) && <SeparatoryLine />}
          {((documentsExist && (searchValue || isManagementSystem)) || showGrid) && (
            <Grid
              library={library}
              category={category}
              accountId={accountId}
              key={accountId}
              setGridApi={setGridApi}
              fetchRows={async (vars: QueryGetDocumentsPageArgs) => {
                const variables = { ...vars };
                if (!variables.filters) {
                  variables.filters = {};
                }
                const extraFilters = getAllFilters(
                  searchValue,
                  library,
                  category,
                  managementSystemId,
                  managementSystemChapterId
                );

                const { filters } = variables;

                if (!!extraFilters.set) {
                  if (!filters?.set) {
                    filters.set = [];
                  }
                  filters.set.push(...extraFilters.set);
                }

                if (!!extraFilters.text) {
                  if (!filters?.text) {
                    filters.text = [];
                  }
                  filters.text.push(...extraFilters.text);
                }

                const {
                  data: {
                    getDocumentsPage: { totalRows, rows },
                  },
                } = await client.query({
                  query: GET_DOCUMENTS_PAGE,
                  variables,
                  fetchPolicy: 'network-only',
                });
                return {
                  rows,
                  totalRows,
                };
              }}
            />
          )}
        </>
      )}
    </Explorer>
  );
}
