import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/transport/GetAllTransportFuelTypes.query.graphql';

export function useGetTransportFuelTypes() {
  const result = useQuery(QUERY, { notifyOnNetworkStatusChange: true });

  return {
    data: result?.data?.getAllTransportFuelTypes,
    refetch: (variables) => result.refetch(variables)
  };
}
