import { useCallback, useMemo } from 'react';
import { SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { booleanValueFormatter, dateValueFormatter, getAgRowId, gridValueFormatter } from '~/utils';
import { useGetBuildingEnergyRatingsFilterQuery } from './useGetBuildingEnergyRatingsFilterQuery';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useBuildingEnergyRatingsGridConfig() {
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const [getBuildingEnergyRatingsFilter] = useGetBuildingEnergyRatingsFilterQuery();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const field = params.colDef.refData?.filterBy || params.colDef.field!;
        const { data } = await getBuildingEnergyRatingsFilter({
          variables: {
            accountId,
            field,
          },
        });
        if (data?.getBuildingEnergyRatingsFilter) {
          params.success(data.getBuildingEnergyRatingsFilter as string[]);
        }
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [accountId, showNotification, getBuildingEnergyRatingsFilter]
  );
  return useMemo(
    () => ({
      getRowId: getAgRowId('id'),
      columnDefs: [
        {
          headerName: 'Location',
          field: 'location.name',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Block',
          field: 'block',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Location Code',
          field: 'location.code',
          refData: {
            filterBy: 'locationCode',
          },
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          ...OccupationEndDateGridColumn,
          field: 'location.endDate',
        },
        {
          headerName: 'Rating',
          field: 'rating',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Score',
          field: 'score',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Type of heating fuel',
          field: 'heatingFuelType',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Data Provider',
          field: 'dataProvider',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Department(s)',
          field: 'location.departments',
          refData: {
            filterBy: 'location.departments.name',
          },
          valueFormatter: ({ value }: ValueFormatterParams) => {
            if (Array.isArray(value)) {
              return value.map(({ name }) => name).join(', ');
            }
            return '';
          },
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Tag(s)',
          field: 'location.tags',
          refData: {
            filterBy: 'location.tags.tagName',
          },
          valueFormatter: ({ value }: ValueFormatterParams) => {
            if (Array.isArray(value)) {
              return value
                .filter(Boolean)
                .map(({ tagName }) => tagName)
                .join(', ');
            }
            return '';
          },
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Country',
          field: 'location.address.country.name',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Region',
          field: 'location.region.name',
          filterParams: {
            values: getFilterValues,
          },
        },
        {
          headerName: 'Nominated date',
          field: 'nominatedDate',
          filter: 'agDateColumnFilter',
          valueFormatter: dateValueFormatter,
        },
        {
          headerName: 'Issue date',
          field: 'issueDate',
          filter: 'agDateColumnFilter',
          valueFormatter: dateValueFormatter,
        },
        {
          headerName: 'Valid to',
          field: 'certificateValidTo',
          filter: 'agDateColumnFilter',
          valueFormatter: dateValueFormatter,
        },
        {
          headerName: 'Certificate Reference number',
          field: 'certificateReferenceNumber',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Building environment',
          field: 'buildingEnvironment',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'GIA',
          field: 'gia',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Resurveyed',
          field: 'resurveyed',
          filter: false,
          valueFormatter: booleanValueFormatter,
        },
        {
          headerName: 'Other Reference',
          field: 'otherReference',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Comments',
          field: 'comments',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Type',
          field: 'type',
          filterParams: {
            values: getFilterValues,
          },
        },
      ],
    }),
    [getFilterValues]
  );
}
