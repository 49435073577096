import { gql } from '@apollo/client';
import INITIATE_DATA_UPLOAD from '../../graphql/mutations/InitialDataUpload.mutation.graphql';
import UPLOAD_DATA from '../../graphql/mutations/UploadData.mutation.graphql';

export { INITIATE_DATA_UPLOAD, UPLOAD_DATA };

export const GET_TRANSPOSITIONS_BY_ACC_ID = gql`
  query GetTranspositionByAccountId($accId: ID!, $type: TransactionType!) {
    getTranspositionsByAccountId(input: { accountId: $accId, includeSystemLevel: true, type: $type }) {
      id
      transpositionName
      isHalfHourly
    }
  }
`;

export const GET_TRANSPOSITION_BY_ID = gql`
  query GetTranspositionById($id: ID!) {
    getTranspositionById(id: $id) {
      id
      transpositionName
      isHalfHourly
      columnMapper {
        mappings {
          columnName
          transposition
        }
        ignored
      }
    }
  }
`;

export const CREATE_TRANSPOSITION = gql`
  mutation CreateTransposition(
    $id: ID!
    $name: String!
    $mappings: [MappingInput!]!
    $ignored: [String!]!
    $accountId: String
    $type: TransactionType!
  ) {
    createTransposition(
      input: {
        id: $id
        accountId: $accountId
        transpositionName: $name
        columnMapper: { mappings: $mappings, ignored: $ignored }
        type: $type
      }
    ) {
      id
      transpositionName
      columnMapper {
        mappings {
          columnName
          transposition
        }
        ignored
      }
    }
  }
`;
