import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useBuildingEnergyRatingsGridConfig } from '../BuildingEnergyRatings/useGridConfig';
import { useGetBuildingEnergyRatingsByAccountId } from '../BuildingEnergyRatings/useGetBuildingEnergyRatingsByAccountIdQuery';

const breadcrumbs = [{ label: 'Data', to: '..' }, { label: 'Building energy ratings' }];

export const BuildingEnergyRatingsGrid = () => {
  const config = useBuildingEnergyRatingsGridConfig();
  const [getBuildingEnergyRatingsByAccountId] = useGetBuildingEnergyRatingsByAccountId();

  const fetchRows = useCallback(
    async (variables) => {
      const { data } = await getBuildingEnergyRatingsByAccountId({
        variables,
      });
      if (data) {
        const {
          getBuildingEnergyRatingsByAccountId: { totalRows, rows },
        } = data;
        return {
          rows,
          totalRows,
        };
      }
      throw new Error("Couldn't fetch building energy ratings");
    },
    [getBuildingEnergyRatingsByAccountId]
  );

  return (
    <TransactionGrid
      title="Your building energy ratings data"
      defaultExportFileName="building-energy-ratings"
      breadcrumbs={breadcrumbs}
      config={config}
      fetchRows={fetchRows}
    />
  );
};
