import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import { ICellRendererParams } from 'ag-grid-community';
import { Aspect } from '@rio/rio-types';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditLinkStyled = styled.a`
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

interface ActionCellProps extends ICellRendererParams {
  onEdit: (id: string) => void;
  onCopy: (aspect: Aspect) => void;
}

export const ActionCell = ({ data: aspect, onEdit, onCopy }: ActionCellProps) => (
  <GridCellContainer>
    <EditLinkStyled onClick={() => onEdit(aspect?.id)}>
      <Icon name={`account-row__edit--${aspect?.id}`} title="Edit" icon="pencil-alt" />
    </EditLinkStyled>
    <EditLinkStyled onClick={() => onCopy(aspect)}>
      <Icon name={`account-row__copy--${aspect?.id}`} title="Duplicate" icon="copy" />
    </EditLinkStyled>
  </GridCellContainer>
);
