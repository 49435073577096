import { gql } from '@apollo/client';

export const SURVEY_TEMPLATE_SUBSCRIPTION_FRAGMENT = gql`
  fragment SurveyTemplateSubscriptionFields on SurveyTemplateSubscription {
    account {
      id
      name
    }
    template {
      id
      name
    }
  }
`;

export const GET_SURVEY_TEMPLATE_SUBSCRIPTIONS = gql`
  {
    getSurveyTemplateSubscriptions {
      ...SurveyTemplateSubscriptionFields
    }
  }
  ${SURVEY_TEMPLATE_SUBSCRIPTION_FRAGMENT}
`;

export const CREATE_SURVEY_TEMPLATE_SUBSCRIPTION = gql`
  mutation CreateSurveyTemplateSubscription($accountId: ID!, $templateId: ID!) {
    createSurveyTemplateSubscription(accountId: $accountId, templateId: $templateId) {
      ...SurveyTemplateSubscriptionFields
    }
  }
  ${SURVEY_TEMPLATE_SUBSCRIPTION_FRAGMENT}
`;

export const DELETE_SURVEY_TEMPLATE_SUBSCRIPTION = gql`
  mutation DeleteSurveyTemplateSubscription($accountId: ID!, $templateId: ID!) {
    deleteSurveyTemplateSubscription(accountId: $accountId, templateId: $templateId) {
      ...SurveyTemplateSubscriptionFields
    }
  }
  ${SURVEY_TEMPLATE_SUBSCRIPTION_FRAGMENT}
`;
