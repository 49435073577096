import { useCallback, useState } from 'react';
import { Col, Button, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { SurveyTemplateSubscription } from '@rio/rio-types';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContainerHeader from '../../../components/ContainerHeader';
import { CreateSubscription } from './CreateSubscription';
import { useGet, useDelete } from './queries';
import { LoadFailed } from '../../../components/Errors';
import { GridOfSubscriptions } from './GridOfSubscriptions';
import { ConfirmActionModal } from '../../../components/ConfirmAction';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ContainerHeaderStyled = styled(ContainerHeader)`
  justify-content: space-between;
`;

export const ConfigurationSurveyTemplateSubscriptionsContainer = () => {
  const { data, loading, error, refetch, networkStatus } = useGet();
  const [createModalShown, setCreateModalShown] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<SurveyTemplateSubscription | null>(null);
  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const openDeleteConfirmationModal = useCallback(
    (subscription: SurveyTemplateSubscription) => setSubscriptionToDelete(subscription),
    []
  );
  const closeDeleteConfirmationModal = useCallback(() => setSubscriptionToDelete(null), []);
  const [deleteSubscription] = useDelete();
  const handleDeleteSubscription = useCallback(async () => {
    if (!subscriptionToDelete) {
      return;
    }
    await deleteSubscription({
      variables: {
        accountId: subscriptionToDelete?.account.id,
        templateId: subscriptionToDelete?.template.id
      },
      onCompleted: () => closeDeleteConfirmationModal()
    });
  }, [deleteSubscription, subscriptionToDelete, closeDeleteConfirmationModal]);
  return (
    <Col container fullHeight>
      <ContainerHeaderStyled
        name="ConfigurationSubscriptionsContainer"
        icon="briefcase"
        iconColor="primary"
        title="Template Subscriptions"
      >
        <Col span={4} container item>
          <ButtonStyled size="md" color="primary" inline onClick={toggleCreateModal}>
            + Add Template Subscription
          </ButtonStyled>
        </Col>
      </ContainerHeaderStyled>
      {((loading && networkStatus !== 3) || networkStatus === 4) && <ContainerLoadingIndicator />}
      {error && <LoadFailed error={error} retry={refetch} />}
      {!error && !loading && data?.getSurveyTemplateSubscriptions && (
        <GridOfSubscriptions rows={data?.getSurveyTemplateSubscriptions} onDelete={openDeleteConfirmationModal} />
      )}
      {subscriptionToDelete && (
        <ConfirmActionModal
          confirmButtonTitle="Unsubscribe"
          cancelButtonTitle="Cancel"
          headingText={`Are you sure you want to unsubscribe ${subscriptionToDelete.account.name} from ${subscriptionToDelete.template.name}?`}
          bodyText="The subscription can be re-created at anytime"
          onConfirmAction={handleDeleteSubscription}
          onDismiss={closeDeleteConfirmationModal}
          onCancelAction={closeDeleteConfirmationModal}
          show
        />
      )}
      {createModalShown && (
        <Modal size="md" height="50vh" onDismiss={toggleCreateModal} dismissable show>
          <CreateSubscription onDismiss={toggleCreateModal} />
        </Modal>
      )}
    </Col>
  );
};
