import { TransactionType } from '@rio/rio-types';
import { UploadGridV2 } from '~/components/UploadGrid/V2UploadGrid';
import { useUploadsGridConfig } from './useUploadsGridConfig';

const breadcrumbs = [
  { label: 'Data', to: `..` },
  { label: 'Hotel Stays', to: '/data/hotelStays/view' },
  { label: 'Uploads' },
];

export const V2HotelStaysUploadGrid = () => {
  const config = useUploadsGridConfig();

  return (
    <UploadGridV2
      gridKey="hotelStaysUploadGridV2"
      title="Here's your upload history"
      breadcrumbs={breadcrumbs}
      uploadLink="../hotelStays/uploads"
      config={config}
      dataType={TransactionType.Hotelstays}
    />
  );
};
