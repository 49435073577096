"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.textCss = void 0;
const styles_1 = require("@mui/material-next/styles");
const textCss = (theme, { typescale = 'body', size = 'medium', fontStyle = 'normal', oneLine, weight, textAlign, }) => `
  font-style: ${fontStyle};
  font-size: ${theme.sys.typescale[typescale]?.[size]?.size}px;
  font-weight: ${weight || theme.sys.typescale[typescale]?.[size]?.weight};
  line-height: ${theme.sys.typescale[typescale]?.[size]?.lineHeight}px;
  letter-spacing: ${theme.sys.typescale[typescale]?.[size]?.tracking}px;
  font-family: ${theme.sys.typescale[typescale]?.[size]?.family};
  text-align: ${textAlign || 'left'};
  color: ${theme.sys.color.onSurface};
  ${oneLine
    ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
    : ''}
`;
exports.textCss = textCss;
const componentProps = ['typescale', 'size', 'fontStyle', 'oneLine'];
const shouldForwardProp = (prop) => !componentProps.includes(prop);
exports.Text = (0, styles_1.styled)('p', {
    shouldForwardProp,
}) `
  ${({ theme, ...rest }) => (0, exports.textCss)(theme, rest)}
`;
