import React from 'react';
import { TextLink } from 'rio-ui-components';

interface AspectCreatedNotificationProp {
  isUpdate: boolean;
  aspectId: string;
}

export const AspectCreatedNotification = ({ isUpdate, aspectId }: AspectCreatedNotificationProp) => (
  <>
    Aspect successfully {isUpdate ? 'updated' : 'created'}.
    {!isUpdate && (
      <>
        <span>You can check it </span>
        <TextLink href={`/governance/environmental-aspects/${aspectId}`} target="_blank" noreferrer noopener inline>
          here
        </TextLink>
      </>
    )}
  </>
);
