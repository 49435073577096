import { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon } from 'rio-ui-components';
import { Filters, SortCommand } from '@rio/rio-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import {
  MainContainer,
  TopInfoContainer,
  AboutContainer,
  AboutIconContainer,
  HeaderContainer,
  AccordionContainer,
  AccordionSummaryMissingTextContainer,
} from './Elements';
import DataTrackerValidationResultsGrid from './grids/DataTrackerValidationResults';
import { AnomaliesAccordion } from './Anomalies';

const ListItemStyled = styled('li')`
  margin-left: 15px;
`;

const ListWrapper = styled('div')`
  margin-left: 20px;
`;

const AccordionSection = () => {
  return (
    <div>
      <AccordionContainer>
        <Accordion>
          <AccordionSummary expandIcon={<Icon icon="chevron-down" />} aria-controls="panel1a-content">
            <AccordionSummaryMissingTextContainer>
              <FormattedMessage id="pages.data.overview.missingTransactions" />
            </AccordionSummaryMissingTextContainer>
          </AccordionSummary>
          <AccordionDetails>
            <DataTrackerValidationResultsGrid />
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
      <AnomaliesAccordion />
    </div>
  );
};

export type FetchRowsParameters = {
  accountId: string;
  isIgnored?: boolean;
  offset?: number;
  limit?: number;
  sort?: SortCommand[];
  filters?: Filters;
};

const StatusContainer = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <MainContainer>
      <TopInfoContainer>
        <HeaderContainer>
          <FormattedMessage id="pages.data.overview.dataCollection" />
        </HeaderContainer>
        <AboutContainer aria-describedby={id} onClick={handleClick}>
          <AboutIconContainer>
            <Icon icon="info-circle" />
          </AboutIconContainer>
          <FormattedMessage id="pages.data.overview.about" />
        </AboutContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ marginRight: '50px', marginTop: '10px' }}
        >
          <Typography sx={{ p: 2, maxWidth: '900px' }}>
            The Rio platform automatically performs checks against data that is being uploaded alongside identifying
            missing data from your suppliers, locations and meters.
            <p>Checks performed include:</p>
            <ListWrapper>
              <ul>
                <li>Identifying missing data,or rows of data based on:</li>
                <ul>
                  <ListItemStyled>Supplier</ListItemStyled>
                  <ListItemStyled>Locations</ListItemStyled>
                  <ListItemStyled>Meters</ListItemStyled>
                </ul>
              </ul>
              <ul>
                <li>Finding locations with data being uploaded outside of their open/closed dates</li>
                <li>Finding meters with zero data being provided</li>
                <li>
                  Time-period analysis, uploaded data is checked against the same period for previous months or previous
                  year (for seasonality)
                </li>
              </ul>
            </ListWrapper>
          </Typography>
        </Popover>
      </TopInfoContainer>
      <AccordionSection />
    </MainContainer>
  );
};

export default injectIntl(StatusContainer);
