import { styled, Text, Grid } from '@rio/ui-components';

export const GridStyled = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckboxWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;
export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.sys.color.error};
`;
