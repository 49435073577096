import { useState, useCallback } from 'react';
import { Model } from 'survey-core';
import { ReceivedSurvey } from '@rio/rio-types';
import { styled, Text, Icons } from '@rio/ui-components';
import { SurveyLayout } from './SurveyLayout';
import 'survey-core/defaultV2.min.css';

const LockedRow = styled('div')`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  align-items: center;
  padding-top: 12px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const SpeechRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  align-items: flex-end;
  padding-right: 24px;
`;

interface TooltipProps {
  text: string;
}

const Tooltip = ({ text }: TooltipProps) => {
  const TooltipContainer = styled('div')`
    display: flex;
    position: absolute;
    bottom: 35px;
  `;

  const TooltipText = styled(Text)`
    position: relative;
    background-color: ${({ theme }) => theme.sys.color.secondary};
    color: ${({ theme }) => theme.sys.color.onSecondary};
    border-radius: 6px;
    padding: 15px 15px;
    width: 250px;
    word-break: break-word;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      border: 10px solid transparent;
      border-top-color: ${({ theme }) => theme.sys.color.secondary};
      bottom: -20px;
      left: calc(80% - 14px);
      transform: translateX(-50%);
    }
  `.withComponent('span');

  return (
    <TooltipContainer>
      <TooltipText typescale="body" size="small">
        {text}
      </TooltipText>
    </TooltipContainer>
  );
};

type SurveyWorksheetProps = {
  model: Model | null;
  onDismiss: () => void;
  saveSurvey: () => void;
  expanded: boolean;
  survey: ReceivedSurvey;
  children?: React.ReactNode;
  isLocked?: boolean;
  editingUser?: string;
};

export const SurveyWorksheet = ({
  model,
  expanded,
  onDismiss,
  saveSurvey,
  survey,
  isLocked,
  editingUser,
  children,
}: SurveyWorksheetProps) => {
  const [showLockedToolTip, setShowLockedToolTip] = useState(false);

  const handleValueChanged = useCallback(() => {
    saveSurvey();
  }, [saveSurvey]);

  return (
    <SurveyLayout
      readonly={false}
      model={model}
      expanded={expanded}
      onDismiss={onDismiss}
      survey={survey}
      onValueChanged={handleValueChanged}
      header={
        isLocked && (
          <SpeechRow>
            <LockedRow onClick={() => setShowLockedToolTip(!showLockedToolTip)}>
              {showLockedToolTip ? (
                <Tooltip
                  text={`You cannot edit this survey simultaneously. Editing will become available once ${editingUser} exits the survey.`}
                />
              ) : null}
              <Icons.Lock fontSize="small" />
              <Text typescale="body" size="medium">
                Locked
              </Text>
            </LockedRow>
          </SpeechRow>
        )
      }
    >
      {children}
    </SurveyLayout>
  );
};
