import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, styled } from '@rio/ui-components';
import React, { useState } from 'react';
import { Modal } from 'rio-ui-components';
import { LearnContentType } from '@rio/rio-types';
import { GET_COURSE_BY_ID } from '~/containers/LearnContainer/Courses/index.queries';
import { COMPLETE_EVALUATION } from '~/components/ViewCourseButton/index.queries';
import { LearnEmbeddedViewer, useDownloadPdf } from '~/components';
import { useUserToken, useCurrentAccountId, useNotification } from '~/hooks';
import { Evaluation } from './Evaluation';

type TNotification = { color: string; message: string };

const SHOW_PRE_ASSESSMENT = 0;
const SHOW_EMBEDDED_VIEWER = 1;
const SHOW_POST_ASSESSMENT = 2;
const SHOW_REACTION_EVALUATION = 3;

const ViewerCol = styled('div')`
  height: 100%;
`;

const ViewerRow = styled('div')`
  display: flex;
  flex: auto;
  height: auto;

  div:has(> video) {
    width: 100%;
  }

  iframe {
    min-height: 90vh;
  }
`;

const ButtonsRow = styled('div')`
  justify-content: space-between;
  display: flex;
  gap: 16px;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  flex-basis: 50%;
`;

const style = {
  display: 'block',
};

const ViewCourseModal = ({ onDismiss, course, link, courseRefetch = () => void 0 }) => {
  const { showNotification } = useNotification();
  const { token } = useUserToken();
  const accountId = useCurrentAccountId();
  const downloadPdf = useDownloadPdf(course, link, courseRefetch);
  const preAssessmentCompleted = course.assessment?.questions?.every?.((q) => q.answers?.length >= 1);

  const [step, setStep] = useState(course.assessment ? SHOW_PRE_ASSESSMENT : SHOW_EMBEDDED_VIEWER);
  const [notification, setNotification] = useState<TNotification | null>(null);
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const [completeEvaluation, { loading }] = useMutation(COMPLETE_EVALUATION, {
    onError: (err) => {
      setNotification({
        color: 'danger',
        message: err.message,
      });
    },
  });

  const goToCourse = async () => {
    if (course.type === LearnContentType.Pdf) {
      if (!pdfDownloaded) {
        await downloadPdf();
        setPdfDownloaded(true);
      } else {
        setStep(SHOW_POST_ASSESSMENT);
      }
    } else {
      setStep(SHOW_EMBEDDED_VIEWER);
    }
  };

  const updateAnswers = useCallback(
    (answers, evaluationType) => (cache) => {
      cache.writeQuery({
        query: GET_COURSE_BY_ID,
        variables: {
          accountId,
          id: course.id,
          userId: token.sub,
          userIdNullable: token.sub,
        },
        data: {
          getCourseById: {
            ...course,
            [evaluationType]: {
              ...course[evaluationType],
              questions: course[evaluationType].questions.map((q) => {
                const newAnswer = answers.find((a) => a.questionId === q.id);
                const questionAnswers = q.answers || [];
                return {
                  ...q,
                  answers: questionAnswers.concat([
                    {
                      givenAt: new Date().toISOString(),
                      value: newAnswer.value,
                      __typename: 'Answer',
                    },
                  ]),
                };
              }),
            },
          },
        },
      });
    },
    [accountId, course, token]
  );

  const evolutionSubmit = useCallback(
    async (answers, type) => {
      await completeEvaluation({
        variables: {
          answers,
          userId: token.sub,
          courseId: course.id,
          evaluationId: course.reaction.id,
        },
        update: updateAnswers(answers, type),
      });
    },
    [completeEvaluation, course, token.sub, updateAnswers]
  );

  return (
    <Modal
      height="auto"
      maxHeight="100%"
      minHeight={'-webkit-fill-available'}
      span={12}
      onDismiss={onDismiss}
      style={style}
      show
    >
      {step === SHOW_PRE_ASSESSMENT && (
        <Evaluation
          type="Pre-assessment"
          goNextButtonTitle={pdfDownloaded ? 'Go to Post-assessment' : 'Go to Course'}
          evaluation={course.assessment}
          onSubmit={(answers) => {
            evolutionSubmit(answers, 'assessment');
            courseRefetch();
          }}
          onGoNext={goToCourse}
          isSubmitting={loading}
          reevaluation={false}
        />
      )}
      {step === SHOW_EMBEDDED_VIEWER && (
        <ViewerCol>
          <ViewerRow>
            <LearnEmbeddedViewer
              course={course}
              link={link}
              courseRefetch={courseRefetch}
              onCompleted={() => {
                if (course.assesment && preAssessmentCompleted) {
                  setStep(SHOW_POST_ASSESSMENT);
                }
              }}
            />
          </ViewerRow>
          {course.assessment && preAssessmentCompleted && (
            <ButtonsRow>
              <ButtonStyled
                variant="filled"
                onClick={() => {
                  setStep(SHOW_POST_ASSESSMENT);
                }}
              >
                Go to Post-assessment
              </ButtonStyled>
              <ButtonStyled onClick={onDismiss}>Close</ButtonStyled>
            </ButtonsRow>
          )}
          {!course.assessment && course.reaction && (
            <ButtonsRow>
              <ButtonStyled
                variant="filled"
                onClick={() => {
                  setStep(SHOW_REACTION_EVALUATION);
                }}
              >
                Go to Reaction
              </ButtonStyled>
              <ButtonStyled onClick={onDismiss}>Close</ButtonStyled>
            </ButtonsRow>
          )}
        </ViewerCol>
      )}
      {step === SHOW_POST_ASSESSMENT && (
        <Evaluation
          type="Post-assessment"
          goNextButtonTitle={course.reaction ? 'Go to reaction' : 'Close'}
          evaluation={course.assessment}
          onSubmit={(answers) => evolutionSubmit(answers, 'assessment')}
          onGoNext={() => {
            if (course.reaction) {
              setStep(SHOW_REACTION_EVALUATION);
            } else {
              onDismiss();
            }
          }}
          isSubmitting={loading}
          reevaluation={true}
        />
      )}
      {step === SHOW_REACTION_EVALUATION && (
        <Evaluation
          type="Reaction"
          goNextButtonTitle="Close"
          evaluation={course.reaction}
          onSubmit={(answers) => evolutionSubmit(answers, 'reaction')}
          isSubmitting={loading}
          onGoNext={onDismiss}
          reevaluation={false}
        />
      )}
      {notification && showNotification(notification.message)}
    </Modal>
  );
};

export { ViewCourseModal };
