import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useRefrigerantGridConfig } from '../RefrigerantsContainer/TransactionsContainer/useRefrigerantGridConfig';
import { GET_REFRIGERANT_TRANSACTIONS } from '../RefrigerantsContainer/TransactionsContainer/index.queries';
import {
  TariffReference,
  FactorUsedCell,
  UploadReferenceCell,
  DocumentReferenceV2 as DocumentReference,
} from '../CellRenderers';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: `..` }, { label: 'Refrigerants and other gases' }];

export const RefrigerantsTransactionsGrid = () => {
  const config = useRefrigerantGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_REFRIGERANT_TRANSACTIONS, {
    fetchPolicy: 'network-only',
  });

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getRefrigerantTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title="Your refrigerants and other gases data"
      defaultExportFileName="refrigerants-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../refrigerants/uploads"
      config={{
        ...config,
        components: {
          tariffReference: TariffReference,
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={fetchRows}
    />
  );
};
