import { TransactionType, TaskSubject } from '@rio/rio-types';
import { BatchStatus } from '../../../types';

export function getTaskSubjectByUploadType(uploadType: TransactionType) {
  switch (uploadType) {
    case TransactionType.Electricity:
      return TaskSubject.Electricity;
    case TransactionType.Gas:
      return TaskSubject.Gas;
    case TransactionType.Waste:
      return TaskSubject.Waste;
    case TransactionType.Water:
      return TaskSubject.Water;
    case TransactionType.Transport:
      return TaskSubject.Transport;
    default:
      return null;
  }
}

export function mapBatchStatus(status: string): BatchStatus {
  const upper = status.toUpperCase();

  switch (upper) {
    case 'ACTION REQUIRED':
      return BatchStatus.ACTION_REQUIRED;
    case 'COMPLETED':
      return BatchStatus.DONE;
    case 'INITIATED':
    case 'PROCESSING':
      return BatchStatus.PENDING;
    case 'FAILED':
      return BatchStatus.FAILED;
    case 'ROLLED BACK':
      return BatchStatus.ABANDONED;
    case 'DELETED':
      return BatchStatus.DELETED;
    default:
      return BatchStatus.PENDING;
  }
}
