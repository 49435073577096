import { Fragment, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Text } from 'rio-ui-components';
import _ from 'lodash';
import { DataImportBatch, TransactionType } from '@rio/rio-types';
import { v4 as uuid } from 'uuid';
import { Alias } from './types';
import { ValueToAlias } from './useValuesToAlias';
import { AliasAccordion } from './AliasAccordion';
import { AliasRow } from '../../../components/AliasRow';
import { getAliasTypeByFieldName } from './config';
import { Nullable } from '../../../types';

const StyledText = styled(Text)`
  margin: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

interface AliasMatcherProps {
  accountId: string;
  aliases: Alias[];
  valuesToAlias: ValueToAlias[];
  setAliases: Dispatch<SetStateAction<Alias[]>>;
  transactionType: TransactionType;
  batch: Nullable<DataImportBatch>;
}

export const AliasMatcher = ({ accountId, aliases, setAliases, transactionType, valuesToAlias }: AliasMatcherProps) => {
  return (
    <Fragment>
      <StyledText weight="bold">
        Currently viewing {valuesToAlias.length} out of {valuesToAlias.length} aliasable items
      </StyledText>
      {_(valuesToAlias)
        .groupBy((item) => item.error.fieldName)
        .map((values: ValueToAlias[], fieldName) => {
          const aliasType = getAliasTypeByFieldName(fieldName, transactionType);
          const aliasesInThisColumn = aliases.filter((a) => a.aliasType === aliasType);
          const allCompleted = aliasesInThisColumn.length === values.length;
          return (
            <AliasAccordion
              key={fieldName}
              text={`${fieldName} ${`(${aliasesInThisColumn.length} of ${values.length} matched)`}`}
              color={allCompleted ? 'success' : 'danger'}
            >
              {values.map((value) => {
                const alias = aliasesInThisColumn.find(
                  (a) => a.aliasType === value.aliasType && a.name === value.error.value
                );
                const selectValue = alias ? { value: alias.parentId, label: alias.parentName } : null;
                return (
                  <AliasRow
                    transactionType={transactionType}
                    aliasType={value.aliasType}
                    key={[fieldName, value.error.value].join('-')}
                    unknownString={value.error.value}
                    value={selectValue}
                    accountId={accountId}
                    possibleOptions={value?.error?.possibleAliases}
                    onChange={(parentId: string, parentName: string) => {
                      const id = uuid();
                      setAliases((previousAliases) => [
                        ...previousAliases.filter((pa) =>
                          pa.aliasType === value.aliasType ? pa.name !== value.error.value : true
                        ),
                        {
                          id,
                          accountId,
                          parentId,
                          parentName,
                          name: value.error.value || '',
                          aliasType: value.aliasType,
                          energyType: transactionType,
                        },
                      ]);
                    }}
                  />
                );
              })}
            </AliasAccordion>
          );
        })
        .value()}
    </Fragment>
  );
};
