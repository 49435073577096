import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/engage/GetBundleById.query.graphql';

export function useGetBundleByIdQuery(bundleId, options = {}) {
  const { data: { getBundleById } = {}, ...others } = useQuery(QUERY, {
    variables: {
      bundleId
    },
    ...options
  });

  return { data: getBundleById, ...others };
}
