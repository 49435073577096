import { gql } from '@apollo/client';

export const submissionFragment = gql`
  fragment SubmissionFields on SurveySubmission {
    id
    status
    version
    answers {
      id
      answer
      question {
        id
        name
      }
    }
  }
`;

export const GET_MY_CONTRIBUTORS_OVERVIEW = gql`
  query getMyContributorOverview($accountId: ID!, $contributorAccountId: ID!, $contributorUserId: ID) {
    getMySurveyContributorById(
      accountId: $accountId
      contributorAccountId: $contributorAccountId
      contributorUserId: $contributorUserId
    ) {
      ... on AccountSurveyContributor {
        surveys {
          id
          name
          startDate
          deadlineDate
          submission {
            ...SubmissionFields
          }
          template {
            formContent
            questions {
              id
              type
              name
            }
          }
        }
        account {
          id
          name
        }
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
  ${submissionFragment}
`;
