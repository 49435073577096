import { gql } from '@apollo/client';
import { PROJECT_FIELDS } from '../../fragments/ProjectFields.fragment.js';
import { TASK_FIELDS } from '../../fragments/TaskFields.fragment.js';

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: ID!) {
    getProject(id: $id) {
      ...ProjectFields
      tasks {
        ...TaskFields
      }
      scheme {
        id
        name
      }
      evidenceNotes {
        id
        notes
        documents {
          id
          fileName
        }
      }
    }
  }
  ${PROJECT_FIELDS}
  ${TASK_FIELDS}
`;
