import { gql } from '@apollo/client';

export const CREATE_DOCUMENT_RECORD = gql`
  mutation CreateDocumentRecord(
    $id: ID!
    $accountId: ID!
    $referenceId: String
    $key: String!
    $userId: ID!
    $category: String!
    $type: [String!]
    $fileName: String!
    $library: DocumentLibrary!
    $reviewDate: String
    $source: Source!
    $tags: [String!]
  ) {
    createDocumentRecord(
      input: {
        id: $id
        userId: $userId
        accountId: $accountId
        referenceId: $referenceId
        key: $key
        category: $category
        type: $type
        fileName: $fileName
        library: $library
        reviewDate: $reviewDate
        source: $source
        tags: $tags
      }
    ) {
      id
    }
  }
`;
