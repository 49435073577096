export const GetLoginErrorMessageId = (loginError) => {
  return {
    UserNotFoundException: 'login.main.userNotFound',
    NotAuthorizedException: 'login.main.incorrectPassword',
    UserDisabledException: 'login.main.userDisabled',
    GeneralError: 'login.main.generalError',
  }[loginError];
};

export const getLoginException = ({ message }) => {
  switch (message) {
    case 'Incorrect username or password.':
      return 'NotAuthorizedException';
    case 'User does not exist.':
      return 'UserNotFoundException';
    case 'User is disabled.':
      return 'UserDisabledException';
    case 'Confirmation code cannot be empty':
      return 'ConfirmationCodeEmptyException';
    case 'Invalid code provided, please request a code again.':
      return 'InvalidCodeException';
    default:
      return 'GeneralError';
  }
};
