import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';

import { Query, Mutation } from '@apollo/client/react/components';
import { GET_ALL_EWCS, CREATE_WASTE_STREAM } from './index.queries';

import { Heading, Label, TextInput, Select, Checkbox, Row, Col, Button, Notification } from 'rio-ui-components';

const Outer = styled.div`
  padding: ${(p) => p.theme.geometry.lg.spacing};
`;

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

class CreateWasteStream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
      values: {
        name: {
          value: null,
          error: null
        },
        ewc: {
          value: null,
          error: null
        }
      },
      isSubmitted: false
    };

    this.handleCreateComplete = this.handleCreateComplete.bind(this);
    this.handleCreateError = this.handleCreateError.bind(this);
  }

  validate = () => {
    let hasError = false;
    const values = this.state.values;
    for (const v of Object.keys(this.state.values)) {
      if (!values[v].value) {
        values[v] = {
          value: values[v].value,
          error: 'Field cannot be left empty'
        };
        hasError |= true;
      }
    }

    if (hasError) this.setState({ values: values });

    return !hasError;
  };

  handleNameChange = (e) => {
    if (e.target) {
      this.setState({
        values: {
          ...this.state.values,
          name: {
            value: e.target.value,
            error: null
          }
        }
      });
    }
  };

  handleEWCChange = (e, ewcs) => {
    if (e.target && e.target.value) {
      const checked = ewcs.find((element) => element.value === e.target.value).hazardous;
      this.setState({
        isChecked: checked,
        showError: false,
        values: {
          ...this.state.values,
          ewc: {
            value: e.target.value,
            error: null
          }
        }
      });
    }
  };

  handleCreateComplete = (data) => {
    if (this.props.onSuccess)
      this.props.onSuccess(
        'Waste Stream created successfully',
        'success',
        data.createWasteStream.id,
        data.createWasteStream
      );
  };

  handleCreateError = () => {
    this.setState({ showError: true, isSubmitted: false });
    if (this.props.onError)
      this.props.onError('Something went wrong. If the problem continues please contact support.', 'danger');
  };

  handleCreateUpdate = (cache, data) => {
    if (this.props.onUpdate) this.props.onUpdate(cache, data);
  };

  render() {
    const { accountId = jwt_decode(localStorage.getItem('ID_TOKEN')).name, refetchQueries } = this.props;

    return (
      <Query query={GET_ALL_EWCS}>
        {({ loading, error, data }) => {
          if (error) return <div />;

          return (
            <Outer>
              {this.state.showError && (
                <Notification
                  name="wasteStreamError__notification"
                  show
                  color="danger"
                  onClose={() => this.setState({ showError: false })}
                >
                  There was an error creating the waste stream
                </Notification>
              )}
              <HeadingContainer>
                <Heading inline size="lg">
                  New Waste Stream
                </Heading>
              </HeadingContainer>
              <Row>
                <ColStyled>
                  <LabelContainerStyled>
                    <Label>Name</Label>
                  </LabelContainerStyled>
                  <TextInput
                    name="Waste_Stream_Name"
                    disabled={this.state.isSubmitted}
                    box
                    placeholder="Name"
                    onChange={this.handleNameChange}
                    value={this.state.values.name.value}
                    error={this.state.values.name.error}
                  />
                </ColStyled>
              </Row>
              <Row>
                <ColStyled>
                  <LabelContainerStyled>
                    <Label>EWC Code</Label>
                  </LabelContainerStyled>
                  {(() => {
                    if (data && data.getAllEWCs && !loading) {
                      const ewcs = data.getAllEWCs.map((v) => ({
                        label: `${v.code} - ${v.description}`,
                        value: v.id,
                        hazardous: v.hazardous
                      }));
                      return (
                        <Select
                          name="Waste_Stream_EWC_Code"
                          disabled={this.state.isSubmitted}
                          options={ewcs}
                          value={this.state.values.ewc.value}
                          error={this.state.values.ewc.error}
                          onChange={(e) => {
                            this.handleEWCChange(e, ewcs);
                          }}
                        />
                      );
                    } else {
                      return <Select placeholder="Loading" />;
                    }
                  })()}
                </ColStyled>
              </Row>
              <Row container>
                <ColStyled span={11}>
                  <Label>Hazardous</Label>
                </ColStyled>
                <ColStyled span={1}>
                  <Checkbox name="Waste_Stream_IsHazardous" readonly checked={this.state.isChecked} />
                </ColStyled>
              </Row>
              <Row>
                <ColStyled>
                  <Mutation
                    mutation={CREATE_WASTE_STREAM}
                    onCompleted={this.handleCreateComplete}
                    onError={this.handleCreateError}
                    refetchQueries={refetchQueries}
                    update={this.handleCreateUpdate}
                  >
                    {(createWasteStream) => {
                      return (
                        <Button
                          name="Waste_Stream_Create"
                          disabled={!data || !data.getAllEWCs || this.state.isSubmitted}
                          color="primary"
                          onClick={() => {
                            if (this.validate()) {
                              createWasteStream({
                                variables: {
                                  id: uuidv4(),
                                  name: this.state.values.name.value,
                                  ewcId: this.state.values.ewc.value,
                                  accId: accountId,
                                  hazardous: this.state.isChecked
                                }
                              });
                              this.setState({
                                isSubmitted: true
                              });
                            }
                          }}
                        >
                          Create
                        </Button>
                      );
                    }}
                  </Mutation>
                </ColStyled>
              </Row>
            </Outer>
          );
        }}
      </Query>
    );
  }
}

export default CreateWasteStream;
