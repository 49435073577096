import React, { useCallback, useState } from 'react';
import { useCurrentAccountId, useNotification } from '~/hooks';
import CreateEnergyMeter from '~/containers/ConfigurationContainer/ConfigurationEnergyMetersContainer/v2/CreateEnergyMeterModal';
import CreateLocation from '~/containers/ConfigurationContainer/ConfigurationLocationsContainer/CreateLocation';
import { Modal } from '@rio/ui-components';

type Props = {
  onSuccess: (fieldName: string, newOption: { value: string; label: string }) => void;
  onDismiss: () => void;
  energyType: string;
};

export function CreateMeterModal(props: Props) {
  const { onSuccess, onDismiss, energyType } = props;

  const [showCreateLocation, setShowCreateLocation] = useState(false);
  const { showNotification } = useNotification();

  const accountId = useCurrentAccountId();

  const onLocationCreated = useCallback(
    (message, color) => {
      showNotification(message, color);
      setShowCreateLocation(false);
    },
    [showNotification]
  );

  const handleOnSuccess = useCallback(
    (_, newMeter) => onSuccess('data.energySource', { value: newMeter.id, label: newMeter.name }),
    [onSuccess]
  );

  return (
    <Modal grid={{ xs: 8, md: 6 }} open onClose={onDismiss} title="Add new meter">
      {!showCreateLocation && (
        <CreateEnergyMeter onSuccess={handleOnSuccess} onError={showNotification} energyType={energyType} />
      )}
      {showCreateLocation && <CreateLocation accountId={accountId} onSuccess={onLocationCreated} />}
    </Modal>
  );
}
