import React from 'react';
import { useTheme } from 'styled-components';
import { getPriorityIconProps } from '../priorityIconProps';
import { Icon } from 'rio-ui-components';

export function PriorityCell(props) {
  const { data: { priority } = {} } = props;

  const theme = useTheme();

  return <Icon name={`task-row__priority`} size="lg" {...getPriorityIconProps(priority, theme)} />;
}
