import { useParams } from 'react-router-dom';
import { NotFound } from '~/components';
import { SubmissionOverview } from '~/components/Surveys/v2';
import { useMyContributorSubmission } from './hooks';

export const MyContributorSubmissionContainerV2 = () => {
  const { submissionId } = useParams();

  const { submission, expanded, collapse, sendFeedback, formModel, survey } = useMyContributorSubmission(
    submissionId || ''
  );

  if (!submission) {
    return <NotFound error="Not found" />;
  }

  return (
    <SubmissionOverview
      model={formModel}
      expanded={expanded}
      onDismiss={collapse}
      survey={{ ...survey, submission }}
      sendFeedback={sendFeedback}
    />
  );
};
