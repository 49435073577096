import { useQuery } from '@apollo/client';
import { usePermissions, useCurrentAccountId } from '../../../hooks';
import { GET_MARKET_CARBON_CONVERSION_FACTORS } from './index.queries';

export function useGetMarketCarbonConversionFactorsVariables() {
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  return {
    accountId: permissions.data.includes('getMarketCarbonConversionFactorAll') ? null : accountId
  };
}
export function useGetMarketCarbonConversionFactors() {
  const variables = useGetMarketCarbonConversionFactorsVariables();
  const result = useQuery(GET_MARKET_CARBON_CONVERSION_FACTORS, {
    variables,
    skip: usePermissions().loading,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });
  return {
    ...result,
    refetch: () => result.refetch(variables)
  };
}
