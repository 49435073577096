import { useQuery, useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Analytics } from 'aws-amplify';
import { GET_USER_BY_USERNAME } from '../queries/users';
import { useUserToken } from './useUserToken';
import { GET_CURRENT_ACCOUNT_ID, SET_CURRENT_ACCOUNT_ID } from '../queries/currentAccount';

export function useCurrentAccountId() {
  const { data: fromState } = useQuery(GET_CURRENT_ACCOUNT_ID);
  const [currentAccountId, setCurrentAccountId] = useState(
    fromState && fromState.currentAccountId ? fromState.currentAccountId : null
  );
  const client = useApolloClient();
  const { token } = useUserToken();
  const { sub: userId } = token || {};

  useEffect(() => {
    const getAndSetCurrentAccountId = async () => {
      if (!userId) return;
      if (!fromState || !fromState.currentAccountId) {
        const { data } = await client.query({
          query: GET_USER_BY_USERNAME,
          variables: {
            name: userId
          }
        });

        if (data.getUserInfoById.account) {
          Analytics.updateEndpoint({
            userAttributes: {
              account: [data.getUserInfoById.account.name]
            }
          });
        }

        const userAccountId =
          data && data.getUserInfoById && data.getUserInfoById.account && data.getUserInfoById.account.id;
        await client.mutate({
          mutation: SET_CURRENT_ACCOUNT_ID,
          variables: {
            currentAccountId: userAccountId
          }
        });
        return setCurrentAccountId(userAccountId);
      } else if (fromState && fromState.currentAccountId !== currentAccountId) {
        return setCurrentAccountId(fromState.currentAccountId);
      }
    };

    getAndSetCurrentAccountId();
  });
  return currentAccountId;
}
