import { gql } from '@apollo/client';

export const GET_INVESTMENT_TRANSACTIONS_BY_ACCOUND_ID = gql`
  query GetInvestmentTransactionsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getInvestmentTransactionsByAccountId(
      accountId: $accountId
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        date
        fundCommitment
        fundOwnership
        fundRevenue
        fund {
          id
          name
        }
        asset {
          id
          name
          factorName
          factorValue
        }
        assetRevenue
        assetRevenueDate
        assetValuation
        parentFund {
          id
          name
        }
        parentFundCommitment
        parentFundOwnership
        comment
        importBatch {
          id
          fileName
        }
        financedEmissions
        fundCurrencyCode
        parentFundCurrencyCode
        assetCurrencyCode
      }
    }
  }
`;

export const GET_INVESTMENT_TRANSACTIONS_FILTER = gql`
  query GetInvestmentTransactionsFilter($accountId: ID!, $field: String!) {
    getInvestmentTransactionsFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
