import { SurveySubmissionStatus } from '@rio/rio-types';
import { getStatus, isWithinFiveDaysFromNow } from '../Surveys';

const completeStatuses = [
  SurveySubmissionStatus.ReadyForReview,
  SurveySubmissionStatus.Approved,
  SurveySubmissionStatus.InProgress,
];

export const countIncompleteSurveys = (rows) =>
  rows?.filter((item) => {
    const { isOverDue } = getStatus(item.deadlineDate);
    const deadlineWithinFiveDays = isWithinFiveDaysFromNow(item.deadlineDate);

    return (
      (!isOverDue && deadlineWithinFiveDays) ||
      (!isOverDue && (!item.submission || !completeStatuses.includes(item.submission.status)))
    );
  })?.length;
