export const normalizePhoneNumber = (number) => {
  return number.replace(/\D/g, '');
};

const validPhoneChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ' '];

export const isPhoneNumber = (number) => {
  // Remove white space & convert to to char array
  number = [...number.trim()];
  // Assume the number is valid until proven otherwise
  if (number.length <= 0 || (number.length === 1 && number[0] === ' ')) {
    return false;
  }

  let isValid = true;
  for (const char of number) {
    if (!validPhoneChars.includes(char)) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const isNumeric = (number) => {
  return !isNaN(number);
};

export const isUrl = (url) => {
  const match = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (match === null) {
    return false;
  }
  return true;
};
