import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, CircleIcon } from 'rio-ui-components';
import shineCss from '../../../../../utils/shineCss';
import { FadedCourse, LockIcon } from '../../../CourseTile';

const ContainerStyled = styled(Col)`
  background-color: ${(props) => props.theme.colors.basic.white};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CourseImage = styled.div`
  position: relative;
  padding: 0 0 75% 0;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.secondary.dark.background} url(${({ src }) => src}) center center no-repeat;
  background-size: cover;

  :hover {
    width: 100%;
  }

  ${(props) => {
    if (props.placeholder) {
      return css`
        background: ${props.theme.colors.neutral.normal.background};
        ${shineCss}
      `;
    }
  }}
`;

const TextContainer = styled(Col)`
  padding-top: 10px;
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-flex;
  flex: 0 0 auto;
`;

const HeadingStyled = styled.h3`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.md.size};
  color: ${({ placeholder }) => (placeholder ? 'rgba(0,0,0,0)' : 'black')};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  ${({ column }) => (column ? 'padding: 10px 20px;' : '')}

  ${(props) => {
    if (props.placeholder) {
      return css`
        min-width: 100%;
        min-height: 20px;
        background: ${props.theme.colors.neutral.normal.background};
        ${shineCss}
      `;
    }
  }}
`;

const RowStyled = styled(Row)`
  padding-bottom: ${({ theme }) => theme.geometry.xs.spacing};
`;

const BannerTile = ({ learnAccess, courseId, title, image, placeholder, column, restricted }) => {
  return (
    <ContainerStyled name={`CourseTile CourseTile--${courseId}`} container item disabled={!learnAccess}>
      <CourseImage name="CourseTile__Thumbnail" src={image} placeholder={placeholder}>
        {!image && (
          <CircleIconStyled
            name="CourseTile__Icon"
            height="100px"
            size="lg"
            icon="graduation-cap"
            iconColor="white"
            circleColor="white"
          />
        )}
        {restricted ? (
          <FadedCourse>
            <LockIcon icon="lock" color="#ababab" />
          </FadedCourse>
        ) : null}
      </CourseImage>
      <TextContainer container item vdistribution="between">
        <RowStyled container item itemAlign="top">
          <HeadingStyled
            name="CourseTile__Title"
            size="lg"
            color="dark"
            weight="light"
            placeholder={placeholder}
            column={column}
          >
            {title}
          </HeadingStyled>
        </RowStyled>
      </TextContainer>
    </ContainerStyled>
  );
};

export default BannerTile;
