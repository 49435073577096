import { gql } from '@apollo/client';

export const PROJECT_FIELDS = gql`
  fragment ProjectFields on Project {
    id
    accountId
    name
    description
    priority
    scheme {
      id
      name
      category {
        id
        name
      }
    }
    impact
    cost
    currencyId
    baselineStartDate
    startDate
    endDate
    frequency
    plannedBenefit
    plannedCostChange
    plannedCO2eChange
    locations {
      id
      name
    }
    owners {
      id
      first_name
      last_name
      profilePicture
    }
    evidenceNotes {
      id
      notes
      documents {
        id
        fileName
      }
    }
    meters {
      id
      name
    }
  }
`;
