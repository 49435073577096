import { gql } from '@apollo/client';

export const GET_DATA_BATCH_EXPORT_URL = gql`
  query GetDataImportBatchExportUrl($id: ID!) {
    getDataImportBatchExportUrl(id: $id)
  }
`;

export const GET_DATA_BATCH_IMPORT_FILE_URL = gql`
  query GetDataImportBatchImportFileUrl($id: ID!) {
    getDataImportBatchImportFileUrl(id: $id)
  }
`;

export const GET_BATCHES_PAGE = gql`
  query GetDataImportBatchesPage(
    $accountId: ID!
    $type: TransactionType!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getDataImportBatchesPage(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        status
        fileName
        user {
          id
          first_name
          last_name
        }
        lastUpdated
        rowsUploaded
        duplicatesNumber
        isLegacy
        approved
        summarizeStatus
        transposition {
          transpositionName
        }
      }
    }
  }
`;

export const DELETE_BATCH = gql`
  mutation deleteBatch($id: ID!) {
    deleteDataImportBatch(id: $id) {
      id
    }
  }
`;

export const ROLLBACK_BATCH = gql`
  mutation RollBackDataImportBatch($id: ID!) {
    rollBackDataImportBatch(id: $id)
  }
`;

export const UPLOAD_OCR_DOCUMENT = gql`
  mutation UploadOcrDocument(
    $id: ID!
    $accountId: String!
    $parserId: String!
    $fileName: String!
    $fileContent: String!
    $utility: OcrUtility!
  ) {
    uploadOcrDocument(
      input: {
        id: $id
        accountId: $accountId
        parserId: $parserId
        fileName: $fileName
        fileContent: $fileContent
        utility: $utility
      }
    )
  }
`;

export const GET_ALL_TEMPLATES = gql`
  query GetAllTemplates {
    getAllTemplates {
      id
      name
      parserId
    }
  }
`;

export const GET_UPLOAD_SUMMARY_DASHBOARD_URL = gql`
  query GetUploadSummaryDashboardUrl($uploadType: TransactionType!, $batchId: ID!) {
    getUploadSummaryDashboard(uploadType: $uploadType, batchId: $batchId)
  }
`;

export const APPROVE_UPLOAD = gql`
  mutation ApproveUpload($batchId: ID!) {
    approveUpload(batchId: $batchId) {
      id
      errorsCount
      fileName
      status
      timeStamp
      importType
      rowsUploaded
      duplicatesNumber
      approved
      summarizeStatus
      transposition {
        transpositionName
      }
    }
  }
`;
