import { useCallback, useState, useMemo } from 'react';
import set from 'lodash/fp/set';
import { v4 as uuid } from 'uuid';
import {
  Breakdown,
  BreakdownInput,
  BreakdownQuestion as IBreakdownQuestion,
  QuestionUnion,
  IntegerQuestion,
  Maybe
} from '@rio/rio-types';
import { VerbalQuestionField } from './VerbalQuestionField';
import { QuestionRow, QuestionCol, DeleteIcon } from './Elements';
import { NumericQuestionField } from './NumericQuestionField';
import { DraftBreakdown,   NumericQuestion,
  VerbalQuestion, } from './types';
import { splitToFiguresAndPartitions, createBreakdownDraft, getAnswerKeysAndValue } from './utils';

interface DraftBreakdownRowProps {
  draft: true;
  question: IBreakdownQuestion;
  breakdown: DraftBreakdown;
  onChange: (draft: DraftBreakdown) => void;
}

interface BreakdownRowProps {
  draft: false;
  question: IBreakdownQuestion;
  breakdown: Breakdown;
  onChange: (breakdownInput: BreakdownInput) => void;
  onDelete: (breakdownId: string) => void;
}

interface BreakdownQuestionProps {
  question: IBreakdownQuestion;
  onAnswer: (updatedQuestion: IBreakdownQuestion) => void;
}

function BreakdownRow(props: BreakdownRowProps | DraftBreakdownRowProps) {
  const { question, breakdown, draft } = props;

  const handleDeleteIconClick = useCallback(() => {
    if (!draft) {
      props.onDelete(props.breakdown.id);
    }
  }, [props, draft]);

  const handleCreateReactKey = useCallback(
    (q: QuestionUnion, breakdown: DraftBreakdown | Breakdown) =>
      'id' in breakdown ? `${breakdown.id}__${q.id}}` : `DraftQuestionCol__${q.id}`,
    []
  );

  const span = 12 / question.questions.length;

  const { figures, partition } = splitToFiguresAndPartitions(question);

  return (
    <QuestionRow wrap="no" container item>
      {figures.map((q: NumericQuestion, qi: number) => (
        <QuestionCol key={handleCreateReactKey(q, breakdown)} span={span} container item>
          <NumericQuestionField
            answerOnBlur
            question={{
              ...q,
              [q.__typename === 'FloatQuestion' ? 'reportedFloat' : 'reportedInt']: props.breakdown.figures[qi]
            }}
            onAnswer={(updatedQuestion) => {
              const givenAnswer =
                updatedQuestion.__typename === 'FloatQuestion'
                  ? updatedQuestion.reportedFloat
                  : (updatedQuestion as IntegerQuestion).reportedInt;
              const updatedBreakdown = set(['figures', qi], givenAnswer, props.breakdown);
              props.onChange(updatedBreakdown as BreakdownInput);
            }}
          />
        </QuestionCol>
      ))}
      {partition.map((q: VerbalQuestion, qi: number) => (
        <QuestionCol key={handleCreateReactKey(q, breakdown)} span={span} container item>
          <VerbalQuestionField
            question={{
              ...q,
              [getAnswerKeysAndValue(q)[1]]: props.breakdown.partition[qi]
            }}
            onAnswer={(updatedQuestion: VerbalQuestion) => {
              let givenAnswer: Maybe<string | string[]> | undefined = null;
              if (updatedQuestion.__typename === 'ChoiceQuestion') {
                givenAnswer = updatedQuestion.reportedChoice;
              }
              if (updatedQuestion.__typename === 'MultipleChoiceQuestion') {
                givenAnswer = updatedQuestion.reportedChoices;
              }
              if (updatedQuestion.__typename === 'OpenEndedQuestion') {
                givenAnswer = updatedQuestion.reportedText;
              }
              const updatedBreakdown = set(['partition', qi], givenAnswer, props.breakdown);
              props.onChange(updatedBreakdown as BreakdownInput);
            }}
          />
        </QuestionCol>
      ))}
      {!props.draft && <DeleteIcon onClick={handleDeleteIconClick} />}
    </QuestionRow>
  );
}

export function BreakdownQuestion({ question, onAnswer }: BreakdownQuestionProps) {
  const reportedBreakdown = useMemo(() => question.reportedBreakdown || [], [question]);
  const [draft, setDraft] = useState(createBreakdownDraft(question));

  const handleDraftChange = useCallback(
    (newDraft: DraftBreakdown) => {
      const isComplete = newDraft.figures.every((f) => f !== null) && newDraft.partition.every((p) => p !== null);
      if (isComplete) {
        const input: BreakdownInput = {
          ...newDraft,
          id: uuid()
        };
        setDraft(createBreakdownDraft(question));
        onAnswer({
          ...question,
          reportedBreakdown: [input].concat(reportedBreakdown)
        });
      } else {
        setDraft(newDraft);
      }
    },
    [setDraft, question, onAnswer, reportedBreakdown]
  );

  const handleBreakdownChange = useCallback(
    (breakdown: BreakdownInput) => {
      onAnswer({
        ...question,
        reportedBreakdown: reportedBreakdown.map((b: Breakdown) => (b.id === breakdown.id ? breakdown : b))
      });
    },
    [question, onAnswer, reportedBreakdown]
  );

  const handleDelete = useCallback(
    (breakdownId: string) => {
      onAnswer({
        ...question,
        reportedBreakdown: (question.reportedBreakdown || []).filter((b) => b.id !== breakdownId)
      });
    },
    [question, onAnswer]
  );
  return (
    <>
      <BreakdownRow draft question={question} breakdown={draft} onChange={handleDraftChange} />
      {reportedBreakdown.map((breakdown: Breakdown) => (
        <BreakdownRow
          key={breakdown.id}
          draft={false}
          breakdown={breakdown}
          question={question}
          onChange={handleBreakdownChange}
          onDelete={handleDelete}
        />
      ))}
    </>
  );
}
