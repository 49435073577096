import { ManagementSystem } from '@rio/rio-types';
import { Area } from '../../areas';

export const checkRestrictions = (
  restrictedAreas: Area[],
  accessMap: { [accessIndex: string]: ManagementSystem[] }
) => {
  const allowedAreas: Area[] = [];
  restrictedAreas.forEach((area) => {
    if (accessMap[area.accessIndex]?.find((system: ManagementSystem) => system.id === area.restrictionId)) {
      allowedAreas.push(area);
    }
  });
  return allowedAreas;
};
