import Moment from 'moment';
import { Dictionary } from 'lodash';
import { Filters, FilterStrategy, DataType } from '@rio/rio-types';

export function toDate(date: string) {
  const formatted = Moment(date).local().format('DD/MM/YYYY');
  return formatted === 'Invalid date' ? '-' : formatted;
}

export function toDateTime(date: string) {
  const formatted = Moment(date).local().format('DD/MM/YYYY [at] HH:mm');
  return formatted === 'Invalid date' ? '-' : formatted;
}

export function formatReviewDate(reviewDate: string) {
  return Moment.utc(new Date(reviewDate)).format('DD/MM/YYYY');
}

export function getReferenceIdFromFileName(fileName?: string | void) {
  if (typeof fileName !== 'string') {
    return '';
  }
  const parts = fileName.split('.');
  return parts.length > 1 ? parts.slice(0, parts.length - 1).join('.') : fileName;
}

export function createS3Key(accountId: string, id: string) {
  return `CLEAN/${accountId}/${id}/${id}`;
}

export function getLibraryColour(library?: string): string {
  const fallback = 'quaternary';
  if (!library) return fallback;

  const colourMap: Dictionary<string> = {
    GAS: 'quaternary',
    ELECTRICITY: 'quaternary',
    WASTE: 'quaternary',
    TRANSPORT: 'quaternary',
    WATER: 'quaternary',
    GOVERNANCE: 'tertiary',
  };
  const libraryFormatted = library?.toUpperCase();
  return library && libraryFormatted in colourMap ? colourMap[libraryFormatted] || fallback : fallback;
}

export function didReviewDateExceed(reviewDate: string) {
  return reviewDate ? Moment().isAfter(reviewDate) : false;
}

export function isReviewDateApproaching(reviewDate: string) {
  return reviewDate ? Moment().isBetween(Moment(reviewDate).subtract(1, 'months'), Moment(reviewDate)) : false;
}

export function getAllFilters(
  searchValue: string,
  library?: string,
  category?: string,
  managementSystemId?: string,
  managementSystemChapterId?: string | null
): Filters {
  const filters: Filters = {};
  if (searchValue) {
    filters.text = [
      {
        filterType: DataType.Text,
        type: FilterStrategy.Contains,
        filter: searchValue,
        field: 'fileName',
      },
    ];
  }

  if (library || category || managementSystemId || managementSystemChapterId) {
    filters.set = [];
    if (library) {
      filters.set.push({
        values: [library],
        filterType: DataType.Set,
        field: 'library',
      });
    }
    if (category) {
      filters.set.push({
        values: [category],
        filterType: DataType.Set,
        field: 'category',
      });
    }
    if (managementSystemId) {
      filters.set.push({
        values: [managementSystemId],
        filterType: DataType.Set,
        field: 'managementSystemId',
      });
    }
    if (managementSystemChapterId || (managementSystemChapterId === null && !searchValue)) {
      filters.set.push({
        values: [managementSystemChapterId],
        filterType: DataType.Set,
        field: 'managementSystemChapterId',
      });
    }
  }

  return filters;
}

export function getBasePath(library: string, type?: string) {
  const root = '/documents';
  if (library && type) {
    return `${root}/${library}/${type}`;
  } else if (library) {
    return `${root}/${library}`;
  }
  return `${root}/all`;
}
