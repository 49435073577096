import styled from 'styled-components';

import { Button } from 'rio-ui-components';

export const UploadButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${(props) => props.theme.geometry.md.spacing};
  z-index: 1000;
`;
