import _ from 'lodash';

const alphabetiseStrings = (a: string, b: string) => {
  const lowerA = a?.toLowerCase();
  const lowerB = b?.toLowerCase();

  if (lowerA < lowerB) {
    return -1;
  }
  if (lowerA > lowerB) {
    return 1;
  }
  return 0;
};

export const alphabetiseByName = (a: object, b: object) => alphabetiseByField('name')(a, b);

export const alphabetiseByField = (field: string) => (a: object, b: object) =>
  alphabetiseStrings(_.get(a, field), _.get(b, field));

export default alphabetiseStrings;
