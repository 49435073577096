import { gql } from "@apollo/client";

export const GET_ALL_JOURNEY_TARGETS = gql`
  query GetAllJourneyTargets {
    getAllJourneyTargets {
      id
      name
    }
  }
`;
