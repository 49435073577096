import { styled } from '@rio/ui-components';

const Dot = styled('span')<{ status: string }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  background-color: ${(props) => {
    switch (props.status) {
      case 'Overdue': {
        return props.theme.sys.color.error;
      }
      case 'Due for review': {
        return props.theme.customColors.warning.color;
      }
      case 'Within date':
      default: {
        return props.theme.extendedColors.environmental.color;
      }
    }
  }};
`;

type Props = {
  data: { reviewDateStatus: string };
};

export function ReviewStatusCell(props: Props) {
  const { data: { reviewDateStatus } = {} } = props;

  if (!reviewDateStatus) {
    return null;
  }

  return <Dot status={reviewDateStatus} />;
}
