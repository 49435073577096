import styled from 'styled-components';

/**
 * @see https://ag-grid.com/react-data-grid/global-style-customisation-variables/#reference-variables---ag-data-color
 */
export const AgGridSimple = styled.div`
  --ag-borders: none;
  --ag-border-color: #e8e9ec;
  --ag-borders-row: 1px solid;
  --ag-odd-row-background-color: #fafafa;
  --ag-data-color: #000000;
  --ag-font-size: 14px;

  height: 100%;
  max-width: 100%;

  & :not(.ag-row) {
    border: 0;
  }

  &&& .ag-cell:focus {
    border: 0;
  }
  &&& .ag-row .ag-cell {
    display: flex;
    align-items: center;
  }
`;
