import React from 'react';
import { Col, LoadingIndicator, Search } from 'rio-ui-components';
import styled from 'styled-components';

import NoResults from '../../../../components/NoResults';
import ContainerHeader from '../../../../components/ContainerHeader';
import { useNotification } from '../../../../hooks/useNotification';

import SubscriptionRows from './SubscriptionRows';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SubscriptionSelector = ({ title, filteredFlows, onSearchChange, loading, search }) => {
  const { showNotification } = useNotification();
  return (
    <Col name="ConfigurationAccountsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationLearnSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title={title}
      >
        <Col container item>
          <Search
            name="ConfigurationFlowsSubscriptionsContainer__Controls__Search"
            value={search}
            onChange={onSearchChange}
            hideButton
          />
        </Col>
      </ContainerHeader>
      {loading && (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      )}

      {!loading && !filteredFlows.length && (
        <NoResults name="ConfigurationUsersContainer__NoResults" title="There are no results for this search." />
      )}
      {!!filteredFlows.length && <SubscriptionRows flows={filteredFlows} onComplete={showNotification} />}
    </Col>
  );
};

export default SubscriptionSelector;
