import { Heading, Col, Row, Text, TextInput, Icon } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DocumentTile } from '../DocumentTile';
import { noop } from 'lodash';

const PaddedCol = styled(Col)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const EvidenceContainer = styled(Row)`
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
`;

const StyledTextInput = styled(TextInput)`
  & > div {
    height: 63px;
    border-radius: 5px;
  }
`;
const LinkContainer = styled.div`
  padding: 1px;
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: black;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const EvidenceRow = ({
  evidence,
  taskId = null,
  projectId = '',
  onEditEvidence = noop,
  isProjectPage = false,
  canEditProject = false,
  key
}) => {
  return (
    <EvidenceContainer container>
      <Col item span={12}>
        <PaddedCol>
          <Row container span={12} distribution="between">
            <Heading size="md">Notes:</Heading>
          </Row>
          {isProjectPage && (
            <StyledTextInput
              key={key}
              data-testid={`Evidence-Note-${evidence.id}`}
              size="md"
              name="evidenceNote"
              onChange={(e) => {
                onEditEvidence(e, key);
              }}
              value={evidence.notes}
              readOnly={!canEditProject}
            />
          )}
          {!isProjectPage && <Text>{evidence.notes}</Text>}
        </PaddedCol>
        <PaddedCol>
          <Heading size="md">Documents:</Heading>
          <TileContainer>
            {evidence.documents.map((doc) => {
              return (
                <LinkContainer key={doc.id} id={doc.id}>
                  {isProjectPage && canEditProject && (
                    <StyledIcon key={key} icon="times" onClick={() => onEditEvidence(doc, key)} />
                  )}
                  <Link
                    key={`EvidenceRow_Link_${doc.id}`}
                    to={{ pathname: `/documents/all/${doc.id}` }}
                    state={{ taskId: taskId, projectId: projectId }}
                  >
                    <DocumentTile title={doc.fileName} category={doc.category} id={doc.id} iconColor={'tertiary'} />
                  </Link>
                </LinkContainer>
              );
            })}
          </TileContainer>
        </PaddedCol>
      </Col>
    </EvidenceContainer>
  );
};
