import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Label, TextInput, Button } from 'rio-ui-components';
import { UPDATE_USER_PROFILE } from './index.queries';
import { useCreateDepartment } from '../../hooks/useCreateDepartment';
import { isMobilePhone, isEmail, isNumeric, isEmpty } from 'validator';
import { CreateDepartmentModal } from '../../components/CreateDepartmentModal';

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  align-items: center;
`;

const RowStyled = styled(Row)`
  min-width: 100%;
`;

const UserDetailsComponent = ({ user, setNotification, intl }) => {
  const { first_name, last_name, jobTitle, phone, department, mobile, email, id, profilePicture, account } = user;
  const [userInfo, setUserInfo] = useState({
    first_name: { value: first_name, error: '' },
    last_name: { value: last_name, error: '' },
    jobTitle: { value: jobTitle, error: '' },
    phone: { value: phone, error: '' },
    department: { value: department, error: '' },
    mobile: { value: mobile, error: '' },
    email: { value: email, error: '' }
  });
  const { departmentSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    account.departments,
    userInfo.department.value,
    account.id
  );

  const [isEdited, setIsEdited] = useState(false);
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => {
      setNotification({ 
        message: intl.formatMessage({ id: 'profile.userHaveBeenUpdated' }), 
        colour: 'success' 
      });
    },
    onError: () => {
      setNotification({ message: 'Something went wrong. Please try again later.', colour: 'danger' });
    }
  });

  const hasError = () => {
    let error = false;
    Object.keys(userInfo).forEach((key) => {
      if (!!userInfo[key].error) {
        error = true;
      }
    });
    return error;
  };

  const validators = {
    email: (email) => (isEmail(email) ? '' : 'Must be a valid email address'),
    first_name: (name) => (isEmpty(name) ? 'First Name is required.' : ''),
    last_name: (name) => (isEmpty(name) ? 'Last Name is required.' : ''),
    mobile: (mobileNumber) =>
      isEmpty(mobileNumber) || isMobilePhone(mobileNumber) ? '' : 'Must be a valid mobile number.',
    phone: (phoneNumber) => (isEmpty(phoneNumber) || isNumeric(phoneNumber) ? '' : 'Must be a valid phone number.')
  };

  const handleChange = (event) => {
    setIsEdited(true);
    setUserInfo({
      ...userInfo,
      [event.target.name]: { value: event.target.value, error: validators[event.target.name]?.(event.target.value) }
    });
  };

  const handleSubmit = () => {
    const { first_name, last_name, jobTitle, phone, department, mobile, email } = userInfo;
    updateUserProfile({
      variables: {
        id,
        account_id: user.account.id,
        email: email.value,
        previousEmail: user.email,
        first_name: first_name.value,
        last_name: last_name.value,
        jobTitle: jobTitle.value,
        department: department.value !== 'notSelected' ? department.value : null,
        phone: phone.value,
        mobile: mobile.value,
        profile: profilePicture
      }
    });
    setIsEdited(false);
  };

  const setDepartment = async (data, newDepartment) => {
    setUserInfo((prevState) => ({ ...prevState, department: { value: newDepartment.departmentId, error: '' } }));
  };

  return (
    <>
      <CreateDepartmentModal
        passedAccountId={account.id}
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        onComplete={setDepartment}
      />
      <RowStyled container item>
        <ColStyled item span={6}>
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.firstName" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.first_name.value}
            error={userInfo.first_name.error}
            name="first_name"
            box
          />
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.jobTitle" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.jobTitle.value}
            error={userInfo.jobTitle.error}
            name="jobTitle"
            box
          />
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.phoneNumber" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.phone.value}
            error={userInfo.phone.error}
            name="phone"
            box
          />
        </ColStyled>
        <ColStyled item span={6}>
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.lastName" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.last_name.value}
            error={userInfo.last_name.error}
            name="last_name"
            box
          />
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.department" /></LabelStyled>
          </LabelContainerStyled>
          {departmentSelect(handleChange)}
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.mobileNumber" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.mobile.value}
            error={userInfo.mobile.error}
            name="mobile"
            box
          />
        </ColStyled>
      </RowStyled>
      <RowStyled container item>
        <ColStyled item span={12}>
          <LabelContainerStyled>
            <LabelStyled><FormattedMessage id="profile.emailAddress" /></LabelStyled>
          </LabelContainerStyled>
          <TextInput
            onChange={handleChange}
            value={userInfo.email.value}
            error={userInfo.email.error}
            name="email"
            box
          />
        </ColStyled>
      </RowStyled>
      <RowStyled container item>
        <ColStyled item span={3}></ColStyled>
        <ColStyled item span={6}>
          <Button disabled={!isEdited || hasError()} onClick={handleSubmit} color={'success'}>
            <FormattedMessage id="profile.update" />
          </Button>
        </ColStyled>
      </RowStyled>
    </>
  );
};

export const UserDetails = injectIntl(UserDetailsComponent);
