import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  Mutation,
  MutationSetSurveySubmissionArgs,
  DocumentRecord,
  SurveySubmissionStatusInput,
  SurveyAnswerInput,
  MutationCreateDocumentRecordArgs,
  MutationDeleteDocumentRecordsArgs,
  Source,
  DocumentLibrary,
  Maybe,
  SurveySubmission,
  SurveyQuestionType,
  Query,
  QueryGetReceivedSurveyByIdArgs,
} from '@rio/rio-types';
import { Model } from 'survey-core';
import { keyBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useUserToken, usePageSuspendingQuery, useIsV2 } from '~/hooks';
import { SET_SUBMISSION, GET_DOCUMENTS, GET_RECEIVED_SURVEY_BY_ID } from '../queries';
import { UPLOAD_DOCUMENT, CREATE_DOCUMENT_RECORD, DELETE_DOCUMENT_RECORDS } from '../mutations';
import { formatAnswersForSubmission, getNonAnsweredQuestionsAnswers, isAnswered } from '../utils';
import { SurveyFileAnswer } from '../types';
import { createS3Key } from '../../../DocumentContainer/utils';
import { downloadFile, convertFileToBase64String, convertBase64ToFile } from '../../../../utils/file';
import { useGetReceivedSurveysPage } from '../../ReceivedSurveysContainer/queries';
import { useSearchParams } from 'react-router-dom';

interface SurveySubmissionOptions {
  model: Maybe<Model>;
  surveyId: string;
  accountId: string;
  uploadedDocumentsIds: string[];
  version: number;
}

type CreateDocumentRecord = Pick<Mutation, 'createDocumentRecord'>;

/**
 * Hook to set submission
 * @param surveyId
 * @param accountId
 * @returns
 */
const useSetSubmission = (surveyId: string, accountId: string) => {
  const [setSubmission, { loading }] = useMutation<
    Pick<Mutation, 'setSurveySubmission'>,
    MutationSetSurveySubmissionArgs
  >(SET_SUBMISSION);

  const { refetch } = useGetReceivedSurveysPage({ accountId });

  // Saves answers not more often than every 5s
  const saveSubmission = useDebouncedCallback(
    (...args) => {
      setSubmission(...args);
      refetch();
    },
    5000,
    {
      leading: true,
    }
  );

  // This code saves the answers when user leaves the page and there is still pending debounced function
  useEffect(
    () => () => {
      if (saveSubmission.isPending()) {
        saveSubmission.flush();
      }
    },
    [saveSubmission]
  );

  return useMemo(() => ({ saveSubmission, loading }), [saveSubmission, loading]);
};

/**
 * Hook to get uploaded documents
 * @param ids
 * @returns
 */
export const useUploadedDocuments = (submission: Maybe<SurveySubmission> | undefined) => {
  // list of document ids that are in the submission
  const uploadedDocumentsIds = useMemo(() => {
    if (!submission) {
      return [];
    }
    return submission.answers
      .filter((answer) => answer.question.type === SurveyQuestionType.File)
      .map((answer) => JSON.parse(answer.answer))
      .flat();
  }, [submission]);

  const [uploadedDocuments, setUploadedDocuments] = useState<{ [id: string]: SurveyFileAnswer }>({});
  // get documents if available
  const { data: { getDocumentRecordsByIds } = { getDocumentRecordsByIds: [] } } = useQuery<{
    getDocumentRecordsByIds: DocumentRecord[];
  }>(GET_DOCUMENTS, {
    skip: !uploadedDocumentsIds.length,
    variables: {
      ids: uploadedDocumentsIds,
    },
  });

  useEffect(() => {
    if (!getDocumentRecordsByIds.length) {
      return;
    }

    const promises = getDocumentRecordsByIds.map(async (doc) => {
      const file = await downloadFile(doc.link!, doc.fileName);
      const fileString = await convertFileToBase64String(file);

      return {
        id: doc.id,
        name: doc.fileName,
        content: fileString,
        type: file.type,
      };
    });

    Promise.all(promises).then((downloadedDocuments) => {
      setUploadedDocuments(keyBy(downloadedDocuments, 'id'));
    });
  }, [getDocumentRecordsByIds]);

  return useMemo(
    () => ({
      uploadedDocuments,
      uploadedDocumentsIds,
    }),
    [uploadedDocuments, uploadedDocumentsIds]
  );
};

/**
 * Hooks to upload file answers
 * @param accountId
 * @returns
 */
const useUploadDocuments = (accountId: string) => {
  const { token } = useUserToken();
  const [uploadDocument, { loading: documentLoading }] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord, { loading: creatingDocument }] = useMutation<
    CreateDocumentRecord,
    MutationCreateDocumentRecordArgs
  >(CREATE_DOCUMENT_RECORD);

  const uploadDocuments = useCallback(
    async (answers: { [name: string]: SurveyFileAnswer[] }): Promise<SurveyAnswerInput[]> => {
      const result: { name: string; value: string }[] = [];

      for (const name in answers) {
        const files = answers[name];
        const documentIds: string[] = [];

        for (const file of files) {
          // if the file is already uploaded, we can just use the id
          if (file.id) {
            documentIds.push(file.id);
            continue;
          }

          const id = uuid();
          const fileBlob = await convertBase64ToFile(file.content, file.name, file.type);
          await uploadDocument({
            variables: {
              accountId,
              id,
              file: fileBlob,
              fileName: file.name,
              userId: token.sub,
            },
          });

          const response = await createDocumentRecord({
            variables: {
              input: {
                id,
                accountId,
                fileName: file.name,
                userId: token.sub,
                source: Source.S3,
                key: createS3Key(accountId, id),
                library: DocumentLibrary.Invest,
                category: 'Forms',
              },
            },
          });

          if (response.data?.createDocumentRecord?.id) {
            documentIds.push(response.data.createDocumentRecord.id);
          }
        }

        if (documentIds.length) {
          result.push({
            name,
            value: JSON.stringify(documentIds),
          });
        }
      }

      return result;
    },
    [accountId, token.sub, createDocumentRecord, uploadDocument]
  );

  return useMemo(
    () => ({ uploadDocuments, loading: documentLoading || creatingDocument }),
    [uploadDocuments, documentLoading, creatingDocument]
  );
};

const getRemovedDocumentsIds = (existingDocumentIds: string[], answers: SurveyFileAnswer[]) => {
  const addedDocumentIds = answers.map((answer) => answer.id).filter(Boolean);
  return existingDocumentIds.filter((id) => !addedDocumentIds.includes(id));
};

/**
 * Hook to handle survey submission
 * @param Object
 * @returns
 */
export const useSubmitData = ({
  accountId,
  surveyId,
  model,
  version,
  uploadedDocumentsIds,
}: SurveySubmissionOptions) => {
  const isV2 = useIsV2();
  const [searchParams] = useSearchParams();
  const contributorId = isV2 ? searchParams.get('contributorId') : null;

  const { uploadDocuments, loading: uploadingDocuments } = useUploadDocuments(accountId);
  const { saveSubmission, loading: savingSubmission } = useSetSubmission(surveyId, contributorId || accountId);
  const [deleteDocuments, { loading: deletingDocuments }] = useMutation<
    Pick<Mutation, 'deleteDocumentRecords'>,
    MutationDeleteDocumentRecordsArgs
  >(DELETE_DOCUMENT_RECORDS);

  const fileQuestions = useMemo(() => {
    if (!model) {
      return [];
    }
    return model
      .getAllQuestions()
      .filter((question) => question.getType() === 'file')
      .map((question) => question.name);
  }, [model]);

  const submitData = useCallback(
    async (status: SurveySubmissionStatusInput, onCompleted: () => void, onError: (message: any) => any) => {
      if (!model) {
        return;
      }
      // format non-file answers for submission
      const answers = formatAnswersForSubmission(model.data)
        .filter((answer) => !fileQuestions.includes(answer.name))
        .filter((item) => isAnswered(item.value));

      // format file answers for submission
      const fileAnswers = Object.entries(model.data)
        .filter((answer) => fileQuestions.includes(answer[0]))
        .reduce<{ [name: string]: SurveyFileAnswer[] }>((acc, [name, files]) => {
          acc[name] = files as SurveyFileAnswer[];
          return acc;
        }, {});

      // upload documents if available
      if (Object.keys(fileAnswers).length > 0) {
        const uploadedFiles = await uploadDocuments(fileAnswers);
        if (uploadedFiles.length > 0) {
          answers.push(...uploadedFiles);
        }
      }

      // remove documents if available
      const removedDocuments = getRemovedDocumentsIds(uploadedDocumentsIds, Object.values(fileAnswers).flat());
      if (removedDocuments.length > 0) {
        await deleteDocuments({
          variables: {
            ids: removedDocuments,
          },
        });
      }

      // add empty answers for unanswered questions
      const formQuestions = model.getAllQuestions();

      const nonAnsweredQuestions = formQuestions.filter((question) => {
        const answer = model.data[question.name];
        return !isAnswered(answer);
      });

      const nonAnsweredQuestionsAnswers = getNonAnsweredQuestionsAnswers(nonAnsweredQuestions);
      const answeredQuestionsAnswers = answers;

      const answersToSend = [...nonAnsweredQuestionsAnswers, ...answeredQuestionsAnswers];

      await saveSubmission({
        variables: {
          accountId: contributorId || accountId,
          surveyId,
          submission: {
            surveyId: surveyId!,
            accountId: contributorId || accountId,
            answers: answersToSend,
            status,
            version,
          },
        },
        onCompleted,
        onError,
      });
    },
    [
      contributorId,
      accountId,
      fileQuestions,
      deleteDocuments,
      model,
      saveSubmission,
      surveyId,
      version,
      uploadedDocumentsIds,
      uploadDocuments,
    ]
  );

  return useMemo(
    () => ({
      submitData,
      loading: savingSubmission || uploadingDocuments || deletingDocuments,
    }),
    [deletingDocuments, savingSubmission, uploadingDocuments, submitData]
  );
};

export type GetReceivedSurveyByIdResponse = Pick<Query, 'getReceivedSurveyById'>;

export function useGetReceivedSurveyById(id: string, accountId: string) {
  return useQuery<GetReceivedSurveyByIdResponse, QueryGetReceivedSurveyByIdArgs>(GET_RECEIVED_SURVEY_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      id,
      accountId,
    },
  });
}

export const useGetReceivedSurveyByIdV2 = (id: string, accountId: string) => {
  return usePageSuspendingQuery<GetReceivedSurveyByIdResponse, QueryGetReceivedSurveyByIdArgs>(
    GET_RECEIVED_SURVEY_BY_ID,
    {
      variables: {
        id,
        accountId,
      },
    }
  );
};
