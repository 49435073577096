import { useEffect } from 'react';
import { Model } from 'survey-core';
import { Modal, styled } from '@rio/ui-components';
import { Survey as SurveyUi } from 'survey-react-ui';
import { ReceivedSurvey, SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { ThemeV2Json } from '../../Surveys/Themes/theme-v2';
import { SurveyInfo } from './SurveyInfo';
import { SurveyPagination } from './SurveyPagination';
import 'survey-core/defaultV2.min.css';

const SurveyContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
`;

const StyledSurveyUi = styled(SurveyUi)`
  .sd-title {
    color: ${({ theme }) => theme.sys.color.onSurface};
    font-weight: 300;
  }
`;

type SurveyLayoutProps = {
  model: Model | null;
  survey: ReceivedSurvey;
  onDismiss: () => void;
  header?: React.ReactNode;
  expanded?: boolean;
  readonly?: boolean;
  children?: React.ReactNode;
  sendFeedback?: (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;
  onValueChanged?: (model: Model) => void;
};

export const SurveyLayout = (props: SurveyLayoutProps) => {
  const { model, readonly, survey, sendFeedback, header, onValueChanged, children, expanded, onDismiss } = props;

  useEffect(() => {
    if (model) {
      model.applyTheme(ThemeV2Json);
    }
  }, [model]);

  const body = (
    <SurveyContainer>
      {readonly ? <SurveyInfo readonly survey={survey} sendFeedback={sendFeedback} /> : <SurveyInfo survey={survey} />}

      {header}
      <StyledSurveyUi
        model={model}
        rootNodeId="Survey__element"
        mode={readonly ? 'display' : undefined}
        onValueChanged={!readonly ? onValueChanged : undefined}
      />
      <SurveyPagination model={model} />
      {children}
    </SurveyContainer>
  );

  return expanded ? (
    <Modal grid={{ xs: 11 }} title={survey.name} onClose={onDismiss} open>
      {body}
    </Modal>
  ) : (
    body
  );
};
