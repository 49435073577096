import { gql } from '@apollo/client';

export const GET_ALIAS_BY_ID = gql`
  query GetAliasByIdAndType($aliasId: ID!, $accountId: ID!, $aliasType: AliasType!, $energyType: TransactionType) {
    getAliasByIdAndType(aliasId: $aliasId, accountId: $accountId, aliasType: $aliasType, energyType: $energyType) {
      id
      name
      parentId
      aliasType
      parentItem {
        id
        name
      }
    }
  }
`;

export const DELETE_ALIAS_BY_ID_AND_TYPE = gql`
  mutation DeleteAliasByIdAndType($aliasId: ID!, $accountId: ID!, $aliasType: AliasType!) {
    deleteAliasByIdAndType(aliasId: $aliasId, accountId: $accountId, aliasType: $aliasType)
  }
`;

export const GET_TREATMENT_PROCESSES = gql`
  query GetTreatmentProcesses {
    getAllWasteTreatmentProcesses {
      id
      name
    }
  }
`;

export const GET_CONTAINER_TYPES_BY_ACCOUNT_ID = gql`
  query GetContainerTypesByAccountId($filterValue: String) {
    getContainerTypeSizePage(page: 1, pageSize: 50, filterValue: $filterValue) {
      typeSizes {
        id
        name
      }
    }
  }
`;

export const UPDATE_ALIAS_DATA = gql`
  mutation updateAlias($id: ID!, $name: String!, $parentId: ID!, $accountId: ID!, $aliasType: AliasType!) {
    updateAlias(input: { id: $id, name: $name, parentId: $parentId, accountId: $accountId, aliasType: $aliasType }) {
      id
      name
      parentId
      aliasType
      parentItem {
        id
        name
        typeId
      }
    }
  }
`;

export const GET_LOCATIONS_BY_ACCOUNT = gql`
  query GetLocationsByAccountId(
    $accountId: ID!
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getLocationsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;

export const GET_ALL_FUEL_TYPES = gql`
  query GetAllFuelTypes {
    getAllFuelTypes {
      id
      name
    }
  }
`;

export const GET_ALL_TRANSPORT_FUEL_TYPES = gql`
  query GetAllTransportFuelTypes {
    getAllTransportFuelTypes {
      id
      name
    }
  }
`;

export const GET_ALL_TRANSPORT_SUB_TYPES = gql`
  query GetAllTransportFuelTypes {
    getAllTransportSubTypes {
      id
      name
    }
  }
`;

export const GET_ALL_JOURNEY_TARGETS = gql`
  query GetAllJourneyTargets {
    getAllJourneyTargets {
      id
      name
    }
  }
`;

export const GET_ALL_TRANSPORT_TYPES = gql`
  query GetAllTransportTypes {
    getAllTransportTypes {
      id
      name
    }
  }
`;

export const GET_ALL_UNIT_TYPES = gql`
  query GetAllUnits {
    getAllUnits {
      id
      name
    }
  }
`;

export const GET_ALL_ACCURACY_TYPES = gql`
  query GetAllAccuracyTypes {
    getAllAccuracyTypes {
      id
      name
    }
  }
`;

export const GET_ALL_CURRENCY_TYPES = gql`
  query GetAllCurrencyTypes {
    getAllCurrencyTypes {
      id
      name
    }
  }
`;

export const GET_ALL_MATERIAL_ORIGIN_TYPES = gql`
  query GetAllMaterialOriginTypes {
    getAllMaterialOriginTypes {
      id
      name
    }
  }
`;

export const GET_ALIASES_PAGE = gql`
  query GetAliasesPage(
    $accountId: ID!
    $aliasType: AliasType!
    $energyType: TransactionType
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getAliasesPage(
      accountId: $accountId
      aliasType: $aliasType
      energyType: $energyType
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        name
        parentId
        parentItem {
          id
          name
          typeId
        }
        aliasType
        energyType
      }
    }
  }
`;
