import password from 'generate-password';
import _ from 'lodash';
import { tryParseSpreadsheet } from '../../../utils/excelUtils';
import { getIndexedTagsAndLocations } from '../../../utils/dataUtils';
import { ACCOUNT, LOCATION, TAG } from '../../../constants/scopes';
import { isMobilePhone, isNumeric } from 'validator';

const splitValue = (row, column) => _.get(row, column, '').split(',');

const usersParser = async (file, accountId, roleOptions, client) => {
  const [err, rows] = await tryParseSpreadsheet(file);
  if (err) {
    return [[err], null];
  }
  const uniqResourceNames = rows.map((row) => splitValue(row, 'Allowed Resources')).flat();
  const { tags, locations } = await getIndexedTagsAndLocations(client, accountId, uniqResourceNames);
  const validationErrors = validateRows(rows, roleOptions, tags, locations);
  if (validationErrors.length) {
    return [validationErrors, null];
  }
  return [[], converterUsers(rows, accountId, roleOptions, locations, tags)];
};

const getAccessLevel = (row) => (row['Access Level'] ? row['Access Level'].toUpperCase() : ACCOUNT);

const validateRows = (rows, roleOptions, tags, locations) => {
  const errors = [];
  if (!rows.length) {
    errors.push('Your spreadsheet is empty, please add some rows');
  }

  const userTypes = roleOptions.map(({ label }) => label);
  const userTypesLowerCased = userTypes.map((type) => type.toLowerCase());

  rows.forEach((row, rowIndex) => {
    const rowNumber = rowIndex + 2;
    const userType = row['User Type'] || '';
    const accessLevel = getAccessLevel(row);
    const accessLevels = [ACCOUNT, LOCATION, TAG];

    if (!row['Email Address']) {
      errors.push(`Row ${rowNumber}: "Email Address" should be defined`);
    }
    if (!userType) {
      errors.push(`Row ${rowNumber}: "User Type" should be defined`);
    }
    if (!userTypesLowerCased.includes(userType.toLowerCase())) {
      errors.push(`Row ${rowNumber}: "User Type" should equal to one of: ${userTypes.join(', ')}`);
    }
    if (!row['First Name']) {
      errors.push(`Row ${rowNumber}: "First Name" should be defined`);
    }
    if (!row['Last Name']) {
      errors.push(`Row ${rowNumber}: "Last Name" should be defined`);
    }
    if (!!row['Mobile Number (Optional)'] && !isMobilePhone(row['Mobile Number (Optional)'])) {
      errors.push(`Row ${rowNumber}: "Mobile Number" should be valid`);
    }
    if (!!row['Phone Number (Optional)'] && !isNumeric(row['Phone Number (Optional)'])) {
      errors.push(`Row ${rowNumber}: "Phone Number" should be numeric`);
    }
    if (!accessLevels.includes(accessLevel)) {
      errors.push(`Row ${rowNumber}: "Access Level" should equal to one of: ${accessLevels.join(', ')}`);
    }
    if (accessLevel === LOCATION && row['Allowed Resources']) {
      const list = row['Allowed Resources'].split(',');
      list.forEach((location) => {
        if (!locations[location.trim()]) {
          errors.push(`Row ${rowNumber}: Location ${location} wasn't found in your account`);
        }
      });
    }
    if (accessLevel === TAG && row['Allowed Resources']) {
      const list = row['Allowed Resources'].split(',');
      list.forEach((tag) => {
        if (!tags[tag.trim()]) {
          errors.push(`Row ${rowNumber}: Tag ${tag} wasn't found in your account`);
        }
      });
    }
  });
  return errors;
};

const converterUsers = (sheet, accountId, roleOptions, locations, tags) =>
  sheet.map((user) => ({
    username: user['Email Address'],
    password: password.generate({ length: 32, numbers: true, symbols: false }),
    roles: roleOptions.find(({ label }) => label.toLowerCase() === user['User Type'].toLowerCase()).value,
    first_name: user['First Name'],
    last_name: user['Last Name'],
    jobTitle: user['Job Title (Optional)'] || null,
    department: user['Department (Optional)'] || null,
    phone: user['Phone Number (Optional)'] || null,
    mobile: user['Mobile Number (Optional)'] || null,
    account_id: accountId,
    accessLevel: getAccessLevel(user),
    allowedLocationsIds:
      getAccessLevel(user) === LOCATION
        ? user['Allowed Resources'].split(',').map((location) => locations[location.trim()].id)
        : [],
    allowedTagsIds:
      getAccessLevel(user) === TAG ? user['Allowed Resources'].split(',').map((tag) => tags[tag.trim()].id) : []
  }));

export { usersParser };
