"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readFile = exports.getCroppedImageBlobUrl = exports.createImage = void 0;
const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.crossOrigin = 'Anonymous';
    image.src = url;
});
exports.createImage = createImage;
const getCroppedImageBlobUrl = async (imageSrc, areaPixels, imageType) => {
    const image = await (0, exports.createImage)(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate (if needed) in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;
    if (ctx) {
        // translate canvas context to a central location on image to allow rotating around the center (if needed).
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.translate(-safeArea / 2, -safeArea / 2);
        // draw image and store data.
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
        const data = ctx.getImageData(0, 0, safeArea, safeArea);
        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = areaPixels.width;
        canvas.height = areaPixels.height;
        // paste generated image with correct offsets for x,y crop values.
        ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - areaPixels.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - areaPixels.y));
    }
    return canvas.toDataURL(imageType, 0.7);
};
exports.getCroppedImageBlobUrl = getCroppedImageBlobUrl;
const readFile = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), { once: true });
    reader.addEventListener('error', () => reject(new Error('Error at uploading the files')), {
        once: true,
    });
    reader.readAsDataURL(file);
});
exports.readFile = readFile;
