import { useApolloClient } from '@apollo/client';
import { TransactionType } from '@rio/rio-types';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_BATCHES_PAGE, GET_DATA_BATCH_EXPORT_URL } from '~/containers/DataContainer/index.queries';
import { useCurrentAccountId } from '~/hooks';
import { downloadFileFromUrl } from '~/utils';

export function useV2UploadGrid(dataType: TransactionType) {
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);
  const [confirmQuickViewId, setConfirmQuickViewId] = useState<string | null>(null);
  const [confirmRollbackId, setConfirmRollbackId] = useState<string | null>(null);
  const [erroredRowId, setErroredRowId] = useState<string | null>(null);
  const client = useApolloClient();
  const navigate = useNavigate();
  const accountId = useCurrentAccountId();
  return {
    confirmQuickViewId,
    confirmRollbackId,
    confirmDeleteId,
    erroredRowId,
    handleErrorClick: useCallback((id: string | null) => setErroredRowId(id), [setErroredRowId]),
    handleDeleteClick: useCallback(
      (id: string | null) => {
        setConfirmDeleteId(id);
      },
      [setConfirmDeleteId]
    ),
    handleQuickviewClick: useCallback(
      (id: string | null) => {
        setConfirmQuickViewId(id);
      },
      [setConfirmQuickViewId]
    ),
    handleRollbackClick: useCallback(
      (id: string | null) => {
        setConfirmRollbackId(id);
      },
      [setConfirmRollbackId]
    ),
    handleDownloadClick: useCallback(
      async (id: string) => {
        try {
          const { data } = await client.query({
            query: GET_DATA_BATCH_EXPORT_URL,
            variables: { id },
          });

          downloadFileFromUrl(data.getDataImportBatchExportUrl);
        } catch (error) {}
      },
      [client]
    ),
    handleActionClick: useCallback((id: string) => navigate(`${location.pathname}/alias/${id}`), [navigate]),
    fetchRows: useCallback(
      async (variables) => {
        const {
          data: {
            getDataImportBatchesPage: { rows, totalRows },
          },
        } = await client.query({
          query: GET_BATCHES_PAGE,
          variables: { ...variables, accountId, type: dataType },
          fetchPolicy: 'network-only',
        });

        return { rows, totalRows };
      },
      [accountId, client, dataType]
    ),
  };
}
