import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { useEffect, useCallback, useState } from 'react';
import { Filters } from '@rio/rio-types';
import { mapFilterModel, mapSortModel } from '../utils';
import { FetchRowsFunction, FetchRowsResult } from '../containers/DataContainer/UploadContainer/types';
import { AgGrid } from './useAgGrid';

const POLL_INTERVAL_SECONDS = 10;

export function useUploadPolling(
  fetchRows: FetchRowsFunction,
  agGrid: AgGrid,
  isGridReady: boolean,
  gridOptions: GridOptions
) {
  const [currentResponse, setUpdatedResponse] = useState<FetchRowsResult | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const createRowFetcher = useCallback(
    (params) => {
      const { startRow, sortModel, filterModel } = params?.request;
      const filters: Filters = mapFilterModel(filterModel);
      return fetchRows({
        offset: startRow || 0,
        limit: gridOptions.paginationPageSize,
        sort: mapSortModel(sortModel),
        filters,
      });
    },
    [fetchRows, gridOptions.paginationPageSize]
  );

  const getAllRows = useCallback(() => {
    agGrid.api?.setServerSideDatasource({
      async getRows(rowParams: IServerSideGetRowsParams) {
        try {
          const response: FetchRowsResult = await createRowFetcher(rowParams);

          setUpdatedResponse(response);

          const { rows, totalRows } = response;
          rowParams.success({
            rowData: rows,
            rowCount: totalRows,
          });

          return { rows, totalRows };
        } catch (err) {
          rowParams.fail();
          throw err;
        }
      },
    });
  }, [agGrid.api, createRowFetcher]);

  useEffect(() => {
    if (!isGridReady) {
      return;
    }

    getAllRows();

    return () => {
      agGrid?.api?.destroy();
    };
  }, [isGridReady, getAllRows, agGrid.api]);

  useEffect(() => {
    if (!currentResponse || intervalId) {
      return;
    }

    const pollIntervalMs = POLL_INTERVAL_SECONDS * 1000;

    const poll = async () => agGrid?.api?.refreshServerSide();

    const newIntervalId = setInterval(poll, pollIntervalMs);
    setIntervalId(newIntervalId);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentResponse, intervalId, agGrid.api]);
}
