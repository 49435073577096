import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getDashboardLink } from '../../utils';
import ReportTile from './ReportTile';

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const ReportingReportsGrid = ({ items }) => {
  return (
    <TileContainer>
      {items.map((item) => (
        <Link
          key={item.id}
          name={`ReportingReportsGrid__Link ReportingReportsGrid__Link__${item.id}`}
          to={getDashboardLink(item)}
        >
          <ReportTile
            reportId={item.providerDashboardId}
            key={item.id}
            title={item.name}
            subject={item.area}
            description={item.description}
            thumbnail={item.thumbnailUrl}
          />
        </Link>
      ))}
    </TileContainer>
  );
};

export default ReportingReportsGrid;
