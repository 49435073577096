import { Modal, Row, Col, Button, TextArea } from 'rio-ui-components';
import { useCallback, useState } from 'react';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import styled from 'styled-components';
import { InputEvent } from '../../types';
import { InfoButton } from './InfoButton';

const Form = styled.form`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const Title = styled.h1`
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.light.text};
`;
const Subtitle = styled.h5`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  font-size: 16px;
  line-height: 19px;
`;
const FeedbackField = styled(TextArea)`
  height: 90px;
  margin-bottom: 12px;
`;
const Description = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.text.normal.background};
`;
const Buttons = styled(Row)`
  justify-content: space-between;
`;
const CancelButton = styled(InfoButton)`
  width: 95%;
  font-weight: 600;
`;
const WarningButton = styled(Button)`
  color: ${(props) => props.theme.colors.text.light.text};
  font-weight: 600;
`;

interface SendFeedbackModalProps {
  intention: SurveySubmissionFeedbackStatus;
  onDismiss: () => void;
  onFeedback: (intention: SurveySubmissionFeedbackStatus, feedback: string | null) => void;
}

export function SendFeedbackModal({ intention, onDismiss, onFeedback }: SendFeedbackModalProps) {
  const approve = intention === SurveySubmissionFeedbackStatus.Approved;

  const [feedback, setFeedback] = useState<string | null>(null);

  const [sending, setSending] = useState(false);

  const handleFeedbackChange = useCallback((e: InputEvent) => setFeedback(e.target.value), [setFeedback]);

  const handleConfirm = useCallback(async () => {
    try {
      setSending(true);
      await onFeedback(intention, feedback);
    } finally {
      setSending(false);
      onDismiss();
    }
  }, [onFeedback, feedback, intention, onDismiss]);

  return (
    <Modal show size="md" height="auto" dismissable enableEscapeKey>
      <Form>
        <Title>
          {approve
            ? 'Are you sure you want to approve this response?'
            : 'Are you sure you want to request changes this response?'}
        </Title>
        <Subtitle>Comments for recipients (optional)</Subtitle>
        <FeedbackField maxLength={1000} onChange={handleFeedbackChange} />
        <Description>These notes will appear in the email sent to your recipients</Description>
        <Buttons container>
          <Col item span={6}>
            <CancelButton disabled={sending} onClick={onDismiss}>
              No, keep as is
            </CancelButton>
          </Col>
          <Col item span={6}>
            {approve ? (
              <Button onClick={handleConfirm} type="button" color="success" disabled={sending}>
                Yes, approve
              </Button>
            ) : (
              <WarningButton onClick={handleConfirm} type="button" color="warning" disabled={sending}>
                Yes, request changes
              </WarningButton>
            )}
          </Col>
        </Buttons>
      </Form>
    </Modal>
  );
}
