import { styled, useTheme } from '@rio/ui-components';
import { getPriorityIconProps } from '../../priorityIconPropsV2';

const WrapperStyled = styled('div')<{ fill: string }>`
  display: flex;
  align-items: center;

  path {
    fill: ${(props) => props.fill};
  }
`;

export const PriorityCell = (props) => {
  const theme = useTheme();
  const {
    data: { priority },
  } = props;

  const { icon: Icon, fill } = getPriorityIconProps(priority, theme);

  return (
    <WrapperStyled fill={fill}>
      <Icon />
    </WrapperStyled>
  );
};
