import React, { useState } from 'react';
import { TransactionType } from '@rio/rio-types';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { Text, Button, Carousel, HeadingEdit, TranspositionColumn } from 'rio-ui-components';

import wasteColumns from '../../../containers/DataContainer/WasteContainer/UploadContainer/columns.json';
import electricityColumns from '../../../containers/DataContainer/ElectricityContainer/UploadContainer/columns.json';
import gasColumns from '../../../containers/DataContainer/GasContainer/UploadContainer/columns.json';
import waterColumns from '../../../containers/DataContainer/WaterContainer/UploadContainer/columns.json';
import transportColumns from '../../../containers/DataContainer/TransportContainer/UploadTransportTransactions/columns.json';
import fuelColumns from '../../../containers/DataContainer/FuelContainer/UploadFuelTransactions/columns.json';
import materialsColumns from '../../../containers/DataContainer/MaterialsContainer/UploadContainer/columns.json';
import hotelStaysColumns from '../../../containers/DataContainer/HotelStaysContainer/UploadContainer/columns.json';
import heatSteamColumnns from '../../../containers/DataContainer/HeatSteamContainer/UploadContainer/columns.json';
import refrigerantsColumnns from '../../../containers/DataContainer/RefrigerantsContainer/UploadContainer/columns.json';

const mandatory = {
  [TransactionType.Waste]: [
    'Waste In Location',
    'Waste Out Location',
    'Waste Stream',
    'Waste Contractor',
    'Container Type',
    'Treatment Process',
  ],
  [TransactionType.Electricity]: [
    'Meter Name',
    'Start Date (dd/mm/yyyy)',
    'End Date (dd/mm/yyyy)',
    'Quantity (kWh)',
    'Act/Est',
  ],
  [TransactionType.Gas]: [
    'Meter Name',
    'Start Date (dd/mm/yyyy)',
    'End Date (dd/mm/yyyy)',
    'Quantity (kWh)',
    'Act/Est',
  ],
  [TransactionType.Water]: [
    'Meter Name',
    'Start Date (dd/mm/yyyy)',
    'End Date (dd/mm/yyyy)',
    'Act/Est',
    'Quantity (m3)',
    'Tariff (£/m3)',
  ],
  [TransactionType.Transport]: [
    'Date',
    'Location',
    'Type',
    'Subtype',
    'Origin of Travel',
    'Destination of Travel',
    'Purpose of Journey',
    'Fuel Type',
    'Notes',
  ],
  [TransactionType.Fuel]: ['Location', 'Date (dd/mm/yyyy)', 'Quantity', 'Quantity units'],
  [TransactionType.Materials]: ['Location', 'Date (dd/mm/yyyy)', 'Material', 'Origin'],
  [TransactionType.Hotelstays]: [
    'StartDate',
    'EndDate',
    'Location',
    'Hotel stay / Home working country',
    'Data Provider',
  ],
  [TransactionType.Heatsteam]: [
    'Meter Name',
    'Start Date (dd/mm/yyyy)',
    'End Date (dd/mm/yyyy)',
    'Quantity (kWh)',
    'Act/Est',
  ],
  [TransactionType.Refrigerants]: ['Location', 'End Date (dd/mm/yyyy)', 'Gas Type', 'Gas Group', 'Quantity', 'Act/Est'],
};

const createFieldList = (columns, type) =>
  columns.map((item) => ({
    name: item,
    required: mandatory[type].includes(item),
  }));

const fieldList = {
  [TransactionType.Waste]: createFieldList(wasteColumns, TransactionType.Waste),
  [TransactionType.Electricity]: createFieldList(electricityColumns, TransactionType.Electricity),
  [TransactionType.Gas]: createFieldList(gasColumns, TransactionType.Gas),
  [TransactionType.Water]: createFieldList(waterColumns, TransactionType.Water),
  [TransactionType.Transport]: createFieldList(transportColumns, TransactionType.Transport),
  [TransactionType.Fuel]: createFieldList(fuelColumns, TransactionType.Fuel),
  [TransactionType.Materials]: createFieldList(materialsColumns, TransactionType.Materials),
  [TransactionType.Hotelstays]: createFieldList(hotelStaysColumns, TransactionType.Hotelstays),
  [TransactionType.Heatsteam]: createFieldList(heatSteamColumnns, TransactionType.Heatsteam),
  [TransactionType.Refrigerants]: createFieldList(refrigerantsColumnns, TransactionType.Refrigerants),
};

const dittoColumns = (type) => {
  const headerColumn = sortBy(fieldList[type], 'name');
  return headerColumn.map((v) => ({ value: v.name, label: v.name }));
};

const FlexContainerStyled = styled.div`
  ${({ theme }) => `
    padding: ${theme.geometry.md.spacing};
    background: ${theme.colors.overlay.light.background};
    margin: ${theme.geometry.md.spacing} 0; 
    overflow: hidden;
  `};
`;

const CarouselContainer = styled.div`
  ${({ theme }) => `
    padding: ${theme.geometry.md.spacing} ${theme.geometry.sm.spacing}; 
    align-self: stretch;
    background: ${theme.colors.basic.white};
    margin-top: ${theme.geometry.md.spacing}; 
  `};
`;

const ButtonContainerStyled = styled.div`
  text-align: right;
`;

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
`;

const generateColumns = (headers, transpositions) => {
  if (!transpositions)
    return headers.map((column) => {
      return { userColumn: column, dittoColumn: null, ignored: null };
    });

  const { mappings, ignored } = transpositions;
  const ignoredArray = ignored.map((column) => column.toUpperCase().trim());
  const mappingsArray = mappings.map((column) => column.columnName.toUpperCase().trim());
  return headers.map((userColumn) => {
    const columnName = String(userColumn).toUpperCase().trim();
    const userColumnIgnored = ignoredArray.indexOf(columnName) !== -1;
    const mappingsIndex = mappingsArray.indexOf(columnName);
    const dittoColumn = mappingsIndex !== -1 ? mappings[mappingsIndex].transposition : null;

    return { userColumn, dittoColumn, ignored: userColumnIgnored };
  });
};

const reduceActionableItems = (accumulator, currentValue) =>
  !!currentValue.dittoColumn || currentValue.ignored ? accumulator : accumulator + 1;

const TranspositionSection = (props) => {
  const defaultTranspositions = generateColumns(props.headers, props.transpositions);
  const [transpositions, setTranspositions] = useState(defaultTranspositions);
  const [transpositionName, setTranspositionName] = useState(props.templateName || props.defaultTemplateValue);
  const [isEditing, setEditing] = useState(false);

  const generateTranspositionColumn = (column, index) => {
    return (
      <TranspositionColumn
        title={column.userColumn}
        name={`transposition-section__transposition-column__${column.userColumn}`}
        key={`transposition-section__transposition-column__${column.userColumn}__${index}`}
        value={column.dittoColumn || 'Unmatched'}
        ignored={column.ignored}
        options={dittoColumns(props.type).filter(
          (dc) => !transpositions.find((trans) => trans.dittoColumn === dc.value)
        )}
        width="250px"
        onMatch={(e) =>
          setTranspositions((prev) => [
            ...prev.slice(0, index),
            { ...prev[index], dittoColumn: e.target.value },
            ...prev.slice(index + 1),
          ])
        }
        onIgnore={(e) =>
          setTranspositions((prev) => [
            ...prev.slice(0, index),
            { ...prev[index], dittoColumn: null, ignored: e.target.checked },
            ...prev.slice(index + 1),
          ])
        }
        readOnly={props.readOnly}
        match={transpositions[index].dittoColumn}
        ignoreLabel="Ignore column"
      />
    );
  };

  const handleHeadingChange = (event) => setTranspositionName(event.target.value);

  const handleSaveClick = (e, onSave, defaultTemplateValue, actionableItemsRemaining) => {
    if (props.readOnly) return onSave({ transpositionName, transposition: transpositions });
    if (!actionableItemsRemaining && transpositionName !== defaultTemplateValue)
      return onSave({ transpositionName, transposition: transpositions });
    return false;
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const { readOnly, defaultTemplateValue, onSave = () => {}, transpositionNames } = props;
  const actionableItemsRemaining = transpositions.reduce(reduceActionableItems, 0);
  const missingRequiredFields =
    fieldList[props.type]
      .filter((header) => header.required)
      .filter((header) => !transpositions.find((trans) => trans.dittoColumn === header.name)) && !props.isHalfHourly;
  const nameAvailable = transpositionNames && !transpositionNames.find((t) => t === transpositionName);

  return (
    <div>
      <FlexContainerStyled>
        <HeadingEdit
          name="TranspositionSection-HeadingEdit"
          size="lg"
          readOnly={readOnly}
          inline
          placeholder={transpositionName}
          color={readOnly ? 'dark' : 'tertiary'}
          onEditChange={handleEditClick}
          editing={isEditing}
          onChange={handleHeadingChange}
        />
        <Text name="TranspositionSection-MappedColsText" color="dark">
          {readOnly
            ? 'Here are your mapped columns'
            : 'Create a template to map your excel columns to Rio’s columns. We’ll remember this for next time.'}
        </Text>

        <CarouselContainer>
          <Carousel
            show={3}
            scroll={3}
            name="TranspositionSection__Carousel"
            responsive={[
              {
                breakpoint: 1250,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1660,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
          >
            {transpositions.map(generateTranspositionColumn)}
          </Carousel>
        </CarouselContainer>
      </FlexContainerStyled>
      {!actionableItemsRemaining && !!missingRequiredFields.length && (
        <Text color="danger">{`There are columns missing from your upload. ${missingRequiredFields
          .map((field) => field.name)
          .join(
            ', '
          )} are required fields and need to be matched. Note, this applies to every row in your upload.`}</Text>
      )}
      <ButtonContainerStyled>
        <ButtonStyled
          name="TranspositionSection-Upload-Button"
          inline
          disabled={
            actionableItemsRemaining ||
            (!readOnly && transpositionName === defaultTemplateValue) ||
            (!readOnly && !nameAvailable) ||
            !!missingRequiredFields.length
          }
          color={
            actionableItemsRemaining ||
            (!readOnly && transpositionName === defaultTemplateValue) ||
            (!readOnly && !nameAvailable)
              ? 'danger'
              : 'success'
          }
          onClick={(e) => handleSaveClick(e, onSave, defaultTemplateValue, actionableItemsRemaining)}
        >
          {(actionableItemsRemaining && `${actionableItemsRemaining} not matched or ignored`) ||
            (!readOnly && transpositionName === defaultTemplateValue && 'Give your template a name') ||
            (!readOnly && !nameAvailable && 'Name already exists') ||
            (readOnly && 'Upload') ||
            'Save & Upload'}
        </ButtonStyled>
      </ButtonContainerStyled>
    </div>
  );
};

export default TranspositionSection;
