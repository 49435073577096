import React from 'react';
import { Row, Col, Heading, ProfilePicture, Button } from 'rio-ui-components';
import styled from 'styled-components';

import CustomRouteLink from '../../../components/CustomRouteLink';
import { useNavigate } from 'react-router-dom';
import { makeUrl } from '../../../utils';
import { LicencesCount } from '../../../components/AccountProductLicence';
import { useRoutes } from '~/hooks';

const AccountRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  flex: 0 0 auto;
`;

const AccountProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const StyledRow = styled(Row)`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const RightText = styled.div`
  padding-right: 24px;
  text-align: right;
  width: 145px;
  float: right;

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
`;

const AccountRow = (props) => {
  const navigate = useNavigate();
  const {
    accountId,
    businessName,
    profileImage,
    path,
    isCourseRows = false,
    record,
    permissions = {},
    licencesCountMap = new Map(),
  } = props;
  const routes = useRoutes();
  let manageLicenceUrl;
  const isManageLicenceVisible = permissions?.licenceService?.assignLicence && isCourseRows;
  // bundle or single course
  const isBundle = record?.inBundles?.length > 0;
  const id = isBundle ? record.inBundles[0] : record.id;

  if (isManageLicenceVisible) {
    manageLicenceUrl = makeUrl(`/${routes.configuration.root}/${routes.configuration.menu.learn.assignLicense}`, {
      accountId,
      productType: isBundle ? 'bundle' : 'course',
      productId: id,
    });
  }

  const rowUrl = isCourseRows ? manageLicenceUrl : `${path}/${accountId}`;

  return (
    <div>
      <StyledRow container>
        <Col span={isManageLicenceVisible ? 8 : 12}>
          <CustomRouteLink name="configuration-menu__item--contractors" to={rowUrl}>
            <AccountRowStyled name={`account-row--${accountId}`} container itemAlign="center">
              <AccountProfilePictureStyled
                name={`account-row__profile-picture--${accountId}`}
                src={profileImage}
                size="xs"
                accountName={businessName}
              />

              <Col container item>
                <Heading inline name={`account-row__name--${accountId}`} size="md">
                  {businessName}
                </Heading>
              </Col>
            </AccountRowStyled>
          </CustomRouteLink>
        </Col>
        {isManageLicenceVisible && (
          <Col span={4}>
            <RightText>
              <Button size="sm" onClick={() => navigate(manageLicenceUrl)}>
                <LicencesCount id={id} licencesCountMap={licencesCountMap} />
              </Button>
            </RightText>
          </Col>
        )}
      </StyledRow>
    </div>
  );
};

export default AccountRow;
