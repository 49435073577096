import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { hasUUID } from '../../../../utils/surveyNavigation';
import { useGetById, GetSurveyResponse } from '../../queries';
import { SurveyOverviewPage } from './SurveyOverviewPage';
import { ActionTypes, SurveyOverviewHeader } from './SurveyOverviewHeader';

interface SurveyByIdRetrieverProps {
  action?: ActionTypes;
}

export function SurveyByIdRetriever({ action }: SurveyByIdRetrieverProps) {
  const location = useLocation();
  const pathname = location.pathname;
  const pathId = pathname.split('/').find(hasUUID);
  const id = useMemo(() => hasUUID(pathname) && pathId, [pathId, pathname]);
  const isSubmission = pathname.includes('submission');
  const isResponseLog = pathname.includes('response-log');

  const result = useGetById(isSubmission || isResponseLog ? null : (id as string));

  const renderData = useCallback(
    (data: GetSurveyResponse) =>
      action ? (
        <SurveyOverviewHeader survey={data.getSurveyById} action={action} />
      ) : (
        <SurveyOverviewPage survey={data.getSurveyById} latest={false} />
      ),
    [action]
  );

  return result?.data ? renderData(result.data) : null;
}
