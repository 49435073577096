import { useMutation } from '@apollo/client';
import { UPDATE_USER_COURSE_PROGRESS } from './index.queries';
import { useUserToken } from '../../hooks/useUserToken';
import { NOTSTARTED, COMPLETE } from '../../constants/courseProgress';

export function useCompleteCourse(course, refetch) {
  const { token } = useUserToken();
  const [updateUserCourseProgress] = useMutation(UPDATE_USER_COURSE_PROGRESS, {
    onCompleted: refetch
  });
  return async () => {
    const currentCourseProgress = course.userCourseMetaData ? course.userCourseMetaData.courseProgress : NOTSTARTED;

    if (currentCourseProgress !== COMPLETE) {
      const variables = {
        userId: token.sub,
        courseId: course.id,
        courseProgress: COMPLETE
      };
      await updateUserCourseProgress({
        variables
      });
    }
  };
}
