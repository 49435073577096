import { gql } from '@apollo/client';

export const GET_DATA_TRACKER_VALIDATION_FILTERS = gql`
  query GetDataTrackerValidationFilters($accountId: ID!, $field: String!) {
    getDataTrackerValidationFilters(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
