import React from 'react';
import { Text } from 'rio-ui-components';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

const categoryColor = {
  GOVERNANCE: 'tertiary',
  DATA: 'quaternary',
  REPORTING: 'quaternary',
  DOCUMENTS: 'quaternary',
  ENGAGE: 'secondary'
};

const CategoryPill = styled.div`
  background-color: ${(props) => props.theme.colors[props.colour].normal.background};
  color: white;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
`;

export function CategoryCell(props) {
  const { data: task } = props;
  return (
    <Text align="left" name={`task-row__subject--${task.id}`}>
      <CategoryPill colour={categoryColor[task.category]}>{capitalize(task.category)}</CategoryPill>
    </Text>
  );
}
