import { Text } from '@rio/ui-components';

export const DescriptionCell = (props) => {
  const {
    data: { description },
  } = props;

  return (
    description && (
      <Text typescale="body" size="medium">
        {description}
      </Text>
    )
  );
};
