import { gql } from '@apollo/client';

export const GET_DATA_TRACKER_VALIDATION_RESULTS_BY_ACCOUNT_ID = gql`
  query GetDataTrackerValidationResults(
    $accountId: ID!
    $isIgnored: Boolean
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getDataTrackerValidationResults(
      accountId: $accountId
      isIgnored: $isIgnored
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        dataTrackerConfigId
        startDate
        endDate
        supplierName
        locations
        meters
        utility
        validationType
        note
        frequency
        reason
        __typename
      }
    }
  }
`;

export const UPDATE_DATA_TRACKER_VALIDATION_RESULT = gql`
  mutation UpdateDataTrackerValidationResults($input: [DataTrackerValidationResultInput]!) {
    updateDataTrackerValidationResults(input: $input) {
      id
    }
  }
`;
