/**
 * key is a dashboard id in our system
 */
export const defaultDashboardParams = {
  'a56cf0c8-27a2-45e2-8997-a746b4e7df4e': {
    Date: '(05) May 2022',
    Departments: 'All',
    ProjectName: 'All',
    SchemeName: '(Empty)',
  },
};
