import { Route, Navigate, useRoutes, createRoutesFromElements, useOutletContext } from 'react-router-dom';
import { useMemo } from 'react';
import { RioBot } from '../../ChatBot';
import ConfigurationContainer from '../../ConfigurationContainer';
import DataContainer from '../../DataContainer/V2';
import ReportingContainer from '../../ReportingContainer';
import GovernanceContainer from '../../GovernanceContainer/v2';
import { ContactContainer } from '../../ContactContainer/v2';
import { SurveysContainer } from '../../SurveysContainer/v2';
import { HomeContainer } from '../../HomeContainer/v2';
import { DocumentContainer } from '../../DocumentContainer/DocumentContainerV2';
import { LearnContainer } from '../../LearnContainer/v2';
import { TasksContainer } from '../../TasksContainer/v2';
import ProjetcViewContainer from '../../ProjectContainer/ProjectView';
import TasksViewContainer from '../../TasksContainer/TaskView/v2';
import MarketContainer from '../../MarketContainer';
import { FlowsContainer } from '../../FlowsContainer/v2';
import { ProfileContainer } from '../../ProfileContainer/v2';
import { OutletContext } from './types';

import { useLicencesPaidSubscription, useRoutes as useRouterRoutes, getConfigurationRoute } from '~/hooks';

export function AppRoutes() {
  const [addItems, setCreateMutation, setOnComplete, applicationData] = useOutletContext<OutletContext>();

  useLicencesPaidSubscription();

  const routes = useRouterRoutes();

  const setMutation = (uploadMutation, onCompleteAction) => {
    setCreateMutation(uploadMutation);
    if (onCompleteAction) {
      setOnComplete(onCompleteAction);
    }
  };
  const {
    getAccountInfo: { accessControls },
    getUserInfoById: {
      role: { permissions },
    },
  } = applicationData;

  const hasAccessToConfiguration = useMemo(() => getConfigurationRoute(permissions), [permissions]);

  const elements = useRoutes(
    createRoutesFromElements(
      <>
        <Route path={routes.home.root} element={<HomeContainer applicationData={applicationData} />} />
        <Route path={`${routes.learn.root}/*`} element={<LearnContainer applicationData={applicationData} />} />
        <Route path={`${routes.governance.root}/*`} element={<GovernanceContainer />} />
        <Route path={`${routes.surveys.root}/*`} element={<SurveysContainer applicationData={applicationData} />} />
        <Route path={`${routes.data.root}/*`} element={<DataContainer applicationData={applicationData} />} />
        <Route path={`${routes.reporting.root}/*`} element={<ReportingContainer />} />
        <Route path={`${routes.docs.root}/*`} element={<DocumentContainer />} />
        <Route
          path={`${routes.tasks.root}/:id`}
          element={<TasksViewContainer hasProjectsAccess={accessControls.projects} />}
        />
        <Route
          path={`${routes.tasks.root}/*`}
          element={<TasksContainer hasProjectsAccess={accessControls.projects} hasTasksAccess={accessControls.tasks} />}
        />
        <Route path={`${routes.projects.root}/:id`} element={<ProjetcViewContainer />} />
        <Route
          path={`${routes.projects.root}/*`}
          element={<TasksContainer hasProjectsAccess={accessControls.projects} hasTasksAccess={accessControls.tasks} />}
        />
        {!!hasAccessToConfiguration && (
          <Route
            path={`${routes.configuration.root}/*`}
            element={<ConfigurationContainer massUploadFunction={addItems} setCreateMutation={setMutation} />}
          />
        )}
        {!!accessControls.intelligence && <Route path={`${routes.intelligence.root}/*`} element={<RioBot />} />}
        {!!accessControls.flows && <Route path={`${routes.flows.root}/*`} element={<FlowsContainer />} />}
        <Route path={`${routes.header.contact}/*`} element={<ContactContainer />} />
        <Route path={`${routes.header.profile}/*`} element={<ProfileContainer />} />
        <Route path={`${routes.header.upgrade}/*`} element={<MarketContainer />} />
        <Route path="*" element={<Navigate to={`${routes.home.root}`} />} />
      </>
    )
  );

  return elements;
}
