import React from 'react';
import { Row, CircleIcon, Heading, Breadcrumbs, TextLink } from 'rio-ui-components';
import styled from 'styled-components';
import { Breadcrumb } from '../../types';

const ControlsContainerStyled = styled(Row)`
  flex: 0 0 auto;
  align-items: center;
  padding: ${(props) => props.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
  width: 100%;
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextLinkStyled = styled(TextLink)`
  text-decoration: none;
`;

const CustomIconContainer = styled.div`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
`;

interface ContainerHeaderProps {
  name?: string;
  icon: string | React.ReactNode;
  iconColor: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  breadcrumb?: Breadcrumb[];
}

const ContainerHeader = ({ icon, iconColor, breadcrumb, title, children, ...rest }: ContainerHeaderProps) => (
  <ControlsContainerStyled container alignItems="center" {...rest}>
    {(title || icon) && (
      <TitleContainerStyled>
        {(icon && typeof icon === 'string') || Array.isArray(icon) ? (
          <CircleIconStyled inline height="50px" size="xl" circleColor={iconColor} iconColor={iconColor} icon={icon} />
        ) : (
          icon && <CustomIconContainer>{icon}</CustomIconContainer>
        )}
        <BreadcrumbContainer>
          {breadcrumb && breadcrumb.length > 0 && (
            <Breadcrumbs>
              {breadcrumb.map((crumb) => (
                <TextLinkStyled
                  size="sm"
                  key={crumb.key || crumb.to || crumb.title}
                  component="routerLink"
                  to={crumb.to}
                  inline
                >
                  {crumb.title}
                </TextLinkStyled>
              ))}
            </Breadcrumbs>
          )}
          {title && <Heading>{title}</Heading>}
        </BreadcrumbContainer>
      </TitleContainerStyled>
    )}
    {children}
  </ControlsContainerStyled>
);

export default ContainerHeader;
