import React from 'react';
import { Text } from 'rio-ui-components';
import styled from 'styled-components';
import { categoryColor } from '../constants';
import { NamedEntity } from '@rio/rio-types';

const CategoryPill = styled.div`
  background-color: ${(props) => (props.color ? props.theme.colors[props.color].normal.background : 'secondary')};
  color: white;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;

  position: relative;
  display: inline-block;

  :hover span {
    visibility: visible;
    opacity: 1;
  }
`;

const ToolTip = styled.span`
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;

  position: fixed;
  height: auto;
  width: auto;
  margin-left: 20px;
  padding: 5px 10px;

  word-break: break-word;
  text-align: center;
  border-radius: 6px;

  font-size: 14px;
  background-color: #ddd;
  color: black;

  ::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #ddd transparent transparent;
  }
`;

type CategoryCellProps = {
  data: {
    id: string;
    scheme?: {
      category?: NamedEntity;
    };
  };
};

export function CategoryCell(props: CategoryCellProps) {
  const {
    data: { id: projectId, scheme }
  } = props;
  const category = scheme?.category;
  const acronym = category?.name
    .replace(/\(.*/, '')
    .split(' ')
    .map((a) => a[0] || '')
    .join('')
    .toUpperCase();

  return (
    <Text align="left" name={`project-row__subject-${projectId}`}>
      {category ? (
        <CategoryPill color={categoryColor[category.name]}>
          {acronym}
          <ToolTip>{category.name}</ToolTip>
        </CategoryPill>
      ) : (
        ''
      )}
    </Text>
  );
}
