import { useState, useCallback, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import { Scheme, Project } from '@rio/rio-types';
import { Col, Search, Button, ErrorMessage, ContainerError } from 'rio-ui-components';
import styled from 'styled-components';
import { MODALS, Nullable, SelectEvent } from '../../../types';
import { useGetSchemesPage } from '../../../hooks/useGetSchemesPage';
import { useNotification, useUserToken, useCurrentAccountId, useAccessControls, usePermissions } from '../../../hooks';
import GET_PROJECTS from '../../../graphql/queries/projects/GetProjects.query.graphql';
import { GET_SCHEMES_PAGE } from '../../../graphql/queries/scheme/GetSchemesByAccountId.query';
import { AG_GRID_PARAMS } from '../../../constants/common';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { DeleteModal } from '../../../components/DeleteModal';
import NoResults from '../../../components/NoResults';
import { ExportButton } from '../../../components/ExportButton/ExportButton';
import SchemePopup from './SchemePopup';
import { Grid } from './Grid';
import { FetchRowsParameters, GridApiType } from './types';
import { DELETE_SCHEME } from './index.queries';
import { ToastColor } from '@rio/ui-components';

const ColStyled = styled(Col)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

interface Notification {
  message?: Nullable<string>;
  color?: Nullable<string>;
  id?: string;
}

interface ModalInfo {
  type: Nullable<string>;
  schemeId: Nullable<string>;
}

interface SchemeWithRelatedProjects extends Scheme {
  relatedProjects: Array<string>;
}

const ConfigurationSchemesContainer = () => {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { token } = useUserToken();
  const { showNotification } = useNotification();

  const [searchValue, setSearchValue] = useState('');
  const [schemes, setSchemes] = useState<Scheme[]>([]);
  const [gridApi, setGridApi] = useState<GridApiType>();
  const [showModalInfo, setShowModal] = useState<ModalInfo>({ type: null, schemeId: null });

  const { data: accessControls, loading: isAccessControlsLoading } = useAccessControls();
  const hasProjectsAccess = accessControls?.projects;

  const { error, refetch: refetchFn, networkStatus } = useGetSchemesPage(accountId);
  const [deleteScheme] = useMutation(DELETE_SCHEME);

  const schemeToUpdate = schemes.find((scheme: Scheme) => scheme.id === showModalInfo.schemeId);

  const onSearch = useCallback(() => {
    const instance = gridApi?.api.getFilterInstance('name');
    instance?.setModel({
      filterType: 'text',
      type: 'contains',
      filter: searchValue,
    });

    gridApi?.api.onFilterChanged();
  }, [gridApi?.api, searchValue]);

  const refreshGrid = useCallback(() => gridApi?.api?.refreshServerSide({ purge: true }), [gridApi]);

  const refreshGridCb = useMemo(() => debounce(onSearch, 250), [onSearch]);

  useEffect(() => {
    refreshGridCb();

    return () => {
      // clear filters after leaving the page
      localStorage.removeItem(AG_GRID_PARAMS);
    };
  }, [searchValue, refreshGridCb]);

  const showModal = (type?: Nullable<string>, schemeId?: Nullable<string>) => {
    return setShowModal({ type, schemeId });
  };

  const dismissModals = (notification?: Notification) => {
    showModal(null, null);
    if (notification?.message) {
      showNotification(notification?.message, notification?.color as ToastColor);
    }
  };

  const fetchRows = async (variables: FetchRowsParameters) => {
    const {
      data: {
        getProjectSchemesPage: { totalRows, rows: responseRows },
      },
    } = await client.query({
      query: GET_SCHEMES_PAGE,
      variables: { ...variables, accountId: accountId },
      fetchPolicy: 'network-only',
    });

    const {
      data: { getProjects: projectsData },
    } = await client.query({
      query: GET_PROJECTS,
      variables: { accountId: accountId, userId: token.sub },
      fetchPolicy: 'network-only',
    });

    // need to fetch Schemes after each grid request so updating Schemes into index for consistency
    setSchemes((prev) => [...prev, ...responseRows]);

    // add 'relatedProjects' field to each Scheme which name is the same as project's Scheme name
    // add 'projectsRelated' field for Schemes export
    const rows = responseRows.map((scheme: SchemeWithRelatedProjects) => {
      return {
        ...scheme,
        relatedProjects: projectsData
          .filter((project: Project) => project?.scheme?.name === scheme.name)
          .map((proj: Project) => {
            return {
              id: proj.id,
              name: proj.name,
            };
          }),
        projectsRelated: projectsData
          .filter((project: Project) => project?.scheme?.name === scheme.name)
          .map((proj: Project) => proj.name)
          .join(),
      };
    });

    return { rows, totalRows };
  };

  const deleteSchemeFunc = async () => {
    try {
      await deleteScheme({ variables: { id: schemeToUpdate?.id, accountId } });
      showNotification(`Scheme ${schemeToUpdate?.name} has been successfully deleted`, 'success');
      refreshGrid();
    } catch (err) {
      showNotification('An error has occurred. If the problem persists please contact support', 'danger');
      console.error(err);
    } finally {
      dismissModals();
    }
  };

  if (!hasProjectsAccess) {
    return <NoResults name="Schemes__NoResults" title="Your account has no access to this section" />;
  }
  return (
    <Col name="ConfigurationSchemesContainer" container fullHeight>
      <ContainerHeader name="ConfigurationSchemesContainer__Controls" icon="list" iconColor="primary" title="Schemes">
        <ColStyled span={6} container item>
          <Search
            name="ConfigurationSchemesContainer__Controls__Search"
            value={searchValue}
            onChange={(e: SelectEvent) => setSearchValue(e.target.value)}
            hideButton
          />
        </ColStyled>
        {permissions.scheme.find((action: string) => action.startsWith('create')) && (
          <ColStyled span={4} container item>
            <Button
              name="ConfigurationSchemesContainer__Controls__Button--create"
              size="md"
              color="primary"
              inline
              onClick={() => showModal(MODALS.CREATE)}
            >
              + Add Scheme
            </Button>
          </ColStyled>
        )}
        <ColStyled span={4} container item>
          <ExportButton
            fetchRows={fetchRows}
            gridApi={gridApi?.api}
            columnApi={gridApi?.columnApi}
            defaultExportFileName={'Schemes Exports'}
            label="Export Data"
            columnsToSkip={['Actions', 'Related Projects']}
          />
        </ColStyled>
      </ContainerHeader>
      {((isAccessControlsLoading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="ConfigurationSchemesContainer__Loading" />
      )}
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError
              name="ConfigurationSchemesContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetchFn}
            />
          )}
        </ErrorMessage>
      )}
      <Grid fetchRows={fetchRows} setGridApi={setGridApi} onEdit={showModal} onDelete={showModal} />
      {showModalInfo.type === MODALS.CREATE && (
        <SchemePopup accountId={accountId} refresh={refreshGrid} dismiss={dismissModals} />
      )}
      {showModalInfo.type === MODALS.UPDATE && (
        <SchemePopup
          accountId={accountId}
          schemeToUpdate={schemeToUpdate}
          refresh={refreshGrid}
          dismiss={dismissModals}
        />
      )}
      {showModalInfo.type === MODALS.DELETE && (
        <DeleteModal
          deleteObjectName={schemeToUpdate?.name}
          onConfirmClick={deleteSchemeFunc}
          onDismissClick={dismissModals}
        />
      )}
    </Col>
  );
};

export default ConfigurationSchemesContainer;
