import React, { useState, useCallback } from 'react';
import { Button, styled } from '@rio/ui-components';
import { FormattedMessage } from 'react-intl';
import { withTour } from '../../../../hocs';
import { CourseHandleType } from '~/utils';
import { useCompleteCourse, useDownloadPdf } from '~/components';
import { ViewCourseModal } from './ViewCourseModal';

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const ViewCourseButton = ({ course, downloadLink, handleType, courseRefetch, name }) => {
  const [showModal, setShowModal] = useState(false);

  const downloadPdf = useDownloadPdf(course, downloadLink, courseRefetch);
  const completeCourse = useCompleteCourse(course, courseRefetch);
  const shouldDownload = handleType === CourseHandleType.PDF && course.assessment === null;

  const handleClickButton = useCallback(() => {
    if (shouldDownload) {
      downloadPdf();
    } else if (handleType === CourseHandleType.External) {
      completeCourse();
      window.open(course.metaData.url, '_blank');
    } else if (handleType === CourseHandleType.Iframe) {
      completeCourse();
      setShowModal(true);
    } else {
      setShowModal(true);
    }
  }, [shouldDownload, handleType, downloadPdf, completeCourse, course, setShowModal]);

  return (
    <>
      {showModal && (
        <ViewCourseModal
          onDismiss={() => {
            setShowModal(false);
          }}
          course={course}
          link={downloadLink}
          courseRefetch={courseRefetch}
        />
      )}
      <ButtonStyled variant="elevated" name={name} onClick={handleClickButton}>
        {shouldDownload ? 'Download PDF' : <FormattedMessage id="pages.coursesPreview.startCourse" />}
      </ButtonStyled>
    </>
  );
};

export default withTour(ViewCourseButton, 'learn.preview', {
  closeLabel: 'learnTour.close',
  joyrideOptions: { scrollToFirstStep: false },
});
