import React, { useCallback } from 'react';
import { styled, Button, Modal, Text } from '@rio/ui-components';

const ButtonRow = styled('div')<{ fullWidth?: boolean }>`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${({ fullWidth }) =>
    fullWidth
      ? `
    width: 100%;
    button {
      flex: 1;  
    }
  `
      : `
  justify-content: flex-end;
  `}
`;

const TextStyled = styled(Text)`
  max-width: 500px;
`;

type Props = {
  open: boolean;
  title: string;
  onConfirm: () => void;
  onClose: () => void;
  onCloseButtonClick?: () => void;
  body?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  confirmButtonTestId?: string;
  closeButtonTestId?: string;
  confirmButtonColor?: 'primary' | 'secondary' | 'tertiary';
  withFullWidthButtons?: boolean;
};

export const ConfirmActionModal = ({
  open,
  title,
  body = '',
  confirmButtonText = 'Confirm',
  closeButtonText = 'Close',
  onConfirm,
  onClose,
  onCloseButtonClick,
  closeButtonTestId,
  confirmButtonTestId,
  confirmButtonColor = 'primary',
  withFullWidthButtons,
}: Props) => {
  const handleConfirmClick = useCallback(() => {
    onClose();
    onConfirm();
  }, [onClose, onConfirm]);

  const handleCloseClick = useCallback(() => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    } else {
      onClose();
    }
  }, [onCloseButtonClick, onClose]);

  return (
    <Modal open={open} onClose={onClose} title={title} titleMaxWidth={500}>
      <TextStyled typescale="body" size="medium">
        {body}
      </TextStyled>
      <ButtonRow fullWidth={withFullWidthButtons}>
        <Button onClick={handleCloseClick} variant="outlined" color="primary" data-testid={confirmButtonTestId}>
          {closeButtonText}
        </Button>
        <Button
          onClick={handleConfirmClick}
          variant="filled"
          color={confirmButtonColor}
          data-testid={closeButtonTestId}
        >
          {confirmButtonText}
        </Button>
      </ButtonRow>
    </Modal>
  );
};
