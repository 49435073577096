import { styled, Grid, Text } from '@rio/ui-components';

export const GridStyled = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const FormStyled = styled(GridStyled)``.withComponent('form');

export const GridWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  & > *:last-child {
    grid-column: span 2;
  }
`;

export const CheckboxWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.sys.color.error};
`;
