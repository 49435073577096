import { gql } from '@apollo/client';
import { ASPECT_FIELDS } from '../../../graphql/fragments/AspectFields.fragment.js';

export const CREATE_ASPECT = gql`
  mutation CreateAspect($input: AspectInput!) {
    createAspect(input: $input) {
      ...AspectFields
    }
  }
  ${ASPECT_FIELDS}
`;

export const DELETE_ASPECT = gql`
  mutation DeleteAspect($id: ID!, $accountId: ID!) {
    deleteAspect(id: $id, accountId: $accountId)
  }
`;

export const GET_ASPECTS_BY_ACCOUNT_ID = gql`
  query GetAspectsByAccountId($accountId: ID!, $offset: Int, $limit: Int, $sort: [SortCommand!], $filters: Filters) {
    getAspectsByAccountId(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        ...AspectFields
      }
    }
  }
  ${ASPECT_FIELDS}
`;

export const GET_ASPECTS_COUNT_BY_ACCOUNT_ID = gql`
  query GetAspectsCountByAccountId(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getAspectsByAccountId(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
    }
  }
`;

export const ASPECT_OPTION_FIELDS = gql`
  fragment AspectOptionFields on AspectOption {
    id
    accountId
    name
    type
    isGlobal
  }
`;

export const CREATE_ASPECT_OPTION = gql`
  mutation CreateAspectOption($input: AspectOptionInput!) {
    createAspectOption(input: $input) {
      ...AspectOptionFields
    }
  }
  ${ASPECT_OPTION_FIELDS}
`;

export const DELETE_ASPECT_OPTION = gql`
  mutation DeleteAspectOption($id: ID!, $accountId: ID!, $type: AspectOptionType!) {
    deleteAspectOption(id: $id, accountId: $accountId, type: $type)
  }
`;

export const GET_ASPECT_FILTER = gql`
  query GetAspectFilter($accountId: ID!, $field: String!) {
    getAspectFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;

export const GET_ALL_ASPECT_OPTIONS = gql`
  query GetAllAspectOptions($accountId: ID!) {
    activities: getAspectOptions(accountId: $accountId, type: ACTIVITY) {
      ...AspectOptionFields
    }
    aspects: getAspectOptions(accountId: $accountId, type: ASPECT) {
      ...AspectOptionFields
    }
    emergencyConditions: getAspectOptions(accountId: $accountId, type: EMERGENCY_CONDITION) {
      ...AspectOptionFields
    }
    impacts: getAspectOptions(accountId: $accountId, type: IMPACT) {
      ...AspectOptionFields
    }
    controlMeasures: getAspectOptions(accountId: $accountId, type: CONTROL_MEASURE) {
      ...AspectOptionFields
    }
    statuses: getAspectOptions(accountId: $accountId, type: STATUS) {
      ...AspectOptionFields
    }
  }
  ${ASPECT_OPTION_FIELDS}
`;
