import { gql } from '@apollo/client';
import GET_USERS_BY_ACCOUNT_ID from '../../../graphql/queries/user/GetUsersInfoByAccountId.query.graphql';

export { GET_USERS_BY_ACCOUNT_ID };

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    email
    username
    roles
    first_name
    last_name
    jobTitle
    department
    mobile
    phone
    profilePicture
    disabled
    last_active_at
    total_time_spent_ms
    account {
      id
      name
    }
    role {
      role
      name
      restrictedAccess
    }
    accessLevel
    allowedLocations {
      id
      name
      tags {
        id
        tagName
      }
    }
    allowedTags {
      id
      tagName
      locations {
        id
      }
    }
  }
`;

export const MYSQL_USER_FRAGMENT = gql`
  fragment MysqlUserFields on MysqlUser {
    id
    accountId
    firstName
    lastName
    email
    isDisabled
    accessLevel
    allowedLocations
    allowedTags
    role
    jobTitle
    phone
    mobile
    department
    profilePicture
    lastActiveAt
    totalTimeSpentMs
    accountName
    tags
    locations
    readonly
    isExternalUser
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username)
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserInfoById(id: $id) {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation createNewUser(
    $email: String!
    $first_name: String!
    $last_name: String!
    $roles: UserRole!
    $password: String!
    $account_id: ID!
    $accessLevel: Scope
    $allowedTagsIds: [ID]
    $allowedLocationsIds: [ID]
    $jobTitle: String
    $department: String
    $phone: String
    $mobile: String
  ) {
    createUser(
      input: {
        username: $email
        first_name: $first_name
        last_name: $last_name
        roles: [$roles]
        password: $password
        account_id: $account_id
        accessLevel: $accessLevel
        allowedTagsIds: $allowedTagsIds
        allowedLocationsIds: $allowedLocationsIds
        jobTitle: $jobTitle
        department: $department
        phone: $phone
        mobile: $mobile
      }
    ) {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $email: String!
    $previousEmail: String
    $first_name: String!
    $last_name: String!
    $account_id: ID!
    $id: String!
    $accessLevel: Scope
    $allowedTagsIds: [ID]
    $allowedLocationsIds: [ID]
    $jobTitle: String
    $department: String
    $phone: String
    $mobile: String
    $profile: String
  ) {
    updateUser(
      input: {
        email: $email
        previousEmail: $previousEmail
        first_name: $first_name
        last_name: $last_name
        account_id: $account_id
        id: $id
        accessLevel: $accessLevel
        allowedTagsIds: $allowedTagsIds
        allowedLocationsIds: $allowedLocationsIds
        jobTitle: $jobTitle
        department: $department
        phone: $phone
        mobile: $mobile
        profilePicture: $profile
      }
    ) {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: String!, $roles: UserRole!) {
    updateUserRoles(input: { roles: [$roles], username: $id }) {
      id
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendEmail($username: String!, $password: String!) {
    resendEmail(input: { username: $username, password: $password }) {
      username
    }
  }
`;

export const SET_USER_DISABLED = gql`
  mutation SetUserDisabled($id: String!, $disabled: Boolean!) {
    setUserDisabled(id: $id, disabled: $disabled) {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($username: ID!, $password: String!) {
    resetUserPassword(username: $username, password: $password)
  }
`;

export const GET_ROLES = gql`
  query GetRoles {
    getUserRoles {
      role
      name
      restrictedAccess
      readonly
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers($accountId: String!, $offset: Int, $limit: Int = 25, $sort: [SortCommand!], $filters: Filters) {
    getUsers(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      rows {
        ...MysqlUserFields
      }
      totalRows
    }
  }
  ${MYSQL_USER_FRAGMENT}
`;

export const GET_USERS_FILTER = gql`
  query GetUsersFilter($accountId: ID!, $field: String!) {
    getUsersFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;

export const GET_USERS_LOCATION = gql`
  query getUsersLocation($accountId: ID!) {
    getUsersLocation(accountId: $accountId) {
      id
      name
    }
  }
`;
