import { useMutation } from '@apollo/client';
import { Mutation, MutationCreateSurveyTemplateArgs as Variables } from '@rio/rio-types';
import { GetSurveyTemplatesResponse } from './useGet';
import { CREATE_SURVEY_TEMPLATE, GET_SURVEY_TEMPLATES } from './queries';
import { useNotification } from '../../../../hooks';

type CreateFormTemplateResponse = Pick<Mutation, 'createSurveyTemplate'>;

export function useCreate() {
  const { showNotification } = useNotification();
  return useMutation<CreateFormTemplateResponse, Variables>(CREATE_SURVEY_TEMPLATE, {
    onCompleted: () => {
      showNotification('Template has been successfully created!', 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },
    update: (cache, result) => {
      const { getSurveyTemplates } = cache.readQuery<GetSurveyTemplatesResponse>({
        query: GET_SURVEY_TEMPLATES,
      }) as GetSurveyTemplatesResponse;
      const newTemplate = result.data?.createSurveyTemplate;
      if (newTemplate) {
        cache.writeQuery({
          query: GET_SURVEY_TEMPLATES,
          data: {
            getSurveyTemplates: getSurveyTemplates.concat(newTemplate),
          },
        });
      }
    },
  });
}
