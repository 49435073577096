import React from 'react';
import styled from 'styled-components';
import { LabelValueItem } from '../../../components/LabelValueItem';
import { TextLink, PropertySheet } from 'rio-ui-components';

const OuterContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ViewWasteHierarchies = (props) => {
  const { hierarchy } = props;

  const { id, treatmentProcess, prevention, preparingForReuse, recycling, otherRecovery, disposal, unknown, reference, isGlobal, user } = hierarchy;

  return (
    <OuterContainer>
      <PropertySheet.HeaderRow>{treatmentProcess.name}</PropertySheet.HeaderRow>

      <PropertySheet.Row>
        <PropertySheet.TextColumn id={`ViewWasteHierarchies__isGlobal--${id}`} name={`ViewWasteHierarchies__isGlobal--${id}`}>
          This is a {isGlobal ? 'default' : 'custom'} waste hierarchy
        </PropertySheet.TextColumn>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.TextColumn id={`ViewWasteHierarchies__user--${id}`} name={`ViewWasteHierarchies__user--${id}`}>
          Created By: {user ? user.first_name : 'Ditto'} {user ? user.last_name : 'Sustainability'}
        </PropertySheet.TextColumn>
      </PropertySheet.Row>

      <LabelValueItem id={id} name="ViewWasteHierarchiesPrevention" label="Prevention:" value={`${prevention}%`} />
      <LabelValueItem id={id} name="ViewWasteHierarchiesPreparingForReuse" label="Preparing For Reuse:" value={`${preparingForReuse}%`} />
      <LabelValueItem id={id} name="ViewWasteHierarchiesRecycling" label="Recycling:" value={`${recycling}%`} />
      <LabelValueItem id={id} name="ViewWasteHierarchiesOtherRecovery" label="Other Recovery:" value={`${otherRecovery}%`} />
      <LabelValueItem id={id} name="ViewWasteHierarchiesDisposal" label="Disposal:" value={`${disposal}%`} />
      <LabelValueItem id={id} name="ViewWasteHierarchiesUnknown" label="Unknown:" value={`${unknown}%`} />

      {reference && (
        <PropertySheet.Row>
          <PropertySheet.TextColumn id={`ViewWasteHierarchies__reference--${id}`} name={`ViewWasteHierarchies__reference--${id}`} label="Reference:">
            <TextLink id={`ViewWasteHierarchies__reference--${id}`} name={`ViewWasteHierarchies__reference--${id}`} href={reference} target="_blank">
              {reference}
            </TextLink>
          </PropertySheet.TextColumn>
        </PropertySheet.Row>
      )}
    </OuterContainer>
  );
};
export default ViewWasteHierarchies;
