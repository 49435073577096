import styled, { css } from 'styled-components';
import { TextLink } from 'rio-ui-components';
import { Link } from 'react-router-dom';

const SharedStyle = css`
  font-family: inherit;
  display: inline-block;
  color: inherit;
  opacity: 1;
  &:hover {
    text-decoration: none;
  }
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts.sm.size};
`;

const TextLinkStyled = styled(TextLink)`
  ${SharedStyle}
`;

export { TextLinkStyled as TextLink };

export const LinkStyled = styled(Link)`
  ${SharedStyle}
`;
