import { useQuery, QueryHookOptions, OperationVariables } from '@apollo/client';
import { usePageErrorContext, usePageLoadingContext } from '@rio/ui-components';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';

/**
 * @description Wrapper around useQuery that encapsulates loading and error state
 * @param query Graphql document node
 * @param options QueryHookOptions<Response, Variables> options that will be bypassed to useQuery
 * @returns QueryHookOptions<Response, Variables>
 */
export function usePageSuspendingQuery<Response = any, Variables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<Response, Variables> & { keepErrorAfterUnmount?: boolean } = {}
) {
  const { loading: pageLoading, setLoading: setPageLoading } = usePageLoadingContext({
    defaultValue: true,
  });

  const { setError } = usePageErrorContext();

  const useQueryResult = useQuery<Response, Variables>(query, {
    ...options,
    /**
     * This is important because by default refetch doesn't change loading status
     */
    notifyOnNetworkStatusChange: true,
    onCompleted: (...args) => {
      options.onCompleted?.(...args);
      /**
       * setTimeout is important because apollo returns cache data synchronously
       * and useEffect below executes after that's why we need to call setPageLoading(false) on the next tick
       * because otherwise page will be forever loading
       */
      setTimeout(() => {
        setError(null);
        setPageLoading(false);
      }, 0);
    },
    onError: (error) => {
      options.onError?.(error);
      /**
       * Reason of setTimeout is exactly the same as in onCompleted
       */
      setTimeout(() => {
        setError(error);
        setPageLoading(false);
      }, 0);
    },
  });

  /**
   * Toggle LoadingIndicator in Page component through context
   */
  useEffect(() => {
    if (!pageLoading && useQueryResult.loading) {
      setPageLoading(true);
    }
  }, [pageLoading, setPageLoading, useQueryResult.loading]);

  /**
   * To cleanup loading and error state when user leaves the page
   */
  useEffect(
    () => () => {
      if (!options.keepErrorAfterUnmount) {
        setError(null);
      }
      setPageLoading(false);
    },
    [setError, setPageLoading, options.keepErrorAfterUnmount]
  );

  return useQueryResult;
}
