import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';
import { ManagementSystemPage } from '../../../../../types';
import { useNotification } from '../../../../../hooks';
interface CarbonDisclosureOpportunitiesProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureOpportunities({ page, framework }: CarbonDisclosureOpportunitiesProps) {
  const { showNotification } = useNotification();
  const buttons = useMemo(
    () => [
      {
        title: 'Export disclosure',
        color: 'primary',
        onClick: () => {
          showNotification('Not implemented', 'warning');
        }
      }
    ],
    [showNotification]
  );
  return <ReportingFrameworkLayout page={page} framework={framework} buttons={buttons} />;
}
