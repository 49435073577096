// calculates amount of times per page based on window width
function getPageSize(innerWidth: number) {
  switch (true) {
    case innerWidth > 1920: {
      return 25;
    }
    case innerWidth > 1600 && innerWidth <= 1920: {
      return 20;
    }
    case innerWidth > 1366 && innerWidth <= 1600: {
      return 15;
    }
    default:
    case innerWidth <= 1366: {
      return 10;
    }
  }
}

export { getPageSize };
