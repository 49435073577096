import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../../types';
import { useNavigate } from 'react-router-dom';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';

interface CarbonDisclosureIntroductionProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureIntroduction({ page, framework }: CarbonDisclosureIntroductionProps) {
  const navigate = useNavigate();
  const buttons = useMemo(
    () => [
      {
        title: 'Learn more',
        color: 'primary',
        onClick: () => {
          navigate('/engage/course/scorm/eaf6fa55-fce4-4244-b324-ceea22ed5c90'); // TODO un-hardcode course's id
        }
      }
    ],
    [navigate]
  );
  return <ReportingFrameworkLayout framework={framework} page={page} buttons={buttons} />;
}
