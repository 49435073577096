import { useMutation } from '@apollo/client';
import { Mutation, MutationCreateSurveyTemplateSubscriptionArgs as Variables } from '@rio/rio-types';
import { GetResponse } from './useGet';
import { CREATE_SURVEY_TEMPLATE_SUBSCRIPTION, GET_SURVEY_TEMPLATE_SUBSCRIPTIONS } from './queries';
import { useNotification } from '../../../../hooks';
import { addSurveyTemplateToAccountQuery } from './updateAccountQuery';

type CreateResponse = Pick<Mutation, 'createSurveyTemplateSubscription'>;

export function useCreate() {
  const { showNotification } = useNotification();
  return useMutation<CreateResponse, Variables>(CREATE_SURVEY_TEMPLATE_SUBSCRIPTION, {
    onCompleted: ({ createSurveyTemplateSubscription: subscription }) => {
      showNotification(`Subscribed ${subscription.account.name} to ${subscription.template.name}`, 'success');
    },
    onError: (err) => showNotification(err.toString(), 'danger'),
    update: (cache, result) => {
      const { getSurveyTemplateSubscriptions } = cache.readQuery<GetResponse>({
        query: GET_SURVEY_TEMPLATE_SUBSCRIPTIONS,
      }) as GetResponse;
      const newSubscription = result.data?.createSurveyTemplateSubscription;
      if (!newSubscription) {
        return;
      }
      cache.writeQuery({
        query: GET_SURVEY_TEMPLATE_SUBSCRIPTIONS,
        data: {
          getSurveyTemplateSubscriptions: getSurveyTemplateSubscriptions.concat(newSubscription),
        },
      });

      addSurveyTemplateToAccountQuery(cache, result.data);
    },
  });
}
