import { useMutation } from '@apollo/client';
import { Query, Mutation, MutationCreateSurveyQuestionCategoryArgs as Variables } from '@rio/rio-types';
import { CREATE_SURVEY_QUESTION_CATEGORY, GET_SURVEY_QUESTION_CATEGORIES } from './queries';

type Response = Pick<Mutation, 'createSurveyQuestionCategory'>;

export function useCreateSurveyQuestionCategory() {
  return useMutation<Response, Variables>(CREATE_SURVEY_QUESTION_CATEGORY, {
    update: (cache, { data }) => {
      cache.updateQuery<Pick<Query, 'getSurveyQuestionCategories'>>(
        { query: GET_SURVEY_QUESTION_CATEGORIES },
        (cacheResponse) => {
          const categories = cacheResponse?.getSurveyQuestionCategories;
          if (!categories || !data?.createSurveyQuestionCategory) {
            return;
          }
          return {
            getSurveyQuestionCategories: categories.concat(data?.createSurveyQuestionCategory),
          };
        }
      );
    },
  });
}
