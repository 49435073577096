import { isBoolean, mapValues } from 'lodash';

interface Mesage {
  [key: string]: string | Mesage;
}

export function flattenMessages(nestedMessages: Mesage, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {} as { [key: string]: string });
}

export const getIconByFileExtension = (fileName: string) => {
  const extensionRegex = /(?:\.([^.]+))?$/;
  const res = extensionRegex.exec(fileName);
  const extension = res && res[1];
  const fileExtension = extension ? extension.toUpperCase() : '';

  switch (fileExtension) {
    case 'PDF':
      return 'file-pdf';
    case 'PNG':
    case 'JPG':
    case 'JPEG':
    case 'BMP':
      return 'file-image';
    case 'DOCX':
    case 'DOCM':
    case 'DOC':
      return 'file-word';
    case 'XLSX':
    case 'XLS':
    case 'CSV':
      return 'file-excel';
    default:
      return 'file';
  }
};

export const getLocale = () =>
  (navigator.languages && navigator.languages[0]) || navigator.languages || navigator.language || 'en-GB';

export const getFullName = (user: { first_name: string; last_name: string } | null) => {
  return [user?.first_name, user?.last_name].filter(Boolean).join(' ');
};

export const rearrangeFilterParameters = (params: { parameters: { name: string; value: string }[] }) => {
  const { parameters } = params;
  const originalParams = parameters.reduce((result, param) => {
    const { name, value } = param;

    Object.assign(result, {
      [name]: value,
    });

    return result;
  }, {});

  return originalParams;
};

export const formatBooleanValue = ({ value }: { value: any }) => {
  return isBoolean(value) && value ? 'Yes' : isBoolean(value) && !value ? 'No' : null;
};

export const formatBooleanFilterValue = ({ value }: { value: string | null }) => {
  if (value === null) return 'Blank';
  const { value: filterValue } = JSON.parse(value);

  return filterValue === '1' ? 'Yes' : 'No';
};

export const boolYesNo = ({ value }: { value: string | boolean }) => {
  // check if value is a string with boolean value like 'false' to avoid unexpected output
  if (value === 'true') return 'Yes';
  if (value === 'false') return 'No';
  return value ? 'Yes' : 'No';
};

export const nullIfEmptyString = (value: unknown) => (value === '' ? null : value);

export const prepareDromoResultsToGraphql = (object: object) =>
  mapValues(object, (value) =>
    Array.isArray(value) ? (value as Array<unknown>).filter(Boolean) : nullIfEmptyString(value)
  );

export const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};
