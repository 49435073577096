import { useMutation } from '@apollo/client';
import { SurveyTemplate, MutationUpdateSurveyTemplateArgs as Variables } from '@rio/rio-types';
import { GetSurveyTemplatesResponse } from './useGet';
import { UPDATE_SURVEY_TEMPLATE, GET_SURVEY_TEMPLATES } from './queries';
import { useNotification } from '../../../../hooks';

type UpdateFormTemplateResponse = {
  updateFormTemplate: SurveyTemplate;
};

export function useUpdate() {
  const { showNotification } = useNotification();
  return useMutation<UpdateFormTemplateResponse, Variables>(UPDATE_SURVEY_TEMPLATE, {
    onCompleted: () => {
      showNotification('The template has been successfully updated!', 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },
    update: (cache, result) => {
      const { getSurveyTemplates } = cache.readQuery<GetSurveyTemplatesResponse>({
        query: GET_SURVEY_TEMPLATES,
      }) as GetSurveyTemplatesResponse;
      const updatedTemplate = result.data?.updateFormTemplate;
      cache.writeQuery({
        query: GET_SURVEY_TEMPLATES,
        data: {
          getSurveyTemplates: getSurveyTemplates.map((t: SurveyTemplate) =>
            t.id === updatedTemplate?.id ? updatedTemplate : t
          ),
        },
      });
    },
  });
}
