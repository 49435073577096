import { formatDate, compareDate, defaultFilterParams } from '../../../../utils';
import { ParamsData } from './types';
import { Option } from '../../../../types';
import { checkForActiveReviewFlag } from './common';

const flaggedFilter = (params: { value: string }) => {
  return params.value === 'true' ? 'Yes' : 'No';
};

const rowStyle = { 'align-items': 'center', display: 'flex' };

export const columnDefs: any = [
  {
    headerName: 'Title',
    field: 'title',
    resizable: true,
    minWidth: 500
  },
  {
    headerName: 'Review Date',
    field: 'reviewDate',
    valueFormatter: (params: ParamsData) => formatDate(params.data.reviewDate),
    resizable: true,
    cellStyle: rowStyle,
    filter: 'agDateColumnFilter',
    filterParams: {
      ...defaultFilterParams(),
      comparator: compareDate
    }
  },
  {
    headerName: 'Compliance Status',
    field: 'status',
    resizable: true,
    cellRenderer: 'legislationStatusIcon',
    cellStyle: rowStyle,
    filterParams: {
      cellRenderer: 'legislationStatusIcon'
    }
  },
  {
    headerName: 'Flagged',
    valueGetter: (params: ParamsData) =>
      checkForActiveReviewFlag(params.data.lastFlaggedForReview, params.data.lastClearedReviewFlag),
    resizable: true,
    filterParams: {
      valueFormatter: flaggedFilter
    },
    cellRenderer: 'activeFlag'
  },
  {
    headerName: 'Countries',
    field: 'countries',
    resizable: true,
    cellRenderer: 'listCountries',
    cellStyle: rowStyle,
    minWidth: 250
  }
];

const scopeTag = {
  headerName: 'Scope (Tags)',
  field: 'tags',
  resizable: true,
  valueGetter: (params: ParamsData) => {
    return params.data.tags?.map((item) => item.tagName);
  },
  cellStyle: rowStyle
};

const scopeAll = [
  {
    headerName: 'Scope (Location)',
    field: 'locations',
    resizable: true,
    valueGetter: (params: ParamsData) => {
      return params.data.locations?.map((item) => item.name);
    },
    cellStyle: rowStyle
  },
  {
    headerName: 'Scope (Departments)',
    field: 'departments',
    resizable: true,
    cellStyle: rowStyle,
    valueGetter: (params: ParamsData) => {
      return params.data.departments?.map((item: Option) => item.label);
    }
  },
  {
    headerName: 'Scope (Regions)',
    field: 'regions',
    resizable: true,
    valueGetter: (params: ParamsData) => {
      return params.data.regions?.map((item) => item.name);
    },
    cellStyle: rowStyle
  }
];

export const columnDefsAll = [...columnDefs, scopeTag, ...scopeAll];
export const columnDefsWithoutTag = [...columnDefs, ...scopeAll];
