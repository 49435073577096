import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { Task, Project } from '@rio/rio-types';
import { Button, Col, Heading, Modal, Row, ScrollContainer } from 'rio-ui-components';
import TaskDetails from './TaskDetails';
import { LOCATION, TAG } from '../../../constants/scopes';
import CREATE_TASK from '../../../graphql/mutations/tasks/CreateTask.mutation.graphql';
import CREATE_TASK_EVIDENCE from '../../../graphql/mutations/tasks/CreateTaskEvidence.mutation.graphql';
import { ValidateTask } from './validate';
import { useSaveEvidence, EvidencePicker } from '../../../components/EvidenceNotes';
import { AUDIT } from '../../../constants/taskEvidenceTypes';
import { Dictionary } from '../../../types';
import { GET_PROJECT_BY_ID } from '../../../graphql/queries/projects/GetProjectById.query';

interface FullTask extends Task {
  UserId: string;
  locationIds: string[];
  tagIds: string[];
}

interface TaskModalProps {
  accountId: string;
  onDismiss: () => void;
  onComplete: (arg1: Record<string, unknown>, arg2?: Record<string, unknown>) => void;
  sourcePage?: string;
  defaultValues?: FullTask | Dictionary<any>;
  disabled?: object;
  projectId?: string;
  hasProjectsAccess?: boolean;
}

const ModalContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => `${props.theme.geometry.xs.spacing} ${props.theme.geometry.sm.spacing}`};
`;

const ScrollableContainerStyled = styled(ScrollContainer)`
  ${({ theme }) => `
    overflow: auto;
    padding: ${theme.geometry.xs.spacing};
  `}
`;

type CacheProject = {
  getProject: Project;
};

const CreateTaskModal = ({
  accountId,
  onDismiss,
  onComplete,
  sourcePage,
  defaultValues = {},
  disabled = {},
  projectId,
  hasProjectsAccess = false,
}: TaskModalProps) => {
  const [createTask] = useMutation(CREATE_TASK, {
    onCompleted: (data) => {
      onComplete(
        {
          message: 'Task has been created.',
          colour: 'success',
        },
        data
      );
      onDismiss();
    },
    update(cache, { data }) {
      if (!projectId) return;

      const newTask = data.createTask;
      const cacheProject: CacheProject | null = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: { id: projectId },
      });
      const project = cacheProject?.getProject;

      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: { id: projectId },
        data: {
          getProject: {
            ...project,
            tasks: [...(project?.tasks || []), newTask],
          },
        },
      });
    },
    onError: () => {
      onComplete({
        message: 'Something went wrong! Please try again later.',
        colour: 'danger',
      });
    },
  });

  const [createTaskEvidence] = useMutation(CREATE_TASK_EVIDENCE);

  const [task, setTask] = useState({
    scope: defaultValues.scope || '',
    locationIds: defaultValues.locationIds || [],
    tagIds: defaultValues.tagIds || [],
    name: defaultValues.name || '',
    description: defaultValues.description || '',
    category: defaultValues.category || null,
    subject: defaultValues.subject || null,
    item: defaultValues.item || null,
    owners: defaultValues.owners || '',
    dueDate: defaultValues.dueDate || '',
    priority: defaultValues.priority || null,
    isCompleted: defaultValues.isCompleted || false,
    projectId: projectId || null,
  });

  const saveEvidence = useSaveEvidence();
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');

  const handleSubmit = async () => {
    const taskId = uuid();
    const tags = task.scope === TAG ? task.tagIds.map(({ value }: { value: string }) => value) : [];
    createTask({
      variables: {
        id: taskId,
        createdAt: new Date().toISOString(),
        accountId,
        itemId: task.item?.id || null,
        ...task,
        locationIds: task.scope === LOCATION ? task.locationIds.map(({ value }: { value: string }) => value) : [],
        tagIds: tags,
      },
      refetchQueries: ['GetTasksByAccountId', 'GetLegislationById'],
    });

    if (!!evidenceDocuments.length) {
      const newDocumentIds = await saveEvidence(evidenceDocuments, tags, 'Other Reference');

      createTaskEvidence({
        variables: {
          id: uuid(),
          taskId: taskId,
          evidenceType: AUDIT,
          notes: evidenceNotes,
          documents: newDocumentIds,
        },
      });
    }
  };

  return (
    <Modal size="md" show onDismiss={() => onDismiss()}>
      <ModalContainerStyled>
        <HeaderStyled size="lg">Create New Task</HeaderStyled>
        <ScrollableContainerStyled>
          <TaskDetails
            task={task}
            setTask={setTask}
            accountId={accountId}
            sourcePage={sourcePage}
            disabled={disabled}
            isSubmitting={undefined}
            isEditing={undefined}
            projectId={projectId}
            hasProjectsAccess={hasProjectsAccess}
          />
          <EvidencePicker
            evidenceDocuments={evidenceDocuments}
            setEvidenceDocuments={setEvidenceDocuments}
            evidenceNotes={evidenceNotes}
            setEvidenceNotes={setEvidenceNotes}
          />
        </ScrollableContainerStyled>
        <Row container align="between">
          <ColStyled item>
            <Button
              data-cy="CreateTaskModal__submit"
              disabled={!isEmpty(ValidateTask(task))}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </ColStyled>
        </Row>
      </ModalContainerStyled>
    </Modal>
  );
};

export { CreateTaskModal };
