import { useState, useEffect } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { Modal, Icon } from 'rio-ui-components';
import ModalContent from './ModalContent';
import _ from 'lodash';
import constructUserBatchRequest from './userBatchRequests';
import { GET_USERS_BY_ACCOUNT_ID } from '../../containers/ConfigurationContainer/ConfigurationUsersContainer/index.queries';

const UploadBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 360px;
  right: 100px;
  height: 75px;
  background: white;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
`;

const mapResponses = (responses, items, responseType) => {
  const success = responses.data[responseType].success.map((result) => {
    return {
      type: 'success',
      message: `'${result.name || `${result.first_name} ${result.last_name}`}' successfully uploaded!`,
      row: 2,
    };
  });
  const errors = responses.data[responseType].errors.map((error) => {
    return {
      type: 'error',
      message: `Duplicate name: ${error}`,
      row: items.findIndex((item) => item.name === error.split(': ')[1]),
    };
  });
  return [...success, ...errors];
};

const mapResponseSelection = (id) => {
  const map = {
    locations: 'createLocationWithLocationPointsBatch',
    users: 'createUsers',
  };
  return map[id];
};

const batchUploadItems = (client, update, row, createMutation, createMutationId) => async (variables, items) => {
  const responses = await client.mutate({
    mutation: createMutation,
    variables: variables,
    refetchQueries: variables.account_id0
      ? [
          {
            query: GET_USERS_BY_ACCOUNT_ID,
            variables: {
              limit: 600,
              accountId: variables.account_id0,
            },
          },
        ]
      : [],
  });
  update((state) => [...state, ...mapResponses(responses, items, mapResponseSelection(createMutationId))]);
};

const chunkItems = (items) => {
  if (items.length <= 10) return [items];
  return _.chunk(items, items.length / 10);
};

const uploadItems = async (items, client, update, createMutationId, onComplete) => {
  const batches = chunkItems(items);
  await Promise.all(
    batches.map(async (batch, row) => {
      switch (createMutationId) {
        case 'users':
          // do user upload
          const usersBatchRequest = constructUserBatchRequest(batch);
          console.log(usersBatchRequest);
          await batchUploadItems(
            client,
            update,
            row,
            usersBatchRequest.createMutation,
            createMutationId
          )(usersBatchRequest.variables, items);
          break;
        default:
          break;
      }
    })
  );
  if (onComplete) {
    onComplete();
  }
};

const ProgressBarContainer = styled.div`
  height: 20px;
  width: 100%;
`;

const ProgressBarDone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ProgressBarNotDone = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral.normal.background};
  box-shadow: 1px 1px 6px inset rgba(0, 0, 0, 0.3);
`;

const ProgressBarSuccess = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background: ${({ theme }) => theme.colors.primary.dark.background};
`;

const ProgressBarError = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background: ${({ theme }) => theme.colors.danger.dark.background};
`;

const ProgressBar = ({ percentage, errorPercentage }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarNotDone>
        <ProgressBarDone>
          <ProgressBarSuccess percentage={percentage} />
          <ProgressBarError percentage={errorPercentage} />
        </ProgressBarDone>
      </ProgressBarNotDone>
    </ProgressBarContainer>
  );
};

const getPercentage = (num, denom) => Math.round((num / denom) * 100);

const ViewUploads = styled.div`
  cursor: pointer;
`;

const DismissButton = styled(Icon)``;

const Header = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const getErrorCount = (uploadedItems) => uploadedItems.filter((item) => item.type === 'error').length;
const getSuccessCount = (uploadedItems) => uploadedItems.filter((item) => item.type === 'success').length;

const UploadBar = ({ client, items, dismiss, createMutation, onComplete }) => {
  const [uploadedItems, updateUploadedItems] = useState([]);
  const [showModal, toggleModal] = useState(false);
  useEffect(() => {
    uploadItems(items, client, updateUploadedItems, createMutation, onComplete);
  }, [items, client, updateUploadedItems, createMutation, onComplete]);
  return (
    <UploadBarContainer>
      <Header>
        <div>Uploaded: {getSuccessCount(uploadedItems)}</div>
        <div>Errors: {getErrorCount(uploadedItems)}</div>
        <ViewUploads onClick={() => toggleModal(true)}>View Uploads</ViewUploads>
        <DismissButton icon="times" onClick={dismiss} />
      </Header>
      <ProgressBar
        percentage={getPercentage(getSuccessCount(uploadedItems), items.length)}
        errorPercentage={getPercentage(getErrorCount(uploadedItems), items.length)}
        successCount={getSuccessCount(uploadedItems)}
        errorCount={getErrorCount(uploadedItems)}
      />
      <Modal
        span={5}
        size="md"
        show={showModal}
        name="MassUpload__Modal"
        dismissable
        onDismiss={() => toggleModal(false)}
      >
        <ModalContent uploadedItems={uploadedItems} />
      </Modal>
    </UploadBarContainer>
  );
};

export default withApollo(UploadBar);
