import { gql } from '@apollo/client';

export const ASPECT_FIELDS = gql`
  fragment AspectFields on Aspect {
    id
    accountId
    scope
    activity {
      id
      name
    }
    locations {
      id
      name
      tags {
        id
        tagName
      }
    }
    tags {
      id
      tagName
    }
    aspects {
      id
      name
    }
    impacts {
      id
      name
    }
    legislations {
      id
      title
    }
    emergencyConditions {
      id
      name
    }
    controlMeasures {
      id
      name
    }
    status {
      id
      name
    }
    task {
      id
    }
    departments {
      id
      name
    }
    likelihoodNoControl
    severityNoControl
    likelihoodControl
    severityControl
    significanceControl
    significanceNoControl
    reviewDate
    author {
      id
      name
    }
    editor {
      id
      name
    }
    createdDate
    editedDate
    activeTasks
  }
`;
