import { useQueryParam, JsonParam, decodeJson } from 'use-query-params';
import { AspectOption } from '@rio/rio-types';
import { parseUrl } from 'query-string';
import { Nullable } from '../../../types';

const PARAM_NAME = 'optionToDelete';

export interface OptionToDelete {
  option: AspectOption;
  isReviewingAspects: boolean;
  relatedAspectsCount: number;
}
export function useOptionToDeleteFromQueryParam() {
  return useQueryParam<Nullable<OptionToDelete>>(PARAM_NAME, JsonParam);
}

export function getOptionNameToDeleteFromQueryParam(): Nullable<string> {
  const { query } = parseUrl(window.location.href);
  const optionToDelete = decodeJson(query[PARAM_NAME]);
  return optionToDelete?.option?.name || null;
}
