import { GridOptions, IServerSideDatasource, RowModelType } from 'ag-grid-community';
import { FactorsGrid } from './FactorsGrid';

const pageSize = 25;

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  autoHeight: true,
  filterParams: {
    showTooltip: true,
  },
};

const defaultConfig = {
  pagination: true,
  paginationPageSize: 25,
  defaultColDef,
  rowModelType: 'serverSide' as RowModelType,
  cacheBlockSize: pageSize,
  maxBlocksInCache: 3,
};

interface PaginatedFactorsGridProps {
  config: GridOptions;
  serverSideDatasource?: IServerSideDatasource;
}

export function PaginatedFactorsGrid({ config, serverSideDatasource }: PaginatedFactorsGridProps) {
  return (
    <FactorsGrid
      {...defaultConfig}
      {...config}
      serverSideDatasource={serverSideDatasource}
    />
  );
}
