import { useState } from 'react';
import { useElements, useStripe, CardNumberElement } from '@stripe/react-stripe-js';

export function useCreateStripePaymentMethod() {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const stripe = useStripe();
  const elements = useElements();

  return [
    async ({ billing_details } = {}) => {
      if (!stripe || !elements) {
        return;
      }

      const cardNumber = elements.getElement(CardNumberElement);

      setLoading(true);

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details
      })

      if (error) {
        setError(error);

        throw new Error(error.message);
      }

      paymentMethod && setData(paymentMethod);

      setLoading(false);

      return paymentMethod;
    },

    { loading, error, data }
  ]
}