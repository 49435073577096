import { gql } from "@apollo/client";

export const GET_ALL_TRANSPORT_TYPES = gql`
  query GetAllTransportTypes {
    getAllTransportTypes {
      id
      name
    }
  }
`;
