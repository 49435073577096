import React, { useState } from 'react';
import styled from 'styled-components';
import { TextLink, Notification } from 'rio-ui-components';
import { Auth } from 'aws-amplify';

const LinkStyled = styled(TextLink)`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  text-align: center;
`;

export function ResendEmailButton({ email }) {
  const [notification, setNotification] = useState(null);
  const resend = async () => {
    if (email) {
      try {
        await Auth.resendSignUp(email.trim().toLowerCase());
        setNotification({
          message: `Email has been sent again!`,
          color: 'success',
        });
      } catch (err) {
        setNotification({
          message: `Email wasn't sent: ${err.message}`,
          color: 'danger',
        });
      }
    }
  };

  return (
    <>
      <LinkStyled onClick={resend}>Not received your confirmation? Resend Email</LinkStyled>
      {notification && (
        <Notification name={notification.message} color={notification.color} onClose={() => setNotification(null)} show>
          {notification.message}
        </Notification>
      )}
    </>
  );
}
