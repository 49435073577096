import { useQuery } from '@apollo/client';
import { Query } from '@rio/rio-types';
import { GET_ACCOUNT_BY_ID } from './index.queries';
import { Nullable } from '../../../types';

export function useGetAccountById(id: Nullable<string>, skip?: boolean) {
  return useQuery<Query>(GET_ACCOUNT_BY_ID, {
    variables: {
      id,
    },
    skip: skip || !id,
    fetchPolicy: 'cache-and-network',
  });
}
