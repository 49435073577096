import { gql } from '@apollo/client';
import TARGET_FIELDS from '../../graphql/fragments/TargetFields.fragment.graphql';

export const GET_COMPLY_DATA = gql`
  query GetComplyData($accountId: ID!) {
    getDocumentRecordsForReview(id: $accountId) {
      id
      reviewDate
      fileName
    }
  }
`;

export const GET_LEGISLATION_ALERTS = gql`
  query GetLegislationAlerts($accountId: ID!) {
    getLegislationAlerts(accountId: $accountId) {
      expired {
        id
        title
      }
      approaching {
        id
        title
      }
      flagged {
        id
        title
      }
    }
  }
`;

export const GET_ASPECTS_ALERTS = gql`
  query GetAspectsAlerts($accountId: ID!) {
    getAspectsAlerts(accountId: $accountId) {
      expired {
        id
        activity {
          id
          name
        }
        reviewDate
      }
      approaching {
        id
        activity {
          id
          name
        }
        reviewDate
      }
    }
  }
`;

export const GET_ANALYSE_DATA = gql`
  query GetAnalyseData($accId: ID!, $wasteBatchPage: Int!) {
    getDataImportBatchesByAccountId(accountId: $accId, page: $wasteBatchPage, type: WASTE, pageSize: 25) {
      page
      pageSize
      pageCount
      isLastPage
      isFirstPage
      batches {
        id
        status
        rowsUploaded
        fileName
        timeStamp
      }
    }
    getTargetsByAccountId(accountId: $accId) {
      ...TargetFields
    }
  }
  ${TARGET_FIELDS}
`;
