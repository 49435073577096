import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentLibrary } from '@rio/rio-types';
import { useQueryParam, StringParam } from 'use-query-params';
import { useIntl } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import _ from 'lodash';

import { UploadDocumentModal } from '../../DocumentModal/v2';
import { usePermissions, useNotification, useCurrentAccountId } from '~/hooks';
import documentTypes from '../../documentTypes';
import { DocumentExplorer } from '../../DocumentExplorer/v2';
import { DocumentTypeFolder } from '../../types';
import ExportButtons from './ExportButtons';
import { useGetDocuments } from '../../useGetDocuments';
import { getAllFilters } from '../../utils';
import { Page, Button, styled, CustomIcons } from '@rio/ui-components';

interface DocumentFilesProps {
  documentFolders: DocumentTypeFolder[];
}

const ActionButtonsContainerStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ClearFiltesButtonStyled = styled(Button)`
  width: 160px;
`;

const UploadDocumentsButtonStyled = styled(Button)`
  width: 160px;
  height: 40px;
`;

const DocumentFiles = (props: DocumentFilesProps) => {
  const intl = useIntl();
  const { documentFolders } = props;
  const accountId = useCurrentAccountId();
  const { library, type } = useParams();
  const [gridApi, setGridApi] = useState<GridApi>();
  const permissions = usePermissions();
  const userCanUpload = !!permissions.document.find((action: string) => action.startsWith('create'));
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useQueryParam('search', StringParam);

  const { showNotification } = useNotification();

  const appliedDocumentType = useMemo(
    () =>
      library && type && type !== 'all'
        ? documentFolders.find((folder: DocumentTypeFolder) => folder.link === type)
        : null,
    [library, type, documentFolders]
  );

  const breadcrumbs = useMemo(() => {
    const crumbs = [{ label: intl.formatMessage({ id: 'pages.documents.breadcrumbs.one' }), to: '/documents' }];
    if (library) {
      crumbs.push({ label: _.capitalize(library), to: `/documents/${library}` });
    } else {
      crumbs.push({ label: 'All', to: `/documents/all` });
    }
    return crumbs;
  }, [intl, library]);

  const pageTitle = useMemo(() => appliedDocumentType?.title || 'All Documents', [appliedDocumentType]);

  const { documentsExist, error, loading } = useGetDocuments(
    {
      accountId,
      filters: getAllFilters(searchValue || ''),
    },
    !searchValue
  );

  const onComplete = useCallback(async () => {
    setShowCreateModal(false);

    setTimeout(() => {
      gridApi?.refreshServerSide();
    }, 1000);
  }, [gridApi]);

  const onError = useCallback(
    (err: Error) => {
      showNotification(err.message, 'danger');
    },
    [showNotification]
  );

  const onClearClick = useCallback(() => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  }, [gridApi]);

  return (
    <Page
      title={{
        crumbs: breadcrumbs,
        content: pageTitle,
        actionButton: (
          <ActionButtonsContainerStyled>
            <ClearFiltesButtonStyled variant="outlined" onClick={onClearClick}>
              Clear Filters
            </ClearFiltesButtonStyled>
            {userCanUpload && (
              <>
                <ExportButtons gridApi={gridApi} accountId={accountId} />
                <UploadDocumentsButtonStyled variant="filled" onClick={() => setShowCreateModal(true)}>
                  <CustomIcons.Upload color="onPrimary" />
                  Upload
                </UploadDocumentsButtonStyled>
              </>
            )}
          </ActionButtonsContainerStyled>
        ),
      }}
      loading={loading && !documentsExist}
      error={error}
    >
      <DocumentExplorer
        showGrid={!!type || !library}
        library={library}
        category={appliedDocumentType?.filterValue}
        gridApi={gridApi}
        setGridApi={setGridApi}
        searchValue={searchValue as string}
        onSearch={setSearchValue}
        documentsExist={documentsExist}
      />
      {showCreateModal && (
        <UploadDocumentModal
          predefinedValues={{
            library: library?.toUpperCase() as DocumentLibrary,
            category: documentTypes.find((item) => item.link === type)?.filterValue,
          }}
          onDismiss={() => {
            setShowCreateModal(false);
          }}
          onComplete={onComplete}
          onUpload={onComplete}
          onError={onError}
        />
      )}
    </Page>
  );
};

export default DocumentFiles;
