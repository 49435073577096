import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  RowModelType,
  SetFilterValuesFuncParams,
} from 'ag-grid-community';
import * as Sentry from '@sentry/react';
import { Filters, FilterData } from '@rio/rio-types';
import { Grid as AgGrid } from '../../../../components/UI';
import { useAgGrid, useCurrentAccountId, useNotification } from '~/hooks';
import { mapSortModel, mapFilterModel, defaultFilterParams, formatDate, gridValueFormatter } from '../../../../utils';
import { FetchRowsFunction, GridApiType } from '../types';
import { GET_DATA_TRACKER_VALIDATION_FILTERS } from './index.queries';

export const PAGINATION_PAGE_SIZE = 25;

interface GridProps {
  fetchRows: FetchRowsFunction;
  setGridApi: React.Dispatch<React.SetStateAction<GridApiType>>;
  onCellEditingStopped: (event: CellEditingStoppedEvent) => void;
  onCellEditingStarted: (event: CellEditingStartedEvent) => void;
  showPlaceholder: () => void;
  onSelectionChanged?: () => void;
}

export function Grid({
  setGridApi,
  fetchRows,
  onCellEditingStopped,
  onCellEditingStarted,
  showPlaceholder,
  onSelectionChanged,
}: GridProps) {
  const accountId = useCurrentAccountId();
  const client = useApolloClient();
  const { showNotification } = useNotification();
  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getDataTrackerValidationFilters: values },
      } = await client.query({
        query: GET_DATA_TRACKER_VALIDATION_FILTERS,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });

      params.success(values.map((item: FilterData) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      Sentry.captureException(err);
    }
  };

  const agGrid = useAgGrid({ autoFit: true });
  const options = {
    pagination: true,
    paginationPageSizeSelector: false,
    paginationPageSize: PAGINATION_PAGE_SIZE,
    rowSelection: 'multiple' as 'multiple',
    rowModelType: 'serverSide' as RowModelType,
    enableCellChangeFlash: true,
    tooltipShowDelay: 500,
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        paddingTop: '5px',
        paddingBottom: '5px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
      autoHeight: true,
    },
    columnDefs: [
      {
        headerName: 'Utility',
        headerTooltip: 'Resource for which data is expected',
        checkboxSelection: true,
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        field: 'utility',
        minWidth: 150,
      },
      {
        headerName: 'Supplier',
        headerTooltip: 'Upload supplier',
        filter: 'agTextColumnFilter',
        filterParams: {
          ...defaultFilterParams(),
        },
        field: 'supplierName',
        minWidth: 200,
      },
      {
        headerName: 'Start Date',
        headerTooltip: 'Start date of the expected time frame',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        field: 'startDate',
        minWidth: 200,
      },
      {
        headerName: 'End Date',
        headerTooltip: 'End date of the expected time frame',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => formatDate(value),
        field: 'endDate',
        minWidth: 200,
      },
      {
        headerName: 'Location',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        field: 'locations',
        minWidth: 200,
      },
      {
        headerName: 'Meter',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        field: 'meters',
        minWidth: 200,
      },
      {
        headerName: 'Reason',
        headerTooltip: 'Notification reason',
        filter: 'agTextColumnFilter',
        filterParams: {
          ...defaultFilterParams(),
        },
        field: 'reason',
        minWidth: 300,
      },
      {
        headerName: 'Notes',
        headerTooltip: 'Notification notes can be left here',
        filter: 'agTextColumnFilter',
        filterParams: {
          ...defaultFilterParams(),
        },
        field: 'note',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueGetter: ({ data: { note } }) => note,
        valueSetter: (params) => {
          if (params.newValue !== params.oldValue) {
            return true;
          } else {
            return false;
          }
        },
        minWidth: 200,
      },
      {
        headerName: 'Uploader',
        headerTooltip: 'Username',
        valueGetter: () => '-',
        field: 'emptyField',
        sortable: false,
        filter: false,
        minWidth: 200,
      },
      {
        headerName: 'Filename',
        headerTooltip: 'Filename',
        valueGetter: () => '-',
        field: 'emptyField2',
        sortable: false,
        filter: false,
        minWidth: 200,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  return (
    <AgGrid
      {...options}
      suppressRowClickSelection={true}
      cacheBlockSize={options.paginationPageSize}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      onCellEditingStopped={onCellEditingStopped}
      onCellEditingStarted={onCellEditingStarted}
      onSelectionChanged={onSelectionChanged}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, columnApi: params.columnApi });
        params.api.setServerSideDatasource({
          // eslint-disable-next-line @typescript-eslint/no-shadow
          async getRows(params) {
            try {
              const { startRow, sortModel, filterModel } = params.request;
              const filters: Filters = mapFilterModel(filterModel);

              const { rows, totalRows } = await fetchRows({
                offset: startRow || 0,
                limit: options.paginationPageSize,
                sort: mapSortModel(sortModel),
                filters,
              });
              params.success({
                rowData: rows,
                rowCount: totalRows,
              });
              if (totalRows === 0 && isEmpty(filters)) {
                return showPlaceholder();
              }
            } catch (err) {
              params.fail();
              Sentry.captureException(err);
            }
          },
        });
      }}
    />
  );
}
