import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { TaskSubject, Scope } from '@rio/rio-types';
import { StyledLabelWithRequiredBlock } from '../../../../components/StyledElements';
import priorityOptions from '../../TasksGrid/taskPriorities.json';
import categoryOptions from '../../TasksGrid/taskCategories.json';
import { subjectOptions } from '../taskSubjects';
import GET_TASK_BY_ID from '../../../../graphql/queries/tasks/GetTaskById.query.graphql';
import UPDATE_TASK from '../../../../graphql/mutations/tasks/UpdateTask.mutation.graphql';
import DELETE_TASK from '../../../../graphql/mutations/tasks/DeleteTask.mutation.graphql';
import { LegislationSelect } from '../../../../components/LegislationSelect/v2';
import { AspectsSelect } from '../../../../components/AspectsSelect/v2';
import { TargetsSelect } from '../../../../components/TargetsSelect/v2';
import { LocationsMultiSelect } from '../../../../components/LocationsMultiSelect/v2';
import { TagsMultiSelect } from '../../../../components/TagsMultiSelect/v2';
import { ProjectSelect } from '../../../../components/ProjectSelect/v2';
import { UsersMultiSelect } from '../../../../components/UserSelect/v2';
import { formatDateLocal } from '~/utils';
import { SelectEvent, InputEvent } from '../../../../types';
import { formatTaskData, prepareTaskInput } from '../utils';
import { TaskDetails } from '../types';
import { ValidateTask } from '../../TasksGrid/validate';
import { DeleteModal } from '../../../../components/DeleteModal/v2';
import GET_TASKS_BY_ACCOUNT from '../../../../graphql/queries/tasks/GetTasksByAccountId.query.graphql';
import {
  useUserToken,
  useNotification,
  usePermissions,
  useScopeOptions,
  useCurrentAccountId,
  useRoutes,
  usePageSuspendingQuery,
} from '~/hooks';
import {
  Page,
  styled,
  Button,
  Grid,
  TextField,
  Checkbox,
  DatePicker,
  Select,
  TextArea,
  OptionsProps,
} from '@rio/ui-components';
import { useMutation } from '@apollo/client';

type Error = Partial<{
  name: string;
  description: string;
  priority: string;
  category: string;
  subject: string;
  owner: string;
  dueDate: string;
}>;

const ActionButtonsContainerStyled = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ActionButtonStyled = styled(Button)`
  width: 160px;
`;

const hasPreviousPage = window.history.state && window.history.state.idx > 0;

const TasksViewContainer = ({ hasProjectsAccess = false }) => {
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const scopeOptions = useScopeOptions();
  const canEditTask = permissions.task.some((action: string) => action.startsWith('edit'));
  const canDeleteTask = permissions.task.some((action: string) => action.startsWith('delete'));
  const { token } = useUserToken();
  const { showNotification } = useNotification();
  const [taskData, setTaskData] = useState<TaskDetails>({} as TaskDetails);
  const { id: taskId } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Error>({});
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const routes = useRoutes();

  const { data, error, loading } = usePageSuspendingQuery(GET_TASK_BY_ID, {
    variables: { id: taskId },

    onCompleted: (receivedData) => {
      const task = receivedData?.getTaskById;
      setTaskData(formatTaskData(task));
    },
  });

  const navigateOnButtonClick = (hasPrevPage: boolean) => (hasPrevPage ? navigate(-1) : navigate(routes.tasks.root));

  const [updateTask, { loading: taskUpdateLoading }] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      showNotification('Task has been successfully updated');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const [deleteTask, { loading: taskDeleteLoading }] = useMutation(DELETE_TASK, {
    onCompleted: () => {
      navigateOnButtonClick(hasPreviousPage);
      showNotification('Task has been successfully deleted');
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
    refetchQueries: [{ query: GET_TASKS_BY_ACCOUNT, variables: { accountId, userId: token.sub } }],
  });

  const handleInputChange = (event: SelectEvent | InputEvent) => {
    if (Object.keys(errors).find((name) => name === event.target.name)) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }

    setTaskData({
      ...taskData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (fieldName: string, value: string | OptionsProps[], cb?: () => void) => {
    if (Object.keys(errors).find((name) => name === fieldName)) {
      setErrors({
        ...errors,
        [fieldName]: null,
      });
    }

    setTaskData({
      ...taskData,
      [fieldName]: value || null,
    });
    cb?.();
  };

  const handleSaveTask = useCallback(() => {
    const taskInput = prepareTaskInput(taskData);
    const validationErrors = ValidateTask(taskInput);

    if (isEmpty(validationErrors)) {
      setErrors({});
      updateTask({
        variables: taskInput,
        refetchQueries: ['GetTaskById'],
      });
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
    }
  }, [taskData, updateTask]);

  const handleTaskDelete = (id: string | undefined) => {
    if (id === undefined) return;
    deleteTask({
      variables: { id },
    });
  };

  const savedTaskName = data?.getTaskById?.name;

  const crumbs = useMemo(() => {
    const result: { label: string; to?: string }[] = [{ label: 'Tasks', to: routes.tasks.root }];

    if (savedTaskName) {
      result.push({ label: savedTaskName });
    }

    return result;
  }, [savedTaskName, routes.tasks.root]);

  const areActionsDisabled = loading || taskUpdateLoading || taskDeleteLoading;

  return (
    <Page
      title={{
        content: data?.getTaskById?.name || 'Task Details',
        crumbs,
        actionButton: (
          <ActionButtonsContainerStyled>
            {canEditTask && (
              <ActionButtonStyled
                onClick={handleSaveTask}
                disabled={areActionsDisabled}
                data-testid="Save_Button"
                variant="filled"
                color="primary"
              >
                Save
              </ActionButtonStyled>
            )}
            <ActionButtonStyled
              onClick={() => navigateOnButtonClick(hasPreviousPage)}
              disabled={areActionsDisabled}
              data-testid="Cancel_Button"
              variant="outlined"
              color="primary"
            >
              Cancel
            </ActionButtonStyled>
            {canDeleteTask && (
              <ActionButtonStyled
                onClick={() => setShowDeleteTaskModal(true)}
                disabled={areActionsDisabled}
                data-testid="Delete_Button"
                variant="filled"
                color="tertiary"
              >
                Delete
              </ActionButtonStyled>
            )}
          </ActionButtonsContainerStyled>
        ),
      }}
      loading={loading}
      error={error}
    >
      <DeleteModal
        open={showDeleteTaskModal}
        deleteObjectName="task"
        onConfirmClick={() => handleTaskDelete(taskId)}
        onDismissClick={() => setShowDeleteTaskModal(false)}
      />
      {data && (
        <Grid container columns={12} rowSpacing={6} columnSpacing={4}>
          <Grid item xs={12}>
            <TextField
              data-testid="Task_Name"
              name="name"
              label="Name"
              value={taskData.name}
              onChange={handleInputChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              data-testid="Task_Description"
              name="description"
              label="Description"
              value={taskData.description}
              onChange={handleInputChange}
              error={Boolean(errors.description)}
              helperText={errors.description}
              disabled={!canEditTask}
              rows={5}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              data-testid="Task_Creator"
              name="author"
              label="Creator"
              value={taskData.author}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              data-testid="Task_CreatedAt"
              name="createdAt"
              label="Created at"
              value={formatDateLocal(taskData.createdAt)}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              label="Completed"
              checked={taskData.isCompleted}
              onChange={() => {
                setTaskData({
                  ...taskData,
                  isCompleted: !taskData.isCompleted,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {taskData.isCompleted && (
              <DatePicker
                data-testid="Completed_Date"
                name="completedAt"
                label="Completion Date"
                value={
                  taskData.isCompleted && !!taskData.completedAt
                    ? new Date(taskData.completedAt)
                    : taskData.isCompleted
                    ? new Date()
                    : undefined
                }
                onChange={(date) => {
                  setTaskData({
                    ...taskData,
                    completedAt: date ? new Date(date).toISOString() : null,
                  });
                }}
                disabled={!canEditTask}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              data-testid="Task_Status"
              name="status"
              label="Status"
              onChange={handleInputChange}
              value={
                taskData.isCompleted
                  ? 'Completed'
                  : new Date(taskData.dueDate as string) > new Date()
                  ? 'Incompleted'
                  : 'Overdue'
              }
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              data-testid="Due_Date"
              name="dueDate"
              label="Due date"
              value={taskData.dueDate ? new Date(taskData.dueDate) : undefined}
              onChange={(date) => {
                setTaskData({
                  ...taskData,
                  completedAt: date ? new Date(date).toISOString() : null,
                });
              }}
              error={errors.dueDate}
              disabled={!canEditTask}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              data-testid="Task_scope"
              name="scope"
              label="Scope"
              options={scopeOptions}
              value={taskData.scope}
              onChange={({ value }) => handleSelectChange('scope', value)}
              disabled={!canEditTask}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              data-testid="Task_priority"
              name="priority"
              label="Priority"
              value={taskData.priority}
              options={priorityOptions}
              onChange={({ value }) => handleSelectChange('priority', value)}
              disabled={!canEditTask}
              error={Boolean(errors.priority)}
              helperText={errors.priority}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              data-testid="Task_category"
              name="category"
              label="Category"
              onChange={({ value }) =>
                handleSelectChange('category', value, () =>
                  setTaskData((prevState) => ({ ...prevState, subject: null, item: null }))
                )
              }
              value={taskData.category}
              options={categoryOptions}
              disabled={!canEditTask}
              error={Boolean(errors.category)}
              helperText={errors.category}
            />
          </Grid>
          {taskData.scope === Scope.Location && (
            <Grid item xs={12}>
              <LocationsMultiSelect
                label="Locations"
                name="locations"
                accountId={accountId}
                value={taskData.locations || []}
                onChange={(value) => handleSelectChange('locations', value)}
                disabled={!canEditTask}
              />
            </Grid>
          )}
          {taskData.scope === Scope.Tag && (
            <Grid item xs={12}>
              <TagsMultiSelect
                label="Tags"
                name="tags"
                accountId={accountId}
                value={taskData.tags || []}
                onChange={(value) => handleSelectChange('tags', value)}
                disabled={!canEditTask}
              />
            </Grid>
          )}
          {`${taskData.category}` in subjectOptions ? (
            <Grid item xs={12}>
              <Select
                name="subject"
                label="Subject"
                onChange={({ value }) => handleSelectChange('subject', value)}
                value={taskData.subject?.toUpperCase() || null}
                options={subjectOptions[taskData.category]}
                error={Boolean(errors.subject)}
                helperText={errors.subject}
                disabled={!canEditTask}
              />
            </Grid>
          ) : null}
          {taskData.subject &&
            [TaskSubject.Legislation, TaskSubject.Aspects, TaskSubject.Targets].includes(
              taskData.subject as TaskSubject
            ) && (
              <>
                {taskData.subject === TaskSubject.Legislation && (
                  <Grid item xs={12}>
                    <LegislationSelect
                      name="itemId"
                      onChange={({ value }) => handleSelectChange('itemId', value)}
                      value={taskData.itemId || taskData.item?.id || ''}
                      disabled={!canEditTask}
                    />
                  </Grid>
                )}
                {taskData.subject === TaskSubject.Aspects && (
                  <Grid item xs={12}>
                    <AspectsSelect
                      name="itemId"
                      onChange={({ value }) => handleSelectChange('itemId', value)}
                      value={taskData.itemId || taskData.item?.id || ''}
                      disabled={!canEditTask}
                    />
                  </Grid>
                )}
                {taskData.subject === TaskSubject.Targets && (
                  <Grid item xs={12}>
                    <TargetsSelect
                      name="itemId"
                      onChange={({ value }) => handleSelectChange('itemId', value)}
                      value={taskData.itemId || taskData.item?.id || ''}
                      disabled={!canEditTask}
                    />
                  </Grid>
                )}
              </>
            )}
          <Grid item xs={12}>
            <UsersMultiSelect
              label="Owners"
              onChange={(value) => handleSelectChange('owners', value)}
              value={taskData.owners}
              accountId={accountId}
              disabled={!canEditTask}
              error={errors.owner}
            />
          </Grid>
          {hasProjectsAccess && (
            <Grid item xs={12}>
              <ProjectSelect
                name="projectId"
                accountId={accountId}
                onChange={({ value }) => handleSelectChange('projectId', value)}
                value={taskData.projectId || taskData?.project?.id || ''}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Page>
  );
};

export default TasksViewContainer;
