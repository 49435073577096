import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import { MODALS, Nullable } from '../../../../types';
import { usePermissions } from '../../../../hooks';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 0 0 ${(props) => props.theme.geometry.xl.spacing};
  margin-right: ${(props) => props.theme.geometry.sm.spacing};

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

export type ActionFunction = (
  type?: Nullable<string>,
  dataTrackerConfigId?: Nullable<string>,
  utilityType?: Nullable<string>
) => void;

export interface ActionCellProps {
  data: {
    id: string;
    utilityType: string;
    account: {
      id: string;
    };
  };
  onEdit: ActionFunction;
  onDelete: ActionFunction;
}

export const ActionCell = ({ data: { id, account }, onEdit, onDelete }: ActionCellProps) => {
  const permissions = usePermissions();
  const canEditDataTrackerConfig = permissions.dataTracker.find((action: string) => action.startsWith('edit'));
  const canDeleteDataTrackerConfig = permissions.dataTracker.find((action: string) => action.startsWith('delete'));

  const selectedId = id ? id : account?.id;

  return (
    <GridCellContainer>
      {canEditDataTrackerConfig && (
        <LinkStyled onClick={() => onEdit(MODALS.UPDATE, selectedId)}>
          <Icon name={`data-tracker-config-row__edit--${selectedId}`} icon="pencil-alt" />
        </LinkStyled>
      )}
      {canDeleteDataTrackerConfig && (
        <LinkStyled onClick={() => onDelete(MODALS.DELETE, selectedId)}>
          <Icon name={`data-tracker-config-row__delete--${selectedId}`} icon="trash-alt" />
        </LinkStyled>
      )}
    </GridCellContainer>
  );
};
