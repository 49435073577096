import { useCallback, useState, useEffect } from 'react';
import { get } from 'lodash';
import { Location } from '@rio/rio-types';
import { Select, Modal, ToastColor, OptionsProps } from '@rio/ui-components';
import { CreateLocation } from '~/containers/ConfigurationContainer/ConfigurationLocationsContainer/v2';
import { Nullable, Option } from '~/types';
import { useNotification, usePrevious } from '~/hooks';

type RefetchType = (variables: {
  id?: string;
  accountId?: string;
  sort: { order: string; field: string }[];
  filters: { text: { filterType: string; type: string; filter: string; field: string }[] };
}) => void;

interface BaseLocationSelectProps {
  accountId: string;
  refetch: RefetchType;
  name?: string;
  onChange: (e: OptionsProps) => void;
  value?: OptionsProps;
  label?: string;
  error?: Nullable<Error | string>;
  loading?: boolean;
  isLocate?: boolean;
  accessor?: string;
  createNew?: boolean;
  loadOptions: () => Promise<{ options: Option[]; total?: number }>;
  locationPoints?: {
    wasteOut?: boolean;
    wasteIn?: boolean;
  };
}

export const order = [
  {
    order: 'asc',
    field: 'name',
  },
];

const CREATE_NEW = 'CREATE_NEW';

export const BaseLocationSelect = ({
  onChange,
  refetch,
  error,
  name,
  value,
  accountId,
  loadOptions,
  isLocate,
  label,
  createNew,
  locationPoints,
  accessor = '',
}: BaseLocationSelectProps) => {
  const { showNotification } = useNotification();
  const [options, setOptions] = useState<OptionsProps[]>([]);
  const [showCreateLocation, setShowCreateLocation] = useState(false);

  const prevOptions: OptionsProps[] = usePrevious(options) || [];

  const handleChange = useCallback(
    (e) => {
      if (e.value === CREATE_NEW) {
        setShowCreateLocation(true);
      } else {
        onChange(e);
      }
    },
    [onChange]
  );

  const onSuccess = useCallback(
    async (message: string, color: string, _: unknown, location: Location) => {
      const filters = {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: '',
            field: 'name',
          },
        ],
      };

      const refetchVariables = isLocate
        ? {
            accountId,
            sort: order,
            filters,
          }
        : {
            id: accountId,
            sort: order,
            filters,
          };

      await refetch(refetchVariables);

      showNotification(message, color as ToastColor);
      const payload = {
        value: get(location, `${accessor}id`) as unknown as string,
        label: get(location, `${accessor}name`) as unknown as string,
      };

      setShowCreateLocation(false);
      handleChange(payload);
    },
    [accessor, accountId, handleChange, isLocate, refetch, showNotification]
  );

  const fetchOptions = useCallback(async () => {
    const { options: loadedOptions } = await loadOptions();
    const formattedOptions: OptionsProps[] = loadedOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));

    if (value?.value && !formattedOptions.find(({ value: optionValue }: OptionsProps) => optionValue === value.value)) {
      formattedOptions.unshift(value);
    }
    if (createNew) {
      formattedOptions.unshift({ label: 'Create new...', value: CREATE_NEW });
    }
    setOptions(formattedOptions);
  }, [createNew, loadOptions, value]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const helperText = error instanceof Error ? error?.message : error;

  return (
    <>
      {showCreateLocation && (
        <Modal
          open={showCreateLocation}
          title="Add New Location"
          grid={{ xs: 8, md: 6 }}
          onClose={() => setShowCreateLocation(false)}
        >
          <CreateLocation accountId={accountId} onSuccess={onSuccess} locationPoints={locationPoints} />
        </Modal>
      )}
      <Select
        key={Number(options.length !== prevOptions?.length)}
        value={value?.value}
        name={name}
        placeholder="Begin typing to find locations"
        options={options as Option[]}
        label={label}
        error={!!error}
        helperText={helperText || ''}
        onChange={handleChange}
      />
    </>
  );
};
