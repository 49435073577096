import styled from 'styled-components';
import { Survey as SurveyInterface } from '@rio/rio-types';
import { SurveyInfoStatus } from '../../../components/Surveys';

interface SurveyProps {
  survey: SurveyInterface;
  latest: boolean;
}

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: auto;
  justify-content: flex-end;
  font-size: 1em;
`;

export const TableStyled = styled.table`
  width: initial;
  color: #444;
  font-size: 0.95em;
`;
export const TableHeaderItem = styled.th`
  padding-right: ${({ theme }) => theme.geometry.xl.spacing};
  padding-bottom: ${({ theme }) => theme.geometry.sm.spacing};
  font-weight: 600;
`;
export const LatestSurveyText = styled(TableHeaderItem)`
  font-size: 15px;
  font-weight: normal;
  color: #83838e;
`;

export function Survey({ survey }: SurveyProps) {
  return (
    <Header>
      <TableStyled>
        <tbody>
          <tr>
            <TableHeaderItem>Contributors</TableHeaderItem>
            <td data-cy="Survey__ContributorsCount">{survey?.contributors?.length}</td>
          </tr>
        </tbody>
      </TableStyled>
      <HeaderRight>
        <SurveyInfoStatus deadlineDate={survey.deadlineDate} survey={survey} />
      </HeaderRight>
    </Header>
  );
}
