import React from 'react';
import { Row, Col, Heading, ProfilePicture } from 'rio-ui-components';
import styled from 'styled-components';

import CustomRouteLink from '../../../components/CustomRouteLink';

const AccountRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const AccountProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

interface AccountRowProps {
  accountId: string;
  businessName: string;
  profileImage: string;
  path: string;
}

const AccountRow: React.FC<AccountRowProps> = ({ accountId, businessName, profileImage, path }) => (
  <CustomRouteLink name="configuration-menu__item--contractors" to={`${path}/${accountId}`}>
    <AccountRowStyled name={`account-row--${accountId}`} container itemAlign="center">
      <AccountProfilePictureStyled
        name={`account-row__profile-picture--${accountId}`}
        src={profileImage}
        size="xs"
        accountName={businessName}
      />

      <Col container item>
        <Heading inline name={`account-row__name--${accountId}`} size="md">
          {businessName}
        </Heading>
      </Col>
    </AccountRowStyled>
  </CustomRouteLink>
);

export default AccountRow;
