import { TransactionType } from '@rio/rio-types';
import UploadContainer from '../../UploadContainer';
import columns from './columns.json';
import { TemplateType } from '../../../../types';

function ElectricityDataUploadContainer(props) {
  return (
    <UploadContainer
      {...props}
      dataSection={TransactionType.Electricity}
      template={{
        columns,
        fileName: 'electricity-template',
        type: TemplateType.Dynamic,
      }}
      showInvoiceUpload
    />
  );
}

export default ElectricityDataUploadContainer;
