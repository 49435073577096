import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Label, Col, Row, TextArea, CircleIcon, Button, InputFile } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonColStyled = styled(ColStyled)`
  flex-basis: 50%;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;
const FileName = styled(Col)`
  text-align: left;
  align-items: center;
  flex-flow: row nowrap;
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const DocumentFileButtonColumn = styled(ColStyled)`
  flex-basis: 120px;
  max-width: 120px;
`;

function File({ file, onDelete, readOnly }) {
  const isFile = file instanceof window.File;
  return (
    <Row container>
      <ColStyled grow="0">
        <CircleIcon size="xxs" icon="file" color="tretiary" />
      </ColStyled>
      <FileName grow="1" item container>
        {isFile && file.name}
      </FileName>
      {!readOnly && (
        <DocumentFileButtonColumn item grow="0">
          <Button type="button" color="danger" onClick={onDelete}>
            Remove
          </Button>
        </DocumentFileButtonColumn>
      )}
    </Row>
  );
}

const EvidencePicker = ({ evidenceDocuments = [], setEvidenceDocuments, evidenceNotes, setEvidenceNotes }) => {
  useEffect(() => {
    return () => {
      setEvidenceNotes('');
      setEvidenceDocuments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Evidence Name</LabelStyled>
          </LabelContainerStyled>
          <TextArea
            size="lg"
            onChange={(e) => {
              setEvidenceNotes(e.target.value);
            }}
            name="notes"
            value={evidenceNotes}
            box
          />
        </ColStyled>
      </Row>
      <Row container>
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Evidence</LabelStyled>
          </LabelContainerStyled>
          {!!evidenceDocuments?.length &&
            evidenceDocuments.map((document) => (
              <File
                file={document}
                key={document instanceof window.File ? document.name : document.fileName}
                onDelete={() => {
                  setEvidenceDocuments(evidenceDocuments.filter((doc) => doc !== document));
                }}
              />
            ))}
        </ColStyled>
      </Row>
      <Row container>
        <ButtonColStyled item grow="1">
          <InputFile
            multiple
            onChange={async (files) => {
              const documents = evidenceDocuments.concat(files);
              setEvidenceDocuments(documents);
            }}
          >
            {(pickFile) => (
              <ButtonStyled
                type="button"
                color="tertiary"
                onClick={() => {
                  pickFile();
                }}
              >
                Attach Evidence
              </ButtonStyled>
            )}
          </InputFile>
        </ButtonColStyled>
      </Row>
    </>
  );
};

export { EvidencePicker };
