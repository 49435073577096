import { useState, useCallback, FormEvent } from 'react';
import { Button, styled, Text } from '@rio/ui-components';
import { MutationCreateSurveyArgs, SurveyInput } from '@rio/rio-types';
import { useCurrentAccountId } from '../../../../hooks';
import { Errors } from '../../../../types';
import { validate } from '../../SurveysContainer/validate';
import { useCreate } from '../../queries';
import { SurveyForm } from './SurveyForm';
import { useSurveyForm } from '../../SurveysContainer/hooks/useSurveyForm';

const SurveyFormWrapper = styled('div')`
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledTextColumn = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

const ButtonStyle = styled(Button)`
  max-width: 144px;
  width: 100%;
`;

interface CreateSurveyProps {
  onDismiss: () => void;
  buttonDescription?: string;
  submitButtonText: string;
}

export function CreateSurvey({ onDismiss, buttonDescription, submitButtonText }: CreateSurveyProps) {
  const accountId = useCurrentAccountId();
  const { state: survey } = useSurveyForm();

  const [formErrors, setErrors] = useState<Errors<SurveyInput>>({});

  const [createSurvey, { loading }] = useCreate();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      setErrors({});

      const [errors, validSurvey] = validate(survey);
      if (errors) {
        return setErrors((err) => ({
          ...err,
          ...errors,
        }));
      }

      const variables: MutationCreateSurveyArgs = {
        survey: { ...validSurvey, ownerAccountId: accountId },
      };

      await createSurvey({
        variables,
        onCompleted: () => onDismiss(),
      });
    },
    [survey, createSurvey, setErrors, onDismiss, accountId]
  );

  return (
    <SurveyFormWrapper onSubmit={handleSubmit}>
      <SurveyForm errors={formErrors} disabled={loading} accountId={accountId} />

      <ButtonWrapper>
        <ButtonStyle variant="filled" name="AddSurvey__Submit" onClick={handleSubmit} disabled={loading}>
          {submitButtonText}
        </ButtonStyle>
        <StyledTextColumn typescale="body" size="small">
          {buttonDescription}
        </StyledTextColumn>
      </ButtonWrapper>
    </SurveyFormWrapper>
  );
}
