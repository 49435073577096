import { SvgIconProps } from './types';
import { Svg } from './Svg';

export const Fullscreen = ({ width = 12, height = 13, fill = '#83838e', ...rest }: SvgIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99999 12.5V10.5H9.99998V7.5L12 7.5V12.5H6.99999Z" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99999 0.5V2.5L9.99998 2.5V5.49999H12V0.5L6.99999 0.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99999 12.5V10.5H2L2 7.5L2.52881e-07 7.5L0 12.5H4.99999Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99999 0.5V2.5L2 2.5L2 5.49999H2.52881e-07L0 0.5L4.99999 0.5Z"
      fill={fill}
    />
  </Svg>
);
