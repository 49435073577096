import { useSubscription, useApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { ON_USER_UPDATED_SUBSCRIPTION } from '../graphql/subscriptions/user/OnUserUpdated.subscription.graphql';
import { saveUserSession } from '../resolvers/auth';
import { useCurrentUserId } from './useCurrentUserId';
import { useNotification } from './useNotification';

const updateSession = async (client) => {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  await user.refreshSession(session.getRefreshToken(), async () => {
    const newSession = await Auth.currentSession();
    await saveUserSession(newSession, client);
  });
};

/**
 * TODO: review when the following issues are fixed
 * https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509
 * https://github.com/apollographql/apollo-client/issues/6348
 */

export function useUserUpdated() {
  const userId = useCurrentUserId();
  const { showNotification } = useNotification();
  const client = useApolloClient();

  const { data: subscriptionData } = useSubscription(ON_USER_UPDATED_SUBSCRIPTION, {
    variables: { id: userId },
    skip: !userId,
    onSubscriptionData: () => {
      updateSession(client);
      showNotification('Your account has been updated');
    },
  });

  return subscriptionData?.userUpdated;
}
