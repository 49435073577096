import { useCallback, useRef, useMemo, useState } from 'react';
import { Button, CustomIcons } from '@rio/ui-components';
import { Dictionary, get, map } from 'lodash';
import { Filters, SortCommand } from '@rio/rio-types';
import { GridApi, Column, ValueFormatterParams, SortModelItem, ColumnState } from 'ag-grid-community';
import { Nullable } from '../../types';
import { useExportApi, ExportPageFn } from '../../hooks';
import { mapFilterModel, mapSortModel } from '../../utils';
import { NameYourFileModalV2 as NameYourFileModal } from '../NameYourFileModal';

interface FormatterConfig {
  headerName: string;
  valueFormatter: (value: unknown, data: object) => unknown;
}

interface ExportButtonProps {
  fetchRows: ExportPageFn;
  gridApi?: GridApi;
  defaultExportFileName: string;
  context?: Dictionary<unknown>;
  columnsToSkip?: string[];
  step?: number;
  customSortModelMapper?: (columnState: Array<SortModelItem | ColumnState>) => SortCommand[];
  customFilterModelMapper?: (filterModel: Dictionary<unknown>) => Filters;
}

export const ExportButtonV2 = ({
  fetchRows,
  gridApi,
  defaultExportFileName,
  context = {},
  columnsToSkip = [],
  step,
  customFilterModelMapper,
  customSortModelMapper,
}: ExportButtonProps) => {
  const [fileNameModalShown, setFileNameModalShown] = useState(false);
  const exportApi = useExportApi();
  const exportId = useRef<Nullable<string>>();

  const formatters = useMemo(() => {
    const columns = gridApi?.getColumns() || [];
    const valueFormatters: Dictionary<FormatterConfig> = {};

    columns.forEach((column: Column) => {
      const colDef = column.getColDef();

      if (!columnsToSkip.includes(colDef.headerName as string)) {
        valueFormatters[colDef.field as string] = {
          headerName: colDef.headerName as string,
          valueFormatter: (value: unknown, data: object) =>
            typeof colDef.valueFormatter === 'function'
              ? colDef.valueFormatter({ value, context, data } as ValueFormatterParams)
              : value,
        };
      }
    });
    return valueFormatters;
  }, [gridApi, context, columnsToSkip]);

  const formatRow = useCallback(
    (row) => {
      const formattedRow: Dictionary<unknown> = {};
      map(formatters, (formatter, key) => {
        const { headerName, valueFormatter } = formatter;
        const value = get(row, key);
        formattedRow[headerName] = valueFormatter(value || '', row);
      });
      return formattedRow;
    },
    [formatters]
  );

  const handleExportClick = useCallback(() => {
    setFileNameModalShown(true);
  }, []);

  const handleDismissModal = useCallback(() => {
    setFileNameModalShown(false);
  }, []);

  const handleSubmit = useCallback(
    (exportFileName: string) => {
      if (!gridApi) {
        throw new Error('Data is not yet ready for export');
      }

      const columnState = gridApi.getColumnState();
      const filterModel = gridApi.getFilterModel();

      setFileNameModalShown(false);

      const sort = customSortModelMapper ? customSortModelMapper(columnState) : mapSortModel(columnState);
      const filters = customFilterModelMapper ? customFilterModelMapper(filterModel) : mapFilterModel(filterModel);

      exportId.current = exportApi?.start({
        fetchRows,
        exportFileName,
        formatRow,
        step,
        sort,
        filters,
      });
    },
    [gridApi, exportApi, fetchRows, formatRow, step, customSortModelMapper, customFilterModelMapper]
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleExportClick}
        startIcon={<CustomIcons.Download width="18px" height="18px" color="primary" />}
      >
        Export
      </Button>
      <NameYourFileModal
        show={fileNameModalShown}
        onDismiss={handleDismissModal}
        defaultFileName={defaultExportFileName}
        onSubmit={handleSubmit}
      />
    </>
  );
};
