import { gql } from '@apollo/client';

export const GET_OCR_DATA = gql`
  query GetOcrDataForAccount($accountId: String!, $utility: OcrUtility!) {
    getOcrDataForAccount(accountId: $accountId, utility: $utility) {
      id
      accountId
      fileName
      uploadedAt
      uploadedBy {
        first_name
        last_name
      }
      utility
      meterName
      invoiceNumber
      quantity
      startDate
      endDate
      totalCost
      tariff
      reading
    }
  }
`;

export const MARK_AS_REVIEWED = gql`
  mutation MarkAsreviewed($id: ID!) {
    markOcrDataAsReviewed(id: $id)
  }
`;
