import { styled } from '@mui/material-next/styles';
import { Page, TextField } from '@rio/ui-components';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useDebounce } from 'use-debounce';

import { capitalise } from '../../utils';
import CourseBanner from '../../Courses/CourseBanner';

import { CourseCards } from '../CoursesSection';

import { useSearchCourses } from './useSearchCourses';
import { useCoursesSections } from './useCoursesSections';

const breadcrumbs = [
  {
    label: 'Learn',
    to: '/v2/engage',
  },
  {
    label: 'Courses',
  },
];
const fixedSection = ['explore', 'continue', 'completed', 'all'];

const TextFieldStyled = styled(TextField)`
  margin-bottom: 32px;
`;

const PageContent = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`;

const CourseBannerContainer = styled('div')`
  margin-bottom: 32px;
`;

export function Courses() {
  const { id: section = 'all' } = useParams();
  const [search, setSearch] = useQueryParam<string>('search');
  const [debouncedSearchValue] = useDebounce(search, 600);

  const isFixedSection = fixedSection.includes(section);

  const { data: searchData, loading: searchLoading } = useSearchCourses(
    debouncedSearchValue ?? '',
    !isFixedSection ? section : null,
    false,
    'cache-first'
  );

  const coursesToDisplay = searchData?.searchCourses?.data;
  const currentBundle = searchData?.getLearnBundleById;
  const coursesSection = useCoursesSections(
    coursesToDisplay,
    currentBundle ? [currentBundle] : searchData?.getLearnBundlesForAccount
  );
  const detectedCoursesList = useMemo(
    () =>
      coursesSection.find((coursesSectionItem) =>
        isFixedSection ? coursesSectionItem.title.toLowerCase() === section : coursesSectionItem.bundleId === section
      ),
    [coursesSection, isFixedSection, section]
  );

  const breadcrumbDynamicItem =
    !isFixedSection && !currentBundle?.title
      ? undefined
      : { label: isFixedSection ? capitalise(section) : currentBundle?.title };

  const handleSearchChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  return (
    <Page
      title={{
        crumbs: breadcrumbDynamicItem ? [...breadcrumbs, breadcrumbDynamicItem] : breadcrumbs,
        content: 'Learn about sustainability',
      }}
      loading={searchLoading}
    >
      <PageContent>
        <CourseBannerContainer>
          <CourseBanner />
        </CourseBannerContainer>
        <TextFieldStyled
          name="ConfigurationAccountsContainer__Controls__Search"
          type="search"
          value={search}
          onChange={handleSearchChange}
          error={false}
          placeholder="Search"
        />

        {!!detectedCoursesList?.courses.length && <CourseCards useGridView courses={detectedCoursesList.courses} />}
      </PageContent>
    </Page>
  );
}
