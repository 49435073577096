import { useApolloClient } from '@apollo/client';

import { Tag } from '@rio/rio-types';
import { useEffect, useState } from 'react';
import { GET_TAGS_BY_ACCOUNT_ID } from '../queries/tags';
import { useCurrentAccountId } from './useCurrentAccountId';

export function useTags() {
  const accountId = useCurrentAccountId();
  const client = useApolloClient();
  const [tags, setTags] = useState<Tag[] | null>(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await client.query({
        query: GET_TAGS_BY_ACCOUNT_ID,
        variables: {
          accountId,
        },
      });

      setTags(data?.getTagsByAccountId || []);
    }
    fetchData();
  }, [accountId, client]);

  return tags;
}
