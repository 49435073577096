import React from 'react';
import styled from 'styled-components';
import { Col } from 'rio-ui-components';
import { UserRole, GovernanceAccessControls } from '@rio/rio-types';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAccessControls, useGetCurrentUserQuery, useRoutes } from '~/hooks';
import { ENGAGE } from '../../../constants/accountTypes';
import LegislationLibrary from '../Legislation/LegislationLibrary';
import ManagementSystemLandingPage from '../ManagementSystems/LandingPage';
import ManagementSystemOverview from '../ManagementSystems/Overview';
import GresbOverview from '../ManagementSystems/GRESB';
import LegislationItem from '../Legislation/LegislationItem';
import GovernanceLandingPage from './GovernanceLandingPage';
import { Policies } from '../Policies';
import { Targets } from '../Targets';
import { Aspects } from '../Aspects';
import { AspectView } from '../Aspects/AspectView';
import { HealthAndSafetyRiskRegister } from '../HealthAndSafetyRiskRegister';
import { Materiality } from '../Materiality/Grid';
import materialities from '../Materiality/materialities.json';

const ContainerStyled = styled(Col)`
  height: 100%;
  overflow-y: auto;
`;

const protectedTo = (
  Component: React.ComponentType,
  complyAccessControls: GovernanceAccessControls,
  topic: keyof GovernanceAccessControls
) => {
  if (complyAccessControls?.[topic]) {
    return <Component />;
  }
  return <Navigate to="/governance" />;
};

const GovernanceContainer = () => {
  const {
    data: { comply },
  } = useAccessControls();
  const { data: userData } = useGetCurrentUserQuery();
  const routes = useRoutes();

  const isShouldUserUpgrade = userData?.role.role === UserRole.Individual && userData?.account.type === ENGAGE;

  return (
    <ContainerStyled container item>
      <Routes>
        <Route
          path=""
          element={<GovernanceLandingPage accessControls={comply} isShouldUserUpgrade={isShouldUserUpgrade} />}
        />
        <Route path={routes.governance.systems} element={protectedTo(ManagementSystemLandingPage, comply, 'systems')} />
        <Route
          path={routes.governance.agreements}
          element={protectedTo(ManagementSystemLandingPage, comply, 'agreements')}
        />
        <Route
          path={routes.governance.frameworks}
          element={protectedTo(ManagementSystemLandingPage, comply, 'frameworks')}
        />
        <Route
          path={`${routes.governance.systems}/50f543aa-da72-4090-8f1f-0a1fbd218b0b/*`}
          element={protectedTo(GresbOverview, comply, 'systems')}
        />
        <Route
          path={`${routes.governance.systems}/:id/*`}
          element={protectedTo(ManagementSystemOverview, comply, 'systems')}
        />
        <Route
          path={`${routes.governance.agreements}/:id/*`}
          element={protectedTo(ManagementSystemOverview, comply, 'agreements')}
        />
        <Route
          path={`${routes.governance.frameworks}/:id/*`}
          element={protectedTo(ManagementSystemOverview, comply, 'frameworks')}
        />
        <Route path={routes.governance.policies} element={protectedTo(Policies, comply, 'policies')} />
        <Route path={routes.governance.targets} element={protectedTo(Targets, comply, 'targets')} />
        <Route path={routes.governance.materiality} element={<Materiality materialities={materialities} />} />
        <Route path={routes.governance.aspects} element={protectedTo(Aspects, comply, 'systems')} />
        <Route path={`${routes.governance.aspects}/:id`} element={protectedTo(AspectView, comply, 'systems')} />
        <Route path={routes.governance.health} element={protectedTo(HealthAndSafetyRiskRegister, comply, 'systems')} />
        <Route path={routes.governance.legislation} element={<LegislationLibrary accessControls={comply} />} />
        <Route
          path={`${routes.governance.legislation}/:id`}
          element={<LegislationItem accessControls={comply} isShouldUserUpgrade={isShouldUserUpgrade} />}
        />
        <Route
          path={`${routes.governance.legislation}/:id/:tagId`}
          element={<LegislationItem accessControls={comply} isShouldUserUpgrade={isShouldUserUpgrade} />}
        />
        <Route path="*" element={<Navigate to={routes.governance.root} />} />
      </Routes>
    </ContainerStyled>
  );
};

export default GovernanceContainer;
