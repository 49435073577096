"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const Icons_1 = require("../Icons");
const Loader_1 = require("../Loader");
const ThemeProvider_1 = require("../../ThemeProvider");
const utils_1 = require("./utils");
const style_1 = require("./style");
const MultiSelect = (props) => {
    const { error, label, value, options, onChange, disabled, onCreate, newValue, isLoading, helperText, placeholder, handleClearOption, ...rest } = props;
    const sortedOptions = (0, react_1.useMemo)(() => [...options].sort((a, b) => {
        const firstOption = a.label.toLowerCase();
        const secondOption = b.label.toLowerCase();
        const createNewOption = 'create new';
        if (firstOption.startsWith(createNewOption)) {
            return -1;
        }
        if (secondOption.startsWith(createNewOption)) {
            return 1;
        }
        return firstOption.localeCompare(secondOption);
    }), [options]);
    const theme = (0, ThemeProvider_1.useTheme)();
    const selectedValues = value
        ? value
            .map((v) => (typeof v === 'string' ? options.find((o) => o.value === v) : v))
            .filter((v) => v !== undefined)
        : [];
    const handleChange = (0, react_1.useCallback)((selectedOptions) => {
        const selected = options.filter((option) => selectedOptions.includes(option.value));
        const valuesList = selected.map((el) => el.value);
        if (valuesList.includes(newValue || 'NEW') && onCreate) {
            onCreate();
        }
        else {
            onChange(selected);
        }
    }, [newValue, onChange, options]);
    const clearAllSelects = (0, react_1.useCallback)((event) => {
        event.stopPropagation();
        onChange([]);
    }, [onChange]);
    const handleDelete = (0, react_1.useCallback)((valueToDelete) => {
        const newValues = selectedValues?.filter((option) => option.value !== valueToDelete) || [];
        onChange(newValues);
    }, [onChange, selectedValues]);
    const stopEventPropagation = (0, react_1.useCallback)((event) => {
        event.stopPropagation();
    }, []);
    const loader = isLoading && ((0, jsx_runtime_1.jsx)(style_1.LoaderWrapper, { children: (0, jsx_runtime_1.jsx)(Loader_1.Loader, { spinner: "ClipLoader", color: theme.sys.color.primary }) }));
    const renderValue = (0, react_1.useCallback)((selected) => ((0, jsx_runtime_1.jsxs)(style_1.BlockStyled, { children: [(0, jsx_runtime_1.jsx)(style_1.ChipWrapper, { children: selected.map((item) => {
                    return ((0, jsx_runtime_1.jsx)(material_1.Chip, { clickable: true, label: options.find((option) => option.value === item)?.label, onDelete: () => handleDelete(item), deleteIcon: (0, jsx_runtime_1.jsx)(Icons_1.Icons.Clear, { onMouseDown: stopEventPropagation, fontSize: "small" }) }, item));
                }) }), !!selected.length && ((0, jsx_runtime_1.jsx)(Icons_1.Icons.Clear, { onMouseDown: stopEventPropagation, onClick: clearAllSelects, fontSize: "small" }))] })), [handleDelete, stopEventPropagation, clearAllSelects]);
    const menuItems = (0, react_1.useMemo)(() => sortedOptions.map((option) => ((0, jsx_runtime_1.jsxs)(style_1.MenuItemStyled, { value: option.value, children: [option.label, handleClearOption && option.value !== 'NEW' && ((0, jsx_runtime_1.jsx)(Icons_1.Icons.Clear, { sx: { color: theme.palette.grey[400], cursor: 'pointer' }, onMouseDown: stopEventPropagation, onClick: () => handleClearOption(option.value) }))] }, option.value))), [sortedOptions, handleClearOption, stopEventPropagation, theme.palette.grey]);
    return ((0, jsx_runtime_1.jsxs)(style_1.StyledFormControl, { variant: "standard", disabled: disabled, children: [label && (0, jsx_runtime_1.jsx)(style_1.StyledLabel, { children: label }), (0, jsx_runtime_1.jsxs)(style_1.StyledSelect, { ...rest, multiple: true, value: selectedValues.map(({ value }) => value), onChange: ({ target: { value } }) => handleChange(value), renderValue: renderValue, MenuProps: utils_1.MenuProps, children: [loader, menuItems] }), error && ((0, jsx_runtime_1.jsx)(style_1.StyledHelperText, { error: !!error, children: typeof error === 'boolean' ? helperText : error })), helperText && !error && (0, jsx_runtime_1.jsx)(style_1.StyledHelperText, { children: helperText })] }));
};
exports.MultiSelect = MultiSelect;
