import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Notification, Row, Search } from 'rio-ui-components';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useAccessControls } from '../../../hooks/useAccessControls';
import { GET_POLICIES_BY_ACCOUNT_ID } from './index.queries';
import { PolicyList } from './PolicyList';
import { AddPolicyButton } from './AddPolicyButton';
import { useSearch } from '../../../hooks/useSearch';
import PageHeader from '../../../components/PageHeader';
import { usePermissions } from '../../../hooks/usePermissions';
import areas from '../areas';

const PageContainer = styled.div`
  padding: 32px 48px 48px;
`;
const PoliciesGrid = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  padding-top: 32px;
  flex: 1 1 auto;
  gap: 24px;
`;
const SearchHeaderRow = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;
const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

export const Policies = () => {
  const permissions = usePermissions();
  const accountId = useCurrentAccountId();
  const { data: accessControls } = useAccessControls();
  const variables = {
    accountId
  };
  const [notification, setNotification] = useState(null);
  const { data } = useQuery(GET_POLICIES_BY_ACCOUNT_ID, {
    variables
  });
  const policies = data?.getPoliciesByAccountId || [];
  const [searchValue, setSearchValue, searchedPolicies] = useSearch(policies, ['id', 'title', 'version']);
  const canCreateOrEdit = !!permissions.governance.find((action) => action.startsWith('createPolicy'));

  const availableGovernancePages = areas.filter((area) => !area.comingSoon && accessControls.comply[area.accessIndex]);

  return (
    <>
      <PageHeader
        title="Policies"
        breadcrumbs={[{ title: 'Governance', to: '/governance' }, { title: 'Policies' }]}
        icon="pencil-alt"
        iconColor="tertiary"
        isDropdown
        dropdownItems={availableGovernancePages}
        dropdownDefault="Policies"
      />
      <PageContainer>
        <SearchHeaderRow container item itemAlign="center">
          <SearchStyled value={searchValue} onChange={(event) => setSearchValue(event.target.value)} hideButton />
        </SearchHeaderRow>
        <PoliciesGrid>
          {canCreateOrEdit && <AddPolicyButton showNotification={(message) => setNotification(message)} />}
          <PolicyList policies={searchedPolicies} showNotification={setNotification} canEdit={canCreateOrEdit} />
        </PoliciesGrid>
        {notification && (
          <Notification show color={notification.color} onClose={() => setNotification(null)}>
            {notification.message}
          </Notification>
        )}
      </PageContainer>
    </>
  );
};
