import { useEffect } from 'react';
import { MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';
import { usePageErrorContext, usePageLoadingContext } from '@rio/ui-components';
import { DocumentNode } from 'graphql';

/**
 * @description Wrapper around useMutation that encapsulates loading and error state
 * @param query Graphql document node
 * @param options MutationHookOptions<Response, Variables> options that will be bypassed to useMutation
 * @returns MutationTuple<Response, Variables>
 */
export function usePageSuspendingMutation<Response = any, Variables = OperationVariables>(
  query: DocumentNode,
  options: MutationHookOptions<Response, Variables> = {}
) {
  const { loading: pageLoading, setLoading: setPageLoading } = usePageLoadingContext();

  const { setError } = usePageErrorContext();

  const useMutationResult = useMutation<Response, Variables>(query, {
    ...options,
    notifyOnNetworkStatusChange: true,
    onCompleted: (...args) => {
      options.onCompleted?.(...args);
      /**
       * setTimeout is important because apollo returns cache data synchronously
       * and useEffect below executes after that's why we need to call setPageLoading(false) on the next tick
       * because otherwise page will be forever loading
       */
      setTimeout(() => {
        setPageLoading(false);
      }, 0);
    },
    onError: (error) => {
      options.onError?.(error);
      /**
       * Reason of setTimeout is exactly the same as in onCompleted
       */
      setTimeout(() => {
        setError(error);
      }, 0);
    },
  });

  /**
   * Toggle LoadingIndicator in Page component through context
   */
  useEffect(() => {
    const [, result] = useMutationResult;
    if (result.loading && !pageLoading) {
      setPageLoading(true);
    }
  }, [useMutationResult, pageLoading, setPageLoading, setError]);

  /**
   * To cleanup loading and error state when user leaves the page
   */
  useEffect(
    () => () => {
      setError(null);
      setPageLoading(false);
    },
    [setError, setPageLoading]
  );

  return useMutationResult;
}
