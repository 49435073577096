import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, styled, Icons } from '@rio/ui-components';
import { Filters, SortCommand } from '@rio/rio-types';
import Popover from '@mui/material/Popover';
import { AnomaliesDrawer } from '../AnomaliesDrawer';
import { TopTextContainer } from '../InfoContainer';
import { AboutContainer } from '../../Elements';
import { MissingDataDrawer } from '../MissingDataDrawer';

const MainContainer = styled('div')`
  padding: 44px 0;
  margin-bottom: 4px;
  height: fit-content;
  width: 100%;
  font-family: 'Plus Jakarta Sans', Helvetica, Arial, sans-serif;
`;

const AboutIconContainer = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${(p) => p.theme.palette.grey[600]};
    }
  }
`;

const HeaderContainer = styled('p')`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.sys.color.onBackground};
`;

const PopoverText = styled('p')`
  margin: 10px;
`;

const DataStatusContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export type FetchRowsParameters = {
  accountId: string;
  isIgnored?: boolean;
  offset?: number;
  limit?: number;
  sort?: SortCommand[];
  filters?: Filters;
};

const StatusContainer = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <MainContainer>
      <TopTextContainer typescale="body" size="medium">
        <HeaderContainer>
          <Text typescale="body" size="large">
            <FormattedMessage id="pages.data.overview.dataNotifications" />
          </Text>
          <AboutContainer aria-describedby={id} onClick={handleClick}>
            <AboutIconContainer>
              <Icons.InfoOutlined />
            </AboutIconContainer>
          </AboutContainer>
        </HeaderContainer>
        <Popover
          id={id}
          open={open}
          disableScrollLock
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Text typescale="label" size="large">
            <PopoverText>
              This section highlights inaccuracies or outliers in your data to improve your overall data quality
            </PopoverText>
          </Text>
        </Popover>
      </TopTextContainer>
      <DataStatusContainer>
        <MissingDataDrawer />
        <AnomaliesDrawer />
      </DataStatusContainer>
    </MainContainer>
  );
};

export default StatusContainer;
