import { useCallback, useMemo, useReducer } from 'react';
import { Option } from '~/types';

const initialState: MeterModalFormValues = {
  meterName: { error: '', value: '' },
  locationId: { error: '', value: '', label: '' },
  typeId: { error: '', value: { id: '', name: '' }, label: '' },
  meterIn: true,
  meterOut: false,
  isGeneration: false,
  code: '',
  hardwareProviderName: null,
  operatorProviderName: null,
  startDate: null,
  endDate: null,
};

type MeterModalFormValues = {
  meterName: { error: string; value: string | null };
  locationId: { error: string } & Option;
  typeId: { error: string; value: { id: string; name: string }; label: string };
  meterIn: boolean;
  meterOut: boolean;
  isGeneration: boolean;
  code: string | null;
  hardwareProviderName: string | null;
  operatorProviderName: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

type ChangeFieldValue = {
  type: 'change-field-value';
  fieldName: keyof Omit<MeterModalFormValues, 'typeId' | 'locationId' | 'meterName'>;
  value: string | Date | boolean;
};

type ChangeMeterName = {
  type: 'change-meter-name';
  value: MeterModalFormValues['meterName']['value'];
};

type ChangeLocation = {
  type: 'change-location';
  value: MeterModalFormValues['locationId']['value'];
  label: string;
};

type ChangeMeterType = {
  type: 'change-meter-type';
  value: MeterModalFormValues['typeId']['value'];
  label: string;
};

type ReplaceState = {
  type: 'replace-state';
  newState: MeterModalFormValues;
};

type MeterFormReducerActions = ChangeFieldValue | ChangeMeterName | ChangeLocation | ChangeMeterType | ReplaceState;

function meterFormReducer(state: MeterModalFormValues, action: MeterFormReducerActions): MeterModalFormValues {
  switch (action.type) {
    case 'change-field-value':
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    case 'change-meter-name': {
      let error = action.value ? '' : 'Meter Name cannot be empty';
      if (action.value !== null && action.value.length > 64) error = 'Meter Name cannot exceed 64 characters';

      return {
        ...state,
        meterName: { error, value: action.value },
      };
    }
    case 'change-location': {
      const fieldValue = action.value;
      const error = fieldValue ? '' : 'An location must be selected';

      return {
        ...state,
        locationId: { error: error, value: fieldValue, label: action.label },
      };
    }
    case 'change-meter-type':
      const fieldValue = action.value;
      const error = fieldValue ? '' : 'Type must be selected';

      if (!state.meterIn && !state.meterOut && fieldValue.name === 'Water') {
        return {
          ...state,
          meterIn: true,
          typeId: { error, value: fieldValue, label: fieldValue.name },
        };
      }
      return {
        ...state,
        typeId: { error, value: fieldValue, label: fieldValue.name },
      };
    case 'replace-state':
      return action.newState;
  }
}

export function useMeterModalForm() {
  const [state, dispatch] = useReducer(meterFormReducer, initialState);

  const isValidData = useMemo(() => {
    if (!state.meterName.value || state.meterName.error) return false;
    if (!state.typeId.value || state.typeId.error) return false;
    if (!state.locationId.value || state.locationId.error) return false;
    return true;
  }, [state.meterName, state.typeId, state.locationId, state.startDate]);

  const waterMeterType = useMemo(() => {
    if (state.typeId.value.name === 'Water') {
      return state.meterIn && state.meterOut ? 'BOTH' : state.meterOut ? 'OUT' : 'IN';
    }
    return null;
  }, [state.meterIn, state.meterOut, state.typeId.value.name]);

  const toggleWaterMeterIn = useCallback(() => {
    if (state.meterIn && !state.meterOut) {
      return;
    }
    dispatch({
      type: 'change-field-value',
      fieldName: 'meterIn',
      value: !state.meterIn,
    });
  }, [state.meterIn, state.meterOut]);

  const toggleWaterMeterOut = useCallback(() => {
    if (state.meterOut && !state.meterIn) {
      return;
    }
    dispatch({
      type: 'change-field-value',
      fieldName: 'meterOut',
      value: !state.meterOut,
    });
  }, [state.meterIn, state.meterOut]);
  return {
    isValidData,
    state,
    changeMeterName: (action: Omit<ChangeMeterName, 'type'>) => dispatch({ ...action, type: 'change-meter-name' }),
    changeMeterType: (action: Omit<ChangeMeterType, 'type'>) => dispatch({ ...action, type: 'change-meter-type' }),
    changeLocation: (action: Omit<ChangeLocation, 'type'>) => dispatch({ ...action, type: 'change-location' }),
    changeFieldValue: (action: Omit<ChangeFieldValue, 'type'>) => dispatch({ ...action, type: 'change-field-value' }),
    replaceState: (newState: MeterModalFormValues) => dispatch({ newState, type: 'replace-state' }),
    toggleWaterMeterIn,
    toggleWaterMeterOut,
    waterMeterType,
  };
}
