import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { StyledHeading, StyledText } from './Styled';

const StyledSummary = styled(Accordion)`
  min-width: 100%;
  & > .MuiAccordionSummary-root {
    background-color: ${(props) => (props.active ? props.theme.colors.tertiary.light.background : 'white')};
  }
  & .MuiAccordionSummary-root,
  & .MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    padding: 16px 24px;
  }
  & .MuiAccordionDetails-root {
    padding-top: 0;
    display: flex;
    flex-direction: column !important;
  }
  & .MuiAccordionSummary-content,
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: 30px;
  right: 60px;
  cursor: pointer;
`;

export const AccordionItem = ({
  heading,
  summary,
  children,
  defaultExpanded,
  active,
  editable = false,
  onEdit = () => {}
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <StyledSummary active={active} expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <Icon
            icon="chevron-down"
            size="md"
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        }
        id="panel1a-header"
      >
        {editable && <IconStyled icon={'pencil-alt'} onClick={onEdit} />}
        <div>
          <StyledHeading active={active}>{heading}</StyledHeading>
          {!expanded && summary && <StyledText active={active} source={summary} />}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {React.Children.map(children, (child) => {
          return child;
        })}
      </AccordionDetails>
    </StyledSummary>
  );
};
