import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_INFO } from '../queries/accounts';

export function useAccountById(accountId) {
  const { data } = useQuery(GET_ACCOUNT_INFO, {
    variables: { id: accountId }
  });

  return data && data.getAccountInfo ? data.getAccountInfo : null;
}
