import { DataAnomaly } from '@rio/rio-types';
import { styled, LineChart, Text } from '@rio/ui-components';
import { CustomTooltipProps } from 'ag-grid-react';
import HighchartsReact from 'highcharts-react-official';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

type Props = CustomTooltipProps<DataAnomaly>;

const Container = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.sys.color.surface};
  border-radius: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

export const VarianceTimeseriesTooltip = ({ data: anomaly }: Props) => {
  const [isContainerAnimating, setIsContainerAnimating] = useState(true);
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  // Needed for proper sizing of the chart
  useLayoutEffect(() => {
    setTimeout(() => {
      setIsContainerAnimating(false);
    }, 100);
  }, []);

  const chartLabels = useMemo(() => {
    const anomalyType = anomaly?.transaction?.__typename;

    switch (anomalyType) {
      case 'GasTransactionPageItem':
      case 'HeatSteamTransactionPageItem':
      case 'ElectricityTransactionPageItem': {
        return {
          title: `Meter (${anomaly?.meterName}) consumption - historic data`,
          yAxisTitle: 'Quantity Sum (kWh)',
        };
      }
      default:
        return undefined;
    }
  }, [anomaly]);

  if (!anomaly?.varianceTimeseries) {
    return null;
  }

  if (anomaly?.varianceTimeseries?.error) {
    return (
      <Container>
        <Text typescale="body" size="medium" textAlign="center">
          {anomaly.varianceTimeseries.error}
        </Text>
      </Container>
    );
  }

  return (
    <Container>
      {!isContainerAnimating && (
        <LineChart
          data={anomaly?.varianceTimeseries?.data || []}
          disableLegend
          title={chartLabels?.title}
          yAxisTitle={chartLabels?.yAxisTitle}
          ref={chartRef}
        />
      )}
    </Container>
  );
};
