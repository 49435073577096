import React from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';

const UtilityIcon = styled(Icon)`
  margin-left: 10px;
  margin-right: 15px;
`;

const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.normal.background};
`;

const UtilityButtonDiv = styled.div`
  font-size: ${({ theme }) => theme.fonts.md.size};
  color: ${({ theme }) => theme.colors.text.light.text};
  color: inherit;
  margin-right: ${({ theme }) => theme.geometry.xs.spacing};
  transition: all 0.3s linear;
  ${({ disabled }) => (disabled ? `opacity: 0.3;` : 'cursor: pointer;')}
  user-select: none;
`;

const UtilityButton = ({ children, onClick, active }) => {
  const handleClick = () => {
    if (active) onClick();
  };
  return (
    <UtilityButtonDiv onClick={handleClick} disabled={!active}>
      {children}
    </UtilityButtonDiv>
  );
};

const DocumentTypeFilterToolbar = ({ addActive, removeActive, addAll, removeAll }) => {
  return (
    <ToolBar>
      <UtilityButton onClick={addAll} active={addActive}>
        Show all
        <UtilityIcon icon="check" size="sm" />
      </UtilityButton>
      <UtilityButton onClick={removeAll} active={removeActive}>
        Hide all
        <UtilityIcon icon="times" size="sm" />
      </UtilityButton>
    </ToolBar>
  );
};

export default DocumentTypeFilterToolbar;
