import { useQuery } from '@apollo/client';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Text, LoadingIndicator, Button } from 'rio-ui-components';
import GET_PROJECTS from '../../../graphql/queries/projects/GetProjects.query.graphql';
import { Grid } from './Grid';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { usePermissions } from '../../../hooks/usePermissions';
import { useUserToken } from '../../../hooks/useUserToken';
import { LoadFailed } from '../../../components/Errors';
import { ClearButton } from '../../../components/ClearButton';
import { useAgGrid } from '../../../hooks';
import { useInitialFilterModel } from './useInitialFilterModel';
import { useNotification } from '../../../hooks';
import { ProjectPriority } from '@rio/rio-types';
import { CreateProjectModal } from './CreateProjectModal';

const HeaderButtonsContainerStyled = styled.div`
  display: flex;
  margin-left: auto;

  * {
    width: auto;
    min-width: 150px;
    margin-left: 1rem;
  }
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled(Col)`
  overflow: auto;
  height: 100%;
  padding: 0 ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: ${(props) => props.theme.geometry.md.spacing};
`;

const FilterContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextStyled = styled(Text)`
  cursor: pointer;
  margin-right: 15px;

  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'inherit')};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

enum ModalType {
  Create,
}

const ProjectsGridContainer = () => {
  const agGrid = useAgGrid();
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);

  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const canAccessAccountProjects: boolean = Boolean(permissions.project.find((action: string) => action === 'getAll'));
  const canCreateProjects: boolean = Boolean(permissions.project.find((action: string) => action.startsWith('create')));

  const { data, loading, refetch, error } = useQuery(GET_PROJECTS, {
    variables: { accountId, userId: token.sub },
  });
  useEffect(() => {
    refetch({ accountId, userId: token.sub });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { showMine, setShowMine, showHighPriority, setShowHighPriority } = useInitialFilterModel();

  const projects = data?.getProjects || [];

  const showProjectCreatedNotification = useCallback(
    ({ message, colour }) => {
      showNotification(message, colour);
    },
    [showNotification]
  );

  const filterMine = () => {
    const newValue = !showMine;
    const filterModel = agGrid.api?.getFilterModel() || {};

    if (newValue) {
      filterModel.owners = {
        type: 'set',
        values: [`${token.given_name} ${token.family_name}`],
      };
    } else {
      delete filterModel.owners;
    }

    agGrid.api?.setFilterModel(filterModel);
    setShowMine(newValue);
  };

  const filterHighPriority = () => {
    const newValue = !showHighPriority;
    const filterModel = agGrid.api?.getFilterModel() || {};

    if (newValue) {
      filterModel.priority = {
        type: 'set',
        values: [ProjectPriority.High],
      };
    } else {
      delete filterModel.priority;
    }

    agGrid.api?.setFilterModel(filterModel);
    setShowHighPriority(newValue);
  };

  return (
    <ContainerStyled>
      {loading ? (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ) : (
        ''
      )}

      {modalType === ModalType.Create ? (
        <CreateProjectModal
          accountId={accountId}
          onDismiss={() => setModalType(undefined)}
          onComplete={(n) => showProjectCreatedNotification({ message: n.message, colour: n.color })}
        />
      ) : (
        ''
      )}

      {error ? (
        <LoadFailed error={error} retry={() => refetch({ accountId, userId: token.sub })} />
      ) : (
        !loading && (
          <ContentContainer container item>
            <FilterContainerStyled>
              <TextContainerStyled>
                {canAccessAccountProjects && (
                  <TextStyled onClick={filterMine} isActive={showMine}>
                    {showMine ? 'Show All Projects' : 'Show My Projects'}
                  </TextStyled>
                )}
                <TextStyled onClick={filterHighPriority} isActive={showHighPriority}>
                  {showHighPriority ? 'Show All Priorities' : 'Show High Priority'}
                </TextStyled>
              </TextContainerStyled>
              <HeaderButtonsContainerStyled>
                <ClearButton
                  gridApi={agGrid.api}
                  columnApi={agGrid.columnApi}
                  onClick={() => {
                    setShowMine(false);
                    setShowHighPriority(false);
                  }}
                />

                {canCreateProjects ? (
                  <Button
                    data-cy="ProjectsHome__Button-create"
                    inline
                    color="primary"
                    onClick={() => setModalType(ModalType.Create)}
                  >
                    Create Project
                  </Button>
                ) : (
                  ''
                )}
              </HeaderButtonsContainerStyled>
            </FilterContainerStyled>
            <Grid projects={projects} agGrid={agGrid} showGoTo={false} />
          </ContentContainer>
        )
      )}
    </ContainerStyled>
  );
};

export default ProjectsGridContainer;
