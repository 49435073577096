import { SurveyInput } from '@rio/rio-types';
import { TextField, Checkbox, DatePicker, Text, styled, TextArea } from '@rio/ui-components';
import { Errors } from '../../../../types';
import { useSurveyForm } from '../../SurveysContainer/hooks/useSurveyForm';
import { Recipients } from '../SurveyOverviewV2/Recipients';
import { SurveyTemplateSelect } from './SurveyTemplateSelect';

export type SurveyFormProps = {
  errors: Errors<SurveyInput>;
  disabled?: boolean;
  accountId: string;
  isUpdatingSurvey?: boolean;
};

const DatePickersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const OptionSubText = styled(Text)`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
  padding: 0;
  padding-left: 30px;
`;

const ColumnStyle = styled('div')`
  color: ${({ theme }) => theme.sys.color.onSurfaceVariant};
`;

export function SurveyForm({ errors, accountId, disabled, isUpdatingSurvey }: SurveyFormProps) {
  const {
    state: survey,
    changeName,
    toggleAutomatedScoring,
    toggleFeedbackEnabled,
    changeNote,
    changeStartDate,
    changeDeadlineDate,
    changeSurveyTemplateId,
  } = useSurveyForm();

  return (
    <>
      <TextField
        id="AddSurveyForm__Input_Name"
        label="Survey name"
        name="name"
        placeholder="e.g. ESG Assessment 2022"
        helperText="This will be presented to recipients"
        onChange={(e) => changeName(e.target.value)}
        onClear={() => changeName('')}
        value={survey.name}
        defaultValue={survey.name}
        error={!!errors.name}
        disabled={disabled}
      />

      <SurveyTemplateSelect
        value={survey.surveyTemplateId}
        accountId={accountId}
        error={errors.surveyTemplateId || undefined}
        onChange={(e) => changeSurveyTemplateId(e)}
      />

      <Recipients hideNotifyByEmail={isUpdatingSurvey} />

      <ColumnStyle>
        <Text typescale="body" size="large">
          Feedback
        </Text>
        <Checkbox
          id="AddSurveyForm_Checkbox_Feedback"
          onChange={toggleFeedbackEnabled}
          checked={survey.feedbackEnabled}
          label="Approve responses"
        />
        <OptionSubText typescale="body" size="medium">
          If selected, you will have to approve or request changes for each response in Rio
        </OptionSubText>
      </ColumnStyle>

      <ColumnStyle>
        <Text typescale="body" size="large">
          Automated Scoring
        </Text>
        <Checkbox
          id="AddSurveyForm_Checkbox_AutomatedScoring"
          onChange={toggleAutomatedScoring}
          checked={survey.automatedScoring}
          label="Score contributions"
        />
        <OptionSubText typescale="body" size="medium">
          If selected, you and your recipients will automatically receive performance feedback against Rio
          sustainability metrics
        </OptionSubText>
      </ColumnStyle>
      <DatePickersContainer>
        <DatePicker
          onChange={(val) => changeStartDate(val as Date)}
          name="startDate"
          label="Start date"
          value={survey?.startDate as Date}
          error={errors.startDate}
        />

        <DatePicker
          onChange={(val) => changeDeadlineDate(val as Date)}
          name="deadlineDate"
          label="Deadline for responses"
          value={survey?.deadlineDate as Date}
          error={errors.deadlineDate}
        />
      </DatePickersContainer>
      <TextArea
        id="AddSurveyForm__Input_Notes"
        name="notes"
        onChange={(e) => changeNote(e.target.value)}
        value={survey.notes || ''}
        error={!!errors.notes}
        disabled={disabled}
        rows={4}
        label="Notes for recipients (optional)"
        helperText="These notes will appear in the email sent to your recipients"
      />
    </>
  );
}
