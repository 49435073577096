import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Row, Search, Icon, TextLink, Button } from 'rio-ui-components';
import jwt_decode from 'jwt-decode';
import { orderBy, remove, keyBy } from 'lodash';

import { LoadFailed, NotAvailable } from '../../../components/Errors';
import NoResults from '../../../components/NoResults';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import PageHeader from '../../../components/PageHeader';
import UserBannersSection from '../../../components/UserBannersSection';
import CoursesGrid from './CoursesGrid';
import CoursesList from './CoursesList';
import CourseBanner from './CourseBanner';

import { SEARCH_AVALIABLE_COURSES, SEARCH_AVALIABLE_COURSES_WITH_ONE_BUNDLE } from './index.queries';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useViewPreference } from '../../../hooks/useViewPreference';
import { filterComplete, filterContinue, filterExplore, sortByDateDesc, capitalise } from '../utils';
import { DocumentTypeFilterDropDown, DocumentFilterSection } from '../../../components/FiltersAndSorts';
import useCategoriesFilters from '../useCategoriesFilters';
import { CATEGORIES } from '../../ConfigurationContainer/ConfigurationLearnUploadContainer/constants';
import { COMPLETE } from '../../../constants/courseProgress';

const filterMap = (section, data, restrictedListId, categories) => {
  const applied = categories.applied;

  const filterByCategories = (item) => {
    const categories = item.metaData.categories;
    if (
      ((!categories || !categories.length) && applied.includes(null)) ||
      (categories && categories.length && categories.some((r) => applied.includes(r)))
    )
      return true;
  };

  const sorting = {
    explore: (courses) => courses.filter(filterExplore),
    continue: (courses) => courses.filter(filterContinue).sort(sortByDateDesc),
    completed: (courses) => courses.filter(filterComplete).sort(sortByDateDesc),
    all: (courses) => courses
  };

  const result = sorting[section] ? sorting[section](data).filter(filterByCategories) : data.filter(filterByCategories);

  result.forEach((element) => {
    element.restricted = restrictedListId.includes(element.id);
  });

  return result;
};

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
  height: 100%;
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const LoadMore = styled(Button)`
  margin: ${(props) => props.theme.geometry.md.spacing} 0;
  border-radius: 0;
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;
const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

const ErrorMessageStyled = styled.div`
  margin-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const CourseBannerStyled = styled(CourseBanner)`
  margin-bottom: 30px;
`;
const categoriesList = CATEGORIES.map((item) => ({ name: item.label, id: item.value }));

function Courses({ learnAccess }) {
  const { id: section = 'all' } = useParams();
  const [search, setSearch] = React.useState({
    value: '',
    error: ''
  });

  const [pageNumber, setPageNumber] = React.useState(1);
  const { isList, isGrid, showList, showGrid } = useViewPreference();
  const {
    filters,
    activeFilter,
    previousFilter,
    handleFilterToggle,
    removeAllFilters,
    addAllFilters,
    setActiveFilter
  } = useCategoriesFilters({
    values: categoriesList,
    options: [...categoriesList.map((item) => item.id), null],
    applied: [...categoriesList.map((item) => item.id), null]
  });
  const notSpecifiedOption = { id: null, name: 'Not specified' };

  const [state, setState] = useState({
    categories: {
      name: 'Categories',
      options: [...CATEGORIES.map((item) => ({ name: item.label, id: item.value })), notSpecifiedOption],
      applied: [...CATEGORIES.map((item) => item.value), notSpecifiedOption.id]
    },
    listCategoriesId: keyBy(CATEGORIES, 'value'),
    restrictedListId: []
  });

  const userId = jwt_decode(localStorage.getItem('ID_TOKEN')).sub;
  const accountId = useCurrentAccountId();

  const fixedSection = ['explore', 'continue', 'completed', 'all'];
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    fixedSection.includes(section) ? SEARCH_AVALIABLE_COURSES : SEARCH_AVALIABLE_COURSES_WITH_ONE_BUNDLE,
    {
      variables: {
        userId,
        accountId,
        pageNumber: 1,
        searchTerm: search.value.trim(),
        section,
        bundleId: section
      },
      fetchPolicy: 'network-only'
    }
  );
  const results = data && data.searchCourses.data.length > 0;
  const bundle = (data && data.getLearnBundleById) || {};

  if (bundle.title && !state.restrictedListId.length) {
    data.searchCourses.data = orderBy(data.searchCourses.data, (item) => bundle.courses.indexOf(item.id), 'asc');
    const removeList = remove(
      data.searchCourses.data,
      (item) => item.userCourseMetaData && item.userCourseMetaData.courseProgress === COMPLETE
    );

    if (bundle.restricted && bundle.courses.length) {
      const copyCourses = [...bundle.courses];
      remove(copyCourses, (item) => removeList.some((itemInner) => itemInner['id'] === item));
      const restrictedListId = copyCourses.slice(1, copyCourses.length);
      setState({ ...state, restrictedListId });
    }
  }

  let restrictedListId = [...state.restrictedListId];
  if (data && data.getLearnBundlesForAccount) {
    const allStartCourses = [...data.searchCourses.data]
      .filter((item) => item.userCourseMetaData && item.userCourseMetaData.courseProgress === COMPLETE)
      .map((item) => item.id);
    data.getLearnBundlesForAccount.forEach((item) => {
      if (item.restricted && item.courses.length >= 2) {
        const copyItemCourses = [...item.courses].filter((item) => !allStartCourses.includes(item));
        restrictedListId = [...restrictedListId, ...copyItemCourses.slice(1, copyItemCourses.length)];
      }
    });
  }

  const updateState = (filters) => setState({ ...state, categories: filters.categories });

  function handleLoadMore(fetchMore, pageNumber) {
    setPageNumber(pageNumber);
    fetchMore({
      variables: {
        pageNumber,
        searchTerm: search.value
      },
      updateQuery: (previous, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previous;
        }
        return {
          ...previous,
          searchCourses: {
            isLastPage: fetchMoreResult.searchCourses.isLastPage,
            totalItemCount: fetchMoreResult.searchCourses.totalItemCount,
            section: fetchMoreResult.searchCourses.section,
            data: [...previous.searchCourses.data, ...fetchMoreResult.searchCourses.data],
            __typename: previous.searchCourses.__typename
          }
        };
      }
    });
  }

  const forbiddenErrorMessage = "Cannot return null for non-nullable type: 'LearnBundleEngage'";
  const isForbidden =
    error && error.graphQLErrors.length && error.graphQLErrors[0].message.includes(forbiddenErrorMessage);

  return (
    <ContainerStyled name="Courses">
      <PageHeader
        name="Courses"
        title="Engage Courses"
        breadcrumbs={[
          { title: 'Engage', to: '/engage' },
          { title: 'Courses' },
          { title: fixedSection.includes(section) ? capitalise(section) : bundle.title }
        ]}
        icon="graduation-cap"
        iconColor="secondary"
      />
      <ContentContainer>
        {!isForbidden && (
          <>
            <CourseBannerStyled />
            <SearchHeader container item itemAlign="center">
              <SearchStyled
                name="Courses__Search"
                value={search.value}
                onChange={({ target: { value } }) => {
                  setSearch({
                    ...search,
                    value
                  });
                  setPageNumber(1);
                }}
                hideButton
              />
              <TextLinkStyled
                name="Courses__ShowGrid"
                size="md"
                color={isGrid ? 'primary' : 'normal'}
                weight={isGrid ? 'bold' : 'normal'}
                underline={false}
                onClick={() => showGrid()}
                inline
              >
                <Icon icon="th" />
              </TextLinkStyled>
              <TextLinkStyled
                name="Courses__ShowList"
                size="md"
                color={isList ? 'primary' : 'normal'}
                weight={isList ? 'bold' : 'normal'}
                underline={false}
                onClick={() => showList()}
                inline
              >
                <Icon icon="th-list" />
              </TextLinkStyled>
            </SearchHeader>

            <DocumentTypeFilterDropDown
              filters={filters}
              sorters={null}
              onSort={null}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
            >
              <DocumentFilterSection
                filters={filters}
                previousFilter={previousFilter}
                activeFilter={activeFilter}
                onChange={updateState}
                onFilterClick={handleFilterToggle}
                removeAll={removeAllFilters}
                addAll={addAllFilters}
              />
            </DocumentTypeFilterDropDown>
          </>
        )}
        {((loading && networkStatus !== 3) || networkStatus === 4) && (
          <ContainerLoadingIndicator name="Courses__Loading" />
        )}
        {error && !isForbidden && <LoadFailed name="LearnBundle_LoadFaile_Error" error={error} />}
        {isForbidden && <NotAvailable name="LearnBundle_error" error={error} />}
        {!error && (
          <>
            <UserBannersSection id="learnCourses" />
            {!!results && filterMap(section, data.searchCourses.data, restrictedListId, state.categories).length > 0 && (
              <>
                {isList ? (
                  <CoursesList
                    items={filterMap(section, data.searchCourses.data, restrictedListId, state.categories)}
                    learnAccess={learnAccess}
                    bundleId={section}
                  />
                ) : (
                  <CoursesGrid
                    items={filterMap(section, data.searchCourses.data, restrictedListId, state.categories)}
                    learnAccess={learnAccess}
                    bundleId={section}
                  />
                )}

                {!data.searchCourses.isLastPage && (
                  <LoadMore
                    color="primary"
                    name="Courses__Pagination__Button--loadmore"
                    onClick={() => handleLoadMore(fetchMore, pageNumber + 1)}
                  >
                    Load more
                  </LoadMore>
                )}
              </>
            )}

            {(!results ||
              filterMap(section, data.searchCourses.data, restrictedListId, state.categories).length === 0) && (
              <ErrorMessageStyled>
                <NoResults name="Courses__NoResults" title="There are no results for this search." />
              </ErrorMessageStyled>
            )}
          </>
        )}
      </ContentContainer>
    </ContainerStyled>
  );
}

export default Courses;
