"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HorizontalCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("@mui/material-next/styles");
const Text_1 = require("../Text");
const utils_1 = require("../../utils");
const CardStyle = (0, styles_1.styled)('div') `
  display: flex;
  border-radius: 12px;
  width: 328px;
  min-width: 328px;
  height: 80px;
  min-height: 80px;
  flex-direction: unset;
  justify-content: space-between;
  align-items: start;
  box-shadow: ${({ theme }) => theme.sys.elevation[1]};
  color: ${({ theme }) => theme.sys.color.onSurface};
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
  background-color: ${({ theme }) => theme.sys.color.onSecondary};
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.sys.color.secondaryContainer};
  }
`;
const Img = (0, styles_1.styled)('div') `
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-color lightgray;
  background-size: cover;
  height: 100%;
  min-height: 80px;
  min-width: 80px;
  flex: 80px 1 0;
`;
const CardInfoContent = (0, styles_1.styled)('div') `
  flex: 248px 1 0;
  max-width: 248px;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
`;
const Title = (0, styles_1.styled)(Text_1.Text) `
  margin: 0;
  margin-bottom: 4px;
`.withComponent('h2');
const TextStyled = (0, styles_1.styled)(Text_1.Text) `
  margin: 0;
`;
const ImgContainer = (0, styles_1.styled)('div') `
  flex: 0 1 80px;
`;
const HorizontalCard = (props) => {
    const { src, title, subTitle, onClick } = props;
    const handleCardClick = (0, react_1.useCallback)((event) => {
        onClick?.(event);
    }, [onClick]);
    return ((0, jsx_runtime_1.jsxs)(CardStyle, { onClick: handleCardClick, children: [(0, jsx_runtime_1.jsxs)(CardInfoContent, { children: [(0, jsx_runtime_1.jsx)(Title, { typescale: "title", size: "medium", title: (0, utils_1.getNodeText)(title), oneLine: true, children: title }), subTitle && ((0, jsx_runtime_1.jsx)(TextStyled, { typescale: "body", size: "medium", title: (0, utils_1.getNodeText)(subTitle), oneLine: true, children: subTitle }))] }), (0, jsx_runtime_1.jsx)(ImgContainer, { children: (0, jsx_runtime_1.jsx)(Img, { src: src }) })] }));
};
exports.HorizontalCard = HorizontalCard;
