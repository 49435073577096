import { gql } from '@apollo/client';
import { GET_USER_INFO_FRAGMENT } from '../../../queries/users';
import { GET_ACCOUNT_INFO_FRAGMENT } from '../../../queries/accounts';

export const GET_ID_TOKEN = gql`
  {
    idToken @client {
      sub
      name
    }
  }
`;
export const GET_CURRENT_ACCOUNT_ID = gql`
  {
    currentAccountId @client
  }
`;

export const GET_APPLICATION_DATA = gql`
  query GetApplicationData($userId: ID!, $userIdString: String!, $accountId: ID!) {
    getUserInfoById(id: $userIdString) {
      ...getUserInfoFragment
    }

    getAccountInfo(id: $accountId) {
      ...getAccountInfoFragment
    }

    getReceivedSurveysPage(accountId: $accountId) {
      rows {
        id
        deadlineDate
        submission {
          id
          status
        }
      }
    }

    getTasksByUserId(id: $userId, accountId: $accountId) {
      id
      isCompleted
      dueDate
      name
      owners {
        id
      }
    }
  }
  ${GET_USER_INFO_FRAGMENT}
  ${GET_ACCOUNT_INFO_FRAGMENT}
`;
