import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';

import alphabetiseStrings from '../../../../utils/alphabetise';
import SubscriptionRow from './SubscriptionRow';
import { SAVE_SUBSCRIPTIONS, GET_ALL_SUBSCRIPTIONS } from './index.queries';

const SubscriptionRows = ({ knowledgebases, accountId }) => {
  const { data } = useQuery(GET_ALL_SUBSCRIPTIONS, { variables: { accountId }, fetchPolicy: 'network-only' });
  const [saveSubscriptions] = useMutation(SAVE_SUBSCRIPTIONS);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    setSubscriptions(data?.getIntelligenceSubscriptions || []);
  }, [data]);

  const onCheckboxClick = (kbId) => {
    let newSubs = [...subscriptions];
    if (newSubs.includes(kbId)) {
      newSubs = newSubs.filter((sub) => sub !== kbId);
    } else {
      newSubs.push(kbId);
    }

    saveSubscriptions({ variables: { accountId, subscriptions: newSubs } });
    setSubscriptions(newSubs);
  };

  return (
    <>
      {knowledgebases
        .slice()
        .sort((kbA, kbB) => alphabetiseStrings(kbA.title, kbB.title))
        .map((kb) => {
          return (
            <SubscriptionRow
              key={kb.id}
              id={kb.id}
              name={kb.title}
              onCheckboxClick={() => onCheckboxClick(kb.id)}
              checked={!subscriptions.includes(kb.id)}
            />
          );
        })}
    </>
  );
};

export default withApollo(SubscriptionRows);
