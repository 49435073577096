import moment from 'moment';
import { gridNumberFormatter } from '../../../utils';

export const gridConfig = {
  startDate: {
    columnName: 'Start Date',
    width: 2,
    order: 1,
    format(val) {
      return moment.utc(new Date(val)).format('DD/MM/YYYY');
    },
  },
  endDate: {
    columnName: 'Expiry',
    width: 2,
    order: 2,
    format(val) {
      return moment.utc(new Date(val)).format('DD/MM/YYYY');
    },
  },
  reportingYear: {
    columnName: 'Reporting Year',
    width: 1,
    order: 3,
  },
  fromUnit: {
    columnName: 'From unit',
    width: 1,
    order: 4,
  },
  toUnit: {
    columnName: 'To unit',
    width: 1,
    order: 5,
  },
  value: {
    columnName: 'Factor',
    width: 1,
    order: 6,
    valueFormatter: gridNumberFormatter,
  },
  scope: {
    columnName: 'Scope',
    width: 1,
    order: 7,
  },
  reference: {
    columnName: 'Reference',
    width: 1,
    order: 8,
  },
  note: {
    columnName: 'Note',
    width: 2,
    order: 9,
  },
};

export const wasteFactorsGridConfig = {
  'Re-use': {
    columnName: 'Re-use',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  'Open-loop': {
    columnName: 'Open-loop',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  'Closed-loop': {
    columnName: 'Closed-loop',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  Composting: {
    columnName: 'Composting',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  Combustion: {
    columnName: 'Combustion',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  Landfill: {
    columnName: 'Landfill',
    width: 2,
    valueFormatter: gridNumberFormatter,
  },
  'Anaerobic Digestion': {
    columnName: 'Anaerobic Digestion',
    width: 2,
  },
};

export const transportGridConfig = {
  transportType: {
    columnName: 'Type',
    width: 2,
    order: 1,
  },
  transportSubtype: {
    columnName: 'Subtype',
    width: 2,
    order: 2,
  },
  fuelType: {
    columnName: 'Fuel Type',
    width: 2,
    order: 3,
  },
  fromUnit: {
    columnName: 'Unit',
    width: 2,
    order: 4,
  },
  value: {
    columnName: 'kgCO2e',
    width: 2,
    order: 5,
    format: (value) => gridNumberFormatter({ value }),
  },
  scope: {
    columnName: 'Scope',
    width: 1,
    order: 6,
  },
  note: {
    columnName: 'Note',
    width: 2,
    order: 7,
  },
};

export const fuelGridConfig = {
  fuelActivityName: {
    columnName: 'Fuel Activity',
    width: 2,
    order: 1,
  },
  fuelName: {
    columnName: 'Fuel Type',
    width: 2,
    order: 2,
  },
  fromUnitName: {
    columnName: 'From Unit',
    width: 1,
    order: 3,
  },
  toUnitName: {
    columnName: 'To Unit',
    width: 1,
    order: 4,
  },
  value: {
    columnName: 'Factor',
    width: 2,
    order: 5,
  },
  reference: {
    columnName: 'Reference',
    width: 1,
    order: 6,
  },
  type: {
    columnName: 'Note',
    width: 2,
    order: 7,
  },
};

export function getColumnWidth(config, column) {
  const allColumns = Object.keys(config);
  return allColumns.length !== Object.keys(gridConfig).length ? 12 / allColumns.length : config[column].width;
}

export function sorter(f1, f2) {
  return new Date(f2.startDate).getTime() - new Date(f1.startDate).getTime();
}
