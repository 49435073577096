import { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'rio-ui-components';
import { Grid } from '~/components/UI';
import { RowModelType } from 'ag-grid-community';
import { useBannersGrid } from './hooks/useBannersGrid';
import { Banner } from '@rio/rio-types';

const IconStyled = styled(Icon)`
  cursor: pointer;
`;

const ImgStyled = styled.img`
  object-fit: contain;
  width: 100%;
`;

export const VisibilityIcon = ({ data }) => {
  return data.visible ? <Icon icon="eye" /> : <Icon icon="eye-slash" />;
};

export const EditIcon = ({ data }) => {
  const { onEdit } = useBannersGrid();
  return <IconStyled icon="pencil-alt" onClick={() => onEdit(data)} />;
};

export const ThumbnailView = ({ data }) => {
  return <ImgStyled src={data.thumbnail} />;
};

const GridStyled = styled(Grid)`
  .ag-header-cell-resize {
    z-index: 0;
  }
`;

const defaultConfig = {
  pagination: true,
  paginationPageSize: 25,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

const gridConfig = {
  ...defaultConfig,
  columnDefs: [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'URL',
      field: 'url',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Thumbnail',
      field: 'thumbnail',
      cellRenderer: 'thumbnail',
    },
    {
      headerName: 'Type',
      field: 'type',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Visible',
      field: 'visible',
      cellRenderer: 'visibility',
    },
    {
      cellRenderer: 'edit',
    },
  ],
  components: {
    thumbnail: ThumbnailView,
    visibility: VisibilityIcon,
    edit: EditIcon,
  },
};

export type BannersGridProps = {
  fetchRows: (variables: { accountId: string }) => Promise<{ rows: Banner[]; totalRows: number }>;
};

export function BannersGrid({ fetchRows }: BannersGridProps) {
  const { agGrid, currentAccountId } = useBannersGrid();

  const onGridReady = useCallback(
    (gridReadyParams) => {
      agGrid.onGridReady(gridReadyParams);
      gridReadyParams.api.setServerSideDatasource({
        async getRows(params) {
          try {
            const { rows, totalRows } = await fetchRows({
              accountId: currentAccountId,
            });
            params.success({
              rowData: rows,
              rowCount: totalRows,
            });
          } catch (err) {
            params.fail();
            throw err;
          }
        },
      });
    },
    [currentAccountId, agGrid, fetchRows]
  );

  return (
    <GridStyled
      {...gridConfig}
      key={currentAccountId} // This will re-mount the grid and refetch the data when account changes
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      onColumnMoved={agGrid.onSaveGridColumnState}
      onColumnRowGroupChanged={agGrid.onSaveGridColumnState}
      onColumnValueChanged={agGrid.onSaveGridColumnState}
      onColumnPivotChanged={agGrid.onSaveGridColumnState}
      onDestroy={agGrid.onDestroy}
      onGridReady={onGridReady}
    />
  );
}
