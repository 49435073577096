import { Logo } from '@rio/ui-components';
import { FormattedMessage } from 'react-intl';
import { AuthContent, GridStyled, TitleStyled } from './style';

export const AuthPage = ({ children, title }) => {
  return (
    <GridStyled container>
      <AuthContent>
        <Logo height="70px" style={{ marginTop: '1rem' }} />
        <TitleStyled size="large" typescale="title">
          <FormattedMessage id={title} />
        </TitleStyled>
        {children}
      </AuthContent>
    </GridStyled>
  );
};
