import { User } from '@rio/rio-types';
import { Modal } from '@rio/ui-components';
import { Editing } from '../ReceivedSurveyContainer/v2/Editing';

type Props = {
  open: boolean;
  lockedBy?: User | null;
  onClose: () => void;
};

export const SurveyLockWarningModal = ({ lockedBy, open, onClose }: Props) => {
  return (
    <Modal
      onClose={onClose}
      title={`Sorry! It seems that ${lockedBy?.first_name} ${lockedBy?.last_name} is currently making edits to this survey.`}
      grid={{ xs: 8, md: 6 }}
      open={open}
    >
      <Editing
        description={`You won't be able to make changes until 5 minutes after ${lockedBy?.first_name} ${lockedBy?.last_name} has completed their edits and exited the survey.`}
        onDismiss={onClose}
        submitButtonText="OK"
        buttonDescription="This will send your survey to your recipients"
      />
    </Modal>
  );
};
