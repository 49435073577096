import { useCallback } from 'react';
import GET_HEATSTEAM_TRANSACTIONS_PAGE from '../../../graphql/queries/heatSteam/GetHeatSteamTransactionsPage.query.graphql';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useHeatSteamGridConfig } from '../HeatSteamContainer/TransactionsContainer/useHeatSteamGridConfig';
import {
  TariffReference,
  FactorUsedCell,
  UploadReferenceCell,
  DocumentReferenceV2 as DocumentReference,
} from '../CellRenderers';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: '..' }, { label: 'Heat and steam' }];

export const HeatSteamTransactionsGrid = () => {
  const config = useHeatSteamGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_HEATSTEAM_TRANSACTIONS_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getHeatSteamTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });

      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title="Your heat and steam data"
      defaultExportFileName="heatsteam-export"
      breadcrumbs={breadcrumbs}
      uploadLink="../heatSteam/uploads"
      config={{
        ...config,
        components: {
          tariffReference: TariffReference,
          uploadReference: UploadReferenceCell,
          documentReference: DocumentReference,
          factorReference: FactorUsedCell,
        },
      }}
      fetchRows={fetchRows}
    />
  );
};
