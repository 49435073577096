import { useQuery } from '@apollo/client';
import { Select } from '@rio/ui-components';

import GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID from '../../../../graphql/queries/surveys/GetSurveyTemplatesByAccountId.query.graphql';
import { Query } from '@rio/rio-types';

export type OptionsProps = {
  value: string;
  imgUrl?: string;
  label: string;
};

const templateToOption = (survey: { id: string; name: string }) => ({
  value: survey.id,
  label: survey.name,
});

interface SurveySelectProps {
  value: string;
  error?: string;
  accountId: string;
  onChange: (value: string) => void;
}

export const SurveyTemplateSelect = ({ accountId, value, onChange, error }: SurveySelectProps) => {
  const { data } = useQuery<Pick<Query, 'getSurveyTemplatesByAccountId'>>(GET_SURVEY_TEMPLATES_BY_ACCOUNT_ID, {
    variables: {
      accountId,
    },
  });

  const options = (data?.getSurveyTemplatesByAccountId ?? []).map(templateToOption);
  const selectedValue = options?.find((opt) => opt.value === value);

  return (
    <Select
      id="AddSurvey__Select"
      name="SurveyTemplateSelect"
      placeholder="Please select a form"
      defaultValue={selectedValue?.value}
      onChange={(e) => onChange(e.value)}
      label="Form"
      error={!!error}
      options={options}
      helperText="Your survey recipients will be asked to fill in these form"
    />
  );
};
