import { TransactionType } from '@rio/rio-types';
import { useConvertWasteDataFormToRows } from './waste';
import { useConvertEnergyDataFormToRows } from './energy';
import { useConvertTransportDataFormToRows } from './transport';
import { useConvertWaterDataFormToRows } from './water';
import { useConvertHotelStaysFormToRows } from './hotel-stays';
import wasteColumns from '../data/waste-grid-columns.json';
import electricityColumns from '../data/electricity-grid-columns.json';
import gasColumns from '../data/gas-grid-columns.json';
import waterColumns from '../data/water-direct-data-upload.json';
import transportColumns from '../data/transport-grid-columns.json';
import hotelStaysColumns from '../data/hotel-stays-columns.json';
import heatSteamColumns from '../data/heat-steam-grid-columns.json';

type ConvertFn = (form?: Record<string, unknown>, columns?: unknown[]) => void;

export type ConvertTuple = [ConvertFn, { data: unknown[]; loading: boolean }];

export function useGridHooksByDataType(dataType: TransactionType | string) {
  const wasteConvert = useConvertWasteDataFormToRows() as ConvertTuple;
  const energyConvert = useConvertEnergyDataFormToRows() as ConvertTuple;
  const transportConvert = useConvertTransportDataFormToRows() as ConvertTuple;
  const waterConvert = useConvertWaterDataFormToRows() as ConvertTuple;
  const hotelStaysConvert = useConvertHotelStaysFormToRows() as ConvertTuple;

  switch (dataType) {
    case TransactionType.Waste:
      return [wasteColumns, wasteConvert];
    case TransactionType.Electricity:
      return [electricityColumns, energyConvert];
    case TransactionType.Gas:
      return [gasColumns, energyConvert];
    case TransactionType.Heatsteam:
      return [heatSteamColumns, energyConvert];
    case TransactionType.Water:
      return [waterColumns, waterConvert];
    case TransactionType.Transport:
      return [transportColumns, transportConvert];
    case TransactionType.Hotelstays:
      return [hotelStaysColumns, hotelStaysConvert];
    default:
      throw new Error(`Undefined data section: ${dataType}`);
  }
}
