import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Heading, CircleIcon, Col, Row } from 'rio-ui-components';
import PageHeader from '../../../../components/PageHeader';
import { constructNavigationMenu } from '../Overview/constructNavigationMenu';
import { usePermissions } from '../../../../hooks/usePermissions';
import { AccordionMenu } from '../../../../components/AccordionMenu';
import { createBreadcrumbs, createManagementSystemTree } from '../../../../utils/managementSystemUtils';
import { ManagementSystemOverview } from './GresbOverview';
import GresbSystem from './content.json';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CircleIconStyled = styled(CircleIcon)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  display: inline-flex;
  flex: 0 0 auto;
`;

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ChildHeader = styled.div`
  background-color: ${(props) => props.theme.colors.basic.white};
  width: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} ${(props) => props.theme.geometry.sm.spacing} 0
    ${(props) => props.theme.geometry.sm.spacing};
`;

const ContentContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const MenuContainer = styled(Col)`
  max-height: 100vh;
  overflow: auto;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ManagementSystemOverviewContainer = () => {
  const { pathname } = useLocation();
  const chapterId = pathname.split('/').pop();
  const permissions = usePermissions();
  const isReadOnly = !permissions.document.find((action) => action.startsWith('create'));
  const managementSystem = GresbSystem;
  const { tree } = createManagementSystemTree(managementSystem);
  const currentPage = tree[chapterId] || managementSystem;
  const canEditContent = permissions.governance.find((action) => action.startsWith('attachContentToManagementSystem'));
  const breadcrumbs = [
    { title: 'Governance', to: '/governance' },
    ...createBreadcrumbs(managementSystem, tree, currentPage, '/governance/systems'),
  ];
  const ManagementSystem = () => (
    <ManagementSystemOverview
      managementSystem={managementSystem}
      managementSystemTree={tree}
      canEditContent={canEditContent}
      isReadOnly={isReadOnly}
    />
  );

  return (
    <ContainerStyled name="ManagementSystemOverview">
      <PageHeader
        name="ManagementSystemOverviewHeader"
        title={managementSystem?.title}
        breadcrumbs={breadcrumbs}
        icon="pencil-alt"
        iconColor="tertiary"
      />
      {managementSystem && (
        <ContentContainer container>
          <MenuContainer span={4} item>
            <ChildHeader>
              <FlexRow>
                <TitleContainerStyled>
                  <CircleIconStyled
                    inline
                    height="50px"
                    size="xl"
                    circleColor="tertiary"
                    iconColor="tertiary"
                    icon="link"
                  />
                  <Heading name="LegislationItem_Docs__Title" inline>
                    Management System Breakdown
                  </Heading>
                </TitleContainerStyled>
              </FlexRow>
            </ChildHeader>
            <AccordionMenu
              active={chapterId || managementSystem?.id}
              menu={constructNavigationMenu(managementSystem, tree)}
            />
          </MenuContainer>
          <Routes>
            <Route path="/" element={<ManagementSystem />} />
            <Route path="/:itemId" element={<ManagementSystem />} />
          </Routes>
        </ContentContainer>
      )}
    </ContainerStyled>
  );
};

export default ManagementSystemOverviewContainer;
