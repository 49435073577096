import { Link } from 'react-router-dom';
import { Card, styled, MuiIconName } from '@rio/ui-components';
import { Area } from '../../areas';

const TileContainerStyled = styled('div')`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
`;

const LinkStyled = styled(Link)<{ disabled: boolean }>`
  ${(props) =>
    props.disabled
      ? `
    opacity: 0.5;
  `
      : ''}
`;

type Props = {
  areas: Area[];
  accessControls: { [accessIndex: string]: boolean };
};

const GovernanceAreaGrid = ({ areas, accessControls }: Props) => {
  return (
    <TileContainerStyled>
      {areas.map((area) => {
        let linkTo = area.link;
        if (!accessControls[area.accessIndex]) {
          linkTo = '/contact';
        }

        let icon = '';
        switch (area.icon) {
          case 'balance-scale':
            icon = 'Balance';
            break;
          case 'university':
            icon = 'AccountBalance';
            break;
          case 'clipboard-list':
            icon = 'AssignmentOutlined';
            break;
          case 'thumbs-up':
            icon = 'ThumbUp';
            break;
          case 'subscript':
            icon = 'AdsClick';
            break;
          case 'drafting-compass':
            icon = 'Architecture';
            break;
          case 'notes-medical':
            icon = 'MedicalServices';
            break;
          case 'scroll':
            icon = 'AutoStories';
            break;
          default:
            icon = '';
            break;
        }

        const disabled = area.comingSoon || !accessControls[area.accessIndex];

        let chipContent = '';
        if (disabled && !accessControls[area.accessIndex]) {
          chipContent = 'Upgrade now';
        }

        if (disabled && area.comingSoon) {
          chipContent = 'Coming soon';
        }

        return (
          <LinkStyled key={area.id} to={linkTo} disabled={disabled}>
            <Card
              title={area.title}
              subContent={area.description}
              iconName={icon as MuiIconName}
              chipContent={chipContent}
            />
          </LinkStyled>
        );
      })}
    </TileContainerStyled>
  );
};

export default GovernanceAreaGrid;
