import styled from 'styled-components';
import { useCallback } from 'react';
import { ReceivedSurvey, Survey } from '@rio/rio-types';

import { StatusSpan } from './StatusSpan';
import { formatSubmissionStatus, getProgress, isReceivedSurvey } from './utils';
import { SurveyOverflowMenu, isOverflowMenu } from './SurveyOverflowMenu';
import { SurveyInfoStatus } from '../Surveys';

export interface SurveyTileProps {
  survey: ReceivedSurvey | Survey;
  subtitle?: string;
  onClick?: (id: string) => void;
  disabled?: boolean;
}

interface TileProps {
  disabled: boolean;
  name: string;
}

export const TileContainer = styled.div`
  flex: 1 1 auto;
  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-gap: 20px;

  @media (min-width: 1920px) {
    grid-template-columns: repeat(5, minmax(10px, 1fr));
  }

  @media (min-width: 1601px) and (max-width: 1920px) {
    grid-template-columns: repeat(4, minmax(10px, 1fr));
  }

  @media (min-width: 1367px) and (max-width: 1600px) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }

  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
`;
const Tile = styled.div<TileProps>`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 20%;
  height: 202px;
  z-index: 0;
  padding-top: ${(props) => props.theme.geometry.sm.spacing};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  position: relative;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.text.light.text};
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
const Progress = styled.div`
  position: relative;
  z-index: 10;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  padding-top: ${(props) => props.theme.geometry.xxs.spacing};
  padding-left: ${(props) => props.theme.geometry.md.spacing};
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const Title = styled.div`
  padding-left: ${(props) => props.theme.geometry.md.spacing};
  flex: 1;
  position: relative;
  font-size: 20px;
  line-height: 24px;
`;
const Subtitle = styled.div`
  margin-top: ${(props) => props.theme.geometry.xs.spacing};
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.text.normal.background};
`;

export const SurveyTile = ({ survey, disabled, subtitle, onClick }: SurveyTileProps) => {
  const { name, template, deadlineDate } = survey || {};

  const handleClick = useCallback(
    (e: React.MouseEvent): void => {
      if (!isOverflowMenu(e) && !disabled) {
        onClick?.(survey.id);
      }
    },
    [onClick, survey, disabled]
  );

  return (
    <Tile name={`SurveyTile--${survey.id}`} disabled={!!disabled} onClick={handleClick}>
      <Progress>
        {isReceivedSurvey(survey) && (
          <StatusSpan status={survey.submission?.status}>
            {formatSubmissionStatus(survey.submission?.status, getProgress(template, survey.submission))}
          </StatusSpan>
        )}
        <SurveyOverflowMenu survey={survey} />
      </Progress>
      <Title>
        {name}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Title>
      <SurveyInfoStatus deadlineDate={deadlineDate} fill />
    </Tile>
  );
};
