import _ from 'lodash';
import moment, { Moment } from 'moment';
import { DateRange, DateLike, Dictionary, Nullable } from '../types';
import { formatDate } from './formatDate';

export const ISO_DATE_REG = /^(\d{4})-(\d{2})-(\d{2})T((\d{2}):(\d{2}):(\d{2}))\.(\d{3})Z?$/;

export const DATE_FORMAT_WITH_SLASHES = /^(\d{2})\/(\d{2})\/(\d{4})$/;

export const DATE_FORMAT_WITH_DASHES = /^(\d{2})-(\d{2})-(\d{4})$/;

export const inRange = (date: Nullable<DateLike>, [startDate, endDate]: DateRange) => {
  const x = moment(date);
  const a = moment(startDate);
  const b = moment(endDate);
  const isBetween = x.isBetween(a, b);
  const isEqual = x.isSame(a) || x.isSame(b);
  return isBetween || isEqual;
};

export const rangeInRange = (r1: DateRange, r2: DateRange) => inRange(r1[0], r2) || inRange(r1[1], r2);

interface FormatRangeOptions {
  includeReportingYear?: boolean;
}

export function formatDateRange([startDate, endDate]: DateRange, { includeReportingYear }: FormatRangeOptions = {}) {
  const a = formatDate(startDate);
  const b = formatDate(endDate);
  const base = b ? `${a} — ${b}` : `${a} - Present`;
  return includeReportingYear ? `${base} (Reporting year - ${moment(startDate).year()})` : base;
}

export function sortRangesComparator([startDate]: DateRange) {
  if (!startDate) {
    return;
  }

  return new Date(startDate.toString());
}
interface FormatRangesOptions {
  includeReportingYear?: boolean;
}
export function formatRanges(ranges: DateRange[], { includeReportingYear }: FormatRangesOptions = {}) {
  const mapOfDates: Dictionary<DateRange> = {};
  const rangesFormatted = _(ranges)
    .orderBy(sortRangesComparator, 'desc')
    .map(([startDate, endDate]) => {
      const formattedRange = formatDateRange([startDate, endDate], { includeReportingYear });
      if (!mapOfDates[formattedRange]) {
        mapOfDates[formattedRange] = [startDate, endDate];
      }
      return formattedRange;
    })
    .uniq()
    .value();
  return {
    rangesFormatted,
    mapOfDates,
  };
}
export function mostRecentRange(ranges: DateRange[]) {
  return _.maxBy(ranges, sortRangesComparator);
}

export function nextYear(): Moment {
  return moment().add(1, 'year');
}

export function tenYearsBefore(): Moment {
  return moment().subtract(10, 'year');
}

export function oneMonthBefore(): Moment {
  return moment().subtract(1, 'month');
}

export function oneYearBefore(): Moment {
  return moment().subtract(1, 'year');
}

export function today(): Moment {
  return moment().startOf('day');
}

export function getMinutesTillNow(date: DateLike): number {
  return moment().diff(moment(date), 'minutes');
}

export function isDateLike(value: DateLike): value is DateLike {
  if (typeof value === 'string') {
    return ISO_DATE_REG.test(value) || DATE_FORMAT_WITH_DASHES.test(value) || DATE_FORMAT_WITH_SLASHES.test(value);
  }
  return value instanceof Date || moment.isMoment(value);
}

export function ensureIsDate(value: DateLike): Date {
  if (moment.isMoment(value)) {
    return value.toDate();
  }
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return moment(value).toDate();
  }
  if (typeof value === 'string' && ISO_DATE_REG.test(value)) {
    return moment(value).toDate();
  }
  if (typeof value === 'string' && DATE_FORMAT_WITH_DASHES.test(value)) {
    return moment(value, 'DD-MM-YYYY').toDate();
  }
  if (typeof value === 'string' && DATE_FORMAT_WITH_SLASHES.test(value)) {
    return moment(value, 'DD/MM/YYYY').toDate();
  }
  const maybeValidDate = new Date(value);
  if (Number.isNaN(maybeValidDate.getTime())) {
    throw new Error(`${value} is invalid date`);
  }
  return maybeValidDate;
}

export function getGridDateComparator(format: string = 'DD/MM/YYYY') {
  return (filterLocalDateAtMidnight: DateLike, cellValue: string) => {
    const cellDate = moment(cellValue, format).toDate();
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  };
}

export function toDateTime(date: string, format: string = 'DD/MM/YY HH:mm') {
  const dateMoment = moment(date).local();
  return dateMoment.isValid() ? dateMoment.format(format) : '-';
}
