import { useMutation } from '@apollo/client';
import { Mutation, MutationIgnoreDataAnomaliesArgs as Variables } from '@rio/rio-types';
import { IGNORE_DATA_ANOMALIES } from './queries';
import { useNotification } from '~/hooks';

type Response = Pick<Mutation, 'ignoreDataAnomalies'>;

export function useIgnoreDataAnomalies() {
  const { showNotification } = useNotification();

  return useMutation<Response, Variables>(IGNORE_DATA_ANOMALIES, {
    refetchQueries: ['GetDataAnomaliesByAccountId', 'GetDataAnomaliesByAccountIdCount'],
    onCompleted: () => {
      showNotification('Those anomalies have been accepted, they will now appear under accepted variances', 'success');
    },
    onError: (err) => {
      showNotification(`Anomalies haven't been accepted due to this error: ${err}`, 'danger');
    },
  });
}
