import { DocumentLibrary } from '@rio/rio-types';
import { DocumentLibraryFolder } from './types';

const documentLibraries: DocumentLibraries = [
  {
    id: 'be36f589-60f2-4e30-923d-0657ca6cbaf7',
    title: 'All Documents',
    library: 'ALL',
    link: 'all',
    icon: 'folder',
  },
  {
    id: 'ad1d9409-bfe9-4c34-9ce6-12cf10a1b594',
    title: 'Electricity Documents',
    library: DocumentLibrary.Electricity,
    link: 'electricity',
    icon: 'folder',
    accessControlIndexer: 'data.electricity',
  },
  {
    id: '4cacab91-659a-4437-ba4e-18020d9badd7',
    title: 'Gas Documents',
    library: DocumentLibrary.Gas,
    link: 'gas',
    icon: 'folder',
    accessControlIndexer: 'data.gas',
  },
  {
    id: '24cde553-cced-4add-998d-5bbacfed3717',
    title: 'Governance Documents',
    library: DocumentLibrary.Governance,
    link: 'governance',
    icon: 'folder',
    iconColor: 'tertiary',
    accessControlIndexer: 'comply',
  },
  {
    id: '02cf37a1-b95e-4b05-9731-e3ae5f41af77',
    title: 'Transport Documents',
    library: DocumentLibrary.Transport,
    link: 'transport',
    icon: 'folder',
    accessControlIndexer: 'data.transport',
  },
  {
    id: '8eb9e9e4-0d6c-4ef4-b1eb-f2850947782a',
    title: 'Waste Documents',
    library: DocumentLibrary.Waste,
    link: 'waste',
    icon: 'folder',
    accessControlIndexer: 'data.waste',
  },
  {
    id: '71a1ff35-ea84-4a5a-8b5c-bbacc51a3f2b',
    title: 'Water Documents',
    library: DocumentLibrary.Water,
    link: 'water',
    icon: 'folder',
    accessControlIndexer: 'data.water',
  },
];

export type DocumentLibraries = (
  | (Omit<DocumentLibraryFolder, 'library'> & { library: 'ALL' })
  | (DocumentLibraryFolder & { iconColor?: 'tertiary' })
)[];

export default documentLibraries;
