import { useMutation } from '@apollo/client';
import { Survey, MutationUpdateSurveyArgs as Variables } from '@rio/rio-types';
import { useParams } from 'react-router-dom';
import { GetSurveysResponse } from './useGet';

import { UPDATE_SURVEY, GET_SURVEYS_PAGE } from './queries';
import { GET_SURVEY } from './queries';

import { useCurrentAccountId, useNotification } from '../../../hooks';

type UpdateSurveyResponse = {
  updateSurvey: Survey;
};

export function useUpdate() {
  const { surveyId } = useParams();
  const variables = {
    id: surveyId,
  };
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();
  const getSurveysVariables = {
    accountId,
  };
  return useMutation<UpdateSurveyResponse, Variables>(UPDATE_SURVEY, {
    onCompleted: () => {
      showNotification('The survey has been successfully updated!', 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },
    update: (cache, result) => {
      const updatedSurvey = result.data?.updateSurvey;
      if (updatedSurvey) {
        cache.writeQuery({
          variables,
          query: GET_SURVEY,
          data: {
            getSurveyById: updatedSurvey,
          },
        });

        const getSurveysResult = cache.readQuery<GetSurveysResponse>({
          query: GET_SURVEYS_PAGE,
          variables: getSurveysVariables,
        }) as GetSurveysResponse;

        if (getSurveysResult?.getSurveysPage) {
          cache.writeQuery({
            variables: getSurveysVariables,
            query: GET_SURVEYS_PAGE,
            data: {
              rows: getSurveysResult.getSurveysPage.rows.map((c) => (c.id === updatedSurvey.id ? updatedSurvey : c)),
            },
          });
        }
      }
    },
  });
}
