import { IDeveloperField, IDeveloperSettings, IUser } from 'dromo-uploader-react';
import { getEnvVar } from '../../../env';

export type ICustomDeveloperSettings = Omit<IDeveloperSettings, 'reviewStep'> & {
  reviewStep: {
    helpText?: string | null | undefined;
    processingText?: string | null | undefined;
    saveForLater?: boolean | undefined;
    enableUserTransformations?: boolean | undefined;
  };
};

export interface DromoConfiguration {
  settings: ICustomDeveloperSettings;
  fields: IDeveloperField[];
  user?: IUser;
  style?: React.CSSProperties;
  schemaIds: EnvironmentSchemaIds;
  bulkRowHooks?: any[];
}

export interface EnvironmentSchemaIds {
  dev: string;
  qa: string;
  uat: string;
  live: string;
}

// See docs: https://developer.dromo.io/fields/
const fields: IDeveloperField[] = [];

// See docs: https://developer.dromo.io/settings/
const settings: ICustomDeveloperSettings = {
  developmentMode: getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'Develop',
  importIdentifier: '',
  allowCustomFields: false, // Disallow custom fields
  invalidDataBehavior: 'BLOCK_SUBMIT', // Prevent errors from being submitted
  backendSyncMode: 'FULL_DATA',
  autoMapHeaders: true, // Auto map CSV headers
  manualInputDisabled: false,
  displayEncoding: false,
  matchValuesStep: {
    maxMappableSelectValues: 1000,
  },
  uploadStep: {
    helpText: 'Please click the link to download a template for this upload, including a separate tab for guidance.',
  },
  reviewStep: {
    processingText: `We're currently processing your data upload now and hope to be complete shortly`,
    enableUserTransformations: true,
  },
  styleOverrides: {
    global: {
      primaryTextColor: '#394B59',
      secondaryTextColor: '#667D8D',
    },
    primaryButton: {
      textColor: '#FFFFFF',
      backgroundColor: '#003E82',
    },
    secondaryButton: {
      textColor: '#FFFFFF',
      backgroundColor: '#838B94',
    },
  },
};

const schemaIds: EnvironmentSchemaIds = {
  dev: '',
  qa: '',
  uat: '',
  live: '',
};

const style: React.CSSProperties = {
  backgroundColor: '#0094c9',
  borderColor: '#0094c9',
  borderRadius: '32px',
  borderStyle: 'solid',
  borderWidth: '1px',
  boxSizing: 'border-box',
  color: '#ffffff',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: '"Proxima Nova", Helvetica, Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '1.4em',
  marginLeft: '16px',
  opacity: 1,
  padding: '16px 24px',
  textAlign: 'center',
  textDecoration: 'none',
  width: 'auto',
};

export const dromoConfiguration: DromoConfiguration = {
  fields,
  settings,
  style,
  schemaIds,
};
