import React from 'react';

import { ConfirmAction } from '../../../components/ConfirmAction/ConfirmAction';
import { Modal } from 'rio-ui-components';

const ConfirmRollbackModal = ({ onDismiss, rollBackFunction }) => {
  return (
    <Modal name="wasteDataUploadContainer__uploadModal" size="sm" show dismissable onDismiss={onDismiss} height="auto" maxHeight="90vh">
      <ConfirmAction
        name="confirmRollback"
        notification={{ show: false }}
        headingText="Start Rollback"
        bodyText="Are you sure you want to roll back this data?"
        onConfirmAction={() => {
          rollBackFunction();
          onDismiss();
        }}
        onCancelAction={onDismiss}
      />
    </Modal>
  );
};

export default ConfirmRollbackModal;
