import { gql } from '@apollo/client';

export const GET_AVAILABLE_MANAGEMENT_SYSTEMS = gql`
  query GetAvailableManagementSystems($accountId: ID!, $type: GovernanceItemType) {
    getAvailableManagementSystems(accountId: $accountId, type: $type) {
      id
      title
      type
    }
  }
`;
