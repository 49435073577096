import { useApolloClient } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GET_NORMALISERS_BY_ID } from './index.queries';
import * as scopes from '../../../constants/scopes';
import { getNormaliserScope } from './normalizerScopes';
import { utcToLocaleDate } from '../../../utils/formatDate';

const yearAfter = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

const defaultValues = {
  name: '',
  description: '',
  quantity: 0,
  scope: scopes.ACCOUNT,
  account: null,
  locationId: null,
  startDate: new Date(),
  endDate: yearAfter(),
  tagId: null,
  type: null
};

const defaultErrors = {
  name: null,
  description: null,
  quantity: null,
  scope: null,
  account: null,
  locationId: null,
  startDate: null,
  endDate: null,
  tagId: null,
  type: null
};

function validatePresence(values) {
  const errorMessages = {
    name: 'Name must be specified',
    description: 'Description must be specified',
    quantity: 'Quantity must be specified',
    locationId: 'Location must be specified',
    tagId: 'Tag must be specified',
    startDate: 'Start Date must be specified',
    endDate: 'End Date must be specified',
    type: 'Type must be specified',
    scope: 'Scope must be specified'
  };

  return Object.keys(errorMessages).reduce((acc, field) => {
    const fieldInvalid = !values[field];

    if (fieldInvalid) {
      const fieldIsLocationButCanBeNull = field === 'locationId' && values.scope !== scopes.LOCATION;
      const fieldIsTagButCanBeNull = field === 'tagId' && values.scope !== scopes.TAG;
      if (fieldIsLocationButCanBeNull || fieldIsTagButCanBeNull) {
        return acc;
      }
      return {
        ...acc,
        [field]: errorMessages[field]
      };
    }
    return acc;
  }, {});
}

function validateBusinessLogic(values) {
  const errors = {};
  if (!!values.startDate && !!values.endDate && values.startDate.getTime() > values.endDate.getTime()) {
    errors.endDate = 'End Date cannot be less than Start Date';
  }
  return errors;
}

export function useNormaliserForm(defaults = defaultValues) {
  const [values, setValues] = useState(defaults);
  const [errors, setErrors] = useState(defaultErrors);

  const setValue = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...value
    }));
  };

  const validate = () => {
    const absenceErrors = validatePresence(values);
    const businessLogicErrors = validateBusinessLogic(values);
    const mergedErrors = {
      ...absenceErrors,
      ...businessLogicErrors
    };
    setErrors(mergedErrors);
    return !Object.keys(mergedErrors).length;
  };

  return [values, setValue, errors, validate];
}

export function useNormaliserFormWithDefaultsFromServer(normaliserId) {
  const [values, setValues, errors, validate] = useNormaliserForm();
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();

  useEffect(
    () => {
      async function fetchNormaliserAndLocation() {
        const { data, error } = await client
          .query({
            query: GET_NORMALISERS_BY_ID,
            variables: {
              id: normaliserId
            }
          })
          .finally(() => {
            setLoading(false);
          });
        if (!error && data && data.getNormaliserById) {
          const normaliser = data.getNormaliserById;
          const scope = getNormaliserScope(normaliser);
          setValues({
            ...normaliser,
            type: normaliser?.type?.id || null,
            scope,
            startDate: normaliser.startDate ? utcToLocaleDate(normaliser.startDate) : null,
            endDate: normaliser.endDate ? utcToLocaleDate(normaliser.endDate) : null
          });
        }
      }

      fetchNormaliserAndLocation();
    },
    // eslint-disable-next-line
    [normaliserId]
  );

  return { values, setValues, errors, validate, loading };
}
