import { setValue } from './index.queries';
import { UPDATE_USER_COURSE_PROGRESS } from '../ViewCourseButton/index.queries';
import { NOTSTARTED, INPROGRESS, COMPLETE } from '../../constants/courseProgress';

class InitialiseScormApi {
  startupData;

  constructor(startUpData) {
    this.startupData = startUpData;
  }

  initialise(
    client,
    courseId,
    courseTitle,
    cpdValue,
    passScore,
    userName,
    courseProgress,
    userId,
    refetch,
    onCompleted
  ) {
    window.API = {
      LMSInitialize: () => {
        client.mutate({ mutation: setValue, variables: { key: 'user_name', value: userName, courseId: courseId } });
        client.mutate({
          mutation: setValue,
          variables: { key: 'course_title', value: courseTitle, courseId: courseId }
        });
        client.mutate({
          mutation: setValue,
          variables: { key: 'cpd_value', value: cpdValue ? cpdValue : 0, courseId: courseId }
        });
        client.mutate({
          mutation: setValue,
          variables: { key: 'pass_score', value: passScore ? passScore : 0, courseId: courseId }
        });
        if (!courseProgress || courseProgress === NOTSTARTED) {
          client
            .mutate({
              mutation: UPDATE_USER_COURSE_PROGRESS,
              variables: { userId: userId, courseId, courseProgress: INPROGRESS }
            })
            .then(() => refetch());
        }
      },
      LMSFinish: (arg) => {
        console.log('TCL: InitialiseScormApi -> initialise -> arg', arg);
      },
      LMSGetValue: (element) => {
        const key = this.getValueByKey(element);
        const result = key ? key.value : key;
        if (result) {
          return result;
        } else {
          return '';
        }
      },
      LMSSetValue: (element, value) => {
        client.mutate({ mutation: setValue, variables: { key: element, value: value, courseId: courseId } });
        if (element === 'cmi.core.lesson_status' && (value === 'passed' || value === 'completed')) {
          client
            .mutate({
              mutation: UPDATE_USER_COURSE_PROGRESS,
              variables: { userId: userId, courseId, courseProgress: COMPLETE }
            })
            .then(() => {
              refetch();
              onCompleted();
            });
        }
      },
      LMSCommit: () => {},
      LMSGetLastError: () => {},
      LMSGetErrorString: () => {},
      LMSGetDiagnostic: () => {}
    };
  }

  getValueByKey(key) {
    return this.startupData.find((i) => i.key === key);
  }
}

export default InitialiseScormApi;
