import _ from 'lodash';
import { DataImportError } from '@rio/rio-types';
import { styled, Text } from '@rio/ui-components';
import { useMemo } from 'react';

const ContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.ref.palette.neutral[70]};
  background-color: ${(props) => props.theme.ref.palette.neutral[90]};
`;

const Subtitle = styled(Text)`
  margin-bottom: 16px;
`;

const RowOfErrors = styled('div')`
  margin-bottom: 16px;
`;

const Ul = styled('ul')`
  list-style-type: disc;
  margin-bottom: 12px;
`;

const Li = styled('li')`
  margin-left: 20px;
`;

interface ValidationErrorsProps {
  errors: DataImportError[];
  errorsCount: number;
}
type SplitError = DataImportError & {
  row?: string;
};

export function ValidationErrors({ errors, errorsCount }: ValidationErrorsProps) {
  const splitErrors: SplitError[] = useMemo(
    () =>
      errors.filter(Boolean).reduce((acc: SplitError[], error) => {
        const rows = error.rows?.split(',');
        if (rows?.length) {
          return acc.concat(
            rows.map((row) => ({
              ...error,
              row,
            }))
          );
        }
        return acc.concat([error]);
      }, []),
    [errors]
  );

  const rowsWithErrorsNumber = useMemo(() => Object.keys(_(splitErrors).groupBy('row').value()).length, [splitErrors]);

  const groupedErrors = useMemo(() => {
    return _(splitErrors)
      .groupBy('row')
      .map((importErrors: DataImportError[], row) => (
        <RowOfErrors key={row}>
          <Text typescale="title" size="large">
            Row {row}:
          </Text>
          {_(importErrors)
            .filter(Boolean)
            .groupBy((e) => _.capitalize(e.fieldName || '') || 'Other errors')
            .map((err, field) => (
              <Ul key={field}>
                <Text typescale="title" size="medium">
                  {field}:
                </Text>
                {err.map((e) => (
                  <Li key={JSON.stringify(e)}>
                    {e.message?.includes('Something unexpected happened') || e.message?.includes('0 rows') ? (
                      <Text typescale="body" size="medium" weight={!e.actionRequired ? 'bold' : 'normal'}>
                        {e.message}
                      </Text>
                    ) : (
                      <Text
                        typescale="body"
                        size="medium"
                        weight={!e.actionRequired ? 'bold' : 'normal'}
                      >{`You've provided: "${e.value}". ${e.message}`}</Text>
                    )}
                    {e.actionRequired && (
                      <Text typescale="body" size="medium">
                        Field can be aliased.
                      </Text>
                    )}
                  </Li>
                ))}
              </Ul>
            ))
            .value()}
        </RowOfErrors>
      ))
      .value();
  }, [splitErrors]);

  return (
    <ContainerStyled>
      <Subtitle typescale="body" size="large">
        Showing {errorsCount} errors in {rowsWithErrorsNumber} rows:
      </Subtitle>
      {groupedErrors}
    </ContainerStyled>
  );
}
