import { styled, AgGrid } from '@rio/ui-components';

export const ButtonGrid = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const AgGridStyled = styled(AgGrid)`
  flex: 1;
  height: calc(100vh - 216px); // header + page title height

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: calc(100vh - 268px); // header + page title height in lg
  }
`;
