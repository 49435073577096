import { useQuery } from "@apollo/client";
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_MATERIAL_ORIGIN_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface MaterialOriginSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function MaterialOriginSelect({ value, onChange }: MaterialOriginSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllMaterialOriginTypes'>>(GET_ALL_MATERIAL_ORIGIN_TYPES);
  return <Select placeholder="Please select origin of material" value={value} options={toSelectOptions(data?.getAllMaterialOriginTypes)} error={error} isLoading={loading} onChange={onChange} />;
}
