import React from 'react';
import { LearnContentType } from '@rio/rio-types';
import styled from 'styled-components';
import CourseTile from '../../CourseTile';
import { LinkRef } from '../../../../components/CustomRouteLink';
import { NOTSTARTED } from '../../../../constants/courseProgress';

const TileContainer = styled.div`
  flex: 1 1 auto;

  // Fallbacks for IE
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-center;

  // CSS grid for real browsers
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: ${(props) => props.theme.geometry.md.spacing};
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const CoursesGrid = ({ items, learnAccess, bundleId }) => {
  const isStatic = ['explore', 'continue', 'completed', 'all'].includes(bundleId);

  return (
    <TileContainer>
      {items.map((item, index) => {
        const id = isStatic ? item?.bundleIdList[0] : bundleId;

        const url = item.restricted
          ? '#'
          : `/engage/${id ? id + '/' : ''}course${item.type === LearnContentType.Scorm ? '/SCORM' : ''}/${item.id}`;

        if (learnAccess) {
          return (
            <LinkRef
              restricted={item.restricted}
              name={`CoursesGrid__Link__${item.id}`}
              to={url}
              key={`link${index}-${item.id}`}
            >
              <CourseTile
                learnAccess={learnAccess}
                courseId={item.id}
                title={item.name}
                viewed={item.metaData.viewed}
                type={item.type}
                image={item.metaData.thumbnail}
                restricted={item.restricted}
                key={item.id}
                courseProgress={item.userCourseMetaData ? item.userCourseMetaData.courseProgress : NOTSTARTED}
              />
            </LinkRef>
          );
        } else {
          return (
            <CourseTile
              learnAccess={learnAccess}
              restricted={item.restricted}
              courseId={item.id}
              title={item.name}
              viewed={item.metaData.viewed}
              type={item.type}
              image={item.metaData.thumbnail}
              key={item.id}
              courseProgress={item.userCourseMetaData ? item.userCourseMetaData.courseProgress : NOTSTARTED}
            />
          );
        }
      })}
    </TileContainer>
  );
};

export default CoursesGrid;
