import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Notification, PropertySheet } from 'rio-ui-components';
import { isPostalCode } from 'validator';
import { isPhoneNumber } from '../../../utils/validation';
import { UPDATE_CONTRACTOR } from './index.queries';
import AddressPropertySheet from '../../../components/AddressPropertySheet';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const UpdateContractor = ({ defaultCountry, onDismiss, countryList, ...props }) => {
  const [state, setState] = useState({
    hasError: false,
    isSuccess: false,
    notification: { show: false, message: '', color: 'success' },
    contractorId: { value: props.contractorId },
    contractorName: { value: props.contractorName, success: true },
    contractorAddressId: { value: props.contractorAddressId },
    address1: { text: props.address1, error: '' },
    address2: { text: props.address2, error: '' },
    address3: { text: props.address3, error: '' },
    city: { text: props.city, error: '' },
    postcode: { text: props.postcode, error: '' },
    phone: { text: props.phone, error: '' },
    options: [],
    selectCountry: defaultCountry
  });

  const [updateContractor] = useMutation(UPDATE_CONTRACTOR, {
    onError: () => {
      setState({
        ...state,
        notification: {
          show: true,
          message: 'Something went wrong. If the problem continues please contact support.',
          color: 'danger'
        },
        hasError: true,
        isSuccess: false
      });
    },
    onCompleted: () => {
      onDismiss({ show: false, message: 'Contractor has been updated!', color: 'success' });
    }
  });

  const hideNotification = () => {
    setState({
      ...state,
      notification: { ...state.notification, show: false }
    });
  };

  const handleSaveContractor = () => {
    if (!isValid()) {
      return;
    }
    updateContractor({
      variables: {
        id: state.contractorId.value,
        name: state.contractorName.value,
        addressId: state.contractorAddressId.value,
        add1: state.address1.text || null,
        add2: state.address2.text || null,
        add3: state.address3.text || null,
        city: state.city.text,
        post: state.postcode.text,
        phone: state.phone.text ? state.phone.text.trim() : null,
        countryId: state.selectCountry.value
      }
    });
  };
  const handleChange = (e) => setState({ ...state, [e.target.name]: { text: e.target.value, error: null } });

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.select.value, label: e.target.select.label }
    }));
  };

  const isValid = () =>
    state.contractorName.success &&
    validateField(state.address1.text) &&
    validateField(state.city.text) &&
    validateField(state.postcode.text) &&
    isPostalCode(state.postcode.text, 'GB') &&
    (!state.phone.text || isPhoneNumber(state.phone.text));

  const validateField = (value) => value && value.trim() !== '';

  const handleContractorNameChange = (event) => {
    const value = event.target.value;
    const success = value ? true : null;
    setState({
      ...state,
      contractorName: { ...state.contractorName, value, success }
    });
  };

  return (
    <Container name="update-contractor">
      {state.notification.show && (
        <Notification
          name="update-account__notification"
          show
          color={state.notification.color}
          onClose={hideNotification}
        >
          {state.notification.message}
        </Notification>
      )}

      <PropertySheet id={'PropertySheet'}>
        <PropertySheet.HeaderRow>Update Contractor</PropertySheet.HeaderRow>
        <PropertySheet.Row>
          <PropertySheet.TextInputColumn
            name="contractor--name"
            onChange={handleContractorNameChange}
            label="Contractor Name"
            value={state.contractorName.value}
            success={state.contractorName.success}
          />
        </PropertySheet.Row>
        <AddressPropertySheet
          id="ContractorAddressPropertySheet"
          name="ContractorAddressPropertySheet"
          address1={state.address1}
          address2={state.address2}
          address3={state.address3}
          city={state.city}
          postcode={state.postcode}
          phone={state.phone}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          disabled={false}
          countryList={countryList}
          selectCountry={state.selectCountry}
        />

        <PropertySheet.Row>
          <PropertySheet.ButtonColumn name="updateContractor" onClick={handleSaveContractor} disabled={!isValid()}>
            Save
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
};

export default UpdateContractor;
