import { capitalize, get } from 'lodash';
import { resetErrors, setError } from './utils';

function extractRecordKey(record: object, fields: string[]) {
  return fields.map((field: string) => get(record, field)).join('/');
}

export function validateDependency(data: object[], fields: string[], records: any[]) {
  if (fields.length !== 2) {
    throw new Error('Dependency validation only supports 2 fields');
  }

  return records.map((record) => {
    const newRecord = { ...record };

    // Get recordKey
    const recordKey = extractRecordKey(
      record.row,
      fields.map((field) => `${field}.resultValue`)
    );

    // Get data index - array of keys
    const dataIndex = data.map((item) => extractRecordKey(item, fields));

    if (!dataIndex.includes(recordKey)) {
      setError(
        {
          message: `Incorrect value pair for ${fields.map(capitalize).join(', ')}.`,
          level: 'error',
        },
        newRecord,
        fields
      );
    } else {
      resetErrors(newRecord, fields);
    }

    return newRecord;
  });
}
