import { useQuery } from '@apollo/client';
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_TRANSPORT_SUB_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface SubTypeSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function SubTypeSelect({ value, onChange }: SubTypeSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllTransportSubTypes'>>(GET_ALL_TRANSPORT_SUB_TYPES);
  return (
    <Select
      placeholder="Please select a subtype"
      value={value}
      options={toSelectOptions(data?.getAllTransportSubTypes)}
      error={error}
      isLoading={loading}
      onChange={onChange}
    />
  );
}
