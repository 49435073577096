import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Heading, Row, TextInput, Label, Button, Text } from 'rio-ui-components';
import { RESET_USER_PASSWORD } from './index.queries';
import { passwordPattern } from '../../RegistrationContainer/validators';

const ContainerStyled = styled.div`
  padding: 20px;
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const TextStyled = styled(Text)`
  text-align: center;
  font-size: 13px;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const TextInputStyled = styled(TextInput)`
  width: 100%;
`;

const ResetPasswordModal = ({ userId, onDissmiss }) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const isMatch = password === confirm;

  const [resetPasswordMutation, { error, data }] = useMutation(RESET_USER_PASSWORD, {
    variables: { username: userId, password },
  });

  if (error) {
    Sentry.captureException(error);
    onDissmiss({
      message: 'Something went wrong. Please try again later or contact support.',
      color: 'danger',
      id: userId,
    });
  }

  if (data) {
    onDissmiss({ message: 'The password has been reset!', color: 'success', id: userId });
  }

  const isAppropriateLength = password && !passwordPattern.test(password);

  return (
    <ContainerStyled>
      <HeaderStyled size="lg">Reset Password</HeaderStyled>
      <TextStyled> Passwords must be at least 10 characters.</TextStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>New Password</LabelStyled>
          </LabelContainerStyled>
          <TextInputStyled type="password" inline value={password} onChange={(e) => setPassword(e.target.value)} />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Confirm Password</LabelStyled>
          </LabelContainerStyled>
          <TextInputStyled
            type="password"
            inline
            value={confirm}
            error={isMatch || !confirm.length ? null : 'Passwords do not match.'}
            onChange={(e) => setConfirm(e.target.value)}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <Button
          name="SubmitDocuemnt"
          onClick={resetPasswordMutation}
          disabled={!password.length || !confirm.length || !isMatch || isAppropriateLength}
          color="success"
        >
          Reset Password
        </Button>
      </Row>
    </ContainerStyled>
  );
};

export default ResetPasswordModal;
