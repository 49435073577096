/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DataTrackerConfig, DataTrackerConfigInput, Frequency } from '@rio/rio-types';
import { get } from 'lodash';
import { DataTrackerFormattedDetails } from './types';
import {
  toSelectOptions,
  mapSelectedOptionsToInputValues,
  namedEntityToSelectOption,
  enumValueToSelectOption,
} from '../../../utils';

export const formatDataTrackerConfigData = (dataTrackerConfig: DataTrackerConfig): DataTrackerFormattedDetails => {
  return {
    id: dataTrackerConfig.id,
    account: namedEntityToSelectOption(dataTrackerConfig.account),
    supplier: namedEntityToSelectOption(dataTrackerConfig.supplier),
    frequency: enumValueToSelectOption(dataTrackerConfig.frequency),
    recurrence: dataTrackerConfig.recurrence,
    locations: toSelectOptions(dataTrackerConfig.locations),
    meters: toSelectOptions(dataTrackerConfig.meters),
    supplierUtility: dataTrackerConfig.supplier.utility
  };
};

export const prepareDataTrackerConfigInput = (
  dataTrackerConfig: DataTrackerFormattedDetails,
  accountId?: string
): DataTrackerConfigInput => {
  return {
    accountId: accountId || dataTrackerConfig?.account?.value!,
    id: dataTrackerConfig.id,
    supplierId: dataTrackerConfig?.supplier?.value!,
    frequency: dataTrackerConfig.frequency?.value! as Frequency,
    recurrence: Number(dataTrackerConfig.recurrence!),
    locationIds: mapSelectedOptionsToInputValues(dataTrackerConfig.locations || []) as string[],
    metersIds: mapSelectedOptionsToInputValues(dataTrackerConfig.meters || []) as string[],
  };
};

export const isTextEvent = (event: object) => {
  const type = get(event, 'target.type');
  return type === 'text' || type === 'textarea';
};
