import { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  GridOptions,
  GridReadyEvent,
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  IRowNode,
} from 'ag-grid-community';
import { Dictionary } from 'lodash';
import get from 'lodash/get';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { downloadJSONFile } from '../../utils/downloadJSONFile';
import { JSONIcon } from '../../constants/iconIds';
import { getEnvVar } from '../../env';

LicenseManager.setLicenseKey(getEnvVar('REACT_APP_AG_GRID_LICENSE_KEY'));

const GridContainer = styled.div`
  flex: 1;
  overflow: auto;
  & .ag-paging-panel {
    justify-content: flex-start;
  }
  /* reduce ag-grid default header line-height and padding */
  & .ag-header-cell {
    line-height: normal;
    padding: 8px;
  }

  /* make header separator height proportional to header height */
  & .ag-header-cell:after,
  & .ag-header-group-cell:after {
    height: 50%;
  }

  /* set icons height to their real absolute value to ensure proper vertical alignment */
  & .ag-header-cell-menu-button .ag-icon-menu,
  & .ag-header-cell-label .ag-header-icon {
    height: 16px;
  }

  /* make header text wrap, without breaking words and without ellipsis */
  & .ag-header-cell-label .ag-header-cell-text {
    height: auto;
    overflow: visible;
    overflow-wrap: normal;
    text-overflow: clip;
    white-space: normal;
  }
`;

interface GridProps extends GridOptions {
  id?: string;
  rowIdField?: string;
  onDestroy?: () => void;
}

const DEFAULT_GRID_ID = 'ag-grid';

const MIN_HEIGHT = 48;

function autosizeHeaders(event: GridReadyEvent | ColumnResizedEvent, id: string) {
  if ((event as ColumnResizedEvent).finished !== false) {
    event.api.setHeaderHeight(MIN_HEIGHT);
    const headerCells = document.querySelectorAll(`#${id} .ag-header-cell-label`);
    let minHeight = MIN_HEIGHT;
    headerCells.forEach((cell) => {
      minHeight = Math.max(minHeight, cell.scrollHeight);
    });
    event.api.setHeaderHeight(minHeight + 16);
  }
}

const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
  const result: (string | MenuItemDef)[] = [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    {
      name: 'Download JSON',
      action: () => {
        const rowData: Dictionary<string>[] = [];
        params.api.forEachNode((node: IRowNode) => rowData.push(node.data));
        downloadJSONFile(rowData, 'transactions');
      },
      icon: `<img border="0" width="16" height="16" src="${JSONIcon}"/>`,
    },
  ];

  return result;
};

const getObjectValue = (obj): string | null => {
  if (obj?.hasOwnProperty('name')) {
    return obj?.name;
  } else if (obj?.hasOwnProperty('title')) {
    return obj?.title;
  }

  if (Array.isArray(obj)) {
    return obj.map((o) => getObjectValue(o)).join(',');
  }

  return null;
};

const defaultExcelExportParams = {
  processCellCallback: (params) => {
    const value = params.value;

    if (typeof value === 'object') {
      return getObjectValue(value);
    }
    return value;
  },
};

/**
 *
 * @deprecated - has performance issues and uses deprecated APIs
 */
export function Grid({
  onDestroy,
  id,
  onGridReady,
  onColumnResized,
  defaultColDef = {},
  rowIdField = 'id',
  ...props
}: GridProps) {
  useEffect(
    () => () => {
      if (onDestroy) {
        onDestroy();
      }
    },
    [onDestroy]
  );
  const gridId = id || DEFAULT_GRID_ID;
  return (
    <GridContainer className="ag-theme-alpine" id={gridId}>
      <AgGridReact
        debounceVerticalScrollbar
        suppressColumnVirtualisation={getEnvVar('NODE_ENV') === 'test'}
        getContextMenuItems={(params) => getContextMenuItems(params)}
        getRowId={({ data }) => get(data, rowIdField)}
        onColumnResized={(event: ColumnResizedEvent) => {
          autosizeHeaders(event, gridId);
          if (onColumnResized) {
            onColumnResized(event);
          }
        }}
        onGridReady={(event: GridReadyEvent) => {
          autosizeHeaders(event, gridId);
          if (onGridReady) {
            onGridReady(event);
          }
        }}
        defaultColDef={{
          wrapText: true,
          ...defaultColDef,
        }}
        defaultExcelExportParams={defaultExcelExportParams}
        {...props}
      />
    </GridContainer>
  );
}
