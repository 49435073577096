import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button, Heading, Text } from 'rio-ui-components';
import TaskDetails from './TaskDetails';
import { ACCOUNT, LOCATION, TAG } from '../../../constants/scopes';
import UPDATE_TASK from '../../../graphql/mutations/tasks/UpdateTask.mutation.graphql';
import DELETE_TASK from '../../../graphql/mutations/tasks/DeleteTask.mutation.graphql';
import { ValidateTask } from './validate';
import { useCurrentAccountId, usePermissions, useUserToken, useNotification } from '../../../hooks';
import { v4 as uuid } from 'uuid';
import { useSaveEvidence, EvidencePicker } from '../../../components/EvidenceNotes';
import { AUDIT } from '../../../constants/taskEvidenceTypes';
import CREATE_TASK_EVIDENCE from '../../../graphql/mutations/tasks/CreateTaskEvidence.mutation.graphql';
import { ScrollContainer } from 'rio-ui-components';

const ModalContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing} 0;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
`;

const ColStyled = styled(Col)`
  padding: ${(props) => `${props.theme.geometry.xs.spacing} ${props.theme.geometry.sm.spacing}`};
`;

const StyledModalContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButtonRow = styled(Row)`
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ContentContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: ${(props) => props.theme.geometry.sm.spacing};
  background-color: ${(props) => props.theme.colors.basic.white};
`;

const ScrollableContainerStyled = styled(ScrollContainer)`
  ${({ theme }) => `
    overflow: auto;
    padding: ${theme.geometry.xs.spacing};
  `}
`;

const prepareOptions = (options, labelKey = 'name', valueKey = 'id') =>
  options?.map((option) => ({ ...option, label: option[labelKey], value: option[valueKey] })) || [];

const checkPermissions = (permissions, action, isOwnResource) =>
  !!permissions.task.find((a) => a.startsWith(action) && !a.endsWith('Own')) ||
  (permissions.task.includes(`${action}Own`) && isOwnResource);

const EditTaskModal = ({ onDismiss, selectedTask, sourcePage, disabled, hasProjectsAccess = false }) => {
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const { showNotification } = useNotification();

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      showNotification('Task has been updated');
      onDismiss();
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);

  const [task, setTask] = useState({
    ...selectedTask,
    itemId: selectedTask?.item?.id ?? null,
    scope: selectedTask.scope || ACCOUNT,
    locationIds: prepareOptions(selectedTask.locations),
    tagIds: prepareOptions(selectedTask.tags, 'tagName'),
    projectId: selectedTask?.projectId?.id ?? selectedTask?.projectId,
  });

  const saveEvidence = useSaveEvidence();
  const [evidenceDocuments, setEvidenceDocuments] = useState([]);
  const [evidenceNotes, setEvidenceNotes] = useState('');

  const [createTaskEvidence] = useMutation(CREATE_TASK_EVIDENCE, {
    onCompleted: () => {
      showNotification('Evidence has been attached to the current task.', 'success');
      setShowEvidenceModal(false);
    },
    onError: () => {
      showNotification('Something went wrong! Please try again later.', 'danger');
    },
  });

  const saveEvidenceDocuments = async () => {
    if (!!evidenceDocuments.length) {
      const newDocumentIds = await saveEvidence(evidenceDocuments, [], 'Other Reference');

      createTaskEvidence({
        variables: {
          id: uuid(),
          taskId: selectedTask.id,
          evidenceType: AUDIT,
          notes: evidenceNotes,
          documents: newDocumentIds,
        },
        refetchQueries: ['GetTaskById'],
      });
    }
  };

  const handleSubmit = () => {
    updateTask({
      variables: {
        accountId,
        ...task,
        createdAt: task?.createdAt ? task.createdAt : new Date().toISOString(),
        completedAt: task?.isCompleted ? new Date().toISOString() : null,
        locationIds: task.scope === LOCATION ? task.locationIds.map(({ value }) => value) : [],
        tagIds: task.scope === TAG ? task.tagIds.map(({ value }) => value) : [],
      },
      refetchQueries: ['GetTasksByAccountId', 'GetLegislationById', 'GetTaskById'],
    });
  };

  const permissions = usePermissions();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const showDeleteButton = checkPermissions(permissions, 'delete', task.author.id === token.sub);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const [deleteTask] = useMutation(DELETE_TASK, {
    onError: (err) => {
      showNotification(err.message, 'danger');
      setIsSubmitting(false);
    },
    onCompleted: () => {
      showNotification('Task has been deleted!');
      onDismiss();
    },
    refetchQueries: ['GetTasksByAccountId'],
  });

  return (
    <Modal size="md" show onDismiss={() => onDismiss()}>
      <ModalContainerStyled>
        <HeaderStyled size="lg">Edit Task</HeaderStyled>
        <ScrollableContainerStyled>
          <TaskDetails
            task={task}
            setTask={setTask}
            accountId={accountId}
            isEditing
            sourcePage={sourcePage}
            disabled={disabled}
            hasProjectsAccess={hasProjectsAccess}
          />
        </ScrollableContainerStyled>
        <ColStyled item>
          <Button onClick={() => setShowEvidenceModal(true)}>Add Evidence</Button>
        </ColStyled>
        <Row container align="between">
          {showDeleteButton && (
            <ColStyled item>
              <Button
                color="danger"
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteConfirmationModal(true);
                }}
              >
                Delete
              </Button>
            </ColStyled>
          )}
          <ColStyled item>
            <Button disabled={!isEmpty(ValidateTask(task))} onClick={() => handleSubmit()}>
              Update
            </Button>
          </ColStyled>
        </Row>
      </ModalContainerStyled>
      {showDeleteConfirmationModal && (
        <Modal onDismiss={() => setShowDeleteConfirmationModal(false)} show name="confirmationModal">
          <StyledModalContainer>
            <StyledButtonRow item>
              <Heading>Delete Task</Heading>
            </StyledButtonRow>
            <StyledButtonRow item>
              <Text>This action will permanently delete this task. Do you want to proceed?</Text>
            </StyledButtonRow>
            <StyledButtonRow container distribution="around">
              <StyledButton
                color="danger"
                disabled={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  deleteTask({
                    variables: { id: task.id, accountId },
                    refetchQueries: ['GetTasksByAccountId', 'GetAspectsByAccountId'],
                  });
                }}
              >
                Delete
              </StyledButton>
              <StyledButton disabled={isSubmitting} onClick={() => setShowDeleteConfirmationModal(false)}>
                Cancel
              </StyledButton>
            </StyledButtonRow>
          </StyledModalContainer>
        </Modal>
      )}
      {showEvidenceModal && (
        <Modal show size="md" onDismiss={() => setShowEvidenceModal(false)} height="auto" maxHeight="90vh">
          <ContentContainer item>
            <Heading>Add Evidence</Heading>
            <EvidencePicker
              evidenceDocuments={evidenceDocuments}
              setEvidenceDocuments={setEvidenceDocuments}
              evidenceNotes={evidenceNotes}
              setEvidenceNotes={setEvidenceNotes}
            />
            <Button onClick={() => saveEvidenceDocuments()}>Save Evidence</Button>
          </ContentContainer>
        </Modal>
      )}
    </Modal>
  );
};

export { EditTaskModal };
