import React from 'react';
import { Icon } from 'rio-ui-components';
import styled, { useTheme as useV1Theme } from 'styled-components';
import { useTheme as useV2Theme } from '@rio/ui-components';
import { usePermissions, useUserToken } from '../../../../hooks';
import { mapBatchStatus } from '../utils';
import { UserPermissions } from '@rio/rio-types';
import { ActionFunction } from '../types';
import { BatchStatus } from '~/types';
import { useIsV2 } from '~/hooks/useIsV2';

const GridCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  margin: 0 10px;
`;

export type StatusProps = {
  icon: string;
  iconColor: string;
  linkText: string;
  onClick?: ActionFunction;
};

export interface StatusCellProps {
  data: {
    isLegacy: boolean;
    id: string;
    status: string;
    user: {
      id: string;
    };
  };
  download: {
    [id: string]: boolean;
  };
  defineStatusProps: (
    batchStatus: BatchStatus,
    theme: any,
    isLegacy: boolean,
    onActionClick: ActionFunction,
    onDataClick: ActionFunction,
    onErrorClick: ActionFunction
  ) => StatusProps;
  statusLink: (
    batchStatus: BatchStatus,
    isLegacy: boolean,
    name: string,
    onErrorClick: () => void,
    onDataClick: () => void,
    onActionClick: () => void,
    statusProps: StatusProps,
    download: boolean,
    permissions: UserPermissions,
    isOwnResource: boolean
  ) => React.ReactNode;
  onErrorClick: ActionFunction;
  onDataClick: ActionFunction;
  onActionClick: ActionFunction;
}

export const StatusCell = ({
  data,
  download,
  defineStatusProps,
  statusLink,
  onActionClick,
  onDataClick,
  onErrorClick,
}: StatusCellProps) => {
  const isV2 = useIsV2();
  const v1Theme = useV1Theme();
  const v2Theme = useV2Theme();
  const theme = isV2 ? v2Theme : v1Theme;
  const { token } = useUserToken();
  const permissions = usePermissions();
  const isOwnResource = data?.user?.id?.toLowerCase() === token.sub;
  const batchStatus = mapBatchStatus(data.status);
  const statusProps = defineStatusProps(batchStatus, theme, data.isLegacy, onErrorClick, onDataClick, onActionClick);

  return (
    <GridCellContainer>
      <>
        <IconStyled icon={statusProps?.icon} color={statusProps?.iconColor} />
        {statusLink(
          batchStatus,
          data.isLegacy,
          'Uploads',
          () => onErrorClick(data.id),
          () => onDataClick(data.id),
          () => onActionClick(data.id),
          statusProps,
          download?.[data.id],
          permissions,
          isOwnResource
        )}
      </>
    </GridCellContainer>
  );
};
