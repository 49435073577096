/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useCallback } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';
import { UserRole, DataTrackerConfig } from '@rio/rio-types';
import { Col, Button, ErrorMessage, ContainerError } from 'rio-ui-components';
import styled from 'styled-components';
import { MODALS, Nullable } from '../../../types';
import { useGetDataTrackerConfigs } from '../../../hooks/useGetDataTrackerConfigs';
import { useNotification, useCurrentAccountId, usePermissions, useCurrentAccount } from '../../../hooks';

import ContainerHeader from '../../../components/ContainerHeader';
import { DeleteModal } from '../../../components/DeleteModal';
import { Grid } from './Grid';

import DataTrackerConfigPopup from './DataTrackerConfigModal';
import { FetchRowsParameters, GridApiType } from './types';
import { DELETE_DATA_TRACKER_CONFIGURATION, GET_DATA_TRACKER_CONFIGURATIONS } from './index.queries';
import { formatDataTrackerConfigData } from './utils';
import { ClearButton } from '../../../components/ClearButton';
import { ToastColor } from '@rio/ui-components';

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

interface Notification {
  message?: Nullable<string>;
  color?: Nullable<string>;
  id?: string;
}

interface ModalInfo {
  type: Nullable<string>;
  dataTrackerConfigId: Nullable<string>;
}

const ConfigurationDataTrackerContainer = () => {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { id, name } = useCurrentAccount();
  const currentAccount = { id, name };
  const permissions = usePermissions();
  const isSysAdmin = permissions.role === UserRole.Sysadmin;
  const { showNotification } = useNotification();
  const [dataTrackerConfigs, setDataTrackerConfigs] = useState<DataTrackerConfig[]>([]);
  const [gridApi, setGridApi] = useState<GridApiType>();
  const [showModalInfo, setShowModal] = useState<ModalInfo>({ type: null, dataTrackerConfigId: null });

  const { error, refetch: refetchFn } = useGetDataTrackerConfigs(isSysAdmin ? null : accountId);

  const [deleteDataTrackerConfig] = useMutation(DELETE_DATA_TRACKER_CONFIGURATION);

  const dataTrackerConfigToUpdate = dataTrackerConfigs.find(
    (dataTrackerConfig: DataTrackerConfig) => dataTrackerConfig.id === showModalInfo.dataTrackerConfigId
  );

  const refreshGrid = useCallback(() => gridApi?.api?.refreshServerSide({ purge: true }), [gridApi]);

  const showModal = (type?: Nullable<string>, dataTrackerConfigId?: Nullable<string>) => {
    return setShowModal({ type, dataTrackerConfigId });
  };

  const dismissModals = (notification?: Notification) => {
    showModal(null, null);
    if (notification?.message) {
      showNotification(notification?.message, notification?.color as ToastColor);
    }
  };

  const fetchRows = async (variables: FetchRowsParameters) => {
    const {
      data: {
        getDataTrackerConfigs: { rows, totalRows },
      },
    } = await client.query({
      query: GET_DATA_TRACKER_CONFIGURATIONS,
      variables: { ...variables, accountId: isSysAdmin ? null : accountId },
      fetchPolicy: 'network-only',
    });

    setDataTrackerConfigs(rows);

    return { rows, totalRows };
  };

  const deleteDataTrackerConfigFunc = async () => {
    try {
      await deleteDataTrackerConfig({ variables: { id: dataTrackerConfigToUpdate?.id } });
      showNotification('DataTracker configuration has been successfully deleted', 'success');
      refreshGrid();
    } catch (err) {
      showNotification('An error has occurred. If the problem persists please contact support', 'danger');
      console.error(err);
    } finally {
      dismissModals();
    }
  };

  return (
    <Col name="ConfigurationDataTrackerContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationDataTrackerContainer__Controls"
        icon="list"
        iconColor="primary"
        title={<FormattedMessage id="pages.configuration.dataTracker.container.header.missing" />}
      >
        <Col span={2}>
          <ClearButton gridApi={gridApi?.api} columnApi={gridApi?.columnApi} />
        </Col>
        {permissions.dataTracker.find((action: string) => action.startsWith('create')) && (
          <ButtonStyled
            name="ConfigurationDataTrackerContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => showModal(MODALS.CREATE)}
          >
            <FormattedMessage id="pages.configuration.dataTracker.container.add" />
          </ButtonStyled>
        )}
      </ContainerHeader>
      {error && (
        <ErrorMessage error={error}>
          {({ title, body, icon }: { title: string; body: string; icon: string }) => (
            <ContainerError
              name="ConfigurationDataTrackerContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetchFn}
            />
          )}
        </ErrorMessage>
      )}
      <Grid
        isSystemAdmin={isSysAdmin}
        fetchRows={fetchRows}
        setGridApi={setGridApi}
        onEdit={showModal}
        onDelete={showModal}
      />
      {showModalInfo.type === MODALS.CREATE && (
        <DataTrackerConfigPopup
          isSysAdmin={isSysAdmin}
          currentAccount={currentAccount}
          refresh={refreshGrid}
          dismiss={dismissModals}
        />
      )}
      {showModalInfo.type === MODALS.UPDATE && (
        <DataTrackerConfigPopup
          isSysAdmin={isSysAdmin}
          currentAccount={currentAccount}
          dataTrackerConfigToUpdate={formatDataTrackerConfigData(dataTrackerConfigToUpdate!)}
          refresh={refreshGrid}
          dismiss={dismissModals}
        />
      )}
      {showModalInfo.type === MODALS.DELETE && (
        <DeleteModal
          deleteObjectName={'Data Tracker Configuration'}
          onConfirmClick={deleteDataTrackerConfigFunc}
          onDismissClick={dismissModals}
        />
      )}
    </Col>
  );
};

export default injectIntl(ConfigurationDataTrackerContainer);
