import React from 'react';
import styled from 'styled-components';
import { ContactDetails } from './ContactDetails';
import { Page } from '@rio/ui-components';
import { EmailForm } from './EmailForm';

const PageContentContainerStyled = styled('div')`
  display: flex;
  gap: 72px;
`;

export const ContactContainer = () => {
  return (
    <Page
      title={{
        content: 'Contact Us',
        crumbs: [{ label: 'Contact' }],
      }}
    >
      <PageContentContainerStyled>
        <ContactDetails />
        <EmailForm />
      </PageContentContainerStyled>
    </Page>
  );
};
