import { useParams } from 'react-router-dom';

import { SurveyLatestRetriever } from './SurveyLatestRetriever';
import { SurveyByIdRetriever } from './SurveyByIdRetriever';

export function SurveyOverviewContainer() {
  const { surveyId } = useParams();
  if (!surveyId) {
    return <SurveyLatestRetriever />;
  }

  return <SurveyByIdRetriever surveyId={surveyId} />;
}
