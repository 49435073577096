import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, Row } from 'rio-ui-components';

const AccordionRow = styled(Row)`
  background: white;
`;
interface AliasAccordionProps {
  text: string;
  color: string;
  children: React.ReactNode;
}
export function AliasAccordion({ text, color, children }: AliasAccordionProps) {
  const [open, setOpen] = useState(false);
  return (
    <AccordionRow span={12} item>
      <Accordion onToggle={() => setOpen((isOpen) => !isOpen)} open={open} size="md" text={text} color={color}>
        {children}
      </Accordion>
    </AccordionRow>
  );
}
