import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Heading } from 'rio-ui-components';
import { FormattedMessage } from 'react-intl';
import { Query } from '@apollo/client/react/components';
import CoursesGrid from './CoursesGrid';
import { GET_AVAILABLE_COURSES } from '../Courses/index.queries';
import { useUserToken } from '../../../hooks/useUserToken';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';

const RelatedCourses = styled(Heading)`
  margin-top: ${(props) => props.theme.geometry.lg.spacing};
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: ${(props) => props.theme.geometry.xs.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.text.normal.background};
`;

export const RelatedCourseGrid = ({ learnAccess, id }) => {
  const {
    token: { sub: userId }
  } = useUserToken();
  const accountId = useCurrentAccountId();

  return (
    <Query query={GET_AVAILABLE_COURSES} variables={{ pageNumber: 1, userId, accountId }}>
      {({ loading, error, data }) => {
        if (loading || error || !data?.getAvailableCourses?.data?.length) {
          return null;
        }

        if (data.getAvailableCourses.data.length > 0) {
          const relatedCourses = Array.from(new Set(data.getAvailableCourses.data));
          return (
            <Fragment>
              <RelatedCourses size="lg" weight="normal" color="normal">
                <FormattedMessage id="pages.coursesPreview.relatedCourses" defaultMessage="Related courses" />
              </RelatedCourses>
              <CoursesGrid courseId={id} items={relatedCourses} learnAccess={learnAccess} />
            </Fragment>
          );
        }
      }}
    </Query>
  );
};
