import { useBuySubscriptionMutation } from './useBuySubscriptionMutation';
import { Error } from '../../components/StripeForm/StripeFormStyles';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useBuyProductMutation } from './useBuyProductMutation';

export function useStripePayment() {
  const [payAsSubscription, { loading: isPayingSubscription }] = useBuySubscriptionMutation();
  const [payAsOneTimePurchase, { loading: isPayingOnetime }] = useBuyProductMutation();
  const stripe = useStripe();
  const elements = useElements();

  return [
    async ({ items = [], paymentMethod, couponId }, isSubscriptionStrategy = true) => {
      if (!stripe || !elements || items.length < 1) {
        return;
      }

      if (!paymentMethod) {
        throw new Error('Payment method is required');
      }

      const { data: { id } = {} } = isSubscriptionStrategy ? await payAsSubscription({ paymentMethod, items, couponId }) : await payAsOneTimePurchase({ paymentMethod, items, couponId });

      if (!id) {
        throw new Error('Payment is not created');
      }
    },
    {
      loading: isPayingSubscription || isPayingOnetime,
      isNotLoaded: !stripe
    }
  ];
}
