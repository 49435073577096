import { GridOptions } from 'ag-grid-community';
import { formatDatetime } from '~/utils/formatDate';

export function useUploadsGridConfig(): GridOptions {
  return {
    columnDefs: [
      {
        headerName: 'Status',
        field: 'status',
        filter: 'agTextColumnFilter',
        cellRenderer: 'status',
      },
      {
        headerName: 'Filename',
        field: 'fileName',
        filter: 'agTextColumnFilter',
        cellRenderer: 'fileName',
      },
      {
        headerName: 'User First Name',
        field: 'user.first_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'User Last Name',
        field: 'user.last_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Last Updated Date',
        field: 'lastUpdated',
        filter: 'agDateColumnFilter',
        sort: 'desc',
        initialSortIndex: 1,
        valueFormatter: ({ value }) => formatDatetime(new Date(value)),
      },
      {
        headerName: 'Rows',
        field: 'rowsUploaded',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Template used',
        cellRenderer: 'transposition',
        filter: false,
        sortable: false,
      },
      {
        headerName: 'Rollback',
        filter: false,
        sortable: false,
        cellRenderer: 'rollback',
      },
      {
        headerName: 'Summary',
        sortable: false,
        filter: false,
        cellRenderer: 'summary',
        cellStyle: {
          marginLeft: '20px',
        },
      },
    ],
  };
}
