import { styled } from '@rio/ui-components';
import { getEnvVar } from '../../../env';

// Access the environment variable
const flowUri = getEnvVar('REACT_APP_FLOW_URI') || getEnvVar('REACT_APP_BASE_URL');

const Frame = styled('iframe')`
  width: 100%;
  height: 100vh;
`;

export const FlowsHome = () => {
  return <Frame id="flowsFrame" src={`${flowUri}/rio-flows/v2/flows/`}></Frame>;
};
