/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DataTrackerFormattedDetails } from '../types';

enum NumberFields {
  recurrence = 'recurrence',
}

enum RequiredFields {
  account = 'account',
  supplier = 'supplier',
  frequency = 'frequency',
  recurrence = 'recurrence',
}

type NumberFieldsValidateErrors = {
  [name in NumberFields]?: string;
};

type RequiredFieldsValidateErrors = {
  [name in RequiredFields]?: string;
};

export type ValidateErrors = RequiredFieldsValidateErrors & NumberFieldsValidateErrors;

export const ValidateDataTrackerConfig = (dataTrackerConfig: DataTrackerFormattedDetails): ValidateErrors => {
  const errors: ValidateErrors = {};

  if (Number(dataTrackerConfig.recurrence!) <= 0 || !Number.isInteger(Number(dataTrackerConfig.recurrence))) {
    errors.recurrence = 'The value must be an number greater than zero';
  }

  for (const key of Object.values(RequiredFields)) {
    if (!dataTrackerConfig[key]) {
      errors[key] = `Value required`;
    }
  }

  return errors;
};
