import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import NotificationBadge from './NotificationBadge';
import { FormattedMessage } from 'react-intl';
import { Text, CircleIcon, SustainabilityTheme } from 'rio-ui-components';

type Theme = typeof SustainabilityTheme;

interface SideBarProps {
  route: string;
  messageId: string;
  icon: string | string[];
  id: string;
  name: string;
  circleColor: string;
  collapsed: boolean;
  externalLink?: boolean;
  notificationCount?: number;
  exact?: boolean;
}

type LinkContainerProps = Partial<SideBarProps> & {
  theme: Theme;
};

const styles = {
  circleIcon: {
    height: '32px',
    color: 'white',
    size: 'md',
  },
  Icon: {
    size: '24px',
  },
};

const LinkContainer = styled.div`
  ${({ theme, collapsed }: LinkContainerProps) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${collapsed ? 'center' : 'left'};
    padding: ${
      collapsed ? `${theme.geometry.xs.spacing} 0` : `${theme.geometry.xs.spacing} ${theme.geometry.sm.spacing}`
    };
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `};
`;

const LinkContainerDisabled = styled.div`
  ${({ theme, collapsed }: LinkContainerProps) => `
    display: flex;
    opacity: 0.5; 
    flex-direction: row;
    align-items: center;
    justify-content: ${collapsed ? 'center' : 'left'};
    padding: ${
      collapsed ? `${theme.geometry.xs.spacing} 0` : `${theme.geometry.xs.spacing} ${theme.geometry.sm.spacing}`
    };
  `};
`;

const NavLinkStyled = styled(NavLink)`
  &.active > div {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const LinkTextStyled = styled(Text)`
  color: white;
  padding-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ComingSoonStyled = styled.div`
  opacity: 0.7;
  display: inline-block;
  background: ${(props) => props.theme.colors.basic.white};
  padding: ${(props) => props.theme.geometry.xxs.spacing} ${(props) => props.theme.geometry.xs.spacing};
  margin-left: ${(props) => props.theme.geometry.xs.spacing};
`;

const LinkTextHolder = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  position: relative;
  font-size: ${styles.Icon.size};
  width: ${styles.circleIcon.height};
`;

const LinkInners = (props: SideBarProps) => {
  const { icon: iconText, messageId, id, name, circleColor, collapsed, ...rest } = props;

  return (
    <LinkContainer id={`${id}-LinkContainer`} collapsed={collapsed} {...rest}>
      <IconContainer>
        {!!props.notificationCount && <NotificationBadge count={props.notificationCount} />}
        <CircleIcon
          height={styles.circleIcon.height}
          circleColor={circleColor}
          iconColor={styles.circleIcon.color}
          size={styles.circleIcon.size}
          icon={iconText}
          id={`${id}-CircleIcon`}
          name={`${name}-CircleIcon`}
        />
      </IconContainer>
      {!collapsed && (
        <LinkTextHolder>
          <LinkTextStyled inline align="center" id={`${id}-LinkTextStyled`} name={`${name}-LinkTextStyled`}>
            <FormattedMessage id={messageId} />
          </LinkTextStyled>
        </LinkTextHolder>
      )}
    </LinkContainer>
  );
};

export const SideBarItem = (props: SideBarProps) => {
  const { route: routeText, id, externalLink, exact, ...rest } = props;

  if (externalLink) {
    return (
      <a href={routeText} target="_blank" rel="noopener noreferrer" id={`${id}-NavLinkStyled`} {...rest}>
        <LinkInners {...props} />
      </a>
    );
  }

  return (
    <NavLinkStyled end={exact} to={routeText} id={`${id}-NavLinkStyled`}>
      <LinkInners {...props} />
    </NavLinkStyled>
  );
};

export const SideBarItemComingSoon = (props: SideBarProps) => {
  const { icon: iconText, messageId, id, name, circleColor, collapsed } = props;
  return (
    <LinkContainerDisabled id={`${id}-LinkContainer`} name={`${name}-LinkContainer`} collapsed={collapsed}>
      <IconContainer>
        <CircleIcon
          height={styles.circleIcon.height}
          circleColor={circleColor}
          iconColor={styles.circleIcon.color}
          size={styles.circleIcon.size}
          icon={iconText}
          id={`${id}-CircleIcon`}
          name={`${name}-CircleIcon`}
        />
      </IconContainer>
      {!collapsed && (
        <LinkTextHolder>
          <LinkTextHolder>
            <LinkTextStyled inline align="center" id={`${id}-LinkTextStyled`} name={`${name}-LinkTextStyled`}>
              <FormattedMessage id={messageId} />
            </LinkTextStyled>
          </LinkTextHolder>
          <ComingSoonStyled>
            <Text size="xs" color="primary">
              Coming soon
            </Text>
          </ComingSoonStyled>
        </LinkTextHolder>
      )}
    </LinkContainerDisabled>
  );
};
