import { graphql } from '@apollo/client/react/hoc';
import { GET_TRANSPOSITIONS_BY_ACC_ID } from './index.queries';

export const withTranspositionsGotByAccountId = (component) => {
  const getTranspositionsResult = graphql(GET_TRANSPOSITIONS_BY_ACC_ID, {
    options: (ownProps) => {
      return {
        variables: {
          accId: ownProps.accountId,
          type: ownProps.type
        },
        fetchPolicy: 'network-only'
      };
    },
    props: ({ ownProps, data }) => {
      if (data.loading) return { loading: true };
      if (data.error) return { error: data.error };
      return {
        ...ownProps,
        refetch: data.refetch,
        transpositions: data.getTranspositionsByAccountId
      };
    }
  });

  return getTranspositionsResult(component);
};
