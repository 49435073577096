import { useState, useCallback, useEffect } from 'react';
import { DashboardExperience } from 'amazon-quicksight-embedding-sdk';
import {
  Page,
  Grid,
  styled,
  Dialog,
  LinearProgress,
  Icons,
  IconButton,
  PreLoader,
  LoadingTextTheme,
} from '@rio/ui-components';
import { useParams } from 'react-router-dom';
import { useGetSubscribedDashboards } from '~/hooks/useGetSubscribedDashboards';
import { DashboardFrame } from './DashboardFrame';
import { ReportingNavigation } from './ReportingNavigation';
import { ReportingOverview } from './ReportingOverview';

const Container = styled('div')`
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const ColContainer = styled('div')`
  min-height: 100vh;
  width: 100%;
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled('div')`
  height: 80vh;
  margin-left: 24px;
  overflow: scroll;
`;

const FullScreenContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StaticDashboard = styled('img')`
  max-width: 100%;
`;

const ButtonGrid = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const LinearProgressStyled = styled(LinearProgress)`
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  transform: translateY(-150%);
`;

const CLEAN_SCORE_PNG_SRC = 'https://dashboard-thumbnails-dev.s3.eu-west-1.amazonaws.com/clean-score.png';

type Actions = {
  resetAction: () => void;
  printAction: () => void;
  expandAction?: () => void;
  closeAction?: () => void;
};

const ReportingActionButtons = ({ resetAction, printAction, expandAction, closeAction }: Actions) => {
  return (
    <ButtonGrid wrap="wrap" container xs={12}>
      <IconButton variant="filled" color="secondary" onClick={() => resetAction()} title="Reset Filters">
        <Icons.LoopRounded />
      </IconButton>
      <IconButton variant="filled" onClick={() => printAction()} title="Print">
        <Icons.PrintRounded />
      </IconButton>
      {expandAction && (
        <IconButton variant="filled" onClick={() => expandAction()} title="Full Screen">
          <Icons.Fullscreen />
        </IconButton>
      )}
      {closeAction && (
        <IconButton variant="filled" onClick={() => closeAction()} title="Exit Full Screen">
          <Icons.FullscreenExit />
        </IconButton>
      )}
    </ButtonGrid>
  );
};

export default function ReportingContainer() {
  const [dashboardData, loading, error] = useGetSubscribedDashboards();
  const [dashFrame, setDashFrame] = useState<DashboardExperience>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);

  const { id, name } = useParams();

  useEffect(() => {
    setIsContentReady(false);
  }, [id]);

  const isCleanScore = name === 'CleanScore';

  const resetDashboard = useCallback(() => {
    dashFrame?.reset();
  }, [dashFrame]);

  const printDashboard = useCallback(() => {
    dashFrame?.initiatePrint();
  }, [dashFrame]);

  return (
    <Container>
      <Page
        loading={loading}
        error={error}
        title={{
          content: name ? `Your ${name} report` : 'Overview',
          crumbs: [
            {
              label: 'Reporting',
              to: '../../reporting',
            },
            {
              label: name ? name! : 'Overview',
            },
          ],
          actionButton: (
            <ReportingActionButtons
              resetAction={resetDashboard}
              printAction={printDashboard}
              expandAction={() => setDialogOpen(!dialogOpen)}
            />
          ),
        }}
      >
        <PageContainer>
          {dashboardData?.getSubscribedDashboards.length > 0 && (
            <>
              <ReportingNavigation dashboards={dashboardData?.getSubscribedDashboards || []} />

              {id && (
                <ColContainer>
                  {!isContentReady && !isCleanScore && (
                    <PreLoader themes={[LoadingTextTheme.REPORTING, LoadingTextTheme.GENERAL]} />
                  )}

                  {!isCleanScore && (
                    <DashboardFrame
                      dashboardId={id}
                      setDashFrame={setDashFrame}
                      setIsContentReady={setIsContentReady}
                      id="quicksightFrameInPage"
                    />
                  )}
                  {isCleanScore && (
                    <ImageContainer>
                      <StaticDashboard src={CLEAN_SCORE_PNG_SRC} />
                    </ImageContainer>
                  )}
                </ColContainer>
              )}
            </>
          )}
          {!id && !isCleanScore && <ReportingOverview />}
          <Dialog open={dialogOpen}>
            <ReportingActionButtons
              resetAction={resetDashboard}
              printAction={printDashboard}
              closeAction={() => setDialogOpen(!dialogOpen)}
            />
            {loading && <LinearProgressStyled />}
            <FullScreenContainer>
              {id && !isCleanScore && (
                <DashboardFrame
                  dashboardId={id}
                  setDashFrame={setDashFrame}
                  setIsContentReady={setIsContentReady}
                  id="quicksightFrameFullscreen"
                />
              )}
              {isCleanScore && (
                <ImageContainer>
                  <StaticDashboard src={CLEAN_SCORE_PNG_SRC} />
                </ImageContainer>
              )}
            </FullScreenContainer>
          </Dialog>
        </PageContainer>
      </Page>
    </Container>
  );
}
