import { useQuery } from '@apollo/client';
import { OptionsProps } from '@rio/ui-components';
import { Nullable, Option } from '~/types';
import { useCurrentAccountId } from '~/hooks';
import { mapLocationToOption } from '../utils';
import { GET_WASTE_OUT_LOCATIONS_BY_ACCOUNT_ID } from '../index.queries';
import { BaseLocationSelect, order } from './BaseLocationSelect';

interface WasteOutLocationSelectProps {
  value?: OptionsProps;
  name?: string;
  onChange: (e: OptionsProps) => void;
  error?: Nullable<Error | string>;
  label?: string;
  createNew?: boolean;
  createNewAccessor?: string;
}

export const WasteOutLocationSelect = ({
  value,
  name,
  onChange,
  error,
  label,
  createNew,
  createNewAccessor,
}: WasteOutLocationSelectProps) => {
  const accountId = useCurrentAccountId();

  const { data, refetch } = useQuery(GET_WASTE_OUT_LOCATIONS_BY_ACCOUNT_ID, {
    variables: {
      id: accountId,
      sort: order,
      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: '',
            field: 'name',
          },
        ],
      },
    },
  });

  return (
    <BaseLocationSelect
      value={value}
      name={name}
      locationPoints={{ wasteOut: true }}
      onChange={onChange}
      accountId={accountId}
      error={error}
      label={label}
      createNew={createNew}
      accessor={createNewAccessor}
      loadOptions={async () => {
        const options = data?.getWasteOutsByAccountId
          ? (data.getWasteOutsByAccountId.map(mapLocationToOption) as Option[])
          : [];
        return { options };
      }}
      refetch={refetch}
    />
  );
};
