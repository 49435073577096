import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TransactionType } from '@rio/rio-types';
import { Heading, Row, Button, Text, Modal, LoadingIndicator } from 'rio-ui-components';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import PageHeader from '../../../components/PageHeader';
import { LoadFailed } from '../../../components/Errors';
import { useCurrentAccountId, useNotification } from '../../../hooks';
import { usePersistToStorage } from '../../../hooks';
import { UPDATE_IMPORT_STATUS } from './index.queries';
import { AliasMatcher } from './AliasMatcher';
import { CREATE_ALIASES } from './index.queries';
import { useValuesToAlias } from './useValuesToAlias';
import { Alias } from './types';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ButtonStyled = styled(Button)`
  flex: 0 0 auto;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const ContainerStyled = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: auto;
`;
const HeaderContainer = styled(Row)`
  padding: ${(p) => p.theme.geometry.md.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;
const StyledRow = styled(Row)`
  flex: 0 0 auto;
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledModalContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButtonRow = styled(Row)`
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;
const RowsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  padding: ${(p) => p.theme.geometry.sm.spacing};
`;
const StyledLoadingContainer = styled(LoadingIndicator)`
  margin: 10px auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const StyledText = styled(Text)`
  margin: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

export const AliasContainer = () => {
  const [aliases, setAliases] = useState<Alias[]>([]);
  const { subject, id: batchId } = useParams();
  const accountId = useCurrentAccountId();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [{ batch, valuesToAlias }, { loading, error, refetch }] = useValuesToAlias(
    accountId,
    batchId!,
    subject?.toUpperCase() as TransactionType
  );
  const aliasesStorageKey = useMemo(
    () => `${accountId}-${batchId}-${subject?.toUpperCase()}`,
    [accountId, batchId, subject]
  );
  const { clearStorageItem } = usePersistToStorage(aliasesStorageKey, aliases, setAliases);

  const [updateImportStatus] = useMutation(UPDATE_IMPORT_STATUS);
  const { showNotification } = useNotification();
  const onCompleted = async () => {
    await updateImportStatus({ variables: { id: batchId } });
    navigate(`/data/${subject}/uploads`);
    clearStorageItem();
  };
  const onError = (err: Error) => {
    showNotification(err.message, 'danger');
  };
  const [createAliases] = useMutation(CREATE_ALIASES, {
    onCompleted,
    onError,
  });

  const onCancelClick = () => {
    navigate(`/data/${subject}/uploads`);
  };

  const onConfirmClick = () => {
    if (!!aliases.length) {
      createAliases({ variables: { input: aliases.map((a) => omit(a, 'parentName')) } });
    } else {
      onCompleted();
    }
  };
  const subjectLabel = capitalize(subject);
  const submitDisabled = valuesToAlias.length > 0 && aliases.length < valuesToAlias.length;
  const transactionType: TransactionType = subject?.toUpperCase() as TransactionType;

  const confirmModalHeaderText = valuesToAlias.length ? 'Confirm Aliases' : 'Re-Submit Batch';
  const confirmModalBodyText = valuesToAlias.length ? 'Are you sure these Aliases are correct?' : '';

  return (
    <OuterContainer>
      {showConfirmationModal && (
        <Modal onDismiss={() => setShowConfirmationModal(false)} show height="auto" maxHeight="90vh">
          <StyledModalContainer>
            <StyledButtonRow item>
              <Heading>{confirmModalHeaderText}</Heading>
            </StyledButtonRow>
            <StyledButtonRow item>
              <Text>{confirmModalBodyText}</Text>
            </StyledButtonRow>
            <StyledButtonRow container distribution="around">
              <StyledButton color="info" onClick={() => setShowConfirmationModal(false)}>
                Cancel
              </StyledButton>
              <StyledButton onClick={onConfirmClick}>Confirm</StyledButton>
            </StyledButtonRow>
          </StyledModalContainer>
        </Modal>
      )}
      <PageHeader
        name="back"
        title={`${subjectLabel} Upload Aliases`}
        breadcrumbs={[
          { title: 'Data', to: '/data' },
          { title: subjectLabel, to: `/data/${subject}` },
          { title: 'Uploads', to: `/data/${subject}/uploads` },
          { title: 'Aliases' },
        ]}
        icon="chart-line"
        iconColor="quaternary"
      >
        <Button inline component="routerLink" color="info" to={`/data/${subject}/uploads`}>
          Back to Uploads
        </Button>
      </PageHeader>

      <ContentContainer>
        <ContainerStyled>
          <HeaderContainer container distribution="between" itemAlign="center">
            <Text>
              To help keep your data consistent we need to match some unrecognized entries to existing types. Please
              review each item and either match it to an existing type, or create a new type.
            </Text>
          </HeaderContainer>
          {loading && (
            <RowsContainer>
              <StyledLoadingContainer />
            </RowsContainer>
          )}
          {error && <LoadFailed error={error} retry={refetch} />}
          {batch && !loading && !error && !!valuesToAlias.length && (
            <AliasMatcher
              accountId={accountId}
              aliases={aliases}
              transactionType={transactionType}
              setAliases={setAliases}
              valuesToAlias={valuesToAlias}
              batch={batch}
            />
          )}
          {batch && !loading && !error && !valuesToAlias.length && (
            <StyledText weight="bold">All items has been aliased</StyledText>
          )}
          <StyledRow itemAlign="center" distribution="end" vdistributon="center" container>
            <ButtonStyled inline color="info" onClick={onCancelClick}>
              Cancel
            </ButtonStyled>
            <ButtonStyled
              inline
              disabled={submitDisabled}
              onClick={() => {
                setShowConfirmationModal(true);
              }}
            >
              Confirm
            </ButtonStyled>
          </StyledRow>
        </ContainerStyled>
      </ContentContainer>
    </OuterContainer>
  );
};
