import { useCallback } from 'react';
import { Card } from '@rio/ui-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useCurrentAccountId } from '~/hooks';
import {
  StatusSpan,
  getProgress,
  SurveyInfoStatus,
  isReceivedSurvey,
  SurveyOverflowMenu,
  formatSubmissionStatus,
  isSubmissionReviewable,
} from '~/components/Surveys';
import { EmptySurveys } from '../../SurveyOverviewContainer/SurveyOverviewV2';
import { EllipsisMenu, ListSurvey } from '../../SurveysContainer/styles';
import { useGetMyContributor } from '../queries';

export const MyContributorContainer = () => {
  const navigate = useNavigate();
  const accountId = useCurrentAccountId();
  const { contributorId } = useParams();

  const [contributorAccountId, contributorUserId] = contributorId!.split(':');
  const { data, loading } = useGetMyContributor(accountId, contributorAccountId!, contributorUserId);
  const contributorsList = data?.getMySurveyContributorById?.surveys || [];

  const handleSurveyClick = useCallback(
    (c, reviewable) => {
      if (reviewable && c.submission) {
        return navigate(`./submission/${c.submission.id}`);
      }
    },
    [navigate]
  );

  const emptySurveys = loading ? null : <EmptySurveys />;

  return (
    <>
      {contributorsList.length ? (
        <ListSurvey>
          {contributorsList?.map((c) => {
            const reviewable = isSubmissionReviewable(c.submission?.status);

            return (
              <Card
                key={c.id}
                subContent={<SurveyInfoStatus deadlineDate={c.deadlineDate} fill />}
                onClick={() => handleSurveyClick(c, reviewable)}
                title={c.name}
              >
                <EllipsisMenu>
                  {isReceivedSurvey(c) && (
                    <StatusSpan status={c.submission?.status}>
                      {formatSubmissionStatus(c.submission?.status, getProgress(c.template, c.submission?.[0]))}
                    </StatusSpan>
                  )}
                  <SurveyOverflowMenu survey={c} />
                </EllipsisMenu>
              </Card>
            );
          })}
        </ListSurvey>
      ) : (
        emptySurveys
      )}
    </>
  );
};
