import { useCallback } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { useTransportGridConfig } from '../TransportContainer/TransportTransactions/useTransportGridConfig';
import { GET_TRANSPORT_TRANSACTION_PAGE } from '../TransportContainer/TransportTransactions/index.queries';
import { UploadReferenceCell, DocumentReferenceV2 as DocumentReference } from '../CellRenderers/index';
import { usePageSuspendingLazyQuery } from '~/hooks';

const breadcrumbs = [{ label: 'Data', to: '..' }, { label: 'Transport' }];

export const TransportTransactionsGrid = () => {
  const config = useTransportGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_TRANSPORT_TRANSACTION_PAGE, {
    fetchPolicy: 'network-only',
  });

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getTransportTransactionPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <>
      <TransactionGrid
        title="Your transport data"
        defaultExportFileName="transport-export"
        breadcrumbs={breadcrumbs}
        uploadLink="../transport/uploads"
        config={{
          ...config,
          components: {
            uploadReference: UploadReferenceCell,
            documentReference: DocumentReference,
          },
        }}
        fetchRows={fetchRows}
      />
    </>
  );
};
