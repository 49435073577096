import { ApolloProvider } from '@apollo/client';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { SustainabilityTheme } from 'rio-ui-components';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as V2ThemeProvider } from '@rio/ui-components';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { IntlProvider } from 'react-intl';
import apolloClient from '../apollo-client';
import { ExportProvider } from '../components/ExportProvider';
import HubspotTracker from '../components/HubspotTracker';
import { NotificationProvider, useCurrentAccountId } from '../hooks';
import { ANCITEL_ACCOUNT_LIVE, COREVE_ACCOUNT_LIVE } from '../constants';
import { messages } from '../locales';
import { getLocale, flattenMessages } from '../utils';
import TitleProvider from '~/components/TitleProvider';
import { SurveyProvider } from '~/components/SurveyProvider';

const Intl = ({ children }) => {
  const locale = getLocale();
  const accountId = useCurrentAccountId();
  const currentLocale: any = ANCITEL_ACCOUNT_LIVE === accountId || COREVE_ACCOUNT_LIVE === accountId ? 'it-IT' : locale;
  const localeMessages = messages[currentLocale as keyof typeof messages] ? messages[currentLocale] : messages['en-US'];
  const localeOrDefault: any = messages[currentLocale] ? locale : 'en-US';

  return (
    <IntlProvider locale={localeOrDefault} key={localeOrDefault} messages={flattenMessages(localeMessages)}>
      {children}
    </IntlProvider>
  );
};

export const AllProviders = () => {
  return (
    <ThemeProvider theme={SustainabilityTheme}>
      <V2ThemeProvider>
        <ApolloProvider client={apolloClient}>
          <Intl>
            <NotificationProvider>
              <ExportProvider>
                <SurveyProvider>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <TitleProvider>
                      <HubspotTracker>
                        <Outlet /> {/* Children rendered instead of this */}
                        <ScrollRestoration />
                      </HubspotTracker>
                    </TitleProvider>
                  </QueryParamProvider>
                </SurveyProvider>
              </ExportProvider>
            </NotificationProvider>
          </Intl>
        </ApolloProvider>
      </V2ThemeProvider>
    </ThemeProvider>
  );
};
