import { gql } from '@apollo/client';
import { ASPECT_FIELDS } from '../../fragments/AspectFields.fragment.js';

export const GET_ASPECTS_BY_ACCOUNT_ID = gql`
  query GetAspectsByAccountId($accountId: ID!) {
    getAspectsByAccountId(accountId: $accountId) {
      totalRows
      rows {
        ...AspectFields
      }
    }
  }
  ${ASPECT_FIELDS}
`;
