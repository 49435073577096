import React from 'react';
import { Icons, Text, styled } from '@rio/ui-components';

const ContainerStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface FileCardProps {
  fileName: string;
}

export function FileCard({ fileName }: FileCardProps) {
  return (
    <ContainerStyled>
      <Icons.InsertDriveFile />
      <Text typescale="title" size="medium">
        {fileName}
      </Text>
    </ContainerStyled>
  );
}
