import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { InputEvent } from '~/types';
import { isNumeric, isNumericWithNegativeOption } from '~/utils';
import { TextFieldStyled } from '../../WasteDataForm/style';

interface NumberInputProps {
  onChange?: (value: string | undefined, event: InputEvent) => void;
  trigger?: () => void;
  type?: string;
  value?: number;
  label?: string;
  withNegative?: boolean;
  [key: string]: unknown;
}

export const NumberInput = (props: NumberInputProps) => {
  const {
    onChange = () => {},
    trigger = () => {},
    type = 'text',
    value = '',
    label,
    withNegative = false,
    ...others
  } = props;

  const handleOnChange = useCallback(
    (e: InputEvent) => {
      const {
        target: { value: currentValue },
      } = e;

      const validator = withNegative ? isNumericWithNegativeOption : isNumeric;

      if (isEmpty(currentValue)) {
        onChange(undefined, e);
        trigger();

        return;
      }

      const isValid = validator(currentValue) ? currentValue : null;

      if (isValid) {
        onChange(currentValue, e);
        trigger();

        return;
      }

      e.preventDefault();
    },
    [onChange, trigger, withNegative]
  );

  return <TextFieldStyled {...others} onChange={handleOnChange} value={String(value)} label={label} type={type} />;
};

export const NumberInputWithNegativeOption = (props) => {
  return <NumberInput {...props} withNegative />;
};
