import { useEffect } from 'react';
import { useGetAccountProductLicencesCountLazyQuery } from './useGetAccountProductLicencesCountLazyQuery';

export function useGetAccountProductLicencesCountQuery(accountId) {
  const [fetch, payload] = useGetAccountProductLicencesCountLazyQuery();

  useEffect(() => {
    accountId && fetch(accountId);
    // eslint-disable-next-line
  }, []);

  return payload;
}
