import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from 'rio-ui-components';
import { tileCss } from './css';
import { DetailsModal } from './DetailsModal';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useSavePolicyWithDocuments } from './useSavePolicyWithDocuments';
import { createEmptyPolicy } from './utils';

const Button = styled.button`
  ${tileCss}
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
`;

const IconStyled = styled(Icon)`
  margin-bottom: 16px;
`;

export function AddPolicyButton({ showNotification }) {
  const accountId = useCurrentAccountId();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const [policy, setPolicy] = useState(createEmptyPolicy(accountId));
  const savePolicyWithDocuments = useSavePolicyWithDocuments();
  return (
    <Button onClick={() => setShowCreateModal(true)}>
      <IconStyled icon="plus-circle" size="6x" color={theme.colors.success.normal.background} />
      Add new
      <DetailsModal
        title="Create Policy"
        submitTitle="Create Policy"
        show={showCreateModal}
        isSubmitting={isSubmitting}
        policy={policy}
        onDismiss={() => {
          setTimeout(() => setShowCreateModal(false), 0);
        }}
        onChange={setPolicy}
        onSubmit={async (policyToSubmit) => {
          setIsSubmitting(true);
          try {
            await savePolicyWithDocuments(policyToSubmit);
            showNotification({
              message: 'Your policy has been created!',
              color: 'success'
            });
          } catch (err) {
            showNotification({
              message: 'Something went wrong. Try again later.',
              color: 'danger'
            });
          } finally {
            setIsSubmitting(false);
            setShowCreateModal(false);
            setPolicy(createEmptyPolicy(accountId));
          }
        }}
      />
    </Button>
  );
}
