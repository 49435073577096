import { styled, Button, Text, Grid } from '@rio/ui-components';

export const SectionStyled = styled(Grid)`
  display: flex;
  gap: 24px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const GridStyed = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};
`;

export const WrapperStyled = styled('div')`
  padding: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.sys.color.outlineVariant};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 24px;
    min-height: 460px;
  }
`;

export const TitleStyles = styled(Text)``.withComponent('h2');

export const BlockStyled = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const RadialChartWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;

  rect {
    fill: none;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 10px 16px 10px 12px;
  position: absolute;
  top: 68px;
  right: 32px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

export const ChartPieWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const BoxWrapperStyled = styled('div')`
  width: 100%;
  overflow-x: auto;
`;

export const ContainerStyled = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  padding: 2px;
  margin-bottom: 12px;
`;

export const ToolsContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 2px;
`;
