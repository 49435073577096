import { useLocation } from 'react-router-dom';
import { NavigationDrawer } from '@rio/ui-components';
import { styled } from '@mui/material-next/styles';

import { Dashboard } from '@rio/rio-types';
import { alphabetiseByName } from '~/utils';
import { useMemo } from 'react';

const Container = styled('div')`
  width: 100%;
  max-width: 260px;
  height: max-content;
  overflow: auto;
  margin-right: 24px;
`;

type ReportingNavigationProps = {
  dashboards: Dashboard[];
};

export const ReportingNavigation = ({ dashboards }: ReportingNavigationProps) => {
  const location = useLocation();
  const { pathname } = location;

  const links = useMemo(() => {
    const sortedDashboards = [...dashboards].sort(alphabetiseByName);
    const externalDashboards = sortedDashboards.map((dash) => {
      const encodedName = encodeURIComponent(dash.name);
      return {
        to: `/v2/reporting/${encodedName}/${dash.id}`,
        item: dash.name,
        isActive: pathname.includes(`/${encodedName}/`),
      };
    });
    const overview = {
      to: '/v2/reporting/overview',
      item: 'Overview',
      isActive: pathname === '/v2/reporting/overview',
    };
    return [overview, ...externalDashboards];
  }, [dashboards, pathname]);

  return (
    <Container>
      <NavigationDrawer items={links} />
    </Container>
  );
};
