import styled from 'styled-components';
import { TextInput, Icon as RioIcon, LoadingIndicator } from 'rio-ui-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: visible;
  background-color: #f8f8f8;

  > div {
    margin: 0 auto;
    max-width: 900px;
    background-color: #f8f8f8;
    padding: 0 0 60px;
  }

  & h4 {
    font-size: 22px;
    margin-bottom: 40px;
  }

  & b {
    font-weight: bold;
  }
`;

export const Box = styled.div`
  padding: 35px;
  border: 1px solid #ccc;
  background-color: #fcfcfc;
  border-radius: 5px;
  margin-bottom: 25px;
`;

export const ServiceProviderBox = styled.div`
  margin-top: 35px;
  padding-left: 35px;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 25px;
  background: rgb(59, 134, 255);
  background: linear-gradient(72deg, rgba(59, 134, 255, 1) 0%, rgba(0, 186, 255, 1) 100%);
  color: #fff;

  > div {
    height: 100%;
  }

  h3 {
    font-size: 22px;
    padding-left: 25px;
  }

  p {
    padding-left: 25px;
  }

  small {
    font-size: 12px;
  }
`;

export const Error = styled.div`
  text-align: center;
  color: #ff909a;
  margin-bottom: 30px;
`;

export const PaymentMethodList = styled.ul`
  display: flex;
`;

export const PaymentMethod = styled.li`
  min-height: 100px;
  text-align:center;
  border-radius: 5px;
  list-style: none;
  border: ${({ isSelected }) => (isSelected ? '2px solid #00c8b5;' : '1px solid #ccc;')} 
  background-color: #fff;
  padding: 20px 30px;
  
  h3 {
    font-size: 120px;
  }
`;

export const InputHolder = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  height: 41px;
  padding: 11px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: stretch;

  & > {
    height: 100%;
  }
`;

export const Input = styled(TextInput)`
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;

  > div {
    border: none;
  }

  input {
    border: none;
    border-radius: 0;
    background-color: #fff;
    height: 39px;
    padding: 9px;
    outline-color: #fff;
    outline-offset: 0;
    font-size: 16px;
    color: #000;
  }
`;

export const Icon = styled(RioIcon)`
  margin-bottom: 10px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`;
export const Loader = styled(LoadingIndicator)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -82px;
  margin-left: -82px;
`;

export const PaymentProcessingMessage = styled.div`
  text-align: center;

  svg {
    color: ${(props) => props.theme.colors.gradient.green.normal.background};
  }

  p {
    font-size: 18px;
  }

  a {
    color: rgba(59, 134, 255, 1);
    text-decoration: underline;
  }
`;

export const PaymentProcessingErrorMessage = styled.div`
  text-align: center;

  svg {
    color: ${(props) => props.theme.colors.danger.normal.background};
  }

  p {
    font-size: 18px;
  }

  a {
    color: rgba(59, 134, 255, 1);
    text-decoration: underline;
  }
`;

export const TermsAndConditionMessage = styled.div`
   text-align: center;
   padding: 25px 0 0;
   
   a {
    color: ${(props) => props.theme.colors.primary.light.text};
    text-decoration: underline;
   }
`;
