import { styled } from '@rio/ui-components';
import { ReceivedSurvey, Survey } from '@rio/rio-types';
import { Nullable } from '~/types';
import { getStatus } from './utils';
import { DueDate } from './DueDate';
import { SurveyOverflowMenu } from './SurveyOverflowMenu';

export interface DeadlineDateProps {
  deadlineDate?: Nullable<Date>;
  survey?: ReceivedSurvey | Survey;
  fill?: boolean;
  newSubscriptionUpdate?: boolean;
  showAutoSave?: boolean;
}

export interface StatusLabelProps {
  color: string;
  fontWeight: string;
}

export interface StatusProps {
  fill?: boolean;
}

const Status = styled('div')<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusLabel = styled('div')<StatusLabelProps>`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: 14px;
  line-height: 20px;
`;

const StatusDot = styled('div')`
  color: ${(props) => props.color};
`;

const SurveyOverflowMenuContainer = styled('div')`
  height: 38px;
  width: 20px;
`;

export const SurveyInfoStatus = ({ deadlineDate, survey }: DeadlineDateProps) => {
  const { color, isOverDue, fontWeight } = getStatus(deadlineDate);
  return (
    <Status>
      {deadlineDate && (
        <StatusLabel color={color} fontWeight={fontWeight}>
          {isOverDue ? 'Closed' : 'Open'}
        </StatusLabel>
      )}
      {deadlineDate && <StatusDot color={color}> &nbsp;•&nbsp; </StatusDot>}
      {deadlineDate && <DueDate deadlineDate={deadlineDate} color={color} fontWeight={fontWeight} />}
      <SurveyOverflowMenuContainer>{survey && <SurveyOverflowMenu survey={survey} />}</SurveyOverflowMenuContainer>
    </Status>
  );
};
