import styled from 'styled-components';
import { Text, PropertySheet, Row, Heading } from 'rio-ui-components';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const StyledPropRow = styled(Row)`
  justify-content: center;
`;

const TextStyled = styled(Text)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

export interface ConfirmActionProps {
  headingText: string;
  bodyText: string;
  name?: string;
  onConfirmAction: () => void;
  onCancelAction: () => void;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
}

export const ConfirmAction = ({
  headingText,
  bodyText,
  onConfirmAction,
  onCancelAction,
  name = 'confirmAction',
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel'
}: ConfirmActionProps) => {
  return (
    <Container id={`${name}__container`}>
      <PropertySheet>
        <Heading id={`${name}__text--heading`} name={`${name}__text--heading`} align="center" size="lg">
          {headingText}
        </Heading>
        <StyledPropRow container item>
          <TextStyled id={`${name}__text--body`} name={`${name}__text--body`} align="center">
            {bodyText}
          </TextStyled>
        </StyledPropRow>
        <PropertySheet.Row>
          <PropertySheet.ButtonColumn
            span={6}
            color="danger"
            id={`${name}__button--confirm`}
            name={`${name}__button--confirm`}
            onClick={() => onConfirmAction()}
          >
            {confirmButtonTitle}
          </PropertySheet.ButtonColumn>
          <PropertySheet.ButtonColumn
            span={6}
            color="primary"
            id={`${name}__button--cancel`}
            name={`${name}__button--cancel`}
            onClick={() => onCancelAction()}
          >
            {cancelButtonTitle}
          </PropertySheet.ButtonColumn>
        </PropertySheet.Row>
      </PropertySheet>
    </Container>
  );
};
