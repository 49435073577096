import React from 'react';
import { Select } from 'rio-ui-components';
import _ from 'lodash';
import { useGetTargetsByAccountId } from '~/hooks';

export function TargetsSelect({ value, error, name, onChange }) {
  const { targets } = useGetTargetsByAccountId();
  const options = _(targets)
    .map((l) => ({
      value: l.id,
      label: l.name,
    }))
    .orderBy(['label'], ['asc']);

  return (
    <Select
      name={name}
      placeholder="Please select the target"
      value={value}
      options={options}
      error={error}
      onChange={onChange}
      classPrefix="targets-select"
    />
  );
}
