import styled from 'styled-components';
import { Col, CircleIcon, Heading } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-right: 16px;
`;
const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.geometry.xs.spacing} 0;
`;
const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.basic.white};
`;
const IconContainer = styled.div`
  padding: 0 ${(props) => props.theme.geometry.sm.spacing};
`;

export function FolderGridTile({ folder }) {
  return (
    <ContainerStyled disabled={folder.disabled} container item>
      <CenteredContainer>
        <IconContainer>
          <CircleIconStyled
            height="40px"
            size="md"
            icon={folder.icon.name}
            iconColor={folder.icon.color}
            circleColor={folder.icon.color}
          />
        </IconContainer>
        <Heading size="lg">{folder.name}</Heading>
      </CenteredContainer>
    </ContainerStyled>
  );
}
