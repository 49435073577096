import styled from 'styled-components';
import { ReceivedSurvey, Survey } from '@rio/rio-types';
import { SurveysPlaceholder } from '../SurveysPlaceholder';
import { SurveyTile, TileContainer } from './SurveyTile';
import { calculateResponseRate, isReceivedSurvey } from './utils';

interface SurveysGridProps {
  onSurveyClick: (id: string) => void;
  surveys: {
    rows: ReceivedSurvey[] | Survey[];
    totalRows: number;
  };
}

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

export const SurveysGrid = ({ surveys: { rows: surveys, totalRows }, onSurveyClick }: SurveysGridProps) => {
  if (!totalRows) {
    return (
      <SurveysPlaceholder
        title="You don't have any surveys yet"
        description="Surveys are a quick way to collect data and insights"
        icon={['rio', 'clipboard-check-custom']}
      />
    );
  }

  return (
    <ContentContainer>
      <TileContainer>
        {surveys.map((survey) => (
          <SurveyTile
            key={survey.id}
            onClick={onSurveyClick}
            survey={survey}
            subtitle={
              isReceivedSurvey(survey) ? `From ${survey.owner.name}` : `${calculateResponseRate(survey)}% response rate`
            }
          />
        ))}
      </TileContainer>
    </ContentContainer>
  );
};
