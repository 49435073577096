import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { ContentEditor } from 'rio-ui-components';
import { OpenEndedRichQuestion } from '@rio/rio-types';
import { QuestionText } from './Elements';
import styled from 'styled-components';

export const OpenEndedRichQuestionWrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
  .editor-class {
    padding: 10px !important;
  }
  .wrapper-class {
    width: 100%;
  }
`;

interface OpenEndedRichQuestionFieldProps {
  question: OpenEndedRichQuestion;
  onAnswer: (question: OpenEndedRichQuestion) => void;
}

export function OpenEndedRichQuestionField({ question, onAnswer }: OpenEndedRichQuestionFieldProps) {
  const saveAnswer = useCallback(
    (value: string) => {
      onAnswer({
        ...question,
        reportedText: value || null
      });
    },
    [question, onAnswer]
  );

  const handleChange = useMemo(() => debounce(saveAnswer, 3000), [saveAnswer]);

  const shouldShowFormulation = useCallback(() => {
    if (!question?.displaySettings) {
      return true;
    }
    if (question?.displaySettings?.showFormulation) {
      return true;
    }
    return false;
  }, [question]);

  return (
    <OpenEndedRichQuestionWrapper>
      {shouldShowFormulation() && <QuestionText source={question.formulation} />}
      <ContentEditor
        fullWidth
        name={question.id}
        id={question.id}
        placeholder={question.shortFormulation || question.formulation || 'Enter your value'}
        label={question.shortFormulation || question.formulation}
        sourceContent={question.reportedText || ''}
        onMarkdownChange={handleChange}
      />
    </OpenEndedRichQuestionWrapper>
  );
}