import { gql } from '@apollo/client';

const getInputs = (items) =>
  items
    .map(
      (item, i) =>
        `$username${i}: String!, $password${i}: String!, $roles${i}: [UserRole!]!, $first_name${i}: String!, $last_name${i}: String!, $jobTitle${i}: String, $department${i}: String, $phone${i}: String, $mobile${i}: String, $account_id${i}: ID!, $accessLevel${i}: Scope, $allowedLocationsIds${i}: [ID], $allowedTagsIds${i}: [ID]`
    )
    .join(', ');

const getListOfUsers = (items) => {
  const array = items
    .map((item, i) => {
      return `{
        username: $username${i}
        password: $password${i}
        roles: $roles${i}
        first_name: $first_name${i}
        last_name: $last_name${i}
        jobTitle: $jobTitle${i}
        department: $department${i}
        phone: $phone${i}
        mobile: $mobile${i}
        account_id: $account_id${i}
        accessLevel: $accessLevel${i}
        allowedLocationsIds: $allowedLocationsIds${i}
        allowedTagsIds: $allowedTagsIds${i}
      }`;
    })
    .join(',');
  return `[${array}]`;
};

const getCreateStatements = (items) => {
  const listOfUsers = getListOfUsers(items);
  return `createUsers(input: ${listOfUsers}) {
        success {
          first_name
          last_name
          id
          username
          email
          jobTitle
          department
          phone
          mobile
          roles
        }
        errors
      }
      `;
};

const mapBatchVariables = (items) => {
  const variables = {};
  items.forEach((item, i) => {
    variables[`username${i}`] = item.username;
    variables[`password${i}`] = item.password;
    variables[`roles${i}`] = item.roles ? item.roles.split(', ') : [];
    variables[`first_name${i}`] = item.first_name;
    variables[`last_name${i}`] = item.last_name;
    variables[`jobTitle${i}`] = item.jobTitle;
    variables[`department${i}`] = item.department;
    variables[`phone${i}`] = item.phone;
    variables[`mobile${i}`] = item.mobile;
    variables[`account_id${i}`] = item.account_id;
    variables[`accessLevel${i}`] = item.accessLevel;
    variables[`allowedLocationsIds${i}`] = item.allowedLocationsIds;
    variables[`allowedTagsIds${i}`] = item.allowedTagsIds;
  });
  return variables;
};

const constructUserBatchRequest = (items) => {
  const inputs = getInputs(items);
  const mutationStatement = `mutation Create${items.length}Users(${inputs}) {`;
  const createStatements = getCreateStatements(items);
  const mutation = `
      ${mutationStatement}
      ${createStatements}
      }
    `;
  const gqlMutation = gql(mutation);
  const variables = mapBatchVariables(items);
  return { createMutation: gqlMutation, variables: variables };
};

export default constructUserBatchRequest;
