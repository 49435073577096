import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'rio-ui-components';
import { v4 as uuid } from 'uuid';
import { Account, AccessControls } from '@rio/rio-types';
import setImmutable from 'lodash/fp/set';
import { escape } from '../../../utils/escape';
import { useCreateAccount } from './useCreateAccount';
import { AccountDetails } from './AccountDetails';
import { ENTERPRISE } from '../../../constants/accountTypes';
import { CheckboxEvent, Option } from '../../../types';
import { validateField } from './utils';
import { CALENDAR_REPORTING_YEAR } from './constants';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

interface CreateAccountProps {
  allAccounts: Account[];
  onDismiss: Function;
}

interface CreateAccountInputState {
  text: string;
  error: string;
}
interface CreateAccountState {
  hasError: boolean;
  values: {
    businessName: CreateAccountInputState;
    childAccounts: Option[];
    notes: string;
    sector: {
      id: string | null;
    };
  };
  accessControls: AccessControls;
  reportingYearId: string;
  showChatBot: boolean;
}

export function CreateAccount({ allAccounts, onDismiss }: CreateAccountProps) {
  const [state, setState] = useState<CreateAccountState>({
    hasError: false,
    values: {
      businessName: { text: '', error: '' },
      childAccounts: [] as Option[],
      notes: '',
      sector: {
        id: null,
      },
    },
    accessControls: {
      learn: false,
      intelligence: false,
      tasks: true,
      projects: false,
      surveys: false,
      dromoUpload: false,
      v2Design: false,
      comply: {
        legislation: false,
        tagLevelLegislation: false,
        policies: false,
        systems: false,
        targets: false,
        frameworks: false,
        agreements: false,
      },
      data: {
        waste: false,
        electricity: false,
        gas: false,
        water: false,
        materials: false,
        fuel: false,
        transport: false,
        refrigerants: false,
        heatSteam: false,
        buildingEnergyRatings: false,
        hotelStays: false,
        investment: false,
      },
    },
    reportingYearId: CALENDAR_REPORTING_YEAR,
    showChatBot: false,
  });
  const [createAccount, { loading }] = useCreateAccount(onDismiss);
  const onCheckboxClick = (event: CheckboxEvent) => {
    setState((prevState) => ({
      ...prevState,
      accessControls: setImmutable(event.target.name, event.target.checked, prevState.accessControls),
    }));
  };
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      values: {
        ...state.values,
        [e.target.name]: {
          text: e.target.value,
          error: null,
        },
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { values, accessControls, showChatBot, reportingYearId } = state;
    const error = validateField(values.businessName.text);
    if (error) {
      return setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          businessName: {
            ...values.businessName,
            error,
          },
        },
      }));
    }
    const id = uuid();
    await createAccount({
      variables: {
        id,
        accessControls,
        reportingYearId,
        showChatBot,
        name: values.businessName.text,
        type: ENTERPRISE,
        notes: escape(values.notes),
        children: values.childAccounts.map(({ value }) => value),
        sectorId: values.sector?.id,
      },
    });
  };
  return (
    <Container>
      <form
        name="create-account__form"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <AccountDetails
          heading="Create Account"
          handleChange={handleChange}
          businessName={state.values.businessName}
          isSubmitted={loading}
          onCheckboxClick={onCheckboxClick}
          accessControls={state.accessControls}
          allAccounts={allAccounts}
          selectedChildAccounts={state.values.childAccounts}
          selectedSector={state.values.sector?.id}
          notes={state.values.notes}
          showChatBot={state.showChatBot}
          onShowChatBotChange={() => {
            setState((prevState) => ({
              ...prevState,
              showChatBot: !state.showChatBot,
            }));
          }}
          onNotesChange={(content: string) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                notes: content,
              },
            }))
          }
          onChildAccountsChange={(childAccounts: Option[]) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                childAccounts,
              },
            }))
          }
          onSectorChange={(sector: { label: string; value: string }) =>
            setState((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                sector: {
                  id: sector.value,
                },
              },
            }))
          }
          reportingYearId={state.reportingYearId}
          onReportingYearChange={(reportingYearId: string) =>
            setState((prevState) => ({
              ...prevState,
              reportingYearId,
            }))
          }
        />
        <Row container align="between">
          <ColStyled item>
            <Button
              name="create-account__button--create"
              color="success"
              submit
              disabled={!state.values.businessName.text || loading}
            >
              Create Account
            </Button>
          </ColStyled>
        </Row>
      </form>
    </Container>
  );
}
