import { Query, QueryGetReceivedSurveysPageArgs as Variables } from '@rio/rio-types';
import { usePageSuspendingQuery } from '~/hooks';
import { GET_RECEIVED_SURVEYS_PAGE } from './queries';

export type GetReceivedSurveysPageResponse = Pick<Query, 'getReceivedSurveysPage'>;

export function useGetReceivedSurveysPage({ accountId, offset = 0, limit, sort = [], filters = {} }: Variables) {
  return usePageSuspendingQuery<GetReceivedSurveysPageResponse, Variables>(GET_RECEIVED_SURVEYS_PAGE, {
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      offset,
      limit,
      sort,
      filters,
    },
    keepErrorAfterUnmount: true,
  });
}
