import keyBy from 'lodash/keyBy';
import { ApolloClient } from '@apollo/client';
import { Query, QueryGetLocationsPageArgs, DataType } from '@rio/rio-types';
import { GET_LOCATIONS_BY_ACCOUNT } from '../queries/locations';
import { GET_TAGS_BY_ACCOUNT_ID } from '../queries/tags';
import { MAX_ITEMS_IN_MEMORY_LIMIT } from '../constants/limits';

type TagsResponse = Pick<Query, 'getTagsByAccountId'>;
type LocationsResponse = Pick<Query, 'getLocationsPage'>;

export const getIndexedTagsAndLocations = async (
  client: ApolloClient<unknown>,
  accountId: string,
  locationNames?: string[]
) => {
  const tagsQuery = client.query<TagsResponse>({
    query: GET_TAGS_BY_ACCOUNT_ID,
    variables: {
      accountId,
    },
  });
  const locationNameFilter = locationNames
    ? [
        {
          field: 'name',
          filterType: DataType.Set,
          values: locationNames,
        },
      ]
    : [];
  const locationsQuery = client.query<LocationsResponse, QueryGetLocationsPageArgs>({
    query: GET_LOCATIONS_BY_ACCOUNT,
    variables: {
      accountId,
      limit: MAX_ITEMS_IN_MEMORY_LIMIT,
      filters: {
        set: locationNameFilter,
      },
    },
  });
  const [
    {
      data: { getTagsByAccountId },
    },
    {
      data: { getLocationsPage },
    },
  ] = await Promise.all([tagsQuery, locationsQuery]);
  const tags = keyBy(getTagsByAccountId, 'tagName');
  const locations = keyBy(getLocationsPage.rows, 'name');
  return {
    tags,
    locations,
  };
};
