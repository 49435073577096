import styled from 'styled-components';
import { Row, Col, Icon, MarkdownRenderer } from 'rio-ui-components';

export const QuestionCol = styled(Col)`
  padding-right: 16px;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
export const QuestionRow = styled(Row)`
  position: relative;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;

export const QuestionText = styled(MarkdownRenderer)`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;

const IconStyled = styled(Icon)`
  position: absolute;
  right: -${(p) => p.theme.geometry.sm.spacing};
  top: ${(p) => p.theme.geometry.sm.spacing};
  cursor: pointer;
`;


export const DeleteIcon = ({ onClick }: { onClick: () => void }) => <IconStyled icon="trash" color="#a2a3a5" onClick={onClick} />;
