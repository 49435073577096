import styled from 'styled-components';
import { CircleIcon, Heading, Row, Col } from 'rio-ui-components';
import { getIconByFileExtension } from '../../utils';

const ContainerStyled = styled(Col)`
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 0;
`;

const HeadContainer = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  padding-bottom: 0;
  flex: 0 0 auto;
`;

const CircleIconStyled = styled(CircleIcon)`
  z-index: 1;
  background: #fff;
  display: inline-flex;
`;

const TextContainer = styled(Col)`
  padding: ${(props) => props.theme.geometry.md.spacing};
  padding-top: ${(props) => props.theme.geometry.xl.spacing};
  border-top: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  margin-top: -${(props) => props.theme.geometry.lg.spacing};
`;

const FileIcon = ({ fileName, iconColor }) => {
  const icon = getIconByFileExtension(fileName);
  return (
    <CircleIconStyled
      name="DocumentTile__Icon"
      height="70px"
      size="lg"
      icon={icon}
      iconColor={iconColor}
      circleColor={iconColor}
    />
  );
};

const DocumentTile = ({ title, category, id, iconColor, code }) => {
  return (
    <ContainerStyled container item key={id}>
      <HeadContainer container item distribution="between">
        <FileIcon fileName={title} iconColor={iconColor} />
        <Heading inline="inline" size="md" color="normal" weight="light">
          {category}
        </Heading>
      </HeadContainer>
      <TextContainer container item vdistribution="between">
        <Heading size="lg" color="dark" weight="light">
          {`${title} ${code ? `(${code.toUpperCase()})` : ''}`}
        </Heading>
      </TextContainer>
    </ContainerStyled>
  );
};

export default DocumentTile;
