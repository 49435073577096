"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimePicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("@mui/material-next/styles");
const AdapterDayjs_1 = require("@mui/x-date-pickers/AdapterDayjs");
const Input_1 = require("@mui/base/Input");
const demo_1 = require("@mui/x-date-pickers/internals/demo");
const InputLabel_1 = require("@mui/material/InputLabel");
const OutlinedInput_1 = require("@mui/material/OutlinedInput");
const LocalizationProvider_1 = require("@mui/x-date-pickers/LocalizationProvider");
const x_date_pickers_1 = require("@mui/x-date-pickers");
const Text_1 = require("../Text");
const MobileTimePickerStyled = (0, styles_1.styled)(x_date_pickers_1.MobileTimePicker) `
  .${Input_1.inputClasses.input} {
    padding: 16px;
  }

  .${InputLabel_1.inputLabelClasses.root} {
    color: ${({ theme }) => theme.sys.color.primary};
  }

  .${Input_1.inputClasses.input} ~ .${OutlinedInput_1.outlinedInputClasses.notchedOutline} {
    border-color: ${({ theme }) => theme.sys.color.outlineVariant};
  }

  .${Input_1.inputClasses.focused} .${OutlinedInput_1.outlinedInputClasses.notchedOutline} {
    border-color: ${({ theme }) => theme.sys.color.primary};
  }

  .${OutlinedInput_1.outlinedInputClasses.root}:hover .${OutlinedInput_1.outlinedInputClasses.notchedOutline} {
    border-color: ${({ theme }) => theme.sys.color.primary};
  }

  .${OutlinedInput_1.outlinedInputClasses.notchedOutline} {
    top: 32px;
    bottom: -9px;
    margin-bottom: 9px;
    border-top: none;
    z-index: 0;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    z-index: 100;
    bottom: 8px;
    background-color: ${({ theme }) => theme.sys.color.onPrimary};
    width: 100%;
    height: 10px;
  }
`;
const ErrorText = (0, styles_1.styled)(Text_1.Text) `
  color: ${(p) => p.theme.sys.color.error};
`;
const TimePicker = ({ label, error, value, onChange, disabled, name, ...otherProps }) => {
    const handleTimeChange = (0, react_1.useCallback)((newValue) => {
        onChange(newValue);
    }, [onChange]);
    return ((0, jsx_runtime_1.jsx)(LocalizationProvider_1.LocalizationProvider, { dateAdapter: AdapterDayjs_1.AdapterDayjs, children: (0, jsx_runtime_1.jsxs)(demo_1.DemoContainer, { components: ['MobileDateTimePicker'], children: [(0, jsx_runtime_1.jsx)(MobileTimePickerStyled, { ...otherProps, label: label, defaultValue: value, onChange: (val) => handleTimeChange(val), openTo: "hours", orientation: "landscape" }), !!error && ((0, jsx_runtime_1.jsx)(ErrorText, { typescale: "body", size: "medium", children: error }))] }) }));
};
exports.TimePicker = TimePicker;
