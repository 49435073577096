import { useApolloClient } from '@apollo/client';
import React from 'react';
import { MultiSelect } from 'rio-ui-components';
import { Location } from '@rio/rio-types';
import { GET_LOCATIONS_BY_ACCOUNT } from './index.queries';
import { Nullable, MultiSelectEvent, Option } from '../../types';

interface LocationMultiSelectProps {
  accountId: string;
  value: Nullable<string | string[] | Option[]>;
  onChange: (e: MultiSelectEvent) => void;
  error?: Nullable<Error | string>;
  isSubmitting?: boolean;
  name?: string;
  defaultOptions?: Array<string> | Array<Option> | boolean;
  disabled?: boolean;
}

function locationToSelectOptions(location: Location) {
  return {
    value: location.id,
    label: location.name
  };
}

function LocationsMultiSelect({
  accountId,
  value,
  onChange,
  error,
  isSubmitting,
  name = 'locationIds',
  defaultOptions,
  disabled
}: LocationMultiSelectProps) {
  const apolloClient = useApolloClient();

  return (
    <MultiSelect
      name={name}
      onChange={onChange}
      isDisabled={isSubmitting || disabled}
      error={error}
      multiValueColor="tertiary"
      defaultOptions={Array.isArray(defaultOptions) ? defaultOptions : true}
      value={value}
      placeholder={'Start typing to search for a location'}
      loadOptions={async (filterValue: string) => {
        const {
          data: { getLocationsPage }
        } = await apolloClient.query({
          query: GET_LOCATIONS_BY_ACCOUNT,
          variables: {
            accountId,
            filters: {
              text: [
                {
                  filterType: 'text',
                  type: 'contains',
                  filter: filterValue,
                  field: 'name'
                }
              ]
            },
            sort: [{ field: 'name', order: 'asc' }]
          }
        });
        const { totalRows } = getLocationsPage;
        const locations = getLocationsPage.rows ? getLocationsPage.rows.map(locationToSelectOptions) : [];

        return { options: locations, total: totalRows };
      }}
    />
  );
}

export { LocationsMultiSelect };
