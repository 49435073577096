import React from 'react';
import styled from 'styled-components';
import Toolbar from './Toolbar';
import { Icon, Row, Col } from 'rio-ui-components';
import { keyBy } from 'lodash';

const DocumentFilterSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
`;
export const Image = styled.div`
  height: 30px;
  width: 38px;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background: url(${(props) => props.image}) center center no-repeat;
  background-size: cover;
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-top: ${(props) => props.theme.geometry.xs.spacing};
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  cursor: pointer;

  ${({ active, fade }) => {
    return fade ? `opacity: ${active ? '1' : '0.3'};` : '';
  }}
`;
const DocumentFilterItemButton = styled.div`
  display: flex;
  background: ${({ theme, color }) => theme.colors[color].normal.background};
  padding: ${({ theme }) => theme.geometry.xs.spacing};
  font-size: ${({ theme }) => theme.fonts.sm.size};
  color: white;
  margin-top: ${({ theme }) => theme.geometry.xs.spacing};
  margin-right: ${({ theme }) => theme.geometry.sm.spacing};
  margin-bottom: ${({ theme }) => theme.geometry.xs.spacing};
  border: 2px solid ${({ theme, color }) => theme.colors[color].normal.background};
  cursor: pointer;
  user-select: none;
  transition: all 0.1s linear;
  ${({ active, theme, color, hollow }) => {
    if (hollow)
      return active
        ? ''
        : `
    background: none;
    color: ${theme.colors[color].normal.background}
    `;
  }}

  ${({ active, fade }) => {
    return fade ? `opacity: ${active ? '1' : '0.3'};` : '';
  }}
`;

const IconStyled = styled(Icon)`
  margin-right: ${({ theme }) => theme.geometry.xs.spacing};
  ${(props) =>
    props.colour
      ? `
    color: ${props.theme.colors[props.colour].normal.background};
  `
      : ''}
`;

const RowFlex = styled(Row)`
  display: flex;
`;

export const DocumentFilterSection = ({ filters, activeFilter, previousFilter, onFilterClick, removeAll, addAll, onChange }) => {
  const filterKey = activeFilter || previousFilter;
  if (!filterKey) {
    return null;
  }
  const { options, applied, values } = filters[filterKey];
  const addActive = applied.length < options.length;
  const removeActive = applied.length > 0;
  const optionsById = keyBy(values, 'id');
  return (
    <>
      <Toolbar
        addActive={addActive}
        removeActive={removeActive}
        removeAll={() => {
          const { filters } = removeAll();
          onChange(filters);
        }}
        addAll={() => {
          const { filters } = addAll();
          onChange(filters);
        }}
      />
      <RowFlex>
        <Col span="24">
          <DocumentFilterSectionContainer>
            {options.map((item) => {
              const id = typeof item === 'object' ? item.id : item;
              const option = optionsById[id];
              return (
                <DocumentFilterItem
                  key={id}
                  active={applied.includes(id)}
                  item={option}
                  onClick={(value) => {
                    const { filters } = onFilterClick(value, filterKey);
                    onChange(filters);
                  }}
                  nameFilter={filterKey}
                />
              );
            })}
          </DocumentFilterSectionContainer>
        </Col>
      </RowFlex>
    </>
  );
};

export const DocumentFilterItem = ({ item, active, onClick, nameFilter }) => {
  return item.image ? (
    <Image name="LegislationTile__Image" image={item.image} active={active} alt={item.name} title={item.value} onClick={() => onClick(item.id, nameFilter)} fade />
  ) : (
    <DocumentFilterItemButton active={active} color="sidebar" onClick={() => onClick(typeof item === 'object' ? item.id : item, nameFilter)} fade>
      {item.icon && <IconStyled icon={item.icon} colour={item.colour} />} {typeof item === 'object' ? item.name : item}
    </DocumentFilterItemButton>
  );
};
