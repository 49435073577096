import { useQuery } from "@apollo/client";
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_TRANSPORT_FUEL_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface TransportFuelTypeSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function TransportFuelTypeSelect({ value, onChange }: TransportFuelTypeSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllTransportFuelTypes'>>(GET_ALL_TRANSPORT_FUEL_TYPES);
  return <Select placeholder="Please select type of fuel" value={value} options={toSelectOptions(data?.getAllTransportFuelTypes)} error={error} isLoading={loading} onChange={onChange} />;
}
