import { useMemo, useCallback, useState, useLayoutEffect } from 'react';
import { Query } from '@rio/rio-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NavigationDrawer, Button, Icons, Grid, Page, styled as styledV2 } from '@rio/ui-components';
import { hasAccess } from '~/utils/routes';
import { PageNotFound } from '~/components/Errors';
import { ExpandButton, SurveyInfoStatus, StatusWrapper } from '~/components/Surveys/v2';
import NotificationBanner from '~/components/NotificationBanner';
import { ApplicationData } from '~/types';
import { countIncompleteSurveys } from '~/hooks/surveys/useGetReceivedSurveysCount';
import { useRoutes, useCurrentAccountId } from '~/hooks';
import {
  crumbs,
  hasUUID,
  getSurveyId,
  getPathnameId,
  navigationItems,
  handleChangeTitle,
} from '~/utils/surveyNavigation';
import { GET_SURVEY_NAME } from '../../SurveysContainer/queries/queries';
import { ReceivedHeader } from '../ReceivedSurveyContainer/v2/ReceivedHeader';
import { MyContributorContainer } from '../MyContributorContainer/v2';
import { ReceivedSurveyContainer } from '../ReceivedSurveyContainer/v2';
import { SurveyOverviewContainer } from '../SurveyOverviewContainer/SurveyOverviewV2/SurveyOverviewContainer';
import { useMyContributorSubmission } from '../MyContributorSubmissionContainer/hooks/useMyContributorSubmission';
import { useGetMyContributorOverview } from '../MyContributorContainer/queries';
import { SurveysContainer as SentSurveysContainer } from '../SurveysContainer/SurveysContainerV2';
import { MyContributorsContainerV2 as MyContributorsContainer } from '../MyContributorsContainer';
import { SurveyAuditLogContainerV2 as SurveyAuditLogContainer } from '../SurveyAuditLogContainer';
import { ReceivedSurveysContainerV2 as ReceivedSurveysContainer } from '../ReceivedSurveysContainer';
import { MyContributorAuditLogContainerV2 as MyContributorAuditLogContainer } from '../MyContributorContainer';
import { ReceivedSurveyAuditLogContainerV2 as ReceivedSurveyAuditLogContainer } from '../ReceivedSurveyAuditLogContainer';
import { MyContributorSubmissionContainerV2 as MyContributorSubmissionContainer } from '../MyContributorSubmissionContainer';

const Container = styled('div')`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const Overlay = styledV2('div')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${(props) => props.theme.sys.color.onSecondary};
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;

const GridStyled = styled('div')`
  height: 100%;
  display: flex;
  gap: 40px;
`;

const ContentContainerStyled = styledV2('div')`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.sys.color.onSecondary};
  height: 100%;
`;

const ButtonGrid = styled(Grid)`
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonGridColumn = styled(Grid)`
  & > button,
  & > a {
    width: 100%;
    min-width: 190px;
    max-height: 44px;
  }
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-bottom: ${(props) => props.theme.geometry.md.spacing};
  z-index: 3;
  position: relative;
`;

interface SurveysContainerProps {
  applicationData: ApplicationData;
}

export const SurveysContainer = ({
  applicationData: { getAccountInfo, getUserInfoById, getReceivedSurveysPage },
}: SurveysContainerProps) => {
  const routes = useRoutes();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const pathId = getPathnameId(pathname);
  const accountId = useCurrentAccountId();

  const [editModalShown, setEditModalShown] = useState(false);
  const [createModalShown, setCreateModalShown] = useState(false);

  const overviewPath = pathname.includes(routes.surveys.sent.overview);
  const id = useMemo(() => hasUUID(pathname) && pathId, [pathId, pathname]);
  const isEdit = overviewPath || id;

  const isSubmission = pathname.includes('submission');
  const isContributors = pathname.includes(routes.surveys.sent.contributors);
  const [contributorAccountId, contributorUserId] = isContributors && !isSubmission && id ? id.split(':') : '';
  const { data: contributorData } = useGetMyContributorOverview(accountId, contributorAccountId, contributorUserId);
  const account = contributorData?.getMySurveyContributorById?.account;
  const currentSurvey = contributorData?.getMySurveyContributorById?.surveys?.find(
    (s) => s.id === getSurveyId(pathname)
  );

  const submissionId = isSubmission ? id : '';

  const { submission, expand, survey } = useMyContributorSubmission(submissionId || '');
  const { data } = useQuery<Pick<Query, 'getSurveyById'>>(GET_SURVEY_NAME, {
    variables: {
      id,
    },
    skip: !id || isSubmission,
  });

  const incompleteSurveysCount = useMemo(
    () => countIncompleteSurveys(getReceivedSurveysPage.rows),
    [getReceivedSurveysPage]
  );

  const surveyName = data?.getSurveyById.name;
  const hasSubscription = getAccountInfo.accessControls.surveys;
  const hasFormManagerAccess = getUserInfoById.role.permissions.survey.some((action: string) =>
    action.startsWith('getSurveys')
  );
  const hasContributorAccess = getUserInfoById.role.permissions.survey.some((action: string) =>
    action.startsWith('getReceivedSurveys')
  );

  useLayoutEffect(() => {
    if (hasFormManagerAccess) {
      navigate(`/v2/${routes.surveys.root}/${routes.surveys.sent.overview}`);
    } else {
      navigate(`/v2/${routes.surveys.root}/${routes.surveys.received.surveys}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleEditModal = useCallback(() => setEditModalShown((shown) => !shown), []);

  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const isLog = pathname.includes('log');

  const renderCurrentAction = useMemo(
    () =>
      (isContributors && !isSubmission) || isLog ? (
        ''
      ) : isSubmission ? (
        <>
          <ExpandButton expand={expand} />
          <StatusWrapper>
            <SurveyInfoStatus survey={{ ...survey, submission }} />
          </StatusWrapper>
        </>
      ) : pathname.includes('received') ? (
        <ReceivedHeader />
      ) : (
        <ButtonGrid wrap="wrap" container>
          {isEdit && (
            <ButtonGridColumn xs={12} lg={5.5}>
              <Button variant="outlined" onClick={toggleEditModal}>
                Edit survey
              </Button>
            </ButtonGridColumn>
          )}
          <ButtonGridColumn xs={12} lg={5.5}>
            <Button variant="filled" onClick={toggleCreateModal} startIcon={<Icons.Add />}>
              Create a survey
            </Button>
          </ButtonGridColumn>
        </ButtonGrid>
      ),
    [
      isLog,
      expand,
      isEdit,
      survey,
      pathname,
      submission,
      isSubmission,
      isContributors,
      toggleEditModal,
      toggleCreateModal,
    ]
  );

  const renderTitle = useMemo(() => {
    return {
      content: handleChangeTitle(pathname, routes, surveyName, account, submission),
      crumbs: crumbs(
        pathname,
        routes,
        surveyName,
        incompleteSurveysCount,
        account,
        submission,
        currentSurvey,
        hasFormManagerAccess
      ),
      actionButton: renderCurrentAction,
    };
  }, [
    pathname,
    routes,
    surveyName,
    account,
    submission,
    currentSurvey,
    incompleteSurveysCount,
    renderCurrentAction,
    hasFormManagerAccess,
  ]);

  return (
    <>
      <Container>
        {!hasSubscription && (
          <>
            <Overlay />
            <NotificationBannerStyled
              name="FormsContainer_Access_NotificationBanner"
              icon="exclamation-triangle"
              color="danger"
              title="Surveys"
              body="Currently Surveys are not included in your subscription, if you’d like access to Surveys please contact Rio ESG LTD or get in touch with your Business Admin."
            />
          </>
        )}
        <Page title={renderTitle}>
          <GridStyled>
            <NavigationDrawer items={navigationItems(pathname, routes, incompleteSurveysCount, hasFormManagerAccess)} />
            <ContentContainerStyled>
              <Routes>
                <Route
                  path={routes.surveys.sent.overview}
                  element={hasAccess(SurveyOverviewContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.contributors}
                  element={hasAccess(MyContributorsContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.contributor}
                  element={hasAccess(MyContributorContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.contributorAuditLog}
                  element={hasAccess(MyContributorAuditLogContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.submission}
                  element={hasAccess(MyContributorSubmissionContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.surveys}
                  element={hasAccess(SentSurveysContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.sent.survey}
                  element={hasAccess(SurveyOverviewContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.received.surveys}
                  element={hasAccess(ReceivedSurveysContainer, hasContributorAccess)}
                />
                <Route
                  path={routes.surveys.received.survey}
                  element={hasAccess(ReceivedSurveyContainer, hasContributorAccess)}
                />
                <Route
                  path={routes.surveys.sent.auditLog}
                  element={hasAccess(SurveyAuditLogContainer, hasFormManagerAccess)}
                />
                <Route
                  path={routes.surveys.received.auditLog}
                  element={hasAccess(ReceivedSurveyAuditLogContainer, hasContributorAccess)}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </ContentContainerStyled>
          </GridStyled>
        </Page>
      </Container>
      {(editModalShown || createModalShown) && (
        <SurveyOverviewContainer action={{ editModalShown, createModalShown, toggleEditModal, toggleCreateModal }} />
      )}
    </>
  );
};
