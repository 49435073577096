import { AnomalyFormattedDetails } from '../types';

enum NumberFields {
  thresholdPercentage = 'recurrence',
}

enum RequiredFields {
  account = 'account',
  thresholdPercentage = 'thresholdPercentage',
}

type NumberFieldsValidateErrors = {
  [name in NumberFields]?: string;
};

type RequiredFieldsValidateErrors = {
  [name in RequiredFields]?: string;
};

export type ValidateErrors = RequiredFieldsValidateErrors & NumberFieldsValidateErrors;

export const ValidateAnomalyThresholdConfig = (anomalyThresholdConfig: AnomalyFormattedDetails): ValidateErrors => {
  const errors: ValidateErrors = {};

  const thresholdPercentage = Number(anomalyThresholdConfig.thresholdPercentage!);

  if (isNaN(thresholdPercentage) || thresholdPercentage <= 0 || thresholdPercentage > 100) {
    errors.thresholdPercentage = 'The value must be a number between 1 and 100';
  }

  for (const key of Object.values(RequiredFields)) {
    if (!anomalyThresholdConfig[key]) {
      errors[key] = `Value required`;
    }
  }

  return errors;
};
