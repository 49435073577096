import { useNotification } from '~/hooks';
import { useMeterModalForm } from './useMeterModalForm';
import { useQuery } from '@apollo/client';
import { GET_ENERGY_SOURCE_BY_ID } from './index.queries';

export function useUpdateMeterModalForm(meterId: string) {
  const { state, replaceState, ...rest } = useMeterModalForm();
  const { showNotification } = useNotification();

  useQuery(GET_ENERGY_SOURCE_BY_ID, {
    variables: { id: meterId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const es = data.getEnergySourceById;

      replaceState({
        meterName: { error: '', value: es.name },
        locationId: { error: '', value: es.locationPoint.location.id, label: es.locationPoint.location.name },
        typeId: { error: '', value: { id: es.type.id, name: es.type.name }, label: es.type.name },
        meterIn: es.inOut === 'IN' || es.inOut === 'BOTH',
        meterOut: es.inOut === 'OUT' || es.inOut === 'BOTH',
        isGeneration: es.isGeneration,
        code: es.meterCode || '',
        hardwareProviderName: es.hardwareProviderName,
        operatorProviderName: es.operatorProviderName,
        startDate: es.startDate ? new Date(es.startDate) : null,
        endDate: es.endDate ? new Date(es.endDate) : null,
      });
    },
    onError: () => {
      showNotification('Server error occurred. Please try again later.', 'danger');
    },
  });

  return { state, ...rest };
}
