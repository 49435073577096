import { useQuery } from '@apollo/client';
import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Col,
  Text,
  Button,
  Modal,
  Notification,
  ErrorMessage,
  ContainerError,
  Tabs,
  Tab,
  TabPanel
} from 'rio-ui-components';
import { TransactionType } from '@rio/rio-types';
import { useCountries } from '../../../hooks/useCountries';
import { GET_ALL_SUPPLIERS, SET_PAGE, GET_SEARCH, SET_SEARCH } from './index.queries';
import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import SupplierRow from './SupplierRow';
import PaginationContainer from '../../../components/PaginationContainer';
import AccountSelector from '../../../components/AccountSelector';
import SearchContainer from '../../../components/SearchContainer';
import CreateSupplier from './CreateSupplier';
import UpdateSupplier from './UpdateSupplier';
import supplierUtilities from './supplierUtilities.json';
import { alphabetiseByField } from '../../../utils/alphabetise';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useGetMaterialsTypesByAccountId } from '../../../hooks/materials/useGetMaterialsByAccountID';
import { getGridConfig, ColStyled, RowStyled } from './gridConfig';
import { usePermissions } from '../../../hooks/usePermissions';

const Headers = styled(RowStyled)`
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;
const CreateButtonStyled = styled(Button)`
  width: 250px;
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
  cursor: po;
`;
const TabPanelWrapper = styled.div`
  & > div {
    overflow: auto;
  }
`;
const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;

const useTabStyles = makeStyles({
  root: {
    justifyContent: 'center'
  },
  scroller: {
    flexGrow: '0'
  }
});

const ConfigurationMySuppliersContainer = () => {
  const classes = useTabStyles();
  const userAccountId = useCurrentAccountId();

  const permissions = usePermissions();
  const defaultPagination = { page: 1, totalPages: 0 };
  const [pagination, setPagination] = useState(defaultPagination);
  const [state, setState] = useState({
    createModal: { show: false },
    updateModal: { id: '' },
    selectedTags: [],
    notification: { message: null, color: null },
    shouldRefetch: false,
    selectAll: false,
    orderByDesc: true,
    countryList: [],
    defaultCountry: null
  });
  const [tab, setTab] = useState(0);
  const [accountId, setAccountId] = useState(null);

  const currentUtility = supplierUtilities[tab];

  const { countryList, allCountries } = useCountries();

  const {
    data: { suppliersGridSearch }
  } = useQuery(GET_SEARCH);

  const gridConfig = getGridConfig(currentUtility?.value);
  const { data: materialTypes } = useGetMaterialsTypesByAccountId(userAccountId);
  const materialTypesOptions = materialTypes
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort(alphabetiseByField('label'));

  const queryVariables = {
    accountId,
    page: pagination.page,
    pageSize: 20,
    filterValue: suppliersGridSearch || null,
    orderDir: state.orderByDesc ? 'desc' : 'asc',
    filterBy: 'name',
    utility: currentUtility.value
  };

  const dismissModals = (notification = {}) => {
    setState({
      ...state,
      createModal: { show: false },
      updateModal: { id: null },
      notification: { message: notification.message, color: notification.color, id: notification.id }
    });
  };

  const showModal = (modalType, id, name, utility, address, type, materials, notes, accountId) => {
    if (modalType === 'create') return setState({ ...state, createModal: { show: true } });
    if (modalType === 'update')
      return setState({ ...state, updateModal: { id, name, utility, address, type, materials, notes, accountId } });
  };

  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: null, color: null, id: null }
    }));
  };

  const onPageChange = (page) => {
    setPagination({
      page,
      totalPages: pagination.totalPages
    });
  };

  const renderModals = (queryVariables) => (
    <Fragment>
      {state.notification.message && (
        <Notification
          name="ConfigurationMySuppliersContainer__Notification"
          show
          color="success"
          onClose={hideNotification}
        >
          {state.notification.message}
          <div hidden name="ConfigurationMySuppliersContainer__Notification__createlocation_id">
            {state.notification.id ? state.notification.id[0] : null}
          </div>
          <div hidden name="ConfigurationMySuppliersContainer__Notification__createaddress_id">
            {state.notification.id ? state.notification.id[1] : null}
          </div>
        </Notification>
      )}
      {state.createModal.show && (
        <Modal
          span={5}
          size="md"
          show
          name="ConfigurationMySuppliersContainer__Modal--create"
          dismissable
          onDismiss={dismissModals}
        >
          <CreateSupplier
            onSuccess={(message, color, id) => dismissModals({ message, color, id })}
            accountId={userAccountId}
            countryList={countryList}
            refetchQuery={[{ query: GET_ALL_SUPPLIERS, variables: queryVariables }]}
            utility={supplierUtilities[tab].value}
            heading={`Create ${currentUtility.label} Supplier`}
            forType={currentUtility.value === TransactionType.Materials ? 'Supplier' : undefined}
            materialTypesOptions={materialTypesOptions}
          />
        </Modal>
      )}

      {state.updateModal.id && (
        <Modal
          size="md"
          show
          dismissable
          onDismiss={dismissModals}
          name="ConfigurationMySuppliersContainer__Modal--update"
        >
          <UpdateSupplier
            supplierId={state.updateModal.id}
            notes={state.updateModal.notes}
            materials={state.updateModal.materials}
            accountId={state.updateModal.accountId}
            supplierName={state.updateModal.name}
            addressId={state.updateModal.address && state.updateModal.address.id}
            address1={state.updateModal.address && state.updateModal.address.address1}
            address2={state.updateModal.address && state.updateModal.address.address2}
            address3={state.updateModal.address && state.updateModal.address.address3}
            city={state.updateModal.address && state.updateModal.address.city}
            postcode={state.updateModal.address && state.updateModal.address.postcode}
            phone={state.updateModal.address && state.updateModal.address.phone1}
            utility={state.updateModal.utility}
            onDismiss={dismissModals}
            onSuccess={(message, color, id) => dismissModals({ message, color, id })}
            countryList={countryList}
            defaultCountry={
              state.updateModal.address && {
                value: state.updateModal.address.country.id,
                label: state.updateModal.address.country.name
              }
            }
            allCountries={allCountries}
            type={state.updateModal.type}
            materialTypesOptions={materialTypesOptions}
          />
        </Modal>
      )}
    </Fragment>
  );

  const onGetCompleted = (data) => {
    const pageChange = pagination.page !== data.getSuppliers.page;
    const pageCountChange = pagination.totalPages !== data.getSuppliers.pageCount;
    if (pageChange || pageCountChange) {
      setPagination({
        page: data.getSuppliers.page,
        totalPages: data.getSuppliers.pageCount
      });
    }
  };

  const getContractorRows = (contractorData) => {
    if (contractorData.suppliers.length === 0)
      return <NoResults name="ConfigurationMySuppliersContainer__NoResults" title="No Suppliers found" />;

    return contractorData.suppliers.map((supplier) => {
      return (
        <SupplierRow
          key={supplier.id}
          supplierId={supplier.id}
          name={supplier.name}
          type={supplier.type}
          address={supplier.address}
          utility={supplier.utility}
          materials={supplier.materials}
          hasTransactions={supplier.hasTransactions}
          phoneNumber={supplier.address ? supplier.address.phone1 : null}
          accountId={supplier.accountId}
          permissions={permissions}
          onEdit={() =>
            showModal(
              'update',
              supplier.id,
              supplier.name,
              supplier.utility,
              supplier.address,
              supplier.type,
              supplier.materials,
              supplier.notes,
              supplier.accountId
            )
          }
        />
      );
    });
  };

  const { data, error, loading, refetch } = useQuery(GET_ALL_SUPPLIERS, {
    variables: queryVariables,
    onCompleted: onGetCompleted,
    fetchPolicy: 'network-only',
    skip: !permissions
  });

  useEffect(() => {
    setAccountId(userAccountId);
  }, [userAccountId]);

  return (
    <Col name="ConfigurationMySuppliersContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationMySuppliersContainer__Controls"
        icon="parachute-box"
        iconColor="primary"
        title="Suppliers"
      >
        <Col container item>
          <SearchContainer setPageMutation={SET_PAGE} setSearchMutation={SET_SEARCH} hideButton={true} />
        </Col>
        <CreateButtonStyled
          name="ConfigurationMySuppliersContainer__Controls__Button--create"
          size="md"
          color="primary"
          inline
          onClick={() => showModal('create')}
        >
          + Add Supplier
        </CreateButtonStyled>
      </ContainerHeader>
      {renderModals(queryVariables)}
      {permissions.account.includes('getAll') && (
        <AccountSelectorContainer>
          <AccountSelector
            value={accountId}
            onChange={({ id }) => {
              setAccountId(id);
            }}
          />
        </AccountSelectorContainer>
      )}
      <Tabs
        classes={{ root: classes.root, scroller: classes.scroller }}
        variant="scrollable"
        value={tab}
        onChange={(_, tab) => {
          setPagination(defaultPagination);
          setTab(tab);
        }}
      >
        {supplierUtilities.map((utility) => (
          <Tab key={utility.label + utility.value} label={utility.label} />
        ))}
      </Tabs>
      <Headers container>
        {gridConfig.map((column) => (
          <ColStyled key={column.name} container item span={column.width}>
            <Text weight="bold">{column.name}</Text>
          </ColStyled>
        ))}
      </Headers>
      {supplierUtilities.map((_, index) => (
        <TabPanelWrapper key={_.label + _.value}>
          <TabPanel index={index} value={tab}>
            <TabContent>
              {error ? (
                <ErrorMessage error={error}>
                  {({ title, body, icon }) => (
                    <ContainerError
                      name="ConfigurationMySuppliersContainer__Error"
                      icon={icon}
                      title={title}
                      body={body}
                      retry={() => refetch()}
                    />
                  )}
                </ErrorMessage>
              ) : loading ? (
                <ContainerLoadingIndicator name="ConfigurationMySuppliersContainer__Loading" />
              ) : (
                getContractorRows(data.getSuppliers)
              )}
            </TabContent>
          </TabPanel>
        </TabPanelWrapper>
      ))}
      {pagination.totalPages > 1 ? (
        <PaginationContainer
          totalPagesCount={pagination.totalPages}
          currentPage={pagination.page}
          onChange={(data) => onPageChange(data)}
        />
      ) : null}
    </Col>
  );
};
export default ConfigurationMySuppliersContainer;
