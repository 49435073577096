import { Query, ManagementSystem, ManagementSystemChapter, Chapter } from '@rio/rio-types';
import { SustainabilityTheme } from 'rio-ui-components';
import { Dictionary, Nullable } from './generic';

export enum BatchStatus {
  DONE = 'DONE',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  ACTION_REQUIRED = 'ACTION',
  ABANDONED = 'ABANDONED',
  DELETED = 'DELETED',
}

export type TransactionGridFilter = Array<{ label: Nullable<string>; value: Nullable<string> }>;
export type TransactionGridFilters = Dictionary<TransactionGridFilter>;

export type GetManagementSystemByIdResponse = Pick<Query, 'getManagementSystemById'>;

export type ManagementSystemPage = {
  type: string;
  tocTitle: string;
  tocNumber: string;
  parent: ManagementSystemPage;
  siblings: ManagementSystemPage[];
  index?: number;
} & Chapter;

export type ChaptersIndex = Dictionary<ManagementSystem | ManagementSystemChapter>;

export type NotificationType = { message: string; colour: string };

export interface Breadcrumb {
  to?: string;
  title: string;
  key?: string;
}

export enum TemplateType {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

export enum MODALS {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface NotificationProps {
  message: string;
  color: string;
}

export enum FEATURES {
  PROJECTS = 'Projects',
}

export type Theme = typeof SustainabilityTheme;
