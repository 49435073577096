import React, { useCallback, useState } from 'react';
import { Mutation, MutationUpdateDataTrackerValidationResultsArgs } from '@rio/rio-types';
import { Button, Box } from '@mui/material';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';

import { Grid } from './Grid';
import { useCurrentAccountId, useNotification, usePermissions } from '~/hooks';
import {
  GET_DATA_TRACKER_VALIDATION_RESULTS_BY_ACCOUNT_ID,
  UPDATE_DATA_TRACKER_VALIDATION_RESULT,
} from '../index.queries';
import { FetchRowsParameters, GridApiType } from '../types';
import ConfirmationDialog from '../ConfirmationDialog';
import { PAGINATION_PAGE_SIZE } from './Grid';

import { handleSelection } from '../Anomalies/types/grids/handle-selection';
import { Placeholder } from '../Anomalies/types/DataAnomalies';
import { CellEditingStartedEvent, IRowNode } from 'ag-grid-community';

const GridStyled = styled.div`
  display: flex;
  height: 450px;
  border-radius: 5px;

  .ag-theme-alpine {
    .utility-header-class {
      .ag-header-cell-label {
        padding-left: 10px;
      }
    }
    .ag-paging-panel {
      justify-content: flex-end;
    }
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ButtonContainer = styled.div`
  margin-right: 10px;
`;

type UpdateDataTrackerValidationResultsResponse = Pick<Mutation, 'updateDataTrackerValidationResults'>;

const DataTrackerValidationResults = ({ intl }) => {
  const accountId = useCurrentAccountId();
  const client = useApolloClient();
  const { showNotification } = useNotification();
  const [gridApi, setGridApi] = useState<GridApiType>();

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [placeholderShown, setPlaceholderShown] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IRowNode | null>(null);

  const showPlaceholder = useCallback(() => setPlaceholderShown(true), []);

  const permissions = usePermissions();

  const refreshGrid = useCallback(() => gridApi?.api?.refreshServerSide({ purge: true }), [gridApi]);

  const fetchRows = async (variables: FetchRowsParameters) => {
    const {
      data: {
        getDataTrackerValidationResults: { rows, totalRows },
      },
    } = await client.query({
      query: GET_DATA_TRACKER_VALIDATION_RESULTS_BY_ACCOUNT_ID,
      variables: { ...variables, accountId: accountId, isIgnored: false },
      fetchPolicy: 'network-only',
    });

    return { rows, totalRows };
  };

  const [updateResults] = useMutation<
    UpdateDataTrackerValidationResultsResponse,
    MutationUpdateDataTrackerValidationResultsArgs
  >(UPDATE_DATA_TRACKER_VALIDATION_RESULT);

  const updateIgnoredRecordsHandler = async () => {
    const ignoreRecords = gridApi?.api.getSelectedRows();

    if (ignoreRecords?.length) {
      try {
        await updateResults({
          variables: {
            input: ignoreRecords?.map(({ id }) => ({
              id: id.toString(),
              accountId,
              isIgnored: true,
            })),
          },
        });
        showNotification('The data tracker result(-s) has been successfully updated!');
        refreshGrid();
      } catch (e) {
        showNotification(`Something went wrong. The data tracker result(-s) wasn't updated.`, 'danger');
      }
    }
  };

  const setRowToUpdate = useCallback(
    (e: CellEditingStartedEvent) => {
      setSelectedRow(e.node);
    },
    [setSelectedRow]
  );

  const updateRow = React.useCallback(
    async (params) => {
      if (selectedRow === null) return;
      // const selectedRow = params.api.getSelectedNodes()[0];

      // save new value to variable for cases when user hasn't changed note or typed something and then erased the field
      const newValue = params.newValue || null;

      if (newValue !== params.oldValue) {
        try {
          selectedRow.updateData({ ...selectedRow.data, note: params.newValue });
          await updateResults({
            variables: {
              input: [
                {
                  id: params.data.id,
                  accountId,
                  note: newValue,
                },
              ],
            },
          });
          selectedRow.setSelected(false);
          showNotification('The data tracker result note has been successfully updated!');
        } catch (e) {
          selectedRow.updateData({ ...selectedRow.data, note: params.oldValue });
          showNotification(`Something went wrong. The data tracker result note wasn't updated.`, 'danger');
        }
      }
    },
    [accountId, updateResults, showNotification, selectedRow]
  );

  const onSelectionChanged = () => {
    const selectedRowsList = gridApi?.api.getSelectedRows();
    setIsSelected(!!selectedRowsList?.length);
  };

  const handleAcceptButtonClick = React.useCallback(() => {
    const results = gridApi?.api.getSelectedRows();
    if (results?.length === 0) {
      return;
    }
    setConfirmationDialogState(true);
  }, [gridApi]);

  const acceptAllowedForRole = permissions.dataTracker.find((a) => a.startsWith('updateDataTrackerValidationResults'));
  return (
    <Box flexDirection={'column'}>
      {confirmationDialogState && (
        <ConfirmationDialog
          title={<FormattedMessage id="pages.data.overview.confirmationDialog.title" />}
          agreeButtonCaption={<FormattedMessage id="pages.data.overview.confirmationDialog.agree" />}
          disagreeButtonCaption={<FormattedMessage id="pages.data.overview.confirmationDialog.disagree" />}
          onAgree={updateIgnoredRecordsHandler}
          onClose={() => setConfirmationDialogState(false)}
        />
      )}
      {placeholderShown ? (
        <Placeholder />
      ) : (
        <>
          <ButtonsContainer>
            {!!acceptAllowedForRole && (
              <ButtonContainer>
                <Button
                  variant="outlined"
                  onClick={() => handleSelection(gridApi?.api, PAGINATION_PAGE_SIZE)}
                  disabled={!gridApi}
                  title={intl.formatMessage({ id: 'pages.data.overview.selectAll' })}
                >
                  <FormattedMessage id="pages.data.overview.selectAllButtonText" />
                </Button>
              </ButtonContainer>
            )}
            {isSelected && (
              <ButtonContainer>
                <Button
                  variant="outlined"
                  onClick={() => handleSelection(gridApi?.api, PAGINATION_PAGE_SIZE, true)}
                  disabled={!gridApi}
                  title={intl.formatMessage({ id: 'pages.data.overview.deselectAll' })}
                >
                  <FormattedMessage id="pages.data.overview.deselectAllButtonText" />
                </Button>
              </ButtonContainer>
            )}
            <ButtonContainer>
              <Button
                variant="outlined"
                onClick={() => {
                  if (gridApi?.api) {
                    gridApi?.api.setFilterModel(null);
                  }
                }}
                disabled={!gridApi}
                title={intl.formatMessage({ id: 'pages.data.overview.clear' })}
              >
                <FormattedMessage id="pages.data.overview.clearButtonText" />
              </Button>
            </ButtonContainer>
            {!!acceptAllowedForRole && (
              <Button
                variant="outlined"
                onClick={handleAcceptButtonClick}
                title={intl.formatMessage({ id: 'pages.data.overview.acceptInfo' })}
              >
                <FormattedMessage id="pages.data.overview.accept" />
              </Button>
            )}
          </ButtonsContainer>
          <GridStyled>
            <Grid
              key={'MissingTransactionsGrid'}
              fetchRows={fetchRows}
              setGridApi={setGridApi}
              onCellEditingStopped={updateRow}
              onCellEditingStarted={setRowToUpdate}
              showPlaceholder={showPlaceholder}
              onSelectionChanged={onSelectionChanged}
            />
          </GridStyled>
        </>
      )}
    </Box>
  );
};

const DataTrackerValidationResultsGrid = injectIntl(DataTrackerValidationResults);

export { DataTrackerValidationResultsGrid };
export default DataTrackerValidationResultsGrid;
