import { SurveyQuestionType, SurveyAnswerInput, SurveyAnswer } from '@rio/rio-types';
import { Question } from 'survey-core';
import { Dictionary } from 'lodash';
import { SurveyFileAnswer } from './types';

type Answers = {
  [key: string]: string | object | string[];
};

const multiValueFields = [SurveyQuestionType.Checkbox, SurveyQuestionType.Tagbox, SurveyQuestionType.File];

export function transformSubmissionToAnswers(
  answers: SurveyAnswer[],
  keyedFiles: Dictionary<SurveyFileAnswer>
): Answers {
  return answers.reduce((prev: Answers, next) => {
    const answer = JSON.parse(next.answer);
    if (next.question.type === SurveyQuestionType.File) {
      prev[next.question.name] = answer.map((id: string) => keyedFiles[id]).filter(Boolean);
    } else {
      prev[next.question.name] = answer;
    }
    return prev;
  }, {});
}

export function formatAnswersForSubmission(answers: Answers): SurveyAnswerInput[] {
  return Object.entries(answers).map(([questionName, answer]) => ({
    name: questionName,
    value: JSON.stringify(answer),
  }));
}

export function getNonAnsweredQuestionsAnswers(questions: Question[]): SurveyAnswerInput[] {
  return questions.map((question) => {
    if (multiValueFields.includes(question.getType() as SurveyQuestionType)) {
      return { name: question.name, value: JSON.stringify([]) };
    }
    return { name: question.name, value: JSON.stringify('') };
  });
}

export const isAnswered = (value: unknown): boolean => {
  if (value === null || value === undefined) return false;

  // Check if value is a string but not a special one
  const isStringButNotSpecial =
    typeof value === 'string' && value !== '""' && value !== '' && value !== '[]' && value !== '{}';

  // Check if value is a boolean
  const isBoolean = typeof value === 'boolean';

  // Check if value is a number
  const isNumber = typeof value === 'number';

  // Check if value is a non-empty array
  const isNonEmptyArray = Array.isArray(value) && value.length > 0;

  // Check if value is a non-empty object (but not an array or null)
  const isNonEmptyObject =
    typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length > 0;

  // Value is considered answered if any of the above conditions is true
  return isStringButNotSpecial || isBoolean || isNumber || isNonEmptyArray || isNonEmptyObject;
};
