import { forwardRef, useCallback } from 'react';
import { isEmpty } from 'lodash';
import TextInput from 'rio-ui-components/components/TextInput';
import { isNumeric, isNumericWithNegativeOption } from '../../utils';
import { InputEvent } from '../../types';

interface NumberInputProps {
  onChange?: (value: string | undefined, event: InputEvent) => void;
  trigger?: () => void;
  type?: string;
  value?: number;
  withNegative?: boolean;
  error?: string;
  [key: string]: unknown;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(function NumberInput(props, ref) {
  const { onChange = () => {}, trigger = () => {}, type = 'text', value = '', withNegative = false, ...others } = props;

  const handleOnChange = useCallback(
    (e: InputEvent) => {
      const {
        target: { value: currentValue },
      } = e;

      const validator = withNegative ? isNumericWithNegativeOption : isNumeric;

      if (isEmpty(currentValue)) {
        onChange(undefined, e);
        trigger();

        return;
      }

      const isValid = validator(currentValue) ? currentValue : null;

      if (isValid) {
        onChange(currentValue, e);
        trigger();

        return;
      }

      e.preventDefault();
    },
    [onChange, trigger, withNegative]
  );

  return <TextInput {...others} onChange={handleOnChange} value={value} textInputRef={ref} type={type} />;
});

export const NumberInputWithNegativeOption = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInputWithNegativeOption(props, ref) {
    return <NumberInput {...props} ref={ref} withNegative />;
  }
);
