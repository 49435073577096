import { gql } from '@apollo/client';
import { ENERGY_SOURCE_FRAGMENT } from '../../../../graphql/fragments/EnergySourceFragment.fragment';

export const GET_WATER_TRANSACTIONS_PAGE = gql`
  query GetWaterTransactionsPage($accountId: ID!, $offset: Int, $limit: Int, $sort: [SortCommand!], $filters: Filters) {
    getWaterTransactionPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        startDate
        endDate
        waterIn
        waterOut
        totalCost
        totalCostVat
        reading
        accuracy
        tariff
        inEmissions
        outEmissions
        carbonEmissionsScope3
        comment
        currency
        invoiceNumber
        evidenceReference
        otherReference
        meterName
        importBatchFileName
        importBatchId
        locationName
        locationCode
        locationEndDate
        locationPointName
        tags
        supplier
        departments
        reportingYearOfFactors
        energySource {
          ...EnergySourceFields
        }
      }
    }
  }
  ${ENERGY_SOURCE_FRAGMENT}
`;

// LABEL SELECTOR
export const GET_COLUMNS = gql`
  {
    waterTransactionGridColumns @client {
      id
      displayName
      display
      width
      dataType
      field
      filterable
    }
  }
`;

export const SET_COLUMNS = gql`
  mutation SwapWaterTransactionColumnDisplay($id: String!) {
    swapWaterTransactionColumnDisplay(id: $id) @client
  }
`;

// GRID CONTAINER
export const SET_TOTAL_PAGES = gql`
  mutation SetWaterTransactionGridTotalPages($totalPages: Int!) {
    setWaterTransactionGridTotalPages(totalPages: $totalPages) @client
  }
`;
