import React from 'react';
import { Select } from 'rio-ui-components';
import _ from 'lodash';
import { useAllLegislation } from '~/hooks';

export function LegislationSelect({ value, error, name, onChange, isDisabled }) {
  const { legislations } = useAllLegislation();
  const options = _(legislations)
    .map((l) => ({
      value: l.id,
      label: l.title,
    }))
    .orderBy(['label'], ['asc']);

  return (
    <Select
      name={name}
      placeholder="Please select the legislation item"
      value={value}
      options={options}
      error={error}
      onChange={onChange}
      classPrefix="legislation-select"
      isDisabled={isDisabled}
    />
  );
}
