export const PAPER_REDUCTION = 'Paper reduction';
export const DOMESTIC_FLIGHTS = 'Domestic Flights (km)';
export const REUSE = 'Reuse';
export const NON_TRADED_EMISSIONS = 'Non-traded emissions';
export const WATER_CONSUMPTION = 'Water consumption';
export const FOOD_WASTE = 'Food waste';
export const NATURAL_GAS_CONSUMPTION = 'Natural Gas consumption';
export const RECYCLED_WASTE = 'Recycled Waste';
export const WASTE_GENERATION = 'Waste generation';
export const INTERNATIONAL_FLIGHTS = 'International Flights (km)';
export const ELECTRICITY_CONSUMPTION = 'Electricity consumption';
export const LANDFILLED_WASTE = 'Landfilled waste';
export const TRANSPORT = 'Transport';
export const CARBON_EMISSIONS = 'Carbon emissions';
