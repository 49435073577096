import { VerbalQuestion } from './types';
import { ChoiceQuestionSelect } from './ChoiceQuestionSelect';
import { MultipleChoiceQuestionSelect } from './MultipleChoiceQuestionSelect';
import { OpenEndedQuestionField } from './OpenEndedQuestionField';
import { OpenEndedRichQuestionField } from './OpenEndedRichQuestionField';

interface VerbalQuestionFieldProps {
  question: VerbalQuestion;
  onAnswer: (updatedQuestion: VerbalQuestion) => void;
}

export function VerbalQuestionField({ question, onAnswer }: VerbalQuestionFieldProps) {
  switch (question.__typename) {
    case 'ChoiceQuestion':
      return <ChoiceQuestionSelect question={question} onAnswer={onAnswer} />;
    case 'MultipleChoiceQuestion':
      return <MultipleChoiceQuestionSelect question={question} onAnswer={onAnswer} />;
    case 'OpenEndedQuestion':
      return <OpenEndedQuestionField question={question} onAnswer={onAnswer} />;
    case 'OpenEndedRichQuestion':
      return <OpenEndedRichQuestionField question={question} onAnswer={onAnswer} />;
    default:
      return null;
  }
}