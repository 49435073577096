import { Button, TextInput, Icon as RioIcon, LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 900px;

  & h4 {
    font-size: 22px;
    margin-bottom: 40px;
  }

  & b {
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  position: relative;

  & h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }
`;

export const Box = styled.div`
  padding: 35px;
  border: 1px solid #ccc;
  background-color: #fcfcfc;
  border-radius: 5px;
  margin-bottom: 25px;
`;

export const Error = styled.div`
  text-align: center;
  color: #ff909a;
  margin-bottom: 30px;
`;

export const PaymentMethodList = styled.ul`
  display: flex;
`;

export const PaymentMethod = styled.li`
  min-height: 100px;
  text-align:center;
  border-radius: 5px;
  list-style: none;
  border: ${({ isSelected }) => (isSelected ? '2px solid #00c8b5;' : '1px solid #ccc;')} 
  background-color: #fff;
  padding: 20px 30px;
  
  h3 {
    font-size: 120px;
  }
`;

export const PayButton = styled(Button)`
  margin: 0 auto;
  background-color: #4ad991;
  border-color: #4ad991;
  border-radius: 5px;
  width: 330px;
  cursor: pointer;
  font-size: 20px;
  text-transform: capitalize;

  &:hover {
    background-color: #4ad991;
    border-color: #4ad991;
    opacity: 0.8;
  }
`;

export const InputHolder = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  height: 41px;
  padding: 11px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: stretch;

  & > {
    height: 100%;
  }
`;

export const Input = styled(TextInput)`
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;

  > div {
    border: none;
  }

  input {
    border: none;
    border-radius: 0;
    background-color: #fff;
    height: 39px;
    padding: 9px;
    outline-color: #fff;
    outline-offset: 0;
    font-size: 16px;
    color: #000;
  }
`;

export const Icon = styled(RioIcon)`
  margin-bottom: 10px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`;
export const Loader = styled(LoadingIndicator)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -82px;
  margin-left: -82px;
`;
