export const EMPLOYEE_NUMBERS = 'Employee Numbers';
export const OFFICE_AREA = 'Office Area';
export const TURNOVER = 'Turnover';
export const CUSTOM_1 = 'Custom 1';
export const CUSTOM_2 = 'Custom 2';
export const CUSTOM_3 = 'Custom 3';

export const unitsByType = {
  [EMPLOYEE_NUMBERS]: 'employee number',
  [OFFICE_AREA]: 'office area',
  [TURNOVER]: 'turnover',
  [CUSTOM_1]: 'custom normaliser 1',
  [CUSTOM_2]: 'custom normaliser 2',
  [CUSTOM_3]: 'custom normaliser 3'
};
