import { useCallback, useContext, useMemo } from 'react';
import { SurveyFormStateContext } from '../context/SurveyFormStateContext';
import { SurveyFormDispatchContext } from '../context/SurveyFormDispatchContext';

export enum RecipientSurveyType {
  user = 'PER_USER',
  contributor = 'PER_CONTRIBUTOR',
}

export function useSurveyForm() {
  const state = useContext(SurveyFormStateContext);
  const dispatch = useContext(SurveyFormDispatchContext);
  const surveyType = useMemo(() => {
    if (state.contributors) {
      return RecipientSurveyType.contributor;
    }
    return RecipientSurveyType.user;
  }, [state]);

  const toggleNotifyByEmail = useCallback(() => {
    dispatch({
      type: 'toggleNotifyByEmail',
    });
  }, [dispatch]);

  const toggleFeedbackEnabled = useCallback(() => {
    dispatch({
      type: 'toggleFeedbackEnabled',
    });
  }, [dispatch]);

  const toggleAutomatedScoring = useCallback(() => {
    dispatch({
      type: 'toggleAutomatedScoring',
    });
  }, [dispatch]);

  const selectIndividualContributors = useCallback(
    (ids: string[]) => {
      dispatch({
        type: 'selectIndividualContributors',
        ids,
      });
    },
    [dispatch]
  );

  const selectContributors = useCallback(
    (ids: string[]) => {
      dispatch({
        type: 'selectContributors',
        ids,
      });
    },
    [dispatch]
  );

  const changeName = useCallback(
    (value: string) => {
      dispatch({
        type: 'changeName',
        value,
      });
    },
    [dispatch]
  );

  const changeNote = useCallback(
    (value: string) => {
      dispatch({
        type: 'changeNote',
        value,
      });
    },
    [dispatch]
  );

  const changeStartDate = useCallback(
    (value: Date) => {
      dispatch({
        type: 'changeStartDate',
        value,
      });
    },
    [dispatch]
  );

  const changeDeadlineDate = useCallback(
    (value: Date) => {
      dispatch({
        type: 'changeDeadlineDate',
        value,
      });
    },
    [dispatch]
  );

  const changeSurveyTemplateId = useCallback(
    (value: string) => {
      dispatch({
        type: 'changeSurveyTemplateId',
        value,
      });
    },
    [dispatch]
  );

  const selectedIds = useMemo(() => {
    if (surveyType === RecipientSurveyType.contributor) {
      return new Set(state.contributors);
    }
    return new Set(state.individualContributors);
  }, [state.individualContributors, state.contributors, surveyType]);

  return {
    state,
    surveyType,
    toggleNotifyByEmail,
    selectIndividualContributors,
    selectContributors,
    changeName,
    toggleFeedbackEnabled,
    toggleAutomatedScoring,
    changeNote,
    changeStartDate,
    changeDeadlineDate,
    changeSurveyTemplateId,
    selectedIds,
  };
}
