import { useMemo } from 'react';
import { ManagementSystem } from '@rio/rio-types';
import { ManagementSystemPage } from '../../../../../types';
import { useNavigate } from 'react-router-dom';
import { ReportingFrameworkLayout } from '../../../../../components/ReportingFramework';
import { useRoutes } from '~/hooks';

interface CarbonDisclosureBusinessStrategyProps {
  page: ManagementSystemPage;
  framework: ManagementSystem;
}

export function CarbonDisclosureVerification({ page, framework }: CarbonDisclosureBusinessStrategyProps) {
  const navigate = useNavigate();
  const { governance: govRoutes } = useRoutes();
  const buttons = useMemo(
    () => [
      {
        title: 'Export disclosure',
        color: 'primary',
        onClick: () => {
          navigate(govRoutes.root);
        },
      },
    ],
    [navigate, govRoutes.root]
  );

  return <ReportingFrameworkLayout page={page} framework={framework} buttons={buttons} />;
}
