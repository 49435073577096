import { gql } from '@apollo/client';

export const GET_ASSETS_PAGE_BY_ACCOUNT_ID = gql`
  query GetAssetsPageByAccountId(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters!
  ) {
    getAssetsPageByAccountId(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
        description
        currency {
          code
        }
        externalId
        gicsCode {
          code
        }
        factorLink
        factorName
        factorValue
        createdAt
        deleted
        lastUpdatedAt
        accountId
      }
    }
  }
`;

export const GET_ASSETS_PAGE_BY_ACCOUNT_ID_FILTER = gql`
  query GetAssetsPageByAccountIdFilter($field: String!, $accountId: ID!) {
    getAssetsPageByAccountIdFilter(field: $field, accountId: $accountId) {
      label
      value
    }
  }
`;

export const IMPORT_ASSETS = gql`
  mutation ImportAssets($assets: [AssetInput!]!, $accountId: ID!) {
    importAssets(assets: $assets, accountId: $accountId) {
      created
      updated
      deleted
    }
  }
`;
