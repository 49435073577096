import { useCallback, useMemo } from 'react';
import { TransactionGridV2 as TransactionGrid } from '~/components/TransactionGrid';
import { GET_HOTELSTAYS_TRANSACTION_PAGE } from '../../../graphql/queries/hotelStays/GetHotelstaysTransactionsPage.query';
import { FactorUsedCell, TariffReference, UploadReferenceCell } from '../CellRenderers';
import { useHotelStaysGridConfig } from '../HotelStaysContainer/useHotelStaysGridConfig';
import { usePageSuspendingLazyQuery } from '~/hooks';

const title = 'Your hotel stays and home working data';

const breadcrumbs = [{ label: 'Data', to: '..' }, { label: 'Hotel stays and home working' }];

export const HotelStaysTransactionsGrid = () => {
  const config = useHotelStaysGridConfig();
  const [query] = usePageSuspendingLazyQuery(GET_HOTELSTAYS_TRANSACTION_PAGE, {
    fetchPolicy: 'network-only',
  });
  const fullConfig = useMemo(
    () => ({
      ...config,
      components: {
        tariffReference: TariffReference,
        uploadReference: UploadReferenceCell,
        link: FactorUsedCell,
      },
    }),
    [config]
  );

  const fetchRows = useCallback(
    async (variables) => {
      const {
        data: {
          getHotelStaysTransactionsPage: { totalRows, rows },
        },
      } = await query({
        variables,
      });
      return {
        rows,
        totalRows,
      };
    },
    [query]
  );

  return (
    <TransactionGrid
      title={title}
      breadcrumbs={breadcrumbs}
      config={fullConfig}
      fetchRows={fetchRows}
      uploadLink="../hotelStays/uploads"
      defaultExportFileName="Hotel stays & home work export"
    />
  );
};
