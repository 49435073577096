import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Col, Search, LoadingIndicator } from 'rio-ui-components';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_ACCOUNTS } from '../../../components/AccountsMultiSelect/index.queries';
import AccountRow from './AccountRow';
import NoResults from '../../../components/NoResults';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

const ManagementSystemsSubscriptions = () => {
  const [searchValue, setSearchValue] = useState('');
  const { pathname } = useLocation();
  const { data, loading } = useQuery(GET_ACCOUNTS, {
    variables: {
      limit: 100,
      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: searchValue,
            field: 'name'
          }
        ]
      }
    }
  });

  const searchedData = data?.getAccounts.rows || [];

  return (
    <ContainerStyled>
      <Col name="ConfigurationMssContainer" container fullHeight>
        <ContainerHeader
          name="ConfigurationMssSubscriptionsContainer__Controls"
          icon="briefcase"
          iconColor="primary"
          title="Management Systems Subscriptions"
        >
          <Col container item>
            <Search
              name="ConfigurationMssSubscriptionsContainer__Controls__Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              hideButton
            />
          </Col>
        </ContainerHeader>
      </Col>
      {loading && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {!loading && !searchedData.length && (
        <NoResults
          name="ConfigurationIntelligenceSubscriptionsContainer__NoResults"
          title="There are no results for this search."
        />
      )}

      {!!searchedData.length &&
        searchedData.slice().map((account) => {
          return (
            <AccountRow
              key={account.id}
              accountId={account.id}
              businessName={account.name}
              profileImage={account.profile}
              path={pathname}
            />
          );
        })}
    </ContainerStyled>
  );
};

export default ManagementSystemsSubscriptions;
