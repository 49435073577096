import React from 'react';
import { Text, TextLink } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TableStyled = styled.table`
  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.overlay.light.background};
  }
`;

const TableHeaderItem = styled.th`
  padding: ${({ theme }) => theme.geometry.xs.spacing};
  background: ${({ theme }) => theme.colors.overlay.light.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
  border-right: 1px solid ${({ theme }) => theme.colors.overlay.normal.background};
  font-weight: 600;
`;

const TableBodyItem = styled.td`
  padding: ${({ theme }) => theme.geometry.xs.spacing};
`;

const TextLinkStyled = styled(TextLink)`
  display: inline;
  word-wrap: break-word;
  hyphens: auto;
`;

const DivStyled = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 32px;
  font-size: ${({ theme }) => theme.fonts.md.size};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.normal.background};
`;

const TextStyled = styled(Text)`
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 32px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export function CommonFactorsReportingYearDescription() {
  return (
    <>
      <TextStyled weight="bold">Reporting year</TextStyled>
      <TextStyled>
        If you would like to report in calendar years or financial years, please ask your Customer Support Manager to
        configure your account. Visit your{' '}
        <StyledLink to="/configuration/accountconfig/accounts">Account Configuration</StyledLink> page to see how your
        reporting period is currently configured.
      </TextStyled>
    </>
  );
}

export function CommonFactorsNoteDescription() {
  return (
    <>
      <TextStyled weight="bold">Note:</TextStyled>
      <TextStyled>
        Rio has been configured to account for data ranging across two annual government conversion factor datasets as
        above. In the unlikely event that factors for the most recent year are not yet published, the most recent
        available conversion factor will be used. Once new factors are published, Rio will update any calculated carbon
        emissions.
      </TextStyled>
      <TextStyled>
        If you wish to configure reporting year to calendar or financial you will need to contact your Customer Support
        Manager as Rio will not re-calculate carbon emissions.
      </TextStyled>
    </>
  );
}

export function CommonFactorsDescription() {
  return (
    <>
      {CommonFactorsReportingYearDescription()}
      {CommonFactorsNoteDescription()}
    </>
  );
}

export function WasteFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your selected waste stream has been based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government GHG Conversion Factors for Company Reporting
        </TextLinkStyled>
        . Rio has associated the most appropriate conversion factor for your waste stream based on its physical
        properties and EWC code and the treatment processes you are applying.
      </TextStyled>
      <TextStyled>
        These factors cannot be used to determine the relative lifecycle merit of different waste management options.
        This is because the benefits of energy recovery and recycling are attributed to the user of the recycled
        materials, not the producer of the waste, in line with{' '}
        <TextLinkStyled href="https://ghgprotocol.org/corporate-standard" target="_blank" rel="noopener noreferrer">
          GHG Protocol Guidelines.
        </TextLinkStyled>
      </TextStyled>
    </>
  );
}

export function GasFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your gas consumption are based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
    </>
  );
}

export function WaterFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your water consumption are based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
    </>
  );
}

export function TransportFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your travel data are based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
      <TextStyled>Actual fuel data is used in preference distance and cost conversions.</TextStyled>
    </>
  );
}

const fuelScopeDescriptions = [
  {
    scope: 1,
    column: 'Combustion',
    comment: 'Included to Scope 1 if activity is direct, otherwise, goes to Scope 3',
  },
  {
    scope: 3,
    column: 'Well-to-tank',
    comment: 'Always included to Scope 3',
  },
];

export function FuelFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your Fuel consumption are based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
      <TextStyled weight="bold">Scopes</TextStyled>
      <TextStyled>
        Here is the explanation of how Rio utilises these factors to calculate Scope 1 and Scope 3 for your fuel
        transactions:
      </TextStyled>
      <DivStyled>
        <TableStyled>
          <thead>
            <tr>
              <TableHeaderItem>Scope</TableHeaderItem>
              <TableHeaderItem>Column</TableHeaderItem>
              <TableHeaderItem>Comment</TableHeaderItem>
            </tr>
          </thead>
          <tbody>
            {fuelScopeDescriptions.map((description, key) => (
              <tr key={key}>
                <TableBodyItem>{description.scope}</TableBodyItem>
                <TableBodyItem>{description.column}</TableBodyItem>
                <TableBodyItem>{description.comment}</TableBodyItem>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </DivStyled>
    </>
  );
}

export function ElectricityFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your electricity consumed in the UK are based on the{' '}
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
      <TextStyled>
        Overseas CO2 emission factors are based on data published by the International Energy Agency (IEA) from 2023.
        This includes emission factors for generation, electricity trade, and transmission and distribution losses.
      </TextStyled>
      <TextStyled>
        The IEA conversion factors have varying associated timeframes. The IEA conversion factor are annual figures (1st
        Jan-31st Dec). If the timeframe appropriate for the calculation is not available, Rio will use the conversion
        factor for the most recent year.
      </TextStyled>
      <CommonFactorsDescription />
    </>
  );
}

export function RefrigerantFactorsDescription() {
  return (
    <>
      <TextStyled>
        The carbon conversion factors applied to your refrigerants and other gasses are based on the
        <TextLinkStyled
          href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          UK Government conversion factors for company reporting of greenhouse gas emissions.
        </TextLinkStyled>
      </TextStyled>
    </>
  );
}

export function HeatFactorsDescription() {
  const scope3description = {
    scope: 3,
    comment: 'Always included to Scope 3',
  };
  const scopeDescriptions = [
    {
      scope: 2,
      column: 'Generation',
      comment: 'Included to Scope 2 if activity is direct, otherwise, included to Scope 3',
    },
    {
      ...scope3description,
      column: 'Transmission & distribution',
    },
    {
      ...scope3description,
      column: 'Well-to-tank generation',
    },
    {
      ...scope3description,
      column: 'Well-to-tank transmission and distribution',
    },
  ];

  return (
    <>
      {WaterFactorsDescription()}
      {CommonFactorsReportingYearDescription()}
      <TextStyled weight="bold">Scopes</TextStyled>
      <TextStyled>
        Here is the explanation of how Rio utilises these factors to calculate Scope 2 and Scope 3 for your heat & steam
        transactions:
      </TextStyled>
      <DivStyled>
        <TableStyled>
          <thead>
            <tr>
              <TableHeaderItem>Scope</TableHeaderItem>
              <TableHeaderItem>Column</TableHeaderItem>
              <TableHeaderItem>Comment</TableHeaderItem>
            </tr>
          </thead>
          <tbody>
            {scopeDescriptions.map((description, key) => (
              <tr key={key}>
                <TableBodyItem>{description.scope}</TableBodyItem>
                <TableBodyItem>{description.column}</TableBodyItem>
                <TableBodyItem>{description.comment}</TableBodyItem>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </DivStyled>
      {CommonFactorsNoteDescription()}
    </>
  );
}

export function MaterialConversionFactorsDescription() {
  return (
    <>
      <TextStyled>
        Material use conversion factors should be used to report on consumption of procured materials based on their
        origin (that is, comprised of primary material or recycled materials). For primary materials, these factors
        cover the extraction, primary processing, manufacturing and transporting materials to the point of sale, not the
        materials in use. For secondary materials, the factors cover sorting, processing, manufacturing and transporting
        to the point of sale, not the materials in use. These factors are useful for reporting efficiencies gained
        through reduced procurement of material or the benefit of procuring items that are the product of a previous
        recycling process.
      </TextStyled>
      <TextStyled>
        The carbon conversion factors applied to your Materials consumption are based on the UK Government conversion
        factors for company reporting of greenhouse gas emissions.
      </TextStyled>
    </>
  );
}
