"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageLoadingContext = exports.LoadingContextProvider = exports.LoadingContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const defaultValue = {
    loading: false,
    setLoading: (_) => { },
};
exports.LoadingContext = (0, react_1.createContext)(defaultValue);
const LoadingContextProvider = ({ children }) => {
    const [loading, setLoading] = (0, react_1.useState)(false);
    const value = (0, react_1.useMemo)(() => ({
        loading,
        setLoading,
    }), [loading, setLoading]);
    return (0, jsx_runtime_1.jsx)(exports.LoadingContext.Provider, { value: value, children: children });
};
exports.LoadingContextProvider = LoadingContextProvider;
const usePageLoadingContext = (props = {}) => {
    const context = (0, react_1.useContext)(exports.LoadingContext);
    (0, react_1.useEffect)(() => {
        if (props.defaultValue) {
            context.setLoading(props.defaultValue);
        }
    }, []);
    return context;
};
exports.usePageLoadingContext = usePageLoadingContext;
