import React, { memo } from 'react';
import LearnBundleRow from './LearnBundleRow';
import NoResults from '../../../components/NoResults';
import styled from 'styled-components';

const NoResultsWrapper = styled.div`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const LearnBundleRows = ({ checkedElements, courses, search, onChecked, changeTyping, changeOrder }) => {
  const searchedCourses = search ? courses.filter((course) => course.name.toLowerCase().includes(search)) : courses;

  return searchedCourses.length ? (
    searchedCourses.map((course) => <LearnBundleRow checkedElements={checkedElements} courseId={course.id} courseTitle={course.name} courseType={course.type} onChecked={() => onChecked(course)} key={course.id} changeTyping={(e) => changeTyping(e, course)} changeOrder={() => changeOrder(course)} />)
  ) : (
    <NoResultsWrapper>
      <NoResults name="LearnUploads__NoResults" title="There are no results for this search." />
    </NoResultsWrapper>
  );
};
export default memo(LearnBundleRows);
