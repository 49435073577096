import { AliasType, TransactionType } from '@rio/rio-types';
import { Row, Icon, Text, Col } from 'rio-ui-components';
import styled, { keyframes as styledKeyframes, css } from 'styled-components';
import { Nullable, Option } from '../../types';
import { AliasParentSelect } from './AliasParentSelect';

const keyframes: any = styledKeyframes;

const grow = keyframes`
  from {
    height:0;
    overflow:hidden;
    padding: 0px ${(props: any) => `${props.theme.geometry.md.spacing}`};
    opacity:0;
  }
  to {
    height: auto;
    padding: ${(props: any) => `${props.theme.geometry.md.spacing}`};
    opacity:1;
  }
`;
const growAnimation = css`
  ${grow} 0.2s;
`;
const AliasRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  align-items: start;
  animation: ${growAnimation};
`;
const StyledIconSuccess = styled(Icon)`
  color: ${(props) => props.theme.colors.success.normal.background};
  transition: opacity 0.2s, transform 0.2s;
  transform: ${(props) => (props.matched && !props.loading ? `` : 'rotate(-180deg) scale(.5)')};
  opacity: ${(props) => (props.matched && !props.loading ? `1` : '0')};
`;
const StyledIconDanger = styled(Icon)`
  color: ${(props) => props.theme.colors.danger.normal.background};
  transition: opacity 0.2s, transform 0.2s;
  transform: ${(props) => (!props.matched && !props.loading ? `` : 'rotate(-180deg) scale(.5)')};
  opacity: ${(props) => (!props.matched && !props.loading ? `1` : '0')};
`;
const IconContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`;
const AnimateCol = styled(Col)`
  position: relative;
`;
const StyledCol = styled(Col)`
  margin-left: auto;
`;
interface AliasRowProps {
  transactionType: TransactionType;
  aliasType: AliasType;
  unknownString?: string | null;
  accountId: string;
  value: Nullable<string | Option>;
  onChange: (id: string, name: string) => void;
  possibleOptions?: Nullable<string[]>;
}

export const AliasRow = ({
  transactionType,
  aliasType,
  unknownString,
  value,
  onChange,
  accountId,
  possibleOptions,
  ...rest
}: AliasRowProps) => {
  return (
    <AliasRowStyled container {...rest}>
      <AnimateCol item span={1}>
        <IconContainer>
          {value ? (
            <StyledIconSuccess matched={value} icon="check" />
          ) : (
            <StyledIconDanger matched={value} icon="times" />
          )}
        </IconContainer>
      </AnimateCol>
      <Col item span={4}>
        <Text>{unknownString}</Text>
      </Col>
      <StyledCol item span={4}>
        <AliasParentSelect
          transactionType={transactionType}
          aliasType={aliasType}
          value={value}
          accountId={accountId}
          onChange={onChange}
          possibleOptions={possibleOptions}
        />
      </StyledCol>
    </AliasRowStyled>
  );
};
