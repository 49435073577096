import { useQuery } from '@apollo/client';
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_FUEL_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface FuelTypeSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
}
export function FuelTypeSelect({ value, onChange }: FuelTypeSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllFuelTypes'>>(GET_ALL_FUEL_TYPES);
  return (
    <Select
      placeholder="Please select type of fuel"
      value={value}
      options={toSelectOptions(data?.getAllFuelTypes)}
      error={error}
      isLoading={loading}
      onChange={onChange}
    />
  );
}
