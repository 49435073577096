import React, { useState } from 'react';
import styled from 'styled-components';
import * as JsSearch from 'js-search';

import { injectIntl } from 'react-intl';
import { Row, Search, Icon, TextLink } from 'rio-ui-components';
import NotificationBanner from '../../../components/NotificationBanner';
import PageHeader from '../../../components/PageHeader';
import GovernanceAreaGrid from './GovernanceAreaGrid';
import GovernanceAreaList from './GovernanceAreaList';
import areas from '../areas';
import * as _ from 'lodash';
import { checkRestrictions } from './utils';
import { useViewPreference } from '../../../hooks/useViewPreference';
import { useManagementSystems } from '../../../hooks/useManagementSystems';
import { usePermissions } from '../../../hooks/usePermissions';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

const GovernanceLandingPage = (props) => {
  const { intl, accessControls, isShouldUserUpgrade } = props;
  const [searchValue, setSearchValue] = useState('');
  const availableSystems = useManagementSystems();
  const permissions = usePermissions();

  const allowedAreas = areas.filter(
    (item) => !!permissions[item.permissionSection]?.find((perm) => perm.startsWith(item.basePermission))
  );

  const [restrictedAreas, openAreas] = _.partition(allowedAreas, (area) => {
    return !!area.restricted;
  });
  const allowedRestrictions = checkRestrictions(restrictedAreas, { systems: availableSystems });

  const validAreas = openAreas.concat(allowedRestrictions);

  const search = (data, term) => {
    if (!term) return data;
    const searchJs = new JsSearch.Search('id');
    searchJs.addIndex('title');
    searchJs.addDocuments(data);
    return searchJs.search(term);
  };

  const searchedAreas = search(validAreas, searchValue);
  const { isList, isGrid, showGrid, showList } = useViewPreference();

  return (
    <ContainerStyled name="GovernanceLandingPage">
      <PageHeader
        name="GovernanceLandingPageHeader"
        title={intl.formatMessage({ id: 'pages.governance.heading' })}
        breadcrumbs={[
          { title: intl.formatMessage({ id: 'pages.governance.breadcrumbs.one' }) },
          { title: intl.formatMessage({ id: 'pages.governance.breadcrumbs.two' }) },
        ]}
        icon="pencil-alt"
        iconColor="tertiary"
      />

      <ContentContainer>
        <SearchHeader container item itemAlign="center">
          <SearchStyled
            name="DataSubjects__Search"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            hideButton
          />
          <TextLinkStyled
            name="DataSubjects__ShowGrid"
            size="md"
            color={isGrid ? 'primary' : 'normal'}
            weight={isGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={showGrid}
            inline
          >
            <Icon icon="th" />
          </TextLinkStyled>
          <TextLinkStyled
            name="DataSubjects__ShowList"
            size="md"
            color={isList ? 'primary' : 'normal'}
            weight={isList ? 'bold' : 'normal'}
            underline={false}
            onClick={showList}
            inline
          >
            <Icon icon="th-list" />
          </TextLinkStyled>
        </SearchHeader>

        {isShouldUserUpgrade && (
          <NotificationBannerStyled
            name="ComplyContainer_Access_NotificationBanner"
            icon="exclamation-triangle"
            color="danger"
            title={`Governance`}
            body={intl.formatMessage({ id: 'pages.governance.permissionsProblem' })}
          />
        )}

        {isGrid && <GovernanceAreaGrid items={searchedAreas} fullAccess={accessControls} />}
        {!isGrid && <GovernanceAreaList items={searchedAreas} fullAccess={accessControls} />}
      </ContentContainer>
    </ContainerStyled>
  );
};

export default injectIntl(GovernanceLandingPage);
