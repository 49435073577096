import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries/licence/GetLicencesByProductAndAccount.query.graphql';
import { licenceStatus } from '../../constants';

export function useGetLicencesByProductAndAccountQuery(productId, accountId) {
  const { data: { getLicencesByProductAndAccount = [] } = {}, ...others } = useQuery(QUERY, {
    variables: {
      productId,
      accountId
    },
    fetchPolicy: 'cache-and-network'
  });

  const licences = getLicencesByProductAndAccount
    ? getLicencesByProductAndAccount.map((licence) => ({
        ...licence,
        isNew: licence.status === licenceStatus.NEW
      }))
    : [];

  return { data: licences, ...others };
}
