import { IDeveloperField } from 'dromo-uploader-react';

interface SchemaData {
  fields: IDeveloperField[];
  data?: object;
}

export function parseData(dromoSchemaString: string): SchemaData {
  if (!dromoSchemaString) {
    return {
      fields: [],
      data: {},
    };
  }

  return JSON.parse(dromoSchemaString);
}
