import React from 'react';
import styled from 'styled-components';
import { Col, CircleIcon, Heading, Text } from 'rio-ui-components';

const ContainerStyled = styled(Col)`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  background-color: ${(props) => props.theme.colors.basic.white};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.geometry.xs.spacing} 0;
`;

const CircleIconStyled = styled(CircleIcon)`
  display: inline-flex;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.basic.white};
`;

const TextStyled = styled(Text)`
  font-size: ${(props) => props.theme.fonts.sm.size};
  padding: 0 ${(props) => props.theme.geometry.lg.spacing};
`;

const ComingSoonPill = styled.div`
  position: absolute;
  right: ${(props) => props.theme.geometry.sm.spacing};
  top: ${(props) => props.theme.geometry.sm.spacing};
  flex: 0 0 auto;
  opacity: 0.7;
  display: inline-block;
  background: ${(props) => props.theme.colors.text.dark.background};
  padding: ${(props) => props.theme.geometry.xs.spacing} ${(props) => props.theme.geometry.sm.spacing};
`;

const GovernanceAreaTile = ({ title, description, icon, iconColor, comingSoon, fullAccess }) => {
  return (
    <ContainerStyled name={`SubjectTile SubjectTile--${title}`} container item disabled={comingSoon || !fullAccess}>
      {(comingSoon || !fullAccess) && (
        <ComingSoonPill>
          <Text align="center" size="xs" color="white">
            {comingSoon ? 'Coming Soon' : 'Upgrade Now'}
          </Text>
        </ComingSoonPill>
      )}
      <CenteredContainer>
        <CircleIconStyled name="SubjectTile__Icon" height="70px" size="lg" icon={icon} iconColor={iconColor} circleColor={iconColor} />
      </CenteredContainer>
      <CenteredContainer>
        <Heading align="center" size="lg">
          {title}
        </Heading>
      </CenteredContainer>
      <CenteredContainer>
        <TextStyled align="center">{description}</TextStyled>
      </CenteredContainer>
    </ContainerStyled>
  );
};

export default GovernanceAreaTile;
