import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SurveyContributor, SurveySubmission } from '@rio/rio-types';
import { AgGridReact } from 'ag-grid-react';
import { Icon, Notification } from 'rio-ui-components';
import styled from 'styled-components';
import { ColDef, GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridSimple } from '../../../components/AgGridStyled';
import { alphabetiseStrings } from '~/utils';
import { StatusSpan, formatSubmissionStatus } from '~/components/Surveys';
import { getPerformanceColor, getPerformanceStatus } from './charts/calculateScores';
import { FlexContainer, ProgressBarChart } from './charts';

type ContributorsSubmissionInformation = {
  contributor: SurveyContributor;
  submission?: SurveySubmission;
  reviewable: boolean;
  performance?: number;
};

type GridContainerProps = {
  v2: boolean;
};

interface ContributorsGridProps {
  submissionDetails: ContributorsSubmissionInformation[];
  showPerforance: boolean;
}

const ROW_HEIGHT = 50;

/**
 * @see https://ag-grid.com/react-data-grid/global-style-customisation-variables/#reference-variables---ag-data-color
 */
const GridContainer = styled(AgGridSimple)<GridContainerProps>`
  --ag-row-height: ${ROW_HEIGHT}px;
  --ag-row-hover-color: #e8e9ec;
  font-family: ${(props) => props.v2 && 'Plus Jakarta Sans'};

  & .ag-paging-panel {
    justify-content: flex-start;
  }

  .ag-root-wrapper-body {
    height: 100%;
  }

  && .ag-row:hover {
    cursor: pointer;
  }
  &&&& .ag-row .ag-cell {
    line-height: 17px;
  }
  &&& .ag-row .ag-cell:first-child {
    padding-left: 12px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FlexContainerColumn = styled(FlexContainer)`
  flex-direction: column;
`;

const columnDefs = (showPerformance: boolean): ColDef[] => {
  return [
    {
      headerName: 'Name',
      valueGetter: ({ data: { contributor } }) =>
        contributor.user
          ? `${contributor.user.first_name} ${contributor.user.last_name} (${contributor.user.email})`
          : contributor.account.name,
      sort: 'asc' as 'asc',
      comparator: alphabetiseStrings,
      flex: 100,
    },
    {
      headerName: 'Contributor Type',
      valueGetter: ({ data: { contributor } }) => (contributor.user ? 'Contributor' : 'Child Account'),
      flex: 100,
    },
    {
      headerName: 'Response Status',
      cellRenderer: 'status',
      flex: 100,
    },
    {
      headerName: 'Performance',
      cellRenderer: 'performance',
      flex: 100,
      hide: !showPerformance,
    },
    {
      headerName: 'Chevron',
      cellRenderer: 'chevron',
      flex: 1,
    },
  ];
};
const defaultColDef = {
  filter: false,
};

const components = {
  status: ({ data }) => (
    <StatusSpan status={data.submission?.status}>{formatSubmissionStatus(data.submission?.status)}</StatusSpan>
  ),
  performance: ({ data }) => {
    return (
      <>
        {data.performance && (
          <FlexContainerColumn>
            <ProgressBarChart
              text={`${getPerformanceStatus(data.performance)} overall performance`}
              totals={[data.performance, 100]}
              color={getPerformanceColor(data.performance)}
            />
          </FlexContainerColumn>
        )}
        {!data.performance && '-'}
      </>
    );
  },
  chevron: ({ data }) => {
    return (
      <>
        {data.reviewable && (
          <IconContainer>
            <Icon icon="chevron-right" color="#83838e" />
          </IconContainer>
        )}
      </>
    );
  },
};

const getRowId = (params: GetRowIdParams) => params.data.contributor.account.id;

export const SurveyContributorsResponseGrid = ({ submissionDetails, showPerforance }: ContributorsGridProps) => {
  const location = useLocation();
  const v2 = location.pathname.startsWith('/v2');
  const [notification, setNotification] = useState('');

  const hideNotification = () => {
    setNotification('');
  };
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (e: RowClickedEvent) => {
      if (e.data.reviewable) {
        navigate(`../sent/contributors/${e.data.contributor.account.id}/submission/${e.data.submission.id}`);
      } else {
        setNotification('This response is not currently in a reviewable state.');
      }
    },
    [navigate, setNotification]
  );

  const columns = columnDefs(showPerforance);
  return (
    <>
      {notification && (
        <Notification show color="danger" onClose={hideNotification}>
          {notification}
        </Notification>
      )}
      <GridContainer v2={v2} className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={defaultColDef}
          rowHeight={ROW_HEIGHT}
          components={components}
          rowData={submissionDetails}
          paginationPageSize={25}
          getRowId={getRowId}
          columnDefs={columns}
          onRowClicked={handleRowClick}
          pagination
        />
      </GridContainer>
    </>
  );
};
