import { keyframes, css } from 'styled-components';

const slide = keyframes`
  0% {transform:translateX(-100%);}
  100% {transform:translateX(100%);}
`;

const slideAnimation = css`
  ${slide} 1s infinite 0s;
`;

const shineCss = css`
  &:after {
    content: '';
    color: black;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    animation: ${slideAnimation};
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  }
`;

export default shineCss;
