import { useCallback } from 'react';
import { useCurrentAccountId } from '~/hooks';
import { useGetByLatest, GetSurveyLatestResponse } from '../../queries';
import { SurveyOverviewPage } from './SurveyOverviewPage';
import { ActionTypes, SurveyOverviewHeader } from './SurveyOverviewHeader';

type SurveyLatestRetrieverProps = {
  action?: ActionTypes;
};

export function SurveyLatestRetriever({ action }: SurveyLatestRetrieverProps) {
  const currentAccountId = useCurrentAccountId();
  const result = useGetByLatest(currentAccountId);

  const renderData = useCallback(
    ({ getSurveyLatest }: GetSurveyLatestResponse) =>
      action ? (
        <SurveyOverviewHeader survey={getSurveyLatest ?? null} action={action} />
      ) : (
        <SurveyOverviewPage survey={getSurveyLatest} latest={true} />
      ),
    [action]
  );

  return result?.data ? renderData(result.data) : null;
}
