import React from 'react';
import { Row, Col, Text } from 'rio-ui-components';
import styled from 'styled-components';

const RowStyled = styled(Row)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  ${(p) => {
    if (p.action) return `background-color: ${p.theme.colors.warning.highlight.background};`;
  }};
`;

const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
  word-break: break-all;
  flex: 1;
`;

const getDisplayedValue = (config, column, containerType) => {
  return config[column] && typeof config[column].format === 'function'
    ? config[column].format(containerType[column])
    : containerType[column];
};

export function ContainerTypeRow({ containerType, config }) {
  return (
    <RowStyled container>
      {Object.keys(config).map((column) => (
        <ColStyled item key={column} span={config[column].width}>
          <Text>{getDisplayedValue(config, column, containerType)}</Text>
        </ColStyled>
      ))}
    </RowStyled>
  );
}
