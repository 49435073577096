import React from 'react';
import styled from 'styled-components';
import { Row, Search, Icon, TextLink } from 'rio-ui-components';

import NoResults from '../../../../components/NoResults';

import ReportingReportsGrid from '../ReportingReportsGrid';
import ReportingReportsList from '../ReportingReportsList';
import ReportSubjectFilter from '../../ReportSubjectFilter';
import { useSearch } from '../../../../hooks/useSearch';
import { useViewPreference } from '../../../../hooks/useViewPreference';

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.xl.spacing};
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;

const SearchHeader = styled(Row)`
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

const FilterContainer = styled.div`
  padding: 10px 0px 0px 10px;
`;

function ReportingReports({ dashboards, filterOnClick, subjectFilters }) {
  const [searchValue, setSearchValue, searchResults = []] = useSearch(dashboards, ['name']);
  const { showGrid, showList, isGrid, isList } = useViewPreference();
  return (
    <ContentContainer>
      <Row container>
        <SearchHeader container item itemAlign="center">
          <SearchStyled
            name="ReportingReports__Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            hideButton
          />
          <TextLinkStyled
            name="ReportingReports__ShowGrid"
            size="md"
            color={isGrid ? 'primary' : 'normal'}
            weight={showGrid ? 'bold' : 'normal'}
            underline={false}
            onClick={showGrid}
            inline
          >
            <Icon icon="th" />
          </TextLinkStyled>
          <TextLinkStyled
            name="ReportingReports__ShowList"
            size="md"
            color={isList ? 'primary' : 'normal'}
            weight={showList ? 'bold' : 'normal'}
            underline={false}
            onClick={showList}
            inline
          >
            <Icon icon="th-list" />
          </TextLinkStyled>
        </SearchHeader>
        <FilterContainer>
          <ReportSubjectFilter
            onClick={filterOnClick}
            showLearn={subjectFilters.showLearn}
            showComply={subjectFilters.showComply}
            showData={subjectFilters.showData}
          />
        </FilterContainer>
      </Row>
      {searchResults.length > 0 && isGrid && <ReportingReportsGrid items={searchResults} />}
      {searchResults.length > 0 && isList && <ReportingReportsList items={searchResults} />}
      {!searchResults.length > 0 && (
        <NoResults name="ReportingReports__NoResults" title="There are no results for this search." />
      )}
    </ContentContainer>
  );
}
export default ReportingReports;
