import { Col, Row } from 'rio-ui-components';
import styled from 'styled-components';
import { TransactionType } from '@rio/rio-types';

export const ColStyled = styled(Col)`
  flex: ${(props) => props.span};
  padding: ${(p) => p.theme.geometry.md.profile.padding};
`;
export const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
`;
export const getGridConfig = (utility) => [
  {
    field: 'name',
    name: 'Name',
    width: 4
  },
  {
    field: 'address',
    name: 'Address',
    width: 4
  },
  utility === TransactionType.Materials
    ? {
        field: 'material',
        name: 'Materials',
        width: 3
      }
    : {
        field: 'type',
        name: 'Type',
        width: 3
      },
  {
    field: 'level',
    name: 'Level',
    width: 2
  },
  {
    field: null,
    name: null,
    width: 1
  }
];
