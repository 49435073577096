import { LinkStyled as RouterLink, TextLink } from './elements';

interface FactorUsedCellProps {
  value: number;
  data: {
    factorsArePrivate: string;
    __typename: string;
  };
  to: string;
  type?: string;
}

const getUrl = (to: string, reportingYear: number, type?: string) => {
  let toUrl = `${to}?reportingYear=${reportingYear}`;

  if (type) {
    toUrl += `&type=${type}`;
  }

  return toUrl;
};

export const FactorUsedCell = ({ value, data, to, type }: FactorUsedCellProps) => {
  if (!value) {
    return null;
  }
  if (data.factorsArePrivate) {
    return (
      <TextLink
        component="a"
        href="https://www.iea.org/data-and-statistics/data-product/emissions-factors-2021"
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </TextLink>
    );
  }
  return <RouterLink to={getUrl(to, value, type)}>{value}</RouterLink>;
};
