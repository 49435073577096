import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, TextField } from '@rio/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from './Form';
import { AuthPage } from './AuthPage';
import { TextLink, ButtonStyled, ErrorTextStyled } from './style';

export const ContactsForm = ({ handleChange, errors, form, queryParams, setErrors, handleContactsSubmit }) => {
  const intl = useIntl();
  const error = errors?.submit;
  const errorMessages = {
    'An account with the given email already exists.': (
      <>
        <FormattedMessage
          id="login.errors.existAccount"
          values={{
            email: form.email,
          }}
        />
        <TextLink
          to={`/v2/register${queryParams}`}
          onClick={() => {
            setErrors({});
          }}
        >
          <FormattedMessage id="login.errors.changeEmail" />
        </TextLink>
      </>
    ),
  };

  const RenderRegisterError = ({ registrationError }) => {
    if (!registrationError) return null;

    return (
      <ErrorTextStyled size="large" typescale="label">
        {registrationError}
      </ErrorTextStyled>
    );
  };

  return (
    <AuthPage title="login.registration.contactPage.title">
      <Form
        name="contacts"
        form={form}
        setErrors={setErrors}
        onSubmit={() => handleContactsSubmit(errorMessages as any)}
      >
        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <TextField
              type="text"
              onChange={handleChange}
              error={!!errors.jobTitle}
              label={intl.formatMessage({ id: 'login.registration.contactPage.jobTitle' })}
              helperText={errors.jobTitle && intl.formatMessage({ id: `login.errors.validation.${errors.jobTitle}` })}
              value={form.jobTitle}
              autoComplete="job"
              name="jobTitle"
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              type="text"
              error={!!errors.department}
              label={intl.formatMessage({ id: 'login.registration.contactPage.department' })}
              helperText={
                errors.department && intl.formatMessage({ id: `login.errors.validation.${errors.department}` })
              }
              onChange={handleChange}
              value={form.department}
              autoComplete="department"
              name="department"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <TextField
              type="phone"
              onChange={handleChange}
              error={!!errors.phone}
              label={intl.formatMessage({ id: 'login.registration.contactPage.phoneNumber' })}
              helperText={errors.phone && intl.formatMessage({ id: `login.errors.validation.${errors.phone}` })}
              value={form.phone}
              autoComplete="tel"
              name="phone"
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              type="phone"
              onChange={handleChange}
              error={!!errors.mobile}
              label={intl.formatMessage({ id: 'login.registration.contactPage.mobileNumber' })}
              helperText={errors.mobile && intl.formatMessage({ id: `login.errors.validation.${errors.mobile}` })}
              value={form.mobile}
              autoComplete="tel"
              name="mobile"
            />
          </Grid>
        </Grid>
        {error && <RenderRegisterError registrationError={error} />}
        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <Link to="/v2/register/profile">
              <ButtonStyled variant="outlined" type="button" color="primary">
                <FormattedMessage id="login.registration.contactPage.backButtonText" />
              </ButtonStyled>
            </Link>
          </Grid>
          <Grid xs={6}>
            <ButtonStyled variant="filled" type="submit" color="primary">
              <FormattedMessage id="login.registration.contactPage.finishButtonText" />
            </ButtonStyled>
          </Grid>
        </Grid>
      </Form>
    </AuthPage>
  );
};
