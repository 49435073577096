import { gql } from "@apollo/client";

export const GET_LOCATIONS_BY_ACCOUNT = gql`
  query GetLocationsByAccountId($accountId: ID!, $offset: Int = 0, $limit: Int = 25, $sort: [SortCommand!] = [], $filters: Filters = {}) {
    getLocationsPage(accountId: $accountId, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;

export const GET_WASTE_IN_LOCATIONS_BY_ACCOUNT_ID = gql`
  query GetWasteInsByAccountId($id: ID!, $offset: Int = 0, $limit: Int = 25, $sort: [SortCommand!] = [], $filters: Filters = {}) {
    getWasteInsByAccountId(id: $id, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      id
      name
    }
  }
`;

export const GET_WASTE_OUT_LOCATIONS_BY_ACCOUNT_ID = gql`
  query GetWasteOutsByAccountId($id: ID!, $offset: Int = 0, $limit: Int = 25, $sort: [SortCommand!] = [], $filters: Filters = {}) {
    getWasteOutsByAccountId(id: $id, offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      id
      name
    }
  }
`;
