import { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled, { useTheme } from 'styled-components';
import { Theme } from '../../../../types';
import { baseProgressBarChart } from './baseProgressBarChart';

interface ProgressBarChartProps {
  text: string;
  totals: [number, number];
  color: string;
  className?: string;
}

const chart = (theme: Theme, percent: number, percentColor: string): Highcharts.Options => ({
  ...baseProgressBarChart,
  series: [
    ...(baseProgressBarChart.series as Highcharts.SeriesOptionsType[]),
    {
      data: [percent],
      color: percentColor,
      type: 'bar',
      borderRadius: 6
    }
  ]
});

const ChartText = styled.div`
  margin-top: 8px;
  flex: 1;
  color: #4d505b;
`;
const ChartTextRight = styled.div`
  margin-top: 8px;
  text-align: right;
  font-size: 14px;
`;
const ChartLightText = styled.span`
  color: #83838e;
`;
export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap;
`;
export const Column = styled.div`
  margin-left: 50px;

  &.${(props) => props.className || 'progress-bar--column'} {
    margin-bottom: 20px;
    flex: 0 0 calc(33% - 50px);
  }
`;

export function ProgressBarChart({ text, totals, color, className = 'progress-bar--column' }: ProgressBarChartProps) {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const percent = totals[0] > 0 ? (totals[0] / totals[1]) * 100 : 0;
  const chartCached = useMemo(() => chart(theme, percent, color), [theme, percent, color]);

  return (
    <Column className={className}>
      <HighchartsReact highcharts={Highcharts} options={chartCached} ref={chartRef} />
      <FlexContainer>
        <ChartText color="normal">{text}</ChartText>
        <ChartTextRight color="normal">
          {totals[0]} / <ChartLightText>{totals[1]}</ChartLightText>
        </ChartTextRight>
      </FlexContainer>
    </Column>
  );
}
