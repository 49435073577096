import { LocationSelect } from '../LocationSelect';

export function LocationSelectRender(props) {
  const { value, onChange, name, trigger = () => {}, mapper = () => {}, error, ...others } = props;

  return (
    <LocationSelect
      {...others}
      name={name}
      createNewModalSize={4}
      onChange={({ target } = {}) => {
        onChange({ name: target.select.label, id: target.select.value });
        trigger();
      }}
      value={value && mapper(value)}
      error={error}
      createNew
    />
  );
}
