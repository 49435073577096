import { useEffect } from 'react';
import TransactionTable from '../TransactionTable';
import { useGridHooksByDataType } from '../../hooks';
import { HeaderStyled, TableContainer, ButtonContainer, Loader, ButtonStyled } from './DataFormPreviewStyles';

export function DataFormPreview(props) {
  const { formData = {}, onConfirm = () => {}, onBack = () => {}, dataSection } = props;
  const [columns, convertTuple] = useGridHooksByDataType(dataSection);
  const [convert, { data: rows, loading }] = convertTuple;

  useEffect(() => {
    convert(formData, columns);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HeaderStyled>Data preview</HeaderStyled>

      <TableContainer>
        {loading && <Loader />}

        {!loading && <TransactionTable columnHeaderModel={columns} data={rows} />}
      </TableContainer>

      <ButtonContainer>
        <ButtonStyled onClick={onBack} color="info" style={{ marginRight: '20px' }} disabled={loading} inline>
          Back
        </ButtonStyled>

        <ButtonStyled onClick={onConfirm} disabled={loading} inline>
          Confirm data import to RIO
        </ButtonStyled>
      </ButtonContainer>
    </>
  );
}
