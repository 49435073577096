import { useMemo } from 'react';

export const staticRoutes = {
  home: {
    root: '',
  },
  impact: {
    root: '',
  },
  data: {
    root: 'data',
  },
  reporting: {
    root: 'reporting',
  },
  learn: {
    root: 'engage',
  },
  governance: {
    root: 'governance',
    legislation: 'legislation',
    systems: 'systems',
    agreements: 'agreements',
    frameworks: 'frameworks',
    policies: 'policies',
    targets: 'targets',
    materiality: 'materiality',
    aspects: 'environmental-aspects',
    health: 'health-and-safety-risk-register',
  },
  docs: {
    root: 'documents',
    all: 'all',
    systems: 'governance/ManagementSystems',
  },
  intelligence: {
    root: 'intelligence',
  },
  surveys: {
    root: 'surveys',
    sent: {
      overview: 'sent/overview',
      surveys: 'sent/surveys',
      survey: 'sent/surveys/:surveyId',
      contributors: 'sent/contributors',
      contributor: 'sent/contributors/:contributorId',
      contributorAuditLog: 'sent/contributors/:contributorId/response-log/:surveyId',
      submission: 'sent/contributors/:contributorId/submission/:submissionId',
      auditLog: 'sent/surveys/:surveyId/survey-log',
    },
    received: {
      surveys: 'received',
      survey: 'received/:surveyId',
      auditLog: 'received/:surveyId/response-log',
    },
  },
  flows: {
    root: 'flows',
  },
  tasks: {
    root: '/tasks',
  },
  configuration: {
    root: 'configuration',
    menu: {
      accounts: 'accountconfig/accounts',
      tracker: {
        frequency: 'datatracker/frequency-recurrence',
        anomolies: 'datatracker/anomaliesthresholds',
      },
      locations: 'accountconfig/locations',
      meters: 'accountconfig/meters',
      normalisers: 'accountconfig/normalisers',
      schemes: 'accountconfig/schemes',
      tags: 'accountconfig/tags',
      users: 'accountconfig/users',
      portfolio: {
        assets: 'portfoliomanagement/asset-management',
        funds: 'portfoliomanagement/funds',
      },
      aliases: {
        waste: 'aliases/waste',
        meters: 'aliases/meters',
        transport: 'aliases/transport',
        location: 'aliases/location',
        others: 'aliases/others',
      },
      suppliers: 'suppliers',
      contractors: 'wasteconfig/contractors',
      containerTypes: 'wasteconfig/containertypes',
      wasteStream: 'wasteconfig/wastestream',
      hierarchies: 'wasteconfig/wastehierarchies',
      surveys: {
        templates: 'surveys/templates',
        subscriptions: 'surveys/subscriptions',
        questions: 'surveys/questions',
        categories: 'surveys/question-categories',
      },
      flows: {
        root: 'flows/flows',
        subscriptions: 'flows/subscriptions',
      },
      dashboards: {
        root: 'dashboardconfig/dashboards',
        subscriptions: 'dashboardconfig/subscriptions',
      },
      learn: {
        content: 'learnconfig/learncontent',
        subscriptions: 'learnconfig/learnsubscriptions',
        assignLicense: 'learnconfig/account/:accountId/:productType/:productId/licence/assign',
        createLicense: 'learnconfig/account/:accountId/:productType/:productId/licence/create',
        evaluations: 'learnconfig/evaluations',
        banners: 'learnconfig/banners',
      },
      governance: {
        legislation: 'governanceconfig/legislation',
        management: 'governanceconfig/managementsubscriptions',
      },
      intelligence: {
        knowledgebases: 'intelligenceconfig/knowledgebases',
        subscriptions: 'intelligenceconfig/kbsubscriptions',
      },
      ocr: 'ocr',
    },
    factors: {
      location: 'carbonconversionfactors/location',
      market: 'carbonconversionfactors/market',
      gas: '/configuration/carbonconversionfactors/location/gas',
      electricity: '/configuration/carbonconversionfactors/location/electricity',
      water: '/configuration/carbonconversionfactors/location/water',
      waste: '/configuration/carbonconversionfactors/location/waste',
      transport: '/configuration/carbonconversionfactors/location/transport',
      fuel: '/configuration/carbonconversionfactors/location/fuel',
      heat: '/configuration/carbonconversionfactors/location/heat',
      refrigerant: '/configuration/carbonconversionfactors/location/refrigerant',
      hotelStays: '/configuration/carbonconversionfactors/location/hotel-stays',
      material: '/configuration/carbonconversionfactors/location/material',
    },
  },
  projects: {
    root: 'projects',
  },
  header: {
    profile: 'profile',
    contact: 'contact',
    upgrade: 'upgrade',
  },
  registration: {
    verify: 'verify-email',
    setPassword: 'set-password',
    resetPassword: 'reset-password',
    register: 'register',
    login: 'login',
  },
  market: {
    checkout: 'checkout',
    cart: 'cart',
    engage: 'upgrade/courses',
  },
};

export function useRoutes() {
  const routes = useMemo(() => staticRoutes, []);

  return routes;
}
