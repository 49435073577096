import { Banner, BannerType } from '@rio/rio-types';
import { createContext, useCallback, useContext, useState } from 'react';
import { Modal } from 'rio-ui-components';
import AccountBannerUpload from '../../ConfigurationAccountsContainer/AccountBannerUpload';
import { AgGrid, useAgGrid, useCurrentAccountId, useNotification } from '~/hooks';
import { v4 as uuid } from 'uuid';

export type BannersInteractions = {
  onEdit: (banner: Banner) => void;
  onCreate: () => void;
  onAccountSelectorChange: (accountId: string) => void;
  agGrid: AgGrid;
  currentAccountId: string;
};

const BannersContext = createContext<BannersInteractions>({
  onEdit() {
    throw new Error('Not implemented');
  },
  onCreate() {
    throw new Error('Not implemented');
  },
  onAccountSelectorChange() {
    throw new Error('Not implemented');
  },
  agGrid: {} as AgGrid,
  currentAccountId: '',
});

export function useBannersGrid(): BannersInteractions {
  return useContext(BannersContext);
}

export const BannersInteractionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { showNotification } = useNotification();

  const accountId = useCurrentAccountId();

  const [currentAccountId, setCurrentAccountId] = useState<string>(accountId);
  const agGrid = useAgGrid();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editedBanner, setEditedBanner] = useState<Banner | null>(null);

  const onEdit = useCallback(
    (banner: Banner) => {
      setEditedBanner(banner);
    },
    [setEditedBanner]
  );

  const onDismissEdit = useCallback(() => {
    setEditedBanner(null);
  }, [setEditedBanner]);

  const onCreate = useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const onDismissCreate = useCallback(() => {
    setShowCreateModal(false);
  }, [setShowCreateModal]);

  return (
    <BannersContext.Provider
      value={{
        onAccountSelectorChange: setCurrentAccountId,
        onCreate,
        onEdit,
        agGrid,
        currentAccountId,
      }}
    >
      {children}
      {editedBanner && (
        <Modal size="lg" show dismissable name="ConfigurationAccountsContainer__Modal--Logo" onDismiss={onDismissEdit}>
          <AccountBannerUpload
            banner={editedBanner}
            onBannerSaved={() => {
              showNotification('The banner has been updated', 'success');
              agGrid.api?.refreshServerSide();
              onDismissEdit();
            }}
            showDelete
            onBannerDeleted={() => {
              showNotification('The banner has been deleted', 'success');
              agGrid.api?.refreshServerSide();
              onDismissEdit();
            }}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal
          size="lg"
          show
          dismissable
          name="ConfigurationAccountsContainer__Modal--Logo"
          onDismiss={onDismissCreate}
        >
          <AccountBannerUpload
            banner={{
              id: uuid(),
              accountId: currentAccountId,
              name: '',
              thumbnail: '',
              visible: true,
              type: BannerType.Hyperlink,
              url: '',
              __typename: 'Banner',
            }}
            onBannerSaved={() => {
              showNotification('The banner has been created', 'success');
              agGrid.api?.refreshServerSide();
              setShowCreateModal(false);
            }}
          />
        </Modal>
      )}
    </BannersContext.Provider>
  );
};
