import React from 'react';
import { OptionsProps, Select } from '@rio/ui-components';
import { useGetTargetsByAccountId } from '~/hooks';

type Props = {
  value: string;
  name: string;
  disabled?: boolean;
  error?: string;
  onChange: (option: { value: string; label: string }) => void;
};

const loadingOption = {
  label: 'Loading...',
  value: '',
  disabled: true,
};
const noResultsFoundOption = { label: 'No targets found', value: '', disabled: true };

export function TargetsSelect({ value, error, name, onChange, disabled }: Props) {
  const { targets, loading, error: loadingError } = useGetTargetsByAccountId();

  const options = targets
    .filter(Boolean)
    .map((t) => ({
      value: t!.id,
      label: t!.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)) as OptionsProps[];

  if (!loading && !error && options.length === 0) {
    options.unshift(noResultsFoundOption);
  }

  return (
    <Select
      name={name}
      label="Target"
      placeholder="Please select the target"
      value={value}
      options={loading ? [loadingOption] : options}
      error={Boolean(error || loadingError)}
      helperText={error || loadingError?.message || undefined}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
