import { QuestionUnion } from '@rio/rio-types';
import { useGetReportingQuestions } from './useGetReportingQuestions';
import { AnswerQuestionFunction } from './types';
import { useAnswerQuestion } from './useAnswerReportingQuestions';

type UseReportingQuestionsResult = [QuestionUnion[], AnswerQuestionFunction, { loading: boolean }];

export function useReportingQuestions(frameworkId: string, chapterId?: string): UseReportingQuestionsResult {
  const { data, ...queryResponseParams } = useGetReportingQuestions({ frameworkId, chapterId });

  const answerQuestion: AnswerQuestionFunction = useAnswerQuestion();

  return [data?.getReportingQuestions || [], answerQuestion, queryResponseParams];
}
