import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Search } from 'rio-ui-components';
import { useNavigate } from 'react-router-dom';
import { Col, Button, Modal } from 'rio-ui-components';
import { Async } from '../../../components/Async';
import { PageFailed } from '../../../components/Errors';
import { SurveysGrid } from '../../../components/Surveys';
import { getPageSize } from '../../../utils/width';
import { SelectEvent } from '../../../types';
import ContainerHeader from '../../../components/ContainerHeader';
import PaginationContainer from '../../../components/PaginationContainer';
import { useCurrentAccountId } from '../../../hooks';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { useGet, GetSurveysResponse } from '../queries';
import { CreateSurvey } from './CreateSurvey';
import { SurveyFormProvider } from './provider/SurveyFormProvider';

export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;

const CreateSurveyButton = styled(Button)`
  align-self: center;
  max-width: 200px;
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const ActionsColumn = styled(Col)`
  display: contents;
`;

const SearchContainer = styled(Search)`
  padding-right: ${(props) => props.theme.geometry.md.spacing};
  min-width: 200px;
`;

const breadcrumbs = [{ to: '../sent/overview', title: 'Surveys sent' }, { title: 'All surveys' }];

export function SurveysContainer() {
  const pageSize = getPageSize(window.innerWidth);
  const accountId = useCurrentAccountId();

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [createModalShown, setCreateModalShown] = useState(false);

  const variables = {
    accountId,
    offset: (currentPage - 1) * pageSize,
    limit: pageSize,
    filters: {
      search: {
        field: ['name'],
        value: searchValue,
      },
    },
  };

  const result = useGet(variables);
  const retry = useCallback(() => result.refetch({ accountId }), [accountId, result]);
  const toggleCreateModal = useCallback(() => setCreateModalShown((shown) => !shown), []);
  const navigate = useNavigate();
  const handleSurveyClick = useCallback((id: string) => navigate(`./${id}`), [navigate]);

  const renderLoading = useCallback(
    () => <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />,
    []
  );

  const renderError = useCallback((error) => <PageFailed error={error} retry={retry} />, [retry]);

  const renderData = useCallback(
    ({ getSurveysPage }) => <SurveysGrid surveys={getSurveysPage} onSurveyClick={handleSurveyClick} />,
    [handleSurveyClick]
  );

  // checks and changes total pages each time query succeeds
  useEffect(() => {
    if (result?.data?.getSurveysPage?.totalRows) {
      setTotalPages(Math.ceil(result.data.getSurveysPage.totalRows / pageSize));
    }
  }, [pageSize, result]);

  return (
    <Page>
      <ContainerHeader
        name="Surveys"
        icon={['rio', 'clipboard-check-custom']}
        iconColor="quaternary"
        title="Surveys"
        breadcrumb={breadcrumbs}
      >
        <ActionsColumn span={9} container>
          <SearchContainer
            name="ConfigurationAccountsContainer__Controls__Search"
            value={searchValue}
            onChange={(e: SelectEvent) => setSearchValue(e.target.value)}
            size="md"
            hideButton
          />
          <CreateSurveyButton id="CreateSurveyButton" onClick={toggleCreateModal}>
            + Create a survey
          </CreateSurveyButton>

          {totalPages > 1 && (
            <PaginationContainer
              id="survey-container-pagination"
              name="survey-container-pagination"
              currentPage={currentPage}
              itemsCountPerPage={pageSize}
              totalPagesCount={totalPages}
              onChange={setCurrentPage}
              size={'sm'}
            />
          )}
        </ActionsColumn>
      </ContainerHeader>
      {createModalShown && (
        <Modal size="xl" onDismiss={toggleCreateModal} dismissable enableEscapeKey show>
          <SurveyFormProvider>
            <CreateSurvey
              onDismiss={toggleCreateModal}
              title="Create a survey"
              submitButtonText="Create Survey"
              buttonDescription="This will send your survey to your recipients"
            />
          </SurveyFormProvider>
        </Modal>
      )}
      <Async<GetSurveysResponse> result={result} loading={renderLoading} data={renderData} error={renderError} />
    </Page>
  );
}
