import { gql } from '@apollo/client';
import { PROJECT_FIELDS } from '../../fragments/ProjectFields.fragment.js';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: ID!
    $owners: [ID!]!
    $locations: [ID!]!
    $accountId: ID!
    $name: String!
    $description: String
    $priority: ProjectPriority!
    $schemeId: ID!
    $impact: String
    $cost: Float
    $currencyId: ID
    $baselineStartDate: String!
    $startDate: String!
    $endDate: String!
    $frequency: String
    $plannedBenefit: Float!
    $plannedCostChange: Float
    $plannedCO2eChange: Float
    $evidenceNotes: [ProjectEvidenceInput!]!
    $meters: [ID!]!
  ) {
    updateProject(
      input: {
        id: $id
        owners: $owners
        locations: $locations
        accountId: $accountId
        name: $name
        description: $description
        priority: $priority
        schemeId: $schemeId
        impact: $impact
        cost: $cost
        currencyId: $currencyId
        baselineStartDate: $baselineStartDate
        startDate: $startDate
        endDate: $endDate
        frequency: $frequency
        plannedBenefit: $plannedBenefit
        plannedCostChange: $plannedCostChange
        plannedCO2eChange: $plannedCO2eChange
        evidenceNotes: $evidenceNotes
        meters: $meters
      }
    ) {
      ...ProjectFields
    }
  }
  ${PROJECT_FIELDS}
`;
