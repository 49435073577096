import { useMutation } from '@apollo/client';
import { Mutation, MutationDeleteSurveyTemplateSubscriptionArgs as Variables } from '@rio/rio-types';
import { DELETE_SURVEY_TEMPLATE_SUBSCRIPTION, GET_SURVEY_TEMPLATE_SUBSCRIPTIONS } from './queries';
import { useNotification } from '../../../../hooks';
import { GetResponse } from './useGet';
import { deleteSurveyTemplateFromAccountQuery } from './updateAccountQuery';

type DeleteResponse = Pick<Mutation, 'deleteSurveyTemplateSubscription'>;

export function useDelete() {
  const { showNotification } = useNotification();
  return useMutation<DeleteResponse, Variables>(DELETE_SURVEY_TEMPLATE_SUBSCRIPTION, {
    onCompleted: ({ deleteSurveyTemplateSubscription: subscription }) => {
      showNotification(`Unsubscribed ${subscription.account.name} from ${subscription.template.name}`, 'success');
    },
    onError: (err) => {
      showNotification(`Something went wrong. If the problem persists, contact support. ${err}`, 'danger');
    },
    update: (cache, result) => {
      const { getSurveyTemplateSubscriptions } = cache.readQuery<GetResponse>({
        query: GET_SURVEY_TEMPLATE_SUBSCRIPTIONS,
      }) as GetResponse;
      const deletedSubscription = result.data?.deleteSurveyTemplateSubscription;

      if (deletedSubscription) {
        cache.writeQuery({
          query: GET_SURVEY_TEMPLATE_SUBSCRIPTIONS,
          data: {
            getSurveyTemplateSubscriptions: getSurveyTemplateSubscriptions.filter(
              (s) =>
                !(s.account.id === deletedSubscription.account.id && s.template.id === deletedSubscription.template.id)
            ),
          },
        });

        deleteSurveyTemplateFromAccountQuery(cache, result.data);
      }
    },
  });
}
