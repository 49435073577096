import { useCallback } from 'react';
import { validateCredentialsForm } from '.././validators';
import { FormStyled } from './style';

export const Form = ({ onSubmit, children, form, setErrors, name }) => {
  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const validationErrors = validateCredentialsForm(form);
      if (!!Object.keys(validationErrors).length) {
        setErrors(validationErrors);
      } else {
        onSubmit(form);
      }
    },
    [form, onSubmit, setErrors]
  );

  return (
    <FormStyled name={name} onSubmit={handleFormSubmit}>
      {children}
    </FormStyled>
  );
};
