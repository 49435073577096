import { useCallback } from 'react';
import { Autocomplete, AutocompleteField } from 'rio-ui-components';
import { MultipleChoiceQuestion } from '@rio/rio-types';

interface MultipleChoiceQuestionSelectProps {
  question: MultipleChoiceQuestion;
  onAnswer: (question: MultipleChoiceQuestion) => void;
}

export function MultipleChoiceQuestionSelect({ question, onAnswer }: MultipleChoiceQuestionSelectProps) {
  const handleChange = useCallback(
    (_, answer: string[] | null) => {
      onAnswer({
        ...question,
        reportedChoices: answer || []
      });
    },
    [question, onAnswer]
  );
  return (
    <Autocomplete
      fullWidth
      multiple
      limitTags={1}
      value={question.reportedChoices || []}
      onChange={handleChange}
      options={question.variants || []}
      renderInput={(props: object) => (
        <AutocompleteField
          {...props}
          label={question.shortFormulation || question.formulation || 'Select all that apply'}
          placeholder={question.shortFormulation || ''}
        />
      )}
    />
  );
}
