export function downloadJSONFile(
  data: {
    [key: string]: string;
  }[],
  filename: string
) {
  // Creating a blob object from non-blob data using the Blob constructor
  const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', filename);

  downloadLink.click();
  downloadLink.remove();
}
