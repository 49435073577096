import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import { Col, LoadingIndicator, Search, ErrorMessage, ContainerError } from 'rio-ui-components';

import SubscriptionWrapper from './SubscriptionWrapper';
import { GET_ALL_SUBSCRIPTION_INFO, GET_AVAILABLE_SUBSCRIPTION_INFO } from './index.queries';
import ContainerHeader from '../../../components/ContainerHeader';
import { useGetActivatedLicencesCountByAccountIdQuery } from '../../../hooks/licence';
import { useCurrentUserId, usePermissions } from '../../../hooks';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SubscriptionSelector = ({ title: parentTitle, accountId }) => {
  const userId = useCurrentUserId();
  const { role } = usePermissions();
  const isSysAdmin = role === 'SYSADMIN';

  const {
    loading,
    error,
    data: allSubscriptionInfo,
    refetch,
  } = useQuery(isSysAdmin ? GET_ALL_SUBSCRIPTION_INFO : GET_AVAILABLE_SUBSCRIPTION_INFO, {
    variables: {
      accountId,
      userId,
    },
  });

  const { productLicences = [], loading: isLicencesCountLoading } =
    useGetActivatedLicencesCountByAccountIdQuery(accountId);
  const licencesCountMap = new Map(productLicences.map(({ productId, count }) => [productId, count]));
  const isLoading = isLicencesCountLoading || loading;

  return allSubscriptionInfo && !error ? (
    <SubscriptionWrapper
      accountId={accountId}
      licencesCountMap={licencesCountMap}
      allSubscriptionInfo={allSubscriptionInfo}
      title={parentTitle}
    />
  ) : (
    <Col name="ConfigurationAccountsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationLearnSubscriptionsContainer__Controls"
        icon="briefcase"
        iconColor="primary"
        title={parentTitle}
      >
        <Col container item>
          <Search name="ConfigurationLearnSubscriptionsContainer__Controls__Search" hideButton />
        </Col>
      </ContainerHeader>
      {isLoading ? (
        <CenterContainer>
          <LoadingIndicator size="md" />
        </CenterContainer>
      ) : error ? (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError
              name="ConfigurationLearnSubscriptionsContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={refetch}
            />
          )}
        </ErrorMessage>
      ) : null}
    </Col>
  );
};
export default SubscriptionSelector;
