import { Query, QueryGetSurveyLatestArgs as Variables } from '@rio/rio-types';
import { GET_DATA_QUALITY_PERCENTAGE } from './queries';
import { useQuery } from '@apollo/client';

export type GetDataQualityPercentageResponse = Pick<Query, 'getDataQualityPercentage'>;

export function useGetDataQualityPercentage(accountId: string) {
  return useQuery<GetDataQualityPercentageResponse, Variables>(GET_DATA_QUALITY_PERCENTAGE, {
    variables: { accountId },
  });
}
