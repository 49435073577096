import React from 'react';
import styled from 'styled-components';
import { Row, Search, TextLink } from 'rio-ui-components';
import NotificationBanner from '../../../components/NotificationBanner';

export const SearchHeaderContainer = styled(Row)`
  flex: 0;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
  padding-right: ${(props) => props.theme.geometry.sm.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.dark.background};
`;

export const SearchStyled = styled(Search)`
  flex: 1 1 auto;
  form > div {
    background: none;
    border: 0;
    border-radius: 0;
  }
`;

export const TextLinkStyled = styled(TextLink)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

export const NotificationBannerStyled = styled(NotificationBanner)`
  margin-top: ${(props) => props.theme.geometry.sm.spacing};
`;

interface SearchHeaderProps {
  search: string;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
  placeholder?: string;
}
export const SearchHeader = (props: SearchHeaderProps) => {
  const { search, onSearchValueChange, onSearch, placeholder = 'Search Documents...' } = props;

  return (
    <SearchHeaderContainer container item itemAlign="center">
      <SearchStyled name="DocumentFiles__Search" placeholder={placeholder} value={search} onChange={onSearchValueChange} onSearch={onSearch} hideButton />
    </SearchHeaderContainer>
  );
};
