import { Query, QueryGetAvailableCoursesArgs as Variables } from '@rio/rio-types';
import { GET_AVAILABLE_COURSES } from '../../Courses/index.queries';
import { useCurrentAccountId, usePageSuspendingQuery, useUserToken } from '~/hooks';

export type Response = Pick<Query, 'getAvailableCourses'>;

export function useGetAvailableCourses() {
  const { token } = useUserToken();
  const accountId = useCurrentAccountId();

  return usePageSuspendingQuery<Response, Variables>(GET_AVAILABLE_COURSES, {
    variables: {
      accountId,
      pageNumber: 1,
      userId: token?.sub,
    },
    skip: !token,
  });
}
