import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import SubjectRow from './SubjectRow';

const ResultsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background: ${(props) => props.theme.colors.basic.white};
  margin-top: ${(props) => props.theme.geometry.md.spacing};
`;

const DataSubjectsList = ({ items, fullAccess, intl }) => {
  return (
    <ResultsContainer>
      {items.map((item) => {
        const hasAccessToSubject = fullAccess[item.title];
        const link = item.comingSoon || !hasAccessToSubject ? '/contact' : `/data/${item.title}`;
        return (
          <Link name={`DataSubjectsList__Link__${item.id}`} to={link} key={item.id}>
            <SubjectRow fullAccess={hasAccessToSubject} botId={item.id} title={intl.formatMessage({ id: `pages.data.subjects.${item.title.toLowerCase()}` })} icon={item.icon} iconColor={item.iconColor} key={item.id} comingSoon={item.comingSoon || !hasAccessToSubject} />
          </Link>
        );
      })}
    </ResultsContainer>
  );
};

export default injectIntl(DataSubjectsList);
