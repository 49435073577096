import { NaturalGasConversionFactor } from '@rio/rio-types';
import { useContext } from 'react';
import { ElectricityFactorsDescription } from './FactorsDescriptions';
import { FactorsGrid } from './FactorsGrid';
import { toDate } from '../../DocumentContainer/utils';
import { TabContent } from '../../../components/TabContent';
import { TabContentContext } from './LocationBasedFactors';
import { gridNumberFormatter } from '~/utils';
import { ColDef } from 'ag-grid-community';
import { useRoutes } from '~/hooks';

interface GasConversionFactorGridProps {
  factors: NaturalGasConversionFactor[];
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Reporting year',
    field: 'reportingYear',
    filter: 'agSetColumnFilter',
    minWidth: 100,
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    filter: 'agSetColumnFilter',
    valueFormatter: (data: { value: string }) => toDate(data.value),
    minWidth: 100,
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    filter: 'agSetColumnFilter',
    valueFormatter: (data: { value: string }) => toDate(data.value),
    minWidth: 100,
  },
  {
    headerName: 'Scope 1 Combustion (kgCO2e)',
    field: 'consumptionKgCo2e',
    minWidth: 200,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Scope 3 WTT (kgCO2e)',
    field: 'wttKgCo2e',
    minWidth: 200,
    valueFormatter: gridNumberFormatter,
  },
  {
    headerName: 'Reference',
    field: 'reference',
    cellRenderer: 'reference',
    cellRendererParams: {
      icon: 'external-link-alt',
    },
    minWidth: 100,
  },
];

export function GasConversionFactorGrid({ factors }: GasConversionFactorGridProps) {
  const tabContentData = useContext(TabContentContext);
  const routes = useRoutes();

  return (
    <TabContent {...tabContentData} index={routes.configuration.factors.gas}>
      <div className="ag-theme-alpine">
        <FactorsGrid rowData={factors} columnDefs={columnDefs} />
      </div>
      <ElectricityFactorsDescription />
    </TabContent>
  );
}
