import { useQuery } from '@apollo/client';
import { Select, ErrorMessage, InlineError } from 'rio-ui-components';
import { GET_ACCOUNT_SECTORS } from '../SectorSelector/index.queries';
import { Option } from '../../types';

interface SectorSelectorProps {
  onChange: Function;
  value?: string | string[] | null;
  name?: string;
  error?: string | null;
  isSubmitting?: boolean;
  disabled?: boolean;
}

const SectorSelector = ({
  onChange,
  isSubmitting,
  disabled,
  value,
  name = 'SectorSelector',
  ...rest
}: SectorSelectorProps) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT_SECTORS, {});

  if (error) {
    return (
      <ErrorMessage error={error}>
        {({ icon }: { icon: string }) => (
          <InlineError name="SectorSelector__error" icon={icon} title="Sector selector failed to load" />
        )}
      </ErrorMessage>
    );
  }

  const options = data?.getAccountSectors;

  return (
    <Select
      {...rest}
      value={Array.isArray(value) ? value.map((id) => options.find((o: Option) => o.value === id)) : value}
      name={name}
      isLoading={loading}
      isDisabled={isSubmitting || disabled}
      onChange={onChange}
      options={options}
      defaultOptions={options}
    />
  );
};

export default SectorSelector;
