import { IHeader, ITheme } from 'survey-core';

const header = {
  height: 150,
  inheritWidthFrom: 'survey',
  titlePositionX: 'center',
  descriptionPositionX: 'center',
} as IHeader;

export const ThemeV2Json: ITheme = {
  themeName: 'plain' as const,
  colorPalette: 'light' as const,
  isPanelless: false,
  backgroundImage: '',
  backgroundOpacity: 1,
  backgroundImageAttachment: 'scroll' as const,
  backgroundImageFit: 'cover' as const,
  headerView: 'basic' as const,
  header,
  cssVariables: {
    '--sjs-editorpanel-hovercolor': 'rgba(241, 239, 255, 1)',
    '--sjs-editorpanel-cornerRadius': '16px',
    '--sjs-questionpanel-hovercolor': 'rgba(255, 255, 255, 1)',
    '--sjs-questionpanel-cornerRadius': '16px',
    '--sjs-corner-radius': '4px',
    '--sjs-base-unit': '8px',
    '--sjs-shadow-small': '0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
    '--sjs-font-questiontitle-weight': '400',
    '--sjs-font-questiontitle-color': 'rgba(27, 27, 31, 1)',
    '--sjs-font-questiondescription-size': '15px',
    '--sjs-font-questiondescription-color': 'rgba(27, 27, 31, 1)',
    '--sjs-shadow-inner': '0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
    '--sjs-font-editorfont-color': 'rgba(27, 27, 31, 0.91)',
    '--sjs-font-editorfont-placeholdercolor': 'rgba(27, 27, 31, 0.45)',
    '--sjs-border-default': 'rgba(27, 27, 31, 0.15)',
    '--sjs-border-light': 'rgba(27, 27, 31, 0.15)',
    '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
    '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-medium': '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.05)',
    '--sjs-shadow-inner-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-article-font-xx-large-textDecoration': 'none',
    '--sjs-article-font-xx-large-fontWeight': '700',
    '--sjs-article-font-xx-large-fontStyle': 'normal',
    '--sjs-article-font-xx-large-fontStretch': 'normal',
    '--sjs-article-font-xx-large-letterSpacing': '0',
    '--sjs-article-font-xx-large-lineHeight': '64px',
    '--sjs-article-font-xx-large-paragraphIndent': '0px',
    '--sjs-article-font-xx-large-textCase': 'none',
    '--sjs-article-font-x-large-textDecoration': 'none',
    '--sjs-article-font-x-large-fontWeight': '700',
    '--sjs-article-font-x-large-fontStyle': 'normal',
    '--sjs-article-font-x-large-fontStretch': 'normal',
    '--sjs-article-font-x-large-letterSpacing': '0',
    '--sjs-article-font-x-large-lineHeight': '56px',
    '--sjs-article-font-x-large-paragraphIndent': '0px',
    '--sjs-article-font-x-large-textCase': 'none',
    '--sjs-article-font-large-textDecoration': 'none',
    '--sjs-article-font-large-fontWeight': '700',
    '--sjs-article-font-large-fontStyle': 'normal',
    '--sjs-article-font-large-fontStretch': 'normal',
    '--sjs-article-font-large-letterSpacing': '0',
    '--sjs-article-font-large-lineHeight': '40px',
    '--sjs-article-font-large-paragraphIndent': '0px',
    '--sjs-article-font-large-textCase': 'none',
    '--sjs-article-font-medium-textDecoration': 'none',
    '--sjs-article-font-medium-fontWeight': '700',
    '--sjs-article-font-medium-fontStyle': 'normal',
    '--sjs-article-font-medium-fontStretch': 'normal',
    '--sjs-article-font-medium-letterSpacing': '0',
    '--sjs-article-font-medium-lineHeight': '32px',
    '--sjs-article-font-medium-paragraphIndent': '0px',
    '--sjs-article-font-medium-textCase': 'none',
    '--sjs-article-font-default-textDecoration': 'none',
    '--sjs-article-font-default-fontWeight': '400',
    '--sjs-article-font-default-fontStyle': 'normal',
    '--sjs-article-font-default-fontStretch': 'normal',
    '--sjs-article-font-default-letterSpacing': '0',
    '--sjs-article-font-default-lineHeight': '28px',
    '--sjs-article-font-default-paragraphIndent': '0px',
    '--sjs-article-font-default-textCase': 'none',
    '--sjs-general-backcolor-dim': 'rgba(255, 255, 255, 1)',
    '--sjs-primary-backcolor': 'rgba(46, 62, 255, 1)',
    '--sjs-primary-backcolor-dark': 'rgba(42, 57, 235, 1)',
    '--sjs-primary-backcolor-light': 'rgba(46, 62, 255, 0.1)',
    '--sjs-primary-forecolor': 'rgba(241, 239, 255, 1)',
    '--sjs-primary-forecolor-light': 'rgba(27, 27, 31, 0.38)',
    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    '--sjs-font-surveydescription-size': '21px',
    '--sjs-font-headertitle-weight': '400',
    '--sjs-font-headertitle-color': 'rgba(27, 27, 31, 1)',
    '--sjs-font-headerdescription-weight': '400',
    '--sjs-font-headerdescription-color': 'rgba(27, 27, 31, 1)',
    '--sjs-header-backcolor': 'transparent',
  },
};
