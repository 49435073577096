import React, { useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { Label, Col, Row, Modal, Heading, DatePicker, Button, Select, MultiSelect, Text } from 'rio-ui-components';
import Option from '../../../components/Option';
import { useCreateDepartment } from '../../../hooks/useCreateDepartment';
import { useCurrentAccount } from '../../../hooks/useCurrentAccount';

import { validate } from './validate';
import { usePermissions, useNotification, useUserToken } from '../../../hooks';

import { TagsMultiSelect } from '../../../components/TagsMultiSelect';
import { LocationsMultiSelect } from '../../../components/LocationsMultiSelect';
import { scopeOptions, TAG, LOCATION } from '../../../constants/scopes';
import { TYPES, TYPES_LABELS, LIKELIHOOD_OPTIONS, SEVERITY_OPTIONS } from '../../../constants/aspects';
import { CreateOptionModal } from '../../../components/CreateOptionModal';
import { CreateDepartmentModal } from '../../../components/CreateDepartmentModal';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;
const Form = styled.form`
  padding: 32px;
`;
const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;
const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;
const ButtonColStyled = styled(ColStyled)`
  flex-basis: 50%;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;
const ExpiryDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -340px;
    left: 50%;
  }
`;

const StyledModalContainer = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButtonRow = styled(Row)`
  padding-top: ${(props) => props.theme.geometry.lg.spacing};
`;
const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.geometry.sm.spacing};
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const checkPermissions = (permissions, action, isOwnResource) =>
  !!permissions.governance.find((a) => a.startsWith(action) && !a.includes('Option') && !a.endsWith('Own')) ||
  (permissions.governance.includes(`${action}Own`) && isOwnResource);

const prepareOptions = (options, labelKey = 'name', valueKey = 'id') =>
  options?.filter(Boolean).map((option) => ({ ...option, label: option[labelKey], value: option[valueKey] })) || [];

const onSelectChange = (event, onCreate, onChange) => {
  const createNew = Array.isArray(event.target.select)
    ? event.target.select.find(({ value }) => value === 'NEW')
    : event.target.select?.value === 'NEW';

  if (createNew) {
    onCreate(event);
  } else {
    onChange(event);
  }
};

const prepareMultiselectOptions = (options) => (options ? options.map((value) => ({ label: value, value })) : []);

const formatRisk = (values, accountId, token) => ({
  id: values.id || uuid(),
  accountId,
  scope: values.scope,
  locationIds: prepareOptions(values.locations),
  tagIds: prepareOptions(values.tags, 'tagName'),
  activity: values.activity || null,
  departments: prepareMultiselectOptions(values.departments),
  risks: prepareMultiselectOptions(values.risks),
  impacts: prepareMultiselectOptions(values.impacts),
  legislationIds: prepareOptions(values.legislations, 'title'),
  likelihoodNoControl: values.likelihoodNoControl,
  severityNoControl: values.severityNoControl,
  emergencyConditions: prepareMultiselectOptions(values.emergencyConditions),
  controlMeasures: prepareMultiselectOptions(values.controlMeasures),
  likelihoodControl: values.likelihoodControl,
  severityControl: values.severityControl,
  reviewDate: values.reviewDate,
  status: values.status,
  taskId: values.task?.id,
  authorId: values.authorId || token.sub
});

const isDuplicate = (original, copy) => {
  const propsToCompare = [
    'scope',
    'locationIds',
    'tagIds',
    'departments',
    'activity',
    'risks',
    'impacts',
    'legislationReference'
  ];
  const originalRisk = _.pick(formatRisk(original), propsToCompare);
  const copiedRisk = _.pick(copy, propsToCompare);
  return _.isEqual(originalRisk, copiedRisk);
};

const RiskModal = ({ accountId, title, submitTitle, onDismiss, onSubmit, values = {}, isCopy }) => {
  const { token } = useUserToken();
  const currentAccount = useCurrentAccount();
  const defaultRisk = formatRisk(values, accountId, token);
  const [errors, setErrors] = useState({});
  const [risk, setRisk] = useState(defaultRisk);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createOptionType, setShowCreateModal] = useState(false);
  const { showNotification } = useNotification();
  const { departmentMultipleSelect, showCreateDepartment, setShowCreateDepartment } = useCreateDepartment(
    currentAccount.departments,
    risk.departments
  );

  const permissions = usePermissions();
  const canDeleteOptions = permissions.governance.find((action) => action.startsWith('deleteRiskOption'));

  const isUpdate = !!values.id;
  const showDeleteButton = isUpdate && checkPermissions(permissions, 'deleteRisk', values.authorId === token.sub);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const legislationOptions = [];
  const activityOptions = [];
  const hazzardOptions = [];
  const whoIsEffectedOptions = [];
  let riskOptions = [];
  const impactOptions = [];
  const emergencyConditionOptions = [];
  const controlMeasureOptions = [];
  const statusOptions = [];

  const isDeletedActivityOption = values.activity && !activityOptions.find(({ value }) => value === values.activity);
  if (isDeletedActivityOption) {
    activityOptions.push({ value: values.activity, label: values.activity, isDeleted: true });
  }

  const riskKeyByType = {
    [TYPES.ACTIVITY]: 'activity',
    [TYPES.RISK]: 'risks',
    [TYPES.IMPACT]: 'impacts',
    [TYPES.EMERGENCY_CONDITION]: 'emergencyConditions',
    [TYPES.CONTROL_MEASURE]: 'controlMeasures',
    [TYPES.STATUS]: 'status',
    [TYPES.DEPARTMENT]: 'departments'
  };

  const deleteRiskOption = (id) => {
    riskOptions = riskOptions.filter((risk) => risk.id !== id);
  };

  const deleteRisk = () => {
    showNotification('Risk has been deleted!');
    onDismiss();
  };

  const onDelete = (id) => deleteRiskOption(id);

  const optionsComponents = {
    Option: ({ ...props }) => <Option {...props} onDelete={onDelete} canDelete={canDeleteOptions} />
  };

  const setDepartment = async (data, newDepartment) => {
    setRisk({
      ...risk,
      departments: [...risk['departments'], { label: newDepartment.name, value: newDepartment.departmentId }]
    });
  };

  return (
    <Modal size="md" onDismiss={onDismiss} dismissable show>
      <CreateDepartmentModal
        showCreateDepartment={showCreateDepartment}
        setShowCreateDepartment={setShowCreateDepartment}
        onComplete={setDepartment}
      />
      {createOptionType && (
        <CreateOptionModal
          accountId={accountId}
          type={createOptionType}
          onDismiss={() => setShowCreateModal(false)}
          onError={(err) => showNotification(err.message, 'danger')}
          onCompleted={(data) => {
            const { type, name } = data.createRiskOption;
            setShowCreateModal(false);

            const key = riskKeyByType[type];
            if (Array.isArray(risk[key])) {
              setRisk({ ...risk, [key]: [...risk[key], { label: name, value: name }] });
            } else {
              setRisk({ ...risk, [key]: name });
            }

            setErrors({ ...errors, [key]: null });

            showNotification(`${TYPES_LABELS[type]} has been created!`);
          }}
        />
      )}
      <Form>
        <HeaderStyled>{title}</HeaderStyled>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Scope</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={scopeOptions}
              value={risk.scope}
              onChange={(e) => {
                setRisk({ ...risk, scope: e.target.value, tagIds: null, locationIds: null });
                setErrors({ ...errors, scope: null, tagIds: null, locationIds: null });
              }}
              error={errors.scope}
            />
          </ColStyled>
        </Row>
        {risk.scope === LOCATION && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Location(s)</LabelStyled>
              </LabelContainerStyled>
              <LocationsMultiSelect
                accountId={accountId}
                value={risk.locationIds}
                onChange={(e) => {
                  setRisk({ ...risk, locationIds: e.target.select });
                  setErrors({ ...errors, locationIds: null });
                }}
                error={errors.locationIds}
                isSubmitting={isSubmitting}
              />
            </ColStyled>
          </Row>
        )}
        {risk.scope === TAG && (
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Tag(s)</LabelStyled>
              </LabelContainerStyled>
              <TagsMultiSelect
                accountId={accountId}
                value={risk.tagIds}
                onChange={(e) => {
                  setRisk({ ...risk, tagIds: e.target.select });
                  setErrors({ ...errors, tagIds: null });
                }}
                error={errors.tagIds}
                isSubmitting={isSubmitting}
              />
            </ColStyled>
          </Row>
        )}

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Departments</LabelStyled>
            </LabelContainerStyled>
            {departmentMultipleSelect((e) => {
              setRisk({ ...risk, departments: e.target.select });
              setErrors({ ...errors, departments: null });
            })}
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Activity / Process</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={activityOptions}
              value={risk.activity}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.ACTIVITY),
                  () => {
                    setRisk({ ...risk, activity: e.target.value });
                    setErrors({ ...errors, activity: null });
                  }
                );
              }}
              components={optionsComponents}
              error={errors.activity}
            />
          </ColStyled>
        </Row>

        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Identified Hazards</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={hazzardOptions}
              value={risk.hazzards}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.HAZZARDS),
                  () => {
                    setRisk({ ...risk, hazzards: e.target.value });
                    setErrors({ ...errors, hazzards: null });
                  }
                );
              }}
              components={optionsComponents}
              error={errors.activity}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Who may be effected</LabelStyled>
            </LabelContainerStyled>
            <Select
              options={whoIsEffectedOptions}
              value={risk.whoIsEffected}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.WHOISEFFECTED),
                  () => {
                    setRisk({ ...risk, whoIsEffected: e.target.value });
                    setErrors({ ...errors, whoIsEffected: null });
                  }
                );
              }}
              components={optionsComponents}
              error={errors.activity}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Environmental Risk(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={riskOptions}
              value={risk.risks}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.RISK),
                  () => {
                    setRisk({ ...risk, risks: e.target.select });
                    setErrors({ ...errors, risks: null });
                  }
                );
              }}
              error={errors.risks}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Potential Environmental Impact(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={impactOptions}
              value={risk.impacts}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.IMPACT),
                  () => {
                    setRisk({ ...risk, impacts: e.target.select });
                    setErrors({ ...errors, impacts: null });
                  }
                );
              }}
              error={errors.impacts}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Legislation Reference(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={legislationOptions}
              value={risk.legislationIds}
              onChange={(e) => {
                setRisk({ ...risk, legislationIds: e.target.select });
                setErrors({ ...errors, legislationIds: null });
              }}
              error={errors.legislationIds}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Likelihood (no control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={LIKELIHOOD_OPTIONS}
              value={String(risk.likelihoodNoControl)}
              onChange={(e) => {
                setRisk({ ...risk, likelihoodNoControl: e.target.value });
                setErrors({ ...errors, likelihoodNoControl: null });
              }}
              error={errors.likelihoodNoControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Severity (no control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={SEVERITY_OPTIONS}
              value={String(risk.severityNoControl)}
              onChange={(e) => {
                setRisk({ ...risk, severityNoControl: e.target.value });
                setErrors({ ...errors, severityNoControl: null });
              }}
              error={errors.severityNoControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Potential Emergency Condition(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={emergencyConditionOptions}
              value={risk.emergencyConditions}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.EMERGENCY_CONDITION),
                  () => {
                    setRisk({ ...risk, emergencyConditions: e.target.select });
                    setErrors({ ...errors, emergencyConditions: null });
                  }
                );
              }}
              error={errors.emergencyConditions}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Relevant Control Measure(s)</LabelStyled>
            </LabelContainerStyled>
            <MultiSelect
              options={controlMeasureOptions}
              value={risk.controlMeasures}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.CONTROL_MEASURE),
                  () => {
                    setRisk({ ...risk, controlMeasures: e.target.select });
                    setErrors({ ...errors, controlMeasures: null });
                  }
                );
              }}
              error={errors.controlMeasures}
              components={optionsComponents}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Likelihood (with control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={LIKELIHOOD_OPTIONS}
              value={String(risk.likelihoodControl)}
              onChange={(e) => {
                setRisk({ ...risk, likelihoodControl: e.target.value });
                setErrors({ ...errors, likelihoodControl: null });
              }}
              error={errors.likelihoodControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Severity (with control) (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={SEVERITY_OPTIONS}
              value={String(risk.severityControl)}
              onChange={(e) => {
                setRisk({ ...risk, severityControl: e.target.value });
                setErrors({ ...errors, severityControl: null });
              }}
              error={errors.severityControl}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Review date (optional)</LabelStyled>
            </LabelContainerStyled>
            <ExpiryDatePicker
              placeholder="Select review date"
              disabled={isSubmitting}
              onDayClick={(reviewDate) => {
                setRisk({ ...risk, reviewDate: reviewDate?.toISOString() });
                setErrors({ ...errors, reviewDate: null });
              }}
              selectedDate={risk.reviewDate ? new Date(risk.reviewDate) : null}
            />
          </ColStyled>
        </Row>
        <Row container align="between">
          <ColStyled item>
            <LabelContainerStyled>
              <LabelStyled>Status (optional)</LabelStyled>
            </LabelContainerStyled>
            <Select
              isClearable
              options={statusOptions}
              value={risk.status}
              onChange={(e) => {
                onSelectChange(
                  e,
                  () => setShowCreateModal(TYPES.STATUS),
                  () => {
                    setRisk({ ...risk, status: e.target.value });
                    setErrors({ ...errors, status: null });
                  }
                );
              }}
              components={optionsComponents}
              error={errors.status}
            />
          </ColStyled>
        </Row>
        {errors?.general && (
          <Row container>
            <Text color="danger" name="errorMessage">
              {errors.general}
            </Text>
          </Row>
        )}
        <Row container>
          {showDeleteButton && (
            <ButtonColStyled item grow="1">
              <ButtonStyled
                color="danger"
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteConfirmationModal(true);
                }}
              >
                Delete
              </ButtonStyled>
            </ButtonColStyled>
          )}
          <ButtonColStyled item grow="1">
            <ButtonStyled
              disabled={isSubmitting}
              onClick={async (e) => {
                e.preventDefault();

                const errors = validate(risk);
                if (Object.keys(errors).length) {
                  return setErrors(errors);
                }

                if (isCopy && isDuplicate(values, risk)) {
                  return setErrors({
                    general:
                      'It seems you are attempting to create an risk which already exists. Please create a unique risk.'
                  });
                }

                setIsSubmitting(true);
                await onSubmit(risk);
                setIsSubmitting(false);
              }}
            >
              {submitTitle}
            </ButtonStyled>
          </ButtonColStyled>
        </Row>
      </Form>
      {showDeleteConfirmationModal && (
        <Modal onDismiss={() => setShowDeleteConfirmationModal(false)} show name="confirmationModal">
          <StyledModalContainer>
            <StyledButtonRow item>
              <Heading>Delete Risk</Heading>
            </StyledButtonRow>
            <StyledButtonRow item>
              <Text>This action will permanently delete this risk. Do you want to proceed?</Text>
            </StyledButtonRow>
            <StyledButtonRow container distribution="around">
              <StyledButton
                color="danger"
                disabled={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  deleteRisk({ variables: { id: risk.id, accountId } });
                }}
              >
                Delete
              </StyledButton>
              <StyledButton disabled={isSubmitting} onClick={() => setShowDeleteConfirmationModal(false)}>
                Cancel
              </StyledButton>
            </StyledButtonRow>
          </StyledModalContainer>
        </Modal>
      )}
    </Modal>
  );
};

export default RiskModal;
