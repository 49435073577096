import { useMutation } from '@apollo/client';
import { LearnContentType } from '@rio/rio-types';
import React, { useState } from 'react';
import { Modal, Notification, Row, Col, Button } from 'rio-ui-components';
import styled from 'styled-components';
import LearnEmbeddedViewer from '../LearnEmbeddedViewer';
import { Evaluation } from '../Evaluation';
import { COMPLETE_EVALUATION } from './index.queries';
import { useUserToken } from '../../hooks/useUserToken';
import { useCurrentAccountId } from '../../hooks/useCurrentAccountId';
import { useDownloadPdf } from './useDownloadPdf';
import { GET_COURSE_BY_ID } from '../../containers/LearnContainer/Courses/index.queries';

const SHOW_PRE_ASSESSMENT = 0;
const SHOW_EMBEDDED_VIEWER = 1;
const SHOW_POST_ASSESSMENT = 2;
const SHOW_REACTION_EVALUATION = 3;

const ViewerCol = styled(Col)`
  height: 100%;
`;
const ViewerRow = styled(Row)`
  display: flex;
  flex: auto;
  height: auto;

  iframe {
    min-height: 90vh;
  }
`;
const ButtonsRow = styled(Row)`
  padding: ${(p) => p.theme.geometry.md.spacing};
  justify-content: space-between;
  align-self: flex-end;
  flex: 0 0 auto;
  width: 100%;
`;
const ButtonStyled = styled(Button)`
  flex-basis: 48%;
  padding-right: ${(p) => p.theme.geometry.xs.spacing};
`;

const style = {
  display: 'block'
};

export function ViewCourseModal({ onDismiss, course, link, courseRefetch = () => void 0 }) {
  const preAssessmentCompleted = course.assessment?.questions?.every?.((q) => q.answers?.length >= 1);
  const [notification, setNotification] = useState(null);
  const [step, setStep] = useState(course.assessment ? SHOW_PRE_ASSESSMENT : SHOW_EMBEDDED_VIEWER);
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const accountId = useCurrentAccountId();
  const [completeEvaluation, { loading }] = useMutation(COMPLETE_EVALUATION, {
    onError: (err) => {
      setNotification({
        color: 'danger',
        message: err.message
      });
    }
  });
  const downloadPdf = useDownloadPdf(course, link, courseRefetch);
  const goToCourse = async () => {
    if (course.type === LearnContentType.Pdf) {
      if (!pdfDownloaded) {
        await downloadPdf();
        setPdfDownloaded(true);
      } else {
        setStep(SHOW_POST_ASSESSMENT);
      }
    } else {
      setStep(SHOW_EMBEDDED_VIEWER);
    }
  };
  const { token } = useUserToken();
  const updateAnswers = (answers, evaluationType) => (cache) => {
    cache.writeQuery({
      query: GET_COURSE_BY_ID,
      variables: {
        accountId,
        id: course.id,
        userId: token.sub,
        userIdNullable: token.sub
      },
      data: {
        getCourseById: {
          ...course,
          [evaluationType]: {
            ...course[evaluationType],
            questions: course[evaluationType].questions.map((q) => {
              const newAnswer = answers.find((a) => a.questionId === q.id);
              const questionAnswers = q.answers || [];
              return {
                ...q,
                answers: questionAnswers.concat([
                  {
                    givenAt: new Date().toISOString(),
                    value: newAnswer.value,
                    __typename: 'Answer'
                  }
                ])
              };
            })
          }
        }
      }
    });
  };
  return (
    <Modal
      height="auto"
      maxHeight="100%"
      minHeight={'-webkit-fill-available'}
      span={12}
      onDismiss={onDismiss}
      style={style}
      show
    >
      {step === SHOW_PRE_ASSESSMENT && (
        <Evaluation
          type="Pre-assessment"
          goNextButtonTitle={pdfDownloaded ? 'Go to Post-assessment' : 'Go to Course'}
          evaluation={course.assessment}
          onSubmit={async (answers) => {
            await completeEvaluation({
              variables: {
                answers,
                userId: token.sub,
                courseId: course.id,
                evaluationId: course.assessment.id
              },
              update: updateAnswers(answers, 'assessment')
            });
            courseRefetch();
          }}
          onGoNext={goToCourse}
          isSubmitting={loading}
        />
      )}
      {step === SHOW_EMBEDDED_VIEWER && (
        <ViewerCol container>
          <ViewerRow item>
            <LearnEmbeddedViewer
              course={course}
              link={link}
              courseRefetch={courseRefetch}
              onCompleted={() => {
                if (course.assesment && preAssessmentCompleted) {
                  setStep(SHOW_POST_ASSESSMENT);
                }
              }}
            />
          </ViewerRow>
          {course.assessment && preAssessmentCompleted && (
            <ButtonsRow container item>
              <ButtonStyled
                color="secondary"
                onClick={() => {
                  setStep(SHOW_POST_ASSESSMENT);
                }}
              >
                Go to Post-assessment
              </ButtonStyled>
              <ButtonStyled color="info" onClick={onDismiss}>
                Close
              </ButtonStyled>
            </ButtonsRow>
          )}
          {!course.assessment && course.reaction && (
            <ButtonsRow container item>
              <ButtonStyled
                color="secondary"
                onClick={() => {
                  setStep(SHOW_REACTION_EVALUATION);
                }}
              >
                Go to Reaction
              </ButtonStyled>
              <ButtonStyled color="info" onClick={onDismiss}>
                Close
              </ButtonStyled>
            </ButtonsRow>
          )}
        </ViewerCol>
      )}
      {step === SHOW_POST_ASSESSMENT && (
        <Evaluation
          type="Post-assessment"
          goNextButtonTitle={course.reaction ? 'Go to reaction' : 'Close'}
          evaluation={course.assessment}
          onSubmit={async (answers) => {
            await completeEvaluation({
              variables: {
                answers,
                userId: token.sub,
                courseId: course.id,
                evaluationId: course.assessment.id
              },
              update: updateAnswers(answers, 'assessment')
            });
          }}
          onGoNext={() => {
            if (course.reaction) {
              setStep(SHOW_REACTION_EVALUATION);
            } else {
              onDismiss();
            }
          }}
          isSubmitting={loading}
          reevaluation
        />
      )}
      {step === SHOW_REACTION_EVALUATION && (
        <Evaluation
          type="Reaction"
          goNextButtonTitle="Close"
          evaluation={course.reaction}
          onSubmit={async (answers) => {
            await completeEvaluation({
              variables: {
                answers,
                userId: token.sub,
                courseId: course.id,
                evaluationId: course.reaction.id
              },
              update: updateAnswers(answers, 'reaction')
            });
          }}
          isSubmitting={loading}
          onGoNext={onDismiss}
        />
      )}
      {notification && (
        <Notification color={notification.color} name={notification.message} show>
          {notification.message}
        </Notification>
      )}
    </Modal>
  );
}
