import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { DocumentRecord, NamedEntityInput } from '@rio/rio-types';
import { GridApi } from 'ag-grid-community';

import { CLEAN } from '~/constants/antivirusStatuses';
import { S3 } from '~/constants/documentSources';
import { GET_ZIPPED_EXPORT } from '../../DocumentExplorer/index.queries';
import { useNotification } from '~/hooks';
import { Button, styled } from '@rio/ui-components';

const ButtonStyled = styled(Button)`
  width: 160px;
`;

interface ExportButtonsProps {
  gridApi: GridApi | { api: GridApi } | undefined;
  accountId: string;
}

const ExportButtons = ({ gridApi: gridApiProp, accountId }: ExportButtonsProps) => {
  const { showNotification } = useNotification();
  const gridApi = (gridApiProp as { api: GridApi })?.api || gridApiProp;
  const [generateZip] = useMutation(GET_ZIPPED_EXPORT, {
    onCompleted: () => {
      showNotification(
        'Your documents have been exported. Note: Web Links or Dashboard References are skipped.',
        'success'
      );
    },
    onError: () => {
      showNotification('Something went wrong. Try reducing the number of documents in export.', 'danger');
    },
  });

  const filterForExport = useCallback((documents: DocumentRecord[]): NamedEntityInput[] => {
    return documents
      .filter((doc) => doc.antivirusStatus === CLEAN && doc.source === S3)
      .map((doc) => {
        return { id: doc.id, name: doc.fileName };
      });
  }, []);

  const onDocumentExport = useCallback(
    async (onlySelected = false) => {
      const nodes: DocumentRecord[] = [];
      gridApi?.forEachNode((node) => {
        if (!onlySelected || node.isSelected()) {
          nodes.push(node.data);
        }
      });

      const files = filterForExport(nodes);

      if (!!files.length) {
        showNotification('Starting document export. For large exports, please wait up to 30 seconds.', 'success');
        const { data } = await generateZip({
          variables: {
            files,
            accountId,
          },
        });
        if (data?.getZippedFileExport) {
          window.open(data.getZippedFileExport);
        }
      } else {
        showNotification(
          'Your selection only contains Web Links and/or Dashboard References. These can not be exported.',
          'danger'
        );
      }
    },
    [gridApi, showNotification, accountId, filterForExport, generateZip]
  );

  return (
    <>
      <ButtonStyled variant="filled" onClick={() => onDocumentExport(true)} disabled={false}>
        Export Selected
      </ButtonStyled>
      <ButtonStyled variant="filled" onClick={() => onDocumentExport()}>
        Export Page
      </ButtonStyled>
    </>
  );
};

export default ExportButtons;
