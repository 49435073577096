import React from 'react';
import { useParams } from 'react-router-dom';
import Courses from './CoursesTab';
import { MarketBody } from './MarketBody';
import { LoadingIndicator } from 'rio-ui-components';
import { useCart, useGetCurrentUserQuery, useGetProductsQuery } from '../../../hooks';
import styled from 'styled-components';
import { useGetAccountUsersInfoPagedQuery } from '../../../hooks/user';

const Loader = styled(LoadingIndicator)`
  margin: 0 auto;
  position: relative;
  top: 70px;
`;

export function Catalog() {
  const { route = 'courses' } = useParams();

  const cart = useCart();

  const {
    data: { content },
    loading: isProductsLoading
  } = useGetProductsQuery();
  const { data: user, loading: isUserLoading } = useGetCurrentUserQuery();
  const {
    data: { content: accountUsers },
    loading: isaAccountUsersloading
  } = useGetAccountUsersInfoPagedQuery();

  const Component = {
    courses: Courses
  }[route];

  const isLoading =
    (isUserLoading || isProductsLoading || isaAccountUsersloading) &&
    (!content.itemList || content.itemList?.length < 1);

  const accountUsersCount = accountUsers.length;

  return (
    <MarketBody route={route} cart={cart}>
      {isLoading ? (
        <Loader />
      ) : (
        <Component cart={cart} content={content} currentUser={user} accountUsersCount={accountUsersCount} />
      )}
    </MarketBody>
  );
}
