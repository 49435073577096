import { clone } from 'lodash';
import { GET_USER_BY_USERNAME } from '../queries/users.js';
import { GET_ACCOUNT_INFO } from '../queries/accounts';

export function useUpdateCacheAccount() {
  const updateCacheAccount = (cache, createDepartment, accountId, userId) => {
    const cachedQuery = clone(
      cache.readQuery({
        query: GET_USER_BY_USERNAME,
        variables: { name: userId }
      })
    );
    const userData = {
      ...cachedQuery,
      getUserInfoById: {
        ...cachedQuery.getUserInfoById,
        account: {
          ...cachedQuery.getUserInfoById.account,
          departments: createDepartment
        }
      }
    };
    cache.writeQuery({
      query: GET_USER_BY_USERNAME,
      variables: { name: userId },
      data: userData
    });

    const cachedQueryAccount = clone(
      cache.readQuery({
        query: GET_ACCOUNT_INFO,
        variables: { id: accountId }
      })
    );
    const accountData = {
      ...cachedQueryAccount,
      getAccountInfo: {
        ...cachedQueryAccount.getAccountInfo,
        departments: createDepartment
      }
    };
    cache.writeQuery({
      query: GET_ACCOUNT_INFO,
      variables: { id: accountId },
      data: accountData
    });
  };
  return updateCacheAccount;
}
