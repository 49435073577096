import { Tabs, styled } from '@rio/ui-components';
import NoResults from '~/components/NoResults';
import { FEATURES } from '~/types';
import UpgradeBanner from '~/components/UpgradeBanner/UpgradeBanner';
import { useCurrentAccount } from '~/hooks';
import { TasksRoutes } from './TasksRoutes';
import { ProjectRoutes } from './ProjectRoutes';

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiTabs-flexContainer {
    padding: 0 32px;
  }
`;

export const TasksContainer = ({ hasProjectsAccess, hasTasksAccess }) => {
  const account = useCurrentAccount();
  const isProject = account?.accessControls?.projects;

  const defaultTabs = [
    {
      label: 'Tasks',
      to: '../tasks',
      children: (
        <>
          {!hasTasksAccess && (
            <NoResults name="TasksAndProjects__NoResults" title="Your account has no access to this section" />
          )}
          {hasTasksAccess && <TasksRoutes hasProjectsAccess={hasProjectsAccess} />}
        </>
      ),
    },
  ];

  const tabs = [
    ...defaultTabs,
    {
      label: 'Projects',
      to: '../projects',
      children: (
        <>
          {!hasProjectsAccess && <UpgradeBanner featureName={FEATURES.PROJECTS} link="/contact" />}
          {hasProjectsAccess ? <ProjectRoutes /> : ''}
        </>
      ),
    },
  ];

  return (
    <WrapperStyled>
      <Tabs tabs={isProject ? tabs : defaultTabs} />
    </WrapperStyled>
  );
};
