import { useCallback, useState } from 'react';
import { Button } from 'rio-ui-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { generateAndDownloadTemplate, downloadFileFromUrl } from '../../utils';
import { TemplateType } from '../../types';
import { MassUploadProp, MassUploadTemplate } from './types';

interface DownloadTemplateButtonProps {
  template: MassUploadProp;
  size?: string;
  color?: string;
}

export function DownloadTemplateButton({ template, size, color }: DownloadTemplateButtonProps) {
  const [downloadButtonElement, setDownloadButtonElement] = useState<null | HTMLElement>(null);
  const downloadMenuOpen = Boolean(downloadButtonElement);
  const multiple = Array.isArray(template);
  const handleDownloadMenuClose = useCallback(() => {
    setDownloadButtonElement(null);
  }, [setDownloadButtonElement]);

  const handleTemplateDownload = useCallback((template: MassUploadTemplate) => {
    if (template.type === TemplateType.Static) {
      downloadFileFromUrl(template.url);
    } else if (template.type === TemplateType.Dynamic) {
      generateAndDownloadTemplate(template.columns, template.fileName, template.exampleValues);
    }
  }, []);

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (multiple) {
        setDownloadButtonElement(event.currentTarget);
      } else {
        handleTemplateDownload(template);
      }
    },
    [setDownloadButtonElement, handleTemplateDownload, multiple, template]
  );

  return (
    <>
      <Button size={size || 'md'} color={color || 'secondary'} onClick={handleDownloadClick} inline>
        Download template
      </Button>
      {Array.isArray(template) && (
        <Menu anchorEl={downloadButtonElement} open={downloadMenuOpen} onClose={handleDownloadMenuClose}>
          {template.map((template) => (
            <MenuItem key={template.name} id={template.name} onClick={() => handleTemplateDownload(template)}>
              {template.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
