import _ from 'lodash';
import { COMPLETE } from '../../../../constants/courseProgress';

export const getRelatedCourses = (courses, courseId) => {
  const course = courses.find((item) => item.id === courseId);
  const filteredCourses = courses.filter((item) => {
    const { categories = [] } = item?.metaData || {};
    const itemIsCompleted = _.get(item, 'userCourseMetaData.courseProgress') === COMPLETE;
    if (course && _.intersection(categories, course?.metaData?.categories).length && item.id !== courseId && !itemIsCompleted) {
      return true;
    }
    return false;
  });
  return filteredCourses;
};
