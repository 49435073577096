import { useApolloClient } from '@apollo/client';
import { FilterData, TransactionType } from '@rio/rio-types';
import { ColDef, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { formatDate, gridValueFormatter, getAgRowId } from '~/utils';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { GET_HOTELSTAYS_TRANSACTIONS_PAGE_FILTER } from '../../../graphql/queries/hotelStays/GetHotelstaysTransactionsPageFilter.query';

export function useHotelStaysGridConfig(): { columnDefs: ColDef[] } {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = useCallback(
    async (params: SetFilterValuesFuncParams) => {
      try {
        const {
          data: { getHotelStaysTransactionsPageFilter: values },
        } = await client.query<{ getHotelStaysTransactionsPageFilter: FilterData[] }>({
          query: GET_HOTELSTAYS_TRANSACTIONS_PAGE_FILTER,
          variables: {
            accountId,
            energyType: TransactionType.Hotelstays,
            field: params.colDef.colId || params.colDef.field,
          },
        });
        params.success(values.map((item) => (item.value ? JSON.stringify(item) : (item.value as string))));
      } catch (err) {
        showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
      }
    },
    [accountId, client, showNotification]
  );

  const dateValueFormatter = useCallback(({ value }: ValueFormatterParams) => formatDate(value), []);

  const config = useMemo(
    () => ({
      getRowId: getAgRowId('id'),
      columnDefs: [
        {
          headerName: 'Start Date',
          field: 'startDate',
          valueFormatter: dateValueFormatter,
          cellStyle: {
            textAlign: 'center',
          },
          filter: 'agDateColumnFilter',
          initialSort: 'desc' as const,
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          valueFormatter: dateValueFormatter,
          cellStyle: {
            textAlign: 'center',
          },
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Location',
          colId: 'locationName',
          field: 'location.name',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Hotel Nights',
          field: 'hotelNights',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Home Working Days',
          field: 'homeWorkingDays',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Hotel stay / Home working country',
          field: 'country',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Electricity Tariff',
          field: 'tariff',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Data Provider',
          field: 'dataProvider',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Total Cost',
          field: 'totalCost',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Currency Code',
          field: 'currency',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Notes',
          field: 'notes',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reference',
          field: 'reference',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Invoice Number',
          field: 'invoiceNumber',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Evidence Reference',
          field: 'evidenceReference',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Other Reference',
          field: 'otherReference',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Carbon Emission Scope 3 (kgCO2e)',
          field: 'carbonScope3',
          filter: 'agNumberColumnFilter',
          headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
        },
        {
          headerName: 'Upload Reference',
          field: 'importBatchFileName',
          cellRenderer: 'uploadReference',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Tags',
          field: 'tags',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Departments',
          field: 'departments',
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
        },
        {
          headerName: 'Hotel Nights Factors used',
          field: 'reportingYearOfHSFactors',
          cellRenderer: 'link',
          cellRendererParams: {
            to: '/configuration/carbonconversionfactors/location/hotel-stays',
            type: 'HOTEL',
          },
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          headerTooltip:
            'The factor set applied to this transaction, click the link to see the factors for this year in more detail',
        },
        {
          headerName: 'Work From Home Factors used',
          field: 'reportingYearOfWFHFactors',
          cellRenderer: 'link',
          cellRendererParams: {
            to: '/configuration/carbonconversionfactors/location/hotel-stays',
            type: 'WORK_FROM_HOME',
          },
          filterParams: {
            values: getFilterValues,
            valueFormatter: gridValueFormatter,
          },
          headerTooltip:
            'The factor set applied to this transaction, click the link to see the factors for this year in more detail',
        },
      ],
    }),
    [dateValueFormatter, getFilterValues]
  );

  return config;
}
