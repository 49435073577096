import React, { useState } from 'react';
import { WasteStream, WasteCarbonConversionFactor, Ewc } from '@rio/rio-types';
import styled from 'styled-components';
import { Row, Col, Text } from 'rio-ui-components';
import keyBy from 'lodash/keyBy';
import round from 'lodash/round';
import mapValues from 'lodash/mapValues';
import { rangeInRange, mostRecentRange } from '../../../utils';
import { DateRange } from '../../../types';
import { WasteStreamSelect } from '../../../components/WasteStreamSelect';
import { wasteFactorsGridConfig } from './gridConfig';
import { CarbonConversionFactorsGrid } from './CarbonConversionFactorsGrid';
import { DateRangeSelect } from '../../../components/DateRangeSelect';
import { WasteFactorsDescription } from './FactorsDescriptions';

const ControlPanelContainer = styled(Row)`
  flex-flow: row nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const ControlPanelSection = styled(Col)`
  padding-left: 12px;
  padding-right: 12px;
`;

interface WasteCarbonConversionFactorsGridProps {
  factors: WasteCarbonConversionFactor[];
}

function getEwcCodeFromWasteStream(stream: WasteStream | null): Ewc {
  return stream?.ewc as Ewc;
}

function getFactorsForWasteStreamAndDate(factors: WasteCarbonConversionFactor[], dateRange: DateRange, ewcId?: string) {
  return factors.filter((f) => f.ewcId === ewcId && rangeInRange(dateRange, [f.startDate, f.endDate]));
}

export function WasteCarbonConversionFactorsGrid({ factors }: WasteCarbonConversionFactorsGridProps) {
  const dateRanges: DateRange[] = factors.map((f) => [f.startDate, f.endDate]);
  const [wasteStream, setWasteStream] = useState<WasteStream | null>(null);
  const [dateRange, setDateRange] = useState(mostRecentRange(dateRanges));
  const ewcCode = getEwcCodeFromWasteStream(wasteStream);
  const factorsForWasteStreamAndDate = getFactorsForWasteStreamAndDate(factors, dateRange!, ewcCode?.id);
  const factorsByDefraEquivalent = mapValues(
    keyBy(factorsForWasteStreamAndDate, 'defraEquivalent'),
    (f) => f.value && round(f.value, 4)
  );

  return (
    <div>
      <ControlPanelContainer container>
        <ControlPanelSection span={5} item>
          <WasteStreamSelect onChange={(stream) => setWasteStream(stream)} />
        </ControlPanelSection>
        <ControlPanelSection span={2} vdistribution="center" item container>
          <Text>EWC: {ewcCode ? ewcCode.code : 'Not specified'}</Text>
        </ControlPanelSection>
        <ControlPanelSection span={5} item>
          <DateRangeSelect
            ranges={dateRanges}
            value={dateRange!}
            onChange={(r) => setDateRange(r)}
            includeReportingYear
          />
        </ControlPanelSection>
      </ControlPanelContainer>
      <CarbonConversionFactorsGrid factors={[factorsByDefraEquivalent]} config={wasteFactorsGridConfig} />
      <WasteFactorsDescription />
    </div>
  );
}
