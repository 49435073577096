import moment from 'moment';
import humanizeDuration from 'humanize-duration';

export const formatDateLocal = (date, format = 'DD/MM/YYYY') => date && moment(date).format(format);
export const formatTimeLocal = (date, format = 'DD/MM/YYYY') => date && moment(date).format(format);

export const formatDate = (date, format = 'DD/MM/YYYY') => date && moment(date).utc().format(format);
export const formatTime = (date, format = 'HH:mm') => date && moment(date).utc().format(format);

export const removeTimezone = (date) => date.slice(0, -1);
export const toDateObj = (date) => (date ? new Date(removeTimezone(date)) : null);

export const formatDuration = (duration) => (duration ? humanizeDuration(parseInt(duration / 1000) * 1000) : '');
export const formatDatetime = (date, format = 'DD/MM/YYYY HH:mm') => (date ? moment(date).format(format) : '');

export const getTimezoneOffset = () => {
  const date = new Date();
  return date.getHours() - date.getUTCHours();
};

/**
 * Method returns date with subtracted utc offset.
 * Use it to ensure that you want to have `Date` object
 * displaying same value as input, not depending on local timezone
 * 2020-02-06T23:59:59.000Z -> Date object Thursday Feb 06 2020 23:59:59 GMT+0800
 * @param string date
 * @returns
 */
export const utcToLocaleDate = (date) => moment(date).subtract(getTimezoneOffset(), 'hours').toDate();

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (!cellValue) return 0;

  const cellDate = new Date(cellValue).setHours(0, 0, 0, 0);

  return cellDate < filterLocalDateAtMidnight ? -1 : cellDate > filterLocalDateAtMidnight ? 1 : 0;
};
