import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { useNotification } from '../../../hooks';
import { UPDATE_ENERGY_SOURCE } from './index.queries';
import { Heading, Row, Col, Label, TextInput, Button, Checkbox } from 'rio-ui-components';
import { LocationSelect } from '../../../components/LocationSelect';
import { DatePicker } from 'rio-ui-components';
import { EnergySourcesSelection } from './EnergySourcesSelection';
import { useUpdateMeterModalForm } from './useUpdateMeterModalForm';

const Container = styled.div`
  padding: ${(props) => props.theme.geometry.lg.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

export type UpdateEnergyMeterProps = {
  accountId: string;
  sourceId: string;
  onSuccess: (msg: string) => void;
};

// TODO: refactor to unite with CreateEnergyMeterModal
export const UpdateEnergyMeter = ({ accountId, onSuccess, sourceId }: UpdateEnergyMeterProps) => {
  const { showNotification } = useNotification();

  const {
    state,
    changeMeterName,
    changeLocation,
    changeMeterType,
    isValidData,
    changeFieldValue,
    waterMeterType,
    toggleWaterMeterIn,
    toggleWaterMeterOut,
  } = useUpdateMeterModalForm(sourceId);

  // Handlers
  const HandleMeterNameChange = (event) => {
    changeMeterName({
      value: event.target.value,
    });
  };

  const HandleTypeSelectorChange = (event) => {
    const {
      select: { value },
    } = event.target;

    changeMeterType({
      value,
      label: value.name,
    });
  };

  const HandleLocationSelectChange = (event) => {
    const fieldValue = event.target.value;
    changeLocation({
      value: fieldValue,
      label: event.target.label,
    });
  };

  const HandleUpdateEnergySourceClick = (updateFunc) => {
    updateFunc({
      variables: {
        id: sourceId,
        name: state.meterName.value,
        locationId: state.locationId.value,
        typeId: state.typeId.value.id,
        accountId,
        meterId: sourceId,
        inOut: waterMeterType,
        isGeneration:
          state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam'
            ? state.isGeneration || false
            : null,
        meterCode: state.code,
        hardwareProviderName: state.hardwareProviderName,
        operatorProviderName: state.operatorProviderName,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    });
  };

  const RenderUpdateLocationsButton = () => {
    return (
      <Row container align="between">
        <ColStyled item>
          <Mutation
            mutation={UPDATE_ENERGY_SOURCE}
            onCompleted={() => {
              onSuccess(`The Meter ${state.meterName.value} was updated successfully`);
            }}
            onError={() => {
              showNotification(`Something went wrong. If the problem persists, contact support.`, 'danger');
            }}
          >
            {(updateEnergySource, { called }) => {
              return (
                <Button
                  name="update-energyMeter__button--update"
                  color="success"
                  disabled={!isValidData || called}
                  onClick={() => HandleUpdateEnergySourceClick(updateEnergySource)}
                >
                  Update Meter
                </Button>
              );
            }}
          </Mutation>
        </ColStyled>
      </Row>
    );
  };

  const selectStartDate = (value) => {
    changeFieldValue({
      fieldName: 'startDate',
      value,
    });
  };
  const selectEndDate = (value) => {
    changeFieldValue({
      fieldName: 'endDate',
      value,
    });
  };

  return (
    <Container>
      <HeaderStyled name="update-energyMeter__heading" size="lg">
        Update Meter
      </HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>What do you want to call this meter?</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            key="meterName"
            value={state.meterName.value}
            error={state.meterName.error}
            onChange={HandleMeterNameChange}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>What is the meter type?</LabelStyled>
          </LabelContainerStyled>
          <EnergySourcesSelection value={state.typeId} onChange={HandleTypeSelectorChange} error={state.typeId.error} />
        </ColStyled>
      </Row>
      {state.typeId.value.name === 'Water' && (
        <Row container align="between">
          <ColStyled item>
            <LabelStyled>{state.typeId.value.name} In</LabelStyled>
            <Checkbox id="create-meter-in" name="in" onChange={() => toggleWaterMeterIn()} checked={state.meterIn} />
          </ColStyled>
          <ColStyled item>
            <LabelStyled>{state.typeId.value.name} Out</LabelStyled>
            <Checkbox
              id="create-meter-out"
              name="out"
              onChange={() => toggleWaterMeterOut()}
              checked={state.meterOut}
            />
          </ColStyled>
          Ç
        </Row>
      )}
      {(state.typeId.value.name === 'Electricity' || state.typeId.value.name === 'Heat & Steam') && (
        <Row container align="between">
          <ColStyled item>
            <LabelStyled>Renewable energy generating meter</LabelStyled>
            <Checkbox
              disabled={state.isGeneration}
              id="is-generating-meter"
              name="isGeneration"
              onChange={() =>
                changeFieldValue({
                  fieldName: 'isGeneration',
                  value: !state.isGeneration,
                })
              }
              checked={state.isGeneration || false}
            />
          </ColStyled>
        </Row>
      )}
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Which location is this meter at?</LabelStyled>
          </LabelContainerStyled>
          <LocationSelect
            value={state.locationId}
            onChange={HandleLocationSelectChange}
            error={state.locationId.error}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Meter Code</LabelStyled>
          </LabelContainerStyled>
          <TextInput
            value={state.code}
            onChange={(e) => changeFieldValue({ fieldName: 'code', value: e.target.value })}
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Has this meter closed?</LabelStyled>
          </LabelContainerStyled>

          <LabelContainerStyled>
            <LabelStyled>Start Date</LabelStyled>
          </LabelContainerStyled>

          <DatePicker
            name="startDate"
            box
            onDayClick={selectStartDate}
            selectedDate={state.startDate}
            disabledDatesAfter={state.endDate}
          />

          <LabelContainerStyled>
            <LabelStyled>End Date</LabelStyled>
          </LabelContainerStyled>
          <DatePicker
            name="endDate"
            box
            onDayClick={selectEndDate}
            selectedDate={state.endDate}
            disabledDatesBefore={state.startDate}
          />
        </ColStyled>
      </Row>
      <RenderUpdateLocationsButton />
    </Container>
  );
};

export default UpdateEnergyMeter;
