import { ChangeEvent, useMemo } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { Account } from '@rio/rio-types';
import { MultiSelect } from 'rio-ui-components';
import { Option } from '../../types';
import { GET_ACCOUNTS } from './index.queries';

const accountToSelectOptions = ({ id, name }: Account): Option => ({
  value: id,
  label: name,
});

export const loadOptions = (client: ApolloClient<object>) => async (filterValue: string) => {
  const {
    data: { getAccounts },
  } = await client.query({
    query: GET_ACCOUNTS,
    variables: {
      filters: {
        text: [
          {
            filterType: 'text',
            type: 'contains',
            filter: filterValue,
            field: 'name',
          },
        ],
      },
    },
  });
  const { totalRows } = getAccounts;
  const accounts: Option[] = getAccounts.rows ? getAccounts.rows.map(accountToSelectOptions) : [];

  return { options: accounts, total: totalRows };
};

interface AccountsMultiSelectProps {
  name: string;
  value: Option[];
  onChange: (event: ChangeEvent<any>) => void;
  error?: string;
  isSubmitting: boolean;
  defaultOptions?: Option[];
  disabled?: boolean;
  multiValueColor: string;
}

export const AccountsMultiSelect = ({
  multiValueColor = 'tertiary',
  name = 'accountIds',
  value,
  onChange,
  error,
  isSubmitting,
  defaultOptions,
  disabled,
}: AccountsMultiSelectProps) => {
  const client = useApolloClient();

  return (
    <MultiSelect
      id="Accounts__MultiSelect"
      name={name}
      onChange={onChange}
      isDisabled={isSubmitting || disabled}
      error={error}
      multiValueColor={multiValueColor}
      defaultOptions={Array.isArray(defaultOptions) ? defaultOptions : true}
      value={value}
      placeholder={'Start typing to search for an account'}
      loadOptions={useMemo(() => loadOptions(client), [client])}
    />
  );
};
