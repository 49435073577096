import { useGetAccountProductLicencesCountQuery } from './useGetAccountProductLicencesCountQuery'
import { useGetUsersInfoByAccountIdPagedQuery } from '../user';

export function useGetActivatedLicencesCountByAccountIdQuery(accountId) {
  const { data: licences } = useGetAccountProductLicencesCountQuery(accountId);
  const { data: { users = [] } = {} } = useGetUsersInfoByAccountIdPagedQuery(accountId)

  return {
    usersCount: users.length,
    productLicences: licences
  }
}
