import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import IntelligenceHome from './IntelligenceHome';

const ContainerStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const IntelligenceContainer = () => {
  return (
    <ContainerStyled>
      <Routes>
        <Route path="/" element={<IntelligenceHome />} />
        <Route path="/:id" element={<IntelligenceHome />} />
      </Routes>
    </ContainerStyled>
  );
};

export default IntelligenceContainer;
