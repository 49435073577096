import { styled, Modal } from '@rio/ui-components';
import NotificationBanner from '../../../components/NotificationBanner';
import { V2LoginForm } from './V2LoginForm';
import { useLoginForm } from './useLoginForm';
import { V2ForgotPassword } from '../ForgotPassword/V2ForgotPassword';
import { getEnvVar } from '../../../env';

const ContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  place-items: center;

  & > #notification_banner {
    position: absolute;
    width: auto;
    background-color: ${({ theme }) => theme.sys.color.primary};
  }
`;

const Row = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const ColStyled = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-width: 30rem;
`;

export const V2LoginContainer = () => {
  const { handleChange, dismissForgotPassword, handleForgotPassword, handleSubmit, state } = useLoginForm();
  return (
    <ContainerStyled>
      {(getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'Develop' ||
        getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'QA' ||
        getEnvVar('REACT_APP_ENVIRONMENT_NAME') === 'UAT') && (
        <NotificationBanner
          id="notification_banner"
          name="LoginContainer__NotificationBanner"
          color="primary"
          icon="exclamation-triangle"
          title={`This is the ${getEnvVar('REACT_APP_ENVIRONMENT_NAME')} site`}
          body="Please note that we regularly reset the data and anything you enter may be overwritten. We recommend that personal details of real individuals are not entered"
          dismissable={false}
          onDismiss={null}
        />
      )}
      <Modal open={state.forgotPasswordModal} onClose={dismissForgotPassword}>
        <V2ForgotPassword id="forgotPasswordModal__Contents" username={state.email.value} />
      </Modal>
      <Row>
        <ColStyled>
          <V2LoginForm
            email={state.email}
            password={state.password}
            handleChange={handleChange}
            handleForgotPassword={handleForgotPassword}
            handleSubmit={handleSubmit}
            isLoading={state.isLoading}
            loginError={state.loginError}
          />
        </ColStyled>
      </Row>
    </ContainerStyled>
  );
};
