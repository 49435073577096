import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useCurrentAccountId, usePageSuspendingQuery } from '~/hooks';
import { getPathnameId, hasUUID } from '~/utils/surveyNavigation';
import { GET_RECEIVED_SURVEY_BY_ID } from '../queries';
import { ReceivedSurvey } from './ReceivedSurvey';

export const ReceivedSurveyContainer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathId = getPathnameId(pathname);
  const accountId = useCurrentAccountId();
  const [searchParams] = useSearchParams();
  const contributorId = searchParams.get('contributorId');

  const id = useMemo(() => hasUUID(pathname) && pathId, [pathId, pathname]);

  const { data } = usePageSuspendingQuery(GET_RECEIVED_SURVEY_BY_ID, {
    variables: {
      id,
      accountId: contributorId || accountId,
    },
    fetchPolicy: 'no-cache',
    keepErrorAfterUnmount: true,
  });

  return data && <ReceivedSurvey survey={data.getReceivedSurveyById} />;
};
