import { gql } from '@apollo/client';

export const GET_METERS_BY_ACCOUNT_ID = gql`
  query getEnergySourceByAccountIdAndType(
    $accountId: ID!
    $type: TransactionType
    $offset: Int = 0
    $limit: Int = 25
    $sort: [SortCommand!] = []
    $filters: Filters = {}
  ) {
    getEnergySourceByAccountIdAndType(
      accountId: $accountId
      type: $type
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        name
      }
    }
  }
`;

export const GET_ENERGY_SOURCE_FILTER = gql`
  query GetEnergySourceFilter($accountId: ID!, $field: String!) {
    getEnergySourcePageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
