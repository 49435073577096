import { styled, useTheme } from '@rio/ui-components';
import { getPriorityIconProps } from '../../priorityIconPropsV2';

const WrapperStyled = styled('div')<{ fill: string }>`
  display: flex;
  align-items: center;

  path {
    fill: ${(props) => props.fill};
  }
`;

interface Props {
  data: {
    isCompleted: boolean;
    dueDate: Date | string;
  };
}

export const StatusCell = ({ data }: Props) => {
  const theme = useTheme();

  const { isCompleted, dueDate } = data;
  const taskPriority = isCompleted ? 'COMPLETED' : new Date(dueDate) > new Date() ? 'INCOMPLETED' : 'OVERDUE';

  const { icon: Icon, fill } = getPriorityIconProps(taskPriority, theme);

  return (
    <WrapperStyled fill={fill}>
      <Icon />
    </WrapperStyled>
  );
};
