import { Checkbox } from 'rio-ui-components';
import { Text } from 'rio-ui-components';
import { PropertySheet } from 'rio-ui-components';
import styled from 'styled-components';
import { RecipientsGrid } from './RecipientsGrid';
import { RecipientSurveyType, useSurveyForm } from './hooks/useSurveyForm';
import { RadioButton } from '@rio/ui-components';
import { FormControlLabel, FormGroup } from '@mui/material';
import { Label } from 'rio-ui-components';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionSubText = styled(Text)`
  padding: 0;
  padding-left: 30px;
`;

export type RecipientsProp = {
  hideNotifyByEmail?: boolean;
};

export const Recipients = ({ hideNotifyByEmail }: RecipientsProp) => {
  const {
    state: survey,
    surveyType,
    toggleNotifyByEmail,
    selectIndividualContributors,
    selectContributors,
  } = useSurveyForm();

  return (
    <>
      <PropertySheet.Row>
        {!hideNotifyByEmail && (
          <PropertySheet.TextColumn size="sm" color={'normal'}>
            Your survey recipients will be asked to fill in these survey/s
          </PropertySheet.TextColumn>
        )}
      </PropertySheet.Row>
      <PropertySheet.Row>
        <Label>Recipients</Label>
      </PropertySheet.Row>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          control={
            <RadioButton
              name="contributorsType"
              checked={surveyType === RecipientSurveyType.user}
              value={RecipientSurveyType.user}
              onChange={() => selectIndividualContributors([])}
            />
          }
          label={
            <Text size="md" color="normal">
              One survey per user
            </Text>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <RadioButton
              name="contributorsType"
              checked={surveyType === RecipientSurveyType.contributor}
              value={RecipientSurveyType.contributor}
              onChange={() => selectContributors([])}
            />
          }
          label={
            <Text size="md" color="normal">
              One survey per account
            </Text>
          }
          labelPlacement="end"
        />
      </FormGroup>
      {!hideNotifyByEmail && (
        <PropertySheet.Row>
          <PropertySheet.Column>
            <Container>
              <Checkbox
                id="AddSurveyForm_Checkbox_SendAllContributors"
                inline
                onChange={() => toggleNotifyByEmail()}
                name="notifyByEmail"
                checked={survey.notifyByEmail}
              />
              <PropertySheet.TextColumn size="md" color={'normal'}>
                Notify recipients by email
              </PropertySheet.TextColumn>
            </Container>
            <Container>
              <OptionSubText size="sm" color="normal">
                Contact a system administrator to add, list and manage contributors.
                <br />
                All users listed for each contributor will receive the survey.
              </OptionSubText>
            </Container>
          </PropertySheet.Column>
        </PropertySheet.Row>
      )}
      <RecipientsGrid />
    </>
  );
};
