import { useQuery } from '@apollo/client';
import { GET_ACCOUNTS } from './index.queries';

export const GET_ACCOUNTS_VARIABLES = {
  limit: 25
};

export function useGetAccounts() {
  return useQuery(GET_ACCOUNTS, {
    variables: GET_ACCOUNTS_VARIABLES
  });
}
