import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { AliasContainer } from '../AliasContainer';
import OcrReviewContainer from '../OcrReviewContainer';
import DataOverviewContainer from '../DataOverview/v2';
import { GasTransactionsGrid } from './GasTransactionsGrid';
import { FuelTransactionsGrid } from './FuelTransactionsGrid';
import { WasteTransactionsGrid } from './WasteTransactionsGrid';
import { MaterialsTransactionsGrid } from './MaterialsTransactionsGrid';
import { TransportTransactionsGrid } from './TransportTransactionsGrid';
import { HeatSteamTransactionsGrid } from './HeatSteamTransactionsGrid';
import { BuildingEnergyRatingsGrid } from './BuildingEnergyRatingsGrid';
import { WaterTransactionsContainer } from './WaterTransactionsContainer';
import { HotelStaysTransactionsGrid } from './HotelStaysTransactionsGrid';
import { InvestmentTransactionsGrid } from './InvestmentTransactionsGrid';
import { ElectricityTransactionsGrid } from './ElectricityTransactionsGrid';
import { RefrigerantsTransactionsGrid } from './RefrigerantsTransactionsGrid';
import { ElectricityUploadsGrid } from './Uploads/ElectricityUploadsGrid';
import { WasteUploadsGrid } from './Uploads/WasteUploadsGrid';
import { V2GasUploadGrid } from './Uploads/V2GasUploadGrid';
import { V2WaterUploadGrid } from './Uploads/V2WaterUploadGrid';
import { V2TransportUploadGrid } from './Uploads/V2TransportUpload';
import { V2FuelUploadGrid } from './Uploads/V2FuelUploadGrid';
import { V2MaterialsUploadGrid } from './Uploads/V2MaterialsUploadGrid';
import { V2HotelStaysUploadGrid } from './Uploads/V2HotelStaysUploadGrid';
import { V2HeatSteamUploadGrid } from './Uploads/V2HeatSteamUploadGrid';
import { V2RefrigerantsUploadGrid } from './Uploads/V2RefrigerantsUploadGrid';
import { V2InvestmentUploadGrid } from './Uploads/V2InvestmentUploadGrid';
import { ApplicationData } from '~/types';

const GetViewContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();
  if (dataAccessControls?.[subject!]) {
    switch (subject!) {
      case 'waste':
        return <WasteTransactionsGrid />;
      case 'electricity':
        return <ElectricityTransactionsGrid />;
      case 'gas':
        return <GasTransactionsGrid />;
      case 'water':
        return <WaterTransactionsContainer />;
      case 'transport':
        return <TransportTransactionsGrid />;
      case 'fuel':
        return <FuelTransactionsGrid />;
      case 'materials':
        return <MaterialsTransactionsGrid />;
      case 'refrigerants':
        return <RefrigerantsTransactionsGrid />;
      case 'heatSteam':
        return <HeatSteamTransactionsGrid />;
      case 'buildingEnergyRatings':
        return <BuildingEnergyRatingsGrid />;
      case 'hotelStays':
        return <HotelStaysTransactionsGrid />;
      case 'investment':
        return <InvestmentTransactionsGrid />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

const GetUploadContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();

  if (dataAccessControls[subject!]) {
    switch (subject) {
      case 'waste':
        return <WasteUploadsGrid />;
      case 'electricity':
        return <ElectricityUploadsGrid />;
      case 'gas':
        return <V2GasUploadGrid />;
      case 'water':
        return <V2WaterUploadGrid />;
      case 'transport':
        return <V2TransportUploadGrid />;
      case 'fuel':
        return <V2FuelUploadGrid />;
      case 'materials':
        return <V2MaterialsUploadGrid />;
      case 'hotelStays':
        return <V2HotelStaysUploadGrid />;
      case 'heatSteam':
        return <V2HeatSteamUploadGrid />;
      case 'refrigerants':
        return <V2RefrigerantsUploadGrid />;
      case 'investment':
        return <V2InvestmentUploadGrid />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

const GetOcrReviewContainerBySubject = ({ dataAccessControls }) => {
  const { subject } = useParams();

  if (dataAccessControls[subject!]) {
    switch (subject) {
      case 'electricity':
      case 'gas':
      case 'water':
      case 'heatSteam':
        return <OcrReviewContainer utility={subject.toUpperCase()} />;
      default:
        return null;
    }
  }
  return <Navigate to="data" />;
};

interface DataContainerProps {
  applicationData: ApplicationData;
}

const DataContainer = ({ applicationData: { getAccountInfo } }: DataContainerProps) => {
  return (
    <Routes>
      <Route path="" element={<DataOverviewContainer />} />
      <Route
        path=":subject/view"
        element={<GetViewContainerBySubject dataAccessControls={getAccountInfo.accessControls.data} />}
      />
      <Route
        path=":subject/uploads"
        element={<GetUploadContainerBySubject dataAccessControls={getAccountInfo.accessControls.data} />}
      />
      <Route
        path=":subject/uploads/ocr"
        element={<GetOcrReviewContainerBySubject dataAccessControls={getAccountInfo.accessControls.data} />}
      />
      <Route path=":subject/uploads/alias/:id" element={<AliasContainer />} />
      <Route path=":subject/" element={<Navigate to="./view" />} />
      <Route path="*" element={<Navigate to="/v2/data" />} />
    </Routes>
  );
};

export default DataContainer;

export { dromoConfiguration } from '../DromoConfiguration';
export { default as UploadContainer } from '../UploadContainer';
