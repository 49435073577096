/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ContainerHeader from '../../../components/ContainerHeader';
import { Async } from '../../../components/Async';
import { Page } from '../SurveyAuditLogContainer/styles';
import { useGetAuditByEntityId, GetAuditByEntityIdResponse, useCurrentAccountId } from '../../../hooks';
import { ContributorAuditLogGrid } from '../ReceivedSurveyAuditLogContainer';
import { SurveysPlaceholder } from '../../../components/SurveysPlaceholder';
import { PageFailed } from '../../../components/Errors';
import { useGetMyContributorOverview, GetMyContributorOverviewResponse } from './queries';
import { Breadcrumb } from '~/types';

const defaultBreadcrumbs = [
  { to: '../sent/overview', title: 'Surveys sent' },
  { to: '../sent/contributors', title: 'Contributors' },
];

const Placeholder = () => (
  <SurveysPlaceholder
    title="Contributor hasn't responded to your questions yet"
    description="Responses will be logged soon after contributor saves his answers"
    icon={['rio', 'clipboard-check-custom']}
  />
);

export function MyContributorAuditLogContainer() {
  const { contributorId, surveyId } = useParams();
  const [contributorAccountId, contributorUserId] = contributorId!.split(':');
  const accountId = useCurrentAccountId();
  const contributorData = useGetMyContributorOverview(accountId, contributorAccountId!, contributorUserId);
  const myContributor = contributorData.data?.getMySurveyContributorById;
  const survey = myContributor?.surveys?.find((s) => s.id === surveyId);
  const audit = useGetAuditByEntityId(survey?.submission?.id);

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    if (myContributor && survey) {
      return [
        ...defaultBreadcrumbs,
        {
          to: `../sent/contributors/${myContributor.account.id}`,
          title: myContributor.account.name,
        },
        survey.submission?.id && {
          to: `../sent/contributors/${myContributor.account.id}/submission/${survey.submission.id}`,
          title: survey.name,
        },
        {
          title: 'Response log',
        },
      ].filter(Boolean) as Breadcrumb[];
    }
    return defaultBreadcrumbs;
  }, [myContributor, survey]);

  const renderError = useCallback((error: Error) => {
    return error.message.toLowerCase().includes('not found') ? <Placeholder /> : <PageFailed error={error} />;
  }, []);

  return (
    <Page>
      <ContainerHeader
        name="SurveyMyContributorAuditLog"
        icon="clipboard-check"
        iconColor="quaternary"
        title="Response log"
        breadcrumb={breadcrumbs}
      />
      <Async<GetMyContributorOverviewResponse>
        result={contributorData}
        data={useCallback(
          () =>
            survey?.submission ? (
              <Async<GetAuditByEntityIdResponse>
                result={audit}
                data={({ getAuditByEntityId }) => <ContributorAuditLogGrid rows={getAuditByEntityId} />}
              />
            ) : (
              <Placeholder />
            ),
          [audit, survey?.submission]
        )}
        error={renderError}
      />
    </Page>
  );
}
