import { COMPLETE, INPROGRESS } from '../../constants/courseProgress';

export const filterExplore = (c) =>
  !c.userCourseMetaData ||
  (c.userCourseMetaData && ![INPROGRESS, COMPLETE].includes(c.userCourseMetaData.courseProgress));
export const filterContinue = (c) => c.userCourseMetaData && c.userCourseMetaData.courseProgress === INPROGRESS;
export const filterComplete = (c) => c.userCourseMetaData && c.userCourseMetaData.courseProgress === COMPLETE;
export const sortByDateDesc = (a, b) =>
  a.userCourseMetaData?.lastUpdatedAt > b.userCourseMetaData?.lastUpdatedAt
    ? -1
    : a.userCourseMetaData?.lastUpdatedAt < b.userCourseMetaData?.lastUpdatedAt
    ? 1
    : 0;
export const capitalise = (word) => word.split('')[0].toUpperCase() + word.slice(1);
