import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import {
  Query,
  QueryGetEnergySourceByAccountIdAndTypeArgs,
  DataType,
  FilterStrategy,
  TransactionType,
  EnergySource
} from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { Nullable, SelectEvent, Option } from '../../types';
import { usePermissions } from '../../hooks';
import { GET_METERS_BY_ACCOUNT_ID } from '../../graphql/queries/energySource/getEnergySourceByAccountIdAndType.queries';
import { toSelectOptions, CREATE_NEW_OPTION, CREATE_NEW_OPTION_ID } from '../../utils';
import { CreateMeterModal } from '../CreateMeterModal';

interface MeterSelectProps {
  type: TransactionType;
  value: Nullable<string | Option>;
  accountId: string;
  onChange: (id: string, name: string) => void;
  createNew?: boolean;
}

const order = [
  {
    order: 'asc',
    field: 'name'
  }
];

export function MeterSelect({ type, onChange, accountId, value, createNew }: MeterSelectProps) {
  const permissions = usePermissions();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const onSuccess = (_: string, { id, name }: EnergySource) => {
    onChange(id, name);
    setShowCreateModal(false);
  };
  const client = useApolloClient();
  return (
    <>
      {showCreateModal && (
        <CreateMeterModal
          showModal
          energyType={type}
          onDismiss={() => setShowCreateModal(false)}
          onSuccess={onSuccess}
        />
      )}
      <Select
        placeholder="Begin typing to find meters"
        value={value}
        defaultOptions
        debounceTimeout={1000}
        loadOptions={async (filterValue: string) => {
          const variables: QueryGetEnergySourceByAccountIdAndTypeArgs = {
            accountId,
            type,
            filters: {
              text: [
                {
                  filterType: DataType.Text,
                  type: FilterStrategy.Contains,
                  filter: filterValue,
                  field: 'name'
                }
              ]
            },
            sort: order
          };
          const {
            data: {
              getEnergySourceByAccountIdAndType: { rows, totalRows }
            }
          } = await client.query<Pick<Query, 'getEnergySourceByAccountIdAndType'>>({
            query: GET_METERS_BY_ACCOUNT_ID,
            variables
          });
          const options = toSelectOptions(rows);
          if (createNew && permissions.data.find((action: string) => action.startsWith('createEnergySource'))) {
            options.unshift(CREATE_NEW_OPTION);
          }
          return { options, total: totalRows };
        }}
        onChange={({ target: { select } }: SelectEvent) => {
          if (select.value === CREATE_NEW_OPTION_ID) {
            setShowCreateModal(true);
          } else {
            onChange(select.value, select.label);
          }
        }}
      />
    </>
  );
}
