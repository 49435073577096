import { useEffect } from 'react';
import { createXls, objectInRowsToStringValue, formatDate } from '~/utils';
import { useGridHooksByDataType, useUploadFileMutation } from '~/hooks';
import { DIRECT } from '~/constants/transactionActivityTypes';
import { styled, LinearProgress } from '@rio/ui-components';
import { TransactionType } from '@rio/rio-types';
import { ConvertTuple } from '~/hooks/useGridHooksByDataType';

const LinearProgressContainer = styled('div')`
  width: 100%;
  margin-top: 16px;
  height: 4px;
`;

const LinearProgressStyled = styled(LinearProgress)`
  width: 100%;
`;

const getDirectActivity = (formData) => {
  return formData.data.activity === DIRECT;
};

const getUploadFileName = ({ startDate, endDate, uploadReference }) => {
  const dateFormat = 'DD-MM-YYYY';
  const tillDate = endDate ? `—${formatDate(endDate, dateFormat)}` : '';
  const dateRange = `${formatDate(startDate, dateFormat)}${tillDate}`;

  const reference = uploadReference.replace(/[^a-zA-Z0-9]/g, ' ');
  return { sheetName: dateRange, fileName: `${dateRange} ${reference}` };
};

type Props = {
  onUploadCompleted?: () => void;
  onError?: (error: unknown) => void;
  formData?: Record<string, unknown>;
  dataSection: TransactionType;
};

export function DataFormXlsUploader(props: Props) {
  const { onUploadCompleted = () => {}, onError = () => {}, formData = {}, dataSection } = props;

  const [columns, convertTuple] = useGridHooksByDataType(dataSection);
  const [convert, { data: rows }] = convertTuple as ConvertTuple;

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    convert(formData, columns);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!columns.length || !rows.length) {
      return;
    }

    const run = async () => {
      const mappedRows = objectInRowsToStringValue(rows, columns);

      const { sheetName, fileName } = getUploadFileName(
        formData as { startDate: string; endDate: string; uploadReference: string; [key: string]: unknown }
      );
      const file = createXls(mappedRows, fileName, 'xlsx', sheetName);

      const isWaste = dataSection === 'WASTE';

      try {
        await uploadFile({
          file,
          fileName: file.name,
          transactionType: dataSection,
          isDirectActivity: isWaste ? false : getDirectActivity(formData),
        });
        onUploadCompleted();
      } catch (e) {
        onError(e);
      }
    };

    run();
    // eslint-disable-next-line
  }, [rows, columns]);

  return (
    <LinearProgressContainer>
      <LinearProgressStyled />
    </LinearProgressContainer>
  );
}
