import axios from 'axios';
import * as Sentry from '@sentry/react';

export const resolvers = {
  Mutation: {
    uploadDataFile: async (_, { file, url }, { cache }) => {
      const headers = {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      };
      if (!url) {
        headers.Authorization = localStorage.getItem('ACCESS_TOKEN');
      }

      return uploadFile({ url, headers, file, cache });
    }
  }
};

export const uploadFile = async ({ url, headers, file }) => {
  try {
    const response = await axios({
      method: 'PUT',
      url,
      headers,
      data: file
    });

    const data = {
      status: response.status,
      fileUploaded: response.status === 200
    };

    Sentry.withScope(scope => {
      scope.setTag('action', 'document_uploading');
      Sentry.captureMessage('Upload document', { 
        extra: {
          ...response,
        } 
      });
    });

    return data;
  } catch (error) {
    console.error(error);
    Sentry.withScope(scope => {
      scope.setTag('action', 'document_uploading');
      Sentry.captureException(error, { 
        extra: {
          url,
          error,
        } 
      });
    });
  }
};
