import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CredentialsForm } from './CredentialsForm';
import { ProfileForm } from './ProfileForm';
import { ContactsForm } from './ContactsForm';
import { VerifyEmailPrompt } from './VerifyEmailPrompt';
import { useRegistrationForm } from './useRegistrationForm';

const RegistrationContainer = () => {
  const {
    form,
    errors,
    setErrors,
    promptShown,
    queryParams,
    handleChange,
    handleClosePrompt,
    handleProfileChange,
    handleProfileSubmit,
    handleContactsSubmit,
    handleCheckboxChange,
    handleCredentialsSubmit,
  } = useRegistrationForm();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <CredentialsForm
              form={form}
              errors={errors}
              setErrors={setErrors}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleCredentialsSubmit={handleCredentialsSubmit}
            />
          }
        />
        <Route
          path="/profile"
          element={
            <ProfileForm
              form={form}
              errors={errors}
              setErrors={setErrors}
              handleChange={handleChange}
              handleProfileChange={handleProfileChange}
              handleProfileSubmit={handleProfileSubmit}
            />
          }
        />
        <Route
          path="/contacts"
          element={
            <ContactsForm
              form={form}
              errors={errors}
              setErrors={setErrors}
              queryParams={queryParams}
              handleChange={handleChange}
              handleContactsSubmit={handleContactsSubmit}
            />
          }
        />
      </Routes>
      <VerifyEmailPrompt
        show={promptShown}
        email={form.email}
        onDismiss={handleClosePrompt}
        queryParams={queryParams}
      />
    </>
  );
};

export { RegistrationContainer };
