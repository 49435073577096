import Highcharts from 'highcharts';
import { baseChart } from './baseChart';

export const basePieChart: Highcharts.Options = {
  ...baseChart,
  legend: {
    ...baseChart.legend,
    labelFormatter: function () {
      return this.name.replace(/: .+?$/, '');
    },
  },
};
