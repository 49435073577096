import { SurveyTemplateInput } from '@rio/rio-types';
import validator from 'validator';
import { Errors } from '../../../types';

type Invalid = [Errors<SurveyTemplateInput>, null];

type Valid = [null, SurveyTemplateInput];

export function validate(template: Partial<SurveyTemplateInput>): Valid | Invalid {
  const errors: Errors<SurveyTemplateInput> = {};

  if (!template.name) {
    errors.name = 'Name is required';
  }
  if (!template.formContent) {
    errors.formContent = 'Survey content is required';
  }

  if (!template.ownerId) {
    errors.ownerId = 'Owner is required';
  }

  // @ts-ignore
  if (!validator.isJSON(template.formContent as unknown as string)) {
    errors.formContent = 'Should be a valid JSON string';
  }

  if (Object.keys(errors).length) {
    return [errors, null];
  }
  return [null, template as SurveyTemplateInput];
}
