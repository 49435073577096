import { GridApi } from 'ag-grid-community';
import { useState, useCallback } from 'react';

export const useFiltersApplied = (agGridApi: React.MutableRefObject<GridApi | undefined>) => {
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const updateClearFilter = useCallback(() => {
    const calculateFiltersApplied = () => {
      if (!agGridApi.current) {
        return false;
      }
      const api: GridApi = agGridApi.current;
      const filterModel = api.getFilterModel();
      return Object.keys(filterModel).length > 0;
    };

    const calculateColumnsVisible = () => {
      if (!agGridApi.current) {
        return false;
      }
      const api: GridApi = agGridApi.current;
      const columnStates = api.getColumnState();
      return columnStates.some((columnState) => columnState.hide);
    };

    const handleFilterChange = () => {
      setHasFiltersApplied(calculateFiltersApplied());
    };

    const handleColumnVisibilityChange = () => {
      setHasFiltersApplied(calculateColumnsVisible());
    };

    const api: GridApi | undefined = agGridApi.current;

    if (api) {
      api.addEventListener('filterChanged', handleFilterChange);
      api.addEventListener('columnVisible', handleColumnVisibilityChange);
    }

    return () => {
      if (api) {
        api.removeEventListener('filterChanged', handleFilterChange);
        api.removeEventListener('columnVisible', handleColumnVisibilityChange);
      }
    };

    // agGridApi is ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasFiltersApplied, updateClearFilter };
};
