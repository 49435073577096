import { useQuery } from '@apollo/client';
import { QueryGetMySurveyContributorByIdArgs as Variables, Query } from '@rio/rio-types';
import { GET_MY_CONTRIBUTORS_OVERVIEW } from './queries';

export type GetMyContributorOverviewResponse = Pick<Query, 'getMySurveyContributorById'>;

export function useGetMyContributorOverview(
  accountId: string,
  contributorAccountId: string,
  contributorUserId?: string
) {
  return useQuery<GetMyContributorOverviewResponse, Variables>(GET_MY_CONTRIBUTORS_OVERVIEW, {
    variables: {
      accountId,
      contributorAccountId,
      contributorUserId,
    },
    skip: !contributorAccountId,
  });
}
