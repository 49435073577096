import React from 'react';
import { useInitializeExportApi, ExportContext } from '../../hooks';

interface ExportProviderProps {
  children: React.ReactNode;
}

export function ExportProvider({ children }: ExportProviderProps) {
  const exportApi = useInitializeExportApi();
  return <ExportContext.Provider value={exportApi}>{children}</ExportContext.Provider>;
}
