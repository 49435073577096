import { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Col, Button } from 'rio-ui-components';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent, RowModelType } from 'ag-grid-community';
import { DataType } from '@rio/rio-types';
import { usePermissions, useCurrentAccountId } from '../../hooks';
import PageHeader from '../PageHeader';
import { LoadFailed } from '../Errors';
import { ExportButton } from '../ExportButton';
import { mapSortModel, mapFilterModel } from '../../utils';
import { ClearButton } from '../ClearButton';
import { ControlPanel } from '../ControlPanel';
import { Breadcrumb, Nullable } from '../../types';
import { FetchRowsFunction } from './types';
import { PageSizeSelect } from './PageSizeSelect';
import { AgGridReact } from 'ag-grid-react';

const ColStyled = styled(Col)`
  flex: 1;
`;
const RowStyled = styled(Col)`
  flex: 1;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const GridContainer = styled.div`
  flex: 1;
  overflow: auto;
  & .ag-paging-panel {
    justify-content: flex-start;
  }
  /* reduce ag-grid default header line-height and padding */
  & .ag-header-cell {
    line-height: normal;
    padding: 8px;
  }

  /* make header separator height proportional to header height */
  & .ag-header-cell:after,
  & .ag-header-group-cell:after {
    height: 50%;
  }

  /* set icons height to their real absolute value to ensure proper vertical alignment */
  & .ag-header-cell-menu-button .ag-icon-menu,
  & .ag-header-cell-label .ag-header-icon {
    height: 16px;
  }

  /* make header text wrap, without breaking words and without ellipsis */
  & .ag-header-cell-label .ag-header-cell-text {
    height: auto;
    overflow: visible;
    overflow-wrap: normal;
    text-overflow: clip;
    white-space: normal;
  }
`;

const defaultConfig = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: false,
  rowModelType: 'serverSide' as RowModelType,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
  },
};

interface TransactionGridProps {
  config: GridOptions;
  title: string;
  breadcrumbs: Breadcrumb[];
  uploadLink?: string;
  fetchRows: FetchRowsFunction;
  defaultExportFileName?: string;
}

export function TransactionGrid({
  config,
  title,
  breadcrumbs,
  uploadLink,
  fetchRows,
  defaultExportFileName,
}: TransactionGridProps) {
  const accountId = useCurrentAccountId();
  const gridConfig = {
    ...defaultConfig,
    ...config,
  };
  const permissions = usePermissions();
  const [error, setError] = useState<Nullable<Error>>(null);
  const [pageSize, setPageSize] = useState(gridConfig.paginationPageSize);
  const [gridApi, setGridApi] = useState<GridApi | null>();
  const [columnApi, setColumnApi] = useState<ColumnApi | null>();
  const dataSource = useMemo(
    () => ({
      async getRows(params) {
        try {
          const { startRow, sortModel, filterModel } = params.request;
          for (const columnName in filterModel) {
            if (filterModel[columnName].filterType === DataType.Set) {
              const filterValues = filterModel[columnName].values;
              if (filterValues.includes(null)) {
                filterValues.push(' ');
              }
            }
          }
          const { rows, totalRows } = await fetchRows({
            accountId,
            offset: startRow!,
            limit: pageSize,
            sort: mapSortModel(sortModel),
            filters: mapFilterModel(filterModel),
          });
          params.success({
            rowData: rows,
            rowCount: totalRows,
          });
        } catch (err) {
          params.fail();
          setError(err as unknown as Error);
        }
      },
    }),
    [accountId, fetchRows, setError, pageSize]
  );

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setColumnApi(event.columnApi);
    setGridApi(event.api);
  }, []);

  const handlePageSizeChange = useCallback(
    (size) => {
      setPageSize(size);
      if (gridApi) {
        gridApi.setGridOption('paginationPageSize', size);
      }
    },
    [gridApi, setPageSize]
  );

  const handleRetry = useCallback(async () => {
    await fetchRows({
      accountId,
      offset: 0,
      limit: pageSize,
      sort: [],
    });
    setError(null);
  }, [fetchRows, setError, accountId, pageSize]);

  return (
    <ColStyled container item>
      <PageHeader name={title} title={title} breadcrumbs={breadcrumbs} icon="chart-line" iconColor="quaternary">
        <ControlPanel container item distribution="center" vdistribution="center" itemAlign="center">
          <Col span={4}>
            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
          </Col>
          <Col span={4}>
            <ClearButton gridApi={gridApi} columnApi={columnApi} />
          </Col>
          {!!permissions.data.find((action: string) => action.startsWith('exportTransactions')) &&
            !!defaultExportFileName && (
              <Col span={4}>
                <ExportButton
                  fetchRows={fetchRows}
                  gridApi={gridApi!}
                  columnApi={columnApi!}
                  defaultExportFileName={defaultExportFileName}
                  label="Export Data"
                />
              </Col>
            )}
          {!!uploadLink && (
            <Col span={4}>
              <Button component="routerLink" to={uploadLink}>
                Upload Data
              </Button>
            </Col>
          )}
        </ControlPanel>
      </PageHeader>

      {error ? (
        <LoadFailed error={error} name={error.message} retry={handleRetry} />
      ) : (
        <RowStyled container item>
          <GridContainer className="ag-theme-alpine" id="transactions">
            <AgGridReact
              {...gridConfig}
              paginationPageSize={pageSize}
              cacheBlockSize={pageSize}
              serverSideDatasource={dataSource}
              onGridReady={handleGridReady}
            />
          </GridContainer>
        </RowStyled>
      )}
    </ColStyled>
  );
}
