import React, { Fragment } from 'react';
import { Row, Col, Text } from 'rio-ui-components';
import styled from 'styled-components';
import { CarbonConversionFactorRow } from './CarbonConversionFactorRow';
import { getColumnWidth, sorter } from './gridConfig';

const RowStyled = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const RowsContainer = styled.div`
  flex: 1 1 auto;
  overflow: scroll;
`;

export function CarbonConversionFactorsGrid({ factors, config, children = null }) {
  const columns = Object.keys(config).sort((a, b) => config[a].order - config[b].order);
  return (
    <Fragment>
      {children}
      <RowStyled align="between" container>
        {columns.map((column) => (
          <Col item span={getColumnWidth(config, column)} key={column}>
            <Text weight="bold">{config[column].columnName}</Text>
          </Col>
        ))}
      </RowStyled>
      <RowsContainer>
        {factors
          .slice()
          .sort(sorter)
          .map((f) => (
            <CarbonConversionFactorRow factor={f} key={f.id} config={config} />
          ))}
      </RowsContainer>
    </Fragment>
  );
}
