import { useQuery } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Col, Button, Modal, Notification, ErrorMessage, ContainerError } from 'rio-ui-components';
import { useCountries } from '../../../hooks/useCountries';
import { GET_WASTE_CONTRACTORS_PAGED, SET_PAGE, GET_SEARCH, SET_SEARCH } from './index.queries';
import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import ContractorRow from './ContractorRow';
import PaginationContainer from '../../../components/PaginationContainer';
import SearchContainer from '../../../components/SearchContainer';
import CreateContractor from './createContractor';
import UpdateContractor from './updateContractor';
import { usePermissions } from '../../../hooks/usePermissions';

const CreateButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const ConfigurationContractorsContainer = (props) => {
  const [state, setState] = useState({
    createModal: { show: false },
    updateModal: { id: '' },
    selectedTags: [],
    notification: { message: null, color: null },
    account: { id: props.accountId },
    shouldRefetch: false,
    selectAll: false,
    orderByDesc: true,
    pagination: {
      page: 1,
      totalPages: 0
    },
    countryList: [],
    defaultCountry: null
  });

  const { countryList } = useCountries();
  const permissions = usePermissions();

  const {
    data: { wasteContractorsGridSearch }
  } = useQuery(GET_SEARCH);

  const queryVariables = {
    accountId: state.account.id,
    page: state.pagination.page,
    pageSize: 20,
    filterValue: wasteContractorsGridSearch || null,
    orderDir: state.orderByDesc ? 'desc' : 'asc',
    filterBy: 'name'
  };

  const dismissModals = (notification = {}) => {
    setState({
      ...state,
      createModal: { show: false },
      updateModal: { id: null },
      notification: { message: notification.message, color: notification.color, id: notification.id }
    });
  };
  const showModal = (type, id, name, address) => {
    if (type === 'create') return setState({ ...state, createModal: { show: true } });
    if (type === 'update') return setState({ ...state, updateModal: { id, name, address } });
  };

  const hideNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { message: null, color: null, id: null }
    }));
  };

  const onPageChange = (page) => {
    setState({
      ...state,
      pagination: {
        page,
        totalPages: state.pagination.totalPages
      }
    });
  };

  const renderModals = (queryVariables) => (
    <Fragment>
      {state.notification.message && (
        <Notification
          name="ConfigurationContractorsContainer__Notification"
          show
          color="success"
          onClose={hideNotification}
        >
          {state.notification.message}
          <div hidden name="ConfigurationContractorsContainer__Notification__createlocation_id">
            {state.notification.id ? state.notification.id[0] : null}
          </div>
          <div hidden name="ConfigurationContractorsContainer__Notification__createaddress_id">
            {state.notification.id ? state.notification.id[1] : null}
          </div>
        </Notification>
      )}
      {state.createModal.show && (
        <Modal
          span={5}
          size="md"
          show
          name="ConfigurationContractorsContainer__Modal--create"
          dismissable
          onDismiss={dismissModals}
        >
          <CreateContractor
            onSuccess={(message, color, id) => dismissModals({ message, color, id })}
            accountId={state.account.id}
            countryList={countryList}
            refetchQuery={[{ query: GET_WASTE_CONTRACTORS_PAGED, variables: queryVariables }]}
          />
        </Modal>
      )}

      {state.updateModal.id && (
        <Modal
          size="md"
          show
          dismissable
          onDismiss={dismissModals}
          name="ConfigurationContractorsContainer__Modal--update"
        >
          <UpdateContractor
            contractorId={state.updateModal.id}
            contractorName={state.updateModal.name}
            contractorAddressId={state.updateModal.address && state.updateModal.address.id}
            address1={state.updateModal.address && state.updateModal.address.address1}
            address2={state.updateModal.address && state.updateModal.address.address2}
            address3={state.updateModal.address && state.updateModal.address.address3}
            city={state.updateModal.address && state.updateModal.address.city}
            postcode={state.updateModal.address && state.updateModal.address.postcode}
            phone={state.updateModal.address && state.updateModal.address.phone1}
            onDismiss={dismissModals}
            onSuccess={(message, color, id) => dismissModals({ message, color, id })}
            countryList={countryList}
            defaultCountry={
              state.updateModal.address && {
                value: state.updateModal.address.country.id,
                label: state.updateModal.address.country.name
              }
            }
          />
        </Modal>
      )}
    </Fragment>
  );

  const onGetCompleted = (data) => {
    const pageChange = state.pagination.page !== data.getWasteContractorsPaged.page;
    const pageCountChange = state.pagination.totalPages !== data.getWasteContractorsPaged.pageCount;
    if (pageChange || pageCountChange) {
      setState({
        ...state,
        pagination: {
          page: data.getWasteContractorsPaged.page,
          totalPages: data.getWasteContractorsPaged.pageCount
        }
      });
    }
  };

  const getContractorRows = (contractorData) => {
    if (contractorData.wasteContractors.length === 0)
      return <NoResults name="ConfigurationContractorsContainer__NoResults" title="No Contractors found" />;

    return contractorData.wasteContractors.map((contractor) => {
      return (
        <ContractorRow
          key={contractor.id}
          contractorId={contractor.id}
          name={contractor.name}
          address={contractor.address}
          hasTransactions={contractor.hasTransactions}
          phoneNumber={contractor.address ? contractor.address.phone1 : null}
          onEdit={() => showModal('update', contractor.id, contractor.name, contractor.address)}
        />
      );
    });
  };

  const getWasteContractors = ({ data, error, loading, refetch }) => {
    if (error)
      return (
        <ErrorMessage error={error}>
          {({ title, body, icon }) => (
            <ContainerError
              name="ConfigurationContractorsContainer__Error"
              icon={icon}
              title={title}
              body={body}
              retry={() => refetch()}
            />
          )}
        </ErrorMessage>
      );

    return loading ? (
      <ContainerLoadingIndicator name="ConfigurationContractorsContainer__Loading" />
    ) : (
      getContractorRows(data.getWasteContractorsPaged)
    );
  };

  const { data, error, loading, refetch } = useQuery(GET_WASTE_CONTRACTORS_PAGED, {
    variables: queryVariables,
    onCompleted: (data) => onGetCompleted(data)
  });

  const wasteContractors = getWasteContractors({ data, error, loading, refetch });

  return (
    <Col name="ConfigurationContractorsContainer" container fullHeight>
      <ContainerHeader
        name="ConfigurationContractorsContainer__Controls"
        icon="business-time"
        iconColor="primary"
        title="Contractors"
      >
        <Col container item>
          <SearchContainer setPageMutation={SET_PAGE} setSearchMutation={SET_SEARCH} hideButton={true} />
        </Col>
        {permissions.data.includes('createWasteContractorAll') && (
          <CreateButtonStyled
            name="ConfigurationContractorsContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={() => showModal('create')}
          >
            + Create Contractor
          </CreateButtonStyled>
        )}
      </ContainerHeader>
      {renderModals(queryVariables)}
      {wasteContractors}
      <PaginationContainer
        totalPagesCount={state.pagination.totalPages}
        currentPage={state.pagination.page}
        onChange={(data) => onPageChange(data)}
      />
    </Col>
  );
};
export default ConfigurationContractorsContainer;
