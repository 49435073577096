import { styled, Grid, TextField } from '@rio/ui-components';

type SectionStyledProps = {
  isVisible: boolean;
};

export const GridStyled = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const ColumnStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SectionStyled = styled('div')<SectionStyledProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const TextFieldStyled = styled(TextField)`
  width: 100%;
`;
