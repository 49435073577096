import { User } from '@rio/rio-types';
import capitalize from 'lodash/capitalize';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { compareDate, defaultFilterParams, getAgRowId } from '~/utils';
import { getSubjectItemName, formatScope, priorityValueFormatter, formatStatus } from '../../utils';

const textCellStyle = {
  lineHeight: '21px',
  wordBreak: 'inherit',
  display: 'inline-block',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

type FormatMessageFunction = (props: { id: string }) => string;

export const options = (formatMessage: FormatMessageFunction): GridOptions => ({
  rowHeight: 56,
  getRowId: getAgRowId('id'),
  columnDefs: [
    {
      headerName: 'Priority',
      field: 'priority',
      cellRenderer: 'priority',
      minWidth: 100,
      cellStyle: { verticalAlign: 'middle' },
      filterParams: {
        ...defaultFilterParams(),
        valueFormatter: priorityValueFormatter,
      },
    },

    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'status',
      valueGetter: ({ data }) => formatStatus(data),
      minWidth: 50,
      cellStyle: { verticalAlign: 'middle' },
      filterParams: {
        ...defaultFilterParams(),
      },
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.task' }),
      field: 'name',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 90,
      cellStyle: textCellStyle,
    },

    {
      headerName: 'Description',
      field: 'description',
      sortable: false,
      filter: false,
      cellStyle: {},
    },

    {
      headerName: 'Scope',
      field: 'scope',
      filterParams: {
        ...defaultFilterParams(),
      },
      valueFormatter: ({ data }) => formatScope(data),
      minWidth: 130,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.category' }),
      field: 'category',
      cellRenderer: 'category',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 150,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.subject' }),
      valueGetter: ({ data }) => capitalize(data.subject),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 110,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.item' }),
      valueGetter: ({ data }) => getSubjectItemName(data),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
      cellStyle: textCellStyle,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.owners' }),
      field: 'owners',
      cellRenderer: 'owners',
      valueGetter: ({ data: { owners = [] } }) =>
        owners.map(({ first_name, last_name }: User) => `${first_name} ${last_name}`),
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 90,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.creator' }),
      field: 'author',
      cellRenderer: 'creator',
      valueGetter: ({ data: { author } }: ValueGetterParams) => `${author.first_name} ${author.last_name}`,
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.date' }),
      field: 'dueDate',
      cellRenderer: 'dueDate',
      filter: 'agDateColumnFilter',
      sort: 'asc',
      filterParams: {
        ...defaultFilterParams(),
        comparator: compareDate,
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.completedDate' }),
      field: 'completedAt',
      cellRenderer: 'completedAt',
      filter: 'agDateColumnFilter',
      sort: 'asc',
      filterParams: {
        ...defaultFilterParams(),
        comparator: compareDate,
      },
      minWidth: 120,
    },

    {
      headerName: formatMessage({ id: 'pages.task.grid.header.project' }),
      valueGetter: ({ data: { projectId } }: ValueGetterParams) => projectId?.name || '',
      filterParams: {
        ...defaultFilterParams(),
      },
      minWidth: 90,
      cellStyle: textCellStyle,
    },
  ],
});

export const defaultConfig: GridOptions = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: [10, 25, 50, 100],
  cacheBlockSize: 25,
  suppressNoRowsOverlay: true,
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
    filterParams: {
      showTooltip: true,
    },
    cellStyle: {
      height: 'auto',
    },
  },
  rowStyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

export const defaultSortModel = [
  { colId: 'dueDate', sort: 'asc', sortIndex: 0 },
  { colId: 'name', sort: 'asc', sortIndex: 1 },
];

export const segmentedButtonValues = [{ offValue: 'Show My Tasks', onValue: 'Show All Tasks' }];
