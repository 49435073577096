import { useApolloClient } from '@apollo/client';
import { FilterData, Filters } from '@rio/rio-types';
import { SetFilterValuesFuncParams, RowModelType } from 'ag-grid-community';
import { Grid as AgGrid } from '../../../components/UI';
import { useAgGrid } from '../../../hooks';
import { mapSortModel, mapFilterModel, gridValueFormatter } from '../../../utils';
import { ActionCell, ActionCellProps, ActionFunction } from '../ConfigurationDataTrackerContainer/GridCell/ActionCell';
import { FetchRowsFunction, GridApiType } from './types';
import { useNotification, useCurrentAccountId } from '../../../hooks';
import { GET_DATA_TRACKER_ANOMALY_FILTERS } from './index.queries';
import { MODALS } from '../ConfigurationAccountsContainer';

interface GridProps {
  onEdit: ActionFunction;
  onDelete: ActionFunction;
  fetchRows: FetchRowsFunction;
  setGridApi: React.Dispatch<React.SetStateAction<GridApiType>>;
  isSystemAdmin: boolean;
}

export function Grid({ setGridApi, fetchRows, onEdit, onDelete, isSystemAdmin }: GridProps) {
  const accountId = useCurrentAccountId();
  const client = useApolloClient();
  const agGrid = useAgGrid({ autoFit: true });
  const { showNotification } = useNotification();

  const getFilterValues = async (params: SetFilterValuesFuncParams) => {
    try {
      const {
        data: { getDataTrackerAnomalyFilters: values },
      } = await client.query({
        query: GET_DATA_TRACKER_ANOMALY_FILTERS,
        variables: {
          accountId: isSystemAdmin ? null : accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });

      params.success(values.map((item: FilterData) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  const options = {
    pagination: true,
    paginationPageSize: 25,
    rowModelType: 'serverSide' as RowModelType,
    defaultColDef: {
      resizable: false,
      sortable: true,
      filter: true,
      cellStyle: {
        padding: '10px',
        height: 'auto',
        wordBreak: 'break-word',
        lineHeight: '20px',
      },
      autoHeight: true,
    },
    columnDefs: [
      {
        headerName: 'Account',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          newRowsAction: 'keep',
        },
        field: 'account.name',
        hide: !isSystemAdmin,
        flex: 1,
      },
      {
        headerName: 'Threshold Percentage',
        filter: 'agNumberColumnFilter',
        field: 'thresholdPercentage',
        valueFormatter: (params) => `${params.value}%`,
        flex: 1,
      },
      {
        headerName: 'Utility Type',
        filter: 'agSetColumnFilter',
        field: 'transactionType',
        valueGetter: (params) => {
          const { transactionType, isDefault } = params.data.utilityType;
          return isDefault ? 'DEFAULT' : transactionType;
        },
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        flex: 1,
      },
      {
        cellRenderer: 'action',
        filter: false,
        sortable: false,
      },
    ],
    rowStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rowHeight: 58,
  };

  const components = {
    action: (props: ActionCellProps) => (
      <ActionCell
        {...props}
        onEdit={() => onEdit(MODALS.UPDATE, props.data.id)}
        onDelete={() => onDelete(MODALS.DELETE, props.data.id)}
      />
    ),
  };

  return (
    <AgGrid
      {...options}
      cacheBlockSize={options.paginationPageSize}
      onFirstDataRendered={agGrid.onFirstDataRendered}
      onFilterChanged={agGrid.onFilterChanged}
      onSortChanged={agGrid.onSortChanged}
      onColumnVisible={agGrid.onSaveGridColumnState}
      onColumnPinned={agGrid.onSaveGridColumnState}
      onColumnResized={agGrid.onSaveGridColumnState}
      components={components}
      onGridReady={(params) => {
        agGrid.onGridReady(params);
        setGridApi({ api: params.api, columnApi: params.columnApi });
        params.api.setServerSideDatasource({
          async getRows(rowParams) {
            try {
              const { startRow, sortModel, filterModel } = rowParams.request;
              const filters: Filters = mapFilterModel(filterModel);

              const { rows, totalRows } = await fetchRows({
                offset: startRow || 0,
                limit: options.paginationPageSize,
                sort: mapSortModel(sortModel),
                filters,
              });
              rowParams.success({
                rowData: rows,
                rowCount: totalRows,
              });

              return { rows, totalRows };
            } catch (err) {
              rowParams.fail();
              throw err;
            }
          },
        });
      }}
    />
  );
}
