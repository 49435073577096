import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Modal, TextLink, Heading, PropertySheet } from 'rio-ui-components';
import { QuestionSequence } from './QuestionSequence';
import {
  QuestionSequenceRepeatable as IQuestionSequenceRepeatable,
  QuestionUnion,
  SequenceRepeatableQuestionAnswer,
  SequenceRepeatableQuestionRow,
  ManagementSystem
} from '@rio/rio-types';
import { getAnswerKeysAndValue, mapSequenceRepeatableRowToSequence } from './utils';
import { Nullable } from '../../types';

const Container = styled(PropertySheet)`
  padding: ${(p) => p.theme.geometry.sm.spacing};
`;
const HeadingStyled = styled(Heading)`
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;
const Ul = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
`;

interface QuestionSequenceRepeatableProps {
  question: IQuestionSequenceRepeatable;
  onAnswer: (updatedQuestion: IQuestionSequenceRepeatable) => void;
  framework: ManagementSystem;
}

export function QuestionSequenceRepeatable({ framework, question, onAnswer }: QuestionSequenceRepeatableProps) {
  const [addRow, setAddRow] = useState(false);

  const [newRow, setNewRow] = useState(question.questions);

  const [editRow, setEditRow] = useState<Nullable<SequenceRepeatableQuestionRow>>(null);

  const handleAddRowClick = useCallback(() => {
    setAddRow(true);
  }, []);

  const handleAnswer = useCallback(
    (updatedQuestion: QuestionUnion) => {
      if (addRow) {
        setNewRow((previousQuestions: QuestionUnion[]) =>
          previousQuestions.map((q: QuestionUnion) => (q.id === updatedQuestion.id ? updatedQuestion : q))
        );
      }
      if (editRow) {
        setEditRow((row: Nullable<SequenceRepeatableQuestionRow>) => {
          if (row) {
            const updatedRow = {
              ...row,
              answers: row.answers.map((answer: SequenceRepeatableQuestionAnswer) =>
                answer.questionId === updatedQuestion.id
                  ? { ...answer, answer: JSON.stringify(getAnswerKeysAndValue(updatedQuestion)[2]) }
                  : answer
              )
            };
            onAnswer({
              ...question,
              reportedRows: question.reportedRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
            });
            return updatedRow;
          }
          return row;
        });
      }
    },
    [setNewRow, editRow, addRow, question, onAnswer]
  );

  const handleDismiss = useCallback(() => {
    if (addRow) {
      setAddRow(false);
      setNewRow(question.questions);
    }
    if (editRow) {
      setEditRow(null);
    }
  }, [addRow, setNewRow, question, editRow]);

  const handleSave = useCallback(() => {
    const alreadyReportedRows = question.reportedRows || [];
    onAnswer({
      ...question,
      reportedRows: alreadyReportedRows.concat({
        id: uuid(),
        answers: newRow.map((q) => ({
          questionId: q.id,
          answer: JSON.stringify(getAnswerKeysAndValue(q)[2])
        }))
      })
    });
    handleDismiss();
  }, [onAnswer, question, newRow, handleDismiss]);

  const handleDelete = useCallback(() => {
    if (!editRow) {
      return;
    }
    const alreadyReportedRows = question.reportedRows || [];
    onAnswer({
      ...question,
      reportedRows: alreadyReportedRows.filter((row) => row.id !== editRow.id)
    });
    handleDismiss();
  }, [editRow, onAnswer, question, handleDismiss]);

  return (
    <div>
      <TextLink onClick={handleAddRowClick}>Add new row</TextLink>
      {!!question.reportedRows?.length && (
        <Ul>
          {question.reportedRows.map((row: SequenceRepeatableQuestionRow, i: number) => (
            <li onClick={() => setEditRow(row)} key={row.id}>
              <TextLink inline>Row {i + 1}</TextLink>
            </li>
          ))}
        </Ul>
      )}
      <Modal size="lg" onDismiss={handleDismiss} show={addRow || editRow}>
        <Container container item>
          <HeadingStyled align="center" item>
            {question.formulation}
          </HeadingStyled>
          <QuestionSequence
            question={question}
            framework={framework}
            questions={addRow ? newRow : mapSequenceRepeatableRowToSequence(editRow, question.questions)}
            onAnswer={handleAnswer}
          />
          <PropertySheet.Row>
            {addRow ? (
              <PropertySheet.ButtonColumn type="button" color="success" onClick={handleSave}>
                Save
              </PropertySheet.ButtonColumn>
            ) : (
              <PropertySheet.ButtonColumn type="button" color="danger" onClick={handleDelete}>
                Delete
              </PropertySheet.ButtonColumn>
            )}
          </PropertySheet.Row>
        </Container>
      </Modal>
    </div>
  );
}
