import React from 'react';
import { Row, Col, Heading, ProfilePicture, Icon } from 'rio-ui-components';
import styled from 'styled-components';

const TemplateRowStyled = styled(Row)`
  padding: ${(props) => `${props.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
  flex: 0 0 auto;
`;

const TemplateProfilePictureStyled = styled(ProfilePicture)`
  margin-right: ${(props) => props.theme.geometry.md.spacing};
`;

const EditLinkStyled = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.text.normal.background};
  padding: 0 ${(props) => props.theme.geometry.xxl.spacing} 0 0;

  &:hover {
    color: ${(props) => props.theme.colors.text.dark.background};
    cursor: pointer;
  }
`;

const TemplateRow = ({ template, onEdit }) => {
  return (
    <TemplateRowStyled name={`template-row--${template.id}`} container itemAlign="center">
      <TemplateProfilePictureStyled name={`template-row__profile-picture--${template.id}`} size="xs" accountName={template.name} />

      <Col container item>
        <Heading inline name={`template-row__name`} size="md">
          {template.name}
        </Heading>
      </Col>

      <EditLinkStyled onClick={onEdit}>
        <Icon name={`template-row__edit`} icon="pencil-alt" />
      </EditLinkStyled>
    </TemplateRowStyled>
  );
};

export default TemplateRow;
