import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { AliasType } from '@rio/rio-types';
import _ from 'lodash';
import {
  GET_ALIAS_BY_ID,
  GET_TREATMENT_PROCESSES,
  GET_CONTAINER_TYPES_BY_ACCOUNT_ID,
  UPDATE_ALIAS_DATA,
  DELETE_ALIAS_BY_ID_AND_TYPE,
  GET_LOCATIONS_BY_ACCOUNT,
  GET_ALL_FUEL_TYPES,
  GET_ALL_TRANSPORT_FUEL_TYPES,
  GET_ALL_TRANSPORT_SUB_TYPES,
  GET_ALL_JOURNEY_TARGETS,
  GET_ALL_TRANSPORT_TYPES,
  GET_ALL_UNIT_TYPES,
  GET_ALL_ACCURACY_TYPES,
  GET_ALL_CURRENCY_TYPES,
} from './index.queries';
import GET_WASTE_CONTRACTORS from '../../../graphql/queries/waste/GetWasteContrators.query.graphql';
import GET_WASTE_OUTS_BY_ACCOUNT_ID from '../../../graphql/queries/waste/GetWasteOutsByAccountId.query.graphql';
import GET_WASTE_INS_BY_ACCOUNT_ID from '../../../graphql/queries/waste/GetWasteInsByAccountId.query.graphql';
import GET_WASTE_STREAMS_BY_ACCOUNT_ID from '../../../graphql/queries/waste/GetWasteStreamsByAccountId.query.graphql';
import { GET_ENERGY_SOURCE_BY_ACC_ID_AND_TYPE } from './../../DataContainer/AliasContainer/index.queries';
import { GET_MARKET_CARBON_CONVERSION_FACTORS } from '../ConfigurationMarketCarbonConversionFactors/index.queries';
import GET_CARRIERS_BY_ACCOUNT_ID from '../../../graphql/queries/waste/GetWasteCarriers.query.graphql';
import { Col, Row, Label, TextInput, Heading, LoadingIndicator, Select, Button } from 'rio-ui-components';
import { LocationSelect, WasteOutLocationSelect, WasteInLocationSelect } from '../../../components/LocationSelect';
import { useNotification, usePermissions, useCurrentAccountId } from '../../../hooks';

const ContainerStyled = styled.div`
  margin: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  flex-direction: column;
`;

const LoadingContainerStyled = styled.div`
  margin: ${(props) => props.theme.geometry.sm.spacing};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const defaultMappingFunction = ({ id, name }) => ({
  value: id,
  label: name,
});
const queries = {
  WASTESTREAM: {
    query: GET_WASTE_STREAMS_BY_ACCOUNT_ID,
    accessor: 'getWasteStreamsByAccountId.wasteStreams',
    mapFunction: defaultMappingFunction,
  },
  WASTEIN: {
    query: GET_WASTE_INS_BY_ACCOUNT_ID,
    accessor: 'getWasteInsByAccountId',
    mapFunction: defaultMappingFunction,
  },
  WASTEOUT: {
    query: GET_WASTE_OUTS_BY_ACCOUNT_ID,
    accessor: 'getWasteOutsByAccountId',
    mapFunction: defaultMappingFunction,
  },
  WASTETREATMENTPROCESS: {
    query: GET_TREATMENT_PROCESSES,
    accessor: 'getAllWasteTreatmentProcesses',
    mapFunction: defaultMappingFunction,
  },
  WASTECONTRACTOR: {
    query: GET_WASTE_CONTRACTORS,
    accessor: 'getAllWasteContractors',
    mapFunction: defaultMappingFunction,
  },
  WASTECARRIER: {
    query: GET_CARRIERS_BY_ACCOUNT_ID,
    accessor: 'getSuppliers.suppliers',
    mapFunction: defaultMappingFunction,
  },
  CONTAINERTYPESIZE: {
    query: GET_CONTAINER_TYPES_BY_ACCOUNT_ID,
    accessor: 'getContainerTypeSizePage.typeSizes',
    mapFunction: defaultMappingFunction,
  },
  METER: {
    ELECTRICITY: {
      query: GET_ENERGY_SOURCE_BY_ACC_ID_AND_TYPE,
      accessor: 'getEnergySourceByAccountIdAndType.rows',
      mapFunction: defaultMappingFunction,
    },
    GAS: {
      query: GET_ENERGY_SOURCE_BY_ACC_ID_AND_TYPE,
      accessor: 'getEnergySourceByAccountIdAndType.rows',
      mapFunction: defaultMappingFunction,
    },
    WATER: {
      query: GET_ENERGY_SOURCE_BY_ACC_ID_AND_TYPE,
      accessor: 'getEnergySourceByAccountIdAndType.rows',
      mapFunction: defaultMappingFunction,
    },
  },
  LOCATIONPOINT: {
    query: GET_LOCATIONS_BY_ACCOUNT,
    accessor: 'getLocationsPage.rows',
    mapFunction: defaultMappingFunction,
  },
  TRANSPORT: {
    query: GET_ALL_TRANSPORT_TYPES,
    accessor: 'getAllTransportTypes',
    mapFunction: defaultMappingFunction,
  },
  FUELTYPE: {
    query: GET_ALL_FUEL_TYPES,
    accessor: 'getAllFuelTypes',
    mapFunction: defaultMappingFunction,
  },
  TRANSPORTFUELTYPE: {
    query: GET_ALL_TRANSPORT_FUEL_TYPES,
    accessor: 'getAllTransportFuelTypes',
    mapFunction: defaultMappingFunction,
  },
  TRANSPORTSUBTYPE: {
    query: GET_ALL_TRANSPORT_SUB_TYPES,
    accessor: 'getAllTransportSubTypes',
    mapFunction: defaultMappingFunction,
  },
  JOURNEYTARGET: {
    query: GET_ALL_JOURNEY_TARGETS,
    accessor: 'getAllJourneyTargets',
    mapFunction: defaultMappingFunction,
  },
  UNIT: {
    query: GET_ALL_UNIT_TYPES,
    accessor: 'getAllUnits',
    mapFunction: defaultMappingFunction,
  },
  ACCURACY: {
    query: GET_ALL_ACCURACY_TYPES,
    accessor: 'getAllAccuracyTypes',
    mapFunction: defaultMappingFunction,
  },
  CURRENCY: {
    query: GET_ALL_CURRENCY_TYPES,
    accessor: 'getAllCurrencyTypes',
    mapFunction: defaultMappingFunction,
  },
  [AliasType.Tariff]: {
    query: GET_MARKET_CARBON_CONVERSION_FACTORS,
    accessor: 'getMarketCarbonConversionFactors',
    mapFunction: (factor) => ({
      label: factor.tariff,
      value: factor.id,
    }),
  },
};

const applyFilter = (values, filter) => {
  if (!filter) {
    return values;
  }

  const adjustedFilter = filter.trim().toLowerCase();
  return values.filter((value) => value.label.trim().toLowerCase().includes(adjustedFilter));
};

const UpdateAlias = ({ aliasData, onComplete }) => {
  const [aliasName, setAliasName] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();
  const { showNotification } = useNotification();
  const onUpdateError = useCallback(() => {
    showNotification('Something went wrong! If the problem persists, please contact support.', 'danger');
  }, [showNotification]);

  const onUpdateComplete = useCallback(() => {
    setAliasName('');
    setSelectedOption(null);
    setIsSubmitting(false);
    showNotification('Success! Your alias has been updated.', 'success');
    onComplete();
  }, [setAliasName, setSelectedOption, setIsSubmitting, showNotification, onComplete]);

  const [updateMutation] = useMutation(UPDATE_ALIAS_DATA, {
    onCompleted: (data) => onUpdateComplete(data),
    onError: () => onUpdateError(),
    variables: {
      id: aliasData.aliasId,
      name: aliasName,
      parentId: selectedOption?.value,
      accountId: aliasData.accountId,
      aliasType: aliasData.aliasType,
      energyType: aliasData?.energyType,
    },
    refetchQueries: ['GetAliasesPage'],
  });

  const { data: fetchedAliasData, loading: aliasLoading } = useQuery(GET_ALIAS_BY_ID, {
    notifyOnNetworkStatusChange: true,
    variables: {
      aliasId: aliasData.aliasId,
      accountId: aliasData.accountId,
      aliasType: aliasData.aliasType,
      energyType: aliasData?.energyType,
    },
  });

  if (fetchedAliasData && !aliasName) {
    setAliasName(fetchedAliasData.getAliasByIdAndType.name);
    setSelectedOption({
      label: fetchedAliasData.getAliasByIdAndType.parentItem.name,
      value: fetchedAliasData.getAliasByIdAndType.parentItem.id,
    });
  }

  const [deleteMutation] = useMutation(DELETE_ALIAS_BY_ID_AND_TYPE, {
    variables: {
      aliasId: aliasData.aliasId,
      accountId: aliasData.accountId,
      aliasType: aliasData.aliasType,
    },
    refetchQueries: ['GetAliasesPage'],
  });
  const queryConfig =
    aliasData.aliasType === AliasType.Meter
      ? queries[aliasData.aliasType][aliasData.energyType]
      : queries[aliasData.aliasType];

  const mapOptions = (data) => _.get(data, queryConfig.accessor).map(queryConfig.mapFunction);

  const canDelete = !!permissions.data.find((a) => a.startsWith('deleteAlias'));

  const loadOptions = async (filterValue) => {
    try {
      const response = await client.query({
        query: queryConfig.query,
        variables: {
          filterValue,
          accountId: aliasData.accountId,
          type: aliasData.energyType,
        },
      });

      const data = response.data ? mapOptions(response.data) : [];
      return { options: applyFilter(data, filterValue) };
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ContainerStyled>
      {aliasLoading && (
        <LoadingContainerStyled>
          <LoadingIndicator size="md" />
        </LoadingContainerStyled>
      )}
      {fetchedAliasData && (
        <>
          <HeaderStyled size="lg">Update Alias</HeaderStyled>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Name</LabelStyled>
              </LabelContainerStyled>
              <TextInput name="aliasedName" onChange={(e) => setAliasName(e.target.value)} value={aliasName} box />
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <LabelContainerStyled>
                <LabelStyled>Aliased Name</LabelStyled>
              </LabelContainerStyled>
              {aliasData.aliasType === AliasType.Locationpoint && (
                <LocationSelect
                  accountId={accountId}
                  onChange={(e) => setSelectedOption({ label: e.target.select.label, value: e.target.select.value })}
                  value={selectedOption}
                />
              )}
              {aliasData.aliasType === AliasType.Wastein && (
                <WasteInLocationSelect
                  accountId={accountId}
                  onChange={(e) => setSelectedOption({ label: e.target.select.label, value: e.target.select.value })}
                  value={selectedOption}
                />
              )}
              {aliasData.aliasType === AliasType.Wasteout && (
                <WasteOutLocationSelect
                  accountId={accountId}
                  onChange={(e) => setSelectedOption({ label: e.target.select.label, value: e.target.select.value })}
                  value={selectedOption}
                />
              )}
              {![AliasType.Locationpoint, AliasType.Wastein, AliasType.Wasteout].includes(aliasData.aliasType) && (
                <Select
                  name="item-to-alias"
                  onChange={(e) => {
                    setSelectedOption({ label: e.target.select.label, value: e.target.select.value });
                  }}
                  value={selectedOption}
                  loadOptions={loadOptions}
                  debounceTimeout={1000}
                  defaultOptions
                />
              )}
            </ColStyled>
          </Row>
          <Row container align="between">
            <ColStyled item>
              <Button
                name="update-alias__button"
                color="success"
                submit
                onClick={() => {
                  setIsSubmitting(true);
                  updateMutation();
                }}
                disabled={!selectedOption || !aliasName || isSubmitting}
              >
                Update Alias
              </Button>
            </ColStyled>
            {canDelete && (
              <ColStyled item>
                <Button
                  name="delete-alias__button"
                  color="danger"
                  submit
                  onClick={async () => {
                    const agree = window.confirm('Are you sure you want to delete this alias?');
                    if (!agree) {
                      return;
                    }
                    try {
                      setIsSubmitting(true);
                      await deleteMutation();
                      showNotification('Alias has been deleted!', 'success');
                      setIsSubmitting(false);
                      onComplete();
                    } catch (err) {
                      showNotification(`${err.message}`, 'danger');
                    }
                  }}
                  disabled={!selectedOption || !aliasName || isSubmitting}
                >
                  Delete Alias
                </Button>
              </ColStyled>
            )}
          </Row>
        </>
      )}
    </ContainerStyled>
  );
};

export { UpdateAlias };
