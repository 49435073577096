import { styled } from '@mui/material-next/styles';
import { Page, TextField } from '@rio/ui-components';
import { useCallback } from 'react';
import { useQueryParam } from 'use-query-params';
import { useGetAvailableCourses } from './useGetAvailableCourses';
import { useCoursesSections } from './useCoursesSections';
import { useSearchCourses } from './useSearchCourses';
import { CoursesSection } from '../CoursesSection';
import CourseBanner from '../../Courses/CourseBanner';

const breadcrumbs = [
  {
    label: 'Learn',
    to: '/v2/engage',
  },
  {
    label: 'Overview',
    to: '/v2/engage',
  },
];
const TextFieldStyled = styled(TextField)`
  margin-bottom: 32px;
`;

const PageContent = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`;

const CourseBannerContainer = styled('div')`
  margin-bottom: 32px;
`;

export function CoursesOverview() {
  const [search, setSearch] = useQueryParam<string>('search');

  const handleSearchChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const { data, loading } = useGetAvailableCourses();

  const { data: searchData, loading: searchLoading } = useSearchCourses(search);

  const coursesToDisplay = search ? searchData?.searchCourses?.data : data?.getAvailableCourses?.data;

  const bundlesToDisplay = search ? searchData?.getLearnBundlesForAccount : data?.getAvailableCourses.learnBundleList;

  const coursesSection = useCoursesSections(coursesToDisplay, bundlesToDisplay);

  return (
    <Page title={{ crumbs: breadcrumbs, content: 'Learn about sustainability' }} loading={loading || searchLoading}>
      <PageContent>
        <CourseBannerContainer>
          <CourseBanner />
        </CourseBannerContainer>
        <TextFieldStyled
          name="ConfigurationAccountsContainer__Controls__Search"
          type="search"
          value={search}
          onChange={handleSearchChange}
          error={false}
          placeholder="Search"
        />

        {coursesSection.map((section) => (
          <CoursesSection
            key={section.title}
            title={section.title}
            description={section.description}
            courses={section.courses}
            bundleId={section.bundleId}
          />
        ))}
      </PageContent>
    </Page>
  );
}
