import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UploadFiles, InputFile, Text, Select, Row, Col, Heading, LoadingIndicator } from 'rio-ui-components';
import { UPLOAD_OCR_DOCUMENT, GET_ALL_TEMPLATES } from '../index.queries';
import { CREATE_DOCUMENT_RECORD } from '../../DocumentContainer/index.queries';
import UPLOAD_DOCUMENT from '../../../graphql/mutations/UploadDocument.graphql';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { useUserToken } from '../../../hooks/useUserToken';
import { createS3Key } from '../../DocumentContainer/utils';
import { v4 as uuid } from 'uuid';

const SelectFilesContainer = styled(Col)`
  display: flex;
  height: 100%;
  width: 100%;
`;

const UploadFilesStyled = styled(UploadFiles)`
  min-height: 40vh;
  display: flex;
  flex: 1;
  & > div {
    width: 100%;
  }
`;

const PaddedRow = styled(Row)`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height 100%;
  align-items: center;
  justify-content: center;
`;

export function OcrUpload({ onComplete, dataSection }) {
  const accountId = useCurrentAccountId();
  const { token } = useUserToken();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [mutationTriggered, setMutationTriggered] = useState(false);
  const { data: templateData, loading } = useQuery(GET_ALL_TEMPLATES);

  const [uploadOcr, { error: ocrError, loading: ocrLoading }] = useMutation(UPLOAD_OCR_DOCUMENT);
  const [uploadDoc, { error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_DOCUMENT);
  const [createDocRecord, { error: createError, loading: createLoading }] = useMutation(CREATE_DOCUMENT_RECORD);

  const fileToBase64 = async (files, callback) => {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileInfo = {
        name: file.name,
        type: file.type,
        base64: reader.result.split(',')[1],
        file: file
      };

      callback(fileInfo);
    };
  };

  const startUpload = (fileInfo) => {
    setMutationTriggered(true);
    const id = uuid();

    uploadDoc({
      variables: {
        id,
        accountId,
        fileName: fileInfo.name,
        file: fileInfo.file,
        key: createS3Key(accountId, id)
      }
    });

    uploadOcr({
      variables: {
        id,
        accountId,
        parserId: selectedTemplate,
        fileName: fileInfo.name,
        fileContent: fileInfo.base64,
        utility: dataSection
      }
    });

    createDocRecord({
      variables: {
        id,
        userId: token.sub,
        accountId,
        fileName: fileInfo.name,
        key: createS3Key(accountId, id),
        category: 'Bill',
        source: 'S3',
        library: dataSection,
        referenceId: fileInfo.name.split('.')[0]
      }
    });
  };

  const templates = templateData && templateData.getAllTemplates ? templateData.getAllTemplates : [];

  const mutationLoading = ocrLoading || uploadLoading || createLoading;
  const noErrors = !ocrError && !uploadError && !createError;

  useEffect(() => {
    if (mutationTriggered) {
      if (!mutationLoading && noErrors) {
        onComplete('Your document is now being processed with OCR.', 'success');
      }
    }
  }, [mutationLoading, noErrors, onComplete, mutationTriggered]);

  return (
    <SelectFilesContainer>
      <PaddedRow>
        <Heading align="center">OCR Document Upload</Heading>
      </PaddedRow>
      {(loading || mutationLoading) && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
      {templateData && !loading && !mutationLoading && (
        <>
          <PaddedRow>
            <Text>Select Template</Text>
            <Select
              options={templates.map((template) => {
                return { label: template.name, value: template.parserId };
              })}
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
            />
          </PaddedRow>
          {selectedTemplate && (
            <PaddedRow>
              <InputFile
                onChange={(files) => {
                  fileToBase64([...files], startUpload);
                }}
              >
                {(selectFiles) => (
                  <UploadFilesStyled
                    buttonText="Select files"
                    onClick={selectFiles}
                    onDrop={(files) => {
                      fileToBase64([...files], startUpload);
                    }}
                    background
                  />
                )}
              </InputFile>
            </PaddedRow>
          )}
        </>
      )}
    </SelectFilesContainer>
  );
}
