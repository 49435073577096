import { DromoRecord } from '../types';
import { fieldIsPresent, resetErrors, setError } from './utils';

export function validateEitherFieldsIncluded(fields: string[], records: DromoRecord[]) {
  return records.map((record) => {
    const newRecord = { ...record };

    // checks if at least 1 of specified fields has truthy result value
    const hasResultValue = fields.some((field) => fieldIsPresent(record.row[field].resultValue));

    if (!hasResultValue) {
      fields.forEach((field) => {
        setError(
          {
            message: `At least one of ${fields.join(', ')} should be present.`,
            level: 'error',
          },
          newRecord,
          [field]
        );
      });
    } else {
      resetErrors(record, fields);
    }

    return newRecord;
  });
}

export function validateCombinedMandatoryFields(fieldSets: string[][], records: DromoRecord[]) {
  return records.map((record) => {
    const newRecord = { ...record };

    fieldSets.forEach(([key, ...peers]) => {
      // checks if key has truthy result value
      const hasKey = fieldIsPresent(record.row[key].resultValue);

      if (hasKey) {
        peers.forEach((field) => {
          const hasPeer = fieldIsPresent(record.row[field].resultValue);
          if (!hasPeer) {
            setError(
              {
                message: `When ${key} is present, ${field} must be specified.`,
                level: 'error',
              },
              newRecord,
              [field]
            );
          } else {
            resetErrors(record, [field]);
          }
        });
      } else {
        resetErrors(record, peers);
      }
    });

    return newRecord;
  });
}
