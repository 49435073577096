import styled from 'styled-components';
import { Col, Row } from 'rio-ui-components';
import { Link } from 'react-router-dom';
import { AccordionItem, StyledHeading, underlineElement, accordionTitle } from '../AccordionItem';

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  min-height: 64px;
  align-items: center;
  ${accordionTitle}
  ${underlineElement}
`;

export function AccordionMenu({ menu, active }) {
  return (
    <Col span={12} container>
      {menu.map((item) => {
        const itemIsActive = item.id === active;
        const childIsActive = item.children?.some((child) => child.id === active);
        const childOfChildIsActive = item.children?.some(child=> child?.children?.some(c => c.id === active));
      
        return (
          <Link to={item.link} key={item.id}>
            {item.children?.length ? (
              <AccordionItem heading={item.heading} active={itemIsActive} defaultExpanded={itemIsActive || childIsActive ||childOfChildIsActive || item.expanded} hasChildren>
                <Col span={12} container>
                  <AccordionMenu menu={item.children} active={active} />
                </Col>
              </AccordionItem>
            ) : (
              <RowStyled active={itemIsActive} container item>
                <StyledHeading>{item.heading}</StyledHeading>
              </RowStyled>
            )}
          </Link>
        );
      })}
    </Col>
  );
}
