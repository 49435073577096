import { gql } from '@apollo/client';

import { LOCATION_FRAGMENT } from '../../../../graphql/fragments/LocationFields.fragment';

export const GET_MATERIALS_TRANSACTIONS_PAGE = gql`
  query GetMaterialsTransactionsPage(
    $accountId: ID!
    $offset: Int
    $limit: Int
    $sort: [SortCommand!]
    $filters: Filters
  ) {
    getMaterialsTransactionsPage(
      accountId: $accountId
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      totalRows
      rows {
        id
        locationName
        locationCode
        locationEndDate
        date
        activity
        material
        origin
        supplier
        productCode
        unitsQuantity
        weight
        quantityUnit
        totalCost
        totalCostVat
        currency
        notes
        reference
        invoiceNumber
        evidenceReference
        otherReference
        tags
        carbonScope3
        carbonScope1
        importBatchId
        importBatchFileName
        departments
        location {
          ...LocationFields
        }
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;
