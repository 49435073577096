import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${(p) => p.theme.geometry.xl.spacing};
  position: relative;
  max-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LearnEmbeddedIframe = ({ course }) => {
  return (
    <Container>
      <iframe title="engage" src={course.metaData.url} width="100%" height="100%" />
    </Container>
  );
};

export default LearnEmbeddedIframe;
