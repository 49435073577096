import { useQuery } from '@apollo/client';
import React from 'react';
import { Query } from '@rio/rio-types';
import { Select } from 'rio-ui-components';
import { GET_ALL_UNIT_TYPES } from './index.queries';
import { toSelectOptions } from '../../utils';
import { SelectEvent, Nullable, Option } from '../../types';

interface MeasurementUnitSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  possibleOptions?: Nullable<string[]>;
  error?: Nullable<string>;
  placeholder?: Nullable<string>;
}
export function MeasurementUnitSelect({
  value,
  onChange,
  possibleOptions,
  error: validationError,
  placeholder
}: MeasurementUnitSelectProps) {
  const { data, loading, error } = useQuery<Pick<Query, 'getAllUnits'>>(GET_ALL_UNIT_TYPES);
  const allOptions = toSelectOptions(data?.getAllUnits);
  const validOptions = allOptions.filter((option) => possibleOptions?.includes(option.label));

  return (
    <Select
      placeholder={placeholder || 'Please select unit of measurement'}
      value={value}
      options={validOptions}
      error={error || validationError}
      isLoading={loading}
      onChange={onChange}
    />
  );
}
