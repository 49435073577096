import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserToken } from '~/hooks';
import withTracker from '~/utils/withTracker';

export const PublicRoute = withTracker(({ component: Component }) => {
  const { token, loading } = useUserToken();
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (token) {
    const root = location.pathname.startsWith('/v2/') ? '/v2/' : '/';
    return <Navigate to={location?.state?.from || root} />;
  }
  return <Component />;
});

export const ProtectedRoute = withTracker(({ component: Component, redirectUrl }) => {
  const { token, loading } = useUserToken();
  const location = useLocation();

  const Result = useMemo(() => {
    if (loading) {
      return null;
    }
    if (!token) {
      return <Navigate to={redirectUrl} state={{ from: location }} />;
    }
    return <Component />;
  }, [loading, token, redirectUrl, location, Component]);
  return Result;
});
