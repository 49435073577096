import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { Project } from '@rio/rio-types';
import TaskDetails, { FullTask } from './TaskDetails';
import { LOCATION, TAG } from '~/constants/scopes';
import CREATE_TASK from '../../../../graphql/mutations/tasks/CreateTask.mutation.graphql';
import { ValidateTask } from '../validate';
import { GET_PROJECT_BY_ID } from '../../../../graphql/queries/projects/GetProjectById.query';
import { Button, Modal, OptionsProps, styled } from '@rio/ui-components';

const SubmitButton = styled(Button)`
  margin-top: 32px;
  width: 100%;
`;

interface TaskModalProps {
  accountId: string;
  onDismiss: () => void;
  onComplete: (arg1: { message: string; colour: string }, arg2?: Record<string, unknown>) => void;
  sourcePage?: string;
  defaultValues?: FullTask;
  disabled?: Record<string, boolean>;
  projectId?: string;
  hasProjectsAccess?: boolean;
  open: boolean;
}

type CacheProject = {
  getProject: Project;
};

const CreateTaskModal = ({
  accountId,
  onDismiss,
  onComplete,
  sourcePage,
  defaultValues,
  disabled = {},
  projectId,
  hasProjectsAccess = false,
  open,
}: TaskModalProps) => {
  const defaultInputs = useMemo(
    () =>
      ({
        scope: defaultValues?.scope || null,
        locationIds: defaultValues?.locationIds || [],
        tagIds: defaultValues?.tagIds || [],
        name: defaultValues?.name || '',
        description: defaultValues?.description || '',
        category: defaultValues?.category || null,
        subject: defaultValues?.subject || null,
        item: defaultValues?.item || undefined,
        itemId: defaultValues?.itemId || null,
        accountId: defaultValues?.accountId || '',
        owners: defaultValues?.owners || [],
        dueDate: defaultValues?.dueDate || new Date().toISOString(),
        priority: defaultValues?.priority || null,
        isCompleted: defaultValues?.isCompleted || false,
        projectId: projectId || null,
      } as FullTask),
    [defaultValues, projectId]
  );
  const [task, setTask] = useState<FullTask>(defaultInputs);

  const [createTask, { loading: isSubmitting }] = useMutation(CREATE_TASK, {
    onCompleted: (data) => {
      onComplete(
        {
          message: 'Task has been created.',
          colour: 'success',
        },
        data
      );
      onDismiss();
      setTask(defaultInputs);
    },
    update(cache, { data }) {
      if (!projectId) return;

      const newTask = data.createTask;
      const cacheProject: CacheProject | null = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: { id: projectId },
      });
      const project = cacheProject?.getProject;

      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: { id: projectId },
        data: {
          getProject: {
            ...project,
            tasks: [...(project?.tasks || []), newTask],
          },
        },
      });
    },
    onError: () => {
      onComplete({
        message: 'Something went wrong! Please try again later.',
        colour: 'danger',
      });
    },
  });

  const emptyErrors = useMemo(
    () => ({
      name: '',
      description: '',
      priority: '',
      category: '',
      subject: '',
      owner: '',
      dueDate: '',
    }),
    []
  );
  const [errors, setErrors] = useState(emptyErrors);

  const handleSubmit = useCallback(async () => {
    const validationErrors = ValidateTask(task);
    if (!isEmpty(validationErrors)) {
      setErrors({ ...emptyErrors, ...validationErrors });
      return;
    } else {
      setErrors(emptyErrors);
    }

    const taskId = uuid();
    const tags = task.scope === TAG ? task.tagIds : [];
    createTask({
      variables: {
        id: taskId,
        createdAt: new Date().toISOString(),
        ...task,
        accountId,
        itemId: task.itemId || task.item?.id || null,
        locationIds: task.scope === LOCATION ? task.locationIds : [],
        tagIds: tags,
        owners: task.owners.map((owner) => (owner as unknown as OptionsProps).value) || [],
      },
      refetchQueries: ['GetTasksByAccountId', 'GetLegislationById'],
    });
  }, [accountId, createTask, emptyErrors, task]);

  return (
    <Modal grid={{ xs: 8, md: 6 }} open={open} onClose={() => onDismiss()} title="Create New Task">
      <TaskDetails
        task={task}
        setTask={setTask}
        accountId={accountId}
        sourcePage={sourcePage}
        disabled={disabled}
        isSubmitting={isSubmitting}
        isEditing={undefined}
        projectId={projectId}
        hasProjectsAccess={hasProjectsAccess}
        errors={errors}
      />
      <SubmitButton
        loading={isSubmitting}
        variant="filled"
        data-cy="CreateTaskModal__submit"
        onClick={() => handleSubmit()}
      >
        Submit
      </SubmitButton>
    </Modal>
  );
};

export { CreateTaskModal };
