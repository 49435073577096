import React, { useEffect, useState } from 'react';
import { Select } from 'rio-ui-components';
import { useNotification, useGetFlows } from '../../hooks';

import { Nullable, Option, SelectEvent } from '../../types';

const templateToOption = (flow) => ({
  value: flow.id,
  label: flow.title,
});

interface FlowSelectProps {
  value: Nullable<string | Option>;
  onChange: (e: SelectEvent) => void;
  error?: string;
}

export const FlowSelector = ({ value, onChange, error = '' }: FlowSelectProps) => {
  const { showNotification } = useNotification();

  const { error: loadError, data } = useGetFlows({
    onError: () => showNotification('Error occurred while loading options:', 'danger'),
  });
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (loadError) {
      setOptions([]);
    } else {
      const flows = data?.getFlows || [];
      const newOptions = flows.map(templateToOption);
      setOptions(newOptions);
    }
  }, [data, loadError]);

  return (
    <Select
      id="AddFlow__Select"
      name="FlowSelect"
      placeholder="Please select a flow"
      multiValueColor="tertiary"
      defaultValue={value}
      defaultOptions
      onChange={onChange}
      classPrefix="user-select"
      error={error}
      options={options}
    />
  );
};
