import axios from 'axios';
import { getEnvVar } from '../../env';

function getFileMimeType(file) {
  const supportedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/svg+xml',
    'image/webp',
    'text/html',
    'text/xml',
    'text/csv',
    'text/plain',
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
  ];

  return supportedMimeTypes.includes(file.type) ? file.type : 'binary/octet-stream';
}

export const resolvers = {
  Mutation: {
    uploadDocument: async (_, { id, accountId, file, fileName, shouldThrowIfFailed }) => {
      const endpoint = `${getEnvVar('REACT_APP_DOCUMENT_UPLOAD')}/${accountId}/${id}/${id}`;
      try {
        const mimeType = getFileMimeType(file);
        const headers = {
          'X-Amz-Meta-Filename': encodeURI(fileName),
          'Content-Type': mimeType,
          Authorization: localStorage.getItem('ACCESS_TOKEN'),
        };

        if (mimeType === 'binary/octet-stream') {
          headers['Content-Disposition'] = `attachment; filename="${encodeURI(fileName)}";`;
        }

        const response = await axios({
          method: 'PUT',
          url: endpoint,
          headers,
          data: file,
        });
        const data = {
          status: response.status,
          fileUploaded: response.status === 200,
          __typename: 'UploadDocumentResponse',
        };

        return data;
      } catch (error) {
        console.error(error);

        if (shouldThrowIfFailed) {
          let errorMessage = '';

          if (error.response) {
            errorMessage = typeof error.response.data === 'string' ? error.response.data : error.response.data?.message;
          } else if (error.request) {
            errorMessage = 'Unfortunately the file is too large, please upload a file smaller than 10Mb';
          }

          throw new Error(errorMessage || 'Reason unknown');
        }
      }
    },
  },
};
