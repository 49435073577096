import React from 'react';
import styled from 'styled-components';
import { Col, Row, Label, TextInput, Heading, DatePicker } from 'rio-ui-components';

const LabelStyled = styled(Label)`
  margin-right: ${(props) => props.theme.geometry.xs.spacing};
`;

const LabelContainerStyled = styled.div`
  margin-bottom: ${(props) => props.theme.geometry.xs.spacing};
  display: flex;
  align-items: center;
`;

const HeaderStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.geometry.sm.spacing};
`;

const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
`;

const StartDatePicker = styled(DatePicker)`
  .DayPickerInput-Overlay {
    top: -354px;
    left: 50%;
  }
`;

const EnergyDetails = ({ heading, isSubmitting, upload, setUpload }) => {
  const handleChange = (event) => {
    setUpload({
      ...upload,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <HeaderStyled size="lg">{heading}</HeaderStyled>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Meter Name</LabelStyled>
          </LabelContainerStyled>
          <TextInput disabled={isSubmitting} onChange={(e) => handleChange(e)} name="meterName" value={upload.meterName} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Invoice Number</LabelStyled>
          </LabelContainerStyled>
          <TextInput disabled={isSubmitting} onChange={(e) => handleChange(e)} name="invoiceNumber" value={upload.invoiceNumber} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Quantity</LabelStyled>
          </LabelContainerStyled>
          <TextInput disabled={isSubmitting} onChange={(e) => handleChange(e)} name="quantity" value={upload.quantity} box />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Start Date</LabelStyled>
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date) => {
              handleChange({
                target: {
                  value: date ? date.toISOString() : date,
                  name: 'startDate'
                }
              });
            }}
            name="startDate"
            selectedDate={upload.startDate ? new Date(upload.startDate) : upload.startDate}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>End Date</LabelStyled>
          </LabelContainerStyled>
          <StartDatePicker
            disabled={isSubmitting}
            onDayClick={(date) => {
              handleChange({
                target: {
                  value: date ? date.toISOString() : date,
                  name: 'endDate'
                }
              });
            }}
            name="endDate"
            selectedDate={upload.endDate ? new Date(upload.endDate) : upload.endDate}
            box
          />
        </ColStyled>
      </Row>
      <Row container align="between">
        <ColStyled item>
          <LabelContainerStyled>
            <LabelStyled>Total Cost</LabelStyled>
          </LabelContainerStyled>
          <TextInput disabled={isSubmitting} onChange={(e) => handleChange(e)} name="totalCost" value={upload.totalCost} box />
        </ColStyled>
      </Row>
    </>
  );
};

export default EnergyDetails;
