import { gql } from '@apollo/client';

export const GET_HOTELSTAYS_TRANSACTIONS_PAGE_FILTER = gql`
  query GetHotelStaysTransactionsPageFilter($accountId: ID!, $field: String!) {
    getHotelStaysTransactionsPageFilter(accountId: $accountId, field: $field) {
      label
      value
    }
  }
`;
