import React from 'react';
import { Widget, Col } from 'rio-ui-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';

import NoAccess from './NoAccess';
import ContentColumns from './ContentColumns';
import NoOutstandingActions from './NoOutstandingActions';
import CoursesBanner from '../LearnContainer/Courses/CoursesBanner';
import { filterContinue, sortByDateDesc, filterComplete } from '../LearnContainer/utils';
import { Tile } from '../../components/Tile';
import shineCss from '../../utils/shineCss';
import { ReviewDateIcon } from '../../components/ReviewDateIcon';
import { utilityIcons } from '../../constants/utilityTypes';
import { Status } from '../GovernanceContainer/Targets/Grid';
import { dashboardsByUtilityType } from '../../constants/dashboards';
import { usePermissions } from '../../hooks/usePermissions';
import { useIsDemoAccount } from '../../hooks/useIsDemoAccount';
import { accountsWithHiddenContentParts } from '../../constants/accounts';

const DivStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const DateContainer = styled.div`
  font-size: 18px;
  line-height: 28px;
`;

const createLearnStatProps = (incomplete, completed) => {
  return {
    left: {
      name: <FormattedMessage id="pages.home.engageTasksStatuses.incomplete" />,
      value: `${incomplete} min`,
      alert: incomplete > 0,
      new: false,
    },
    right: {
      name: <FormattedMessage id="pages.home.engageTasksStatuses.completed" />,
      value: `${completed} min`,
      alert: false,
      new: false,
    },
  };
};

const overOneMonthSinceLastUpload = (timeStamp) => {
  if (timeStamp) {
    const fourWeeks = 28;
    const today = moment(moment.now()).dayOfYear();
    const dayOfLastUpload = moment(timeStamp).dayOfYear();
    return today - dayOfLastUpload >= fourWeeks;
  }
  return false;
};

const getReadableDate = (timeStamp) => {
  if (timeStamp) return new Date(timeStamp).toLocaleString().split(',')[0];
  return '-';
};

const createDataStatProps = (timeStamp) => {
  return {
    left: {
      name: 'Last Upload',
      value: <DateContainer>{getReadableDate(timeStamp)}</DateContainer>,
      alert: overOneMonthSinceLastUpload(timeStamp),
      new: false,
    },
  };
};

const NotificationContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  cursor: default;
`;

const Action = styled(Link)`
  position: relative;
  padding: 16px;
  background: ${({ theme }) => theme.colors.primary.light.background};
  font-size: 20px;
  color: white;
  width: 100%;
  text-align: center;

  :hover {
    background: ${({ theme }) => theme.colors.primary.dark.background};
  }
`;

const HashLinkStyled = styled(HashLink)`
  text-decoration: underline;
`;

const NotificationContainerDemo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const ActionDemo = styled.div`
  position: relative;
  padding: 16px;
  background: #ff9789;
  font-size: 20px;
  color: white;
  width: 100%;
  text-align: center;
`;

const ActionPlaceholder = styled.div`
  position: relative;
  padding: 16px;
  text-align: center;
  background: ${(props) => props.theme.colors.neutral.normal.background};
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  ${shineCss}
`;

const WasteDashboardNotification = ({ loading, goTo, title, buttonTitle, category, icon, iconColor, status }) => {
  return (
    <NotificationContainer>
      <Tile placeholder={loading} title={title} category={category} icon={icon} iconColor={iconColor} status={status} />
      {loading && <ActionPlaceholder />}
      {!loading && !!goTo && <Action to={goTo}>{buttonTitle}</Action>}
    </NotificationContainer>
  );
};

const DemoNotifications = ({ loading }) => {
  return (
    <>
      <NotificationContainerDemo>
        <Tile
          placeholder={loading}
          title="Paper and Card disposal 42% over market rate – March 2020"
          category="Investigate"
          icon="pound-sign"
          iconColor="quaternary"
        />
        <ActionDemo />
      </NotificationContainerDemo>
      <NotificationContainerDemo>
        <Tile
          placeholder={loading}
          title="Metal disposal 62% over market rate – May 2020"
          category="Investigate"
          icon="pound-sign"
          iconColor="quaternary"
        />
        <ActionDemo />
      </NotificationContainerDemo>
      <NotificationContainerDemo>
        <Tile
          placeholder={loading}
          title="Paper and card could benefit from baling/compaction to reduce collection frequency"
          category="Investigate"
          icon="recycle"
          iconColor="quaternary"
        />
        <ActionDemo />
      </NotificationContainerDemo>
    </>
  );
};

const ComplyDocumentDashboardNotification = ({ count, documentType, loading }) => {
  return (
    <NotificationContainer to="data/Waste/view">
      <Tile
        placeholder={loading}
        title={`Missing: ${count} ${documentType} IDs`}
        category="Action"
        icon="file-alt"
        iconColor="tertiary"
      />
      {loading && <ActionPlaceholder />}
      {!loading && <Action to="data/Waste/view">Add {documentType} IDs</Action>}
    </NotificationContainer>
  );
};

const ComplyDocumentReviewNotification = ({ id, reviewDate, fileName, loading }) => {
  const isExpired = moment().isAfter(moment(reviewDate));
  const title = isExpired
    ? `Your document ${fileName} has an expired review date.`
    : `Your document ${fileName} is approaching its review date.`;
  return (
    <NotificationContainer to={`documents/all/${id}`}>
      <Tile placeholder={loading} title={title} category="Action" icon="file-alt" iconColor="tertiary" />
      {loading && <ActionPlaceholder />}
      {!loading && <Action to={`documents/all/${id}`}>{`Review ${fileName}`}</Action>}
    </NotificationContainer>
  );
};

const ComplyLegislationNotification = ({ id, title, alertText, date }) => {
  const text = `Legislation item ${title} ${alertText}`;
  return (
    <NotificationContainer to={`governance/legislation/${id}`}>
      <ReviewDateIcon date={date} tile />
      <Tile title={text} category="Action" icon="file-alt" iconColor="tertiary" />
      <Action to={`governance/legislation/${id}`}>{`Review Legislation`}</Action>
    </NotificationContainer>
  );
};

const ComplyAspectsNotification = ({ id, activity, alertText, reviewDate }) => {
  const text = `Environmental aspect ${activity} ${alertText}`;
  const link = `governance/environmental-aspects?showEditModal=${id}`;
  return (
    <NotificationContainer to={link}>
      <ReviewDateIcon date={reviewDate} tile />
      <Tile title={text} category="Action" icon="file-alt" iconColor="tertiary" />
      <Action to={link}>{`Review Aspect ${activity}`}</Action>
    </NotificationContainer>
  );
};

// Not removed for possible future need in this component again
// const ComplyUnlinkedDocumentDashboardNotification = ({ count, documentType, loading }) => {
//   return (
//     <NotificationContainer to="/data/Waste/view">
//       <Tile
//         placeholder={loading}
//         title={`${count}${count === 100 ? ' or more' : ''} waste movement${
//           count > 1 ? 's' : ''
//         } with ${documentType} ID${count > 1 ? 's' : ''}, but no ${documentType} linked`}
//         category="Action"
//         icon="file-alt"
//         iconColor="tertiary"
//       />{' '}
//       {loading && <ActionPlaceholder />}
//       {!loading && <Action to="/Documents/new">Upload {documentType}s</Action>}
//     </NotificationContainer>
//   );
// };

const NoAccessWrapper = styled.div`
  height: 100%;
  ${({ access }) => (access ? '' : 'opacity: 0.3;')}
  ${({ access }) => (access ? '' : 'overflow-y: hidden;')}
`;

const ReadOnlyCheck = ({ children, readOnly }) => {
  return (
    <DivStyled>
      {!readOnly && children}
      {readOnly && <NoOutstandingActions />}
    </DivStyled>
  );
};
const getLearnDashboardData = (learnData, learnLoading) => {
  if (!learnLoading && learnData) {
    const incompleteCourses = learnData.getAvailableCourses.data.filter(filterContinue).sort(sortByDateDesc);
    const completedCourses = learnData.getAvailableCourses.data.filter(filterComplete);
    return { incompleteCourses, completedCourses };
  }
  return { incompleteCourses: [], completedCourses: [] };
};
const getCoursesCpd = (courses) => {
  if (!courses || !courses.length) {
    return 0;
  }
  return courses.reduce((cpd, course) => cpd + course.metaData.cpd, 0);
};
const getComplyDashboardData = (complyData, complyLoading) => {
  if (!complyLoading && complyData) {
    const documentsToReview = complyData.getDocumentRecordsForReview;
    const expiredLegislation = complyData.getLegislationAlerts.expired;
    const approachingLegislation = complyData.getLegislationAlerts.approaching;
    const flaggedLegislation = complyData.getLegislationAlerts.flagged;
    const expiredAspects = complyData.getAspectsAlerts.expired;
    const approachingAspects = complyData.getAspectsAlerts.approaching;

    return {
      documentsToReview,
      expiredLegislation,
      approachingLegislation,
      flaggedLegislation,
      expiredAspects,
      approachingAspects,
    };
  }
  return {};
};

const getAnalyseDashboardData = (analyseData, analyseLoading) => {
  if (!analyseLoading && analyseData) {
    const lastWasteImport = analyseData.getDataImportBatchesByAccountId.batches[0]
      ? analyseData.getDataImportBatchesByAccountId.batches[0].timeStamp
      : null;
    const lastWasteImportOverOneMonth = overOneMonthSinceLastUpload(lastWasteImport);
    const targets = analyseData.getTargetsByAccountId;
    return { lastWasteImport, lastWasteImportOverOneMonth, targets };
  }
  return {};
};

const Dashboard = ({ access, loading, data, intl, accountId }) => {
  const { hasLearnAccess, hasComplyAccess, hasDataAccess } = access;
  const { learnLoading, complyLoading, analyseLoading } = loading;
  const { learnData, complyData, analyseData } = data;
  const { incompleteCourses, completedCourses } = getLearnDashboardData(learnData, learnLoading);
  const {
    documentsToReview,
    expiredLegislation,
    approachingLegislation,
    flaggedLegislation,
    expiredAspects,
    approachingAspects,
  } = getComplyDashboardData(complyData, complyLoading, accountId);
  const { lastWasteImport, lastWasteImportOverOneMonth, targets } = getAnalyseDashboardData(
    analyseData,
    analyseLoading
  );
  const showDemoNotification = useIsDemoAccount();
  const permissions = usePermissions();

  const hideWidget = accountsWithHiddenContentParts.includes(accountId);

  const noComplyActionsAvailable =
    expiredLegislation?.length === 0 &&
    approachingLegislation?.length === 0 &&
    documentsToReview?.length === 0 &&
    flaggedLegislation?.length === 0 &&
    expiredAspects?.length === 0 &&
    approachingAspects?.length === 0;

  return hideWidget ? (
    <ContentColumns>
      <Widget
        darkMode={false}
        layout={{ reverse: false, header: 'center' }}
        circleProps={{
          accentColor: 'secondary',
          icon: 'graduation-cap',
          title: intl.formatMessage({ id: 'pages.home.dashboard.learn' }),
          linkTo: 'engage',
          badgeStyle: false,
        }}
        statProps={createLearnStatProps(getCoursesCpd(incompleteCourses), getCoursesCpd(completedCourses))}
      >
        <ReadOnlyCheck readOnly={!permissions.learn.find((action) => action.startsWith('updateCourseProgress'))}>
          {!hasLearnAccess && <NoAccess contentSection="Engage" />}
          <NoAccessWrapper access={hasLearnAccess}>
            {learnLoading && (
              <CoursesBanner
                sectionTitle="Continue"
                highlightColor="secondary"
                items={[]}
                learnAccess={hasLearnAccess}
                loading
                column
                noBackground
                noHeader
              />
            )}
            {(incompleteCourses.length > 0 && learnData && (
              <CoursesBanner
                sectionTitle="Continue"
                highlightColor="secondary"
                items={incompleteCourses}
                learnAccess={hasLearnAccess}
                column
                noBackground
                noHeader
              />
            )) ||
              (!learnLoading && <NoOutstandingActions />)}
          </NoAccessWrapper>
        </ReadOnlyCheck>
      </Widget>
    </ContentColumns>
  ) : (
    <ContentColumns>
      <Widget
        darkMode={false}
        layout={{ reverse: false, header: 'center' }}
        circleProps={{
          accentColor: 'secondary',
          icon: 'graduation-cap',
          title: intl.formatMessage({ id: 'pages.home.dashboard.learn' }),
          linkTo: 'engage',
          badgeStyle: false,
        }}
        statProps={createLearnStatProps(getCoursesCpd(incompleteCourses), getCoursesCpd(completedCourses))}
      >
        <ReadOnlyCheck readOnly={!permissions.learn.find((action) => action.startsWith('updateCourseProgress'))}>
          {!hasLearnAccess && <NoAccess contentSection="Engage" />}
          <NoAccessWrapper access={hasLearnAccess}>
            {learnLoading && (
              <CoursesBanner
                sectionTitle="Continue"
                highlightColor="secondary"
                items={[]}
                learnAccess={hasLearnAccess}
                loading
                column
                noBackground
                noHeader
              />
            )}
            {(incompleteCourses.length > 0 && learnData && (
              <CoursesBanner
                sectionTitle="Continue"
                highlightColor="secondary"
                items={incompleteCourses}
                learnAccess={hasLearnAccess}
                column
                noBackground
                noHeader
              />
            )) ||
              (!learnLoading && <NoOutstandingActions />)}
          </NoAccessWrapper>
        </ReadOnlyCheck>
      </Widget>
      <Widget
        darkMode={false}
        layout={{ reverse: false, header: 'center' }}
        circleProps={{
          accentColor: 'tertiary',
          icon: 'pencil-alt',
          title: intl.formatMessage({ id: 'pages.home.dashboard.governance' }),
          linkTo: 'governance',
          badgeStyle: false,
        }}
        statProps={{}}
      >
        <ReadOnlyCheck
          readOnly={
            !permissions.governance.find((action) => action.startsWith('editLegislation')) &&
            !permissions.document.find((action) => action.startsWith('create'))
          }
        >
          {!hasComplyAccess && <NoAccess contentSection="Governance" />}
          <NoAccessWrapper access={hasComplyAccess}>
            {complyLoading && (
              <>
                {new Array(5)
                  .fill(0)
                  .map((_, idx) => idx)
                  .map((id) => {
                    return <ComplyDocumentDashboardNotification key={id} loading={complyLoading} />;
                  })}
              </>
            )}
            {!complyLoading &&
              expiredLegislation &&
              expiredLegislation.map((item) => {
                return (
                  <ComplyLegislationNotification
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    alertText="has an expired review date."
                    date={moment().subtract(1, 'day')}
                  />
                );
              })}
            {!complyLoading &&
              approachingLegislation &&
              approachingLegislation.map((item) => {
                return (
                  <ComplyLegislationNotification
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    alertText="is approaching its review date."
                    date={moment().add(1, 'day')}
                  />
                );
              })}
            {!complyLoading &&
              flaggedLegislation &&
              flaggedLegislation.map((item) => {
                return (
                  <ComplyLegislationNotification
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    alertText="has been flagged as changed by the Rio team."
                  />
                );
              })}
            {!complyLoading &&
              documentsToReview &&
              documentsToReview.map((document) => {
                return (
                  <ComplyDocumentReviewNotification
                    id={document.id}
                    key={document.id}
                    reviewDate={document.reviewDate}
                    fileName={document.fileName}
                  />
                );
              })}
            {!complyLoading &&
              expiredAspects &&
              expiredAspects.map((aspect) => {
                return (
                  <ComplyAspectsNotification
                    id={aspect.id}
                    key={document.id}
                    activity={aspect.activity}
                    reviewDate={aspect.reviewDate}
                    alertText="has an expired review date."
                  />
                );
              })}
            {!complyLoading &&
              approachingAspects &&
              approachingAspects.map((aspect) => {
                return (
                  <ComplyAspectsNotification
                    id={aspect.id}
                    key={document.id}
                    activity={aspect.activity}
                    reviewDate={aspect.reviewDate}
                    alertText="is approaching its review date."
                  />
                );
              })}
            {!complyLoading && noComplyActionsAvailable && <NoOutstandingActions />}
          </NoAccessWrapper>
        </ReadOnlyCheck>
      </Widget>
      <Widget
        darkMode={false}
        layout={{ reverse: false, header: 'center' }}
        circleProps={{
          accentColor: 'quaternary',
          icon: 'chart-area',
          title: intl.formatMessage({ id: 'pages.home.dashboard.analyse' }),
          linkTo: 'reporting',
          badgeStyle: false,
        }}
        statProps={createDataStatProps(lastWasteImport)}
      >
        <ReadOnlyCheck readOnly={!permissions.data.find((action) => action.startsWith('createImportBatch'))}>
          {!hasDataAccess && <NoAccess contentSection="Data" />}
          <NoAccessWrapper access={hasDataAccess}>
            {analyseLoading &&
              new Array(5)
                .fill(0)
                .map((_, idx) => idx)
                .map((id) => {
                  return <WasteDashboardNotification key={id} loading={loading} />;
                })}
            {!analyseLoading && lastWasteImportOverOneMonth && (
              <WasteDashboardNotification
                goTo="data/Waste/uploads"
                title="It has been one month since you last uploaded waste data"
                category="Action"
                icon="trash"
                iconColor="quaternary"
                buttonTitle="Update your data"
              />
            )}
            {!analyseLoading &&
              (targets?.length
                ? targets.map((target) => {
                    const targetText = `${target.name} between ${moment(target.startDate)
                      .utc()
                      .format('DD/MM/YYYY')} and ${moment(target.endDate).utc().format('DD/MM/YYYY')}`;
                    return (
                      <WasteDashboardNotification
                        key={target.id}
                        goTo={dashboardsByUtilityType[target.topic.utility]}
                        title={targetText}
                        buttonTitle="Review performance"
                        icon={utilityIcons[target.topic.utility]}
                        iconColor="quaternary"
                        category={
                          <HashLinkStyled to={`governance/targets#${target.id}`} smooth>
                            Go to target
                          </HashLinkStyled>
                        }
                        status={<Status {...target} />}
                      />
                    );
                  })
                : !!permissions.data.find((action) => action.startsWith('createTarget')) && (
                    <WasteDashboardNotification
                      goTo="governance/targets?showCreateModal=1"
                      title="You don't have any targets yet - let's create them."
                      buttonTitle="Create target"
                      icon="subscript"
                      iconColor="quaternary"
                      category="Action"
                    />
                  ))}
            {!analyseLoading && showDemoNotification && <DemoNotifications />}
            {!analyseLoading &&
              targets?.length === 0 &&
              !permissions.data.find((action) => action.startsWith('createTarget')) &&
              !lastWasteImportOverOneMonth &&
              !showDemoNotification && <NoOutstandingActions />}
          </NoAccessWrapper>
        </ReadOnlyCheck>
      </Widget>
    </ContentColumns>
  );
};

export default injectIntl(Dashboard);
