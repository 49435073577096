import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { FundsAssetsIcon } from '~/components/SvgIcons/FundsAssets';
import { DataItemProps, Icons, UploadDataItemsProps } from './types';
import { getIconFromName } from './Icons';
import { DataItemContainer, DataItemsContainer, IconTextContainer, ItemContainer } from './Elements';

const dataWithoutUploads = ['buildingEnergyRatings'];

const DataItem = ({ kind, to }: DataItemProps) => {
  const navigate = useNavigate();
  const icon = getIconFromName(kind);
  const hasUploadNavigation = !dataWithoutUploads.includes(kind);

  return (
    <DataItemContainer onClick={hasUploadNavigation ? () => navigate(to) : () => {}}>
      <ItemContainer>{icon}</ItemContainer>
      <IconTextContainer>
        <FormattedMessage id={`pages.data.subjects.${kind}`} />
      </IconTextContainer>
    </DataItemContainer>
  );
};

export const UploadDataItems = ({ activeAccessControls, isPortfolio }: UploadDataItemsProps) => {
  const navigate = useNavigate();
  const to = './investment/uploads';
  const portfolioItemTitle = 'Funds & Assets';

  if (isPortfolio) {
    return (
      <DataItemsContainer>
        <DataItemContainer onClick={() => navigate(to)}>
          <ItemContainer>
            <FundsAssetsIcon />
          </ItemContainer>
          <IconTextContainer>{portfolioItemTitle}</IconTextContainer>
        </DataItemContainer>
      </DataItemsContainer>
    );
  }

  return (
    <DataItemsContainer>
      {activeAccessControls
        ?.filter((control) => !dataWithoutUploads.includes(control))
        .map((control, index) => (
          <DataItem key={`${control} - ${index}`} kind={control as Icons} to={`/data/${control}/uploads`} />
        ))}
    </DataItemsContainer>
  );
};
