import { ReactNode, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { DocumentRecord, DocumentLibrary, Source } from '@rio/rio-types';
import { v4 as uuid } from 'uuid';
import { InputFile } from 'rio-ui-components';
import { useCurrentAccountId, useUserToken } from '../../hooks';
import { createS3Key } from '../../containers/DocumentContainer/utils';
import { CREATE_DOCUMENT_RECORD } from './index.queries';
import UPLOAD_DOCUMENT from '../../graphql/mutations/UploadDocument.graphql';

interface PredefinedValues {
  category: string;
  library: DocumentLibrary;
  type?: string;
  reviewDate?: string;
  managementSystemCode?: string;
  managementSystemId?: string;
  managementSystemChapterId?: string;
}

interface FileUploadButtonProps {
  children: (selectFiles: () => void) => ReactNode;
  onUpload: (document: DocumentRecord) => void;
  predefinedValues: PredefinedValues;
  accept?: string;
}

export function FileUpload({ children, onUpload, predefinedValues, accept = '*' }: FileUploadButtonProps) {
  const accountId = useCurrentAccountId();
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [createDocumentRecord] = useMutation(CREATE_DOCUMENT_RECORD);
  const { token } = useUserToken();
  const handleRender = useCallback((selectFiles: () => void) => children(selectFiles), [children]);
  const handleChange = useCallback(
    async ([file]: File[]) => {
      const id = uuid();
      await uploadDocument({
        variables: {
          accountId,
          id,
          file,
          fileName: file.name,
          userId: token.sub
        }
      });
      const response = await createDocumentRecord({
        variables: {
          input: {
            id,
            accountId,
            fileName: file.name,
            userId: token.sub,
            source: Source.S3,
            key: createS3Key(accountId, id),
            ...predefinedValues
          }
        }
      });
      const newDocument = response?.data?.createDocumentRecord;
      if (newDocument && onUpload) {
        onUpload(newDocument);
      }
    },
    [accountId, token, uploadDocument, onUpload, createDocumentRecord, predefinedValues]
  );
  return (
    <InputFile onChange={handleChange} accept={accept}>
      {handleRender}
    </InputFile>
  );
}
