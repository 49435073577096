import * as Sentry from '@sentry/react';
import { useRouteError } from 'react-router-dom';
import { PageFailed } from '../Errors';
import { useEffect } from 'react';

export const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    window.console.error(error);
    Sentry.captureException(error);
  }, [error]);

  if (error) {
    return (
      <PageFailed
        retry={() => {
          window.location.reload();
        }}
        error={error as any}
      />
    );
  }

  return null;
};
