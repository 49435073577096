import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import React from 'react';
import { Row } from 'rio-ui-components';
import { injectIntl } from 'react-intl';
import { useCurrentAccount } from '../../hooks/useCurrentAccount';
import { useUserToken } from '../../hooks/useUserToken';
import { usePermissions } from '../../hooks/usePermissions';
import UserBannersSection from '../../components/UserBannersSection';
import GreetingsHeader from './GreetingsHeader';
import { GET_COMPLY_DATA, GET_ANALYSE_DATA, GET_ASPECTS_ALERTS, GET_LEGISLATION_ALERTS } from './index.queries';
import { GET_AVAILABLE_COURSES } from '../LearnContainer/Courses/index.queries';
import Dashboard from './Dashboard';

const getComplyData = (comply, aspects, legislation) => {
  if (!comply) {
    return undefined;
  }

  const result = {
    ...comply,
    getAspectsAlerts: { expired: [], approaching: [] },
    getLegislationAlerts: { expired: [], approaching: [], flagged: [] }
  };

  if (aspects) {
    result.getAspectsAlerts = aspects.getAspectsAlerts;
  }

  if (legislation) {
    result.getLegislationAlerts = legislation.getLegislationAlerts;
  }

  return result;
};

const HomeContainer = (props) => {
  const permissions = usePermissions();
  const { token } = useUserToken();
  const account = useCurrentAccount();
  const accountId = account ? account.id : null;
  const userId = token ? token.sub : null;

  const defaultAllowedAccess = { hasLearnAccess: true, hasComplyAccess: true, hasDataAccess: true };
  const access = account
    ? {
        hasLearnAccess: account.accessControls.learn,
        hasComplyAccess: account.accessControls.comply,
        hasDataAccess: account.accessControls.data
      }
    : defaultAllowedAccess;
  const { data: learnData, loading: learnLoading } = useQuery(GET_AVAILABLE_COURSES, {
    variables: {
      pageNumber: 1,
      userId,
      accountId
    }
  });

  const { data: comply, loading: complyLoading } = useQuery(GET_COMPLY_DATA, {
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });

  const canAccessAspects = permissions.governance.find((p) => p.startsWith('getAspect'));
  const { data: aspectsData, loading: aspectsLoading } = useQuery(GET_ASPECTS_ALERTS, {
    skip: !canAccessAspects,
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });

  const canAccessLegislation = permissions.governance.find((p) => p.startsWith('getLegislation'));
  const { data: legislationData, loading: legislationLoading } = useQuery(GET_LEGISLATION_ALERTS, {
    skip: !canAccessLegislation,
    variables: {
      accountId
    },
    fetchPolicy: 'network-only'
  });

  const complyData = getComplyData(comply, aspectsData, legislationData);

  const { data: analyseData, loading: analyseLoading } = useQuery(GET_ANALYSE_DATA, {
    variables: {
      accId: accountId,
      wasteBatchPage: 1
    }
  });

  const GuidanceRow = styled(Row)`
    margin: 0 auto 10px;
    justify-content: center;
    display: flex;
    color: rgb(136, 136, 136);
  `;

  return (
    <>
      <UserBannersSection id="home" padding="16px" />
      <Row name="home-container" container distribution="center" itemAlign="top" fullHeight={false}>
        <GreetingsHeader message={props.intl.formatMessage({ id: 'pages.home.welcome' })} />
      </Row>
      <GuidanceRow>{props.intl.formatMessage({ id: 'pages.home.guidanceText' })}</GuidanceRow>
      <Dashboard
        access={access}
        loading={{
          learnLoading: learnLoading || !learnData,
          complyLoading: complyLoading || aspectsLoading || legislationLoading || !complyData,
          analyseLoading: analyseLoading || !analyseData
        }}
        data={{ learnData, complyData, analyseData }}
        accountId={accountId}
      />
    </>
  );
};

HomeContainer.propTypes = {};

export default injectIntl(HomeContainer);
