import React, { Component } from 'react';
import { Col, ErrorMessage, ContainerError, Search } from 'rio-ui-components';
import { Query } from '@apollo/client/react/components';

import NoResults from '../../../components/NoResults';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_ACCOUNTS } from '../../../components/AccountsMultiSelect/index.queries';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import AccountRow from './AccountRow';

class ConfigurationDashboardSubscriptionsContainer extends Component {
  state = {
    search: { value: '', error: '' }
  };

  handleSearchChange = (event) => {
    this.setState({ search: { ...this.state.search, value: event.target.value } });
  };

  render() {
    return (
      <>
        <Col name="ConfigurationAccountsContainer" container fullHeight>
          <ContainerHeader
            name="ConfigurationDashboardSubscriptionsContainer__Controls"
            icon="briefcase"
            iconColor="primary"
            title="Dashboard Subscriptions"
          >
            <Col container item>
              <Search
                name="ConfigurationDashboardSubscriptionsContainer__Controls__Search"
                value={this.state.search.value}
                onChange={this.handleSearchChange}
                hideButton
              />
            </Col>
          </ContainerHeader>

          <Query
            query={GET_ACCOUNTS}
            variables={{
              limit: 100,
              filters: {
                text: [
                  {
                    filterType: 'text',
                    type: 'contains',
                    filter: this.state.search.value,
                    field: 'name'
                  }
                ]
              }
            }}
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              if ((loading && networkStatus !== 3) || networkStatus === 4)
                return <ContainerLoadingIndicator name="ConfigurationAccountsContainer__Loading" />;
              if (error)
                return (
                  <ErrorMessage error={error}>
                    {({ title, body, icon }) => (
                      <ContainerError
                        name="ConfigurationAccountsContainer__Error"
                        icon={icon}
                        title={title}
                        body={body}
                        retry={refetch}
                      />
                    )}
                  </ErrorMessage>
                );

              if (!data.getAccounts.totalRows) {
                return (
                  <NoResults
                    name="ConfigurationDashboardSubscriptionsContainer__NoResults"
                    title="There are no results for this search."
                  />
                );
              }

              return data.getAccounts.rows.map((account) => {
                return (
                  <AccountRow
                    key={account.id}
                    accountId={account.id}
                    businessName={account.name}
                    profileImage={account.profile}
                  />
                );
              });
            }}
          </Query>
        </Col>
      </>
    );
  }
}

export default ConfigurationDashboardSubscriptionsContainer;
