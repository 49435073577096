import { Option } from '../../types';

interface Location {
  id: string;
  name: string;
}

export function mapLocationToOption(location: Location): Option {
  return {
    value: location.id,
    label: location.name
  };
}
