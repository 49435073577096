import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_INFO } from '../queries/accounts';
import { useCurrentAccountId } from './useCurrentAccountId';
import { useAccountUpdated } from './useAccountUpdated';

/**
 * @deprecated Use applicationData instead
 * @returns AccessControls
 */
export function useAccessControls() {
  const accountId = useCurrentAccountId();
  const accountUpdatedData = useAccountUpdated();
  const { data, loading } = useQuery(GET_ACCOUNT_INFO, {
    variables: { id: accountId },
    skip: !!accountUpdatedData || !accountId,
  });
  const defaultAllowedAccess = {
    intelligence: false,
    learn: true,
    tasks: true,
    projects: false,
    dataTracker: false,
    comply: {
      legislation: true,
      tagLevelLegislation: false,
      policies: true,
      systems: true,
      targets: true,
      frameworks: true,
      agreements: true,
    },
    data: {
      waste: true,
      electricity: true,
      gas: true,
      water: true,
      materials: true,
      fuel: true,
      transport: true,
      refrigerants: true,
      heatSteam: true,
      buildingEnergyRatings: true,
      hotelStays: true,
      investment: true,
    },
  };

  return {
    data: accountUpdatedData?.accessControls || data?.getAccountInfo?.accessControls || defaultAllowedAccess,
    loading,
  };
}
