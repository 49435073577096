import { useMutation } from '@apollo/client';
import { MarketCarbonConversionFactor, Query } from '@rio/rio-types';
import { GET_MARKET_CARBON_CONVERSION_FACTORS, EDIT_MARKET_CARBON_CONVERSION_FACTOR } from './index.queries';
import { useGetMarketCarbonConversionFactorsVariables } from './useGetMarketCarbonConversionFactors';

export function useUpdateMarketCarbonConversionFactor({ onComplete }: { onComplete: Function }) {
  const readVariables = useGetMarketCarbonConversionFactorsVariables();
  return useMutation(EDIT_MARKET_CARBON_CONVERSION_FACTOR, {
    update: (dataProxy, fetchResult) => {
      const newFactor = fetchResult.data?.updateMarketCarbonConversionFactor;
      if (newFactor) {
        const result = dataProxy.readQuery<Query>({
          query: GET_MARKET_CARBON_CONVERSION_FACTORS,
          variables: readVariables,
        });
        const factors = result?.getMarketCarbonConversionFactors || [];
        const updatedFactors = factors.map((f: MarketCarbonConversionFactor) => {
          if (f.id === newFactor.id) {
            return newFactor;
          }
          return f;
        });
        dataProxy.writeQuery({
          query: GET_MARKET_CARBON_CONVERSION_FACTORS,
          variables: readVariables,
          data: {
            getMarketCarbonConversionFactors: updatedFactors,
          },
        });
        onComplete(updatedFactors);
      }
    },
  });
}
