import { SurveySubmission, SurveySubmissionStatus } from '@rio/rio-types';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { Row, Button } from 'rio-ui-components';
import { SendFeedbackModal } from './SendFeedbackModal';

type SendFeedbackFunction = (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;

interface SendFeedbackPanelProps {
  submission: SurveySubmission;
  sendFeedback: SendFeedbackFunction;
}

const SectionButtons = styled(Row)`
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.geometry.md.spacing};
`;
const FeedbackButton = styled(Button)`
  width: auto;
  font-weight: 600;
`;
const RequestChangesButton = styled(FeedbackButton)`
  color: ${(props) => props.theme.colors.text.light.text};
`;
const ApproveButton = styled(FeedbackButton)`
  margin-left: ${(props) => props.theme.geometry.md.spacing};
`;

export function SendFeedbackButtons({ submission, sendFeedback }: SendFeedbackPanelProps) {
  const [requestChangesModalShown, setRequestChangesModalShown] = useState(false);
  const [approveModalShown, setApproveModalShown] = useState(false);

  const toggleRequestChangesModal = useCallback(
    () => setRequestChangesModalShown((shown) => !shown),
    [setRequestChangesModalShown]
  );
  const toggleApproveModal = useCallback(() => setApproveModalShown((shown) => !shown), []);

  return (
    <>
      <SectionButtons item container>
        {submission.status !== SurveySubmissionStatus.RequiresChanges && (
          <RequestChangesButton color="warning" onClick={toggleRequestChangesModal}>
            Request changes
          </RequestChangesButton>
        )}
        {submission.status !== SurveySubmissionStatus.Approved && (
          <ApproveButton color="success" onClick={toggleApproveModal}>
            Approve
          </ApproveButton>
        )}
      </SectionButtons>

      {requestChangesModalShown && (
        <SendFeedbackModal
          intention={SurveySubmissionFeedbackStatus.RequiresChanges}
          onDismiss={toggleRequestChangesModal}
          onFeedback={sendFeedback}
        />
      )}
      {approveModalShown && (
        <SendFeedbackModal
          intention={SurveySubmissionFeedbackStatus.Approved}
          onDismiss={toggleApproveModal}
          onFeedback={sendFeedback}
        />
      )}
    </>
  );
}
