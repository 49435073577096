import { gql } from '@apollo/client';
import CONTAINER_TYPE_FRAGMENT from '../../../graphql/fragments/ContainerTypeFields.fragment.graphql';
import GET_CONTAINER_TYPES from '../../../graphql/queries/GetContainerTypes.query.graphql';

export { CONTAINER_TYPE_FRAGMENT, GET_CONTAINER_TYPES };

export const GET_ALL_UNITS = gql`
  {
    getAllUnits {
      id
      name
    }
  }
`;

export const CREATE_CONTAINER_TYPE = gql`
  mutation CreateContainerType($input: CreateContainerTypeInput!) {
    createContainerType(input: $input) {
      ...ContainerTypeFields
    }
  }
  ${CONTAINER_TYPE_FRAGMENT}
`;
