import React from 'react';
import styled from 'styled-components';
import { Button } from 'rio-ui-components';
import { useParams } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import PageHeader from '../../../components/PageHeader';
import { RelatedCourseGrid } from './RelatedCourseGrid';
import SelectedCoursePreview from './SelectedCoursePreview';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.geometry.md.spacing};
  height: 100%;
`;

const BackButtonStyled = styled(Button)`
  border-radius: 0;
`;

const CoursePreview = ({ learnAccess, intl }) => {
  const { bundleId, id } = useParams();
  return (
    <ContainerStyled>
      <PageHeader
        name="CoursePreview"
        title={intl.formatMessage({ id: 'pages.coursesPreview.breadcrumbs.two' })}
        breadcrumbs={[
          { title: intl.formatMessage({ id: 'pages.coursesPreview.breadcrumbs.one' }), to: '/engage' },
          { title: intl.formatMessage({ id: 'pages.coursesPreview.breadcrumbs.two' })},
        ]}
        icon="graduation-cap"
        iconColor="secondary"
      >
        <BackButtonStyled name="CoursePreview__Button-back" inline color="info" component="routerLink" to="..">
          <FormattedMessage id="pages.coursesPreview.backToEngage" />
        </BackButtonStyled>
      </PageHeader>

      <ContentContainer>
        <SelectedCoursePreview id={id} bundleId={bundleId} />
        <RelatedCourseGrid learnAccess={learnAccess} id={id} />
      </ContentContainer>
    </ContainerStyled>
  );
};

export default injectIntl(CoursePreview);
