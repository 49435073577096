import moment from 'moment';
import { ExportedList, CustomLegislation } from './types';
import { Nullable } from '../../../../types';
import { createXls, formatDate } from '../../../../utils';
import downloadFile from 'js-file-download';
import statuses from './legislationStatuses.json';

export const checkForActiveReviewFlag = (dateFlagged: Nullable<string>, dateCleared: Nullable<string>) => {
  if (!dateFlagged) return false;
  if (dateFlagged && !dateCleared) return true;

  return !moment(dateCleared).isSameOrAfter(moment(dateFlagged));
};

export const complianceStatus = (params: { value: Nullable<string> }) => {
  const selectedStatus = statuses.find((item) => item.value === params.value);
  return selectedStatus?.name || 'TBD';
};

export const startExportData = (dataForExport: CustomLegislation[], isTagLibraryAccess: boolean) => {
  const defaultApplicability = 'You can add content relating to the applicability of this legislation here.';
  const exportedFile: ExportedList[] = [];
  dataForExport.forEach((legislation: CustomLegislation) => {
    let exportColumn: ExportedList = {
      Title: legislation.title,
      Synopsis: legislation.synopsis,
      'Compliance Status': complianceStatus({ value: legislation.status }),
      'Review Date': formatDate(legislation.reviewDate),
      Applicability: legislation.applicability !== defaultApplicability ? legislation.applicability : '',
      Countries: legislation.countries.join(', ')
    };

    exportColumn = !isTagLibraryAccess ? { ...exportColumn, 'Scope (Tags)': legislation.tags?.map((item) => item.tagName).join(', ') } : exportColumn;

    exportColumn = {
      ...exportColumn,
      'Scope (Location)': legislation.locations?.map((item) => item.name).join(', '),
      'Scope (Departments)': legislation.departments?.map((item) => item.label).join(', '),
      'Scope (Regions)': legislation.regions?.map((item) => item.name).join(', ')
    };

    exportedFile.push(exportColumn);
  });
  const file = createXls(exportedFile);
  downloadFile(file, 'export-legislation.xlsx');
};
