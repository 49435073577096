import React, { useCallback, useState } from 'react';
import { TransactionType } from '@rio/rio-types';
import { Checkbox, Row, TextInput, PropertySheet } from 'rio-ui-components';
import { Controller, Control } from 'react-hook-form';
import { NumberInput } from '../UI';
import { useCurrentAccountId } from '../../hooks';
import { DataForm } from '../DataForm';
import { LabelContainerStyled, LabelStyled, SectionStyled, SubHeader } from '../DataForm/DataFormStyles';
import { CountrySelect } from '../CountrySelect';
import { LocationSelect } from '../LocationSelect';
import { SupplierSelector } from '../SuppliersSelector';
import { TariffSelect } from '../TariffSelect';
import { CurrencySelect } from '../CurrencySelect';
import { Option } from '../../types';

type Reference = Partial<{
  invoiceNumber: string | number;
  evidenceReference: string | number;
  otherReference: string | number;
}>;

type Data = {
  location: Option;
  country: Option;
  dataProvider: Option;
  electricityTariff: Option;
  currency: Option;
  totalCost: string;
  hotelNights: string;
  homeWorkingDays: string;
  notes: string;
  reference: string;
  invoiceNumber: string;
  evidenceReference: string;
  otherReference: string;
};

type FormData = Partial<{
  data: Data;
  reference: Reference;
}>;

interface FormProps {
  setValue: (field: string, value: object) => void;
  getValues: (name?: string) => FormData;
  control: Control;
  trigger: () => void;
  register: (field: string) => void;
  watch: (fields: string[]) => boolean[];
}

interface HotelStaysDataFormProps {
  form: FormProps;
  onSubmit: (data: object) => void;
  dataSection: TransactionType;
  children: React.ReactNode;
}

type SubmitProps = {
  data: object;
  reference: object;
};

const quantityRequiredMessage = 'Either Hotel nights either Home working days are required';

export function HotelStaysDataForm({ form, onSubmit, children }: HotelStaysDataFormProps) {
  const { control, setValue } = form;

  const accountId = useCurrentAccountId();

  const [isReferenceDataRequired, setIsReferenceDataRequired] = useState(false);

  const toggleIsReferenceDataRequired = () => setIsReferenceDataRequired((v) => !v);

  const values = form.getValues();

  const handleOnSubmit = useCallback(
    ({ data, ...others }: SubmitProps) => {
      const formData = {
        ...others,
        data,
      };
      onSubmit(formData);
    },
    [onSubmit]
  );

  return (
    <DataForm onSubmit={handleOnSubmit} form={form} showEndDate>
      <Row>
        <SubHeader>Please enter the following transactional hotel stays data:</SubHeader>
      </Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Location">
          <Controller
            name="data.location"
            render={({ field, fieldState }) => (
              <LocationSelect
                value={field.value}
                onChange={(e) => setValue('data.location', e.target.select)}
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Location is required' }}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Hotel stay / Home working country">
          <Controller
            name="data.country"
            render={({ field, fieldState }) => (
              <CountrySelect
                value={field.value}
                onChange={(e) => setValue('data.country', e.target.select)}
                error={fieldState.error?.message}
              />
            )}
            rules={{ required: 'Country is required' }}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Hotel nights">
          <Controller
            name="data.hotelNights"
            render={({ field, fieldState }) => <NumberInput {...field} error={fieldState.error?.message} />}
            rules={{ required: !values.data?.homeWorkingDays ? quantityRequiredMessage : false }}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Home working days">
          <Controller
            name="data.homeWorkingDays"
            render={({ field, fieldState }) => <NumberInput {...field} error={fieldState.error?.message} />}
            rules={{ required: !values.data?.hotelNights ? quantityRequiredMessage : false }}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Data provider">
          <Controller
            name="data.dataProvider"
            render={({ field, fieldState }) => (
              <SupplierSelector
                accountId={accountId}
                value={field.value}
                onChange={(e) => setValue('data.dataProvider', e.target.select)}
                error={fieldState.error?.message}
                utility={TransactionType.Hotelstays}
              />
            )}
            rules={{ required: 'Data provider is required' }}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Electricity tariff (optional)">
          <Controller
            name="data.electricityTariff"
            render={({ field }) => (
              <TariffSelect
                accountId={accountId}
                value={field.value}
                onChange={(e) => setValue('data.electricityTariff', e.target.select)}
              />
            )}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Total cost (optional)">
          <Controller
            name="data.totalCost"
            render={({ field, fieldState }) => <NumberInput {...field} error={fieldState.error?.message} />}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Currency (optional)">
          <Controller
            name="data.currency"
            render={({ field }) => (
              <CurrencySelect value={field.value} onChange={(e) => setValue('data.currency', e.target.select)} />
            )}
            control={control}
          />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column label="Notes (optional)">
          <Controller name="data.notes" render={({ field }) => <TextInput {...field} />} control={control} />
        </PropertySheet.Column>
      </PropertySheet.Row>

      <PropertySheet.Row>
        <PropertySheet.Column span={6}>Do you want to add any references?</PropertySheet.Column>

        <PropertySheet.Column span={3}>
          <Row container item>
            <Checkbox size="md" checked={isReferenceDataRequired} onChange={toggleIsReferenceDataRequired} />

            <LabelContainerStyled>
              <LabelStyled>Yes</LabelStyled>
            </LabelContainerStyled>
          </Row>
        </PropertySheet.Column>
        <PropertySheet.Column span={3}>
          <Row container item>
            <Checkbox size="md" checked={!isReferenceDataRequired} onChange={toggleIsReferenceDataRequired} />

            <LabelStyled>No</LabelStyled>
          </Row>
        </PropertySheet.Column>
      </PropertySheet.Row>

      <SectionStyled isVisible={isReferenceDataRequired}>
        <PropertySheet.Row>
          <PropertySheet.Column label="Reference (optional)">
            <Controller name="data.reference" render={({ field }) => <TextInput {...field} />} control={control} />
          </PropertySheet.Column>
        </PropertySheet.Row>

        <PropertySheet.Row>
          <PropertySheet.Column label="Invoice number (optional)">
            <Controller name="data.invoiceNumber" render={({ field }) => <TextInput {...field} />} control={control} />
          </PropertySheet.Column>
        </PropertySheet.Row>

        <PropertySheet.Row>
          <PropertySheet.Column label="Evidence reference (optional)">
            <Controller
              name="data.evidenceReference"
              render={({ field }) => <TextInput {...field} />}
              control={control}
            />
          </PropertySheet.Column>
        </PropertySheet.Row>

        <PropertySheet.Row>
          <PropertySheet.Column label="Other reference (optional)">
            <Controller name="data.otherReference" render={({ field }) => <TextInput {...field} />} control={control} />
          </PropertySheet.Column>
        </PropertySheet.Row>
      </SectionStyled>

      {children}
    </DataForm>
  );
}
