import { Model } from 'survey-core';
import { ReceivedSurvey, SurveySubmissionFeedbackStatus } from '@rio/rio-types';
import { SurveyLayout } from './SurveyLayout';
import 'survey-core/defaultV2.min.css';

type SubmissionOverviewProps = {
  model: Model | null;
  onDismiss: () => void;
  expanded: boolean;
  survey: ReceivedSurvey;
  children?: React.ReactNode;
  sendFeedback: (status: SurveySubmissionFeedbackStatus, feedback: string | null) => void;
};

export const SubmissionOverview = ({ model, expanded, onDismiss, survey, sendFeedback }: SubmissionOverviewProps) => {
  return (
    <SurveyLayout
      readonly
      model={model}
      expanded={expanded}
      onDismiss={onDismiss}
      survey={survey}
      sendFeedback={sendFeedback}
    />
  );
};
