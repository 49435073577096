import { ReceivedSurvey } from '@rio/rio-types';
import { Modal, Row } from 'rio-ui-components';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { InfoButton } from './InfoButton';

interface ReviewFeedbackPanelProps {
  survey: ReceivedSurvey;
}

const SectionButtons = styled(Row)`
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.geometry.md.spacing};
`;
const ViewButton = styled(InfoButton)`
  width: auto;
`;
const FeedbackArticle = styled.article`
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const Title = styled.h1`
  margin-bottom: ${(props) => props.theme.geometry.lg.spacing};
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.light.text};
`;
const Feedback = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.text.light.text};
`;

export function ReviewFeedbackButton({ survey }: ReviewFeedbackPanelProps) {
  const [modalShown, setModalShown] = useState(false);

  const toggleModal = useCallback(() => setModalShown((shown) => !shown), [setModalShown]);

  return (
    <SectionButtons container>
      <ViewButton onClick={toggleModal}>View comments from {survey.owner.name}</ViewButton>

      {modalShown && (
        <Modal show size="md" height="auto" onDismiss={toggleModal}>
          <FeedbackArticle>
            <Title>Comments from {survey.owner.name}</Title>
            <Feedback>{survey.submission?.feedback}</Feedback>
          </FeedbackArticle>
        </Modal>
      )}
    </SectionButtons>
  );
}
