import { SummarizeStatus } from '@rio/rio-types';
import { Icon } from 'rio-ui-components';
import { Nullable } from '../../../types';
import styled from 'styled-components';

const IconStyled = styled(Icon)`
  > path {
    ${({ summarizeStatus }) =>
      summarizeStatus === SummarizeStatus.Processing ? 'animation: eye 1s ease-out infinite;' : ''}
    ${({ summarizeStatus }) =>
      summarizeStatus === SummarizeStatus.Processing ? '-webkit-animation: eye 1s ease-out infinite;' : ''}
    ${({ summarizeStatus }) =>
      summarizeStatus === SummarizeStatus.Processing ? '-moz-animation: eye 1s ease-out infinite;' : ''}
  }

  @-webkit-keyframes eye {
    0%,
    10% {
      fill: rgba(197, 204, 215, 56);
    }
    11%,
    20% {
      fill: rgba(187, 194, 215, 56);
    }
    21%,
    30% {
      fill: rgba(167, 184, 215, 56);
    }
    31%,
    40% {
      fill: rgba(157, 174, 215, 56);
    }
    41%,
    50% {
      fill: rgba(147, 164, 215, 56);
    }
    51%,
    60% {
      fill: rgba(137, 154, 215, 56);
    }
    61%,
    70% {
      fill: rgba(127, 144, 215, 56);
    }
    71%,
    80% {
      fill: rgba(137, 154, 215, 56);
    }
    81%,
    90% {
      fill: rgba(147, 164, 215, 56);
    }
    91%,
    100% {
      fill: rgba(157, 174, 215, 56);
    }
  }
  @-moz-keyframes eye {
    0%,
    10% {
      fill: rgba(197, 204, 215, 56);
    }
    11%,
    20% {
      fill: rgba(187, 194, 215, 56);
    }
    21%,
    30% {
      fill: rgba(167, 184, 215, 56);
    }
    31%,
    40% {
      fill: rgba(157, 174, 215, 56);
    }
    41%,
    50% {
      fill: rgba(147, 164, 215, 56);
    }
    51%,
    60% {
      fill: rgba(137, 154, 215, 56);
    }
    61%,
    70% {
      fill: rgba(127, 144, 215, 56);
    }
    71%,
    80% {
      fill: rgba(137, 154, 215, 56);
    }
    81%,
    90% {
      fill: rgba(147, 164, 215, 56);
    }
    91%,
    100% {
      fill: rgba(157, 174, 215, 56);
    }
  }
  @keyframes eye {
    0%,
    10% {
      fill: rgba(197, 204, 215, 56);
    }
    11%,
    20% {
      fill: rgba(187, 194, 215, 56);
    }
    21%,
    30% {
      fill: rgba(167, 184, 215, 56);
    }
    31%,
    40% {
      fill: rgba(157, 174, 215, 56);
    }
    41%,
    50% {
      fill: rgba(147, 164, 215, 56);
    }
    51%,
    60% {
      fill: rgba(137, 154, 215, 56);
    }
    61%,
    70% {
      fill: rgba(127, 144, 215, 56);
    }
    71%,
    80% {
      fill: rgba(137, 154, 215, 56);
    }
    81%,
    90% {
      fill: rgba(147, 164, 215, 56);
    }
    91%,
    100% {
      fill: rgba(157, 174, 215, 56);
    }
  }
`;

interface SummarizeButtonProps {
  showDeleteButton: boolean;
  isCompleted: boolean;
  duplicatesNumber: number;
  approved: boolean;
  summarizeStatus: SummarizeStatus;
  onDeleteClick: () => void;
  handleQuickViewClick: () => void;
}

function getData(
  summarizeStatus: SummarizeStatus,
  duplicatesNumber: number,
  approved: boolean,
  handleQuickViewClick: () => void
) {
  let title = '';
  let color = '';
  let cursor = 'pointer';
  let onClick: Nullable<() => void> = handleQuickViewClick;

  switch (summarizeStatus) {
    case SummarizeStatus.Processing:
      title = 'Processing summary...';
      color = '#0094c9';
      cursor = 'progress';
      onClick = null;
      break;
    case SummarizeStatus.Completed:
      title = 'Quick View';
      color = duplicatesNumber && !approved ? '#ffca83' : '#47d3c8';
      break;
    case SummarizeStatus.Failed:
      title = 'Summary failed with errors';
      color = '#c9606c';
      cursor = 'not-allowed';
      onClick = null;
      break;
    case SummarizeStatus.NotStarted:
    default:
      title = 'Prepare summary';
      color = '#0094c9';
      break;
  }

  return {
    title,
    color,
    cursor,
    onClick
  };
}

export function SummarizeButton({
  showDeleteButton,
  isCompleted,
  onDeleteClick,
  duplicatesNumber,
  approved,
  summarizeStatus,
  handleQuickViewClick
}: SummarizeButtonProps) {
  const { title, color, cursor, onClick } = getData(summarizeStatus, duplicatesNumber, approved, handleQuickViewClick);

  return (
    <>
      {showDeleteButton && <Icon icon="times" size="md" color="#83838e" cursor="pointer" onClick={onDeleteClick} />}
      {isCompleted && (
        <IconStyled
          icon="eye"
          title={title}
          size="md"
          color={color}
          cursor={cursor}
          onClick={onClick}
          summarizeStatus={summarizeStatus}
        />
      )}
    </>
  );
}
