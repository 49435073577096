import { styled, Text } from '@rio/ui-components';
import { formatDate } from '~/utils';

export interface DueDateProps {
  deadlineDate: Date;
  color: string;
}

export interface StyledDueDateProps {
  color: string;
}

const StyledDueDate = styled(Text)<StyledDueDateProps>`
  color: ${(props) => props.color};
  white-space: nowrap;
`;

export const DueDate = ({ deadlineDate, color }: DueDateProps) => (
  <StyledDueDate color={color} typescale="body" size="medium">
    Due {formatDate(deadlineDate, 'LL')}
  </StyledDueDate>
);
