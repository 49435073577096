import { useState } from 'react';
import { Page } from '@rio/ui-components';
import { GridApi } from 'ag-grid-community';
import { useQuery } from '@apollo/client';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useCurrentAccountId, useSearch } from '~/hooks';
import { GET_AVAILABLE_MANAGEMENT_SYSTEMS_AND_DOCUMENTS } from '../../../GovernanceContainer/ManagementSystems/Overview/index.queries';
import { Folder } from '../../types';
import { getAllFilters } from '../../utils';
import { useGetDocuments } from '../../useGetDocuments';
import { DocumentExplorer } from '../../DocumentExplorer/v2';
import { createBaseBreadcrumbs, mapManagementSystemsToFolders, CATEGORY, LIBRARY } from '../utils';

export const ManagementSystems = injectIntl(function ManagementSystems({ intl }) {
  const accountId = useCurrentAccountId();
  const variables = {
    accountId,
  };
  const { data } = useQuery(GET_AVAILABLE_MANAGEMENT_SYSTEMS_AND_DOCUMENTS, {
    variables,
  });
  const { pathname } = useLocation();
  const managementSystems = data?.getAvailableManagementSystems || [];
  const folders = mapManagementSystemsToFolders(managementSystems, pathname);

  const [gridApi, setGridApi] = useState<GridApi>();
  const breadcrumbs = createBaseBreadcrumbs(intl).map(({ to, title }) => ({ to, label: title }));
  const [searchValue, setSearchValue, searchedFolders] = useSearch(folders, ['name']);

  const { documentsExist, error, loading } = useGetDocuments(
    {
      accountId,
      filters: getAllFilters(searchValue || ''),
    },
    !searchValue
  );

  return (
    <Page
      title={{
        crumbs: breadcrumbs,
        content: 'Management Systems',
      }}
      loading={loading && !documentsExist}
      error={error}
    >
      <DocumentExplorer
        folders={searchedFolders as Folder[]}
        documentsExist={documentsExist}
        onSearch={setSearchValue}
        gridApi={gridApi}
        setGridApi={setGridApi}
        searchValue={searchValue as string}
        searchPlaceholder="Search management systems"
        library={LIBRARY}
        category={CATEGORY}
      />
    </Page>
  );
});
