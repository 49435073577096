import React, { useState } from 'react';
import { Row, Col, Text, Icon } from 'rio-ui-components';
import styled from 'styled-components';
import { remove } from 'lodash';
import { usePermissions } from '../../../hooks/usePermissions';

const RowHeader = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  background: ${(p) => p.theme.colors.overlay.light.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
  border-top: 1px solid ${(p) => p.theme.colors.text.light.background};
  flex: 0 0 auto;
`;

const RowContent = styled(Row)`
  padding: ${(p) => `${p.theme.geometry.sm.spacing} ${p.theme.geometry.md.spacing}`};
  border-bottom: 1px solid ${(p) => p.theme.colors.text.light.background};
`;

const ColStyled = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
`;

const ColStyledEdit = styled(Col)`
  padding: ${(p) => p.theme.geometry.md.profile.padding};
  justify-content: center;
  display: flex;
`;

const EditLinkStyled = styled.a`
  display: block;
  color: ${(props) => props.theme.colors.text.normal.background};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.text.dark.background};
  }
`;

const ReadMore = styled.span`
  cursor: pointer;
`;

const ArrowUp = styled(Icon)`
  cursor: pointer;
  margin: 0 0 0 5px;
`;

const maxLocationLength = 90;

const TagRow = ({ tags, onUpdateTag }) => {
  const [state, setState] = useState({ openLocation: [] });
  const permissions = usePermissions();

  const isSystemAdmin = permissions.tag.includes('getAll');

  const collapsedShow = (tag) => setState({ ...state, openLocation: [...state.openLocation, tag.id] });

  const collapsedHide = (tag) => setState({ ...state, openLocation: remove(state.openLocation, tag.id) });

  const getLocations = (tag) => {
    const locations = tag.locations.map((item) => item.name).join(', ');
    const collapsedText = state.openLocation.includes(tag.id) ? (
      <>
        {locations}
        <ArrowUp onClick={() => collapsedHide(tag)} icon="arrow-up" />
      </>
    ) : (
      <>
        {locations.substr(0, maxLocationLength)}
        <ReadMore onClick={() => collapsedShow(tag)}>...</ReadMore>
      </>
    );

    return locations.length < maxLocationLength ? <Text>{locations}</Text> : <Text>{collapsedText}</Text>;
  };

  return (
    <>
      <RowHeader container align="between">
        <ColStyled span={3}>
          <Text weight="bold">Tag</Text>
        </ColStyled>
        <ColStyled span={isSystemAdmin ? 10 : 20}>
          <Text weight="bold">Location</Text>
        </ColStyled>
        {isSystemAdmin && (
          <ColStyled span={10}>
            <Text weight="bold">Legislation Tag</Text>
          </ColStyled>
        )}
        <ColStyled span={1} />
      </RowHeader>

      {tags.map((tag) => {
        const locations = getLocations(tag);
        return (
          <RowContent container align="between" key={tag.id}>
            <ColStyled span={3}>
              <Text>{tag.tagName}</Text>
            </ColStyled>
            <ColStyled span={isSystemAdmin ? 10 : 20}>{locations}</ColStyled>
            {isSystemAdmin && <ColStyled span={10}>{tag.legislationTag ? 'Yes' : 'No'}</ColStyled>}
            <ColStyledEdit span={1}>
              {(!tag.isSystemLevelTag || permissions.tag.includes('editAll')) && (
                <EditLinkStyled onClick={() => onUpdateTag(tag)}>
                  <Icon icon="pencil-alt" />
                </EditLinkStyled>
              )}
            </ColStyledEdit>
          </RowContent>
        );
      })}
    </>
  );
};

export default TagRow;
