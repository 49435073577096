const learnTourStepsHome = () => [
  {
    title: 'learnTour.home.item1.title',
    content: 'learnTour.home.item1.content',
    placement: 'center',
    target: 'body',
    textAlign: 'left',
    disableBeacon: true
  },
  {
    title: `Get studying`,
    content: 'Upgrade your knowledge with our accredited learning',
    placement: 'auto',
    target: '[name="Sidebar__NavLink--Learn-NavLinkStyled"]',
    textAlign: 'left',
    floaterProps: { disableAnimation: true },
    disableBeacon: true
  }
];

const learnTourStepsExplore = [
  {
    title: 'learnTour.explore.item1.title',
    content: 'learnTour.explore.item1.content',
    placement: 'right',
    target: '[name="Explore-ViewAll"]',
    textAlign: 'left',
    floaterProps: { disableAnimation: true },
    disableBeacon: true
  },
  {
    title: 'learnTour.explore.item2.title',
    content: 'learnTour.explore.item2.content',
    placement: 'auto',
    target: '[name^="CoursesBanner__Link__"]',
    textAlign: 'left',
    floaterProps: { disableAnimation: true },
    disableBeacon: true
  }
];

const learnTourStepsPreview = [
  {
    title: 'learnTour.preview.item1.title',
    content: 'learnTour.preview.item1.content',
    placement: 'auto',
    target: '[name="StartCourse-Button"]',
    textAlign: 'left',
    floaterProps: { disableAnimation: true },
    disableBeacon: true
  }
];

export { learnTourStepsHome, learnTourStepsExplore, learnTourStepsPreview };
