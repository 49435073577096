import React from 'react';
import styled from 'styled-components';
import { Heading, Row, Col, Button } from 'rio-ui-components';

export const isDirectActivityQuestionnaire = {
  ELECTRICITY: {
    header: 'Does this electricity data relate to the account holder’s direct activities?',
    text: `This question allows Rio to determine which carbon emission scope your data relates to.
Select 'yes' if this data relates to electricity purchased and consumed directly by your company for its own buildings and operations (scope 2 and 3).
Select 'no' if the data relates to activities of contractors or other third parties (scope 3).`,
  },
  GAS: {
    header: 'Does this gas data relate to the account holder’s direct activities?',
    text: `This question allows Rio to determine which carbon emission scope your data relates to.
Select 'yes' if the data relates to gas purchased and consumed directly by your company for its own buildings and operations (scope 1 and 3).
Select 'no' if the data relates to activities of contractors or other third parties (scope 3).`,
  },
  TRANSPORT: {
    header: 'Does this data relate to the account holder’s owned and controlled vehicles?',
    text: `This question allows Rio to determine which carbon emission scope your data relates to.
Select 'yes' if the data relates to company-owned vehicles (scope 1, 2, and 3).
Select 'no' if the data relates to third party operated and/or owned vehicles, or commuting (scope 3)`,
  },
  FUEL: {
    header: 'Does this data relate to the account holder’s direct activities?',
    text: `This question allows Rio to determine which carbon emission scope your data relates to. 
Select 'yes' if the data relates to fuels purchased and consumed directly by your company for its own buildings, operations and transport (scope 1 and 3). 
Select 'no' if the data relates to activities of contractors or other third parties (scope 3).`,
  },
};
const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  padding: ${(props) => props.theme.geometry.md.spacing};
`;
const ButtonsContainer = styled(Row)`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
`;
const ColStyled = styled(Col)`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  min-width: 200px;
`;
const HeadingStyled = styled(Heading)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.geometry.sm.spacing};
`;

const IsDirectActivityModal = ({ type, onConfirm }) => (
  <CenterContainer>
    <HeadingStyled size="lg">{isDirectActivityQuestionnaire[type].header}</HeadingStyled>
    <HeadingStyled size="md">{isDirectActivityQuestionnaire[type].text}</HeadingStyled>
    <ButtonsContainer container>
      <ColStyled span={4}>
        <ButtonStyled name="create-energyMeter__button--create" color="success" onClick={() => onConfirm(true)}>
          Yes
        </ButtonStyled>
      </ColStyled>
      <ColStyled span={4}>
        <ButtonStyled name="create-energyMeter__button--create" color="info" onClick={() => onConfirm(false)}>
          No
        </ButtonStyled>
      </ColStyled>
    </ButtonsContainer>
  </CenterContainer>
);

export default IsDirectActivityModal;
