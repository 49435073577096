import { useApolloClient, useQuery } from '@apollo/client';
import { LearnContentType } from '@rio/rio-types';
import React, { useState } from 'react';
import { Col, Search, Button, Modal, Notification, Tabs, Tab } from 'rio-ui-components';
import styled from 'styled-components';
import { GET_ALL_COURSES_AND_LEARN_BUNDLES, GET_AVAILABLE_COURSES } from './index.queries';
import ContainerHeader from '../../../components/ContainerHeader';
import UploadContent from './UploadContent';
import AddLearnBundle from './AddLearnBundle';
import { enhanceMetadata } from './utils';
import NotificationBanner from '../../../components/NotificationBanner';
import { useCurrentAccountId } from '../../../hooks/useCurrentAccountId';
import { getPresignedUrl, uploadFiles, saveMetadata, getSubscriptions, addSubscription } from './api';
import NoResults from '../../../components/NoResults';
import { usePermissions } from '../../../hooks/usePermissions';
import AccountSelector from '../../../components/AccountSelector';
import { LoadFailed } from '../../../components/Errors';
import ContainerLoadingIndicator from '../../../components/ContainerLoadingIndicator';
import { CoursesGrid } from './CoursesGrid';
import { BundlesGrid } from './BundlesGrid';

export const TabsStyled = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 60px;
  }
`;

const ButtonStyled = styled(Button)`
  margin-left: ${(props) => props.theme.geometry.sm.spacing};
`;

const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.xs.spacing};
  border-bottom: 1px solid ${(props) => props.theme.colors.overlay.normal.background};
`;

const defaultState = {
  upload: false,
  notification: {
    show: false,
    message: '',
    color: ''
  },
  edit: false,
  test: true,
  editedCourse: { id: '', name: '', type: '', metaData: { link: '', thumbnail: '', description: '' } },
  currentUpload: null,
  uploadCount: 0,
  error: false,
  selectedButton: null,
  updateBundle: 0,
  editBundle: null
};

function useData(isSystemAdmin, accountId) {
  const variables = {
    accountId,
    pageNumber: 1
  };

  if (!isSystemAdmin) {
    variables.ownedCourses = true;
  }

  return useQuery(isSystemAdmin ? GET_ALL_COURSES_AND_LEARN_BUNDLES : GET_AVAILABLE_COURSES, {
    notifyOnNetworkStatusChange: true,
    variables
  });
}

function ConfigurationLearnUploadContainer() {
  const [state, setState] = useState(defaultState);
  const [courseSearch, setCourseSearch] = useState('');
  const [bundleSearch, setBundleSearch] = useState('');
  const [tab, setTab] = useState(0);
  const [isCoursesData, setIsCoursesData] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('SYSTEM_LEVEL');
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const permissions = usePermissions();

  const contentTabs = [{ label: 'Bundles' }, { label: 'Courses' }];

  const isSystemAdmin = permissions.learn.includes('createCourseAll');

  const dismissModals = () => {
    return setState((prevState) => ({
      ...prevState,
      upload: false,
      edit: false
    }));
  };
  const notify = (notification) => {
    setState((prevState) => ({ ...prevState, notification }));
  };

  const uploadContent = async (contentData) => {
    const { edit, editedCourse } = state;
    try {
      const contentWasSelected = contentData.content instanceof File || contentData.content instanceof FileList;
      const thumbnailWasSelected = contentData.thumbnail instanceof File;
      const uploadRequired = edit ? contentWasSelected || thumbnailWasSelected : true;
      let contentPath = editedCourse.metaData.link;
      if (uploadRequired) {
        setState((prevState) => ({
          ...prevState,
          currentUpload: { contentData, status: 'In-progress', showNotification: true, color: 'primary' }
        }));
        const { data: presignedUrl } = contentWasSelected ? await getPresignedUrl(contentData, client, accountId) : {};
        const metadata = enhanceMetadata(contentData, presignedUrl);
        await uploadFiles(metadata, client, accountId);
        if (contentWasSelected) {
          contentPath = metadata.contentPath;
        }
      }
      const accountSpecificId =
        selectedAccount === 'SYSTEM_LEVEL'
          ? permissions.learn.includes('createCourseAll')
            ? null
            : accountId
          : selectedAccount;

      const openIframe =
        contentData.type === LearnContentType.Url
          ? contentData.openIframe === undefined
            ? true
            : contentData.openIframe
          : undefined;

      await saveMetadata(
        {
          contentPath: contentData.type !== LearnContentType.Url ? contentPath : undefined,
          accountId: accountSpecificId,
          id: contentData.id,
          title: contentData.title,
          description: contentData.description,
          cpd: contentData.cpd,
          passScore: contentData.passScore,
          type: contentData.type,
          categories: contentData.categories,
          assessmentId: contentData.assessmentId,
          reactionId: contentData.reactionId,
          isEngage: contentData.isEngage,
          url: contentData.type === LearnContentType.Url ? contentData.url : undefined,
          openIframe
        },
        client
      );
      if (contentData.isAccountSpecific) {
        try {
          const { subscriptions, bundleShow, bundleHidden, mandatoryBundleId } = await getSubscriptions(
            accountId,
            client
          );
          await addSubscription(
            {
              subscriptions: [...subscriptions, contentData.id],
              bundleShow,
              bundleHidden,
              mandatoryBundleId,
              accountId
            },
            client
          );
        } catch (error) {
          console.log('ERROR', error);
        }
      }
      setState((prevState) => ({
        ...prevState,
        currentUpload: { ...state.currentUpload, status: 'Complete', color: 'success' },
        uploadCount: state.uploadCount + 1
      }));
      notify({
        show: true,
        message: edit ? 'Your course has been updated!' : 'Your upload has finished!',
        color: 'success'
      });
      refetch();
    } catch (error) {
      notify({
        show: true,
        message: edit ? 'Your course failed to update' : 'Your course failed to upload',
        color: 'danger'
      });
      setState((prevState) => ({
        ...prevState,
        error,
        currentUpload: { ...state.currentUpload, status: 'Failed', color: 'danger' }
      }));
    }
  };

  const onSuccess = () => {
    dismissModals();
    refetch();
    notify({
      show: true,
      message: `Your upload will continue in the background. Check back shortly to see it`,
      color: 'primary'
    });
  };

  const onNotificationClose = () => {
    setState((prevState) => ({
      ...prevState,
      notification: { show: false, message: '' }
    }));
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    isCoursesData ? setCourseSearch(e.target.value.toLowerCase()) : setBundleSearch(e.target.value.toLowerCase());
  };

  const uploadModal = () => {
    setState((prevState) => ({
      ...prevState,
      upload: true,
      selectedButton: 'LearnContent',
      editedCourse: {
        id: '',
        name: '',
        type: '',
        metaData: { link: '', thumbnail: '', description: '', categories: [] }
      }
    }));
  };

  const uploadBundleModal = () => {
    setState((prevState) => ({
      ...prevState,
      upload: true,
      editBundle: null,
      selectedButton: 'LearnBundle'
    }));
  };

  const hideCurrentUploadNotificationBanner = () => {
    setState((prevState) => ({
      ...prevState,
      currentUpload: {
        ...state.currentUpload,
        showNotification: false
      }
    }));
  };

  const editModal = (id) => {
    setState((prevState) => ({
      ...prevState,
      edit: true,
      editedCourse: courses?.find((course) => course.id === id),
      selectedButton: 'LearnContent'
    }));
  };

  const onSuccessBundle = () => {
    dismissModals();
    refetch();
    setState((prevState) => ({
      ...prevState
    }));
    notify({
      show: true,
      message: state.edit ? `The bundle was updated` : `The bundle was added`,
      color: 'primary'
    });
  };

  const editBundleModal = (id) => {
    setState((prevState) => ({
      ...prevState,
      edit: true,
      editBundle: bundles?.find((bundle) => bundle.id === id),
      selectedButton: 'LearnBundle'
    }));
  };

  const getQueryData = (data) => {
    let allCourses;
    let allBundles;
    if (isSystemAdmin) {
      allCourses = data ? data.getAllCourses.data : null;
      allBundles = data ? data.getAllLearnBundles : null;
      return { allBundles, allCourses };
    } else {
      allCourses = data ? data.getAvailableCourses.data : null;
      allBundles = data ? data.getAvailableCourses.learnBundleList : null;
      return { allBundles, allCourses };
    }
  };

  const { data, loading, error, refetch, networkStatus } = useData(isSystemAdmin, accountId);

  const queriedData = data ? getQueryData(data) : { allCourses: [], allBundles: [] };

  const courses = queriedData?.allCourses?.filter((course) => course.name.toLowerCase().includes(courseSearch));
  const bundles = queriedData?.allBundles?.filter((bundle) => bundle.title.toLowerCase().includes(bundleSearch));

  return (
    <Col name="ConfigurationLearnUploadContainer" container fullHeight>
      {(state.upload || state.edit) && (
        <Modal size="lg" show name="ConfigurationLearnUploadContainer--create" dismissable onDismiss={dismissModals}>
          {state.selectedButton === 'LearnBundle' ? (
            <AddLearnBundle
              client={client}
              bundle={state.editBundle}
              loading={loading}
              onSuccess={onSuccessBundle}
              edit={state.edit}
              courses={courses}
            />
          ) : (
            <UploadContent
              course={state.editedCourse}
              onSuccess={state.upload ? onSuccess : dismissModals}
              uploadContent={uploadContent}
              edit={state.edit}
            />
          )}
        </Modal>
      )}

      {state.notification.show && (
        <Notification
          id={`ConfigurationLearnUpload__notification`}
          name={`ConfigurationLearnUpload__notification`}
          show
          color={state.notification.color}
          onClose={onNotificationClose}
        >
          {state.notification.message}
        </Notification>
      )}

      <ContainerHeader
        name="ConfigurationLearnUploadContainer__Controls"
        icon="graduation-cap"
        iconColor="primary"
        title="Engage Content"
      >
        <Col container item>
          <Search
            name="ConfigurationLearnUploadContainer__Controls__Search"
            value={isCoursesData ? courseSearch : bundleSearch}
            onChange={handleSearchChange}
            hideButton
          />
        </Col>
        {permissions.learn.find((action) => action.startsWith('createBundle')) && (
          <ButtonStyled
            name="ConfigurationLearnUploadContainer__Controls__Button--create"
            size="md"
            color="primary"
            inline
            onClick={uploadBundleModal}
          >
            + Add bundle
          </ButtonStyled>
        )}
        <ButtonStyled
          name="ConfigurationLearnUploadContainer__Controls__Button--create"
          size="md"
          color="primary"
          inline
          onClick={uploadModal}
        >
          + Add Engage Content
        </ButtonStyled>
      </ContainerHeader>

      {isSystemAdmin && (
        <>
          <AccountSelectorContainer>
            <AccountSelector
              value={selectedAccount}
              onChange={({ id }) => setSelectedAccount(id)}
              customOption={{ value: 'SYSTEM_LEVEL', label: 'System Level' }}
            />
          </AccountSelectorContainer>
          <TabsStyled variant="fullWidth" value={tab} onChange={(_, tab) => setTab(tab)}>
            {contentTabs.map(({ label }) => (
              <Tab key={label} label={label} onClick={() => setIsCoursesData(label === 'Courses' ? true : false)} />
            ))}
          </TabsStyled>
        </>
      )}

      {state.currentUpload && state.currentUpload.showNotification && (
        <div>
          <NotificationBanner
            id="CurrentUpload__NotificationBanner"
            name="CurrentUpload__NotificationBanner"
            icon="upload"
            dismissable
            onDismiss={hideCurrentUploadNotificationBanner}
            title={`Uploading Files: ${
              state.currentUpload.contentData.content?.name || state.currentUpload.contentData.thumbnail.name
            }`}
            body={`Upload Status: ${state.currentUpload.status}`}
            color={state.currentUpload.color}
          />
        </div>
      )}

      {((loading && networkStatus !== 3) || networkStatus === 4) && (
        <ContainerLoadingIndicator name="CoursePreview__Loading" />
      )}

      {!loading &&
        isSystemAdmin &&
        selectedAccount === 'SYSTEM_LEVEL' &&
        isCoursesData &&
        (error ? (
          <LoadFailed error={error} retry={refetch} />
        ) : courses.length ? (
          <CoursesGrid onEdit={editModal} courses={courses} />
        ) : (
          <NoResults name="LearnUploads__NoResults" title="There are no results for this search." />
        ))}

      {!loading &&
        isSystemAdmin &&
        selectedAccount === 'SYSTEM_LEVEL' &&
        !isCoursesData &&
        (error ? (
          <LoadFailed error={error} retry={refetch} />
        ) : bundles.length ? (
          <BundlesGrid onEdit={editBundleModal} bundles={bundles} />
        ) : (
          <NoResults name="LearnUploads__NoResults" title="There are no results for this search." />
        ))}

      {!loading &&
        !isSystemAdmin &&
        (error ? (
          <LoadFailed error={error} retry={refetch} />
        ) : courses.length ? (
          <CoursesGrid onEdit={editModal} courses={courses} />
        ) : (
          <NoResults name="LearnUploads__NoResults" title="There are no results for this search." />
        ))}
    </Col>
  );
}

export default ConfigurationLearnUploadContainer;
