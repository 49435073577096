import styled from 'styled-components';
import { AliasesContentGrid } from './AliasesContentGrid';
import { LoadFailed } from '../../../components/Errors';
import { useState, useCallback } from 'react';
import { Tab, TabPanel, Col, Modal, Row, Tabs, LoadingIndicator, Notification } from 'rio-ui-components';
import { ExportButton } from '../../../components/ExportButton';
import ContainerHeader from '../../../components/ContainerHeader';
import { GET_ALIASES_PAGE } from './index.queries';
import { useApolloClient } from '@apollo/client';
import AccountSelector from '../../../components/AccountSelector';
import { UpdateAlias } from './UpdateAlias';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetAliasesPage } from '../../../hooks';

export const TabsStyled = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 60px;
  }
`;
export const AccountSelectorContainer = styled.div`
  padding: ${(props) => props.theme.geometry.md.spacing};
  flex: 0 0 auto;
`;
export const Page = styled(Col)`
  display: flex;
  height: 100%;
`;
export const TabContentContainer = styled(Row)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;
export const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const defaultExportFileName = 'aliases';

export function TabContent({ error, loading, showUpdateModal, setGridApi, fetchRows }) {
  if (error) {
    return <LoadFailed error={error} retry={fetchRows} />;
  }
  if (loading) {
    return (
      <CenterContainer>
        <LoadingIndicator />
      </CenterContainer>
    );
  }
  return <AliasesContentGrid showModal={showUpdateModal} setGridApi={setGridApi} fetchRows={fetchRows} />;
}

export function ConfigurationAliases({ accountId, aliasTypes }) {
  const client = useApolloClient();
  const [gridApi, setGridApi] = useState({});
  const permissions = usePermissions();
  const [tab, setTab] = useState(0);
  const [state, setState] = useState({
    accountId,
    updateModal: { alias: null },
    notification: {
      show: false,
      color: 'success',
      message: '',
    },
  });
  const { loading, error } = useGetAliasesPage(
    accountId,
    aliasTypes[tab]?.aliasType,
    aliasTypes[tab]?.energyType || null
  );

  const refreshGrid = useCallback(
    () =>
      gridApi?.api?.refreshServerSide({
        purge: true,
      }),
    [gridApi]
  );

  const fetchRows = async (variables) => {
    const {
      data: {
        getAliasesPage: { rows, totalRows },
      },
    } = await client.query({
      query: GET_ALIASES_PAGE,
      variables: {
        ...variables,
        accountId: state.accountId,
        aliasType: aliasTypes[tab].aliasType,
        // null used to match nulls returned from graphql query as values to be used in further cache/other searches
        energyType: aliasTypes[tab].energyType || null,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

    return {
      rows,
      totalRows,
    };
  };

  const showUpdateModal = (alias) => {
    setState((prevState) => ({ ...prevState, updateModal: { alias } }));
  };

  const hideNotification = () => {
    setState((prevState) => ({ ...prevState, notification: { ...prevState.notification, show: false } }));
  };

  const showNotification = (notificationData) => {
    setState((prevState) => ({ ...prevState, notification: notificationData }));
  };

  const dismissModals = () => {
    setState((prevState) => {
      return {
        ...prevState,
        updateModal: { alias: null },
      };
    });
    refreshGrid();
  };

  const ShowTabContent = useCallback(
    ({ aliasType, energyType }) => {
      switch ((aliasType, energyType)) {
        case ('WASTESTREAM', undefined):
        case ('WASTEIN', undefined):
        case ('WASTEOUT', undefined):
        case ('CONTAINERTYPESIZE', undefined):
        case ('WASTETREATMENTPROCESS', undefined):
        case ('WASTECONTRACTOR', undefined):
        case ('WASTECARRIER', undefined):
        case ('METER', 'WATER'):
        case ('METER', 'GAS'):
        case ('METER', 'ELECTRICITY'):
        case ('TRANSPORTFUELTYPE', undefined):
        case ('TRANSPORT', undefined):
        case ('JOURNEYTARGET', undefined):
        case ('TRANSPORTSUBTYPE', undefined):
        case ('LOCATIONPOINT', undefined):
        case ('TARIFF', undefined):
        case ('FUELTYPE', undefined):
          return (
            <TabContent
              showUpdateModal={showUpdateModal}
              setGridApi={setGridApi}
              fetchRows={fetchRows}
              loading={loading}
              error={error}
            />
          );
        default:
          return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aliasTypes[tab].aliasType, aliasTypes[tab].energyType]
  );

  return (
    <Page>
      <ContainerHeader
        name="ConfigurationContractorsContainer__Controls"
        icon="link"
        iconColor="primary"
        title="Aliases"
      >
        <Col container item>
          <ExportButton
            fetchRows={fetchRows}
            gridApi={gridApi.api}
            columnApi={gridApi.columnApi}
            defaultExportFileName={defaultExportFileName}
            columnsToSkip={['Actions']}
            label="Export Aliases"
          />
        </Col>
      </ContainerHeader>
      {permissions.account.includes('getAll') && (
        <AccountSelectorContainer>
          <AccountSelector
            value={state.accountId}
            onChange={({ id }) =>
              setState((prevState) => ({
                ...prevState,
                accountId: id,
              }))
            }
          />
        </AccountSelectorContainer>
      )}
      <TabsStyled variant="fullWidth" value={tab} onChange={(_, t) => setTab(t)}>
        {aliasTypes.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </TabsStyled>
      <TabContentContainer>
        <TabPanel value={tab} index={tab}>
          <ShowTabContent aliasType={aliasTypes[tab].aliasType} energyType={aliasTypes[tab].energyType} />
        </TabPanel>
      </TabContentContainer>
      <Notification
        name="update-alias__notification"
        show={state.notification.show}
        color={state.notification.color}
        onClose={hideNotification}
      >
        {state.notification.message}
      </Notification>
      {state.updateModal.alias && (
        <Modal
          size="md"
          show
          name="ConfigurationAliasContainer__Modal--update"
          dismissable
          onDismiss={dismissModals}
          height="auto"
          maxHeight="90vh"
        >
          <UpdateAlias
            aliasData={{
              aliasId: state.updateModal.alias.id,
              typeId: state.updateModal.alias?.parentItem?.typeId,
              energyType: aliasTypes[tab].energyType,
              aliasType: aliasTypes[tab].aliasType,
              accountId: state.accountId,
            }}
            onComplete={dismissModals}
            triggerNotification={showNotification}
          />
        </Modal>
      )}
    </Page>
  );
}
