import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { LoadingIndicator } from 'rio-ui-components';
import styled from 'styled-components';

import SubscriptionSelector from './SubscriptionSelector';
import { GET_ACCOUNT_BY_ID } from './index.queries';
import { usePermissions } from '../../../../hooks';

const CenterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function DashboardSelectionContainer() {
  const { accountId } = useParams();
  const permissions = usePermissions();

  return (
    <>
      <Query query={GET_ACCOUNT_BY_ID} variables={{ id: accountId }}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return (
              <CenterContainer>
                <LoadingIndicator size="md" />
              </CenterContainer>
            );
          }
          return (
            <SubscriptionSelector
              accountId={accountId}
              permissions={permissions}
              title={`Dashboard Subscriptions - ${data.getAccountInfo.name}`}
            />
          );
        }}
      </Query>
    </>
  );
}
