import { useApolloClient } from '@apollo/client';
import { useCurrentAccountId, useNotification } from '~/hooks';
import { formatDate, getAgRowId, gridValueFormatter } from '~/utils';
import GET_MATERIALS_TRANSACTIONS_PAGE_FILTER from '../../../../graphql/queries/materials/GetMaterialsTransactionsPageFilter.query.graphql';
import { OccupationEndDateGridColumn } from '~/constants/columns/occupationEndDateColumn';

export function useMaterialsGridConfig() {
  const client = useApolloClient();
  const accountId = useCurrentAccountId();
  const { showNotification } = useNotification();

  const getFilterValues = async (params) => {
    try {
      const {
        data: { getMaterialsTransactionsPageFilter: values },
      } = await client.query({
        query: GET_MATERIALS_TRANSACTIONS_PAGE_FILTER,
        variables: {
          accountId,
          field: params.colDef.colId || params.colDef.field,
        },
      });
      params.success(values.map((item) => (item.value ? JSON.stringify(item) : item.value)));
    } catch (err) {
      showNotification(`Couldn't load filter values for column "${params.colDef.headerName}"`, 'warning');
    }
  };

  return {
    getRowId: getAgRowId('id'),
    columnDefs: [
      {
        headerName: 'Location',
        field: 'locationName',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Location Code',
        field: 'locationCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      OccupationEndDateGridColumn,
      {
        headerName: 'Date',
        field: 'date',
        valueFormatter: ({ value }) => formatDate(value),
        cellStyle: {
          textAlign: 'center',
        },
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
      },
      {
        headerName: 'Activity',
        headerTooltip: 'This shows if the activity falls under your organisation’s direct (company owned emission sources) or indirect (non company owned, employee, contractor or third party emission sources) activities',
        field: 'activity',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Material',
        field: 'material',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Origin',
        field: 'origin',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Supplier',
        field: 'supplier',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Product Code',
        field: 'productCode',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Units Quantity',
        field: 'unitsQuantity',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Weight',
        field: 'weight',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Quantity Unit',
        field: 'quantityUnit',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Total Cost',
        field: 'totalCost',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Total Cost (VAT)',
        field: 'totalCostVat',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Carbon Emission Scope 1 (kgCO2e)',
        field: 'carbonScope1',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerTooltip: 'These are the direct emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Embodied Carbon (kgCO2e)',
        field: 'carbonScope3',
        filter: 'agNumberColumnFilter',
        headerTooltip: 'These are the indirect emissions associated with the uploaded activity data',
      },
      {
        headerName: 'Currency Code',
        field: 'currency',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Departments',
        field: 'departments',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Notes',
        field: 'notes',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Reference',
        field: 'reference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Invoice Number',
        field: 'invoiceNumber',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Country',
        field: 'location.address.country.name',
        colId: 'country',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Region',
        field: 'location.region.name',
        colId: 'region',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
      {
        headerName: 'Evidence Reference',
        field: 'evidenceReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Other Reference',
        field: 'otherReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
        cellRenderer: 'documentReference',
      },
      {
        headerName: 'Upload Reference',
        field: 'importBatchFileName',
        cellRenderer: 'uploadReference',
        filterParams: {
          values: getFilterValues,
          valueFormatter: gridValueFormatter,
        },
      },
    ],
  };
}
