import React from 'react';
import { Select } from 'rio-ui-components';
import { alphabetiseByName } from '../../../utils/alphabetise';
import { search } from '../../../hooks/useSearch';

const MAX_OPTIONS_COUNT = 100;

const defaultWasteIn = {
  label: 'Default',
  value: 'Default'
};

function getOptionsFromData(wasteInLocations) {
  return [defaultWasteIn].concat(
    wasteInLocations
      .slice()
      .sort(alphabetiseByName)
      .map((w) => ({
        label: w.name,
        value: w.id
      }))
      .slice(0, MAX_OPTIONS_COUNT)
  );
}

export function WasteInSelect({ wasteInLocations, value, onChange }) {
  const options = getOptionsFromData(wasteInLocations);

  return (
    <Select
      placeholder="Waste In Locations"
      options={options}
      defaultOptions={options}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      loadOptions={(filterValue) => {
        const newOptions = getOptionsFromData(search(wasteInLocations, filterValue, ['id', 'name']));

        return { options: newOptions };
      }}
      value={value}
    />
  );
}
