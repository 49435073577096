import React from 'react';
import { Select } from 'rio-ui-components';

export function SelectRender(props) {
  const { value, onChange, name, options = [], ...others } = props;

  return (
    <Select
      {...others}
      name={name}
      onChange={({ target: { value } } = {}) => {
        onChange(value);
      }}
      options={options}
      value={value}
    />
  );
}
