import { Card, styled } from '@rio/ui-components';
import { VirtuosoGrid } from 'react-virtuoso';
import {
  SurveyInfoStatus,
  isReceivedSurvey,
  formatSubmissionStatus,
  getProgress,
  SurveyOverflowMenu,
  StatusSpan,
} from '~/components/Surveys';
import { ReceivedSurvey, User } from '@rio/rio-types';
import React, { forwardRef, useMemo } from 'react';

type Props = {
  surveys: ReceivedSurvey[];
  userId: string;
  setLock: React.Dispatch<React.SetStateAction<User | null>>;
  handleSurveyClick: (id: string) => void;
  toggleLockedModal: () => void;
};

const Virtuoso = styled(VirtuosoGrid)`
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  height: 100%;

  & > div:first-child {
    position: static !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const EllipsisMenu = styled('div')`
  position: absolute;
  top: 16px;
  right: 4px;
  left: 16px;
  display: flex;
  width: calc(100% - 20px);
  justify-content: space-between;
  z-index: 10;
  font-family: ${({ theme }) => theme.sys.typescale.body.medium.family};
  font-size: 12px !important;
  line-height: 16px;
`;

const StatusSpanStyle = styled(StatusSpan)`
  position: absolute;
`;

const ListItemWrapper = styled('div')`
  width: 100%;
`;

const ListSurvey = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, minmax(10px, 1fr));
  gap: 20px;
  font-family: 'Plus Jakarta Sans';

  @media (min-width: 1367px) and (max-width: 1600px) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }

  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
`;

const generateLockText = (lock: User) => {
  return ` - Locked by ${lock.first_name} ${lock.last_name}`;
};

// eslint-disable-next-line react/display-name
export const ReceivedSurveysVirtualList = React.memo(
  ({ surveys, userId, setLock, handleSurveyClick, toggleLockedModal }: Props) => {
    const gridComponents = useMemo(() => {
      const components = {
        List: forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ style, children, ...props }, ref) => (
          <ListSurvey
            ref={ref}
            {...props}
            style={{
              ...style,
            }}
          >
            {children}
          </ListSurvey>
        )),
      };

      components.List.displayName = 'List';

      return components;
    }, []);

    return (
      <Virtuoso
        totalCount={surveys.length}
        components={gridComponents}
        itemContent={(index) => {
          const lock = surveys[index].lockedBy;
          const isLocked = !!lock?.id && lock?.id !== userId;
          return (
            <ListItemWrapper key={surveys[index].id}>
              <Card
                subContent={<SurveyInfoStatus deadlineDate={surveys[index]?.deadlineDate} fill />}
                onClick={() => {
                  if (!isLocked) {
                    handleSurveyClick(surveys[index].id);
                  } else {
                    setLock(lock);
                    toggleLockedModal();
                  }
                }}
                title={surveys[index].name}
                subTitle={`From ${surveys[index].owner.name}` + `${isLocked ? generateLockText(lock) : ''}`}
                disabled={isLocked}
              >
                <EllipsisMenu>
                  {isReceivedSurvey(surveys[index]) && (
                    <StatusSpanStyle status={surveys[index].submission?.status}>
                      {formatSubmissionStatus(
                        surveys[index].submission?.status,
                        getProgress(surveys[index].template, surveys[index].submission)
                      )}
                    </StatusSpanStyle>
                  )}
                  <SurveyOverflowMenu survey={surveys[index]} />
                </EllipsisMenu>
              </Card>
              {index === surveys.length - 1 && <div id="sentinel" />}
            </ListItemWrapper>
          );
        }}
      />
    );
  }
);
