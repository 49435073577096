import {
  ManagementSystem,
  QuestionSequence as IQuestionSequence,
  QuestionSequenceRepeatable as IQuestionSequenceRepeatable,
  QuestionUnion
} from '@rio/rio-types';
import { AnswerQuestionFunction } from './types';
import { YesNoQuestionSelect } from './YesNoQuestionSelect';
import { NumericQuestionField } from './NumericQuestionField';
import { VerbalQuestionField } from './VerbalQuestionField';
import { DateQuestionField } from './DateQuestionField';
import { BreakdownQuestion } from './BreakdownQuestion';
import { QuestionSequence } from './QuestionSequence';
import { QuestionSequenceRepeatable } from './QuestionSequenceRepeatable';
import { AttachmentQuestion } from './AttachmentQuestion';

interface RenderQuestionUnionProps {
  question: QuestionUnion;
  onAnswer: AnswerQuestionFunction;
  framework: ManagementSystem;
  parent?: IQuestionSequence | IQuestionSequenceRepeatable;
}

export function RenderQuestionUnion({ framework, question, onAnswer, parent }: RenderQuestionUnionProps) {
  switch (question.__typename) {
    case 'YesNoQuestion':
      return <YesNoQuestionSelect question={question} onAnswer={onAnswer} />;
    case 'OpenEndedQuestion':
    case 'OpenEndedRichQuestion':
    case 'MultipleChoiceQuestion':
    case 'ChoiceQuestion':
      return <VerbalQuestionField question={question} onAnswer={onAnswer} />;
    case 'IntegerQuestion':
    case 'FloatQuestion':
      return <NumericQuestionField question={question} onAnswer={onAnswer} />;
    case 'DateQuestion':
      return <DateQuestionField question={question} onAnswer={onAnswer} />;
    case 'BreakdownQuestion':
      return <BreakdownQuestion question={question} onAnswer={onAnswer} />;
    case 'QuestionSequence':
      return <QuestionSequence question={question} framework={framework} questions={question.questions} onAnswer={onAnswer!} />;
    case 'QuestionSequenceRepeatable':
      return <QuestionSequenceRepeatable framework={framework} question={question} onAnswer={onAnswer} />;
    case 'AttachmentQuestion':
      return <AttachmentQuestion parent={parent} framework={framework} question={question} onAnswer={onAnswer} />;
    default:
      return null;
  }
}
