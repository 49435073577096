import React from 'react';
import styled from 'styled-components';
import { findKey } from 'lodash';
import { Icon } from 'rio-ui-components';

const FilterDropDownContainer = styled.div`
  margin-bottom: ${({ show, theme }) => (!show ? 0 : theme.geometry.sm.spacing)};
  transition: max-height 0.2s linear, opacity 0.2s linear;
  max-height: 0;

  ${({ show }) =>
    show
      ? 'max-height: 1200px; visibility: visible;'
      : `opacity: 0; visibility: hidden;
  `}
`;
const Button = styled.div`
  position: relative;
  margin: 15px 0;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-size: ${({ theme }) => theme.fonts.md.size};
`;
const DropDownIndicator = styled(Icon)`
  margin-left: 7px;
  transition: transform 0.3s linear;
  ${({ open }) => (open ? 'transform: rotate(90deg);' : '')}
`;
const FilterOptionsContainer = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.colors.text.light.text};
`;
const FilterApplied = styled.sup`
  margin-left: 5px;
  color: #ff9789;
  font-weight: bold;
  font-size: smaller;
`;
const Span = styled.span`
  margin-left: 24px;
`;
const SortIndicator = styled(Icon)`
  margin-left: 7px;
  transition: transform 0.3s linear;
  cursor: pointer;
  ${({ order }) => (order === 'DESC' ? 'transform: rotate(180deg);' : '')}
`;

const Sorter = ({ sorter, onSort }) => {
  return (
    <>
      <Button key={sorter.field} onClick={() => onSort(sorter)}>
        {sorter.label}
      </Button>
      <SortIndicator icon="chevron-up" size="sm" order={sorter.order} onClick={() => onSort(sorter)} />
    </>
  );
};

const FilterOption = ({ filterKey, filters, setActiveFilter, activeFilter }) => {
  const { name, options, applied, showFilter } = filters[filterKey];
  const showFilterKey = findKey(filters, { showIn: filterKey });

  const { optionsRight, appliedRight } = showFilterKey ? { optionsRight: filters[showFilterKey].options, appliedRight: filters[showFilterKey].applied } : { optionsRight: [], appliedRight: [] };

  return showFilter ? (
    <Button onClick={() => setActiveFilter(activeFilter === filterKey ? null : filterKey)}>
      {name}
      {(options.length !== applied.length || optionsRight.length !== appliedRight.length) && (
        <FilterApplied title="Filter applied">
          <span role="img" aria-label="img">
            🔍
          </span>
        </FilterApplied>
      )}
      <DropDownIndicator icon="chevron-right" size="sm" open={activeFilter === filterKey} />
    </Button>
  ) : null;
};

export const DocumentTypeFilterDropDown = ({ setActiveFilter, filters, sorters, onSort, children, activeFilter }) => (
  <>
    <FilterOptionsContainer>
      {sorters ? (
        <>
          <Span>Sort by: </Span>
          {sorters.map((sorter) => (
            <Sorter key={sorter.field} sorter={sorter} onSort={onSort} />
          ))}
        </>
      ) : null}
      {Object.keys(filters).map((filterKey) => (
        <>
          <Span>Filter by: </Span>
          <FilterOption key={filterKey} filterKey={filterKey} setActiveFilter={setActiveFilter} filters={filters} activeFilter={activeFilter} />
        </>
      ))}
    </FilterOptionsContainer>
    <FilterDropDownContainer show={!!activeFilter}>{children}</FilterDropDownContainer>
  </>
);
