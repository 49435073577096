import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FolderGridTile } from './FolderGridTile';
import { useIsV2 } from '~/hooks/useIsV2';
import { FolderGridTileV2 } from './FolderGridTileV2';

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-row-gap: 32px;
  grid-column-gap: 24px;
  padding-bottom: 12px;
`;

export function FolderGrid({ folders }) {
  const isV2 = useIsV2();
  const Tile = isV2 ? FolderGridTileV2 : FolderGridTile;
  return (
    <GridContainer>
      {folders.map((folder) =>
        folder.disabled ? (
          <Tile key={folder.id} folder={folder} />
        ) : (
          <Link key={folder.id} to={folder.disabled ? '#' : folder.url}>
            <Tile folder={folder} />
          </Link>
        )
      )}
    </GridContainer>
  );
}
