import { Col, Modal } from 'rio-ui-components';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { SurveyQuestion } from '@rio/rio-types';
import ContainerHeader from '../../../components/ContainerHeader';
import { GridOfQuestions } from './GridOfQuestions';
import { useNotification } from '../../../hooks/useNotification';
import { UpdateQuestion } from './UpdateQuestion';

const ContainerHeaderStyled = styled(ContainerHeader)`
  justify-content: space-between;
`;

export const ConfigurationSurveyQuestions = () => {
  const [agGridApi, setAgGridApi] = useState<GridApi | null>(null);
  const [questionEditing, setQuestionEditing] = useState<SurveyQuestion | null>(null);
  const closeUpdateModal = useCallback(() => setQuestionEditing(null), []);
  const handleEditButtonClick = useCallback((question) => setQuestionEditing(question), []);
  const { showNotification } = useNotification();

  const onCategoryUpdated = useCallback(async () => {
    closeUpdateModal();
    if (agGridApi) {
      agGridApi.refreshServerSide();
    }
    showNotification('Question updated successfully', 'success');
  }, [closeUpdateModal, showNotification, agGridApi]);

  return (
    <Col container fullHeight>
      <ContainerHeaderStyled
        name="ConfigurationSurveyQuestionsContainer"
        icon="question"
        iconColor="primary"
        title="Questions"
      ></ContainerHeaderStyled>
      <GridOfQuestions setAgGridApi={setAgGridApi} onEdit={handleEditButtonClick} />
      {questionEditing && (
        <Modal size="md" onDismiss={closeUpdateModal} dismissable show>
          <UpdateQuestion question={questionEditing} onUpdated={onCategoryUpdated} />
        </Modal>
      )}
    </Col>
  );
};
